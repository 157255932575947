import React from 'react'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { ArrowLeftIcon, DragIcon, BinIcon } from '@components/Icons'
import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Toggle } from '@components/Toggle'
import StatusButton from '@components/StatusButton'
import { DateTimeInput } from '@components/DateTimeInput'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { DisplayTypeEnum } from '@interfaces/DisplayTypeEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { HighlightBannerBookPageProps } from './interface'

export function HighlightBannerBookPage({
  id,
  data,
  selectedIds,
  publishedType,
  navigate,
  handleDragItem,
  handleSelectItemAll,
  handleSelectItem,
  handleEnable,
  handleDeleteBanner,
  handleDeleteBannerList,
  handlePublishBanner,
  startDateChange,
  endDateChange,
  publishChange,
  displayChangeActive,
  handleToggleIsEbook,
}: HighlightBannerBookPageProps) {
  const permission = usePermissionAction('homePageManagement')
  const { position } = useParams()
  return (
    <Container>
      <section className='flex items-start justify-between my-5'>
        <div className='flex items-center gap-x-[20px] mb-[28px]'>
          <button
            className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
            type='button'
            onClick={() =>
              navigate({
                pathname: `/home-page/highlight-banner/${position}`,
              })
            }
          >
            <ArrowLeftIcon width='24' height='24' />
          </button>

          <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
            {data?.bannerName}
          </div>
        </div>

        {data?.bookBanner.length && permission.edit ? (
          <Button
            type='button'
            className={cn('text-[14px] text-white bg-secondary', {
              hidden: !permission.create,
            })}
            onClick={() =>
              navigate({
                pathname: `/home-page/highlight-banner/${position}/book/${id}/edit`,
              })
            }
          >
            + แก้ไขรายการ
          </Button>
        ) : (
          <></>
        )}
      </section>

      <hr
        className={cn('my-5', {
          hidden: !permission.edit,
        })}
      />

      <section
        className={cn(
          'flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap',
          {
            hidden: !permission.edit,
          }
        )}
      >
        <div className='text-[12px]'>เผยแพร่ :</div>
        <Checkbox
          label='ทันที'
          type='radio'
          value={PublishedEnum.IMMEDIATELY}
          checked={publishedType === PublishedEnum.IMMEDIATELY}
          onChange={() => publishChange(PublishedEnum.IMMEDIATELY)}
        />
        <Checkbox
          label='ตั้งเวลาเผยแพร่'
          type='radio'
          value={PublishedEnum.CUSTOM}
          checked={publishedType === PublishedEnum.CUSTOM}
          onChange={() => publishChange(PublishedEnum.CUSTOM)}
        />

        <DateTimeInput
          className='max-w-[200px]'
          placeholder='เลือกวัน/เวลา'
          selected={
            data?.publishedFrom ? new Date(data.publishedFrom) : new Date()
          }
          disabled={publishedType === PublishedEnum.IMMEDIATELY}
          onChange={startDateChange}
          showTimeSelect
        />

        <DateTimeInput
          className='max-w-[200px]'
          placeholder='เลือกวัน/เวลา'
          selected={data?.publishedTo ? new Date(data.publishedTo) : new Date()}
          onChange={endDateChange}
          showTimeSelect
        />
      </section>

      <hr className='my-5' />

      <section className='flex items-center justify-between my-5 h-[40px]'>
        <div className='flex gap-x-[10px] text-secondary'>
          <Checkbox
            className={cn({
              hidden: !permission.edit && !permission.delete,
            })}
            onChange={handleSelectItemAll}
            checked={
              selectedIds.length === data?.bookBanner.length &&
              data?.bookBanner.length !== 0
            }
          />
          <span className='font-light'>ทั้งหมด</span>
          <span className='font-bold text-blue-3'>
            ({' '}
            {(data?.bookBanner && data.bookBanner.length.toLocaleString()) || 0}{' '}
            รายการ )
          </span>
        </div>

        {!!selectedIds.length && (
          <div className='flex gap-x-[10px]'>
            {permission.edit && (
              <>
                <StatusButton
                  status='publish'
                  type='button'
                  onClick={() => handlePublishBanner(true)}
                />
                <StatusButton
                  status='unpublish'
                  type='button'
                  onClick={() => handlePublishBanner(false)}
                />
              </>
            )}
            {permission.delete && (
              <StatusButton
                status='delete'
                type='button'
                onClick={handleDeleteBannerList}
              />
            )}
          </div>
        )}
      </section>

      <hr className='my-5' />

      {!data?.bookBanner.length && permission.create && (
        <div className='w-full h-[200px] my-5 flex items-center justify-center border-dashed border border-[#cecfd7] rounded-lg'>
          <Button
            type='button'
            className='text-[14px] text-white bg-secondary'
            onClick={() =>
              navigate({
                pathname: `/home-page/highlight-banner/${position}/book/${id}/create`,
              })
            }
          >
            + เพิ่มนิยาย
          </Button>
        </div>
      )}

      <DragDropContext onDragEnd={handleDragItem}>
        <Droppable droppableId='list' isDropDisabled={!permission.edit}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data?.bookBanner.map((item, index) => (
                <Draggable
                  draggableId={`${item?.id.toString()}`}
                  index={index}
                  key={item?.id}
                >
                  {providedDragable => (
                    <div
                      className='flex gap-5 my-5'
                      ref={providedDragable.innerRef}
                      {...providedDragable.draggableProps}
                      {...providedDragable.dragHandleProps}
                    >
                      <div className='flex items-center justify-between gap-x-[30px] text-black-480'>
                        <Checkbox
                          className={cn({
                            hidden: !permission.edit && !permission.delete,
                          })}
                          checked={selectedIds.includes(item?.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.stopPropagation()
                            handleSelectItem(item?.id)
                          }}
                        />
                        <span className='font-mitr font-medium text-2xl'>
                          {item?.runningNo.toLocaleString()}
                        </span>
                        <DragIcon width='10' className='cursor-grab' />
                      </div>

                      <div className='grid grid-cols-[60px_1fr_1fr_1fr_1fr_1fr] gap-x-[30px] content-between w-full border-[0.5px] border-gray rounded-lg p-[30px] shadow-51'>
                        <div
                          className={cn(
                            'w-[60px] rounded-lg overflow-hidden row-span-2',
                            {
                              'opacity-50': !item?.isActive,
                            }
                          )}
                        >
                          <div className='relative w-full pt-[136.67%]'>
                            <img
                              className='absolute inset-0'
                              src={item?.book?.coverImgPath}
                              alt='banner'
                            />
                          </div>
                        </div>

                        <div className='col-span-2'>
                          <p className='text-xs font-light text-secondary-100'>
                            เรื่อง
                          </p>
                          <p className='text-sm font-light break-word line-clamp-1 leading-[1.6]'>
                            {item?.book?.title}
                          </p>
                        </div>
                        <div>
                          <p className='text-xs font-light text-secondary-100'>
                            สำนักพิมพ์
                          </p>
                          <p className='text-sm font-light max-w-[150px] break-all line-clamp-1 leading-[1.6]'>
                            {item?.book?.penName?.user?.displayName}
                          </p>
                        </div>
                        <div>
                          <p className='text-xs font-light text-secondary-100'>
                            นามปากกา
                          </p>
                          <p className='text-sm font-light break-all line-clamp-1 leading-[1.6]'>
                            {item?.book?.penName?.firstPenName}
                          </p>
                        </div>

                        <Toggle
                          className='ml-auto'
                          disabled={!permission.edit}
                          checked={item.isActive}
                          onChange={() => handleEnable(item)}
                        />

                        <div className='col-span-3 flex items-center space-x-[30px]'>
                          <p className='text-xs font-light text-secondary-100'>
                            Display
                          </p>
                          <Checkbox
                            className='cursor-pointer'
                            name='isOnWeb'
                            label='Web'
                            disabled={!permission.edit}
                            defaultChecked={item.isOnWeb}
                            onChange={() =>
                              displayChangeActive(item, DisplayTypeEnum.WEB)
                            }
                          />
                          <Checkbox
                            className='cursor-pointer'
                            name='isOnIos'
                            label='iOS'
                            disabled={!permission.edit}
                            defaultChecked={item.isOnIos}
                            onChange={() =>
                              displayChangeActive(item, DisplayTypeEnum.IOS)
                            }
                          />
                          <Checkbox
                            className='cursor-pointer'
                            name='isOnAndroid'
                            label='Android'
                            disabled={!permission.edit}
                            defaultChecked={item.isOnAndroid}
                            onChange={() =>
                              displayChangeActive(item, DisplayTypeEnum.ANDROID)
                            }
                          />
                        </div>

                        {SHOW_EBOOK_FEATURE ? (
                          <Checkbox
                            className='cursor-pointer'
                            name='isEbook'
                            label='Ebook Series'
                            disabled={!permission.edit}
                            defaultChecked={item.isEbook}
                            onChange={() => handleToggleIsEbook(item)}
                          />
                        ) : (
                          <div />
                        )}
                        <div className='flex items-center justify-end gap-x-[10px]'>
                          <button
                            type='button'
                            className={cn(
                              'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                              {
                                hidden: !permission.delete,
                              }
                            )}
                            onClick={() => handleDeleteBanner(item?.id)}
                          >
                            <BinIcon width='20' height='20' />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}
