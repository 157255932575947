import React, { useState } from 'react'
import { DateTime } from 'luxon'

import { DurationEnum } from '@interfaces/DurationEnum'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import {
  SearchDateProps,
  WithSearchDateRangeProps,
  SearchType,
} from './interface'

export function withSearchDate(Component: React.FC<SearchDateProps>) {
  function Hoc({
    defaultSelectedDate,
    onSearch,
    onExport,
    onReset,
    ...props
  }: WithSearchDateRangeProps) {
    const [searchParams, setSearchParams] = useState<SearchType>({
      selectedDate: defaultSelectedDate,
      startDate: null,
      endDate: null,
    })

    function handleDateRangeChange(value: DurationEnum) {
      setSearchParams({
        selectedDate: value,
        startDate: null,
        endDate: null,
      })
    }

    function handleStartDateChange(date: Date) {
      setSearchParams(prev => ({
        ...prev,
        selectedDate: DurationEnum.CUSTOM,
        startDate: date,
      }))
    }

    function handleEndDateChange(date: Date) {
      setSearchParams(prev => ({
        ...prev,
        selectedDate: DurationEnum.CUSTOM,
        endDate: date,
      }))
    }

    function formatDate() {
      let start = DateTime.now()
      let end = DateTime.now()

      if (searchParams.selectedDate === DurationEnum.DAILY) {
        start = start.minus({ days: 1 }).startOf('day')
        end = end.minus({ days: 1 }).endOf('day')
      } else if (searchParams.selectedDate === DurationEnum.MONTHLY) {
        start = start
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        end = end.endOf('day')
      } else if (searchParams.selectedDate === DurationEnum.YEARLY) {
        start = start
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        end = end.endOf('day')
      } else {
        if (!searchParams.startDate || !searchParams.endDate) {
          return undefined
        }
        start = DateTime.fromJSDate(searchParams.startDate as Date).startOf(
          'day'
        )
        end = DateTime.fromJSDate(searchParams.endDate as Date).endOf('day')
      }
      return { start, end, selectedDate: searchParams.selectedDate }
    }

    function handleSearch() {
      const selectDate = formatDate()
      if (selectDate) {
        onSearch(selectDate.start, selectDate.end, selectDate.selectedDate)
      }
    }

    function handleReset() {
      setSearchParams({
        selectedDate: DurationEnum.MONTHLY,
        startDate: null,
        endDate: null,
      })
      onReset()
    }

    const componentProps = {
      ...props,
      searchParams,
      handleSearch,
      handleExport: onExport,
      handleReset,
      handleDateRangeChange,
      handleStartDateChange,
      handleEndDateChange,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
