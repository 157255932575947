import { Expose } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'

export class BookByTitleType {
  @Expose() id: number

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() penName: {
    id: number

    firstPenName: string
  }

  @Expose() chapterCount: number

  @Expose() totalSales: number

  @Expose() totalRead: number

  @Expose() totalLike: number

  @Expose() bookType: BookEnum
}
