import { gql } from 'graphql-request'

export const CREATE_BOOK_STORY_SETTING = gql`
  mutation CreateBookStorySetting(
    $createBookStorySettingInput: CreateBookStorySettingInput!
  ) {
    createBookStorySetting(
      createBookStorySettingInput: $createBookStorySettingInput
    ) {
      id
      name
      maxCharacter
    }
  }
`
