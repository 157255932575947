import { gql } from 'graphql-request'

export const GET_PROMOTION_CHALLENGE_HISTORY = gql`
  query PromotionRequest($promotionRequestId: Float!) {
    promotionRequest(id: $promotionRequestId) {
      id
      status
      createdBy {
        id
        avatarImgPath
        displayName
      }
      approvedBy {
        id
        avatarImgPath
        displayName
      }
      rejectedReason
      action
      createdAt
      updatedAt
      oldPromotion {
        status
      }
      newPromotion {
        lastRequest {
          id
          status
          createdBy {
            id
            avatarImgPath
            displayName
          }
          action
          createdAt
        }
        id
        businessId
        name
        description
        userTerm
        startPublishedAt
        endPublishedAt
        publishedType
        status
        isActive
        isEditable
        createdAt
        updatedAt
        promotionChallenge {
          id
          action
          actionValue
          wordCount
          socialMediaChannels
          readCondition
          coinsCondition {
            id
            name
          }
          bookTypes
          benefitValue
          benefitCoinId
          benefitLimitType
          benefitLimitValue
          budget
          budgetPerDay
          categories {
            id
            name
          }
          summary {
            userCount
            progressCount
            coinValue
            coin {
              id
              name
            }
          }
        }
      }
      correctionDetail {
        name {
          old
          new
        }
        description {
          new
          old
        }
        userTerm {
          new
          old
        }
        challengeAction {
          action {
            old
            new
          }
          actionValue {
            old
            new
          }
          wordCount {
            old
            new
          }
          socialMediaChannels {
            old
            new
          }
          readCondition {
            old
            new
          }
          coinsCondition {
            old
            new
          }
          bookTypes {
            old
            new
          }
          categories {
            old
            new
          }
        }
        challengeBenefit {
          benefitCoin {
            new
            old
          }
          benefitValue {
            old
            new
          }
        }
        challengeBenefitLimit {
          benefitLimitType {
            old
            new
          }
          benefitLimitValue {
            old
            new
          }
        }
        challengeBudget {
          budget {
            old
            new
          }
          budgetPerDay {
            old
            new
          }
        }
        publishPeriod {
          startPublishedAt {
            old
            new
          }
          endPublishedAt {
            old
            new
          }
        }
      }
    }
  }
`
