import { Expose, Transform, Type } from 'class-transformer'
import { ActiveUserHistoryType } from './ActiveUserHistoryType'
import { DeviceCategoryType } from './DeviceCategoryType'
import { SessionHistoryType } from './SessionHistoryType'
import { StorageSizeHistoryType } from './StorageSizeHistoryType'
import { TopPageViewType } from './TopPageViewType'
import { TrafficCategoryType } from './TrafficCategoryType'

export class AppPerformanceType {
  @Expose()
  @Transform(({ value }) => value || 0)
  totalUser: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalPageView: number

  @Expose()
  @Transform(({ value }) => value || 0)
  avgSessionDuration: number

  @Expose()
  @Transform(({ value }) => value || 0)
  bounceRate: number

  @Expose()
  @Transform(({ value }) => value || 0)
  storageSize: number

  @Expose()
  @Type(() => SessionHistoryType)
  sessionHistory: SessionHistoryType

  @Expose()
  @Type(() => TrafficCategoryType)
  trafficCategory: TrafficCategoryType[]

  @Expose()
  @Type(() => DeviceCategoryType)
  deviceCategory: DeviceCategoryType[]

  @Expose()
  @Type(() => TopPageViewType)
  topPageView: TopPageViewType[]

  @Expose()
  @Type(() => StorageSizeHistoryType)
  storageSizeHistory: StorageSizeHistoryType

  @Expose()
  @Type(() => ActiveUserHistoryType)
  activeUserHistory: ActiveUserHistoryType
}
