import { PopularSearchTextSection } from '@features/dashboard/pages/SearchPage/components/PopularSearchTextSection'
import { PopularSearchTextByTypeCard } from '@features/dashboard/pages/SearchPage/components/PopularSearchTextByTypeCard'
import { SearchPopularBy } from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export function PopularTab({
  searchParam,
}: {
  searchParam: DonationQueryParam
}) {
  return (
    <div>
      <PopularSearchTextSection searchParam={searchParam} />
      <div className='grid grid-cols-2 mt-5 gap-x-5 gap-y-5'>
        <PopularSearchTextByTypeCard
          searchParam={searchParam}
          type={SearchPopularBy.ALL}
        />
        <PopularSearchTextByTypeCard
          searchParam={searchParam}
          type={SearchPopularBy.TITLE}
        />
        <PopularSearchTextByTypeCard
          searchParam={searchParam}
          type={SearchPopularBy.WRITER}
        />
        <PopularSearchTextByTypeCard
          searchParam={searchParam}
          type={SearchPopularBy.CONTENT}
        />
        <PopularSearchTextByTypeCard
          searchParam={searchParam}
          type={SearchPopularBy.TAG}
        />
      </div>
    </div>
  )
}
