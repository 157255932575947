import React from 'react'
import {
  SaleAnalyticsChartAcceptProps,
  SaleAnalyticsChartProps,
} from './interface'

export const withSaleAnalyticsChart = (
  Component: React.FC<SaleAnalyticsChartProps>
) => {
  function WithSaleAnalyticsChart(props: SaleAnalyticsChartAcceptProps) {
    const newProps = {
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithSaleAnalyticsChart
}
