import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import {
  ColumnType,
  SortByType,
  SortingType,
} from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { useClient } from '@hooks/useClient'
import { UserConsentType } from '@models/consent/UserConsentType'
import { CircleArrowDownIcon } from '@components/Icons'
import { REST_API_URL } from '@configs/config'
import { useAuthentication } from '@hooks/useAuthentication'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { PDPAProps } from './interface'

export function withPDPA(Component: React.FC<PDPAProps>) {
  function WithPDPA() {
    const client = useClient()
    const { id } = useParams()
    const { user } = useAuthentication()
    const [sorting, setSorting] = useState<SortByType>({
      key: 'createdAt',
      order: SortingType.DESC,
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({})
    const { data } = useQuery(['pdpa-user', id, page, sorting], () =>
      client?.userWriterClient
        .getUserConsents({
          userId: Number(id),
          page,
          limitPerPage: perpage,
          key: sorting.key,
          order: sorting.order,
        })
        .then(res => {
          handleTotalChange(res.total)
          return res.data
        })
    )

    function onSortingChange(value: SortByType) {
      setSorting({
        key: value.key,
        order: value.order,
      })
    }

    const columns: ColumnType<UserConsentType>[] = [
      {
        column: 'วันที่',
        accessor: 'createdAt',
        cell: ({ value }) => (
          <div>
            {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
              locale: 'th-Th',
            })}
          </div>
        ),
        isSortable: true,
        cellClassName: 'min-w-[300px] max-w-[300px] pr-[5px]',
      },
      {
        column: 'Version',
        accessor: 'consent.version',
        cell: ({ value }) => <div>{value}</div>,
        isSortable: true,
        sortKey: 'version',
        cellClassName: 'min-w-[300px] max-w-[300px] pr-[5px]',
      },
      {
        column: 'Type',
        accessor: 'consent.type',
        cell: ({ value }) => (
          <div>
            {value === ConsentTypeEnum.TERMS_OF_USE
              ? 'ยอมรับเงื่อนไขการใช้บริการ'
              : value === ConsentTypeEnum.PRIVACY_POLICY
              ? 'ยอมรับนโยบายส่วนบุคคล'
              : '-'}
          </div>
        ),
        isSortable: false,
        cellClassName: 'w-full pr-[5px]',
      },
      {
        column: 'Download',
        actionCell: ({ obj }) => (
          <div className='py-[6px]'>
            <a
              href={`${REST_API_URL}/pdpa/download-document-by-admin?userId=${obj.userId}&adminId=${user.id}&version=${obj.consent.version}`}
              target='_blank'
              rel='noreferrer'
            >
              <div className='border border-blue-2 rounded-[8px] text-secondary-100 p-[4px] bg-white'>
                <CircleArrowDownIcon width='16' height='16' />
              </div>
            </a>
          </div>
        ),
        cellClassName: 'flex justify-end w-[100px]',
        isAction: true,
      },
    ]

    const componentProps = {
      page,
      perpage,
      total,
      data,
      columns,
      sorting,
      isLoading: false,
      onSortingChange,
      pageChange,
    }
    return <Component {...componentProps} />
  }

  return WithPDPA
}
