import { OrderByEnum } from '@interfaces/dashboard/TopHashtagParam'
import { Box } from '@components/Box'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import {
  TopHashtagProps,
  TopHashtagType,
} from '@features/dashboard/pages/SearchPage/components/TopHashtag/interface'

export function TopHashtag({
  data,
  isLoading,
  handleWaypointEnter,
  type,
  handleSortChange,
}: TopHashtagProps) {
  const sortKey =
    type === TopHashtagType.USE ? OrderByEnum.USED_COUNT : OrderByEnum.TAG_COUNT
  const items = data?.pages.flatMap(row => row?.data) || []
  return (
    <div className='w-[455px]'>
      <Box.Title>
        Top hashtag {type === TopHashtagType.USE ? 'use' : 'click'}
      </Box.Title>

      <Table
        className='mb-[30px] max-h-[460px] pr-[10px]'
        isLoading={isLoading}
        data={items}
        columns={[
          {
            column: 'hashtag',
            accessor: 'tagName',
            cell: ({ value }) => (
              <div className='line-clamp-1 break-word'>{value}</div>
            ),
            cellClassName: 'py-[10px] pr-[10px]',
            isSortable: false,
          },
          {
            column: 'จำนวนการใช้ (ครั้ง)',
            accessor:
              type === TopHashtagType.USE ? 'usedCount' : 'totalUserClick',
            cell: ({ value }) => (
              <div className='text-right'>{Number(value).toLocaleString()}</div>
            ),
            cellClassName: 'py-[10px] w-[135px] pr-[10px]',
            sortKey,
            isSortable: true,
          },
        ]}
        initialSort={{
          key: sortKey,
          order: SortingType.DESC,
        }}
        onSort={handleSortChange}
        onWaypointEnter={handleWaypointEnter}
        fixedHeader
      />
    </div>
  )
}
