import { Expose, Transform, Type } from 'class-transformer'

import { ImageType } from '../ImageType'

export class LevelType {
  @Expose() id: number

  @Expose() level: number

  @Expose() levelName: string

  @Expose() target: number

  @Expose() isUsed: boolean

  @Expose() isActive: boolean

  @Expose({ name: 'iconUrl' })
  @Transform(({ obj }) => ({ blob: undefined, url: obj?.iconUrl || '' }))
  @Type(() => ImageType)
  upload: ImageType
}
