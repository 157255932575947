import { useState } from 'react'
import { ImageViewerProps, WithImageViewerProps } from './interface'

export function withImageViewer(Component: React.FC<ImageViewerProps>) {
  function WithImageViewer({ name, ...props }: WithImageViewerProps) {
    const [isViewerOpen, setIsViewerOpen] = useState(false)

    function handleOpenModal() {
      setIsViewerOpen(true)
    }

    function handleCloseModal() {
      setIsViewerOpen(false)
    }

    const componentProps = {
      ...props,
      name,
      isViewerOpen,
      isPdf: name ? name.endsWith('.pdf') : false,
      handleOpenModal,
      handleCloseModal,
    }
    return <Component {...componentProps} />
  }

  return WithImageViewer
}
