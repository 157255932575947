import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'

export function useGetProfile() {
  const client = useClient()
  return useQuery('my-profile', () => client?.userClient.getProfile(), {
    enabled: false,
  })
}
