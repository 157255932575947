import React from 'react'
import cn from 'classnames'

import { ChevronDownIcon, CrossIcon } from '@components/Icons'
import { useMutiSelectDropDown } from '@hooks/useDropdown'
import { Checkbox } from '@components/Checkbox'
import { MultiSelectFieldPropsType } from './interface'

export function MultiSelectField({
  className,
  optionClassName,
  options,
  label,
  value,
  placeholder,
  handleRemove,
  isInputLabel,
  error,
  touched,
  handleSelectAll,
  showSelectAll,
  ...props
}: MultiSelectFieldPropsType) {
  const { dropdownRef, isShowDropdown, toggleDropdown } =
    useMutiSelectDropDown()

  return (
    <div className={cn('text-secondary-100', className)}>
      {label && <div className='text-[12px] font-light mb-[4px]'>{label}</div>}

      <div
        className={cn(
          'flex gap-x-[10px] min-h-[40px] px-[10px] items-center py-[8px] border border-gray rounded-[8px] cursor-pointer',
          {
            'bg-[#eeeeee] ': props.disabled,
            'border-[#f7575f]': error && touched,
          }
        )}
        onClick={() => {
          if (!props.disabled) {
            toggleDropdown()
          }
        }}
      >
        <div className='flex-1 flex flex-wrap gap-[6px]'>
          {isInputLabel && (
            <div className='text-[14px] font-light text-secondary-100'>
              {label} {(value as string[]).length ? ': ' : null}
            </div>
          )}
          {(value as string[]).length ? (
            options.map(option =>
              (value as string[])?.find(row => row === option.value) ? (
                <div
                  key={option.value}
                  className='flex items-center gap-[5px] text-[14px] bg-secondary font-light rounded-[8px] text-white px-[10px]'
                >
                  <div>{option.text}</div>
                  <button
                    type='button'
                    onClick={event => {
                      if (!props.disabled) {
                        event.stopPropagation()
                        handleRemove(option.value)
                      }
                    }}
                  >
                    <CrossIcon className='w-[14px] h-[14px]' />
                  </button>
                </div>
              ) : null
            )
          ) : (
            <div className='text-[14px] font-light text-secondary-100'>
              {placeholder}
            </div>
          )}
        </div>
        <ChevronDownIcon className={cn('w-[16px] h-[16px] text-primary')} />
      </div>
      <div className='relative'>
        <div
          ref={dropdownRef}
          className={cn(
            'absolute shadow-51 py-[10px] border border-gray w-full top-[1px] z-10 bg-white rounded max-h-[300px] overflow-y-auto',
            {
              hidden: !isShowDropdown,
            }
          )}
        >
          {showSelectAll && (
            <>
              <div className='flex items-center text-[14px] px-[14px] py-[6px] font-light '>
                <Checkbox
                  onClick={handleSelectAll}
                  defaultChecked={
                    (value as string[])?.length === options.length
                  }
                  label='ทั้งหมด'
                  disabled={props.disabled}
                />
              </div>
              <hr className='text-gray' />
            </>
          )}
          {options.map(option => (
            <div
              key={option.value}
              className='flex items-center text-[14px] px-[14px] py-[6px] font-light hover:bg-primary-100 hover:text-blue'
            >
              <Checkbox
                {...props}
                value={option.value}
                checked={(value as string[])?.includes(option.value)}
                label={option.text}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
