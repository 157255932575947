import { Expose, Type } from 'class-transformer'
import { EbookOverviewSaleTopRankDetailType } from './EbookOverviewSaleTopRankDetailType'

export class EbookOverviewSaleTopRankResponse {
  @Expose()
  @Type(() => EbookOverviewSaleTopRankDetailType)
  data: EbookOverviewSaleTopRankDetailType[]

  @Expose() total: number

  @Expose() page: number

  @Expose() summaryTotalSales: number
}
