import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByCategoryType {
  @Expose() category: string

  @Expose() totalSearch: number
}

@Exclude()
export class SearchResultByCategoryResponse {
  @Expose()
  @Type(() => SearchResultByCategoryType)
  data: SearchResultByCategoryType[]

  @Expose() page: number
}
