import cn from 'classnames'

import { Box } from '@components/Box'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { InputField } from '@components/forms/InputField'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { CheckIcon, ErrorIcon } from '@components/Icons'
import { CreatePromotionCodeProps } from './interface'
import { GenerateCodeField } from '../GenerateCodeField'
import { UploadPromotionCodeField } from '../UploadPromotionCodeField'

export function CreatePromotionCode({
  values,
  errors,
  channels,
  channelsInput,
  promotionCodesId,
  generateCoupon,
  setGenerateCoupon,
  setFieldValue,
}: CreatePromotionCodeProps) {
  return (
    <>
      <Box className='flex flex-col mt-[40px]'>
        <p className='font-bold text-secondary'>สร้าง Promotion Code</p>
        <div className='flex py-[30px] border-b'>
          <p className='font-light text-[12px] text-secondary-100 pr-[10px]'>
            รูปแบบ Promotion Code :
          </p>
          <PromotionRadioInputField
            name='codeType'
            label='Universal Code ใช้ได้หลายคน'
            className='pr-[30px]'
            value={CodeTypeEnum.UNIVERSAL}
            checked={values.codeType === CodeTypeEnum.UNIVERSAL}
            disabled={generateCoupon === PromotionCodeJobStatusEnum.PROCESSING}
          />
          <PromotionRadioInputField
            name='codeType'
            label='Unique Code ใช้ได้ครั้งเดียว'
            value={CodeTypeEnum.UNIQE}
            checked={values.codeType === CodeTypeEnum.UNIQE}
          />
        </div>
        <div
          className={cn('flex flex-1 pt-[30px]', {
            hidden: values.codeType !== CodeTypeEnum.UNIVERSAL,
          })}
        >
          <InputField
            name='universalCodeInput'
            label='Promotion Code'
            placeholder='สร้าง Promotion Code ของคุณ'
            className={cn('w-[286px]')}
            disabled={generateCoupon === PromotionCodeJobStatusEnum.PROCESSING}
            onChange={() =>
              setFieldValue('universalCodeInputError', 'checking ...')
            }
            rightIcon={
              values.universalCodeInput &&
              (values.universalCodeInputError ? (
                <ErrorIcon width='18' height='18' />
              ) : (
                <CheckIcon
                  width='18'
                  height='18'
                  className='text-white bg-primary rounded-full p-1'
                />
              ))
            }
          />
          <span className='text-[12px] font-light text-secondary-100 pt-[32px] pl-[18px]'>
            *Promotion Code รองรับตัวอักษรและตัวเลข และมีอย่างน้อย 6
            ตัวอักษร/ตัวเลขขึ้นไป
          </span>
        </div>

        <div
          className={cn('flex flex-col  w-full pt-[30px]', {
            'gap-y-[65px]': !errors?.codeFile,
            'gap-y-[31px]': errors?.codeFile,
            hidden: values.codeType !== CodeTypeEnum.UNIQE,
          })}
        >
          <div className='flex flex-col'>
            <UploadPromotionCodeField
              name='codeFile'
              fieldValueName='coupons'
              fieldErrorName='codeFileError'
              channelsInput={channelsInput}
              generateCoupon={generateCoupon}
              promotionCodeId={promotionCodesId}
            />
            <span
              className={cn('text-[12px] ml-[44px] text-red-74 pt-[16px]', {
                hidden: !errors?.codeFile,
              })}
            >
              {errors?.codeFile}
            </span>
          </div>
          <GenerateCodeField
            channels={channels}
            channelsInput={channelsInput}
            promotionCodesId={promotionCodesId}
            generateCoupon={generateCoupon}
            setGenerateCoupon={setGenerateCoupon}
          />
        </div>
      </Box>
    </>
  )
}
