import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { PopupBannerFormType } from '@models/popupBanner/PopupBannerFormType'
import { EditPopupBannerPageProps } from './interface'

export function withEditPopupBannerPage(
  Component: React.FC<EditPopupBannerPageProps>
) {
  function WithEditPopupBannerPage() {
    const { id } = useParams()
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()

    const { data, isFetching, isLoading } = useQuery(
      ['popup', id],
      () =>
        client?.popupBannerClient
          .getPopupBanner(Number(id))
          .then(res => plainToInstance(PopupBannerFormType, res)),
      {
        enabled: !!Number(id),
      }
    )

    function goBackToPupUpBannerPage() {
      navigate('/home-page/pop-up/pop-up-management')
    }

    async function onSubmit(value: PopupBannerFormType) {
      try {
        await client?.popupBannerClient.updatePopupBanner(value)
        alert.success('แก้ไข pop up สำเร็จ')
        goBackToPupUpBannerPage()
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    if (!data || isFetching || isLoading) return null

    const componentProps = {
      initialValues: data,
      goBackToPupUpBannerPage,
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithEditPopupBannerPage
}
