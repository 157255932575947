import React, { useState } from 'react'
import { QueryKey, useInfiniteQuery, useQuery } from 'react-query'

import { BookEnum } from '@interfaces/BookEnum'
import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { EbookOverviewSaleTopRankResponse } from '@models/dashboard/EbookOverviewSaleTopRankResponse'
import {
  EbookByCategoryProps,
  SearchParamsType,
  WithEbookByCategoryProps,
} from './interface'

const PER_PAGE = 20

const withEbookByCategory = (Component: React.FC<EbookByCategoryProps>) => {
  function WithEbookByCategory({ queryParam }: WithEbookByCategoryProps) {
    const client = useClient()
    const [bookTypeByCategory, setBookTypeByCategory] =
      useState<BookEnum | null>(null)
    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      bookType: null,
      category: null,
    })
    const [sort, setSort] = useState({
      sortKey: 'downloadedCount',
      sortOrder: SortingType.DESC,
    })

    const queryKey: QueryKey = ['overview', 'sales', 'ebook', queryParam]

    const { data: categoryOptions = [] } = useQuery(['category-options'], () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          label: row.text,
          value: String(row.value),
        }))
      )
    )

    const { data: overviewSale = [], isLoading: overviewSaleLoading } =
      useQuery([...queryKey, bookTypeByCategory], () =>
        client?.dashboardClient.ebookDashboardOverviewSalesByCategory({
          ...queryParam,
          bookType: bookTypeByCategory,
        })
      )

    const {
      fetchNextPage,
      data: overviewSaleTopRank,
      isFetchingNextPage,
      isLoading,
    } = useInfiniteQuery(
      [queryKey, 'top-rank', searchParams, sort],
      async ({ pageParam = 1 }) => {
        const res =
          await client!.dashboardClient.ebookDashboardOverviewSalesTopRank({
            startDate: queryParam.startDate,
            endDate: queryParam.endDate,
            categoryIds: searchParams.category,
            ebookTypes: searchParams.bookType,
            sortKey: sort.sortKey,
            sortOrder: sort.sortOrder,
            limitPerPage: PER_PAGE,
            page: pageParam,
          })

        return res
      },
      {
        getNextPageParam: (lastPage: EbookOverviewSaleTopRankResponse) => {
          if (lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleBookTypeChange(bookType: BookEnum) {
      setSearchParams(prev => ({
        ...prev,
        bookType,
      }))
    }
    function handleCategoryChange(category: string) {
      setSearchParams(prev => ({
        ...prev,
        category,
      }))
    }

    function handleBookTypeByCategoryChange(value: BookEnum) {
      setBookTypeByCategory(value)
    }

    function handleSort({ key, order }: SortByType) {
      setSort({ sortKey: key, sortOrder: order })
    }
    const newProps = {
      bookTypeByCategory,
      handleBookTypeByCategoryChange,
      searchParams,
      categoryOptions,
      handleBookTypeChange,
      handleCategoryChange,
      handleSort,
      overviewSale,
      overviewSaleLoading,
      overviewSaleTopRank,
      overviewSaleTopRankLoading: isFetchingNextPage || isLoading,
      handleFetchNextPage,
    }
    return <Component {...newProps} />
  }

  return WithEbookByCategory
}

export default withEbookByCategory
