import { Expose, Transform } from 'class-transformer'

export class ConsentType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  content: string

  @Expose()
  @Transform(({ value }) => value || '')
  version: string
}
