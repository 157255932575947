import React, { useState } from 'react'
import cn from 'classnames'
import { usePortalDropdown } from '@hooks/useDropdown'
import { Editor, JSONContent } from '@tiptap/react'
import Tooltip from '@reach/tooltip'
import TiptapDropdownInput from './TiptapDropdownInput'

interface TiptapFootnoteInputProps {
  icon: React.ReactNode
  editor: Editor
  isActive?: boolean
  tooltipLabel?: string
}

export default function TiptapFootnoteInput({
  icon,
  editor,
  isActive,
  tooltipLabel,
}: TiptapFootnoteInputProps) {
  const [text, setText] = useState('')
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  function reorderFootnote(content: JSONContent[], count: number) {
    let c = count
    // eslint-disable-next-line no-restricted-syntax
    for (const node of content) {
      if (node.type === 'footnote') {
        node.attrs = { hrefId: c }
        c += 1
      }
      if (node?.content && node?.content?.length > 0) {
        const childCount = reorderFootnote(node.content, c)
        c = childCount
      }
    }
    return c
  }

  const handleAddFootnote = () => {
    if (text) {
      let count = 0
      let insertIndex = 0
      // eslint-disable-next-line @typescript-eslint/no-shadow
      editor!.state.doc.descendants((n: any, position: number) => {
        if (n.type.name === 'footnote') {
          count += 1
          if (editor.state?.selection?.anchor > position) {
            insertIndex = count
          }
        }
      })
      editor.commands.insertContent({
        type: 'footnote',
        attrs: {
          hrefId: (insertIndex + 1).toString(),
        },
      })
      if (count === 0) {
        editor.chain().focus('end').run()
        editor.commands.insertContent({
          type: 'footnote-ordered-list',
          content: [
            {
              type: 'customOrderedList',
              attrs: { start: 1 },
              content: [
                {
                  type: 'customListItem',
                  content: [
                    {
                      type: 'paragraph',
                      attrs: { textAlign: 'left' },
                      content: [{ type: 'text', text }],
                    },
                  ],
                },
              ],
            },
          ],
        })
      } else {
        const editorContent = editor.getJSON()
        if (editorContent?.content) {
          // DESC: call recursive function to reorder all footnote attrs hrefId
          reorderFootnote(editorContent?.content, 1)
        }
        // DESC: insert footnote list into new order
        editorContent?.content?.forEach((c: JSONContent, index) => {
          if (c.type === 'footnote-ordered-list') {
            c.content?.forEach((ordered: JSONContent) => {
              if (ordered.content) {
                ordered.content.splice(insertIndex, 0, {
                  type: 'customListItem',
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        textAlign: 'left',
                      },
                      content: [
                        {
                          type: 'text',
                          text,
                        },
                      ],
                    },
                  ],
                })
              }
            })
          }
        })
        editor.commands.setContent(editorContent)
        editor.chain().focus('end').run()
      }
    }
  }

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            type='button'
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
              {
                'text-primary': isActive,
              }
            )}
            onClick={e => {
              setText('')
              toggleDropdown(e)
            }}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          type='button'
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
            {
              'text-primary': isActive,
            }
          )}
          onClick={e => {
            setText('')
            toggleDropdown(e)
          }}
        >
          {icon}
        </button>
      )}
      <Dropdown>
        <TiptapDropdownInput
          className={cn({ hidden: !isShowDropdown })}
          title='Footnote'
          onComfirm={handleAddFootnote}
          value={text}
          onChange={setText}
          toggleDropdown={toggleDropdown}
        />
      </Dropdown>
    </div>
  )
}
