import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_PUBLISHER = gql`
  query GetSearchResultByPublisher(
    $dateRange: DatePicker
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByPublisherSort
    $orderDirection: OrderDirection
  ) {
    searchResultByPublisher(
      dateRange: $dateRange
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        userId
        writerId
        publisher
        totalSearch
      }
      page
    }
  }
`
