import React from 'react'
import { useField } from 'formik'
import {
  SelectSearchFieldProps,
  SelectSearchFieldAcceptProps,
} from './interface'

export function withSelectSearchField(
  Component: React.FC<SelectSearchFieldProps>
) {
  function WithSelectSearchField({
    name,
    onChange,
    ...props
  }: SelectSearchFieldAcceptProps) {
    const [field, { touched, error }, { setValue }] = useField(name)

    function handleChange(value: string | number) {
      setValue(value)

      if (onChange) {
        onChange(value)
      }
    }

    const componentProps = {
      ...props,
      ...field,
      touched,
      error,
      onChange: handleChange,
    }

    return <Component {...componentProps} />
  }

  return WithSelectSearchField
}
