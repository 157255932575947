import { Expose } from 'class-transformer'

export class TopWriterByViewType {
  @Expose() userId: number

  @Expose() username: string

  @Expose() userViewCount: number

  @Expose() writerId: number
}
