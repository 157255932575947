import { Container } from '@components/Container'
import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { ReaderList } from './components/ReaderList'
import { StoryReaderPageProps } from './interface'
import { Cover } from './components/Cover'
import { ChapterList } from './components/ChapterList'
import { BookChapterViewer } from './components/BookChapterViewer'
import { PromotionList } from './components/PromotionList'

export function StoryReaderPage({
  data,
  bookId,
  searchParams,
  totalViewer,
  uniqueView,
  handleSearch,
  handleReset,
  handleExport,
}: StoryReaderPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>รายละเอียดงานเขียน</Container.Title>
      <Cover data={data} />
      <div className='flex items-center gap-x-[20px] mt-[20px]'>
        <SearchDateRange
          className='flex-1'
          onSearch={handleSearch}
          onReset={handleReset}
          onExport={handleExport}
        />
      </div>
      <BookChapterViewer totalViewer={totalViewer} uniqueView={uniqueView} />
      {bookId && searchParams && (
        <>
          <ChapterList bookId={bookId} searchParams={searchParams} />
          <PromotionList bookId={bookId} searchParams={searchParams} />
          <ReaderList
            bookId={bookId}
            searchParams={searchParams}
            uniqueView={uniqueView}
          />
        </>
      )}
    </Container>
  )
}
