import { withPermissionGuard } from '@libs/permission'
import { DuplicatePromotionCodePage } from './DuplicatePromotionCodePage'
import { withDuplicatePromotionCodePage } from './withDuplicatePromotionCodePage'

const ConnectedDuplicatePromotionCodePage = withPermissionGuard(
  withDuplicatePromotionCodePage(DuplicatePromotionCodePage),
  'promotionSetting',
  'create'
)

export { ConnectedDuplicatePromotionCodePage as DuplicatePromotionCodePage }
