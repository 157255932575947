import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useNavigate } from 'react-router-dom'
import { PromotionSettingListProps } from './interface'

export function withPromotionSettingList(
  Component: React.FC<PromotionSettingListProps>
) {
  function WithPromotionSettingList({ ...props }: PromotionSettingListProps) {
    return <Component {...props} />
  }

  return WithPromotionSettingList
}
