import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { OrderByEnum, HashtagParam } from '@interfaces/dashboard/HashtagParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { HashtagProps, WithHashtagProps } from './interface'

export function withHashtag(Component: React.FC<HashtagProps>) {
  function WithHashtag({ searchParam }: WithHashtagProps) {
    const client = useClient()
    const [hashtagParam, setHashtagParam] = useState<HashtagParam>({
      orderBy: OrderByEnum.TOTAL_SEARCH,
      orderDirection: SortingType.DESC,
    })

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        ['search-result-by-hashtag', searchParam, hashtagParam],
        async ({ pageParam = 1 }) => {
          const res = await client?.dashboardClient.searchResultByHashtag({
            ...searchParam,
            ...hashtagParam,
            page: pageParam,
          })

          return res
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setHashtagParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      isLoading,
      handleSortChange,
      handleWaypointEnter,
    }

    return <Component {...componentProps} />
  }

  return WithHashtag
}
