import { TextInput } from '@components/TextInput'
import { OptionType } from '@interfaces/OptionType'

function CustomTextInput({
  label,
  placeholder,
  value,
  options,
}: {
  label: string
  placeholder?: string
  value: string
  options?: OptionType[]
}) {
  return (
    <div className='flex flex-col gap-y-[10px]'>
      <div className='text-[12px] font-light mb-[6px] text-secondary-100'>
        {label}
      </div>
      <TextInput
        className='border-gray'
        placeholder={placeholder}
        value={
          options ? options.find(o => o.value === value)?.text || value : value
        }
        disabled
      />
    </div>
  )
}

export { CustomTextInput as TextInput }
