import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Box } from '@components/Box'
import { TextAreaField } from '@components/forms/TextAreaField'
import { Button } from '@components/Button'
import { ReportReplyFormProps } from './interface'

const validationMessageToReporterSchema = Yup.object().shape({
  messageToReporter: Yup.string().required(),
})

const validationMessageToOwnerSchema = Yup.object().shape({
  messageToBookOwner: Yup.string().required(),
})

export function ReportReplyForm({
  fullName,
  initialValueMessageToOwner,
  initialValueMessageToReporter,
  handleUpdateBookReport,
  bookReportId,
  status,
}: ReportReplyFormProps) {
  return (
    <Box>
      <Box.Title className='font-bold text-black-480'>ตอบกลับ</Box.Title>
      <div className='flex flex-row gap-x-[40px]'>
        <Formik
          initialValues={initialValueMessageToReporter}
          onSubmit={form => {
            handleUpdateBookReport({ id: bookReportId, ...form })
          }}
          validationSchema={validationMessageToReporterSchema}
        >
          {({ isValid }) => (
            <Form className='flex flex-col gap-y-[20px] items-end w-full'>
              <TextAreaField
                className='w-full'
                labelClassName='text-secondary'
                textAreaClassName='h-[120px]'
                name='messageToReporter'
                label={`ส่งข้อความถึงผู้ Report : ${fullName}`}
                placeholder='พิมพ์ข้อความ'
                maxLength={200}
                disabled={
                  !!initialValueMessageToReporter.messageToReporter ||
                  status === 'SUCCESS'
                }
              />
              <Button
                type='submit'
                disabled={
                  !isValid ||
                  !!initialValueMessageToReporter.messageToReporter ||
                  status === 'SUCCESS'
                }
                className='bg-secondary text-white w-[120px]'
              >
                ส่งข้อความ
              </Button>
            </Form>
          )}
        </Formik>
        <div className='border-l-[1px] border-gray' />
        <Formik
          initialValues={initialValueMessageToOwner}
          validationSchema={validationMessageToOwnerSchema}
          onSubmit={form => {
            handleUpdateBookReport({ id: bookReportId, ...form })
          }}
        >
          {({ isValid }) => (
            <Form className='flex flex-col gap-y-[20px] items-end w-full'>
              <TextAreaField
                labelClassName='text-secondary'
                className='w-full'
                textAreaClassName='h-[120px]'
                name='messageToBookOwner'
                label='ส่งข้อความถึงเจ้าของงานเขียน'
                placeholder='พิมพ์ข้อความ'
                maxLength={200}
                disabled={
                  !!initialValueMessageToOwner.messageToBookOwner ||
                  status === 'SUCCESS'
                }
              />
              <Button
                type='submit'
                disabled={
                  !isValid ||
                  !!initialValueMessageToOwner.messageToBookOwner ||
                  status === 'SUCCESS'
                }
                className='bg-secondary text-white w-[120px]'
              >
                ส่งข้อความ
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Box>
  )
}
