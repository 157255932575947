import { TextAreaField } from '@components/forms/TextAreaField'

function PromotionChallengeCondition({
  maxConditionLength,
  userTerm,
  viewMode,
}: {
  maxConditionLength: number
  userTerm: string
  viewMode: boolean
}) {
  return (
    <div className='flex flex-col w-full text-secondary gap-y-[10px]'>
      <TextAreaField
        textAreaClassName='h-[150px] placeholder:text-secondary-100 placeholder:font-light'
        name='userTerm'
        label='เงื่อนไขการใช้งาน'
        placeholder='เงื่อนไขการใช้งาน'
        maxLength={maxConditionLength}
        disabled={viewMode}
      />
      <p className='text-xs font-light text-secondary-100'>
        จำนวนตัวอักษรทั้งหมด:{' '}
        <span className='font-bold'>{userTerm?.length.toLocaleString()}</span>
        <span>/{maxConditionLength.toLocaleString()}</span>
      </p>
    </div>
  )
}

export default PromotionChallengeCondition
