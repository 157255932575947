import { gql } from 'graphql-request'

export const BAN_USER = gql`
  mutation BanUser($userId: Int!) {
    banUser(userId: $userId) {
      id
      isBan
    }
  }
`
