import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import cn from 'classnames'
import { Box } from '@components/Box'
import { RadioInputField } from '@components/forms/RadioInputField'
import { SelectField } from '@components/forms/SelectField'
import { OptionType } from '@interfaces/OptionType'
import { CoinOptionType } from '@models/coin/CoinOptionType'
import { Checkbox } from '@components/Checkbox'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { CheckboxField } from '@components/forms/CheckboxField'
import {
  bookTypeOptions,
  readConditionOptions,
  socialMediaChannelsOptions,
} from '@features/promotionSetting/pages/CreatePromotionChallengePage/promotionChallengeOptions'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { NumberWithDecimalField } from '@components/forms/NumberWithDecimalField'
import { Container } from '../../Container'
import SelectCoinTypeField from './SelectCoinTypeField'
import { MultiSelectCategory } from './MultiSelectCategory'

function ChallengeConditionForm({
  actionOptions,
  challengeCoinOptions,
  activeCoinOptions,
  viewMode,
}: {
  actionOptions: OptionType[]
  challengeCoinOptions: CoinOptionType[]
  activeCoinOptions: CoinOptionType[]
  viewMode: boolean
}) {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<PromotionChallengeFormType>()

  const actionCountUnit = useMemo(() => {
    let unit = {
      className: 'w-[89px]',
      rightIcon: '',
      label: 'จำนวน :',
      labelClassName: '!pr-[35px]',
    }
    if (
      [
        PromotionChallengeActionEnum.LOVE,
        PromotionChallengeActionEnum.COMMENT,
        PromotionChallengeActionEnum.SHARE,
      ].includes(values.action)
    ) {
      unit.rightIcon = 'ครั้ง'
    } else if (
      [
        PromotionChallengeActionEnum.UNLOCK_CHAPTER,
        PromotionChallengeActionEnum.DONATE,
      ].includes(values.action)
    ) {
      unit = {
        className: 'w-[177px]',
        rightIcon: 'เหรียญขึ้นไป',
        label: 'จำนวน :',
        labelClassName: '!pr-[85px]',
      }
    } else if (
      [PromotionChallengeActionEnum.SET_PRICE_CHAPTER].includes(values.action)
    ) {
      unit = {
        className: 'w-[200px]',
        rightIcon: 'เหรียญขึ้นไป',
        label: 'ขายตอนใหม่ได้ :',
        labelClassName: '!pr-[85px]',
      }
    } else if (
      [PromotionChallengeActionEnum.CREATE_BOOK].includes(values.action)
    ) {
      unit = {
        className: 'w-[155px]',
        rightIcon: 'เหรียญขึ้นไป',
        label: 'ขายตอนในเรื่องใหม่ได้ :',
        labelClassName: '!pr-[85px]',
      }
    }
    return unit
  }, [values.action])

  return (
    <Box>
      <div className='grid gap-y-[30px]'>
        <h1 className='text-[18px] font-bold text-secondary'>
          เงื่อนไขโปรโมชั่น
        </h1>
      </div>

      <div className='flex gap-x-[40px] my-[30px]'>
        <div
          className={cn('flex gap-x-[40px] items-start', {
            'flex-col gap-y-[30px]':
              values.action === PromotionChallengeActionEnum.CREATE_BOOK,
          })}
        >
          <div className='flex items-center gap-x-[20px]'>
            <span className='text-[14px] text-secondary-100 min-w-fit'>
              Action :
            </span>
            <SelectField
              name='action'
              className='w-[230px]'
              maxHeight={250}
              options={actionOptions}
              placeholder='เลือกประเภท Challenge'
              disabled={viewMode}
            />
            {values.action === PromotionChallengeActionEnum.CREATE_BOOK && (
              <div className='flex items-center gap-x-[40px]'>
                <div className='flex items-center gap-x-[52px]'>
                  <div className='flex items-center'>
                    <span className='text-[14px] text-secondary-100 mr-[10px]'>
                      ประเภทงานเขียน :{' '}
                      {errors.bookTypes && touched.bookTypes && (
                        <span className='text-red-74'>*</span>
                      )}
                    </span>
                    {bookTypeOptions.map(bookOptions => (
                      <Checkbox
                        key={bookOptions.value}
                        name='bookTypes'
                        label={bookOptions.label}
                        checked={values.bookTypes?.includes(bookOptions.value)}
                        className='first-of-type:mr-[52px]'
                        onChange={() => {
                          const bookType = [...values.bookTypes]
                          const bookTypeIndex = bookType.findIndex(
                            t => t === bookOptions.value
                          )
                          if (bookTypeIndex !== -1) {
                            bookType.splice(bookTypeIndex, 1)
                          } else {
                            bookType.push(bookOptions.value)
                          }
                          setFieldValue('bookTypes', bookType)
                          setFieldValue('categoryIds', [])
                        }}
                        disabled={viewMode}
                      />
                    ))}
                  </div>
                </div>
                <div className='flex items-center'>
                  <span className='text-[14px] text-secondary-100 mr-[10px]'>
                    หมวดหมู่ :
                  </span>
                  <MultiSelectCategory
                    name='categoryIds'
                    bookTypes={values.bookTypes || []}
                    className='w-[200px]'
                    disabled={viewMode}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={cn('flex items-start gap-x-[40px]')}>
            {values.action !== PromotionChallengeActionEnum.FINISH_PROFILE && (
              <div className='flex items-center gap-x-[10px]'>
                <span className='text-[14px] text-secondary-100 min-w-fit'>
                  {actionCountUnit.label}{' '}
                  {errors.actionValue && <span className='text-red-74'>*</span>}
                </span>
                <NumberWithCommaField
                  name='actionValue'
                  inputClassName={actionCountUnit.labelClassName}
                  className={cn(
                    'text-secondary-100 text-[14px]',
                    actionCountUnit.className
                  )}
                  showErrorMsg={false}
                  rightIcon={actionCountUnit.rightIcon}
                  disabled={viewMode}
                  maxLength={10}
                />
              </div>
            )}
            {[
              PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
              PromotionChallengeActionEnum.DONATE,
              PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
              PromotionChallengeActionEnum.CREATE_BOOK,
              PromotionChallengeActionEnum.UNLOCK_CHAPTER,
            ].includes(values.action) && (
              <div
                className={cn('flex', {
                  'ml-[93px]': [
                    PromotionChallengeActionEnum.CREATE_BOOK,
                  ].includes(values.action),
                })}
              >
                {errors.coinConditionIds && touched.coinConditionIds && (
                  <span className='text-red-74 mr-[10px] text-sm'>*</span>
                )}
                <div className={cn('flex flex-wrap items-center gap-[20px]')}>
                  {activeCoinOptions.map(coinOption => (
                    <Checkbox
                      key={coinOption.value}
                      name='coinConditionIds'
                      label={`เหรียญ${coinOption.label}`}
                      checked={values.coinConditionIds?.includes(
                        Number(coinOption.value)
                      )}
                      className='min-w-fit'
                      onChange={() => {
                        const coinIds = [...values.coinConditionIds]
                        const coinIdsIndex = coinIds.findIndex(
                          t => t === Number(coinOption.value)
                        )
                        if (coinIdsIndex !== -1) {
                          coinIds.splice(coinIdsIndex, 1)
                        } else {
                          coinIds.push(Number(coinOption.value))
                        }
                        setFieldValue('coinConditionIds', coinIds)
                      }}
                      disabled={viewMode}
                    />
                  ))}
                </div>
              </div>
            )}
            {values.action === PromotionChallengeActionEnum.READ && (
              <div className='flex items-center gap-x-[30px]'>
                {readConditionOptions.map(conditionOptions => (
                  <RadioInputField
                    key={conditionOptions.value}
                    name='readCondition'
                    label={conditionOptions.label}
                    value={conditionOptions.value}
                    labelClassName='text-[12px] text-secondary-100 ml-[5px]'
                    checked={values.readCondition === conditionOptions.value}
                    disabled={viewMode}
                  />
                ))}
              </div>
            )}
            {values.action === PromotionChallengeActionEnum.COMMENT && (
              <div className='flex items-center gap-x-[10px]'>
                <span className='text-[14px] text-secondary-100'>
                  จำนวนตัวอักษร :
                </span>
                <NumberWithCommaField
                  name='wordCount'
                  className='w-[116px] text-secondary-100 text-[14px]'
                  inputClassName='!pr-[63px]'
                  rightIcon='ตัวอักษร'
                  showErrorMsg={false}
                  disabled={viewMode}
                  maxLength={10}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {values.action === PromotionChallengeActionEnum.SHARE && (
        <>
          <Container.Hr />
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex items-center gap-x-[40px]'>
              <div className='flex items-center gap-x-[20px]'>
                <span className='text-[14px] text-secondary-100'>
                  แชร์ไปยัง :{' '}
                  {errors.socialMediaChannels &&
                    touched.socialMediaChannels && (
                      <span className='text-red-74 text-sm'>*</span>
                    )}
                </span>
                {socialMediaChannelsOptions.map(socialOption => (
                  <Checkbox
                    key={socialOption.value}
                    name='socialMediaChannels'
                    label={socialOption.label}
                    checked={values.socialMediaChannels?.includes(
                      socialOption.value
                    )}
                    onChange={() => {
                      const socials = [...values.socialMediaChannels]
                      const socialsIndex = socials.findIndex(
                        t => t === socialOption.value
                      )
                      if (socialsIndex !== -1) {
                        socials.splice(socialsIndex, 1)
                      } else {
                        socials.push(socialOption.value)
                      }
                      setFieldValue('socialMediaChannels', socials)
                    }}
                    disabled={viewMode}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Container.Hr />
      <div className='flex items-center gap-x-[40px]'>
        <div className='flex items-center gap-x-[40px]'>
          <div className='flex items-center gap-x-[20px]'>
            <span className='text-[14px] text-secondary-100'>
              รับเหรียญ :{' '}
              {(errors.benefitValue || errors.benefitCoinId) && (
                <span className='text-red-74'>*</span>
              )}
            </span>
            <NumberWithDecimalField
              name='benefitValue'
              className='w-[140px] text-secondary-100 text-[14px]'
              inputClassName='!pr-[63px]'
              rightIcon='เหรียญ'
              showErrorMsg={false}
              disabled={viewMode}
              maxLength={8}
            />
            <SelectCoinTypeField
              coins={challengeCoinOptions}
              viewMode={viewMode}
            />
          </div>
        </div>
      </div>

      {values.action !== PromotionChallengeActionEnum.FINISH_PROFILE && (
        <>
          <Container.Hr />
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex items-center gap-x-[40px]'>
              <div className='flex items-center gap-x-[20px]'>
                <span className='text-[14px] text-secondary-100'>
                  เก็บเหรียญได้ :
                </span>
                <RadioInputField
                  name='benefitLimitType'
                  label='ครั้งเดียว'
                  value={PromotionChallengeLimitTypeEnum.ONCE}
                  labelClassName='text-[12px] text-secondary-100 ml-[5px]'
                  checked={
                    values.benefitLimitType ===
                    PromotionChallengeLimitTypeEnum.ONCE
                  }
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className='flex items-center gap-x-[40px]'>
              <RadioInputField
                name='benefitLimitType'
                label='ไม่จำกัด'
                value={PromotionChallengeLimitTypeEnum.UNLIMITED}
                labelClassName='text-[12px] text-secondary-100 ml-[5px]'
                checked={
                  values.benefitLimitType ===
                  PromotionChallengeLimitTypeEnum.UNLIMITED
                }
                disabled={viewMode}
              />
            </div>
            <div className='flex items-center gap-x-[40px]'>
              <div className='flex gap-x-[10px]'>
                <RadioInputField
                  checkMarkClassName='mt-[10px]'
                  name='benefitLimitType'
                  label=''
                  value={PromotionChallengeLimitTypeEnum.PER_DAY}
                  checked={
                    values.benefitLimitType ===
                    PromotionChallengeLimitTypeEnum.PER_DAY
                  }
                  disabled={viewMode}
                />
                <NumberWithCommaField
                  name='benefitLimitValue'
                  className='w-[120px] text-secondary-100 text-[14px]'
                  rightIcon='ครั้ง/วัน'
                  inputClassName='!pr-[68px]'
                  disabled={
                    values.benefitLimitType !==
                      PromotionChallengeLimitTypeEnum.PER_DAY || viewMode
                  }
                  showErrorMsg={false}
                  maxLength={10}
                />
              </div>
            </div>
            <div className='flex items-center gap-x-[40px]'>
              <div className='flex gap-x-[10px]'>
                <RadioInputField
                  checkMarkClassName='mt-[10px]'
                  name='benefitLimitType'
                  label=''
                  value={PromotionChallengeLimitTypeEnum.PER_CAMPAIGN}
                  checked={
                    values.benefitLimitType ===
                    PromotionChallengeLimitTypeEnum.PER_CAMPAIGN
                  }
                  disabled={viewMode}
                />
                <NumberWithCommaField
                  name='benefitLimitValue'
                  className='w-[142px] text-secondary-100 text-[14px]'
                  rightIcon='ครั้ง/แคมเปญ'
                  inputClassName='!pr-[90px]'
                  disabled={
                    values.benefitLimitType !==
                      PromotionChallengeLimitTypeEnum.PER_CAMPAIGN || viewMode
                  }
                  showErrorMsg={false}
                  maxLength={10}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Container.Hr />

      <div className='flex items-center gap-x-[40px]'>
        <div className='flex items-center gap-x-[40px]'>
          <div className='flex items-center gap-x-[20px]'>
            <span className='text-[14px] text-secondary-100'>
              งบประมาณ/แคมเปญ :{' '}
              {errors.budget && <span className='text-red-74'>*</span>}
            </span>
            <NumberWithCommaField
              name='budget'
              className='w-[214px] text-secondary-100 text-[14px]'
              rightIcon={`เหรียญ${
                challengeCoinOptions.find(
                  coin => coin.value === Number(values.benefitCoinId)
                )?.label || ''
              }`}
              inputClassName='!pr-[60px]'
              showErrorMsg={false}
              disabled={viewMode}
              maxLength={10}
            />
          </div>
          <div className='flex items-center'>
            <CheckboxField
              type='checkbox'
              name='isLimitBudget'
              label='จำกัดจำนวน'
              checked={values.isLimitBudget}
              className='mr-[24px]'
              disabled={viewMode}
            />
            <NumberWithCommaField
              name='budgetPerDay'
              className='w-[214px] text-secondary-100 text-[14px] mr-[10px]'
              rightIcon={`เหรียญ${
                challengeCoinOptions.find(
                  coin => coin.value === Number(values.benefitCoinId)
                )?.label || ''
              }`}
              inputClassName='!pr-[60px]'
              disabled={!values.isLimitBudget || viewMode}
              showErrorMsg={false}
              maxLength={10}
            />
            <span className='text-[14px] text-secondary-100'>ต่อวัน</span>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default ChallengeConditionForm
