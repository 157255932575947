import { gql } from 'graphql-request'

export const GET_PROMOTION_CODE_FORM = gql`
  query PromotionCode($promotionId: Float!) {
    promotion(id: $promotionId) {
      promotionCode {
        id
        type
        buyCoinConditionValue
        buyCoinCondition
        paymentChannels
        benefitValue
        coin {
          id
          name
        }
        channels {
          name
          totalCount
          totalUsedCount
        }
      }
      id
      name
      description
      userTerm
      startPublishedAt
      endPublishedAt
      publishedType
      userType
      isFirstTopUp
      usageLimitPerUser
      isUsageLimitPerUser
      usageLimit
      isUsageLimit
      usageDay
      status
      users {
        id
      }
      isActive
      isEditable
      usersFileName
      usersFilePath
      createdAt
      updatedAt
      deletedAt
      type
      businessId
      generateCouponStatus
      lastRequest {
        status
        action
      }
    }
  }
`
