import React, { useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'

import {
  PublishActivityModalAcceptProps,
  PublishActivityModalProps,
} from './interface'

export function withPublishActivityModal(
  Component: React.FC<PublishActivityModalProps>
) {
  function WithPublishActivityModal({
    id,
    type,
  }: PublishActivityModalAcceptProps) {
    const client = useClient()
    const {
      data: publishActivityLogs,
      isLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
    } = useInfiniteQuery(
      ['report-publish-logs', id, type],
      ({ pageParam = 1 }) =>
        client!.reportClient.reportPublishLogs({
          id,
          page: pageParam,
          limitPerPage: 10,
          type,
        }),
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.page * 10 < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )
    function handleFetchNextPage() {
      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
    }

    const data = useMemo(
      () => publishActivityLogs?.pages?.flatMap(page => page.data) ?? [],
      [publishActivityLogs]
    )

    const newProps = {
      data,
      isLoading,
      handleFetchNextPage,
    }
    return <Component {...newProps} />
  }
  return WithPublishActivityModal
}
