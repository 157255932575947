import cn from 'classnames'

import { PenIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { BoughtEbookDetailType } from '@models/ebook/BoughtEbookDetailResponse'
import { DateTime } from 'luxon'
import { AnalyticsEbookDetailTableType } from '@models/ebook/AnalyticsEbookDetailTableType'
import {
  SalesEbookPromotionStatusEnum,
  ebookSalesStatusValue,
} from '@interfaces/SalesEbookPromotionStatusEnum'
import { formatDateDomini } from '@libs/utils'
import { ActionCell } from '../ActionCell'

export const buyerColumns: ColumnType<BoughtEbookDetailType>[] = [
  {
    column: 'User ID',
    accessor: 'id',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
  },
  {
    column: 'วันที่ซื้อ',
    accessor: 'createdAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy', {
          locale: 'th-Th',
        })}
      </div>
    ),
    isSortable: true,
  },
  {
    column: 'ยอดซื้อ',
    accessor: 'price',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
  },
  {
    column: 'เรตติ้ง',
    accessor: 'star',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
  },
  {
    column: 'เล่มที่ซื้อในซีรีส์',
    accessor: 'boughtSeriesCount',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell userId={obj.id} />,
    cellClassName: 'flex justify-end py-[10px]',
    isAction: true,
  },
]

export const salesColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value }) => <p>{value}</p>,
  },
  {
    column: 'จำนวนครั้งที่ขาย',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    isSortable: true,
  },
]

export const promotionColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value, obj }) => (
      <div className='grid grid-cols-[77px_1fr]'>
        <img
          className='w-[57px] h-[80px] rounded-lg'
          src={obj.coverImgPath}
          alt='ebook'
        />
        <div className='flex flex-col justify-between h-full'>
          <div className='flex items-center text-secondary-1 text-[12px] text-secondary-100 '>
            <div className='flex mr-[10px] text-[14px] text-secondary '>
              <PenIcon className='w-[18px] h-[18px] shrink-0 mr-[5px]' />
              <span className='max-w-[200px] line-clamp-1 break-all'>
                {obj.penName}
              </span>
            </div>
            {obj.category}
          </div>
          <div className='text-[14px] font-light line-clamp-2 h-[40px] '>
            {value}
          </div>
        </div>
      </div>
    ),
    isSortable: true,
  },
  {
    column: 'รูปแบบโปรโมชัน',
    accessor: 'discountCoin',
    cell: ({ obj }) =>
      obj.rawPrice &&
      obj.discountedPrice && (
        <div>
          ลดราคา {obj.rawPrice - obj.discountedPrice}
          {' เหรียญ'}(
          {100 - Math.round((obj.discountedPrice / obj.rawPrice) * 100)}
          %)
        </div>
      ),
    cellClassName: 'w-[170px] max-w-[170px] px-[5px]',
  },
  {
    column: 'ราคาตั้ง(เหรียญ)',
    accessor: 'rawPrice',
    cell: ({ value }) => <p>{value?.toLocaleString()}</p>,
    isSortable: true,
    cellClassName: 'w-[100px] max-w-[100px] pr-[5px]',
  },
  {
    column: 'ราคาที่ลดแล้ว(เหรียญ)',
    accessor: 'discountedPrice',
    cell: ({ value }) => <p>{value?.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'ระยะเวลาโปรโมชั่น',
    accessor: 'promotionStatus',
    cell: ({ value, obj }) => (
      <div className='grid text-[14px] font-light'>
        <span
          className={cn('font-bold', {
            'text-primary':
              value === SalesEbookPromotionStatusEnum.PROMOTION_PERIOD,
            'text-red':
              value === SalesEbookPromotionStatusEnum.PROMOTION_CLOSED,
            'text-secondary-1':
              value === SalesEbookPromotionStatusEnum.PROMOTION_ENDED,
            'text-green':
              value === SalesEbookPromotionStatusEnum.PROMOTION_NOT_START_YET,
          })}
        >
          {ebookSalesStatusValue[value as SalesEbookPromotionStatusEnum]}
        </span>
        <div className='mt-[10px]'>
          <p>{formatDateDomini(obj.promotionStartedAt, true)} -</p>
          <p>{formatDateDomini(obj.promotionEndedAt, true)}</p>
        </div>
      </div>
    ),
    sortKey: 'promotionStartedAt',
    cellClassName: 'w-[150px] max-w-[150px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'จำนวนที่ขาย(ครั้ง)',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[5px]',
    isSortable: true,
  },
]

export const viewColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value }) => <p>{value}</p>,
  },
  {
    column: 'จำนวน(วิว)',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    isSortable: true,
  },
]

export const readTrialColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value }) => <p>{value}</p>,
  },
  {
    column: 'จำนวนทดลองอ่าน(ครั้ง)',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    isSortable: true,
  },
]

export const ebookWishColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value }) => <p>{value}</p>,
  },
  {
    column: 'จำนวนอีบุ๊กที่อยากได้',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    isSortable: true,
  },
]

export const ebookReviewColumns: ColumnType<AnalyticsEbookDetailTableType>[] = [
  {
    column: 'ชื่อเรื่อง',
    accessor: 'ebookTitle',
    cell: ({ value }) => <p>{value}</p>,
  },
  {
    column: 'จำนวนรีวิว',
    accessor: 'total',
    cell: ({ value }) => <p>{value.toLocaleString() || 0}</p>,
    isSortable: true,
  },
]
