import React, { useState } from 'react'

import { ConfirmPromotionSaleModalProps } from './interface'

export function withConfirmPromotionSaleModal(
  Component: React.FC<ConfirmPromotionSaleModalProps>
) {
  function ConfirmPromotionSaleModal({
    ...props
  }: ConfirmPromotionSaleModalProps) {
    const componentProps = {
      ...props,
    }
    return <Component {...componentProps} />
  }

  return ConfirmPromotionSaleModal
}
