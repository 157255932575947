import { gql } from 'graphql-request'

export const GET_WRITING_LIST = gql`
  query GetWritingLists(
    $writerId: Float
    $penNameId: Float
    $bookStatus: BookStatus
    $page: Int
    $limitPerPage: Int
    $orderBy: writingListOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getWritingLists(
      writerId: $writerId
      penNameId: $penNameId
      bookStatus: $bookStatus
      page: $page
      limitPerPage: $limitPerPage
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      page
      data {
        id
        penName {
          firstPenName
        }
        purchaseCount
        title
        coverImgPath
        viewCount
        chapterCount
        sumCharacterDonate
        totalSales
        bookType
      }
    }
  }
`
