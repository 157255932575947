/* eslint-disable react/button-has-type */
import { Waypoint } from 'react-waypoint'
import cn from 'classnames'

import { CrossIcon, SearchIcon } from '@components/Icons'
import { SearchUserFieldProps } from './interface'

export function SearchUserField({
  text,
  userOption,
  placeholder,
  className,
  inputClassName,
  isShowSearchLog,
  showSearchLog,
  searchRef,
  handleTextChange,
  handleSelectWriter,
  handleFetchNextPage,
  removeTextSearch,
}: SearchUserFieldProps) {
  return (
    <div ref={searchRef} className={cn('w-full', className)}>
      <div className='relative flex items-center w-full'>
        <div className='absolute left-0 ml-[20px]'>
          <SearchIcon className='text-primary' />
        </div>
        <input
          className={cn(
            'flex w-full h-[40px] pl-[50px] pr-[40px] border-gray-border focus:outline-none text-[14px] font-light rounded-[8px] p-[10px]  focus:border-primary focus:text-secondary',
            inputClassName
          )}
          autoComplete='off'
          value={text}
          placeholder={placeholder}
          onFocus={showSearchLog}
          onChange={handleTextChange}
          onKeyDown={(keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
            if (keyEvent.key === 'Enter') {
              keyEvent.preventDefault()
            }
          }}
        />
        <button
          className={cn('absolute right-[15px] text-secondary', {
            hidden: !text,
          })}
          type='reset'
          onClick={removeTextSearch}
        >
          <CrossIcon />
        </button>
        {isShowSearchLog && (
          <div className='absolute w-full z-10 mt-[45px] top-0 bg-white border-1 border-gray rounded-[10px] max-h-[320px] overflow-y-auto shadow'>
            {userOption.map(row => (
              <div
                key={row.id}
                className='flex justify-between items-center px-[15px] py-[10px] cursor-pointer text-black-480 hover:text-primary hover:bg-blue-8'
                onClick={() => handleSelectWriter(row)}
              >
                <div className='flex items-center gap-x-[10px]'>
                  {row.avatarImgPath ? (
                    <img
                      className='w-[40px] h-[40px] rounded-full'
                      src={row.avatarImgPath}
                      alt=''
                    />
                  ) : (
                    <div className='w-[40px] h-[40px] rounded-full bg-gray' />
                  )}
                  <span className='text-[14px] font-light line-clamp-1 break-word'>
                    {row.username}
                  </span>
                </div>
                <div className='text-[14px] text-secondary-100 font-light min-w-[60px] pl-[10px]'>
                  ID : {row.id}
                </div>
              </div>
            ))}
            <Waypoint onEnter={handleFetchNextPage} />
          </div>
        )}
      </div>
    </div>
  )
}
