import { DateTime } from 'luxon'

import { ConsentProps } from './interface'

export function Consent({
  writer,
  consentHistory,
  isPending,
  openTermsOfServiceModal,
}: ConsentProps) {
  return (
    <div className='flex flex-col gap-y-[20px] border-gray border-t pt-[30px] mt-[-20px]'>
      {!!writer
        ? consentHistory?.approvedRequests.map(row =>
            row.writersConsents.map(writersConsent => (
              <div
                key={writersConsent.createdAt}
                className='flex text-[14px] font-light text-secondary'
              >
                <span className='text-secondary-100'>
                  Submitted Date : &nbsp;
                </span>
                {DateTime.fromISO(writersConsent.createdAt).toFormat(
                  'dd LLL yyyy HH:mm:ss',
                  {
                    locale: 'th-Th',
                  }
                )}
                &nbsp;
                <span
                  className='text-primary underline cursor-pointer'
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    openTermsOfServiceModal(
                      writersConsent.consent.id,
                      writersConsent.consent.content,
                      writersConsent.consent.name,
                      writersConsent.consent.version,
                      writersConsent.createdAt,
                      writersConsent.id
                    )
                  }}
                >
                  {writersConsent.consent.name}
                </span>
              </div>
            ))
          )
        : null}

      {isPending &&
        writer &&
        writer.writersConsents.map(row => (
          <div className='flex text-[14px] font-light text-secondary'>
            <span className='text-secondary-100'>Submitted Date : &nbsp;</span>
            {DateTime.fromISO(row.createdAt).toFormat('dd LLL yyyy HH:mm:ss', {
              locale: 'th-Th',
            })}
            &nbsp;
            <span
              className='text-primary underline cursor-pointer'
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                openTermsOfServiceModal(
                  row.consent.id,
                  row.consent.content,
                  row.consent.name,
                  row.consent.version,
                  row.createdAt,
                  row.id
                )
              }}
            >
              {row.consent.name}
            </span>
          </div>
        ))}
    </div>
  )
}
