import * as Yup from 'yup'

export const validationSchema = Yup.object()
  .shape({
    coverImg: Yup.object().shape({
      url: Yup.string().required(),
    }),
    description: Yup.string().required(),
    rawLink: Yup.string().required(),
    endPublishedAt: Yup.date().min(Yup.ref('startPublishedAt')),
  })
  .test((value, context) => {
    if (!value.web && !value.ios && !value.android) {
      return context.createError({
        path: 'web',
        message: 'required',
      })
    }

    return true
  })
