import React from 'react'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'

export function Spoiler({ updateAttributes, node }: any) {
  function handleToggle() {
    updateAttributes({
      toggle: !node.attrs.toggle,
    })
  }

  return (
    <NodeViewWrapper className='spoiler-container'>
      <button
        className='title'
        type='button'
        contentEditable={false}
        onClick={handleToggle}
      >
        {node.attrs.toggle ? 'ซ่อนสปอยล์' : 'แสดงสปอยล์'}
      </button>

      <NodeViewContent className='content' hidden={!node.attrs.toggle} />
    </NodeViewWrapper>
  )
}
