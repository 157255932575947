import { DateTime } from 'luxon'
import { Typography } from '@features/user/components/Typography'
import { UserInformationProps } from './interface'

export function UserInformation({
  avatarImgPath,
  userId,
  fullName,
  username,
  email,
  phoneNumber,
  birthDay,
}: UserInformationProps) {
  return (
    <div className='flex items-center gap-x-[40px]'>
      <div className='flex items-center gap-x-[20px]'>
        {avatarImgPath ? (
          <img
            className='w-[38px] h-[38px] rounded-full object-cover'
            src={avatarImgPath}
            alt='User cover'
          />
        ) : (
          <div className='w-[38px] h-[38px] rounded-full bg-gray' />
        )}
        <Typography label='User ID' text={userId} />
      </div>
      <Typography
        label='ชื่อ - นามสกุล'
        text={fullName}
        className='max-w-[250px]'
      />
      <Typography
        label='ชื่อผู้ใช้'
        text={username}
        className='max-w-[250px]'
      />
      <Typography label='อีเมล' text={email} className='max-w-[250px]' />
      <Typography label='เบอร์โทรศัพท์' text={phoneNumber} />
      <Typography
        label='วันเกิด'
        text={
          birthDay ? DateTime.fromISO(birthDay).toFormat('yyyy/LL/dd') : '-'
        }
      />
    </div>
  )
}
