import cn from 'classnames'
import { useInfiniteQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useDropdown } from '@hooks/useDropdown'
import { useAuthentication } from '@hooks/useAuthentication'
import { Button } from '@components/Button'
import { MenuIcon } from '@components/Icons'
import { useClient } from '@hooks/useClient'
import { SelectChapterProps } from './interface'

export function SelectChapter({ bookId }: SelectChapterProps) {
  const client = useClient()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuthentication()
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const limit = 12

  const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
    ['book', bookId, 'chapters'],
    async ({ pageParam = 1 }) => {
      const res = await client?.chapterClient.bookChapterOptions(
        bookId,
        pageParam,
        limit
      )

      return res
    },
    {
      getNextPageParam: (lastPage: any) => {
        if (lastPage.chapters.length) return lastPage.page + 1

        return undefined
      },
      enabled: isAuthenticated,
    }
  )

  return (
    <Button
      className='bg-blue-2 text-secondary relative'
      type='button'
      onClick={toggleDropdown}
    >
      <div className='flex items-center'>
        <div className='flex h-full items-center space-x-[5px]'>
          <MenuIcon width='24' height='24' />
          <span>เลือกตอน</span>
        </div>

        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute top-[41px] right-[-230px] py-2.5 w-[341px] z-10 bg-white rounded-[10px] shadow'
          >
            <div
              className='flex flex-col p-1 max-h-[380px] overflow-y-auto'
              onScroll={(e: any) => {
                const scrollHeight = e.target.scrollHeight - 10
                const scrollTop = e.target.scrollTop + e.target.offsetHeight
                if (scrollHeight <= scrollTop && !isFetchingNextPage) {
                  fetchNextPage()
                }
              }}
            >
              {data?.pages.map(page =>
                page?.chapters.map(chapter => (
                  <button
                    key={chapter.id}
                    type='button'
                    onClick={() =>
                      navigate(`/preview/${bookId}/chapter/${chapter.id}`)
                    }
                  >
                    <div
                      className={cn(
                        'px-[25px] py-[8px] text-secondary hover:bg-blue-8 hover:text-primary font-sarabun'
                      )}
                    >
                      <span className='line-clamp-1 text-left'>
                        {chapter.chapterTitle} {chapter.chapterName}
                      </span>
                    </div>
                  </button>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </Button>
  )
}
