import { Expose, Type } from 'class-transformer'

class UserType {
  @Expose() id: number

  @Expose() username: string

  @Expose() avatarImgPath: string

  @Expose({ name: 'penNameCount' }) totalPenName: number
}

export class WriterOptionType {
  @Expose() id: number

  @Expose()
  @Type(() => UserType)
  user: UserType
}

export class WriterOptionResponse {
  @Expose() page: number

  @Expose() total: number

  @Expose()
  @Type(() => WriterOptionType)
  data: WriterOptionType[]
}
