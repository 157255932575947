import { Type } from 'class-transformer'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinType } from './CoinType'

class UserType {
  id: number

  avatarImgPath: string

  displayName: string
}

export class CoinRequestType {
  id: number

  @Type(() => CoinType)
  newCoins: CoinType[]

  status: CoinRequestStatusEnum

  createdBy: UserType

  approvedBy: UserType
}
