import React from 'react'
import { DataOverviewProps } from './interface'

function Content({
  label1,
  label2,
  value1,
  value2,
}: {
  label1: string
  label2: string
  value1: number
  value2: number
}) {
  return (
    <div className='grid grid-cols-2'>
      <div className='grid text-[14px] font-light text-secondary-100'>
        {label1}
        <span className='text-[24px] font-bold text-black'>
          {value1.toLocaleString()}
        </span>
      </div>
      <div className='grid text-[14px] font-light text-secondary-100'>
        {label2}
        <span className='text-[24px] font-bold text-black'>
          {value2.toLocaleString()}
        </span>
      </div>
    </div>
  )
}

export function DataOverview({
  salesFormNovel,
  salesFormManga,
  salesCountFormNovel,
  salesCountFormManga,
  totalSaleEbook,
  totalDownload,
  totalNovelEbook,
  totalPublishNovelEbook,
  totalMangaEbook,
  totalPublishMangaEbook,
  totalFreeEbook,
  totalFreeDownloadEbook,
}: DataOverviewProps) {
  return (
    <div className='grid grid-cols-3 gap-x-[93px] gap-y-[38px] my-[30px]'>
      <Content
        label1='รายได้จากอีบุ๊กนิยาย'
        label2='รายได้จากอีบุ๊กการ์ตูน'
        value1={salesFormNovel}
        value2={salesFormManga}
      />
      <Content
        label1='จำนวนที่ขาย (นิยาย)'
        label2='จำนวนที่ขาย  (การ์ตูน)'
        value1={salesCountFormNovel}
        value2={salesCountFormManga}
      />
      <Content
        label1='จำนวนเรื่องที่ขาย(ซื้อ)'
        label2='จำนวนดาวน์โหลด(ซื้อ)'
        value1={totalSaleEbook}
        value2={totalDownload}
      />
      <Content
        label1='อีบุ๊กนิยายทั้งหมด'
        label2='อีบุ๊กนิยายที่เผยแพร่'
        value1={totalNovelEbook}
        value2={totalPublishNovelEbook}
      />
      <Content
        label1='อีบุ๊กการ์ตูนทั้งหมด'
        label2='อีบุ๊กการ์ตูนที่เผยแพร่'
        value1={totalMangaEbook}
        value2={totalPublishMangaEbook}
      />
      <Content
        label1='จำนวนเรื่องที่ขาย(ฟรี)'
        label2='จำนวนดาวน์โหลด(ฟรี)'
        value1={totalFreeEbook}
        value2={totalFreeDownloadEbook}
      />
    </div>
  )
}
