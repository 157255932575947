import { SideMenu } from '@components/layouts/components/SideMenu'
import { Avatar } from '@components/Avatar'
import { NotificationPopup } from '@components/NotificationPopup'
import { AppLayoutProps } from './interface'
import { ExportList } from '../ExportList'

export function AppLayout({ children, user, signOut }: AppLayoutProps) {
  return (
    <div className='bg-white'>
      <div className='px-[15px] h-[60px] flex items-center shadow-navbar sticky top-0 bg-white z-30'>
        <div className='flex-1'>
          <img
            className='w-[125px] h-[40px] my-auto'
            src='/logo.webp'
            alt='logo'
          />
        </div>
        <NotificationPopup className='mr-[20px]' />
        {user.avatarImgPath ? (
          <Avatar
            className='w-[30px] h-[30px] mr-[10px]'
            src={user.avatarImgPath}
            alt='User avatar'
          />
        ) : (
          <div className='mr-[10px] bg-gray rounded-full w-[30px] h-[30px]' />
        )}
        {user.displayName && (
          <div className='text-secondary-100 text-sm font-light mr-[30px]'>
            {user.displayName}
          </div>
        )}
        <div
          className='text-sm font-medium font-mitr underline text-secondary cursor-pointer'
          onClick={signOut}
        >
          ออกจากระบบ
        </div>
      </div>

      <div className='grid grid-cols-[237px_1fr] h-[calc(100vh-60px)]'>
        <SideMenu />
        <div className='max-h-[calc(100vh-60px)] overflow-auto'>{children}</div>
        <ExportList />
      </div>
    </div>
  )
}
