import { PenNameTable } from './components/PenNameTable'
import { PennameSectionProps } from './interface'

export function PennameSection({ writerId, userId }: PennameSectionProps) {
  return (
    <>
      <div className='text-[18px] font-bold font-sarabun text-secondary mb-[14px] mt-[30px]'>
        นามปากกาทั้งหมด
      </div>
      <PenNameTable writerId={writerId} userId={userId} />
    </>
  )
}
