import { gql } from 'graphql-request'

export const GET_HIGHEST_CHARACTER_DONATE = gql`
  query GetHighestCharacterDonate(
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: GetCharacterDonateOrderBy
  ) {
    getHighestCharacterDonate(
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
    ) {
      rowNumber
      characterName
      characterTotalDonate
      username
      bookTitle
      bookId
    }
  }
`
