import { useEffect, useState } from 'react'
import { usePagination } from '@hooks/usePagination'
import { useAuthentication } from '@hooks/useAuthentication'
import { useTable } from '@hooks/useTable'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { SortByType } from '@components/Table/interface'
import { useParams } from 'react-router-dom'
import { SearchParamType } from '@features/user/pages/UserManagementDetailPage/interface'
import { SortingEnum } from '@interfaces/SortingEnum'
import { PenNameListType } from '@models/user/PenNameListType'
import { PenNameTableProps, WithPenNameTableProps } from './interface'
import { columns } from './column'

export function withPenNameTable(Component: React.FC<PenNameTableProps>) {
  function WithPenNameTable({ writerId, userId }: WithPenNameTableProps) {
    const client = useClient()
    const { id } = useParams()
    const { isAuthenticated } = useAuthentication()

    const [searchParam, setSearchParam] = useState<SearchParamType>({
      sortKey: 'id',
      sortOrder: SortingEnum.DESC,
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({})
    const { data: penName, isLoading } = useQuery(
      ['pen-name-list', page, perpage, pageChange, searchParam, id],
      () =>
        client?.userClient.getPenNameList(
          Number(id),
          page,
          perpage,
          searchParam
        ),
      { enabled: isAuthenticated && !!id }
    )

    useEffect(() => {
      handleTotalChange(penName?.total || 0)
    }, [handleTotalChange, penName])

    function handleSort({ key, order }: SortByType) {
      setSearchParam(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }
    const newPenName = penName?.data.map(item => ({
      ...item,
      writerId,
      userId,
    }))

    const { Table } = useTable({
      data: newPenName || [],
      columns,
    })

    const componentProps = {
      data: newPenName as Array<PenNameListType>,
      page,
      perpage,
      total,
      isLoading,
      Table,
      pageChange,
      handleSort,
    }

    return <Component {...componentProps} />
  }

  return WithPenNameTable
}
