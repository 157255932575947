import { ProgressBarOverAllTwoSide } from '@components/ProgressBar'
import { OverAllStoryProps } from './interface'

export function OverAllStory({
  isEnded,
  notEnded,
  isEndedPercent,
  notEndedPercent,
}: OverAllStoryProps) {
  const isEndedLabel = `เรื่องที่จบแล้ว (${isEndedPercent.toFixed(2)}%)`
  const notEndedLabel = `เรื่องที่ยังไม่จบ (${notEndedPercent.toFixed(2)}%)`

  return (
    <ProgressBarOverAllTwoSide
      firstValue={isEnded}
      secondValue={notEnded}
      firstLabel={isEndedLabel}
      secondLabel={notEndedLabel}
    />
  )
}
