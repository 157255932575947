import { DateTime, Duration } from 'luxon'

export function TimeSpentCell({ value }: { value: number }) {
  const dur = Duration.fromObject({ milliseconds: value })
    .shiftTo('day', 'hours', 'minutes', 'seconds')
    .toObject()

  return (
    <div className='flex space-x-[5px]'>
      {dur.days !== 0 && <div>{dur.days} d.</div>}
      {dur.hours !== 0 && <div>{dur.hours} hr.</div>}
      {dur.minutes !== 0 && <div>{dur.minutes} m.</div>}
    </div>
  )
}
