import { Link } from 'react-router-dom'

import { Box } from '@components/Box'
import { EyeIcon } from '@components/Icons'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import { OrderByEnum } from '@interfaces/dashboard/PublisherParam'
import { PublisherProps } from './interface'

export function Publisher({
  data,
  isLoading,
  handleSortChange,
  handleWaypointEnter,
}: PublisherProps) {
  const items = data?.pages.flatMap(row => row?.data) || []
  return (
    <>
      <Box.Title>สำนักพิมพ์</Box.Title>

      <Table
        className='mb-[30px] max-h-[250px] pr-[10px]'
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
        data={items}
        columns={[
          {
            column: 'ค้นหาจากสำนักพิมพ์',
            accessor: 'publisher',
            cell: ({ value }) => (
              <div className='line-clamp-1 break-word'>{value}</div>
            ),
            cellClassName: 'py-[10px] pr-[10px]',
            sortKey: OrderByEnum.PUBLISHER,
            isSortable: true,
          },
          {
            column: 'จำนวนค้นหา',
            accessor: 'totalSearch',
            cell: ({ value }) => (
              <div className='pr-[10px]'>
                {(value as number).toLocaleString()}
              </div>
            ),
            cellClassName: 'py-[10px] pr-[10px] w-[180px]',
            sortKey: OrderByEnum.TOTAL_SEARCH,
            isSortable: true,
          },
          {
            column: 'Action',
            actionCell: ({ obj }) => (
              <Link
                to={`/dashboard/writer-publisher?tab=byUser&writerId=${obj.writerId}&userId=${obj.userId}`}
              >
                <button
                  className='p-[6px] rounded-[8px] border border-blue-2 text-black-970'
                  type='button'
                >
                  <EyeIcon width='18' height='18' />
                </button>
              </Link>
            ),
            isAction: true,
            cellClassName: 'w-[48px] text-right',
          },
        ]}
        initialSort={{
          key: OrderByEnum.TOTAL_SEARCH,
          order: SortingType.DESC,
        }}
        isLoading={isLoading}
        fixedHeader
        onSort={handleSortChange}
        onWaypointEnter={handleWaypointEnter}
      />
    </>
  )
}
