import { Link } from 'react-router-dom'
import { CopyClipboardIcon, EyeIcon } from '@components/Icons'
import { useAlert } from '@hooks/useAlert'
import { CLIENT_URL } from '@configs/config'
import { ActionSelect } from '../ActionSelect'
import { ActionCellProps } from './interface'

export function ActionCell({ id, slug, handleDelete }: ActionCellProps) {
  const alert = useAlert()
  return (
    <div className='flex gap-x-[15px] py-[10px]'>
      <button
        className='bg-green-1 h-[30px] px-[4px] rounded-[8px]'
        type='button'
        onClick={() => {
          navigator.clipboard
            .writeText(`${CLIENT_URL}/news/${slug}`)
            .then(() => {
              alert.success('คัดลอกลิงค์เรียบร้อย')
            })
        }}
      >
        <CopyClipboardIcon className='text-green w-[18px] h-[18px]' />
      </button>
      <Link to={`/news-promotion/${id}`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-secondary-100' width='18' height='18' />
        </button>
      </Link>

      <ActionSelect handleDelete={() => handleDelete(id)} />
    </div>
  )
}
