import { useMemo } from 'react'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import { ChapterProps } from './interface'
import { columns } from './column'

export function Chapters({
  chapterData,
  handleFetchNextPage,
  handleSort,
  isLoading,
}: ChapterProps) {
  const data = useMemo(
    () => chapterData?.pages?.flatMap(page => page.data) ?? [],
    [chapterData]
  )
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        รายละเอียดตอน
      </h1>

      <Table
        className='max-h-[355px] pr-[20px]'
        onSort={handleSort}
        onWaypointEnter={handleFetchNextPage}
        fixedHeader
        data={data}
        isLoading={isLoading}
        columns={columns}
        initialSort={{ key: 'runningNo', order: SortingType.ASC }}
      />
    </div>
  )
}
