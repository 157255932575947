import cn from 'classnames'
import {
  CorrectGreenIcon,
  ErrorIcon,
  GenerateCodeIcon,
} from '@components/Icons'
import { Button } from '@components/Button'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import { useFormikContext } from 'formik'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'
import { UniqeCodeTypeEnum } from '@interfaces/promotionCode/UniqeCodeTypeEnum'
import { Loading } from '@components/Loading'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { GenerateCodeFieldProps } from './interface'

function GenerateCodeField({
  handleGenerate,
  channels,
  channelsInput,
  generateCoupon,
  isLoading,
}: GenerateCodeFieldProps) {
  const { values } = useFormikContext<CreateCodeFormType>()
  const disabled = values.uniqeCodeType !== UniqeCodeTypeEnum.GENERATE_CODE
  return (
    <PromotionRadioInputField
      name='uniqeCodeType'
      className='w-full'
      value={UniqeCodeTypeEnum.GENERATE_CODE}
      checked={values.uniqeCodeType === UniqeCodeTypeEnum.GENERATE_CODE}
    >
      <div
        className={cn(
          'flex  items-center border-2 rounded-lg ml-[44px] px-5 py-[13px]',
          {
            'border-primary': !disabled,
            'border-gray': disabled,
          }
        )}
      >
        <div className='flex items-center space-x-5'>
          <GenerateCodeIcon />
          <div className='text-sm font-light text-secondary !mr-5'>
            สร้างรหัสคูปองอัตโนมัติ
          </div>
          <div className='h-[50px] w-[1px] border-l border-r mx-1' />
          <Button
            type='button'
            onClick={handleGenerate}
            className='bg-primary text-white rounded-lg'
            disabled={
              disabled ||
              isLoading ||
              generateCoupon === PromotionCodeJobStatusEnum.COMPLETED ||
              generateCoupon === PromotionCodeJobStatusEnum.PROCESSING
            }
          >
            <span className='text-sm'>
              {generateCoupon === PromotionCodeJobStatusEnum.FAILED
                ? 'Regenerate Code'
                : 'Generate Code'}
            </span>
          </Button>
        </div>
        <div className='flex ml-[20px]'>
          <div className='flex flex-col'>
            <div className='text-[12px] font-light text-secondary-100'>
              ช่องทาง:
            </div>
            <div className='text-[14px] text-secondary'>
              <span className='pr-[5px]'>{channels}</span>(
              <span className='px-[3px]'>
                {channelsInput ? channelsInput.toLocaleString() : '-'}
              </span>
              )
            </div>
          </div>
        </div>
        <div className='ml-auto flex '>
          {generateCoupon === PromotionCodeJobStatusEnum.COMPLETED && (
            <CorrectGreenIcon width='24' height='24' />
          )}
          {generateCoupon === PromotionCodeJobStatusEnum.PROCESSING && (
            <Loading className='!my-0' size={24} />
          )}
          {generateCoupon === PromotionCodeJobStatusEnum.FAILED && (
            <ErrorIcon width='24' height='24' />
          )}
        </div>
      </div>
    </PromotionRadioInputField>
  )
}

export default GenerateCodeField
