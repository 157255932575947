import React from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { HighlightPosterBannerFormType } from '@models/highlightBanner/HighlightPosterBannerFormType'
import { EditHighlightBannerPosterPageProps } from './interface'

export function withEditHighlightBannerPosterPage(
  Component: React.FC<EditHighlightBannerPosterPageProps>
) {
  function WithEditHighlightBannerPosterPage() {
    const { id } = useParams()
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()
    const { data, refetch } = useQuery(
      ['poster-banner', id],
      () => client?.highlightBannerClient.getPosterBanner(Number(id)),
      { enabled: !!id }
    )

    const { mutate } = useMutation(
      (form: HighlightPosterBannerFormType) =>
        client!.highlightBannerClient.updatePosterBanner(form),
      {
        onSuccess: () => {
          refetch()
          navigate(-1)
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: HighlightPosterBannerFormType) {
      if ((form?.imgPath?.blob?.size || 0) > 10485760) {
        alert.error('File truncated as it exceeds the 10 mb size limit.')
        return
      }

      mutate({
        id: Number(id),
        description: form?.description,
        rawLink: form?.rawLink,
        linkWeb: form?.linkWeb,
        linkIos: form?.linkIos,
        linkAndroid: form?.linkAndroid,
        isOnWeb: form?.isOnWeb,
        isOnIos: form?.isOnIos,
        isOnAndroid: form?.isOnAndroid,
        imgPath: form?.imgPath,
      })
    }

    if (!data) return null

    const componentProps = {
      initialValues: plainToInstance(HighlightPosterBannerFormType, data),
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithEditHighlightBannerPosterPage
}
