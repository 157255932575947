import { useState } from 'react'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { BookEnum } from '@interfaces/BookEnum'
import {
  SearchParamsType,
  StoryTableTemplateAcceptProps,
  StoryTableTemplateProps,
} from './interface'

export function withStoryTableTemplate(
  Component: React.FC<StoryTableTemplateProps>
) {
  function WithStoryTableTemplate({
    categoryChange,
    bookTypeChange,
    ...props
  }: StoryTableTemplateAcceptProps) {
    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      bookType: null,
      category: null,
    })
    const client = useClient()
    const { data: categoryOptions = [] } = useQuery('category-options', () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          ...row,
          value: String(row.value),
          label: String(row.text),
        }))
      )
    )

    function handleBookTypeChange(bookType: BookEnum) {
      bookTypeChange(bookType)
      setSearchParams(prev => ({
        ...prev,
        bookType,
      }))
    }
    function handleCategoryChange(category: number) {
      categoryChange(category)
      setSearchParams(prev => ({
        ...prev,
        category,
      }))
    }

    const componentProps = {
      ...props,
      searchParams,
      categoryOptions,
      handleBookTypeChange,
      handleCategoryChange,
    }

    return <Component {...componentProps} />
  }

  return WithStoryTableTemplate
}
