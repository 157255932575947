import { withPermissionGuard } from '@libs/permission'
import { DuplicatePromotionSalePage } from './DuplicatePromotionSalePage'
import { withDuplicatePromotionSalePage } from './withDuplicatePromotionSalePage'

const ConnectedDuplicatePromotionSalePage = withPermissionGuard(
  withDuplicatePromotionSalePage(DuplicatePromotionSalePage),
  'promotionSetting',
  'create'
)

export { ConnectedDuplicatePromotionSalePage as DuplicatePromotionSalePage }
