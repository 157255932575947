import { gql } from 'graphql-request'

export const GET_BOOK_STORY_SETTING_DETAIL = gql`
  query BookStorySetting($id: Int!) {
    bookStorySetting(id: $id) {
      id
      name
      maxCharacter
    }
  }
`
