import { Container } from '@components/Container'
import { HighlightBannerPosterForm } from '@features/home/components/HighlightBannerPosterForm'
import { CreateHighlightBannerPosterPageProps } from './interface'

export function CreateHighlightBannerPosterPage({
  initialValues,
  onSubmit,
}: CreateHighlightBannerPosterPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between mb-5'>
        <Container.Title showBackBtn>Create Banner</Container.Title>
      </section>

      <HighlightBannerPosterForm
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
