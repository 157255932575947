import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { PDPAProps } from './interface'

export function PDPA({
  page,
  perpage,
  total,
  data,
  columns,
  sorting,
  isLoading,
  onSortingChange,
  pageChange,
}: PDPAProps) {
  return (
    <div className='mt-[40px]'>
      <Table
        data={data || []}
        columns={columns}
        isLoading={isLoading}
        onSort={onSortingChange}
        initialSort={{
          key: sorting.key,
          order: sorting.order,
        }}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
      />
      <div className='mt-[30px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
