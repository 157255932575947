import { gql } from 'graphql-request'

export const GET_TOP_WRITER_BY_VIEW = gql`
  query GetTopWriterByView(
    $orderBy: GetTopWriterByViewOrderedList
    $startDate: DateTime
    $endDate: DateTime
    $limit: Int
  ) {
    getTopWriterByView(
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      userId
      username
      userViewCount
      writerId
    }
  }
`
