import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { SalesByUserResponse } from '@models/salesByUser/SalesByUserResponse'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { SalesByUserDetailType } from '@models/salesByUser/SalesByUserDetailType'
import { SortingType } from '@components/Table/interface'
import { SalesByUserTotalResponse } from '@models/salesByUser/SalesByUserTotalResponse'
import { UserStopSapPaymentResponse } from '@models/salesByUser/UserStopSapPaymentResponse'
import { CoinTypeEnum } from '@interfaces/coin/CoinTypeEnum'
import { GET_ALL_SALES_BY_USER } from './schemas/getAllSalesByUser'
import { GET_TOTAL_SALES_BY_USER } from './schemas/getTotalSalesByUser'
import { GET_ALL_SALES_BY_USER_DETAIL } from './schemas/getAllSalesByUserDetail'
import { GET_USER_STOP_SAP_PAYMENT } from './schemas/getUserStopSapPayment'
import { TOGGLE_IS_STOP_SAP_PAYMENT } from './schemas/toggleIsStopSapPayment'
import { GET_USER_COIN } from './schemas/getUserCoin'
import { EXPORT_SALES_BY_USER } from './schemas/exportSalesByUser'

export class SaleByUserClient {
  constructor(private client: GraphQLClient) {}

  async getAllSalesByUser({
    userType,
    searchText,
    page,
    sortKey,
    sortOrder,
    secondaryCoinId,
  }: {
    userType: UserManageMentSearchEnum
    searchText: string
    page: number
    sortKey: string
    sortOrder: string
    secondaryCoinId: number
  }): Promise<SalesByUserResponse> {
    const { getAllSalesByUser } = await this.client.request(
      GET_ALL_SALES_BY_USER,
      {
        userType,
        searchText,
        page,
        limitPerPage: 20,
        orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
        secondaryCoinId,
      }
    )
    return plainToInstance(SalesByUserResponse, getAllSalesByUser)
  }

  async getTotalSalesByUser(
    searchText: string
  ): Promise<SalesByUserTotalResponse> {
    const { getUserManagementTableCount } = await this.client.request(
      GET_TOTAL_SALES_BY_USER,
      {
        searchText,
      }
    )

    return plainToInstance(
      SalesByUserTotalResponse,
      getUserManagementTableCount
    )
  }

  async getAllSalesByUserDetail({
    userId,
    sortKey,
    sortOrder,
    period,
    startDate,
    endDate,
    page,
    perpage,
  }: {
    userId: number
    sortKey: string
    sortOrder: SortingType
    period: string
    startDate?: DateTime
    endDate?: DateTime
    page: number
    perpage: number
  }): Promise<SalesByUserDetailType> {
    const { getAllSalesByUserDetail } = await this.client.request(
      GET_ALL_SALES_BY_USER_DETAIL,
      {
        userId,
        limitPerPage: perpage,
        page,
        orderBy: sortKey ? { [sortKey]: sortOrder } : {},
        period,
        startDate,
        endDate,
      }
    )

    return plainToInstance(SalesByUserDetailType, getAllSalesByUserDetail)
  }

  async getUserStopSapPayment({
    searchText,
    page,
    sortKey,
    sortOrder,
  }: {
    searchText: string
    page: number
    sortKey: string
    sortOrder: string
  }): Promise<UserStopSapPaymentResponse> {
    const { userStopSapPayment } = await this.client.request(
      GET_USER_STOP_SAP_PAYMENT,
      {
        searchText,
        page,
        limitPerPage: 20,
        orderBy: sortKey
          ? { [sortKey === 'id' ? 'userId' : sortKey]: sortOrder }
          : undefined,
      }
    )
    return plainToInstance(UserStopSapPaymentResponse, userStopSapPayment)
  }

  async toggleIsStopSapPayment(userId: number): Promise<number> {
    const { toggleIsStopSapPayment } = await this.client.request(
      TOGGLE_IS_STOP_SAP_PAYMENT,
      {
        userId,
      }
    )
    return toggleIsStopSapPayment.id
  }

  async getSecondaryCoinId(): Promise<number | undefined> {
    const res: {
      getUserCoin: { id: number; type: CoinTypeEnum; editable: boolean }[]
    } = await this.client.request(GET_USER_COIN)

    return res.getUserCoin.find(
      row => row.type === CoinTypeEnum.FREE && !row.editable
    )?.id
  }

  async exportSalesByUser({
    userId,
    startDate,
    endDate,
  }: {
    userId: number
    startDate?: DateTime
    endDate?: DateTime
  }) {
    // date เป็น UTC มาอยู่แล้ว ไม่ต้องแปลง
    await this.client.request(EXPORT_SALES_BY_USER, {
      userId,
      startDate,
      endDate,
    })
  }
}
