import { withPermissionGuard } from '@libs/permission'
import { AgeConfirmationPage } from './AgeConfirmationPage'
import { withAgeConfirmationPage } from './withAgeConfirmationPage'

const ConnectedAgeConfirmationPage = withPermissionGuard(
  withAgeConfirmationPage(AgeConfirmationPage),
  'user',
  'view'
)
export { ConnectedAgeConfirmationPage as AgeConfirmationPage }
