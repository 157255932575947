import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { PromotionLastRequestType } from './PromotionLastRequestType'

class PromotionSaleType {
  @Expose() id: number

  @Expose() actionValue: number

  @Expose()
  @Transform(({ obj }) => obj.summary.coin.name)
  benefitCoin: string

  @Expose()
  benefitLimitType: PromotionChallengeLimitTypeEnum

  @Expose()
  benefitLimitValue: number

  @Expose()
  benefitValue: number

  @Expose()
  budget: number

  @Expose()
  budgetPerDay?: number

  @Expose()
  categories: {
    id: number
    name: string
  }[]

  @Expose()
  coinsCondition: {
    id: number
    name: string
  }[]

  @Expose() conditionFileName?: string

  @Expose() conditionType: PSConditionTypeEnum

  @Expose() termCount: number

  @Expose() useCoinConditionType: PSUseCoinConditionTypeEnum

  @Expose() summary: {
    totalBook: number
    totalDeal: number
    totalSale: number
    totalSell: number
    userCount: number
  }

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}

export class PromotionSaleDetailType {
  @Expose() id: number

  @Expose() businessId: string

  @Expose() name: string

  @Expose() description: string

  @Expose() userTerm: string

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose() publishedType: PromotionPublishedEnum

  @Expose() status: PromotionStatusEnum

  @Expose() isActive: boolean

  @Expose() isEditable: boolean

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose() deletedAt: string

  @Expose() usageDay: DaySelectEnum[]

  @Expose()
  @Type(() => PromotionSaleType)
  promotionSale: PromotionSaleType

  @Expose()
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}
