/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { useMemo } from 'react'
import { ButtonSelect } from '../ButtonSelect'
import { ActionCellProps } from './interface'

export function ActionCell({
  id,
  isBan,
  canResetPin,
  writingDisabled,
  handleResetUserPassword,
  handleBanUser,
  handleWarningUser,
  handleToggleWritingDisabled,
  handleUnbanUser,
  resetPinCode,
}: ActionCellProps) {
  const permission = usePermissionAction('user')
  const actions = useMemo(
    () => [
      {
        label: 'Reset Password',
        isShow: !isBan,
        disabled: false,
        onClick: () => handleResetUserPassword(id),
      },
      {
        label: 'Reset PIN code',
        isShow: !isBan,
        disabled: !canResetPin,
        onClick: () => resetPinCode(id),
      },
      {
        label: 'ตักเตือนผู้ใช้งาน',
        isShow: !isBan,
        disabled: false,
        onClick: () => handleWarningUser(id),
      },
      {
        label: 'แบนผู้ใช้งานนี้',
        isShow: !isBan,
        disabled: false,
        onClick: () => handleBanUser(id),
      },
      {
        label: writingDisabled ? 'เปิดการสร้างงานเขียน' : 'ปิดการสร้างงานเขียน',
        isShow: !isBan,
        disabled: false,
        onClick: () => handleToggleWritingDisabled(id),
      },
      {
        label: 'ยกเลิกการแบน',
        isShow: isBan,
        disabled: false,
        onClick: () => handleUnbanUser(id),
      },
    ],
    [id, isBan, writingDisabled]
  )

  return (
    <div className='flex gap-x-[20px] py-[6px]'>
      <Link to={`/user/user-management/${id}`}>
        <button
          className='border border-blue-2 h-[30px] px-[5px] rounded-[8px] bg-white'
          type='button'
        >
          <EyeIcon className='text-secondary-100' width='18' height='18' />
        </button>
      </Link>
      {permission.edit && <ButtonSelect actions={actions} />}
    </div>
  )
}
