import { useMemo, useState } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { WithStorySectionProps, StorySecctionProps } from './interface'

export function withStorySection(Component: React.FC<StorySecctionProps>) {
  function WithStorySection({ searchParams }: WithStorySectionProps) {
    const client = useClient()
    const [sortBy, setSortBy] = useState<SortByType>({
      key: '',
      order: SortingType.ASC,
    })

    const {
      data: donationByStory,
      isFetchingNextPage,
      fetchNextPage,
      isLoading,
    } = useInfiniteQuery(
      ['character-donation-by-story', searchParams, sortBy],
      ({ pageParam = 1 }) =>
        client!.characterClient?.getCharacterDonationByStory({
          ...searchParams,
          ...sortBy,
          page: pageParam,
          limitPerPage: 10,
        }),
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleSort({ key, order }: SortByType) {
      setSortBy(prev => ({ ...prev, key, order }))
    }

    const data = useMemo(
      () => donationByStory?.pages?.flatMap(page => page.data) ?? [],
      [donationByStory]
    )

    const componentProps = {
      data,
      handleSort,
      handleFetchNextPage,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithStorySection
}
