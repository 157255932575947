export enum ChallengeStatus {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum ChallengeUnit {
  CHAPTER = 'CHAPTER',
  BOOK = 'BOOK',
  USER = 'USER',
  VIEW = 'VIEW',
  LOVE = 'LOVE',
  COIN = 'COIN',
}

export enum ChallengeUnitValue {
  CHAPTER = 'ตอน',
  BOOK = 'เรื่อง',
  USER = 'คน',
  VIEW = 'วิว',
  LOVE = 'เลิฟ',
  COIN = 'เหรียญ',
}
