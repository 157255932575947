import React from 'react'
import cn from 'classnames'

import { Time2Icon } from '@components/Icons'
import { CharacterCard } from '@components/CharacterCard'
import { formatDateDomini } from '@libs/utils'
import { Box } from '../Box'
import { CharacterProps } from './interface'

export function Character({ bookId, bookName, characters }: CharacterProps) {
  function formatTime(time: string) {
    return `${formatDateDomini(time)}, ${new Date(time).toLocaleTimeString(
      'th-TH',
      {
        hour: 'numeric',
        minute: 'numeric',
      }
    )} น.`
  }

  return (
    <Box className='mt-5'>
      <div className='flex justify-between gap-y-[10px]'>
        <Box.Title className='font-mitr'>ตัวละคร</Box.Title>
      </div>
      <div className='flex gap-x-[20px] py-[20px] overflow-x-auto'>
        {characters.map(item => (
          <CharacterCard
            key={item.id}
            id={String(item.id)}
            bookId={bookId}
            bookName={bookName}
            imageUrl={item.resizeImgPath || item.imgPath}
            rank={item.characterRank.name}
            nextRank={item.characterNextRank?.name}
            story={item.description}
            name={item.name}
            characterRole={item.characterRole.name}
            coin={item.coinsTotal}
            coinLeft={item.coinsToNextLevel}
            characterRankStartCoins={item.characterRank.startCoins}
            characterRankImg={item.characterRank.imgPath}
          >
            <hr className='text-gray mt-[10px] w-full' />
            <div className='flex justify-between items-center h-[42px]'>
              {!item.visible ? (
                <div className='flex text-[14px] font-light items-center text-secondary'>
                  <div
                    className={cn('h-[15px] w-[15px] mr-[8px] rounded-full', {
                      'bg-primary': item.visible,
                      'bg-secondary-100': !item.visible,
                    })}
                  />
                  ไม่เผยแพร่
                </div>
              ) : item.isShowPublishedAt ? (
                <div className='flex text-[14px] items-center space-x-[8px]'>
                  <Time2Icon
                    className='text-primary shrink-0'
                    width='24'
                    height='24'
                  />
                  <div>
                    <p className='font-light text-secondary flex flex-wrap'>
                      <span className='mr-1'>
                        {item.donatable ? 'เผยแพร่และเปิดสนับสนุน' : 'เผยแพร่'}{' '}
                        :
                      </span>
                      <span>{formatTime(item.publishedAt)}</span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className='flex text-[14px] font-light items-center text-secondary'>
                  <div
                    className={cn('h-[15px] w-[15px] rounded-full mr-[8px]', {
                      'bg-primary': item.visible,
                      'bg-secondary-1': !item.visible,
                    })}
                  />
                  {item.donatable ? 'เผยแพร่และเปิดสนับสนุน' : 'เผยแพร่'}
                </div>
              )}
            </div>
          </CharacterCard>
        ))}
      </div>
    </Box>
  )
}
