import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { DateTime } from 'luxon'
import React, { useState } from 'react'

import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { timeRangeValue } from '@interfaces/TimeRangeEnum'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { SearchPromotioChallengeType } from '../PromotionChallengeSearchForm/interface'
import {
  PromotionChallengeTransactionAcceptListProps,
  PromotionChallengeTransactionListProps,
} from './interface'

export function withPromotionChallengeTransactionList(
  Component: React.FC<PromotionChallengeTransactionListProps>
) {
  function Hoc({
    action,
    promotionChallengeId,
  }: PromotionChallengeTransactionAcceptListProps) {
    const queryClient = useQueryClient()
    const { id } = useParams()
    const client = useClient()
    const [searchParams, setSearchParams] =
      useState<SearchPromotioChallengeType>({
        searchText: '',
        selectedDate: KeyTypeEnum.MONTH,
        startDate: null,
        endDate: null,
      })
    const alert = useAlert()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    function handleSubmit(form: SearchPromotioChallengeType) {
      setSearchParams(form)
    }

    async function handleExport() {
      try {
        const { searchText, selectedDate } = searchParams
        let startDate = searchParams.startDate
          ? DateTime.fromJSDate(searchParams.startDate).startOf('day').toUTC()
          : undefined
        let endDate = searchParams.endDate
          ? DateTime.fromJSDate(searchParams.endDate).endOf('day').toUTC()
          : undefined

        if (selectedDate === KeyTypeEnum.DAY) {
          startDate = DateTime.now().startOf('day').toUTC()
          endDate = DateTime.now().endOf('day').toUTC()
        } else if (selectedDate === KeyTypeEnum.MONTH) {
          startDate = DateTime.now()
            .minus({ day: timeRangeValue[KeyTypeEnum.MONTH] })
            .startOf('day')
            .toUTC()
          endDate = DateTime.now().endOf('day').toUTC()
        } else if (selectedDate === KeyTypeEnum.YEAR) {
          startDate = DateTime.now()
            .minus({ day: timeRangeValue[KeyTypeEnum.YEAR] })
            .startOf('day')
            .toUTC()
          endDate = DateTime.now().endOf('day').toUTC()
        }

        if (startDate && endDate && promotionChallengeId && id) {
          await client?.promotionChallengeClient.exportPromotionChallenge({
            promotionId: Number(id),
            promotionChallengeId,
            action,
            startDate,
            endDate,
            searchText,
          })
          await queryClient.refetchQueries('export-jobs')
        }
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      searchParams,
      handleSubmit,
      page,
      perpage,
      total,
      pageChange,
      handleTotalChange,
      handleExport,
      action,
      promotionChallengeId,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
