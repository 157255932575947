import { withPermissionGuard } from '@libs/permission'
import { CoinDetail } from '@components/CoinDetail'
import { withCoinDetail } from './withCoinDetail'

const ConnectedCoinDetail = withPermissionGuard(
  withCoinDetail(CoinDetail),
  'coin',
  'view'
)

export { ConnectedCoinDetail as CoinDetail }
