import { withPermissionGuard } from '@libs/permission'
import { ManageSearchTermPage } from './ManageSearchTermPage'
import { withManageSearchTermPage } from './withManageSearchTermPage'

const ConnectedManageSearchTermPage = withPermissionGuard(
  withManageSearchTermPage(ManageSearchTermPage),
  'searchManagement',
  'view'
)

export { ConnectedManageSearchTermPage as ManageSearchTermPage }
