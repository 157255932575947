import { Expose, Transform } from 'class-transformer'

export class TopWriterType {
  @Expose({ name: 'userId' }) id: string

  @Expose() writerId: string

  @Expose() username: string

  @Expose({ name: 'userTotalIncome' })
  @Transform(({ value }) => (value ? Number(value) : 0))
  totalIncome: number

  @Expose({ name: 'rowNumber' }) numRow: number
}
