import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { InputField } from '@components/forms/InputField'
import { PasswordField } from '@components/forms/PasswordField'
import { FieldDescription } from '@components/FieldDescription'
import { RegisterPageProps } from './interface'

const schema = Yup.object().shape({
  displayName: Yup.string().required('จำเป็น'),
  password: Yup.string()
    .min(8, 'รหัสผ่านต้องมากกว่าหรือเท่ากับ 8 ตัวอักษร')
    .required('จำเป็น')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_.])[A-Za-z\d@$!%*?&_.]{8,}$/,
      'รูปแบบไม่ถูกต้อง'
    ),
  confirmPassword: Yup.string()
    .required('จำเป็น')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_.])[A-Za-z\d@$!%*?&_.]{8,}$/,
      'รูปแบบไม่ถูกต้อง'
    )
    .oneOf([Yup.ref('password'), null], 'กรุณากรอกรหัสผ่านให้ตรงกัน'),
})

export function RegisterPage({
  email,
  displayName,
  acceptAdminHandler,
}: RegisterPageProps) {
  return (
    <div className='w-[340px]'>
      <div className='font-mitr text-[24px] text-center text-black-480 font-medium mt-[40px] mb-[10px]'>
        ตั้งชื่อและรหัสผ่านของคุณ
      </div>
      <div className='font-sarabun text-[14px] text-center text-black-480 font-light mb-[20px]'>
        {email}
      </div>
      <Formik
        initialValues={{
          displayName,
          password: '',
          confirmPassword: '',
        }}
        validationSchema={schema}
        onSubmit={acceptAdminHandler}
      >
        {({ isSubmitting }) => (
          <Form className='flex flex-col gap-y-[20px] mobile:w-full'>
            <InputField name='displayName' placeholder='ชื่อ' />
            <div>
              <PasswordField name='password' placeholder='รหัสผ่าน' />
              <FieldDescription
                text={`ความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์เล็ก (a-z)
            อย่างน้อย 1 ตัว, ตัวอักษรพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว, มีตัวเลข
            และอักขระพิเศษ (@$!%*?&_.)`}
              />
            </div>
            <div>
              <PasswordField
                name='confirmPassword'
                placeholder='ยืนยันรหัสผ่าน'
              />
              <FieldDescription
                text={`ความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์เล็ก (a-z)
            อย่างน้อย 1 ตัว, ตัวอักษรพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว, มีตัวเลข
            และอักขระพิเศษ (@$!%*?&_.)`}
              />
            </div>
            <button
              disabled={isSubmitting}
              type='submit'
              className='font-mitr text-[14px] w-[340px] text-white bg-primary px-[18px] py-[8px] rounded-[20px] mt-[30px]'
            >
              ยืนยัน
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
