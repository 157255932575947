import { Table } from '@components/Table'
import { TopRankTableProps } from './interface'

export function TopRankTable({
  className,
  title,
  data,
  columns,
  handleSort,
  isLoading,
}: TopRankTableProps) {
  return (
    <div className='flex flex-col gap-y-[20px] mt-[30px]'>
      <div className='font-medium font-mitr text-[24px] text-secondary'>
        {title}
      </div>

      <Table
        className={className}
        fixedHeader
        columns={columns}
        data={data}
        onSort={handleSort}
        isLoading={isLoading}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
      />
    </div>
  )
}
