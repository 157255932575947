import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'

import { BASE_URL } from '@configs/config'
import { FileClient } from '@client/collections/File/FileClient'
import { BannerEnum } from '@interfaces/BannerEnum'
import { BookType } from '@models/book/BookType'
import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'
import { HighlightPosterBannerType } from '@models/highlightBanner/HighlightPosterBannerType'
import { HighlightBannerFormType } from '@models/highlightBanner/HighlightBannerFormType'
import { HighlightPosterBannerFormType } from '@models/highlightBanner/HighlightPosterBannerFormType'
import { HighlightBookBannerType } from '@models/highlightBanner/HighlightBookBannerType'
import { HighlightBookBannerFormType } from '@models/highlightBanner/HighlightBookBannerFormType'
import { HighlightBannerPositionEnum } from '@interfaces/mainBanner/HighlightBannerPositionEnum'
import { AdminSearchBookResponse } from '@models/book/AdminSearchBookResponse'
import { BookEnum } from '@interfaces/BookEnum'
import { RecommendTypeSearchEnum } from '@interfaces/RecommendTypeSearchEnum'
import { OrderedEnum } from '@interfaces/OrderedEnum'
import {
  GET_ALL_HIGHLIGH_BANNER,
  CREATE_HIGHLIGHT_GROUP_BANNER,
  UPDATE_HIGHLIGHT_BANNER,
  UPDATE_ORDER_HIGHLIGHT_BANNER,
  UPDATE_HIGHLIGHT_GROUP_BANNER,
  PUBLISH_HIGHLIGHT_BANNER,
  UNPUBLISH_HIGHLIGHT_BANNER,
  DELETE_HIGHLIGHT_BANNER,
} from './schemas/highlighBanner'
import {
  GET_POSTER_BANNER,
  GET_HIGHLIGHT_GROUP_POSTER_BANNER,
  CREATE_POSTER_BANNER,
  UPDATE_POSTER_BANNER,
  DELETE_POSTER_BANNER,
  DELETE_POSTER_BANNERS,
} from './schemas/highlighBannerPoster'
import {
  GET_BOOK_BANNER,
  ADMIN_SEARCH_BOOKS,
  GET_HIGHLIGHT_GROUP_BOOK_BANNER,
  UPDATE_BOOK_BANNER,
  CREATE_GROUP_BOOK_BANNER,
  REMOVE_GROUP_BOOK_BANNER,
  DELETE_BOOK_BANNER,
} from './schemas/highlighBannerBook'

export class HighlightBannerClient {
  constructor(private client: GraphQLClient, private fileClient: FileClient) {}

  async getAllHighlightBanner(
    position: HighlightBannerPositionEnum
  ): Promise<HighlightBannerType[]> {
    const { getAllHighlightBanner } = await this.client.request(
      GET_ALL_HIGHLIGH_BANNER,
      { position }
    )

    return plainToInstance(HighlightBannerType, getAllHighlightBanner as [])
  }

  async getHighlightBanner(
    id: number,
    type: BannerEnum
  ): Promise<HighlightBannerType> {
    if (type === BannerEnum.POSTER) {
      const { getHighlightGroupPoster } = await this.client.request(
        GET_HIGHLIGHT_GROUP_POSTER_BANNER,
        { id }
      )

      return plainToInstance(HighlightBannerType, getHighlightGroupPoster)
    }

    const { getHighlightBookBanner } = await this.client.request(
      GET_HIGHLIGHT_GROUP_BOOK_BANNER,
      { id }
    )

    return plainToInstance(HighlightBannerType, getHighlightBookBanner)
  }

  async updateEnableHighlightBanner(
    id: number,
    isActive: boolean,
    bannerType: BannerEnum
  ): Promise<HighlightBannerType> {
    const { updateHighlightBanner } = await this.client.request(
      UPDATE_HIGHLIGHT_BANNER,
      {
        updateHighlightGroupInput: {
          id,
          isActive,
          forcedUnable: !isActive,
          ...(isActive && { bannerType }),
        },
      }
    )
    return plainToInstance(HighlightBannerType, updateHighlightBanner)
  }

  async updateOrderHighlightBanner(
    runningNo: number,
    updateHighlightGroupRunningNoId: number
  ): Promise<HighlightBannerType[]> {
    const { updateHighlightGroupRunningNo } = await this.client.request(
      UPDATE_ORDER_HIGHLIGHT_BANNER,
      {
        runningNo,
        updateHighlightGroupRunningNoId,
      }
    )

    return plainToInstance(
      HighlightBannerType,
      updateHighlightGroupRunningNo as []
    )
  }

  async deleteHighlightBanner(id: number): Promise<void> {
    await this.client.request(DELETE_HIGHLIGHT_BANNER, {
      removeHighlightBannerId: id,
    })
  }

  async publishHighlightBanner(ids: number[]): Promise<HighlightBannerType[]> {
    const { publishHighlightGroups } = await this.client.request(
      PUBLISH_HIGHLIGHT_BANNER,
      { ids }
    )

    return plainToInstance(HighlightBannerType, publishHighlightGroups as [])
  }

  async unPublishHighlightBanner(
    ids: number[]
  ): Promise<HighlightBannerType[]> {
    const { publishHighlightGroups } = await this.client.request(
      UNPUBLISH_HIGHLIGHT_BANNER,
      { ids }
    )

    return plainToInstance(HighlightBannerType, publishHighlightGroups as [])
  }

  async getHighlightGroupPosterBanner(
    id: number
  ): Promise<HighlightBannerType> {
    const { getHighlightGroupPoster } = await this.client.request(
      GET_HIGHLIGHT_GROUP_POSTER_BANNER,
      { id }
    )

    return plainToInstance(HighlightBannerType, getHighlightGroupPoster)
  }

  async getPosterBanner(id: number): Promise<HighlightPosterBannerType> {
    const { getPosterBanner } = await this.client.request(GET_POSTER_BANNER, {
      getPosterBannerId: id,
    })

    return plainToInstance(HighlightPosterBannerType, getPosterBanner)
  }

  async createHighlightGroupBanner(
    form: HighlightBannerFormType,
    position: HighlightBannerPositionEnum
  ): Promise<HighlightBannerType> {
    const { createHighlightBanner } = await this.client.request(
      CREATE_HIGHLIGHT_GROUP_BANNER,
      {
        createHighlightGroupInput: {
          bannerName: form.bannerName,
          bannerType: form.bannerType,
          publishedFrom: form.publishedFrom,
          publishedTo: form.publishedTo,
          position,
        },
      }
    )

    return plainToInstance(HighlightBannerType, createHighlightBanner)
  }

  async updateHighlightGroupBanner(
    form: HighlightBannerFormType
  ): Promise<HighlightBannerType> {
    const { updateHighlightBanner } = await this.client.request(
      UPDATE_HIGHLIGHT_GROUP_BANNER,
      {
        updateHighlightGroupInput: {
          ...form,
        },
      }
    )

    return plainToInstance(HighlightBannerType, updateHighlightBanner)
  }

  async createPosterBanner(form: HighlightPosterBannerFormType): Promise<void> {
    if (form?.imgPath) {
      const imgPath = await this.fileClient.uploadFile(form.imgPath.blob!)
      const dynamicLink = await this.fileClient.getDynamicLink(
        form?.rawLink || ''
      )

      await this.client.request(CREATE_POSTER_BANNER, {
        createPosterBannerInput: {
          imgPath,
          highlightGroupId: form.highlightGroupId,
          description: form.description,
          isOnWeb: form.isOnWeb,
          isOnIos: form.isOnIos,
          isOnAndroid: form.isOnAndroid,
          rawLink: form.rawLink,
          linkWeb: form.isOnWeb ? form.rawLink : null,
          linkIos: form.isOnIos ? dynamicLink || form.rawLink : null,
          linkAndroid: form.isOnAndroid ? dynamicLink || form.rawLink : null,
          isActive: true,
        },
      })
    }
  }

  async enablePosterBanner(form: HighlightPosterBannerFormType): Promise<void> {
    await this.client.request(UPDATE_POSTER_BANNER, {
      updatePosterBannerInput: {
        ...form,
      },
    })
  }

  async updatePosterBanner(form: HighlightPosterBannerFormType): Promise<void> {
    if (form) {
      let imgPath = form?.imgPath?.url
      const dynamicLink = await this.fileClient.getDynamicLink(
        form.rawLink || ''
      )
      if (form?.imgPath?.blob) {
        imgPath = await this.fileClient.uploadFile(form.imgPath.blob!)
      }

      await this.client.request(UPDATE_POSTER_BANNER, {
        updatePosterBannerInput: {
          id: form.id,
          description: form.description,
          imgPath,
          isOnWeb: form.isOnWeb,
          isOnIos: form.isOnIos,
          isOnAndroid: form.isOnAndroid,
          rawLink: form.rawLink,
          linkWeb: form.isOnWeb ? form.rawLink : null,
          linkIos: form.isOnIos ? dynamicLink || form.rawLink : null,
          linkAndroid: form.isOnAndroid ? dynamicLink || form.rawLink : null,
        },
      })
    }
  }

  async updateOrderPosterBanner(id: number, runningNo: number): Promise<void> {
    await this.client.request(UPDATE_POSTER_BANNER, {
      updatePosterBannerInput: {
        id,
        runningNo,
      },
    })
  }

  async deletePosterBanner(id: number): Promise<void> {
    await this.client.request(DELETE_POSTER_BANNER, {
      removePosterBannerId: id,
    })
  }

  async deletePosterBanners(
    highlightGroupId: number,
    ids: number[]
  ): Promise<void> {
    await this.client.request(DELETE_POSTER_BANNERS, {
      highlightGroupId,
      ids,
    })
  }

  // ### ---------- Highlight Book Banner

  async getBookBanner(): Promise<HighlightBookBannerType[]> {
    const { getBookBanners } = await this.client.request(GET_BOOK_BANNER)

    return plainToInstance(HighlightBookBannerType, getBookBanners as [])
  }

  async adminSearchBooks(params: {
    searchText: string
    bookType: BookEnum | null
    categoryIds: number[]
    secondaryType: RecommendTypeSearchEnum | null
    orderBy: OrderedEnum | null
    isEbook: boolean
  }): Promise<AdminSearchBookResponse> {
    const res = await this.client.request(ADMIN_SEARCH_BOOKS, {
      ...params,
    })

    return plainToInstance(AdminSearchBookResponse, res.adminSearchBooks)
  }

  async getSelectedBookBanner(id: number): Promise<BookType[]> {
    const { getHighlightBookBanner } = await this.client.request(
      GET_HIGHLIGHT_GROUP_BOOK_BANNER,
      { id }
    )

    const books = getHighlightBookBanner.bookBanner.map(
      (res: HighlightBookBannerType) => ({
        ...res.book,
        bannerId: res.id,
      })
    )

    return plainToInstance(BookType, books as [])
  }

  async getHighlightGroupBookBanner(id: number): Promise<HighlightBannerType> {
    const { getHighlightBookBanner } = await this.client.request(
      GET_HIGHLIGHT_GROUP_BOOK_BANNER,
      { id }
    )

    return plainToInstance(HighlightBannerType, getHighlightBookBanner)
  }

  async createGroupBookBanner(highlightGroupId: number, bookIds: number[]) {
    const url = (bookId: number) => `${BASE_URL}/story/${bookId}`

    await this.client.request(CREATE_GROUP_BOOK_BANNER, {
      createBookBannersInput: {
        highlightGroupId,
        bookBanners: bookIds.map(bookId => ({
          bookId,
          linkWeb: url(bookId),
          linkIos: url(bookId),
          linkAndroid: url(bookId),
          isOnWeb: true,
          isOnIos: true,
          isOnAndroid: true,
          isActive: true,
        })),
      },
    })
  }

  async updateBookBanner(form: HighlightBookBannerFormType): Promise<void> {
    await this.client.request(UPDATE_BOOK_BANNER, {
      updateBookBannerInput: form,
    })
  }

  async updateActiveBookBanner(form: {
    id: number
    highlightGroupId: number
    isActive: boolean
  }): Promise<void> {
    await this.client.request(UPDATE_BOOK_BANNER, {
      updateBookBannerInput: form,
    })
  }

  async updateOrderBookBanner(id: number, runningNo: number): Promise<void> {
    await this.client.request(UPDATE_BOOK_BANNER, {
      updateBookBannerInput: {
        id,
        runningNo,
      },
    })
  }

  async removeBookBanner(id: number): Promise<void> {
    await this.client.request(DELETE_BOOK_BANNER, {
      removeBookBannerId: id,
    })
  }

  async removeBookBanners(
    highlightGroupId: number,
    ids: number[]
  ): Promise<void> {
    await this.client.request(REMOVE_GROUP_BOOK_BANNER, {
      highlightGroupId,
      ids,
    })
  }
}
