import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { BookStorySettingFormType } from '@models/bookStorySetting/BookStorySettingFormType'
import { BookStorySettingType } from '@models/bookStorySetting/BookStorySettingType'
import { GET_BOOK_STORY_SETTINGS } from './schemas/bookStorySettings'
import { CREATE_BOOK_STORY_SETTING } from './schemas/createBookStorySetting'
import { GET_BOOK_STORY_SETTING_DETAIL } from './schemas/bookStorySettingDetail'
import { UPDATE_BOOK_STORY_SETTING } from './schemas/updateBookStorySetting'
import { REMOVE_BOOK_STORY_SETTING } from './schemas/removeBookStorySetting'

export class BookStorySettingClient {
  constructor(private client: GraphQLClient) {}

  async getBookStorySettings(): Promise<BookStorySettingType[]> {
    const { bookStorySettings } = await this.client.request(
      GET_BOOK_STORY_SETTINGS
    )
    return plainToInstance(BookStorySettingType, <any[]>bookStorySettings)
  }

  async createBookStorySetting(form: BookStorySettingFormType): Promise<void> {
    await this.client.request(CREATE_BOOK_STORY_SETTING, {
      createBookStorySettingInput: form,
    })
  }

  async getBookStorySettingDetail(
    id: number
  ): Promise<BookStorySettingFormType> {
    const { bookStorySetting } = await this.client.request(
      GET_BOOK_STORY_SETTING_DETAIL,
      { id }
    )
    return plainToInstance(BookStorySettingFormType, bookStorySetting)
  }

  async updateBookStorySetting(
    form: BookStorySettingFormType
  ): Promise<BookStorySettingFormType> {
    const { updateBookStorySetting } = await this.client.request(
      UPDATE_BOOK_STORY_SETTING,
      {
        updateBookStorySettingInput: form,
      }
    )

    return plainToInstance(BookStorySettingFormType, updateBookStorySetting)
  }

  async removeBookStorySetting(id: number): Promise<void> {
    await this.client.request(REMOVE_BOOK_STORY_SETTING, {
      id,
    })
  }

  async updateAndRemoveBookStorySetting(
    form: BookStorySettingType[]
  ): Promise<void> {
    const promises = form.map(item =>
      item.isDeleted && item.id
        ? this.removeBookStorySetting(item.id)
        : this.updateBookStorySetting({
            id: item.id,
            name: item.name,
            maxCharacter: item.maxCharacter,
          })
    )

    await Promise.all(promises)
  }
}
