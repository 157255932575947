import React from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { HighlightPosterBannerFormType } from '@models/highlightBanner/HighlightPosterBannerFormType'
import { CreateHighlightBannerPosterPageProps } from './interface'

export function withCreateHighlightBannerPosterPage(
  Component: React.FC<CreateHighlightBannerPosterPageProps>
) {
  function WithCreateHighlightBannerPosterPage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const { id, position } = useParams()

    const initialValues = plainToInstance(HighlightPosterBannerFormType, {
      imgPath: '',
      description: '',
      isOnWeb: false,
      isOnIos: false,
      isOnAndroid: false,
      rawLink: '',
      linkWeb: '',
      linkIos: '',
      linkAndroid: '',
      isActive: false,
    })

    const { mutate } = useMutation(
      (form: HighlightPosterBannerFormType) =>
        client!.highlightBannerClient.createPosterBanner(form),
      {
        onSuccess: () => {
          navigate(`/home-page/highlight-banner/${position}/poster/${id}`)
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: HighlightPosterBannerFormType) {
      if ((form?.imgPath?.blob?.size || 0) > 10485760) {
        alert.error('File truncated as it exceeds the 10 mb size limit.')
        return
      }

      mutate({ ...form, highlightGroupId: Number(id) })
    }

    const componentProps = { initialValues, onSubmit }

    return <Component {...componentProps} />
  }

  return WithCreateHighlightBannerPosterPage
}
