import { useFormikContext } from 'formik'
import { DateTime } from 'luxon'

import { Box } from '@components/Box'
import { InputField } from '@components/forms/InputField'
import { Checkbox } from '@components/Checkbox'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { diffDate } from '@libs/utils'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import PromotionChallengeCondition from './PromotionChallengeCondition'

function ChallengeForm({
  viewMode,
  title = ' รายละเอียด Challenge',
}: {
  viewMode: boolean
  title?: string
}) {
  const { values, setFieldValue, setValues } =
    useFormikContext<PromotionChallengeFormType>()

  const currentDate = new Date()
  const responseEndDiffCurrentDate = diffDate(
    values.endPublishedAt,
    currentDate
  )
  const responseStartDiffCurrentDate = diffDate(
    values.startPublishedAt,
    currentDate
  )
  const responseStartDiffEndDate = diffDate(
    values.startPublishedAt,
    values.endPublishedAt
  )
  const responseEndDiffStartDate = diffDate(
    values.endPublishedAt,
    values.startPublishedAt
  )

  return (
    <Box>
      <div className='grid gap-y-[30px]'>
        <h1 className='text-[18px] font-bold text-secondary'>{title}</h1>
        <div className='flex-cols'>
          <InputField
            name='name'
            className='w-full mb-[30px]'
            label='ชื่อโปรโมชั่น'
            placeholder='ชื่อโปรโมชั่น'
            disabled={viewMode}
            showErrorMsg={false}
            maxLength={100}
          />
          <InputField
            name='description'
            className='w-full mb-[30px]'
            label='รายละเอียด'
            placeholder='รายละเอียด'
            disabled={viewMode}
            showErrorMsg={false}
            maxLength={200}
          />
          <PromotionChallengeCondition
            maxConditionLength={5000}
            userTerm={values.userTerm}
            viewMode={viewMode}
          />
        </div>
        <div className='flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap'>
          <div className='text-[12px]'>เผยแพร่ :</div>
          <Checkbox
            name='publishedType'
            label='ทันที'
            type='radio'
            value={PromotionPublishedEnum.NOW}
            checked={values.publishedType === PromotionPublishedEnum.NOW}
            onChange={() => {
              setValues({
                ...values,
                publishedType: PromotionPublishedEnum.NOW,
                startPublishedAt: new Date(),
                endPublishedAt: DateTime.now().plus({ month: 1 }).toJSDate(),
              })
            }}
            disabled={viewMode}
          />
          <div className='text-[14px]'>ถึง</div>
          <DateTimeInputField
            className='w-[186px] mr-[29px]'
            name='endPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType === PromotionPublishedEnum.PERIOD || viewMode
            }
            minDate={currentDate}
            minTime={
              responseEndDiffCurrentDate === 0
                ? currentDate
                : responseEndDiffCurrentDate < 0
                ? values.endPublishedAt
                : undefined
            }
            maxTime={
              responseEndDiffCurrentDate === 0
                ? new Date(new Date().setHours(23, 59, 59, 999))
                : responseEndDiffCurrentDate < 0
                ? values.endPublishedAt
                : undefined
            }
            onChange={value => {
              if (value) {
                const endPublishedAt =
                  value < values.startPublishedAt
                    ? values.startPublishedAt
                    : value
                setFieldValue('endPublishedAt', endPublishedAt)
              }
            }}
          />
          <Checkbox
            name='publishedType'
            label='ระยะเวลาแคมเปญ'
            type='radio'
            value={PromotionPublishedEnum.PERIOD}
            checked={values.publishedType === PromotionPublishedEnum.PERIOD}
            onChange={() => {
              setFieldValue('publishedType', PromotionPublishedEnum.PERIOD)
            }}
            disabled={viewMode}
          />
          <DateTimeInputField
            className='w-[186px]'
            name='startPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType === PromotionPublishedEnum.NOW || viewMode
            }
            minDate={currentDate}
            maxDate={values?.endPublishedAt}
            minTime={
              responseStartDiffCurrentDate < 0
                ? values.startPublishedAt
                : responseStartDiffCurrentDate === 0
                ? currentDate
                : responseStartDiffEndDate === 0
                ? new Date(new Date().setHours(0, 0, 0, 0))
                : undefined
            }
            maxTime={
              responseStartDiffCurrentDate < 0
                ? values.startPublishedAt
                : responseStartDiffEndDate === 0
                ? new Date(new Date(values.endPublishedAt))
                : responseStartDiffCurrentDate === 0
                ? new Date(new Date().setHours(23, 59, 59, 999))
                : undefined
            }
            onChange={value => {
              if (value) {
                const startPublishedAt =
                  value < currentDate
                    ? currentDate
                    : value < values.endPublishedAt
                    ? value
                    : values.endPublishedAt
                setFieldValue('startPublishedAt', startPublishedAt)
              }
            }}
          />
          <div className='text-[14px]'>ถึง</div>
          <DateTimeInputField
            className='w-[186px]'
            name='endPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType === PromotionPublishedEnum.NOW || viewMode
            }
            minDate={
              values.startPublishedAt < currentDate
                ? currentDate
                : values.startPublishedAt
            }
            minTime={
              responseEndDiffStartDate <= 0
                ? values.startPublishedAt
                : undefined
            }
            maxTime={
              responseEndDiffStartDate <= 0
                ? new Date(
                    new Date(values.startPublishedAt).setHours(23, 59, 59, 999)
                  )
                : undefined
            }
            onChange={value => {
              if (value) {
                const endPublishedAt =
                  value < values.startPublishedAt
                    ? values.startPublishedAt
                    : value
                setFieldValue('endPublishedAt', endPublishedAt)
              }
            }}
          />
        </div>
      </div>
    </Box>
  )
}

export default ChallengeForm
