import { TopPageViewAcceptProps, TopPageViewProps } from './interface'

export function withTopPageView(Component: React.FC<TopPageViewProps>) {
  function WithTopPageView({ topPageView, tabId }: TopPageViewAcceptProps) {
    const componentProps = {
      data: topPageView,
      tabId,
    }

    return <Component {...componentProps} />
  }

  return WithTopPageView
}
