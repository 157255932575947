import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'
import { DateTime } from 'luxon'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { SalesByUserDetailPeriodEnum } from '@interfaces/SalesByUserDetailPeriodEnum'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { SalesByUserDetailPageProps } from './interface'

const defaultSort = {
  key: 'date',
  order: SortingType.DESC,
}

export function withSalesByUserDetailPage(
  Component: React.FC<SalesByUserDetailPageProps>
) {
  function WithSalesByUserDetailPage() {
    const { id } = useParams()
    const client = useClient()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const { page, perpage, pageChange } = usePagination({})
    const alert = useAlert()

    const [searchParams, setSearchParams] = useState<{
      userId: number
      sortKey: string
      sortOrder: SortingType
      period: string
      startDate?: DateTime
      endDate?: DateTime
    }>({
      userId: Number(id),
      sortKey: defaultSort.key,
      sortOrder: defaultSort.order,
      period: SalesByUserDetailPeriodEnum.TOTAL,
    })

    const queryKey = ['sales-by-user-detail', searchParams, page]

    const { data, isLoading } = useQuery(
      queryKey,
      () =>
        client?.saleByUserClient.getAllSalesByUserDetail({
          perpage,
          page,
          ...searchParams,
        }),
      { enabled: isAuthenticated && !!id }
    )

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function setValue(value: string) {
      pageChange(1)

      let result: {
        period: SalesByUserDetailPeriodEnum
        startDate?: DateTime
        endDate?: DateTime
      }
      if (value === 'current') {
        result = {
          period: SalesByUserDetailPeriodEnum.DATE_RANGE,
          startDate: DateTime.fromJSDate(new Date()).startOf('month').toUTC(),
          endDate: DateTime.fromJSDate(new Date()).endOf('day').toUTC(),
        }
      } else if (value === 'total') {
        result = {
          period: SalesByUserDetailPeriodEnum.TOTAL,
          startDate: undefined,
          endDate: undefined,
        }
      } else {
        result = {
          period: SalesByUserDetailPeriodEnum.DATE_RANGE,
          startDate: DateTime.fromJSDate(new Date(value))
            .startOf('month')
            .toUTC(),
          endDate: DateTime.fromJSDate(new Date(value)).endOf('month').toUTC(),
        }
      }
      setSearchParams(prev => ({ ...prev, ...result }))
    }

    async function handleExport() {
      try {
        await client?.saleByUserClient.exportSalesByUser({
          userId: searchParams.userId,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      data,
      initialSort: { key: defaultSort.key, order: defaultSort.order },
      isLoading,
      page,
      perpage,
      total: data?.detail.total || 0,
      pageChange,
      handleSort,
      setValue,
      handleExport,
    }

    return <Component {...newProps} />
  }
  return WithSalesByUserDetailPage
}
