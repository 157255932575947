import * as Yup from 'yup'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('จำเป็น'),
  description: Yup.string().required('จำเป็น'),
  userTerm: Yup.string().required('จำเป็น'),
  startPublishedAt: Yup.date().when('publishedType', {
    is: (publishedType: PromotionPublishedEnum) =>
      publishedType === PromotionPublishedEnum.PERIOD,
    then: Yup.date().required(),
  }),
  endPublishedAt: Yup.date().required(),
  action: Yup.string().required('จำเป็น'),
  actionValue: Yup.number().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      action !== PromotionChallengeActionEnum.FINISH_PROFILE,
    then: Yup.number().min(1, ' ').required('จำเป็น'),
  }),
  wordCount: Yup.number().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      action === PromotionChallengeActionEnum.COMMENT,
    then: Yup.number().min(1, ' ').required('จำเป็น'),
  }),
  socialMediaChannels: Yup.array().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      action === PromotionChallengeActionEnum.SHARE,
    then: Yup.array().min(1).required(),
  }),
  coinConditionIds: Yup.array().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      [
        PromotionChallengeActionEnum.DONATE,
        PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
        PromotionChallengeActionEnum.CREATE_BOOK,
        PromotionChallengeActionEnum.UNLOCK_CHAPTER,
      ].includes(action),
    then: Yup.array().min(1).required(),
  }),
  bookTypes: Yup.array().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      action === PromotionChallengeActionEnum.CREATE_BOOK,
    then: Yup.array().min(1).required(),
  }),
  categoryIds: Yup.array().when('action', {
    is: (action: PromotionChallengeActionEnum) =>
      action === PromotionChallengeActionEnum.CREATE_BOOK,
    then: Yup.array().min(1).required(),
  }),
  benefitValue: Yup.number()
    .min(0.1, ' ')
    .required('จำเป็น')
    .max(Yup.ref('budget')),
  benefitCoinId: Yup.number().required('จำเป็น'),
  benefitLimitType: Yup.string().required('จำเป็น'),
  benefitLimitValue: Yup.number().when('benefitLimitType', {
    is: (benefitLimitType: PromotionChallengeLimitTypeEnum) =>
      [
        PromotionChallengeLimitTypeEnum.PER_DAY,
        PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
      ].includes(benefitLimitType),
    then: Yup.number().min(0.1, ' ').required(),
  }),
  budget: Yup.number().min(0.1, ' ').required(),
  isLimitBudget: Yup.boolean(),
  budgetPerDay: Yup.number().when('isLimitBudget', {
    is: (isLimitBudget: boolean) => isLimitBudget,
    then: Yup.number().min(0.1, ' ').required().max(Yup.ref('budget')),
  }),
})
