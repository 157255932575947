import { Box } from '@components/Box'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { ReportSummaryDetail } from './components/ReportSummaryDetail'
import { SalesReportSearchForm } from './components/SalesReportSearchForm'
import { PurchaseProportion } from './components/PurchaseProportion'
import { MoneySpenderTable } from './components/MoneySpenderTable'
import { TopBookTable } from './components/TopBookTable'
import { TopWriterTable } from './components/TopWriterTable'
import { TopCharacterTable } from './components/TopCharacterTable'
import { SalesReportPageProps } from './interface'

export function SalesReportPage({
  data,
  searchParam,
  handleSubmit,
  handleExport,
}: SalesReportPageProps) {
  return (
    <>
      <div className='w-full h-[1px] mt-[30px] mb-[20px] bg-gray' />
      <SalesReportSearchForm
        initialValues={{
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <Box>
        <ReportSummaryDetail
          downloadTotal={data.downloadTotal}
          remainCoin={data.remainCoin}
          salesSystem={data.salesSystem}
          donateSystem={data.donateSystem}
          shareSystem={data.shareSystem}
          activeUser={data.activeUser}
          salesTotal={data.salesTotal}
          amountTotal={data.amountTotal}
          donateTotal={data.donateTotal}
        />
        <PurchaseProportion data={data.purchaseProportion} />
        <MoneySpenderTable searchParam={searchParam} />
        <TopBookTable searchParam={searchParam} />
        <TopWriterTable searchParam={searchParam} />
        <TopCharacterTable searchParam={searchParam} />
      </Box>
    </>
  )
}
