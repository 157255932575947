import { InfiniteData } from 'react-query'

import { SortByType } from '@components/Table/interface'
import {
  TopHashtagResponseClick,
  TopHashtagResponseUsed,
} from '@models/dashboard/TopHashtag'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export enum TopHashtagType {
  USE = 'USE',
  CLICK = 'CLICK',
}

export interface WithTopHashtagProps {
  type: TopHashtagType
  searchParam: DonationQueryParam
}

export interface TopHashtagProps {
  data?: InfiniteData<
    TopHashtagResponseClick | TopHashtagResponseUsed | undefined
  >
  isLoading: boolean
  type: TopHashtagType
  handleSortChange: (sort: SortByType) => void
  handleWaypointEnter: () => void
}
