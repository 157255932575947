import { gql } from 'graphql-request'

export const USER_REMAIN_COINS = gql`
  query UserRemainCoins(
    $limitPerPage: Int = 10
    $page: Int = 1
    $orderBy: CoinRemainOrderBy
    $searchText: String
    $paidCoinId: Int!
    $secondaryCoinId: Int
    $activityCoinId: Int
  ) {
    userRemainCoins(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      searchText: $searchText
      paidCoinId: $paidCoinId
      secondaryCoinId: $secondaryCoinId
      activityCoinId: $activityCoinId
    ) {
      data {
        userId
        username
        paidCoin
        secondaryCoin
        activityCoin
      }
      total
      page
    }
  }
`
