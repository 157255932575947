import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { PromotionDetail } from '@features/promotionSetting/components/PromotionCodeForm/components/PromotionDetail'
import { PromotionPublishing } from '@features/promotionSetting/components/PromotionCodeForm/components/PromotionPublishing'
import { PromotionCondition } from '@features/promotionSetting/components/PromotionCodeForm/components/PromotionCondition'
import { Button } from '@components/Button'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { OptionType } from '@interfaces/OptionType'
import { CoinOptionType } from '@models/promotion/CoinOptionType'

interface PromotionCodeViewFormProps {
  data: PromotionCodeFormType
  promotionChannelOption: OptionType[]
  coinOptions: CoinOptionType[]
  handleApprove: () => void
  handleReject: () => void
}
export function PromotionCodeViewForm({
  data,
  promotionChannelOption,
  coinOptions,
  handleApprove,
  handleReject,
}: PromotionCodeViewFormProps) {
  const navigate = useNavigate()
  return (
    <Formik initialValues={data} onSubmit={() => {}}>
      {({ values }) => (
        <Form>
          <div className='flex flex-col gap-y-[20px]'>
            <PromotionDetail viewMode />
            <PromotionPublishing
              viewMode
              promotionChannelOption={promotionChannelOption}
            />
            <PromotionCondition coinOptions={coinOptions} viewMode />
            <div className='flex justify-between gap-x-[20px] mt-[10px]'>
              <Button
                onClick={() =>
                  navigate(
                    `/promotion-setting/promotion-code/${data.id}/history`
                  )
                }
                type='button'
                className='border border-secondary rounded-[20px]'
              >
                <span className='font-mitr font-medium text-sm'>
                  ดูประวัติการแก้ไขโปรโมชั่น
                </span>
              </Button>
              <div className='flex gap-x-[20px]'>
                <Button
                  type='button'
                  onClick={handleReject}
                  className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
                >
                  <span>Reject</span>
                </Button>
                <Button
                  type='button'
                  onClick={handleApprove}
                  className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
                >
                  <span>Approve</span>
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
