import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { SearchPopupBannerResponse } from '@models/popupBanner/SearchPopupBannerResponse'
import { client as appClient } from '@client/init'
import { PopupBannerFormType } from '@models/popupBanner/PopupBannerFormType'
import { SortingType } from '@components/Table/interface'
import { PopupBannerType } from '@models/popupBanner/PopupBannerType'
import { PopupBannerLogResponse } from '@models/popupBanner/PopupBannerLogResponse'
import {
  PopupActionEnum,
  PopupScreenEnum,
  PopupStatusEnum,
} from '@features/home/pages/PopupBannerManagementPage/interface'
import { PopupBannerListResponse } from '@models/popupBanner/PopupBannerListResponse'
import { PopupBannerStatusEnum } from '@interfaces/PopupBannerStatusEnum'
import { CREATE_POPUP_BANNER } from './schemas/createPopupBanner'
import { SEARCH_POPUP_BANNER } from './schemas/searchPopupBanner'
import { POPUP_BANNER } from './schemas/popupBanner'
import { UPDATE_POPUP_BANNER } from './schemas/updatePopupBanner'
import { POPUP_BANNER_LOGS } from './schemas/popupBannerLogs'
import { REMOVE_POPUP_BANNER } from './schemas/removePopupBanner'
import { BULK_UPDATE_POPUP_BANNER } from './schemas/bulkUpdatePopupBanner'
import { HANDLE_POPUP_BANNER } from './schemas/handlePopupBanner'
import { POPUP_BANNERS } from './schemas/popupBanners'
import { EXPORT_POPUP_BANNERS } from './schemas/exportPopupBanners'

export class PopupBannerClient {
  constructor(private client: GraphQLClient) {}

  async getPopupBanner(id: number): Promise<PopupBannerType> {
    const { popupBanner } = await this.client.request(POPUP_BANNER, {
      id,
    })

    return plainToInstance(PopupBannerType, popupBanner)
  }

  async getPopupBannerLogs({
    limitPerPage,
    page,
    popupBannerId,
  }: {
    limitPerPage: number
    page: number
    popupBannerId: number
  }): Promise<PopupBannerLogResponse> {
    const { popupBannerLogs } = await this.client.request(POPUP_BANNER_LOGS, {
      limitPerPage,
      page,
      popupBannerId,
    })

    return plainToInstance(PopupBannerLogResponse, popupBannerLogs)
  }

  async createPopupBanner(value: PopupBannerFormType): Promise<void> {
    const imgPath = await appClient.fileClient.uploadFile(
      value.coverImage.blob!
    )
    let dynamicLink = null

    if (value.link) {
      dynamicLink = await appClient.fileClient.getDynamicLink(value.link)
    }

    await this.client.request(CREATE_POPUP_BANNER, {
      createPopupBannerInput: {
        title: value.title,
        link: dynamicLink || value.link,
        imgPath,
        userTypes: value.userTypes,
        screen: value.displayAt,
        platforms: value.display,
        publishedType: value.publishedType,
        startPublishedAt: DateTime.fromJSDate(value.startDate).toUTC(),
        endPublishedAt: DateTime.fromJSDate(value.endDate).toUTC(),
      },
    })
  }

  async searchPopupBanner({
    limitPerPage,
    page,
    startDate,
    endDate,
    searchText,
    sortKey,
    sortOrder,
    status,
  }: {
    limitPerPage: number
    page: number
    startDate?: Date
    endDate?: Date
    searchText: string
    sortKey: string
    sortOrder: SortingType
    status: PopupBannerStatusEnum
  }): Promise<SearchPopupBannerResponse> {
    const { searchAdminPopupBanner } = await this.client.request(
      SEARCH_POPUP_BANNER,
      {
        limitPerPage,
        page,
        startDate:
          startDate && DateTime.fromJSDate(startDate).startOf('day').toUTC(),
        endDate: endDate && DateTime.fromJSDate(endDate).endOf('day').toUTC(),
        searchText,
        orderBy: {
          [sortKey]: sortOrder,
        },
        status: status === PopupBannerStatusEnum.ALL ? undefined : status,
      }
    )
    return plainToInstance(SearchPopupBannerResponse, searchAdminPopupBanner)
  }

  async updatePopupBanner(value: PopupBannerFormType): Promise<void> {
    let imgPath = value.coverImage.url
    if (value.coverImage.blob)
      imgPath = await appClient.fileClient.uploadFile(value.coverImage.blob!)

    let dynamicLink = null
    if (value.link) {
      dynamicLink = await appClient.fileClient.getDynamicLink(value.link)
    }

    await this.client.request(UPDATE_POPUP_BANNER, {
      updatePopupBannerInput: {
        id: value.id,
        title: value.title,
        link: dynamicLink || value.link,
        imgPath,
        userTypes: value.userTypes,
        screen: value.displayAt,
        platforms: value.display,
        publishedType: value.publishedType,
        startPublishedAt: DateTime.fromJSDate(value.startDate).toUTC(),
        endPublishedAt: DateTime.fromJSDate(value.endDate).toUTC(),
      },
    })
  }

  async popupBanners({
    limitPerPage,
    page,
    statuses,
    screens,
  }: {
    limitPerPage: number
    page: number
    statuses: PopupStatusEnum
    screens: PopupScreenEnum
  }): Promise<PopupBannerListResponse> {
    const { popupBanners } = await this.client.request(POPUP_BANNERS, {
      statuses: statuses === PopupStatusEnum.ALL ? [] : [statuses],
      screens: screens === PopupScreenEnum.ALL ? [] : [screens],
      page,
      limitPerPage,
    })
    return plainToInstance(PopupBannerListResponse, popupBanners)
  }

  async handlePopupBanner({
    id,
    isActive,
    runningNo,
  }: {
    id: number
    isActive?: boolean
    runningNo?: number
  }): Promise<void> {
    await this.client.request(HANDLE_POPUP_BANNER, {
      handlePopupBannerInput: {
        id,
        isActive,
        runningNo,
      },
    })
  }

  async bulkUpdatePopupBanner({
    ids,
    action,
  }: {
    ids: number[]
    action: PopupActionEnum
  }): Promise<void> {
    await this.client.request(BULK_UPDATE_POPUP_BANNER, {
      bulkUpdatePopupBannerInput: {
        action,
        ids,
      },
    })
  }

  async removePopupBanner(id: number): Promise<void> {
    await this.client.request(REMOVE_POPUP_BANNER, {
      removePopupBannerId: id,
    })
  }

  async exportPopupBanners({
    startDate,
    endDate,
    searchText,
    key,
    order,
    status,
  }: {
    startDate?: Date
    endDate?: Date
    searchText: string
    key: string
    order: SortingType
    status: PopupBannerStatusEnum
  }) {
    await this.client.request(EXPORT_POPUP_BANNERS, {
      startDate: startDate
        ? DateTime.fromJSDate(startDate).startOf('day').toUTC()
        : undefined,
      endDate: endDate
        ? DateTime.fromJSDate(endDate).endOf('day').toUTC()
        : undefined,
      searchText: searchText || undefined,
      orderBy: {
        [key]: order,
      },
      status: status === PopupBannerStatusEnum.ALL ? undefined : status,
    })
  }
}
