import { Expose, Type } from 'class-transformer'

import { CorrectionDetailType } from './CorrectionDetailType'
import { LogUserType } from './LogUserType'

export class LogType {
  @Expose() id: number

  @Expose()
  @Type(() => LogUserType)
  createdBy: LogUserType

  @Expose() createdAt: string

  @Expose()
  @Type(() => CorrectionDetailType)
  correctionDetail: CorrectionDetailType
}
