import { gql } from 'graphql-request'

export const GET_TOTAL_USER_PUNISHMENT = gql`
  query GetUserPunishmentTableCount($userId: Float!) {
    getUserPunishmentTableCount(userId: $userId) {
      totalAdmonish
      totalBan
    }
  }
`
