import { gql } from 'graphql-request'

export const GET_SALES_REPORTS_OVERVIEW = gql`
  query GetSalesReportsOverview($startDate: DateTime, $endDate: DateTime) {
    getSalesReportsOverview(startDate: $startDate, endDate: $endDate) {
      totalDownloadedCount
      totalBookAndBookChapterPlatformShare
      totalDonationPlatformShare
      totalPlatformShare
      totalCoinSales
      totalBookAndBookChapterSales
      totalDonation
      totalRemainingCoin
      dailyActiveUser
    }
  }
`
