import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'

import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { GenderEnum } from '@interfaces/GenderEnum'
import { LogTimeEnum, logTimeValue } from '@interfaces/LogTimeEnum'
import { AgeRangeEnum } from '@interfaces/AgeRangeEnum'
import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { SearchLogFormType } from '@models/searchManagement/SearchLogFormType'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { SearchHistoryPageProps } from './interface'

const mapDisplay = {
  [LogTimeEnum.A_DAY_AGO]: '1_day_ago',
  [LogTimeEnum.A_MONTH_AGO]: '1_month_ago',
  [LogTimeEnum.A_YEAR_AGO]: '1_year_ago',
}

export function withSearchHistoryPage(
  Component: React.FC<SearchHistoryPageProps>
) {
  function WithSearchHistoryPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const [sort, setSort] = useState({
      key: 'DATE_TIME',
      order: SortingType.DESC,
    })
    const [searchParams, setSearchParams] = useState({
      searchText: '',
      display: LogTimeEnum.A_MONTH_AGO,
      gender: GenderEnum.ALL,
      ageRange: AgeRangeEnum.ALL,
      startDate: DateTime.now()
        .minus({ days: logTimeValue[LogTimeEnum.A_MONTH_AGO] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })
    const alert = useAlert()
    const [selectedId, setSelectedId] = useState<string[]>([])

    const { data: searchLog, isLoading } = useQuery(
      ['search-log', searchParams, page, sort],
      () =>
        client?.searchManagementClient.searchLog({
          searchText: searchParams.searchText,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          gender: searchParams.gender,
          ageRange: searchParams.ageRange,
          key: sort.key,
          order: sort.order,
          page,
        }),
      {
        enabled: isAuthenticated,
        onSuccess: response => {
          if (response) {
            handleTotalChange(response.total)
          }
        },
      }
    )

    function handleSubmit(val: SearchLogFormType) {
      let startDate = DateTime.now().minus({ day: 1 })
      let endDate = DateTime.now()

      if (val.display === LogTimeEnum.A_MONTH_AGO) {
        startDate = DateTime.now()
          .minus({ days: logTimeValue[LogTimeEnum.A_MONTH_AGO] })
          .startOf('day')
        endDate = DateTime.now().endOf('day')
      } else if (val.display === LogTimeEnum.A_YEAR_AGO) {
        startDate = DateTime.now()
          .minus({ days: logTimeValue[LogTimeEnum.A_YEAR_AGO] })
          .startOf('day')
        endDate = DateTime.now().endOf('day')
      }

      setSearchParams({
        ...val,
        startDate,
        endDate,
      })
      setSelectedId([])
      pageChange(1)
    }

    async function handleExport() {
      try {
        await client?.searchManagementClient.exportSearchManagementHistory({
          searchText: searchParams.searchText,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          gender: searchParams.gender,
          ageRange: searchParams.ageRange,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function handleSort({ key, order }: SortByType) {
      setSort({ key, order })
      setSelectedId([])
    }

    function handleSelectAll() {
      if (searchLog?.data) {
        if (searchLog.data.length === selectedId.length) {
          setSelectedId([])
        } else {
          setSelectedId(searchLog.data.map(row => row.id))
        }
      }
    }

    function handleSelect(value: string) {
      const index = selectedId.findIndex((item: string) => item === value)

      if (index !== -1) {
        setSelectedId((prev: string[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedId((prev: string[]) => [...prev, value])
      }
    }

    function handlePageChange(value: number) {
      setSelectedId([])
      pageChange(value)
    }

    const newProps = {
      data: searchLog?.data || [],
      page,
      perpage,
      total,
      isLoading,
      searchParams,
      selectedId,
      handlePageChange,
      handleSort,
      handleSubmit,
      handleExport,
      handleSelectAll,
      handleSelect,
    }
    return <Component {...newProps} />
  }

  return WithSearchHistoryPage
}
