import { withPermissionGuard } from '@libs/permission'
import { PromotionSaleHistoryPage } from './PromotionSaleHistoryPage'
import { withPromotionSaleHistoryPage } from './withPromotionSaleHistoryPage'

const ConnectedPromotionSaleHistoryPage = withPermissionGuard(
  withPromotionSaleHistoryPage(PromotionSaleHistoryPage),
  'promotionSetting',
  'view'
)

export { ConnectedPromotionSaleHistoryPage as PromotionSaleHistoryPage }
