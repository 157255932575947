import { PieChart } from '@components/chart/PieChart'
import { ChartOptionType } from '@interfaces/ChartOptionType'

export function AgeRangeChart({ data }: { data: ChartOptionType[] }) {
  return (
    <div className='w-full'>
      <PieChart
        title='ช่วงอายุ'
        data={data}
        legendStyle='bottom'
        style={{ height: `calc(274px + ${data.length * 40}px)`, width: '100%' }}
      />
    </div>
  )
}
