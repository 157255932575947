import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class ReadTimeUsageType {
  @Expose({ name: 'hour' })
  @Transform(({ value }) => DateTime.fromISO(value).toFormat('H:00'))
  name: string

  @Expose({ name: 'count' }) value: number

  @Expose() color: string
}
