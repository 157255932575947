import { Expose, Type } from 'class-transformer'
import { PromotionChallengeLogType } from './PromotionChallengeLogType'

export class PromotionChallengeLogResponseType {
  @Expose()
  @Type(() => PromotionChallengeLogType)
  data: PromotionChallengeLogType[]

  @Expose()
  total: number
}
