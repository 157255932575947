import cn from 'classnames'
import { abbreviateNumber } from '@libs/utils'
import { Mousewheel, FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  HeartIcon,
  EyeIcon,
  CollectionsBookmarkIcon,
  ChatboxIcon,
  ListIcon,
  TagIcon,
  BanCoverIcon,
} from '@components/Icons'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { Box } from '../Box'
import { CoverProps } from './interface'

export function Cover({
  coverImgPath,
  title,
  writingType,
  cover,
  viewCount,
  commentCount,
  likeCount,
  chapterCount,
  shelfAddedCount,
  penName,
  tags,
  rating,
  category,
  isTranslated,
  isEpub,
  isPdf,
  isEnded,
  status,
  bookType,
  banCover,
}: CoverProps) {
  return (
    <Box>
      <div className='flex flex-row w-full gap-x-[30px]'>
        <div className='relative w-[372px] shrink-0 rounded-lg overflow-hidden'>
          <div className='relative w-full h-full pt-[140.625%]'>
            {coverImgPath ? (
              <div className='absolute inset-0'>
                <img
                  className='w-full h-full inset-0 '
                  src={coverImgPath}
                  alt='รูปประจำงานเขียน'
                />
              </div>
            ) : (
              <div className='bg-gray w-full absolute h-full inset-0 ' />
            )}
          </div>
          <button
            className='font-light text-[14px] text-primary border border-gray flex items-center absolute bg-white rounded-lg bottom-[10px] left-[10px] px-[10px] py-[5px]'
            type='button'
            onClick={banCover}
          >
            <BanCoverIcon width='18' height='18' className='mr-[5px]' />
            แบนรูปภาพปก
          </button>
        </div>

        <div className='flex-1'>
          <div className='flex items-start'>
            <Box.Title className='break-word'>{title}</Box.Title>
            <div
              className={cn(
                'rounded text-center px-[10px] py-[4px] mt-[6px] font-medium ml-[10px] text-green bg-[#48bea21a] text-xs',
                {
                  'min-w-[76px]': status === BookStatusEnum.DRAFT,
                  'min-w-[62px]': status === BookStatusEnum.PUBLISHED,
                }
              )}
            >
              {status === BookStatusEnum.DRAFT ? 'ไม่เผยแพร่' : 'เผยแพร่'}
            </div>

            {isEnded && (
              <Box.Tag className='ml-[10px] min-w-[56px] mt-[6px] font-medium'>
                จบแล้ว
              </Box.Tag>
            )}
          </div>

          <div className='flex items-center mt-[20px]'>
            <img
              className='w-[40px] h-[40px] rounded-full shrink-0'
              src={penName?.user?.avatarImgPath || '/images/avatar.png'}
              alt='รูปนักเขียน'
            />

            <Box.SubTitle className='tracking-[0.13px] ml-[10px]'>
              {penName?.firstPenName}
            </Box.SubTitle>
          </div>

          <Box.Paragraph className='line-clamp-3 px-1 mt-[20px] break-all'>
            {cover}
          </Box.Paragraph>

          <div className='grid grid-cols-3 gap-x-[50px] gap-y-[20px] mt-[80px]'>
            <div>
              <Box.Label>หมวดหมู่</Box.Label>
              <Box.Paragraph className='mt-1.5'>{category.name}</Box.Paragraph>
            </div>

            <div>
              <Box.Label>ประเภทงานเขียน</Box.Label>
              <Box.Paragraph className='mt-1.5'>
                {writingType}
                {isTranslated && ', นิยายแปล'}
              </Box.Paragraph>
            </div>

            <div>
              <Box.Label>ระดับเนื้อหา (Rating)</Box.Label>
              <Box.Paragraph className='mt-1.5'>{rating?.name}</Box.Paragraph>
            </div>
          </div>

          <div className='flex flex-row mt-[40px]'>
            <div className='flex items-center gap-[5px]'>
              <HeartIcon className='text-primary ' width='24' height='24' />
              <Box.SubTitle>{abbreviateNumber(likeCount)}</Box.SubTitle>
              <Box.SubParagraph>หัวใจ</Box.SubParagraph>
            </div>

            <div className='flex border-l border-gray mx-5' />

            <div className='flex items-center gap-[5px]'>
              <EyeIcon className='text-primary ' width='24' height='24' />
              <Box.SubTitle>{abbreviateNumber(viewCount)}</Box.SubTitle>
              <Box.SubParagraph>วิว</Box.SubParagraph>
            </div>

            <div className='flex border-l border-gray mx-5' />

            <div className='flex items-center gap-[5px]'>
              <CollectionsBookmarkIcon
                className='text-primary '
                width='24'
                height='24'
              />
              <Box.SubTitle>{abbreviateNumber(shelfAddedCount)}</Box.SubTitle>
              <Box.SubParagraph>เพิ่มเข้าชั้น</Box.SubParagraph>
            </div>

            <hr className='text-gray mx-5' />

            <div className='flex items-center gap-[5px]'>
              <ChatboxIcon className='text-primary ' width='24' height='24' />
              <Box.SubTitle>{abbreviateNumber(commentCount)}</Box.SubTitle>
              <Box.SubParagraph>คอมเมนต์</Box.SubParagraph>
            </div>
          </div>

          <div className='flex border-b border-gray my-10' />

          <div className='flex flex-row items-center justify-start'>
            <div className='flex gap-[5px] items-center justify-center'>
              <ListIcon className='text-primary' width='24' height='24' />
              <Box.SubTitle className='mx-1'>{chapterCount}</Box.SubTitle>
              <Box.SubParagraph>ตอน</Box.SubParagraph>
            </div>
          </div>
        </div>
      </div>
      <hr className='text-gray my-[30px]' />

      {!!tags?.length && (
        <div className='flex space-x-[10px] items-center justify-center'>
          <TagIcon className='text-primary' width='24' height='24' />
          <Swiper
            className='w-fit'
            slidesPerView='auto'
            spaceBetween={10}
            modules={[Mousewheel, FreeMode]}
            mousewheel
            freeMode
          >
            {tags.map(tag => (
              <SwiperSlide
                key={tag.id}
                className='bg-blue-2 px-[10px] py-[4px] font-light whitespace-nowrap text-[14px] text-secondary-100 rounded-[8px] h-[30px] w-fit'
              >
                {tag.name}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Box>
  )
}
