import { gql } from 'graphql-request'

export const UPDATE_MAIN_BANNER = gql`
  mutation UpdateMainBanner($updateMainBannerInput: UpdateMainBannerInput!) {
    updateMainBanner(updateMainBannerInput: $updateMainBannerInput) {
      id
      web
      android
      ios
      isActiveWeb
      isActiveIos
      isActiveAndroid
      coverImgPath
      linkWeb
      linkIos
      linkAndroid
      description
      startPublishedAt
      endPublishedAt
      runningNo
    }
  }
`
