import { ColumnType } from '@components/Table/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { abbreviateNumber } from '@libs/utils'
import { WriterListType } from '@models/writer/WriterListType'
import { ActionCell } from './components/ActionCell'

export const columns: ColumnType<WriterListType>[] = [
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img className='w-[29px] h-[41px]' alt='cover' src={value} />
      ) : (
        <div className='flex w-[29px] h-[41px] bg-gray ' />
      ),
    isSortable: false,
    cellClassName: 'w-[50px] min-w-[50px] py-[9.5px] pr-[10px]',
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => <div className='line-clamp-2 break-all'>{value}</div>,
    isSortable: true,
    cellClassName: 'pr-[10px] w-full ',
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => (
      <p> {value === BookEnum.NOVEL ? 'นิยาย' : 'การ์ตูน'}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[100px] min-w-[100px] pr-[10px]',
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    cell: ({ obj }) => (
      <p className='max-w-[120px] truncate'>{obj.penName.firstPenName}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[120px] min-w-[120px] pr-[10px]',
  },
  {
    column: 'Penname ID',
    accessor: 'id',
    cell: ({ obj }) => (
      <p className='max-w-[80px] truncate'>{obj.penName.id}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[90px] min-w-[90px] pr-[10px]',
    sortKey: 'bookId',
  },
  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => (
      <p className='max-w-[60px] truncate'>{abbreviateNumber(value || 0)}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[90px] min-w-[90px] pr-[10px]',
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => (
      <p className='max-w-[60px] truncate'>{abbreviateNumber(value || 0)}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[80px] min-w-[80px] pr-[10px]',
  },
  {
    column: 'Love',
    accessor: 'likeCount',
    cell: ({ value }) => (
      <p className='max-w-[60px] truncate'>{abbreviateNumber(value || 0)}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[80px] min-w-[80px] pr-[10px]',
  },
  {
    column: 'ยอดขาย',
    accessor: 'amountSpent',
    cell: ({ value }) => (
      <p className='max-w-[60px] truncate'>{abbreviateNumber(value || 0)}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[80px] min-w-[80px] pr-[10px]',
  },
  {
    column: 'บริจาค',
    accessor: 'sumCharacterDonate',
    cell: ({ value }) => (
      <p className='max-w-[60px] truncate'>{abbreviateNumber(value || 0)}</p>
    ),
    isSortable: true,
    cellClassName: 'w-[80px] min-w-[80px] pr-[10px]',
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell id={obj.id} />,
    isAction: true,
    cellClassName: 'flex justify-end ',
  },
]
