import { Button } from '@components/Button'
import { promotionRequestActionText } from '@libs/promotion/utils'
import { formatDateTime } from '@libs/utils'

import { PromotionCodeApproveSectionProps } from './interface'

function PromotionCodeApproveSection({
  lastRequest,
  handleApprove,
  handleReject,
  approvedLoading,
  handleGoToFormDetail,
}: PromotionCodeApproveSectionProps) {
  return (
    <div className='flex justify-between pt-[20px]'>
      <div className='flex items-center space-x-5'>
        <div className='flex items-center space-x-[10px]'>
          <span className='text-secondary-100 font-bold text-sm'>
            ดำเนินการโดย :
          </span>
          {lastRequest.createdBy.avatarImgPath ? (
            <img
              className='w-[50px] h-[50px] rounded-full'
              src={lastRequest.createdBy.avatarImgPath}
              alt='User cover'
            />
          ) : (
            <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
          )}
          <div className='space-y-1 text-sm'>
            <p className='text-secondary font-bold'>
              {lastRequest.createdBy.displayName}
            </p>
            <p className='text-secondary-100 font-normal'>
              {formatDateTime(lastRequest.createdAt)}
            </p>
          </div>
        </div>
        <div className='w-[1px] h-full border-r' />
        <div className='text-sm font-bold text-primary'>
          {promotionRequestActionText(lastRequest.action)}
        </div>
      </div>
      <div className='flex items-center space-x-5'>
        <Button
          type='button'
          className='px-0 text-secondary font-mitr text-sm font-medium'
          onClick={handleGoToFormDetail}
        >
          <span>ดูรายละเอียด</span>
        </Button>
        <Button
          type='button'
          onClick={handleReject}
          className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
        >
          <span>Reject</span>
        </Button>
        <Button
          type='button'
          disabled={approvedLoading}
          onClick={handleApprove}
          className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
        >
          <span>Approve</span>
        </Button>
      </div>
    </div>
  )
}

export default PromotionCodeApproveSection
