import React from 'react'
import { ExportButtonProps } from './interface'

export function withExportButton(Component: React.FC<ExportButtonProps>) {
  function WithExportButton(props: ExportButtonProps) {
    return <Component {...props} />
  }

  return WithExportButton
}
