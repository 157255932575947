import cn from 'classnames'
import { Link, useNavigate } from 'react-router-dom'

import { DateDailyField } from '@components/DateDailyField'
import { SelectButton } from '@components/SelectButton'
import { TopRankFilterProps } from './interface'

export default function TopRankFilter({
  title,
  category,
  storyType,
  dateOfWeek,
  storyTypeOptions,
  categoryOptions,
  disabledNext,
  disabledPrev,
  className = '',
  handleChangeCategory,
  handleChangeStoryType,
  handleClickNextWeek,
  handleClickPrevWeek,
}: TopRankFilterProps) {
  const navigate = useNavigate()
  return (
    <>
      <div>
        <div
          className={cn(
            'flex flex-row justify-between items-center mb-[20px] font-mitr',
            className
          )}
        >
          <h1 className='font-mitr text-[24px] text-secondary'>{title}</h1>
          <Link to='ban-list'>
            <div className='text-[14px] underline cursor-pointer'>
              ดูรายการแบนหนังสือ
            </div>
          </Link>
        </div>
        <div className='flex flex-row justify-between items-center mb-[20px]'>
          <SelectButton
            options={storyTypeOptions}
            value={storyType}
            onChange={handleChangeStoryType}
          />
          <DateDailyField
            title='ประจำวันที่'
            dateOfWeek={dateOfWeek}
            handleClickNextWeek={handleClickNextWeek}
            handleClickPrevWeek={handleClickPrevWeek}
            disabledNext={disabledNext}
            disabledPrev={disabledPrev}
            className='col-span-2'
          />
        </div>
        <div className='border-[1px] border-[#eee] mb-[20px]' />
      </div>
      <div className='overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
        <SelectButton
          options={categoryOptions}
          value={category}
          onChange={handleChangeCategory}
          className='gap-y-[15px]'
        />
      </div>
      <div className='border-[1px] border-[#eee] mt-[20px]' />
    </>
  )
}
