import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function AuthenticationLayout({ children }: FunctionComponentType) {
  return (
    <div className='flex h-screen'>
      <div className='m-auto'>
        <div className='w-[400px] rounded overflow-hidden shadow-lg px-[70px] py-[40px]'>
          <img className='w-[260px]' src='/logo.webp' alt='logo' />
          <div className='flex flex-col items-center px-[30px]'>{children}</div>
        </div>
      </div>
    </div>
  )
}
