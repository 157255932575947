import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { SalesByUserPageProps, SearchParamsType } from './interface'

export function withSalesByUserPage(Component: React.FC<SalesByUserPageProps>) {
  function WithSalesByUserPage() {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const client = useClient()
    const { isUserLoading, isAuthenticated } = useAuthentication()

    const [activeTab, setActiveTab] = useState<UserManageMentSearchEnum>(() => {
      const tab = params.get('tab') as UserManageMentSearchEnum
      if (
        [
          UserManageMentSearchEnum.ALL,
          UserManageMentSearchEnum.PUBLISHER,
          UserManageMentSearchEnum.WRITER,
          UserManageMentSearchEnum.READER,
          UserManageMentSearchEnum.PAYMENT,
        ].includes(tab)
      ) {
        return tab
      }
      return UserManageMentSearchEnum.ALL
    })

    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      searchText: params.get('text') || '',
      sortKeyAllSaleByUser:
        activeTab === UserManageMentSearchEnum.PAYMENT && params.get('key')
          ? 'id'
          : params.get('key') || 'id',
      sortOrderAllSaleByUser:
        activeTab === UserManageMentSearchEnum.PAYMENT
          ? SortingType.DESC
          : (params.get('order') as SortingType) || SortingType.DESC,
      sortKeyUserStopSapPayment:
        activeTab === UserManageMentSearchEnum.PAYMENT
          ? params.get('key') || 'userId'
          : 'userId',
      sortOrderUserStopSapPayment:
        activeTab === UserManageMentSearchEnum.PAYMENT
          ? (params.get('order') as SortingType) || SortingType.DESC
          : SortingType.DESC,
    })

    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({
        perpage: 20,
        page: params.get('page') ? Number(params.get('page')) : 1,
      })

    const { data: secondaryCoinId } = useQuery('silver-coin', () =>
      client?.saleByUserClient.getSecondaryCoinId()
    )

    function handleUpdateActiveTab(value: UserManageMentSearchEnum) {
      setActiveTab(value)
      pageChange(1)

      const query = new URLSearchParams()
      query.set('tab', value)
      if (searchParams.searchText) query.set('text', searchParams.searchText)
      if (value === UserManageMentSearchEnum.PAYMENT) {
        query.set('key', searchParams.sortKeyUserStopSapPayment)
        query.set('order', searchParams.sortOrderUserStopSapPayment)
      } else {
        query.set('key', searchParams.sortKeyAllSaleByUser)
        query.set('order', searchParams.sortOrderAllSaleByUser)
      }

      navigate(`/user/sales-by-user?${query.toString()}`, { replace: true })
    }

    function handleSubmitSearch(searchText: string) {
      setSearchParams(prev => ({ ...prev, searchText }))
      pageChange(1)

      const query = new URLSearchParams()
      query.set('tab', activeTab)
      if (searchText) query.set('text', searchText)
      if (activeTab === UserManageMentSearchEnum.PAYMENT) {
        query.set('key', searchParams.sortKeyUserStopSapPayment)
        query.set('order', searchParams.sortOrderUserStopSapPayment)
      } else {
        query.set('key', searchParams.sortKeyAllSaleByUser)
        query.set('order', searchParams.sortOrderAllSaleByUser)
      }

      navigate(`/user/sales-by-user?${query.toString()}`)
    }

    function handleResetSearch() {
      setSearchParams(prev => ({ ...prev, searchText: '' }))
      pageChange(1)

      const query = new URLSearchParams()
      query.set('tab', activeTab)
      if (activeTab === UserManageMentSearchEnum.PAYMENT) {
        query.set('key', searchParams.sortKeyUserStopSapPayment)
        query.set('order', searchParams.sortOrderUserStopSapPayment)
      } else {
        query.set('key', searchParams.sortKeyAllSaleByUser)
        query.set('order', searchParams.sortOrderAllSaleByUser)
      }

      navigate(`/user/sales-by-user?${query.toString()}`)
    }

    function handleSort({ key, order }: SortByType) {
      if (activeTab === UserManageMentSearchEnum.PAYMENT) {
        setSearchParams(prev => ({
          ...prev,
          sortKeyUserStopSapPayment: key,
          sortOrderUserStopSapPayment: order,
        }))
      } else {
        setSearchParams(prev => ({
          ...prev,
          sortKeyAllSaleByUser: key,
          sortOrderAllSaleByUser: order,
        }))
      }

      const query = new URLSearchParams()
      query.set('tab', activeTab)
      query.set('key', key)
      query.set('order', order)
      query.set('page', String(page))
      if (searchParams.searchText) query.set('text', searchParams.searchText)

      navigate(`/user/sales-by-user?${query.toString()}`)
    }

    function handlePageChange(value: number) {
      pageChange(value)

      const query = new URLSearchParams()
      query.set('tab', activeTab)
      query.set('page', String(value))
      if (searchParams.searchText) query.set('text', searchParams.searchText)
      if (activeTab === UserManageMentSearchEnum.PAYMENT) {
        query.set('key', searchParams.sortKeyUserStopSapPayment)
        query.set('order', searchParams.sortOrderUserStopSapPayment)
      } else {
        query.set('key', searchParams.sortKeyAllSaleByUser)
        query.set('order', searchParams.sortOrderAllSaleByUser)
      }

      navigate(`/user/sales-by-user?${query.toString()}`)
    }

    const {
      data: dataTotal = {
        allCount: 0,
        publisherCount: 0,
        writerCount: 0,
        readerCount: 0,
      },
      isLoading: isTotalLoading,
      isSuccess: isdataTotal,
    } = useQuery(
      ['sales-by-user', 'total', searchParams, activeTab],
      () =>
        client!.saleByUserClient.getTotalSalesByUser(searchParams.searchText),
      {
        onSuccess: response => {
          let totalSalesByUser = 0

          if (activeTab === UserManageMentSearchEnum.ALL) {
            totalSalesByUser = response.allCount
          } else if (activeTab === UserManageMentSearchEnum.PUBLISHER) {
            totalSalesByUser = response.publisherCount
          } else if (activeTab === UserManageMentSearchEnum.READER) {
            totalSalesByUser = response.readerCount
          } else if (activeTab === UserManageMentSearchEnum.WRITER) {
            totalSalesByUser = response.writerCount
          }

          handleTotalChange(totalSalesByUser)
        },
      }
    )

    const queryParams = ['sales-by-user', searchParams, activeTab, page]

    const {
      data,
      isLoading,
      refetch: refetchAllSalesByUser,
    } = useQuery(
      queryParams,
      () =>
        client?.saleByUserClient.getAllSalesByUser({
          ...searchParams,
          sortKey: searchParams.sortKeyAllSaleByUser,
          sortOrder: searchParams.sortOrderAllSaleByUser,
          page,
          userType: activeTab,
          secondaryCoinId: secondaryCoinId!,
        }),
      {
        enabled:
          !isUserLoading &&
          isAuthenticated &&
          isdataTotal &&
          activeTab !== UserManageMentSearchEnum.PAYMENT &&
          !!secondaryCoinId,
      }
    )

    const {
      data: userStopSapPaymentData,
      isLoading: isUserStopSapPaymentLoading,
      refetch: refetchUserStopSapPayment,
    } = useQuery(
      ['user-stop-sap-payment', searchParams, activeTab, page],
      () =>
        client!.saleByUserClient.getUserStopSapPayment({
          ...searchParams,
          sortKey: searchParams.sortKeyUserStopSapPayment,
          sortOrder: searchParams.sortOrderUserStopSapPayment,
          page,
        }),
      {
        onSuccess: response => {
          handleTotalChange(response.total)
        },
        enabled:
          !isUserLoading &&
          isAuthenticated &&
          isdataTotal &&
          activeTab === UserManageMentSearchEnum.PAYMENT,
      }
    )

    const componentProps = {
      searchParams,
      page,
      perpage,
      total,
      dataTotal,
      activeTab,
      data: data?.data ?? [],
      isLoading: isLoading || isUserLoading || isTotalLoading,
      userStopSapPaymentData: userStopSapPaymentData?.data ?? [],
      isUserStopSapPaymentData: isUserStopSapPaymentLoading || isUserLoading,
      refetchAllSalesByUser,
      refetchUserStopSapPayment,
      handleSort,
      pageChange: handlePageChange,
      handleSubmitSearch,
      handleResetSearch,
      handleUpdateActiveTab,
    }
    return <Component {...componentProps} />
  }

  return WithSalesByUserPage
}
