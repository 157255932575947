import cn from 'classnames'

import { ArrowUpIcon, CrossIcon, MinusIcon, PlusIcon } from '@components/Icons'
import { Button } from '@components/Button'
import { CropImageModalProps } from './interface'

export function CropImageModal({
  title,
  imgRef,
  uploadUrl,
  visible,
  loading,
  isCircle,
  hideModal,
  cropImage,
  zoom,
  move,
}: CropImageModalProps) {
  return (
    <div
      className={cn(
        'fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-50',
        { hidden: !visible }
      )}
    >
      <div
        className='relative w-[500px] mobile:w-[370px] flex flex-col px-[30px] py-[40px] mobile:px-[20px] h-auto max-w-full max-h-[90%] bg-white rounded-[10px]'
        onClick={event => {
          event.stopPropagation()
        }}
      >
        <div className='absolute right-[15px] top-[15px] z-[1] flex justify-end min-h-[20px]'>
          <button type='button' onClick={hideModal}>
            <CrossIcon className='text-secondary' />
          </button>
        </div>
        <div
          className={cn(
            'bg-white rounded overflow-y-auto transform transition-all crop-image',
            { 'circle-crop': isCircle }
          )}
        >
          <h1 className='text-[24px] text-center mb-[20px] font-medium font-mitr'>
            {title}
          </h1>
          <div className='flex flex-col items-center'>
            {uploadUrl ? (
              <div className='w-full h-[300px] mobile:w-[308px] mobile:h-[210px]'>
                <img ref={imgRef} id='image' src={uploadUrl} alt='' />
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <div className='flex items-center justify-center gap-x-[10px] mt-[20px]'>
            <button type='button' onClick={() => zoom(-0.1)}>
              <MinusIcon className='text-secondary' />
            </button>
            <hr className='w-[250px] text-gray mobile:w-[160px]' />
            <button type='button' onClick={() => zoom(0.1)}>
              <PlusIcon className='text-secondary' />
            </button>
          </div>

          <div className='flex gap-x-[3px] items-center justify-center mt-[20px]'>
            <button
              className='p-[3px] bg-blue-2 rounded-[8px] hover:bg-primary-1 active:bg-primary-1'
              type='button'
              onClick={() => move(-1, 0)}
            >
              <ArrowUpIcon className='text-secondary -rotate-90 hover:text-primary active:text-primary' />
            </button>
            <button
              className='p-[3px] bg-blue-2 rounded-[8px] hover:bg-primary-1 active:bg-primary-1'
              type='button'
              onClick={() => move(1, 0)}
            >
              <ArrowUpIcon className='text-secondary rotate-90 hover:text-primary active:text-primary' />
            </button>
            <button
              className='p-[3px] bg-blue-2 rounded-[8px] hover:bg-primary-1 active:bg-primary-1'
              type='button'
              onClick={() => move(0, -1)}
            >
              <ArrowUpIcon className='text-secondary  hover:text-primary active:text-primary' />
            </button>
            <button
              className='p-[3px] bg-blue-2 rounded-[8px] hover:bg-primary-1 active:bg-primary-1'
              type='button'
              onClick={() => move(0, 1)}
            >
              <ArrowUpIcon className='text-secondary rotate-180 hover:text-primary active:text-primary' />
            </button>
          </div>

          <div className='flex gap-x-[10px] mt-[30px] justify-center'>
            <Button
              className='text-secondary bg-white font-medium py-[8px] px-[10px] '
              type='button'
              onClick={hideModal}
            >
              <span className='text-[14px]'>ยกเลิก</span>
            </Button>
            <Button
              className='text-white bg-primary font-medium py-[8px] px-[34px]'
              type='button'
              onClick={cropImage}
              disabled={loading}
            >
              <span className='text-[14px]'>บันทึก</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
