import { gql } from 'graphql-request'

export const GET_PROFILE = gql`
  query GetProfile {
    getProfile {
      id
      avatarImgPath
      bannerImgPath
      idNumber
      fullName
      email
      phoneNumber
      birthDay
      gender
      isSubscribe
      isAcceptPolicy
      username
      displayName
      role {
        id
        name
        permissions {
          name
          actions
          status
        }
      }
    }
  }
`
