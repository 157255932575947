import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { HighestCharacterDonateResponse } from '@models/salse/HighestCharacterDonateReponse'

export const columns: ColumnType<HighestCharacterDonateResponse>[] = [
  {
    column: 'อันดับ',
    accessor: 'numRow',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    sortKey: 'rowNumber',
    cellClassName: 'w-[100px] max-w-[100px]',
  },
  {
    column: 'Character Name',
    accessor: 'characterName',
    cell: ({ value }) => <p className='truncate'>{value || '-'}</p>,
    isSortable: true,
    sortKey: 'characterName',
    cellClassName: 'w-[250px] max-w-[250px] py-[7px]',
  },
  {
    column: 'Story',
    accessor: 'title',
    cell: ({ value }) => (
      <p className='line-clamp-1 leading-[1.8]'>{value || '-'}</p>
    ),
    isSortable: true,
    sortKey: 'bookTitle',
    cellClassName: 'py-[7px]',
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>@{value}</p>,
    isSortable: true,
    sortKey: 'userUsername',
    cellClassName: 'w-[200px] max-w-[200px] py-[7px]',
  },
  {
    column: 'Donation',
    accessor: 'donateCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
    sortKey: 'characterTotalDonate',
    cellClassName: 'w-[250px] max-w-[250px] py-[7px]',
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => (
      <Link to={`/preview/${value}`} target='_blank'>
        <button
          className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isSortable: false,
    cellClassName: 'w-[45px] max-w-[45px] py-[7px]',
  },
]
