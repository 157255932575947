import { gql } from 'graphql-request'

export const UPDATE_BOOK_STORY_SETTING = gql`
  mutation UpdateBookStorySetting(
    $updateBookStorySettingInput: UpdateBookStorySettingInput!
  ) {
    updateBookStorySetting(
      updateBookStorySettingInput: $updateBookStorySettingInput
    ) {
      id
      name
      maxCharacter
    }
  }
`
