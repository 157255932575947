import { Formik, Form } from 'formik'
import cn from 'classnames'

import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Button } from '@components/Button'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { SearchWriterField } from '../SearchWriterField'
import { ByUserFormProps } from './interface'
import { PenNameField } from '../PenNameField'

export function ByUserForm({
  initialValues,
  text,
  disableExport,
  handleSubmit,
  handleExport,
  setText,
  resetHandler,
  penNameHandle,
}: ByUserFormProps) {
  const permission = usePermissionAction('dashboard')

  const timeRangeOption = [
    { value: TimeRangeEnum.DAY, text: 'เมื่อวาน' },
    { value: TimeRangeEnum.MONTH, text: '1 เดือน' },
    { value: TimeRangeEnum.YEAR, text: '1 ปี' },
  ]
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, resetForm }) => (
          <Form>
            <div className='flex flex-col gap-y-[20px] mb-[30px]'>
              <div className='grid grid-cols-2 gap-x-[20px] '>
                <SearchWriterField
                  name='writerId'
                  text={text}
                  setText={setText}
                  placeholder='ค้นหาด้วยชื่อนักเขียน, สำนักพิมพ์ หรือ ID'
                  inputClassName='font-[300] bg-gray-50 rounded-[20px]'
                  className='mt-[22px]'
                  resetHandler={resetHandler}
                />
                <PenNameField penNameHandle={penNameHandle} />
              </div>
              <div className='flex justify-between items-end '>
                <div className='flex items-end gap-x-[10px]'>
                  <SelectButtonField
                    name='timeRange'
                    options={timeRangeOption}
                    label='เลือกช่วงเวลา'
                    mode='line'
                    className='w-fit overflow-auto'
                    onChange={() => {
                      if (values.startAt) {
                        setFieldValue('startAt', undefined)
                      }
                      if (values.endAt) {
                        setFieldValue('endAt', undefined)
                      }
                    }}
                  />
                  <DateTimeInputField
                    className='w-[180px]'
                    name='startAt'
                    placeholder='ตั้งแต่วันที่'
                    onChange={value => {
                      setFieldValue('startAt', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    maxDate={values.endAt || new Date()}
                  />
                  <DateTimeInputField
                    className='w-[180px] '
                    name='endAt'
                    placeholder='ถึงวันที่'
                    onChange={value => {
                      setFieldValue('endAt', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    minDate={values.startAt}
                    maxDate={new Date()}
                  />
                </div>
                <div className='flex font-mitr justify-end ml-[25px] gap-x-[20px]'>
                  <Button
                    type='reset'
                    className='text-secondary underline'
                    onClick={() => {
                      resetForm()
                      resetHandler()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white '
                    disabled={isSubmitting}
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                  <ExportButton
                    className={cn({
                      hidden: !permission.download,
                    })}
                    disabled={disableExport}
                    handleClick={handleExport}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
