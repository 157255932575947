import { withPermissionGuard } from '@libs/permission'
import { SummaryCoinPage } from './SummaryCoinPage'
import { withSummaryCoinPage } from './withSummaryCoinPage'

const ConnectedSummaryCoinPage = withPermissionGuard(
  withSummaryCoinPage(SummaryCoinPage),
  'coin',
  'view'
)

export { ConnectedSummaryCoinPage as SummaryCoinPage }
