import React from 'react'
import cn from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'

import { formatDate } from '@libs/utils'
import { Container } from '@components/Container'
import {
  EbookIcon,
  DragIcon,
  TimeIcon,
  PenIcon,
  BinIcon,
  Upload2Icon,
} from '@components/Icons'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Toggle } from '@components/Toggle'
import StatusButton from '@components/StatusButton'
import { BannerEnum } from '@interfaces/BannerEnum'
import { HighlightBannerPageProps, TabEnum } from './interface'

const tabMenus = [
  { value: TabEnum.ALL, label: 'All' },
  { value: TabEnum.ACTIVE, label: 'Active' },
  { value: TabEnum.INACTIVE, label: 'Inactive' },
  { value: TabEnum.EXPIRED, label: 'Expired' },
]

export function HighlightBannerPage({
  data,
  selectedIds,
  tabSelected,
  navigate,
  handleTabChange,
  handleDragItem,
  handleSelectItemAll,
  handleSelectItem,
  handleEnable,
  handleDeleteBanner,
  handleDeleteBannerList,
  handlePublishHighlightBanner,
  handleUnpublishHighlightBanner,
}: HighlightBannerPageProps) {
  const permission = usePermissionAction('homePageManagement')
  const { position } = useParams()
  return (
    <Container>
      <section className='flex items-start justify-between mb-5'>
        <Container.Title showBackBtn={false}>highlight Banner</Container.Title>

        <div
          className={cn('flex gap-x-[10px]', {
            hidden: !permission.create,
          })}
        >
          <Button
            type='button'
            className='border border-black-480 rounded-3xl bg-secondary px-5 py-2 text-sm text-white'
            icon={<Upload2Icon width='24' height='24' />}
            onClick={() =>
              navigate(`/home-page/highlight-banner/${position}/poster/create`)
            }
          >
            เพิ่มแบนเนอร์แบบ Poster
          </Button>

          <Button
            type='button'
            className='border border-black-480 rounded-3xl bg-secondary px-5 py-2 text-sm text-white'
            icon={<EbookIcon width='24' height='24' />}
            onClick={() =>
              navigate(`/home-page/highlight-banner/${position}/book/create`)
            }
          >
            เพิ่มแบนเนอร์แบบปกหนังสือ
          </Button>
        </div>
      </section>
      <section className='flex text-black-480'>
        {tabMenus.map((menu, index) => (
          <button
            key={index}
            className='relative'
            type='button'
            onClick={() => handleTabChange(menu.value)}
          >
            <div
              className={cn('px-[20px] py-[10px] text-[14px]', {
                'font-bold': tabSelected === menu.value,
                'font-light': tabSelected !== menu.value,
              })}
            >
              {menu.label}
            </div>
            <div
              className={cn('w-full absolute border-b', {
                'border-secondary border-b-[3px] rounded-l-[2px] rounded-r-[2px] bottom-[-1px]':
                  tabSelected === menu.value,
                'border-gray bottom-0': tabSelected !== menu.value,
              })}
            />
          </button>
        ))}
      </section>
      <hr />
      <section className='flex items-center justify-between my-5 h-[40px]'>
        <div className='flex gap-x-[10px] text-secondary'>
          <Checkbox
            className={cn({ hidden: !permission.edit && !permission.delete })}
            onChange={handleSelectItemAll}
            checked={selectedIds.length === data.length && data.length !== 0}
          />
          <span className='font-light'>ทั้งหมด</span>
          <span className='text-blue-3'>
            ( <b>{data.length.toLocaleString() || 0} รายการ</b> )
          </span>
        </div>

        {!!selectedIds.length && (
          <div className='flex gap-x-[10px]'>
            {permission.edit && (
              <>
                <StatusButton
                  status='publish'
                  type='button'
                  onClick={handlePublishHighlightBanner}
                />
                <StatusButton
                  status='unpublish'
                  type='button'
                  onClick={handleUnpublishHighlightBanner}
                />
              </>
            )}
            {permission.delete && (
              <StatusButton
                status='delete'
                type='button'
                onClick={handleDeleteBannerList}
              />
            )}
          </div>
        )}
      </section>
      <hr className='my-5' />

      <DragDropContext onDragEnd={handleDragItem}>
        <Droppable droppableId='list' isDropDisabled={!permission.edit}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data.map((item, index) => (
                <Draggable
                  draggableId={`${item?.id.toString()}`}
                  index={index}
                  key={item?.id}
                >
                  {providedDragable => (
                    <div
                      className='flex gap-5 mb-[10px]'
                      ref={providedDragable.innerRef}
                      {...providedDragable.draggableProps}
                      {...providedDragable.dragHandleProps}
                    >
                      <div className='flex items-center justify-between gap-x-[30px] text-secondary-100'>
                        <Checkbox
                          className={cn({
                            hidden: !permission.edit && !permission.delete,
                          })}
                          checked={selectedIds.includes(item?.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.stopPropagation()
                            handleSelectItem(item?.id)
                          }}
                        />
                        <span className='font-mitr font-medium text-2xl w-[32px]'>
                          {item?.runningNo.toLocaleString()}
                        </span>
                        <DragIcon
                          width='10'
                          className='cursor-grab text-secondary'
                        />
                      </div>

                      <div className='w-full border-[0.5px] border-gray rounded-lg p-[30px] shadow-51'>
                        <div className='grid grid-cols-2 mb-5'>
                          <div className='text-secondary font-bold flex gap-x-5'>
                            <span
                              className='cursor-pointer truncate'
                              onClick={() =>
                                navigate({
                                  pathname:
                                    item?.bannerType === BannerEnum.POSTER
                                      ? `/home-page/highlight-banner/${position}/poster/${item?.id}`
                                      : `/home-page/highlight-banner/${position}/book/${item?.id}`,
                                })
                              }
                            >
                              {item?.bannerName}
                            </span>
                            <span className='flex-shrink-0 text-blue-3 font-light'>
                              (
                              {item?.bannerType === BannerEnum.POSTER &&
                                item?.posterBanner.length}
                              {item?.bannerType === BannerEnum.BOOK &&
                                item?.bookBanner.length}{' '}
                              รายการ)
                            </span>
                          </div>
                          <div className='flex justify-end gap-x-[74px]'>
                            <span className='text-sm font-light text-secondary-100 flex items-center gap-x-[5px]'>
                              {item?.publishedFrom && item?.publishedTo && (
                                <>
                                  <TimeIcon
                                    width='18'
                                    height='18'
                                    className='text-secondary'
                                  />
                                  {formatDate(item?.publishedFrom)} -{' '}
                                  {formatDate(item?.publishedTo)}
                                </>
                              )}
                            </span>

                            <Toggle
                              disabled={!permission.edit}
                              checked={item.isActive}
                              onChange={() => handleEnable(item)}
                            />
                          </div>
                        </div>

                        <div
                          className={cn('flex items-end justify-between', {
                            'opacity-50': !item?.isActive,
                          })}
                        >
                          {item?.bannerType === BannerEnum.POSTER && (
                            <div className='flex gap-x-[10px]'>
                              {item?.posterBanner &&
                                item?.posterBanner.slice(0, 3).map(poster => (
                                  <button
                                    type='button'
                                    key={poster?.id}
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/poster/${item?.id}`,
                                      })
                                    }
                                  >
                                    <img
                                      className='w-[170px] h-[72.86px] aspect-[21/9] rounded-lg object-cover'
                                      src={poster?.imgPath}
                                      alt='banner'
                                    />
                                  </button>
                                ))}

                              {item?.posterBanner &&
                                item?.posterBanner.length > 3 && (
                                  <button
                                    type='button'
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/poster/${item?.id}`,
                                      })
                                    }
                                  >
                                    <div className='w-[170px] h-[72.86px] aspect-[21/9] rounded-lg bg-gray flex justify-center items-center text-2xl font-medium font-mitr text-secondary-100'>
                                      +
                                      {(
                                        item.posterBanner.length - 3
                                      ).toLocaleString()}
                                    </div>
                                  </button>
                                )}

                              {item?.posterBanner &&
                                item?.posterBanner.length === 0 && (
                                  <button
                                    type='button'
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/poster/${item?.id}`,
                                      })
                                    }
                                  >
                                    <div className='w-[170px] h-[72.86px] aspect-[21/9] rounded-lg bg-gray flex justify-center items-center text-2xl font-medium font-mitr text-secondary-100'>
                                      +0
                                    </div>
                                  </button>
                                )}
                            </div>
                          )}

                          {item?.bannerType === BannerEnum.BOOK && (
                            <div className='flex gap-x-[10px]'>
                              {item?.bookBanner &&
                                item?.bookBanner.slice(0, 8).map(book => (
                                  <button
                                    type='button'
                                    key={book?.id}
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/book/${item?.id}`,
                                      })
                                    }
                                  >
                                    <img
                                      className='w-[66px] h-[92px] rounded-lg object-cover'
                                      src={book?.book?.coverImgPath}
                                      alt='banner'
                                    />
                                  </button>
                                ))}

                              {item?.bookBanner && item?.bookBanner.length > 8 && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    navigate({
                                      pathname: `/home-page/highlight-banner/${position}/book/${item?.id}`,
                                    })
                                  }
                                >
                                  <div className='w-[66px] h-[92px] rounded-lg bg-blue-2 flex justify-center items-center text-2xl font-medium font-mitr text-secondary-100'>
                                    +
                                    {(
                                      item.bookBanner.length - 8
                                    ).toLocaleString()}
                                  </div>
                                </button>
                              )}

                              {item?.bookBanner &&
                                item?.bookBanner.length === 0 && (
                                  <button
                                    type='button'
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/book/${item?.id}`,
                                      })
                                    }
                                  >
                                    <div className='w-[66px] h-[92px] rounded-lg bg-gray flex justify-center items-center text-2xl font-medium font-mitr text-secondary-100'>
                                      +0
                                    </div>
                                  </button>
                                )}
                            </div>
                          )}

                          <div className='flex gap-x-[10px]'>
                            <button
                              type='button'
                              className={cn(
                                'flex items-center justify-center border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                {
                                  hidden: !permission.edit,
                                }
                              )}
                              onClick={() =>
                                navigate({
                                  pathname:
                                    item?.bannerType === BannerEnum.BOOK
                                      ? `/home-page/highlight-banner/${position}/book/edit/${item?.id}`
                                      : `/home-page/highlight-banner/${position}/poster/edit/${item?.id}`,
                                })
                              }
                            >
                              <PenIcon
                                width='20'
                                height='20'
                                className='text-secondary-100'
                              />
                            </button>
                            <button
                              type='button'
                              className={cn(
                                'flex items-center justify-center border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                {
                                  hidden: !permission.delete,
                                }
                              )}
                              onClick={() => handleDeleteBanner(item?.id)}
                            >
                              <BinIcon
                                width='20'
                                height='20'
                                className='text-secondary-100'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}
