import cn from 'classnames'
import { TextInputVerifyNumberProps } from './interface'

export function TextInputVerifyNumber({
  className,
  label,
  placeholder,
  value,
  isVerifyNumber,
  isNumberHasBan,
  handleValidateNumber,
}: TextInputVerifyNumberProps) {
  return (
    <div className='flex flex-col gap-y-[10px]'>
      <div className='text-[12px] font-light mb-[6px] text-secondary-100'>
        {label}
      </div>
      <div className={cn('relative ', className)}>
        <input
          type='text'
          className={cn(
            className,
            'p-[10px] leading-10 rounded-[8px] border outline-none text-[14px] h-[40px] w-full placeholder:text-secondary-100 placeholder:font-light text-secondary',
            {
              'border-red': isNumberHasBan,
            }
          )}
          placeholder={placeholder}
          value={value}
          disabled
        />
        {isVerifyNumber && (
          <div
            className={cn(
              'absolute right-[15px] top-[8px] font-bold text-[14px] text-primary cursor-pointer'
            )}
            onClick={handleValidateNumber}
          >
            ตรวจสอบ
          </div>
        )}
      </div>
    </div>
  )
}
