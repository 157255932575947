import { gql } from 'graphql-request'

export const REMOVE_MAIN_BANNER = gql`
  mutation RemoveMainBanner($id: Int!) {
    removeMainBanner(id: $id) {
      id
    }
  }
`

export const REMOVE_MAIN_BANNERS = gql`
  mutation RemoveMainBanners($ids: [Int!]!) {
    removeMainBanners(ids: $ids) {
      id
    }
  }
`
