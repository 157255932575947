import { gql } from 'graphql-request'

export const EXPORT_JOBS = gql`
  query ExportJobs($limitPerPage: Int = 10, $page: Int = 1) {
    exportJobs(limitPerPage: $limitPerPage, page: $page) {
      data {
        id
        name
        status
        presignedPath
      }
      total

      page
    }
  }
`
