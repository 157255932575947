import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { StickerManagementPageProps } from './interface'

const PER_PAGE = 24
const REMOVE_MESSAGE_SUCCESS = 'ลบสติ๊กเกอร์สำเร็จ'

export function withStickerManagementPage(
  Component: React.FC<StickerManagementPageProps>
) {
  function WithStickerManagementPage() {
    const navigate = useNavigate()
    const client = useClient()
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const { page, total, perpage, handleTotalChange, pageChange } =
      usePagination({ perpage: PER_PAGE })
    const { data = [], refetch } = useQuery(
      ['sticker-list', 'page', page, 'perpage', PER_PAGE],
      () =>
        client?.stickerClient
          .getStickerList({ page, perpage: PER_PAGE })
          .then(res => {
            handleTotalChange(res.total)

            return res.data
          })
    )

    const { mutate: removeSticker } = useMutation(
      (id: number) => client!.stickerClient.removeSticker(id),
      {
        onSuccess: () => {
          refetch()
          alert.success(REMOVE_MESSAGE_SUCCESS)
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: removeStickerList } = useMutation(
      (ids: number[]) => client!.stickerClient.removeStickerList(ids),
      {
        onSuccess: () => {
          refetch()
          alert.success(REMOVE_MESSAGE_SUCCESS)
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    function handleSelectItem(id: number) {
      const index = selectedIds.findIndex((val: number) => val === id)
      if (index !== -1) {
        setSelectedIds((prev: number[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds((prev: number[]) => [...prev, id])
      }
    }

    function handleEditSticker(id: number) {
      navigate({ pathname: `/chapter-book/sticker-management/${id}` })
    }

    function handleDeleteStickerSelected() {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบสติ๊กเกอร์หรือไม่?
            </p>
          </div>
        ),
        onConfirm: () => {
          removeStickerList(selectedIds)
          confirmModal.hide()
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    function handleDeleteSticker(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบสติ๊กเกอร์หรือไม่?
            </p>
          </div>
        ),
        onConfirm: () => {
          removeSticker(id)
          confirmModal.hide()
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const newProps = {
      data,
      selectedIds,
      page,
      perpage,
      total,
      handleSelectItem,
      handleEditSticker,
      handleDeleteSticker,
      handleDeleteStickerSelected,
      pageChange,
    }

    return <Component {...newProps} />
  }

  return WithStickerManagementPage
}
