import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'

import { PromotionSaleDetailType } from '@models/promotion/PromotionSaleDetailType'
import { PromotionSaleRequestType } from '@models/promotion/PromotionSaleRequestType'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { SaleTermUploadType } from '@models/promotion/sale/SaleTermUploadType'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { SortingType } from '@components/Table/interface'
import { PromotionSaleLogResponseType } from '@models/promotion/sale/PromotionSaleLogResponseType'
import { UPLOAD_PROMOTION_SALE } from './schemas/uploadPromotionSaleTerm'
import { CREATE_PROMOTION_SALE } from './schemas/createPromotionSale'
import { GET_PROMOTION_SALE_FORM } from './schemas/getPromotionSaleForm'
import { EDIT_PROMOTION_SALE } from './schemas/editPromotionSale'
import { GET_PROMOTION_SALE_DETAIL } from './schemas/getPromotionSaleDetail'
import { GET_PROMOTION_SALE_HISTORY_BY_ID } from './schemas/getPromotionSaleHistory'
import { GET_PROMOTION_SALE_FORM_DETAIL } from './schemas/getPromotionSaleFormDetail'
import { PROMOTION_SALE_LOGS } from './schemas/promotionSaleLogs'
import { EXPORT_PROMOTION_SALE } from './schemas/exportPromotionSale'

function transformPromotionSaleInput(values: PromotionSaleFormType) {
  const createPromotionSaleInput = {
    id: values.id,
    name: values.name,
    description: values.description,
    userTerm: values.userTerm,
    publishedType: values.publishedType,
    startPublishedAt:
      values.publishedType === PromotionPublishedEnum.PERIOD
        ? values.startPublishedAt
        : values.startNowAt,
    endPublishedAt: values.endPublishedAt,
    status: values.status,
    categoryIds:
      values.conditionType === PSConditionTypeEnum.ALL
        ? values.categoryIds?.map(Number)
        : undefined,
    useCoinConditionType: values.useCoinConditionType,
    termIds:
      values.conditionType !== PSConditionTypeEnum.ALL ? values.termIds : [],
    termCount:
      values.conditionType !== PSConditionTypeEnum.ALL
        ? values.termCount
        : undefined,
    conditionFilePath:
      values.conditionType !== PSConditionTypeEnum.ALL
        ? values.conditionFile?.url
        : undefined,
    conditionFileName:
      values.conditionType !== PSConditionTypeEnum.ALL
        ? values.conditionFile?.name
        : undefined,
    actionValue: values.actionValue,
    coinConditionIds: values.coinConditionIds.map(Number),
    usageDay: values.usageDay,
    conditionType: values.conditionType,
    benefitValue: Number(values.benefitValue),
    benefitCoinId: Number(values.benefitCoinId),
    benefitLimitType: values.benefitLimitType,
    benefitLimitValue: [
      PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
      PromotionChallengeLimitTypeEnum.PER_DAY,
    ].includes(values.benefitLimitType!)
      ? Number(values.benefitLimitValue)
      : undefined,
    budget: Number(values.budget),
    budgetPerDay: values.isLimitBudget
      ? Number(values.budgetPerDay)
      : undefined,
  }
  return createPromotionSaleInput
}

export class PromotionSaleClient {
  constructor(private client: GraphQLClient) {}

  async getPromotionSaleDetail(id: number): Promise<PromotionSaleDetailType> {
    const { promotion } = await this.client.request(GET_PROMOTION_SALE_DETAIL, {
      promotionId: id,
    })
    return plainToInstance(PromotionSaleDetailType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionSaleHistoryById(
    id: number
  ): Promise<PromotionSaleRequestType> {
    const { promotionRequest } = await this.client.request(
      GET_PROMOTION_SALE_HISTORY_BY_ID,
      {
        promotionRequestId: id,
      }
    )
    return plainToInstance(PromotionSaleRequestType, promotionRequest, {
      excludeExtraneousValues: true,
    })
  }

  async uploadPromotionSaleTerm({
    file,
    type,
  }: {
    file: File | Blob
    type: PSConditionTypeEnum
  }): Promise<SaleTermUploadType> {
    const res = await this.client.request(UPLOAD_PROMOTION_SALE, {
      type,
      file,
    })
    return plainToInstance(SaleTermUploadType, res.uploadPromotionSaleTerm)
  }

  async createPromotionSale(values: PromotionSaleFormType): Promise<number> {
    const createPromotionSaleInput = transformPromotionSaleInput(values)
    const {
      createPromotionSale: { id },
    } = await this.client.request(CREATE_PROMOTION_SALE, {
      createPromotionSaleInput,
    })
    return id
  }

  async editPromotionSale(values: PromotionSaleFormType): Promise<void> {
    const updatePromotionSaleInput = transformPromotionSaleInput(values)
    await this.client.request(EDIT_PROMOTION_SALE, {
      updatePromotionSaleInput,
    })
  }

  async getPromotionSaleForm(id: number): Promise<PromotionSaleFormType> {
    const { promotion } = await this.client.request(GET_PROMOTION_SALE_FORM, {
      promotionId: id,
    })
    return plainToInstance(PromotionSaleFormType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionSaleFormDetail(id: number): Promise<PromotionSaleFormType> {
    const {
      promotion: {
        lastRequest: { newPromotion },
      },
    } = await this.client.request(GET_PROMOTION_SALE_FORM_DETAIL, {
      promotionId: id,
    })
    return plainToInstance(PromotionSaleFormType, newPromotion)
  }

  async promotionSaleLogs({
    promotionSaleId,
    page,
    limitPerPage,
    searchText,
    sortKey,
    orderBy,
    startDate,
    endDate,
  }: {
    promotionSaleId: number
    page: number
    limitPerPage: number
    searchText: string
    sortKey: string
    orderBy?: SortingType
    startDate?: DateTime
    endDate?: DateTime
  }): Promise<PromotionSaleLogResponseType> {
    const { promotionSaleLogs } = await this.client.request(
      PROMOTION_SALE_LOGS,
      {
        promotionSaleId,
        page,
        searchText,
        limitPerPage,
        orderBy: sortKey ? { [sortKey]: orderBy } : undefined,
        startDate,
        endDate,
      }
    )
    return plainToInstance(PromotionSaleLogResponseType, promotionSaleLogs)
  }

  async exportPromotionSale({
    promotionSaleId,
    searchText,
    startDate,
    endDate,
    key,
    order,
  }: {
    promotionSaleId: number
    searchText: string
    startDate?: DateTime
    endDate?: DateTime
    key: string
    order: SortingType
  }): Promise<void> {
    await this.client.request(EXPORT_PROMOTION_SALE, {
      promotionSaleId,
      startDate,
      endDate,
      searchText,
      orderBy: key ? { [key]: order } : undefined,
    })
  }
}
