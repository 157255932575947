import React from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { DAY, getErrorMessage } from '@libs/utils'
import { MainBannerFormType } from '@models/mainBanner//MainBannerFormType'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { CreateMainBannerPageProps } from './interface'

export function withCreateMainBannerPage(
  Component: React.FC<CreateMainBannerPageProps>
) {
  function WithCreateMainBannerPage() {
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()
    const initialValues = plainToInstance(MainBannerFormType, {
      coverImgPath: '',
      description: '',
      rawLink: '',
      linkWeb: '',
      linkIos: '',
      linkAndroid: '',
      startPublishedAt: new Date().toString(),
      endPublishedAt: new Date(new Date(Date.now() + DAY * 30)).toString(),
      web: false,
      ios: false,
      android: false,
    })

    const { mutateAsync } = useMutation(
      (form: MainBannerFormType) =>
        client!.mainBannerClient.createMainBanner(form),
      {
        onSuccess: () => {
          navigate('/home-page/main-banner')
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: MainBannerFormType) {
      if ((form?.coverImg?.blob?.size || 0) > 10485760) {
        alert.error('File truncated as it exceeds the 10 mb size limit.')
        return
      }

      await mutateAsync(form)
    }

    const componentProps = {
      initialValues,
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithCreateMainBannerPage
}
