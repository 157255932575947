import { useState } from 'react'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { usePagination } from '@hooks/usePagination'
import { useAgeConfirmation } from '@hooks/ageConfirmation/useAgeConfirmation'
import { SortByType, SortingType } from '@components/Table/interface'
import { AgeConfirmationPageProps, SearchParamsType } from './interface'

export function withAgeConfirmationPage(
  Component: React.FC<AgeConfirmationPageProps>
) {
  function WithAgeConfirmationPage() {
    const [activeTab, setActiveTab] = useState<SearchUserStatusEnum>(
      SearchUserStatusEnum.ALL
    )
    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      text: '',
      sortKey: 'ageUploadedAt',
      sortOrder: SortingType.DESC,
    })

    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    function handleUpdateActiveTab(value: SearchUserStatusEnum) {
      setActiveTab(value)
      pageChange(1)
    }

    function handleSubmitSearch(text: string) {
      setSearchParams(prev => ({ ...prev, text }))
      pageChange(1)
    }

    function handleResetSearch() {
      setSearchParams(prev => ({ ...prev, text: '' }))
      pageChange(1)
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const {
      data,
      total: userTotal,
      isLoading,
    } = useAgeConfirmation({
      page,
      statusType: activeTab,
      searchText: searchParams.text,
      sortKey: searchParams.sortKey,
      sortOrder: searchParams.sortOrder,
      handleTotalChange,
    })

    const componentProps = {
      activeTab,
      searchParams,
      data: data || [],
      userTotal,
      page,
      perpage,
      total,
      isLoading,
      pageChange,
      handleSort,
      handleUpdateActiveTab,
      handleSubmitSearch,
      handleResetSearch,
    }
    return <Component {...componentProps} />
  }

  return WithAgeConfirmationPage
}
