import { gql } from 'graphql-request'

export const GET_ACTIVE_COINS = gql`
  query GetActiveCoins {
    coins(
      limitPerPage: 3
      orderBy: { id: ASC }
      where: { status: ACTIVE, visible: true }
    ) {
      data {
        id
        name
        isExpireByActivity
        isExpireByPaid
        editable
      }
    }
  }
`
