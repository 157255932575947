import cn from 'classnames'
import { useDropdown } from '@hooks/useDropdown'
import { DragIndicatorIcon } from '@components/Icons'
import { ActionSelectProps } from './interface'

export function ActionSelect({ className, handleDelete }: ActionSelectProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className={cn('flex justify-end relative', className)}>
      <button type='button' className={cn('h-[30px]')} onClick={toggleDropdown}>
        <div className='flex'>
          <DragIndicatorIcon width='20' height='20' />
        </div>
      </button>
      {isShowDropdown && (
        <div
          ref={dropdownRef}
          className='absolute w-[165px] z-10 text-[14px] font-[300] py-[10px] text-black-480 mt-[30px] right-0 bg-white shadow  rounded-[8px] max-h-[400px] overflow-y-auto'
        >
          <div
            className='flex items-center hover:bg-blue-8 hover:text-primary cursor-pointer py-[5px] px-[15px]'
            onClick={() => {
              handleDelete()
            }}
          >
            ลบ
          </div>
        </div>
      )}
    </div>
  )
}
