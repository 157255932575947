import { ActivityEnum } from '@interfaces/ActivityEnum'
import { CoinLogTypeEnum } from '@interfaces/coin/CoinLogTypeEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { DetailProps } from '../interface'

export function Detail({
  amount,
  type,
  platform,
  activity,
  reference,
}: DetailProps) {
  if (type === CoinLogTypeEnum.USE && activity === ActivityEnum.BUY_CHAPTER) {
    const descriptions = reference?.name.split('\n')

    return (
      <div className='flex text-black-480 text-xs font-light'>
        {descriptions?.map((row, index) => (
          <div key={index} className='line-clamp-1 break-all leading-[1.8]'>
            {row}&nbsp;
          </div>
        ))}
      </div>
    )
  }

  if (type === CoinLogTypeEnum.USE && activity === ActivityEnum.DONATION) {
    return (
      <div className='flex text-black-480 text-xs font-light line-clamp-1 break-word'>
        <div className='pr-[10px]'>สนับสนุนตัวละคร {reference?.name}</div>
      </div>
    )
  }

  if (
    type === CoinLogTypeEnum.RECEIVE &&
    activity === ActivityEnum.BUY_COIN &&
    !reference?.coupon
  ) {
    return (
      <div className='flex divide-gray-3 divide-x text-black-480 text-xs font-light'>
        <div className='pr-[10px]'>{`ซื้อเหรียญผ่าน${
          platform === PlatformEnum.WEB ? 'เว็บ' : 'แอป'
        }`}</div>
        <div className='px-[10px]'>{reference?.name}</div>
        <div className='pl-[10px]'>{amount.toLocaleString()} บาท</div>
      </div>
    )
  }

  if (activity === ActivityEnum.BUY_COIN && reference?.coupon) {
    return (
      <div className='grid text-black-480 text-xs font-light gap-y-[10px]'>
        <div className='flex items-center divide-gray-3 divide-x mobile:justify-start'>
          <div className='pr-[10px]'>{`ซื้อเหรียญผ่าน${
            platform === PlatformEnum.WEB ? 'เว็บ' : 'แอป'
          }`}</div>
          <div className='px-[10px]'>{reference?.name}</div>
          <div className='pl-[10px]'>{amount.toLocaleString()} บาท</div>
        </div>
        <div className='flex space-x-[5px]'>
          <div className='flex'>โปรโมชั่น {reference?.promotionName}</div>
        </div>
      </div>
    )
  }

  if (activity === ActivityEnum.REDEEM && reference?.coupon) {
    return (
      <div className='flex text-black-480 text-xs font-light space-x-[10px]'>
        <div className='text-right'>ได้รับเหรียญฟรี(Gift Code)</div>
        <div className='flex items-center space-x-[5px]'>
          <div className='flex ml-auto line-clamp-1'>
            โปรโมชั่น {reference?.promotionName}
          </div>
        </div>
      </div>
    )
  }

  if (type === CoinLogTypeEnum.RECEIVE && activity === ActivityEnum.EVENT) {
    return (
      <div className='flex text-black-480 text-xs font-light divide-gray-3 divide-x'>
        <div>{reference?.name}</div>
      </div>
    )
  }

  if (activity === ActivityEnum.EXPIRED) {
    return (
      <div className='flex text-black-480 text-xs font-light'>
        {reference?.name}
      </div>
    )
  }

  if (activity === ActivityEnum.FORFEITED && reference?.name) {
    const [text1, text2, transactions] = reference.name.split(' ')
    if (text1 && text2 && transactions)
      return (
        <div className='text-black-480 text-xs font-light'>
          {`${text1} ${text2}`}
          <div>{transactions.replaceAll(',', ', ')}</div>
        </div>
      )
  }

  return null
}
