import cn from 'classnames'

import { addComma } from '@libs/utils'
import { ReportSummaryDetailProps } from './interface'

export function ReportSummaryDetail({
  className,
  totalBook,
  totalChapter,
  totalReader,
  totalDownload,
  totalCost,
  totalProfit,
}: ReportSummaryDetailProps) {
  return (
    <div
      className={cn(
        'flex justify-between text-[14px] font-light text-secondary-100 py-[20px]',
        className
      )}
    >
      <div className='flex gap-x-[60px]'>
        <div className='flex flex-col'>
          <span>จำนวนเรื่อง</span>
          <span className='font-bold text-[24px]'>
            {totalBook.toLocaleString()}
          </span>
        </div>
        <div className='flex flex-col'>
          <span>จำนวนตอน</span>
          <span className='font-bold text-[24px]'>
            {totalChapter.toLocaleString()}
          </span>
        </div>
        <div className='flex flex-col'>
          <span>การอ่าน</span>
          <span className='font-bold text-[24px]'>
            {totalReader.toLocaleString()}
          </span>
        </div>
        <div className='flex flex-col'>
          <span>ดาวน์โหลด</span>
          <span className='font-bold text-[24px]'>
            {totalDownload.toLocaleString()}
          </span>
        </div>
      </div>
      <div className='flex gap-x-[60px]'>
        <div className='flex flex-col'>
          <span>ค่าใช้จ่ายรวม</span>
          <span className='font-bold text-[24px] text-secondary'>
            {addComma(totalCost)}
          </span>
        </div>
        <div className='flex flex-col'>
          <span>กำไร</span>
          <span className='font-bold text-[24px] text-primary'>
            {addComma(totalProfit)}
          </span>
        </div>
      </div>
    </div>
  )
}
