import { useMemo } from 'react'
import { DateTime } from 'luxon'

import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'
import { ColumnType } from '@components/Table/interface'
import { addComma } from '@libs/utils'
import { PromotionChallengeLogType } from '@models/promotion/challenge/PromotionChallengeLogType'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { NoData } from '@components/NoData'
import { ActionCell } from './components/ActionCell/ActionCell'
import { PromotionChallengeTransactionTableProps } from './inteface'
import { actionColums } from './actionColumns'

function columnByAction(action: PromotionChallengeActionEnum) {
  let column: ColumnType<PromotionChallengeLogType>[] = [
    {
      column: 'User ID',
      accessor: 'userId',
      cell: ({ value }) => <p>{value}</p>,
      isSortable: true,
    },
    {
      column: 'ผู้ใช้',
      accessor: 'username',
      cell: ({ value }) => <p>{value}</p>,
      isSortable: true,
    },
    {
      column: 'วันที่ได้รับเหรียญ',
      accessor: 'createdAt',
      cell: ({ value }) => (
        <p>
          {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
            locale: 'th-Th',
          })}
        </p>
      ),
      isSortable: true,
    },
    ...actionColums[action],
    {
      column: 'จำนวนเหรียญ',
      accessor: 'coinReceived',
      cell: ({ value }) => <p className=''>{addComma(value)}</p>,
      cellClassName: 'p-[10px]',
      isSortable: true,
    },
    {
      column: 'วันที่เหรียญหมดอายุ',
      accessor: 'expiredAt',
      cell: ({ value }) => (
        <p>
          {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
            locale: 'th-Th',
          })}
        </p>
      ),
      isSortable: true,
    },
    {
      column: 'Action',
      actionCell: ({ obj }) => (
        <ActionCell id={obj.userId} className='!py-[8px]' />
      ),
      isAction: true,
      cellClassName: 'flex justify-end',
    },
  ]

  if (
    [
      PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
      PromotionChallengeActionEnum.CREATE_BOOK,
    ].includes(action)
  ) {
    column = [
      {
        column: 'Writer ID',
        accessor: 'writerId',
        cell: ({ value }) => <p>{value}</p>,
        isSortable: true,
      },
      {
        column: 'ชื่อผู้ใช้งาน',
        accessor: 'username',
        cell: ({ value }) => <p>{value}</p>,
        isSortable: false,
      },
      {
        column: 'วันที่ได้รับเหรียญ',
        accessor: 'createdAt',
        cell: ({ value }) => (
          <p>
            {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm', {
              locale: 'th-Th',
            })}
          </p>
        ),
        isSortable: true,
      },
      ...actionColums[action],
      {
        column: 'จำนวนเหรียญ',
        accessor: 'coinReceived',
        cell: ({ value }) => <p className=''>{addComma(value)}</p>,
        cellClassName: 'p-[10px]',
        isSortable: false,
      },
      {
        column: 'วันที่เหรียญหมดอายุ',
        accessor: 'expiredAt',
        cell: ({ value }) => (
          <p>
            {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm', {
              locale: 'th-Th',
            })}
          </p>
        ),
        isSortable: false,
      },
      {
        column: 'Action',
        actionCell: ({ obj }) => (
          <ActionCell id={obj.userId} className='!py-[8px]' />
        ),
        isAction: true,
        cellClassName: 'flex justify-end',
      },
    ]
  }
  return column
}

function PromotionChallengeTransactionTable({
  data,
  page,
  perpage,
  total,
  action,
  isLoading,
  pageChange,
  handleSort,
}: PromotionChallengeTransactionTableProps) {
  const columns: ColumnType<PromotionChallengeLogType>[] = useMemo(() => {
    return columnByAction(action)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        className='challenge'
      />

      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}

export default PromotionChallengeTransactionTable
