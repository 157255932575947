import React from 'react'
import cn from 'classnames'
import { Container } from '@components/Container'
import StatusButton from '@components/StatusButton'
import { Button } from '@components/Button'
import { BinIcon, PenIcon, Upload2Icon } from '@components/Icons'
import { Checkbox } from '@components/Checkbox'
import { CategoryCoverType } from '@models/category/CategoryCoverType'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CategoryCoverPageProps } from './interface'

function CategoryCoverPage({
  category,
  goToCreateCoverPage,
  selectedCoverIds,
  handleSelectCover,
  goToEditCoverPage,
  handleRemoveCategoryCover,
}: CategoryCoverPageProps) {
  const permission = usePermissionAction('chapterBook')
  return (
    <Container>
      <Container.Title showBackBtn>
        <div className='flex items-center w-full'>
          <div className='flex-1'>{category.name}</div>
          {permission.delete && (
            <StatusButton
              type='button'
              status='delete'
              onClick={() => handleRemoveCategoryCover()}
              disabled={!selectedCoverIds.length}
            />
          )}
          <Button
            type='button'
            className={cn(
              'text-[14px] text-white bg-secondary ml-[20px] w-[130px]',
              {
                hidden: !permission.create,
              }
            )}
            onClick={goToCreateCoverPage}
          >
            + เพิ่มปก
          </Button>
        </div>
      </Container.Title>
      <div>
        {!!category.categoryCovers.length ? (
          <div className='flex flex-wrap gap-x-[15px] gap-y-[33px]'>
            {category.categoryCovers.map(
              (cover: CategoryCoverType, i: number) => (
                <div key={cover.id}>
                  <div className='w-[159px] h-[228px] rounded-[8px]'>
                    <img
                      src={cover.imgPath}
                      className='w-full h-full rounded-[8px]'
                      alt='Cover'
                    />
                  </div>
                  <div className='font-bold text-sm py-[14px] mb-[13px] border-b text-secondary'>
                    {cover.coverName}
                  </div>
                  <div className='flex items-center'>
                    <div
                      className={cn('py-[3px] border-r pr-[10px] mr-[10px]', {
                        hidden: !permission.delete,
                      })}
                    >
                      <Checkbox
                        id='select'
                        checked={selectedCoverIds.includes(cover.id)}
                        onChange={() => handleSelectCover(cover.id)}
                      />
                    </div>
                    <div
                      className={cn(
                        'border p-[4px] rounded-[8px] cursor-pointer mr-[10px]',
                        {
                          hidden: !permission.edit,
                        }
                      )}
                      onClick={() => goToEditCoverPage(cover.id)}
                    >
                      <PenIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </div>
                    <div
                      className={cn(
                        'border p-[4px] rounded-[8px] cursor-pointer',
                        {
                          hidden: !permission.delete,
                        }
                      )}
                      onClick={() => handleRemoveCategoryCover(cover.id)}
                    >
                      <BinIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div
            className={cn(
              'flex text-black-480 flex-col items-center justify-center w-[159px] h-[228px] bg-gray rounded-[8px] cursor-pointer select-none',
              {
                hidden: !permission.create,
              }
            )}
            onClick={goToCreateCoverPage}
          >
            <Upload2Icon width='24' height='24' />
            <span className='underline font-mitr text-sm font-medium'>
              เพิ่มปก
            </span>
          </div>
        )}
      </div>
    </Container>
  )
}

export default CategoryCoverPage
