import React from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { MainBannerFormType } from '@models/mainBanner//MainBannerFormType'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { EditMainBannerPageProps } from './interface'

export function withEditMainBannerPage(
  Component: React.FC<EditMainBannerPageProps>
) {
  function WithEditMainBannerPage() {
    const { id } = useParams()
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()
    const { data, isFetching } = useQuery(
      ['main-banner', id],
      () => client?.mainBannerClient.getMainBanner(Number(id)),
      { enabled: !!id }
    )

    const { mutateAsync } = useMutation(
      (form: MainBannerFormType) =>
        client!.mainBannerClient.updateMainBanner(Number(id), form),
      {
        onSuccess: () => {
          navigate('/home-page/main-banner')
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: MainBannerFormType) {
      if ((form?.coverImg?.blob?.size || 0) > 10485760) {
        alert.error('File truncated as it exceeds the 10 mb size limit.')
        return
      }

      await mutateAsync(form)
    }

    if (isFetching) return null

    const componentProps = {
      initialValues: plainToInstance(MainBannerFormType, data),
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithEditMainBannerPage
}
