import { withPermissionGuard } from '@libs/permission'
import { CoinDetailTable } from './CoinDetailTable'
import { withCoinDetailTable } from './withCoinDetailTable'

const ConnectedCoinDetailTable = withPermissionGuard(
  withCoinDetailTable(CoinDetailTable),
  'coin',
  'view'
)

export { ConnectedCoinDetailTable as CoinDetailTable }
