import { withPermissionGuard } from '@libs/permission'
import { AdminUserManagement } from './AdminUserManagement'
import { withAdminUserManagement } from './withAdminUserManagement'

const ConnectedAdminUserManagement = withPermissionGuard(
  withAdminUserManagement(AdminUserManagement),
  'admin',
  'view'
)

export { ConnectedAdminUserManagement as AdminUserManagement }
