import { Expose, Type } from 'class-transformer'

export class UserOptionType {
  @Expose() avatarImgPath: string

  @Expose() username: string

  @Expose() id: number
}

export class UserOptionResponse {
  @Expose() page: number

  @Expose() total: number

  @Expose()
  @Type(() => UserOptionType)
  data: UserOptionType[]
}
