import { BookChapterViewerProps } from './interface'

export function BookChapterViewer({
  totalViewer,
  uniqueView,
}: BookChapterViewerProps) {
  return (
    <>
      <div className='flex text-[14px] font-light text-secondary-100 pt-[30px]'>
        <div className='flex gap-x-[60px]'>
          <div className='flex flex-col'>
            <span>Unique Viewer</span>
            <span className='font-bold text-[24px] text-secondary'>
              {uniqueView.toLocaleString()}
            </span>
          </div>
          <div className='flex flex-col'>
            <span>Buy All Chapter</span>
            <span className='font-bold text-[24px] text-secondary'>
              {totalViewer.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
