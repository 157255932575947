import { gql } from 'graphql-request'

export const GET_ANALYTICS_EBOOK_DETAIL = gql`
  query AnalyticsEbookDetail(
    $reportType: WriterSalesAnalyticsEbookDetailReportType!
    $ebookId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: GetAnalyticsEbookDetailOrderBy
  ) {
    analyticsEbookDetail(
      reportType: $reportType
      ebookId: $ebookId
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
    ) {
      graphData {
        date
        value
      }
      graphType
      tableData {
        category
        coverImgPath
        discountedPrice
        discountCoin
        discountPercent
        ebookTitle
        penName
        promotionDiscountValue
        promotionEndedAt
        promotionStartedAt
        promotionStatus
        promotionType
        rawPrice
        total
      }
    }
  }
`
