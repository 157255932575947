import { addComma } from '@libs/utils'
import { AddIcon } from '@components/Icons'
import { ChallengeUnitValue } from '@interfaces/user-mission/ChallengeEnum'

import { CreateSectionLogProps } from './interface'

export function CreateSectionLog({
  level,
  levelName,
  target,
  criteria,
  challengeUnit,
}: CreateSectionLogProps) {
  return (
    <div className='flex items-center gap-x-[30px] font-light text-[14px] my-[20px]'>
      <span className='text-[12px] text-primary'>เพิ่ม Level</span>
      <div className='flex items-center justify-center'>
        <AddIcon width='18' height='18' className='text-primary' />
      </div>
      <div className='flex items-center gap-x-[10px] text-secondary'>
        <span className='text-[12px] text-secondary-100'>Level {level} :</span>
        <span>{levelName}</span>
        <span className='text-[12px] text-secondary-100'>{criteria}</span>
        {target && <span>{addComma(target, 0)}</span>}
        <span className='text-[12px] text-secondary-100'>
          {ChallengeUnitValue[challengeUnit]}
        </span>
      </div>
    </div>
  )
}
