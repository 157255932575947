import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { useFormikContext } from 'formik'
import React from 'react'
import { PromotionDetailProps, WithPromotionDetailProps } from './interface'

export function withPromotionDetail(Component: React.FC<PromotionDetailProps>) {
  function WithPromotionDetail({ ...props }: WithPromotionDetailProps) {
    const { values } = useFormikContext<PromotionCodeFormType>()

    const newProps = {
      values,
      ...props,
    }

    return <Component {...newProps} />
  }

  return WithPromotionDetail
}
