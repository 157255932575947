import cn from 'classnames'
import { TabSelectProps } from './interface'

export function TabSelect({
  className,
  options,
  handleUpdateActiveTab,
  activeTab,
}: TabSelectProps) {
  return (
    <div
      className={cn(
        'text-[14px] text-secondary font-light  relative flex',
        className
      )}
    >
      {options.map(item => (
        <button
          key={item.value}
          type='button'
          className={cn(
            'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
            {
              'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                item.value === activeTab,
              'text-secondary-100 font-light': item.value !== activeTab,
            }
          )}
          onClick={() => handleUpdateActiveTab(item.value)}
        >
          {item.label}
          <span className='font-light pl-[5px]'>
            ({item.total.toLocaleString()})
          </span>
        </button>
      ))}
      <div className='absolute w-full h-[1px] bottom-[1px] bg-gray z-0' />
    </div>
  )
}
