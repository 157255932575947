import { gql } from 'graphql-request'

export const GET_PROMOTION_CHALLENGE_DETAIL = gql`
  query PromotionChallengeDatail($promotionId: Float!) {
    promotion(id: $promotionId) {
      id
      businessId
      promotionChallenge {
        id
        action
        actionValue
        wordCount
        socialMediaChannels
        readCondition
        coinsCondition {
          id
          name
        }
        bookTypes
        benefitValue
        benefitCoinId
        benefitLimitType
        benefitLimitValue
        budget
        budgetPerDay
        categories {
          id
          name
        }
        summary {
          userCount
          progressCount
          coinValue
          coin {
            id
            name
          }
        }
      }
      name
      description
      userTerm
      startPublishedAt
      endPublishedAt
      publishedType
      type
      userType
      isFirstTopUp
      usageLimitPerUser
      isUsageLimitPerUser
      usageLimit
      isUsageLimit
      usageDay
      status
      users {
        id
        displayName
      }
      usersFileName
      usersFilePath
      isActive
      isEditable
      createdAt
      updatedAt
      deletedAt
      lastRequest {
        id
        createdBy {
          id
          displayName
          avatarImgPath
        }
        createdAt
        action
        newPromotion {
          id
          promotionChallenge {
            id
            action
            actionValue
            wordCount
            socialMediaChannels
            readCondition
            coinsCondition {
              id
            }
            bookTypes
            benefitValue
            benefitCoinId
            benefitLimitType
            benefitLimitValue
            budget
            budgetPerDay
            categories {
              id
            }
          }
          name
          description
          userTerm
          startPublishedAt
          endPublishedAt
          publishedType
          type
          userType
          isFirstTopUp
          usageLimitPerUser
          isUsageLimitPerUser
          usageLimit
          isUsageLimit
          usageDay
          status
          users {
            id
          }
          isActive
          isEditable
          createdAt
        }
      }
    }
  }
`
