import { EyeIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'
import { EbookDashboardByUserType } from '@models/dashboard/EbookDashboardByUserType'
import { Link } from 'react-router-dom'

export const columns: ColumnType<EbookDashboardByUserType>[] = [
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img
          className='w-[29px] h-[41px] object-cover shrink-0'
          src={value}
          alt='cover'
        />
      ) : (
        <div className='w-[29px] h-[41px] object-cover shrink-0' />
      ),
    cellClassName: 'w-[40px] max-w-[40px] pr-[10px]',
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => (
      <p className='w-[170px] line-clamp-2 pr-[5px] leading-[1.8]'>{value}</p>
    ),
    isSortable: true,
  },
  {
    column: 'ประเภท',
    accessor: 'ebookType',
    cell: ({ value }) => <p>อีบุ๊ก{bookTypeValue[value as BookEnum]}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'penName',
    cell: ({ value }) => <p className='line-clamp-1 leading-[1.8]'>{value}</p>,
    sortKey: 'penName',
    cellClassName: 'w-[110px] max-w-[110px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'ยอดขาย',
    accessor: 'totalSales',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Download',
    accessor: 'downloadedCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'ที่อยากได้',
    accessor: 'wishlistCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'ทดลองอ่าน',
    accessor: 'readTrialChapterCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
  },
  {
    column: 'รีวิว',
    accessor: 'reviewCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => (
      <div className='flex gap-x-[10px]'>
        <Link to={`/ebook/${value}`}>
          <button
            className='border border-gray h-[30px] px-[4px] rounded-[8px]'
            type='button'
          >
            <EyeIcon className='text-black-970' width='18' height='18' />
          </button>
        </Link>
      </div>
    ),
  },
]
