import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { plainToInstance } from 'class-transformer'
import { useMutation, useQuery } from 'react-query'
import { CoverFormType } from '@models/cover/CoverFormType'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { CoverManageProps } from './interface'

const withCoverManage = (Component: React.FC<CoverManageProps>) => {
  function WithCoverManage() {
    const { coverId, id } = useParams()
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState<CoverFormType>()

    useQuery(
      ['cover', coverId],
      () => client?.categoryClient.categoryCover(Number(coverId)),
      {
        enabled: !!coverId,
        onSuccess: data => {
          setInitialValues(plainToInstance(CoverFormType, data))
        },
      }
    )

    const { mutate: createCategoryCover } = useMutation(
      (form: CoverFormType) => client!.categoryClient.createCategoryCover(form),
      {
        onSuccess: () => {
          alert.success('เพิ่มปกหนังสือสำเร็จ')
          navigate(`/chapter-book/cover/${id}`)
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
        },
      }
    )

    const { mutate: updateCategoryCover } = useMutation(
      (form: CoverFormType) => client!.categoryClient.updateCategoryCover(form),
      {
        onSuccess: () => {
          alert.success('แก้ไขปกหนังสือสำเร็จ')
          navigate(`/chapter-book/cover/${id}`)
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
        },
      }
    )

    const onSubmitCategoryCover = (form: CoverFormType) => {
      if (coverId) {
        updateCategoryCover({ ...form, id: Number(coverId) })
      } else {
        createCategoryCover(form)
      }
    }

    useEffect(() => {
      if (!coverId) {
        setInitialValues({
          coverName: '',
          coverImage: { url: '' },
          categoryId: Number(id),
        })
      }
    }, [coverId, id])

    if (initialValues) {
      const newProps = { initialValues, coverId, onSubmitCategoryCover }
      return <Component {...newProps} />
    }
    return <></>
  }

  return WithCoverManage
}

export default withCoverManage
