import { Expose, Type } from 'class-transformer'
import { ReportPublishLogType } from './ReportPublishLogType'

export class ReportPublishLogListType {
  @Expose() total: number

  @Expose() page: number

  @Expose()
  @Type(() => ReportPublishLogType)
  data: ReportPublishLogType[]
}
