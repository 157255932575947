import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WriterPublisherPageProps } from './interface'

export function withWriterPublisherPage(
  Component: React.FC<WriterPublisherPageProps>
) {
  function WithWriterPublisherPage() {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const tab = params.get('tab') || 'overview'

    const [tabActive, setTabActive] = useState(tab)

    function handleSelectTab(active: string) {
      setTabActive(active)
      navigate({
        pathname: '',
        search: `?tab=${active}`,
      })
    }
    useEffect(() => {
      setTabActive(tab)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    const newProps = {
      tabActive,
      handleSelectTab,
    }
    return <Component {...newProps} />
  }

  return WithWriterPublisherPage
}
