import { withPermissionGuard } from '@libs/permission'
import { EditHighlightBannerPosterPage } from './EditHighlightBannerPosterPage'
import { withEditHighlightBannerPosterPage } from './withEditHighlightBannerPosterPage'

const Connected = withPermissionGuard(
  withEditHighlightBannerPosterPage(EditHighlightBannerPosterPage),
  'homePageManagement',
  'edit'
)

export { Connected as EditHighlightBannerPosterPage }
