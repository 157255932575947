import { addComma } from '@libs/utils'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { Tab, TabBody, TabHeader, Tabs } from '@components/Tabs'
import { columns } from './components/WriterSectionColumn'
import { StoryTableTemplate } from '../StoryTableTemplate.tsx'
import { WriterSectionProps } from './interface'

const tabs = [
  { value: '10', label: 'Top 10' },
  { value: '20', label: 'Top 20' },
  { value: '100', label: 'Top 100' },
]

export function WriterSection({
  data,
  income,
  initialActiveTab,
  handleSort,
  handleChangeTab,
  bookTypeChange,
  categoryChange,
  isLoading,
}: WriterSectionProps) {
  return (
    <div>
      <div className='flex'>
        <div className='flex-1 font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          สัดส่วนรายได้จากงานเขียน
        </div>
        <div className='text-[14px] text-secondary-100 font-light'>
          รายได้ทั้งหมด:
          <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
            {addComma(income)}
          </span>
          บาท
        </div>
      </div>
      <Tabs
        initialActiveTab={initialActiveTab}
        mode='line'
        onActiveTabChange={res => handleChangeTab(Number(res))}
      >
        {tabs.map((tab, i) => (
          <Tab key={i} tabId={tab.value}>
            <TabHeader>
              <span>{tab.label}</span>
            </TabHeader>
            <TabBody>
              <StoryTableTemplate
                bookTypeChange={bookTypeChange}
                categoryChange={categoryChange}
              >
                <Table
                  data={data}
                  columns={columns}
                  fixedHeader
                  onSort={handleSort}
                  initialSort={{ key: 'rank', order: SortingType.ASC }}
                  className='h-[355px] max-h-[355px] pr-[20px]'
                  isLoading={isLoading}
                />
              </StoryTableTemplate>
            </TabBody>
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}
