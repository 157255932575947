export function Typography({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) {
  return (
    <div className='flex flex-col gap-y-[4px]'>
      <span className='text-[14px] font-light text-secondary-100'>{label}</span>
      <p className='text-[24px] font-bold text-secondary'>{children}</p>
    </div>
  )
}
