import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'

import {
  TopTimeSpenderTableProps,
  WithTopTimeSpenderTableProps,
} from './interface'

export function withTopTimeSpenderTable(
  Component: React.FC<TopTimeSpenderTableProps>
) {
  function WithTopTimeSpenderTable({
    searchParams,
  }: WithTopTimeSpenderTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'userTimeSpend',
      sortOrder: 'DESC',
    })

    const { data = [], isLoading } = useQuery(
      ['reader-top-time-spend', searchParams, sort],
      () =>
        client?.dashboardClient.getReaderTopTimeSpend({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          key: sort.sortKey,
          order: sort.sortOrder,
        }),
      {
        staleTime: Infinity,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const newProps = { data, handleSort, isLoading }

    return <Component {...newProps} />
  }

  return WithTopTimeSpenderTable
}
