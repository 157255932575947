import { useMemo } from 'react'
import cn from 'classnames'

import { BinIcon, EditIcon, SalePromotionIcon } from '@components/Icons'
import { Toggle } from '@components/Toggle'
import {
  promotionChallengeBenefitLimitTypeText,
  promotionStatusText,
  transformDate,
} from '@libs/promotion/utils'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import {
  promotionSaleConditionValue,
  PSConditionTypeEnum,
} from '@interfaces/promotionSale/PSConditionTypeEnum'
import { daySelectTypeValue } from '@interfaces/promotionCode/DaySelectEnum'
import { promotionSaleCoinConditionValue } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PromotionChallengeInfoProps } from './interface'

function Text({ children, className }: FunctionComponentType) {
  return <span className={cn('font-light', className)}>{children}</span>
}

function PromotionSaleInfo({
  status,
  detail,
  isShowActionMenu,
  handleActivePromotion,
  handleEditPromotion,
  handleDeletePromotion,
}: PromotionChallengeInfoProps) {
  const condition = useMemo(() => {
    if (detail?.promotionSale.conditionType === PSConditionTypeEnum.ALL) {
      return {
        label: 'หมวดหมู่ :',
        value: (
          <Text className='text-[14px] '>
            {detail.promotionSale.categories
              .flatMap(row => row.name)
              .join(', ')}
          </Text>
        ),
      }
    }

    if (
      detail?.promotionSale.conditionType === PSConditionTypeEnum.STORY ||
      detail?.promotionSale.conditionType === PSConditionTypeEnum.WRITER
    ) {
      return {
        label: 'Upload file :',
        value: (
          <>
            <Text className='text-[14px] '>
              {detail.promotionSale.conditionFileName || '-'}
            </Text>
            <Text className='text-[12px] text-secondary-100'>
              &nbsp;&nbsp;({detail.promotionSale.termCount.toLocaleString()} ID)
            </Text>
          </>
        ),
      }
    }

    return { label: '', value: '' }
  }, [detail])

  if (!detail) return null

  return (
    <div className='space-y-[20px] text-secondary'>
      <div className='flex space-x-[20px] items-center'>
        <div className='flex items-center space-x-[10px] font-mitr text-[14px] text-primary pr-[20px] border-r border-r-gray'>
          <SalePromotionIcon />
          <span>โปรโมชั่นการขาย</span>
        </div>
        <div
          className={cn(
            'text-[12px] font-bold px-[8px] py-[4px] rounded-[4px]',
            {
              'bg-blue-2 text-secondary':
                status === PromotionStatusEnum.PENDING,
              'bg-red-74 text-white': status === PromotionStatusEnum.REJECTED,
              'bg-secondary text-white':
                status === PromotionStatusEnum.APPROVED,
              'bg-green-986 text-white': status === PromotionStatusEnum.STARTED,
              'border border-gray-393 text-secondary':
                status === PromotionStatusEnum.DRAFT,
              'bg-secondary-100 text-white':
                status === PromotionStatusEnum.CLOSED,
            }
          )}
        >
          {promotionStatusText(status)}
        </div>
        <div className='flex-1 text-[18px] font-bold line-clamp-1'>
          {detail.name}
        </div>
        <Toggle
          className={cn({ hidden: !isShowActionMenu })}
          checked={detail.isActive}
          disabled={!handleActivePromotion}
          onChange={handleActivePromotion}
        />
        <div
          className={cn('flex items-center space-x-[10px]', {
            hidden: !isShowActionMenu,
          })}
        >
          <button
            type='button'
            className='flex items-center justify-center border border-blue-2 rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed'
            disabled={!handleEditPromotion}
            onClick={handleEditPromotion}
          >
            <EditIcon width='18' height='18' className='text-secondary-100' />
          </button>
          <button
            type='button'
            className='flex items-center justify-center border border-blue-2 rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed'
            disabled={!handleDeletePromotion}
            onClick={handleDeletePromotion}
          >
            <BinIcon width='18' height='18' className='text-secondary-100' />
          </button>
        </div>
      </div>
      <hr className='text-gray' />
      <div className='flex space-x-[20px]'>
        <div className='flex text-[14px] font-bold space-x-[4px]'>
          <Text className='text-secondary-100 text-[12px] whitespace-nowrap'>
            Promotion ID :
          </Text>
          <span>{detail.businessId}</span>
        </div>
        <div className='flex text-[14px] font-bold space-x-[4px]'>
          <Text className='text-secondary-100 text-[12px] whitespace-nowrap'>
            Create date :
          </Text>
          <span>{transformDate(detail.createdAt)}</span>
        </div>
        <div className='flex text-[14px] font-bold space-x-[4px]'>
          <Text className='text-secondary-100 text-[12px] whitespace-nowrap'>
            ระยะเวลาเเคมเปญ :
          </Text>
          <span>
            {detail.startPublishedAt
              ? transformDate(detail.startPublishedAt)
              : 'ทันที'}
          </span>
          &emsp;
          <Text className='text-secondary-100 text-[12px] '>ถึง</Text>
          &emsp;
          <span>{transformDate(detail.endPublishedAt)}</span>
        </div>
      </div>
      <hr className='text-gray' />
      <div className='grid grid-cols-5 gap-y-[20px]'>
        <Text className='text-secondary-100 text-[12px]'>รายละเอียด :</Text>
        <div className='text-[14px] font-light col-span-4'>
          {detail.description}
        </div>
        <Text className='text-secondary-100 text-[12px]'>
          เงื่อนไขการใช้งาน :
        </Text>
        <div className='text-[14px] font-light col-span-4 whitespace-pre-wrap'>
          {detail.userTerm}
        </div>
        <hr className='col-span-5 text-gray' />
        <Text className='text-secondary-100 text-[12px]'>
          เงื่อนไขการซื้อ :
        </Text>
        <div className='flex col-span-4 space-x-[40px]'>
          <div className='flex space-x-[10px] whitespace-nowrap'>
            <Text className='text-secondary-100 text-[12px]'>ใน :</Text>
            <Text className='text-[14px]'>
              {promotionSaleConditionValue[detail.promotionSale.conditionType]}
            </Text>
          </div>
          <div className='flex space-x-[10px]'>
            <Text className='text-secondary-100 whitespace-nowrap text-[12px]'>
              {condition.label}
            </Text>
            {condition.value}
          </div>
        </div>
        <Text />
        <div className='flex flex-wrap col-span-4 gap-x-[40px] gap-y-[20px]'>
          <div className='flex items-center space-x-[10px] whitespace-nowrap'>
            <Text className='text-secondary-100 text-[12px]'>จำนวน :</Text>
            <Text className='text-[14px]'>
              {detail.promotionSale.actionValue.toLocaleString()}
            </Text>
            <Text className='text-secondary-100 text-[12px]'>เหรียญขึ้นไป</Text>
            <Text className='text-secondary-100 text-[12px]'>
              (
              {detail.promotionSale.coinsCondition
                .flatMap(row => row.name)
                .join(', ')}
              )
            </Text>
          </div>
          <div className='flex items-center space-x-[10px]'>
            <Text className='text-secondary-100 whitespace-nowrap text-[12px]'>
              ใช้ได้ในวัน :
            </Text>
            <Text className='text-[14px]'>
              {detail.usageDay.length === 7
                ? 'ทุกวัน'
                : detail.usageDay
                    .map(row => daySelectTypeValue[row])
                    .join(', ')}
            </Text>
          </div>
          <div className='flex items-center space-x-[10px] whitespace-nowrap'>
            <Text className='text-secondary-100 text-[12px]'>ใช้เหรียญ :</Text>
            <Text className='text-[14px]'>
              {
                promotionSaleCoinConditionValue[
                  detail.promotionSale.useCoinConditionType
                ]
              }
            </Text>
          </div>
        </div>
        <hr className='col-span-5 text-gray' />
        <Text className='text-secondary-100 text-[12px] self-center'>
          รับเหรียญ :
        </Text>
        <div className='flex col-span-4 space-x-[40px]'>
          <div className='flex items-center space-x-[10px]'>
            <Text className='text-[14px]'>
              {detail.promotionSale.benefitValue}
            </Text>
            <Text className='text-secondary-100 text-[12px]'>
              เหรียญ{detail.promotionSale.benefitCoin}
            </Text>
          </div>
          <div className='flex space-x-[10px] items-center'>
            <Text className='text-secondary-100 text-[12px]'>
              เก็บเหรียญได้ :
            </Text>
            {detail.promotionSale.benefitLimitValue ? (
              <Text className='text-[14px]'>
                {detail.promotionSale.benefitLimitValue}
              </Text>
            ) : null}
            <Text className='text-[14px]'>
              {promotionChallengeBenefitLimitTypeText(
                detail.promotionSale.benefitLimitType
              )}
            </Text>
          </div>
        </div>
        <hr className='col-span-5 text-gray' />
        <Text className='text-secondary-100 text-[12px] self-center'>
          งบประมาณ/แคมเปญ :
        </Text>
        <div className='flex col-span-4 space-x-[40px]'>
          <div className='flex space-x-[10px] text-[14px] items-center'>
            <Text>{detail.promotionSale.budget.toLocaleString()}</Text>
            <Text className='text-secondary-100 text-[12px]'>
              เหรียญ{detail.promotionSale.benefitCoin}
            </Text>
          </div>
          <div
            className={cn('flex items-center space-x-[10px]', {
              hidden: !detail.promotionSale.budgetPerDay,
            })}
          >
            <Text className='text-secondary-100 text-[12px]'>จำกัดจำนวน :</Text>
            <Text className='text-[14px]'>
              {detail.promotionSale.budgetPerDay}
            </Text>
            <Text className='text-secondary-100 text-[12px]'>
              เหรียญ{detail.promotionSale.benefitCoin} / ต่อวัน
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromotionSaleInfo
