import { Node, mergeAttributes } from '@tiptap/core'
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react'
import { useRef } from 'react'

export interface ListItemOptions {
  HTMLAttributes: Record<string, any>
}

export function CustomListItem({ node }: any) {
  const liRef = useRef<any>(null)
  return (
    <NodeViewWrapper as='li' ref={liRef}>
      <div
        className='cursor-pointer'
        onClick={() => {
          const nodes = Array.prototype.slice.call(
            document.querySelector('#customOrderedList')?.children
          )
          const el = document.querySelector(
            `#footnote-${nodes.indexOf(liRef.current.parentElement) + 1}`
          )
          if (el) {
            el.scrollIntoView({ block: 'center' })
          }
        }}
      >
        <NodeViewContent />
      </div>
    </NodeViewWrapper>
  )
}

export const CustomListItemExtension = Node.create<ListItemOptions>({
  name: 'customListItem',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: 'paragraph block*',

  defining: true,

  parseHTML() {
    return [
      {
        tag: 'custom-list-item',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'custom-list-item',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomListItem)
  },
})
