import { Expose, Transform, Type } from 'class-transformer'
import { SalesByUserDetailTableResponse } from './SalesByUserDetailTableResponse'

export class SalesByUserDetailType {
  @Expose() userId: number

  @Expose() username: string

  @Expose()
  @Transform(({ value }) => value || 0)
  currentSales: number

  @Expose()
  @Transform(({ value }) => value || 0)
  currentDonation: number

  @Expose()
  @Transform(({ value }) => value || 0)
  currentSummary: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalIncome: number

  @Expose() detail: SalesByUserDetailTableResponse
}
