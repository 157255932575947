import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import {
  TopWriterByViewTableProps,
  WithTopWriterByViewTableProps,
} from './interface'

export function withTopWriterByViewTable(
  Component: React.FC<TopWriterByViewTableProps>
) {
  function WithTopWriterByViewTable({
    searchParams,
  }: WithTopWriterByViewTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'viewCount',
      sortOrder: 'DESC',
    })

    const { data = [], isLoading } = useQuery(
      ['writer-top-view', searchParams, sort],
      () =>
        client!.dashboardClient.getTopWriterByView({
          ...searchParams,
          ...sort,
        }),
      {
        enabled: !!searchParams,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort({ sortKey: key, sortOrder: order })
    }

    const newProps = { data, isLoading, handleSort }

    return <Component {...newProps} />
  }

  return WithTopWriterByViewTable
}
