import { Container } from '@components/Container'
import { NewsAndPromotionForm } from '@features/newsAndPromotion/components/NewsAndPromotionForm'
import { NewsAndPromotionDetailPageProps } from './interface'

export function NewsAndPromotionDetailPage({
  initialValues,
  updatedBy,
  handleSubmit,
  handleOpenNewsLogs,
}: NewsAndPromotionDetailPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>Promotion Detail</Container.Title>
      <NewsAndPromotionForm
        initialValues={initialValues}
        updatedBy={updatedBy}
        isEdit
        handleSubmit={handleSubmit}
        handleOpenNewsLogs={handleOpenNewsLogs}
      />
    </Container>
  )
}
