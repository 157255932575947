import { Container } from '@components/Container'

import { ActivityLogsTable } from '../ActivityLogsTable'
import { columns } from './columns'
import { ReportDetailActivityModalProps } from './interface'

function ReportDetailActivityModal({
  data,
  isLoading,
  handleFetchNextPage,
}: ReportDetailActivityModalProps) {
  return (
    <Container className='!w-[610px] !p-5'>
      <Container.Title className='text-center' showBackBtn={false}>
        ประวัติการดำเนินการ
      </Container.Title>
      <div className='max-h-[370px] overflow-y-auto'>
        <ActivityLogsTable
          data={data}
          isLoading={isLoading}
          columns={columns}
          onWayPointEnter={handleFetchNextPage}
        />
      </div>
    </Container>
  )
}

export default ReportDetailActivityModal
