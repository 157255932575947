import { withPermissionGuard } from '@libs/permission'
import { BanListPage } from './BanListPage'
import { withBanListPage } from './withBanListPage'

const ConnectedBanListPage = withPermissionGuard(
  withBanListPage(BanListPage),
  'homePageManagement',
  'view'
)

export { ConnectedBanListPage as BanListPage }
