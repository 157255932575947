import {
  OutlineEightIcon,
  OutlineFiveIcon,
  OutlineFourIcon,
  OutlineNineIcon,
  OutlineOneIcon,
  OutlineSevenIcon,
  OutlineSixIcon,
  OutlineTenIcon,
  OutlineThreeIcon,
  OutlineTwoIcon,
} from '@components/Icons'

export const chartNumbers = [
  <OutlineOneIcon width='100' height='152' className='text-primary' />,
  <OutlineTwoIcon width='100' height='152' className='text-primary' />,
  <OutlineThreeIcon width='100' height='152' className='text-primary' />,
  <OutlineFourIcon width='100' height='152' className='text-primary' />,
  <OutlineFiveIcon width='100' height='152' className='text-primary' />,
  <OutlineSixIcon width='100' height='152' className='text-primary' />,
  <OutlineSevenIcon width='100' height='152' className='text-primary' />,
  <OutlineEightIcon width='100' height='152' className='text-primary' />,
  <OutlineNineIcon width='100' height='152' className='text-primary' />,
  <OutlineTenIcon width='100' height='152' className='text-primary' />,
]
