import { gql } from 'graphql-request'

export const GET_MAIN_BANNER = gql`
  query GetMainBanner($id: Int!) {
    mainBanner(id: $id) {
      id
      web
      android
      ios
      isActiveWeb
      isActiveIos
      isActiveAndroid
      coverImgPath
      rawLink
      linkWeb
      linkIos
      linkAndroid
      description
      startPublishedAt
      endPublishedAt
      runningNo
    }
  }
`
