import { CoinHistorySearchFormProps } from './interface'

export function withCoinHistorySearchForm(
  Component: React.FC<CoinHistorySearchFormProps>
) {
  function WithCoinHistorySearchForm(props: CoinHistorySearchFormProps) {
    return <Component {...props} />
  }

  return WithCoinHistorySearchForm
}
