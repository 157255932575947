import { useState } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { SearchStoryParam } from '@interfaces/dashboard/StoryParam'
import { TopLoveSectionProps, WithTopLoveSectionProps } from './interface'

export function withTopLoveSection(Component: React.FC<TopLoveSectionProps>) {
  function WithTopLoveSection({ searchDateParams }: WithTopLoveSectionProps) {
    const client = useClient()
    const [searchParams, setSearchParams] = useState<SearchStoryParam>({
      ...searchDateParams,
      bookType: null,
      categoryId: null,
      sortKey: 'rank',
      sortOrder: SortingType.ASC,
    })

    const { data, isLoading } = useQuery(
      ['story-dashboard-top-love', searchParams, searchDateParams],
      () =>
        client?.dashboardClient.getStoryTopLove({
          ...searchParams,
          startDate: searchDateParams.startDate,
          endDate: searchDateParams.endDate,
        })
    )

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function bookTypeChange(bookType: BookEnum | null) {
      setSearchParams(prev => ({ ...prev, bookType }))
    }
    function categoryChange(categoryId: number) {
      setSearchParams(prev => ({ ...prev, categoryId: Number(categoryId) }))
    }

    const componentProps = {
      data: data || [],
      handleSort,
      bookTypeChange,
      categoryChange,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithTopLoveSection
}
