import { ColumnType } from '@components/Table/interface'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'
import { DateTime } from 'luxon'
import { BoughtEbookReaderType } from '@models/dashboard/BoughtEbookReaderResponse'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export const columns: ColumnType<BoughtEbookReaderType>[] = [
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img className='w-[29px] h-[41px]' alt='cover' src={value} />
      ) : (
        <div className='w-[29px] h-[41px] bg-gray' />
      ),
    cellClassName: 'w-[60px] min-w-[60px] pr-[5px]',
    isSortable: false,
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => <p className='w-[130px] line-clamp-2'>{value}</p>,
    isSortable: true,
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => (
      <p className='w-[100px]'>{bookTypeValue[value as BookEnum]}</p>
    ),
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    sortKey: 'firstPenName',
    cell: ({ value }) => <p className='w-[130px] line-clamp-1'>{value}</p>,
    isSortable: true,
  },

  {
    column: 'ราคาซื้อ',
    accessor: 'buyPrice',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[150px] max-w-[150px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'วันที่ซื้อ',
    accessor: 'createdAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy', {
          locale: 'th-Th',
        })}
      </div>
    ),
    cellClassName: 'w-[150px] max-w-[150px] pr-[5px]',
    isSortable: true,
    sortKey: 'buyDate',
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => (
      <div className='flex gap-x-[10px] py-[10px]'>
        <Link to={`/ebook/${value}`}>
          <button
            className='border border-gray h-[30px] px-[4px] rounded-[8px]'
            type='button'
          >
            <EyeIcon className='text-black-970' width='18' height='18' />
          </button>
        </Link>
      </div>
    ),
    cellClassName: 'flex justify-end',
    isAction: true,
  },
]
