import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box } from '@components/Box'
import { Checkbox } from '@components/Checkbox'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { InputField } from '@components/forms/InputField'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import {
  CodeChannelTypeEnum,
  CodeChannelTypeValue,
} from '@interfaces/promotionCode/CodeChannelTypeEnum '
import { SelectField } from '@components/forms/SelectField'
import { diffDate } from '@libs/utils'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionPublishingProps } from './interface'

export function PromotionPublishing({
  promotionChannelOption,
  disableEdit = false,
  viewMode = false,
}: PromotionPublishingProps) {
  const { values, setValues, setFieldValue } =
    useFormikContext<PromotionCodeFormType>()

  const currentDate = new Date()
  const limitNumberPromotionCode = 20000

  const responseEndDiffCurrentDate = diffDate(
    values.endPublishedAt,
    currentDate
  )
  const responseStartDiffCurrentDate = diffDate(
    values.startPublishedAt,
    currentDate
  )
  const responseStartDiffEndDate = diffDate(
    values.startPublishedAt,
    values.endPublishedAt
  )
  const responseEndDiffStartDate = diffDate(
    values.endPublishedAt,
    values.startPublishedAt
  )

  useEffect(() => {
    if (values.codeChannelType !== CodeChannelTypeEnum.OTHER) {
      setFieldValue('codePurchase', '')
      setFieldValue('codeChannelSelect', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.codeChannelType])

  return (
    <Box className='divide-y-[1px] gap-y-[30px] flex flex-col'>
      <div className='flex gap-x-[32px] items-center font-light text-secondary-100 whitespace-nowrap'>
        <div className='text-[12px]'>ช่องทาง :</div>
        <PromotionRadioInputField
          name='codeChannelType'
          label='Shopee'
          value={CodeChannelTypeEnum.SHOPEE}
          checked={values.codeChannelType === CodeChannelTypeEnum.SHOPEE}
          disabled={viewMode || disableEdit}
        />
        <PromotionRadioInputField
          name='codeChannelType'
          label='Lazada'
          value={CodeChannelTypeEnum.LAZADA}
          checked={values.codeChannelType === CodeChannelTypeEnum.LAZADA}
          disabled={viewMode || disableEdit}
        />
        <PromotionRadioInputField
          name='codeChannelType'
          label='อื่นๆ'
          value={CodeChannelTypeEnum.OTHER}
          checked={values.codeChannelType === CodeChannelTypeEnum.OTHER}
          disabled={viewMode || disableEdit}
        />
        <SelectField
          name='codeChannelSelect'
          className='w-[200px]'
          placeholder='เลือกช่องทาง'
          maxHeight={240}
          options={promotionChannelOption}
          onChange={() => setFieldValue('codePurchase', '')}
          disabled={
            values.codeChannelType !== CodeChannelTypeEnum.OTHER ||
            viewMode ||
            disableEdit
          }
        />

        <InputField
          name='codePurchase'
          className='w-[200px]'
          placeholder='พิมพ์ช่องทางจำหน่าย Code'
          maxLength={20}
          onKeyDown={() => setFieldValue('codeChannelSelect', '')}
          disabled={
            values.codeChannelType !== CodeChannelTypeEnum.OTHER ||
            viewMode ||
            disableEdit
          }
        />
      </div>
      <div className='flex gap-x-[30px] pt-[30px] gap-y-[15px] items-center font-light text-secondary-100 flex-wrap'>
        <NumberWithCommaField
          name='codeChannelInput'
          showErrorMsg={false}
          label={`จำนวน Code ใน ${
            values.codeChannelType === CodeChannelTypeEnum.OTHER
              ? values.codeChannelSelect
                ? promotionChannelOption?.find(
                    row => row.value === values.codeChannelSelect
                  )?.label
                : values.codePurchase
              : CodeChannelTypeValue[values.codeChannelType]
          }`}
          className='w-[160px]'
          inputClassName='!pr-[55px]'
          disabled={viewMode || disableEdit}
          rightIcon={
            <div className='text-[14px] text-secondary-100 font-light'>
              Code
            </div>
          }
          maxNumber={limitNumberPromotionCode}
        />
      </div>
      <div className='flex pt-[30px] justify-between items-center font-light text-secondary-100'>
        <div className='flex items-center space-x-4'>
          <div className='text-[12px]'>ระยะเวลาโปรโมชั่น :</div>
          <Checkbox
            label='ทันที'
            disabled={viewMode}
            checked={values.publishedType === PromotionPublishedEnum.NOW}
            onChange={() => {
              setValues({
                ...values,
                publishedType: PromotionPublishedEnum.NOW,
                startPublishedAt: new Date(),
              })
            }}
          />
          <div className='text-[14px]'>ถึง</div>
          <DateTimeInputField
            className='w-[178px]'
            name='endPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType !== PromotionPublishedEnum.NOW || viewMode
            }
            minDate={currentDate}
            minTime={
              responseEndDiffCurrentDate === 0
                ? currentDate
                : responseEndDiffCurrentDate < 0
                ? values.endPublishedAt
                : undefined
            }
            maxTime={
              responseEndDiffCurrentDate === 0
                ? new Date(new Date().setHours(23, 59, 59, 999))
                : responseEndDiffCurrentDate < 0
                ? values.endPublishedAt
                : undefined
            }
            onChange={value => {
              if (value) {
                const endPublishedAt =
                  value < values.startPublishedAt
                    ? values.startPublishedAt
                    : value
                setFieldValue('endPublishedAt', endPublishedAt)
              }
            }}
          />
        </div>
        <div className='flex items-center space-x-4'>
          <Checkbox
            label='กำหนดระยะเวลา'
            checked={values.publishedType === PromotionPublishedEnum.PERIOD}
            onChange={() => {
              setFieldValue('publishedType', PromotionPublishedEnum.PERIOD)
            }}
            disabled={viewMode}
          />
          <DateTimeInputField
            className='w-[178px]'
            name='startPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType !== PromotionPublishedEnum.PERIOD || viewMode
            }
            minDate={currentDate}
            maxDate={values?.endPublishedAt}
            minTime={
              responseStartDiffCurrentDate < 0
                ? values.startPublishedAt
                : responseStartDiffCurrentDate === 0
                ? currentDate
                : responseStartDiffEndDate === 0
                ? new Date(new Date().setHours(0, 0, 0, 0))
                : undefined
            }
            maxTime={
              responseStartDiffCurrentDate < 0
                ? values.startPublishedAt
                : responseStartDiffEndDate === 0
                ? new Date(new Date(values.endPublishedAt))
                : responseStartDiffCurrentDate === 0
                ? new Date(new Date().setHours(23, 59, 59, 999))
                : undefined
            }
            onChange={value => {
              if (value) {
                const startPublishedAt =
                  value < currentDate
                    ? currentDate
                    : value < values.endPublishedAt
                    ? value
                    : values.endPublishedAt
                setFieldValue('startPublishedAt', startPublishedAt)
              }
            }}
          />
          <div className='text-[14px]'>ถึง</div>
          <DateTimeInputField
            className='w-[178px]'
            name='endPublishedAt'
            showTimeSelect
            disabled={
              values.publishedType !== PromotionPublishedEnum.PERIOD || viewMode
            }
            minDate={
              values.startPublishedAt < currentDate
                ? currentDate
                : values.startPublishedAt
            }
            minTime={
              responseEndDiffStartDate <= 0
                ? values.startPublishedAt
                : undefined
            }
            maxTime={
              responseEndDiffStartDate <= 0
                ? new Date(
                    new Date(values.startPublishedAt).setHours(23, 59, 59, 999)
                  )
                : undefined
            }
            onChange={value => {
              if (value) {
                const endPublishedAt =
                  value < values.startPublishedAt
                    ? values.startPublishedAt
                    : value
                setFieldValue('endPublishedAt', endPublishedAt)
              }
            }}
          />
        </div>
      </div>
    </Box>
  )
}
