import { useNavigate, useSearchParams } from 'react-router-dom'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { AcceptAdminFormType } from '@models/authentication/AcceptAdminFormType'
import { RegisterPageProps } from './interface'

export function withRegisterPage(Component: React.FC<RegisterPageProps>) {
  function WithRegisterPage() {
    const [searchParams] = useSearchParams()
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const token = searchParams.get('confirmation_token') || ''
    const email = searchParams.get('email') || ''
    const displayName = searchParams.get('displayName') || ''

    async function acceptAdminHandler(form: AcceptAdminFormType) {
      try {
        await client?.userClient.acceptAdmin(form, token)
        alert.success('ยืนยันการสมัคร สำเร็จ')
        navigate('/login')
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการได้สำเร็จ!')
      }
    }

    const newProps = {
      email,
      displayName,
      acceptAdminHandler,
    }
    return <Component {...newProps} />
  }

  return WithRegisterPage
}
