import { gql } from 'graphql-request'

export const GET_READER_DASHBOARD_DETAIL = gql`
  query GetReaderDashboardDetail($endDate: DateTime, $startDate: DateTime) {
    getReaderDashboardDetail(endDate: $endDate, startDate: $startDate) {
      totalReader
      averageTimeUsage
    }
  }
`
