import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { ManageSearchTermType } from '@models/searchManagement/ManageSearchTermType'
import { ManageSearchResponse } from '@models/searchManagement/ManageSearchResponse'
import { SearchLogResponse } from '@models/searchManagement/SearchLogResponse'
import { GenderEnum } from '@interfaces/GenderEnum'
import { AgeRangeEnum } from '@interfaces/AgeRangeEnum'
import { GET_SEARCH_KEYWORD } from './schemas/getSearchKeyword'
import { GET_SEARCH_LOG } from './schemas/getSearchLog'
import { UPDATE_SEARCH_KEYWORD } from './schemas/updateSearchKeyword'
import { MANAGE_SEARCH_KEYWORD } from './schemas/manageSearchKeyword'
import { EXPORT_SEARCH_MANAGEMENT } from './schemas/exportSearchManagementHistory'

export class SearchManagementClient {
  constructor(private client: GraphQLClient) {}

  async searchKeyword(): Promise<ManageSearchResponse> {
    const { searchKeyword } = await this.client.request(GET_SEARCH_KEYWORD)
    return searchKeyword
  }

  async searchLog({
    searchText,
    startDate,
    endDate,
    gender,
    ageRange,
    key,
    order,
    page,
  }: {
    searchText: string
    startDate: DateTime
    endDate: DateTime
    gender: GenderEnum
    ageRange: AgeRangeEnum
    key: string
    order: string
    page: number
  }): Promise<SearchLogResponse> {
    const { getSearchLogsForAdmin } = await this.client.request(
      GET_SEARCH_LOG,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        gender: gender !== GenderEnum.ALL ? gender : undefined,
        ageRange: ageRange !== AgeRangeEnum.ALL ? ageRange : undefined,
        searchText:
          searchText.toLocaleUpperCase() === 'N/A' ? 'N/A' : searchText,
        page,
        limitPerPage: 20,
        orderBy: key || undefined,
        orderDirection: order || undefined,
      }
    )

    return plainToInstance(SearchLogResponse, getSearchLogsForAdmin)
  }

  async updateSearchKeyword(form: ManageSearchTermType): Promise<void> {
    await this.client.request(UPDATE_SEARCH_KEYWORD, {
      updateSearchKeywordInput: {
        deleteIds: form.banSearchTermList
          .concat(form.recommendSearchTermList)
          .reduce((acc, keyword) => {
            if (keyword.isDelete && !keyword.mockId && keyword.id) {
              acc.push(keyword.id)
            }
            return acc
          }, [] as number[]),
        addKeywords: {
          banKeywords: form.banSearchTermList.reduce((acc, ban) => {
            if (!ban.isDelete && ban.mockId && !ban.id) {
              acc.push(ban.name)
            }
            return acc
          }, [] as string[]),
          recommendKeywords: [],
          fiveWordRecommendKeywords: form.recommendSearchTermList.reduce(
            (acc, fiveWord) => {
              if (!fiveWord.isDelete && fiveWord.mockId && !fiveWord.id) {
                acc.push(fiveWord.name)
              }
              return acc
            },
            [] as string[]
          ),
        },
      },
    })
  }

  async manageSearchKeyword({
    action,
    keywords,
  }: {
    action: string
    keywords: string | string[]
  }) {
    await this.client.request(MANAGE_SEARCH_KEYWORD, {
      manageSearchKeywordInput: {
        keywords,
        action,
      },
    })
  }

  async exportSearchManagementHistory({
    searchText,
    startDate,
    endDate,
    gender,
    ageRange,
  }: {
    searchText: string
    startDate: DateTime
    endDate: DateTime
    gender: GenderEnum
    ageRange: AgeRangeEnum
  }) {
    await this.client.request(EXPORT_SEARCH_MANAGEMENT, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      gender: gender !== GenderEnum.ALL ? gender : undefined,
      ageRange: ageRange !== AgeRangeEnum.ALL ? ageRange : undefined,
      searchText: searchText.toLocaleUpperCase() === 'N/A' ? 'N/A' : searchText,
    })
  }
}
