import { Expose, Transform } from 'class-transformer'

export class UserReaderCoinDetailType {
  @Expose()
  @Transform(({ value }) => value || 0)
  currentPaidCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  currentFreeCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalUseFreeCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalUsePaidCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalBuyFreeCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalBuyPaidCoin: number
}
