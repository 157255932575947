import React from 'react'
import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { getErrorMessage } from '@libs/utils'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PreviewPromotionChallenge } from '@features/promotionSetting/components/PromotionChallengeForm/components/PreviewPromotionChallenge'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { Loading } from '@components/Loading'
import { queryClient } from '@client/init'
import { DuplicatePromotionChallengePageProps } from './interface'

export function withDuplicatePromotionChallengePage(
  Component: React.FC<DuplicatePromotionChallengePageProps>
) {
  function Hoc() {
    const confirmInfoModal = useModal({ modal: 'confirm' })
    const client = useClient()
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const queryKey: QueryKey = ['promotion', 'challenge', Number(id)]

    const { data, isLoading, isFetching } = useQuery(
      [...queryKey, 'duplicate'],
      () =>
        client?.promotionChallengeClient.getPromotionChallengeForm(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: activeCoinOptions = [] } = useQuery('activeCoinOptions', () =>
      client?.coinClient.getVisibleActiveCoinOptions()
    )

    const { data: challengeCoinOptions = [] } = useQuery(
      'challengeCoinOptions',
      () => client?.coinClient.getPromotionChallengeCoinOptions()
    )

    const { mutateAsync: createPromotion } = useMutation(
      (form: PromotionChallengeFormType) =>
        client!.promotionChallengeClient.createPromotionChallenge({
          ...form,
          status: PromotionStatusEnum.PENDING,
        }),
      {
        onSuccess: respId => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          queryClient.invalidateQueries(queryKey)
          navigate(`/promotion-setting/promotion-challenge/${respId}`) // TODO: redirect to approve page after submit form success
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionChallengeFormType) =>
        client!.promotionChallengeClient.createPromotionChallenge({
          ...values,
          status: PromotionStatusEnum.DRAFT,
        }),
      {
        onSuccess: () => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleSubmit(form: PromotionChallengeFormType) {
      confirmInfoModal.show({
        content: (
          <PreviewPromotionChallenge
            form={form}
            activeCoinOptions={activeCoinOptions}
            challengeCoinOptions={challengeCoinOptions}
          />
        ),
        onConfirm: async () => {
          confirmInfoModal.hide()
          await createPromotion(form)
        },
        onClose: () => {
          confirmInfoModal.hide()
        },
      })
    }

    function handleSaveDraft(form: PromotionChallengeFormType) {
      confirmInfoModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่น Challenge
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(form)
          confirmInfoModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmInfoModal.hide()
          navigate(-1)
        },
      })
    }

    if (!data || isLoading || isFetching) {
      return (
        <div className='flex w-full justify-center'>
          <Loading />
        </div>
      )
    }

    const componentProps = {
      initialValues: {
        ...data,
        id: undefined,
        startNowAt: undefined,
        startPublishedAt: new Date(),
        endPublishedAt: DateTime.now().plus({ month: 1 }).toJSDate(),
      },
      activeCoinOptions,
      challengeCoinOptions,
      handleSubmit,
      handleSaveDraft,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
