import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthentication } from '@hooks/useAuthentication'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { ModalContext } from '@hooks/contexts/ModalContext/ModalContext'
import { AppLayoutProps } from './interface'

export function withAppLayout(Component: React.FC<AppLayoutProps>) {
  function WithAppLayout(props: FunctionComponentType) {
    const { signOut, user } = useAuthentication()
    const { hideModal } = useContext(ModalContext)
    const location = useLocation()

    useEffect(() => {
      hideModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const newProps = { ...props, user, signOut }
    return <Component {...newProps} />
  }

  return WithAppLayout
}
