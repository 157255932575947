import React, { useRef, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'

import {
  OrderByEnum,
  PopularSearchTextSectionSortParam,
  SearchPopularBy,
} from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import {
  PopularTextSearchSectionProps,
  WithPopularTextSearchSectionProps,
} from './interface'

export function withPopularTextSearchSection(
  Component: React.FC<PopularTextSearchSectionProps>
) {
  function WithPopularTextSearchSection({
    searchParam,
  }: WithPopularTextSearchSectionProps) {
    const client = useClient()
    const [searchText, setSearchText] = useState<string>('')
    const [selectedGraphSearchText, setSelectedGraphSearchText] = useState<
      string | null
    >(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [popularSearchTextParam, setPopularSearchTextParam] =
      useState<PopularSearchTextSectionSortParam>({
        orderBy: OrderByEnum.SEARCH_COUNT,
        orderDirection: SortingType.DESC,
        searchBy: SearchPopularBy.ALL,
      })

    const { data: popularBookGraph } = useQuery(
      [
        'popular-book-search-graph',
        selectedGraphSearchText,
        popularSearchTextParam,
        searchParam,
      ],
      () =>
        client?.dashboardClient.popularBookGraph({
          orderBy: popularSearchTextParam.orderBy,
          orderDirection: popularSearchTextParam.orderDirection,
          searchBy: popularSearchTextParam.searchBy,
          page: 1,
          searchText: selectedGraphSearchText!,
          ...searchParam,
        }),
      {
        enabled: !!selectedGraphSearchText,
      }
    )

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        [
          `popular-search-text-section`,
          searchParam,
          searchText,
          popularSearchTextParam,
        ],
        async ({ pageParam = 1 }) => {
          const params = {
            orderBy: popularSearchTextParam.orderBy,
            orderDirection: popularSearchTextParam.orderDirection,
            searchBy: popularSearchTextParam.searchBy,
            page: pageParam,
            searchText,
            ...searchParam,
          }

          return client?.dashboardClient.popularSearchText(params)
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setPopularSearchTextParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
        searchBy: SearchPopularBy.ALL,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }
    const handleOnSearch = (text: string) => {
      setSearchText(text)
    }
    const handleOnReset = () => {
      setSearchText('')
    }

    const handleOpenGraph = (v: string) => {
      setSelectedGraphSearchText(v)
    }

    const componentProps = {
      data,
      isLoading,
      handleSortChange,
      handleWaypointEnter,
      inputRef,
      onSearch: handleOnSearch,
      onReset: handleOnReset,
      handleOpenGraph,
      popularBookGraph,
      selectedGraphSearchText,
    }

    return <Component {...componentProps} />
  }

  return WithPopularTextSearchSection
}
