import { PopupForm } from './components/PopupForm'
import { PopupTable } from './components/PopupTable'
import { PopupBannerDetailPageProps } from './interface'

export function PopupBannerDetailPage({
  queryParam,
  data,
  isLoading,
  page,
  perpage,
  sort,
  pageChange,
  handleSort,
  handleSearch,
  handleExport,
  onResetForm,
}: PopupBannerDetailPageProps) {
  return (
    <>
      <PopupForm
        initialValues={{
          ...queryParam,
        }}
        handleSubmit={handleSearch}
        onResetForm={onResetForm}
        onExport={handleExport}
      />

      <PopupTable
        data={data?.data || []}
        isLoading={isLoading}
        page={page}
        perpage={perpage}
        total={data?.total || 0}
        sort={sort}
        pageChange={pageChange}
        handleSort={handleSort}
      />
    </>
  )
}
