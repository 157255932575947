import { Expose, Type } from 'class-transformer'

import { UserConsentType } from './UserConsentType'

export class UserConsentRespons {
  @Expose()
  @Type(() => UserConsentType)
  data: UserConsentType[]

  @Expose() total: number

  @Expose() page: number
}
