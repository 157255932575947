import { FC, SVGProps } from 'react'

import { ReactComponent as Add } from '@assets/icons/add.svg'
import { ReactComponent as Chat } from '@assets/icons/chat.svg'
import { ReactComponent as Search } from '@assets/icons/search.svg'
import { ReactComponent as Upload1 } from '@assets/icons/upload-1.svg'
import { ReactComponent as Upload2 } from '@assets/icons/upload-2.svg'
import { ReactComponent as Calendar } from '@assets/icons/calendar.svg'
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow-left.svg'
import { ReactComponent as ChevronDown } from '@assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@assets/icons/chevron-up.svg'
import { ReactComponent as Right } from '@assets/icons/right.svg'
import { ReactComponent as Left } from '@assets/icons/left.svg'
import { ReactComponent as Book } from '@assets/icons/book.svg'
import { ReactComponent as Cartoon } from '@assets/icons/cartoon.svg'
import { ReactComponent as Eye } from '@assets/icons/eye.svg'
import { ReactComponent as EyeOff } from '@assets/icons/eye-off.svg'
import { ReactComponent as Heart } from '@assets/icons/heart.svg'
import { ReactComponent as Ban } from '@assets/icons/ban.svg'
import { ReactComponent as ArrowUp } from '@assets/icons/arrow-up.svg'
import { ReactComponent as Star } from '@assets/icons/star.svg'
import { ReactComponent as Return } from '@assets/icons/return.svg'
import { ReactComponent as Cross } from '@assets/icons/cross.svg'
import { ReactComponent as Check } from '@assets/icons/check.svg'
import { ReactComponent as Drag } from '@assets/icons/drag.svg'
import { ReactComponent as Pen } from '@assets/icons/pen.svg'
import { ReactComponent as Bin } from '@assets/icons/bin.svg'
import { ReactComponent as Error } from '@assets/icons/error.svg'
import { ReactComponent as Info } from '@assets/icons/info.svg'
import { ReactComponent as Success } from '@assets/icons/success.svg'
import { ReactComponent as Warning } from '@assets/icons/warning.svg'
import { ReactComponent as Ebook } from '@assets/icons/e-book.svg'
import { ReactComponent as GoldCoin } from '@assets/icons/m-gold-coin.svg'
import { ReactComponent as SilverCoin } from '@assets/icons/m-silver-coin.svg'
import { ReactComponent as BronzeCoin } from '@assets/icons/m-bronze-coin.svg'
import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg'
import { ReactComponent as ClipboardCheck } from '@assets/icons/clipboard-check.svg'
import { ReactComponent as ClipboardPending } from '@assets/icons/clipboard-pending.svg'
import { ReactComponent as ClipboardCancel } from '@assets/icons/clipboard-cancel.svg'
import { ReactComponent as ClipboardCopy } from '@assets/icons/clipboard-copy.svg'
import { ReactComponent as ThreeDot } from '@assets/icons/three-dot.svg'
import { ReactComponent as SortDown } from '@assets/icons/sort-down.svg'
import { ReactComponent as Time } from '@assets/icons/time.svg'
import { ReactComponent as DragIndicator } from '@assets/icons/drag-indicator.svg'
import { ReactComponent as NotificationOutline } from '@assets/icons/notification-outline.svg'
import { ReactComponent as Reload } from '@assets/icons/reload.svg'
import { ReactComponent as Information } from '@assets/icons/information.svg'
import { ReactComponent as RankOne } from '@assets/icons/rank-one.svg'
import { ReactComponent as RankTwo } from '@assets/icons/rank-two.svg'
import { ReactComponent as RankThree } from '@assets/icons/rank-three.svg'
import { ReactComponent as Moon } from '@assets/icons/moon.svg'
import { ReactComponent as Paperclip } from '@assets/icons/paperclip.svg'
import { ReactComponent as Clock } from '@assets/icons/clock.svg'
import { ReactComponent as MinusCircle } from '@assets/icons/minus-circle.svg'
import { ReactComponent as PlusCircle } from '@assets/icons/plus-circle.svg'
import { ReactComponent as Export } from '@assets/icons/export.svg'
import { ReactComponent as Avatar } from '@assets/icons/avatar.svg'
import { ReactComponent as Chatbox } from '@assets/icons/chatbox.svg'
import { ReactComponent as List } from '@assets/icons/list.svg'
import { ReactComponent as Tag } from '@assets/icons/tag.svg'
import { ReactComponent as CollectionsBookmark } from '@assets/icons/collections-bookmark.svg'
import { ReactComponent as SwapVertIDownActive } from '@assets/icons/swap-vert-down-active.svg'
import { ReactComponent as Edit } from '@assets/icons/edit.svg'
import { ReactComponent as CancelTime } from '@assets/icons/cancel-time.svg'
import { ReactComponent as DeleteBin } from '@assets/icons/delete-bin.svg'
import { ReactComponent as Time2 } from '@assets/icons/time2.svg'
import { ReactComponent as Menu } from '@assets/icons/menu.svg'
import { ReactComponent as Character } from '@assets/icons/character.svg'
import { ReactComponent as Crown } from '@assets/icons/rank-crown.svg'
import { ReactComponent as Image } from '@assets/icons/image.svg'
import { ReactComponent as OutlineOne } from '@assets/icons/outline-one.svg'
import { ReactComponent as OutlineTwo } from '@assets/icons/outline-two.svg'
import { ReactComponent as OutlineThree } from '@assets/icons/outline-three.svg'
import { ReactComponent as OutlineFour } from '@assets/icons/outline-four.svg'
import { ReactComponent as OutlineFive } from '@assets/icons/outline-five.svg'
import { ReactComponent as OutlineSix } from '@assets/icons/outline-six.svg'
import { ReactComponent as OutlineSeven } from '@assets/icons/outline-seven.svg'
import { ReactComponent as OutlineEight } from '@assets/icons/outline-eight.svg'
import { ReactComponent as OutlineNine } from '@assets/icons/outline-nine.svg'
import { ReactComponent as OutlineTen } from '@assets/icons/outline-ten.svg'
import { ReactComponent as CopyClipboard } from '@assets/icons/copy-clipboard.svg'
import { ReactComponent as Discount } from '@assets/icons/discount.svg'
import { ReactComponent as PatchCheck } from '@assets/icons/patch-check.svg'
import { ReactComponent as Promotion } from '@assets/icons/promotion.svg'
import { ReactComponent as Spoil } from '@assets/icons/spoil.svg'
import { ReactComponent as Cut } from '@assets/icons/cut.svg'
import { ReactComponent as Copy } from '@assets/icons/copy.svg'
import { ReactComponent as Paste } from '@assets/icons/paste.svg'
import { ReactComponent as Undo } from '@assets/icons/undo.svg'
import { ReactComponent as Redo } from '@assets/icons/redo.svg'
import { ReactComponent as Link } from '@assets/icons/link.svg'
import { ReactComponent as Video } from '@assets/icons/video.svg'
import { ReactComponent as Emoji } from '@assets/icons/emoji.svg'
import { ReactComponent as Sticker } from '@assets/icons/sticker.svg'
import { ReactComponent as Quote } from '@assets/icons/quote.svg'
import { ReactComponent as Bold } from '@assets/icons/bold.svg'
import { ReactComponent as Italic } from '@assets/icons/italic.svg'
import { ReactComponent as Underline } from '@assets/icons/underline.svg'
import { ReactComponent as StrikeThrough } from '@assets/icons/strike-through.svg'
import { ReactComponent as Sup } from '@assets/icons/sup.svg'
import { ReactComponent as Sub } from '@assets/icons/sub.svg'
import { ReactComponent as HorizontalLine } from '@assets/icons/horizontal-line.svg'
import { ReactComponent as NumberList } from '@assets/icons/number-list.svg'
import { ReactComponent as BulletList } from '@assets/icons/bullet-list.svg'
import { ReactComponent as AlignLeft } from '@assets/icons/align-left.svg'
import { ReactComponent as AlignCenter } from '@assets/icons/align-center.svg'
import { ReactComponent as AlignRight } from '@assets/icons/align-right.svg'
import { ReactComponent as TextColor } from '@assets/icons/text-color.svg'
import { ReactComponent as BgColor } from '@assets/icons/bg-color.svg'
import { ReactComponent as Footnote } from '@assets/icons/footnote.svg'
import { ReactComponent as CircleCheck } from '@assets/icons/circle-check.svg'
import { ReactComponent as Warn } from '@assets/icons/warn.svg'
import { ReactComponent as Unban } from '@assets/icons/unBan.svg'
import { ReactComponent as Camera } from '@assets/icons/camera.svg'
import { ReactComponent as PromotionCode } from '@assets/icons/promotionCode.svg'
import { ReactComponent as CorrectBlue } from '@assets/icons/correct-blue.svg'
import { ReactComponent as CorrectGreen } from '@assets/icons/correct-green.svg'
import { ReactComponent as GenerateCode } from '@assets/icons/generate-code.svg'
import { ReactComponent as UploadCode } from '@assets/icons/upload-code.svg'
import { ReactComponent as Uploading } from '@assets/icons/uploading.svg'
import { ReactComponent as WrongRed } from '@assets/icons/wrong-red.svg'
import { ReactComponent as ExportCode } from '@assets/icons/export-code.svg'
import { ReactComponent as PromotionEmpty } from '@assets/icons/promotion-empty.svg'
import { ReactComponent as Minus } from '@assets/icons/minus.svg'
import { ReactComponent as Plus } from '@assets/icons/plus.svg'
import { ReactComponent as AlignJustify } from '@assets/icons/align-justify.svg'
import { ReactComponent as Notification } from '@assets/icons/notification.svg'
import { ReactComponent as ArrowRight2 } from '@assets/icons/arrow-right2.svg'
import { ReactComponent as Information2 } from '@assets/icons/information2.svg'
import { ReactComponent as BanCover } from '@assets/icons/ban-icon.svg'
import { ReactComponent as Web } from '@assets/icons/web.svg'
import { ReactComponent as Android } from '@assets/icons/android.svg'
import { ReactComponent as Apple } from '@assets/icons/apple.svg'
import { ReactComponent as SalePromotion } from '@assets/icons/sales-promotion.svg'
import { ReactComponent as CircleArrowDown } from '@assets/icons/circle-arrow-down.svg'
import { ReactComponent as Loading } from '@assets/icons/loading.svg'

import { IconProps } from './interface'

const Svg = (
  SvgComponent: FC<SVGProps<SVGSVGElement>>
): React.FC<IconProps> => {
  return function Icon({
    height = '24',
    width = '24',
    className = '',
  }: IconProps) {
    const svgProps = {
      height,
      width,
      className,
    }
    return <SvgComponent {...svgProps} />
  }
}

export const AddIcon = Svg(Add)
export const ChatIcon = Svg(Chat)
export const SearchIcon = Svg(Search)
export const Upload1Icon = Svg(Upload1)
export const Upload2Icon = Svg(Upload2)
export const CalendarIcon = Svg(Calendar)
export const ArrowLeftIcon = Svg(ArrowLeft)
export const ChevronDownIcon = Svg(ChevronDown)
export const ChevronUpIcon = Svg(ChevronUp)
export const RightIcon = Svg(Right)
export const LeftIcon = Svg(Left)
export const BookIcon = Svg(Book)
export const CartoonIcon = Svg(Cartoon)
export const EyeIcon = Svg(Eye)
export const EyeOffIcon = Svg(EyeOff)
export const HeartIcon = Svg(Heart)
export const BanIcon = Svg(Ban)
export const ArrowUpIcon = Svg(ArrowUp)
export const StarIcon = Svg(Star)
export const ReturnIcon = Svg(Return)
export const CrossIcon = Svg(Cross)
export const CheckIcon = Svg(Check)
export const DragIcon = Svg(Drag)
export const PenIcon = Svg(Pen)
export const BinIcon = Svg(Bin)
export const ErrorIcon = Svg(Error)
export const InfoIcon = Svg(Info)
export const SuccessIcon = Svg(Success)
export const WarningIcon = Svg(Warning)
export const EbookIcon = Svg(Ebook)
export const GoldCoinIcon = Svg(GoldCoin)
export const SilverCoinIcon = Svg(SilverCoin)
export const BronzeCoinIcon = Svg(BronzeCoin)
export const ArrowRightIcon = Svg(ArrowRight)
export const ClipboardCheckIcon = Svg(ClipboardCheck)
export const ClipboardPendingIcon = Svg(ClipboardPending)
export const ClipboardCancelIcon = Svg(ClipboardCancel)
export const ClipboardCopyIcon = Svg(ClipboardCopy)
export const ThreeDotIcon = Svg(ThreeDot)
export const SortDownIcon = Svg(SortDown)
export const TimeIcon = Svg(Time)
export const DragIndicatorIcon = Svg(DragIndicator)
export const NotificationOutlineIcon = Svg(NotificationOutline)
export const ReloadIcon = Svg(Reload)
export const InformationIcon = Svg(Information)
export const RankOneIcon = Svg(RankOne)
export const RankTwoIcon = Svg(RankTwo)
export const RankThreeIcon = Svg(RankThree)
export const MoonIcon = Svg(Moon)
export const PaperclipIcon = Svg(Paperclip)
export const ClockIcon = Svg(Clock)
export const MinusCircleIcon = Svg(MinusCircle)
export const PlusCircleIcon = Svg(PlusCircle)
export const ExportIcon = Svg(Export)
export const AvatarIcon = Svg(Avatar)
export const ChatboxIcon = Svg(Chatbox)
export const ListIcon = Svg(List)
export const TagIcon = Svg(Tag)
export const CollectionsBookmarkIcon = Svg(CollectionsBookmark)
export const SwapVertIDownActiveIcon = Svg(SwapVertIDownActive)
export const EditIcon = Svg(Edit)
export const CancelTimeIcon = Svg(CancelTime)
export const DeleteBinIcon = Svg(DeleteBin)
export const Time2Icon = Svg(Time2)
export const MenuIcon = Svg(Menu)
export const CharacterIcon = Svg(Character)
export const CrownIcon = Svg(Crown)
export const ImageIcon = Svg(Image)
export const OutlineOneIcon = Svg(OutlineOne)
export const OutlineTwoIcon = Svg(OutlineTwo)
export const OutlineThreeIcon = Svg(OutlineThree)
export const OutlineFourIcon = Svg(OutlineFour)
export const OutlineFiveIcon = Svg(OutlineFive)
export const OutlineSixIcon = Svg(OutlineSix)
export const OutlineSevenIcon = Svg(OutlineSeven)
export const OutlineEightIcon = Svg(OutlineEight)
export const OutlineNineIcon = Svg(OutlineNine)
export const OutlineTenIcon = Svg(OutlineTen)
export const CopyClipboardIcon = Svg(CopyClipboard)
export const DiscountIcon = Svg(Discount)
export const PatchCheckIcon = Svg(PatchCheck)
export const PromotionIcon = Svg(Promotion)
export const SpoilIcon = Svg(Spoil)
export const CutIcon = Svg(Cut)
export const CopyIcon = Svg(Copy)
export const PasteIcon = Svg(Paste)
export const UndoIcon = Svg(Undo)
export const RedoIcon = Svg(Redo)
export const LinkIcon = Svg(Link)
export const VideoIcon = Svg(Video)
export const EmojiIcon = Svg(Emoji)
export const StickerIcon = Svg(Sticker)
export const QuoteIcon = Svg(Quote)
export const BoldIcon = Svg(Bold)
export const ItalicIcon = Svg(Italic)
export const UnderlineIcon = Svg(Underline)
export const StrikeThroughIcon = Svg(StrikeThrough)
export const SupIcon = Svg(Sup)
export const SubIcon = Svg(Sub)
export const HorizontalLineIcon = Svg(HorizontalLine)
export const NumberListIcon = Svg(NumberList)
export const BulletListIcon = Svg(BulletList)
export const AlignLeftIcon = Svg(AlignLeft)
export const AlignCenterIcon = Svg(AlignCenter)
export const AlignRightIcon = Svg(AlignRight)
export const TextColorIcon = Svg(TextColor)
export const BgColorIcon = Svg(BgColor)
export const FootnoteIcon = Svg(Footnote)
export const CircleCheckIcon = Svg(CircleCheck)
export const WarnIcon = Svg(Warn)
export const UnbanIcon = Svg(Unban)
export const CameraIcon = Svg(Camera)
export const PromotionCodeIcon = Svg(PromotionCode)
export const CorrectBlueIcon = Svg(CorrectBlue)
export const CorrectGreenIcon = Svg(CorrectGreen)
export const GenerateCodeIcon = Svg(GenerateCode)
export const UploadCodeIcon = Svg(UploadCode)
export const UploadingIcon = Svg(Uploading)
export const WrongRedIcon = Svg(WrongRed)
export const ExportCodeIcon = Svg(ExportCode)
export const PromotionEmptyIcon = Svg(PromotionEmpty)
export const PlusIcon = Svg(Plus)
export const MinusIcon = Svg(Minus)
export const AlignJustifyIcon = Svg(AlignJustify)
export const NotificationIcon = Svg(Notification)
export const ArrowRight2Icon = Svg(ArrowRight2)
export const Information2Icon = Svg(Information2)
export const BanCoverIcon = Svg(BanCover)
export const WebIcon = Svg(Web)
export const AndroidIcon = Svg(Android)
export const AppleIcon = Svg(Apple)
export const SalePromotionIcon = Svg(SalePromotion)
export const CircleArrowDownIcon = Svg(CircleArrowDown)
export const LoadingIcon = Svg(Loading)
