import React, { useEffect } from 'react'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import SpoilerExtension from '@components/Tiptap/components/Spoiler/SpoilerExtension'
import IframeExtension from '@components/Tiptap/components/IframeExtension'
import { CustomListItemExtension } from '@components/Tiptap/components/CustomListItem'
import { CustomOrderedListExtension } from '@components/Tiptap/components/CustomOrderedList'
import FootnoteExtension from '@components/Tiptap/components/Footnote/FootnoteExtension'
import FootnoteOrderedListExtension from '@components/Tiptap/components/FootnoteOrderedList/FootnoteOrderedListExtension'
import { TiptapViewerAcceptProps, TiptapViewerProps } from './interface'

const withTiptapViewer = (Component: React.FC<TiptapViewerProps>) => {
  function Hoc({ value, ...props }: TiptapViewerAcceptProps) {
    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Subscript,
        Superscript,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['paragraph'],
        }),
        Highlight.configure({ multicolor: true }),
        FootnoteExtension,
        FootnoteOrderedListExtension,
        SpoilerExtension,
        Link.configure({
          openOnClick: true,
        }),
        Image.configure({ inline: true }),
        IframeExtension,
        CustomOrderedListExtension,
        CustomListItemExtension,
      ],
      content: value,
      editable: false,
    })

    function checkFootnoteOrderedList(initValue: string) {
      if (editor) {
        const splitMessage = 'footnote-ordered-list'
        const splitFootnote = initValue.split(splitMessage)
        if (splitFootnote.length === 3) {
          const replaceNewFootnote = splitFootnote[1]
            .replace(/<ol>/g, '<ol id="customOrderedList">')
            .replace(/<li>/g, '<custom-list-item>')
            .replace(/<\/li>/g, '</custom-list-item>')
          editor.commands.setContent(
            `${splitFootnote[0]}${splitMessage}${replaceNewFootnote}${splitMessage}${splitFootnote[2]}`
          )
        } else {
          editor?.commands.setContent(initValue)
        }
      }
    }
    useEffect(() => {
      const initValue = value || '<p></p>'
      // DESC: Check old version footnote
      if (
        initValue.match(/(<footnote-ordered-list>)+/g) &&
        !initValue.match(/(<custom-list-item>)+/g)
      ) {
        checkFootnoteOrderedList(initValue)
      } else {
        editor?.commands.setContent(initValue)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, editor])

    const newProps = {
      editor,
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withTiptapViewer }
