import { Link, useParams } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export function ActionCell({ userId }: { userId: number }) {
  const { id } = useParams()
  return (
    <div className='flex gap-x-[10px]'>
      <Link to={`/dashboard/reader?tab=byUser&userId=${userId}`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-secondary-100' width='18' height='18' />
        </button>
      </Link>
    </div>
  )
}
