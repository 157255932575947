import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { Loading } from '@components/Loading'
import { FormDetailPromotionSalePageProps } from './interface'

export function withFormDetailPromotionSalePage(
  Component: React.FC<FormDetailPromotionSalePageProps>
) {
  function Hoc() {
    const navigate = useNavigate()
    const { id } = useParams()
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryKey: QueryKey = ['promotion', 'sale', Number(id)]

    const { data, isLoading } = useQuery(
      [...queryKey, 'form-detail'],
      () => client?.promotionSaleClient.getPromotionSaleFormDetail(Number(id)),
      {
        onError: () => navigate(-1),
      }
    )

    const { data: saleCoinOptions = [] } = useQuery('sale-coin-options', () =>
      client?.coinClient.getPromotionChallengeCoinOptions()
    )

    const { mutateAsync: approvePromotionSale } = useMutation(
      ({
        promotionRequestId,
        status,
      }: {
        promotionRequestId: number
        status:
          | PromotionStatusEnum.PENDING
          | PromotionStatusEnum.APPROVED
          | PromotionStatusEnum.REJECTED
      }) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status,
        }),
      {
        onSuccess: () => {
          alert.success(`ดำเนินการสำเร็จ`)
          navigate(-1)
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
        },
      }
    )

    async function handleApprove() {
      if (data?.lastRequest?.id && data.id) {
        await approvePromotionSale({
          promotionRequestId: data.lastRequest.id,
          status: PromotionStatusEnum.APPROVED,
        })
      }
    }

    function handleReject() {
      if (data?.lastRequest?.id && data?.id) {
        rejectModal.show({
          requestId: data.lastRequest.id,
          promotionId: data.id,
          promotionName: data?.name || '',
          startPromotion: new Date(data.startPublishedAt!),
          endPromotion: new Date(data.endPublishedAt!),
          createdAt: new Date(data.createdAt!),
          promotionDetail: data.description,
          onSuccess: () => {
            navigate(-1)
          },
        })
      }
    }

    if (!data || isLoading) {
      return (
        <div className='m-auto'>
          <Loading />
        </div>
      )
    }

    const newProps = {
      promotionId: id,
      initialValues: data,
      saleCoinOptions,
      handleApprove,
      handleReject,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
