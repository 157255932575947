import { Expose } from 'class-transformer'

export class PromotionCodeUploadType {
  @Expose() couponCount: number

  @Expose() coupons: string[]

  @Expose() fileName: string

  @Expose() filePath: string
}
