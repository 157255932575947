import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_CHAPTER = gql`
  query GetSearchResultByChapter(
    $dateRange: DatePicker
    $interval: SearchPastInterval
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByChapterSort
    $orderDirection: OrderDirection
  ) {
    searchResultByChapter(
      dateRange: $dateRange
      interval: $interval
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        bookId
        title
        writer
        penName
        totalSearch
        chapterName
      }
      page
    }
  }
`
