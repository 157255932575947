import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'

export const initialValues: RolePermissionFormType = {
  name: '',
  permissions: [
    {
      name: 'dashboard',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'sale',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'homePageManagement',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'menuManagement',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'chapterBook',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'promotionSetting',
      status: true,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'newsPromotion',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'user',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'userMission',
      status: true,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'coin',
      status: true,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'searchManagement',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'admin',
      status: true,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
  ],
}
