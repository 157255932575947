import { Container } from '@components/Container'
import { HighlightBannerBookForm } from '@features/home/components/HighlightBannerBookForm'
import { EditHighlightBannerBookPageProps } from './interface'

export function EditHighlightBannerBookPage({
  data,
  onSubmit,
}: EditHighlightBannerBookPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between mb-5'>
        <Container.Title showBackBtn>แก้ไขนิยายสำหรับแบนเนอร์</Container.Title>
      </section>

      <HighlightBannerBookForm data={data} onSubmit={onSubmit} />
    </Container>
  )
}
