import React from 'react'
import cn from 'classnames'

import { BinIcon, EditIcon, PatchCheckIcon } from '@components/Icons'
import { Toggle } from '@components/Toggle'
import { capitalizeFirstLetter, formatDateTime } from '@libs/utils'
import {
  promotionChallengeActionText,
  promotionChallengeBenefitLimitTypeText,
  promotionChallengeBookTypeText,
  promotionChallengeReadConditionText,
  promotionStatusText,
} from '@libs/promotion/utils'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeInfoProps } from './interface'
import { Container } from '../Container'

function PromotionChallengeInfo({
  status,
  detail,
  isShowActionMenu,
  handleActivePromotion,
  handleEditPromotion,
  handleDeletePromotion,
}: PromotionChallengeInfoProps) {
  return detail ? (
    <>
      <div className='border-b pb-5 flex items-center justify-between'>
        <div className='flex items-center gap-x-5'>
          <div className='flex items-center gap-x-[10px] font-mitr text-primary'>
            <PatchCheckIcon />
            <h3 className='font-medium text-sm'>User Challenge</h3>
          </div>
          <div className='border-r border-gray h-[25px]' />
          <div
            className={cn(
              'flex justify-center items-center rounded px-[8px] py-[4px] text-[12px] font-bold',
              {
                'bg-blue-2 text-secondary':
                  status === PromotionStatusEnum.PENDING,
                'bg-red-74 text-white': status === PromotionStatusEnum.REJECTED,
                'bg-secondary text-white':
                  status === PromotionStatusEnum.APPROVED,
                'bg-green-986 text-white':
                  status === PromotionStatusEnum.STARTED,
                'border border-gray-393 text-secondary':
                  status === PromotionStatusEnum.DRAFT,
                'bg-secondary-100 text-white':
                  status === PromotionStatusEnum.CLOSED,
              }
            )}
          >
            {promotionStatusText(status)}
          </div>
          <span className='text-lg font-bold text-secondary'>
            {detail.name}
          </span>
        </div>

        {isShowActionMenu && (
          <div className='flex items-center gap-x-5 text-gray-500'>
            <Toggle
              checked={detail.isActive}
              disabled={!handleActivePromotion}
              onChange={handleActivePromotion}
            />
            <button
              type='button'
              className='flex items-center justify-center border border-[#f4f4f7] rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed'
              disabled={!handleEditPromotion}
              onClick={handleEditPromotion}
            >
              <EditIcon width='18' height='18' className='text-secondary-100' />
            </button>
            <button
              type='button'
              className='flex items-center justify-center border border-[#f4f4f7] rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed'
              disabled={!handleDeletePromotion}
              onClick={handleDeletePromotion}
            >
              <BinIcon width='18' height='18' className='text-secondary-100' />
            </button>
          </div>
        )}
      </div>

      <div className='w-full text-sm border-b py-5 flex items-center space-x-[40px]'>
        <div className='space-x-1'>
          <span className='text-xs text-secondary-100 font-light'>
            Promotion ID :
          </span>
          <span className=' text-secondary font-bold'>
            {detail.businessId || '-'}
          </span>
        </div>
        <div className='space-x-1'>
          <span className='text-xs text-secondary-100 font-light'>
            Create Date :
          </span>
          <span className=' text-secondary'>
            {formatDateTime(detail.createdAt, { separateText: '-' })}
          </span>
        </div>
        <div className='flex items-center space-x-[10px]'>
          <div className='space-x-1'>
            <span className='text-secondary-100 font-light'>
              ระยะเวลาใช้โค้ด :
            </span>
            <span className='text-secondary font-bold'>
              {new Date(detail.startPublishedAt!) <= new Date()
                ? 'ทันที'
                : formatDateTime(detail.startPublishedAt!, {
                    separateText: '-',
                  })}
            </span>
          </div>
          <span className='text-secondary-100 font-light'>ถึง</span>
          <span className='text-secondary font-bold'>
            {formatDateTime(detail.endPublishedAt!, {
              separateText: '-',
            })}
          </span>
        </div>
      </div>
      <>
        <div
          className={cn(
            'grid grid-cols-[150px_1fr] gap-y-[25px] pt-[25px] text-[14px] text-secondary'
          )}
        >
          <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
            รายละเอียด :
          </span>
          <span>{detail.description || '-'}</span>
          <span className='text-[12px] font-light text-secondary-100'>
            เงื่อนไขการใช้งาน :
          </span>
          <div className='grid grid-col-1 whitespace-pre-wrap break-word'>
            <span>{detail.userTerm || '-'}</span>
          </div>
        </div>
        <Container.Hr />
        <div
          className={cn(
            'grid grid-cols-[150px_200px_200px_6fr] gap-y-[25px] text-[14px] items-center text-secondary'
          )}
        >
          <span className='text-[12px] font-light text-secondary-100'>
            Action :
          </span>
          <span>
            {promotionChallengeActionText(detail.promotionChallenge?.action)}
          </span>
          {detail.promotionChallenge?.action !==
            PromotionChallengeActionEnum.FINISH_PROFILE && (
            <div className='text-[14px] font-light text-secondary-100'>
              จำนวน :
              <span className={cn('px-[10px] text-secondary')}>
                {detail.promotionChallenge?.actionValue?.toLocaleString()}
              </span>
              {detail.promotionChallenge?.readCondition
                ? promotionChallengeReadConditionText(
                    detail.promotionChallenge?.readCondition
                  )
                : detail.promotionChallenge?.coinsCondition?.length
                ? 'เหรียญขึ้นไป'
                : 'ครั้ง'}
            </div>
          )}
          {detail.promotionChallenge?.action ===
            PromotionChallengeActionEnum.COMMENT && (
            <div className={cn('text-[14px] font-light text-secondary')}>
              จำนวนตัวอักษร : &nbsp;
              {detail.promotionChallenge?.wordCount?.toLocaleString() || '-'}
              &nbsp;
              <span className='text-secondary-100'>ตัวอักษร</span>
            </div>
          )}
          {(detail.promotionChallenge?.action ===
            PromotionChallengeActionEnum.UNLOCK_CHAPTER ||
            detail.promotionChallenge?.action ===
              PromotionChallengeActionEnum.DONATE ||
            detail.promotionChallenge?.action ===
              PromotionChallengeActionEnum.SET_PRICE_CHAPTER ||
            detail.promotionChallenge?.action ===
              PromotionChallengeActionEnum.CREATE_BOOK) && (
            <div className='font-light space-x-[10px]'>
              <span className='text-sm text-secondary'>
                {detail.promotionChallenge.coinsCondition
                  ?.map(coin => `เหรียญ${coin.name}`)
                  .join(', ')}
              </span>
            </div>
          )}
        </div>
        {detail.promotionChallenge?.action ===
          PromotionChallengeActionEnum.CREATE_BOOK && (
          <>
            <Container.Hr />
            <div
              className={cn(
                'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center text-secondary'
              )}
            >
              <span className='text-[14px] font-light text-secondary-100'>
                ประเภทงานเขียน :
              </span>
              <div className='text-secondary'>
                {detail.promotionChallenge?.bookTypes
                  ?.map(bookType => promotionChallengeBookTypeText(bookType))
                  .join(', ')}
              </div>
              <div className='text-[14px] font-light text-secondary-100'>
                หมวดหมู่ : &nbsp;
                <span className='text-secondary'>
                  {detail.promotionChallenge?.categories
                    ?.map(category => category.name)
                    .join(', ')}
                </span>
              </div>
            </div>
          </>
        )}
        {detail.promotionChallenge?.action ===
          PromotionChallengeActionEnum.SHARE && (
          <>
            <Container.Hr />
            <div
              className={cn(
                'grid grid-cols-[150px_200px_1fr_6fr] gap-y-[25px] text-[14px] items-center text-secondary'
              )}
            >
              <span className='text-xs text-secondary-100'>แชร์ไปยัง : </span>
              <span className='text-secondary'>
                {detail.promotionChallenge?.socialMediaChannels
                  ?.map(social => capitalizeFirstLetter(social.toLowerCase()))
                  .join(', ')}
              </span>
            </div>
          </>
        )}
        <Container.Hr />
        <div
          className={cn(
            'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center text-secondary'
          )}
        >
          <span className='text-[14px] font-light text-secondary-100'>
            รับเหรียญ :
          </span>
          <div className='text-secondary'>
            {detail.promotionChallenge?.benefitValue?.toLocaleString()} &nbsp;
            <span className='text-secondary-100'>
              เหรียญ{detail.promotionChallenge?.summary?.coin?.name}
            </span>
          </div>
          <div className='text-[14px] font-light text-secondary-100'>
            เก็บเหรียญได้ :
            <span className='text-secondary px-[5px]'>
              {detail.promotionChallenge?.benefitLimitValue?.toLocaleString()}
            </span>
            <span
              className={cn('text-secondary', {
                'text-secondary-100': [
                  PromotionChallengeLimitTypeEnum.PER_DAY,
                  PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
                ].includes(
                  detail.promotionChallenge?.benefitLimitType ||
                    PromotionChallengeLimitTypeEnum.ONCE
                ),
              })}
            >
              {promotionChallengeBenefitLimitTypeText(
                detail.promotionChallenge?.benefitLimitType
              )}
            </span>
          </div>
        </div>
        <Container.Hr />
        <div
          className={cn(
            'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center text-secondary'
          )}
        >
          <span className='text-[14px] font-light text-secondary-100'>
            งบประมาณ/แคมเปญ :
          </span>
          <div className='text-secondary'>
            {detail.promotionChallenge?.budget?.toLocaleString() || 0} &nbsp;
            <span className='text-secondary-100'>
              เหรียญ{detail.promotionChallenge?.summary?.coin?.name}
            </span>
          </div>
          {detail.promotionChallenge?.budgetPerDay && (
            <div className='text-[14px] font-light text-secondary-100'>
              จำกัดจำนวน &nbsp;
              <span className='text-secondary'>
                {detail.promotionChallenge?.budgetPerDay?.toLocaleString()}{' '}
                &nbsp;
              </span>
              <span className='text-secondary-100'>
                เหรียญ{detail.promotionChallenge?.summary?.coin?.name} / ต่อวัน
              </span>
            </div>
          )}
        </div>
      </>
    </>
  ) : null
}

export default PromotionChallengeInfo
