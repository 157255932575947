import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
  getAuth,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from '@firebase/auth'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { GenderEnum } from '@interfaces/GenderEnum'
import { UserType } from '@models/profile/UserType'
import { useGetProfile } from '@hooks/user/useGetProfile'
import { LoginFormType } from '@models/authentication/LoginFormType'
import { RolePermissionType } from '@models/rolePermission/RolePermissionType'
import { firebase } from '@libs/firebase'
import { PermissionGuardType } from '@models/rolePermission/PermissionGuardType'
import { defaultPermissionGuard } from '@constants/permission/defaultPermission'
import { useClient } from './useClient'
import { useAlert } from './useAlert'

interface AuthenticationContext {
  user: UserType
  permission: PermissionGuardType
  isAuthenticated: boolean
  isUserLoading: boolean
  isLogin: boolean
  token?: string
  signIn: (user: LoginFormType) => void
  signOut: () => void
  forgotPassword: (email: string) => void
  verifyPassword: (code: string) => Promise<string>
  resetPassword: (code: string, password: string) => void
}

const defaultUser = {
  id: 0,
  avatarImgPath: '',
  bannerImgPath: '',
  idNumber: '',
  fullName: '',
  email: '',
  role: { id: 0, name: '', permissions: [] } as RolePermissionType,
  phoneNumber: '',
  birthDay: '',
  gender: GenderEnum.OTHER,
  isSubscribe: false,
  isAcceptPolicy: false,
  username: '',
  displayName: '',
  permission: defaultPermissionGuard,
}

const AuthenticateContext = createContext<AuthenticationContext>({
  user: defaultUser,
  permission: {} as PermissionGuardType,
  isAuthenticated: false,
  isUserLoading: true,
  isLogin: false,
  token: undefined,
  signIn: () => {},
  signOut: () => {},
  forgotPassword: () => {},
  verifyPassword: () => {
    return Promise.resolve('')
  },
  resetPassword: () => {},
})

const facebookProvider = new FacebookAuthProvider()
facebookProvider.addScope('user_birthday')
facebookProvider.setCustomParameters({
  display: 'popup',
})

const auth = getAuth(firebase)
auth.languageCode = 'th'

export function AuthenticateProvider({ children }: FunctionComponentType) {
  const [isFirebaseLoading, setIsFirebaseLoading] = useState(true)
  const [token, setToken] = useState<string | undefined>(undefined)
  const alert = useAlert()
  const client = useClient()
  const user = useGetProfile()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  async function signIn({ email, password }: LoginFormType) {
    await signInWithEmailAndPassword(auth, email, password)
  }

  async function forgotPassword(email: string) {
    try {
      await sendPasswordResetEmail(auth, email)
      alert.success(`โปรดตรวจสอบอีเมล ${email} เพื่อรีเซ็ตรหัสผ่าน`)
    } catch (error: any) {
      alert.error(error?.message)
    }
  }

  async function verifyPassword(code: string): Promise<string> {
    const response = await verifyPasswordResetCode(auth, code)
    return response
  }

  async function resetPassword(code: string, password: string) {
    try {
      await confirmPasswordReset(auth, code, password)
      alert.success('รีเซ็ตรหัสผ่านสำเร็จ')
      navigate('/login')
    } catch (_) {
      alert.error('เกิดข้อผิดพลาด')
    }
  }

  async function signOut() {
    try {
      await auth.signOut()
      navigate('/login')
    } catch (error: any) {
      alert.error(error?.message)
    }
  }

  // listen for token changes
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (firebaseUser: any) => {
      if (firebaseUser) {
        const result = await auth.currentUser?.getIdTokenResult()
        if (result?.claims?.admin) {
          localStorage.setItem('token', result.token)
          localStorage.setItem('isLogin', 'true')
          await user.refetch()
          setToken(result?.token)
          client?.userClient.updateLastLogin()
        } else {
          alert.error('อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง')
          await auth.signOut()
        }
        setIsFirebaseLoading(false)
      } else {
        localStorage.removeItem('isLogin')
        await queryClient.resetQueries('my-profile')
        setToken(undefined)
        setIsFirebaseLoading(false)
      }
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // force refresh the token every jwt token expired (before 1 mins)
  // useEffect(() => {
  //   if (token) {
  //     const jwt: { exp: number } = jwtDecode(token)
  //     const dateTime = jwt.exp - Math.floor(new Date().valueOf() / 1000)

  //     if (dateTime > 0) {
  //       const millisec = Math.max(dateTime * 1000 - 60000, 0)
  //       handle.current = setTimeout(async () => {
  //         if (auth.currentUser) {
  //           await auth.currentUser.getIdToken(true)
  //         }
  //       }, millisec)
  //     }
  //   }

  //   return () => {
  //     // clean up setTimeout
  //     if (handle.current) clearTimeout(handle.current)
  //   }
  // }, [token])

  const value = {
    user: user.data || defaultUser,
    permission: user.data?.permission || defaultPermissionGuard,
    isUserLoading: user.isLoading || isFirebaseLoading,
    isAuthenticated: !!user.data,
    isLogin:
      typeof window !== 'undefined' ? !!localStorage.getItem('isLogin') : false,
    token,
    signIn,
    signOut,
    forgotPassword,
    verifyPassword,
    resetPassword,
  }

  return (
    <AuthenticateContext.Provider value={value}>
      {children}
    </AuthenticateContext.Provider>
  )
}

export function useAuthentication() {
  return useContext(AuthenticateContext)
}
