import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Loading } from '@components/Loading'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { Overview } from './components/Overview'
import { TraficChart } from './components/TraficChart'
import { ReadersByAge } from './components/ReadersByAge'
import { ReadersByGender } from './components/ReadersByGender'
import { IncomeByPenname } from './components/IncomeByPenname'
import { AllWriterTable } from './components/AllWriterTable'
import { ByUserPageProps } from './interface'
import { ByUserForm } from './components/ByUserForm'

export function ByUserPage({
  queryParam,
  handleSearch,
  handleExport,
  writerData,
  trafficByUser,
  penNameCount,
  penNameHandle,
  onResetForm,
  isLoading,
}: ByUserPageProps) {
  return (
    <>
      <ByUserForm
        initialValues={{
          userId: queryParam.userId,
          writerId: queryParam.writerId,
          penNameId: queryParam.penNameId,
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        disableExport={!(queryParam.userId && queryParam.writerId)}
        handleSubmit={handleSearch}
        handleExport={handleExport}
        penNameHandle={penNameHandle}
        onResetForm={onResetForm}
      />
      {isLoading ? (
        <Loading size={36} className='m-auto mt-[80px]' />
      ) : writerData ? (
        <>
          <CardContainer>
            <Overview
              user={writerData.user}
              penNameCount={penNameCount}
              penNameId={queryParam.penNameId}
              summaryActionTime={writerData.summaryActionTime}
              createdAt={writerData.user.createdAt}
              totalRead={writerData.summaryTotalRead}
              firstBookCreatedDate={writerData.firstBookCreatedDate}
              summaryDonate={writerData.summaryDonate}
              summaryBookPurchase={writerData.summaryBookPurchase}
              totalBookshelf={writerData.summaryAddBookShelfCount}
              summaryBookLike={writerData.summaryBookLike}
              summaryBookComment={writerData.summaryBookComment}
              summarySellCount={writerData.summarySellCount}
              penNameTitle={writerData.firstPenName}
              totalPaidWriterIncome={writerData.totalPaidWriterIncome}
              totalPayableWriterIncome={writerData.totalPayableWriterIncome}
            />

            <IncomeByPenname queryParam={queryParam} />

            <section className='w-full flex items-start justify-between'>
              <ReadersByGender readerGender={writerData.readerGender} />
              <ReadersByAge readerAge={writerData.readerAge} />
              <TraficChart readerTraffic={trafficByUser} />
            </section>
          </CardContainer>

          <CardContainer>
            <AllWriterTable queryParam={queryParam} />
          </CardContainer>
        </>
      ) : (
        <CardContainer>
          <p className='text-center'>
            เลือกนักเขียน,นามปากกา,ช่วงเวลา ที่ต้องการดูข้อมูล
          </p>
        </CardContainer>
      )}
    </>
  )
}
