import cn from 'classnames'

import { Accordion } from '@components/Accordion'
import { SwapVertIDownActiveIcon } from '@components/Icons'
import { SortingEnum } from '@interfaces/SortingEnum'
import { ChapterGroupType, ManageChaptersProps } from './interface'
import { Box } from '../Box'
import { ChapterGroup } from './components/ChapterGroup'

const sortType = {
  [SortingEnum.ASC]: 'เก่าไปใหม่',
  [SortingEnum.DESC]: 'ใหม่ไปเก่า',
}

export function ManageChapters({
  bookId,
  chapterGroups,
  total,
  sorter,
  isManga,
  handleSortData,
}: ManageChaptersProps) {
  return (
    <Box className='border-0 shadow-none p-0 mb-[80px]'>
      <div className='mt-[51px]' id='manage_chapters'>
        <div className='flex flex-row items-center justify-between'>
          <Box.Title className='font-mitr'>จัดการตอน</Box.Title>
        </div>

        <div className='p-[10px] mt-5 mb-[21px]'>
          <div className='grid grid-cols-4 gap-y-5'>
            <p className='text-base font-bold text-secondary col-span-4'>
              ตอนทั้งหมด : <span className='font-light'>{total} ตอน</span>
            </p>

            <div
              className='flex flex-row items-center text-secondary cursor-pointer col-span-2'
              onClick={handleSortData}
            >
              <SwapVertIDownActiveIcon
                className={cn({ 'rotate-180': sorter === SortingEnum.DESC })}
              />
              <span className='text-sm font-light ml-[5px]'>
                {sortType[sorter]}
              </span>
            </div>
          </div>
        </div>
        <div className='grid gap-y-[10px]'>
          {!!chapterGroups?.length &&
            chapterGroups
              .sort((a, b) =>
                sorter === SortingEnum.ASC
                  ? a.order - b.order
                  : b.order - a.order
              )
              .map((chapterGroup: ChapterGroupType, index: number) => (
                <Accordion
                  bookId={bookId}
                  page={chapterGroup.order + 1}
                  key={index}
                  initialActive={index === 0}
                  title={
                    <label className='flex items-center '>
                      <span className='ml-[10px]'>
                        <span className='text-secondary-100'>ตอนที่ </span>{' '}
                        {chapterGroup.label}
                      </span>
                    </label>
                  }
                >
                  <ChapterGroup isManga={isManga} sorter={sorter} />
                </Accordion>
              ))}
        </div>
      </div>
    </Box>
  )
}
