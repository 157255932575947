import { Container } from '@components/Container'
import { HorizontalStyle } from '@components/HorizontalStyle'
import { TiptapViewer } from '@components/TiptapViewer'
import { VerticalStyle } from '@components/VerticalStyle'
import { BookEnum } from '@interfaces/BookEnum'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { EndChapter } from '../../../../components/EndChapter'
import { NavigationReadingPage } from './components/NavigationReadingPage/NavigationReadingPage'
import { ReadingPageFooter } from './components/ReadingPageFooter'
import { ReadingPageHeader } from './components/ReadingPageHeader'
import { PreviewReadingPageProps } from './interface'

export function PreviewReadingPage({
  data,
  bookId,
  chapterId,
}: PreviewReadingPageProps) {
  return (
    <>
      <NavigationReadingPage
        bookId={bookId}
        chapterName={data?.chapterName || ''}
        chapterTitle={data?.chapterTitle || ''}
      />
      <Container>
        <div className='pt-[50px] px-[60px] bg-white rounded-lg'>
          <ReadingPageHeader
            storyName={data?.book?.title || ''}
            chapterName={data?.chapterName || ''}
            chapterTitle={data?.chapterTitle || ''}
            by={data?.book?.penName?.firstPenName || ''}
            totalPage={
              data?.book?.bookType === BookEnum.MANGA &&
              data?.book?.orientation === OrientationEnum.HORIZONTAL
                ? data?.chapterPages.length
                : undefined
            }
          />
          {data?.book?.bookType === BookEnum.NOVEL && (
            <>
              <TiptapViewer
                className='max-w-[860px] w-full m-[0_auto]'
                value={data.chapterPages[0]?.content}
              />

              <EndChapter />
            </>
          )}
          {data?.book?.bookType === BookEnum.MANGA &&
            data.book.orientation === OrientationEnum.HORIZONTAL && (
              <HorizontalStyle
                chapterPages={data.chapterPages}
                chapterId={chapterId}
              />
            )}
          {data?.book?.bookType === BookEnum.MANGA &&
            data.book.orientation === OrientationEnum.VERTICAL && (
              <VerticalStyle
                chapterPages={data.chapterPages}
                chapterId={chapterId}
              />
            )}
        </div>
        <ReadingPageFooter
          bookId={bookId}
          prevId={data?.prevId || null}
          nextId={data?.nextId || null}
        />
      </Container>
    </>
  )
}
