import { Formik, Form } from 'formik'
import cn from 'classnames'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { Button } from '@components/Button'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { SearchSectionProps } from './interface'

const pastIntervalOptions = [
  { value: TimeRangeEnum.DAY, text: '24 ชั่วโมง' },
  { value: TimeRangeEnum.WEEK, text: '7 วัน' },
  { value: TimeRangeEnum.MONTH, text: '1 เดือน' },
  { value: TimeRangeEnum.THREE_MONTH, text: '3 เดือน' },
  { value: TimeRangeEnum.SIX_MONTH, text: '6 เดือน' },
  { value: TimeRangeEnum.YEAR, text: '1 ปี' },
]

export function SearchSection({
  initialValues,
  handleSubmit,
  handleExport,
  handleReset,
}: SearchSectionProps) {
  const permission = usePermissionAction('dashboard')

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <div className='flex justify-between items-end mb-[30px]'>
            <div className='flex items-end gap-x-[10px]'>
              <SelectButtonField
                name='interval'
                options={pastIntervalOptions}
                label='เลือกช่วงเวลา'
                mode='line'
                className='w-fit overflow-auto'
                onChange={() => {
                  setFieldValue('startDate', undefined)
                  setFieldValue('endDate', undefined)
                }}
              />
              <DateTimeInputField
                className='w-[145px]'
                name='startDate'
                placeholder='ตั้งแต่วันที่'
                onChange={value => {
                  setFieldValue('startDate', value)
                  if (values.interval) setFieldValue('interval', undefined)
                }}
                maxDate={values.endDate || new Date()}
              />
              <DateTimeInputField
                className='w-[145px] '
                name='endDate'
                placeholder='ถึงวันที่'
                onChange={value => {
                  setFieldValue('endDate', value)
                  if (values.interval) setFieldValue('interval', undefined)
                }}
                maxDate={new Date()}
                minDate={values.startDate}
              />
            </div>
            <div className='flex justify-end space-x-[6px]'>
              <Button
                type='button'
                className='text-secondary underline'
                onClick={() => {
                  resetForm()
                  handleReset()
                }}
              >
                <span className='text-[14px] whitespace-pre'>ล้างค่า</span>
              </Button>
              <Button type='submit' className='bg-secondary text-white '>
                <span className='text-[14px]'>ค้นหา</span>
              </Button>
              <ExportButton
                className={cn({
                  hidden: !permission.download,
                })}
                handleClick={handleExport}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
