import { Duration } from 'luxon'

import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { PieChart } from '@components/chart/PieChart'
import { Box } from '@components/Box'
import { VerticalStackBarChart } from '@components/chart/VerticalStackBarChart'
import { VerticalBarChart } from '@components/chart/VerticalBarChart'
import { OverviewProps } from './interface'
import { TopMoneySpenderTable } from '../TopMoneySpenderTable'
import { TopTimeSpenderTable } from '../TopTimeSpenderTable'
import { LogCityChart } from '../LogCityChart'

function Overview({
  searchParams,
  performanceLogCity,
  readerDashboardDetail,
  payingVsNonUserPayingData,
  averageRevenuePerPayingUserData,
  readerGender,
  readerAge,
  readTimeUsage,
  handleExport,
  handleReset,
  handleSearch,
  setGraphPayingVsNonUserPayingData,
  setGraphPayingData,
}: OverviewProps) {
  return (
    <>
      <section className='flex items-center gap-x-[20px] mb-[30px]'>
        <SearchDateRange
          className='flex-1'
          onSearch={handleSearch}
          onReset={handleReset}
          onExport={handleExport}
        />
      </section>
      {readerDashboardDetail && searchParams && (
        <Box>
          <section className='mb-[30px] pb-[30px] border-b border-gray'>
            <div className='flex items-center gap-x-[100px]'>
              <div>
                <div className='text-secondary-100 text-sm font-light'>
                  จำนวนนักอ่าน
                </div>
                <div className='text-secondary text-2xl font-bold'>
                  {readerDashboardDetail.totalReader.toLocaleString()}
                </div>
              </div>
              <div>
                <div className='text-secondary-100 text-sm font-light'>
                  เวลาใช้งานเฉลี่ย
                </div>
                <div className='text-secondary text-2xl font-bold'>
                  {Duration.fromObject({
                    milliseconds: readerDashboardDetail.averageTimeUsage,
                  })?.toFormat('h:mm:ss')}
                  <span className='text-secondary-100 text-sm '> hr</span>
                </div>
              </div>
            </div>
          </section>
          <div className='flex w-full space-x-[30px]'>
            <div className='flex flex-col w-full'>
              <VerticalBarChart
                title='Paying User'
                headerTitleClassName='text-[18px]'
                grid={{ left: 40, right: 15, top: 10 }}
                style={{ width: '100%', height: '350px' }}
                dataSource={setGraphPayingData(payingVsNonUserPayingData)}
              />
            </div>
            <div className='flex flex-col w-full'>
              <VerticalStackBarChart
                title='Paying VS Non-Paying User'
                headerTitleClassName='text-[18px]'
                grid={{ left: 40, right: 15, top: 65 }}
                style={{ width: '100%', height: '350px' }}
                dataSource={setGraphPayingVsNonUserPayingData(
                  payingVsNonUserPayingData
                )}
              />
            </div>
            {averageRevenuePerPayingUserData && (
              <div className='flex flex-col w-full'>
                <VerticalBarChart
                  title='ARPU (นับ coin out แบบเสียตังค์)'
                  headerTitleClassName='text-[18px]'
                  grid={{ left: 70, right: 15, top: 10 }}
                  style={{ width: '100%', height: '350px' }}
                  dataSource={averageRevenuePerPayingUserData}
                />
              </div>
            )}
          </div>
          <VerticalBarChart
            title='ช่วงเวลาเข้าใช้งาน'
            style={{ width: '100%', height: '350px' }}
            dataSource={readTimeUsage}
          />
          <div className='flex flex-col py-[30px] border-y-[1px] border-gray'>
            <div className='flex'>
              <PieChart title='เพศ' data={readerGender} legendStyle='left' />
              <PieChart title='ช่วงอายุ' data={readerAge} legendStyle='left' />
            </div>
            <div className='flex flex-col gap-y-[20px]'>
              <p className='font-bold text-secondary text-[24px]'>สถานที่</p>
              <LogCityChart data={performanceLogCity} />
            </div>
          </div>
          <div className='flex my-[30px] gap-x-[80px]'>
            <div className='w-full'>
              <TopMoneySpenderTable searchParams={searchParams} />
            </div>
            <div className='w-full'>
              <TopTimeSpenderTable searchParams={searchParams} />
            </div>
          </div>
        </Box>
      )}
    </>
  )
}

export default Overview
