export enum CodeChannelTypeEnum {
  SHOPEE = 'SHOPEE',
  LAZADA = 'LAZADA',
  OTHER = 'OTHER',
}

export const CodeChannelTypeValue = {
  [CodeChannelTypeEnum.SHOPEE]: 'Shopee',
  [CodeChannelTypeEnum.LAZADA]: 'Lazada',
}
