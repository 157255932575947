import { Link } from 'react-router-dom'

import { EyeIcon } from '@components/Icons'

export function ActionCell({ id }: { id: number }) {
  return (
    <Link target='_blank' to={`/preview/${id}`} className='py-[14px]'>
      <button
        className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
        type='button'
      >
        <EyeIcon className='text-secondary-100' width='18' height='18' />
      </button>
    </Link>
  )
}
