import { Expose } from 'class-transformer'

export class HotWriterType {
  @Expose() userUserId: number

  @Expose() userUsername: string

  @Expose() writerAffiliation: string

  @Expose() writerFullName: string

  @Expose() bookId: number

  @Expose() writerId: number

  @Expose() bookTitle: string

  @Expose() userViewCount: number
}
