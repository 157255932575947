import { Container } from '@components/Container'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Toggle } from '@components/Toggle'

import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { StatusSection } from './components/StatusSection'
import { DetailSection } from './components/DetailSection'
import { ReportReplyForm } from './components/ReportReplyForm'
import { ReportProps } from './interface'

export function ReportListDetailPage({
  report,
  isLoading,
  handleUpdateBookReport,
  handleOpenPublishActivityModal,
  handleOpenConfirmChangePublishModal,
  handleOpenReportDetailActivityModal,
}: ReportProps) {
  const permission = usePermissionAction('chapterBook')
  return (
    <Container>
      <Container.Title showBackBtn>
        <h1>รายละเอียด report</h1>
      </Container.Title>
      {report !== undefined && !isLoading ? (
        <div className='flex flex-col gap-y-[20px]'>
          <StatusSection
            status={report?.status || '-'}
            isWaitingReview={!!report?.status}
            isNotified={
              report?.status === ReportStatusEnum.REPORTED ||
              report?.status === ReportStatusEnum.SUCCESS
            }
            bookReportId={report.id}
            handleUpdateBookReport={handleUpdateBookReport}
          />
          <DetailSection detailData={report} />
          {permission.edit && (
            <ReportReplyForm
              bookReportId={report.id}
              fullName={report?.reportBy?.displayName || '-'}
              handleUpdateBookReport={handleUpdateBookReport}
              messageToBookOwner={report?.messageToBookOwner || ''}
              messageToReporter={report?.messageToReporter || ''}
              status={report.status}
            />
          )}
          <div className='flex flex-row justify-between'>
            {(report?.hasBookReportLog || report.hasBookLog) && (
              <div className='flex space-x-5'>
                {report?.hasBookReportLog && (
                  <button
                    type='button'
                    className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 border border-secondary text-secondary'
                    onClick={() => {
                      handleOpenReportDetailActivityModal(report.id)
                    }}
                  >
                    <span>ดูประวัติการจัดการรายงาน</span>
                  </button>
                )}
                {report.hasBookLog && (
                  <button
                    type='button'
                    className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 border border-secondary text-secondary'
                    onClick={() => {
                      handleOpenPublishActivityModal(
                        report.book.id,
                        report.type
                      )
                    }}
                  >
                    <span>ดูประวัติการเผยแพร่</span>
                  </button>
                )}
              </div>
            )}
            <div className='flex flex-row font-sarabun items-center gap-x-[10px]'>
              {report?.updatedBy && (
                <>
                  <div className='text-[14px] text-secondary-100 font-light'>
                    ผู้ดำเนินการ :
                  </div>
                  <div className='flex items-center gap-x-2'>
                    {report?.updatedBy?.avatarImgPath ? (
                      <img
                        className='w-[50px] h-[50px] rounded-full'
                        src={report?.updatedBy?.avatarImgPath}
                        alt='User cover'
                      />
                    ) : (
                      <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
                    )}
                    <div className='text-[14px] text-secondary font-bold'>
                      {report?.updatedBy?.displayName || 'ไม่ทราบชื่อ'}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='flex flex-row font-sarabun items-center gap-x-[10px]'>
              <div className='text-[14px] text-secondary font-light'>
                เผยแพร่เนื้อหา
              </div>
              <Toggle
                disabled={!permission.edit}
                checked={report.isVisible}
                onChange={() => {
                  handleOpenConfirmChangePublishModal({
                    id: report.id,
                    isVisible: !report.isVisible,
                  })
                }}
              />
              {/* <div className='border-l-[1px] border-gray h-[40px] mx-[20px]' />
              {permission.delete && (
                <>
                  <div className='text-[14px] text-secondary font-light'>
                    ลบเนื้อหา
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      handleUpdateBookReport({
                        id: report.id,
                        isBanned: !report.isBanned,
                      })
                    }}
                    disabled={report.status === 'SUCCESS'}
                    className='flex items-center space-x-[10px] disabled:opacity-70'
                  >
                    <div className='bg-[#feecec] p-[5px] rounded-[8px]'>
                      <BinIcon
                        className={`${
                          report.isBanned ? ' text-slate-400' : 'text-[#f74945]'
                        }`}
                        width='20'
                        height='20'
                      />
                    </div>
                  </button>
                </>
              )} */}
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>เกิดข้อผิดพลาด</div>
      )}
    </Container>
  )
}
