import { Exclude, Expose, Transform } from 'class-transformer'

@Exclude()
export class ActiveCoinType {
  @Expose() id: number

  @Expose() name: string

  @Expose()
  @Transform(
    ({ obj }) => !obj.isExpireByActivity && !obj.isExpireByPaid && !obj.editable
  )
  isGoldCoin: boolean

  @Expose()
  @Transform(
    ({ obj }) => obj.isExpireByActivity && obj.isExpireByPaid && !obj.editable
  )
  isSilverCoin: boolean

  @Expose()
  @Transform(
    ({ obj }) => obj.isExpireByActivity && obj.isExpireByPaid && obj.editable
  )
  isOtherCoin: boolean
}
