import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { WriterRejectModalProps, WithWriterRejectModalProps } from './interface'

export function withWriterRejectModal(
  Component: React.FC<WriterRejectModalProps>
) {
  function WriterRejectModal(props: WithWriterRejectModalProps) {
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'writerReject' })

    const { mutate: updateReject } = useMutation(
      (form: {
        id: number
        status: WriterStatusEnum
        rejectedReason?: string
      }) => client!.userWriterClient.verifyWriter(form),
      {
        onSuccess: () => {
          alert.success('อัปเดตเรียบร้อย')
          rejectModal.hide()
          navigate('/user/writer-submit')
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    function handleSubmit(form: { message: string }) {
      const { writerId, status } = props
      const params = {
        id: writerId,
        status:
          status === WriterStatusEnum.CREATED_PENDING
            ? WriterStatusEnum.CREATED_REJECTED
            : WriterStatusEnum.EDITED_REJECTED,
        rejectedReason: form.message,
      }

      updateReject(params)
    }

    function handleCancel() {
      rejectModal.hide()
    }

    const componentProps = {
      ...props,
      handleSubmit,
      handleCancel,
    }
    return <Component {...componentProps} />
  }

  return WriterRejectModal
}
