import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'

export class FrequencyToBuyCoinType {
  @Expose({ name: 'value' }) name: string

  @Expose({ name: 'totalBuyCoin' }) value: number

  @Expose() color: string
}

export class FrequencyToBuyCoinByUserType {
  @Expose() averageBuyCoin: number

  @Expose() keyType: TimeRangeEnum

  @Expose()
  @Type(() => FrequencyToBuyCoinType)
  @Transform(({ value, obj }) => {
    const TIME_ZONE_TH = 7
    const temp = [...value]
    const topThree = temp
      .sort(
        (a: FrequencyToBuyCoinType, b: FrequencyToBuyCoinType) =>
          b.value - a.value
      )
      .slice(0, 3)
    const fillData = []
    const max =
      obj.keyType === TimeRangeEnum.DAY
        ? 24
        : obj.keyType === TimeRangeEnum.MONTH
        ? obj.startEndOfMonth
          ? obj.startEndOfMonth - obj.startDay + obj.endDay + 1
          : obj.endDay - obj.startDay + 1
        : 12

    for (let i = 1; i <= max; i += 1) {
      const data = value.find((item: FrequencyToBuyCoinType) => {
        if (obj.keyType === TimeRangeEnum.MONTH) {
          const val = obj.startEndOfMonth
            ? obj.startDay + i - 1 > obj.startEndOfMonth
              ? obj.startDay + i - 1 - obj.startEndOfMonth
              : obj.startDay + i - 1
            : obj.startDay + i - 1
          return Number(item.name) === val
        }

        if (obj.keyType === TimeRangeEnum.DAY) {
          const time = Number(item.name) + TIME_ZONE_TH
          const res = time > 24 ? time - 24 : time
          return res === i
        }

        return Number(item.name) === i
      })

      const nameData =
        obj.keyType === TimeRangeEnum.DAY
          ? DateTime.now()
              .set({ hour: i, minute: 0 })
              .toFormat('HH:mm', { locale: 'th-TH' })
          : obj.keyType === TimeRangeEnum.MONTH
          ? obj.startEndOfMonth
            ? obj.startDay + i - 1 > obj.startEndOfMonth
              ? obj.startDay + i - 1 - obj.startEndOfMonth
              : obj.startDay + i - 1
            : obj.startDay + i - 1
          : DateTime.now()
              .set({ month: i })
              .toFormat('LLL', { locale: 'th-TH' })
      const color = topThree.some((item: FrequencyToBuyCoinType) => {
        if (obj.keyType === TimeRangeEnum.MONTH) {
          const val = obj.startEndOfMonth
            ? obj.startDay + i - 1 > obj.startEndOfMonth
              ? obj.startDay + i - 1 - obj.startEndOfMonth
              : obj.startDay + i - 1
            : obj.startDay + i - 1
          return Number(item.name) === val
        }

        if (obj.keyType === TimeRangeEnum.DAY) {
          const time = Number(item.name) + TIME_ZONE_TH
          const res = time > 24 ? time - 24 : time
          return res === i
        }

        return Number(item.name) === i
      })
        ? '#3f54d9'
        : '#cecfd7'

      if (data) {
        fillData.push({ name: nameData, value: data.value, color })
      } else {
        fillData.push({ name: nameData, value: 0, color })
      }
    }

    return fillData
  })
  data: FrequencyToBuyCoinType[]
}
