import { Tabs, Tab, TabBody, TabHeader } from '@components/Tabs'
import { DashboardPerformanceEnum } from '@interfaces/dashboard/DashboardPerformanceEnum'
import { PerformanceTemplate } from './components/PerformanceTemplate'

export function AppPerformancePage() {
  return (
    <Tabs initialActiveTab={DashboardPerformanceEnum.WEB} mode='line'>
      <Tab tabId={DashboardPerformanceEnum.WEB}>
        <TabHeader>
          <span>Website</span>
        </TabHeader>
        <TabBody>
          <PerformanceTemplate tabId={DashboardPerformanceEnum.WEB} />
        </TabBody>
      </Tab>
      <Tab tabId={DashboardPerformanceEnum.MOBILE}>
        <TabHeader>
          <span>App</span>
        </TabHeader>
        <TabBody>
          <PerformanceTemplate tabId={DashboardPerformanceEnum.MOBILE} />
        </TabBody>
      </Tab>
    </Tabs>
  )
}
