import {
  ClipboardCheckIcon,
  ClipboardPendingIcon,
  ClipboardCancelIcon,
} from '@components/Icons'

interface StatusProps {
  value: 'pending' | 'approved' | 'rejected'
}

const icon = {
  pending: (
    <div className='bg-primary/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
      <ClipboardPendingIcon className='text-primary' width='18' height='18' />
    </div>
  ),
  approved: (
    <div className='bg-green/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
      <ClipboardCheckIcon className='text-green' width='18' height='18' />
    </div>
  ),
  rejected: (
    <div className='bg-red/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
      <ClipboardCancelIcon className='text-red' width='18' height='18' />
    </div>
  ),
}

export function StatusCell({ value }: StatusProps) {
  if (value) {
    return icon[value]
  }
  return null
}
