import React from 'react'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function withTopPurchasePage(
  Component: React.FC<FunctionComponentType>
) {
  function WithTopPurchasePage() {
    return <Component />
  }
  return WithTopPurchasePage
}
