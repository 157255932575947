import { BookEnum } from '@interfaces/BookEnum'
import { OptionType } from '@interfaces/OptionType'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { SocialMediaChannelsEnum } from '@interfaces/promotionChllenge/SocialMediaChannelsEnum'

export const socialMediaChannelsOptions = [
  { label: 'Facebook', value: SocialMediaChannelsEnum.FACEBOOK },
  { label: 'Twitter', value: SocialMediaChannelsEnum.TWITTER },
  { label: 'Line', value: SocialMediaChannelsEnum.LINE },
]

export const bookTypeOptions = [
  { label: 'นิยาย', value: BookEnum.NOVEL },
  { label: 'การ์ตูน', value: BookEnum.MANGA },
]

export const actionOptions: OptionType[] = [
  {
    label: 'กดหัวใจ',
    value: PromotionChallengeActionEnum.LOVE,
  },
  {
    label: 'Comment นิยาย',
    value: PromotionChallengeActionEnum.COMMENT,
  },
  {
    label: 'Share นิยายไปยัง Social Media',
    value: PromotionChallengeActionEnum.SHARE,
  },
  {
    label: 'กรอกข้อมูล Profile ให้ครบ',
    value: PromotionChallengeActionEnum.FINISH_PROFILE,
  },
  {
    label: 'อ่านนิยายเรื่องใดก็ได้',
    value: PromotionChallengeActionEnum.READ,
  },
  {
    label: 'ปลดล็อกนิยายรายตอน',
    value: PromotionChallengeActionEnum.UNLOCK_CHAPTER,
  },
  {
    label: 'สนับสนุนตัวละคร',
    value: PromotionChallengeActionEnum.DONATE,
  },
  {
    label: 'เพิ่มตอนติดเหรียญ',
    value: PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
  },
  {
    label: 'สร้างเรื่องใหม่',
    value: PromotionChallengeActionEnum.CREATE_BOOK,
  },
]

export const readConditionOptions = [
  { label: 'ตอน', value: PromotionChallengeReadConditionEnum.CHAPTER },
  { label: 'เรื่อง', value: PromotionChallengeReadConditionEnum.BOOK },
]

export const benefitLimitType = {
  [PromotionChallengeLimitTypeEnum.ONCE]: 'ครั้งเดียว',
  [PromotionChallengeLimitTypeEnum.UNLIMITED]: 'ไม่จำกัด',
  [PromotionChallengeLimitTypeEnum.PER_DAY]: 'ครั้ง/วัน',
  [PromotionChallengeLimitTypeEnum.PER_CAMPAIGN]: 'ครั้ง/แคมเปญ',
}
