import { withPermissionGuard } from '@libs/permission'
import { StickerManagementPage } from './StickerManagementPage'
import { withStickerManagementPage } from './withStickerManagementPage'

const ConnectedStickerManagementPage = withPermissionGuard(
  withStickerManagementPage(StickerManagementPage),
  'chapterBook',
  'view'
)

export { ConnectedStickerManagementPage as StickerManagementPage }
