import { Upload2Icon } from '@components/Icons'
import { UploadCoinFieldProps } from './interface'

export function UploadCoinField({
  src,
  inputRef,
  onChange,
  showUploadDialog,
}: UploadCoinFieldProps) {
  return (
    <div className='relative cursor-pointer'>
      {src && (
        <div
          onClick={showUploadDialog}
          className='w-[159px] h-[159px] rounded-[8px]'
        >
          <img
            src={src}
            className='w-full h-full object-cover rounded-[8px]'
            alt='cover'
          />
        </div>
      )}

      {!src && (
        <div
          onClick={showUploadDialog}
          className='bg-blue-2 w-[159px] h-[159px] rounded-[8px] flex flex-col items-center justify-center'
        >
          <Upload2Icon className='text-secondary-100 w-[24px] h-[24px]' />
          <div className='text-secondary-100 font-medium font-mitr text-[14px] underline'>
            อัปโหลดรูปเหรียญ
          </div>
          <div className='text-secondary-100 text-[14px] font-light font-sarabun pt-[10px]'>
            100 X 100 px
          </div>
        </div>
      )}

      <input
        ref={inputRef}
        className='hidden'
        type='file'
        accept='image/*'
        onChange={onChange}
      />
    </div>
  )
}
