import { Expose, Transform, Type } from 'class-transformer'
import { TransactionTypeEnum } from '@interfaces/coin/TransactionTypeEnum'
import { ActivityEnum } from '@interfaces/ActivityEnum'
import { CoinLogTypeEnum } from '@interfaces/coin/CoinLogTypeEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'

export class ReferenceType {
  @Expose() id: number

  @Expose() name: string

  @Expose() coupon: string

  @Expose() coinName: string

  @Expose() promotionName: string

  @Expose() transactionDetail: string

  @Expose() transactionType: TransactionTypeEnum
}

export class UserCoinHistoriesType {
  @Expose() id: number

  @Expose() activity: ActivityEnum

  @Expose() type: CoinLogTypeEnum

  @Expose() platform: PlatformEnum

  @Expose()
  @Transform(({ obj }) => obj.transaction.transactionId)
  transactionId: string

  @Expose()
  @Transform(({ obj }) => obj.transaction.currency)
  currency: string

  @Expose()
  @Transform(({ obj }) => obj.transaction.amount || 0)
  amount: number

  @Expose()
  @Type(() => ReferenceType)
  reference: ReferenceType

  @Expose() paidCoin: number

  @Expose() freeCoin: number

  @Expose() createdAt: string

  @Expose()
  @Transform(({ obj }) => {
    return Number(obj.paidCoin) + Number(obj.freeCoin)
  })
  coinValue: number
}
