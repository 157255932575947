import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import type { FileType } from '@components/UploadFileUserIdField/interface'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { PromotionLastRequestType } from '../PromotionLastRequestType'

export class PromotionSaleFormType {
  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.id || undefined, {
    toClassOnly: true,
  })
  id?: number

  @Expose()
  name: string

  @Expose()
  description: string

  @Expose()
  userTerm: string

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : new Date()))
  startPublishedAt: Date

  @Expose()
  @Transform(({ obj }) =>
    obj.publishedType === PromotionPublishedEnum.NOW
      ? obj.startPublishedAt
        ? new Date(obj.startPublishedAt)
        : undefined
      : undefined
  )
  startNowAt?: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  endPublishedAt: Date

  @Expose()
  publishedType: PromotionPublishedEnum

  @Expose()
  status: PromotionStatusEnum

  @Expose()
  @Transform(
    ({ obj }) => obj?.promotionSale?.conditionType || PSConditionTypeEnum.ALL,
    {
      toClassOnly: true,
    }
  )
  conditionType: PSConditionTypeEnum

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionSale?.terms?.map((c: { termId: number }) => c.termId) || [],
    {
      toClassOnly: true,
    }
  )
  termIds: number[]

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.termCount, {
    toClassOnly: true,
  })
  termCount?: number

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionSale?.categories?.map((c: { id: number }) =>
        c.id.toString()
      ) || [],
    {
      toClassOnly: true,
    }
  )
  categoryIds?: string[]

  @Expose()
  @Transform(({ obj }) =>
    obj?.promotionSale.conditionFilePath && obj?.promotionSale.conditionFileName
      ? {
          url: obj.promotionSale.conditionFilePath,
          name: obj.promotionSale.conditionFileName,
        }
      : undefined
  )
  conditionFile?: FileType

  @Expose() conditionFileError?: string

  @Expose()
  @Transform(({ obj }) =>
    obj?.promotionSale?.conditionFileName
      ? undefined
      : `${obj?.promotionSale?.terms?.map(
          (c: { termId: number }) => c.termId
        )}` || ''
  )
  termIdInput?: string

  @Expose() termIdInputError?: string

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.actionValue || 0, {
    toClassOnly: true,
  })
  actionValue: number

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionSale?.coinsCondition?.map((coin: any) => coin?.id) || [],
    {
      toClassOnly: true,
    }
  )
  coinConditionIds: number[]

  @Expose() usageDay: DaySelectEnum[]

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionSale?.useCoinConditionType ||
      PSUseCoinConditionTypeEnum.ALL,
    {
      toClassOnly: true,
    }
  )
  useCoinConditionType: PSUseCoinConditionTypeEnum

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.benefitValue || 0, {
    toClassOnly: true,
  })
  benefitValue: number

  @Expose()
  @Transform(
    ({ obj }) => obj?.promotionSale?.benefitCoinId?.toString() || undefined,
    {
      toClassOnly: true,
    }
  )
  benefitCoinId?: string

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.benefitLimitType || undefined, {
    toClassOnly: true,
  })
  benefitLimitType?: PromotionChallengeLimitTypeEnum

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.benefitLimitValue || 0, {
    toClassOnly: true,
  })
  benefitLimitValue?: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.budget || 0, {
    toClassOnly: true,
  })
  budget: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionSale?.budgetPerDay || 0, {
    toClassOnly: true,
  })
  budgetPerDay?: number

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Transform(({ obj }) => !!obj?.promotionSale?.budgetPerDay, {
    toClassOnly: true,
  })
  isLimitBudget?: boolean

  @Expose() businessId?: string

  @Expose() createdAt?: string

  @Expose() updatedAt?: string

  @Expose() deletedAt?: string

  @Expose() isActive?: boolean

  @Expose() isEditable?: boolean

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}
