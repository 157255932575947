import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { Pagination } from '@components/Pagination'
import { WarningTableProps } from './interface'
import { columns } from './column'

export function WarningTable({
  data,
  isLoading,
  page,
  perpage,
  total,
  sort,
  pageChange,
  handleSort,
}: WarningTableProps) {
  return (
    <div>
      <Table
        fixedHeader
        className='max-h-[610px]'
        data={data}
        columns={columns}
        isLoading={isLoading}
        onSort={handleSort}
        initialSort={{ key: sort.key, order: sort.order }}
      />
      <div className='mt-[30px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
