import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import cloneDeep from 'lodash.clonedeep'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { usePagination } from '@hooks/usePagination'
import { useAlert } from '@hooks/useAlert'
import { SortByType, SortingType } from '@components/Table/interface'
import { NewsAndPromotionResponse } from '@models/newsAndPromotion/NewsAndPromotionResponse'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { NewsAndPromotionListPageProps, SearchParamsType } from './interface'

export function withNewsAndPromotionListPage(
  Component: React.FC<NewsAndPromotionListPageProps>
) {
  function WithNewsAndPromotionListPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      newsType: 'all',
      sortKey: 'updatedAt',
      sortOrder: SortingType.DESC,
    })
    const { page, perpage, pageChange } = usePagination({})
    const queryKey = ['news-and-promotion', searchParams, page, perpage]

    const {
      data: newsAndPromotion,
      refetch,
      isLoading,
    } = useQuery(queryKey, () =>
      client?.newsClient.getAllNewsAndPromotion({
        ...searchParams,
        limitPerPage: perpage,
        page,
      })
    )

    function handleDelete(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบข่าวสาร และโปรโมชั่นนี้หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.newsClient.removeNewsAndPromotion(id)
            alert.success('ลบข่าวสาร และโปรโมชั่น สำเร็จ')
            refetch()
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
          }
        },
        onClose: () => confirmModal.hide(),
      })
    }

    function handleDeleteAll(ids: number[]) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบข่าวสาร และโปรโมชั่นทั้งหมดนี้หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            const promises = ids.map(id =>
              client?.newsClient.removeNewsAndPromotion(id)
            )
            await Promise.all(promises)
            alert.success('ลบข่าวสาร และโปรโมชั่น สำเร็จ')
            refetch()
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
          }
        },
        onClose: () => confirmModal.hide(),
      })
    }

    async function handleEnable(id: number, value: boolean) {
      try {
        const newData = await client?.newsClient.updateNewsAndPromotionActive(
          id,
          value
        )
        if (newData) {
          queryClient.setQueryData<NewsAndPromotionResponse | undefined>(
            queryKey,
            (oldData?: NewsAndPromotionResponse) => {
              if (oldData) {
                const cloneData = cloneDeep(oldData)
                const index = oldData.data.findIndex(
                  item => item.id === newData.id
                )
                cloneData.data[index] = newData
                return cloneData
              }
              return oldData
            }
          )
        }
        alert.success('อัปเดทสถานะเรียบร้อย')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    function handleSelectAll() {
      if (newsAndPromotion?.data.length === selectedIds.length) {
        setSelectedIds([])
      } else {
        setSelectedIds(newsAndPromotion?.data?.map(row => row.id) ?? [])
      }
    }

    function handleSelect(id: number) {
      const index = selectedIds.findIndex(value => value === id)

      if (index !== -1) {
        setSelectedIds(prev => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds(prev => [...prev, id])
      }
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function handleChangeTab(tab: string) {
      setSelectedIds([])
      pageChange(1)
      setSearchParams(prev => ({ ...prev, newsType: tab }))
    }

    function handlePageChange(value: number) {
      pageChange(value)
      setSelectedIds([])
    }

    const newProps = {
      selectedIds,
      page,
      perpage,
      total: newsAndPromotion?.total || 0,
      data: newsAndPromotion?.data ?? [],
      activeTab: searchParams.newsType,
      handlePageChange,
      handleSort,
      handleSelectAll,
      handleSelect,
      handleDelete,
      handleDeleteAll,
      handleEnable,
      handleChangeTab,
      isLoading,
    }
    return <Component {...newProps} />
  }
  return WithNewsAndPromotionListPage
}
