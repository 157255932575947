import { useMemo } from 'react'
import cn from 'classnames'

import { Box } from '@components/Box'
import { ReaderBadgeType } from '@models/dashboard/ReaderBadgeType'
import { addComma } from '@libs/utils'
import {
  ChallengeUnit,
  ChallengeUnitValue,
} from '@interfaces/user-mission/ChallengeEnum'

export function UserMissionCard({ data }: { data: ReaderBadgeType }) {
  const { currentLevel, completed, challengeLevels } = data

  const trophy = useMemo(
    () =>
      challengeLevels
        .filter(prev => prev.isActive)
        .map((challenge, i) => ({
          iconUrl: challenge.iconUrl,
          active: i < currentLevel && completed > 0,
        })),
    [challengeLevels, completed, currentLevel]
  )

  const isMaxLevel = data.currentLevel === data.maxLevel
  const showNextLevel = data.currentLevel < trophy.length

  return (
    <Box className='shadow border-blue-2 flex flex-col items-center gap-y-[10px] py-[20px] mt-[10px] mb-[30px]'>
      <p className='font-mitr font-medium text-secondary text-center'>
        {data.title}
      </p>

      <div className='flex flex-col items-center justify-center gap-y-[10px]'>
        <div
          className={cn(
            'flex items-center justify-center w-[100px] aspect-square rounded-full bg-blue-2',
            {
              'opacity-20': !data.completed && data.currentLevel === 1,
            }
          )}
        >
          <img
            className='w-full h-full rounded-full object-cover'
            src={data.iconUrl}
            alt='user-mission'
          />
        </div>

        <div className='flex flex-col gap-y-[9px]'>
          <div className='text-center'>
            <p
              className={cn('text-primary font-bold text-[14px] mb-[4px]', {
                'opacity-20': !data.completed && data.currentLevel === 1,
              })}
            >
              Lv. {data.currentLevel} {data.currentLevelName}
            </p>
            <p className='font-light text-[12px] text-secondary-100 text-center'>
              {data.criteria}
              {!isMaxLevel
                ? ` ${addComma(data.previousTarget || 0, 0)} - ${addComma(
                    data.nextTarget - 1 || 0,
                    0
                  )} `
                : ` ${addComma(data.previousTarget || 0, 0)} `}
              {ChallengeUnitValue[ChallengeUnit[data.unit]]}
              {isMaxLevel && 'ขึ้นไป'}
            </p>
          </div>
          <div className='text-xs font-light text-secondary-100 text-center h-[37px]'>
            <p>
              {data.criteria}อีก
              <span className='text-primary font-bold'>
                {!data.completed && data.currentLevel === 1
                  ? ` 0 / 1 `
                  : isMaxLevel
                  ? ` ${addComma(data.completed, 0)} `
                  : ` ${addComma(
                      data.nextTarget - data.nextRemaining || 0,
                      0
                    )} / ${addComma(data.nextTarget || 0, 0)} `}
              </span>
              {ChallengeUnitValue[ChallengeUnit[data.unit]]}
              {data.maxLevel - 1 === data.currentLevel && <span>ขึ้นไป</span>}
            </p>
            {showNextLevel && (
              <p className='mt-[5px]'>
                เพื่อเลื่อนระดับเป็น{' '}
                <span className='text-primary font-bold'>
                  {!data.completed && data.currentLevel === 1 ? (
                    <>
                      Lv. {data.currentLevel} {data.currentLevelName}
                    </>
                  ) : (
                    <>
                      Lv. {data.nextLevel} {data.nextLevelName}
                    </>
                  )}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-wrap justify-center'>
        {trophy.map((r, i, arr) => (
          <div
            key={i}
            className={cn(
              'w-[30px] h-[30px] rounded-full bg-blue-2 flex items-center justify-center',
              {
                'opacity-30': !r.active,
                'mr-[2px]': i !== arr.length - 1,
              }
            )}
          >
            <img
              src={r.iconUrl}
              alt='challengeUrl'
              className='w-[30px] h-[30px] rounded-full object-cover'
            />
          </div>
        ))}
      </div>
    </Box>
  )
}
