import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'

import { Box } from '@components/Box'
import { LoadingModal } from '@components/modals/LoadingModalV2/LoadingModal'
import { UploadFile } from './components/UploadFile'
import { UploadFileFormType, UploadFilePageProps } from './interface'

const schema = Yup.object().shape({
  report: Yup.mixed().required(''),
  exchange: Yup.mixed().required(''),
})

export function UploadFilePage({
  updatedAt,
  canEdit,
  errorMsg,
  onSubmit,
  setErrorMsg,
}: UploadFilePageProps) {
  return (
    <Formik
      initialValues={
        {
          report: undefined,
          exchange: undefined,
        } as UploadFileFormType
      }
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ values, isValid, isSubmitting, dirty, resetForm }) => (
        <Form>
          <Box>
            <div className='text-[18px] font-bold text-secondary'>
              อัปโหลดไฟล์
            </div>
            <div className='text-[14px] font-light text-secondary mt-[10px]'>
              อัปโหลดไฟล์ข้อมูลการจ่ายเงินจาก App Store เข้าสู่ SAP
            </div>

            <UploadFile
              name='report'
              className='mt-[30px]'
              text='อัปโหลดไฟล์ Report จาก App Store'
              fileName={
                typeof values.report === 'object' ? values.report.name : ''
              }
              disabled={!canEdit || isSubmitting}
              errorMsg={errorMsg.report}
              onChange={() =>
                setErrorMsg(prev => ({
                  ...prev,
                  report: '',
                }))
              }
            />
            <UploadFile
              name='exchange'
              className='mt-[20px]'
              text='อัปโหลดไฟล์ Exchange จาก App Store'
              fileName={
                typeof values.exchange === 'object' ? values.exchange.name : ''
              }
              disabled={!canEdit || isSubmitting}
              errorMsg={errorMsg.exchange}
              onChange={() =>
                setErrorMsg(prev => ({
                  ...prev,
                  exchange: '',
                }))
              }
            />
            {updatedAt && (
              <div className='mt-[20px] text-[14px] font-light text-secondary'>
                * อัปโหลดข้อมูลล่าสุดเมื่อวันที่ {updatedAt}
              </div>
            )}
          </Box>

          <div className='mt-[30px] font-mitr text-[14px] font-medium text-center'>
            <button
              className={cn(
                'text-secondary-100 border border-secondary-100 py-[8.5px] px-[29.5px] rounded-[20px] mr-[20px] disabled:opacity-30',
                {
                  'cursor-not-allowed': isSubmitting || !dirty || !canEdit,
                }
              )}
              type='button'
              disabled={isSubmitting || !dirty || !canEdit}
              onClick={() => resetForm()}
            >
              ยกเลิก
            </button>
            <button
              className={cn(
                'bg-primary text-white py-[9.5px] px-[31.5px] rounded-[20px] disabled:opacity-30',
                {
                  'cursor-not-allowed':
                    !isValid || isSubmitting || !dirty || !canEdit,
                }
              )}
              type='submit'
              disabled={!isValid || isSubmitting || !dirty || !canEdit}
            >
              อัปโหลดไฟล์
            </button>
          </div>
          <LoadingModal isOpen={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}
