import cn from 'classnames'
import { useDropdown } from '@hooks/useDropdown'
import { DragIndicatorIcon } from '@components/Icons'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { ButtonSelectProps } from './interface'

export function ButtonSelect({
  className,
  isStopSapPayment,
  activeTab,
  onClick,
}: ButtonSelectProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className={cn('relative', className)}>
      <button type='button' className={cn('h-[30px]')} onClick={toggleDropdown}>
        <div className='flex'>
          <DragIndicatorIcon width='20' height='20' />
        </div>
      </button>
      {isShowDropdown && (
        <div
          ref={dropdownRef}
          className='absolute w-[158px] z-10 text-[14px] font-[300] py-[10px] text-black-480 mt-[3px] right-0 bg-white shadow  rounded-[8px] max-h-[400px] overflow-y-auto'
        >
          {activeTab === UserManageMentSearchEnum.PAYMENT &&
          !isStopSapPayment ? (
            <div
              className='flex items-center hover:bg-blue-8 hover:text-primary cursor-pointer py-[5px] px-[15px] whitespace-nowrap'
              onClick={onClick}
            >
              เปิดการจ่ายเงินอัตโนมัติ
            </div>
          ) : isStopSapPayment || isStopSapPayment === null ? (
            <div className='flex items-center hover:bg-blue-8 hover:cursor-not-allowed py-[5px] px-[15px] whitespace-nowrap'>
              ปิดการจ่ายเงินอัตโนมัติ
            </div>
          ) : (
            <div
              className='flex items-center hover:bg-blue-8 hover:text-primary cursor-pointer py-[5px] px-[15px] whitespace-nowrap'
              onClick={onClick}
            >
              ปิดการจ่ายเงินอัตโนมัติ
            </div>
          )}
        </div>
      )}
    </div>
  )
}
