import { PromotionSettingTabsProps } from './interface'

export function withPromotionSettingTabs(
  Component: React.FC<PromotionSettingTabsProps>
) {
  function WithPromotionSettingTabs(props: PromotionSettingTabsProps) {
    return <Component {...props} />
  }

  return WithPromotionSettingTabs
}
