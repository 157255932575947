import { gql } from 'graphql-request'

export const GET_USER_STOP_SAP_PAYMENT = gql`
  query UserStopSapPayment(
    $limitPerPage: Int
    $page: Int
    $searchText: String
    $orderBy: UserManagementOrderedList
  ) {
    userStopSapPayment(
      limitPerPage: $limitPerPage
      page: $page
      searchText: $searchText
      orderBy: $orderBy
    ) {
      data {
        id
        username
        displayName
        writer {
          id
          affiliation
          status
        }
      }
      total
    }
  }
`
