import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import {
  TopMoneySpenderTableProps,
  WithTopMoneySpenderTableProps,
} from './interface'

export function withTopMoneySpenderTable(
  Component: React.FC<TopMoneySpenderTableProps>
) {
  function WithTopMoneySpenderTable({
    searchParams,
  }: WithTopMoneySpenderTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'userCoinSpend',
      sortOrder: 'DESC',
    })

    const { data = [], isLoading } = useQuery(
      ['reader-top-money-spender', searchParams, sort],
      () =>
        client?.dashboardClient.getReaderTopMoneySpender({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          key: sort.sortKey,
          order: sort.sortOrder,
        }),
      {
        staleTime: Infinity,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const newProps = { data, handleSort, isLoading }

    return <Component {...newProps} />
  }

  return WithTopMoneySpenderTable
}
