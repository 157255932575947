import { PieChart } from '@components/chart/PieChart'
import { TraficProps } from './PerformanceTemplate/interface'

export function Trafic({ trafficCategory }: TraficProps) {
  return (
    <div className='w-full'>
      <PieChart
        title='Trafic'
        data={trafficCategory}
        legendStyle='left'
        style={{ width: '100%' }}
      />
    </div>
  )
}
