import cn from 'classnames'

import { TextArea } from '@components/TextArea'
import { TextAreaFieldProps } from './interface'

export function TextAreaField({
  className,
  textAreaClassName,
  labelClassName = 'text-secondary-100',
  label,
  touched,
  error,
  ...props
}: TextAreaFieldProps) {
  return (
    <div className={cn('text-[14px]', className)}>
      {label && (
        <label className={cn('block mb-[4px] text-[12px]', labelClassName)}>
          {label}
        </label>
      )}
      <TextArea
        className={cn({ 'border-red': touched && error }, textAreaClassName)}
        {...props}
      />
    </div>
  )
}
