import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { ReaderListProps, WithReaderListProps } from './interface'

export function withReaderList(Component: React.FC<ReaderListProps>) {
  function Hoc({ bookId, searchParams, uniqueView }: WithReaderListProps) {
    const [sort, setSort] = useState({
      orderKey: 'userTotalSpend',
      orderBy: SortingType.DESC,
    })

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, orderKey: key, orderBy: order }))
    }
    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['list-user-reading', bookId, sort, searchParams],
        ({ pageParam = 1 }) =>
          client?.storyClient.getListUserReading({
            bookId: Number(bookId),
            page: pageParam,
            startDate: searchParams.startDate,
            endDate: searchParams.endDate,
            ...sort,
          }),
        {
          enabled: !!bookId && !!searchParams,
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) {
              return lastPage.page + 1
            }

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const newProps = {
      data,
      isLoading,
      uniqueView,
      handleSort,
      handleFetchNextPage,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
