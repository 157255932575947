import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { CategoryOptionType } from '@models/category/CategoryOptionType'
import { client as appClient } from '@client/init'
import { CategoryType } from '@models/category/CategoryType'
import { CoverFormType } from '@models/cover/CoverFormType'
import { CategoryCoverType } from '@models/category/CategoryCoverType'
import { CategoryEditType } from '@features/chapterBook/pages/CategoryManagePage/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { GET_CATEGORY_OPTIONS } from './schemas/getCategory'
import { CREATE_CATEGORY_COVER } from './schemas/createCategoryCover'
import { UPDATE_CATEGORY_COVER } from './schemas/updateCategoryCover'
import { CATEGORY } from './schemas/category'
import { CATEGORY_COVER } from './schemas/categoryCover'
import { REMOVE_MANY_CATEGORY_COVER } from './schemas/removeManyCategoryCover'
import { ALL_CATEGORIES } from './schemas/categories'
import { CREATE_OR_UPDATE_CATEGORIES } from './schemas/createOrUpdateCategories'

export class CategoryClient {
  constructor(private client: GraphQLClient) {}

  async getCategoryOptions(
    bookType?: BookEnum[]
  ): Promise<CategoryOptionType[]> {
    const { categories } = await this.client.request(GET_CATEGORY_OPTIONS, {
      bookType,
    })
    return plainToInstance(CategoryOptionType, categories as [])
  }

  async allCategories(bookType?: BookEnum[]): Promise<CategoryType[]> {
    const { allCategories } = await this.client.request(ALL_CATEGORIES, {
      bookType,
    })
    return allCategories
  }

  async category(categoryId: number): Promise<CategoryType> {
    const { category } = await this.client.request(CATEGORY, {
      categoryId,
    })
    return category
  }

  async categoryCover(categoryCoverId: number): Promise<CategoryCoverType> {
    const { categoryCover } = await this.client.request(CATEGORY_COVER, {
      categoryCoverId,
    })
    return categoryCover
  }

  async removeManyCategoryCover(ids: number[]) {
    return this.client.request(REMOVE_MANY_CATEGORY_COVER, {
      ids,
    })
  }

  async createCategoryCover(form: CoverFormType): Promise<void> {
    let imgPath = ''
    if (form.coverImage.blob) {
      imgPath = await appClient.fileClient.uploadFile(form.coverImage.blob)
    }
    await this.client.request(CREATE_CATEGORY_COVER, {
      createCategoryCoverInput: {
        categoryId: form.categoryId,
        imgPath,
        coverName: form.coverName,
      },
    })
  }

  async createOrUpdateCategories(
    form: CategoryEditType[]
  ): Promise<CategoryType[]> {
    const { createOrUpdateCategory } = await this.client.request(
      CREATE_OR_UPDATE_CATEGORIES,
      {
        createOrUpdateCategoryInput: form,
      }
    )

    return plainToInstance(CategoryType, createOrUpdateCategory as [])
  }

  async updateCategoryCover(form: CoverFormType): Promise<void> {
    let imgPath = form.coverImage.url
    if (form.coverImage.blob) {
      imgPath = await appClient.fileClient.uploadFile(form.coverImage.blob)
    }
    await this.client.request(UPDATE_CATEGORY_COVER, {
      updateCategoryCoverInput: {
        id: form.id,
        imgPath,
        coverName: form.coverName,
      },
    })
  }
}
