import { gql } from 'graphql-request'

export const GET_BOOK_BY_TITLE = gql`
  query BookDashboardByTitle(
    $bookId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    bookDashboardByTitle(
      bookId: $bookId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      coverImgPath
      title
      penName {
        id
        firstPenName
      }
      chapterCount
      totalRead
      totalLike
      bookType
      totalSales
    }
  }
`
