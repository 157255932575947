import { DateTime } from 'luxon'
import cn from 'classnames'

import { Container } from '@components/Container'
import { ArrowLeftIcon } from '@components/Icons'
import { Loading } from '@components/Loading'
import {
  CodeTypeEnum,
  CodeTypeValue,
} from '@interfaces/promotionCode/CodeTypeEnum'
import { PromotionCodeHistoryPageProps } from './interface'
import { PromotionCodeInfo } from '../PromotionCodeDetailPage/components/PromotionCodeInfo'
import { PromotionCodeHistoryUserInfo } from './components/PromotionCodeHistoryUserInfo'
import CorrectionDetail from './components/CorrectionDetail'

export function PromotionCodeHistoryPage({
  historyList,
  historyData,
  coinOptions,
  listLoading,
  detailLoading,
  approvedLoading,
  selectIdHandle,
  requestId,
  goBackHandle,
  handleReject,
  handleApprove,
  handleGoToFormDetail,
}: PromotionCodeHistoryPageProps) {
  return (
    <Container>
      {listLoading ? (
        <Loading size={36} className='m-auto' />
      ) : historyList.length >= 1 ? (
        <>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={goBackHandle}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>

            <div className='text-[24px] text-secondary font-medium font-mitr'>
              ดูประวัติการเปลี่ยนโปรโมชั่น
            </div>
          </div>
          <div className='flex divide-x'>
            <div className='w-[180px] flex flex-col gap-y-[20px] max-h-[70vh] overflow-y-auto'>
              {historyList.map(item => (
                <button
                  key={item.id}
                  type='button'
                  className={cn('flex text-sm font-bold', {
                    'text-secondary': item.id === requestId,
                    'text-secondary-100': item.id !== requestId,
                  })}
                  onClick={() => selectIdHandle(item.id)}
                >
                  {DateTime.fromISO(item.createdAt)
                    .setLocale('th')
                    .toFormat('dd  LLL yyyy - HH:mm:ss')}
                </button>
              ))}
            </div>
            <div className='flex-1 pl-[50px]'>
              {detailLoading ? (
                <Loading size={36} className='m-auto' />
              ) : historyData ? (
                <>
                  <PromotionCodeInfo
                    promotionStatus={historyData.oldPromotion.status}
                    values={historyData.newPromotion}
                    isHistoryDetail
                  >
                    <div className='flex font-light gap-x-[40px] pt-[20px]'>
                      {
                        CodeTypeValue[
                          historyData.newPromotion.promotionCode.couponType ||
                            CodeTypeEnum.UNIQE
                        ]
                      }
                      {Boolean(
                        historyData.newPromotion.promotionCode.codesFileName
                      ) && (
                        <div className='text-secondary-100'>
                          อัปโหลดไฟล์คูปอง (.CSV) :
                          <span className='text-secondary font-bold'>
                            {
                              historyData.newPromotion.promotionCode
                                .codesFileName
                            }
                          </span>
                        </div>
                      )}
                      <div>
                        <span className='px-[5px]'>
                          {historyData.newPromotion.promotionCode.channels[0]?.totalCount.toLocaleString()}
                        </span>
                        Code
                      </div>
                    </div>
                  </PromotionCodeInfo>
                  <PromotionCodeHistoryUserInfo
                    status={historyData.oldPromotion.status}
                    data={historyData}
                    approvedLoading={approvedLoading}
                    handleGoToFormDetail={handleGoToFormDetail}
                    handleReject={handleReject}
                    handleApprove={handleApprove}
                  />
                  {historyData.correctionDetail && (
                    <CorrectionDetail
                      data={historyData.correctionDetail}
                      coinOptions={coinOptions}
                    />
                  )}
                  {historyData.rejectedReason && (
                    <div className='pt-[30px]'>
                      <div className='text-xs text-secondary-100 font-light'>
                        ส่งข้อความถึงผู้ดำเนินการ
                      </div>
                      <div className='text-sm font-light whitespace-pre-line'>
                        {historyData.rejectedReason}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <span className='text-center'>
                  เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่ในภายหลัง
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        'No data'
      )}
    </Container>
  )
}
