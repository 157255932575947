import { gql } from 'graphql-request'

export const EXPORT_SEARCH_MANAGEMENT = gql`
  query ExportSearchManagementHistory(
    $startDate: DateTime!
    $endDate: DateTime!
    $gender: GenderEnum
    $ageRange: AgeRange
    $searchText: String
  ) {
    exportSearchManagementHistory(
      startDate: $startDate
      endDate: $endDate
      gender: $gender
      ageRange: $ageRange
      searchText: $searchText
    )
  }
`
