import { Box } from '@components/Box'
import { DataOverAll } from '../DataOverAll'
import { SearchDate } from '../SearchDate'
import { StoryHotNewSection } from '../StoryHotNewSection'
import { TopBookShelfSection } from '../TopBookShelfSection'
import { TopCommentSection } from '../TopCommentSection'
import { TopLoveSection } from '../TopLoveSection'
import { TopViewSection } from '../TopViewSection'
import { WriterSection } from '../WriterSection'
import { OverallPageProps } from './interface'

export function OverallPage({
  data,
  searchParams,
  handleSearch,
  handleReset,
  handleExport,
}: OverallPageProps) {
  return (
    <>
      <SearchDate
        defaultSelectedDate={searchParams.typeDuration}
        onSearch={handleSearch}
        onReset={handleReset}
        onExport={handleExport}
      />

      {data && (
        <Box className='flex flex-col gap-y-[30px] mt-[30px]'>
          <DataOverAll data={data} />
          <WriterSection searchDateParams={searchParams} />
          <TopViewSection searchDateParams={searchParams} />
          <TopLoveSection searchDateParams={searchParams} />
          <TopBookShelfSection searchDateParams={searchParams} />
          <TopCommentSection searchDateParams={searchParams} />
          <StoryHotNewSection searchDateParams={searchParams} />
        </Box>
      )}
    </>
  )
}
