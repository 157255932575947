import React from 'react'
import cn from 'classnames'
import { Box } from '@components/Box'
import { formatDateDomini } from '@libs/utils'
import { BinIcon, EditIcon, PromotionCodeIcon } from '@components/Icons'
import {
  PromotionUserEnum,
  PromotionUserTypeValue,
} from '@interfaces/promotionCode/PromotionUserEnum'
import {
  DaySelectEnum,
  daySelectTypeValue,
} from '@interfaces/promotionCode/DaySelectEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import {
  PaymentMethodEnum,
  PaymentMethodTypeValue,
} from '@interfaces/promotionCode/PaymentMethodEnum'
import {
  CoinCoditionEnum,
  CoinCoditionTypeValue,
} from '@interfaces/promotionCode/CoinCoditionEnum'
import {
  PromotionStatusEnum,
  PromotionStatusTypeValue,
} from '@interfaces/promotionCode/PromotionStatusEnum'
import { Toggle } from '@components/Toggle'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionCodeInfoProps } from './interface'

export function PromotionCodeInfo({
  values,
  children,
  toggleActiveLoading = false,
  isHistoryDetail = false,
  promotionStatus,
  handleChangeStatus,
  handleEditPromotion,
  handleDeletePromotion,
}: PromotionCodeInfoProps) {
  const titleClassName = 'text-[12px] font-light text-secondary-100 mr-[5px]'
  const permission = usePermissionAction('promotionSetting')
  return (
    <Box className='divide-y-[1px] gap-y-[20px] text-[14px] flex flex-col text-secondary'>
      <div className='flex items-center '>
        <div className='flex items-center gap-x-[10px] pr-[20px]  border-r-[1px] whitespace-nowrap'>
          <PromotionCodeIcon />
          <span className='font-mitr font-medium text-[14px] text-primary'>
            โปรโมชั่น Code
          </span>
        </div>
        <div
          className={cn(
            'flex justify-center items-center whitespace-nowrap rounded mx-[20px] px-[8px] py-[4px] text-[12px] font-bold',
            {
              'bg-blue-2 text-secondary':
                promotionStatus === PromotionStatusEnum.PENDING,
              'bg-red-74 text-white':
                promotionStatus === PromotionStatusEnum.REJECTED,
              'bg-secondary text-white':
                promotionStatus === PromotionStatusEnum.APPROVED,
              'bg-green-986 text-white':
                promotionStatus === PromotionStatusEnum.STARTED,
              'bg-secondary-100 text-white':
                promotionStatus === PromotionStatusEnum.CLOSED,
              'border border-gray-393 text-secondary':
                promotionStatus === PromotionStatusEnum.DRAFT,
              'border border-green-986 text-green-986':
                promotionStatus === PromotionStatusEnum.GENERATE_CODE ||
                promotionStatus === PromotionStatusEnum.CODE_GENERATED,
              'border border-red-74 text-red-74':
                promotionStatus === PromotionStatusEnum.GENERATE_FAIL,
            }
          )}
        >
          {PromotionStatusTypeValue[promotionStatus!]}
        </div>
        <span className='text-lg font-bold text-secondary line-clamp-1 break-word'>
          {values.name}
        </span>
        {!isHistoryDetail && (
          <div className='flex ml-auto items-center gap-x-[10px] pl-[10px]  text-gray-500'>
            <Toggle
              checked={values.isActive}
              onChange={handleChangeStatus}
              disabled={!Boolean(handleChangeStatus) || toggleActiveLoading}
              className={cn({ hidden: !permission.edit })}
            />
            <button
              type='button'
              className={cn(
                'flex items-center ml-[10px] justify-center border border-[#f4f4f7] rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed',
                { hidden: !permission.edit }
              )}
              onClick={handleEditPromotion}
              disabled={Boolean(!handleEditPromotion)}
            >
              <EditIcon width='18' height='18' className='text-secondary-100' />
            </button>
            <button
              type='button'
              className={cn(
                'flex items-center justify-center border border-[#f4f4f7] rounded-lg w-[30px] h-[30px] disabled:opacity-30 disabled:cursor-not-allowed',
                { hidden: !permission.delete }
              )}
              onClick={handleDeletePromotion}
              disabled={Boolean(!handleDeletePromotion)}
            >
              <BinIcon width='18' height='18' className='text-secondary-100' />
            </button>
          </div>
        )}
      </div>
      <div className='flex gap-x-[30px] pt-[20px]'>
        <div>
          <span className={cn(titleClassName)}>
            Promotion ID :{' '}
            <span className='text-[14px] font-bold text-secondary'>
              {values.businessId || '-'}
            </span>
          </span>
        </div>
        <div>
          <span className={cn(titleClassName)}>Create date :</span>
          <span>{formatDateDomini(values.createdAt!, true)}</span>
        </div>
        <div
          className={cn({
            'mr-[40px]': values.publishedType === PromotionPublishedEnum.NOW,
          })}
        >
          <span className='text-[14px] mr-[5px] font -light text-secondary-100'>
            ระยะเวลาใช้โค้ด :
          </span>
          <span className='font-bold'>
            {values.publishedType === PromotionPublishedEnum.NOW &&
            !Boolean(values.startPublishedAt)
              ? 'ทันที'
              : formatDateDomini(values.startPublishedAt, true)}
          </span>
          <span className='text-[14px] px-[10px] font -light text-secondary-100'>
            ถึง
          </span>
          <span className='font-bold'>
            {formatDateDomini(values.endPublishedAt, true)}
          </span>
        </div>
      </div>
      <div className='flex flex-col pt-[20px] gap-y-[20px] '>
        <div className='flex'>
          <div className={cn('w-[120px]', titleClassName)}>รายละเอียด :</div>
          <div className='flex-1 break-word'>{values.description || '-'}</div>
        </div>
        <div className='flex'>
          <div className={cn('w-[120px] ', titleClassName)}>
            เงื่อนไขการใช้งาน :
          </div>
          <div className='flex-1 whitespace-pre-line break-word'>
            {values.userTerm || '-'}
          </div>
        </div>
      </div>
      <div className='flex pt-[20px]'>
        <div className={cn('w-[120px]', titleClassName)}>ช่องทาง :</div>
        <div className=' flex flex-1 flex-wrap  gap-x-[20px]'>
          {values.promotionCode.channels[0].name}(
          {values.promotionCode.channels[0].totalCount.toLocaleString()} Code)
        </div>
      </div>
      {values.userType === PromotionUserEnum.SPECIFY_USER && (
        <>
          <div className='flex pt-[20px] gap-x-[20px] items-center'>
            <div className='flex items-center whitespace-nowrap'>
              <div className={cn('pr-[10px]', titleClassName)}>สำหรับ :</div>
              <div>
                กำหนดผู้ใช้งาน
                {values.isFirstTopUp && ', เติมเงินครั้งแรก'}
              </div>
            </div>
            {values.usersFileName && (
              <div className='flex items-center'>
                <div className={cn('pr-[10px]', titleClassName)}>
                  Upload file: :
                </div>
                <div className='font-bold'>{values.usersFileName || '-'}</div>
              </div>
            )}
            <div className='text-secondary-100'>
              ({values.userLength.toLocaleString()} ID)
            </div>
          </div>
          <div className='flex pt-[20px] gap-x-[40px] items-center'>
            <div className='flex items-center whitespace-nowrap'>
              {values.isUsageLimitPerUser ? (
                <div className={titleClassName}>
                  จำกัดการใช้โปรโมชั่น:{' '}
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.usageLimitPerUser.toLocaleString()}
                  </span>
                  ครั้ง
                </div>
              ) : (
                <div className={titleClassName}>ไม่จำกัดจำนวน</div>
              )}
            </div>
            <div className='flex items-center'>
              {values.isUsageLimit ? (
                <div className={titleClassName}>
                  จำกัดจำนวนสิทธิ์ :{' '}
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.usageLimit.toLocaleString()}
                  </span>
                  สิทธิ์
                </div>
              ) : (
                <div className={cn('whitespace-nowrap', titleClassName)}>
                  จำกัดจำนวนสิทธิ์:
                  <span className='text-[14px] px-[10px] text-secondary'>
                    ไม่จำกัดสิทธิ์
                  </span>
                </div>
              )}
            </div>
            <div className='flex items-center'>
              <div className={cn('whitespace-nowrap', titleClassName)}>
                ใช้ได้ในวัน:
              </div>
              <div className='pl-[10px] max-w-[220px] break-word line-clamp-1'>
                {values.usageDay.length === 7
                  ? 'ทุกวัน'
                  : values.usageDay.map(item => (
                      <React.Fragment key={item}>
                        <span>{daySelectTypeValue[item as DaySelectEnum]}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className='flex flex-wrap gap-y-[20px] pt-[20px] font-light'>
            <div className={cn('mr-[10px] text-secondary-100')}>เงื่อนไข :</div>

            {values.promotionCode.type === UserByEnum.BUY_COIN && (
              <>
                <div className='text-secondary-100 flex gap-x-[40px] mr-[40px] items-center'>
                  <div className='flex'>
                    เมื่อผู้ใช้งานซื้อเหรียญ
                    <span className='text-secondary px-[10px]'>
                      {
                        CoinCoditionTypeValue[
                          values.promotionCode
                            .buyCoinCondition as CoinCoditionEnum
                        ]
                      }
                    </span>
                    <span className='text-primary font-bold mr-[4px]'>
                      {values.promotionCode.buyCoinConditionValue.toLocaleString()}
                    </span>
                    บาท
                  </div>
                  <div className='flex'>
                    <span className='whitespace-nowrap'>ผ่านช่องทาง :</span>
                    <span className='ml-[10px] text-secondary break-word line-clamp-1'>
                      {values.promotionCode.paymentChannels.length === 4
                        ? 'ทุกช่องทาง'
                        : values.promotionCode.paymentChannels.map(item => (
                            <React.Fragment key={item}>
                              <span>
                                {
                                  PaymentMethodTypeValue[
                                    item as PaymentMethodEnum
                                  ]
                                }
                              </span>
                              <span className=' mr-1 last:mr-0 last:hidden'>
                                ,
                              </span>
                            </React.Fragment>
                          ))}
                    </span>
                  </div>
                </div>
                <div className='flex gap-x-[40px]'>
                  <div>
                    รับเพิ่ม:
                    <span className='text-primary font-bold pl-[10px] pr-[4px]'>
                      {values.promotionCode.benefitValue.toLocaleString()}
                    </span>
                    <span className='text-secondary-100'>
                      {values.promotionCode.coinName}
                    </span>
                  </div>
                </div>
              </>
            )}
            {values.promotionCode.type === UserByEnum.REDEEM && (
              <div>
                <span className='text-secondary-100 mr-[40px]'>
                  เมื่อผู้ใช้งานรีดีมโค้ด
                </span>
                รับทันที:
                <span className='text-primary font-bold pl-[10px] pr-[4px]'>
                  {values.promotionCode.benefitValue.toLocaleString()}
                </span>
                <span className='text-secondary-100'>
                  {values.promotionCode.coinName}
                </span>
              </div>
            )}
          </div>
        </>
      )}
      {values.userType !== PromotionUserEnum.SPECIFY_USER && (
        <div className='flex flex-col pt-[20px] '>
          <div className='flex  gap-x-[20px] items-center'>
            <div className='flex items-center whitespace-nowrap'>
              <div className={cn('pr-[10px]  ', titleClassName)}>สำหรับ :</div>
              <div>
                {PromotionUserTypeValue[values.userType as PromotionUserEnum]}
                {values.isFirstTopUp && ', เติมเงินครั้งแรก'}
              </div>
            </div>
            <div className='flex items-center whitespace-nowrap'>
              {values.isUsageLimitPerUser ? (
                <div className={titleClassName}>
                  จำกัดการใช้โปรโมชั่น:{' '}
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.usageLimitPerUser.toLocaleString()}
                  </span>
                  ครั้ง
                </div>
              ) : (
                <div className={titleClassName}>ไม่จำกัดจำนวน</div>
              )}
            </div>
            <div className='flex items-center'>
              {values.isUsageLimit ? (
                <div className={titleClassName}>
                  จำกัดจำนวนสิทธิ์ :{' '}
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.usageLimit.toLocaleString()}
                  </span>
                  สิทธิ์
                </div>
              ) : (
                <div className={cn('whitespace-nowrap', titleClassName)}>
                  จำกัดจำนวนสิทธิ์:
                  <span className='text-[14px] px-[10px] text-secondary'>
                    ไม่จำกัดสิทธิ์
                  </span>
                </div>
              )}
            </div>
            <div className='flex items-center'>
              <div className={cn('whitespace-nowrap', titleClassName)}>
                ใช้ได้ในวัน:
              </div>
              <div className='pl-[10px] max-w-[220px] break-word line-clamp-1'>
                {values.usageDay.length === 7
                  ? 'ทุกวัน'
                  : values.usageDay.map(item => (
                      <React.Fragment key={item}>
                        <span>{daySelectTypeValue[item as DaySelectEnum]}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className='flex flex-wrap gap-y-[20px] pt-[20px] font-light'>
            <div className={cn('mr-[10px] text-secondary-100')}>เงื่อนไข :</div>

            {values.promotionCode.type === UserByEnum.BUY_COIN && (
              <>
                <div className='text-secondary-100 flex gap-x-[40px] mr-[40px] items-center'>
                  <div className='flex'>
                    เมื่อผู้ใช้งานซื้อเหรียญ
                    <span className='text-secondary px-[10px]'>
                      {
                        CoinCoditionTypeValue[
                          values.promotionCode
                            .buyCoinCondition as CoinCoditionEnum
                        ]
                      }
                    </span>
                    <span className='text-primary font-bold mr-[4px]'>
                      {values.promotionCode.buyCoinConditionValue.toLocaleString()}
                    </span>
                    บาท
                  </div>
                  <div className='flex'>
                    <span className='whitespace-nowrap'>ผ่านช่องทาง :</span>
                    <span className='ml-[10px] text-secondary break-word line-clamp-1'>
                      {values.promotionCode.paymentChannels.length === 4
                        ? 'ทุกช่องทาง'
                        : values.promotionCode.paymentChannels.map(item => (
                            <React.Fragment key={item}>
                              <span>
                                {
                                  PaymentMethodTypeValue[
                                    item as PaymentMethodEnum
                                  ]
                                }
                              </span>
                              <span className=' mr-1 last:mr-0 last:hidden'>
                                ,
                              </span>
                            </React.Fragment>
                          ))}
                    </span>
                  </div>
                </div>
                <div className='flex gap-x-[40px]'>
                  <div>
                    รับเพิ่ม:
                    <span className='text-primary font-bold pl-[10px] pr-[4px]'>
                      {values.promotionCode.benefitValue.toLocaleString()}
                    </span>
                    <span className='text-secondary-100'>
                      {values.promotionCode.coinName}
                    </span>
                  </div>
                </div>
              </>
            )}
            {values.promotionCode.type === UserByEnum.REDEEM && (
              <div>
                <span className='text-secondary-100 mr-[40px]'>
                  เมื่อผู้ใช้งานรีดีมโค้ด
                </span>{' '}
                รับทันที:
                <span className='text-primary font-bold px-[5px]'>
                  {values.promotionCode.benefitValue.toLocaleString()}
                </span>{' '}
                <span className='text-secondary-100'>
                  {values.promotionCode.coinName}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </Box>
  )
}
