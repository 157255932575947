import { ArrowRightIcon } from '@components/Icons'
import { promotionChallengeBenefitLimitTypeText } from '@libs/promotion/utils'
import { formatDateTime } from '@libs/utils'
import { promotionSaleCoinConditionValue } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { daySelectTypeValue } from '@interfaces/promotionCode/DaySelectEnum'
import { CorrectionDetailProps } from './interface'

function CorrectionDetail({ data, benefitCoin }: CorrectionDetailProps) {
  const oldBenefirCoin = data.saleBenefit?.benefitCoin?.old || benefitCoin
  const newBenefirCoin = data.saleBenefit?.benefitCoin?.new || benefitCoin

  return (
    <div className='py-[30px] flex flex-col space-y-5 border-b text-sm font-light'>
      <div className='text-secondary-100'>รายละเอียดการแก้ไข :</div>
      <div className='grid grid-cols-12 gap-y-[20px] items-start'>
        {data?.name && (
          <>
            <div className='col-span-6 flex items-center space-x-[10px]'>
              <span className='text-secondary-100 whitespace-nowrap'>
                ชื่อโปรโมชั่น :
              </span>
              <span className='text-secondary'>{data.name?.old}</span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 text-secondary'>{data.name?.new}</div>
          </>
        )}
        {data.description && (
          <>
            <div className='col-span-6 flex items-center space-x-[10px]'>
              <span className='text-secondary-100 whitespace-nowrap'>
                รายละเอียด :
              </span>
              <span className='text-secondary'>{data.description.old}</span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 text-secondary'>
              {data.description.new}
            </div>
          </>
        )}
        {data.userTerm && (
          <>
            <div className='col-span-6 flex space-x-[10px]'>
              <span className='text-secondary-100 whitespace-nowrap'>
                เงื่อนไขการใช้งาน :
              </span>
              <span className='text-secondary whitespace-pre-line break-word'>
                {data.userTerm.old}
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 text-secondary whitespace-pre-line break-word'>
              {data.userTerm.new}
            </div>
          </>
        )}
        {data.publishPeriod && (
          <>
            <div className='col-span-6 flex items-center space-x-[10px]'>
              <span className='text-secondary-100 whitespace-nowrap'>
                เผยแพร่ :
              </span>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.startPublishedAt!.old!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
              <span className='text-secondary-100'>ถึง</span>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.endPublishedAt!.old!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>

            <div className='col-span-5 flex items-center space-x-[10px]'>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.startPublishedAt!.new!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
              <span className='text-secondary-100'>ถึง</span>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.endPublishedAt!.new!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
            </div>
          </>
        )}
        {data.saleAction?.actionValue && (
          <>
            <div className='col-span-6 flex items-center space-x-[10px]'>
              <span className='text-secondary-100'>จำนวน :</span>
              <span>{data.saleAction.actionValue.old.toLocaleString()}</span>
              <span className='text-secondary-100'>เหรียญขึ้นไป</span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 flex items-center space-x-[10px]'>
              <span>{data.saleAction.actionValue.new.toLocaleString()}</span>
              <span className='text-secondary-100'>เหรียญขึ้นไป</span>
            </div>
          </>
        )}
        {data.saleAction?.coinsCondition && (
          <>
            <div className='col-span-6'>
              {data.saleAction.coinsCondition.old
                .map(row => row.name)
                .join(', ')}
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5'>
              {data.saleAction.coinsCondition.new
                .map(row => row.name)
                .join(', ')}
            </div>
          </>
        )}
        {data.usageDay && (
          <>
            <div className='col-span-6 flex space-x-[10px]'>
              <span className='text-secondary-100'>ใช้ได้ในวัน :</span>
              <span>
                {data.usageDay.old.length === 7
                  ? 'ทุกวัน'
                  : data.usageDay.old
                      .map(row => daySelectTypeValue[row])
                      .join(', ')}
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5'>
              {data.usageDay.new.length === 7
                ? 'ทุกวัน'
                : data.usageDay.new
                    .map(row => daySelectTypeValue[row])
                    .join(', ')}
            </div>
          </>
        )}
        {data.saleAction?.useCoinConditionType && (
          <>
            <div className='col-span-6 flex space-x-[10px]'>
              <span className='text-secondary-100'>ใช้เหรียญ :</span>
              <span>
                {
                  promotionSaleCoinConditionValue[
                    data.saleAction.useCoinConditionType.old
                  ]
                }
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5'>
              {
                promotionSaleCoinConditionValue[
                  data.saleAction.useCoinConditionType.new
                ]
              }
            </div>
          </>
        )}
        {data.saleBenefit && (
          <>
            <div className='col-span-6 space-x-[10px]'>
              <span className='text-secondary-100'>รับเหรียญ :</span>
              <span>{data.saleBenefit.benefitValue?.old.toLocaleString()}</span>
              <span className='text-secondary-100'>
                เหรียญ{data.saleBenefit.benefitCoin?.old}
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 space-x-[10px]'>
              <span>{data.saleBenefit.benefitValue?.new.toLocaleString()}</span>
              <span className='text-secondary-100'>
                เหรียญ{data.saleBenefit.benefitCoin?.new}
              </span>
            </div>
          </>
        )}
        {data.saleBenefitLimit && (
          <>
            <div className='col-span-6 space-x-[10px]'>
              <span className='text-secondary-100'>เก็บเหรียญได้ :</span>
              {data.saleBenefitLimit.benefitLimitType?.old ===
                PromotionChallengeLimitTypeEnum.PER_CAMPAIGN ||
              data.saleBenefitLimit.benefitLimitType?.old ===
                PromotionChallengeLimitTypeEnum.PER_DAY ? (
                <span>
                  {data.saleBenefitLimit.benefitLimitValue?.old.toLocaleString()}
                </span>
              ) : null}
              <span>
                {promotionChallengeBenefitLimitTypeText(
                  data.saleBenefitLimit.benefitLimitType?.old
                )}
              </span>
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 space-x-[10px]'>
              {data.saleBenefitLimit.benefitLimitType?.new ===
                PromotionChallengeLimitTypeEnum.PER_CAMPAIGN ||
              data.saleBenefitLimit.benefitLimitType?.new ===
                PromotionChallengeLimitTypeEnum.PER_DAY ? (
                <span>
                  {data.saleBenefitLimit.benefitLimitValue?.new.toLocaleString()}
                </span>
              ) : null}
              <span>
                {promotionChallengeBenefitLimitTypeText(
                  data.saleBenefitLimit.benefitLimitType?.new
                )}
              </span>
            </div>
          </>
        )}
        {data.saleBudget && (
          <>
            <div className='col-span-6 space-x-[10px]'>
              <span className='text-secondary-100'>งบประมาณ/แคมเปญ :</span>
              <span>{data.saleBudget.budget?.old.toLocaleString()}</span>
              <span className='text-secondary-100'>เหรียญ{oldBenefirCoin}</span>

              {data.saleBudget.budgetPerDay?.old && (
                <>
                  <span className='text-secondary-100'>จำกัดจำนวน</span>
                  <span>
                    {data.saleBudget.budgetPerDay.old.toLocaleString()}
                  </span>
                  <span className='text-secondary-100'>
                    เหรียญ{oldBenefirCoin}/วัน
                  </span>
                </>
              )}
            </div>
            <div className='col-span-1 justify-self-center'>
              <ArrowRightIcon className='text-primary' width='18' height='18' />
            </div>
            <div className='col-span-5 space-x-[10px]'>
              <span>{data.saleBudget.budget?.new.toLocaleString()}</span>
              <span className='text-secondary-100'>เหรียญ{newBenefirCoin}</span>
              {data.saleBudget.budgetPerDay?.new && (
                <>
                  <span className='text-secondary-100'>จำกัดจำนวน</span>
                  <span>
                    {data.saleBudget.budgetPerDay.new.toLocaleString()}
                  </span>
                  <span className='text-secondary-100'>
                    เหรียญ{newBenefirCoin}/วัน
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CorrectionDetail
