import { Expose, Transform } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'

export class EbookOverviewSaleTopRankDetailType {
  @Expose() coverImgPath: string

  @Expose() downloadedCount: number

  @Expose() ebookType: BookEnum

  @Expose() id: number

  @Expose() penName: string

  @Expose() readTrialChapterCount: number

  @Expose() reviewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  rowNumber: number

  @Expose() title: string

  @Expose() totalSales: number

  @Expose() viewCount: number

  @Expose() wishlistCount: number
}
