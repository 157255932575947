import { Expose } from 'class-transformer'
import { PenNameListType } from './PenNameListType'

export class UserPenNameListResponse {
  @Expose() total: number

  @Expose() page: number

  @Expose() data: PenNameListType[]
}
