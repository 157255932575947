import { withPermissionGuard } from '@libs/permission'
import { CreatePopupBannerPage } from './CreatePopupBannerPage'
import { withCreatePopupBannerPage } from './withCreatePopupBannerPage'

const ConnectedCreatePopupBannerPage = withPermissionGuard(
  withCreatePopupBannerPage(CreatePopupBannerPage),
  'homePageManagement',
  'create'
)

export { ConnectedCreatePopupBannerPage as CreatePopupBannerPage }
