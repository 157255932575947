/* eslint-disable max-classes-per-file */
import { Expose, Type } from 'class-transformer'

class RoleType {
  @Expose() id: number
}

class UserType {
  @Expose() email: string
}

export class PendingListAdminType {
  @Expose() id: number

  @Expose()
  @Type(() => UserType)
  user: UserType

  @Expose()
  @Type(() => RoleType)
  role: RoleType

  @Expose() confirmationSentAt: string
}
