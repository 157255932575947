import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { ResetPasswordPageProps } from './interface'

export function withResetPasswordPage(
  Component: React.FC<ResetPasswordPageProps>
) {
  function WithResetPasswordPage() {
    const { resetPassword } = useAuthentication()
    const [searchParams] = useSearchParams()
    const alert = useAlert()
    const navigate = useNavigate()

    function submitResetPassword(form: {
      password: string
      confirmPassword: string
    }) {
      resetPassword(searchParams.get('oobCode') || '', form.password)
    }

    const newProps = {
      submitResetPassword,
    }
    return <Component {...newProps} />
  }

  return WithResetPasswordPage
}
