import { Expose, Type } from 'class-transformer'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { PenNameType } from './PenNameType'
import { WriterType } from './WriterType'

export class UserManagementList {
  @Expose() id: number

  @Expose() idNumber: string

  @Expose() username: string

  @Expose() email: string

  @Expose() idCardImgPath: string

  @Expose() displayName: string

  @Expose() isIdCard: boolean

  @Expose()
  @Type(() => WriterType)
  writer: WriterType

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType[]

  @Expose()
  isBan: boolean

  @Expose()
  ageVerify: AgeVerifyEnum

  @Expose()
  writingDisabled: boolean

  @Expose() isPinEnable: boolean
}
