import cn from 'classnames'
import { EditorContent } from '@tiptap/react'
import { TiptapViewerProps } from './interface'

function TiptapViewer({ editor, className }: TiptapViewerProps) {
  return (
    <EditorContent editor={editor} className={cn('tiptap-viewer', className)} />
  )
}

export default TiptapViewer
