import { Formik, Form } from 'formik'
import { Tabs, Tab, TabHeader, TabBody } from '@components/Tabs'
import { BookEnum } from '@interfaces/BookEnum'
import { Button } from '@components/Button'
import { SelectSearchField } from '@components/forms/SelectSearchField'
import { MultiSelectField } from '@components/forms/MultiSelectField'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { WritingTable } from './components/WritingTable'
import { WritingSectionProps } from './interface'

export function WritingSection({
  initialValues,
  data,
  categories,
  penNameOptions,
  page,
  perpage,
  total,
  isLoading,
  publishedCount,
  draftCount,
  searchParam,
  pageChange,
  handleSubmitSearch,
  handleSort,
  handleSelectTab,
}: WritingSectionProps) {
  const bookType = [
    { value: BookEnum.NOVEL, text: 'นิยาย' },
    { value: BookEnum.MANGA, text: 'การ์ตูน' },
  ]

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmitSearch}>
        {({ resetForm, submitForm }) => (
          <Form>
            <div className='flex flex-col w-full my-[30px]'>
              <div className='w-full mb-[20px]'>
                <SelectSearchField
                  name='text'
                  className='w-full'
                  placeholder='ค้นหานามปากกา'
                  options={penNameOptions}
                />
              </div>
              <div className='flex items-end gap-x-[20px]'>
                <MultiSelectField
                  name='bookType'
                  isInputLabel
                  className='w-full'
                  label='ลักษณะงานเขียน'
                  options={bookType}
                />
                <MultiSelectField
                  name='categories'
                  isInputLabel
                  className='w-full mr-[10px]'
                  label='หมวดหมู่'
                  options={categories}
                />
                <Button
                  type='reset'
                  className='underline text-secondary whitespace-pre !px-0 text-[14px]'
                  onClick={() => {
                    resetForm()
                    submitForm()
                  }}
                >
                  ล้างค่า
                </Button>
                <Button
                  type='submit'
                  className='bg-secondary text-[14px] text-white'
                >
                  ค้นหา
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Tabs
        initialActiveTab={searchParam.bookStatus}
        mode='line'
        onActiveTabChange={(tab: string) => handleSelectTab(tab)}
      >
        <Tab tabId={BookStatusEnum.PUBLISHED}>
          <TabHeader>
            <div>
              งานเขียนที่เผยแพร่{' '}
              <span className='font-light'>
                ({publishedCount.toLocaleString()})
              </span>
            </div>
          </TabHeader>
          <TabBody>
            <WritingTable
              data={data}
              handleSort={handleSort}
              isLoading={isLoading}
              page={page}
              perpage={perpage}
              total={total}
              pageChange={pageChange}
            />
          </TabBody>
        </Tab>
        <Tab tabId={BookStatusEnum.DRAFT}>
          <TabHeader>
            <div>
              งานเขียนที่ไม่เผยแพร่{' '}
              <span className='font-light'>
                ({draftCount.toLocaleString()})
              </span>
            </div>
          </TabHeader>
          <TabBody>
            <WritingTable
              data={data}
              handleSort={handleSort}
              isLoading={isLoading}
              page={page}
              perpage={perpage}
              total={total}
              pageChange={pageChange}
            />
          </TabBody>
        </Tab>
      </Tabs>
    </>
  )
}
