import { withPermissionGuard } from '@libs/permission'
import { CreateHighlightBannerPage } from './CreateHighlightBannerPage'
import { withCreateHighlightBannerPage } from './withCreateHighlightBannerPage'

const Connected = withPermissionGuard(
  withCreateHighlightBannerPage(CreateHighlightBannerPage),
  'homePageManagement',
  'create'
)

export { Connected as CreateHighlightBannerPage }
