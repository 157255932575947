import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import { client } from '@client/init'
import { useDebounce } from '@hooks/useDebounce'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'
import {
  CreatePromotionCodeProps,
  WithCreatePromotionCodeProps,
} from './interface'

export function withCreatePromotionCode(
  Component: React.FC<CreatePromotionCodeProps>
) {
  function WithCreatePromotionCode({
    generateCouponStatus,
    ...props
  }: WithCreatePromotionCodeProps) {
    const { values, setFieldValue, errors } =
      useFormikContext<CreateCodeFormType>()
    const { debounce } = useDebounce()
    const [generateCoupon, setGenerateCoupon] = useState(generateCouponStatus)

    useEffect(() => {
      debounce(() => {
        if (values.universalCodeInput) {
          const matched = /\b[A-Z0-9]+[0-9A-Z]{5,}\b/.test(
            values.universalCodeInput
          )
          if (matched) {
            client?.promotionCodeClient
              .checkCouponCodeAlreadyExist(values.universalCodeInput)
              .then(res => {
                if (res) {
                  setFieldValue(
                    'universalCodeInputError',
                    '*Code นี้ถูกใช้แล้ว ไม่สามารถใช้งานได้'
                  )
                } else {
                  setFieldValue('coupons', [values.universalCodeInput])
                  setFieldValue('universalCodeInputError', undefined)
                }
              })
              .catch(error => {
                setFieldValue(
                  'universalCodeInputError',
                  'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
                )
              })
          } else {
            setFieldValue('universalCodeInputError', 'รูปแบบไม่ถูกต้อง')
          }
        } else {
          setFieldValue('universalCodeInputError', undefined)
        }
      }, 600)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.universalCodeInput])

    useEffect(() => {
      if (values.codeType === CodeTypeEnum.UNIQE) {
        setFieldValue('universalCodeInput', '')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.codeType])

    const newProps = {
      values,
      errors,
      generateCoupon,
      setGenerateCoupon,
      setFieldValue,
      ...props,
    }

    return <Component {...newProps} />
  }

  return WithCreatePromotionCode
}
