import { ArrowRightIcon } from '@components/Icons'
import {
  CoinCoditionEnum,
  CoinCoditionTypeValue,
} from '@interfaces/promotionCode/CoinCoditionEnum'
import {
  DiscountOptionEnum,
  DiscountOptionTypeValue,
} from '@interfaces/promotionCode/DiscountOptionEnum'
import {
  PaymentMethodEnum,
  PaymentMethodTypeValue,
} from '@interfaces/promotionCode/PaymentMethodEnum'
import { dayOptionsText } from '@libs/promotion/utils'
import { formatDateTime } from '@libs/utils'
import React from 'react'
import { CorrectionDetailProps } from './interface'

function CorrectionDetail({ data, coinOptions }: CorrectionDetailProps) {
  return (
    <div className='py-[30px] flex flex-col space-y-5 border-b text-sm font-light'>
      <div className='text-secondary-100'>รายละเอียดการแก้ไข :</div>
      {data?.name && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              ชื่อโปรโมชั่น :
            </span>
            <span className='text-secondary'>{data.name?.old}</span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              ชื่อโปรโมชั่น :
            </span>
            <span className='text-secondary'>{data.name?.new}</span>
          </div>
        </div>
      )}
      {data.description && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              รายละเอียด :
            </span>
            <span className='text-secondary'>{data.description.old}</span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              รายละเอียด :
            </span>
            <span className='text-secondary'>{data.description.new}</span>
          </div>
        </div>
      )}
      {data.userTerm && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              เงื่อนไขการใช้งาน :
            </span>
            <span className='text-secondary whitespace-pre-line break-word'>
              {data.userTerm.old}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              เงื่อนไขการใช้งาน :
            </span>
            <span className='text-secondary whitespace-pre-line break-word'>
              {data.userTerm.new}
            </span>
          </div>
        </div>
      )}
      {data.publishPeriod && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>เผยแพร่ :</span>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.startPublishedAt!.old!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
            <span className='text-secondary-100'>ถึง</span>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.endPublishedAt!.old!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>

          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.startPublishedAt!.new!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
            <span className='text-secondary-100'>ถึง</span>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.endPublishedAt!.new!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
          </div>
        </div>
      )}
      {data.usageLimitPerUser && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดการใช้โปรโมชั่น :</span>
            <span className='text-secondary'>
              {data.usageLimitPerUser.old === 'UNLIMITED'
                ? 'ไม่จำกัด'
                : data.usageLimitPerUser.old === 'LIMITED'
                ? 'จำกัด'
                : data.usageLimitPerUser.old}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดการใช้โปรโมชั่น :</span>
            <span className='text-secondary'>
              {data.usageLimitPerUser.new === 'UNLIMITED'
                ? 'ไม่จำกัด'
                : data.usageLimitPerUser.new === 'LIMITED'
                ? 'จำกัด'
                : data.usageLimitPerUser.new}
            </span>
          </div>
        </div>
      )}
      {data.usageLimit && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดจำนวนสิทธิ์ :</span>
            <span className='text-secondary'>
              {data.usageLimit.old === 'UNLIMITED'
                ? 'ไม่จำกัด'
                : data.usageLimit.old === 'LIMITED'
                ? 'จำกัด'
                : data.usageLimit.old}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดจำนวนสิทธิ์ :</span>
            <span className='text-secondary'>
              {data.usageLimit.new === 'UNLIMITED'
                ? 'ไม่จำกัด'
                : data.usageLimit.new === 'LIMITED'
                ? 'จำกัด'
                : data.usageLimit.new}
            </span>
          </div>
        </div>
      )}
      {data.usageDay && (
        <div className='grid grid-cols-9 items-baseline'>
          <div className='col-span-4 flex items-baseline space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              ใช้ได้ในวัน :
            </span>
            <span className='text-secondary flex flex-wrap'>
              {data.usageDay.old?.length
                ? data.usageDay.old.length === 7
                  ? 'ทุกวัน'
                  : data.usageDay.old.map((day, index) => (
                      <React.Fragment key={index}>
                        <span>{dayOptionsText(day)}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))
                : '-'}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-baseline space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              ใช้ได้ในวัน :
            </span>
            <span className='text-secondary flex flex-wrap'>
              {data.usageDay.new?.length
                ? data.usageDay.new.length === 7
                  ? 'ทุกวัน'
                  : data.usageDay.new.map((day, index) => (
                      <React.Fragment key={index}>
                        <span>{dayOptionsText(day)}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))
                : '-'}
            </span>
          </div>
        </div>
      )}
      {data.codeCondition && (
        <div className='grid grid-cols-9 items-baseline'>
          <div className='col-span-4 flex items-baseline space-x-[10px]'>
            <span className='text-secondary-100'>เงื่อนไข :</span>
            {data.codeCondition.type?.old === 'REDEEM' &&
              'เมื่อผู้ใช้งานรีดีมโค้ด '}
            {data.codeCondition.type?.old === 'BUY_COIN' && (
              <div className='flex flex-col gap-y-[5px]'>
                <div className='flex'>
                  เมื่อผู้ใช้งานซื้อเหรียญ
                  <span className='text-secondary px-[4px]'>
                    {
                      CoinCoditionTypeValue[
                        data.codeCondition.buyCoinCondition
                          ?.old as CoinCoditionEnum
                      ]
                    }
                  </span>
                  <span className='text-primary font-bold mr-[4px]'>
                    {data.codeCondition.buyCoinConditionValue?.old.toLocaleString()}
                  </span>
                  บาท
                </div>
                <div className='flex break-all line-clamp-1'>
                  ผ่านช่องทาง :
                  <span className='text-secondary pl-[5px]'>
                    {data.codeCondition.paymentChannels?.old.length === 4
                      ? 'ทุกช่องทาง'
                      : data.codeCondition.paymentChannels?.old.map(
                          item =>
                            `${
                              PaymentMethodTypeValue[item as PaymentMethodEnum]
                            },`
                        )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-baseline space-x-[10px]'>
            <span className='text-secondary-100'>เงื่อนไข :</span>
            {data.codeCondition.type?.new === 'REDEEM' &&
              'เมื่อผู้ใช้งานรีดีมโค้ด '}
            {data.codeCondition.type?.new === 'BUY_COIN' && (
              <div className='flex flex-col gap-y-[5px]'>
                <div className='flex'>
                  เมื่อผู้ใช้งานซื้อเหรียญ
                  <span className='text-secondary px-[4px]'>
                    {
                      CoinCoditionTypeValue[
                        data.codeCondition.buyCoinCondition
                          ?.new as CoinCoditionEnum
                      ]
                    }
                  </span>
                  <span className='text-primary font-bold mr-[4px]'>
                    {data.codeCondition.buyCoinConditionValue?.new.toLocaleString()}
                  </span>
                  บาท
                </div>
                <div className='flex break-all line-clamp-1'>
                  ผ่านช่องทาง :
                  <span className='text-secondary break-all pl-[5px] '>
                    {data.codeCondition.paymentChannels?.new.length === 4
                      ? 'ทุกช่องทาง'
                      : data.codeCondition.paymentChannels?.new.map(
                          item =>
                            `${
                              PaymentMethodTypeValue[item as PaymentMethodEnum]
                            },`
                        )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {data.codeBenefit && (
        <div className='grid grid-cols-9 items-center'>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <div className='text-secondary-100'>
              รับเพิ่ม:{' '}
              <span className='text-primary font-bold px-[5px]'>
                {data.codeBenefit.benefitValue?.old.toLocaleString()}
              </span>{' '}
              <span className='text-secondary-100'>
                {
                  coinOptions.find(
                    item => item.value === data.codeBenefit?.coinsId?.old
                  )?.label
                }
              </span>
            </div>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-4 flex items-center space-x-[10px]'>
            <div className='text-secondary-100'>
              รับเพิ่ม:{' '}
              <span className='text-primary font-bold px-[5px]'>
                {data.codeBenefit.benefitValue?.new.toLocaleString()}
              </span>{' '}
              <span className='text-secondary-100'>
                {
                  coinOptions.find(
                    item => item.value === data.codeBenefit?.coinsId?.new
                  )?.label
                }
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CorrectionDetail
