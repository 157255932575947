import cn from 'classnames'
import { ProgressBar } from '@components/ProgressBar'
import { CategorySectionProps } from './interface'

export function CategorySection({
  data,
  maxValue,
  topThree,
}: CategorySectionProps) {
  return (
    <div>
      <div className='font-sarabun text-[24px] font-sarabun font-bold text-secondary'>
        สัดส่วนการสนับสนุนตามหมวดหมู่งานเขียน
      </div>
      <div className='pt-[15px] text-[14px]'>
        {data.map(item => (
          <div
            key={item.categoryId}
            className='grid grid-cols-[200px_80px_1fr] font-sarabun items-center py-[5px]'
          >
            <div className='text-secondary'>{item.categoryName}</div>
            <div className='text-secondary-100 text-right'>
              {item.totalDonation.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </div>
            <ProgressBar
              className='bg-transparent ml-[20px]'
              progressBarClassName={cn('rounded-[40px] bg-secondary-50', {
                '!bg-primary': topThree.includes(item.categoryId),
              })}
              value={item.totalDonation}
              max={maxValue}
            />
          </div>
        ))}
      </div>
      <hr className='border border-gray h-[1px] my-[30px] w-full' />
    </div>
  )
}
