import { SearchInput } from '@components/SearchInput'
import { SearchSectionProps } from './interface'

export function SearchSection({
  onSearch,
  handleReset,
  placeholder,
  defaultValue,
}: SearchSectionProps) {
  return (
    <div className='flex text-[14px]'>
      <SearchInput
        className='w-full text-primary'
        placeholder={placeholder}
        defaultValue={defaultValue}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onSearch((event.target as HTMLInputElement).value)
          }
        }}
        onReset={handleReset}
      />
    </div>
  )
}
