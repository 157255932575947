import React from 'react'
import { OverviewProps } from './interface'

export function withOverview(Component: React.FC<OverviewProps>) {
  function Hoc(props: OverviewProps) {
    return <Component {...props} />
  }

  return Hoc
}
