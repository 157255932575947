import { SortingType } from '@components/Table/interface'
import { OptionType } from '@interfaces/OptionType'

export enum SelectedDateEnum {
  TO_DAY = 'TO_DAY',
  SEVEN_DAY = 'SEVEN_DAY',
  THROUGHOUT_THE_CAMPAIGN = 'THROUGHOUT_THE_CAMPAIGN',
}

export type SearchPromotioType = {
  searchText: string
  selectedDate: SelectedDateEnum | null
  startDate: Date | null
  endDate: Date | null
  key: string
  order: SortingType
}

export interface PromotionSearchFormProps {
  initialValues: SearchPromotioType
  placeholder: string
  dateRangeOptions: OptionType[]
  handleReset: () => void
  handleSubmit: (val: SearchPromotioType) => Promise<void>
  handleExport: () => Promise<void>
}
