import { useStory } from '@hooks/story/useStory'
import React from 'react'

import { IntroductionProps } from './interface'

export function withIntroduction(Component: React.FC<IntroductionProps>) {
  function Hoc() {
    const { data } = useStory()

    if (!data) return null

    const componentProps = {
      intro: data?.intro ?? '',
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
