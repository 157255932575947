import { Pagination } from '@components/Pagination'
import { DateTimeInput } from '@components/DateTimeInput'
import { NoData } from '@components/NoData'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { columns } from './column'
import { CoinTableProps } from './interface'

function CoinTable({
  page,
  perpage,
  data,
  isLoading,
  searchParams,
  total,
  handleSort,
  pageChange,
  startDateChange,
  endDateChange,
}: CoinTableProps) {
  return (
    <div>
      <div className='flex items-center mb-[30px]'>
        <div className='text-secondary text-lg font-bold flex-1'>
          รายละเอียดการซื้อ/ใช้เหรียญ
        </div>
        <DateTimeInput
          placeholder='เลือกวัน/เวลา'
          selected={searchParams.startDate}
          onChange={startDateChange}
          className='w-[186px] min-w-[186px]'
          showTimeSelect
        />
        <div className='mx-[20px] text-sm font-light text-black-480'>ถึง</div>
        <DateTimeInput
          placeholder='เลือกวัน/เวลา'
          selected={searchParams.endDate}
          onChange={endDateChange}
          className='w-[186px] min-w-[186px]'
          showTimeSelect
        />
      </div>

      <div className='flex pb-[20px]'>
        <Table
          data={data}
          columns={columns}
          onSort={handleSort}
          isLoading={isLoading}
          initialSort={{
            key: searchParams.sortKey,
            order: searchParams.sortOrder,
          }}
          className='w-[1750px]'
          headerClassName='border-y-[1px] border-gray text-secondary-100 text-[12px] font-bold'
        />
      </div>
      <div className='mt-[10px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}

export default CoinTable
