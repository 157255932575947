import { Expose, Type } from 'class-transformer'
import { PaymentReportType } from './PaymentReportType'

export class PaymentReportResponse {
  @Expose() totalPaid: number

  @Expose() total: number

  @Expose() page: number

  @Expose()
  @Type(() => PaymentReportType)
  data: PaymentReportType[]
}
