import { Link } from 'react-router-dom'

import { NoData } from '@components/NoData'
import { Table } from '@components/Table'
import { ExportButton } from '@components/ExportButton'
import { CoinDetailTableProps } from './interface'

export function CoinDetailTable({
  remainCoins,
  columns,
  sort,
  isLoading,
  handleSort,
  handleWaypointEnter,
  exportFile,
}: CoinDetailTableProps) {
  return (
    <div className='w-full mt-[20px]'>
      <Table
        className='h-[480px] pr-[20px]'
        data={remainCoins?.pages.flatMap(row => row.data) || []}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary border-b-[1px]'
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        fixedHeader
        initialSort={{
          key: sort.key,
          order: sort.order,
        }}
        onWaypointEnter={handleWaypointEnter}
      />

      <div className='flex justify-end gap-x-[20px] mt-[30px]'>
        <Link to='/coin/summary/coin-balance'>
          <button
            type='button'
            className='font-mitr text-[14px] py-[4px] px-[20px] rounded-full bg-blue-6 text-primary '
          >
            ดูรายการทั้งหมด
          </button>
        </Link>
        <ExportButton className='h-[30px]' handleClick={exportFile} />
      </div>
    </div>
  )
}
