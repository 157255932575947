import { gql } from 'graphql-request'

export const GET_ALL_SALES_BY_USER = gql`
  query GetAllSalesByUser(
    $userType: UserType
    $orderBy: GetAllSalesByUserOrderedList
    $searchText: String
    $page: Int
    $limitPerPage: Int
    $secondaryCoinId: Int
  ) {
    getAllSalesByUser(
      userType: $userType
      orderBy: $orderBy
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
      secondaryCoinId: $secondaryCoinId
    ) {
      data {
        id
        totalIncome
        username
        totalReceivedDonation
        totalReceivedSales
        currentFreeCoin
        currentPaidCoin
        isStopSapPayment
      }
    }
  }
`
