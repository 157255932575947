import { Formik, Form } from 'formik'

import { PenIcon } from '@components/Icons'
import { Modal } from '@components/Modal'
import { ProgressBarOverAll } from '@components/ProgressBar'
import { Button } from '@components/Button'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { addComma } from '@libs/utils'
import { OverAllReportProps } from './interface'

export function OverAllReport({
  totalOverAllSale,
  saleTarget,
  isOpen,
  openSaleTargetSettingModal,
  closeSaleTargetSettingModal,
  handleSaleTargetChange,
}: OverAllReportProps) {
  return (
    <div className='pb-[20px] border-b-[1px]'>
      <div className='flex justify-between items-center text-[14px] text-secondary-100 mb-[10px] font-light'>
        <span>ยอดขายภาพรวมทั้งระบบ ({addComma(totalOverAllSale)})</span>
        <div className='flex items-center gap-x-[10px]'>
          <span>Sale Target ({addComma(saleTarget)})</span>
          <button
            type='button'
            className='border rounded-[8px] flex justify-center items-center w-[30px] h-[30px]'
            onClick={openSaleTargetSettingModal}
          >
            <PenIcon width='20' height='20' />
          </button>
        </div>
      </div>
      <ProgressBarOverAll value={totalOverAllSale} max={saleTarget} />
      <Modal isOpen={isOpen} onClose={closeSaleTargetSettingModal}>
        <Formik
          initialValues={{ saleTarget }}
          onSubmit={handleSaleTargetChange}
          enableReinitialize
        >
          {() => (
            <Form className='px-[80px] py-[40px]'>
              <div className='font-mitr font-medium text-[24px] text-secondary mb-[30px]'>
                Sale target setting
              </div>
              <NumberWithCommaField
                name='saleTarget'
                label='Sale target'
                rightIcon={
                  <div className='text-[14px] text-secondary-100 font-light'>
                    บาท
                  </div>
                }
                maxLength={13}
              />
              <div className='flex justify-center mt-[40px]'>
                <Button
                  className='text-secondary text-[14px]'
                  type='button'
                  onClick={closeSaleTargetSettingModal}
                >
                  ยกเลิก
                </Button>
                <Button
                  className='bg-primary text-white text-[14px]'
                  type='submit'
                >
                  บันทึก
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}
