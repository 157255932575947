import PaymentReportTable from '../PaymentReportTable'
import { ReportByUserProps } from './interface'
import { ByUserForm } from '../ByUserForm'

function ReportByUser({
  data,
  total,
  amount,
  isLoading,
  initialValues,
  sortBy,
  handleSearch,
  handleSortChange,
  handleWaypointEnter,
  handleReset,
  handleExport,
}: ReportByUserProps) {
  return (
    <>
      <ByUserForm
        initialValues={initialValues}
        handleSearch={handleSearch}
        onResetForm={handleReset}
        handleExport={handleExport}
      />
      <PaymentReportTable
        sortBy={sortBy}
        data={data}
        total={total}
        amount={amount}
        isLoading={isLoading}
        handleSortChange={handleSortChange}
        handleWaypointEnter={handleWaypointEnter}
      />
    </>
  )
}

export default ReportByUser
