import React from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { CoinHistoryTypeEnum } from '@interfaces/coin/CoinHistoryTypeEnum'
import { CoinHistoryTableAcceptProps, CoinHistoryTableProps } from './interface'

const withCoinTable = (Component: React.FC<CoinHistoryTableProps>) => {
  function WithCoinTable({
    searchParam,
    activeTab,
    page,
    perpage,
    total,
    pageChange,
    handleTotalChange,
    handleSort,
  }: CoinHistoryTableAcceptProps) {
    const client = useClient()

    const queryKey = ['coin-histories', searchParam, page, activeTab]

    const { data, isLoading } = useQuery(
      queryKey,
      () =>
        client!.coinClient.getCoinHistoriesData({
          limitPerPage: perpage,
          page,
          coinHistoryType: activeTab as CoinHistoryTypeEnum,
          searchParam,
        }),
      {
        onSuccess: response => {
          handleTotalChange(response?.total)
        },
      }
    )

    const newProps = {
      data: data?.data ?? [],
      isLoading,
      page,
      perpage,
      total,
      searchParam,
      pageChange,
      handleSort,
    }
    return <Component {...newProps} />
  }

  return WithCoinTable
}

export default withCoinTable
