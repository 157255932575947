import { gql } from 'graphql-request'

export const GET_POSTER_BANNER = gql`
  query Query($getPosterBannerId: Int!) {
    getPosterBanner(id: $getPosterBannerId) {
      id
      runningNo
      imgPath
      description
      isOnWeb
      isOnIos
      isOnAndroid
      rawLink
      linkWeb
      linkIos
      linkAndroid
      isActive
    }
  }
`

export const CREATE_POSTER_BANNER = gql`
  mutation CreatePosterBanner(
    $createPosterBannerInput: CreatePosterBannerInput!
  ) {
    createPosterBanner(createPosterBannerInput: $createPosterBannerInput) {
      id
    }
  }
`

export const UPDATE_POSTER_BANNER = gql`
  mutation Mutation($updatePosterBannerInput: UpdatePosterBannerInput!) {
    updatePosterBanner(updatePosterBannerInput: $updatePosterBannerInput) {
      id
    }
  }
`

export const DELETE_POSTER_BANNER = gql`
  mutation RemovePosterBanner($removePosterBannerId: Int!) {
    removePosterBanner(id: $removePosterBannerId) {
      id
    }
  }
`

export const DELETE_POSTER_BANNERS = gql`
  mutation RemovePosterBanners($highlightGroupId: Int!, $ids: [Int!]!) {
    removePosterBanners(highlightGroupId: $highlightGroupId, ids: $ids) {
      id
      runningNo
    }
  }
`

export const GET_HIGHLIGHT_GROUP_POSTER_BANNER = gql`
  query GetHighlightGroupPoster($id: Int!) {
    getHighlightGroupPoster(id: $id) {
      id
      bannerName
      runningNo
      bannerType
      status
      isActive
      publishedFrom
      publishedTo
      posterBanner {
        id
        runningNo
        imgPath
        description
        isOnWeb
        isOnIos
        isOnAndroid
        rawLink
        linkWeb
        linkIos
        linkAndroid
        isActive
      }
    }
  }
`
