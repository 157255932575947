import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ButtonSelect } from '../ButtonSelect'
import { ActionCellProps } from './interface'

export function ActionCell({
  id,
  isStopSapPayment,
  activeTab,
  handleActiveSapPayment,
}: ActionCellProps) {
  const permission = usePermissionAction('user')

  return (
    <div className='flex gap-x-[10px]'>
      <Link to={`/user/sales-by-user/${id}`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-secondary-100' width='18' height='18' />
        </button>
      </Link>
      {permission.edit && (
        <ButtonSelect
          isStopSapPayment={isStopSapPayment}
          activeTab={activeTab}
          onClick={() => handleActiveSapPayment(id)}
        />
      )}
    </div>
  )
}
