import { withPermissionGuard } from '@libs/permission'
import { EditPopupBannerPage } from './EditPopupBannerPage'
import { withEditPopupBannerPage } from './withEditPopupBannerPage'

const ConnectedEditPopupBannerPage = withPermissionGuard(
  withEditPopupBannerPage(EditPopupBannerPage),
  'homePageManagement',
  'edit'
)

export { ConnectedEditPopupBannerPage as EditPopupBannerPage }
