import { withPermissionGuard } from '@libs/permission'
import { WriterSubmitDetailPage } from './WriterSubmitDetailPage'
import { withWriterSubmitDetailPage } from './withWriterSubmitDetailPage'

const ConnectedWriterSubmitDetailPage = withPermissionGuard(
  withWriterSubmitDetailPage(WriterSubmitDetailPage),
  'user',
  'view'
)
export { ConnectedWriterSubmitDetailPage as WriterSubmitDetailPage }
