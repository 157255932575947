import { Expose, Type } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'

export class BoughtEbookReaderType {
  @Expose() id: number

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() firstPenName: string

  @Expose() buyPrice: number

  @Expose() createdAt: string
}

export class BoughtEbookReaderResponse {
  @Expose()
  @Type(() => BoughtEbookReaderType)
  data: BoughtEbookReaderType[]

  @Expose() total: number

  @Expose() page: number
}
