import React, { useRef } from 'react'
import { useFormikContext } from 'formik'

import { useAlert } from '@hooks/useAlert'
import { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import { UploadStickerButtonFieldProps } from './interface'

export function withUploadStickerButtonField(
  Component: React.FC<UploadStickerButtonFieldProps>
) {
  function WithUploadStickerButtonField() {
    const { error } = useAlert()
    const inputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } =
      useFormikContext<StickerManagementFormType>()

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        const img = new Image()
        const url = URL.createObjectURL(files[0])
        img.src = url
        img.onload = () => {
          if (img.width === img.height) {
            setFieldValue('upload', {
              url: URL.createObjectURL(files[0]),
              blob: files[0],
            })
          } else {
            error('สติ๊กเกอร์ต้องมีขนาด 1:1')
          }
          URL.revokeObjectURL(url)
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: values?.upload?.url,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadStickerButtonField
}
