import React, { useRef } from 'react'
import cx from 'classnames'

import { Upload2Icon } from '@components/Icons'
import { UploadCoverProps } from '@interfaces/UploadCover'

export const UploadCover = React.memo(
  ({
    className,
    src,
    onChange,
    label = 'เพิ่มหน้าปก',
    description = '',
    inputRef,
  }: UploadCoverProps) => {
    const ref = useRef<HTMLInputElement>(null)

    function showUploadDialog() {
      const event = inputRef ? inputRef.current : ref.current
      if (event) {
        event.click()
      }
    }

    return (
      <div className={cx('relative rounded-[8px] overflow-hidden', className)}>
        {src ? (
          <img src={src} className='w-full h-full object-cover' alt='cover' />
        ) : (
          <div className='flex flex-col justify-center items-center bg-blue-2 h-full'>
            <Upload2Icon className='text-secondary-100 w-[24px] h-[24px]' />
            <div
              className='underline text-secondary-100 text-sm font-mitr font-medium cursor-pointer'
              onClick={showUploadDialog}
            >
              {label}
            </div>
            <div className='mt-[10px] text-secondary-100 text-sm font-light'>
              {description}
            </div>
          </div>
        )}
        <input
          ref={inputRef || ref}
          className='hidden'
          type='file'
          accept='image/*'
          onChange={onChange}
        />
      </div>
    )
  }
)
