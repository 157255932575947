/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'

function Footnote({ node }: any) {
  return (
    <NodeViewWrapper as='span' id={`footnote-${node.attrs.hrefId}`}>
      <sup>
        <a
          onClick={() => {
            const el = document.querySelector('#footnote')
            if (el) {
              el.scrollIntoView({ block: 'center' })
            }
          }}
          contentEditable={false}
          style={{ color: '#0c1135', textDecoration: 'unset' }}
        >
          [{node.attrs.hrefId}]
        </a>
      </sup>
    </NodeViewWrapper>
  )
}

export default Footnote
