import cn from 'classnames'

import { Upload2Icon } from '@components/Icons'
import { UploadStickerFieldProps } from './interface'

export function UploadStickerField({
  src,
  inputRef,
  isError,
  onChange,
  showUploadDialog,
}: UploadStickerFieldProps) {
  return (
    <div className='relative cursor-pointer'>
      {src && (
        <div
          onClick={showUploadDialog}
          className='w-[159px] aspect-square rounded-[8px]'
        >
          <img src={src} className='w-full h-full rounded-[8px]' alt='cover' />
        </div>
      )}

      {!src && (
        <div
          className={cn(
            'bg-blue-2 w-[159px] h-[159px] rounded-[8px] flex flex-col items-center justify-center',
            {
              'border border-red': isError,
            }
          )}
          onClick={showUploadDialog}
        >
          <Upload2Icon className='text-secondary-100 w-[24px] h-[24px]' />
          <div className='text-secondary-100 font-medium font-mitr text-[14px] underline'>
            เพิ่มสติ๊กเกอร์
          </div>
          <div className='text-secondary-100 text-[14px] font-light font-sarabun pt-[10px]'>
            200 X 200 px
          </div>
        </div>
      )}

      <input
        ref={inputRef}
        className='hidden'
        type='file'
        accept='image/*'
        onChange={onChange}
      />
    </div>
  )
}
