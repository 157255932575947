import { withPermissionGuard } from '@libs/permission'
import { EditPromotionCodePage } from './EditPromotionCodePage'
import { withEditPromotionCodePage } from './withEditPromotionCodePage'

const ConnectedEditPromotionCodePage = withPermissionGuard(
  withEditPromotionCodePage(EditPromotionCodePage),
  'promotionSetting',
  'create'
)

export { ConnectedEditPromotionCodePage as EditPromotionCodePage }
