import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider } from 'react-query'
import { ModalProvider } from '@hooks/contexts/ModalContext/ModalContext'
import { AuthenticateProvider } from '@hooks/useAuthentication'
import { AppClientProvider } from '@hooks/useClient'
import { client, queryClient } from '@client/init'
import '@styles/toastify.css'
import '@styles/tiptap.css'
import App from './App'

function Root() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AppClientProvider client={client}>
            <AuthenticateProvider>
              <ModalProvider>
                <ToastContainer
                  autoClose={5000}
                  hideProgressBar
                  closeOnClick
                  rtl={false}
                  position='top-center'
                  pauseOnHover
                  theme='colored'
                />
                <App />
              </ModalProvider>
            </AuthenticateProvider>
          </AppClientProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default Root
