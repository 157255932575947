import { Expose } from 'class-transformer'

export class PenNameIncomeType {
  @Expose() penNameId: number

  @Expose({ name: 'penNameFirstPenName' }) firstPenName: string

  @Expose({ name: 'penNameTotalIncome' }) PenNameIncome: number

  @Expose() writerId: number
}
