import { client } from '@client/init'
import { PieChart } from '@components/chart/PieChart'
import { BookCategoriesType } from '@models/dashboard/WriterOverViewType'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export function WritingCetegoryChart({ data }: { data: BookCategoriesType[] }) {
  const { data: categories = [], isLoading } = useQuery('categories', () =>
    client?.categoryClient.allCategories()
  )
  const colorMaster = useMemo(() => {
    const temp = [
      '#4fdec2',
      '#fcf6b2',
      '#e94f37',
      '#54b554',
      '#fa940c',
      '#cf3c7a',
      '#2d2d2d',
      '#b7b4b6',
      '#7465c0',
      '#f1103a',
      '#e58f1e',
      '#257165',
      '#ba59fa',
      '#dfdbb4',
      '#be7c3f',
      '#507bb9',
      '#b0ad68',
      '#4df3f3',
      '#a24789',
      '#58c4a5',
      '#e5d11b',
      '#ffa356',
      '#3ea182',
      '#757575',
      '#ed3847',
      '#ffc18e',
      '#00ccca',
      '#b9d076',
      '#fe775c',
      '#f5f61f',
    ]
    return data
      .map(item =>
        categories.findIndex(categorie => categorie.id === item.category)
      )
      .map(item => temp[item])
  }, [categories, data])

  return (
    <div className='w-full'>
      <PieChart
        title='หมวดหมู่งานเขียน'
        data={data}
        legendStyle='left'
        style={{ width: '100%' }}
        colorMaster={colorMaster}
      />
    </div>
  )
}
