import React from 'react'
import cn from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'

import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Toggle } from '@components/Toggle'
import { ArrowLeftIcon, DragIcon, BinIcon, PenIcon } from '@components/Icons'
import StatusButton from '@components/StatusButton'
import { DateTimeInput } from '@components/DateTimeInput'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { DisplayTypeEnum } from '@interfaces/DisplayTypeEnum'
import { HighlightBannerPosterPageProps } from './interface'

export function HighlightBannerPosterPage({
  id,
  data,
  selectedIds,
  publishedType,
  navigate,
  handleDragItem,
  handleSelectItemAll,
  handleSelectItem,
  handleEnable,
  handleDeleteBanner,
  handleDeleteBannerList,
  handlePublishBanner,
  handleUnPublishBanner,
  startDateChange,
  endDateChange,
  publishChange,
  displayChangeActive,
}: HighlightBannerPosterPageProps) {
  const permission = usePermissionAction('homePageManagement')
  const { position } = useParams()
  return (
    <Container>
      <section className='flex items-start justify-between my-5'>
        <div className='flex items-center gap-x-[20px] mb-[28px]'>
          <button
            className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
            type='button'
            onClick={() =>
              navigate({
                pathname: `/home-page/highlight-banner/${position}`,
              })
            }
          >
            <ArrowLeftIcon width='24' height='24' />
          </button>

          <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
            {data?.bannerName}
          </div>
        </div>

        <Button
          type='button'
          className={cn('text-[14px] text-white bg-secondary', {
            hidden: !permission.create,
          })}
          onClick={() =>
            navigate({
              pathname: `/home-page/highlight-banner/${position}/poster/${id}/create`,
            })
          }
        >
          + เพิ่มแบนเนอร์
        </Button>
      </section>

      <hr
        className={cn('my-5', {
          hidden: !permission.edit,
        })}
      />

      <section
        className={cn(
          'flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap',
          {
            hidden: !permission.edit,
          }
        )}
      >
        <div className='text-[12px]'>เผยแพร่ :</div>
        <Checkbox
          label='ทันที'
          type='radio'
          value={PublishedEnum.IMMEDIATELY}
          checked={publishedType === PublishedEnum.IMMEDIATELY}
          onChange={() => publishChange(PublishedEnum.IMMEDIATELY)}
        />
        <Checkbox
          label='ตั้งเวลาเผยแพร่'
          type='radio'
          value={PublishedEnum.CUSTOM}
          checked={publishedType === PublishedEnum.CUSTOM}
          onChange={() => publishChange(PublishedEnum.CUSTOM)}
        />

        <DateTimeInput
          className='max-w-[200px]'
          placeholder='เลือกวัน/เวลา'
          selected={
            data?.publishedFrom ? new Date(data.publishedFrom) : new Date()
          }
          disabled={publishedType === PublishedEnum.IMMEDIATELY}
          onChange={startDateChange}
          showTimeSelect
        />

        <DateTimeInput
          className='max-w-[200px]'
          placeholder='เลือกวัน/เวลา'
          selected={data?.publishedTo ? new Date(data.publishedTo) : new Date()}
          onChange={endDateChange}
          showTimeSelect
        />
      </section>

      <hr className='my-5' />

      <section className='flex items-center justify-between my-5 h-[40px]'>
        <div className='flex gap-x-[10px] text-secondary'>
          <Checkbox
            onChange={handleSelectItemAll}
            className={cn({
              hidden: !permission.edit && !permission.delete,
            })}
            checked={
              selectedIds.length === data?.posterBanner.length &&
              data?.posterBanner.length !== 0
            }
          />
          <span className='font-light'>ทั้งหมด</span>
          <span className='font-bold text-blue-3'>
            ({' '}
            {(data?.posterBanner &&
              data.posterBanner.length.toLocaleString()) ||
              0}{' '}
            รายการ )
          </span>
        </div>

        {!!selectedIds.length && (
          <div className='flex gap-x-[10px]'>
            {permission.edit && (
              <>
                <StatusButton
                  status='publish'
                  type='button'
                  onClick={handlePublishBanner}
                />
                <StatusButton
                  status='unpublish'
                  type='button'
                  onClick={handleUnPublishBanner}
                />
              </>
            )}
            {permission.delete && (
              <StatusButton
                status='delete'
                type='button'
                onClick={handleDeleteBannerList}
              />
            )}
          </div>
        )}
      </section>

      <hr />

      {!data?.posterBanner.length && permission.create && (
        <div className='w-full h-[200px] my-5 flex items-center justify-center border-dashed border border-[#cecfd7] rounded-lg'>
          <Button
            type='button'
            className='text-[14px] text-white bg-secondary'
            onClick={() =>
              navigate({
                pathname: `/home-page/highlight-banner/${position}/poster/${id}/create`,
              })
            }
          >
            + เพิ่มแบนเนอร์
          </Button>
        </div>
      )}

      <DragDropContext onDragEnd={handleDragItem}>
        <Droppable droppableId='list' isDropDisabled={!permission.edit}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data?.posterBanner.map((item, index) => (
                <Draggable
                  draggableId={`${item?.id.toString()}`}
                  index={index}
                  key={item?.id}
                >
                  {providedDragable => (
                    <div
                      className='flex gap-5 my-5'
                      ref={providedDragable.innerRef}
                      {...providedDragable.draggableProps}
                      {...providedDragable.dragHandleProps}
                    >
                      <div className='flex items-center justify-between gap-x-[30px] text-black-480'>
                        <Checkbox
                          className={cn({
                            hidden: !permission.edit && !permission.delete,
                          })}
                          checked={selectedIds.includes(item?.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.stopPropagation()
                            handleSelectItem(item?.id)
                          }}
                        />
                        <span className='font-mitr text-secondary-100 font-medium text-2xl'>
                          {item?.runningNo.toLocaleString()}
                        </span>
                        <DragIcon
                          width='10'
                          className='cursor-grab text-secondary'
                        />
                      </div>

                      <div className='w-full border-[0.5px] border-gray rounded-lg p-[30px] shadow-51'>
                        <div className='flex flex-row w-full h-full gap-x-[30px]'>
                          <div
                            className={cn('w-[273px] h-[117px] aspect-[21/9]', {
                              'opacity-50': !item?.isActive,
                            })}
                          >
                            <img
                              className='h-full w-full object-cover rounded-lg'
                              src={item?.imgPath}
                              alt='banner'
                            />
                          </div>

                          <div className='flex-1 flex flex-col justify-between'>
                            <div className='flex gap-x-5'>
                              <div className='flex flex-col items-start justify-between gap-y-5 basis-3/4'>
                                <div>
                                  <p className='text-xs font-light text-secondary-100'>
                                    Description
                                  </p>
                                  <p className='text-sm font-light text-secondary line-clamp-1'>
                                    {item?.description}
                                  </p>
                                </div>
                                <div>
                                  <p className='text-xs font-light text-secondary-100'>
                                    Link
                                  </p>
                                  <a
                                    href='/'
                                    className='text-sm font-light text-secondary line-clamp-1 hover:text-blue'
                                  >
                                    {item.rawLink}
                                  </a>
                                </div>
                                <div>
                                  <div className='flex items-center gap-x-[30px]'>
                                    <p className='text-xs font-light text-secondary-100'>
                                      Display
                                    </p>
                                    <Checkbox
                                      className='cursor-grab'
                                      name='isOnWeb'
                                      label='Web'
                                      defaultChecked={item.isOnWeb}
                                      disabled={!permission.edit}
                                      onChange={() =>
                                        displayChangeActive(
                                          item,
                                          DisplayTypeEnum.WEB
                                        )
                                      }
                                    />
                                    <Checkbox
                                      className='cursor-grab'
                                      name='isOnIos'
                                      label='iOS'
                                      defaultChecked={item.isOnIos}
                                      disabled={!permission.edit}
                                      onChange={() =>
                                        displayChangeActive(
                                          item,
                                          DisplayTypeEnum.IOS
                                        )
                                      }
                                    />
                                    <Checkbox
                                      className='cursor-grab'
                                      name='isOnAndroid'
                                      label='Android'
                                      defaultChecked={item.isOnAndroid}
                                      disabled={!permission.edit}
                                      onChange={() =>
                                        displayChangeActive(
                                          item,
                                          DisplayTypeEnum.ANDROID
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='flex flex-col items-end justify-between basis-1/4'>
                                <Toggle
                                  disabled={!permission.edit}
                                  checked={item.isActive}
                                  onChange={() => handleEnable(item)}
                                />

                                <div className='flex items-center gap-x-[10px]'>
                                  <button
                                    type='button'
                                    className={cn(
                                      'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                      {
                                        hidden: !permission.edit,
                                      }
                                    )}
                                    onClick={() =>
                                      navigate({
                                        pathname: `/home-page/highlight-banner/${position}/poster/${id}/edit/${item?.id}`,
                                      })
                                    }
                                  >
                                    <PenIcon
                                      width='20'
                                      height='20'
                                      className='text-secondary-100'
                                    />
                                  </button>
                                  <button
                                    type='button'
                                    className={cn(
                                      'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                      {
                                        hidden: !permission.delete,
                                      }
                                    )}
                                    onClick={() => handleDeleteBanner(item?.id)}
                                  >
                                    <BinIcon
                                      width='20'
                                      height='20'
                                      className='text-secondary-100'
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}
