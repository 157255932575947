import React from 'react'
import { useField } from 'formik'

import { SlugInputFieldProps, WithSlugInputFieldProps } from './interface'

export function withSlugInputField(Component: React.FC<SlugInputFieldProps>) {
  function WithSlugInputField({ name, ...props }: WithSlugInputFieldProps) {
    const [field, { error, touched }, { setTouched }] = useField(name)

    const componentProps = {
      error: touched ? error : undefined,
      ...props,
      ...field,
      setTouched,
    }

    return <Component {...componentProps} />
  }

  return WithSlugInputField
}
