import { gql } from 'graphql-request'

export const CHARACTER_DONATION_BY_WRITER = gql`
  query CharacterDonationByWriter(
    $dateRange: DatePicker
    $orderBy: CharacterDonationByWriterSort
    $orderDirection: OrderDirection
    $limitPerPage: Int
    $page: Int
  ) {
    characterDonationByWriter(
      dateRange: $dateRange
      orderBy: $orderBy
      orderDirection: $orderDirection
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        userId
        writerId
        username
        totalBook
        totalDonation
        profilePageSlug
      }
      total
      page
    }
  }
`
