import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { EbookDashboardByUserResponse } from '@models/dashboard/EbookDashboardByUserResponse'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { ByUserProps, SearchType } from './interface'
import { TabActiveEnum } from '../../interface'
import { ByUserFormType } from '../ByUserForm/interface'

const PER_PAGE = 20

export function withByUser(Component: React.FC<ByUserProps>) {
  function WithByUser() {
    const queryClient = useQueryClient()
    const alert = useAlert()
    const client = useClient()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const userId = Number(searchParams.get('userId')) || undefined
    const [queryParam, setQueryParam] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      userId,
      key: 'downloadedCount',
      order: SortingType.DESC,
    })
    const { fetchNextPage, data, isFetchingNextPage, isLoading, hasNextPage } =
      useInfiniteQuery(
        ['ebook-dashboard-by-user', queryParam],
        async ({ pageParam = 1 }) => {
          const res = await client!.dashboardClient.ebookByUser({
            page: pageParam,
            limitPerPage: PER_PAGE,
            startDate: queryParam.startDate,
            endDate: queryParam.endDate,
            userId: queryParam.userId!,
            key: queryParam.key,
            order: queryParam.order,
          })

          return res
        },
        {
          getNextPageParam: (lastPage: EbookDashboardByUserResponse) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          enabled: queryParam.userId !== undefined,
        }
      )

    function newDateAt(
      startDate: Date | undefined,
      endDate: Date | undefined,
      timeRange: TimeRangeEnum | undefined
    ) {
      if (timeRange) {
        let newStartAt
        let newEndAt

        if (timeRange === TimeRangeEnum.DAY) {
          newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
          newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
        } else if (timeRange === TimeRangeEnum.MONTH) {
          newStartAt = DateTime.now()
            .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
            .startOf('day')
          newEndAt = DateTime.now().endOf('day')
        } else {
          newStartAt = DateTime.now()
            .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
            .startOf('day')
          newEndAt = DateTime.now().endOf('day')
        }

        return {
          newStartAt,
          newEndAt,
        }
      }

      return {
        newStartAt: DateTime.fromJSDate(startDate!).startOf('day'),
        newEndAt: DateTime.fromJSDate(endDate!).endOf('day'),
      }
    }

    async function handleSubmit(value: ByUserFormType) {
      const { newStartAt, newEndAt } = newDateAt(
        value.startDate,
        value.endDate,
        value.timeRange
      )

      setQueryParam(prev => ({
        ...prev,
        startDate: newStartAt?.toUTC(),
        endDate: newEndAt?.toUTC(),
        userId: value.userId,
      }))
      searchParams.set('userId', String(value.userId))
      navigate(
        {
          search: searchParams.toString(),
        },
        {
          replace: true,
        }
      )
    }

    function onClickReset() {
      setQueryParam({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        userId: undefined,
        key: 'downloadedCount',
        order: SortingType.DESC,
      })
      navigate(
        {
          search: `?tab=${TabActiveEnum.BY_USER}`,
        },
        {
          replace: true,
        }
      )
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    function handleSort({ key, order }: SortByType) {
      setQueryParam(prev => ({ ...prev, key, order }))
    }

    async function exportFile() {
      try {
        if (queryParam.userId) {
          await client?.dashboardClient.exportEbookByUserDashboard({
            startDate: queryParam.startDate,
            endDate: queryParam.endDate,
            userId: queryParam.userId,
          })
          await queryClient.refetchQueries('export-jobs')
        }
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      data: data?.pages.flatMap(row => row.data) || [],
      queryParam,
      isLoading: isFetchingNextPage || isLoading,
      onClickReset,
      handleSubmit,
      handleWaypointEnter,
      handleSort,
      exportFile,
    }

    return <Component {...componentProps} />
  }

  return WithByUser
}
