import { Box } from '@components/Box'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import SaleBookTableRow from '@features/sales/components/SaleBookTableRow'
import { Table } from '@features/sales/components/Table'
import { Typography } from '@features/sales/components/Typography'
import { addComma, showCommaFromString } from '@libs/utils'
import { ProductType } from '@models/salse/ProductType'
import { PaymentReportDetailPageProps } from './interface'

const months = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

const productName = {
  [ProductType.NOVEL_CHAPTER]: 'รายได้จากนิยายรายตอน',
  [ProductType.MANGA_CHAPTER]: 'รายได้จากการ์ตูนรายตอน',
  // :'รายได้จากอีบุ๊กนิยาย',
  // :'รายได้จากอีบุ๊กการ์ตูน',
  [ProductType.DONATION]: 'รายได้จากการสนับสนุน',
}

export function PaymentReportDetailPage({
  data,
  userId,
  amount,
  year,
  withholdingTax,
  avatarImg,
  fullName,
  username,
  email,
  transactionId,
  monthIndex,
  productType,
  startDate,
  endDate,
  isFetchingNextPage,
  handleFetchNextPage,
}: PaymentReportDetailPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>รายละเอียดการจ่ายเงิน</Container.Title>
      <div className='flex gap-x-[20px] pb-[30px] mb-[30px] border-b border-b-gray'>
        <div className='w-[38px] h-[38px] rounded-full overflow-hidden shrink-0'>
          <img className='w-full h-full' src={avatarImg} alt='profile_image' />
        </div>
        <div className='flex gap-x-[40px]'>
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='User ID'
            text={<div className='text-[14px] font-light'>{userId}</div>}
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='ชื่อที่ลงทะเบียนไว้'
            text={<div className='text-[14px] font-light'>{fullName}</div>}
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='ชื่อผู้ใช้'
            text={<div className='text-[14px] font-light'>{username}</div>}
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='อีเมล'
            text={<div className='text-[14px] font-light'>{email}</div>}
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='Transaction ID'
            text={<div className='text-[14px] font-light'>{transactionId}</div>}
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='ยอดเงิน'
            text={
              <div className='text-[14px] text-primary font-light'>
                {addComma(amount)}
              </div>
            }
          />
          <Typography
            className='text-[12px] text-secondary font-bold'
            label='หักภาษี ณ ที่จ่าย'
            text={
              <div className='text-[14px] font-light'>
                {addComma(amount - withholdingTax)}
              </div>
            }
          />
        </div>
      </div>
      <Box>
        <Box.Title>
          {productName[productType]}
          <div className='font-light text-[14px] text-secondary-100 mt-[10px]'>
            เดือน {months[monthIndex]} {year}
          </div>
        </Box.Title>
        <hr className='text-gray mt-[30px] mb-[20px]' />
        <Table className='w-full max-h-[500px]' onScroll={handleFetchNextPage}>
          <Table.Row className='sticky top-0 z-10'>
            <Table.Header className='border-b border-b-gray-3 bg-white py-[10px] flex items-center'>
              ชื่อเรื่อง
            </Table.Header>
            <Table.Header className='border-b border-b-gray-3 bg-white text-right py-[10px]'>
              ราคาขาย
              <br />
              (บาท)
            </Table.Header>
            <Table.Header className='border-b border-b-gray-3 bg-white text-right py-[10px]'>
              จำนวนซื้อ
              <br />
              (ครั้ง)
            </Table.Header>
            <Table.Header className='border-b border-b-gray-3 bg-white text-right py-[10px]'>
              ยอดขายรวม
              <br />
              (บาท)
            </Table.Header>
            <Table.Header className='border-b border-b-gray-3 bg-white text-right py-[10px]'>
              รายได้ของนักเขียน
              <br />
              (บาท)
            </Table.Header>
            <Table.Header className='border-b border-b-gray-3 bg-white' />
          </Table.Row>

          {data?.pages &&
            data.pages
              .map(row => row.salesDetail)
              .flatMap(row => row.data)
              .map(row => (
                <SaleBookTableRow
                  key={row.id}
                  data={row}
                  startDate={startDate}
                  endDate={endDate}
                  productType={productType}
                  userId={userId}
                />
              ))}
          {isFetchingNextPage ? (
            <div className='flex justify-center'>
              <Loading />
            </div>
          ) : null}

          <Table.Row className='sticky bottom-0'>
            <Table.Footer className='pl-[20px] py-[16px] text-left'>
              รายได้รวม
            </Table.Footer>
            <Table.Footer />
            <Table.Footer className='py-[16px] text-right'>
              {data?.pages &&
                data.pages.length > 0 &&
                data.pages[
                  data.pages.length - 1
                ].purchasedCount.toLocaleString()}
            </Table.Footer>
            <Table.Footer className='py-[16px] text-right'>
              {showCommaFromString(
                data?.pages && data.pages.length > 0
                  ? data.pages[data.pages.length - 1].sales
                  : undefined
              )}
            </Table.Footer>
            <Table.Footer className='py-[16px] text-right'>
              {showCommaFromString(
                data?.pages && data.pages.length > 0
                  ? data.pages[data.pages.length - 1].writerIncome
                  : undefined
              )}
            </Table.Footer>
            <Table.Footer />
          </Table.Row>
        </Table>
      </Box>
    </Container>
  )
}
