import { withPermissionGuard } from '@libs/permission'
import { CategoryManagePage } from './CategoryManagePage'
import { withCategoryManagePage } from './withCategoryManagepage'

const ConnectedCategoryManagePage = withPermissionGuard(
  withCategoryManagePage(CategoryManagePage),
  'chapterBook',
  'view'
)

export { ConnectedCategoryManagePage as CategoryManagePage }
