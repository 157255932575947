import { Expose } from 'class-transformer'

export class SaleTermUploadType {
  @Expose() count: number

  @Expose() fileName: string

  @Expose() filePath: string

  @Expose() ids: number[]
}
