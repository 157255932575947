import { Expose, Type } from 'class-transformer'

import { Pagination } from '@models/pagination/Pagination'
import { PaginatedWriterBookSale } from './PaginatedWriterBookSale'
import { WriterBookSaleType } from './WriterBookSaleType'

export class WriterSalesType {
  @Expose()
  writerId: number

  @Expose()
  purchasedCount: number

  @Expose()
  sales: string

  @Expose()
  writerIncome: string

  @Expose()
  @Type(() => PaginatedWriterBookSale)
  salesDetail: Pagination<WriterBookSaleType>
}
