import { gql } from 'graphql-request'

export const EBOOK_OVERVIEW = gql`
  query EbookDashboardOverview($endDate: DateTime, $startDate: DateTime) {
    ebookDashboardOverview(endDate: $endDate, startDate: $startDate) {
      alreadyPurchasedCount
      downloadedCount
      freeAlreadyPurchasedCount
      freeDownloadedCount
      mangaEbookCount
      mangaEbookPublishedCount
      mangaEbookPurchasedCount
      mangaEbookWriterIncome
      novelEbookCount
      novelEbookPublishedCount
      novelEbookPurchasedCount
      novelEbookWriterIncome
    }
  }
`
