import React, { useState } from 'react'
import { QueryKey, useQuery, useQueryClient } from 'react-query'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import {
  PromotionCodeTransactionProps,
  SearchParamsType,
  WithPromotionCodeTransactionProps,
} from './interface'

export function withPromotionCodeTransaction(
  Component: React.FC<PromotionCodeTransactionProps>
) {
  function Hoc({
    couponType,
    id,
    ...props
  }: WithPromotionCodeTransactionProps) {
    const queryClient = useQueryClient()
    const alert = useAlert()
    const [activeTab, setActiveTab] = useState(
      couponType === CodeTypeEnum.UNIVERSAL ? 'used' : 'all'
    )

    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      searchText: '',
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })

    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    function handleUpdateActiveTab(value: string) {
      setActiveTab(value)
      pageChange(1)
    }

    function handleSubmitSearch(searchText: string) {
      setSearchParams(prev => ({ ...prev, searchText }))
      pageChange(1)
    }

    function handleResetSearch() {
      setSearchParams(prev => ({ ...prev, searchText: '' }))
      pageChange(1)
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    async function handleExport() {
      try {
        await client?.promotionCodeClient.exportPromotionCode({
          promotionCodeId: id,
          searchText: searchParams.searchText,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const queryKey: QueryKey = ['promotion', 'code', id, page]

    const { data: promotionLogCount, isLoading } = useQuery(
      [...queryKey, 'log-count', searchParams.searchText],
      () =>
        client?.promotionCodeClient.promotionCodeLogCount(
          Number(id),
          searchParams.searchText
        ),
      {
        enabled: !!id && !!Number(id),
      }
    )
    const { data: promotionCodeLogs = [], isLoading: allLoading } = useQuery(
      [...queryKey, 'all', searchParams.searchText],
      () =>
        client?.promotionCodeClient
          .promotionCodeLogs(Number(id), page, searchParams.searchText)
          .then(res => {
            handleTotalChange(res.total)
            return res.data
          }),
      {
        enabled: !!id && !!Number(id) && activeTab === 'all',
      }
    )
    const { data: promotionCodeUsedLogs = [], isLoading: usedLoading } =
      useQuery(
        [...queryKey, 'used', searchParams],
        () =>
          client?.promotionCodeClient
            .promotionCodeUsedLogs(Number(id), page, searchParams)
            .then(res => {
              handleTotalChange(res.total)
              return res.data
            }),
        {
          enabled: !!id && !!Number(id) && activeTab === 'used',
        }
      )
    const {
      data: promotionCodeRemainingLogs = [],
      isLoading: remainingLoading,
    } = useQuery(
      [...queryKey, 'log-remaining', searchParams.searchText],
      () =>
        client?.promotionCodeClient
          .promotionCodeRemainingLogs(Number(id), page, searchParams.searchText)
          .then(res => {
            handleTotalChange(res.total)
            return res.data
          }),
      {
        enabled: !!id && !!Number(id) && activeTab === 'remaining',
      }
    )

    const newProps = {
      page,
      perpage,
      total,
      isLoading: isLoading || allLoading || usedLoading || remainingLoading,
      data:
        activeTab === 'all'
          ? promotionCodeLogs
          : activeTab === 'used'
          ? promotionCodeUsedLogs
          : promotionCodeRemainingLogs,
      handleSubmitSearch,
      handleResetSearch,
      handleUpdateActiveTab,
      activeTab,
      pageChange,
      handleSort,
      handleExport,
      couponType,
      promotionLogCount,
      ...props,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
