import { withPermissionGuard } from '@libs/permission'
import { AddAdminPage } from './AddAdminPage'
import { withAddAdminPage } from './withAddAdminPage'

const ConnectedAddAdminPage = withPermissionGuard(
  withAddAdminPage(AddAdminPage),
  'admin',
  'create'
)

export { ConnectedAddAdminPage as AddAdminPage }
