import { Expose } from 'class-transformer'

export class ActiveUserHistoryDataType {
  @Expose() value: number

  @Expose() active1DayUsers: number

  @Expose() active7DayUsers: number

  @Expose() active28DayUsers: number
}
