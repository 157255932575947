import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { UserMissionType } from '@models/user-mission/UserMissionType'
import { UserMissionEnum } from '@interfaces/user-mission/UserMissionEnum'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { UserMissionPageProps } from './interface'

export function withUserMissionPage(Component: React.FC<UserMissionPageProps>) {
  function WithUserMissionPage() {
    const client = useClient()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const confirmModal = useModal({ modal: 'confirm' })
    const type =
      (searchParams.get('type') as UserMissionEnum) || UserMissionEnum.READER
    const { data = [] } = useQuery(['user-missions', 'type', type], () =>
      client?.userMissionClient.getUserMissions(type)
    )

    const { mutate: updateEnable } = useMutation(
      (form: { id: number; active: boolean }) =>
        client!.userMissionClient.updateEnableUserMission(form),
      {
        onMutate: variables => {
          queryClient.setQueryData<UserMissionType[] | undefined>(
            ['user-missions', 'type', type],
            oldData => {
              if (oldData) {
                const tmp = cloneDeep(oldData)
                const index = tmp.findIndex(row => row.id === variables.id)

                if (index !== -1) {
                  tmp[index] = {
                    ...tmp[index],
                    active: variables.active,
                  }
                }

                return tmp
              }

              return oldData
            }
          )
        },
        onError: (error, variables) => {
          queryClient.setQueryData<UserMissionType[] | undefined>(
            ['user-missions', 'type', type],
            oldData => {
              if (oldData) {
                const tmp = cloneDeep(oldData)
                const index = tmp.findIndex(row => row.id === variables.id)

                if (index !== -1) {
                  tmp[index] = { ...tmp[index], active: !variables.active }
                }

                return tmp
              }

              return oldData
            }
          )

          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleTabChange(value: UserMissionEnum) {
      if (type !== value) {
        navigate(
          {
            pathname: '/user-mission',
            search: `?type=${value}`,
          },
          { replace: true }
        )
      }
    }

    function handleToggle(id: number) {
      const res = data.find(row => row.id === id)

      if (res) {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                {res.active
                  ? `ยืนยันการปิดเผยแพร่ ${res.title} หรือไม่?`
                  : `ยืนยันการเปิดเผยแพร่ ${res.title} หรือไม่?`}
              </p>
            </div>
          ),
          onConfirm: async () => {
            await updateEnable({ id, active: !res.active })
            confirmModal.hide()
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      }
    }

    const componentProps = {
      data,
      type,
      handleTabChange,
      handleToggle,
    }

    return <Component {...componentProps} />
  }

  return WithUserMissionPage
}
