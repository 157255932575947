import { ColumnType } from '@components/Table/interface'
import { SalesByUserDetailTableType } from '@models/salesByUser/SalesByUserDetailTableResponse'
import { DateTime } from 'luxon'

export const columns: ColumnType<SalesByUserDetailTableType>[] = [
  {
    column: 'Date & Time',
    accessor: 'date',
    cell: ({ value }) => (
      <p>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
          locale: 'th-Th',
        })}
      </p>
    ),
    isSortable: true,
    cellClassName: 'w-[120px] py-[10px]',
  },
  {
    column: 'Transaction ID',
    accessor: 'transactionId',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    cellClassName: 'w-[100px]',
  },
  {
    column: 'ขาย',
    accessor: 'sales',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    cellClassName: 'w-[70px]',
  },
  {
    column: 'สนับสนุน',
    accessor: 'donation',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    cellClassName: 'w-[70px]',
  },
  {
    column: <div className='text-right w-full pr-[10px]'>จาก</div>,
    accessor: 'description',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: false,
    cellClassName: 'w-[150px] text-right',
  },
]
