import { gql } from 'graphql-request'

export const GET_CONSENT_HISTORIES = gql`
  query WriterHistory($writerId: Int!) {
    writerHistory(writerId: $writerId) {
      approvedRequests {
        writersConsents {
          id
          createdAt
          consent {
            id
            name
            content
            version
          }
        }
      }
    }
  }
`
