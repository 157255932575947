import { ProgressBarTwoSide } from '@components/ProgressBar'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { OverAllReport } from './components/OverAllReport'
import { ReportCategoryDetail } from './components/ReportCategoryDetail'
import { ReportSummaryDetail } from './components/ReportSummaryDetail'
import { SalesReportSearchForm } from './components/SalesReportSearchForm'
import { WriterReport } from './components/WriterReport'
import { SalesReportPageProps } from './interface'

export function SalesReportPage({
  queryKey,
  data,
  categorySaleReports,
  writer,
  bookType,
  startAt,
  endAt,
  handleSearch,
  handleExport,
  resetHandler,
}: SalesReportPageProps) {
  return (
    <>
      <SalesReportSearchForm
        initialValues={{
          writer: undefined,
          categoryIds: [],
          bookType: [],
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSearch}
        handleExport={handleExport}
        resetHandler={resetHandler}
      />
      {writer ? (
        <WriterReport
          imageUrl={writer.user.avatarImgPath}
          username={writer.user.username}
          writerId={writer.id}
          bookType={bookType}
          totalPenName={writer.user.totalPenName}
          startAt={startAt}
          endAt={endAt}
        />
      ) : (
        <OverAllReport
          queryKey={queryKey}
          totalOverAllSale={data.totalOverAllSale}
          saleTarget={data.saleTarget}
        />
      )}

      <ReportSummaryDetail
        className='mb-[20px]'
        totalBook={data.totalBook}
        totalChapter={data.totalChapter}
        totalReader={data.totalUserReading}
        totalDownload={data.totalDownload}
        totalCost={data.totalSpend}
        totalProfit={data.totalProfit}
      />
      <ProgressBarTwoSide
        firstValue={data.totalSell}
        secondValue={data.totalDonate}
        firstLabel='ยอดขาย'
        secondLabel='ยอด Donate'
        progressBarClassName='bg-primary'
      />
      <ReportCategoryDetail className='mt-[30px]' data={categorySaleReports} />
    </>
  )
}
