import React, { useState, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { BookType } from '@models/book/BookType'
import { HighlightBookBannerSearchParam } from '@models/highlightBanner/HighlightBookBannerSearchParam'
import { RecommendTypeSearchEnum } from '@interfaces/RecommendTypeSearchEnum'
import { OrderedEnum } from '@interfaces/OrderedEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { ActionStatusEnum } from '@interfaces/highlightBanner/ActionStatusEnum'
import {
  HighlightBookBannerFormProps,
  WithHighlightBookBannerFormProps,
} from './interface'

const bookOption = [
  { value: BookEnum.NOVEL, label: 'นิยาย' },
  { value: BookEnum.MANGA, label: 'การ์ตูน' },
]

const typeOption = [
  // { value: RecommendTypeSearchEnum.WHOLE, text: 'ตอนเดียว' },
  // { value: RecommendTypeSearchEnum.CHAPTER, text: 'มีมากกว่า 1 ตอน' },
  { value: RecommendTypeSearchEnum.ENDED, text: 'จบแล้ว' },
  { value: RecommendTypeSearchEnum.NOT_END, text: 'ยังไม่จบ' },
]

const orderOption = [
  { value: OrderedEnum.NEW_OLD, title: 'เรียงตาม', label: 'ใหม่ - เก่า' },
  {
    value: OrderedEnum.PRICE,
    title: 'เรียงตามราคา',
    label: 'ราคามาก - น้อย',
  },
  {
    value: OrderedEnum.POPULAR,
    title: 'เรียงตามความนิยม',
    label: 'นิยมมาก - น้อย',
  },
]
const PER_PAGE = 20

export function withHighlightBannerBookForm(
  Component: React.FC<HighlightBookBannerFormProps>
) {
  function WithHighlightBannerBookForm({
    data,
    onSubmit,
  }: WithHighlightBookBannerFormProps) {
    const client = useClient()
    const [selectedBooks, setSelectedBooks] = useState<BookType[]>([])
    const [statusBooks, setStatusBooks] = useState<BookType[]>([])
    const [round, setRound] = useState(1)

    useEffect(() => {
      setSelectedBooks(data)
    }, [data])

    const [searchParams, setSearchParam] =
      useState<HighlightBookBannerSearchParam>({
        searchText: '',
        bookType: null,
        categoryIds: [] as number[],
        secondaryType: null,
        orderBy: null,
        isEbook: false,
      })

    const { data: adminSearchBooks, isLoading: isLoadingBookList } = useQuery(
      ['book-list', searchParams],
      () =>
        client!.highlightBannerClient.adminSearchBooks({
          searchText: searchParams.searchText,
          bookType: searchParams.bookType,
          categoryIds: searchParams.categoryIds,
          secondaryType: searchParams.secondaryType,
          orderBy: searchParams.orderBy,
          isEbook: searchParams.isEbook,
        }),

      {
        enabled:
          !!searchParams.bookType ||
          !!searchParams.categoryIds.length ||
          !!searchParams.orderBy ||
          !!searchParams.searchText ||
          !!searchParams.secondaryType ||
          searchParams.isEbook,
      }
    )

    const { data: categoryOptions = [] } = useQuery('category-options', () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          ...row,
          value: String(row.value),
        }))
      )
    )

    const bookList = useMemo(() => {
      return (
        adminSearchBooks?.data.filter(
          item => !selectedBooks.find(book => book.id === item.id)
        ) || []
      )
    }, [selectedBooks, adminSearchBooks])

    function searchFormSubmit(form: HighlightBookBannerSearchParam) {
      setSearchParam({
        ...form,
        categoryIds: form.categoryIds.map(id => Number(id)),
      })
    }

    function resetForm() {
      setSearchParam({
        searchText: '',
        bookType: null,
        categoryIds: [] as number[],
        secondaryType: null,
        orderBy: null,
        isEbook: false,
      })
    }

    function addItem(item: BookType) {
      if (!selectedBooks.length) {
        if (!statusBooks.some(p => p.id === item.id)) {
          setStatusBooks([
            ...statusBooks,
            { ...item, actionStatus: ActionStatusEnum.ADD },
          ])
        } else {
          setStatusBooks(statusBooks.filter(p => p.id !== item.id))
        }
      }

      if (selectedBooks.length) {
        for (let i = 0; i < selectedBooks.length; i += 1) {
          if (!statusBooks.some(p => p.id === item.id)) {
            setStatusBooks([
              ...statusBooks,
              { ...item, actionStatus: ActionStatusEnum.ADD },
            ])
          } else {
            setStatusBooks(statusBooks.filter(p => p.id !== item.id))
          }
        }
      }

      setSelectedBooks([...selectedBooks, item])
    }

    function addAllItem() {
      if (bookList.length) {
        const item = bookList.map(book => ({
          ...book,
          actionStatus: ActionStatusEnum.ADD,
        }))

        setStatusBooks(item)
      }
      setSelectedBooks([...selectedBooks, ...bookList])
    }

    function removeItem(item: BookType) {
      if (!selectedBooks.length) {
        if (!statusBooks.some(p => p.id === item.id)) {
          setStatusBooks([
            ...statusBooks,
            { ...item, actionStatus: ActionStatusEnum.REMOVE },
          ])
        } else {
          setStatusBooks(statusBooks.filter(p => p.id !== item.id))
        }
      }

      if (selectedBooks.length) {
        for (let i = 0; i < selectedBooks.length; i += 1) {
          if (!statusBooks.some(p => p.id === item.id)) {
            setStatusBooks([
              ...statusBooks,
              { ...item, actionStatus: ActionStatusEnum.REMOVE },
            ])
          } else {
            setStatusBooks(statusBooks.filter(p => p.id !== item.id))
          }
        }
      }

      setSelectedBooks(selectedBooks.filter(book => book.id !== item.id))
    }

    function removeAllItem() {
      if (selectedBooks.length) {
        const item = data.map(book => ({
          ...book,
          actionStatus: ActionStatusEnum.REMOVE,
        }))

        setStatusBooks(item)
      }

      setSelectedBooks([])
    }

    function handleFetchNextPage() {
      if (round * PER_PAGE < bookList.length) {
        setRound(prev => prev + 1)
      }
    }

    const newProps = {
      bookList: bookList.slice(0, round * PER_PAGE),
      isLoadingBookList,
      selectedBooks,
      statusBooks,
      categoryOptions,
      searchParams,
      bookOption,
      typeOption,
      orderOption,
      totalBook: adminSearchBooks?.total || 0,
      resetForm,
      searchFormSubmit,
      addItem,
      addAllItem,
      removeItem,
      removeAllItem,
      onSubmit,
      handleFetchNextPage,
    }

    return <Component {...newProps} />
  }

  return WithHighlightBannerBookForm
}
