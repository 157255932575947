import { gql } from 'graphql-request'

export const UPDATE_PROMOTION_REQUEST = gql`
  mutation UpdatePromotionRequest(
    $updatePromotionRequestInput: UpdatePromotionRequestInput!
  ) {
    updatePromotionRequest(
      updatePromotionRequestInput: $updatePromotionRequestInput
    ) {
      id
    }
  }
`
