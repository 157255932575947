import { gql } from 'graphql-request'

export const GET_POPULAR_BOOK_SEARCH_LOGS_GRAPH = gql`
  query PopularBookSearchLogsGraph(
    $dateRange: DatePicker
    $limitPerPage: Int
    $page: Int
    $orderBy: PopularBookSearchLogsOrderBy
    $searchBy: SearchBy!
    $searchText: String
  ) {
    popularBookSearchGraph(
      dateRange: $dateRange
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      searchBy: $searchBy
      searchText: $searchText
    ) {
      time
      totalSearch
    }
  }
`
