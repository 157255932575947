import { withPermissionGuard } from '@libs/permission'
import { ReportListPage } from './ReportListPage'
import { withReportListPage } from './withReportListPage'

const ConnectedReportListPage = withPermissionGuard(
  withReportListPage(ReportListPage),
  'chapterBook',
  'view'
)

export { ConnectedReportListPage as ReportListPage }
