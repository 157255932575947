import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import {
  SearchPromotioType,
  SelectedDateEnum,
} from '../PromotionSearchForm/interface'
import { PromotionSaleListProps, WithPromotionSaleListProps } from './interface'

export function withPromotionSaleList(
  Component: React.FC<PromotionSaleListProps>
) {
  function Hoc({ promotionSaleId }: WithPromotionSaleListProps) {
    const client = useClient()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useState<SearchPromotioType>({
      searchText: '',
      selectedDate: SelectedDateEnum.SEVEN_DAY,
      startDate: null,
      endDate: null,
      key: 'createdAt',
      order: SortingType.DESC,
    })
    const alert = useAlert()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    async function handleSubmit(form: SearchPromotioType) {
      setSearchParams(form)
      pageChange(1)
    }

    async function handleExport() {
      try {
        let startDate: DateTime | undefined = searchParams.startDate
          ? DateTime.fromJSDate(searchParams.startDate).startOf('day').toUTC()
          : DateTime.now().minus({ days: 6 }).startOf('day').toUTC()
        let endDate: DateTime | undefined = searchParams.endDate
          ? DateTime.fromJSDate(searchParams.endDate).endOf('day').toUTC()
          : DateTime.now().endOf('day').toUTC()

        if (searchParams.selectedDate === SelectedDateEnum.TO_DAY) {
          startDate = DateTime.now().startOf('day').toUTC()
          endDate = DateTime.now().endOf('day').toUTC()
        } else if (searchParams.selectedDate === SelectedDateEnum.SEVEN_DAY) {
          startDate = DateTime.now().minus({ days: 6 }).startOf('day').toUTC()
          endDate = DateTime.now().endOf('day').toUTC()
        } else if (
          searchParams.selectedDate === SelectedDateEnum.THROUGHOUT_THE_CAMPAIGN
        ) {
          startDate = undefined
          endDate = undefined
        }

        await client?.promotionSaleClient.exportPromotionSale({
          promotionSaleId,
          searchText: searchParams.searchText,
          startDate,
          endDate,
          key: searchParams.key,
          order: searchParams.order,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function handleReset() {
      pageChange(1)
      handleSubmit({
        searchText: '',
        selectedDate: SelectedDateEnum.SEVEN_DAY,
        startDate: null,
        endDate: null,
        key: 'createdAt',
        order: SortingType.DESC,
      })
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({
        ...prev,
        key,
        order,
      }))
    }

    const newProps = {
      searchParams,
      page,
      perpage,
      total,
      promotionSaleId,
      pageChange,
      handleTotalChange,
      handleSubmit,
      handleExport,
      handleReset,
      handleSort,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
