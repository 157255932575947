import { gql } from 'graphql-request'

export const GET_PROMOTION_CHALLENGE = gql`
  query PromotionChallenge($promotionId: Float!) {
    promotion(id: $promotionId) {
      id
      promotionChallenge {
        id
        action
        actionValue
        wordCount
        socialMediaChannels
        readCondition
        coinsCondition {
          id
        }
        bookTypes
        benefitValue
        benefitCoin {
          id
        }
        benefitLimitType
        benefitLimitValue
        budget
        budgetPerDay
        categories {
          id
        }
      }
      name
      description
      userTerm
      startPublishedAt
      endPublishedAt
      publishedType
      status
      createdAt
      lastRequest {
        id
      }
    }
  }
`
