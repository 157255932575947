/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useStory } from '@hooks/story/useStory'
import { BASE_URL } from '@configs/config'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { client, queryClient } from '@client/init'
import { getErrorMessage } from '@libs/utils'
import { BanUserImageEnum } from '@interfaces/user/BanUserImageEnum'
import { CoverProps } from './interface'

export function withCover(Component: React.FC<CoverProps>) {
  function WithCover() {
    const { data, bookId } = useStory()
    const alert = useAlert()
    const confirmModal = useModal({
      modal: 'confirm',
    })

    function banCover() {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ต้องการจะแบนรูปภาพหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              await client!.userManagementClient.createBanImageLog({
                userId: data!.penName.user.id,
                type: BanUserImageEnum.BOOK_COVER,
                imageUrl: data!.coverImgPath,
                bookId,
              })
              alert.success('ดำเนินการสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              await queryClient.invalidateQueries(['story-detail', bookId])
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    if (!data) return null

    const newProps = {
      banCover,
      id: data.id,
      shareUrl: `${BASE_URL}/story/${data.id}`,
      coverImgPath: data.coverImgPath,
      title: data.title,
      createdAt: data.createdAt,
      cover: data.cover,
      viewCount: data.viewCount,
      commentCount: data.commentCount,
      likeCount: data.likeCount,
      chapterCount: data.chapterCount || 0,
      penName: data.penName,
      tags: data.tags,
      category: data.category,
      shelfAddedCount: data.shelfAddedCount,
      isTranslated: data.isTranslated,
      isEnded: data.isEnded,
      isEpub: data.isEpub,
      isPdf: data.isPdf,
      writingType: data.writingType,
      rating: data.rating,
      status: data.status,
      bookType: data.bookType,
    }

    return <Component {...newProps} />
  }

  return WithCover
}
