import { DateTime } from 'luxon'
import cn from 'classnames'
import { SearchHistoryType } from '@models/searchManagement/SearchHistoryType'
import { GenderEnum } from '@interfaces/GenderEnum'
import { ColumnType } from '@components/Table/interface'
import { Checkbox } from '@components/Checkbox'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ActionSelect } from './ActionSelect'

export function useSearchHistoryColumns(
  selectedEmail: string[],
  dataLength: number,
  handleSelectAll: () => void,
  handleSelect: (value: string) => void,
  blockWordingHandler: (id: string) => Promise<void>,
  addWordingHandler: (id: string) => Promise<void>
) {
  const permission = usePermissionAction('searchManagement')
  const columns: ColumnType<SearchHistoryType>[] = [
    {
      column: (
        <Checkbox
          defaultChecked={
            selectedEmail.length === dataLength && selectedEmail.length > 0
          }
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            handleSelectAll()
          }}
        />
      ),
      accessor: 'id',
      cell: ({ value }) => (
        <div className='py-[15px]'>
          <Checkbox
            defaultChecked={selectedEmail.includes(value)}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              handleSelect(value)
            }}
          />
        </div>
      ),
      isSortable: false,
      cellClassName: cn('min-w-[50px] align-top w-[50px]', {
        hidden: !permission.edit,
      }),
    },
    {
      column: 'วันที่-เวลา',
      accessor: 'createdAt',
      cell: ({ value }) => (
        <div className='py-[15px]'>
          {DateTime.fromISO(value).toFormat('dd LLL yyyy-HH:mm:ss', {
            locale: 'th-Th',
          })}
        </div>
      ),
      isSortable: true,
      cellClassName: 'min-w-[200px] w-[200px] align-top pr-[10px]',
      sortKey: 'DATE_TIME',
    },
    {
      column: 'คำค้นหา',
      accessor: 'searchText',
      cell: ({ value }) => <div className='py-[15px]'>{value}</div>,
      isSortable: true,
      cellClassName: 'pr-[10px] align-top',
      sortKey: 'SEARCH_TEXT',
    },
    {
      column: 'ผู้ค้นหา',
      accessor: 'user.displayName',
      cell: ({ value }) => (
        <div className='max-w-[450px] truncate py-[15px]'>{value ?? 'N/A'}</div>
      ),
      isSortable: true,
      cellClassName: 'min-w-[270px] pr-[10px] align-top',
      sortKey: 'DISPLAY_NAME',
    },
    {
      column: 'อายุ',
      accessor: 'user.age',
      cell: ({ value }) => <div className='py-[15px]'>{value ?? 'N/A'}</div>,
      isSortable: true,
      cellClassName: 'min-w-[80px] w-[80px] pr-[10px] align-top',
      sortKey: 'AGE',
    },
    {
      column: 'เพศ',
      accessor: 'user.gender',
      cell: ({ value }) => (
        <div className='py-[15px]'>
          {value === GenderEnum.MALE
            ? 'ชาย'
            : value === GenderEnum.FEMALE
            ? 'หญิง'
            : value === GenderEnum.OTHER
            ? 'ไม่ระบุเพศ'
            : 'N/A'}
        </div>
      ),
      isSortable: true,
      cellClassName: 'min-w-[120px] w-[120px] pr-[10px] align-top',
      sortKey: 'GENDER',
    },
    {
      column: 'Action',
      accessor: 'searchText',
      cell: ({ value }) => (
        <ActionSelect
          className='py-[15px]'
          keyword={value}
          blockWordingHandler={blockWordingHandler}
          addWordingHandler={addWordingHandler}
        />
      ),
      isSortable: false,
      cellClassName: cn('max-w-[50px] w-[50px] pr-[20px] align-top', {
        hidden: !permission.edit,
      }),
    },
  ]
  return { columns }
}
