import { gql } from 'graphql-request'

export const BOOK_CHAPTER = gql`
  query BookChapter($id: Int!) {
    bookChapter(id: $id) {
      id
      chapterName
      chapterTitle
      chapterCoverImgPath
      chapterPages {
        id
        imgPath
        content
        ordered
      }
      book {
        title
        writer
      }
      status
      editedStatus
      commentable
      writerMessage
    }
  }
`
export const BOOK = gql`
  query Book($id: Int!) {
    book(id: $id) {
      id
      bookType
      title
      intro
    }
  }
`

export const BOOK_CHAPTER_PREVIEW = gql`
  query BookChapterPreview($id: Int!) {
    bookChapter(id: $id) {
      id
      chapterName
      chapterTitle
      writerMessage
      prevId
      nextId
      chapterPages {
        id
        imgPath
        content
        ordered
      }
      book {
        id
        title
        penName {
          firstPenName
          user {
            displayName
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        orientation
        bookType
      }
    }
  }
`

export const BOOK_CHAPTER_READER = gql`
  query BookChapterReader($id: Int!) {
    bookChapter(id: $id) {
      id
      chapterName
      chapterTitle
      writerMessage
      prevId
      nextId
      commentCount
      lastCommentDate
      commentable
      isPaid
      price
      status
      isUserHasBlockedComment
      chapterPages {
        id
        imgPath
        content
        ordered
      }
      book {
        id
        title
        guestCommentable
        penName {
          id
          firstPenName
          user {
            id
            displayName
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        orientation
        bookType
        screenCapturable
        status
      }
    }
  }
`

export const GET_CHAPTER_LIST = gql`
  query GetBookChapters($page: Int, $bookId: Int, $limitPerPage: Int) {
    getBookChapters(page: $page, bookId: $bookId, limitPerPage: $limitPerPage) {
      data {
        id
        runningNo
        chapterName
        chapterTitle
        chapterCoverImgPath
        viewCount
        commentCount
        price
        status
        publishedAt
        totalSales
      }
    }
  }
`
