export class CharacterDonationByWriter {
  userId: number

  writerId: number

  username: string

  totalBook: number

  totalDonation: number

  profilePageSlug: string
}

export class CharacterDonationByWriterResponse {
  data: CharacterDonationByWriter[]

  total: number

  page: number
}
