import { gql } from 'graphql-request'

export const GET_USER_COIN_LOGS = gql`
  query getUserCoinLogs(
    $limitPerPage: Int
    $page: Int
    $orderBy: UserCoinLogsOrderBy
    $userId: Float
    $startDate: DateTime
    $endDate: DateTime
  ) {
    userCoinLogs(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        id
        paidCoin
        paidCoinValue
        freeCoin
        freeCoinValue
        type
        summaryPaidCoin
        summaryPaidCoinValue
        summaryPaidCoinAvg
        summaryFreeCoin
        summaryFreeCoinValue
        summaryFreeCoinAvg
        createdAt
        transaction {
          description
          transactionId
        }
      }
      total
    }
  }
`
