import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { useTable } from '@hooks/useTable'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { SortByType } from '@components/Table/interface'
import { getErrorMessage } from '@libs/utils'
import { roleColumns } from './components/useRoleColumns'
import { RolePermissionPageProps } from './interface'

export function withRolePermissionPage(
  Component: React.FC<RolePermissionPageProps>
) {
  function WithRolePermissionPage() {
    const client = useClient()
    const navigate = useNavigate()
    const { isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const permission = usePermissionAction('admin')
    const confirmModal = useModal({ modal: 'confirm' })
    const [sort, setSort] = useState({ key: '', order: '' })

    const { data, refetch } = useQuery(
      ['roles', sort],
      () => client?.adminClient.getRoles(sort.key, sort.order),
      {
        enabled: isAuthenticated,
      }
    )

    function handleDelete(id: string) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบ Role นี้หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.adminClient.removeRole(Number(id))
            alert.success('ลบ Role สำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
            refetch()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    function handleUpdate(id: string) {
      navigate(`/admin/role/${id}`)
    }

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSort({ key, order })
    }, [])

    const { columns } = roleColumns(handleDelete, handleUpdate, permission)

    const { Table } = useTable({
      data: data || [],
      columns,
    })

    const newProps = {
      Table,
      handleSort,
    }
    return <Component {...newProps} />
  }

  return WithRolePermissionPage
}
