import { withPermissionGuard } from '@libs/permission'
import { HighlightBannerPage } from './HighlightBannerPage'
import { withHighlightBannerPage } from './withHighlightBannerPage'

const ConnectedHighlightBannerPage = withPermissionGuard(
  withHighlightBannerPage(HighlightBannerPage),
  'homePageManagement',
  'view'
)

export { ConnectedHighlightBannerPage as HighlightBannerPage }
