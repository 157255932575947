import { columns } from './components/column'
import { StorySecctionProps } from './interface'
import { DonationTemplate } from '../DonationTemplate'

export function StorySection({
  data,
  handleSort,
  handleFetchNextPage,
  isLoading,
}: StorySecctionProps) {
  return (
    <DonationTemplate
      title='Top rank donation by story'
      data={data}
      columns={columns}
      isLoading={isLoading}
      handleSort={handleSort}
      onWaypointEnter={handleFetchNextPage}
    />
  )
}
