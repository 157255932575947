import { Expose } from 'class-transformer'
import { PermissionNameType } from '@interfaces/permission/PermissionNameType'

export class PermissionType {
  @Expose() status: boolean

  @Expose() name: PermissionNameType

  @Expose() actions: string[]
}
