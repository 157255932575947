import { Expose, Transform } from 'class-transformer'

export class ReaderTopTimeSpendType {
  @Expose() userId: string

  @Expose() username: string

  @Expose({ name: 'userTimeSpend' })
  @Transform(({ value }) => (value ? Number(value) : 0))
  timeSpend: number
}
