import { Container } from '@components/Container'
import { PopupBannerForm } from '@features/home/components/PopupBannerForm'
import { HistoryPopupBanner } from '@features/home/components/HistoryPopupBanner'
import { EditPopupBannerPageProps } from './interface'

export function EditPopupBannerPage({
  initialValues,
  goBackToPupUpBannerPage,
  onSubmit,
}: EditPopupBannerPageProps) {
  return (
    <Container>
      <Container.Title onClickBackBtn={goBackToPupUpBannerPage} showBackBtn>
        Edit Pop-up Banner
      </Container.Title>
      <PopupBannerForm initialValues={initialValues} onSubmit={onSubmit} />
      <HistoryPopupBanner />
    </Container>
  )
}
