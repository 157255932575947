import React, { useMemo, UIEvent } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { SupporterResponse } from '@models/supporter/SupporterResponse'
import {
  WithViewAllSponsorsModalProps,
  ViewAllSponsorsModalProps,
} from './interface'

export function withViewAllSponsorsModal(
  Component: React.FC<ViewAllSponsorsModalProps>
) {
  function WithViewAllSponsorsModal({ bookId }: WithViewAllSponsorsModalProps) {
    const client = useClient()
    const { data, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
      ['supporters', bookId],
      ({ pageParam = 1 }) =>
        client!.supporterClient.getSupporters({
          bookId,
          page: pageParam,
          limitPerPage: 50,
        }),
      {
        getNextPageParam: (lastPage: SupporterResponse) => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage(e: UIEvent<HTMLDivElement>) {
      const scrollHeight = e.currentTarget.scrollHeight - 10
      const scrollTop = e.currentTarget.scrollTop + e.currentTarget.offsetHeight

      if (scrollHeight <= scrollTop && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const supporters = useMemo(
      () => data?.pages?.flatMap(page => page?.data) ?? [],
      [data]
    )

    const componentProps = {
      supporters,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithViewAllSponsorsModal
}
