import cn from 'classnames'
import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon } from '@components/Icons'
import { SelectStatusProps } from './interface'

export function SelectButton({
  label,
  title,
  options,
  value,
  className,
  handleSelect,
}: SelectStatusProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className={cn('relative', className)}>
      {label && (
        <div className='font-light text-[12px] text-secondary-100 mb-[5px]'>
          {label}
        </div>
      )}
      <div
        className={cn(
          'w-full min-h-[40px] border bg-white rounded-[8px] text-[14px] p-[10px] text-secondary font-light cursor-pointer',
          {
            'border-primary': isShowDropdown,
            'border-gray-3': !isShowDropdown,
          }
        )}
        onClick={toggleDropdown}
      >
        <div className='flex items-center justify-between'>
          <div>
            <span className='text-secondary-100 mr-[2px]'>{title} </span>
            <span className='font-bold text-secondary'>
              {options?.find(row => row.value === value)?.label}
            </span>
          </div>
          <ChevronDownIcon
            width='16'
            height='16'
            className={cn('text-secondary', {
              'rotate-180 text-primary': isShowDropdown,
            })}
          />
        </div>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-full z-10 mt-[13px] right-0 bg-white border border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow'
          >
            {options.map((row, index) => (
              <div
                className='flex items-center hover:bg-primary-1 hover:text-primary p-[10px] gap-x-[10px]'
                key={index}
                onClick={() => {
                  handleSelect(row.value)
                }}
              >
                {row.label || row.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
