import { Table } from '@components/Table'
import { topSpenderColumns } from './columns'
import { TopSpenderTableProps } from './interface'

export function TopSpenderTable({
  data,
  isLoading,
  title,
  height = '485px',
  sort,
  spenderType,
  handleSort,
}: TopSpenderTableProps) {
  return (
    <div className='flex flex-col gap-y-[20px] mt-[30px]'>
      <div className='font-medium font-mitr text-[24px] text-secondary'>
        {title}
      </div>

      <Table
        data={data}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
        className='h-[485px] max-h-[485px] pr-[20px]'
        columns={topSpenderColumns(spenderType)}
        onSort={handleSort}
        isLoading={isLoading}
        fixedHeader
        initialSort={{
          key: sort.key,
          order: sort.order,
        }}
      />
    </div>
  )
}
