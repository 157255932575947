import { useField } from 'formik'
import React from 'react'
import cn from 'classnames'

import {
  DateTimeInputFieldProps,
  WithDateTimeInputFieldProps,
} from './interface'

export function withDateTimeInputField(
  Component: React.FC<DateTimeInputFieldProps>
) {
  function WithDateTimeInputField({
    name,
    onChange,
    ...props
  }: WithDateTimeInputFieldProps) {
    const [field, { error }, { setValue }] = useField(name)

    function handleChange(val: Date | null) {
      if (onChange) onChange(val)
      else setValue(val)
    }

    const componentProps = {
      ...props,
      ...field,
      inputClassName: cn({ 'border-red': error, 'opacity-50': props.disabled }),
      selected: field.value,
      onChange: handleChange,
    }

    return <Component {...componentProps} />
  }

  return WithDateTimeInputField
}
