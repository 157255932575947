import { ColumnType } from '@components/Table/interface'
import { BookChapterDetailDashboardType } from '@models/story/BookChapterDetailDashboardResponse'
import { DateTime } from 'luxon'

export const columns: ColumnType<BookChapterDetailDashboardType>[] = [
  {
    column: 'ตอนที่',
    accessor: 'runningNo',
    cell: ({ value }) => (
      <p className='w-[100px] pr-[10px] line-clamp-1'>{value}</p>
    ),
    cellClassName: 'w-[50px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'ชื่อตอน',
    accessor: 'chapterName',
    cell: ({ value }) => (
      <p className='w-[150px] pr-[10px] line-clamp-1'>{value || '-'}</p>
    ),
    isSortable: false,
  },
  {
    column: 'วันที่อัปเดตล่าสุด',
    accessor: 'updatedAt',
    cell: ({ value }) => (
      <p>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy', {
          locale: 'th-Th',
        })}
      </p>
    ),
    cellClassName: 'w-[150px] max-w-[150px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'ราคา',
    accessor: 'price',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'Download',
    accessor: 'bookChapterTotalDownload',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
    sortKey: 'download',
  },
  {
    column: 'Revenue',
    accessor: 'bookChapterRevenue',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
    sortKey: 'revenue',
  },
  {
    column: 'All View',
    accessor: 'bookChapterTotalView',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
    sortKey: 'totalViewer',
  },
  {
    column: 'UniqueView',
    accessor: 'bookChapterUniqueView',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
    sortKey: 'uniqueView',
  },
  {
    column: 'คอมเมนต์',
    accessor: 'bookChapterTotalComment',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[10px]',
    isSortable: true,
    sortKey: 'totalComment',
  },
]
