import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { NewsAndPromotionFormType } from '@models/newsAndPromotion/NewsAndPromotionFormType'
import { getErrorMessage, DAY } from '@libs/utils'
import { NewsAndPromotionCreatePageProps } from './interface'

export function withNewsAndPromotionCreatePage(
  Component: React.FC<NewsAndPromotionCreatePageProps>
) {
  function WithNewsAndPromotionCreatePage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const [initialValues] = useState({
      bannerImgPath: { url: '' },
      thumbnailImgPath: { url: '' },
      name: '',
      slug: '',
      description: '',
      content: '',
      isWeb: true,
      isIos: true,
      isAndroid: true,
      startPublishedAt: new Date(),
      endPublishedAt: new Date(Date.now() + DAY * 30),
      newsType: NewsTypeEnum.NEWS,
      publishedType: PublishedEnum.IMMEDIATELY,
      verifySlug: false,
      newsLogs: [],
      isCreateNoti: false,
    })

    async function handleSubmit(form: NewsAndPromotionFormType) {
      try {
        await client?.newsClient.createNewsAndPromotion(form)
        alert.success('บันทึกข้อมูลสำเร็จ')
        navigate('/news-promotion')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    const newProps = { initialValues, handleSubmit }
    return <Component {...newProps} />
  }
  return WithNewsAndPromotionCreatePage
}
