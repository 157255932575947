import { NoData } from '@components/NoData'
import { Table } from '@components/Table'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { ByUserForm } from '../ByUserForm'
import { columns } from './column'
import { ByUserProps } from './interface'

export function ByUser({
  data,
  queryParam,
  isLoading,
  onClickReset,
  handleSubmit,
  handleWaypointEnter,
  handleSort,
  exportFile,
}: ByUserProps) {
  return (
    <div>
      <ByUserForm
        initialValues={{
          userId: queryParam.userId,
          timeRange: TimeRangeEnum.MONTH,
          startDate: undefined,
          endDate: undefined,
        }}
        disableExport={!queryParam.userId}
        onResetForm={onClickReset}
        handleSubmit={handleSubmit}
        exportFile={exportFile}
      />
      {queryParam.userId ? (
        <Table
          className='mt-[30px]'
          data={data}
          columns={columns}
          isLoading={isLoading}
          initialSort={{
            key: queryParam.key,
            order: queryParam.order,
          }}
          onSort={handleSort}
          onWaypointEnter={handleWaypointEnter}
        />
      ) : (
        <CardContainer>
          <p className='text-center'>
            ค้นหานักเขียน, สำนักพิมพ์ หรือ ID ที่ต้องการดูข้อมูล
          </p>
        </CardContainer>
      )}
    </div>
  )
}
