import { Link } from 'react-router-dom'

import { Box } from '@components/Box'
import { formatDateTime } from '@libs/utils'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { DetialSectionProps } from '../interface'
import { reportTopicText } from '../../ReportListPage/components/ReportListTable/useBookReportsColumns'

export function DetailSection({ detailData }: DetialSectionProps) {
  return (
    <Box>
      <Box.Title className='font-bold text-secondary'>รายละเอียด</Box.Title>
      <div className='flex flex-col gap-y-[20px] font-sarabun'>
        <div className='grid grid-cols-[25%_25%_1fr] gap-x-3'>
          <div className='flex flex-col'>
            <label className='text-[14px] text-secondary-100 font-light'>
              วันที่
            </label>
            <span className='ext-[16px] text-secondary'>
              {detailData?.createdAt
                ? formatDateTime(detailData.createdAt, {
                    showSecond: true,
                    separateText: '-',
                  })
                : '-'}
            </span>
          </div>
          <div className='flex flex-col'>
            <label className='text-[14px] text-secondary-100 font-light'>
              หัวข้อ
            </label>
            <span className='ext-[16px] text-secondary'>
              {detailData?.topic ? reportTopicText[detailData.topic] : ''}
            </span>
          </div>
          <div className='flex flex-row gap-x-[10px]'>
            {detailData?.book?.coverImgPath && (
              <img
                src={detailData.book.coverImgPath}
                alt='cover'
                className='w-[33px] h-[50px] object-cover rounded-[4px]'
              />
            )}
            <div className='flex flex-col justify-center'>
              {detailData?.type === ReportBookEnum.STORY ? (
                <Link to={`/preview/${detailData?.book.id}`} target='_blank'>
                  <div className='font-mitr font-medium text-[12px] text-secondary mb-[5px] max-w-[432px] truncate'>
                    {detailData?.book.title}
                  </div>
                </Link>
              ) : (
                <div className='font-mitr font-medium text-[12px] text-secondary mb-[5px] max-w-[432px] truncate'>
                  {detailData?.book.title}
                </div>
              )}

              <div className='flex flex-row gap-x-[10px]'>
                {detailData?.book.isTranslated && (
                  <span className='text-[12px] text-secondary font-light'>
                    <span className='text-secondary-100'>สำนักพิมพ์ : </span>
                    {detailData?.book.writer}
                  </span>
                )}
                <span className='text-[12px] text-secondary-100 font-light'>
                  นามปากกา :{' '}
                  <span className='text-secondary'>
                    {detailData?.book?.penName?.firstPenName || '-'}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <label className='text-[14px] text-secondary-100 font-light'>
            รายละเอียด
          </label>
          <p className='ext-[16px] text-secondary'>{detailData?.detail}</p>
        </div>
        <hr className='border-t-[1px] mt-[10px] border-gray' />
        <div className='grid grid-cols-[25%_25%_1fr] gap-x-3'>
          <div className='flex flex-col'>
            <label className='text-[14px] text-secondary-100 font-light'>
              ชื่อ
            </label>
            <span className='ext-[16px] text-secondary'>
              {detailData?.reportBy?.displayName}
            </span>
          </div>
          <div className='flex flex-col'>
            <label className='text-[14px] text-secondary-100 font-light'>
              อีเมล
            </label>
            <span className='ext-[16px] text-secondary'>
              {detailData?.reportBy?.email || '-'}
            </span>
          </div>
          <div className='flex flex-col'>
            <label className='text-[14px] text-secondary-100 font-light'>
              เบอร์โทร
            </label>
            <span className='ext-[16px] text-secondary'>
              {detailData?.reportBy?.phoneNumber || '-'}
            </span>
          </div>
        </div>
      </div>
    </Box>
  )
}
