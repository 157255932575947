import { gql } from 'graphql-request'

export const PROMOTION_CHALLENGE_LOGS = gql`
  query PromotionChallengeLogs(
    $promotionChallengeId: Int!
    $limitPerPage: Int
    $page: Int
    $searchText: String
    $orderBy: ChallengeLogOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    promotionChallengeLogs(
      promotionChallengeId: $promotionChallengeId
      limitPerPage: $limitPerPage
      page: $page
      searchText: $searchText
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        id
        userId
        username
        penNames {
          name
          sales
          chapters {
            bookName
            chapterName
            coinValue
          }
        }
        bookName
        chapterName
        coinSpent
        coinReceived
        chapters {
          name
          chapters {
            value
            count
          }
          characters {
            value
            count
          }
        }
        socialPlatforms {
          socialPlatforms {
            value
            count
          }
          name
        }
        createdAt
        expiredAt
        deletedAt
        writerId
      }
      total
    }
  }
`
