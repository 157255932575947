import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { StoryTableTemplate } from '../StoryTableTemplate.tsx'
import { columns } from './components/column'
import { StoryHotNewSectionProps } from './interface'

export function StoryHotNewSection({
  data,
  handleSort,
  bookTypeChange,
  categoryChange,
  isLoading,
}: StoryHotNewSectionProps) {
  return (
    <StoryTableTemplate
      title='เรื่องใหม่มาแรง'
      bookTypeChange={bookTypeChange}
      categoryChange={categoryChange}
    >
      <Table
        data={data}
        columns={columns}
        fixedHeader
        onSort={handleSort}
        initialSort={{ key: 'rank', order: SortingType.ASC }}
        className='h-[355px] max-h-[355px] pr-[20px]'
        isLoading={isLoading}
      />
    </StoryTableTemplate>
  )
}
