import { Container } from '@components/Container'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { Loading } from '@components/Loading'
import { Chapters } from './components/Chapters'
import { Cover } from './components/Cover'
import { StoryPageProps } from './interface'

export function StoryPage({ data, bookId, isLoading }: StoryPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>รายละเอียดงานเขียน</Container.Title>
      {isLoading && <Loading />}
      {data && !isLoading && (
        <>
          <Cover data={data} />
          <CardContainer>
            <Chapters bookId={bookId} />
          </CardContainer>
        </>
      )}
    </Container>
  )
}
