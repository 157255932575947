import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { Loading } from '@components/Loading'
import { FormDetailPromotionChallengePageProps } from './interface'

export function withFormDetailPromotionChallengePage(
  Component: React.FC<FormDetailPromotionChallengePageProps>
) {
  function Hoc() {
    const navigate = useNavigate()
    const { id } = useParams()
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryKey: QueryKey = ['promotion', 'challenge', Number(id)]

    const { data, isLoading } = useQuery(
      [...queryKey, 'form-detail'],
      () =>
        client?.promotionChallengeClient.getPromotionChallengeFormDetail(
          Number(id)
        ),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { mutateAsync: approvePromotionChallenge } = useMutation(
      ({
        promotionRequestId,
        status,
      }: {
        promotionRequestId: number
        status:
          | PromotionStatusEnum.PENDING
          | PromotionStatusEnum.APPROVED
          | PromotionStatusEnum.REJECTED
      }) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status,
        }),
      {
        onSuccess: () => {
          alert.success(`ดำเนินการสำเร็จ`)
          navigate('/promotion-setting')
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
        },
      }
    )

    function handleGoToHistoryPage() {
      navigate(`/promotion-setting/promotion-challenge/${id}/history`)
    }

    function handleApprove() {
      if (data?.lastRequest?.id && data.id) {
        approvePromotionChallenge({
          promotionRequestId: data.lastRequest.id,
          status: PromotionStatusEnum.APPROVED,
        })
      }
    }

    function handleReject() {
      if (data?.lastRequest?.id && data?.id) {
        rejectModal.show({
          requestId: data.lastRequest.id,
          promotionId: data.id,
          promotionName: data?.name || '',
          startPromotion: new Date(data.startPublishedAt!),
          endPromotion: new Date(data.endPublishedAt!),
          createdAt: new Date(data.createdAt!),
          promotionDetail: data.description,
          onSuccess: () => {
            navigate('/promotion-setting')
          },
        })
      }
    }

    const { data: activeCoinOptions = [] } = useQuery('activeCoinOptions', () =>
      client?.coinClient.getVisibleActiveCoinOptions()
    )

    const { data: challengeCoinOptions = [] } = useQuery(
      'challengeCoinOptions',
      () => client?.coinClient.getPromotionChallengeCoinOptions()
    )

    if (!data || isLoading) {
      return (
        <div className='m-auto'>
          <Loading />
        </div>
      )
    }

    const newProps = {
      initialValues: data,
      activeCoinOptions,
      challengeCoinOptions,
      handleGoToHistoryPage,
      handleApprove,
      handleReject,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
