import { gql } from 'graphql-request'

export const GET_BOOK_OPTION = gql`
  query SearchBook($searchText: String, $page: Int, $limitPerPage: Int) {
    searchBook(
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        coverImgPath
        title
      }
      page
      total
    }
  }
`
