import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { Loading } from '@components/Loading'
import { OverviewProps } from './interface'
import { DataOverview } from '../DataOverview'
import { EbookByCategory } from '../EbookByCategory'

function Overview({
  searchParams,
  data,
  isLoading,
  handleExport,
  handleReset,
  handleSearch,
}: OverviewProps) {
  return (
    <>
      <section className='flex items-center gap-x-[20px] mb-[30px]'>
        <SearchDateRange
          className='flex-1'
          onSearch={handleSearch}
          onReset={handleReset}
          onExport={handleExport}
        />
      </section>
      {isLoading ? (
        <Loading className='mx-auto mt-[50px]' />
      ) : (
        data && (
          <>
            <DataOverview
              salesFormNovel={data.novelEbookWriterIncome}
              salesFormManga={data.mangaEbookWriterIncome}
              salesCountFormNovel={data.novelEbookPurchasedCount}
              salesCountFormManga={data.mangaEbookPurchasedCount}
              totalSaleEbook={data.alreadyPurchasedCount}
              totalDownload={data.downloadedCount}
              totalNovelEbook={data.novelEbookCount}
              totalMangaEbook={data.mangaEbookCount}
              totalPublishNovelEbook={data.novelEbookPublishedCount}
              totalPublishMangaEbook={data.mangaEbookPublishedCount}
              totalFreeEbook={data.freeAlreadyPurchasedCount}
              totalFreeDownloadEbook={data.freeDownloadedCount}
            />
            <EbookByCategory queryParam={searchParams} />
          </>
        )
      )}
    </>
  )
}

export default Overview
