import { EyeIcon } from '@components/Icons'
import { Link } from 'react-router-dom'

export function ActionCell({ id }: { id: number }) {
  return (
    <Link to={`/user/user-management/${id}?tab=coin`}>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </Link>
  )
}
