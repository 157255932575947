import { BarChart } from '@features/dashboard/components/BarChart'
import { DashboardPerformanceEnum } from '@interfaces/dashboard/DashboardPerformanceEnum'
import { TopPageViewProps } from './interface'

export function TopPageView({ data, tabId }: TopPageViewProps) {
  return (
    <div className='pt-[30px]'>
      <BarChart
        title={
          tabId === DashboardPerformanceEnum.MOBILE
            ? 'Top Screen View'
            : 'Top Page View'
        }
        style={{ width: '100%', height: '350px' }}
        dataSource={data}
        disable={tabId === DashboardPerformanceEnum.MOBILE}
      />
    </div>
  )
}
