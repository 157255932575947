import { useMemo, useState } from 'react'
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import {
  ColumnType,
  SortByType,
  SortingType,
} from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { UserRemainCoinType } from '@models/coin/UserRemainCoinType'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { CoinDetailTableProps } from './interface'

const PER_PAGE = 30
export function withCoinDetailTable(Component: React.FC<CoinDetailTableProps>) {
  function WithCoinDetailTable() {
    const client = useClient()
    const queryClient = useQueryClient()
    const [sort, setSort] = useState<SortByType>({
      key: 'userId',
      order: SortingType.ASC,
    })
    const { data: activeCoins } = useQuery('active-coins', () =>
      client?.coinClient.getActiveCoins()
    )
    const alert = useAlert()

    const columns = useMemo<ColumnType<UserRemainCoinType>[]>(() => {
      const goldCoin = activeCoins?.find(row => row.isGoldCoin)
      const silverCoin = activeCoins?.find(row => row.isSilverCoin)
      const otherCoin = activeCoins?.find(row => row.isOtherCoin)

      const cols: ColumnType<UserRemainCoinType>[] = [
        {
          column: 'User ID',
          accessor: 'userId',
          cell: ({ value }) => <p>{value || '-'}</p>,
          isSortable: true,
          sortKey: 'userId',
          cellClassName: 'w-[80px] py-[7px]',
        },
        {
          column: 'Username',
          accessor: 'username',
          cell: ({ value }) => <p>@{value}</p>,
          isSortable: true,
          sortKey: 'username',
          cellClassName: 'py-[7px]',
        },
      ]

      if (goldCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{goldCoin.name}</div>,
          accessor: 'paidCoin',
          cell: ({ value }) => (
            <p className='font-bold text-gold text-right'>
              {value.toLocaleString()}
            </p>
          ),
          isSortable: true,
          sortKey: 'paidCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      if (silverCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{silverCoin.name}</div>,
          accessor: 'secondaryCoin',
          cell: ({ value }) => (
            <p className='font-bold text-secondary-100 text-right'>
              {value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          ),
          isSortable: true,
          sortKey: 'secondaryCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      if (otherCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{otherCoin.name}</div>,
          accessor: 'activityCoin',
          cell: ({ value }) => (
            <p className='font-bold text-secondary text-right'>
              {value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          ),
          isSortable: true,
          sortKey: 'activityCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      cols.push({
        column: <div className='ml-auto'>Action</div>,
        accessor: 'userId',
        cell: ({ value }) => (
          <Link
            to={{
              pathname: `/user/user-management/${value}`,
              search: `?tab=coin`,
            }}
          >
            <button
              className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
              type='button'
            >
              <EyeIcon className='text-black-970' width='18' height='18' />
            </button>
          </Link>
        ),
        isSortable: false,
        cellClassName: 'w-[100px] py-[7px] text-right px-[20px]',
      })

      return cols
    }, [activeCoins])

    const {
      data: remainCoins,
      isLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
    } = useInfiniteQuery(
      ['remain-coins', sort, activeCoins],
      ({ pageParam = 1 }) =>
        client!.coinClient.userRemainCoins({
          perpage: PER_PAGE,
          page: pageParam,
          sortValue: sort.order,
          sortKey: sort.key,
          paidCoinId: activeCoins!.find(row => row.isGoldCoin)!.id,
          secondaryCoinId: activeCoins!.find(row => row.isSilverCoin)!.id,
          activityCoinId: activeCoins!.find(row => row.isOtherCoin)?.id,
        }),
      {
        enabled: !!activeCoins?.length,
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }

    async function exportFile() {
      try {
        await client?.coinClient.exportCoinRemain({
          sort,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      remainCoins,
      columns,
      sort,
      isLoading,
      handleSort,
      handleWaypointEnter,
      exportFile,
    }

    return <Component {...newProps} />
  }

  return WithCoinDetailTable
}
