import { ReactNode } from 'react'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export interface CellProps<T> {
  value?: any
  obj: T
  i?: number
}

export type CellType<T> = (props: CellProps<T>) => ReactNode

export interface ColumnType<T> {
  column?: string | JSX.Element
  accessor?: string
  cell?: CellType<T>
  actionCell?: CellType<T>
  isAction?: boolean
  isSortable?: boolean
  cellClassName?: string
  sortKey?: string
}

export enum SortingType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortByType = {
  key: string
  order: SortingType
}

export interface WithTableProps<T> extends FunctionComponentType {
  data: T[]
  columns: ColumnType<T>[]
  headerClassName?: string
  bodyClassName?: string
  initialSort?: SortByType
  isLoading?: boolean
  fixedHeader?: boolean
  onSort?: (sort: SortByType) => void
  onWaypointEnter?: () => void
}

export interface TableProps<T> extends FunctionComponentType {
  data: T[]
  columns: ColumnType<T>[]
  sortKey: string
  sortOrder: SortingType
  headerClassName?: string
  bodyClassName?: string
  isLoading?: boolean
  fixedHeader?: boolean
  handleSort: (key: string) => void
  onWaypointEnter?: () => void
}
