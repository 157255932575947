import { Expose, Transform, Type } from 'class-transformer'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'

export class ConsentType {
  @Expose()
  @Transform(({ value }) => value || '')
  version: string

  @Expose() type: ConsentTypeEnum
}

export class UserConsentType {
  @Expose() id: number

  @Expose() userId: number

  @Expose() consentId: number

  @Expose()
  @Type(() => ConsentType)
  consent: ConsentType

  @Expose()
  createdAt: string
}
