import { Expose, Transform } from 'class-transformer'

export class SearchTermType {
  @Expose() id?: number

  @Expose() mockId?: number

  @Expose({ name: 'keyword' }) name: string

  @Expose()
  @Transform(({ value }) => value || false)
  isDelete: boolean
}
