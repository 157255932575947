import cn from 'classnames'

import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function Container({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'px-[50px] py-[30px] mx-auto min-w-[1129px] max-w-[1129px]',
        className
      )}
    >
      {children}
    </div>
  )
}

Container.Title = function CardTitle({ value }: { value: string }) {
  return <h1 className='font-medium text-2xl'>{value}</h1>
}

Container.MenuButton = function MenuButton({
  value,
  icon,
  ...props
}: {
  value: string
  icon: JSX.Element
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <button
      type='button'
      className='flex items-center gap-x-[10px] bg-secondary text-white text-sm max-w-[250px] h-10 rounded-full py-2 px-5'
      {...props}
    >
      {icon}
      {value}
    </button>
  )
}

Container.Hr = function Hr({ className }: FunctionComponentType) {
  return <div className={cn('border-b border-gray my-[20px]', className)} />
}
