import cn from 'classnames'
import { Container } from '@components/Container'
import { EditIcon } from '@components/Icons'
import { Toggle } from '@components/Toggle'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Information } from './components/Information'
import { UserMissionTable } from './components/UserMissionTable'
import { UserMissionDetailPageProps } from './interface'

export function UserMissionDetailPage({
  id,
  dataUserMission,
  dataLevelUserCount,
  handleToggle,
  navigate,
}: UserMissionDetailPageProps) {
  const permission = usePermissionAction('userMission')

  return (
    <Container>
      <Container.Title
        className='flex justify-between'
        showBackBtn
        onClickBackBtn={() => {
          navigate('/user-mission')
        }}
      >
        <span>{dataUserMission.title}</span>
        <div className='flex gap-x-[20px] items-center'>
          <button
            type='button'
            className='w-[212px] h-[40px] rounded-[20px] border border-secondary text-sm font-medium text-secondary font-mitr'
            onClick={() => navigate(`/user-mission/history/${id}`)}
          >
            ดูประวัติการแก้ไข Mission
          </button>

          <Toggle
            disabled={!permission.edit}
            checked={dataUserMission.active}
            onChange={handleToggle}
          />

          <button
            type='button'
            className={cn(
              'text-secondary-100 p-[4px] rounded-[8px] border border-blue-2',
              { hidden: !permission.edit }
            )}
            onClick={() => navigate(`/user-mission/edit/${id}`)}
          >
            <EditIcon width='20' height='20' />
          </button>
        </div>
      </Container.Title>
      <Information
        dataUserMission={dataUserMission}
        dataLevelUserCount={dataLevelUserCount}
      />
      <UserMissionTable userMissionType={dataUserMission.challengeType} />
    </Container>
  )
}
