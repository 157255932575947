import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ReportBookEnum } from '@interfaces/ReportBookType'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { ReportListPageProps } from './interface'

export function withReportListPage(Component: React.FC<ReportListPageProps>) {
  function WithReportListPage() {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(ReportBookEnum.STORY)

    function onActiveTabChange(tabId: ReportBookEnum) {
      setActiveTab(tabId)
      params.set('tab', tabId)
      if (
        tabId === ReportBookEnum.STORY &&
        params.get('subtabs') === ReportStatusEnum.BANED
      ) {
        params.set('subtabs', ReportStatusEnum.ALL)
      }

      navigate(
        {
          search: `?${params.toString()}`,
        },
        { replace: true }
      )
    }

    useEffect(() => {
      setActiveTab(
        (params.get('tab') as ReportBookEnum) || ReportBookEnum.STORY
      )
    }, [params])

    const newProps = {
      activeTab,
      onActiveTabChange,
    }
    return <Component {...newProps} />
  }

  return WithReportListPage
}
