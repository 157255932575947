import cn from 'classnames'

import { ChevronUpIcon } from '@components/Icons'
import { AccordionProps } from './interface'

export function Accordion({
  title,
  children,
  disabled,
  className,
  active,
  rotate,
  contentSpace,
  toggleAccordion,
}: AccordionProps) {
  return (
    <div className='flex flex-col bg-blue-2 rounded-[8px] border border-gray'>
      <button
        type='button'
        disabled={disabled}
        className={cn(
          className,
          'box-border text-secondary-100 appearance-none cursor-pointer focus:outline-none flex items-center justify-between rounded-[8px]',
          {
            'cursor-no-drop opacity-40': disabled,
            'h-[60px] py-[18px] px-5 mt-[1px]': !active,
            'py-[18px] px-5 mt-[1px]': active,
          }
        )}
        onClick={toggleAccordion}
      >
        <div className='flex items-center'>
          <div className='text-base font-bold text-secondary inline-block'>
            {title}
          </div>
        </div>
        <ChevronUpIcon className={cn('text-primary', rotate)} />
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: active ? `700px` : `0px` }}
        className={cn(
          'overflow-y-auto transition-max-height duration-300 ease-in-out mx-[20px] pr-[20px]',
          { 'mb-[20px]': active }
        )}
      >
        {children}
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  className: 'bg-blue-2 h-[60px] py-[18px] px-5 mt-[1px]',
}
