import React from 'react'
import { useField } from 'formik'

import { WithToggleFieldProps, ToggleFieldProps } from './interface'

export function withToggleField(Component: React.FC<ToggleFieldProps>) {
  function WithToggleField({ name, onChange, ...props }: WithToggleFieldProps) {
    const [field, , { setValue }] = useField(name)

    function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
      setValue(event.target.checked)
      if (onChange) {
        onChange(event)
      }
    }

    const componentProps = {
      ...props,
      ...field,
      checked: field.value,
      handleChangeValue,
    }

    return <Component {...componentProps} />
  }

  return WithToggleField
}
