import { useEffect } from 'react'
import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { EditPromotionSalePageProps } from './interface'

export function withEditPromotionSalePage(
  Component: React.FC<EditPromotionSalePageProps>
) {
  function WithEditPromotionSalePage() {
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmModal = useModal({ modal: 'confirm' })

    const queryKey: QueryKey = ['promotion', 'sale', Number(id)]

    const { data, isFetching } = useQuery(
      [...queryKey, 'edit'],
      () => client?.promotionSaleClient.getPromotionSaleForm(Number(id)),
      {
        onError: () => navigate(-1),
      }
    )

    const { data: saleCoinOptions = [] } = useQuery('sale-coin-options', () =>
      client?.coinClient.getPromotionChallengeCoinOptions()
    )

    const { mutateAsync: updatePromotion } = useMutation(
      (values: PromotionSaleFormType) =>
        client!.promotionSaleClient.editPromotionSale({
          ...values,
          status: PromotionStatusEnum.PENDING,
        }),
      {
        onSuccess: () => {
          alert.success('แก้ไขโปรโมชั่นสำเร็จ')
          navigate(-1)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function updatePromotionCode(values: PromotionSaleFormType) {
      await updatePromotion(values)
      confirmModal.hide()
    }

    useEffect(() => {
      if (data) {
        if (!data.isEditable) {
          navigate(-1)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const newProps = {
      initialValues: data,
      updatePromotionCode,
      isLoading: isFetching,
      saleCoinOptions,
    }

    return <Component {...newProps} />
  }

  return WithEditPromotionSalePage
}
