/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthentication } from '@hooks/useAuthentication'
import { PermissionActionType } from '@interfaces/permission/PermissionActionType'
import { PermissionNameType } from '@interfaces/permission/PermissionNameType'

export function usePermissionAction(
  feature: PermissionNameType
): PermissionActionType {
  const { permission } = useAuthentication()

  return permission[feature]
}
