import { withPermissionGuard } from '@libs/permission'
import { PopupBannerDetailPage } from './PopupBannerDetailPage'
import { withPopupBannerDetailPage } from './withPopupBannerDetailPage'

const ConnectedPopupBannerDetailPage = withPermissionGuard(
  withPopupBannerDetailPage(PopupBannerDetailPage),
  'homePageManagement',
  'view'
)

export { ConnectedPopupBannerDetailPage as PopupBannerDetailPage }
