import { gql } from 'graphql-request'

export const GET_USER_READER_COIN_DETAIL = gql`
  query GetUserReaderCoinDetail(
    $userId: Int!
    $endDate: DateTime
    $startDate: DateTime
  ) {
    getUserReaderCoinDetail(
      userId: $userId
      endDate: $endDate
      startDate: $startDate
    ) {
      currentPaidCoin
      currentFreeCoin
      totalUseFreeCoin
      totalUsePaidCoin
      totalBuyFreeCoin
      totalBuyPaidCoin
    }
  }
`
