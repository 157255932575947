import { withPermissionGuard } from '@libs/permission'
import { FormDetailPromotionCodePage } from './FormDetailPromotionCodePage'
import { withFormDetailPromotionCodePage } from './withFormDetailPromotionCodePage'

const ConnectedFormDetailPromotionCodePage = withPermissionGuard(
  withFormDetailPromotionCodePage(FormDetailPromotionCodePage),
  'promotionSetting',
  'view'
)

export { ConnectedFormDetailPromotionCodePage as FormDetailPromotionCodePage }
