import cn from 'classnames'

import { Container } from '@components/Container'
import { BanCoverIcon } from '@components/Icons'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CoinSection } from './components/CoinSection'
import { PennameSection } from './components/PenNameSection'
import { WritingSection } from './components/WritingSection'
import { UserManagementDetailProps } from './interface'
import { BanSection } from './components/BanSection'
import { PDPA } from './components/PDPA'
import { ButtonSelect } from '../UserManagementPage/components/UserManagementTable/components/ButtonSelect'

export function UserManagementDetailPage({
  data,
  tabActive,
  handleSelectTab,
  banCover,
  banBanner,
  handleUnbanUser,
}: UserManagementDetailProps) {
  const permission = usePermissionAction('user')
  const menusArray = [
    {
      value: 'penName',
      text: 'นามปากกา',
      total: data?.penNameCount ? data?.penNameCount.toLocaleString() : 0,
    },
    {
      value: 'writing',
      text: 'งานเขียน',
      total: data?.totalBook ? data?.totalBook.toLocaleString() : 0,
    },
    {
      value: 'coin',
      text: 'เหรียญ',
      total: undefined,
    },
    {
      value: 'ban',
      text: 'ประวัติการแบน',
      total: undefined,
    },
    {
      value: 'pdpa',
      text: 'การกดยอมรับเงื่อนไขการใช้งาน',
      total: undefined,
    },
  ]
  const actions = [
    {
      label: 'ยกเลิกการแบน',
      isShow: !!data?.isBan,
      disabled: false,
      onClick: handleUnbanUser,
    },
  ]
  return (
    <Container>
      <Container.Title showBackBtn>@{data?.username}</Container.Title>
      <section className='grid grid-cols-[100px_1fr] items-center gap-x-[40px] gap-y-[20px] border-b-[1px] border-grey pb-[30px] mb-[30px]'>
        <div className='col-span-2 relative'>
          <img
            src={data?.bannerImgPath || '/images/cover-profile.png'}
            alt=''
            className='rounded-lg h-[240px] w-full '
          />
          {permission.edit && (
            <button
              className='font-light text-[14px] text-primary border boder-gray flex items-center absolute bg-white rounded-lg bottom-[20px] left-[20px] px-[10px] py-[5px] disabled:cursor-not-allowed'
              type='button'
              onClick={banBanner}
              disabled={!data?.bannerImgPath}
            >
              <BanCoverIcon width='18' height='18' className='mr-[5px]' />
              แบนรูปภาพ
            </button>
          )}
        </div>
        {permission.edit && data?.isBan && (
          <div className='font-light px-[10px] h-[40px] text-[14px] text-red w-full col-span-2 border border-red bg-red/10 rounded flex items-center justify-end '>
            <div className='mx-auto flex items-center'>
              <BanCoverIcon
                width='18'
                height='18'
                className='mr-[5px] text-red justify-self-end'
              />
              Banned
            </div>
            <ButtonSelect actions={actions} className='' />
          </div>
        )}
        <div className='relative'>
          <img
            src={data?.avatarImgPath || '/images/avatar.png'}
            alt=''
            className='w-[100px] h-[100px] rounded-full'
          />
          {permission.edit && (
            <button
              className='font-light text-[14px] text-primary border border-gray absolute bg-white rounded-lg bottom-0 right-0 p-[5px] disabled:cursor-not-allowed'
              type='button'
              onClick={banCover}
              disabled={!data?.avatarImgPath}
            >
              <BanCoverIcon width='18' height='18' />
            </button>
          )}
        </div>
        <div className='flex gap-x-[60px] text-secondary font-light text-[14px]'>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              User ID
            </span>
            <p>{data?.id || '-'}</p>
          </div>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              Writer ID
            </span>
            <p>
              {data?.writer?.status === WriterStatusEnum.APPROVED
                ? data?.writer?.id
                : '-'}
            </p>
          </div>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              Username
            </span>
            <p className='line-clamp-1 break-word max-w-[250px]'>
              {data?.username ? `@${data?.username}` : '-'}
            </p>
          </div>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              Email
            </span>
            <p className='line-clamp-1 break-word max-w-[250px]'>
              {data?.email ? data?.email : '-'}
            </p>
          </div>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              Publisher
            </span>
            <p className='line-clamp-1 break-word max-w-[250px]'>
              {data?.writer?.affiliation === AffiliationEnum.PUBLISHER &&
              data?.writer?.status === WriterStatusEnum.APPROVED
                ? data?.displayName
                : '-'}
            </p>
          </div>
          <div>
            <span className='text-secondary-100 font-bold text-[12px]'>
              Writer
            </span>
            <p className='line-clamp-1 break-word max-w-[250px]'>
              {data?.writer?.affiliation === AffiliationEnum.INDIVIDUAL &&
              data?.writer?.status === WriterStatusEnum.APPROVED
                ? data?.displayName
                : data && data.penName.length > 0
                ? data.displayName
                : '-'}
            </p>
          </div>
        </div>
      </section>
      <section>
        <div
          className={cn(
            'text-[14px] text-secondary font-light gap-x-[20px] flex'
          )}
        >
          {menusArray.map(item => (
            <button
              key={item.value}
              type='button'
              className={cn(
                'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
                {
                  'rounded-[8px] bg-secondary text-white':
                    item.value === tabActive,
                  'rounded-[8px] bg-blue-2 text-secondary-100':
                    item.value !== tabActive,
                }
              )}
              onClick={() => handleSelectTab(item.value)}
            >
              {item.text}
              {(item.value === 'penName' || item.value === 'writing') && (
                <span className='px-[2px]'> ({item.total}) </span>
              )}
            </button>
          ))}
        </div>
        {tabActive === 'penName' && (
          <PennameSection
            writerId={String(data?.writer?.id) || ''}
            userId={String(data?.id) || ''}
          />
        )}
        {tabActive === 'writing' && <WritingSection />}
        {tabActive === 'coin' && (
          <CoinSection
            currentFreeCoin={data?.currentFreeCoin || 0}
            currentPaidCoin={data?.currentPaidCoin || 0}
          />
        )}
        {tabActive === 'ban' && <BanSection />}
        {tabActive === 'pdpa' && <PDPA />}
      </section>
    </Container>
  )
}
