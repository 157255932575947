import { SortingType } from '@components/Table/interface'
import { DateType } from '@features/dashboard/components/SearchDateRange/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { DurationEnum } from '@interfaces/DurationEnum'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'
import { DonationSearchFormType } from './DonationSearchFormType'

export enum OrderByEnum {
  TAG_ID = 'tagId',
  USED_COUNT = 'tagUsedCount',
  TAG_COUNT = 'tagClickCount',
}

export interface TopHashtagSortParam {
  orderBy: OrderByEnum
  orderDirection: SortingType
}

export interface TopHashtagByTopHashtagSortParam
  extends TopHashtagSortParam,
    DonationQueryParam {
  page: number
}

export interface TopHashtagQueryParam {
  bookType: BookEnum | null
  typeDuration: DurationEnum | null
  categoryId: number | null
  startDate?: DateType | null
  endDate?: DateType | null
  sortKey?: string
  sortOrder?: SortingType
}
