import { Table } from '@components/Table'
import { DonationTableProps } from '../interface'

export function DonationTemplate<T>({
  title,
  data,
  columns,
  bodyClassName,
  handleSort,
  onWaypointEnter,
  isLoading,
}: DonationTableProps<T>) {
  return (
    <div className='mb-[30px]'>
      {title && (
        <div className='font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          {title}
        </div>
      )}
      <Table
        className='max-h-[355px] pr-[20px]'
        data={data}
        columns={columns}
        bodyClassName={bodyClassName}
        onWaypointEnter={onWaypointEnter}
        onSort={handleSort}
        isLoading={isLoading}
        fixedHeader
      />
    </div>
  )
}
