import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { ByTitlePageProps } from './interface'
import { ByTitleForm } from '../ByTitleForm'
import { ByTitleTable } from '../ByTitleTable'

export function ByTitlePage({
  queryParam,
  handleSearch,
  onResetForm,
}: ByTitlePageProps) {
  return (
    <>
      <ByTitleForm
        initialValues={{
          bookId: queryParam.bookId,
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSearch}
        onResetForm={onResetForm}
      />
      {queryParam.bookId ? (
        <CardContainer>
          <ByTitleTable queryParam={queryParam} />
        </CardContainer>
      ) : (
        <CardContainer>
          <p className='text-center'>
            เลือกชื่อเรื่อง,ช่วงเวลา ที่ต้องการดูข้อมูล
          </p>
        </CardContainer>
      )}
    </>
  )
}
