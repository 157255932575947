import React from 'react'

import { PopupTableProps } from './interface'

export function withPopupTable(Component: React.FC<PopupTableProps>) {
  function WithPopupTable(props: PopupTableProps) {
    return <Component {...props} />
  }

  return WithPopupTable
}
