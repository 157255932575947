import { gql } from 'graphql-request'

export const CREATE_CATEGORY_COVER = gql`
  mutation CreateCategoryCover(
    $createCategoryCoverInput: CreateCategoryCoverInput!
  ) {
    createCategoryCover(createCategoryCoverInput: $createCategoryCoverInput) {
      id
    }
  }
`
