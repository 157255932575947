export enum CodeLogUserTypeEnum {
  NEW_USER = 'NEW_USER',
  NEW_TOP_UP = 'NEW_TOP_UP',
  FREE_USER = 'FREE_USER',
  PAID_USER = 'PAID_USER',
}

export const CodeLogUserTypeValue = {
  [CodeLogUserTypeEnum.NEW_USER]: 'New user',
  [CodeLogUserTypeEnum.NEW_TOP_UP]: 'New top-up',
  [CodeLogUserTypeEnum.FREE_USER]: 'Free user',
  [CodeLogUserTypeEnum.PAID_USER]: 'Paid user',
}
