import { gql } from 'graphql-request'

export const EBOOK_BY_TITLE = gql`
  query EbookDashboardByTitle(
    $ebookId: Int
    $endDate: DateTime
    $startDate: DateTime
    $page: Int
    $limitPerPage: Int
  ) {
    ebookDashboardByTitle(
      ebookId: $ebookId
      endDate: $endDate
      startDate: $startDate
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        ebookType
        downloadedCount
        coverImgPath
        penName
        readTrialChapterCount
        reviewCount
        totalSales
        title
        viewCount
        wishlistCount
      }
    }
  }
`
