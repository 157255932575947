import { Outlet } from 'react-router-dom'

import { SearchManagementLayout } from '@components/layouts/SearchManagementLayout'
import { SearchHistoryPage } from './pages/SearchHistoryPage'
import { ManageSearchTermPage } from './pages/ManageSearchTermPage'

export const searchManagementRoutes = [
  {
    path: 'search-management',
    element: (
      <SearchManagementLayout>
        <Outlet />
      </SearchManagementLayout>
    ),
    children: [
      {
        path: 'history',
        element: <SearchHistoryPage />,
      },
      {
        path: 'manage-search-term',
        element: <ManageSearchTermPage />,
      },
    ],
  },
]
