import { Exclude, Expose, Transform } from 'class-transformer'

import { MainBannerBaseType } from './MainBannerBaseType'

@Exclude()
export class MainBannerType implements MainBannerBaseType {
  @Expose() id: number

  @Expose() runningNo: number

  @Expose() coverImgPath: string

  @Expose() description: string

  @Expose() rawLink: string

  @Expose() linkWeb: string

  @Expose() linkIos: string

  @Expose() linkAndroid: string

  @Expose()
  @Transform(({ value }) => new Date(value).toString())
  startPublishedAt: string

  @Expose()
  @Transform(({ value }) => new Date(value).toString())
  endPublishedAt: string

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose() deletedAt: string

  @Expose() web: boolean

  @Expose() android: boolean

  @Expose() ios: boolean

  @Expose()
  @Transform(({ value, obj }) =>
    new Date().valueOf() > new Date(obj.endPublishedAt).valueOf()
      ? false
      : value
  )
  isActiveWeb: boolean

  @Expose()
  @Transform(({ value, obj }) =>
    new Date().valueOf() > new Date(obj.endPublishedAt).valueOf()
      ? false
      : value
  )
  isActiveIos: boolean

  @Expose()
  @Transform(({ value, obj }) =>
    new Date().valueOf() > new Date(obj.endPublishedAt).valueOf()
      ? false
      : value
  )
  isActiveAndroid: boolean
}
