import { withPermissionGuard } from '@libs/permission'
import { SearchHistoryPage } from './SearchHistoryPage'
import { withSearchHistoryPage } from './withSearchHistoryPage'

const ConnectedSearchHistoryPage = withPermissionGuard(
  withSearchHistoryPage(SearchHistoryPage),
  'searchManagement',
  'view'
)

export { ConnectedSearchHistoryPage as SearchHistoryPage }
