import { gql } from 'graphql-request'

export const EBOOK_LOGS = gql`
  query EbookLogs($ebookId: Int!, $limitPerPage: Int, $page: Int) {
    ebookLogs(ebookId: $ebookId, limitPerPage: $limitPerPage, page: $page) {
      total
      page
      data {
        id
        action
        actionBy {
          id
          avatarImgPath
          displayName
        }
        bookReport {
          id
          code
        }
        createdAt
      }
    }
  }
`
