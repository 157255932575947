import { gql } from 'graphql-request'

export const GET_READER_DETAIL_BY_USER = gql`
  query GetReaderDetailByUser($userId: Int!) {
    getReaderDetailByUser(userId: $userId) {
      user {
        avatarImgPath
        id
        username
        createdAt
        penNameCount
      }
      summaryTime
    }
  }
`
