import { Expose, Transform, Type } from 'class-transformer'

export class TimeListType {
  @Expose({ name: 'time' })
  @Transform(({ value }) => `${value}:00`)
  name: string

  @Expose()
  @Transform(({ obj }) => {
    if (obj.count === 0) return 0
    return obj.summaryTime / obj.count
  })
  value: number

  @Expose() color: string
}

export class ReadTimeUsageResponse {
  @Expose()
  @Type(() => TimeListType)
  @Transform(({ value }) => {
    const temp = [...value]
    const topThree = temp
      .sort((a: TimeListType, b: TimeListType) => b.value - a.value)
      .slice(0, 3)
    const data = value.map((item: TimeListType) => {
      return {
        ...item,
        color: topThree.some(
          (element: TimeListType) => element.name === item.name
        )
          ? '#3f54d9'
          : '#cecfd7',
      }
    })

    return data
  })
  timeList: TimeListType[]

  @Expose()
  @Transform(({ value }) => value || 0)
  averageTimeUsage: number

  @Expose()
  @Transform(({ value }) => value || 0)
  greatestTimeUsage: number
}
