import cn from 'classnames'
import { Link } from 'react-router-dom'

import { Toggle } from '@components/Toggle'
import { Accordion } from '@components/Accordion2'
import { EditIcon, EyeIcon } from '@components/Icons'
import { InformationLevel } from '@features/user-mission/components/InformationLevel'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ChallengeLevelType } from '@models/user-mission/ChallengeLevelType'
import { ChallengeUnit } from '@interfaces/user-mission/ChallengeEnum'

interface UserMissionCardProps {
  id: number
  title: string
  description: string
  active: boolean
  iconUrl: string
  criteria: string
  challengeUnit: ChallengeUnit
  challengeLevels: ChallengeLevelType[]
  handleToggle: (id: number) => void
}

export function UserMissionCard({
  id,
  title,
  description,
  active,
  iconUrl,
  criteria,
  challengeUnit,
  challengeLevels,
  handleToggle,
}: UserMissionCardProps) {
  const permission = usePermissionAction('userMission')

  return (
    <div className='flex flex-col gap-y-[22px] shadow rounded-[8px] border border-blue-2 p-[30px]'>
      {id && (
        <Accordion
          title={
            <>
              <div className='w-[72px] aspect-square border border-gray rounded-full overflow-hidden'>
                <img className='w-full h-full' src={iconUrl} alt='' />
              </div>

              <div className='text-[18px] text-black-480 font-bold'>
                {title}
              </div>

              <div className='flex items-center gap-x-[20px]'>
                <Toggle
                  onChange={() => handleToggle(id)}
                  checked={active}
                  disabled={!permission.edit}
                />
                <Link
                  to={`edit/${id}`}
                  className={cn({ hidden: !permission.edit })}
                >
                  <button
                    className='text-secondary-100 p-[4px] rounded-[8px] border border-blue-2'
                    type='button'
                  >
                    <EditIcon width='20' height='20' />
                  </button>
                </Link>
                <Link to={`${id}`}>
                  <button
                    className='text-secondary-100 p-[4px] rounded-[8px] border border-blue-2'
                    type='button'
                  >
                    <EyeIcon width='20' height='20' />
                  </button>
                </Link>
              </div>

              <div className='flex items-center gap-x-[20px]'>
                <div className='border-[1px] border-gray h-[30px]' />
              </div>
            </>
          }
          className='bg-white'
          titleClassName='grid grid-cols-[72px_1fr_144px_1px_24px] gap-x-[20px] items-center'
        >
          <div className='border-t border-gray mt-[20px] py-[20px]'>
            <table className='text-[14px] text-black-480 font-light'>
              <tbody>
                {challengeLevels.map((challenge, i) => (
                  <InformationLevel
                    key={challenge.id}
                    id={challenge.id}
                    level={challenge.level}
                    levelName={challenge.levelName}
                    criteria={criteria}
                    target={challenge.target}
                    toTarget={
                      challengeLevels.length - 1 === i
                        ? 0
                        : challengeLevels[i + 1].target
                    }
                    challengeUnit={challengeUnit}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      )}
    </div>
  )
}
