import { gql } from 'graphql-request'

export const GET_USER_BY_ID = gql`
  query GetProfileById($userId: Int!) {
    getProfileById(id: $userId) {
      id
      avatarImgPath
      fullName
      email
      phoneNumber
      birthDay
      username
      displayName
      ageVerify
      idCardImgPath
      ageVerifyRejectedReason
      ageVerifyRejectedAt
      ageVerifyApprovedAt
      ageVerifyApprover {
        avatarImgPath
        displayName
      }
    }
  }
`
