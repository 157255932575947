import { Expose } from 'class-transformer'

export class ImageType {
  @Expose()
  url: string

  @Expose()
  blob?: File | Blob

  @Expose()
  name?: string
}
