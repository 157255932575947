import { Expose, Type } from 'class-transformer'
import { ExportJobType } from './ExportJobType'

export class ExportJobResponse {
  @Expose()
  @Type(() => ExportJobType)
  data: ExportJobType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
