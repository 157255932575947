import { PromotionCodeInfoProps, WithPromotionCodeInfoProps } from './interface'

export function withPromotionCodeInfo(
  Component: React.FC<PromotionCodeInfoProps>
) {
  function Hoc(props: WithPromotionCodeInfoProps) {
    return <Component {...props} />
  }
  return Hoc
}
