import cn from 'classnames'

import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function CardContainer({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'border border-[#f4f4f4] p-[30px] mt-[30px] rounded-lg shadow',
        className
      )}
    >
      {children}
    </div>
  )
}
export function CardValue({ children, className }: FunctionComponentType) {
  return (
    <span className={cn('flex flex-col gap-1 min-w-[100px]', className)}>
      {children}
    </span>
  )
}
export function TextTitle({ children, className }: FunctionComponentType) {
  return (
    <p
      className={cn('font-mitr text-[#393c4e] text-2xl font-medium', className)}
    >
      {children}
    </p>
  )
}
export function TextLabel({ children, className }: FunctionComponentType) {
  return (
    <p className={cn('text-secondary-100 text-sm font-light', className)}>
      {children}
    </p>
  )
}
export function TextValue({ children, className }: FunctionComponentType) {
  return (
    <p className={cn('flex items-center gap-x-[10px]  font-bold', className)}>
      {children}
    </p>
  )
}
