import React from 'react'

import { InformationProps } from './interface'

export function withInformation(Component: React.FC<InformationProps>) {
  function WithInformation(props: InformationProps) {
    return <Component {...props} />
  }

  return WithInformation
}
