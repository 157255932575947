import { client } from '@client/init'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { BarChartByUserProps, WithBarChartByUserProps } from './interface'

const withBarChartByUser = (Component: React.FC<BarChartByUserProps>) => {
  function WithBarChartByUser({ queryParam }: WithBarChartByUserProps) {
    const { data: frequencyToBuyCoin } = useQuery(
      ['frequency-to-buy-coin', queryParam],
      () =>
        client?.dashboardClient.getFrequencyToBuyCoinByUser({
          startDate: queryParam.startDate,
          endDate: queryParam.endDate,
          userId: Number(queryParam.userId),
          keyType: queryParam.keyType,
        }),
      {
        enabled: !!queryParam.userId,
      }
    )
    const { data: readTimeUsage } = useQuery(
      ['read-time-usage', queryParam],
      () =>
        client?.dashboardClient.getReadTimeUsageByUser({
          startDate: queryParam.startDate,
          endDate: queryParam.endDate,
          userId: Number(queryParam.userId),
        }),
      {
        enabled: !!queryParam.userId,
      }
    )

    const timeOfUse = useMemo(() => {
      if (readTimeUsage) {
        const max = Math.max(...readTimeUsage.timeList.map(row => row.value))
        const timeList = readTimeUsage.timeList.filter(row => row.value === max)

        return timeList.reduce((res, cur) => {
          const index = res.findIndex(row => {
            const [, to] = row.split(' - ')
            return to.includes(cur.name)
          })

          if (index !== -1) {
            const [from, to] = res[index].split(' - ')
            const [hour] = to.split(':')
            res[index] = `${from} - ${(Number(hour) + 1) % 24}:00`

            return res
          }

          const [hour] = cur.name.split(':')
          return [...res, `${cur.name} - ${(Number(hour) + 1) % 24}:00`]
        }, [] as string[])
      }

      return []
    }, [readTimeUsage])

    if (!frequencyToBuyCoin || !readTimeUsage) return null
    const newProps = {
      frequencyToBuyCoin,
      readTimeUsage,
      timeOfUse,
    }
    return <Component {...newProps} />
  }

  return WithBarChartByUser
}

export default withBarChartByUser
