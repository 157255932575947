import { Expose, Type } from 'class-transformer'
import { PendingListAdminType } from './PendingListAdminType'

export class PendingListAdminResponse {
  @Expose()
  @Type(() => PendingListAdminType)
  data: PendingListAdminType[]

  @Expose()
  page: number

  @Expose()
  total: number
}
