import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { SocialMediaChannelsEnum } from '@interfaces/promotionChllenge/SocialMediaChannelsEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { PromotionLastRequestType } from '../PromotionLastRequestType'

export class PromotionChallengeFormType {
  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.id || undefined, {
    toClassOnly: true,
  })
  id?: number

  @Expose()
  name: string

  @Expose()
  description: string

  @Expose()
  userTerm: string

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : new Date()))
  startPublishedAt: Date

  @Expose()
  @Transform(({ obj }) =>
    obj.publishedType === PromotionPublishedEnum.NOW
      ? obj.startPublishedAt
        ? new Date(obj.startPublishedAt)
        : undefined
      : undefined
  )
  startNowAt?: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  endPublishedAt: Date

  @Expose()
  publishedType: PromotionPublishedEnum

  @Expose()
  status: PromotionStatusEnum

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionChallenge?.categories?.map((c: { id: number }) =>
        c.id.toString()
      ) || [],
    {
      toClassOnly: true,
    }
  )
  categoryIds?: string[]

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.action || undefined, {
    toClassOnly: true,
  })
  action: PromotionChallengeActionEnum

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.actionValue || 0, {
    toClassOnly: true,
  })
  actionValue?: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.wordCount || 0, {
    toClassOnly: true,
  })
  wordCount?: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.socialMediaChannels || [], {
    toClassOnly: true,
  })
  socialMediaChannels: SocialMediaChannelsEnum[]

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.readCondition || undefined, {
    toClassOnly: true,
  })
  readCondition?: PromotionChallengeReadConditionEnum

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionChallenge?.coinsCondition?.map((coin: any) => coin?.id) ||
      [],
    {
      toClassOnly: true,
    }
  )
  coinConditionIds: number[]

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.bookTypes || [], {
    toClassOnly: true,
  })
  bookTypes: BookEnum[]

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.benefitValue || 0, {
    toClassOnly: true,
  })
  benefitValue: number

  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.promotionChallenge?.benefitCoin?.id?.toString() || undefined,
    {
      toClassOnly: true,
    }
  )
  benefitCoinId?: string

  @Expose()
  @Transform(
    ({ obj }) => obj?.promotionChallenge?.benefitLimitType || undefined,
    {
      toClassOnly: true,
    }
  )
  benefitLimitType?: PromotionChallengeLimitTypeEnum

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.benefitLimitValue || 0, {
    toClassOnly: true,
  })
  benefitLimitValue?: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.budget || 0, {
    toClassOnly: true,
  })
  budget: number

  @Expose()
  @Transform(({ obj }) => obj?.promotionChallenge?.budgetPerDay || 0, {
    toClassOnly: true,
  })
  budgetPerDay?: number

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Transform(({ obj }) => !!obj?.promotionChallenge?.budgetPerDay, {
    toClassOnly: true,
  })
  isLimitBudget?: boolean

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  createdAt?: string // DESC: DateTime string
}
