import React from 'react'
import cn from 'classnames'
import { SelectSearch } from '@components/SelectSearch'
import { SelectSearchFieldProps } from './interface'

export function SelectSearchField({
  placeholder,
  label,
  labelClassName,
  disabled,
  options,
  touched,
  error,
  value,
  onChange,
}: SelectSearchFieldProps) {
  return (
    <div className='relative select-none'>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 font-light mb-[4px]',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <SelectSearch
        className={cn({ 'border-red': touched && error })}
        options={options}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}
