import cn from 'classnames'
import RcPagination from 'rc-pagination'
import { ReactNode } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/Icons'
import { PaginationProps } from '@components/Pagination/interface'
import '@styles/rcPaginate.css'

const itemRender = (
  current: number,
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
  element: ReactNode,
  page: number,
  totalPage: number
) => {
  if (type === 'page') {
    return (
      <div>
        {current === totalPage && totalPage > 1 ? (
          <span className='mx-[20px]'>หน้าสุดท้าย</span>
        ) : (
          current
        )}
      </div>
    )
  }
  if (type === 'prev') {
    return (
      <div
        className={cn(
          'border border-gray-3 dark:border-secondary-1 h-[30px] flex px-[3px] items-center justify-center rounded-[8px]'
        )}
      >
        <ArrowLeftIcon
          className={cn({
            'text-gray-500': page === 1 || totalPage === 0,
            'text-secondary': page !== 1 && totalPage !== 0,
          })}
          width='24'
          height='24'
        />
      </div>
    )
  }
  if (type === 'next') {
    return (
      <div
        className={cn(
          'border border-gray-3 dark:border-secondary-1 h-[30px] flex px-[3px] items-center justify-center rounded-[8px]'
        )}
      >
        <ArrowRightIcon
          className={cn({
            'text-gray-500': page === totalPage || totalPage === 0,
            'text-secondary': page !== totalPage && totalPage !== 0,
          })}
          width='24'
          height='24'
        />
      </div>
    )
  }
  return element
}

export function Pagination({
  page,
  total,
  perpage,
  pageChange,
  totalPage,
}: PaginationProps) {
  return (
    <div className='flex justify-center'>
      <RcPagination
        onChange={pageChange}
        pageSize={perpage}
        current={page}
        total={total}
        itemRender={(current, type, element) =>
          itemRender(current, type, element, page, totalPage)
        }
        showTitle={false}
      />
    </div>
  )
}
