import { gql } from 'graphql-request'

export const PROMOTION_SALE_LOGS = gql`
  query PromotionSaleLogs(
    $limitPerPage: Int
    $page: Int
    $promotionSaleId: Int!
    $searchText: String
    $orderBy: SaleLogOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    promotionSaleLogs(
      limitPerPage: $limitPerPage
      page: $page
      promotionSaleId: $promotionSaleId
      searchText: $searchText
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        id
        userId
        username
        bookIds
        bookNames
        penNames
        coinSpent
        coinReceived
        createdAt
      }
      total
      page
    }
  }
`
