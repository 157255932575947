import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Button } from '@components/Button'
import { ArrowLeftIcon } from '@components/Icons'
import { PromotionChallengeFormProp } from './interface'
import ChallengeForm from './components/ChallengeForm'
import ChallengeConditionForm from './components/ChallengeConditionForm'
import { validationSchema } from './components/PreviewPromotionChallenge/validationSchema'

export function PromotionChallengeForm({
  initialValues,
  handleSubmit,
  handleSaveDraft,
  viewMode = false,
  title,
  ...props
}: PromotionChallengeFormProp) {
  const navigate = useNavigate()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty }) => (
        <Form>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={() => {
                if (dirty && handleSaveDraft) {
                  handleSaveDraft(values)
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>

            <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
              {title}
            </div>
          </div>
          <div className='grid gap-y-[20px]'>
            <ChallengeForm viewMode={viewMode} />
            <ChallengeConditionForm viewMode={viewMode} {...props} />
          </div>
          {!viewMode && (
            <div className='flex justify-center gap-x-[20px] mt-[30px]'>
              <Button
                onClick={() => {
                  if (dirty && handleSaveDraft) {
                    handleSaveDraft(values)
                  } else {
                    navigate(-1)
                  }
                }}
                className='border border-secondary-100 text-secondary-100 text-[14px] px-[30px]'
              >
                ยกเลิก
              </Button>
              <Button
                type='submit'
                className='text-white bg-primary px-[30px] text-[14px]'
              >
                บันทึก
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
