import { ChartOptionType } from '@interfaces/ChartOptionType'
import { Expose, Transform, Type } from 'class-transformer'

export class UserType {
  @Expose() id: string

  @Expose() createdAt: string

  @Expose() avatarImgPath: string

  @Expose() username: string

  @Expose()
  @Transform(({ value }) => value || 0)
  penNameCount: number
}

export class WriterByUserType {
  @Expose() id: string

  @Expose() createdAt: string

  @Expose() firstPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType

  @Expose() firstBookCreatedDate: string

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryDonate: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryActionTime: number

  @Expose()
  @Transform(({ value }) => value || 0)
  maleReader: number

  @Expose()
  @Transform(({ value }) => value || 0)
  femaleReader: number

  @Expose()
  @Transform(({ value }) => value || 0)
  otherReader: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryBookLike: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryBookComment: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryBookPurchase: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryAddBookShelfCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryTotalRead: number

  @Expose()
  @Transform(({ value }) => value || 0)
  summarySellCount: number

  @Expose()
  @Transform(({ obj }) => {
    return [
      { value: obj?.femaleReader || 0, name: 'หญิง' },
      { value: obj?.maleReader || 0, name: 'ชาย' },
      { value: obj?.otherReader || 0, name: 'ไม่ระบุเพศ' },
    ]
  })
  readerGender: ChartOptionType[]

  @Expose()
  @Transform(({ obj }) => {
    return [
      { value: obj?.ageBelowFifteen || 0, name: 'ต่ำกว่า 15' },
      { value: obj?.ageBetweenSixteenAndTwenty || 0, name: '16 - 20' },
      { value: obj?.ageBetweenTwentyOneAndThirty || 0, name: '21-30' },
      { value: obj?.ageBetweenThirtyOneAndForty || 0, name: '31-40' },
      { value: obj?.ageMoreThanFortyOne || 0, name: '41 ขึ้นไป' },
      { value: obj?.otherAge || 0, name: 'ไม่ระบุ' },
    ]
  })
  readerAge: ChartOptionType[]

  @Expose()
  @Transform(({ value }) => value || 0)
  totalPaidWriterIncome: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalPayableWriterIncome: number
}
