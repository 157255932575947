import { gql } from 'graphql-request'

export const BOOK_REPORTS = gql`
  query GetBookReports(
    $page: Int
    $limitPerPage: Int
    $search: String
    $where: BookReportWhere
    $orderBy: BookReportOrderBy
    $type: ReportBookType
    $isVisible: Boolean
  ) {
    bookReports(
      page: $page
      limitPerPage: $limitPerPage
      search: $search
      where: $where
      orderBy: $orderBy
      type: $type
      isVisible: $isVisible
    ) {
      total
      page
      data {
        id
        code
        title
        detail
        status
        createdAt
        updatedAt
        reportBy {
          id
          displayName
        }
        book {
          id
          title
        }
        ebook {
          id
          title
        }
      }
    }
  }
`
