import { useClient } from '@hooks/useClient'
import React from 'react'
import { useQuery } from 'react-query'
import {
  TotalDonationSectionProps,
  WithTotalDonationSectionProps,
} from './interface'

export function withTotalDonationSection(
  Component: React.FC<TotalDonationSectionProps>
) {
  function WithTotalDonation({ searchParams }: WithTotalDonationSectionProps) {
    const client = useClient()
    const { data } = useQuery(['total-character-donation', searchParams], () =>
      client?.characterClient.getTotalCharacterDonation(searchParams)
    )
    const componentProps = {
      totalIncome: data?.totalIncome ?? 0,
      writerIncome: data?.writerIncome ?? 0,
      platformIncome: data?.platformIncome ?? 0,
    }
    return <Component {...componentProps} />
  }

  return WithTotalDonation
}
