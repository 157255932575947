import { withPermissionGuard } from '@libs/permission'
import { CoinBalancePage } from './CoinBalancePage'
import { withCoinBalancePage } from './withCoinBalancePage'

const ConnectedCoinBalancePage = withPermissionGuard(
  withCoinBalancePage(CoinBalancePage),
  'coin',
  'view'
)

export { ConnectedCoinBalancePage as CoinBalancePage }
