import cn from 'classnames'
import { ColorResult } from 'react-color'
import { EditorContent } from '@tiptap/react'

import {
  CutIcon,
  CopyIcon,
  PasteIcon,
  UndoIcon,
  RedoIcon,
  LinkIcon,
  ImageIcon,
  VideoIcon,
  StickerIcon,
  QuoteIcon,
  SpoilIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  StrikeThroughIcon,
  SupIcon,
  SubIcon,
  HorizontalLineIcon,
  NumberListIcon,
  BulletListIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  TextColorIcon,
  BgColorIcon,
  FootnoteIcon,
  AlignJustifyIcon,
} from '@components/Icons'
import TiptapIframeInput from './components/TiptapIframeInput'
import { TiptapProps } from './interface'
import { TiptapStickerButton } from './components/TiptapStickerButton'
import TiptapFootnoteInput from './components/TiptapFootnoteInput'
import SeparateLine from './components/SeparateLine'
import TiptapButton from './components/TiptapButton'
import TiptapColorInput from './components/TiptapColorInput'
import TiptapLinkInput from './components/TiptapLinkInput'
import TiptapImageInput from './components/TiptapImageInput'

function Tiptap({
  editor,
  className,
  containerClassName,
  name,
  pasteAlert,
  isMac,
  isWin,
  editorRef,
}: TiptapProps) {
  const tooltipLabel = {
    isMac: {
      cut: 'ตัด(⌘+X)',
      copy: 'สำเนา(⌘+C)',
      paste: 'วาง(⌘+P)',
      undo: 'ยกเลิกคำสั่ง(⌘+Z)',
      redo: 'ทำซ้ำคำสั่ง(⌘+Y)',
      bold: 'ตัวหนา(⌘+B)',
      italic: 'ตัวเอียง(⌘+I)',
      underline: 'ตัวเขีดเส้นใต้(⌘+U)',
    },
    isWin: {
      cut: 'ตัด(Ctrl+X)',
      copy: 'สำเนา(Ctrl+C)',
      paste: 'วาง(Ctrl+P)',
      undo: 'ยกเลิกคำสั่ง(Ctrl+Z)',
      redo: 'ทำซ้ำคำสั่ง(Ctrl+Y)',
      bold: 'ตัวหนา(Ctrl+B)',
      italic: 'ตัวเอียง(Ctrl+I)',
      underline: 'ตัวเขีดเส้นใต้(Ctrl+U)',
    },
    others: {
      cut: 'ตัด',
      copy: 'สำเนา',
      paste: 'วาง',
      undo: 'ยกเลิกคำสั่ง',
      redo: 'ทำซ้ำคำสั่ง',
      bold: 'ตัวหนา',
      italic: 'ตัวเอียง',
      underline: 'ตัวเขีดเส้นใต้',
    },
  }
  return (
    <div data-test={name} className={containerClassName}>
      <section
        className={cn(
          'border-b-[1px] border-gray-3 pb-[20px] mb-[20px]',
          className
        )}
      >
        <div className='max-w-[640px] desktop:max-w-[670px] gap-x-[10px] gap-y-[5px] mobile:gap-y-[20px] flex flex-wrap items-center'>
          <TiptapButton
            icon={<CutIcon width='20' height='20' />}
            onClick={() => document.execCommand('cut')}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.cut
                : isWin
                ? tooltipLabel.isWin.cut
                : tooltipLabel.others.cut
            }
          />
          <TiptapButton
            icon={<CopyIcon width='20' height='20' />}
            onClick={() => document.execCommand('copy')}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.copy
                : isWin
                ? tooltipLabel.isWin.copy
                : tooltipLabel.others.copy
            }
          />
          <TiptapButton
            icon={<PasteIcon width='20' height='20' />}
            onClick={pasteAlert}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.paste
                : isWin
                ? tooltipLabel.isWin.paste
                : tooltipLabel.others.paste
            }
          />
          <SeparateLine />
          <TiptapButton
            icon={<UndoIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().undo().run()}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.undo
                : isWin
                ? tooltipLabel.isWin.undo
                : tooltipLabel.others.undo
            }
          />
          <TiptapButton
            icon={<RedoIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().redo().run()}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.redo
                : isWin
                ? tooltipLabel.isWin.redo
                : tooltipLabel.others.redo
            }
          />
          <SeparateLine />

          <TiptapLinkInput
            icon={<LinkIcon width='20' height='20' />}
            editor={editor}
            isActive={editor.isActive('link')}
            tooltipLabel='Add a link'
          />

          <TiptapImageInput
            icon={<ImageIcon width='20' height='20' />}
            editor={editor}
            tooltipLabel='รูปภาพ'
          />

          <TiptapIframeInput
            icon={<VideoIcon width='20' height='20' />}
            editor={editor}
            tooltipLabel='วีดีโอ'
          />
          <TiptapStickerButton
            icon={<StickerIcon width='20' height='20' />}
            editor={editor}
            tooltipLabel='สติ๊กเกอร์'
          />
          <SeparateLine />
          <TiptapButton
            icon={<QuoteIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive('blockquote')}
            tooltipLabel='Block Quote'
          />
          <div className='desktop:mr-[130px]'>
            <TiptapButton
              icon={
                <div className='flex justify-center'>
                  <SpoilIcon width='20' height='20' className='mr-[5px]' />
                  สปอยล์
                </div>
              }
              onClick={() => {
                editor.commands.insertContent(
                  '<spoiler toggle="true"></spoiler><p></p>'
                )
                editor.chain().focus('end').run()
              }}
              tooltipLabel='สปอยล์'
            />
          </div>
          <TiptapButton
            icon={<BoldIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.bold
                : isWin
                ? tooltipLabel.isWin.bold
                : tooltipLabel.others.bold
            }
          />
          <TiptapButton
            icon={<ItalicIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.italic
                : isWin
                ? tooltipLabel.isWin.italic
                : tooltipLabel.others.italic
            }
          />
          <TiptapButton
            icon={<UnderlineIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive('underline')}
            tooltipLabel={
              isMac
                ? tooltipLabel.isMac.underline
                : isWin
                ? tooltipLabel.isWin.underline
                : tooltipLabel.others.underline
            }
          />
          <TiptapButton
            icon={<StrikeThroughIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
            tooltipLabel='ตัวขีดเส้นทับ'
          />
          <TiptapButton
            icon={<SupIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleSuperscript().run()}
            isActive={editor.isActive('superscript')}
            tooltipLabel='ตัวยก'
          />
          <TiptapButton
            icon={<SubIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleSubscript().run()}
            isActive={editor.isActive('subscript')}
            tooltipLabel='ตัวห้อย'
          />
          <TiptapButton
            icon={<HorizontalLineIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            tooltipLabel='แทรกเส้นคั่นบรรทัด'
          />
          <SeparateLine />
          <TiptapButton
            icon={<NumberListIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
            tooltipLabel='ลำดับรายการแบบตัวเลข'
          />
          <TiptapButton
            icon={<BulletListIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
            tooltipLabel='ลำดับรายการแบบสัญลักษณ์'
          />
          <SeparateLine />
          <TiptapButton
            icon={<AlignLeftIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            isActive={editor.isActive({ textAlign: 'left' })}
            tooltipLabel='จัดชิดซ้าย'
          />
          <TiptapButton
            icon={<AlignCenterIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            isActive={editor.isActive({ textAlign: 'center' })}
            tooltipLabel='กึ่งกลาง'
          />
          <TiptapButton
            icon={<AlignRightIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            isActive={editor.isActive({ textAlign: 'right' })}
            tooltipLabel='จัดชิดขวา'
          />
          <TiptapButton
            icon={<AlignJustifyIcon width='20' height='20' />}
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            isActive={editor.isActive({ textAlign: 'justify' })}
            tooltipLabel='จัดชิดขอบ'
          />
          <SeparateLine />

          <TiptapColorInput
            icon={<TextColorIcon width='20' height='20' />}
            onInput={(color: ColorResult) =>
              editor.chain().focus().setColor(color.hex).run()
            }
            value={editor.getAttributes('textStyle').color}
            tooltipLabel='สีอักษร'
          />
          <TiptapColorInput
            icon={<BgColorIcon width='20' height='20' />}
            onInput={(color: ColorResult) =>
              editor.chain().focus().setHighlight({ color: color.hex }).run()
            }
            value={editor.getAttributes('highlight').color}
            tooltipLabel='สีพื้นหลัง'
          />
          <TiptapFootnoteInput
            icon={<FootnoteIcon width='20' height='20' />}
            editor={editor}
            tooltipLabel='เพิ่มเชิงอรรถ'
          />
        </div>
      </section>
      <EditorContent ref={editorRef} editor={editor} className='tiptap-edit' />
    </div>
  )
}

export default Tiptap
