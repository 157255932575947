import { withPermissionGuard } from '@libs/permission'
import { NewsAndPromotionDetailPage } from './NewsAndPromotionDetailPage'
import { withNewsAndPromotionDetailPage } from './withNewsAndPromotionDetailPage'

const ConnectedNewsAndPromotionDetailPage = withPermissionGuard(
  withNewsAndPromotionDetailPage(NewsAndPromotionDetailPage),
  'newsPromotion',
  'edit'
)

export { ConnectedNewsAndPromotionDetailPage as NewsAndPromotionDetailPage }
