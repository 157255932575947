import { Expose, Transform } from 'class-transformer'

import { GenderEnum } from '@interfaces/GenderEnum'
import { RolePermissionType } from '@models/rolePermission/RolePermissionType'
import { PermissionGuardType } from '@models/rolePermission/PermissionGuardType'
import { defaultPermissionGuard } from '@constants/permission/defaultPermission'
import { PermissionType } from '@models/rolePermission/PermissionType'

export class UserType {
  @Expose() id: number

  @Expose() avatarImgPath: string

  @Expose() bannerImgPath: string

  @Expose() idNumber: string

  @Expose()
  @Transform(({ value }) => value || '')
  fullName: string

  @Expose() role: RolePermissionType

  @Expose()
  @Transform(({ obj }) => {
    const temp = ['view', 'create', 'edit', 'delete', 'download', 'approve']
    const result = obj.role.permissions.reduce(
      (previous: PermissionGuardType, current: PermissionType) => ({
        ...previous,
        [current.name]: temp.reduce(
          (prev, cur) => ({
            ...prev,
            [cur]: current.actions.includes(cur) || false,
          }),
          {}
        ),
      }),
      defaultPermissionGuard
    )

    return result
  })
  permission: PermissionGuardType

  @Expose() email: string

  @Expose() phoneNumber: string

  @Expose()
  birthDay: string

  @Expose()
  @Transform(({ value }) =>
    typeof value === 'string' ? value.toUpperCase() : GenderEnum.OTHER
  )
  gender: GenderEnum

  @Expose() username: string

  @Expose() displayName: string
}
