export enum ChallengeCriteriaEnum {
  READER_CHAPTER = 'READER_CHAPTER',
  READER_SHELF = 'READER_SHELF',
  READER_FOLLOW = 'READER_FOLLOW',
  READER_BUY_CHAPTER = 'READER_BUY_CHAPTER',
  READER_BUY_ALL_CHAPTER = 'READER_BUY_ALL_CHAPTER',
  READER_DONATION = 'READER_DONATION',
  WRITER_VIEW = 'WRITER_VIEW',
  WRITER_LOVE = 'WRITER_LOVE',
  WRITER_BOOK = 'WRITER_BOOK',
}

export const challengeCriteriaValue = {
  [ChallengeCriteriaEnum.READER_CHAPTER]: 'อ่านนิยาย',
  [ChallengeCriteriaEnum.READER_SHELF]: 'เพิ่มนิยายเข้าชั้น',
  [ChallengeCriteriaEnum.READER_FOLLOW]: 'ติดตามผู้ใช้งานอื่น',
  [ChallengeCriteriaEnum.READER_BUY_CHAPTER]: 'ซื้องานเขียนรายตอน',
  [ChallengeCriteriaEnum.READER_BUY_ALL_CHAPTER]: 'ซื้อนิยายครบทุกตอน',
  [ChallengeCriteriaEnum.READER_DONATION]: 'Donate ให้กับตัวละคร',
  [ChallengeCriteriaEnum.WRITER_VIEW]: 'มียอดวิว',
  [ChallengeCriteriaEnum.WRITER_LOVE]: 'มียอด Love',
  [ChallengeCriteriaEnum.WRITER_BOOK]: 'เขียนจบ',
}
