import { ReportLogActionEnum } from '@interfaces/ReportLogActionEnum'
import { Expose, Type } from 'class-transformer'

class UserType {
  @Expose() id: number

  @Expose() fullName: string

  @Expose() displayName: string

  @Expose() phoneNumber: string

  @Expose() email: string

  @Expose() avatarImgPath?: string
}

export class ReportLogType {
  @Expose()
  id: number

  @Expose()
  other?: string

  @Expose()
  action: ReportLogActionEnum

  @Expose()
  @Type(() => UserType)
  actionBy: UserType

  @Expose() createdAt: string
}
