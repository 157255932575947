import { Expose, Transform, Type } from 'class-transformer'

export class UserType {
  @Expose() id: number

  @Expose() avatarImgPath: string

  @Expose() username: string

  @Expose() createdAt: string

  @Expose()
  @Transform(({ value }) => value || 0)
  penNameCount: number
}

export class ReaderDetailByUserType {
  @Expose() user: UserType

  @Expose()
  @Transform(({ value }) => value || 0)
  summaryTime: number
}
