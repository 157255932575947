import cn from 'classnames'

export function Loading({
  className,
  size = 30,
}: {
  className?: string
  size?: number
}) {
  return (
    <div
      className={cn(
        'loader ease-linear rounded-full border-2 border-t-2 border-secondary my-[20px]',
        className
      )}
      style={{ height: `${size}px`, width: `${size}px` }}
    />
  )
}
