import * as Yup from 'yup'
import cn from 'classnames'
import { plainToInstance } from 'class-transformer'
import { FieldArray, Form, Formik } from 'formik'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Container } from '@components/Container'
import { InputField } from '@components/forms/InputField'
import { BinIcon, DragIcon } from '@components/Icons'
import { CategoryManageType } from '@models/chapterBook/CategoryManageType'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CheckboxField } from '@components/forms/CheckboxField'
import { CategoryManagePageProps } from './interface'

const validationSchema = Yup.object().shape({
  categories: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
    })
  ),
})

export function CategoryManagePage({
  data,
  handleDragItem,
  onEditName,
  onSubmit,
}: CategoryManagePageProps) {
  const permission = usePermissionAction('chapterBook')
  return (
    <Container>
      <Formik
        initialValues={{
          categories: plainToInstance(CategoryManageType, data, {
            excludeExtraneousValues: true,
          }),
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, resetForm }) => (
          <Form>
            <FieldArray name='categories'>
              {({ push, remove }) => (
                <>
                  <div className='flex items-center justify-between w-full py-[20px] border-0]'>
                    <h1 className='font-mitr text-[24px] text-secondary'>
                      Category Management
                    </h1>
                    <div className='flex font-mitr gap-x-[10px] justify-center items-center px-[10px]'>
                      <button
                        type='button'
                        className={cn(
                          'flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-black-480',
                          {
                            hidden: !permission.edit,
                          }
                        )}
                        disabled={!dirty}
                        onClick={() => resetForm()}
                      >
                        <span>ยกเลิก</span>
                      </button>
                      <button
                        type='submit'
                        className={cn(
                          'flex font-mitr w-[126px] items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-primary',
                          {
                            hidden: !permission.edit,
                          }
                        )}
                        disabled={!dirty}
                      >
                        <span>บันทึก</span>
                      </button>
                      <button
                        type='button'
                        className={cn(
                          'flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-secondary',
                          {
                            hidden: !permission.create,
                          }
                        )}
                        onClick={() => {
                          push({
                            id: values.categories.length * -1,
                            name: '',
                            isManga: true,
                            isNovel: true,
                          })
                        }}
                      >
                        <span>+ เพิ่มหมวดหมู่</span>
                      </button>
                    </div>
                  </div>
                  <div className='border-[1px] border-[#eee] mt-[20px]' />
                  <div className='flex flex-col w-full '>
                    <div className='flex items-center justify-between gap-y-[20px] w-full py-[20px] border-0'>
                      <div className='flex items-center'>
                        <label className='text-secondary ml-[10px]'>
                          ทั้งหมด
                          <span className='font-bold text-secondary-100 ml-[10px]'>
                            ( {values.categories.length || 0} รายการ )
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='border-[1px] border-[#eee]' />
                    <DragDropContext
                      onDragEnd={result => {
                        handleDragItem(result, values.categories, setFieldValue)
                      }}
                    >
                      <Droppable droppableId='list'>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {values.categories.map((item, index) => (
                              <Draggable
                                draggableId={`${item.id}`}
                                index={index}
                                key={item.id}
                              >
                                {providedDragable => (
                                  <div
                                    className='flex gap-[30px] mb-[10px]'
                                    ref={providedDragable.innerRef}
                                    {...providedDragable.draggableProps}
                                    {...providedDragable.dragHandleProps}
                                  >
                                    <div className='flex items-center justify-between gap-x-[20px] text-black-480'>
                                      <span className='font-mitr font-medium text-2xl text-secondary-100 w-[30px]'>
                                        {index + 1}
                                      </span>
                                      <DragIcon
                                        width='10'
                                        className='cursor-grab'
                                      />
                                    </div>
                                    <div className='flex items-center justify-between gap-y-[20px] w-full py-[20px] border-0'>
                                      <div className='w-full pr-[30px]'>
                                        <InputField
                                          name={`categories[${index}].name`}
                                          type='text'
                                          className='disabled:bg-white'
                                          value={item.name}
                                          onClick={() => onEditName(item)}
                                          placeholder='ชื่อหมวดหมู่'
                                          showErrorMsg={false}
                                        />
                                      </div>

                                      {item.id < 0 && (
                                        <button
                                          type='button'
                                          className='flex items-center mr-[30px] justify-center text-black-970 border border-gray rounded-lg px-[4px] h-[30px] p-0'
                                          onClick={() => remove(index)}
                                        >
                                          <BinIcon
                                            width='20'
                                            height='20'
                                            className='text-secondary-100'
                                          />
                                        </button>
                                      )}
                                      <div className='flex gap-x-[40px]'>
                                        <CheckboxField
                                          name={`categories[${index}].isNovel`}
                                          label='นิยาย'
                                          checked={item.isNovel}
                                          onClick={() => onEditName(item)}
                                        />
                                        <CheckboxField
                                          name={`categories[${index}].isManga`}
                                          label='การ์ตูน'
                                          checked={item.isManga}
                                          onClick={() => onEditName(item)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div
                      className={cn('flex justify-center gap-x-[20px]', {
                        hidden: !permission.edit,
                      })}
                    >
                      <button
                        type='button'
                        className='border border-secondary-100 flex font-mitr items-center rounded-[40px] w-[100px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-secondary-100'
                        disabled={!dirty}
                        onClick={() => resetForm()}
                      >
                        <span>ยกเลิก</span>
                      </button>
                      <button
                        type='submit'
                        className='flex font-mitr w-[126px] items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-primary'
                        disabled={!dirty}
                      >
                        <span>บันทึก</span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
