import React, { useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { client } from '@client/init'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { SortByType } from '@components/Table/interface'
import { AllWriterTableProps, WithAllWriterTableProps } from './interface'

export function withAllWriterTable(Component: React.FC<AllWriterTableProps>) {
  function WithAllWriterTable({ queryParam }: WithAllWriterTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'title',
      sortOrder: 'DESC',
    })
    const [tab, setTab] = useState<BookStatusEnum>(BookStatusEnum.PUBLISHED)

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const {
      fetchNextPage,
      data: book,
      isLoading,
      isFetchingNextPage,
    } = useInfiniteQuery(
      ['book-by-user', queryParam, tab, sort],
      ({ pageParam = 1 }) =>
        client?.dashboardClient.getBookByUser({
          writerId: Number(queryParam.writerId),
          penNameId: Number(queryParam.penNameId),
          bookStatus: tab,
          page: pageParam,
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
          ...sort,
        }),
      {
        enabled: !!queryParam.writerId,
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    const { data: bookCount } = useQuery(
      ['book-count-by-user', queryParam],
      () =>
        client?.dashboardClient.getBookCountByUser({
          writerId: Number(queryParam.writerId),
          penNameId: Number(queryParam.penNameId),
        }),
      {
        enabled: !!queryParam.writerId,
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const componentProps = {
      book,
      bookCount,
      handleSort,
      tab,
      setTab,
      handleFetchNextPage,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithAllWriterTable
}
