import { withPermissionGuard } from '@libs/permission'
import { TopPurchasePage } from './TopPurchasePage'
import { withTopPurchasePage } from './withTopPurchasePage'

const ConnectedTopPurchasePage = withPermissionGuard(
  withTopPurchasePage(TopPurchasePage),
  'homePageManagement',
  'view'
)

export { ConnectedTopPurchasePage as TopPurchasePage }
