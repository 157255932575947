import { Container } from '@components/Container'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'

import { Tabs } from '@components/TabsV2'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { TabModeEnum } from '@components/Tabs/interface'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { ReportStory } from './components/ReportStory'
import { ReportListPageProps } from './interface'

export function ReportListPage({
  activeTab,
  onActiveTabChange,
}: ReportListPageProps) {
  const permission = usePermissionAction('chapterBook')
  return (
    <Container>
      <div className='flex items-center justify-between w-full py-[20px] border-0]'>
        <h1 className='font-mitr text-[24px] text-secondary'>Report List</h1>
      </div>

      <section className='relative'>
        <Tabs
          tabs={
            SHOW_EBOOK_FEATURE
              ? [
                  {
                    key: ReportBookEnum.STORY,
                    title: 'รายงานเนื้อหา',
                    component: (
                      <ReportStory
                        key={ReportBookEnum.STORY}
                        activeTab={activeTab}
                      />
                    ),
                  },
                  {
                    key: ReportBookEnum.EBOOK,
                    title: 'รายงานอีบุ๊ก',
                    component: (
                      <ReportStory
                        key={ReportBookEnum.EBOOK}
                        activeTab={activeTab}
                      />
                    ),
                  },
                ]
              : [
                  {
                    key: ReportBookEnum.STORY,
                    title: 'รายงานเนื้อหา',
                    component: (
                      <ReportStory
                        key={ReportBookEnum.STORY}
                        activeTab={activeTab}
                      />
                    ),
                  },
                ]
          }
          mode={TabModeEnum.BOX}
          initialTab={ReportBookEnum.STORY}
          handleTabChange={onActiveTabChange}
          queryTabKey='tab'
        />
      </section>
    </Container>
  )
}
