import React from 'react'

import { Container } from '@components/Container'
import { MainBannerForm } from '@features/home/components/MainBannerForm'
import { EditMainBannerPageProps } from './interface'

export function EditMainBannerPage({
  initialValues,
  onSubmit,
}: EditMainBannerPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between'>
        <Container.Title showBackBtn>Edit Banner</Container.Title>
      </section>

      <MainBannerForm
        initialValues={initialValues}
        isEdit
        onSubmit={onSubmit}
      />
    </Container>
  )
}
