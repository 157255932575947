import cn from 'classnames'
import { DateTime } from 'luxon'

import { Loading } from '@components/Loading'
import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import PromotionChallengeInfo from '@features/promotionSetting/components/PromotionChallengeInfo'
import CorrectionDetail from './components/CorrectionDetail'
import { PromotionApproveSection } from '../PromotionSettingPage/components/PromotionApproveSection'
import { HistoryPromotionChallengePageProps } from './interface'

function HistoryPromotionChallengePage({
  historyList,
  historyData,
  listLoading,
  dataLoading,
  selectedId,
  handleChangeSelectedId,
}: HistoryPromotionChallengePageProps) {
  return (
    <Container>
      {!listLoading ? (
        historyList.length >= 1 ? (
          <>
            <Container.Title showBackBtn>
              <div className='flex items-center justify-between'>
                <div>ดูประวัติการเปลี่ยนโปรโมชั่น</div>
              </div>
            </Container.Title>

            <div className='flex'>
              <div className='w-[170px] text-sm font-bold flex flex-col items-start space-y-5 max-h-[70vh] overflow-y-auto'>
                {historyList.map(item => (
                  <Button
                    key={item.id}
                    onClick={() => {
                      handleChangeSelectedId(item.id)
                    }}
                    className={cn('px-0 py-0 !h-max font-sarabun font-bold', {
                      'text-secondary': item.id === selectedId,
                      'text-secondary-100': item.id !== selectedId,
                    })}
                  >
                    <span>
                      {DateTime.fromISO(item.createdAt)
                        .setLocale('th')
                        .toFormat('dd  LLL yyyy - HH:mm:ss')}
                    </span>
                  </Button>
                ))}
              </div>
              <div className='ml-[30px] border-l pl-[50px] flex-1 overflow-y-auto'>
                {dataLoading ? (
                  <Loading size={36} className='m-auto' />
                ) : historyData ? (
                  <>
                    <Card>
                      <PromotionChallengeInfo
                        status={historyData.oldPromotion.status}
                        detail={historyData.newPromotion}
                      />
                    </Card>
                    <PromotionApproveSection
                      queryKey={[
                        'promotion',
                        'challenge',
                        selectedId,
                        'history',
                      ]}
                      promotionType={SettingPromotionMenuTypeEnum.CHALLENGE}
                      promotionId={historyData.newPromotion.id}
                      requestId={historyData.newPromotion.lastRequest?.id}
                      promotionName={historyData.newPromotion.name || ''}
                      promotionDetail={historyData.newPromotion.description}
                      promotionRequestStatus={historyData.status}
                      actionBy={{
                        imageUrl: historyData.createdBy.avatarImgPath,
                        displayName: historyData.createdBy.displayName,
                        actionAt: historyData.createdAt,
                        actionType: historyData.action,
                      }}
                      startPromotion={
                        new Date(historyData.newPromotion.startPublishedAt!)
                      }
                      endPromotion={
                        new Date(historyData.newPromotion.endPublishedAt!)
                      }
                      createdAt={new Date(historyData.createdAt)}
                      formDetailUrl={`/promotion-setting/promotion-challenge/${historyData.newPromotion.id}/form-detail`}
                      disabled={
                        historyData.id !==
                        historyData.newPromotion.lastRequest?.id
                      }
                    />

                    {historyData.correctionDetail && (
                      <CorrectionDetail data={historyData.correctionDetail!} />
                    )}
                    {historyData.rejectedReason && (
                      <div className='pt-[30px]'>
                        <div className='text-xs text-secondary-100 font-light'>
                          ส่งข้อความถึงผู้ดำเนินการ
                        </div>
                        <div className='text-sm font-light'>
                          {historyData.rejectedReason}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง'
                )}
              </div>
            </div>
          </>
        ) : (
          <div>เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}

export default HistoryPromotionChallengePage
