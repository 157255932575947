import { BookEnum } from '@interfaces/BookEnum'
import { Expose } from 'class-transformer'

export class EbookDashboardByUserType {
  @Expose() id: number

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() ebookType: BookEnum

  @Expose() penName: string

  @Expose() totalSales: number

  @Expose() downloadedCount: number

  @Expose() viewCount: number

  @Expose() wishlistCount: number

  @Expose() readTrialChapterCount: number

  @Expose() reviewCount: number
}
