import { useNavigate } from 'react-router-dom'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { BookStorySettingFormType } from '@models/bookStorySetting/BookStorySettingFormType'
import { BookStorySettingAddPageProps } from './interface'

export function withBookStorySettingAddPage(
  Component: React.FC<BookStorySettingAddPageProps>
) {
  function WithBookStorySettingAddPage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()

    async function handleSubmit(values: BookStorySettingFormType) {
      try {
        await client?.bookStorySettingClient.createBookStorySetting(values)
        alert.success('เพิ่มแบบฟอร์มสำเร็จ')
        navigate({
          pathname: '/chapter-book/book-story-setting',
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    const pageProps = {
      handleSubmit,
    }
    return <Component {...pageProps} />
  }

  return WithBookStorySettingAddPage
}
