import React from 'react'
import { DonationSearchFormType } from '@interfaces/dashboard/DonationSearchFormType'
import { SearchSectionProps } from './interface'

const withSearchSection = (Component: React.FC<SearchSectionProps>) => {
  function WithSearchSection({ handleSubmit, ...props }: SearchSectionProps) {
    function handleSubmitForm(form: DonationSearchFormType) {
      if (form.interval || (form.startDate && form.endDate)) {
        handleSubmit(form)
      }
    }
    const componentsProps = {
      ...props,
      handleSubmit: handleSubmitForm,
    }

    return <Component {...componentsProps} />
  }

  return WithSearchSection
}

export { withSearchSection }
