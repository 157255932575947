import { withPermissionGuard } from '@libs/permission'
import { DashboardLayout } from './DashboardLayout'
import { withDashboardLayout } from './withDashboardLayout'

const ConnectedDashboardLayout = withPermissionGuard(
  withDashboardLayout(DashboardLayout),
  'dashboard',
  'view'
)

export { ConnectedDashboardLayout as DashboardLayout }
