import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByHashtagType {
  @Expose() tag: string

  @Expose() totalSearch: number
}

@Exclude()
export class SearchResultByHashtagResponse {
  @Expose()
  @Type(() => SearchResultByHashtagType)
  data: SearchResultByHashtagType[]

  @Expose() page: number
}
