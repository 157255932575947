import React, { useCallback, useRef } from 'react'
import cn from 'classnames'
import { Editor } from '@tiptap/react'
import { useAlert } from '@hooks/useAlert'
import Tooltip from '@reach/tooltip'
import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'

interface TiptapImageInputProps {
  icon: React.ReactNode
  editor: Editor
  tooltipLabel?: string
}

const TiptapImageInput = React.memo(function TiptapImage({
  icon,
  editor,
  tooltipLabel,
}: TiptapImageInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const alert = useAlert()
  const client = useClient()

  const handleUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target

      if (files) {
        try {
          const imagePath = await client?.fileClient.uploadFile(files[0])
          editor
            .chain()
            .focus()
            .setImage({ src: imagePath || '' })
            .run()
        } catch (error) {
          const message = getErrorMessage(error)
          alert.error(message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function showUploadDialog() {
    const event = inputRef.current
    if (event) {
      event.click()
    }
  }

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            type='button'
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
            )}
            onClick={showUploadDialog}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          type='button'
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
          )}
          onClick={showUploadDialog}
        >
          {icon}
        </button>
      )}

      <input
        ref={inputRef}
        type='file'
        className='hidden'
        accept='image/*'
        onChange={handleUpload}
      />
    </div>
  )
})

export default TiptapImageInput
