import cn from 'classnames'
import { Box } from '@components/Box'
import { Loading } from '@components/Loading'
import { Tab, TabBody, TabHeader, Tabs } from '@components/Tabs'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { ByUserProps } from './interface'
import ByUserTopSection from '../ByUserTopSection'
import { ReaderBadge } from '../ReaderBadge'
import { ReaderByUserSearchForm } from '../ReaderByUserSearchForm'
import { ReadSection } from '../ReadSection'
import { ShelfSeciton } from '../ShelfSeciton'
import { CommentSection } from '../CommentSection'
import { BarChartByUser } from '../BarChartByUser'
import { EbookSection } from '../EbookSection'

function ByUser({
  handleExport,
  handleReset,
  handleSearch,
  readerDetail,
  readerCoinDetail,
  queryParam,
  isLoading,
}: ByUserProps) {
  return (
    <>
      <ReaderByUserSearchForm
        initialValues={{
          userId: queryParam.userId,
          timeRange: TimeRangeEnum.MONTH,
          startDate: undefined,
          endDate: undefined,
        }}
        disableExport={!queryParam.userId}
        handleSubmit={handleSearch}
        handleReset={handleReset}
        handleExport={handleExport}
      />
      {isLoading ? (
        <Loading size={36} className='m-auto mt-[80px]' />
      ) : readerDetail && readerCoinDetail ? (
        <Box className={cn('shadow border-blue-2')}>
          <ByUserTopSection
            queryParam={queryParam}
            readerDetail={readerDetail}
            readerCoinDetail={readerCoinDetail}
          />
          <div className='flex items-end gap-x-[40px] mb-[20px]'>
            <div className='text-secondary flex-1 text-2xl font-medium font-mitr'>
              Rank & Badge
            </div>
          </div>
          <Tabs initialActiveTab='reader' mode='line'>
            <Tab tabId='reader'>
              <TabHeader>
                <span>Reader</span>
              </TabHeader>
              <TabBody>
                <ReaderBadge queryParam={queryParam} challenge='READER' />
              </TabBody>
            </Tab>
            <Tab tabId='writer'>
              <TabHeader>
                <span>Writer</span>
              </TabHeader>
              <TabBody>
                <ReaderBadge queryParam={queryParam} challenge='WRITER' />
              </TabBody>
            </Tab>
          </Tabs>
          <BarChartByUser queryParam={queryParam} />
          <ReadSection queryParam={queryParam} />
          <ShelfSeciton queryParam={queryParam} />
          {SHOW_EBOOK_FEATURE && <EbookSection queryParam={queryParam} />}
          <CommentSection queryParam={queryParam} />
        </Box>
      ) : (
        <Box className={cn('shadow border-blue-2')}>
          <p className='text-center'>ค้นหานักอ่าน ที่ต้องการดูข้อมูล</p>
        </Box>
      )}
    </>
  )
}

export default ByUser
