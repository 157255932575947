import { withPermissionGuard } from '@libs/permission'
import { CreateHighlightBannerBookPage } from './CreateHighlightBannerBookPage'
import { withCreateHighlightBannerBookPage } from './withCreateHighlightBannerBookPage'

const Connected = withPermissionGuard(
  withCreateHighlightBannerBookPage(CreateHighlightBannerBookPage),
  'homePageManagement',
  'create'
)

export { Connected as CreateHighlightBannerBookPage }
