import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TextAreaField } from '@components/forms/TextAreaField'
import { SelectField } from '@components/forms/SelectField'
import { Button } from '@components/Button'
import { Avatar } from '@components/Avatar'
import { PunishmentPeriodEnum } from '@interfaces/user/PunishmentPeriodEnum'
import { BanUserModalProps } from './interface'

const banRangeOption = [
  { value: PunishmentPeriodEnum.TWELVE_HOURS, label: '12 ชม.' },
  { value: PunishmentPeriodEnum.THREE_DAYS, label: '3 วัน' },
  { value: PunishmentPeriodEnum.SEVEN_DAYS, label: '7 วัน' },
  { value: PunishmentPeriodEnum.THIRTY_DAYS, label: '30 วัน' },
  { value: PunishmentPeriodEnum.FOREVER, label: 'ตลอดกาล (200 ปี)' },
]

const validationMessageWarningSchema = Yup.object().shape({
  message: Yup.string().required(),
})

export function BanUserModal({
  data,
  user,
  isWarning,
  onConfirm,
  onClose,
  onHistory,
}: BanUserModalProps) {
  return (
    <div className='w-[616px] pt-[40px] pb-[30px] px-[30px]'>
      <div className='text-center text-[24px] font-mitr text-secondary font-medium'>
        {isWarning ? 'ตักเตือนผู้ใช้งาน' : 'แบนผู้ใช้งาน'}
      </div>
      <hr className='border-t-[1px] my-[20px] border-gray' />
      <div className='flex justify-between'>
        <div className='flex items-center'>
          <div className='pr-[20px]'>
            <img
              src={data.avatarImgPath || '/images/avatar.png'}
              alt=''
              className='w-[38px] h-[38px] rounded-full'
            />
          </div>
          <div className='flex gap-x-[60px] text-secondary font-light text-[14px]'>
            <div>
              <span className='text-secondary-100 font-bold text-[12px]'>
                User ID
              </span>
              <p>{data.id || '-'}</p>
            </div>
            <div>
              <span className='text-secondary-100 font-bold text-[12px]'>
                ชื่อ - นามสกุล
              </span>
              <p>{data.fullName || '-'}</p>
            </div>
            <div>
              <span className='text-secondary-100 font-bold text-[12px]'>
                ชื่อผู้ใช้
              </span>
              <p>{data.username || '-'}</p>
            </div>
          </div>
        </div>
        <div>
          <Button
            type='button'
            className='border border-secondary text-secondary text-[14px]'
            onClick={onHistory}
          >
            {isWarning ? 'ประวัติการตักเตือน' : 'ดูประวัติการแบน'}
          </Button>
        </div>
      </div>
      <hr className='border-t-[1px] my-[20px] border-gray' />
      <Formik
        initialValues={{
          period: PunishmentPeriodEnum.TWELVE_HOURS,
          message: '',
        }}
        onSubmit={onConfirm}
        validationSchema={validationMessageWarningSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='flex flex-col space-y-[20px]'>
              {!isWarning && (
                <div className='w-[230px]'>
                  <SelectField
                    name='period'
                    label='ระยะเวลาการแบน'
                    options={banRangeOption}
                  />
                </div>
              )}
              <TextAreaField
                textAreaClassName='h-[120px] placeholder:text-secondary-100'
                name='message'
                label={
                  isWarning
                    ? 'ส่งข้อความตักเตือนถึงผู้ใช้งาน'
                    : 'ระบุเหตุผลในการแบน'
                }
                placeholder={isWarning ? 'พิมพ์ข้อความ' : 'ระบุเหตุผลในการแบน'}
                maxLength={200}
              />

              {!isWarning && (
                <hr className='border-t-[1px] my-[20px] border-gray' />
              )}

              <div className='flex justify-between text-[14px] font-medium mt-[30px]'>
                <div className='flex gap-x-[10px] items-center'>
                  <span className='text-[14px] text-secondary-100 font-bold'>
                    ดำเนินการโดย :
                  </span>
                  {user.avatarImgPath ? (
                    <Avatar
                      className='w-[50px] h-[50px]'
                      src={user.avatarImgPath}
                      alt=''
                    />
                  ) : (
                    <div className='w-[50px] h-[50px] rounded-full  bg-gray' />
                  )}
                  <span className='text-[14px] text-secondary font-bold'>
                    {user.displayName || '-'}
                  </span>
                </div>
                <div className='flex space-x-[20px]'>
                  <Button
                    type='button'
                    className='w-[100px] border border-blue-3 text-blue-3 text-[14px]'
                    onClick={onClose}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type='submit'
                    className=' w-[112px] text-white bg-primary'
                    disabled={isSubmitting}
                  >
                    {isWarning ? 'ส่ง' : 'แบนผู้ใช้งาน'}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
