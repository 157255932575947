import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { AgeConfirmRejectFormType } from '@models/ageConfirmation/AgeConfirmRejectFormType'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import {
  AgeConfirmRejectModalProps,
  WithAgeConfirmRejectModalProps,
} from './interface'

export function withAgeConfirmRejectModal(
  Component: React.FC<AgeConfirmRejectModalProps>
) {
  function WithAgeConfirmRejectModal({
    userId,
    ...props
  }: WithAgeConfirmRejectModalProps) {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const rejectModal = useModal({ modal: 'writerReject' })

    async function handleSubmit(value: AgeConfirmRejectFormType) {
      try {
        await client?.ageConfirmClient.verifyAge({
          userId: Number(userId),
          ageVerify: AgeVerifyEnum.REJECTED,
          ageVerifyRejectedReason: value.message,
        })
        rejectModal.hide()
        alert.success('ดำเนินการสำเร็จ')
        navigate('/user/age-confirmation')
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ ได้สำเร็จ!')
      }
    }

    function handleCancel() {
      rejectModal.hide()
    }

    const componentProps = {
      ...props,
      userId,
      handleSubmit,
      handleCancel,
    }
    return <Component {...componentProps} />
  }

  return WithAgeConfirmRejectModal
}
