import { withPermissionGuard } from '@libs/permission'
import { CoverManage } from '@features/chapterBook/components/CoverManage'

const ConnectedCoverEditPage = withPermissionGuard(
  CoverManage,
  'chapterBook',
  'edit'
)

export { ConnectedCoverEditPage as CoverEditPage }
