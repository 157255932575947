import { CharacterSectionProps } from './interface'
import { columns } from './components/column'
import { DonationTemplate } from '../DonationTemplate'

export function CharacterSection({
  data,
  handleSort,
  handleFetchNextPage,
  isLoading,
}: CharacterSectionProps) {
  return (
    <DonationTemplate
      title='Top rank donation by character'
      data={data}
      columns={columns}
      isLoading={isLoading}
      handleSort={handleSort}
      onWaypointEnter={handleFetchNextPage}
    />
  )
}
