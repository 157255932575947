import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { columns } from './column'
import { TopWriterBySalesTableProps } from './interface'

export function TopWriterBySalesTable({
  data,
  isLoading,
  handleSort,
}: TopWriterBySalesTableProps) {
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        Top writer by sales
      </h1>

      <Table
        onSort={handleSort}
        isLoading={isLoading}
        data={data}
        columns={columns}
        initialSort={{ key: 'moneyReceived', order: SortingType.DESC }}
      />
    </div>
  )
}
