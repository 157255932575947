import React from 'react'
import cn from 'classnames'
import { UploadCover } from '../UploadCover'
import { UploadCoverFieldProps } from './interface'

export function UploadCoverField({
  touched,
  error,
  className,
  ...props
}: UploadCoverFieldProps) {
  return (
    <UploadCover
      {...props}
      className={cn({ 'border-red border-[1px]': touched && error }, className)}
    />
  )
}
