import React from 'react'
import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/PromotionStatusEnum'

import {
  PromotionChallengeApproveSectionAcceptProps,
  PromotionChallengeApproveSectionProps,
} from './interface'

export function withPromotionChallengeApproveSection(
  Component: React.FC<PromotionChallengeApproveSectionProps>
) {
  function Hoc({
    requestId,
    promotionId,
    promotionName,
    startPromotion,
    endPromotion,
    createdAt,
    promotionDetail,
    ...props
  }: PromotionChallengeApproveSectionAcceptProps) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryKey: QueryKey = ['promotion', 'challenge', promotionId]

    function handleGoToFormDetailPromotionChallenge() {
      navigate(
        `/promotion-setting/promotion-challenge/${promotionId}/form-detail`
      )
    }

    const { mutateAsync: approvePromotionChallenge } = useMutation(
      (promotionRequestId: number) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status: PromotionStatusEnum.APPROVED,
        }),
      {
        onSuccess: () => {
          alert.success(`ดำเนินการสำเร็จ`)
          queryClient.invalidateQueries(queryKey)
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
        },
      }
    )

    function handleApprove() {
      if (requestId) {
        approvePromotionChallenge(requestId)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId,
        promotionId,
        promotionName,
        startPromotion,
        endPromotion,
        createdAt,
        promotionDetail,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
        },
      })
    }
    const newProps = {
      ...props,
      handleApprove,
      handleReject,
      handleGoToFormDetailPromotionChallenge,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
