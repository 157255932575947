import React from 'react'

import { MainBannerFormProps } from './interface'

export function withHighlightBannerForm(
  Component: React.FC<MainBannerFormProps>
) {
  function WithHighlightBannerForm({ ...props }: MainBannerFormProps) {
    return <Component {...props} />
  }

  return WithHighlightBannerForm
}
