import { useMemo } from 'react'
import { DateTime } from 'luxon'
import cn from 'classnames'

import { ColumnType, SortingType } from '@components/Table/interface'
import { Search } from '@components/Search'
import { BinIcon, ReloadIcon } from '@components/Icons'
import { SelectDropdown } from '@components/SelectDropdown/SelectDropdown'
import { Checkbox } from '@components/Checkbox'
import { Pagination } from '@components/Pagination'
import { PendingListAdminType } from '@models/admin/PendingListAdminType'
import { Table } from '@components/Table'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PendingInvitationPageProps } from './interface'

export function PendingInvitationPage({
  data,
  page,
  perpage,
  total,
  selectedIds,
  roleOptions,
  pageChange,
  handleSearch,
  handleResetSearch,
  handleSelectRow,
  handleSelectAll,
  handleDeleteUsers,
  handleChangeRole,
  handleSort,
  resendInvitation,
  isLoading,
}: PendingInvitationPageProps) {
  const permission = usePermissionAction('admin')
  const columns: ColumnType<PendingListAdminType>[] = useMemo(() => {
    return [
      {
        column: (
          <Checkbox
            checked={
              selectedIds.length > 0 && selectedIds.length === data.length
            }
            onChange={event =>
              handleSelectAll((event.target as HTMLInputElement).checked)
            }
          />
        ),
        actionCell: ({ obj }) => (
          <Checkbox
            checked={selectedIds.includes(obj.id)}
            onChange={() => handleSelectRow(obj.id)}
          />
        ),
        isAction: true,
        cellClassName: cn('w-[60px] pr-[10px]', {
          hidden: !permission.delete,
        }),
      },
      {
        column: 'ส่งเมื่อ',
        accessor: 'confirmationSentAt',
        cell: ({ value }) => (
          <p className='font-mitr'>
            {value
              ? DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
                  locale: 'th-Th',
                })
              : '-'}
          </p>
        ),
        isSortable: true,
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'อีเมล',
        accessor: 'user.email',
        cell: ({ value }) => (
          <p className='font-mitr line-clamp-1 break-word'>{value}</p>
        ),
        isSortable: true,
        sortKey: 'email',
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'Role',
        accessor: 'role.id',
        cell: ({ value, obj }) => (
          <SelectDropdown
            defualtInputColor='bg-blue-2 border-none'
            inputClassName='font-light'
            className='w-[232px]'
            disabled={!permission.edit}
            value={String(value)}
            options={roleOptions}
            handleSelect={rolesId => handleChangeRole(obj.id, Number(rolesId))}
          />
        ),
        isSortable: true,
        sortKey: 'name',
        cellClassName: 'pr-[10px] py-[9px]',
      },
      {
        column: 'ส่งอีกครั้ง',
        actionCell: ({ obj }) => (
          <button
            className='bg-blue-6 w-[30px] h-[30px] rounded-[8px] p-[4px]'
            type='button'
            onClick={() => resendInvitation(obj.id)}
          >
            <ReloadIcon className='text-primary' width='20' height='20' />
          </button>
        ),
        isAction: true,
        cellClassName: cn('w-[120px] pr-[10px]', {
          hidden: !permission.edit,
        }),
      },
      {
        column: 'ลบ',
        actionCell: ({ obj }) => (
          <button
            type='button'
            className='border border-gray w-[30px] h-[30px] bg-white rounded-[8px] p-[4px]'
            onClick={() => handleDeleteUsers(obj.id)}
          >
            <BinIcon className='text-secondary-100' width='20' height='20' />
          </button>
        ),
        isAction: true,
        cellClassName: cn('max-w-[40px] w-[40px]', {
          hidden: !permission.edit,
        }),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, roleOptions, selectedIds])

  return (
    <div className='flex flex-col gap-y-[30px] mt-[30px]'>
      <Search
        placeholder='ค้นหาด้วยชื่อ, ID หรือ email'
        onSearch={handleSearch}
        onReset={handleResetSearch}
      />
      <div className='border-b border-gray' />
      <div className='flex justify-between'>
        <div className='font-light text-secondary-100'>
          จำนวนที่พบ :{' '}
          <span className='font-bold text-secondary'>
            {total.toLocaleString()}
          </span>{' '}
          รายการ
        </div>
        <button
          type='button'
          className={cn(
            'rounded-[20px] px-5 py-[5px] text-sm font-medium font-mitr text-red bg-[#feeeef] disabled:opacity-30',
            {
              hidden: !permission.delete,
            }
          )}
          onClick={() => handleDeleteUsers(selectedIds)}
          disabled={selectedIds.length === 0}
        >
          ลบผู้ใช้งาน
        </button>
      </div>
      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'confirmationSentAt',
          order: SortingType.DESC,
        }}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
      />
      <Pagination
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
      />
    </div>
  )
}
