import { ChallengeStatus } from '@interfaces/user-mission/ChallengeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { ChallengeLevelType } from './ChallengeLevelType'

import { UserMissionType } from './UserMissionType'

class UserType {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath: string
}

export class UserMissionLogsType {
  @Expose() id: number

  @Expose() userId: number

  @Expose() challengeId: number

  @Expose() level: number

  @Expose() levelName: string

  @Expose() newLevelName: string

  @Expose() target: number

  @Expose() newTarget: number

  @Expose() iconUrl: string

  @Expose() isActive: boolean

  @Expose() createdAt: string

  @Expose() type: ChallengeStatus

  @Expose()
  @Type(() => UserMissionType)
  challenge: UserMissionType

  @Expose()
  @Type(() => UserType)
  user: UserType

  @Expose()
  @Transform(({ value }) => value ?? [])
  @Type(() => ChallengeLevelType)
  challengeLevelLogs: ChallengeLevelType[]
}
