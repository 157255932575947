import { Box } from '@components/Box'
import { useWriterDetail } from '../hooks/useWriterDetail'
import { ImageViewer } from './ImageViewer'
import { TextInput } from './TextInput'

export function BankAccountInformation() {
  const { writer } = useWriterDetail()
  return (
    <div>
      <Box.Title>ข้อมูลบัญชีธนาคาร</Box.Title>

      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <TextInput
          label='เลือกธนาคาร'
          value={writer?.bankAccount?.bankName || ''}
        />
        <TextInput
          label='หมายเลขบัญชี'
          placeholder='กรอกไม่เว้นวรรค และไม่ใส่ขีด (-)'
          value={writer?.bankAccount?.accountNumber || ''}
        />
        <TextInput
          label='ชื่อบัญชี'
          placeholder='กรอกชื่อบัญชี'
          value={writer?.bankAccount?.accountName || ''}
        />
        <TextInput
          label='ประเภทบัญชี'
          placeholder='เลือกประเภทบัญชี'
          value={writer?.bankAccount?.accountType || ''}
        />
        <TextInput
          label='สาขา'
          placeholder='กรอกสาขา'
          value={
            `${writer?.bankAccount?.bankBranchCode} (${writer?.bankAccount?.bankBranch})` ||
            ''
          }
        />
        <TextInput
          label='จังหวัด'
          placeholder='จังหวัด'
          value={writer?.bankAccount?.bankProvince || ''}
        />
        <ImageViewer
          className='w-[200px] h-[150px]'
          label='รูปภาพหน้าบัญชีธนาคาร (ไฟล์ pdf, ไฟล์ภาพ jpg หรือ png)'
          src={writer?.bankAccount?.bankCoverImgPath || ''}
          name={writer?.bankAccount?.bankCoverImgPath || ''}
        />
      </div>
    </div>
  )
}
