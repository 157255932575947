import { EyeIcon } from '@components/Icons'
import { PenNameListType } from '@models/user/PenNameListType'
import { Link } from 'react-router-dom'

export function ActionCell({ data }: { data: PenNameListType }) {
  return (
    <Link
      to={{
        pathname: '/dashboard/writer-publisher',
        search: `?tab=byUser&writerId=${data.writerId}&penNameId=${data.id}&userId=${data.userId}`,
      }}
      target='_blank'
    >
      <div className='flex items-center'>
        <button
          className='border border-gray p-[5px] bg-white rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </div>
    </Link>
  )
}
