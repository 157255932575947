import { BookEnum } from '@interfaces/BookEnum'
import { Expose, Transform } from 'class-transformer'

export class TopBookType {
  @Expose({ name: 'bookId' }) id: string

  @Expose({ name: 'bookCoverImgPath' }) coverImgPath: string

  @Expose({ name: 'bookTitle' }) title: string

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  bookType: BookEnum

  @Expose({ name: 'firstPenName' }) penName: string

  @Expose({ name: 'bookChapterCount' }) chapterCount: number

  @Expose({ name: 'bookViewCount' }) viewCount: number

  @Expose({ name: 'bookLikeCount' }) likeCount: number

  @Expose({ name: 'bookTotalSales' })
  @Transform(({ value }) => (value ? Number(value) : 0))
  totalSales: number

  @Expose({ name: 'rowNumber' }) numRow: number
}
