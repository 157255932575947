import { Expose, Type } from 'class-transformer'

import { BookType } from '@models/book/BookType'

export class HighlightBookBannerType {
  @Expose() id: number

  @Expose() bookId: number

  @Expose() runningNo: number

  @Expose() isOnWeb: boolean

  @Expose() isOnIos: boolean

  @Expose() isOnAndroid: boolean

  @Expose() rawLink: string

  @Expose() linkWeb: string

  @Expose() linkIos: string

  @Expose() linkAndroid: string

  @Expose() isActive: boolean

  @Expose()
  @Type(() => BookType)
  book: BookType

  @Expose() isEbook: boolean
}
