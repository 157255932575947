import { gql } from 'graphql-request'

export const UPDATE_COIN_REQUEST_STATUS = gql`
  mutation UpdateCoinRequestStatus(
    $updateCoinRequestStatusInput: UpdateCoinRequestStatusInput!
  ) {
    updateCoinRequestStatus(
      updateCoinRequestStatusInput: $updateCoinRequestStatusInput
    ) {
      id
    }
  }
`
