import React, { useState } from 'react'
import cn from 'classnames'
import { usePortalDropdown } from '@hooks/useDropdown'
import { Editor } from '@tiptap/react'
import Tooltip from '@reach/tooltip'
import TiptapDropdownInput from './TiptapDropdownInput'

interface TiptapIframeInputProps {
  icon: React.ReactNode
  editor: Editor
  tooltipLabel?: string
}

export default function TiptapIframeInput({
  icon,
  editor,
  tooltipLabel,
}: TiptapIframeInputProps) {
  const [url, setUrl] = useState('')
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            type='button'
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
            )}
            onClick={e => {
              toggleDropdown(e)
              setUrl('')
            }}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          type='button'
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
          )}
          onClick={e => {
            toggleDropdown(e)
            setUrl('')
          }}
        >
          {icon}
        </button>
      )}
      <Dropdown>
        <TiptapDropdownInput
          className={cn({ hidden: !isShowDropdown })}
          title='Video Url'
          onComfirm={() => {
            let link = ''
            if (url.includes('watch?v=')) {
              link = url.replace('watch?v=', 'embed/')
            } else if (url.includes('youtu.be')) {
              link = `https://www.youtube.com/embed/${url.match('\\w+$')}`
            }

            editor.chain().focus().setIframe({ src: link }).run()
          }}
          value={url}
          onChange={setUrl}
          toggleDropdown={toggleDropdown}
        />
      </Dropdown>
    </div>
  )
}
