import * as Yup from 'yup'

import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required('จำเป็น'),
    description: Yup.string().required('จำเป็น'),
    userTerm: Yup.string().required('จำเป็น'),
    startPublishedAt: Yup.date().when('publishedType', {
      is: (publishedType: PromotionPublishedEnum) =>
        publishedType === PromotionPublishedEnum.PERIOD,
      then: Yup.date().required(),
    }),
    endPublishedAt: Yup.date().required(),
    categoryIds: Yup.array().when('conditionType', {
      is: (conditionType: PSConditionTypeEnum) =>
        conditionType === PSConditionTypeEnum.ALL,
      then: Yup.array().min(1).required(),
    }),
    actionValue: Yup.number().min(0.1, ' ').required('จำเป็น'),
    coinConditionIds: Yup.array().min(1).required(),
    usageDay: Yup.array().min(1).required(),
    benefitValue: Yup.number()
      .min(0.1, ' ')
      .required('จำเป็น')
      .max(Yup.ref('budget')),
    benefitCoinId: Yup.number().required('จำเป็น'),
    benefitLimitType: Yup.string().required('จำเป็น'),
    benefitLimitValue: Yup.number().when('benefitLimitType', {
      is: (benefitLimitType: PromotionChallengeLimitTypeEnum) =>
        [
          PromotionChallengeLimitTypeEnum.PER_DAY,
          PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
        ].includes(benefitLimitType),
      then: Yup.number().min(0.1, ' ').required(),
    }),
    budget: Yup.number().min(0.1, ' ').required(),
    isLimitBudget: Yup.boolean(),
    budgetPerDay: Yup.number().when('isLimitBudget', {
      is: (isLimitBudget: boolean) => isLimitBudget,
      then: Yup.number().min(0.1, ' ').required().max(Yup.ref('budget')),
    }),
  })
  .test((values, context) => {
    if (
      values.conditionType !== PSConditionTypeEnum.ALL &&
      !values.termIdInput &&
      !values.conditionFile &&
      !values.conditionFileError
    ) {
      return context.createError({
        message: 'จำเป็น',
        path: 'termIdInput',
      })
    }
    if (values.termIdInputError) {
      return context.createError({
        message: values.termIdInputError,
        path: 'termIdInput',
      })
    }
    if (values.conditionFileError) {
      return context.createError({
        path: 'conditionFile',
        message: values.conditionFileError,
      })
    }

    return true
  })
