import { useRef, useState } from 'react'
import Cropper from 'cropperjs'
import { useAlert } from './useAlert'

interface CropImageProps {
  aspectRatio?: number
  maxSize: number
  onChange: (file: Blob | null) => void
}

export function useCropImage({
  aspectRatio,
  maxSize,
  onChange,
}: CropImageProps) {
  const cropper = useRef<Cropper>()
  const imgRef = useRef<HTMLImageElement>(null)
  const [uploadUrl, setUploadUrl] = useState('')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const filename = useRef('')
  const filenameTemp = useRef('')
  const fileTypeRef = useRef<string>('image/jpeg')
  const alert = useAlert()

  function hideModal() {
    setVisible(false)
    setUploadUrl('')
  }

  function zoom(val: number) {
    cropper.current?.zoom(val)
  }

  function move(x: number, y: number) {
    const multiply = 10
    cropper.current?.move(x * multiply, y * multiply)
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target
    if (files && files[0].size / (1000 * 1000) < maxSize) {
      if (files[0].type === 'image/gif') {
        onChange(files[0])
      } else {
        const reader = new FileReader()
        filenameTemp.current = files[0].name
        reader.onload = (readerEvent: any) => {
          if (readerEvent.target?.result) {
            setUploadUrl(readerEvent.target.result)
            if (imgRef.current) {
              cropper.current?.destroy()
              cropper.current = new Cropper(imgRef.current, {
                aspectRatio,
                viewMode: 1,
              })
            }
          }
        }
        reader.readAsDataURL(files[0])
        fileTypeRef.current = files[0].type
        setVisible(true)
      }
    } else {
      alert.error(`ไฟล์ภาพมีขนาดเกินกำหนด (${maxSize} MB)`)
    }
  }

  function cropImage() {
    setLoading(true)
    if (cropper.current) {
      filename.current = filenameTemp.current
      cropper.current?.getCroppedCanvas().toBlob(async (blob: Blob | null) => {
        onChange(blob)
        hideModal()
        setLoading(false)
      }, fileTypeRef.current)
    }
  }

  return {
    imgRef,
    uploadUrl,
    filename: filename.current,
    visible,
    loading,
    hideModal,
    handleFileChange,
    cropImage,
    zoom,
    move,
  }
}
