import { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { DurationEnum } from '@interfaces/DurationEnum'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { OverallPageProps, SearchType } from './interface'

export function withOverallPage(Component: React.FC<OverallPageProps>) {
  function WithOverallPage() {
    const client = useClient()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      typeDuration: DurationEnum.MONTHLY,
    })

    const { data } = useQuery(['story-dashboard', searchParams], () =>
      client?.dashboardClient.getStory({
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
      })
    )

    function handleSearch(
      startDate: DateTime,
      endDate: DateTime,
      keyType: DurationEnum
    ) {
      setSearchParams({
        startDate,
        endDate,
        typeDuration: keyType,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        typeDuration: DurationEnum.MONTHLY,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportStoryDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      data,
      searchParams,
      handleSearch,
      handleReset,
      handleExport,
    }
    return <Component {...componentProps} />
  }

  return WithOverallPage
}
