import { Expose, Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'

export class GraphDataType {
  @Expose() year: number

  @Expose()
  @Transform(
    ({ value, obj }) => `${DateTime.now()
      .set({ month: value })
      .toFormat('LLL', { locale: 'th-TH' })
      .toString()} ${obj.year}
  `
  )
  month: string

  @Expose() earnWriterCount: number

  @Expose() freeWriterCount: number
}

export class WriterFreeVsEarnType {
  @Expose() totalEarnWriter: number

  @Expose() totalFreeWriter: number

  @Expose()
  @Type(() => GraphDataType)
  graphData: GraphDataType[]
}
