import { Box } from '@components/Box'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import { PopularTextSearchSectionProps } from '@features/dashboard/pages/SearchPage/components/PopularSearchTextSection/interface'
import { SearchInput } from '@components/SearchInput'
import cn from 'classnames'
import { OrderByEnum } from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { useNavigate } from 'react-router-dom'
import ReactECharts from 'echarts-for-react'
import { EChartsOption } from 'echarts-for-react/lib/types'
import { PopularBookSearchLog } from '@models/dashboard/PopularBookSearchLogs'

export function PopularSearchTextSection({
  data,
  isLoading,
  handleWaypointEnter,
  handleSortChange,
  onSearch,
  onReset,
  inputRef,
  handleOpenGraph,
  popularBookGraph,
  selectedGraphSearchText,
}: PopularTextSearchSectionProps) {
  const navigate = useNavigate()
  const items = data?.pages.flatMap(row => row?.data) || []
  const option: EChartsOption = {
    grid: {
      top: 10,
      bottom: 30,
      left: 50,
      right: 30,
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        return `${params[0].seriesName}<br />${
          params[0].axisValue
        }: <strong>${params[0].value.toLocaleString()}</strong>`
      },
    },
    xAxis: {
      type: 'category',
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      lineStyle: {
        color: 'red',
        type: 'solid',
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
        },
      },
    },
    series: [
      {
        data: popularBookGraph?.map(item => item.totalSearch),
        type: 'line',
        name: 'จำนวนการค้นหา',
        showSymbol: false,
        lineStyle: {
          width: 1,
          color: '#3f54d9',
        },
      },
    ],
    textStyle: {
      color: '#858baf',
    },
  }

  return (
    <Box className='w-full'>
      <Box.Title>คำค้นหายอดนิยม</Box.Title>
      <SearchInput
        ref={inputRef}
        className={cn('flex-1 text-primary')}
        placeholder='ค้นหาคำค้นที่ต้องการ'
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onSearch((event.target as HTMLInputElement).value)
          }
        }}
        onReset={onReset}
      />
      <div className='mt-5 grid grid-cols-2 gap-x-14'>
        <div>
          <Table
            className='mb-[20px] max-h-[250px] pr-[10px]'
            isLoading={isLoading}
            data={items}
            columns={[
              {
                column: 'คำค้นหายอดนิยม',
                accessor: 'searchText',
                cell: ({ value }) => (
                  <div
                    onClick={() => {
                      handleOpenGraph(value)
                    }}
                    className={cn('line-clamp-1 break-word cursor-pointer', {
                      'font-bold text-primary':
                        selectedGraphSearchText === value,
                    })}
                  >
                    {value}
                  </div>
                ),
                cellClassName: 'py-[10px] pr-[10px]',
              },
              {
                column: 'จำนวนการค้นหา (ครั้ง)',
                accessor: 'totalPopularSearch',
                cell: ({ value, obj }) => (
                  <div
                    className={cn('text-right', {
                      'font-bold text-primary':
                        selectedGraphSearchText === obj.searchText,
                    })}
                  >
                    {value}
                  </div>
                ),
                cellClassName: 'py-[10px] w-[150px] pr-[10px]',
                sortKey: OrderByEnum.SEARCH_COUNT,
                isSortable: true,
              },
            ]}
            initialSort={{
              key: OrderByEnum.SEARCH_COUNT,
              order: SortingType.DESC,
            }}
            onSort={handleSortChange}
            onWaypointEnter={handleWaypointEnter}
            fixedHeader
          />
        </div>
        {selectedGraphSearchText && (
          <div>
            <div className='text-blue-3 text-[12px] font-bold py-[10px]'>
              คำค้นหา :{' '}
              <span className='text-primary'>{selectedGraphSearchText}</span>
            </div>
            <ReactECharts
              option={option}
              style={{ width: '100%', height: '225px' }}
              notMerge
              lazyUpdate
            />
          </div>
        )}
      </div>
      <span
        className='font-mitr text-[14px]  cursor-pointer
      '
        onClick={() => {
          navigate('/search-management/history')
        }}
      >
        ดูรายการค้นหาทั้งหมด
      </span>
    </Box>
  )
}
