import { gql } from 'graphql-request'

export const GET_BANNED_BOOK_TOP_CHARTS = gql`
  query FindBannedBookTopCharts($limitPerPage: Int, $page: Int) {
    findBannedBookTopCharts(limitPerPage: $limitPerPage, page: $page) {
      page
      total
      data {
        id
        currentRank
        previousRank
        rankChange
        viewCount
        likeCount
        isBan
        banReason
        book {
          title
          id
          coverImgPath
          penName {
            user {
              id
            }
            firstPenName
            id
          }
          category {
            id
            name
          }
          bookType
        }
      }
    }
  }
`
