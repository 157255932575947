import cn from 'classnames'

import { ChevronUpIcon } from '@components/Icons'
import { AccordionProps } from './interface'

export function Accordion({
  title,
  children,
  disabled,
  className,
  titleClassName,
  height,
  rotate,
  contentSpace,
  toggleAccordion,
}: AccordionProps) {
  return (
    <div className='flex flex-col'>
      <div className={titleClassName}>
        {title}
        <button
          type='button'
          disabled={disabled}
          className={cn(
            className,
            'box-border text-secondary-100 appearance-none cursor-pointer focus:outline-none flex items-center justify-between',
            {
              'cursor-no-drop opacity-40': disabled,
            }
          )}
          onClick={toggleAccordion}
        >
          <ChevronUpIcon className={cn('text-primary', rotate)} />
        </button>
      </div>

      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className='overflow-auto transition-max-height duration-300 ease-in-out'
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  className: 'bg-gray-50 h-[60px] py-[18px] px-5 mt-[1px]',
}
