import cn from 'classnames'
import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { TextInputField } from '@components/forms/TextInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { FieldArray, Form, Formik } from 'formik'
import { SettingCard } from './components/SettingCard'
import { BookStorySettingPageProps } from './interface'

export function BookStorySettingPage({
  bookStorySettings,
  navigate,
  handleSubmit,
  showConfirmRemoveModal,
}: BookStorySettingPageProps) {
  const permission = usePermissionAction('chapterBook')
  const confirmRemoveModal = useModal({ modal: 'confirm' })

  return (
    <Container>
      <Formik initialValues={{ bookStorySettings }} onSubmit={handleSubmit}>
        {({ values, isSubmitting, dirty }) => (
          <Form>
            <div className='flex items-center justify-between w-full py-[20px] border-0]'>
              <h1 className='font-mitr text-[24px] text-secondary'>
                Book Story Setting
              </h1>
              <div
                className={cn(
                  'flex font-mitr gap-x-[10px] justify-center items-center px-[10px]',
                  {
                    hidden: !permission.edit && !permission.create,
                  }
                )}
              >
                <Button
                  className={cn('text-black-480', {
                    hidden: !permission.edit,
                  })}
                  type='reset'
                  disabled={!dirty}
                >
                  <span>ยกเลิก</span>
                </Button>
                <Button
                  type='submit'
                  className={cn('w-[126px] text-white bg-primary', {
                    hidden: !permission.edit,
                  })}
                  disabled={!dirty}
                >
                  <span>บันทึก</span>
                </Button>
                <Button
                  type='button'
                  className={cn('text-white bg-secondary', {
                    hidden: !permission.create,
                  })}
                  onClick={() =>
                    navigate({
                      pathname: '/chapter-book/book-story-setting/create',
                    })
                  }
                >
                  <span>+ เพิ่มฟอร์มใหม่</span>
                </Button>
              </div>
            </div>
            <div className='border-[1px] border-[#eee] mt-[20px] mb-[30px]' />
            {values.bookStorySettings.length > 0 ? (
              <FieldArray
                name='bookStorySettings'
                render={arrayHelpers => (
                  <div className='flex flex-col gap-y-[30px]'>
                    {values.bookStorySettings.map((item, index) => {
                      if (!item.isDeleted && item.id) {
                        return (
                          <SettingCard
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            component={
                              <TextInputField
                                className='text-secondary'
                                name={`bookStorySettings[${index}].maxCharacter`}
                                disabled={!permission.edit}
                                label='จำกัดจำนวนตัวอักษร'
                                rightIcon={
                                  <p className='text-secondary-100'>ตัวอักษร</p>
                                }
                                type='number'
                              />
                            }
                            canDelete={item.deleteable && permission.delete}
                            canEdit={item.deleteable && permission.edit}
                            onDelete={() => {
                              showConfirmRemoveModal(() => {
                                arrayHelpers.replace(index, {
                                  ...item,
                                  isDeleted: true,
                                })
                              })
                            }}
                          />
                        )
                      }

                      return null
                    })}
                  </div>
                )}
              />
            ) : (
              <div
                className={cn(
                  'flex border border-dashed border-[#bdbdbd] py-[70px] justify-center rounded-[8px] mb-[30px]',
                  {
                    hidden: !permission.create,
                  }
                )}
              >
                <Button
                  type='button'
                  className='text-white bg-black-480'
                  onClick={() =>
                    navigate({
                      pathname: '/chapter-book/book-story-setting/create',
                    })
                  }
                >
                  + เพิ่มฟอร์มใหม่
                </Button>
              </div>
            )}
            <div
              className={cn('flex justify-center mt-[30px]', {
                hidden: !permission.edit,
              })}
            >
              <Button
                className='text-black-480'
                type='reset'
                disabled={isSubmitting || !dirty}
              >
                ยกเลิก
              </Button>
              <Button
                type='submit'
                className='w-[126px] text-white bg-primary'
                disabled={isSubmitting || !dirty}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
