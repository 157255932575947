import { Expose, Type } from 'class-transformer'

import { SearchTermType } from './SearchTermType'

export class ManageSearchTermType {
  @Expose({ name: 'banKeywords' })
  @Type(() => SearchTermType)
  banSearchTermList: SearchTermType[]

  @Expose({ name: 'fiveWordRecommendKeyWord' })
  @Type(() => SearchTermType)
  recommendSearchTermList: SearchTermType[]
}
