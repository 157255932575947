import { Formik, Form } from 'formik'

import { Button } from '@components/Button'
import { TextInputField } from '@components/forms/TextInputField'
import { validationSchema } from '@features/home/pages/HighlightBannerPage/components/CreateHighlightBannerPage/validationSchema'
import { MainBannerFormProps } from './interface'

export function HighlightBannerForm({
  initialValues,
  onSubmit,
}: MainBannerFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        resetForm({ values: { bannerName: '' } })
        onSubmit(values)
      }}
      validationSchema={validationSchema}
    >
      {({ resetForm, dirty, isValid, isSubmitting }) => (
        <Form>
          <TextInputField
            name='bannerName'
            label='Banner Name'
            placeholder='Banner Name'
          />
          <section className='flex justify-center gap-x-5 my-[30px]'>
            <Button
              type='button'
              className='border border-secondary-100 text-sm text-secondary-100 min-w-[100px]'
              onClick={() => resetForm()}
            >
              ยกเลิก
            </Button>
            <button
              type='submit'
              className='border border-none rounded-full bg-primary font-mitr text-sm text-white min-w-[100px] disabled:opacity-40 disabled:cursor-not-allowed'
              disabled={!(dirty && isValid && !isSubmitting)}
            >
              ถัดไป
            </button>
          </section>
        </Form>
      )}
    </Formik>
  )
}
