import { gql } from 'graphql-request'

export const GET_BOOK_CHAPTER_BY_BOOK = gql`
  query GetBookChapterByBook(
    $bookId: Int
    $page: Int
    $limitPerPage: Int
    $orderBy: BookChapterOrderBy
  ) {
    getBookChapterByBook(
      bookId: $bookId
      page: $page
      limitPerPage: $limitPerPage
      orderBy: $orderBy
    ) {
      data {
        chapterName
        chapterTitle
        id
        viewCount
        runningNo
        totalSales
        purchasedCount
      }
      total
      page
    }
  }
`
