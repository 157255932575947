/* eslint-disable max-classes-per-file */
import { Expose } from 'class-transformer'
import { GenderEnum } from '@interfaces/GenderEnum'

export class SearchHistoryType {
  @Expose() id: string

  @Expose() createdAt: string

  @Expose() searchText: string

  @Expose() user: UserType
}

class UserType {
  @Expose() id: string

  @Expose() displayName: string

  @Expose() age: number

  @Expose() gender: GenderEnum
}
