import { DateTime } from 'luxon'
import { useEffect, useRef } from 'react'

import { REST_API_URL } from '@configs/config'

export interface WriterTermsOfServiceModalProps {
  consentId: number
  userId: number
  adminId: number
  content: string
  title: string
  version: string
  submittedDate: string
  writersConsentId: number
}

export function WriterTermsOfServiceModal({
  consentId,
  userId,
  adminId,
  content,
  title,
  version,
  submittedDate,
  writersConsentId,
}: WriterTermsOfServiceModalProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClick(event: any) {
      event.target.classList.toggle('is-active')
      const contentElem = event.target.nextElementSibling
      if (event.target.classList.contains('is-active')) {
        contentElem.style.display = 'block'
      } else {
        contentElem.style.display = 'none'
      }
    }
    const elems = document.querySelectorAll('div.consent-collapsible')
    elems.forEach(elem => {
      elem.addEventListener('click', handleClick)
    })

    return () => {
      if (elems) {
        elems.forEach(elem => {
          elem.removeEventListener('click', handleClick)
        })
      }
    }
  }, [title, content])

  return (
    <>
      <div className='w-[800px] py-[30px]'>
        <h2 className='font-mitr text-[24px] text-secondary font-medium px-[30px]'>
          {title}
        </h2>
        <p className='font-sarabun text-[12px] text-secondary-1 font-bold my-[10px] px-[30px]'>
          version {version}
        </p>
        {submittedDate && (
          <p className='font-sarabun text-[12px] text-secondary-1 font-light my-[10px] px-[30px]'>
            Submitted Date :{' '}
            {DateTime.fromISO(submittedDate).toFormat('dd LLL yyyy', {
              locale: 'th-Th',
            })}
          </p>
        )}
        <div
          className='h-[380px] text-[14px] text-secondary font-light mt-[10px] overflow-y-auto pl-[30px] pr-[20px]'
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <div className='w-full mt-[10px] text-center'>
          <a
            className='text-[12px] hover:text-blue'
            href={`${REST_API_URL}/pdpa/download-writer-consent-by-admin?userId=${userId}&adminId=${adminId}&documentId=${consentId}&createdAt=${submittedDate}&writerConsentId=${writersConsentId}`}
            target='_blank'
            rel='noreferrer'
          >
            ดาวน์โหลดเอกสาร
          </a>
        </div>
      </div>
    </>
  )
}
