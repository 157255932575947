import { gql } from 'graphql-request'

export const GET_PROMOTION_SALE_FORM = gql`
  query PromotionCode($promotionId: Float!) {
    promotion(id: $promotionId) {
      promotionSale {
        budget
        coinsCondition {
          id
        }
        actionValue
        benefitCoinId
        benefitLimitType
        benefitLimitValue
        benefitValue
        budgetPerDay
        categories {
          id
        }
        conditionFileName
        conditionFilePath
        conditionType
        id
        termCount
        terms {
          termId
        }
        useCoinConditionType
      }
      id
      name
      description
      userTerm
      startPublishedAt
      endPublishedAt
      publishedType
      usageDay
      status
      isActive
      isEditable
      createdAt
      updatedAt
      deletedAt
      businessId
      lastRequest {
        status
        action
      }
    }
  }
`
