import { gql } from 'graphql-request'

export const GET_CATEGORY_SALE_REPORT_LIST = gql`
  query GetProfitPerCategory(
    $writerId: Int
    $bookTypes: [BookType!]
    $categoryIds: [Int!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getProfitPerCategory(
      writerId: $writerId
      bookTypes: $bookTypes
      categoryIds: $categoryIds
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      totalSales
    }
  }
`
