import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'
import { ChevronDownIcon, ClockIcon } from '@components/Icons'
import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { formatDateTime } from '@libs/utils'
import { usePortalDropdown } from '@hooks/useDropdown'
import { SelectStatusProps } from './interface'

const statusOptions = [
  { label: 'เผยแพร่', value: ChapterStatusEnum.PUBLISHED },
  { label: 'ปิดเนื้อหา', value: ChapterStatusEnum.RESTRICTED },
  { label: 'ไม่เผยแพร่', value: ChapterStatusEnum.UNPUBLISHED },
]

function SelectStatus({
  id,
  value,
  className,
  publishedAt,
  showPublishedAt,
  disabled,
  handleSelect,
}: SelectStatusProps) {
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  return (
    <div className={cn('relative', className)}>
      <button
        type='button'
        disabled={disabled}
        className={cn(
          'w-full h-[30px] border border-gray-border rounded-lg text-[14px] px-[10px] text-secondary cursor-pointer',
          {
            'cursor-no-drop opacity-40': disabled,
          }
        )}
        onClick={toggleDropdown}
      >
        <div className='flex h-full items-center justify-between'>
          <div className='flex items-center gap-x-[10px]'>
            {showPublishedAt ? (
              <ClockIcon />
            ) : (
              <div
                className={cn('h-[10px] w-[10px] rounded-full', {
                  'bg-primary': value === ChapterStatusEnum.PUBLISHED,
                  'border border-[#bdbdbd]':
                    value === ChapterStatusEnum.RESTRICTED,
                  'bg-secondary-100': value === ChapterStatusEnum.UNPUBLISHED,
                })}
              />
            )}
            {showPublishedAt ? (
              <div className='line-clamp-1'>{formatDateTime(publishedAt)}</div>
            ) : (
              statusOptions?.find(row => row.value === value)?.label
            )}
          </div>
          <ChevronDownIcon width='16' height='16' />
        </div>
        {isShowDropdown && (
          <Dropdown>
            <div className='w-[150px] bg-white shadow-md border border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow'>
              {statusOptions.map((row, index) => (
                <div
                  className='flex items-center py-[10px] px-[10px] gap-x-[10px] text-sm hover:text-primary hover:bg-[#f2f9ff]'
                  key={index}
                  onClick={_ => {
                    if (row.value === ChapterStatusEnum.PUBLISHED) {
                      handleSelect(id, row.value, DateTime.local())
                    } else {
                      handleSelect(id, row.value)
                    }
                  }}
                >
                  <div
                    className={cn('h-[10px] w-[10px] rounded-full', {
                      'bg-primary': row.value === ChapterStatusEnum.PUBLISHED,
                      'border border-[#bdbdbd]':
                        row.value === ChapterStatusEnum.RESTRICTED,
                      'bg-secondary-100':
                        row.value === ChapterStatusEnum.UNPUBLISHED,
                    })}
                  />
                  {row.label}
                </div>
              ))}
            </div>
          </Dropdown>
        )}
      </button>
    </div>
  )
}

export default SelectStatus
