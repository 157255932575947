import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'

import { MainMenuType } from '@models/mainMenu/mainMenuType'
import { MAIN_MENUS } from './schemas/mainMenus'
import { UPDATE_MAIN_MENU } from './schemas/updateMainMenu'
import { UPDATE_MAIN_MENUS } from './schemas/updateMainMenus'

export class MainMenuClient {
  constructor(private client: GraphQLClient) {}

  async mainMenus(): Promise<MainMenuType[]> {
    const { mainMenus } = await this.client.request(MAIN_MENUS)
    return plainToInstance(MainMenuType, mainMenus as [])
  }

  async updateMainMenu(form: {
    id: number
    runningNo?: number
    name?: string
  }): Promise<void> {
    await this.client.request(UPDATE_MAIN_MENU, {
      updateMainMenuInput: {
        id: form.id,
        runningNo: form.runningNo,
        name: form.name,
      },
    })
  }

  async updateMainMenus(
    form: {
      id: number
      runningNo?: number
      name?: string
    }[]
  ) {
    await this.client.request(UPDATE_MAIN_MENUS, {
      updateMainMenusInput: form,
    })
  }
}
