import { SelectButton } from '@components/SelectButton'
import { Container } from '@components/Container'
import { DashboardLayoutProps } from './interface'

export function DashboardLayout({
  children,
  dashboardTabOption,
  tab,
  handleChangeTab,
}: DashboardLayoutProps) {
  return (
    <Container>
      <div className='flex items-center justify-between w-full py-[20px] border-0'>
        <h1 className='font-mitr text-[24px] text-secondary'>Dashboard</h1>
      </div>
      <div className='flex mt-[30px] mb-[30px]'>
        <SelectButton
          options={dashboardTabOption}
          value={tab}
          className='flex-wrap gap-y-[15px]'
          onChange={handleChangeTab}
        />
      </div>
      <div>{children}</div>
    </Container>
  )
}
