import cn from 'classnames'
import { Container } from '@components/Container'
import { SelectButton } from '@components/SelectButton'
import { SalesLayoutProps, SalesPathType } from './interface'

export function SalesLayout({
  children,
  tab,
  salesTabOption,
  handleChangeTab,
}: SalesLayoutProps) {
  return (
    <Container>
      <div className='flex items-center justify-between w-full py-[20px] border-0]'>
        <h1 className='font-mitr text-[24px] text-secondary'>Sales</h1>
      </div>
      <SelectButton
        options={salesTabOption}
        value={tab}
        className='flex-wrap gap-y-[15px]'
        onChange={handleChangeTab}
      />
      <div>{children}</div>
    </Container>
  )
}
