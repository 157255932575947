import { WriterFreeVsEarnType } from '@models/dashboard/WriterFreeVsEarnType'
import { DateTime } from 'luxon'
import React from 'react'

import { OverviewAcceptProps, OverviewProps } from './interface'

export function withOverview(Component: React.FC<OverviewProps>) {
  function WithOverview(props: OverviewAcceptProps) {
    function setGraphData(data: WriterFreeVsEarnType) {
      return [
        {
          title: 'Earn Writer',
          color: '#3f54d9',
          data: data.graphData.map(item => ({
            year: item.year,
            name: item.month,
            value: item.earnWriterCount,
          })),
        },
        {
          title: 'Free Writer',
          data: data.graphData.map(item => ({
            year: item.year,
            name: item.month,
            value: item.freeWriterCount,
          })),
        },
      ]
    }
    const componentProps = {
      ...props,
      setGraphData,
    }
    return <Component {...componentProps} />
  }

  return WithOverview
}
