import React from 'react'
import cn from 'classnames'

import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon } from '@components/Icons'
import { SelectFieldProps } from './interface'

export function SelectField({
  label,
  options,
  value,
  className,
  placeholder,
  disabled,
  error,
  touched,
  labelClassName,
  setValue,
  maxHeight = 400,
}: SelectFieldProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className={cn('relative select-none', className)}>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 font-light mb-[4px]',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <div
        className={cn(
          'w-full border-[1px] border-[#eeeeee] rounded-[8px] text-[14px] text-[#484848] font-medium px-[10px] py-[11px] cursor-pointer',
          {
            'cursor-default pointer-events-none': disabled,
            'bg-[#f7f7f7]': disabled,
            'border-[#f7575f]': touched && error,
            'border-primary': isShowDropdown,
          }
        )}
        onClick={toggleDropdown}
      >
        <div className='flex items-center justify-between font-light'>
          {value ? (
            <p className='text-secondary truncate px-[5px] leading-[1.5]'>
              {options?.find(row => row.value === value)?.label}
            </p>
          ) : (
            <p className='text-secondary-100 px-[5px] leading-[1.5]'>
              {placeholder}
            </p>
          )}

          <ChevronDownIcon
            className={cn('text-primary shrink-0 ')}
            width='16'
            height='16'
          />
        </div>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-full z-10 mt-[13px] right-0 bg-white  border-1 border-gray rounded-[10px]  overflow-y-auto overflow-x-hidden shadow-[0_3px_12px_0_rgba(51,51,51,0.05)]'
            style={{ maxHeight }}
          >
            <div className='my-[10px]'>
              {options.map((menu, index) => (
                <div
                  className='font-light py-[10px] px-[20px] hover:text-primary hover:bg-blue-8'
                  key={index}
                  onClick={() => setValue(menu.value)}
                >
                  {menu.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
