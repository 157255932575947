import { gql } from 'graphql-request'

export const EBOOK_DASHBOARD_BY_USER = gql`
  query EbookDashboardByUser(
    $limitPerPage: Int
    $page: Int
    $startDate: DateTime
    $endDate: DateTime
    $userId: Int
    $orderBy: EbookDashboardByTitleOrderBy
  ) {
    ebookDashboardByUser(
      limitPerPage: $limitPerPage
      page: $page
      startDate: $startDate
      endDate: $endDate
      userId: $userId
      orderBy: $orderBy
    ) {
      data {
        id
        coverImgPath
        title
        ebookType
        penName
        totalSales
        downloadedCount
        viewCount
        wishlistCount
        readTrialChapterCount
        reviewCount
      }
      total
      page
    }
  }
`
