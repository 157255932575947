import { SessionHistoryAcceptProps, SessionHistoryProps } from './interface'

export function withSessionHistory(Component: React.FC<SessionHistoryProps>) {
  function WithSessionHistory({ sessionHistory }: SessionHistoryAcceptProps) {
    const componentProps = {
      data: sessionHistory.data,
      keyType: sessionHistory.keyType,
    }
    return <Component {...componentProps} />
  }

  return WithSessionHistory
}
