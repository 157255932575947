import React from 'react'
import { useField } from 'formik'
import { PromotionRadioInputFieldProps } from './interface'

export function withPromotionRadioInputField(
  Component: React.FC<PromotionRadioInputFieldProps>
) {
  function WithPromotionRadioInputField({
    name,
    value,
    ...props
  }: PromotionRadioInputFieldProps) {
    const [field] = useField(name)
    const componentProps = {
      ...props,
      ...field,
      value,
    }

    return <Component {...componentProps} />
  }

  return WithPromotionRadioInputField
}
