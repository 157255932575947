import { useRef } from 'react'
import cn from 'classnames'

import { useDraggable } from '@hooks/draggableScroll/useDraggable'
import { OptionType } from '@interfaces/OptionType'
import { SelectButtonProps } from './interface'

export function SelectButton({
  className,
  options,
  value,
  label,
  mode = 'box',
  buttonClassName,
  onChange,
}: SelectButtonProps) {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref)

  return (
    <div className='text-[14px] text-secondary-100 font-light mobile:overflow-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
      {label && <div className='font-light text-[12px] mb-[5px]'>{label}</div>}
      <div
        className={cn(
          'flex overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar',
          {
            'gap-x-[15px]': mode === 'box',
            'bg-gray-50 rounded-[8px]': mode === 'line',
          },
          className
        )}
        {...events}
        ref={ref}
      >
        {options.map((option: OptionType) => (
          <div
            className={cn(
              'flex px-[20px] py-[9px] items-center justify-center whitespace-pre cursor-pointer rounded-[8px] h-[40px] w-max',
              {
                'bg-secondary text-white': value === option.value,
                'bg-blue-2 text-secondary-100': value !== option.value,
              },
              buttonClassName
            )}
            key={`${option.value}`}
            onClick={() => onChange && onChange(option.value)}
          >
            {option.icon && <div className='mr-[10px]'>{option.icon}</div>}
            {option.text}
          </div>
        ))}
      </div>
    </div>
  )
}
