import React from 'react'
import { useFormikContext } from 'formik'

import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { DAY, diffDate } from '@libs/utils'
import { Checkbox } from '@components/Checkbox'
import { NewsAndPromotionFormType } from '@models/newsAndPromotion/NewsAndPromotionFormType'

export function NewAndPromotionPublishing() {
  const { values, setValues, setFieldValue } =
    useFormikContext<NewsAndPromotionFormType>()

  const currentDate = new Date()

  const responseStartDiffCurrentDate = diffDate(
    values.startPublishedAt,
    currentDate
  )
  const responseStartDiffEndDate = diffDate(
    values.startPublishedAt,
    values.endPublishedAt
  )
  const responseEndDiffStartDate = diffDate(
    values.endPublishedAt,
    values.startPublishedAt
  )
  return (
    <div className='flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap'>
      <div className='text-[12px]'>เผยแพร่ :</div>
      <Checkbox
        name='publishedType'
        label='ทันที'
        value={PublishedEnum.IMMEDIATELY}
        checked={values.publishedType === PublishedEnum.IMMEDIATELY}
        onChange={() => {
          setValues({
            ...values,
            publishedType: PublishedEnum.IMMEDIATELY,
            startPublishedAt: new Date(),
            endPublishedAt: new Date(Date.now() + DAY * 30),
          })
        }}
      />
      <Checkbox
        name='publishedType'
        label='ตั้งเวลาเผยแพร่'
        type='radio'
        value={PublishedEnum.CUSTOM}
        checked={values.publishedType === PublishedEnum.CUSTOM}
        onChange={() => {
          setFieldValue('publishedType', PublishedEnum.CUSTOM)
        }}
      />
      <DateTimeInputField
        className='w-[180px]'
        name='startPublishedAt'
        placeholder='ตั้งแต่วันที่'
        disabled={values.publishedType === PublishedEnum.IMMEDIATELY}
        showTimeSelect
        minDate={currentDate}
        maxDate={values?.endPublishedAt}
        minTime={
          responseStartDiffCurrentDate < 0
            ? values.startPublishedAt
            : responseStartDiffCurrentDate === 0
            ? currentDate
            : responseStartDiffEndDate === 0
            ? new Date(new Date().setHours(0, 0, 0, 0))
            : undefined
        }
        maxTime={
          responseStartDiffCurrentDate < 0
            ? values.startPublishedAt
            : responseStartDiffEndDate === 0
            ? new Date(new Date(values.endPublishedAt))
            : responseStartDiffCurrentDate === 0
            ? new Date(new Date().setHours(23, 59, 59, 999))
            : undefined
        }
        onChange={value => {
          if (value) {
            const startPublishedAt =
              value < currentDate
                ? currentDate
                : value < values.endPublishedAt
                ? value
                : values.endPublishedAt
            setFieldValue('startPublishedAt', startPublishedAt)
          }
        }}
      />

      <div className='text-[14px]'>ถึง</div>
      <DateTimeInputField
        className='w-[180px]'
        name='endPublishedAt'
        placeholder='ถึงวันที่'
        disabled={values.publishedType === PublishedEnum.IMMEDIATELY}
        showTimeSelect
        minDate={
          values.startPublishedAt < currentDate
            ? currentDate
            : values.startPublishedAt
        }
        minTime={
          responseEndDiffStartDate <= 0 ? values.startPublishedAt : undefined
        }
        maxTime={
          responseEndDiffStartDate <= 0
            ? new Date(
                new Date(values.startPublishedAt).setHours(23, 59, 59, 999)
              )
            : undefined
        }
        onChange={value => {
          if (value) {
            const endPublishedAt =
              value < values.startPublishedAt ? values.startPublishedAt : value
            setFieldValue('endPublishedAt', endPublishedAt)
          }
        }}
      />
    </div>
  )
}
