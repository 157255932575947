import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { plainToInstance } from 'class-transformer'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { defaultPermissionForm } from '@constants/permission/defaultPermission'
import { useAuthentication } from '@hooks/useAuthentication'
import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'
import { EditRolePageProps } from './interface'

export function withEditRolePage(Component: React.FC<EditRolePageProps>) {
  function WithEditRolePage() {
    const client = useClient()
    const alert = useAlert()
    const { isAuthenticated } = useAuthentication()
    const navigate = useNavigate()
    const { id } = useParams()

    const { data: roleData, isLoading } = useQuery(
      ['role', id],
      () =>
        client?.adminClient.getRole(Number(id)).then(res => {
          const newArray = {
            name: res.name,
            permissions: defaultPermissionForm.permissions.map(
              obj => res.permissions.find(o => o.name === obj.name) || obj
            ),
          }

          return plainToInstance(RolePermissionFormType, newArray)
        }),
      { enabled: !!id && isAuthenticated }
    )

    async function updateRoleHandler(value: RolePermissionFormType) {
      try {
        await client?.adminClient.updateRole(value, Number(id))
        alert.success('แก้ไข Role สำเร็จ')
        navigate('/admin/role-permission-setting')
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถแก้ไข Role ได้สำเร็จ!')
      }
    }

    if (!roleData || isLoading) return null

    const newProps = {
      data: roleData,
      updateRoleHandler,
    }
    return <Component {...newProps} />
  }

  return WithEditRolePage
}
