import cn from 'classnames'

import { OptionType } from '@interfaces/OptionType'

interface SelectButtonProps {
  options: OptionType[]
  value: any
  className?: string
  label?: string
}

export function SelectButton({
  className,
  options,
  value,
  label,
}: SelectButtonProps) {
  return (
    <div className='text-[14px] text-secondary-100 font-light'>
      {label && <div className='font-light text-[12px] mb-[5px]'>{label}</div>}
      <div
        className={cn(
          'flex bg-gray-50 rounded-[8px] border-[1px] border-gray-border w-max',
          className
        )}
      >
        {options.map((option: OptionType) => (
          <div
            className={cn(
              'flex px-[40px] py-[9px] items-center justify-center whitespace-pre rounded-[8px] h-[40px] w-max',
              {
                'bg-secondary text-white font-bold': value === option.value,
                'bg-blue-2 text-secondary-100': value !== option.value,
              }
            )}
            key={`${option.value}`}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  )
}
