import ReactECharts from 'echarts-for-react'
import { Box } from '@components/Box'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { DateTime } from 'luxon'
import { StorageUsageProps } from './interface'

export function StorageUsage({
  data,
  keyType,
  dataSize,
  dateStart,
  dateEnd,
}: StorageUsageProps) {
  const option = {
    grid: {
      top: 10,
      bottom: 30,
      left: 30,
      right: 30,
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        return `${params[0].seriesName}<br />${
          params[0].axisValue
        }: <strong>${params[0].value.toLocaleString()}</strong>`
      },
    },
    xAxis: {
      type: 'category',
      data: data.map(item => item.value),
      axisLabel: {
        formatter: (value: number) => {
          switch (keyType) {
            case KeyTypeEnum.DAY:
              return DateTime.now()
                .set({ hour: value, minute: 0 })
                .toFormat('HH:mm', { locale: 'th-TH' })
            case KeyTypeEnum.MONTH:
              return value
            case KeyTypeEnum.YEAR:
              return DateTime.now()
                .set({ month: value })
                .toFormat('LLL', { locale: 'th-TH' })
            default:
              return value
          }
        },
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      lineStyle: {
        color: 'red',
        type: 'solid',
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
        },
      },
    },
    series: [
      {
        data: data.map(item => item.size),
        type: 'line',
        name: 'สถิติการใช้พื้นที่เก็บข้อมูล',
        showSymbol: false,
        lineStyle: {
          width: 1,
          color: '#3f54d9',
        },
      },
    ],
    textStyle: {
      color: '#858baf',
    },
  }

  return (
    <div className='w-full pt-[30px]'>
      <div className='flex items-center justify-between'>
        <Box.Title className='font-medium text-secondary'>
          สถิติการใช้พื้นที่เก็บข้อมูล
        </Box.Title>
        <div className='text-[14px] text-secondary-100 font-light'>
          <span>จำนวนข้อมูลที่เก็บ : &nbsp;</span>
          <span className='text-black-480 font-medium'>{dataSize} &nbsp;</span>
          {(dateStart && dateEnd) !== null ? (
            <span>
              ข้อมูล ณ วันที่&nbsp;
              {dateStart?.toLocaleString('th-Th', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}{' '}
              -{' '}
              {dateEnd?.toLocaleString('th-Th', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </span>
          ) : keyType === KeyTypeEnum.DAY ? (
            <span>
              ข้อมูล ณ วันที่&nbsp;
              {DateTime.now()
                .minus({ days: 1 })
                .startOf('day')
                .toJSDate()
                .toLocaleString('th-Th', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}{' '}
              -{' '}
              {DateTime.now().toJSDate().toLocaleString('th-Th', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </span>
          ) : keyType === KeyTypeEnum.MONTH ? (
            <span>
              ข้อมูล ณ วันที่&nbsp;
              {DateTime.now()
                .startOf('month')
                .toJSDate()
                .toLocaleString('th-Th', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}{' '}
              -{' '}
              {DateTime.now().toJSDate().toLocaleString('th-Th', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </span>
          ) : (
            <span>
              ข้อมูล ณ วันที่&nbsp;
              {DateTime.now()
                .startOf('year')
                .toJSDate()
                .toLocaleString('th-Th', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}{' '}
              -{' '}
              {DateTime.now()
                .startOf('month')
                .toJSDate()
                .toLocaleString('th-Th', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
            </span>
          )}
        </div>
      </div>
      <ReactECharts
        option={option}
        style={{ width: '100%' }}
        notMerge
        lazyUpdate
      />
    </div>
  )
}
