import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Container } from '@components/Container'
import { InputField } from '@components/forms/InputField'
import { UploadCoinButtonField } from '@features/coin/components/UploadCoinButtonField'
import { UploadCoinField } from '@features/coin/components/UploadCoinField'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { CoinFormType } from '@models/coin/CoinFormType'
import { Button } from '@components/Button'

export interface EditCoinModalProps {
  initialValues: CoinFormType
  onSubmit: (value: CoinFormType) => Promise<void>
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  upload: Yup.object().shape({
    url: Yup.string().required(),
  }),
})

export function EditCoinModal({ initialValues, onSubmit }: EditCoinModalProps) {
  const editCoinModal = useModal({ modal: 'editCoin' })
  return (
    <Container>
      <Container.Title className='text-center' showBackBtn={false}>
        แก้ไขเหรียญ
      </Container.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='w-full'>
          <div>
            <div className='w-full flex gap-x-[20px]'>
              <div className='w-[200px]'>
                <UploadCoinField />
              </div>
              <div className='w-full flex flex-col justify-between'>
                <InputField
                  name='name'
                  placeholder='ชื่อ'
                  label='ชื่อเหรียญ'
                  inputClassName='font-[300] bg-white text-black-970 w-full rounded-[20px] '
                  className='w-full'
                />
                <UploadCoinButtonField />
              </div>
            </div>
          </div>
          <div className='border-t border-[#eee] my-[30px]' />
          <div className='flex gap-x-[20px] font-mitr justify-center items-center text-[14px] font-medium'>
            <Button
              type='button'
              className='w-[100px] border border-secondary-100 text-secondary-100'
              onClick={() => editCoinModal.hide()}
            >
              ยกเลิก
            </Button>
            <Button className='text-white bg-primary w-[100px]' type='submit'>
              ตกลง
            </Button>
          </div>
        </Form>
      </Formik>
    </Container>
  )
}
