import { gql } from 'graphql-request'

export const GET_WRITING_COUNT = gql`
  query GetWritingCountLists($writerId: Float, $penNameId: Float) {
    getWritingCountLists(writerId: $writerId, penNameId: $penNameId) {
      publishedCount
      draftCount
    }
  }
`
