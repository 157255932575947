import { Expose, Transform, Type } from 'class-transformer'

import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'

export class UserWritersType {
  @Expose() id: number

  @Expose()
  @Transform(({ obj }) => obj.user.username)
  username: string

  @Expose()
  @Transform(({ obj }) => obj.user.id)
  userId: string

  @Expose()
  @Transform(({ obj }) => obj.user.avatarImgPath)
  avatarImgPath: string

  @Expose()
  @Transform(({ value }) =>
    value === WriterStatusEnum.APPROVED
      ? 'approved'
      : value === WriterStatusEnum.CREATED_REJECTED ||
        value === WriterStatusEnum.EDITED_REJECTED
      ? 'rejected'
      : 'pending'
  )
  status: string

  @Expose() createdAt: string
}
