import { gql } from 'graphql-request'

export const GET_USER_PEN_NAME = gql`
  query GetUserPenNames($userId: Int!) {
    getUserPenNames(userId: $userId) {
      firstPenName
      user {
        avatarImgPath
      }
      id
    }
  }
`
