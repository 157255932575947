import { Formik, Form } from 'formik'
import cn from 'classnames'

import { Button } from '@components/Button'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { SearchWriterField } from '@features/dashboard/pages/WriterPublisherPage/components/ByUserPage/components/SearchWriterField'
import { ExportButton } from '@components/ExportButton'
import { ByUserFormProps } from './interface'

export function ByUserForm({
  initialValues,
  handleSubmit,
  text,
  setText,
  resetHandler,
  handleExport,
}: ByUserFormProps) {
  const permission = usePermissionAction('sale')

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm }) => (
          <Form className='flex gap-x-[20px] mb-[30px]'>
            <SearchWriterField
              name='writer'
              text={text}
              setText={setText}
              placeholder='ค้นหาด้วยชื่อนักเขียน, สำนักพิมพ์ หรือ ID'
              inputClassName='font-[300] bg-blue-2 rounded-[20px] placeholder:text-secondary-100'
              className='w-[329px]'
              resetHandler={resetHandler}
            />
            <div className='flex-1 text-[14px] flex justify-end items-center gap-x-[20px]'>
              <Button
                className='underline text-secondary !px-0'
                type='button'
                onClick={() => {
                  resetForm()
                  resetHandler()
                }}
              >
                ล้างค่า
              </Button>
              <Button className='bg-secondary text-white' type='submit'>
                ค้นหา
              </Button>
              <ExportButton
                handleClick={handleExport}
                disabled={!Boolean(initialValues.writerId)}
                className={cn({
                  hidden: !permission.download,
                })}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
