import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Button } from '@components/Button'
import { ArrowLeftIcon } from '@components/Icons'
import { PromotionSaleFormProp } from './interface'
import ChallengeForm from '../PromotionChallengeForm/components/ChallengeForm'
import { SaleConditionForm } from './components/SaleConditionForm'
import { validationSchema } from './components/validationSchema'

export function PromotionSaleForm({
  initialValues,
  confirmCreate,
  goBackHandle,
  title,
  activeCoinOptions,
  saleCoinOptions,
  categoryOptions,
  viewMode = false,
  isEdit = false,
}: PromotionSaleFormProp) {
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={confirmCreate}
      validationSchema={validationSchema}
    >
      {({ values, dirty, isValid }) => (
        <Form>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={() => {
                if (dirty && goBackHandle) {
                  goBackHandle(values)
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>

            <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
              {title}
            </div>
          </div>
          <ChallengeForm
            viewMode={viewMode}
            title='รายละเอียดโปรโมชั่นการขาย'
          />
          <SaleConditionForm
            activeCoinOptions={activeCoinOptions}
            saleCoinOptions={saleCoinOptions}
            categoryOptions={categoryOptions}
            isEdit={isEdit}
            viewMode={viewMode}
          />
          <div className='flex flex-col gap-y-[20px] mt-[30px]'>
            {!viewMode && (
              <div className='flex justify-center gap-x-[20px] mt-[10px]'>
                <Button
                  onClick={() => {
                    if (dirty && goBackHandle) {
                      goBackHandle(values)
                    } else {
                      navigate(-1)
                    }
                  }}
                  className='border border-secondary-100 text-secondary-100 text-[14px] px-[30px]'
                >
                  ยกเลิก
                </Button>
                <Button
                  type='submit'
                  className='text-white bg-primary px-[30px] text-[14px]'
                >
                  บันทึก
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}
