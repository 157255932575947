import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { AgeConfirmParam } from '@models/ageConfirmation/AgeConfirmParam'
import { AgeConfirmResponse } from '@models/ageConfirmation/AgeConfirmResponse'
import { AgeConfirmUserlType } from '@models/ageConfirmation/AgeConfirmUserlType'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { GET_AGE_VERIFY_USERS } from './schemas/getAgeVerifyUsers'
import { GET_TOTAL_AGE_VERIFY_USERS } from './schemas/getTotalAgeVerifylUser'
import { GET_USER_BY_ID } from './schemas/getUserById'
import { VERIFY_AGE } from './schemas/verifyAge'

export class AgeConfirmClient {
  constructor(private client: GraphQLClient) {}

  async getAgeVerifyUsers(
    params: AgeConfirmParam
  ): Promise<AgeConfirmResponse> {
    const { findAgeVerifyUsers } = await this.client.request(
      GET_AGE_VERIFY_USERS,
      {
        limitPerPage: 20,
        page: params.page,
        statusType:
          params.statusType !== SearchUserStatusEnum.ALL
            ? params.statusType
            : undefined,
        searchText: params.searchText,
        orderBy: params.sortKey ? { [params.sortKey]: params.sortOrder } : {},
      }
    )
    return plainToInstance(AgeConfirmResponse, findAgeVerifyUsers)
  }

  async getTotalAgeVerifyUsers(
    statusType?: SearchUserStatusEnum,
    searchText?: string
  ): Promise<number> {
    const {
      findAgeVerifyUsers: { total },
    } = await this.client.request(GET_TOTAL_AGE_VERIFY_USERS, {
      statusType,
      searchText,
    })
    return total
  }

  async getUserById(userId: number): Promise<AgeConfirmUserlType> {
    const { getProfileById } = await this.client.request(GET_USER_BY_ID, {
      userId,
    })
    return plainToInstance(AgeConfirmUserlType, getProfileById)
  }

  async verifyAge({
    userId,
    ageVerify,
    ageVerifyRejectedReason,
  }: {
    userId: number
    ageVerify: AgeVerifyEnum
    ageVerifyRejectedReason?: string
  }): Promise<void> {
    await this.client.request(VERIFY_AGE, {
      verifyAgeInput: {
        userId,
        ageVerify,
        ageVerifyRejectedReason,
      },
    })
  }
}
