import { gql } from 'graphql-request'

export const GET_AVERAGE_REVENUE_PER_PAYING_USER = gql`
  query GetAverageRevenuePerPayingUser(
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getAverageRevenuePerPayingUser(startDate: $startDate, endDate: $endDate) {
      day
      year
      month
      averageRevenuePerPayingUser
    }
  }
`
