import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { SortingType } from '@components/Table/interface'
import { columns } from './components/AgeConfirmColumns'
import { AgeConfirmTableProps } from './interface'

export function AgeConfirmTable({
  data,
  handleSort,
  isLoading,
}: AgeConfirmTableProps) {
  return (
    <Table
      data={data}
      columns={columns}
      onSort={handleSort}
      isLoading={isLoading}
      initialSort={{
        key: 'ageUploadedAt',
        order: SortingType.DESC,
      }}
      bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
    />
  )
}
