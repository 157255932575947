import { PopupBannerListType } from '@models/popupBanner/PopupBannerListType'
import { DropResult } from 'react-beautiful-dnd'
import { NavigateFunction } from 'react-router-dom'

export interface PopupBannerManagementPageProps {
  popupData: PopupBannerListType[]
  selectedIds: number[]
  total: number
  statusTabs: PopupStatusEnum
  screenTabs: PopupScreenEnum
  navigate: NavigateFunction
  isLoading: boolean
  actionLoading: boolean
  handleDragItem: (result: DropResult) => Promise<void>
  handleSelectItemAll: () => void
  handleFetchNextPage: () => Promise<void>
  handleSelectItem: (id: number) => void
  handleDeletePopupBanner: (id: number) => void
  handlePopupStatus: (
    popupBanner: PopupBannerListType,
    index: number
  ) => Promise<void>
  handleStatusTabChange: (value: PopupStatusEnum) => void
  handleScreenTabChange: (value: PopupScreenEnum) => void
  handlePublishPopup: () => Promise<void>
  handleUnpublishedPopup: () => Promise<void>
  handleDeletePopupBannerList: () => void
}

export enum PopupScreenEnum {
  ALL = 'ALL',
  HOME = 'HOME',
  COIN = 'COIN',
}

export enum PopupStatusEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum PopupActionEnum {
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
  REMOVE = 'REMOVE',
}
