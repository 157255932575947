import { gql } from 'graphql-request'

export const GET_TOTAL_USER_GENDER = gql`
  query GetTotalUserGender(
    $endDate: DateTime
    $startDate: DateTime
    $penNameId: Int
    $writerId: Int
  ) {
    getTotalReadingGender(
      endDate: $endDate
      startDate: $startDate
      penNameId: $penNameId
      writerId: $writerId
    ) {
      maleReader
      femaleReader
      otherReader
    }
  }
`
