import { client } from '@client/init'
import { useAuthentication } from '@hooks/useAuthentication'
import { useQuery } from 'react-query'
import { BanUserModalAcceptProps, BanUserModalProps } from './interface'

export function withBanUserModal(Component: React.FC<BanUserModalProps>) {
  function WithBanUserModal({ id, ...props }: BanUserModalAcceptProps) {
    const { user, isAuthenticated } = useAuthentication()
    const { data: userData, isLoading } = useQuery(
      ['ban-user-modal', id],
      () => client?.ageConfirmClient.getUserById(Number(id)),
      { enabled: !!id && isAuthenticated }
    )
    if (!userData || isLoading) return null

    const newProps = {
      ...props,
      data: userData,
      user,
    }
    return <Component {...newProps} />
  }

  return WithBanUserModal
}
