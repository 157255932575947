import { useRef, useState } from 'react'
import { useField } from 'formik'
import { useMutation } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { PromotionTypeEnum } from '@interfaces/PromotionTypeEnum'

import {
  FileType,
  UploadFileUserIdFieldAcceptProps,
  UploadFileUserIdFieldProps,
} from './interface'

const withUploadFileUserIdField = (
  Component: React.FC<UploadFileUserIdFieldProps>
) => {
  function WithUploadFileUserIdField({
    name,
    promotionType,
    fieldValueName,
    fieldErrorName,
    setFieldValue,
    ...props
  }: UploadFileUserIdFieldAcceptProps) {
    const { error: alertError } = useAlert()
    const [{ value }, { error, touched }, { setValue }] = useField<
      FileType | undefined
    >(name)
    const inputRef = useRef<HTMLInputElement>(null)
    const client = useClient()
    const fileName = useRef(value?.name)

    const { mutateAsync: uploadPromotionUsers, isLoading: isCheckingFile } =
      useMutation(
        (uploadPromotionUsersInput: {
          file: File | Blob
          promotionType: PromotionTypeEnum
        }) =>
          client!.promotionClient.uploadPromotionUsers(
            uploadPromotionUsersInput
          ),
        {
          onSuccess: data => {
            setFieldValue(fieldErrorName, undefined)
            setFieldValue(fieldValueName, data.userIds)
            setValue({
              name: fileName.current,
              url: data.filePath,
            })
          },
          onError: (e: any) => {
            const { errorCode } = e.response.errors[0]
            if (errorCode === 4001) {
              setFieldValue(
                fieldErrorName,
                'มีรายการ User ID ซ้ำ ไม่สามารถใช้ได้'
              )
            } else if (errorCode === 4002) {
              setFieldValue(
                fieldErrorName,
                'User ID บางรายการไม่มีในระบบ ไม่สามารถใช้ได้'
              )
              // add # to split userIds
            } else if (errorCode === 40014) {
              setFieldValue(
                fieldErrorName,
                'รูปแบบไฟล์ไม่ถูกต้อง ไม่สามารถใช้ได้'
              )
            } else {
              setFieldValue(
                fieldErrorName,
                'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
              )
            }
          },
        }
      )

    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target
      if (files) {
        try {
          fileName.current = files[0].name
          await uploadPromotionUsers({
            file: files[0],
            promotionType,
          })
        } finally {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '' // DECS: clear input value for case that select same file
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    function onClearSelectedFile() {
      fileName.current = undefined
      setValue(undefined)
      setFieldValue(fieldValueName, [])
      setFieldValue(fieldErrorName, undefined)
    }

    const newProps = {
      ...props,
      inputRef,
      value,
      fileName: fileName.current,
      isCheckingFile,
      isError: !!error,
      onChange: handleChange,
      showUploadDialog,
      onClearSelectedFile,
    }
    return <Component {...newProps} />
  }
  return WithUploadFileUserIdField
}

export default withUploadFileUserIdField
