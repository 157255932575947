import { ArrayHelpers, useFormikContext } from 'formik'
import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'
import { FeatureRoleListProps } from './interface'

export function withFeatureRoleList(Component: React.FC<FeatureRoleListProps>) {
  function WithFeatureRoleList() {
    const {
      values: { permissions },
    } = useFormikContext<RolePermissionFormType>()

    function handleSelectChange(
      arrayHelpers: ArrayHelpers,
      index: number,
      value: string
    ) {
      const isSelect = permissions[index].actions.some(
        element => element === value
      )
      if (isSelect) {
        arrayHelpers.replace(index, {
          ...permissions[index],
          actions: permissions[index].actions.filter(item => item !== value),
        })
      } else {
        arrayHelpers.replace(index, {
          ...permissions[index],
          actions: [...permissions[index].actions, value],
        })
      }
    }

    const newProps = {
      permissions,
      handleSelectChange,
    }
    return <Component {...newProps} />
  }

  return WithFeatureRoleList
}
