import cn from 'classnames'
import { DateTime } from 'luxon'

import { ColumnType } from '@components/Table/interface'
import { addComma } from '@libs/utils'
import { CoinHistoryDataType } from '@models/coin/CoinHistoryDataType'
import { CoinLogTypeEnum } from '@interfaces/coin/CoinLogTypeEnum'
import { ActivityEnum } from '@interfaces/ActivityEnum'
import { ActionCell } from './components/ActionCell'
import { Detail } from './components/Detail'

export function CoinCell({
  value,
  className = '',
  isDecimalFormat = false,
}: {
  value: any
  className?: string
  isDecimalFormat?: boolean
}) {
  return (
    <div
      className={cn(
        'text-black-480 text-xs font-light text-right pr-[10px]',
        className
      )}
    >
      {!!value
        ? isDecimalFormat
          ? addComma(value)
          : value.toLocaleString()
        : '-'}
    </div>
  )
}

export const columns: ColumnType<CoinHistoryDataType>[] = [
  {
    column: 'Date & time',
    accessor: 'createdAt',
    cell: ({ value }) => (
      <div className='text-black-480 text-xs font-light'>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
          locale: 'th-Th',
        })}
      </div>
    ),
    cellClassName: 'border-l border-r py-[5px] pl-[10px] w-[120px]',
    isSortable: true,
  },
  {
    column: 'Transaction ID',
    accessor: 'transactionId',
    cell: ({ value }) => (
      <p className='text-black-480 text-xs font-light'>{value}</p>
    ),
    cellClassName: 'border-r py-[5px] px-[10px] w-[100px]',
    isSortable: false,
  },
  {
    column: 'User ID',
    accessor: 'user',
    cell: ({ value }) => (
      <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
        {value.id}
      </p>
    ),
    cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
    isSortable: false,
  },
  {
    column: 'User Name',
    accessor: 'user',
    cell: ({ value }) => (
      <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
        {value.username}
      </p>
    ),
    cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
    isSortable: false,
  },
  {
    column: 'Gateway/Channel',
    accessor: 'reference',
    cell: ({ value, obj }) => (
      <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
        {obj.activity === ActivityEnum.EXPIRED
          ? obj.activity
          : obj.activity === ActivityEnum.FORFEITED
          ? 'BUG'
          : value.transactionType || '-'}
      </p>
    ),
    cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
    isSortable: false,
  },
  {
    column: 'Detail',
    accessor: 'reference',
    cell: ({ value, obj }) => (
      <>
        {value.transactionDetail ? (
          <p className='text-black-480 text-xs font-light line-clamp-2 break-word leading-[1.8]'>
            {value.transactionDetail}
          </p>
        ) : (
          <Detail
            amount={obj.amount}
            type={obj.type}
            platform={obj.platform}
            activity={obj.activity}
            reference={obj.reference}
          />
        )}
      </>
    ),
    cellClassName: 'border-r py-[5px] pl-[10px] w-[350px]',
    isSortable: false,
  },
  {
    column: <div className='text-center w-full'>Amount</div>,
    accessor: 'amount',
    cell: ({ value }) => <CoinCell value={value} isDecimalFormat />,
    isSortable: false,
    cellClassName: 'w-[50px] border-r',
  },
  {
    column: <div className='text-center w-full'>สกุลเงิน</div>,
    accessor: 'currency',
    cell: ({ value }) => (
      <p className='text-black-480 text-xs font-light text-right line-clamp-1 break-word'>
        {value}
      </p>
    ),
    cellClassName: 'border-r py-[5px] pr-[10px] w-[50px]',
    isSortable: false,
  },
  {
    column: <div className='text-center w-full'>Amount (THB)</div>,
    accessor: 'amount',
    cell: ({ value }) => <CoinCell value={value} isDecimalFormat />,
    isSortable: false,
    cellClassName: 'w-[70px] border-r',
  },
  {
    column: <div className='text-center w-full'>Type of Coin</div>,
    accessor: 'reference',
    cell: ({ value }) => (
      <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
        {value.coinName || '-'}
      </p>
    ),
    cellClassName: 'border-r py-[5px] pl-[10px] w-[80px]',
    isSortable: false,
  },
  {
    column: <div className='text-center w-full'>Coin-in</div>,
    accessor: 'coinIn',
    cell: ({ obj }) => (
      <CoinCell
        value={obj.type === CoinLogTypeEnum.RECEIVE ? obj.coinValue : undefined}
        isDecimalFormat
      />
    ),
    isSortable: false,
    cellClassName: 'w-[50px] border-r',
  },
  {
    column: <div className='text-center w-full'>Coin-out</div>,
    accessor: 'coinOut',
    cell: ({ obj }) => (
      <CoinCell
        value={obj.type === CoinLogTypeEnum.USE ? obj.coinValue : undefined}
        isDecimalFormat
      />
    ),
    isSortable: false,
    cellClassName: 'w-[50px] border-r',
  },
  {
    column: <div className='text-center w-full'>Action</div>,
    accessor: 'user',
    cell: ({ value }) => <ActionCell id={value.id} />,
    cellClassName: 'border-r py-[5px] text-center w-[50px]',
    isSortable: false,
  },
]
