import { ColumnType } from '@components/Table/interface'
import { abbreviateNumber } from '@libs/utils'
import { ListUserReadingType } from '@models/story/ListUserReadingResponse'
import { ActionCell } from '../ActionCell'

export const columns: ColumnType<ListUserReadingType>[] = [
  {
    column: 'User ID',
    accessor: 'id',
    cell: ({ value }) => (
      <p className='w-[100px] pr-[10px] line-clamp-1'>{value}</p>
    ),
    isSortable: true,
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => (
      <p className='w-[300px] pr-[10px] line-clamp-1'>{value || '-'}</p>
    ),
    isSortable: true,
  },
  {
    column: 'Coin Spend',
    accessor: 'totalSpend',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[250px] max-w-[250px] pr-[10px]',
    isSortable: true,
    sortKey: 'userTotalSpend',
  },
  {
    column: 'คอมเมนต์',
    accessor: 'commentCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[250px] max-w-[250px] pr-[10px]',
    isSortable: true,
    sortKey: 'userCommentCount',
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => <ActionCell userId={value} />,
    isSortable: false,
  },
]
