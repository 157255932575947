import { gql } from 'graphql-request'

export const EXPORT_SALES_DASHBOARD = gql`
  query ExportSalesDashboard(
    $startDate: DateTime
    $endDate: DateTime
    $bookTypes: [BookType!]
    $categoryIds: [Int!]
    $writerId: Int
  ) {
    exportSalesDashboard(
      startDate: $startDate
      endDate: $endDate
      bookTypes: $bookTypes
      categoryIds: $categoryIds
      writerId: $writerId
    )
  }
`
