import cn from 'classnames'
import { Container } from '@components/Container'
import { CheckIcon } from '@components/Icons'
import { Pagination } from '@components/Pagination'
import { NotificationPageProps } from './interface'

export function NotificationPage({
  noticeList,
  page,
  perpage,
  total,
  totalUnread,
  isLoading,
  handlePaginationChange,
  handleClickNotification,
  updateAllReadStatus,
}: NotificationPageProps) {
  return (
    <Container>
      <div className='text-[24px] font-mitr font-medium text-secondary'>
        การแจ้งเตือน ({total < 100 ? total : '99+'})
      </div>
      <button
        className='flex items-center font-mitr mt-[20px] ml-auto font-medium text-[14px] text-secondary underline h-max'
        type='button'
        onClick={updateAllReadStatus}
      >
        <CheckIcon className='inline mr-[4px]' width='16' height='16' />
        อ่านทั้งหมด ({totalUnread < 100 ? totalUnread : '99+'})
      </button>
      <div className='grid my-[20px]'>
        {total !== 0
          ? noticeList.map(item => (
              <div
                className={cn(
                  'flex space-x-[20px] px-[15px] py-[20px] border-gray-3 border-b text-secondary cursor-pointer',
                  {
                    'bg-gray-1': !item.read,
                  }
                )}
                onClick={() => handleClickNotification(item)}
              >
                <div className='relative min-w-[80px] w-[80px] h-[120px] bg-gray rounded-[8px] overflow-hidden' />
                <div className='flex flex-col justify-center'>
                  <p className='font-mitr font-medium line-clamp-1 break-all'>
                    {item.title}
                  </p>
                  <p className='text-[14px] font-light line-clamp-5 break-words'>
                    {item.description}
                  </p>
                  <p className='text-[12px] text-secondary-1 mt-[7px]'>
                    {item.createdAt}
                  </p>
                </div>
              </div>
            ))
          : 'w,jr['}
      </div>
      {total > 0 && (
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={handlePaginationChange}
        />
      )}
    </Container>
  )
}
