import { WarnIcon } from './Icons'

export function FieldDescription({ text }: { text: string }) {
  return (
    <div className='flex gap-x-[5px] mt-[5px]'>
      <WarnIcon className='text-primary min-w-[16px]' width='16' height='16' />
      <span className='text-xs text-secondary-1'>{text}</span>
    </div>
  )
}
