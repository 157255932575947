import { gql } from 'graphql-request'

export const GET_USER_COIN_HISTORIES = gql`
  query UserCoinHistories(
    $userId: Int!
    $limitPerPage: Int
    $page: Int
    $orderBy: GetCoinHistoryOrderBy
    $endDate: DateTime
    $startDate: DateTime
  ) {
    userCoinHistories(
      userId: $userId
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      endDate: $endDate
      startDate: $startDate
    ) {
      data {
        id
        createdAt
        paidCoin
        freeCoin
        activity
        type
        platform
        transaction {
          transactionId
          currency
          amount
        }
        reference {
          id
          name
          coupon
          promotionName
          transactionType
          transactionDetail
          coinName
        }
      }
      page
      total
    }
  }
`
