import cn from 'classnames'

import { TabModeEnum } from '@components/Tabs/interface'
import { Loading } from '@components/Loading'
import { TabsProps } from './interface'

export function Tabs({
  tabs,
  mode,
  activeTab,
  isLoading,
  onTabChange,
}: TabsProps) {
  return (
    <div>
      <div
        className={cn(
          'flex overflow-y-hidden overflow-x-auto scrollbar-none mb-[20px]',
          {
            'space-x-[15px]': mode === TabModeEnum.BOX,
            'border-b border-b-gray-3': mode === TabModeEnum.LINE,
          }
        )}
      >
        {tabs.map(row => (
          <div className='relative' key={row.key}>
            <button
              type='button'
              className={cn(
                'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer whitespace-nowrap text-[14px]',
                {
                  'rounded-[8px] bg-secondary text-white':
                    row.key === activeTab && mode === TabModeEnum.BOX,
                  'rounded-[8px] bg-blue-2 text-secondary-100':
                    row.key !== activeTab && mode === TabModeEnum.BOX,
                  'border-b-[4px] border-secondary text-secondary font-bold z-[1]':
                    row.key === activeTab && mode === TabModeEnum.LINE,
                  'text-secondary-100 font-light':
                    row.key !== activeTab && mode === TabModeEnum.LINE,
                }
              )}
              onClick={() => onTabChange(row.key)}
            >
              {row.title}
            </button>

            {mode === TabModeEnum.LINE && (
              <hr
                className={cn(
                  'absolute bottom-[-2px] left-0 w-full h-[3px] bg-secondary z-[2] rounded-[3px]',
                  { hidden: row.key !== activeTab }
                )}
              />
            )}
          </div>
        ))}
      </div>
      <div>
        {isLoading ? (
          <Loading className='m-auto mt-[50px]' />
        ) : (
          tabs.find(row => row.key === activeTab)?.component
        )}
      </div>
    </div>
  )
}
