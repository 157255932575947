import { Expose } from 'class-transformer'

export class CategoryManageType {
  @Expose()
  id: number

  @Expose() name: string

  @Expose() isNovel: boolean

  @Expose() isManga: boolean
}
