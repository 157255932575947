import { Expose } from 'class-transformer'

export class ChallengeLevelType {
  @Expose() id: number

  @Expose() level: number

  @Expose() levelName: string

  @Expose() iconUrl: string

  @Expose() target: number

  @Expose() toTarget?: number

  @Expose() isActive: boolean

  @Expose() userCount: number

  @Expose() isUsed: boolean
}
