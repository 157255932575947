import { gql } from 'graphql-request'

export const GET_WRITER_SALES_BY_PRODUCT_TYPE = gql`
  query GetWriterSalesByProductType(
    $getWriterSalesByProductTypeInput: GetWriterSalesByProductTypeInput!
  ) {
    getWriterSalesByProductType(
      getWriterSalesByProductTypeInput: $getWriterSalesByProductTypeInput
    ) {
      writerId
      purchasedCount
      sales
      writerIncome
      salesDetail {
        data {
          id
          title
          firstPenName
          secondPenName
          purchasedCount
          sales
          writerIncome
        }
        total
        page
      }
    }
  }
`
