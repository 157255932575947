import { gql } from 'graphql-request'

export const GET_BOOK_CHAPTER_DETAIL_DASHBOARD = gql`
  query GetBookChapterDetailDashboard(
    $bookId: Int
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: BookChapterDashboardDetailOrderBy
    $limitPerPage: Int
    $page: Int
  ) {
    getBookChapterDetailDashboard(
      bookId: $bookId
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        bookChapterTotalComment
        bookChapterUniqueView
        bookChapterTotalView
        bookChapterRevenue
        bookChapterTotalDownload
        price
        updatedAt
        chapterName
        bookChapterId
        runningNo
      }
      page
      total
    }
  }
`
