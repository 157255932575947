import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { client } from '@client/init'
import { CoinFormType } from '@models/coin/CoinFormType'
import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { CoinCreatePageProps } from './interface'

export function withCoinCreatePage(Component: React.FC<CoinCreatePageProps>) {
  function WithCoinCreatePage() {
    const navigate = useNavigate()
    const alert = useAlert()

    const { mutate: creatCoin } = useMutation(
      (form: CoinFormType) => client!.coinClient.createCoin(form),
      {
        onSuccess: () => {
          navigate({
            pathname: '/coin/coin-setting',
          })
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    function onSubmit(value: CoinFormType) {
      creatCoin(value)
    }

    const newProps = {
      onSubmit,
    }
    return <Component {...newProps} />
  }

  return WithCoinCreatePage
}
