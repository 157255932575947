import {
  CodeLogUserTypeValue,
  CodeLogUserTypeEnum,
} from '@interfaces/promotionCode/CodeLogUserTypeEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { Expose, Transform, Type } from 'class-transformer'

export class PromotionCodeLogType {
  @Expose() id: number

  @Expose() userId: number

  @Expose()
  @Transform(({ value }) => CodeLogUserTypeValue[value as CodeLogUserTypeEnum])
  userType: string

  @Expose() type: UserByEnum

  @Expose() channel: string

  @Expose() coupon: string

  @Expose() totalPrice: number

  @Expose() discountedPrice: number

  @Expose() summaryPrice: number

  @Expose() discountedPercentage: number

  @Expose() paidCoinReceived: number

  @Expose() feeCoinReceived: number

  @Expose() promotionName: string

  @Expose() currentUsed: number

  @Expose() maximumUsed: number

  @Expose() createdAt: string
}

export class PromotionCodeLogResponseType {
  @Expose()
  @Type(() => PromotionCodeLogType)
  data: PromotionCodeLogType[]

  @Expose() total: number
}
