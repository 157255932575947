import React from 'react'
import { Container } from '@components/Container'
import CategorySection from './components/CategorySection'
import { CoverListPageProps } from './interface'

function CoverListPage({ categories }: CoverListPageProps) {
  return (
    <Container className='text-secondary'>
      <div className='text-2xl font-medium font-mitr mb-[35px]'>
        Cover Management
      </div>
      {categories?.map(category => (
        <CategorySection key={category.id} category={category} />
      ))}
    </Container>
  )
}

export default CoverListPage
