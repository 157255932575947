import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CoinLayoutProps, CoinPathType } from './interface'

const coinTabOption = [
  {
    text: 'Summary',
    value: 'summary',
  },
  {
    text: 'Coin Setting',
    value: 'coinSetting',
  },
  {
    text: 'Coin History',
    value: 'coinHistory',
  },
  {
    text: 'Upload file',
    value: 'uploadFile',
  },
]
const coinTabPath = {
  summary: '/coin/summary',
  coinSetting: '/coin/coin-setting',
  coinHistory: '/coin/coin-history',
  uploadFile: '/coin/upload-file',
}

export function withCoinLayout(Component: React.FC<CoinLayoutProps>) {
  function WithCoinLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<CoinPathType>('summary')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(coinTabPath).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as CoinPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function handleTabChange(type: CoinPathType) {
      setTab(type)
      navigate(coinTabPath[type])
    }

    const newProps = { coinTabOption, tab, handleTabChange, ...props }
    return <Component {...newProps} />
  }

  return WithCoinLayout
}
