import { Expose, Type } from 'class-transformer'

class PromotionSubCorrectionDetail {
  @Expose()
  old: any

  @Expose()
  new: any
}

class Category {
  id: number

  name: string
}

class PromotionSubCorrectionCategories {
  @Expose()
  old: Category[]

  @Expose()
  new: Category[]
}

class ChallengeBenefitType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitCoin: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitValue: PromotionSubCorrectionDetail
}

class ChallengeBenefitLimitType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitLimitType: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitLimitValue: PromotionSubCorrectionDetail
}

class ChallengeBudgetType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  budget: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  budgetPerDay: PromotionSubCorrectionDetail
}

class ChallengeActionType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  action: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  actionValue: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  wordCount: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  socialMediaChannels: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  readCondition: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  coinsCondition: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  bookTypes: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionCategories)
  categories: PromotionSubCorrectionCategories
}

class PublishPeriodType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  startPublishedAt: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  endPublishedAt: PromotionSubCorrectionDetail
}

export class PromotionChallengeCollectionDetailType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  name: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  description: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  userTerm: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => ChallengeActionType)
  challengeAction: ChallengeActionType

  @Expose()
  @Type(() => ChallengeBenefitType)
  challengeBenefit: ChallengeBenefitType

  @Expose()
  @Type(() => ChallengeBenefitLimitType)
  challengeBenefitLimit: ChallengeBenefitLimitType

  @Expose()
  @Type(() => ChallengeBudgetType)
  challengeBudget: ChallengeBudgetType

  @Expose()
  @Type(() => ChallengeBudgetType)
  publishPeriod: PublishPeriodType
}
