import { Outlet } from 'react-router-dom'
import { StoryReaderPage } from '@features/storyReader/pages/StoryReaderPage'

export const storyReaderRoutes = [
  {
    path: 'story-detail',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <StoryReaderPage />,
      },
    ],
  },
]
