import { bookTypeValue } from '@interfaces/BookEnum'
import { formatDate } from '@libs/utils'
import { WriterReportProps } from './interface'

export function WriterReport({
  imageUrl,
  username,
  writerId,
  totalPenName,
  startAt,
  endAt,
  bookType,
}: WriterReportProps) {
  return (
    <div className='flex items-center divide-x pb-[20px] border-b-[1px]'>
      <div className='flex items-center gap-x-[20px] pr-[40px]'>
        <img className='w-[90px] h-[90px] rounded-full' src={imageUrl} alt='' />
        <div className='flex flex-col'>
          <span className='font-bold text-secondary'>{username}</span>
          <span className='text-[14px] font-light text-secondary-100'>
            ID: {writerId}
          </span>
        </div>
      </div>
      <div className='flex flex-col px-[40px]'>
        <span className='text-[14px] font-light text-secondary-100'>
          นามปากกาทั้งหมด
        </span>
        <span className='font-bold text-secondary'>
          {totalPenName.toLocaleString()}
        </span>
      </div>
      <div className='flex flex-col px-[40px]'>
        <span className='text-[14px] font-light text-secondary-100'>
          ช่วงเวลา
        </span>
        <span className='font-bold text-secondary'>{`${formatDate(
          startAt.toString()
        )} - ${formatDate(endAt.toString())}`}</span>
      </div>
      {bookType[0] && (
        <div className='flex flex-col px-[40px]'>
          <span className='text-[14px] font-light text-secondary-100'>
            ลักษณะงานเขียน
          </span>
          <span className='font-bold text-secondary'>
            {bookTypeValue[bookType[0]]}
          </span>
        </div>
      )}
    </div>
  )
}
