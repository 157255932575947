import { Container } from '@components/Container'
import { StoryProvider } from '@hooks/story/useStory'
import { useParams } from 'react-router-dom'
import { BookLink } from './components/BookLink'
import { Character } from './components/Character'
import { Cover } from './components/Cover'
import { Introduction } from './components/Introduction'
import { ManageChapters } from './components/ManageChapters'
import { PayRank } from './components/PayRank'

export function PreviewStoryPage() {
  const { id } = useParams()

  return (
    <StoryProvider bookId={Number(id)}>
      <Container>
        <Cover />
        <Character />
        <PayRank />
        <Introduction />
        <BookLink />
        <ManageChapters />
      </Container>
    </StoryProvider>
  )
}
