import { Expose } from 'class-transformer'

export class SupporterType {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() username: string

  @Expose() avatarImgPath: string

  @Expose() profilePageSlug: string
}
