import { Link } from 'react-router-dom'
import cn from 'classnames'

import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { Box } from '@components/Box'
import { Checkbox } from '@components/Checkbox'
import { BinIcon, PenIcon } from '@components/Icons'
import { Pagination } from '@components/Pagination'
import { StickerManageType } from '@models/sticker/StickerManageType'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { StickerManagementPageProps } from './interface'

export function StickerManagementPage({
  data,
  selectedIds,
  page,
  perpage,
  total,
  handleSelectItem,
  handleEditSticker,
  handleDeleteSticker,
  handleDeleteStickerSelected,
  pageChange,
}: StickerManagementPageProps) {
  const permission = usePermissionAction('chapterBook')
  return (
    <Container>
      <section className='flex items-center justify-between mb-[30px]'>
        <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
          Sticker Management
        </div>
        <div className='flex items-center gap-x-[10px]'>
          <Button
            onClick={() => handleDeleteStickerSelected()}
            type='button'
            className={cn(
              'border border-none rounded-3xl px-5 py-1 text-sm font-medium font-mitr text-red bg-[#feeeef] h-[30px]',
              {
                hidden: !permission.delete,
              }
            )}
            disabled={selectedIds.length === 0}
          >
            ลบ
          </Button>
          <Link to='/chapter-book/sticker-management/add'>
            <Button
              type='button'
              className={cn('text-[14px] text-white bg-secondary', {
                hidden: !permission.create,
              })}
            >
              + เพิ่มสติ๊กเกอร์
            </Button>
          </Link>
        </div>
      </section>
      <section>
        {data.length ? (
          <>
            <div className='grid grid-cols-6 gap-[15px] mb-[20px]'>
              {data?.map((item: StickerManageType) => (
                <div
                  key={item.id}
                  className='flex flex-col justify-start gap-y-[10px]'
                >
                  <Box className='p-0 w-fit'>
                    <img
                      className='w-[159px] aspect-square rounded-[8px]'
                      alt=''
                      src={item.imageUrl}
                    />
                  </Box>
                  <div className='border-b-[1px] font-bold text-secondary pb-[10px] border-[#eee]'>
                    {item?.name}
                  </div>
                  <div className='flex items-center gap-x-[10px]'>
                    {permission.delete && (
                      <>
                        <Checkbox
                          checked={selectedIds.includes(item?.id)}
                          onChange={() => handleSelectItem(item?.id)}
                        />
                        <div className='border-r-[1px] border-[#eee] h-full' />
                      </>
                    )}
                    <button
                      type='button'
                      onClick={() => handleEditSticker(item?.id)}
                      className={cn(
                        'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                        {
                          hidden: !permission.edit,
                        }
                      )}
                    >
                      <PenIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </button>
                    <button
                      type='button'
                      className={cn(
                        'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                        {
                          hidden: !permission.delete,
                        }
                      )}
                      onClick={() => handleDeleteSticker(item?.id)}
                    >
                      <BinIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              page={page}
              total={total}
              perpage={perpage}
              pageChange={pageChange}
            />
          </>
        ) : (
          <Link
            to='/chapter-book/sticker-management/add'
            className={cn({ hidden: !permission.create })}
          >
            <button
              className='flex justify-center items-center border-dashed border-[1px] border-secondary-100 w-[159px] h-[159px] rounded-[8px] bg-white'
              type='button'
            >
              <span className='font-mitr font-medium text-[14px] text-secondary-100 underline'>
                + เพิ่มสติ๊กเกอร์
              </span>
            </button>
          </Link>
        )}
      </section>
    </Container>
  )
}
