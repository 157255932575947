import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { useTable } from '@hooks/useTable'
import {
  SpenderTypeEnum,
  TopSpenderTableProps,
} from '../TopSpenderTable/interface'
import { TopMoneySpenderProps } from './interface'

export function withTopMoneySpender(Component: React.FC<TopSpenderTableProps>) {
  function WithTopMoneySpender({ searchParam }: TopMoneySpenderProps) {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const [sort, setSort] = useState<SortByType>({
      key: 'userMoneySpend',
      order: SortingType.DESC,
    })

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSort(prev => ({ ...prev, key, order }))
    }, [])

    const { data = [], isLoading } = useQuery(
      ['money-spender', searchParam, sort],
      () =>
        client?.saleClient.getTopMoneySpender({
          searchParam,
          sort,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const pageProps = {
      data,
      isLoading,
      title: 'Top 20 Money Spender',
      spenderType: SpenderTypeEnum.MONEY,
      sort,
      handleSort,
    }
    return <Component {...pageProps} />
  }

  return WithTopMoneySpender
}
