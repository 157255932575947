import cn from 'classnames'
import { Form, Formik } from 'formik'

import { Button } from '@components/Button'
import { SelectButton } from '@components/SelectButton'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { InputField } from '@components/forms/InputField'
import { SearchIcon } from '@components/Icons'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'

import { PromotionChallengeSearchFormProps } from './interface'

const dateRangeOptions = [
  { text: 'วันนี้', value: KeyTypeEnum.DAY },
  { text: '1 เดือน', value: KeyTypeEnum.MONTH },
  { text: '1 ปี', value: KeyTypeEnum.YEAR },
]

function PromotionChallengeSearchForm({
  initialValues,
  handleSubmit,
  handleExport,
}: PromotionChallengeSearchFormProps) {
  const permission = usePermissionAction('promotionSetting')
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, resetForm }) => (
          <Form>
            <div className='flex flex-col gap-y-[20px] mb-[30px]'>
              <div className='flex items-center justify-between'>
                <InputField
                  name='searchText'
                  placeholder='ค้นหาด้วยชื่อ หรือ ID'
                  leftIcon={<SearchIcon className='text-primary' />}
                  autoTrim={false}
                  inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
                />
                <div className='flex-shrink-0 flex gap-x-[10px]'>
                  <Button
                    className='underline text-secondary'
                    onClick={() => {
                      // DESC: call resetForm funtion to reset form values
                      resetForm({
                        values: {
                          searchText: '',
                          selectedDate: KeyTypeEnum.DAY,
                          startDate: null,
                          endDate: null,
                        },
                      })
                      // DESC: call handleSubmit function to reset state of searchParams
                      handleSubmit({
                        searchText: '',
                        selectedDate: KeyTypeEnum.DAY,
                        startDate: null,
                        endDate: null,
                      })
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button type='submit' className='bg-secondary text-white'>
                    ค้นหา
                  </Button>
                </div>
              </div>
              <div className={cn('flex items-center justify-between')}>
                <div className='flex items-end gap-x-[10px]'>
                  <SelectButton
                    label='เลือกช่วงเวลา'
                    mode='line'
                    className='w-fit overflow-auto bg-[#f4f4f7]'
                    options={dateRangeOptions}
                    onChange={value => {
                      setFieldValue('selectedDate', value)
                      setFieldValue('startDate', null)
                      setFieldValue('endDate', null)
                    }}
                    value={values.selectedDate}
                  />
                  <DateTimeInputField
                    name='startDate'
                    className='w-[180px]'
                    placeholder='ตั้งแต่วันที่'
                    maxDate={values.endDate || new Date()}
                    onChange={value => {
                      setFieldValue('startDate', value)
                      if (values.selectedDate) {
                        setFieldValue('selectedDate', null)
                      }
                    }}
                  />
                  <DateTimeInputField
                    name='endDate'
                    className='w-[180px] '
                    placeholder='ถึงวันที่'
                    maxDate={new Date()}
                    minDate={values.startDate || undefined}
                    onChange={value => {
                      setFieldValue('endDate', value)
                      if (values.selectedDate) {
                        setFieldValue('selectedDate', null)
                      }
                    }}
                  />
                </div>
                {permission.download && (
                  <ExportButton
                    className='border !border-secondary-100 text-sm !text-secondary-100 rounded-[20px] !bg-white'
                    handleClick={handleExport}
                    label='Export Report'
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PromotionChallengeSearchForm
