import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { TopTimeSpenderTableProps } from './interface'
import { columns } from './column'

export function TopTimeSpenderTable({
  data,
  handleSort,
  isLoading,
}: TopTimeSpenderTableProps) {
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        Top Time Spender
      </h1>

      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'userTimeSpend',
          order: SortingType.DESC,
        }}
      />
    </div>
  )
}
