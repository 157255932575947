import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'

import { client } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { OverviewProps, SearchType } from './interface'

const withOverview = (Component: React.FC<OverviewProps>) => {
  function WithOverview() {
    const alert = useAlert()
    const queryClient = useQueryClient()

    const [searchParams, setSearchParams] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })

    function handleSearch(startDate: DateTime, endDate: DateTime) {
      setSearchParams({
        startDate,
        endDate,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
      })
    }

    const { data, isLoading } = useQuery(
      ['ebook-overview', searchParams],
      () => client!.dashboardClient.ebookDashboardOverview({ ...searchParams }),
      {
        enabled: !!searchParams,
      }
    )

    async function handleExport() {
      try {
        await client?.dashboardClient.exportEbookDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      data,
      isLoading,
      searchParams,
      handleSearch,
      handleReset,
      handleExport,
    }
    return <Component {...newProps} />
  }

  return WithOverview
}

export default withOverview
