import { gql } from 'graphql-request'

export const UPDATE_ADMIN_ROLE = gql`
  mutation UpdateAdminRole($updateAdminRoleInput: UpdateAdminRoleInput!) {
    updateAdminRole(updateAdminRoleInput: $updateAdminRoleInput) {
      id
      user {
        email
      }
      role {
        id
      }
      confirmationSentAt
    }
  }
`
export const UPDATE_ADMIN_ROLE_USER = gql`
  mutation UpdateAdminRole($updateAdminRoleInput: UpdateAdminRoleInput!) {
    updateAdminRole(updateAdminRoleInput: $updateAdminRoleInput) {
      id
      businessUserId
      lastLoginAt
      role {
        id
      }
      user {
        email
        displayName
      }
    }
  }
`
