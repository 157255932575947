import { Expose, Transform } from 'class-transformer'

export class CoinOptionType {
  @Expose({ name: 'id' })
  value: number | null

  @Expose({ name: 'name' })
  label: string

  @Expose()
  imgPath: string
}
