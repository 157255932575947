import { gql } from 'graphql-request'

export const GET_STORY = gql`
  query BookDashboard($startDate: DateTime, $endDate: DateTime) {
    bookDashboard(startDate: $startDate, endDate: $endDate) {
      countBook
      countBookChapter
      countBookChapterPublish
      countBookByEnding {
        isEnded
        notEnded
      }
      countBookChapterByPrice {
        hasPrice
        freePrice
      }
      countBuyChapterByPlatform {
        platform
        count
      }
      countByCategory {
        name
        count
      }
    }
  }
`
