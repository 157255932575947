import { Expose } from 'class-transformer'

import { PopupBannerDisplayEnum } from '@interfaces/PopupBannerDisplayEnum'

export class PopupBannerListType {
  @Expose() id: number

  @Expose() imgPath: string

  @Expose() title: string

  @Expose() link: string

  @Expose() screen: PopupBannerDisplayEnum

  @Expose() isActive: boolean

  @Expose() runningNo: number

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string
}
