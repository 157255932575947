import { gql } from 'graphql-request'

export const GET_USER_MANAGE_COIN = gql`
  query UserManagementCoins($userId: Int!) {
    userManagementCoins(userId: $userId) {
      coinId
      coinImgPath
      coinName
      editable
      expired
      isExpireByActivity
      isExpireByPaid
      received
      remain
      sell
      used
    }
  }
`
