import { gql } from 'graphql-request'

export const GET_PROMOTION_CHALLENGE_FORM_DETAIL = gql`
  query Promotion($promotionId: Float!) {
    promotion(id: $promotionId) {
      lastRequest {
        newPromotion {
          id
          promotionChallenge {
            id
            action
            actionValue
            wordCount
            socialMediaChannels
            readCondition
            coinsCondition {
              id
            }
            bookTypes
            benefitValue
            benefitCoin {
              id
            }
            benefitLimitType
            benefitLimitValue
            budget
            budgetPerDay
            categories {
              id
            }
          }
          name
          description
          userTerm
          startPublishedAt
          endPublishedAt
          publishedType
          status
          createdAt
          lastRequest {
            id
          }
        }
      }
    }
  }
`
