import { Exclude, Expose, Type } from 'class-transformer'

import { WritersConsentType } from '@models/writer/WritersConsentType'

export class ApprovedRequestType {
  @Expose()
  @Type(() => WritersConsentType)
  writersConsents: WritersConsentType[]
}

@Exclude()
export class ConsentHistoryType {
  @Expose()
  @Type(() => ApprovedRequestType)
  approvedRequests: ApprovedRequestType[]
}
