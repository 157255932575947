import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdminLayoutProps, AdminPathType } from './interface'

const adminTabOption = [
  {
    text: 'Admin User Management',
    value: 'adminUserManagement',
  },
  {
    text: 'Pending Invitation',
    value: 'pendingInvitation',
  },
  {
    text: 'Role & permission Setting',
    value: 'rolePermission',
  },
]
const adminTabPath = {
  adminUserManagement: '/admin/admin-user-management',
  pendingInvitation: '/admin/pending-invitation',
  rolePermission: '/admin/role-permission-setting',
}

export function withAdminLayout(Component: React.FC<AdminLayoutProps>) {
  function WithAdminLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<AdminPathType>('adminUserManagement')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(adminTabPath).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as AdminPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function handleChangeAdmin(type: AdminPathType) {
      setTab(type)
      navigate(adminTabPath[type])
    }

    const newProps = { adminTabOption, tab, handleChangeAdmin, ...props }
    return <Component {...newProps} />
  }

  return WithAdminLayout
}
