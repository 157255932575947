import { withPermissionGuard } from '@libs/permission'
import { CreateHighlightBannerPosterPage } from './CreateHighlightBannerPosterPage'
import { withCreateHighlightBannerPosterPage } from './withCreateHighlightBannerPosterPage'

const Connected = withPermissionGuard(
  withCreateHighlightBannerPosterPage(CreateHighlightBannerPosterPage),
  'homePageManagement',
  'create'
)

export { Connected as CreateHighlightBannerPosterPage }
