import { gql } from 'graphql-request'

export const GET_PROMOTION_SALE_FORM_DETAIL = gql`
  query Promotion($promotionId: Float!) {
    promotion(id: $promotionId) {
      lastRequest {
        newPromotion {
          id
          name
          description
          userTerm
          startPublishedAt
          endPublishedAt
          publishedType
          usageDay
          status
          isActive
          isEditable
          createdAt
          updatedAt
          deletedAt
          businessId
          promotionSale {
            budget
            coinsCondition {
              id
            }
            actionValue
            benefitCoinId
            benefitLimitType
            benefitLimitValue
            benefitValue
            budgetPerDay
            categories {
              id
            }
            conditionFileName
            conditionFilePath
            conditionType
            id
            termCount
            terms {
              termId
            }
            useCoinConditionType
          }
          lastRequest {
            id
          }
        }
      }
    }
  }
`
