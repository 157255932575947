import { Outlet } from 'react-router-dom'

import { PromotionSettingPage } from '@features/promotionSetting/pages/PromotionSettingPage'
import { CreatePromotionChallengePage } from '@features/promotionSetting/pages/CreatePromotionChallengePage'
import { CreatePromotionCodePage } from './pages/CreatePromotionCodePage'
import { PromotionCodeDetailPage } from './pages/PromotionCodeDetailPage'
import { PromotionCodeHistoryPage } from './pages/PromotionCodeHistoryPage'
import { FormDetailPromotionCodePage } from './pages/FormDetailPromotionCodePage'
import { EditPromotionCodePage } from './pages/EditPromotionCodePage'
import { CreateCodePromotionCodePage } from './pages/CreateCodePromotionCodePage'
import { PromotionChallengeDetailPage } from './pages/PromotionChallengeDetailPage'
import { EditPromotionChallengePage } from './pages/EditPromotionChallengePage'
import { HistoryPromotionChallengePage } from './pages/HistoryPromotionChallengePage'
import { FormDetailPromotionChallengePage } from './pages/FormDetailPromotionChallengePage'
import { DuplicatePromotionCodePage } from './pages/DuplicatePromotionCodePage'
import { DuplicatePromotionChallengePage } from './pages/DuplicatePromotionChallengePage'
import { PromotionSaleDetailPage } from './pages/PromotionSaleDetailPage'
import { PromotionSaleHistoryPage } from './pages/PromotionSaleHistoryPage'
import { CreatePromotionSalePage } from './pages/CreatePromotionSalePage'
import { EditPromotionSalePage } from './pages/EditPromotionSalePage'
import { DuplicatePromotionSalePage } from './pages/DuplicatePromotionSalePage'
import { FormDetailPromotionSalePage } from './pages/FormDetailPromotionSalePage'

export const promotionSettingRoutes = [
  {
    path: 'promotion-setting',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <PromotionSettingPage />,
      },
      {
        path: 'promotion-code',
        element: <Outlet />,
        children: [
          {
            path: 'create',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <CreatePromotionCodePage />,
              },
              {
                path: 'duplicate',
                element: <Outlet />,
                children: [
                  {
                    path: ':id',
                    element: <DuplicatePromotionCodePage />,
                  },
                ],
              },
            ],
          },
          {
            path: ':id',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <PromotionCodeDetailPage />,
              },
              {
                path: 'edit',
                element: <EditPromotionCodePage />,
              },
              {
                path: 'create-code',
                element: <CreateCodePromotionCodePage />,
              },
              {
                path: 'form-detail',
                element: <FormDetailPromotionCodePage />,
              },
              {
                path: 'history',
                element: <PromotionCodeHistoryPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'promotion-challenge',
        element: <Outlet />,
        children: [
          {
            path: 'create',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <CreatePromotionChallengePage />,
              },
              {
                path: 'duplicate/:id',
                element: <DuplicatePromotionChallengePage />,
              },
            ],
          },
          {
            path: ':id',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <PromotionChallengeDetailPage />,
              },
              {
                path: 'edit',
                element: <EditPromotionChallengePage />,
              },
              {
                path: 'history',
                element: <HistoryPromotionChallengePage />,
              },
              {
                path: 'form-detail',
                element: <FormDetailPromotionChallengePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'promotion-sale',
        element: <Outlet />,
        children: [
          {
            path: 'create',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <CreatePromotionSalePage />,
              },
              {
                path: 'duplicate',
                element: <Outlet />,
                children: [
                  {
                    path: ':id',
                    element: <DuplicatePromotionSalePage />,
                  },
                ],
              },
            ],
          },
          {
            path: ':id',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <PromotionSaleDetailPage />,
              },
              {
                path: 'edit',
                element: <EditPromotionSalePage />,
              },
              {
                path: 'history',
                element: <PromotionSaleHistoryPage />,
              },
              {
                path: 'form-detail',
                element: <FormDetailPromotionSalePage />,
              },
            ],
          },
        ],
      },
    ],
  },
]
