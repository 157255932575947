import { gql } from 'graphql-request'

export const GET_LIST_USER_READING = gql`
  query GetListUserReading(
    $bookId: Int
    $orderBy: GetListUserReadingOrderBy
    $page: Int
    $limitPerPage: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getListUserReading(
      bookId: $bookId
      orderBy: $orderBy
      page: $page
      limitPerPage: $limitPerPage
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        id
        username
        commentCount
        totalSpend
      }
      total
      page
    }
  }
`
