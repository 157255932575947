import { Expose } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'
import { CategoryType } from '@models/category/CategoryType'
import { SocialMediaChannelsEnum } from '@interfaces/promotionChllenge/SocialMediaChannelsEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'

interface CoinType {
  id: number
  name: string
}
interface SummaryType {
  userCount: number
  progressCount: number
  coinValue: number
  coin: CoinType
}
export class PromotionChallengeType {
  @Expose() id: number

  @Expose() action: PromotionChallengeActionEnum

  @Expose() actionValue: number

  @Expose() wordCount: number

  @Expose() socialMediaChannels: SocialMediaChannelsEnum[]

  @Expose() readCondition: PromotionChallengeReadConditionEnum

  @Expose() coinsCondition: CoinType[]

  @Expose() bookTypes: BookEnum[]

  @Expose() benefitValue: number

  @Expose() benefitLimitType: PromotionChallengeLimitTypeEnum

  @Expose() benefitLimitValue: number

  @Expose() budget: number

  @Expose() budgetPerDay: number

  @Expose() summary: SummaryType

  @Expose() categories: CategoryType[]
}
