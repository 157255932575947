import cn from 'classnames'

import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { TableProps, TableRowProps } from './interface'

export function Table({ children, className, onScroll }: TableProps) {
  return (
    <div className={cn('overflow-y-auto', className)} onScroll={onScroll}>
      {children}
    </div>
  )
}

Table.Header = function TableHeader({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('text-xs text-secondary-100 font-bold', className)}>
      {children}
    </div>
  )
}

Table.Row = function TableRow({ children, className, onClick }: TableRowProps) {
  return (
    <div
      className={cn(
        'grid grid-cols-[1fr_150px_150px_150px_220px_80px]',
        {
          'cursor-pointer hover:bg-blue-7': !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Table.Column = function TableColumn({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('text-[14px] text-secondary font-light', className)}>
      {children}
    </div>
  )
}

Table.Footer = function TableFooter({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('text-[14px] font-bold bg-blue-7', className)}>
      {children}
    </div>
  )
}
