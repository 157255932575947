import { TotalCharacterDonation } from '@models/character/TotalCharacterDonation'

export function TotalDonationSection({
  totalIncome,
  writerIncome,
  platformIncome,
}: TotalCharacterDonation) {
  return (
    <section className='grid grid-cols-[300px_1fr_200px]'>
      <div className='flex flex-col font-sarabun'>
        <div className='text-[14px] font-light text-secondary-100'>
          รายได้ทั้งหมดจากการสนับสนุน
        </div>
        <div className='text-[24px] font-bold text-secondary'>
          {totalIncome.toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </div>
      </div>
      <div className='flex flex-col font-sarabun'>
        <div className='text-[14px] font-light text-secondary-100'>
          ส่วนแบ่งนักเขียน
        </div>
        <div className='text-[24px] font-bold text-secondary'>
          {writerIncome.toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </div>
      </div>
      <div className='flex flex-col font-sarabun'>
        <div className='text-[14px] font-light text-secondary-100'>
          ส่วนแบ่งของ Platform
        </div>
        <div className='text-[24px] font-bold text-primary'>
          {platformIncome.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </section>
  )
}
