import React from 'react'
import { useParams } from 'react-router-dom'

import { UserMissionFormProps, WithUserMissionFormProps } from './interface'

export function withUserMissionForm(Component: React.FC<UserMissionFormProps>) {
  function WithUserMissionForm({
    data,
    onSubmit,
    isEdit,
    setIsEdit,
  }: WithUserMissionFormProps) {
    const { id } = useParams()

    function handleSwitchEditForm() {
      setIsEdit(!isEdit)
    }

    const componentProps = {
      initialValues: {
        id: Number(id),
        title: data.title,
        upload: { blob: undefined, url: data.iconUrl || '' },
        missionLink: data.missionLink,
      },
      isEdit,
      onSubmit,
      handleSwitchEditForm,
    }

    return <Component {...componentProps} />
  }

  return WithUserMissionForm
}
