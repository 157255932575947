import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { LeaveCreateModalProps, WithLeaveCreateModalProps } from './interface'

export function withLeaveCreateModal(
  Component: React.FC<LeaveCreateModalProps>
) {
  function LeaveCreateModal({ values }: WithLeaveCreateModalProps) {
    const navigate = useNavigate()
    const leavePageModal = useModal({ modal: 'leaveCreatePromotionCode' })

    function handleSubmit() {
      leavePageModal.hide()
      // save daft
      navigate('/promotion-setting')
    }

    function handleCancel() {
      leavePageModal.hide()
      navigate('/promotion-setting')
    }

    const componentProps = {
      handleSubmit,
      handleCancel,
    }
    return <Component {...componentProps} />
  }

  return LeaveCreateModal
}
