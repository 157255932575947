import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'
import { ColumnType } from '@components/Table/interface'
import { PromotionSaleLogType } from '@models/promotion/sale/PromotionSaleLogType'
import { EyeIcon } from '@components/Icons'
import { PromotionSaleTableProps } from './inteface'

function PromotionSaleTable({
  searchParams,
  data,
  page,
  perpage,
  total,
  isLoading,
  pageChange,
  handleSort,
}: PromotionSaleTableProps) {
  const columns: ColumnType<PromotionSaleLogType>[] = [
    {
      column: 'วันที่ได้รับเหรียญ',
      accessor: 'createdAt',
      cell: ({ value }) => (
        <p className='leading-[1.8]'>
          {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
            locale: 'th-Th',
          })}
        </p>
      ),
      sortKey: 'createdAt',
      isSortable: true,
      cellClassName: 'p-[10px] min-w-[170px] max-w-[170px]',
    },
    {
      column: 'User ID',
      accessor: 'userId',
      cell: ({ value }) => <p className='leading-[1.8]'>{value}</p>,
      sortKey: 'userId',
      isSortable: true,
      cellClassName: 'p-[10px] min-w-[80px] max-w-[80px]',
    },
    {
      column: 'ผู้ใช้',
      accessor: 'username',
      cell: ({ value }) => (
        <p className='leading-[1.8] text-ellipsis overflow-hidden'>{value}</p>
      ),
      sortKey: 'username',
      isSortable: true,
      cellClassName: 'p-[10px] min-w-[80px] max-w-[80px]',
    },
    {
      column: 'Story ID',
      accessor: 'bookIds',
      cell: ({ value }) => (
        <>
          {value.map((row: number) => (
            <p className='leading-[1.8]'>{row}</p>
          ))}
        </>
      ),
      isSortable: false,
      cellClassName: 'p-[10px] min-w-[80px] max-w-[80px]',
    },
    {
      column: 'ชื่อเรื่อง',
      accessor: 'bookNames',
      cell: ({ value }) => (
        <>
          {value.map((row: number) => (
            <p className='line-clamp-1 leading-[1.8]'>{row}</p>
          ))}
        </>
      ),
      cellClassName: 'p-[10px] w-full',
    },
    {
      column: 'นามปากกา',
      accessor: 'penNames',
      cell: ({ value }) => (
        <>
          {value.map((row: number) => (
            <p className='text-ellipsis overflow-hidden leading-[1.8]'>{row}</p>
          ))}
        </>
      ),
      cellClassName: 'p-[10px] min-w-[100px] max-w-[100px]',
    },
    {
      column: 'จำนวนยอดซื้อ',
      accessor: 'coinSpent',
      cell: ({ value }) => <p className='leading-[1.8]'>{value}</p>,
      cellClassName: 'p-[10px] min-w-[100px] max-w-[100px]',
    },
    {
      column: 'รางวัลที่ได้รับ',
      accessor: 'coinReceived',
      cell: ({ value }) => <p className='leading-[1.8]'>{value}</p>,
      cellClassName: 'p-[10px] min-w-[90px] max-w-[90px]',
    },
    {
      column: 'Action',
      isAction: true,
      actionCell: ({ obj }) => (
        <Link to={`/user/user-management/${obj.userId}?tab=coin`}>
          <button
            className='border border-gray w-[30px] h-[30px] rounded-[8px]'
            type='button'
          >
            <EyeIcon
              className='text-secondary-100 mx-auto'
              width='18'
              height='18'
            />
          </button>
        </Link>
      ),
      cellClassName: 'p-[10px] min-w-[40px] max-w-[40px]',
    },
  ]

  return (
    <div>
      <Table
        data={data || []}
        columns={columns}
        onSort={handleSort}
        initialSort={{
          key: searchParams.key,
          order: searchParams.order,
        }}
        isLoading={isLoading}
        className='challenge'
      />

      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}

export default PromotionSaleTable
