import { useState } from 'react'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { DonationSearchFormType } from '@interfaces/dashboard/DonationSearchFormType'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { DonationPageProps, DonationQueryParam } from './interface'

export function withDonationPage(Component: React.FC<DonationPageProps>) {
  function WithDonationPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const alert = useAlert()

    const [searchParams, setSearchParams] = useState<DonationQueryParam>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })

    function newDateAt(
      startAt: Date | undefined,
      endAt: Date | undefined,
      timeRange: TimeRangeEnum | undefined
    ) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : searchParams.startDate
      let newEndAt = endAt
        ? DateTime.fromJSDate(endAt).endOf('day')
        : searchParams.endDate

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 })
        newEndAt = DateTime.now()
      } else if (timeRange === TimeRangeEnum.WEEK) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.WEEK] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.THREE_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.THREE_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.SIX_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.SIX_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return { newStartAt, newEndAt }
    }

    function handleSearch({
      interval,
      startDate,
      endDate,
    }: DonationSearchFormType) {
      const { newStartAt, newEndAt } = newDateAt(startDate, endDate, interval)
      setSearchParams({
        startDate: newStartAt,
        endDate: newEndAt,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportDonationDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
      })
    }

    const pageProps = {
      searchParams,
      handleSearch,
      handleExport,
      handleReset,
    }

    return <Component {...pageProps} />
  }

  return WithDonationPage
}
