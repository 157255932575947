import { queryClient } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useDropdown } from '@hooks/useDropdown'
import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { SettingPromotionStatusEnum } from '@interfaces/SettingPromotionStatusEnum'
import { getErrorMessage } from '@libs/utils'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  PromotionSettingCardAcceptProps,
  PromotionSettingCardProps,
} from './interface'

export function withPromotionSettingCard(
  Component: React.FC<PromotionSettingCardProps>
) {
  function WithPromotionSettingCard({
    queryKey,
    enabled,
    title,
    status,
    promotionType,
    ...props
  }: PromotionSettingCardAcceptProps) {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
    const [isExpand, setIsExpnad] = useState<boolean>(false)
    const closePromotionModal = useModal(
      {
        modal: 'closePromotion',
      },
      true
    )
    const confirmModal = useModal({ modal: 'confirm' })

    useEffect(() => {
      setIsExpnad(false)
    }, [queryKey])

    const isDisableStatus =
      status === SettingPromotionStatusEnum.APPROVED ||
      status === SettingPromotionStatusEnum.STARTED ||
      status === SettingPromotionStatusEnum.PENDING ||
      status === SettingPromotionStatusEnum.REJECTED

    const { mutate: togglePromotionIsActive, isLoading } = useMutation(
      ({ isEnabled, id }: { isEnabled: boolean; id: number }) =>
        client!.promotionClient.togglePromotionIsActive(isEnabled, id),
      {
        onSuccess: () => {
          alert.success('ดำเนินการสำเร็จ')
          if (queryKey) {
            queryClient.invalidateQueries(queryKey)
          }
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    const { mutateAsync: deletePromotion } = useMutation(
      (promotionId: number) =>
        client!.promotionClient.deletePromotion(promotionId),
      {
        onSuccess: () => {
          alert.success('ลบโปรโมชั่นสำเร็จ')
          queryClient.invalidateQueries(['get-promotions'])
          navigate(`/promotion-setting`)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleChange(value: boolean) {
      if (!value) {
        setIsExpnad(true)
      } else {
        setIsExpnad(false)
      }
    }

    function handleEdit(promotionId: number) {
      const createCodeState = [
        SettingPromotionStatusEnum.GENERATE_CODE,
        SettingPromotionStatusEnum.GENERATE_FAIL,
        SettingPromotionStatusEnum.CODE_GENERATED,
      ]
      if (promotionType === SettingPromotionMenuTypeEnum.CODE) {
        if (createCodeState.includes(status)) {
          navigate(
            `/promotion-setting/promotion-code/${promotionId}/create-code`
          )
        } else {
          navigate(`/promotion-setting/promotion-code/${promotionId}/edit`)
        }
      } else if (promotionType === SettingPromotionMenuTypeEnum.CHALLENGE) {
        navigate(`/promotion-setting/promotion-challenge/${promotionId}/edit`)
      } else if (promotionType === SettingPromotionMenuTypeEnum.SALE) {
        navigate(`/promotion-setting/promotion-sale/${promotionId}/edit`)
      }
    }

    function handleDuplicate(promotionId: number) {
      if (promotionType === SettingPromotionMenuTypeEnum.CODE) {
        navigate(
          `/promotion-setting/promotion-code/create/duplicate/${promotionId}`
        )
      }
      if (promotionType === SettingPromotionMenuTypeEnum.CHALLENGE) {
        navigate(
          `/promotion-setting/promotion-challenge/create/duplicate/${promotionId}`
        )
      }
      if (promotionType === SettingPromotionMenuTypeEnum.SALE) {
        navigate(
          `/promotion-setting/promotion-sale/create/duplicate/${promotionId}`
        )
      }
    }

    function handleDeletePromotion(promotionId: number) {
      confirmModal.show({
        content: (
          <div className='w-[280px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ลบโปรโมชั่น
            </p>
            <div className='flex flex-col text-center  mt-[20px] mb-[30px] text-[14px] font-light text-secondary'>
              คุณกำลังจะลบโปรโมชั่น
              <span className='font-bold'>&ldquo;{title} &rdquo;</span>
              ต้องการที่จะดำเนินการต่อหรือไม่
            </div>
          </div>
        ),
        onConfirm: async () => {
          await deletePromotion(promotionId)
          confirmModal.hide()
        },
        onClose: () => {
          confirmModal.hide()
        },
        confirmButtonText: 'ยืนยัน',
      })
    }

    function handleActivePromotion(value: boolean, id: number) {
      closePromotionModal.show({
        title,
        onConfirm: () => {
          togglePromotionIsActive({ isEnabled: !value, id })
          closePromotionModal.hide()
        },
        isClose: value,
        onClose: () => closePromotionModal.hide(),
      })
    }

    function viewPromotion(promotionId: number) {
      if (promotionType === SettingPromotionMenuTypeEnum.CODE) {
        navigate(`/promotion-setting/promotion-code/${promotionId}`)
      } else if (promotionType === SettingPromotionMenuTypeEnum.CHALLENGE) {
        navigate(`/promotion-setting/promotion-challenge/${promotionId}`)
      } else if (promotionType === SettingPromotionMenuTypeEnum.SALE) {
        navigate(`/promotion-setting/promotion-sale/${promotionId}`)
      }
    }

    const componentProps = {
      ...props,
      promotionType,
      title,
      status,
      queryKey,
      enabled,
      dropdownRef,
      isShowDropdown,
      isExpand,
      isDisableStatus,
      handleChange,
      toggleDropdown,
      handleActivePromotion,
      handleEdit,
      handleDuplicate,
      handleDeletePromotion,
      viewPromotion,
    }
    return <Component {...componentProps} />
  }

  return WithPromotionSettingCard
}
