import React from 'react'

function PlusCoverCard({
  value,
  goToCategoryCoverPage,
}: {
  value: number
  goToCategoryCoverPage: () => void
}) {
  return (
    <div
      className='w-[66px] h-[92px] rounded-[8px] bg-blue-2 flex items-center justify-center cursor-pointer select-none'
      onClick={goToCategoryCoverPage}
    >
      <div className='font-mitr font-medium text-2xl text-secondary-100'>
        +{value}
      </div>
    </div>
  )
}

export default PlusCoverCard
