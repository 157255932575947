import cn from 'classnames'

import {
  EyeIcon,
  HeartIcon,
  BanIcon,
  ArrowUpIcon,
  StarIcon,
  ReturnIcon,
} from '@components/Icons'
import { abbreviateNumber } from '@libs/utils'
import { StoryCardProps } from './interface'

export function StoryCard({
  className,
  header,
  topChartId,
  title,
  imageUrl,
  publisher,
  writer,
  penName,
  category,
  view,
  like,
  previousRank,
  rankChange,
  isBanCard = false,
  isBan,
  banReason,
  isLoading,
  showBanModal,
  showUnBanModal,
}: StoryCardProps) {
  return (
    <div
      className={cn(
        'flex gap-x-[5px] border border-gray rounded-[8px] shadow',
        className
      )}
    >
      <div className='shrink-0 rounded-[8px] w-[106px] h-[152px] overflow-hidden'>
        <img
          className='object-cover w-full h-full'
          src={imageUrl}
          alt='cover'
        />
      </div>
      <section className='flex flex-col flex-1 p-[15px]'>
        <section className='flex justify-between'>
          <div className='font-mitr text-[18px] text-secondary font-medium line-clamp-1 break-word'>
            {title}
          </div>
          {isBan && (
            <div className='rounded px-[10px] py-[4px] font-medium ml-[10px] text-red border border-red text-xs'>
              Ban
            </div>
          )}
        </section>
        <section className='flex font-light text-[14px] text-secondary my-[5px]'>
          {publisher && (
            <p className='pr-[10px] border-r border-gray mr-[10px] line-clamp-1 break-word'>
              <span className='text-secondary-100'>สำนักพิมพ์ : </span>
              {publisher}
            </p>
          )}
          {writer ? (
            <p className='pr-[10px] border-gray mr-[10px] line-clamp-1 break-word'>
              <span className='text-secondary-100 '>
                ผู้เขียน :<span className=''>{writer}</span>
              </span>
            </p>
          ) : (
            <p className='pr-[10px] border-gray mr-[10px] line-clamp-1 break-word'>
              <span className='text-secondary-100'>นามปากกา : </span>
              {penName}
            </p>
          )}
        </section>
        <section className='flex items-center text-[12px] mt-[5px] font-light gap-x-[5px]'>
          <div className='flex'>
            <div className='flex items-center text-primary underline pr-[10px] mr-[10px] max-w-[150px] line-clamp-1 border-r border-gray'>
              {category}
            </div>
          </div>
          <div className='flex gap-x-[10px] text-secondary-100'>
            <div className='flex items-center'>
              <EyeIcon
                className='mr-[5px] text-secondary'
                width='18'
                height='18'
              />
              {abbreviateNumber(view)}
            </div>
            <div className={cn('flex items-center')}>
              <HeartIcon
                className='mr-[5px] text-secondary'
                width='18'
                height='18'
              />
              {abbreviateNumber(like)}
            </div>
          </div>
        </section>
        <section className='flex justify-between items-end mt-auto text-[12px] font-light text-secondary-100 '>
          {isBanCard ? (
            <div className='flex text-[14px] pr-[30px] line-clamp-1'>
              {banReason || '-'}
            </div>
          ) : previousRank === null ? (
            <div className='flex gap-x-[10px]'>
              <div className='flex items-center text-[14px]'>
                <StarIcon
                  className='mr-[5px] text-primary'
                  width='18'
                  height='18'
                />
                <span className='line-clamp-1'>
                  ติด
                  <span className='font-bold px-[4px] text-secondary'>
                    Top Chart
                  </span>{' '}
                  สัปดาห์แรก
                </span>
              </div>
            </div>
          ) : rankChange === 0 ? (
            <div className='flex items-center text-[14px] text-secondary'>
              <div className='w-[9px] h-[2px] mx-[10px] bg-secondary rounded-full' />
              อันดับคงที่
            </div>
          ) : rankChange > 0 ? (
            <div className='flex gap-x-[10px]'>
              <div className='flex items-center text-[14px]'>
                <ArrowUpIcon
                  className='mr-[5px] text-primary rotate-180'
                  width='18'
                  height='18'
                />
                ลงมา
                <div className='font-bold text-secondary mx-[5px]'>
                  {rankChange} อันดับ
                </div>
                จากสัปดาห์ที่แล้ว
              </div>
            </div>
          ) : (
            rankChange < 0 && (
              <div className='flex gap-x-[10px]'>
                <div className='flex items-center text-[14px]'>
                  <ArrowUpIcon
                    className='mr-[5px] text-primary'
                    width='18'
                    height='18'
                  />
                  ขึ้นมา
                  <div className='font-bold text-secondary mx-[5px]'>
                    {Math.abs(rankChange)} อันดับ
                  </div>
                  จากสัปดาห์ที่แล้ว
                </div>
              </div>
            )
          )}

          {isBan ? (
            <div className='flex justify-end'>
              <button
                type='button'
                className='w-[30px] h-[30px] border border-gray-border rounded-lg flex items-center justify-center'
                onClick={showUnBanModal}
              >
                <ReturnIcon width='20' height='20' className='text-secondary' />
              </button>
            </div>
          ) : (
            <div className='flex justify-end'>
              <button
                type='button'
                className='w-[30px] h-[30px] border border-gray-border rounded-lg flex items-center justify-center'
                onClick={showBanModal}
              >
                <BanIcon width='20' height='20' className='text-red' />
              </button>
            </div>
          )}
        </section>
      </section>
    </div>
  )
}
