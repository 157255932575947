import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'

import { formatDateDomini } from '@libs/utils'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import {
  promotionSaleConditionValue,
  PSConditionTypeEnum,
} from '@interfaces/promotionSale/PSConditionTypeEnum'
import { dayOptionsText } from '@libs/promotion/utils'
import { promotionSaleCoinConditionValue } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { benefitLimitType } from '@features/promotionSetting/pages/CreatePromotionChallengePage/promotionChallengeOptions'
import { ConfirmPromotionSaleModalProps } from './interface'

export function ConfirmPromotionSaleModal({
  values,
  title,
  activeCoinOptions,
  saleCoinOptions,
  categoryOptions,
}: ConfirmPromotionSaleModalProps) {
  const now = DateTime.now().toISO()
  const headerClassName = 'text-[18px] font-bold'
  const titleClassName = 'text-[12px] font-light text-secondary-100 mr-[5px]'

  return (
    <div className='flex flex-col w-[700px] text-[14px] font-light text-secondary'>
      <p className='font-mitr font-medium text-[24px] pb-[25px] border-b text-center'>
        คุณยืนยัน{title}
      </p>
      <div className='flex flex-col max-h-[440px] overflow-y-auto'>
        <div className='flex flex-col gap-y-[20px]  py-[20px]'>
          <p className={cn('line-clamp-1 break-word', headerClassName)}>
            {values.name}
          </p>

          <div className='flex justify-between'>
            <div>
              <span className={cn(titleClassName)}>วันที่สร้าง :</span>
              <span>{formatDateDomini(now, true)}</span>
            </div>
            <div>
              <span className='text-[14px] mr-[5px] font -light text-secondary-100'>
                ระยะเวลาแคมเปญ :
              </span>
              <span className='font-bold'>
                {values.publishedType === PromotionPublishedEnum.NOW
                  ? values.startNowAt
                    ? formatDateDomini(values.startNowAt.toISOString(), true)
                    : 'ทันที'
                  : formatDateDomini(
                      values.startPublishedAt.toISOString(),
                      true
                    )}
              </span>
              <span className='text-[14px] px-[10px] font -light text-secondary-100'>
                ถึง
              </span>
              <span className='font-bold'>
                {formatDateDomini(values.endPublishedAt.toISOString(), true)}
              </span>
            </div>
          </div>
          <div className='flex '>
            <div className={cn('min-w-[95px]', titleClassName)}>
              รายละเอียด :
            </div>
            <div className='break-word line-clamp-1'>
              {values.description || '-'}
            </div>
          </div>
          <div className='flex'>
            <div className={cn('min-w-[95px]', titleClassName)}>
              เงื่อนไขการใช้งาน :
            </div>
            <div className='whitespace-pre-line break-word'>
              {values.userTerm || '-'}
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-y-[20px] py-[20px] border-t'>
          <p className={cn(headerClassName)}>เงื่อนไขโปรโมชั่น</p>
          <div className='flex flex-wrap items-baseline'>
            <span className={cn('whitespace-nowrap', titleClassName)}>
              ใน :
            </span>
            {promotionSaleConditionValue[values.conditionType]}
            <div>
              {values.conditionType === PSConditionTypeEnum.ALL ? (
                <>
                  <span className={cn('ml-[20px]', titleClassName)}>
                    หมวดหมู่ :
                  </span>
                  <span>
                    {`${values.categoryIds?.map(
                      item =>
                        categoryOptions.find(row => row.value === item)?.text
                    )}`}
                  </span>
                </>
              ) : (
                <>
                  <span className={cn('ml-[20px]', titleClassName)}>
                    Upload file :
                  </span>
                  <span>{values.conditionFile?.name || '-'}</span>
                  <span className='ml-[10px]'>
                    ({values.termCount?.toLocaleString()} ID)
                  </span>
                </>
              )}
            </div>
          </div>
          <div className='flex flex-wrap items-baseline'>
            <span className={cn('whitespace-nowrap', titleClassName)}>
              จำนวน :
            </span>
            {values.actionValue.toLocaleString()}
            <span className=' mx-[10px]'>เหรียญขึ้นไป</span>
            <div className='flex'>
              (
              {` ${values.coinConditionIds.map(
                item => activeCoinOptions.find(row => row.value === item)?.label
              )} `}
              )
            </div>
            <span className={cn('whitespace-nowrap ml-[20px]', titleClassName)}>
              ใช้ได้ในวัน :
            </span>
            <span className={cn('flex flex-wrap')}>
              {values.usageDay?.length
                ? values.usageDay.length === 7
                  ? 'ทุกวัน'
                  : values.usageDay.map((day, index) => (
                      <React.Fragment key={index}>
                        <span>{dayOptionsText(day)}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))
                : '-'}
            </span>
            <span className={cn('whitespace-nowrap ml-[20px]', titleClassName)}>
              ใช้เหรียญ :
            </span>
            {promotionSaleCoinConditionValue[values.useCoinConditionType]}
          </div>
          <div className='flex items-baseline flex-wrap gap-x-[26px] gap-y-[20px]'>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>รับเหรียญ : </span>
              <span className='text-sm text-secondary'>
                {values.benefitValue.toLocaleString()}
                <span className='text-secondary-100 ml-[4px] text-xs'>
                  {`เหรียญ${
                    activeCoinOptions.find(
                      coin => coin.value === Number(values.benefitCoinId)
                    )?.label
                  }`}
                </span>
              </span>
            </div>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>
                เก็บเหรียญได้ :{' '}
              </span>
              {values.benefitLimitType && (
                <span className='text-sm text-secondary'>
                  {[
                    PromotionChallengeLimitTypeEnum.PER_DAY,
                    PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
                  ].includes(values.benefitLimitType) && (
                    <span>{values.benefitLimitValue?.toLocaleString()} </span>
                  )}
                  {benefitLimitType[values.benefitLimitType]}
                </span>
              )}
            </div>
          </div>
          <div className='flex items-baseline flex-wrap gap-x-[26px] gap-y-[20px]'>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>
                งบประมาณ/แคมเปญ :{' '}
              </span>
              <span className='text-sm text-secondary'>
                {values.budget.toLocaleString()}
                <span className='text-secondary-100 ml-[4px] text-xs'>
                  {`เหรียญ${
                    activeCoinOptions.find(
                      coin => coin.value === Number(values.benefitCoinId)
                    )?.label
                  }`}
                </span>
              </span>
            </div>
            {values.isLimitBudget && (
              <div className='font-light space-x-[10px]'>
                <span className='text-xs text-secondary-100'>
                  จำกัดจำนวน :{' '}
                </span>
                <span className='text-sm text-secondary'>
                  {values.budgetPerDay?.toLocaleString()}
                  <span className='text-secondary-100 ml-[4px] text-xs'>
                    {`เหรียญ${
                      activeCoinOptions.find(
                        coin => coin.value === Number(values.benefitCoinId)
                      )?.label
                    }/วัน`}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
