import { Expose } from 'class-transformer'

export class BookSupporterType {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() username: string

  @Expose() avatarImgPath: string
}
