import React from 'react'

import { useAccordion } from '@components/Accordion/withAccordion'
import { Loading } from '@components/Loading'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { client, queryClient } from '@client/init'
import { getErrorMessage } from '@libs/utils'
import { BanUserImageEnum } from '@interfaces/user/BanUserImageEnum'
import { useStory } from '@hooks/story/useStory'
import { ChapterGroupProps, WithChapterGroupProps } from './interface'

export function withChapterGroup(Component: React.FC<ChapterGroupProps>) {
  function WithChapterGroup({ isManga, sorter }: WithChapterGroupProps) {
    const { chapters, bookId, isLoading, page } = useAccordion()
    const alert = useAlert()
    const { data } = useStory()
    const confirmModal = useModal({
      modal: 'confirm',
    })
    function banCover(id: number, chapterCoverImgPath: string) {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ต้องการจะแบนรูปภาพหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              await client!.userManagementClient.createBanImageLog({
                userId: data!.penName.user.id,
                type: BanUserImageEnum.MANGA_COVER,
                imageUrl: chapterCoverImgPath,
                bookChapterId: Number(id),
              })
              alert.success('ดำเนินการสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              await queryClient.invalidateQueries([
                'book',
                bookId,
                'chapters',
                'tab',
                page,
              ])
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }
    if (isLoading || chapters === undefined) {
      return <Loading className='mx-auto' />
    }

    const componentProps = {
      chapters,
      bookId,
      sorter,
      isManga,
      banCover,
    }

    return <Component {...componentProps} />
  }

  return WithChapterGroup
}
