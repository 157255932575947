import React, { useState, useRef, useEffect } from 'react'
import { AccordionProps } from './interface'

export function withAccordion(Component: React.FC<AccordionProps>) {
  function HOC({ initialActive = false, ...props }: AccordionProps) {
    const [active, setActive] = useState(initialActive)
    const [height, setHeight] = useState('0px')
    const [rotate, setRotate] = useState(
      'transform duration-300 ease rotate-180'
    )
    const contentSpace = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (initialActive) {
        setHeight(`${contentSpace?.current?.scrollHeight}px`)
        setRotate('transform duration-300 ease rotate-180')
      }
    }, [initialActive])

    const toggleAccordion = (event: React.MouseEvent<HTMLElement>) => {
      if (event.target instanceof HTMLInputElement) return

      setActive(active === false)
      setHeight(active ? '0px' : `${contentSpace?.current?.scrollHeight}px`)
      setRotate(
        active
          ? 'transform duration-300 ease rotate-180'
          : 'transform duration-300 ease'
      )
    }

    const newProps = {
      ...props,
      height,
      rotate,
      contentSpace,
      toggleAccordion,
    }

    return <Component {...newProps} />
  }

  return HOC
}
