import {
  OverviewActiveUserAcceptProps,
  OverviewActiveUserProps,
} from './interface'

export function withOverviewActiveUser(
  Component: React.FC<OverviewActiveUserProps>
) {
  function WithOverviewActiveUser({
    Active1DayUsers,
    Active7DayUsers,
    Active28DayUsers,
  }: OverviewActiveUserAcceptProps) {
    const data = [
      {
        name: 'Active1DayUsers',
        value: `${Active1DayUsers?.toLocaleString()}`,
      },
      {
        name: 'Active7DayUsers',
        value: `${Active7DayUsers?.toLocaleString()}`,
      },
      {
        name: 'Active28DayUsers',
        value: `${Active28DayUsers?.toLocaleString()}`,
      },
    ]
    const componentProps = {
      data,
    }
    return <Component {...componentProps} />
  }

  return WithOverviewActiveUser
}
