import cn from 'classnames'

import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon, LeftIcon, RightIcon } from '@components/Icons'
import { SelectButtonProps } from './interface'

export function SelectButton({
  label,
  options,
  className,
  placeholder,
  disabled,
  labelClassName,
  months,
  year,
  selectedLabel,
  datetimeCurrent,
  increaseYear,
  decreaseYear,
  handleSelectedChange,
  setValue,
}: SelectButtonProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  return (
    <div className={cn('relative select-none', className)}>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 font-light mb-[4px]',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <div
        className={cn(
          'w-full h-[40px] border-[1px] border-[#eeeeee] rounded-[8px] text-[14px] text-[#484848] font-medium p-[10px] cursor-pointer',
          {
            'cursor-default pointer-events-none': disabled,
            'bg-[#f7f7f7]': disabled,
            'border-primary': isShowDropdown,
          }
        )}
        onClick={toggleDropdown}
      >
        <div className='flex items-center justify-between font-light'>
          <p className={cn({ 'text-secondary ': !selectedLabel })}>
            {selectedLabel || placeholder || label}
          </p>
          <ChevronDownIcon
            className={cn({ 'text-primary': isShowDropdown })}
            width='16'
            height='16'
          />
        </div>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-full z-10 mt-[8px] right-0 bg-white  border-1 border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow-[0_3px_12px_0_rgba(51,51,51,0.05)]'
          >
            <div className='my-[10px]'>
              <>
                {options.map((menu, index) => (
                  <div
                    className='font-light py-[10px] px-[20px] hover:bg-blue-2 hover:text-primary hover:bg-primary-100 '
                    key={index}
                    onClick={() => {
                      handleSelectedChange(menu.label || '')
                      setValue(menu.value)
                    }}
                  >
                    {menu.label}
                  </div>
                ))}
                <div
                  className='flex justify-center items-center'
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <button type='button' onClick={decreaseYear}>
                    <LeftIcon className='text-primary' width='16' height='16' />
                  </button>
                  <span className='px-[35px]'>{year}</span>
                  <button
                    type='button'
                    className='disabled:opacity-20'
                    onClick={increaseYear}
                    disabled={year >= new Date().getFullYear()}
                  >
                    <RightIcon
                      className='text-primary'
                      width='16'
                      height='16'
                    />
                  </button>
                </div>
                {months.map((item, index) => (
                  <button
                    type='button'
                    className='font-light py-[10px] px-[20px] hover:bg-blue-2 hover:text-primary hover:bg-primary-100 w-full text-left disabled:opacity-20'
                    key={index}
                    disabled={new Date(item.value).getTime() >= datetimeCurrent}
                    onClick={() => {
                      handleSelectedChange(`${item.label} / ${year}`)
                      setValue(item.value)
                    }}
                  >
                    {item.label}
                  </button>
                ))}
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
