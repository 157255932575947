import { gql } from 'graphql-request'

export const PENDING_LIST_ADMIN = gql`
  query PendingListAdmin(
    $limitPerPage: Int
    $page: Int
    $search: String
    $orderBy: PendingListAdminOrderBy
  ) {
    pendingListAdmin(
      limitPerPage: $limitPerPage
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      data {
        id
        role {
          id
        }
        user {
          email
        }
        confirmationSentAt
      }
      total
      page
    }
  }
`
