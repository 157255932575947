import { Type } from 'class-transformer'
import { CoinHistoryDataType } from './CoinHistoryDataType'

export class CoinHistoryDataResponse {
  @Type(() => CoinHistoryDataType)
  data: CoinHistoryDataType[]

  total: number

  page: number
}
