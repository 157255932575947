import { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { SalesReportSearchFormType } from '@models/salse/SalesReportSearchFormType'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { SaleReportQueryParam, SalesReportPageProps } from './interface'

export function withSalesReportPage(Component: React.FC<SalesReportPageProps>) {
  function WithSalesReportPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const [searchParam, setSearchParam] = useState<SaleReportQueryParam>({
      timeRange: TimeRangeEnum.MONTH,
      startAt: DateTime.now()
        .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
    })

    function formatDate({
      timeRange,
      startAt,
      endAt,
    }: SalesReportSearchFormType) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }
      return { timeRange, startAt: newStartAt, endAt: newEndAt }
    }

    async function handleSubmit(form: SalesReportSearchFormType) {
      const selectedDate = formatDate(form)
      if (selectedDate.startAt && selectedDate.endAt) {
        setSearchParam({
          timeRange: selectedDate.timeRange,
          startAt: selectedDate.startAt,
          endAt: selectedDate.endAt,
        })
      }
    }

    const { data: purchaseProportion = [] } = useQuery(
      ['purchase-proportion', searchParam],
      () =>
        client?.saleClient.getPurchaseProportion({
          searchParam,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const { data: salesReportsOverview } = useQuery(
      ['sales-reports-overview', searchParam],
      () =>
        client?.saleClient.getSalesReportsOverview({
          searchParam,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    async function handleExport() {
      try {
        await client?.saleClient.exportSalesReports({
          startDate: searchParam.startAt.toUTC(),
          endDate: searchParam.endAt.toUTC(),
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const data = {
      downloadTotal: salesReportsOverview?.downloadTotal || 0,
      remainCoin: salesReportsOverview?.remainCoin || 0,
      salesSystem: salesReportsOverview?.salesSystem || 0,
      donateSystem: salesReportsOverview?.donateSystem || 0,
      shareSystem: salesReportsOverview?.shareSystem || 0,
      activeUser: salesReportsOverview?.activeUser || 0,
      salesTotal: salesReportsOverview?.salesTotal || 0,
      amountTotal: salesReportsOverview?.amountTotal || 0,
      donateTotal: salesReportsOverview?.donateTotal || 0,
      purchaseProportion,
    }

    const newProps = {
      data,
      searchParam,
      handleSubmit,
      handleExport,
    }
    return <Component {...newProps} />
  }

  return WithSalesReportPage
}
