import { Expose } from 'class-transformer'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'

export class AgeConfirmType {
  @Expose() id?: number

  @Expose() avatarImgPath: string

  @Expose() username: string

  @Expose() ageVerify: AgeVerifyEnum

  @Expose() ageUploadedAt: string
}
