import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { ChapterListProps, WithChapterListProps } from './interface'

export function withChapterList(Component: React.FC<ChapterListProps>) {
  function Hoc({ bookId, searchParams }: WithChapterListProps) {
    const [sort, setSort] = useState<SortByType>({
      key: 'runningNo',
      order: SortingType.ASC,
    })

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }

    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['book-chapter-detail-dashboard', bookId, sort, searchParams],
        ({ pageParam = 1 }) =>
          client?.storyClient.getBookChapterDetailDashboard({
            bookId: Number(bookId),
            page: pageParam,
            startDate: searchParams.startDate,
            endDate: searchParams.endDate,
            ...sort,
          }),
        {
          enabled: !!bookId && !!searchParams,
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) {
              return lastPage.page + 1
            }

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const newProps = {
      data,
      isLoading,
      sort,
      handleSort,
      handleFetchNextPage,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
