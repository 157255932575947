import { gql } from 'graphql-request'

export const SEARCH_POPUP_BANNER = gql`
  query SearchAdminPopupBanner(
    $limitPerPage: Int
    $page: Int
    $orderBy: PopUpBannerOrderBy
    $searchText: String
    $startDate: DateTime
    $endDate: DateTime
    $status: PopupBannerStatus
  ) {
    searchAdminPopupBanner(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      searchText: $searchText
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      page
      total
      data {
        id
        title
        platforms
        viewCount
        uniqueViewerCount
        clickLinkCount
        startPublishedAt
        endPublishedAt
        status
      }
    }
  }
`
