import { createContext, useContext, useEffect, useState } from 'react'
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { DateTime } from 'luxon'
import cloneDeep from 'lodash.clonedeep'

import { useClient } from '@hooks/useClient'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { BookEnum } from '@interfaces/BookEnum'
import { TopChartEnum } from '@interfaces/TopChartEnum'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'
import { BookTopChartsParamType } from '@interfaces/story/BookTopChartsParamType'
import { BookTopChartsResponse } from '@models/story/BookTopChartsResponse'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'

interface BookTopChartProps extends FunctionComponentType {
  bookTopChartType: BookTopChartEnum
  limit: number
}

const initialQuery = {
  categoryId: '',
  limit: 10,
  bookType: BookEnum.NOVEL,
  topChartType: TopChartEnum.WEEK,
  bookTopChartType: BookTopChartEnum.VIEW,
  startDate: DateTime.local().minus({ days: 7 }).startOf('week'),
  endDate: DateTime.local().minus({ days: 7 }).endOf('week'),
}

const TopViewContext = createContext({
  data: undefined as
    | InfiniteData<BookTopChartsResponse | undefined>
    | undefined,
  query: initialQuery as BookTopChartsParamType,
  limit: 10,
  lastWeek: DateTime.local().minus({ days: 7 }),
  isLoading: true,
  handleCategoryChange: (_: string) => {},
  handleBookTypeChange: (_: BookEnum) => {},
  nextWeek: () => {},
  prevWeek: () => {},
  handleFetchNextPage: () => {},
  handleBanBook: async (topChartId: number, banReason?: string) => {},
})

export function BookTopChartProvider({
  children,
  bookTopChartType,
  limit,
}: BookTopChartProps) {
  const lastWeek = DateTime.local().minus({ days: 7 })
  const client = useClient()
  const alert = useAlert()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState({
    ...initialQuery,
    limit,
    categoryId: '',
    bookTopChartType,
    startDate: lastWeek.startOf('week'),
    endDate: lastWeek.endOf('week'),
  })

  const banModal = useModal({ modal: 'ban' })
  const confirmModal = useModal({
    modal: 'confirm',
  })

  const queryKey = [
    'top-chart',
    {
      ...query,
      startDate: query.startDate.toString(),
      endDate: query.endDate.toString(),
    },
  ]
  const {
    fetchNextPage,
    data: bookTopChart,
    isLoading,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      const res = await client?.storyClient.getBookTopCharts({
        ...query,
        page: pageParam,
      })

      return res
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage && lastPage.data.length && lastPage.page * limit < 200) {
          return lastPage.page + 1
        }

        return undefined
      },
    }
  )

  function handleFetchNextPage() {
    if (!isFetchingNextPage && !isFetching) fetchNextPage()
  }

  function handleCategoryChange(value: string) {
    setQuery(prev => ({
      ...prev,
      categoryId: value,
    }))
  }

  function handleBookTypeChange(value: BookEnum) {
    setQuery(prev => ({
      ...prev,
      bookType: value,
    }))
  }

  function nextWeek() {
    setQuery(prev => ({
      ...prev,
      startDate: prev.startDate.plus({ days: 7 }),
      endDate: prev.endDate.plus({ days: 7 }),
    }))
  }

  function prevWeek() {
    setQuery(prev => ({
      ...prev,
      startDate: prev.startDate.minus({ days: 7 }),
      endDate: prev.endDate.minus({ days: 7 }),
    }))
  }

  useEffect(() => {
    setQuery(prev => ({
      ...prev,
      bookTopChartType,
    }))
  }, [bookTopChartType])

  const { mutate: banHandler, isLoading: unBanLoading } = useMutation(
    async ({
      topChartId,
      banReason,
    }: {
      topChartId: number
      banReason?: string
    }) => {
      await client?.storyClient.banTopChartBooks({
        topChartId,
        banReason,
      })
      queryClient.setQueryData<
        InfiniteData<BookTopChartsResponse | undefined> | undefined
      >(queryKey, oldData => {
        if (oldData) {
          const tmp = cloneDeep(oldData)
          return {
            ...tmp,
            pages: tmp.pages.map(page => {
              if (page) {
                return {
                  ...page,
                  data: page?.data.map(row => {
                    return {
                      ...row,
                      isBan: row.id === topChartId ? !row.isBan : row.isBan,
                    }
                  }),
                }
              }
              return page
            }),
          }
        }
        return oldData
      })
    },
    {
      onSuccess: () => {
        alert.success('ดำเนินการสำเร็จ')
      },
      onError: () => {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ ได้สำเร็จ!')
      },
      onSettled: () => {
        banModal.hide()
        confirmModal.hide()
      },
    }
  )

  async function handleBanBook(topChartId: number, banReason?: string) {
    banHandler({ topChartId, banReason })
  }

  const value = {
    data: bookTopChart,
    query,
    limit,
    lastWeek,
    isLoading: unBanLoading || isLoading,
    handleCategoryChange,
    handleBookTypeChange,
    nextWeek,
    prevWeek,
    handleBanBook,
    handleFetchNextPage,
  }

  return (
    <TopViewContext.Provider value={value}>{children}</TopViewContext.Provider>
  )
}

export function useBookTopCharts() {
  return useContext(TopViewContext)
}
