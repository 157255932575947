import React from 'react'

import { BanTableProps } from './interface'

export function withBanTable(Component: React.FC<BanTableProps>) {
  function WithBanTable(props: BanTableProps) {
    return <Component {...props} />
  }

  return WithBanTable
}
