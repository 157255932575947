import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useMutation } from 'react-query'
import { ActionCellAcceptProps, ActionCellProps } from './interface'

export function withActionCell(Component: React.FC<ActionCellProps>) {
  function WithActionCell({
    id,
    refetchAllSalesByUser,
    refetchUserStopSapPayment,
    ...props
  }: ActionCellAcceptProps) {
    const client = useClient()
    const alert = useAlert()

    const { mutate: toggleIsStopSapPayment, isLoading: isCreateLoading } =
      useMutation(
        (userId: number) =>
          client!.saleByUserClient.toggleIsStopSapPayment(userId),
        {
          onSuccess: async () => {
            if (refetchUserStopSapPayment) {
              await refetchUserStopSapPayment()
              alert.success('เปิดการจ่ายเงินสำเร็จ')
            }
            if (refetchAllSalesByUser) {
              await refetchAllSalesByUser()
              alert.success('ปิดการจ่ายเงินสำเร็จ')
            }
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )
    async function handleActiveSapPayment() {
      await toggleIsStopSapPayment(id)
    }

    const componentProps = {
      ...props,
      id,
      handleActiveSapPayment,
    }

    return <Component {...componentProps} />
  }

  return WithActionCell
}
