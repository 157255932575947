import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Button } from '@components/Button'
import { InputField } from '@components/forms/InputField'
import { PasswordField } from '@components/forms/PasswordField'
import { LoginPageProps } from './interface'

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('กรุณากรอกอีเมล'),
  password: Yup.string().required('กรุณากรอกรหัสผ่าน'),
})

export function LoginPage({ handleLogin }: LoginPageProps) {
  return (
    <div className='w-[340px] mobile:hidden'>
      <div className='font-mitr text-[24px] text-center text-black-480 font-medium mt-[40px]'>
        เข้าสู่ระบบ
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleLogin}
        validationSchema={loginSchema}
      >
        {() => (
          <Form className='mobile:w-full'>
            <div className='py-[20px]'>
              <InputField
                type='text'
                name='email'
                placeholder='อีเมล'
                showErrorMsg
              />
            </div>
            <PasswordField
              name='password'
              placeholder='รหัสผ่าน'
              showErrorMsg
            />
            <div className='flex justify-end mb-[30px]'>
              <Link
                to='/forgotpassword'
                className='font-mitr text-[12px] text-black-480 font-medium underline mt-[5px] cursor-pointer'
              >
                ลืมรหัสผ่าน?
              </Link>
            </div>
            <Button
              type='submit'
              className='font-mitr text-[14px] w-[340px] text-white bg-primary px-[18px] py-[8px] rounded-[20px]'
            >
              เข้าสู่ระบบ
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
