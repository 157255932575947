import cn from 'classnames'

import { Box } from '@components/Box'
import { ChevronUpIcon } from '@components/Icons'
import { CoinDetailTable } from '@features/coin/pages/SummaryCoinPage/components/CoinDetailTable'
import { CoinDetailProps } from './interface'

export function CoinSection({
  text,
  value,
  icon,
  isGoldCoin = false,
  isRemaining = false,
}: {
  text: string
  value: number
  icon?: React.ReactNode
  isGoldCoin?: boolean
  isRemaining?: boolean
}) {
  return (
    <div className='flex flex-col font-light'>
      <p className='text-[14px] text-secondary-100'>{text}</p>
      <div className='flex gap-x-[10px] items-center'>
        {icon}
        <span
          className={cn('text-[24px]', {
            'text-gold': isGoldCoin,
            'text-secondary': !isGoldCoin,
            'font-bold': isRemaining,
          })}
        >
          {value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </div>
    </div>
  )
}

export function CoinDetail({
  systemCoins,
  showAllCoin,
  isByUser,
  toggleShowAllCoin,
}: CoinDetailProps) {
  return (
    <Box>
      <div className='text-[18px] font-bold text-secondary'>
        ข้อมูลเหรียญในระบบ
      </div>
      <div className='flex flex-col mt-[20px]'>
        {systemCoins?.map((row, index) => (
          <div
            key={row.coinId}
            className={cn('grid grid-cols-4 pb-[30px] border-b', {
              'pt-[30px]': index !== 0,
            })}
          >
            {row.isGoldCoin && (
              <CoinSection
                text={row.sellText}
                value={row.sell}
                isGoldCoin
                icon={
                  <img
                    className='w-[32px] h-[32px]'
                    src={row.coinImgPath}
                    alt={row.coinName}
                  />
                }
              />
            )}
            <CoinSection
              text={row.receivedText}
              value={row.received}
              isGoldCoin={row.isGoldCoin}
              icon={
                <img
                  className='w-[32px] h-[32px]'
                  src={row.coinImgPath}
                  alt={row.coinName}
                />
              }
            />

            <CoinSection
              text={row.usedText}
              value={row.used}
              icon={
                <img
                  className='w-[32px] h-[32px]'
                  src={row.coinImgPath}
                  alt={row.coinName}
                />
              }
            />

            {(row.isSilverCoin || row.isOtherCoin) && (
              <CoinSection
                text={row.expiredText}
                value={row.expired}
                icon={
                  <img
                    className='w-[32px] h-[32px]'
                    src={row.coinImgPath}
                    alt={row.coinName}
                  />
                }
              />
            )}

            <CoinSection
              text={row.remainText}
              value={row.remain}
              isGoldCoin={row.isGoldCoin}
              isRemaining
              icon={
                <img
                  className='w-[32px] h-[32px]'
                  src={row.coinImgPath}
                  alt={row.coinName}
                />
              }
            />
          </div>
        ))}
      </div>
      {!isByUser && (
        <button
          type='button'
          className={cn(
            'flex items-center text-primary text-[14px] font-bold mt-[20px] ml-auto'
          )}
          onClick={toggleShowAllCoin}
        >
          ดูรายการเหรียญคงเหลือทั้งหมด
          <ChevronUpIcon
            className={cn('text-primary', {
              'transform duration-300 ease': showAllCoin,
              'transform duration-300 ease rotate-180': !showAllCoin,
            })}
            width='16'
            height='16'
          />
        </button>
      )}
      {showAllCoin && <CoinDetailTable />}
    </Box>
  )
}
