import { withPermissionGuard } from '@libs/permission'
import { PromotionCodeDetailPage } from './PromotionCodeDetailPage'
import { withPromotionCodeDetailPage } from './withPromotionCodeDetailPage'

const ConnectedPromotionCodeDetailPage = withPermissionGuard(
  withPromotionCodeDetailPage(PromotionCodeDetailPage),
  'promotionSetting',
  'view'
)

export { ConnectedPromotionCodeDetailPage as PromotionCodeDetailPage }
