import React from 'react'

import { useField } from 'formik'
import { TextAreaFieldProps, WithTextAreaFieldProps } from './interface'

export function withTextAreaField(Component: React.FC<TextAreaFieldProps>) {
  function WithTextAreaField({
    className,
    textAreaClassName,
    name,
    label,
    placeholder,
    disabled,
    ...props
  }: WithTextAreaFieldProps) {
    const [field, { touched, error }] = useField(name)

    const compoenntProps = {
      className,
      textAreaClassName,
      label,
      placeholder,
      touched,
      error,
      disabled,
      ...props,
      ...field,
      ...props,
    }

    return <Component {...compoenntProps} />
  }

  return WithTextAreaField
}
