export enum CoinCoditionEnum {
  EQUALITY = ' EQUALITY',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
}

export const CoinCoditionTypeValue = {
  [CoinCoditionEnum.EQUALITY]: '=',
  [CoinCoditionEnum.GREATER_THAN]: '>',
  [CoinCoditionEnum.LESS_THAN]: '<',
  [CoinCoditionEnum.GREATER_THAN_EQUAL]: '>=',
  [CoinCoditionEnum.LESS_THAN_EQUAL]: '<=',
}
