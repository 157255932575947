import React from 'react'
import { SelectSearchProps } from './interface'

export function withSelectSearch(Component: React.FC<SelectSearchProps>) {
  function WithSelectSearch({ onChange, ...props }: SelectSearchProps) {
    function handleChange(value: string | number) {
      if (onChange) {
        onChange(value)
      }
    }

    const componentProps = {
      ...props,
      onChange: handleChange,
    }
    return <Component {...componentProps} />
  }

  return WithSelectSearch
}
