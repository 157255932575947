import React from 'react'
import cn from 'classnames'
import { Box } from '@components/Box'
import { formatDateDomini } from '@libs/utils'
import { PromotionCodeIcon } from '@components/Icons'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import {
  PromotionUserEnum,
  PromotionUserTypeValue,
} from '@interfaces/promotionCode/PromotionUserEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import {
  DaySelectEnum,
  daySelectTypeValue,
} from '@interfaces/promotionCode/DaySelectEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import {
  DiscountOptionEnum,
  DiscountOptionTypeValue,
} from '@interfaces/promotionCode/DiscountOptionEnum'
import {
  PaymentMethodEnum,
  PaymentMethodTypeValue,
} from '@interfaces/promotionCode/PaymentMethodEnum'
import {
  CoinCoditionEnum,
  CoinCoditionTypeValue,
} from '@interfaces/promotionCode/CoinCoditionEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'

interface PromotionCodeDetailProps {
  values: PromotionCodeFormType
  children?: React.ReactNode
}

export function PromotionCodeDetail({
  values,
  children,
}: PromotionCodeDetailProps) {
  const headerClassName = 'text-[18px] font-bold'
  const titleClassName = 'text-[12px] font-light text-secondary-100 mr-[5px]'
  return (
    <Box className='divide-y-[1px] gap-y-[20px] text-[14px] flex flex-col text-secondary'>
      <div className='flex items-center '>
        <div className='flex items-center gap-x-[10px] pr-[20px]  border-r-[1px]'>
          <PromotionCodeIcon />
          <span className='font-mitr font-medium text-[14px] text-primary whitespace-nowrap '>
            โปรโมชั่น Code
          </span>
        </div>
        <div className='flex items-center text-[18px] pl-[20px] font-bold line-clamp-1 break-word'>
          {values.promotionName}
        </div>
      </div>
      <div className='flex gap-x-[40px] pt-[20px]'>
        <div>
          <span className={cn(titleClassName)}>Promotion ID :</span>
          <span className='font-bold'>{values.businessId || '-'}</span>
        </div>
        <div>
          <span className={cn(titleClassName)}>วันที่สร้างโปรโมชั่น :</span>
          <span>{formatDateDomini(values.createdAt!, true)}</span>
        </div>
        <div
          className={cn({
            'mr-[40px]': values.publishedType === PromotionPublishedEnum.NOW,
          })}
        >
          <span className='text-[14px] mr-[5px] font -light text-secondary-100'>
            ระยะเวลาใช้โค้ด :
          </span>
          <span className='font-bold'>
            {values.publishedType === PromotionPublishedEnum.NOW
              ? 'ทันที'
              : formatDateDomini(values.startPublishedAt.toISOString(), true)}
          </span>
          <span className='text-[14px] px-[10px] font -light text-secondary-100'>
            ถึง
          </span>
          <span className='font-bold'>
            {formatDateDomini(values.endPublishedAt.toISOString(), true)}
          </span>
        </div>
      </div>
      <div className='flex flex-col pt-[20px] gap-y-[20px] '>
        <div className='flex'>
          <div className={cn('w-[120px]', titleClassName)}>รายละเอียด :</div>
          <div className='flex-1 whitespace-pre-line break-word'>
            {values.promotionDetail || '-'}
          </div>
        </div>
        <div className='flex'>
          <div className={cn('w-[120px]', titleClassName)}>
            เงื่อนไขการใช้งาน :
          </div>
          <div className='flex-1 whitespace-pre-line break-word'>
            {values.promotionCondition || '-'}
          </div>
        </div>
      </div>
      <div className='flex pt-[20px]'>
        <div className={cn('w-[120px]', titleClassName)}>ช่องทาง :</div>
        <div className='flex flex-1 flex-wrap gap-x-[20px]'>
          {values.channelName} ({values.codeChannelInput.toLocaleString()} Code)
        </div>
      </div>
      {values.forUserType === PromotionUserEnum.SPECIFY_USER && (
        <>
          <div className='flex pt-[20px] gap-x-[40px] items-center'>
            <div className='flex items-center'>
              <div className={cn('pr-[10px]', titleClassName)}>สำหรับ :</div>
              <div>กำหนดผู้ใช้งาน</div>
              {values.isFirstTopUp && ', เติมเงินครั้งแรก'}
            </div>
            {values.userIdsFile?.name && (
              <div className='flex items-center'>
                <div className={cn('pr-[10px]', titleClassName)}>
                  Upload file: :
                </div>
                <div className='font-bold'>
                  {values.userIdsFile?.name || '-'}
                </div>
              </div>
            )}
            <div className='text-secondary-100'>
              ({values.userIds.length.toLocaleString()} ID)
            </div>
          </div>
          <div className='flex pt-[20px] gap-x-[40px] items-center'>
            <div className='flex items-center'>
              {values.limitJoinPromotionType === LimitToJoinEnum.LIMIT ? (
                <div className={titleClassName}>
                  จำกัดการใช้โปรโมชั่น :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.limitJoinPromotion.toLocaleString()}
                  </span>
                  ครั้ง
                </div>
              ) : (
                <div className={titleClassName}>ไม่จำกัดจำนวน</div>
              )}
            </div>
            <div className='flex items-center'>
              {values.isLimitPromotionCode ? (
                <div className={titleClassName}>
                  จำกัดจำนวนสิทธิ์ :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.limitPromotionCode.toLocaleString()}
                  </span>
                  สิทธิ์
                </div>
              ) : (
                <div className={cn('whitespace-nowrap', titleClassName)}>
                  จำกัดจำนวนสิทธิ์ :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    ไม่จำกัดสิทธิ์
                  </span>
                </div>
              )}
            </div>
            <div className='flex items-center'>
              <div className={cn('ml-[40px]', titleClassName)}>
                ใช้ได้ในวัน :
              </div>
              <div className='ml-[10px] line-clamp-1 break-word'>
                {values.availableDay.length === 7
                  ? 'ทุกวัน'
                  : values.availableDay.map(item => (
                      <React.Fragment key={item}>
                        <span>{daySelectTypeValue[item as DaySelectEnum]}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className='flex pt-[20px]'>
            <span
              className={cn('mr-[10px] text-secondary-100 whitespace-nowrap')}
            >
              เงื่อนไข :
            </span>

            {values.userByType === UserByEnum.BUY_COIN && (
              <>
                <div className='text-secondary-100 flex gap-x-[40px]  items-center'>
                  <div className='flex'>
                    เมื่อผู้ใช้งานซื้อเหรียญ
                    <span className='text-secondary px-[10px]'>
                      {
                        CoinCoditionTypeValue[
                          values.userBuyCoinCondition as CoinCoditionEnum
                        ]
                      }
                    </span>
                    <span className='text-primary font-bold mr-[4px]'>
                      {values.userBuyCoinInput.toLocaleString()}
                    </span>
                    บาท
                  </div>
                  <div className='flex mr-[40px]'>
                    <span className='whitespace-nowrap'>ผ่านช่องทาง :</span>
                    <span className='ml-[10px] text-secondary max-w-[200px] break-word line-clamp-1'>
                      {values.paymentMethod.length === 4
                        ? 'ทุกช่องทาง'
                        : values.paymentMethod.map(item => (
                            <React.Fragment key={item}>
                              <span>
                                {
                                  PaymentMethodTypeValue[
                                    item as PaymentMethodEnum
                                  ]
                                }
                              </span>
                              <span className=' mr-1 last:mr-0 last:hidden'>
                                ,
                              </span>
                            </React.Fragment>
                          ))}
                    </span>
                  </div>
                </div>
                <div className='flex gap-x-[40px] whitespace-nowrap'>
                  <div>
                    รับเพิ่ม :
                    <span className='text-primary font-bold pl-[10px] pr-[3px]'>
                      {values.moreCoinInput.toLocaleString()}
                    </span>
                    <span className='text-secondary-100'>
                      {values.coinName}
                    </span>
                  </div>
                </div>
              </>
            )}
            {values.userByType === UserByEnum.REDEEM && (
              <div>
                <span className='text-secondary-100 mr-[40px]'>
                  เมื่อผู้ใช้งานรีดีมโค้ด
                </span>
                รับทันที :
                <span className='text-primary font-bold pl-[10px] pr-[3px]'>
                  {values.directCoinInput.toLocaleString()}
                </span>
                <span className='text-secondary-100'>{values.coinName}</span>
              </div>
            )}
          </div>
        </>
      )}
      {values.forUserType !== PromotionUserEnum.SPECIFY_USER && (
        <div className='flex flex-col pt-[20px] '>
          <div className='flex  gap-x-[40px] items-center'>
            <div className='flex items-center'>
              <div className={cn('pr-[10px]', titleClassName)}>สำหรับ :</div>
              <div>
                {
                  PromotionUserTypeValue[
                    values.forUserType as PromotionUserEnum
                  ]
                }
                {values.isFirstTopUp && ', เติมเงินครั้งแรก'}
              </div>
            </div>
            <div className='flex items-center'>
              {values.limitJoinPromotionType === LimitToJoinEnum.LIMIT ? (
                <div className={titleClassName}>
                  จำกัดการใช้โปรโมชั่น :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.limitJoinPromotion.toLocaleString()}
                  </span>
                  ครั้ง
                </div>
              ) : (
                <div className={titleClassName}>ไม่จำกัดจำนวน</div>
              )}
            </div>
            <div className='flex items-center'>
              {values.isLimitPromotionCode ? (
                <div className={titleClassName}>
                  จำกัดจำนวนสิทธิ์ :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    {values.limitPromotionCode.toLocaleString()}
                  </span>
                  สิทธิ์
                </div>
              ) : (
                <div className={cn('whitespace-nowrap', titleClassName)}>
                  จำกัดจำนวนสิทธิ์ :
                  <span className='text-[14px] px-[10px] text-secondary'>
                    ไม่จำกัดสิทธิ์
                  </span>
                </div>
              )}
            </div>
            <div className='flex items-center'>
              <div className={cn(titleClassName)}>ใช้ได้ในวัน :</div>
              <div className='ml-[10px] max-w-[240px] line-clamp-1 break-word'>
                {values.availableDay.length === 7
                  ? 'ทุกวัน'
                  : values.availableDay.map(item => (
                      <React.Fragment key={item}>
                        <span>{daySelectTypeValue[item as DaySelectEnum]}</span>
                        <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className='flex pt-[20px]'>
            <span className={cn('mr-[10px] text-secondary-100')}>
              เงื่อนไข :
            </span>

            {values.userByType === UserByEnum.BUY_COIN && (
              <>
                <div className='text-secondary-100 flex gap-x-[40px] mr-[40px] items-center'>
                  <div className='flex'>
                    เมื่อผู้ใช้งานซื้อเหรียญ
                    <span className='text-secondary px-[10px]'>
                      {
                        CoinCoditionTypeValue[
                          values.userBuyCoinCondition as CoinCoditionEnum
                        ]
                      }
                    </span>
                    <span className='text-primary font-bold mr-[4px]'>
                      {values.userBuyCoinInput.toLocaleString()}
                    </span>
                    บาท
                  </div>
                  <div className='flex'>
                    <span className='whitespace-nowrap'>ผ่านช่องทาง :</span>
                    <span className='ml-[10px] text-secondary break-word line-clamp-1'>
                      {values.paymentMethod.length === 4
                        ? 'ทุกช่องทาง'
                        : values.paymentMethod.map(item => (
                            <React.Fragment key={item}>
                              <span>
                                {
                                  PaymentMethodTypeValue[
                                    item as PaymentMethodEnum
                                  ]
                                }
                              </span>
                              <span className=' mr-1 last:mr-0 last:hidden'>
                                ,
                              </span>
                            </React.Fragment>
                          ))}
                    </span>
                  </div>
                </div>
                <div className='flex gap-x-[40px]'>
                  <div>
                    รับเพิ่ม :
                    <span className='text-primary font-bold pl-[10px] pr-[4px]'>
                      {values.moreCoinInput.toLocaleString()}
                    </span>
                    <span className='text-secondary-100'>
                      {values.coinName}
                    </span>
                  </div>
                </div>
              </>
            )}
            {values.userByType === UserByEnum.REDEEM && (
              <div>
                <span className='text-secondary-100 mr-[40px]'>
                  เมื่อผู้ใช้งานรีดีมโค้ด
                </span>
                รับทันที :
                <span className='text-primary font-bold pl-[10px] pr-[4px]'>
                  {values.directCoinInput.toLocaleString()}
                </span>
                <span className='text-secondary-100'>{values.coinName}</span>
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </Box>
  )
}
