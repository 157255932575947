import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Box } from '@components/Box'
import { Button } from '@components/Button'
import { InputField } from '@components/forms/InputField'

import { InviteEmailFormProps } from './interface'

export function InviteEmailForm({
  inviteList,
  onSubmit,
}: InviteEmailFormProps) {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('รูปแบบไม่ถูกต้อง')
      .required('จำเป็น')
      .test(
        'isDuplicate',
        'อีเมลซ้ำ',
        values => !inviteList.some(item => item.email === values)
      ),
  })
  return (
    <Box className='shadow-none !border-gray'>
      <Formik
        initialValues={{ email: '' }}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values)
          resetForm()
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h1 className='text-[18px] font-bold text-secondary mb-[30px]'>
              ส่งคำเชิญ
            </h1>
            <label className='text-[12px] text-secondary-100 font-light'>
              Email
            </label>
            <div className='flex items-start gap-x-[20px]'>
              <InputField
                name='email'
                type='email'
                className='w-[400px]'
                placeholder='พิมพ์อีเมลเพื่อส่งคำเชิญ'
              />
              <Button
                type='submit'
                disabled={isSubmitting}
                className='text-white bg-secondary text-[14px] w-[100px] font-medium'
              >
                เพิ่ม
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
