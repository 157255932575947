import React from 'react'
import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { queryClient } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionSaleDetailPageProps } from './interface'

export function withPromotionSaleDetailPage(
  Component: React.FC<PromotionSaleDetailPageProps>
) {
  function Hoc() {
    const navigate = useNavigate()
    const { id } = useParams()
    const client = useClient()
    const alert = useAlert()
    const queryKey: QueryKey = ['promotion', 'sale', Number(id)]
    const confirmModal = useModal({ modal: 'confirm' })

    const { data, isLoading } = useQuery(
      [...queryKey, 'detail'],
      () => client?.promotionSaleClient.getPromotionSaleDetail(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { mutateAsync: togglePromotionIsActive } = useMutation(
      ({ isActive, promotionId }: { isActive: boolean; promotionId: number }) =>
        client!.promotionClient.togglePromotionIsActive(isActive, promotionId),
      {
        onSuccess: () => {
          alert.success('ดำเนินการสำเร็จ')
          queryClient.invalidateQueries(queryKey)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    const { mutateAsync: deletePromotion } = useMutation(
      (promotionId: number) =>
        client!.promotionClient.deletePromotion(promotionId),
      {
        onSuccess: () => {
          alert.success('ลบโปรโมชั่นสำเร็จ')
          queryClient.invalidateQueries(['get-promotions'])
          navigate(`/promotion-setting`)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function handleActivePromotion() {
      if (id && Number(id)) {
        await togglePromotionIsActive({
          isActive: !data?.isActive,
          promotionId: Number(id),
        })
      }
    }

    function handleDeletePromotion() {
      if (id && Number(id)) {
        confirmModal.show({
          content: (
            <div className='w-[280px]'>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ลบโปรโมชั่น
              </p>
              <div className='flex flex-col text-center  mt-[20px] mb-[30px] text-[14px] font-light text-secondary'>
                คุณกำลังจะลบโปรโมชั่น
                <span className='font-bold'>&ldquo;{data?.name} &rdquo;</span>
                ต้องการที่จะดำเนินการต่อหรือไม่
              </div>
            </div>
          ),
          onConfirm: async () => {
            await deletePromotion(Number(id))
            confirmModal.hide()
          },
          onClose: () => {
            confirmModal.hide()
          },
          confirmButtonText: 'ยืนยัน',
        })
      }
    }

    function handleEditPromotion() {
      navigate(`/promotion-setting/promotion-sale/${id}/edit`)
    }

    const newProps: PromotionSaleDetailPageProps = {
      detail: data,
      isLoading,
      handleActivePromotion,
      handleDeletePromotion,
      handleEditPromotion,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
