import React from 'react'
import { useQuery } from 'react-query'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { client } from '@client/init'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PromotionSaleFormProp, WithPromotionSaleFormProp } from './interface'
import { ConfirmPromotionSaleModal } from './components/ConfirmPromotionSaleModal'

export function withPromotionSaleForm(
  Component: React.FC<PromotionSaleFormProp>
) {
  function Hoc({
    handleSubmit,
    title,
    saleCoinOptions,
    ...props
  }: WithPromotionSaleFormProp) {
    const confirmInfoModal = useModal({ modal: 'confirm' })

    const { data: activeCoinOptions = [] } = useQuery('activeCoinOptions', () =>
      client?.coinClient.getVisibleActiveCoinOptions()
    )

    const { data: categoryOptions = [] } = useQuery('category-options', () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          ...row,
          value: String(row.value),
        }))
      )
    )

    function confirmCreate(values: PromotionSaleFormType) {
      confirmInfoModal.show({
        content: (
          <ConfirmPromotionSaleModal
            values={values}
            title={title}
            activeCoinOptions={activeCoinOptions}
            saleCoinOptions={saleCoinOptions}
            categoryOptions={categoryOptions}
          />
        ),
        onConfirm: async () => {
          await handleSubmit(values)
          confirmInfoModal.hide()
        },
        onClose: () => {
          confirmInfoModal.hide()
        },
      })
    }
    const newProps = {
      confirmCreate,
      activeCoinOptions,
      saleCoinOptions,
      categoryOptions,
      title,
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
