import { useMemo } from 'react'
import cn from 'classnames'

import { diffNow } from '@libs/utils'
import { Table } from '@components/Table'
import { Checkbox } from '@components/Checkbox'
import { Pagination } from '@components/Pagination'
import { ColumnType, SortingType } from '@components/Table/interface'
import { AdminUserType } from '@models/admin/AdminUserType'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { SelectDropdown } from '@components/SelectDropdown/SelectDropdown'
import { Search } from '@components/Search'
import { ButtonSelect } from './components/ButtonSelect'
import { AdminUserManagementProps } from './interface'

export function AdminUserManagement({
  data,
  page,
  perpage,
  total,
  selectedIds,
  roleOptions,
  pageChange,
  handleSearch,
  handleResetSearch,
  handleSelectRow,
  handleSelectAll,
  handleDeleteUsers,
  handleChangeRole,
  handleSort,
  isLoading,
}: AdminUserManagementProps) {
  const permission = usePermissionAction('admin')
  const columns: ColumnType<AdminUserType>[] = useMemo(() => {
    return [
      {
        column: (
          <Checkbox
            checked={
              selectedIds.length > 0 && selectedIds.length === data.length
            }
            onChange={event =>
              handleSelectAll((event.target as HTMLInputElement).checked)
            }
          />
        ),
        actionCell: ({ obj }) => (
          <Checkbox
            checked={selectedIds.includes(obj.id)}
            onChange={() => handleSelectRow(obj.id)}
          />
        ),
        isAction: true,
        cellClassName: cn('w-[60px] pr-[10px]', {
          hidden: !permission.delete,
        }),
      },
      {
        column: 'ID',
        accessor: 'businessUserId',
        cell: ({ value }) => <p>{value || '-'}</p>,
        isSortable: true,
        sortKey: 'businessUserId',
        cellClassName: 'w-[120px] max-w-[120px] pr-[10px]',
      },
      {
        column: 'ชื่อ',
        accessor: 'user.displayName',
        cell: ({ value }) => (
          <p className='font-mitr line-clamp-1 break-word'>{value}</p>
        ),
        isSortable: true,
        sortKey: 'displayName',
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'อีเมล',
        accessor: 'user.email',
        cell: ({ value }) => (
          <p className='font-mitr line-clamp-1 break-word'>{value}</p>
        ),
        isSortable: true,
        sortKey: 'email',
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'Role',
        accessor: 'role.id',
        cell: ({ value, obj }) => (
          <SelectDropdown
            defualtInputColor='bg-blue-2 border-none'
            className='w-[180px]'
            disabled={!permission.edit}
            inputClassName='font-light'
            value={String(value)}
            options={roleOptions}
            handleSelect={rolesId => handleChangeRole(obj.id, Number(rolesId))}
          />
        ),
        isSortable: true,
        sortKey: 'name',
        cellClassName: 'w-[250px] max-w-[250px] py-[10px] pr-[10px]',
      },
      {
        column: 'เช้าใช้งานล่าสุด',
        accessor: 'lastLoginAt',
        cell: ({ value }) => (
          <p className='font-normal'>{value ? diffNow(value) : '-'}</p>
        ),
        isSortable: true,
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'Action',
        actionCell: ({ obj }) => (
          <ButtonSelect deleteUserHandler={() => handleDeleteUsers(obj.id)} />
        ),
        isAction: true,
        cellClassName: cn('w-[50px] max-w-[50px]', {
          hidden: !permission.delete,
        }),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, roleOptions, selectedIds, permission])

  return (
    <div className='flex flex-col gap-y-[30px] mt-[30px]'>
      <Search
        placeholder='ค้นหาด้วยชื่อ, ID หรือ email'
        onSearch={handleSearch}
        onReset={handleResetSearch}
      />
      <div className='border-b border-gray' />
      <div className='flex items-center justify-between'>
        <div className='font-light text-secondary-100'>
          จำนวนที่พบ :{' '}
          <span className='font-bold text-secondary'>
            {total.toLocaleString()}
          </span>{' '}
          รายการ
        </div>
        <button
          type='button'
          className={cn(
            'rounded-[20px] px-5 py-[5px] text-sm font-medium font-mitr text-red bg-[#feeeef] disabled:opacity-30',
            {
              hidden: !permission.delete,
            }
          )}
          onClick={() => handleDeleteUsers(selectedIds)}
          disabled={selectedIds.length === 0}
        >
          ลบผู้ใช้งาน
        </button>
      </div>

      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'businessUserId',
          order: SortingType.DESC,
        }}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
      />
      <Pagination
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
      />
    </div>
  )
}
