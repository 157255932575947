import cn from 'classnames'
import { ProgressBar } from '@components/ProgressBar'
import { PurchaseProportionProps } from './interface'

export function PurchaseProportion({
  data,
  className,
}: PurchaseProportionProps) {
  const temp = [...data]
  const maxValue = Math.max(...data.map(i => i.total))
  const topThree = temp.sort((a, b) => b.total - a.total).slice(0, 3)
  return (
    <div className='flex flex-col my-[30px]'>
      <h1 className='text-[24px] font-bold text-secondary mb-[20px]'>
        สัดส่วนการซื้อผ่านช่องทาง
      </h1>
      <div
        className={cn(
          'grid grid-cols-1 gap-y-[10px] text-[14px] font-light',
          className
        )}
      >
        {data.map((item, i) => (
          <div key={i} className='flex gap-x-[10px] items-center'>
            <div className='w-[160px] text-secondary'>{item.paymentMethod}</div>
            <div
              className={cn('w-[60px] text-right', {
                'text-primary': topThree.some(
                  element => element.paymentMethod === item.paymentMethod
                ),
                'text-secondary-100': !topThree.some(
                  element => element.paymentMethod === item.paymentMethod
                ),
              })}
            >
              <span>{item.total.toLocaleString()}</span>
            </div>
            <ProgressBar
              value={item.total}
              max={maxValue}
              className='flex-1'
              progressBarClassName={cn('rounded-full', {
                'bg-primary': topThree.some(
                  element => element.paymentMethod === item.paymentMethod
                ),
                'bg-gray-400': !topThree.some(
                  element => element.paymentMethod === item.paymentMethod
                ),
              })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
