import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class ChapterOptionType {
  @Expose() id: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() status: ChapterStatusEnum
}
