import { Outlet } from 'react-router-dom'
import { CategoryManagePage } from './pages/CategoryManagePage'
import { StickerManagementPage } from './pages/StickerManagementPage'
import { StickerManagementEditPage } from './pages/StickerManagementEditPage'
import { StickerManagementAddPage } from './pages/StickerManagementAddPage'
import { BookStorySettingPage } from './pages/BookStorySettingPage'
import { CategoryCoverPage } from './pages/CategoryCoverPage'
import { CoverListPage } from './pages/CoverListPage'
import { CoverCreatePage } from './pages/CoverCreatePage'
import { CoverEditPage } from './pages/CoverEditPage'
import { ReportListPage } from './pages/ReportListPage'
import { ReportListDetailPage } from './pages/ReportListDetailPage'
import { BookStorySettingAddPage } from './pages/BookStorySettingAddPage'
import { BookStorySettingEditPage } from './pages/BookStorySettingEditPage'

export const chapterBookRoutes = [
  {
    path: 'chapter-book',
    element: <Outlet />,
    children: [
      {
        path: 'book-story-setting',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <BookStorySettingPage />,
          },
          {
            path: 'create',
            element: <BookStorySettingAddPage />,
          },
          {
            path: 'edit/:id',
            element: <BookStorySettingEditPage />,
          },
        ],
      },
      {
        path: 'manage-category',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <CategoryManagePage />,
          },
        ],
      },
      {
        path: 'sticker-management',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <StickerManagementPage />,
          },
          {
            path: 'add',
            element: <StickerManagementAddPage />,
          },
          {
            path: ':id',
            element: <StickerManagementEditPage />,
          },
        ],
      },
      {
        path: 'cover',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <CoverListPage />,
          },
          {
            path: ':id',
            element: <CategoryCoverPage />,
          },
          {
            path: ':id/create',
            element: <CoverCreatePage />,
          },
          {
            path: ':id/edit/:coverId',
            element: <CoverEditPage />,
          },
        ],
      },
      {
        path: 'report-list',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ReportListPage />,
          },
          {
            path: ':id',
            element: <ReportListDetailPage />,
          },
        ],
      },
    ],
  },
]
