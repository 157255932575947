/* eslint-disable max-classes-per-file */
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { Expose, Type } from 'class-transformer'

class WriterType {
  affiliation: AffiliationEnum
}

class UserType {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath: string

  @Type(() => WriterType)
  writer: WriterType
}

export class PenNameType {
  @Expose() id: number

  @Expose() firstPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}
