import { TopRankTemplate } from '@components/TopRankTemplate'
import { BookTopChartProvider } from '@hooks/book/useBookTopChart'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'

export function TopViewPage() {
  return (
    <BookTopChartProvider bookTopChartType={BookTopChartEnum.VIEW} limit={20}>
      <TopRankTemplate title='Top View' />
    </BookTopChartProvider>
  )
}
