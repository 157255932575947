import { Expose, Transform, Type } from 'class-transformer'

import { Pagination } from '@models/pagination/Pagination'
import { WriterBookSaleDetailType } from './WriterBookSaleDetailType'

export class PaginatedWriterBookSaleDetail extends Pagination<WriterBookSaleDetailType> {
  @Expose()
  @Type(() => WriterBookSaleDetailType)
  @Transform(({ value }) => value || [])
  data: WriterBookSaleDetailType[]
}
