import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { ChallengeLevelType } from '@models/user-mission/ChallengeLevelType'
import { ChallengeUser } from '@models/user-mission/ChallengeUser'
import { ChallengeUserOptionResponse } from '@models/user-mission/ChallengeUserOptionResponse'
import { UserMissionType } from '@models/user-mission/UserMissionType'
import { UserMissionFormType } from '@models/user-mission/UserMissionFormType'
import { UserMissionLogsType } from '@models/user-mission/UserMissionLogsType'
import { UserMissionEnum } from '@interfaces/user-mission/UserMissionEnum'
import { LevelType } from '@models/user-mission/LevelType'
import { SortingEnum } from '@interfaces/SortingEnum'
import { UPLOAD_FILE } from './schemas/uploadFile'
import {
  GET_CHALLENGES,
  GET_CHALLENGE,
  GET_CHALLENGE_LEVEL_USER,
  GET_CHALLENGE_LEVEL_USER_COUNT,
  GET_CHALLENGE_LEVEL_LOGS,
} from './schemas/getChallenge'
import { CREATE_CHALLENGE_LEVEL } from './schemas/createChallenge'
import {
  UPDATE_CHALLENGE,
  UPDATE_CHALLENGE_LEVEL,
  TOGGLE_CHALLENGE_LEVEL,
} from './schemas/updateChallenge'
import { EXPORT_USER_MISSION } from './schemas/exportUserMissionDetail'

export class UserMissionClient {
  constructor(private client: GraphQLClient) {}

  async uploadFile(file: File | Blob): Promise<string> {
    const {
      uploadChallengePhoto: { filePath },
    } = await this.client.request(UPLOAD_FILE, {
      file,
    })

    return filePath
  }

  async getUserMissions(
    challengeType: UserMissionEnum
  ): Promise<UserMissionType[]> {
    const { challenges } = await this.client.request(GET_CHALLENGES, {
      challengeType,
    })

    const result = plainToInstance(UserMissionType, challenges as [])

    // TODO: ถามพี่ท๊อป
    const response = result.map((prev: UserMissionType) => ({
      ...prev,
      challengeLevels: prev.challengeLevels.map(
        (level: ChallengeLevelType, i: number) => ({
          ...level,
          toTarget:
            (prev.challengeLevels.length - 1 === i
              ? 0
              : prev.challengeLevels[i + 1].target) - 1,
        })
      ),
    }))

    return plainToInstance(UserMissionType, challenges as [])
  }

  async getUserMission(challengeId: number): Promise<UserMissionType> {
    const { challenge } = await this.client.request(GET_CHALLENGE, {
      challengeId,
    })

    return plainToInstance(UserMissionType, challenge)
  }

  async getChallengeLevelUser({
    challengeId,
    searchKeyword = '',
    page,
    perpage,
    orderKey,
    orderBy,
  }: {
    challengeId: number
    searchKeyword: string
    page: number
    perpage: number
    orderKey?: string
    orderBy?: string
  }): Promise<ChallengeUserOptionResponse> {
    const { challengeLevelUsers } = await this.client.request(
      GET_CHALLENGE_LEVEL_USER,
      {
        challengeId,
        searchKeyword,
        page,
        limitPerPage: perpage,
        orderDirection: orderBy === SortingEnum.ASC ? 'ASC' : 'DESC',
        orderBy: orderKey || undefined,
      }
    )
    const { paginatedChallengeLevelUsers, challenge } = challengeLevelUsers

    return plainToInstance(ChallengeUserOptionResponse, {
      ...paginatedChallengeLevelUsers,
      data: paginatedChallengeLevelUsers.data.map((user: ChallengeUser) => ({
        ...user,
        tableHeader: challenge.tableHeader,
      })),
    })
  }

  async getchallengeLevelUserCount(
    challengeId: number
  ): Promise<ChallengeLevelType[]> {
    const { challengeLevelUserCount } = await this.client.request(
      GET_CHALLENGE_LEVEL_USER_COUNT,
      {
        challengeId,
      }
    )

    return plainToInstance(ChallengeLevelType, challengeLevelUserCount as [])
  }

  async getUserMissionLogs(
    challengeId: number
  ): Promise<UserMissionLogsType[]> {
    const { challengeLevelLogs } = await this.client.request(
      GET_CHALLENGE_LEVEL_LOGS,
      {
        challengeId,
      }
    )

    return plainToInstance(UserMissionLogsType, challengeLevelLogs as [])
  }

  async updateUserMission(form: UserMissionFormType): Promise<void> {
    const { id, title, missionLink } = form
    let iconUrl = null
    if (form?.upload?.blob) {
      iconUrl = await this.uploadFile(form.upload.blob!)
    }

    await this.client.request(UPDATE_CHALLENGE, {
      updateChallengeInput: {
        id,
        title,
        ...(iconUrl && { iconUrl }),
        missionLink,
      },
    })
  }

  async updateEnableUserMission(form: {
    id: number
    active: boolean
  }): Promise<UserMissionType> {
    const { updateChallenge } = await this.client.request(UPDATE_CHALLENGE, {
      updateChallengeInput: { ...form },
    })

    return plainToInstance(UserMissionType, updateChallenge)
  }

  async createChallengeLevel(
    form: LevelType,
    challengeId: number
  ): Promise<ChallengeLevelType> {
    const { levelName, target } = form
    let iconUrl = form?.upload?.url || null
    if (form?.upload?.blob) {
      iconUrl = await this.uploadFile(form.upload.blob!)
    }

    const { createChallengeLevel } = await this.client.request(
      CREATE_CHALLENGE_LEVEL,
      {
        createChallengeLevelInput: {
          challengeId,
          levelName,
          target,
          iconUrl,
        },
      }
    )

    return plainToInstance(ChallengeLevelType, createChallengeLevel)
  }

  async updateChallengeLevel(form: LevelType): Promise<LevelType> {
    const { id, levelName, target } = form
    let iconUrl = form?.upload?.url || null
    if (form?.upload?.blob) {
      iconUrl = await this.uploadFile(form.upload.blob!)
    }

    const { updateChallengeLevel } = await this.client.request(
      UPDATE_CHALLENGE_LEVEL,
      {
        updateChallengeLevelInput: {
          id,
          levelName,
          target,
          iconUrl,
        },
      }
    )

    return plainToInstance(LevelType, updateChallengeLevel)
  }

  async updateEnableChallengeLevel(form: {
    id: number
    isActive: boolean
  }): Promise<void> {
    await this.client.request(TOGGLE_CHALLENGE_LEVEL, {
      input: { ...form },
    })
  }

  async exportUserMissionDetail({
    challengeId,
    challengeType,
  }: {
    challengeId: number
    challengeType: UserMissionEnum
  }) {
    await this.client.request(EXPORT_USER_MISSION, {
      challengeId,
      challengeType,
    })
  }
}
