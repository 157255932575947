import { Expose, Type } from 'class-transformer'

export class BoughtEbookDetailType {
  @Expose() id: number

  @Expose() username: string

  @Expose() createdAt: string

  @Expose() price: number

  @Expose() star: number

  @Expose() boughtSeriesCount: number
}

export class BoughtEbookDetailResponse {
  @Expose()
  @Type(() => BoughtEbookDetailType)
  data: BoughtEbookDetailType[]

  @Expose() total: number

  @Expose() page: number
}
