import { gql } from 'graphql-request'

export const ALL_CATEGORIES = gql`
  query AllCategories($bookType: [BookType!]) {
    allCategories(bookType: $bookType) {
      id
      name
      isNovel
      isManga
      categoryCovers {
        id
        imgPath
        coverName
      }
    }
  }
`
