import { gql } from 'graphql-request'

export const REMOVE_BOOK_STORY_SETTING = gql`
  mutation RemoveBookStorySetting($id: Int!) {
    removeBookStorySetting(id: $id) {
      id
      name
      maxCharacter
    }
  }
`
