import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { HistoryPromotionChallengePageProps } from './interface'

export function withHistoryPromotionChallengePage(
  Component: React.FC<HistoryPromotionChallengePageProps>
) {
  function Hoc() {
    const { id } = useParams()
    const navigate = useNavigate()
    const client = useClient()
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

    const { data: historyList = [], isLoading: listLoading } = useQuery(
      ['promotion', 'challenge', Number(id), 'history', 'list'],
      () => client?.promotionClient.getPromotionHistoryList(Number(id)),
      {
        onSuccess: res => {
          if (res && res.length >= 1) {
            setSelectedId(res[0].id)
          }
        },
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: historyData, isLoading: dataLoading } = useQuery(
      ['promotion', 'challenge', selectedId, 'history'],
      () => client?.promotionChallengeClient.getPromotionHistory(selectedId!),
      { enabled: !!selectedId }
    )

    const handleChangeSelectedId = (newIndex: number) => {
      setSelectedId(newIndex)
    }

    const newProps: HistoryPromotionChallengePageProps = {
      historyList,
      historyData,
      listLoading,
      dataLoading,
      selectedId,
      handleChangeSelectedId,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
