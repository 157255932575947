import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { ActiveUserHistoryDataType } from './ActiveUserHistoryDataType'
import { StorageSizeHistoryDataType } from './StorageSizeHistoryDataType'

export class ActiveUserHistoryType {
  @Expose({ name: 'key' }) keyType: KeyTypeEnum

  @Expose()
  @Type(() => ActiveUserHistoryDataType)
  @Transform(({ value }) => {
    const fillData = []
    const result = value.map((item: ActiveUserHistoryDataType) => item.value)
    const min = Math.min(...result)
    const max = Math.max(...result)
    let lastStorageSize
    for (let i = min; i <= max; i += 1) {
      const data = value.find(
        (item: ActiveUserHistoryDataType) => item.value === i
      )
      if (data) {
        fillData.push(data)
        lastStorageSize = data.size
      } else {
        fillData.push({ value: i, size: lastStorageSize })
      }
    }
    return fillData
  })
  data: ActiveUserHistoryDataType[]
}
