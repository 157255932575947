import { Expose, Type } from 'class-transformer'

export class BookPromotionDetailDashboardType {
  @Expose() price: number

  @Expose() promotionName: string

  @Expose() purchaseCount: number

  @Expose() revenue: number
}

export class BookPromotionDetailDashboardResponse {
  @Expose()
  @Type(() => BookPromotionDetailDashboardType)
  data: BookPromotionDetailDashboardType[]

  @Expose() page: number

  @Expose() total: number
}
