import { gql } from 'graphql-request'

export const GET_EBOOK_DETAIL = gql`
  query EbookDetail($ebookId: Int!) {
    ebookDetail(ebookId: $ebookId) {
      id
      coverImgPath
      title
      bookType
      purchaseCount
      avgRating
      viewCount
      totalSales
      reviewCount
      readFreeTrialCount
      penName {
        id
        firstPenName
        user {
          id
          displayName
          avatarImgPath
          writer {
            affiliation
          }
        }
      }
      category {
        id
        name
      }
      ebookStatus
    }
  }
`
