import { gql } from 'graphql-request'

export const GET_USER_COMMENT = gql`
  query GetUserComment(
    $userId: Int
    $startDate: DateTime
    $endDate: DateTime
    $page: Int
    $limitPerPage: Int
    $orderBy: CommentOrderBy
  ) {
    getUserComment(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      page: $page
      limitPerPage: $limitPerPage
      orderBy: $orderBy
    ) {
      page
      total
      data {
        content
        id
        book {
          id
          title
        }
        bookChapter {
          chapterTitle
          id
        }
        createdAt
      }
    }
  }
`
