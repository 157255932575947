import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { OptionType } from '@interfaces/OptionType'
import { S3_URL } from '@configs/config'
import { WriterSubmitDetailPageProps } from './interface'
import { useWriterDetail } from './hooks/useWriterDetail'

export function withWriterSubmitDetailPage(
  Component: React.FC<WriterSubmitDetailPageProps>
) {
  function WithWriterSubmitDetailPage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const rejectModal = useModal({ modal: 'writerReject' })
    const confirmModal = useModal({ modal: 'confirm' })
    const [countryOptions, setCountryOptions] = useState<OptionType[]>([])
    const { writer } = useWriterDetail()

    useEffect(() => {
      const getCountries = async () => {
        fetch(`${S3_URL}/countries.json`)
          .then(response => response.json())
          .then(jsonData => {
            setCountryOptions(jsonData)
          })
          .catch((error: any) => {
            const message = getErrorMessage(error)
            alert.error(message)
          })
      }
      getCountries()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mutate: updateApproval } = useMutation(
      (form: {
        id: number
        status: WriterStatusEnum
        rejectedReason?: string
      }) => client!.userWriterClient.verifyWriter(form),
      {
        onSuccess: () => {
          alert.success('อัปเดตเรียบร้อย')
          confirmModal.hide()
          navigate('/user/writer-submit')
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    function handleReject() {
      rejectModal.show({
        userId: writer?.user?.id,
        writerId: writer?.id,
        avatarImgPath: writer?.user?.avatarImgPath,
        fullName: writer?.user?.fullName,
        username: writer?.user?.username,
        status: writer?.status,
      })
    }

    function handleApprove() {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการอนุมัติหรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          if (writer?.id) {
            updateApproval({
              id: writer.id,
              status: WriterStatusEnum.APPROVED,
            })
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    if (!writer) return <></>

    const pageProps = {
      writer,
      handleReject,
      handleApprove,
      countryOptions,
    }

    return <Component {...pageProps} />
  }

  return WithWriterSubmitDetailPage
}
