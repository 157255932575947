interface UserCellProps {
  username: string
  coverImgPath: string
}

export function UserCell({ coverImgPath, username }: UserCellProps) {
  return (
    <div className='flex gap-x-[10px]'>
      {coverImgPath ? (
        <img
          className='w-[24px] h-[24px] rounded-full'
          src={coverImgPath}
          alt='User cover'
        />
      ) : (
        <div className='w-[24px] h-[24px] rounded-full bg-gray' />
      )}

      <span className='text-[14px] underline font-bold line-clamp-1 break-word '>
        @{username}
      </span>
    </div>
  )
}
