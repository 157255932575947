import { QueryObserverResult } from 'react-query'

import { ColumnType } from '@components/Table/interface'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import {
  SalesByUserResponse,
  SalesByUserType,
} from '@models/salesByUser/SalesByUserResponse'
import { ActionCell } from '../ActionCell'

export function salesByUserColumns(
  activeTab: UserManageMentSearchEnum,
  refetchAllSalesByUser: () => Promise<
    QueryObserverResult<SalesByUserResponse | undefined, unknown>
  >
) {
  const columns: ColumnType<SalesByUserType>[] = [
    {
      column: 'User ID',
      accessor: 'id',
      cell: ({ value }) => <p>{value}</p>,
      isSortable: true,
      cellClassName: 'max-w-[100px] w-[100px] pr-[20px]',
      sortKey: 'id',
    },
    {
      column: 'Username',
      accessor: 'username',
      cell: ({ value }) => (
        <p className='line-clamp-1 break-word font-bold'>@{value}</p>
      ),
      cellClassName: 'py-[10px] pr-[10px]',
      isSortable: true,
    },
    {
      column: 'มูลค่าเหรียญทอง',
      accessor: 'currentPaidCoin',
      cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
      isSortable: true,
      cellClassName: 'py-[8px] w-[160px] pr-[10px]',
    },
    {
      column: 'มูลค่าเหรียญเงิน',
      accessor: 'currentFreeCoin',
      cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
      isSortable: true,
      cellClassName: 'py-[8px] w-[160px] pr-[10px]',
    },
    {
      column: 'ยอดขายรวม',
      accessor: 'totalReceivedSales',
      cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
      isSortable: true,
      sortKey: 'userTotalReceivedSales',
      cellClassName: 'py-[8px] w-[160px] pr-[10px]',
    },
    {
      column: 'ยอดสนับสนุนรวม',
      accessor: 'totalReceivedDonation',
      cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
      isSortable: true,
      sortKey: 'userTotalReceivedDonation',
      cellClassName: 'py-[8px] w-[160px] pr-[10px]',
    },
    {
      column: 'ยอดจ่ายเงินรวม',
      accessor: 'totalIncome',
      cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
      isSortable: true,
      sortKey: 'userTotalIncome',
      cellClassName: 'py-[8px] w-[160px] pr-[10px]',
    },
    {
      column: 'Action',
      actionCell: ({ obj }) => (
        <ActionCell
          id={obj.id}
          isStopSapPayment={obj.isStopSapPayment}
          activeTab={activeTab}
          refetchAllSalesByUser={refetchAllSalesByUser}
        />
      ),
      isAction: true,
    },
  ]
  return columns
}
