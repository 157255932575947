import { gql } from 'graphql-request'

export const GET_ROLE = gql`
  query Role($id: Int!) {
    role(id: $id) {
      name
      permissions {
        actions
        name
        status
      }
    }
  }
`
