import { useState, useEffect } from 'react'
import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { WritingSearchType } from '@interfaces/user/WritingSearchType'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useParams } from 'react-router-dom'
import { useAuthentication } from '@hooks/useAuthentication'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { WritingSectionProps } from './interface'

export function withWritingSection(Component: React.FC<WritingSectionProps>) {
  function WithWritingSection() {
    const client = useClient()
    const { id } = useParams()
    const { isAuthenticated } = useAuthentication()
    const initialValues: WritingSearchType = {
      text: '',
      categories: [],
      bookType: [],
      bookStatus: BookStatusEnum.PUBLISHED,
    }
    const [searchParam, setSearchParam] =
      useState<WritingSearchType>(initialValues)
    const [sortTable, setSortTable] = useState({
      sortKey: 'bookId',
      sortOrder: SortingType.DESC,
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({})
    const { data: categories = [] } = useQuery(['category-options'], () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          value: String(row.value),
          text: row.text,
        }))
      )
    )
    const {
      data: writer,
      refetch,
      isLoading,
    } = useQuery(
      ['writer-list', page, perpage, pageChange, searchParam, sortTable, id],
      () =>
        client?.userClient.getWriterList(
          Number(id),
          perpage,
          page,
          searchParam,
          sortTable
        ),
      { enabled: isAuthenticated && !!id }
    )
    const { data: penNameOptions = [] } = useQuery(
      'pen-name-options',
      () =>
        client?.userClient.getUserPenNames(Number(id)).then(res =>
          res.map(row => ({
            value: row.firstPenName,
            label: row.firstPenName,
          }))
        ),
      { enabled: isAuthenticated && !!id }
    )

    useEffect(() => {
      handleTotalChange(writer?.total || 0)
    }, [handleTotalChange, writer])

    function handleSubmitSearch(param: WritingSearchType) {
      setSearchParam(prev => ({
        ...prev,
        text: param.text,
        categories: param.categories,
        bookType: param.bookType,
      }))
      refetch()
    }

    function handleSort({ key, order }: SortByType) {
      setSortTable(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function handleSelectTab(tab: string) {
      setSearchParam(prev => ({
        ...prev,
        bookStatus: tab as BookStatusEnum,
      }))
      setSortTable({
        sortKey: 'bookId',
        sortOrder: SortingType.DESC,
      })
    }

    const componentProps = {
      data: writer?.data || [],
      draftCount: writer?.draftCount || 0,
      publishedCount: writer?.publishedCount || 0,
      page,
      perpage,
      total,
      pageChange,
      isLoading,
      penNameOptions,
      categories,
      initialValues,
      searchParam,
      handleSubmitSearch,
      handleSort,
      handleSelectTab,
    }
    return <Component {...componentProps} />
  }
  return WithWritingSection
}
