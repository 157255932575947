import { withPermissionGuard } from '@libs/permission'
import { CreatePromotionCodePage } from './CreatePromotionCodePage'
import { withCreatePromotionCodePage } from './withCreatePromotionCodePage'

const ConnectedCreatePromotionCodePage = withPermissionGuard(
  withCreatePromotionCodePage(CreatePromotionCodePage),
  'promotionSetting',
  'create'
)

export { ConnectedCreatePromotionCodePage as CreatePromotionCodePage }
