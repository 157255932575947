import { gql } from 'graphql-request'

export const BULK_UPDATE_POPUP_BANNER = gql`
  mutation BulkUpdatePopupBanner(
    $bulkUpdatePopupBannerInput: BulkUpdatePopupBannerInput!
  ) {
    bulkUpdatePopupBanner(
      bulkUpdatePopupBannerInput: $bulkUpdatePopupBannerInput
    ) {
      message
    }
  }
`
