import cn from 'classnames'

export function TextInput({
  className,
  ...props
}: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      type='text'
      className={cn(
        className,
        'p-[10px] leading-10 rounded-[8px] border outline-none text-[14px] h-[40px] w-full placeholder:text-secondary-100 placeholder:font-light text-secondary'
      )}
      {...props}
    />
  )
}
