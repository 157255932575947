import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'

import { client } from '@client/init'
import { PopupBannerSearchFormType } from '@models/popupBanner/PopupBannerSearchFormType'
import { usePagination } from '@hooks/usePagination'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAlert } from '@hooks/useAlert'
import { REST_API_URL } from '@configs/config'
import { useAuthentication } from '@hooks/useAuthentication'
import { PopupBannerStatusEnum } from '@interfaces/PopupBannerStatusEnum'
import { getErrorMessage } from '@libs/utils'
import { PopupBannerDetailPageProps, SearchQueryParam } from './interface'

export function withPopupBannerDetailPage(
  Component: React.FC<PopupBannerDetailPageProps>
) {
  function WithPopupBannerDetailPage() {
    const queryClient = useQueryClient()
    const alert = useAlert()
    const { token } = useAuthentication()
    const { page, perpage, pageChange } = usePagination({ perpage: 20 })
    const [sort, setSort] = useState<SortByType>({
      key: 'id',
      order: SortingType.DESC,
    })
    const [queryParam, setQueryParam] = useState<SearchQueryParam>({
      searchText: '',
      startAt: undefined,
      endAt: undefined,
      status: PopupBannerStatusEnum.ALL,
    })

    function onResetForm() {
      setQueryParam({
        searchText: '',
        startAt: undefined,
        endAt: undefined,
        status: PopupBannerStatusEnum.ALL,
      })
    }

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }

    async function handleSearch(value: PopupBannerSearchFormType) {
      setQueryParam({
        ...value,
      })
    }

    async function handleExport() {
      try {
        await client?.popupBannerClient.exportPopupBanners({
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
          searchText: queryParam.searchText,
          key: sort.key,
          order: sort.order,
          status: queryParam.status,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const { data, isLoading } = useQuery(
      ['popup-banner', queryParam, sort, page],
      () =>
        client!.popupBannerClient.searchPopupBanner({
          limitPerPage: perpage,
          page,
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
          searchText: queryParam.searchText,
          sortKey: sort.key,
          sortOrder: sort.order,
          status: queryParam.status,
        })
    )

    const newProps = {
      queryParam,
      data,
      page,
      perpage,
      isLoading,
      sort,
      pageChange,
      handleSort,
      handleSearch,
      handleExport,
      onResetForm,
    }

    return <Component {...newProps} />
  }

  return WithPopupBannerDetailPage
}
