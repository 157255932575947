import { useCallback, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { SortByType } from '@components/Table/interface'
import { AdminUserResponse } from '@models/admin/AdminUserResponse'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { getErrorMessage } from '@libs/utils'
import { AdminUserManagementProps } from './interface'

export function withAdminUserManagement(
  Component: React.FC<AdminUserManagementProps>
) {
  function WithAdminUserManagement() {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const [searchParams, setSearchParams] = useState({
      search: '',
      orderBy: 'DESC',
      orderKey: 'businessUserId',
    })
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const { page, perpage, pageChange } = usePagination({ perpage: 20 })
    const confirmModal = useModal({ modal: 'confirm' })
    const alert = useAlert()
    const queryClient = useQueryClient()

    const {
      data: adminUser,
      refetch,
      isLoading,
    } = useQuery(
      ['admin-user', searchParams, page],
      () =>
        client?.adminClient.getAdminUser({
          ...searchParams,
          page,
          perpage,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const { data: roleOptions = [] } = useQuery(
      'roles',
      () => client?.adminClient.getRoles(),
      {
        enabled: isAuthenticated,
      }
    )

    function handleSearch(search: string) {
      pageChange(1)
      setSearchParams(prev => ({ ...prev, search }))
    }

    function handleResetSearch() {
      pageChange(1)
      setSearchParams({ search: '', orderBy: '', orderKey: '' })
    }

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSearchParams(prev => ({ ...prev, orderBy: order, orderKey: key }))
    }, [])

    function handleSelectRow(id: number) {
      setSelectedIds(prev =>
        prev.includes(id) ? prev.filter(value => value !== id) : [...prev, id]
      )
    }

    function handleSelectAll(checked: boolean) {
      setSelectedIds(checked ? adminUser?.data.map(({ id }) => id) ?? [] : [])
    }

    function handleDeleteUsers(ids: number | number[]) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบผู้ใช้หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.adminClient.removeAdmins(
              typeof ids === 'number' ? [ids] : ids
            )
            alert.success('ลบผู้ใช้สำเร็จ')
          } catch (error) {
            alert.error(
              'ไม่สามารถลบแอคเคาท์ได้ เนื่องจากแอคเคาท์นี้มีการอนุมัติงานไว้แล้ว'
            )
          } finally {
            confirmModal.hide()
            refetch()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    async function handleChangeRole(id: number, rolesId: number) {
      try {
        const newData = await client?.adminClient.updateAdminRoleUser(
          id,
          rolesId
        )
        if (newData) {
          queryClient.setQueryData<AdminUserResponse | undefined>(
            ['admin-user', searchParams, page],
            (oldData?: AdminUserResponse) => {
              if (oldData) {
                return {
                  ...oldData,
                  data: oldData.data.map(item =>
                    item.id === id ? newData : item
                  ),
                }
              }
              return oldData
            }
          )
          alert.success('แก้ไข Role ผู้ใช้สำเร็จ')
        }
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }
    const pageProps = {
      data: adminUser?.data || [],
      page,
      total: adminUser?.total || 0,
      perpage,
      selectedIds,
      roleOptions,
      pageChange,
      handleSearch,
      handleResetSearch,
      handleSelectRow,
      handleSelectAll,
      handleDeleteUsers,
      handleSort,
      handleChangeRole,
      isLoading,
    }
    return <Component {...pageProps} />
  }

  return WithAdminUserManagement
}
