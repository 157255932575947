import { gql } from 'graphql-request'

export const GET_WRITER_SALES_DETAIL_BY_PRODUCT_TYPE = gql`
  query GetWriterSalesDetailByProductType(
    $getWriterSalesDetailByProductTypeInput: GetWriterSalesDetailByProductTypeInput!
  ) {
    getWriterSalesDetailByProductType(
      getWriterSalesDetailByProductTypeInput: $getWriterSalesDetailByProductTypeInput
    ) {
      salesDetail {
        data {
          id
          name
          price
          purchasedCount
          sales
          writerIncome
        }
        total
        page
      }
    }
  }
`
