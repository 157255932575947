import cn from 'classnames'

import { usePortalDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon } from '@components/Icons'
import { SelectCoinProps } from './interface'

function SelectCoin({
  options,
  value,
  className,
  disabled,
  handleSelect,
}: SelectCoinProps) {
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  return (
    <button
      type='button'
      disabled={disabled}
      className={cn(
        'w-[150px] mobile:w-[124px] h-[30px] border border-[#eeeeee] rounded-[8px] text-[14px] px-[10px] text-secondary cursor-pointer',
        {
          'cursor-no-drop opacity-40': disabled,
        },
        className
      )}
      onClick={toggleDropdown}
    >
      <div className='flex h-full items-center justify-between'>
        <div className='flex items-center gap-x-[10px]'>
          <img
            className='w-[17px] h-[17px]'
            src='/images/gold-coin.webp'
            alt='gold-coin'
          />
          {value === 0
            ? options?.find(row => row.value === value)?.label
            : value.toLocaleString()}
        </div>
        <ChevronDownIcon width='16' height='16' />
      </div>
      {isShowDropdown && (
        <Dropdown>
          <div className='w-[150px] mobile:w-[124px] z-10 bg-white border border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow'>
            {options.map((row, index) => (
              <div
                className='flex items-center text-sm py-[10px] px-[10px] gap-x-[10px] hover:text-primary hover:bg-[#f2f9ff]'
                key={index}
                onClick={() => {
                  handleSelect(row.value)
                }}
              >
                <img
                  className='w-[17px] h-[17px]'
                  src='/images/gold-coin.webp'
                  alt='gold-coin'
                />
                {row.label}
              </div>
            ))}
          </div>
        </Dropdown>
      )}
    </button>
  )
}

export default SelectCoin
