import React from 'react'

import { WriterTableProps } from './interface'

export function withWriterTable(Component: React.FC<WriterTableProps>) {
  function WithWriterTable({ ...props }: WriterTableProps) {
    return <Component {...props} />
  }

  return WithWriterTable
}
