import React from 'react'

export function withTextArea(
  Component: React.FC<React.HTMLProps<HTMLTextAreaElement>>
) {
  function WithTextArea(props: React.HTMLProps<HTMLTextAreaElement>) {
    return <Component {...props} />
  }

  return WithTextArea
}
