import { RolePermissionPageProps } from './interface'

export function RolePermissionPage({
  Table,
  handleSort,
}: RolePermissionPageProps) {
  return (
    <div className='mt-[30px]'>
      <Table
        onSort={handleSort}
        headerClassName='border-b-[1px] border-gray text-secondary-100 text-[12px] h-[40px] font-bold'
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary border-b-[1px]'
      />
    </div>
  )
}
