import { gql } from 'graphql-request'

export const EXPORT_EBOOK_DETAIL_DASHBOARD = gql`
  query ExportEbookDetailDashboard(
    $startDate: DateTime!
    $endDate: DateTime!
    $ebookId: Int!
  ) {
    exportEbookDetailDashboard(
      startDate: $startDate
      endDate: $endDate
      ebookId: $ebookId
    )
  }
`
