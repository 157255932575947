import { Expose, Type } from 'class-transformer'

import { SubCorrectionDetailType } from './SubCorrectionDetailType'

export class CorrectionDetailType {
  @Expose()
  @Type(() => SubCorrectionDetailType)
  title: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  link: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  screen: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  platforms: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  publishedType: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  startPublishedAt: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  endPublishedAt: SubCorrectionDetailType

  @Expose()
  @Type(() => SubCorrectionDetailType)
  userTypes: SubCorrectionDetailType
}
