import { gql } from 'graphql-request'

export const GET_PERFORMANCE = gql`
  query GetPerformanceDashboard(
    $dashboardType: DashboardType!
    $startDate: DateTime
    $endDate: DateTime
    $keyType: KeyType!
  ) {
    getPerformanceDashboard(
      dashboardType: $dashboardType
      startDate: $startDate
      endDate: $endDate
      keyType: $keyType
    ) {
      totalUser
      totalPageView
      avgSessionDuration
      bounceRate
      storageSize
      sessionHistory {
        key
        data {
          totalSession
          value
        }
      }
      trafficCategory {
        name
        total
      }
      deviceCategory {
        name
        total
      }
      topPageView {
        name
        view
      }
      storageSizeHistory {
        key
        data {
          value
          size
        }
      }
      activeUserHistory {
        key
        data {
          value
          active1DayUsers
          active7DayUsers
          active28DayUsers
        }
      }
    }
  }
`
