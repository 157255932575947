import cn from 'classnames'

export function Typography({
  label,
  text,
  className,
}: {
  label: string
  text: string
  className?: string
}) {
  return (
    <div className='flex flex-col gap-y-[4px]'>
      <span className='text-[12px] font-bold min-w-[70px] text-secondary-100'>
        {label}
      </span>
      <span
        className={cn(
          'text-[14px] font-light text-secondary line-clamp-1 break-word',
          className
        )}
      >
        {text || '-'}
      </span>
    </div>
  )
}
