import { useState, useMemo } from 'react'
import { DateTime } from 'luxon'

import { formatDate } from '@libs/utils'
import { useBookTopCharts } from '@hooks/book/useBookTopChart'
import { TopRankTemplateProps, WithTopRankTemplateProps } from './interface'

export function withTopRankTemplate(Component: React.FC<TopRankTemplateProps>) {
  function WithTopRankTemplate({ title }: WithTopRankTemplateProps) {
    const dt = DateTime.local()
    const {
      data,
      query,
      limit,
      isLoading,
      lastWeek,
      handleCategoryChange,
      handleBookTypeChange,
      nextWeek,
      prevWeek,
      handleBanBook,
      handleFetchNextPage,
    } = useBookTopCharts()

    const disabledPrev = useMemo(
      () =>
        query.startDate.toFormat('yyyy-MM-dd') ===
        dt.startOf('year').endOf('week').toFormat('yyyy-MM-dd'),
      [query.startDate, dt]
    )

    const disabledNext = useMemo(
      () =>
        query.endDate.toFormat('yyyy-MM-dd') ===
        lastWeek.endOf('week').toFormat('yyyy-MM-dd'),
      [query.endDate, lastWeek]
    )

    const dateOfWeek = `${formatDate(
      query.startDate.toString()
    )} - ${formatDate(query.endDate.toString())}`

    const componentProps = {
      title,
      query,
      books: data,
      isLoading,
      limit,
      dateOfWeek,
      disabledPrev,
      disabledNext,
      handleCategoryChange,
      handleBookTypeChange,
      nextWeek,
      prevWeek,
      handleBanBook,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithTopRankTemplate
}
