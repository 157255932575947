import { useDropdown } from '@hooks/useDropdown'
import { DragIndicatorIcon } from '@components/Icons'
import { ButtonSelectProps } from './interface'

export function ButtonSelect({ deleteUserHandler }: ButtonSelectProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className='relative'>
      <button
        type='button'
        className='h-[30px] ml-[20px]'
        onClick={toggleDropdown}
      >
        <DragIndicatorIcon width='18' height='18' className='text-secondary' />
      </button>
      {isShowDropdown && (
        <div
          ref={dropdownRef}
          className='absolute w-[158px] z-10 text-[14px] font-[300] py-[10px] text-black-480 mt-[3px] right-0 bg-white shadow  rounded-[8px] max-h-[400px] overflow-y-auto'
        >
          <div
            className='flex items-center hover:bg-[#feeeef] hover:text-red cursor-pointer py-[5px] px-[15px]'
            onClick={() => {
              deleteUserHandler()
            }}
          >
            ลบผู้ใช้งาน
          </div>
        </div>
      )}
    </div>
  )
}
