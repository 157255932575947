import { WriterOptionType } from '@models/user/WriterOptionType'

export interface WithSearchWriterFieldProps {
  name: string
  placeholder?: string
  inputClassName?: string
  className?: string
  type: SearchActionEnum
  resetHandler?: () => void
}

export interface SearchWriterFieldProps {
  text: string
  placeholder?: string
  inputClassName?: string
  className?: string
  writers: WriterOptionType[]
  isShowSearchLog: boolean
  searchRef: React.RefObject<HTMLDivElement>
  showSearchLog: () => void
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectWriter: (writer: WriterOptionType) => void
  handleFetchNextPage: () => void
  removeTextSearch: () => void
}

export enum SearchActionEnum {
  WRITER = 'writer',
  USER = 'user',
}
