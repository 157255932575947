import cn from 'classnames'

interface UserMissionButtonProps {
  children: React.ReactNode
  active: boolean
  onClick: () => void
}

export function UserMissionButton({
  children,
  active,
  onClick,
}: UserMissionButtonProps) {
  return (
    <button
      className={cn('text-[14px] font-bold px-[20px] py-[10px] rounded-[8px]', {
        'bg-blue-2 text-secondary-100': !active,
        'bg-secondary text-white': active,
      })}
      type='button'
      onClick={onClick}
    >
      {children}
    </button>
  )
}
