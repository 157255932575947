import { withPermissionGuard } from '@libs/permission'
import { UserMissionPage } from './UserMissionPage'
import { withUserMissionPage } from './withUserMissionPage'

const ConnectedUserMissionPage = withPermissionGuard(
  withUserMissionPage(UserMissionPage),
  'userMission',
  'view'
)

export { ConnectedUserMissionPage as UserMissionPage }
