import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import {
  TopWriterBySalesTableProps,
  WithTopWriterBySalesTableProps,
} from './interface'

export function withTopWriterBySalesTable(
  Component: React.FC<TopWriterBySalesTableProps>
) {
  function WithTopWriterBySalesTable({
    searchParams,
  }: WithTopWriterBySalesTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'moneyReceived',
      sortOrder: 'DESC',
    })

    const { data = [], isLoading } = useQuery(
      ['writer-top-sale', searchParams, sort],
      () =>
        client!.dashboardClient.getTopWriterBySale({
          ...searchParams,
          ...sort,
        }),
      {
        enabled: !!searchParams,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort({ sortKey: key, sortOrder: order })
    }

    const newProps = { data, isLoading, handleSort }

    return <Component {...newProps} />
  }

  return WithTopWriterBySalesTable
}
