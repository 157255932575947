import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient, useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useAuthentication } from '@hooks/useAuthentication'
import { getErrorMessage } from '@libs/utils'
import { BookStorySettingFormType } from '@models/bookStorySetting/BookStorySettingFormType'
import { BookStorySettingAddPageProps } from './interface'

export function withBookStorySettingEditPage(
  Component: React.FC<BookStorySettingAddPageProps>
) {
  function WithBookStorySettingEditPage() {
    const { isAuthenticated } = useAuthentication()
    const client = useClient()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const params = useParams()
    const bookStorySettingId = Number(params.id)

    const { data: bookStorySetting, isLoading } = useQuery(
      ['book-story-setting', bookStorySettingId],
      () =>
        client?.bookStorySettingClient.getBookStorySettingDetail(
          bookStorySettingId
        ),
      {
        enabled: isAuthenticated && !!bookStorySettingId,
      }
    )

    async function handleSubmit(values: BookStorySettingFormType) {
      try {
        const newData =
          await client?.bookStorySettingClient.updateBookStorySetting(values)
        if (newData) {
          queryClient.setQueryData<BookStorySettingFormType | undefined>(
            ['book-story-setting', bookStorySettingId],
            (oldData?: BookStorySettingFormType) => {
              if (oldData) {
                return {
                  ...oldData,
                  ...newData,
                }
              }

              return oldData
            }
          )
        }
        alert.success('แก้ไขแบบฟอร์มสำเร็จ')
        navigate({
          pathname: '/chapter-book/book-story-setting',
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    if (!bookStorySetting || isLoading) {
      return <></>
    }

    const pageProps = {
      initialValues: bookStorySetting,
      handleSubmit,
    }
    return <Component {...pageProps} />
  }

  return WithBookStorySettingEditPage
}
