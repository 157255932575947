import { RoleManagement } from '@features/admin/components/RoleManagement'
import { initialValues } from '@constants/form/AddNewRole/initialValues'
import { AddNewRolePageProps } from './interface'

export function AddNewRolePage({ createRoleHandler }: AddNewRolePageProps) {
  return (
    <RoleManagement
      title='เพิ่ม Role'
      subTitle='ตั้งชื่อ Role'
      initialValues={initialValues}
      onSubmit={createRoleHandler}
    />
  )
}
