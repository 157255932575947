import { gql } from 'graphql-request'

export const GET_WRITER_OPTION = gql`
  query SearchWriterPaginate(
    $searchText: String
    $page: Int
    $limitPerPage: Int
  ) {
    searchWriterPaginate(
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        user {
          id
          avatarImgPath
          username
          penNameCount
        }
      }
      page
      total
    }
  }
`
