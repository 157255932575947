import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { BookType } from '@models/book/BookType'
import { Expose, Type } from 'class-transformer'

export class WriterType {
  @Expose() id: number

  @Expose() affiliation: AffiliationEnum

  @Expose() status: WriterStatusEnum
}

export class PenNameType {
  @Expose() id: number

  @Expose() firstPenName: string

  @Expose() secondPenName: string

  @Expose() books: BookType[]
}

export class UserStopSapPaymentType {
  @Expose() id: number

  @Expose() username: string

  @Expose() displayName: string

  @Expose()
  @Type(() => WriterType)
  writer: WriterType

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType[]
}

export class UserStopSapPaymentResponse {
  @Expose()
  @Type(() => UserStopSapPaymentType)
  data: UserStopSapPaymentType[]

  @Expose() total: number
}
