import { StorageUsageAcceptProps, StorageUsageProps } from './interface'

export function withStorageUsage(Component: React.FC<StorageUsageProps>) {
  function WithStorageUsage({
    storageSizeHistory,
    startDate,
    endDate,
    storageSize,
  }: StorageUsageAcceptProps) {
    const componentProps = {
      dataSize: storageSize,
      dateStart: startDate,
      dateEnd: endDate,
      ...storageSizeHistory,
    }
    return <Component {...componentProps} />
  }

  return WithStorageUsage
}
