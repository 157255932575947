import cn from 'classnames'

import { NumberWithDecimalFieldProps } from './interface'

export function NumberWithDecimalField({
  label,
  placeholder,
  className,
  inputClassName,
  disabled,
  pattern,
  error,
  touched,
  labelClassName,
  rightIcon,
  showErrorMsg = true,
  onBlurHandle,
  ...props
}: NumberWithDecimalFieldProps) {
  return (
    <div className={cn('w-full', className)}>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 mb-[4px] font-light',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <label className='relative flex items-center w-full'>
        <input
          className={cn(
            'flex w-full h-[40px] leading-10 border-[1px] focus:outline-none text-[14px] text-secondary font-light rounded-[8px] p-[10px] disabled:bg-[#eeeeee] disabled:text-[#979797] placeholder:text-secondary-100 focus:border-primary focus:text-primary',
            {
              'border-[#eeeeee]': !(touched && error),
              'border-[#f7575f]': touched && error,
              'pr-[30px]': rightIcon,
            },
            inputClassName
          )}
          autoComplete='off'
          onBlurCapture={onBlurHandle}
          placeholder={placeholder ?? label}
          disabled={disabled}
          pattern={pattern}
          // value={Number(props.value).toString()}
          {...props}
        />
        {rightIcon && (
          <div className='absolute right-0 mr-[10px]'>{rightIcon}</div>
        )}
      </label>
      {showErrorMsg && touched && error && (
        <div className='flex items-center mt-[10px]'>
          <div className='text-[12px] text-[#f7575f]'>{error}</div>
        </div>
      )}
    </div>
  )
}
