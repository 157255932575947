import { useFormikContext } from 'formik'
import cn from 'classnames'
import { useMemo } from 'react'

import { Box } from '@components/Box'
import { CheckboxField } from '@components/forms/CheckboxField'
import { InputField } from '@components/forms/InputField'
import { RadioInputField } from '@components/forms/RadioInputField'
import { SelectField } from '@components/forms/SelectField'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { UploadFileUserIdField } from '@components/UploadFileUserIdField'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { MultiSelectDay } from '@features/promotionSetting/components/MultiSelectDay'
import { PaymentMethod } from '@features/promotionSetting/components/PaymentMethod'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { DiscountOptionEnum } from '@interfaces/promotionCode/DiscountOptionEnum'
import { PromotionTypeEnum } from '@interfaces/PromotionTypeEnum'
import { NumberWithDecimalField } from '@components/forms/NumberWithDecimalField'
import { PromotionConditionProps } from './interface'

export function PromotionCondition({
  coinOptions,
  coinConditionOption,
  forUserTypeOptions,
  goldCoinId,
  viewMode = false,
  disableEdit = false,
}: PromotionConditionProps) {
  const { values, setFieldValue, errors } =
    useFormikContext<PromotionCodeFormType>()

  const userIdInputError = useMemo(() => {
    if (errors.userIdInput) {
      const error = errors.userIdInput.split('#')
      if (error.length === 1) {
        return errors.userIdInput
      }
      if (error.length === 3) {
        return (
          <>
            {error[0]}
            <span className='font-bold'>{error[1]}</span>
            {error[2]}
          </>
        )
      }
      return undefined
    }
    return undefined
  }, [errors.userIdInput])
  return (
    <Box>
      <div className='grid gap-y-[30px]'>
        <h1 className='text-[18px] font-bold text-secondary'>
          เงื่อนไขโปรโมชั่น
        </h1>
        <div className='border-b pb-[30px] space-y-[30px] font-light whitespace-nowrap'>
          <div className='flex space-x-4 items-start'>
            <div className='text-xs text-secondary mt-[10px]'>สำหรับ :</div>
            <div className='flex items-center space-x-10 h-[40px] '>
              {forUserTypeOptions.map((items, index) => (
                <PromotionRadioInputField
                  key={index}
                  name='forUserType'
                  label={items.label}
                  value={items.value}
                  disabled={viewMode || disableEdit}
                  checked={values.forUserType === items.value}
                />
              ))}
            </div>
            <div>
              <InputField
                name='userIdInput'
                className='w-[300px]'
                placeholder='กรอก User ID'
                showErrorMsg={false}
                onChange={() =>
                  setFieldValue('userIdInputError', 'checking ...')
                }
                disabled={
                  values.forUserType !== PromotionUserEnum.SPECIFY_USER ||
                  Boolean(values.userIdsFile) ||
                  Boolean(values.userIdsFileError) ||
                  viewMode ||
                  disableEdit
                }
              />
              <div
                className={cn(
                  'text-[12px] font-light text-[#f7575f] mt-[5px]',
                  {
                    hidden: !userIdInputError,
                  }
                )}
              >
                {userIdInputError}
              </div>
            </div>
            <CheckboxField
              name='isFirstTopUp'
              className='!ml-[40px] mt-[8px]'
              checked={!!values.isFirstTopUp}
              label='เติมเงินครั้งแรก'
              disabled={viewMode || disableEdit}
            />
          </div>
          {values.forUserType === PromotionUserEnum.SPECIFY_USER && (
            <>
              <UploadFileUserIdField
                name='userIdsFile'
                fieldValueName='userIds'
                fieldErrorName='userIdsFileError'
                setFieldValue={setFieldValue}
                promotionType={PromotionTypeEnum.CODE}
                disabled={
                  viewMode ||
                  Boolean(values.userIdInput) ||
                  Boolean(values.userIdInputError) ||
                  disableEdit
                }
              />
              {values.userIdsFileError && (
                <div className='!mt-4 text-red-74 text-xs font-light'>
                  *{values.userIdsFileError}
                </div>
              )}
            </>
          )}
        </div>

        <div className='flex items-center justify-between border-b pb-[30px]'>
          <div className='flex items-center  font-light whitespace-nowrap'>
            <div className='text-xs text-secondary'>จำกัดการใช้โปรโมชั่น :</div>
            <div className='ml-4 flex items-center'>
              <PromotionRadioInputField
                name='limitJoinPromotionType'
                label='&nbsp;'
                value={LimitToJoinEnum.LIMIT}
                disabled={viewMode}
                checked={
                  values.limitJoinPromotionType === LimitToJoinEnum.LIMIT
                }
              />
              <NumberWithCommaField
                showErrorMsg={false}
                name='limitJoinPromotion'
                className='w-[142px]'
                inputClassName='!pr-[90px]'
                rightIcon={
                  <span className='text-sm text-secondary-100'>
                    ครั้ง/ช่องทาง
                  </span>
                }
                disabled={
                  values.limitJoinPromotionType !== LimitToJoinEnum.LIMIT ||
                  viewMode ||
                  values.isFirstTopUp
                }
                maxLength={13}
              />
            </div>
            <div className='ml-10'>
              <PromotionRadioInputField
                name='limitJoinPromotionType'
                label='ไม่จำกัด'
                value={LimitToJoinEnum.UNLIMIT}
                disabled={viewMode || values.isFirstTopUp}
                checked={
                  values.limitJoinPromotionType === LimitToJoinEnum.UNLIMIT
                }
              />
            </div>
          </div>
          <div className='flex items-center font-light space-x-[10px]'>
            <CheckboxField
              name='isLimitPromotionCode'
              checked={!!values.isLimitPromotionCode}
              label='จำกัดจำนวนสิทธิ์'
              disabled={viewMode}
            />

            <NumberWithCommaField
              name='limitPromotionCode'
              showErrorMsg={false}
              className='w-[113px]'
              inputClassName='!pr-[43px]'
              rightIcon={
                <span className='text-sm text-secondary-100'>สิทธิ์</span>
              }
              disabled={!values.isLimitPromotionCode || viewMode}
            />
          </div>
          <div className='flex items-center font-light space-x-5'>
            <div className='flex-shrink-0 text-xs text-secondary'>
              ใช้ได้ในวัน
            </div>
            <MultiSelectDay
              name='availableDay'
              placeholder='เลือกวัน'
              className='w-[135px]'
              disabled={viewMode}
            />
          </div>
        </div>

        <div className='flex flex-col pb-[30px] border-b gap-y-[30px] items-center font-light whitespace-nowrap'>
          <div className='flex w-full items-center gap-x-[20px]'>
            <PromotionRadioInputField
              name='userByType'
              label='เมื่อผู้ใช้งานซื้อเหรียญ'
              value={UserByEnum.BUY_COIN}
              checked={values.userByType === UserByEnum.BUY_COIN}
              disabled={viewMode}
            />
            <SelectField
              name='userBuyCoinCondition'
              className='w-[70px]'
              options={coinConditionOption}
              disabled={values.userByType !== UserByEnum.BUY_COIN || viewMode}
            />
            <NumberWithCommaField
              name='userBuyCoinInput'
              showErrorMsg={false}
              className='w-[113px]'
              inputClassName='!pr-[43px]'
              rightIcon={
                <span className='text-sm text-secondary-100'>บาท</span>
              }
              disabled={values.userByType !== UserByEnum.BUY_COIN || viewMode}
              maxLength={13}
            />
            <div className=' text-[14px] text-secondary'>ผ่านช่องทาง</div>
            <PaymentMethod
              name='paymentMethod'
              placeholder='เลือกช่องทางชำระเงิน'
              disabled={values.userByType !== UserByEnum.BUY_COIN || viewMode}
              className='w-[179px]'
            />
          </div>
          <div className='flex w-full'>
            <PromotionRadioInputField
              name='userByType'
              label='เมื่อผู้ใช้งานรีดีมโค้ด'
              value={UserByEnum.REDEEM}
              checked={values.userByType === UserByEnum.REDEEM}
              disabled={viewMode}
            />
          </div>
        </div>
        <div className='flex flex-col pb-[30px] gap-y-[30px] items-center font-light whitespace-nowrap'>
          {values.userByType === UserByEnum.BUY_COIN && (
            <>
              <div className='flex w-full items-center gap-x-[20px]'>
                <span className='text-[14px] text-secondary font-light'>
                  รับเพิ่ม
                </span>
                <NumberWithDecimalField
                  name='moreCoinInput'
                  showErrorMsg={false}
                  className='w-[70px]'
                  disabled={viewMode}
                  maxLength={8}
                />
                <SelectField
                  name='moreCoinOption'
                  className='w-[139px]'
                  options={coinOptions.filter(item => !item.isGoldCoin)}
                  disabled={viewMode}
                />
              </div>
            </>
          )}
          {values.userByType === UserByEnum.REDEEM && (
            <div className='flex w-full items-center gap-x-[20px]'>
              <span className='text-[14px] text-secondary font-light'>
                รับทันที
              </span>
              <NumberWithDecimalField
                name='directCoinInput'
                showErrorMsg={false}
                className='w-[70px]'
                disabled={viewMode}
                isNoDecimal={values.directCoinOption === goldCoinId}
                maxLength={8}
              />
              <SelectField
                name='directCoinOption'
                className='w-[139px]'
                options={coinOptions}
                disabled={viewMode}
              />
            </div>
          )}
        </div>
      </div>
    </Box>
  )
}
