import { toast } from 'react-toastify'
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from '@components/Icons'

export function useAlert() {
  function error(message: string) {
    toast.error(message || '', {
      icon: <ErrorIcon />,
    })
  }

  function info(message: string) {
    toast.info(message || '', { icon: <InfoIcon /> })
  }

  function success(message: string) {
    toast.success(message || '', {
      icon: <SuccessIcon />,
    })
  }

  function warning(message: string) {
    toast.warning(message || '', { icon: <WarningIcon /> })
  }

  return {
    error,
    info,
    success,
    warning,
  }
}
