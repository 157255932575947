import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { SearchParamsType, UserManagementPageProps } from './interface'

export function withUserManagementPage(
  Component: React.FC<UserManagementPageProps>
) {
  function WithUserManagementPage() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const alert = useAlert()
    const client = useClient()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({
        perpage: 20,
        page: params.get('page') ? Number(params.get('page')) : 1,
      })
    const [searchParams, setSearchParams] = useState<SearchParamsType>(() => {
      const tab = params.get('tab') as UserManageMentSearchEnum

      return {
        userType: [
          UserManageMentSearchEnum.ALL,
          UserManageMentSearchEnum.PUBLISHER,
          UserManageMentSearchEnum.WRITER,
          UserManageMentSearchEnum.READER,
          UserManageMentSearchEnum.BAN,
        ].includes(tab)
          ? tab
          : UserManageMentSearchEnum.ALL,
        searchText: params.get('text') || '',
        sortKey: params.get('key') || 'userId',
        sortOrder: (params.get('order') as SortingType) || SortingType.DESC,
      }
    })
    const queryKey = ['find-user-managements', searchParams, page]

    const {
      data: userManagementTotal = {
        allCount: 0,
        publisherCount: 0,
        writerCount: 0,
        readerCount: 0,
        banCount: 0,
      },
      isSuccess: isUserManagementTotal,
    } = useQuery(
      ['find-user-managements', 'total', searchParams.searchText],
      () =>
        client!.userManagementClient.getTotalUserManagement(
          searchParams.searchText
        )
    )
    const statusOptions = [
      {
        label: 'ทั้งหมด',
        value: UserManageMentSearchEnum.ALL,
        total: userManagementTotal.allCount,
      },
      {
        label: 'สำนักพิมพ์',
        value: UserManageMentSearchEnum.PUBLISHER,
        total: userManagementTotal.publisherCount,
      },
      {
        label: 'นักเขียน',
        value: UserManageMentSearchEnum.WRITER,
        total: userManagementTotal.writerCount,
      },
      {
        label: 'นักอ่าน',
        value: UserManageMentSearchEnum.READER,
        total: userManagementTotal.readerCount,
      },
      {
        label: 'Banned',
        value: UserManageMentSearchEnum.BAN,
        total: userManagementTotal.banCount,
      },
    ]

    const { data, isLoading } = useQuery(
      queryKey,
      () =>
        client!.userManagementClient.getAllUserManagements({
          limitPerPage: perpage,
          page,
          ...searchParams,
        }),
      {
        enabled: isUserManagementTotal,
        onSuccess: response => {
          handleTotalChange(response?.total)
        },
      }
    )

    async function exportEmail() {
      try {
        await client?.userManagementClient.exportUserManagement()
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function handleSubmitSearch(searchText: string) {
      setSearchParams(prev => ({ ...prev, searchText }))
      pageChange(1)

      const query = new URLSearchParams()
      query.set('key', searchParams.sortKey)
      query.set('order', searchParams.sortOrder)
      query.set('tab', searchParams.userType)
      if (searchText) query.set('text', searchText)

      navigate(`/user/user-management?${query.toString()}`, { replace: true })
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({
        ...prev,
        sortKey: key,
        sortOrder: order,
      }))

      const query = new URLSearchParams()
      query.set('key', key)
      query.set('order', order)
      query.set('tab', searchParams.userType)
      query.set('page', String(page))
      if (searchParams.searchText) query.set('text', searchParams.searchText)

      navigate(`/user/user-management?${query.toString()}`, { replace: true })
    }

    function handleChangeStatus(userType: UserManageMentSearchEnum) {
      setSearchParams(prev => ({ ...prev, userType }))
      pageChange(1)

      const query = new URLSearchParams()
      query.set('key', searchParams.sortKey)
      query.set('order', searchParams.sortOrder)
      query.set('tab', userType)
      if (searchParams.searchText) query.set('text', searchParams.searchText)

      navigate(`/user/user-management?${query.toString()}`, { replace: true })
    }

    function handleReset() {
      setSearchParams(prev => ({ ...prev, searchText: '' }))
      pageChange(1)

      const query = new URLSearchParams()
      query.set('key', searchParams.sortKey)
      query.set('order', searchParams.sortOrder)
      query.set('tab', searchParams.userType)

      navigate(`/user/user-management?${query.toString()}`, {
        replace: true,
      })
    }

    function handlePageChange(value: number) {
      pageChange(value)

      const query = new URLSearchParams()
      query.set('key', searchParams.sortKey)
      query.set('order', searchParams.sortOrder)
      query.set('page', String(value))
      query.set('tab', searchParams.userType)
      if (searchParams.searchText) query.set('text', searchParams.searchText)

      navigate(`/user/user-management?${query.toString()}`, {
        replace: true,
      })
    }

    const componentProps = {
      data: data?.data ?? [],
      total,
      page,
      perpage,
      statusOptions,
      queryKey,
      searchParams,
      isLoading,
      pageChange: handlePageChange,
      handleSubmitSearch,
      handleChangeStatus,
      handleSort,
      handleReset,
      exportEmail,
    }
    return <Component {...componentProps} />
  }

  return WithUserManagementPage
}
