import { gql } from 'graphql-request'

export const GET_CHALLENGES = gql`
  query Challenges($challengeType: ChallengeType!) {
    challenges(challengeType: $challengeType) {
      id
      title
      description
      iconUrl
      challengeType
      active
      criteria
      challengeUnit
      tableHeader
      levels {
        id
        level
        levelName
        iconUrl
        target
        isActive
        userCount
      }
    }
  }
`

export const GET_CHALLENGE = gql`
  query Challenge($challengeId: Int!) {
    challenge(id: $challengeId) {
      id
      title
      description
      iconUrl
      challengeType
      active
      criteria
      challengeUnit
      tableHeader
      missionLink
      levels {
        id
        level
        levelName
        iconUrl
        target
        isActive
        userCount
        isUsed
      }
    }
  }
`

export const GET_CHALLENGE_LEVEL_USER_COUNT = gql`
  query Query($challengeId: Int!) {
    challengeLevelUserCount(challengeId: $challengeId) {
      id
      level
      target
      userCount
    }
  }
`

export const GET_CHALLENGE_LEVEL_USER = gql`
  query ChallengeLevelUsers(
    $challengeId: Int!
    $limitPerPage: Int
    $page: Int
    $orderDirection: OrderDirection
    $searchKeyword: String
    $orderBy: ChallengeLevelUsersOrderBy
  ) {
    challengeLevelUsers(
      challengeId: $challengeId
      limitPerPage: $limitPerPage
      page: $page
      orderDirection: $orderDirection
      searchKeyword: $searchKeyword
      orderBy: $orderBy
    ) {
      paginatedChallengeLevelUsers {
        data {
          id
          user {
            id
            displayName
            avatarImgPath
          }
          current
          challengeLevel {
            id
            level
          }
        }
        total
        page
      }
      challenge {
        tableHeader
      }
    }
  }
`

export const GET_CHALLENGE_LEVEL_LOGS = gql`
  query ChallengeLevelLogs($challengeId: Int!) {
    challengeLevelLogs(challengeId: $challengeId) {
      id
      userId
      level
      levelName
      target
      newTarget
      isActive
      challengeId
      iconUrl
      createdAt
      newLevelName
      type
      user {
        id
        avatarImgPath
        fullName
        displayName
      }
      challenge {
        id
        title
        description
        iconUrl
        challengeType
        active
        criteria
        challengeUnit
        tableHeader
      }
      challengeLevelLogs {
        id
        level
        levelName
        iconUrl
        target
        isActive
        userCount
      }
    }
  }
`
