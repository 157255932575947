import { Link } from 'react-router-dom'
import cn from 'classnames'
import Tooltip from '@reach/tooltip'
import {
  ArrowRight2Icon,
  BanCoverIcon,
  CharacterIcon,
  Information2Icon,
} from '@components/Icons'
import { ProgressBar } from '@components/ProgressBar'
import { CharacterCardProps, CharacterInformationProps } from './interface'

function CharacterInformation({ content }: CharacterInformationProps) {
  return (
    <div className='font-light text-[12px] text-[#0089ff] max-w-[288px]'>
      <div className='text-[10px] text-secondary'>รายละเอียด</div>
      <div className='whitespace-normal'>{content || '-'}</div>
    </div>
  )
}

export function CharacterCard({
  id,
  imageUrl,
  rank,
  nextRank,
  story,
  name,
  characterRole,
  bookId,
  bookName,
  coin,
  coinLeft,
  children,
  classNameImage,
  className,
  characterRankStartCoins,
  characterRankImg,
  banCover,
}: CharacterCardProps) {
  return (
    <div
      id='character-card'
      className={cn(
        'flex flex-col p-[20px] shadow rounded-[8px] bg-white border border-blue-2 gap-y-[10px] w-[260px] z-[1]',
        className
      )}
    >
      <div
        className={cn(
          'relative flex justify-center items-end w-[220px] h-[220px]',
          classNameImage
        )}
      >
        <img
          className='rounded-full'
          src={imageUrl || '/images/avatar.png'}
          alt='Character cover'
        />

        <div className='absolute top-0 left-0'>
          <img src={characterRankImg} width='65' height='65' alt={rank} />
        </div>
        <button
          className='font-light text-[14px] text-primary border border-gray absolute bg-white rounded-lg bottom-0 right-[40px] p-[5px]'
          type='button'
          onClick={banCover}
        >
          <BanCoverIcon width='18' height='18' />
        </button>
      </div>
      <Tooltip
        className='bg-[#f2f9ff] border border-[#0089ff] rounded-[8px] p-[10px]'
        label={<CharacterInformation content={story} />}
        style={{ zIndex: 100 }}
      >
        <button type='button'>
          <Information2Icon width='24' height='24' className='text-blue-50' />
        </button>
      </Tooltip>

      <p className='text-[14px] text-secondary-100 font-light line-clamp-1'>
        {bookName}
      </p>

      <div className='grid grid-cols-2'>
        <div className='font-mitr text-[16px] font-[500] line-clamp-1'>
          {name}
        </div>
        <div className='flex justify-end text-[14px] text-secondary-100 font-light'>
          {characterRole}
        </div>
      </div>
      {nextRank ? (
        <div className='flex justify-between items-center text-[14px] text-secondary-100 font-light'>
          <div className=''>
            <span>อีก {coinLeft.toLocaleString()} เหรียญ</span>
          </div>
          <ArrowRight2Icon height='21' />
          <div className='flex justify-end gap-x-[3px] '>
            <span className='font-mitr text-[16px] font-[500] text-yellow uppercase'>
              {nextRank}
            </span>
          </div>
        </div>
      ) : (
        <div className='text-center text-[14px] text-secondary-100 font-light'>
          Rank :{' '}
          <span className='font-mitr text-[16px] font-[500] text-yellow uppercase'>
            {rank}
          </span>
        </div>
      )}

      <ProgressBar
        className='!h-[4px]'
        progressBarClassName='bg-blue-50 !h-[4px]'
        value={coin - characterRankStartCoins}
        max={coinLeft + coin - characterRankStartCoins}
      />
      <div className='text-[14px] text-secondary-100 font-light'>
        <span>ได้รับ {coin.toLocaleString()} เหรียญ</span>
      </div>
      {children}
    </div>
  )
}
