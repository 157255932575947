import { Formik, Form } from 'formik'

import { Button } from '@components/Button'
import { EditIcon } from '@components/Icons'
import { InputField } from '@components/forms/InputField'
import { CropSquareImage } from '@components/CropSquareImage'
import { schema } from './validationSchema'
import { UserMissionFormProps } from './interface'

export function UserMissionForm({
  initialValues,
  isEdit,
  handleSwitchEditForm,
  onSubmit,
}: UserMissionFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        await onSubmit(values)
        handleSwitchEditForm()
      }}
    >
      {({ isValid, values, resetForm, setFieldValue }) => (
        <Form>
          <div className='flex items-center gap-x-[30px] p-[30px] border border-blue-2 shadow rounded-[8px] mb-[20px]'>
            {isEdit && (
              <CropSquareImage
                src={values.upload.url}
                title='เพิ่ม Badge'
                size={200}
                aspectRatio={1}
                onChange={(blob: Blob | null) => {
                  if (blob) {
                    setFieldValue('upload', {
                      url: URL.createObjectURL(blob),
                      blob,
                    })
                  }
                }}
                isCircle
              />
            )}

            {!isEdit && (
              <div className='w-[159px] aspect-square rounded-full overflow-hidden border border-gray'>
                <img
                  src={initialValues.upload.url}
                  className='w-full h-full'
                  alt='user mission'
                />
              </div>
            )}

            <div className='flex flex-col flex-1'>
              {isEdit && (
                <div className='flex flex-col gap-y-[24px]'>
                  <div className='flex items-end gap-x-[80px] justify-between'>
                    <div className='flex-1'>
                      <InputField
                        name='title'
                        label='ชื่อ Mission'
                        showErrorMsg={false}
                      />

                      <InputField
                        className='mt-[20px]'
                        name='missionLink'
                        label='Link'
                        showErrorMsg={false}
                      />
                    </div>

                    <div className='flex items-center gap-x-[20px]'>
                      <Button
                        className='text-black-480 text-sm font-medium underline w-[40px]'
                        type='button'
                        onClick={() => {
                          resetForm()
                          handleSwitchEditForm()
                        }}
                      >
                        ยกเลิก
                      </Button>

                      <Button
                        type='submit'
                        className='text-white text-sm font-medium bg-secondary w-[76px] h-[40px]'
                        disabled={!isValid}
                      >
                        บันทึก
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {!isEdit && (
                <div className='flex items-center justify-between'>
                  <div className='flex-1'>
                    <div className='flex flex-col gap-y-[4px]'>
                      <span className='text-xs font-light text-secondary-100'>
                        ชื่อ Mission
                      </span>
                      <span className='text-sm font-light text-secondary'>
                        {initialValues.title}
                      </span>
                    </div>
                    <div className='flex flex-col gap-y-[4px] mt-[20px]'>
                      <span className='text-xs font-light text-secondary-100'>
                        Link
                      </span>
                      <span className='text-sm font-light text-secondary'>
                        {initialValues.missionLink || '-'}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='flex items-center justify-center w-[30px] h-[30px] border border-blue-2 rounded-lg text-secondary-100'
                      onClick={handleSwitchEditForm}
                    >
                      <EditIcon width='20' height='20' />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
