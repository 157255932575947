import { Expose, Transform } from 'class-transformer'

export class BookChapterViewerResponse {
  @Expose()
  @Transform(({ value }) => value ?? 0)
  uniqueViewerBuyAllChapter: number

  @Expose()
  @Transform(({ value }) => value ?? 0)
  uniqueView: number
}
