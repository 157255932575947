import { Expose } from 'class-transformer'

export class SalesReportOverviewType {
  @Expose({ name: 'totalDownloadedCount' }) downloadTotal: number

  @Expose({ name: 'totalRemainingCoin' }) remainCoin: number

  @Expose({ name: 'totalBookAndBookChapterPlatformShare' }) salesSystem: number

  @Expose({ name: 'totalDonationPlatformShare' }) donateSystem: number

  @Expose({ name: 'totalPlatformShare' }) shareSystem: number

  @Expose({ name: 'dailyActiveUser' }) activeUser: number

  @Expose({ name: 'totalCoinSales' }) salesTotal: number

  @Expose({ name: 'totalBookAndBookChapterSales' }) amountTotal: number

  @Expose({ name: 'totalDonation' }) donateTotal: number
}
