import { gql } from 'graphql-request'

export const EXPORT_PROMOTION_CODE = gql`
  query ExportPromotionCode($promotionCodeId: Int!, $searchText: String) {
    exportPromotionCode(
      promotionCodeId: $promotionCodeId
      searchText: $searchText
    )
  }
`
