import React from 'react'
import cn from 'classnames'

import { DateTimeInputProps } from './interface'

export function withDateTimeInput(Component: React.FC<DateTimeInputProps>) {
  function WithDateTimeInput({ ...props }: DateTimeInputProps) {
    const componentProps = {
      ...props,
      inputClassName: cn(props.inputClassName, {
        'opacity-50': props.disabled,
      }),
    }

    return <Component {...componentProps} />
  }

  return WithDateTimeInput
}
