import React, { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DAY, getErrorMessage } from '@libs/utils'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PreviewPromotionChallenge } from '@features/promotionSetting/components/PromotionChallengeForm/components/PreviewPromotionChallenge'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { Loading } from '@components/Loading'
import { CreatePromotionChallengePageProps } from './interface'

export function withCreatePromotionChallengePage(
  Component: React.FC<CreatePromotionChallengePageProps>
) {
  function Hoc() {
    const [initialValues, setInitialValues] = useState<
      PromotionChallengeFormType | undefined
    >(undefined)
    const confirmInfoModal = useModal({ modal: 'confirm' })
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()

    const { data: activeCoinOptions = [] } = useQuery('activeCoinOptions', () =>
      client?.coinClient.getVisibleActiveCoinOptions()
    )

    const { data: challengeCoinOptions = [] } = useQuery(
      'challengeCoinOptions',
      () =>
        client?.coinClient.getPromotionChallengeCoinOptions().then(row => {
          if (row[0].value) {
            setInitialValues({
              name: '',
              description: '',
              userTerm: '',
              startPublishedAt: new Date(),
              endPublishedAt: new Date(Date.now() + DAY * 30),
              publishedType: PromotionPublishedEnum.NOW,
              action: PromotionChallengeActionEnum.LOVE,
              actionValue: 0,
              wordCount: 0,
              benefitValue: 0,
              benefitLimitType: PromotionChallengeLimitTypeEnum.ONCE,
              benefitLimitValue: 0,
              benefitCoinId: String(row[0].value),
              budget: 0,
              budgetPerDay: 0,
              isLimitBudget: false,
              socialMediaChannels: [],
              readCondition: PromotionChallengeReadConditionEnum.CHAPTER,
              coinConditionIds: [],
              bookTypes: [],
              categoryIds: [],
              status: PromotionStatusEnum.DRAFT,
            })
          }
          return row
        })
    )

    const { mutateAsync: createPromotion, isLoading } = useMutation(
      (form: PromotionChallengeFormType) =>
        client!.promotionChallengeClient.createPromotionChallenge({
          ...form,
          status: PromotionStatusEnum.PENDING,
        }),
      {
        onSuccess: id => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          navigate(`/promotion-setting/promotion-challenge/${id}`) // TODO: redirect to approve page after submit form success
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionChallengeFormType) =>
        client!.promotionChallengeClient.createPromotionChallenge(values),
      {
        onSuccess: () => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleSubmit(form: PromotionChallengeFormType) {
      confirmInfoModal.show({
        content: (
          <PreviewPromotionChallenge
            form={form}
            activeCoinOptions={activeCoinOptions}
            challengeCoinOptions={challengeCoinOptions}
          />
        ),
        onConfirm: async () => {
          confirmInfoModal.hide()
          await createPromotion(form)
        },
        onClose: () => {
          confirmInfoModal.hide()
        },
      })
    }

    function handleSaveDraft(form: PromotionChallengeFormType) {
      confirmInfoModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่น Challenge
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(form)
          confirmInfoModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmInfoModal.hide()
          navigate(-1)
        },
      })
    }

    if (!initialValues) return <Loading className='mt-[30px] mx-auto' />

    const componentProps = {
      initialValues,
      activeCoinOptions,
      challengeCoinOptions,
      handleSubmit,
      handleSaveDraft,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
