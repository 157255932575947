import { gql } from 'graphql-request'

export const GET_STORY_TOP_ADD_TO_SHELF = gql`
  query BookDashboardTopAddToShelf(
    $startDate: DateTime
    $endDate: DateTime
    $bookType: BookType
    $categoryId: Float
    $limit: Int
    $orderBy: BookDetailTop100OrderBy
  ) {
    bookDashboardTopAddToShelf(
      startDate: $startDate
      endDate: $endDate
      bookType: $bookType
      categoryId: $categoryId
      limit: $limit
      orderBy: $orderBy
    ) {
      data {
        rank
        bookId
        bookCoverImgPath
        bookTitle
        bookType
        firstPenName
        secondPenName
        chapterCount
        countLike
        countView
        countShelf
      }
    }
  }
`
