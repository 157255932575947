import cn from 'classnames'
import { useTab } from '@components/Tabs/Tabs'

interface TabHeaderProps {
  children: React.ReactNode
  tabId?: string
}

export function TabBody({ tabId, children }: TabHeaderProps) {
  const { activeTab } = useTab()
  return <div className={cn({ hidden: activeTab !== tabId })}>{children}</div>
}
