import { SpenderSectionProps } from './interface'
import { DonationTemplate } from '../DonationTemplate'
import { columns } from './components/column'

export function SpenderSection({
  data,
  handleSort,
  handleFetchNextPage,
  isLoading,
}: SpenderSectionProps) {
  return (
    <DonationTemplate
      title='Top rank donation spender'
      data={data}
      columns={columns}
      isLoading={isLoading}
      handleSort={handleSort}
      onWaypointEnter={handleFetchNextPage}
    />
  )
}
