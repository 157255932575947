import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { ChallengeStatus } from '@interfaces/user-mission/ChallengeEnum'
import { UserMissionLevelFormProps } from './interface'
import { UserMissionLevel } from '../UserMissionLevel'
import { CreateUserMissionLevelButton } from '../CreateUserMissionLevelButton'

const validationSchema = yup.object().shape({
  userMissionLevels: yup
    .array()
    .of(
      yup.object().shape({
        levelName: yup.string().required(),
        target: yup.number().required(),
        upload: yup.object().shape({
          url: yup.string().required(),
        }),
      })
    )
    .test((value, context) => {
      if (value?.length) {
        const errors: yup.ValidationError[] = []

        for (let index = 0; index < value?.length; index += 1) {
          if (
            (index > 0 &&
              Number(value[index].target) <= Number(value[index - 1].target)) ||
            (index < value.length - 1 &&
              Number(value[index].target) >= Number(value[index + 1].target))
          ) {
            errors.push(
              new yup.ValidationError(
                'error',
                value[index].target,
                `userMissionLevels.${index}.target`
              )
            )
          }

          if (
            value.some(
              (item, i) =>
                item.levelName?.trim() === value[index].levelName?.trim() &&
                i !== index
            )
          ) {
            errors.push(
              new yup.ValidationError(
                'error',
                value[index].levelName,
                `userMissionLevels.${index}.levelName`
              )
            )
          }
        }

        if (errors.length === 0) {
          return true
        }

        return context.createError({
          message: () => errors,
        })
      }

      return true
    }),
})

export function UserMissionLevelForm({
  initialValues,
  criteria,
  unit,
  userMissionId,
  editId,
  handleToggleChallengeLevel,
  refetchUserMission,
  setEditId,
  setUserMissionForm,
}: UserMissionLevelFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <Form className='text-[14px] text-secondary p-[30px] border border-blue-2 rounded-[8px] bg-white shadow mb-[30px]'>
            <div className='text-[18px] font-bold text-secondary'>
              เงื่อนไข Mission
            </div>

            {values.userMissionLevels.map((row, index) => (
              <UserMissionLevel
                key={row.id}
                status={
                  row.id ? ChallengeStatus.UPDATE : ChallengeStatus.CREATE
                }
                index={index}
                criteria={criteria}
                unit={unit}
                editId={editId}
                userMissionId={userMissionId}
                showForm={row.id === editId}
                setEditId={setEditId}
                handleToggleChallengeLevel={handleToggleChallengeLevel}
                refetchUserMission={refetchUserMission}
              />
            ))}

            <CreateUserMissionLevelButton
              setEditId={setEditId}
              setUserMissionForm={setUserMissionForm}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
