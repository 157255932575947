import { PunishmentPeriodEnum } from '@interfaces/user/PunishmentPeriodEnum'

export const punishmentPeriodText = (text?: string) => {
  switch (text) {
    case PunishmentPeriodEnum.TWELVE_HOURS:
      return '12 ชม.'
    case PunishmentPeriodEnum.THREE_DAYS:
      return '3 วัน'
    case PunishmentPeriodEnum.SEVEN_DAYS:
      return '7 วัน'
    case PunishmentPeriodEnum.THIRTY_DAYS:
      return '30 วัน'
    case PunishmentPeriodEnum.FOREVER:
      return '200 ปี'
    default:
      return '-'
  }
}
