import React from 'react'
import cn from 'classnames'
import Tooltip from '@reach/tooltip'
import { ColorChangeHandler, SketchPicker } from 'react-color'

import { usePortalDropdown } from '@hooks/useDropdown'

interface TiptapColorInputProps {
  icon: React.ReactNode
  onInput?: ColorChangeHandler | undefined
  value?: string
  tooltipLabel?: string
}

export default function TiptapColorInput({
  icon,
  onInput,
  value,
  tooltipLabel,
}: TiptapColorInputProps) {
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <div
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
            )}
            style={{ color: value || '#000000' }}
            onClick={toggleDropdown}
          >
            {icon}
          </div>
        </Tooltip>
      ) : (
        <div
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer'
          )}
          style={{ color: value || '#000000' }}
          onClick={toggleDropdown}
        >
          {icon}
        </div>
      )}
      <Dropdown>
        <SketchPicker
          className={cn({ hidden: !isShowDropdown })}
          color={value || '#000000'}
          onChangeComplete={onInput}
        />
      </Dropdown>
    </div>
  )
}
