import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Button } from '@components/Button'
import { ArrowLeftIcon } from '@components/Icons'
import { validationSchema } from './validationSchema'
import { PromotionDetail } from './components/PromotionDetail'
import { PromotionCodeFormProps } from './interface'
import { PromotionPublishing } from './components/PromotionPublishing'
import { PromotionCondition } from './components/PromotionCondition'

export function PromotionCodeForm({
  initialValues,
  confirmCreate,
  goBackHandle,
  title,
  promotionChannelOption,
  coinOptions,
  viewMode = false,
  disableEdit = false,
}: PromotionCodeFormProps) {
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={confirmCreate}
      validationSchema={validationSchema}
    >
      {({ values, dirty }) => (
        <Form>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={() => {
                if (dirty && goBackHandle) {
                  goBackHandle(values)
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>

            <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
              {title}
            </div>
          </div>
          <div className='flex flex-col gap-y-[20px]'>
            <PromotionDetail viewMode={viewMode} />
            <PromotionPublishing
              viewMode={viewMode}
              disableEdit={disableEdit}
              promotionChannelOption={promotionChannelOption}
            />
            <PromotionCondition
              viewMode={viewMode}
              disableEdit={disableEdit}
              coinOptions={coinOptions}
            />
            {!viewMode && (
              <div className='flex justify-center gap-x-[20px] mt-[10px]'>
                <Button
                  onClick={() => {
                    if (dirty && goBackHandle) {
                      goBackHandle(values)
                    } else {
                      navigate(-1)
                    }
                  }}
                  className='border border-secondary-100 text-secondary-100 text-[14px] px-[30px]'
                >
                  ยกเลิก
                </Button>
                <Button
                  type='submit'
                  className='text-white bg-primary px-[30px] text-[14px]'
                >
                  ถัดไป
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}
