import { Box } from '@components/Box'
import { TextInput } from './TextInput'
import { useWriterDetail } from '../hooks/useWriterDetail'

export function AccountingInformation() {
  const { writer } = useWriterDetail()
  return (
    <div>
      <Box.Title>ข้อมูลฝ่ายบัญชี</Box.Title>
      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <TextInput
          label='ชื่อ - นามสกุลฝ่ายบัญชี'
          placeholder='กรอกชื่อ - นามสกุลฝ่ายบัญชี'
          value={writer?.accountingFullName || ''}
        />
        <TextInput
          label='อีเมล'
          placeholder='email@example.com'
          value={writer?.accountingEmail || ''}
        />
        <TextInput
          label='เบอร์โทรศัพท์'
          placeholder='(+66) กรอกเบอร์โทรศัพท์'
          value={writer?.accountingPhoneNumber || ''}
        />
      </div>
    </div>
  )
}
