import { Expose, Transform } from 'class-transformer'
import { NotificationActionEnum } from '@interfaces/NotificationActionEnum'

export class NotificationType {
  @Expose()
  id: string

  @Expose()
  @Transform(({ obj }) => obj.message.title)
  title: string

  @Expose()
  @Transform(({ obj }) => obj.message.description)
  description: string

  @Expose()
  @Transform(({ obj }) => obj.params.urlPath)
  urlPath: string

  @Expose()
  action: NotificationActionEnum

  @Expose()
  createdAt: string

  @Expose()
  read: boolean
}
