import { gql } from 'graphql-request'

export const GET_HIGHEST_EARNING_WRITER = gql`
  query GetHighestEarningWriter(
    $orderBy: GetUserHighestEarningWriterOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getHighestEarningWriter(
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      rowNumber
      userId
      writerId
      username
      userTotalIncome
    }
  }
`
