import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { UserCommentType } from '@models/dashboard/UserCommentResponse'
import { ColumnType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'

export const columns: ColumnType<UserCommentType>[] = [
  {
    column: 'วันที่-เวลา',
    accessor: 'createdAt',
    cell: ({ value }) => (
      <p>
        {value
          ? DateTime.fromISO(value).toFormat('dd LLL yyyy-HH:mm:ss', {
              locale: 'th-Th',
            })
          : '-'}
      </p>
    ),
    cellClassName: 'w-[170px] min-w-[170px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'เรื่อง',
    accessor: 'book.title',
    cell: ({ value }) => (
      <p className='w-[180px] line-clamp-1 font-mitr text-sm font-medium underline'>
        {value}
      </p>
    ),
    cellClassName: 'w-[180px] max-w-[180px] pr-[5px]',
    isSortable: false,
  },
  {
    column: 'ตอน',
    accessor: 'bookChapter.chapterTitle',
    cell: ({ value }) => (
      <p className='underline w-[160px] line-clamp-1'>
        {!!value ? value : '-'}
      </p>
    ),
    cellClassName: 'w-[160px] max-w-[160px] pr-[5px]',
    isSortable: false,
  },
  {
    column: 'ข้อความ',
    accessor: 'content',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: false,
  },
  {
    column: 'Action',
    accessor: 'book.id',
    cell: ({ value }) => (
      <div className='flex gap-x-[10px] py-[10px]'>
        <Link to={`/preview/${value}`}>
          <button
            className='border border-gray h-[30px] px-[4px] rounded-[8px]'
            type='button'
          >
            <EyeIcon className='text-black-970' width='18' height='18' />
          </button>
        </Link>
      </div>
    ),
    cellClassName: 'flex justify-end',
    isSortable: false,
  },
]
