import { gql } from 'graphql-request'

export const TOGGLE_PROMOTION = gql`
  mutation TogglePromotionIsActive(
    $isActive: Boolean!
    $togglePromotionIsActiveId: Float!
  ) {
    togglePromotionIsActive(
      isActive: $isActive
      id: $togglePromotionIsActiveId
    ) {
      id
    }
  }
`
