import { EyeIcon } from '@components/Icons'
import { Link } from 'react-router-dom'

export function ActionCell({
  userId,
  writerId,
}: {
  userId: number
  writerId: number
}) {
  return (
    <Link
      to={`/dashboard/writer-publisher?tab=byUser&userId=${userId}&writerId=${writerId}`}
    >
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </Link>
  )
}
