import { gql } from 'graphql-request'

export const GET_HOT_WRITER = gql`
  query GetHotWriter(
    $orderBy: GetHotWritingOrderedList
    $startDate: DateTime
    $endDate: DateTime
    $limit: Int
  ) {
    getHotWriter(
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      userUserId
      userUsername
      writerAffiliation
      writerFullName
      bookId
      bookTitle
      userViewCount
      writerId
    }
  }
`
