import { DateTime } from 'luxon'

import { Avatar } from '@components/Avatar'
import { Box } from '@components/Box'
import { NewsLogsType } from '@models/newsAndPromotion/NewsLogsType'

export interface AdminRevisionHistoryProps {
  title: string
  datas: NewsLogsType[]
}

export function AdminRevisionHistory({
  title,
  datas,
}: AdminRevisionHistoryProps) {
  return (
    <div className='w-[610px] max-h-[85vh] p-[20px] flex flex-col'>
      <Box.Title className='text-[24px] text-black-480 text-center my-[20px]'>
        {title}
      </Box.Title>
      <div className='flex flex-col overflow-scroll'>
        {datas.map(item => (
          <div
            key={item.id}
            className='grid grid-cols-2 items-center border-t border-t-gray py-[10px] text-[14px] font-bold '
          >
            <p className='text-secondary'>
              {DateTime.fromISO(item.createdAt).toFormat(
                'dd LLL yyyy - HH:mm:ss',
                { locale: 'th-Th' }
              )}
            </p>
            <div className='flex items-center'>
              <div className='text-secondary-100'>ดำเนินการโดย:</div>
              <Avatar
                className='min-w-[50px] w-[50px] h-[50px] mx-[10px]'
                src={item.user.avatarImgPath}
                alt={item.user.displayName}
              />
              <div className='text-secondary truncate max-w-[100px]'>
                {item.user.displayName}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
