import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'

import { SupporterResponse } from '@models/supporter/SupporterResponse'
import { GET_SUPPORTERS } from './schemas/getSupporters'

export class SupporterClient {
  constructor(private client: GraphQLClient) {}

  async getSupporters({
    bookId,
    limitPerPage,
    page,
  }: {
    bookId?: number
    limitPerPage: number
    page: number
  }): Promise<SupporterResponse> {
    const res = await this.client.request(GET_SUPPORTERS, {
      bookId,
      limitPerPage,
      page,
    })

    return plainToInstance(SupporterResponse, res.getSupporters)
  }
}
