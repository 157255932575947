import { withPermissionGuard } from '@libs/permission'
import { PopupBannerManagementPage } from './PopupBannerManagementPage'
import { withPopupBannerManagementPage } from './withPopupBannerManagementPage'

const ConnectedPopupBannerManagementPage = withPermissionGuard(
  withPopupBannerManagementPage(PopupBannerManagementPage),
  'homePageManagement',
  'view'
)

export { ConnectedPopupBannerManagementPage as PopupBannerManagementPage }
