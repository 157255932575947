import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import { CommentSectionProps, WithCommentSectionProps } from './interface'

export function withCommentSection(Component: React.FC<CommentSectionProps>) {
  function WithCommentSection({ queryParam }: WithCommentSectionProps) {
    const [sort, setSort] = useState({
      sortKey: 'createdAt',
      sortOrder: 'DESC',
    })

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['book-add-to-shelf', queryParam, sort],
        ({ pageParam = 1 }) =>
          client?.dashboardClient.getUserComment({
            startDate: queryParam.startDate,
            endDate: queryParam.endDate,
            userId: Number(queryParam.userId),
            page: pageParam,
            ...sort,
          }),
        {
          enabled: !!queryParam.userId,
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const componentProps = {
      data,
      isLoading,
      handleSort,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithCommentSection
}
