import { gql } from 'graphql-request'

export const UPDATE_CHALLENGE = gql`
  mutation UpdateChallenge($updateChallengeInput: UpdateChallengeInput!) {
    updateChallenge(updateChallengeInput: $updateChallengeInput) {
      id
      title
      description
      iconUrl
      active
    }
  }
`

export const UPDATE_CHALLENGE_LEVEL = gql`
  mutation UpdateChallengeLevel(
    $updateChallengeLevelInput: UpdateChallengeLevelInput!
  ) {
    updateChallengeLevel(
      updateChallengeLevelInput: $updateChallengeLevelInput
    ) {
      id
      level
      levelName
      target
      isUsed
      isActive
      iconUrl
    }
  }
`

export const TOGGLE_CHALLENGE_LEVEL = gql`
  mutation ToggleChallengeLevel($input: ToggleChallengeLevelInput!) {
    toggleChallengeLevel(input: $input) {
      status
      isActive
    }
  }
`
