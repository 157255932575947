import { Expose, Type } from 'class-transformer'

export class BookChapterByBookType {
  @Expose() id: number

  @Expose({ name: 'runningNo' }) order: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() viewCount: number

  @Expose() totalSales: number

  @Expose() purchasedCount: number
}

export class BookChapterByBookResponse {
  @Expose()
  @Type(() => BookChapterByBookType)
  data: BookChapterByBookType[]

  @Expose() total: number

  @Expose() page: number
}
