import { Table } from '@components/Table'
import { ActivityLogsTableProps } from './interface'

export function ActivityLogsTable<T>({
  data,
  columns,
  isLoading,
  onWayPointEnter,
}: ActivityLogsTableProps<T>) {
  return (
    <Table
      data={data}
      isLoading={isLoading}
      columns={columns}
      onWaypointEnter={onWayPointEnter}
    />
  )
}
