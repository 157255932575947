import cn from 'classnames'
import { CrossIcon } from '@components/Icons'
import { ModalProps } from './interface'
import { withModal } from './withModal'

function Modal({
  children,
  onClose,
  disabledClose,
  disableBackground,
}: ModalProps) {
  return (
    <div className='fixed right-0 left-0 top-0 bottom-0 bg-black-480 bg-opacity-30 flex justify-center items-center z-50'>
      <div
        className={cn(
          'relative flex flex-col w-auto h-auto max-w-full max-h-[90%] overflow-auto rounded-[10px]',
          { 'bg-white': !disableBackground }
        )}
      >
        <div className='absolute right-[15px] top-[15px] z-[1] flex justify-end min-h-[20px]'>
          {!disabledClose && (
            <button type='button' onClick={onClose}>
              <CrossIcon className='text-black-480' />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default withModal(Modal)
