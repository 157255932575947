import { Container } from '@components/Container'
import { NewsAndPromotionForm } from '@features/newsAndPromotion/components/NewsAndPromotionForm'

import { NewsAndPromotionCreatePageProps } from './interface'

export function NewsAndPromotionCreatePage({
  initialValues,
  handleSubmit,
}: NewsAndPromotionCreatePageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>Create News & Promotion</Container.Title>
      <NewsAndPromotionForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      />
    </Container>
  )
}
