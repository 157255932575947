import { gql } from 'graphql-request'

export const UPDATE_NEWS = gql`
  mutation UpdateNews($updateNewsInput: UpdateNewsInput!) {
    updateNews(updateNewsInput: $updateNewsInput) {
      id
      bannerImgPath
      thumbnailImgPath
      name
      description
      content
      isActive
      newsType
      updatedAt
      user {
        id
        displayName
      }
    }
  }
`
