import React from 'react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import FootnoteOrderedList from './FootnoteOrderedList'

const FootnoteOrderedListExtension = Node.create({
  group: 'block',

  content() {
    return `${this.options.itemTypeName}+`
  },
  atom: true,

  name: 'footnote-ordered-list',
  addOptions() {
    return {
      itemTypeName: 'customOrderedList',
      HTMLAttributes: {},
    }
  },
  parseHTML() {
    return [
      {
        tag: 'footnote-ordered-list',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['footnote-ordered-list', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(FootnoteOrderedList)
  },
})

export default FootnoteOrderedListExtension
