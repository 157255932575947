import { ReadingPageHeaderProps } from './interface'

export function ReadingPageHeader({
  storyName,
  chapterName,
  chapterTitle,
  by,
  totalPage,
}: ReadingPageHeaderProps) {
  return (
    <div className='flex flex-col items-center text-secondary gap-y-[20px] mb-[50px]'>
      <div className='text-[24px] font-bold'>
        {chapterTitle} {chapterName}
      </div>
      {totalPage && (
        <div className='text-[18px] font-light mt-[20px] text-right'>
          จำนวน :{' '}
          <span className='font-medium'>{totalPage.toLocaleString()} </span>
          หน้า
        </div>
      )}
    </div>
  )
}
