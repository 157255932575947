import { withPermissionGuard } from '@libs/permission'
import { CoverManage } from '@features/chapterBook/components/CoverManage'

const ConnectedCoverCreatePage = withPermissionGuard(
  CoverManage,
  'chapterBook',
  'create'
)

export { ConnectedCoverCreatePage as CoverCreatePage }
