import { CoinManagementFormProps } from './interface'

export function withCoinManagementForm(
  Component: React.FC<CoinManagementFormProps>
) {
  function WithCoinManagementForm(props: CoinManagementFormProps) {
    return <Component {...props} />
  }

  return WithCoinManagementForm
}
