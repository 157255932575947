import { Form, Formik } from 'formik'
import cn from 'classnames'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Button } from '@components/Button'
import { ExportButton } from '@components/ExportButton'
import { SelectField } from '@components/forms/SelectField'
import PaymentReportTable from '../PaymentReportTable'
import { ReportOverviewProps } from './interface'

function ReportOverview({
  data,
  total,
  amount,
  initialValues,
  monthOptions,
  yearOptions,
  isLoading,
  monthYear,
  sortBy,
  handleSearch,
  handleSortChange,
  handleWaypointEnter,
  handleReset,
  handleExport,
}: ReportOverviewProps) {
  const permission = usePermissionAction('sale')
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSearch}>
        {() => (
          <Form className='flex gap-x-[20px] mb-[30px]'>
            <SelectField
              name='month'
              options={monthOptions}
              label='เดือน'
              className='w-[132px]'
            />
            <SelectField
              name='year'
              options={yearOptions}
              label='ปี'
              className='w-[92px]'
            />
            <div className='flex-1 text-[14px] flex justify-end items-center gap-x-[20px]'>
              <Button
                className='underline text-secondary !px-0'
                type='reset'
                onClick={handleReset}
              >
                ล้างค่า
              </Button>
              <Button className='bg-secondary text-white' type='submit'>
                ค้นหา
              </Button>
              <ExportButton
                handleClick={handleExport}
                className={cn({
                  hidden: !permission.download,
                })}
              />
            </div>
          </Form>
        )}
      </Formik>
      <PaymentReportTable
        data={data}
        total={total}
        amount={amount}
        monthYear={monthYear}
        sortBy={sortBy}
        isLoading={isLoading}
        handleSortChange={handleSortChange}
        handleWaypointEnter={handleWaypointEnter}
      />
    </>
  )
}

export default ReportOverview
