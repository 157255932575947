/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { HorizontalStyleProps, WithHorizontalStyleProps } from './interface'

export function withHorizontalStyle(Component: React.FC<HorizontalStyleProps>) {
  function WithHorizontalStyle({
    isReview = false,
    chapterPages,
    chapterId,
  }: WithHorizontalStyleProps) {
    const totalPage = chapterPages.length
    const [page, setPage] = useState(1)

    function nextPage() {
      if (page < totalPage) setPage(page + 1)
    }

    function prevPage() {
      if (page > 1) setPage(page - 1)
    }

    const newProps = {
      chapterPages,
      totalPage,
      page,
      nextPage,
      prevPage,
    }
    return <Component {...newProps} />
  }
  return WithHorizontalStyle
}
