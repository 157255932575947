import React from 'react'
import { useField } from 'formik'

import { CheckboxFieldProps } from './interface'

export function withCheckboxField(Component: React.FC<CheckboxFieldProps>) {
  function WithCheckboxField({ name, value, ...props }: CheckboxFieldProps) {
    const [field] = useField(name)

    const componentProps = {
      ...props,
      ...field,
      value,
    }

    return <Component {...componentProps} />
  }

  return WithCheckboxField
}
