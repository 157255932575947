import { RightIcon, LeftIcon } from '@components/Icons'
import cn from 'classnames'
import { DateDailyFieldProps } from './interface'

export function DateDailyField({
  dateOfWeek,
  title,
  handleClickNextWeek,
  handleClickPrevWeek,
  disabledNext,
  disabledPrev,
  className,
}: DateDailyFieldProps) {
  return (
    <div className={cn('flex items-center', className)}>
      {title && (
        <label className='text-[14px] text-black-480 w-[100%] mr-[10px] font-light'>
          {title}
        </label>
      )}
      <div className='w-[100%] flex flex-row h-[40px]'>
        <button
          type='button'
          disabled={disabledPrev}
          onClick={handleClickPrevWeek}
          className={cn(
            'flex justify-center items-center rounded-l-[20px] w-[40px] bg-secondary cursor-pointer',
            {
              'cursor-no-drop opacity-20': disabledPrev,
            }
          )}
        >
          <span className='text-white'>
            <LeftIcon />
          </span>
        </button>
        <div className='flex justify-center items-center w-[200px] border-t border-b border-[#eeeeee] text-[14px] text-[#484848] font-light'>
          <span className='whitespace-pre text-center'>{dateOfWeek}</span>
        </div>
        <button
          type='button'
          disabled={disabledNext}
          className={cn(
            'flex justify-center items-center rounded-r-[20px] w-[40px] bg-secondary cursor-pointer',
            {
              'cursor-no-drop opacity-20': disabledNext,
            }
          )}
          onClick={handleClickNextWeek}
        >
          <span className='text-white'>
            <RightIcon className='text-[#fff]' />
          </span>
        </button>
      </div>
    </div>
  )
}
