import { Expose, Transform } from 'class-transformer'

import { CoinLogTypeEnum } from '@interfaces/coin/CoinLogTypeEnum'

export class UserCoinLogType {
  @Expose() id: number

  @Expose()
  @Transform(({ obj }) => obj.transaction.transactionId)
  transactionId: string

  @Expose()
  @Transform(({ value, obj }) =>
    obj.type === CoinLogTypeEnum.USE ? value * -1 : value
  )
  paidCoin: number

  @Expose()
  @Transform(({ value, obj }) =>
    obj.type === CoinLogTypeEnum.USE ? value * -1 : value
  )
  paidCoinValue: number

  @Expose()
  @Transform(({ value, obj }) =>
    obj.type === CoinLogTypeEnum.USE ? value * -1 : value
  )
  freeCoin: number

  @Expose()
  @Transform(({ value, obj }) =>
    obj.type === CoinLogTypeEnum.USE ? value * -1 : value
  )
  freeCoinValue: number

  @Expose() type: CoinLogTypeEnum

  @Expose() summaryPaidCoin: number

  @Expose() summaryPaidCoinValue: number

  @Expose() summaryPaidCoinAvg: number

  @Expose() summaryFreeCoin: number

  @Expose() summaryFreeCoinValue: number

  @Expose() summaryFreeCoinAvg: number

  @Expose() createdAt: string

  @Expose()
  @Transform(({ obj }) => obj.transaction?.description || '')
  description: string
}
