import { ReportCategoryDetail } from '@features/dashboard/pages/SalesReportPage/components/ReportCategoryDetail'
import { Box } from '@components/Box'
import { SelectButton } from '@features/dashboard/pages/StoryPage/components/SelectButton/SelectButton'
import { BookEnum } from '@interfaces/BookEnum'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { Loading } from '@components/Loading'
import { NoData } from '@components/NoData'
import { EbookByCategoryProps } from './interface'
import { topRankColumns } from '../column'

const bookTypeOption = [
  { value: BookEnum.NOVEL, label: 'นิยาย' },
  { value: BookEnum.MANGA, label: 'การ์ตูน' },
]

function EbookByCategory({
  bookTypeByCategory,
  handleBookTypeByCategoryChange,
  searchParams,
  categoryOptions,
  handleBookTypeChange,
  handleCategoryChange,
  handleSort,
  overviewSale,
  overviewSaleLoading,
  overviewSaleTopRank,
  overviewSaleTopRankLoading,
  handleFetchNextPage,
}: EbookByCategoryProps) {
  return (
    <Box>
      <span className='font-mitr text-[24px] font-medium'>
        สัดส่วนอีบุ๊กตามหมวดหมู่
      </span>
      <SelectButton
        label='ลักษณะงานเขียน'
        title='ลักษณะงานเขียน'
        options={bookTypeOption}
        value={bookTypeByCategory}
        className='w-[472px] my-[20px]'
        handleSelect={handleBookTypeByCategoryChange}
      />

      {overviewSaleLoading ? (
        <Loading size={48} className='mx-auto mt-[50px]' />
      ) : overviewSale.length > 0 ? (
        <ReportCategoryDetail className='mt-[30px]' data={overviewSale} />
      ) : (
        <NoData className='mt-[30px]' />
      )}
      <div className='border-t border-gray py-[30px] mt-[30px]'>
        <div className='flex text-secondary-100 font-light items-baseline justify-between text-[14px]'>
          <span className='font-medium text-[24px] text-black font-mitr'>
            อันดับอีบุ๊กขายดี
          </span>
          <div>
            รายได้ทั้งหมด :
            <span className='text-[16px] font-medium font-mitr text-black mx-[10px]'>
              {overviewSaleTopRank?.pages.length
                ? overviewSaleTopRank.pages[0].summaryTotalSales.toLocaleString()
                : 0}
            </span>
            บาท
          </div>
        </div>
      </div>
      <div className='w-full flex justify-between gap-x-[20px] mb-[20px]'>
        <SelectButton
          label='ลักษณะงานเขียน'
          title='ลักษณะงานเขียน'
          options={bookTypeOption}
          value={searchParams.bookType}
          className='w-[472px]'
          handleSelect={handleBookTypeChange}
        />
        <SelectButton
          label='หมวดหมู่'
          title='หมวดหมู่'
          options={categoryOptions}
          value={searchParams.category}
          className='w-[477px]'
          handleSelect={handleCategoryChange}
        />
      </div>
      <Table
        data={overviewSaleTopRank?.pages.flatMap(row => row.data) || []}
        columns={topRankColumns}
        fixedHeader
        onSort={handleSort}
        initialSort={{ key: 'downloadedCount', order: SortingType.DESC }}
        className='min-h-[400px] max-h-[400px] pr-[20px]'
        isLoading={overviewSaleTopRankLoading}
        onWaypointEnter={handleFetchNextPage}
      />
    </Box>
  )
}

export default EbookByCategory
