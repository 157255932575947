import { gql } from 'graphql-request'

export const GET_WRITER_FREE_VS_EARN = gql`
  query GetFreeVSEarnWriter($startDate: DateTime, $endDate: DateTime) {
    getFreeVSEarnWriter(startDate: $startDate, endDate: $endDate) {
      totalEarnWriter
      totalFreeWriter
      graphData {
        year
        month
        earnWriterCount
        freeWriterCount
      }
    }
  }
`
