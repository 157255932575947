import { ImageType } from '@models/ImageType'
import { useField } from 'formik'
import React from 'react'

import { CropImageFieldProps, WithCropImageFieldProps } from './interface'

export function withCropImageField(Component: React.FC<CropImageFieldProps>) {
  function WithCropImageField({ name, ...props }: WithCropImageFieldProps) {
    const [field, meta, { setValue }] = useField<ImageType>(name)

    function onChange(blob: Blob | null) {
      if (blob) {
        setValue({
          url: URL.createObjectURL(blob),
          blob,
        })
      }
    }

    const pageProps = {
      ...props,
      src: field.value.url,
      isError: !!meta.error && !!meta.touched,
      onChange,
    }
    return <Component {...pageProps} />
  }

  return WithCropImageField
}
