import React, { useCallback, useContext, useMemo, useState } from 'react'
import cn from 'classnames'
import {
  Tab,
  TabContextType,
  TabsProviderPropsType,
} from '@components/Tabs/interface'

export const TabContext = React.createContext<TabContextType>({
  menus: {},
  activeTab: '',
  handleAddMenu: (tab: Tab) => {},
  handleUpdateActiveTab: () => {},
})

export function Tabs({
  initialActiveTab,
  onActiveTabChange,
  children,
  mode = 'box',
}: TabsProviderPropsType) {
  const [menus, setMenus] = useState<Tab>({})
  const [activeTab, setActiveTab] = useState<string>(initialActiveTab)

  const handleAddMenu = useCallback((tab: Tab) => {
    setMenus(oldValue => ({ ...oldValue, ...tab }))
  }, [])

  const menusArray = Object.entries(menus)

  const handleUpdateActiveTab = useCallback(
    (key: string) => {
      if (activeTab !== key) {
        setActiveTab(key)
        if (onActiveTabChange) {
          onActiveTabChange(key)
        }
      }
    },
    [activeTab, onActiveTabChange]
  )

  const value = useMemo(
    () => ({
      activeTab,
      menus,
      handleAddMenu,
      handleUpdateActiveTab,
      mode,
    }),
    [activeTab, menus, handleAddMenu, handleUpdateActiveTab, mode]
  )

  return (
    <TabContext.Provider value={value}>
      <div className='relative select-none overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
        <div
          className={cn(
            'text-[14px] text-secondary font-light gap-x-[20px] flex',
            {
              'gap-x-0': mode === 'line',
            }
          )}
        >
          {menusArray.map(([key, item]) => (
            <button
              key={key}
              type='button'
              disabled={item?.disabled}
              className={cn(
                'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
                {
                  'rounded-[8px] bg-secondary text-white':
                    key === activeTab && mode === 'box',
                  'rounded-[8px] bg-blue-2 text-secondary-100':
                    key !== activeTab && mode === 'box',
                  'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                    key === activeTab && mode === 'line',
                  'text-secondary-100 font-light':
                    key !== activeTab && mode === 'line',
                  'cursor-no-drop opacity-40': item?.disabled,
                }
              )}
              onClick={() => handleUpdateActiveTab(key)}
            >
              {React.cloneElement(item.children)}
            </button>
          ))}
        </div>
        <div
          className={cn('absolute w-full h-[1px] bottom-[1px] bg-gray z-0', {
            hidden: mode === 'box',
          })}
        />
      </div>
      <div className='mt-5'>{children}</div>
    </TabContext.Provider>
  )
}

export function useTab() {
  return useContext(TabContext)
}
