import { gql } from 'graphql-request'

export const GET_READ_TIME_USAGE = gql`
  query GetReadTimeUsageByUser(
    $userId: Int!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getReadTimeUsageByUser(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      timeList {
        count
        summaryTime
        time
      }
      averageTimeUsage
      greatestTimeUsage
    }
  }
`
