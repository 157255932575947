import { ColumnType } from '@components/Table/interface'
import { punishmentPeriodText } from '@libs/user/utils'
import { UserPunishmentType } from '@models/user/UserPunishmentHistoryResponse'
import { DateTime } from 'luxon'

export const columns: ColumnType<UserPunishmentType>[] = [
  {
    column: 'ครั้งที่แบน',
    accessor: 'punishmentTimes',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    isSortable: true,
    cellClassName: 'min-w-[80px] max-w-[80px] pr-[5px]',
  },
  {
    column: 'ระยะเวลา',
    accessor: 'period',
    cell: ({ value }) => <div>{punishmentPeriodText(value)}</div>,
    cellClassName: 'min-w-[80px] max-w-[80px] pr-[5px]',
  },
  {
    column: 'วันที่แบน',
    accessor: 'startPunishedAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
          locale: 'th-Th',
        })}
      </div>
    ),
    isSortable: true,
    cellClassName: 'min-w-[150px] max-w-[150px] pr-[5px]',
  },
  {
    column: 'วันที่สิ้นสุด',
    accessor: 'endPunishedAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
          locale: 'th-Th',
        })}
      </div>
    ),
    isSortable: true,
    cellClassName: 'min-w-[150px] max-w-[150px] pr-[5px]',
  },
  {
    column: 'เหตุผลการแบน',
    accessor: 'message',
    cell: ({ value }) => <p>{value}</p>,
    cellClassName: 'pr-[5px] w-full py-[10px]',
    isSortable: false,
  },
  {
    column: 'แบนโดย',
    accessor: 'operatedUser.displayName',
    cell: ({ value }) => <p className='line-clamp-1'>{value}</p>,
    cellClassName: 'py-[10px] w-[100px]',
    isSortable: false,
  },
]
