import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { BookType } from '@models/book/BookType'
import { ActionStatusEnum } from '@interfaces/highlightBanner/ActionStatusEnum'
import { EditHighlightBannerBookPageProps } from './interface'

export function withEditHighlightBannerBookPage(
  Component: React.FC<EditHighlightBannerBookPageProps>
) {
  function WithEditHighlightBannerBookPage() {
    const { id, position } = useParams()
    const navigate = useNavigate()
    const client = useClient()
    const alert = useAlert()

    const { data } = useQuery(
      [id, 'selected-books'],
      () => client?.highlightBannerClient.getSelectedBookBanner(Number(id)),
      {
        enabled: !!id,
      }
    )

    const { mutateAsync: createBookBanner } = useMutation(
      (form: { highlightGroupId: number; bookIds: number[] }) =>
        client!.highlightBannerClient.createGroupBookBanner(
          form.highlightGroupId,
          form.bookIds
        ),
      {
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutateAsync: removeBookBanner } = useMutation(
      (form: { highlightGroupId: number; bookIds: number[] }) =>
        client!.highlightBannerClient.removeBookBanners(
          form.highlightGroupId,
          form.bookIds
        ),
      {
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(statusBooks: BookType[]) {
      const createItems = statusBooks.filter(
        item => item.actionStatus === ActionStatusEnum.ADD
      )
      const removeItems = statusBooks.filter(
        item => item.actionStatus === ActionStatusEnum.REMOVE
      )

      if (removeItems.length) {
        await removeBookBanner({
          highlightGroupId: Number(id),
          bookIds: removeItems.map(param => param.bannerId as number),
        })
      }
      if (createItems.length) {
        await createBookBanner({
          highlightGroupId: Number(id),
          bookIds: createItems.map(param => param.id),
        })
      }

      alert.success('บันทึกเรียบร้อย')
      navigate(`/home-page/highlight-banner/${position}/book/${id}`)
    }

    const componentProps = {
      data: data || [],
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithEditHighlightBannerBookPage
}
