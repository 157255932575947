import { Outlet } from 'react-router-dom'
import { NotificationPage } from './pages/NotificationPage'

export const notificationRoutes = [
  {
    path: 'notification',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <NotificationPage />,
      },
    ],
  },
]
