import { Expose, Type } from 'class-transformer'
import { ConsentType } from '@models/consent/ConsentType'

export class WritersConsentType {
  @Expose()
  @Type(() => ConsentType)
  consent: ConsentType

  @Expose() id: number

  @Expose() createdAt: string
}
