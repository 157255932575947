import cn from 'classnames'
import ReactECharts from 'echarts-for-react'

export type DataSourceType = {
  name: string
  value: number
}

export type DataSourceResponse = {
  title: string
  data: DataSourceType[]
  color?: string
}

interface VerticalStackBarChartProps {
  title: string
  dataSource: DataSourceResponse[]
  style?: React.CSSProperties
  labelFormatter?: (value: number, index: number) => string
  grid?: { left?: number; right?: number; top?: number; bottom?: number }
  headerTitleClassName?: string
}

export function VerticalStackBarChart({
  title,
  style,
  labelFormatter,
  grid = { left: 30, right: 0, top: 10 },
  headerTitleClassName,
  dataSource,
}: VerticalStackBarChartProps) {
  const option = {
    grid,
    legend: {
      left: '0%',
      itemWidth: 16,
      itemHeight: 16,
      itemGap: 30,
    },
    tooltip: {
      trigger: 'item',
      formatter: (chart: any) => {
        return `${chart.seriesName}<br /> ${
          chart.name
        }: <strong>${chart.value.toLocaleString()}</strong>`
      },
    },
    xAxis: {
      type: 'category',
      data: dataSource[0].data.map(item => item.name),
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    yAxis: {
      axisLabel: {
        show: true,
        formatter: labelFormatter,
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    animationDuration: 300,
    animationDurationUpdate: 300,
    series: dataSource.map(res => ({
      name: res.title,
      type: 'bar',
      stack: 'stack',
      itemStyle: {
        color: res.color || '#79beff',
      },
      data: res.data.map(item => item.value),
    })),
    textStyle: {
      color: '#858baf',
    },
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <span
          className={cn(
            'font-mitr font-medium text-secondary mb-[20px]',
            headerTitleClassName
          )}
        >
          {title}
        </span>
      </div>
      <ReactECharts option={option} style={style} notMerge lazyUpdate />
    </>
  )
}
