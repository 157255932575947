import { gql } from 'graphql-request'

export const CHARACTER_DONATION_BY_SPENDER = gql`
  query CharacterDonationBySpender(
    $dateRange: DatePicker
    $orderBy: CharacterDonationBySpenderSort
    $orderDirection: OrderDirection
    $limitPerPage: Int
    $page: Int
  ) {
    characterDonationBySpender(
      dateRange: $dateRange
      orderBy: $orderBy
      orderDirection: $orderDirection
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        userId
        username
        coinSpend
        profilePageSlug
      }
      total
      page
    }
  }
`
