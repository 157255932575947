import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { PromotionSaleForm } from '@features/promotionSetting/components/PromotionSaleForm'
import { CreatePromotionSalePageProps } from './interface'

export function CreatePromotionSalePage({
  initialValues,
  saleCoinOptions,
  isLoading,
  createPromotionCode,
  handleLeave,
}: CreatePromotionSalePageProps) {
  return (
    <Container>
      {!isLoading ? (
        initialValues ? (
          <PromotionSaleForm
            initialValues={initialValues}
            saleCoinOptions={saleCoinOptions}
            handleSubmit={createPromotionCode}
            goBackHandle={handleLeave}
            title='สร้างโปรโมชั่นการขาย'
          />
        ) : (
          <div className='m-auto'>เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}
