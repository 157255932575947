/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import {
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getFirestore,
  writeBatch,
  collection,
} from '@firebase/firestore'
import { useAuthentication } from '@hooks/useAuthentication'

export function useNotificationStatus() {
  const { user } = useAuthentication()
  const [unreadNoticeIds, setUnreadNoticeIds] = useState<string[]>([])
  const [totalUnread, setTotalUnread] = useState<number>(0)

  async function fetchUnreadNotification() {
    const db = getFirestore()
    const adminNotificationCollection = collection(
      db,
      'admin-notification',
      `${user.id}`,
      'notifications'
    )
    const q = query(adminNotificationCollection, where('read', '==', false))
    const querySnapshot = await getDocs(q)
    const ids = querySnapshot.docs.map(item => item.id)
    setUnreadNoticeIds(ids)
    setTotalUnread(querySnapshot.size)
  }

  async function updateReadStatus(id: string) {
    const db = getFirestore()
    const adminNotificationCollection = collection(
      db,
      'admin-notification',
      `${user.id}`,
      'notifications'
    )

    await updateDoc(doc(adminNotificationCollection, id), {
      read: true,
    })
  }

  async function updateAllReadStatus() {
    const db = getFirestore()
    const batch = writeBatch(db)
    const adminNotificationCollection = collection(
      db,
      'admin-notification',
      `${user.id}`,
      'notifications'
    )
    unreadNoticeIds.forEach(id => {
      batch.update(doc(adminNotificationCollection, id), {
        read: true,
      })
    })
    await batch.commit()
    await fetchUnreadNotification()
  }

  useEffect(() => {
    if (user.id) {
      fetchUnreadNotification()
    }
  }, [user.id])

  return {
    totalUnread,
    updateReadStatus,
    updateAllReadStatus,
  }
}
