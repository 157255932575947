import { Expose, Type } from 'class-transformer'
import { ReportLogType } from './ReportLogType'

export class ReportLogListType {
  @Expose() total: number

  @Expose() page: number

  @Expose()
  @Type(() => ReportLogType)
  data: ReportLogType[]
}
