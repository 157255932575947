import { gql } from 'graphql-request'

export const EXPORT_PROMOTION_SALE = gql`
  query ExportPromotionSale(
    $promotionSaleId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $searchText: String
    $orderBy: SaleLogOrderBy
  ) {
    exportPromotionSale(
      promotionSaleId: $promotionSaleId
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
      orderBy: $orderBy
    )
  }
`
