import { WritingTableProps } from './interface'

export function withWritingTable(Component: React.FC<WritingTableProps>) {
  function WithWritingTable({ ...props }: WritingTableProps) {
    const componentProps = {
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithWritingTable
}
