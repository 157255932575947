import { Node, mergeAttributes, wrappingInputRule } from '@tiptap/core'

export interface OrderedListOptions {
  itemTypeName: string
  HTMLAttributes: Record<string, any>
}

export const inputRegex = /^(\d+)\.\s$/

export const CustomOrderedListExtension = Node.create<OrderedListOptions>({
  name: 'customOrderedList',

  addOptions() {
    return {
      itemTypeName: 'customListItem',
      HTMLAttributes: { id: 'customOrderedList' },
    }
  },

  group: 'block list',

  content() {
    return `${this.options.itemTypeName}+`
  },

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: element => {
          return element.hasAttribute('start')
            ? parseInt(element.getAttribute('start') || '', 10)
            : 1
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ol',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes

    return start === 1
      ? [
          'ol',
          mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart),
          0,
        ]
      : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: match => ({ start: +match[1] }),
        joinPredicate: (match, node) =>
          node.childCount + node.attrs.start === +match[1],
      }),
    ]
  },
})
