import { Box } from '@components/Box'
import { TopHashtag } from '@features/dashboard/pages/SearchPage/components/TopHashtag'
import { TopHashtagType } from '@features/dashboard/pages/SearchPage/components/TopHashtag/interface'
import { Story } from '@features/dashboard/pages/SearchPage/components/Story'
import { Writer } from '@features/dashboard/pages/SearchPage/components/Writer'
import { Chapter } from '@features/dashboard/pages/SearchPage/components/Chapter'
import { Cover } from '@features/dashboard/pages/SearchPage/components/Cover'
import { Category } from '@features/dashboard/pages/SearchPage/components/Category'
import { Publisher } from '@features/dashboard/pages/SearchPage/components/Publisher'
import { Hashtag } from '@features/dashboard/pages/SearchPage/components/Hashtag'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export function SearchTab({
  searchParam,
}: {
  searchParam: DonationQueryParam
}) {
  return (
    <Box>
      <div className='flex flex-row justify-between'>
        <TopHashtag type={TopHashtagType.USE} searchParam={searchParam} />
        <TopHashtag type={TopHashtagType.CLICK} searchParam={searchParam} />
      </div>

      {/* TODO: ซ่อนไว้ก่อน เนื่องจากยังไม่มีข้มูล */}
      {/* <Story searchParam={searchParam} /> */}

      <Writer searchParam={searchParam} />

      {/* TODO: ซ่อนไว้ก่อน เนื่องจากยังไม่มีข้มูล */}
      {/* <Chapter searchParam={searchParam} /> */}
      {/* <Cover searchParam={searchParam} /> */}

      <Category searchParam={searchParam} />
      <Publisher searchParam={searchParam} />
      <Hashtag searchParam={searchParam} />
    </Box>
  )
}
