import { Button } from '@components/Button'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import {
  promotionRequestActionText,
  promotionRequestStatusText,
} from '@libs/promotion/utils'
import { formatDateTime } from '@libs/utils'
import { Link } from 'react-router-dom'
import { PromotionApproveSectionProps } from './interface'

export function PromotionApproveSection({
  actionBy,
  promotionRequestStatus,
  formDetailUrl,
  disabled,
  handleApprove,
  handleReject,
}: PromotionApproveSectionProps) {
  const permission = usePermissionAction('promotionSetting')

  return (
    <>
      <div className='w-full border-b my-5 h-[1px]' />
      <div className='flex justify-between flex-row-reverse h-[50px]'>
        {permission.approve && !disabled ? (
          <div className='flex items-center space-x-5'>
            <Link to={formDetailUrl}>
              <Button
                type='button'
                className='px-0 text-secondary font-mitr text-sm font-medium'
              >
                <span>ดูรายละเอียด</span>
              </Button>
            </Link>
            <Button
              type='button'
              onClick={handleReject}
              className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
            >
              <span>Reject</span>
            </Button>
            <Button
              type='button'
              onClick={handleApprove}
              className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
            >
              <span>Approve</span>
            </Button>
          </div>
        ) : (
          <div className='flex items-center space-x-5 text-[16px] text-primary'>
            ({promotionRequestStatusText(promotionRequestStatus)})
          </div>
        )}
        {actionBy && (
          <div className='flex items-center space-x-5'>
            <div className='flex items-center space-x-[10px]'>
              <span className='text-secondary-100 font-bold text-sm'>
                ดำเนินการโดย :
              </span>
              {actionBy.imageUrl ? (
                <img
                  className='w-[50px] h-[50px] rounded-full'
                  src={actionBy.imageUrl}
                  alt='User cover'
                />
              ) : (
                <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
              )}
              <div className='space-y-1 text-sm'>
                <p className='text-secondary font-bold'>
                  {actionBy.displayName || '-'}
                </p>
                <p className='text-secondary-100 font-normal'>
                  {formatDateTime(actionBy.actionAt)}
                </p>
              </div>
            </div>
            <div className='w-[1px] h-full border-r' />
            <div className='text-sm font-bold text-primary'>
              {promotionRequestActionText(actionBy.actionType)}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
