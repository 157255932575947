import React, { useEffect, useState } from 'react'
import { SortByType, SortingType } from '@components/Table/interface'
import { EbookSalesGraphTypeEnum } from '@interfaces/EbookSalesGraphTypeEnum'
import { EbookSalesReportEnum } from '@interfaces/EbookSalesReportEnum'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuthentication } from '@hooks/useAuthentication'
import { client } from '@client/init'
import { AnalyticsEbookDetailType } from '@models/ebook/AnalyticsEbookDetailType'
import {
  EbookOverviewTabsAcceptProps,
  EbookOverviewTabsProps,
  SortTableType,
} from './interface'

const initialData: AnalyticsEbookDetailType = {
  tableData: [],
  graphData: [],
  graphType: EbookSalesGraphTypeEnum.MONTH,
}

export function withEbookOverviewTabs(
  Component: React.FC<EbookOverviewTabsProps>
) {
  function WithEbookOverviewTabs({
    ebookId,
    searchParams,
  }: EbookOverviewTabsAcceptProps) {
    const [params] = useSearchParams()
    const { isAuthenticated } = useAuthentication()
    const navigate = useNavigate()
    const tab = params.get('reportType') || EbookSalesReportEnum.PURCHASE_COUNT

    const [sortParams, setSortParams] = useState<SortTableType>({
      reportType: tab as EbookSalesReportEnum,
      sortKey: 'total',
      sortValue: SortingType.ASC,
    })

    const { data = initialData, isLoading } = useQuery(
      ['analytics-ebook-detail', ebookId, searchParams, sortParams],
      () =>
        client?.ebookClient.getAnalyticsEbookDetail({
          ebookId: Number(ebookId),
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          ...sortParams,
        }),
      {
        enabled: !!ebookId && isAuthenticated,
      }
    )

    function handleSortChange(sort: SortByType) {
      setSortParams(prev => ({
        ...prev,
        sortKey: sort.key,
        sortValue: sort.order,
      }))
    }
    function handleTabChange(value: string) {
      setSortParams(prev => ({
        ...prev,
        reportType: value as EbookSalesReportEnum,
      }))
      navigate({
        pathname: '',
        search: `?reportType=${value}`,
      })
    }

    useEffect(() => {
      setSortParams(prev => ({
        ...prev,
        reportType: tab as EbookSalesReportEnum,
      }))
    }, [tab])

    const newProps = {
      data,
      sortParams,
      isLoading,
      isNoData:
        data.graphData.length === 0 &&
        data.tableData.length === 0 &&
        !isLoading,
      handleTabChange,
      handleSortChange,
    }
    return <Component {...newProps} />
  }
  return WithEbookOverviewTabs
}
