import { gql } from 'graphql-request'

export const EXPORT_SALES_BY_USER = gql`
  query ExportSalesByUser(
    $userId: Int!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    exportSalesByUser(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
`
