import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { TopMoneySpenderType } from '@models/salse/TopMoneySpenderType'

export const columns: ColumnType<TopMoneySpenderType>[] = [
  {
    column: 'User ID',
    accessor: 'id',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    sortKey: 'usersId',
    cellClassName: 'w-[320px] max-w-[320px] py-[7px]',
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>@{value}</p>,
    isSortable: true,
    sortKey: 'userUsername',
    cellClassName: 'py-[7px]',
  },
  {
    column: 'Money',
    accessor: 'moneySpend',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
    sortKey: 'userMoneySpend',
    cellClassName: 'w-[350px] max-w-[350px] py-[7px]',
  },
  {
    column: 'Action',
    accessor: 'username',
    cell: ({ obj }) => (
      <Link
        to={{
          pathname: '/dashboard/reader',
          search: `?tab=byUser&userId=${obj.id}`,
        }}
        target='_blank'
      >
        <button
          className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isSortable: false,
    cellClassName: 'w-[45px] max-w-[45px] py-[7px]',
  },
]
