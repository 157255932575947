import { gql } from 'graphql-request'

export const SYSTEM_COINS = gql`
  query SystemCoins($getSystemCoinsInput: GetSystemCoinsInput = {}) {
    systemCoins(getSystemCoinsInput: $getSystemCoinsInput) {
      coinId
      coinName
      coinImgPath
      isExpireByActivity
      isExpireByPaid
      editable
      received
      used
      remain
      expired
      sell
    }
  }
`
