import { Button } from '@components/Button'
import { ExportButton } from '@components/ExportButton'
import { ExportCodeIcon } from '@components/Icons'
import { Pagination } from '@components/Pagination'
import { Search } from '@components/Search'
import {
  CodeTypeEnum,
  CodeTypeValue,
} from '@interfaces/promotionCode/CodeTypeEnum'

import cn from 'classnames'
import { useMemo } from 'react'
import { CodeTransactionTable } from '../CodeTransactionTable'
import { PromotionCodeTransactionProps } from './interface'

export function PromotionCodeTransaction({
  handleSort,
  data,
  isLoading,
  handleSubmitSearch,
  handleResetSearch,
  page,
  perpage,
  total,
  pageChange,
  activeTab,
  handleUpdateActiveTab,
  exportedCouponFilePath,
  codesFilePath,
  couponType,
  promotionType,
  isUsageLimitPerUser,
  coinName,
  promotionLogCount,
  handleExport,
}: PromotionCodeTransactionProps) {
  const menusArray = useMemo(
    () =>
      couponType === CodeTypeEnum.UNIVERSAL
        ? [
            {
              value: 'used',
              text: 'ใช้แล้ว',
              total: promotionLogCount?.usedCount || 0,
            },
          ]
        : [
            {
              value: 'all',
              text: 'ทั้งหมด',
              total: promotionLogCount?.totalCount || 0,
            },
            {
              value: 'remaining',
              text: 'ยังไม่ได้ใช้',
              total: promotionLogCount?.remainingCount || 0,
            },
            {
              value: 'used',
              text: 'ใช้แล้ว',
              total: promotionLogCount?.usedCount || 0,
            },
          ],
    [couponType, promotionLogCount]
  )

  return (
    <div className='flex flex-col gap-y-[30px] mt-[30px]'>
      <Search
        placeholder='ค้นหาด้วย Code, User ID, User Type หรือ Channel'
        onSearch={handleSubmitSearch}
        onReset={handleResetSearch}
      />
      <div className='flex justify-between text-[18px] font-bold text-secondary'>
        <div>Promotion Code - {CodeTypeValue[couponType]}</div>
        <div className='flex gap-x-[20px]'>
          <ExportButton
            className='border !border-secondary-100 !text-secondary-100 !bg-white'
            label='Export Report'
            handleClick={handleExport}
          />
          {Boolean(codesFilePath || exportedCouponFilePath) && (
            <a
              href={codesFilePath || exportedCouponFilePath}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                type='button'
                className=' text-sm text-white bg-secondary  rounded-[20px]'
              >
                <ExportCodeIcon className='text-white mr-[10px]' />
                <span>Export All Code</span>
              </Button>
            </a>
          )}
        </div>
      </div>

      <div
        className={cn(
          'text-[14px] text-secondary font-light gap-x-[15px] flex border-b border-gray'
        )}
      >
        {menusArray.map(item => (
          <button
            key={item.value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  item.value === activeTab,
                'text-secondary-100 font-light': item.value !== activeTab,
              }
            )}
            onClick={() => handleUpdateActiveTab(item.value)}
          >
            {item.text}
            <span className='font-light pl-[5px]'>
              ({item.total.toLocaleString()})
            </span>
          </button>
        ))}
      </div>
      <CodeTransactionTable
        activeTab={activeTab}
        promotionType={promotionType}
        coinName={coinName}
        isUsageLimitPerUser={isUsageLimitPerUser}
        handleSort={handleSort}
        data={data}
        isLoading={isLoading}
      />
      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
