import { Outlet } from 'react-router-dom'

import { EditUserMissionPage } from './pages/EditUserMissionPage'
import { UserMissionDetailPage } from './pages/UserMissionDetailPage'
import { UserMissionPage } from './pages/UserMissionPage'
import { UserMissionHistoryPage } from './pages/UserMissionHistoryPage'

export const userMissionRoutes = [
  {
    path: 'user-mission',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <UserMissionPage />,
      },
      {
        path: ':id',
        element: <UserMissionDetailPage />,
      },
      {
        path: 'edit/:id',
        element: <EditUserMissionPage />,
      },
      {
        path: 'history/:id',
        element: <UserMissionHistoryPage />,
      },
    ],
  },
]
