import { StoryType } from '@models/dashboard/StoryType'
import { CategorySection } from './CategorySection'
import { DeviceSection } from './DeviceSection'
import { OverAllChapter } from './OverAllChapter'
import { OverAllStatis } from './OverAllStatis'
import { OverAllStory } from './OverAllStory'

interface DataOverAllProps {
  data: StoryType
}

export function DataOverAll({ data }: DataOverAllProps) {
  return (
    <>
      <OverAllStatis data={data} />
      <OverAllStory
        isEnded={data.countBookByEnding.isEnded}
        notEnded={data.countBookByEnding.notEnded}
      />
      <OverAllChapter
        freePrice={data.countBookChapterByPrice.freePrice}
        hasPrice={data.countBookChapterByPrice.hasPrice}
      />
      <DeviceSection data={data} />
      <CategorySection data={data} />
    </>
  )
}
