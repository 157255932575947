import cn from 'classnames'
import { Waypoint } from 'react-waypoint'

import { ChevronDownIcon, LoadingIcon } from '@components/Icons'
import { ExportJobStatusEnum } from '@interfaces/exportFile/ExportJobStatusEnum'
import { Status } from './Status'
import { ExportListProps } from './interface'

export function ExportList({
  exportJobs,
  isOpen,
  handleFetchNextPage,
  onCancelExportJob,
  onToggle,
}: ExportListProps) {
  if (exportJobs.length === 0) return null

  return (
    <div className='absolute bottom-0 right-[50px] w-[400px] bg-white rounded-t-[8px] border border-gray shadow z-10'>
      <div className='flex justify-between items-center p-[20px]'>
        <span className='text-secondary font-bold font-mitr'>Export</span>
        <button type='button' onClick={onToggle}>
          <ChevronDownIcon
            className={cn('text-primary transition-all', {
              'rotate-180': isOpen,
            })}
          />
        </button>
      </div>
      <div
        className={cn(
          'grid transition-all duration-500 max-h-[327px] border-t border-t-gray ml-[20px] mr-[10px] pr-[10px] overflow-auto',
          {
            'max-h-0': !isOpen,
          }
        )}
      >
        {exportJobs.map(row => (
          <div
            key={row.id}
            className={cn(
              'py-[12px] border-b border-b-gray text-secondary grid grid-cols-[1fr_16px_120px] gap-x-[10px] items-center hover:bg-blue-50/10',
              {
                'grid-cols-[1fr_16px_120px]':
                  row.status === ExportJobStatusEnum.PROCESSING,
                'grid-cols-[1fr_120px]':
                  row.status !== ExportJobStatusEnum.PROCESSING,
              }
            )}
          >
            <span className='text-[12px] truncate'>{row.name}</span>
            <LoadingIcon
              className={cn('text-primary animate-spin', {
                hidden: row.status !== ExportJobStatusEnum.PROCESSING,
              })}
              width='16'
              height='16'
            />
            <Status
              id={row.id}
              status={row.status}
              presignedPath={row.presignedPath}
              onCancelExportJob={onCancelExportJob}
            />
          </div>
        ))}
        <Waypoint onEnter={handleFetchNextPage} />
      </div>
    </div>
  )
}
