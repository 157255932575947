import { gql } from 'graphql-request'

export const GET_READER_BADGE = gql`
  query GetReaderBadge($userId: Int!, $challengeTypeArgs: ChallengeType) {
    getReaderBadge(userId: $userId, challengeTypeArgs: $challengeTypeArgs) {
      id
      title
      description
      iconUrl
      criteria
      unit
      currentLevel
      currentLevelName
      completed
      nextLevel
      nextLevelName
      nextTarget
      nextRemaining
      maxLevel
      previousTarget
      challengeLevels {
        id
        iconUrl
        isActive
      }
    }
  }
`
