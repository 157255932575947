import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { ProductType } from '@models/salse/ProductType'
import { useClient } from '@hooks/useClient'
import { PaymentReportDetailPageProps } from './interface'

const PER_PAGE = 10

export function withPaymentReportDetailPage(
  Component: React.FC<PaymentReportDetailPageProps>
) {
  function WithPaymentReportDetailPage() {
    const [params] = useSearchParams()
    const { id } = useParams()
    const client = useClient()
    const userId = Number(params.get('userId'))
    const productType =
      (params.get('productType') as ProductType) || ProductType.NOVEL_CHAPTER
    const month = Number(params.get('month'))
    const year = Number(params.get('year'))
    const monthIndex = month - 1
    const startDate = DateTime.fromObject({ year, month })
      .startOf('month')
      .toUTC()
    const endDate = DateTime.fromObject({ year, month }).endOf('month').toUTC()

    const { fetchNextPage, data, isFetchingNextPage, isLoading, hasNextPage } =
      useInfiniteQuery(
        ['sale-book', month, year, productType],
        async ({ pageParam = 1 }) => {
          const res =
            await client!.userWriterClient.getWriterSalesByProductType({
              page: pageParam,
              limitPerPage: PER_PAGE,
              startDate,
              endDate,
              productType,
              userId,
            })

          return res
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (
              lastPage.salesDetail.page * PER_PAGE <
              lastPage.salesDetail.total
            )
              return lastPage.salesDetail.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage(event: any) {
      const scrollHeight = event.target.scrollHeight - 10
      const scrollTop = event.target.scrollTop + event.target.offsetHeight
      if (scrollHeight <= scrollTop && !isFetchingNextPage && hasNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      userId,
      avatarImg: params.get('avatarImg') || '/images/avatar.png',
      fullName: params.get('fullName') || '',
      username: params.get('username') || '',
      email: params.get('email') || '',
      transactionId: id || '',
      amount: Number(params.get('amount')),
      withholdingTax: Number(params.get('withholdingTax')),
      monthIndex,
      year: new Date(year, monthIndex)
        .toLocaleDateString('th-TH')
        .split('/')[2],
      productType,
      startDate,
      endDate,
      isFetchingNextPage,
      isLoading,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithPaymentReportDetailPage
}
