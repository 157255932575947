import { PromotionSettingCloseModalProps } from './interface'

export function withPromotionSettingCloseModal(
  Component: React.FC<PromotionSettingCloseModalProps>
) {
  function WithPromotionSettingCloseModal(
    props: PromotionSettingCloseModalProps
  ) {
    return <Component {...props} />
  }

  return WithPromotionSettingCloseModal
}
