import React, { useState, useContext, useMemo } from 'react'

import { Modal } from '@components/Modal'
import { ModalList } from './ModalList'
import {
  HookModalParams,
  ModalContextType,
  ModalListType,
  ModalProviderPropsType,
} from './interface'

const ModalContext = React.createContext<ModalContextType>({
  isOpen: false,
  showModal: () => {},
  hideModal: () => {},
  setCurrentModal: () => {},
  setCurrentModalProps: () => {},
  disabledCloseButton: (disabled = false) => {},
})

function ModalProvider({ children }: ModalProviderPropsType) {
  const [currentModal, setCurrentModal] = useState<ModalListType | undefined>(
    undefined
  )
  const [currentModalProps, setCurrentModalProps] = useState<any>({})
  const [isOpen, setIsOpen] = useState(false)
  const [disabledClose, setDisabledClose] = useState(false)
  const ModalChildren = ModalList[currentModal!]

  const showModal = () => {
    setIsOpen(true)
  }
  const hideModal = () => {
    setIsOpen(false)
  }

  const disabledCloseButton = (disabled = false) => {
    setDisabledClose(disabled)
  }

  const value = useMemo(
    () => ({
      isOpen,
      showModal,
      hideModal,
      setCurrentModal,
      setCurrentModalProps,
      disabledCloseButton,
    }),
    [isOpen]
  )

  return (
    <ModalContext.Provider value={value}>
      {children}
      {currentModal && (
        <Modal
          isOpen={isOpen}
          onClose={hideModal}
          disabledClose={disabledClose}
          disableBackground={['loading'].includes(currentModal)}
        >
          <ModalChildren {...currentModalProps} />
        </Modal>
      )}
    </ModalContext.Provider>
  )
}

const useModal = (
  { modal, modalProps }: HookModalParams,
  hideCloseButton?: boolean
) => {
  const {
    isOpen,
    showModal,
    setCurrentModal,
    setCurrentModalProps,
    hideModal,
    disabledCloseButton,
  } = useContext(ModalContext)

  const show = (props?: Record<string, unknown>) => {
    disabledCloseButton(hideCloseButton)
    setCurrentModal(modal)
    setCurrentModalProps({ ...modalProps, ...props })
    showModal()
  }

  const hide = () => {
    hideModal()
  }

  return { isOpen, show, hide }
}

export { ModalProvider, ModalContext, useModal }
