import React from 'react'

import { WarningTableProps } from './interface'

export function withWarningTable(Component: React.FC<WarningTableProps>) {
  function WithWarningTable(props: WarningTableProps) {
    return <Component {...props} />
  }

  return WithWarningTable
}
