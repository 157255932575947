import cn from 'classnames'
import { Search } from '@components/Search'
import { Container } from '@components/Container'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { Pagination } from '@components/Pagination'
import { TabSelect } from '@features/user/components/TabSelect'
import { AgeConfirmTable } from './components/AgeConfirmTable'
import { AgeConfirmationPageProps } from './interface'

export function AgeConfirmationPage({
  activeTab,
  searchParams,
  page,
  perpage,
  total,
  data,
  isLoading,
  userTotal,
  pageChange,
  handleSort,
  handleUpdateActiveTab,
  handleSubmitSearch,
  handleResetSearch,
}: AgeConfirmationPageProps) {
  const menusArray = [
    { value: SearchUserStatusEnum.ALL, label: 'ทั้งหมด', total: userTotal.all },
    {
      value: SearchUserStatusEnum.PENDING,
      label: 'รอดำเนินการ',
      total: userTotal.pending,
    },
    {
      value: SearchUserStatusEnum.APPROVED,
      label: 'อนุมัติแล้ว',
      total: userTotal.approved,
    },
    {
      value: SearchUserStatusEnum.REJECTED,
      label: 'ไม่อนุมัติ',
      total: userTotal.rejected,
    },
  ]
  return (
    <Container className='flex flex-col gap-y-[30px]'>
      <h1 className='font-mitr text-[24px] font-medium text-secondary'>
        Age Confirmation
      </h1>
      <Search
        placeholder='ค้นหาด้วยชื่อผู้ใช้งาน หรือ ID'
        onSearch={handleSubmitSearch}
        onReset={handleResetSearch}
      />
      <TabSelect
        options={menusArray}
        handleUpdateActiveTab={handleUpdateActiveTab}
        activeTab={activeTab}
      />

      <AgeConfirmTable
        handleSort={handleSort}
        data={data}
        isLoading={isLoading}
      />
      <div className='mt-[-10px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </Container>
  )
}
