import { withPermissionGuard } from '@libs/permission'
import { BookStorySettingAddPage } from './BookStorySettingAddPage'
import { withBookStorySettingAddPage } from './withBookStorySettingAddPage'

const ConnectedBookStorySettingAddPage = withPermissionGuard(
  withBookStorySettingAddPage(BookStorySettingAddPage),
  'chapterBook',
  'create'
)
export { ConnectedBookStorySettingAddPage as BookStorySettingAddPage }
