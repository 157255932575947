import { gql } from 'graphql-request'

export const EXPORT_APP_PERFORMANCE = gql`
  query ExportAppPerformance(
    $startDate: DateTime
    $endDate: DateTime
    $dashboardType: DashboardType!
    $keyType: KeyType!
  ) {
    exportAppPerformance(
      startDate: $startDate
      endDate: $endDate
      dashboardType: $dashboardType
      keyType: $keyType
    )
  }
`
