/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'
import {
  query,
  where,
  orderBy,
  limit,
  getDocs,
  startAt,
  getFirestore,
  collection,
} from '@firebase/firestore'

import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { NotificationType } from '@models/notification/NotificationType'
import { getErrorMessage } from '@libs/utils'
import { useNotificationStatus } from './useNotificationStatus'

const LIMIT = 10
export function useNotification() {
  const { user } = useAuthentication()
  const alert = useAlert()
  const [data, setData] = useState<NotificationType[]>([])
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { totalUnread, updateReadStatus, updateAllReadStatus } =
    useNotificationStatus()

  async function fetchData() {
    const db = getFirestore()
    const adminNotificationCollection = collection(
      db,
      'admin-notification',
      `${user.id}`,
      'notifications'
    )

    setIsLoading(true)
    try {
      const indexOf = (page - 1) * LIMIT
      const all = query(
        adminNotificationCollection,
        orderBy('createdAt', 'desc')
      )
      const allQuerySnapshot = await getDocs(all)
      const last = allQuerySnapshot.docs[indexOf]
      setTotal(allQuerySnapshot.docs.length)

      if (!last) return

      const q = query(
        adminNotificationCollection,
        orderBy('createdAt', 'desc'),
        startAt(last),
        limit(LIMIT)
      )
      const querySnapshot = await getDocs(q)
      const queryData = querySnapshot.docs.map(item => {
        const docData = item.data()
        return {
          ...docData,
          id: item.id,
          createdAt: DateTime.fromJSDate(docData.createdAt.toDate()).toFormat(
            'dd LLL yy | HH:mm',
            {
              locale: 'th-TH',
            }
          ),
        }
      })

      setData(
        plainToInstance(NotificationType, queryData as [], {
          excludeExtraneousValues: true,
        })
      )
    } catch (error) {
      alert.error(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  async function handleUpdateReadStatus(id: string) {
    await updateReadStatus(id)
    setData(prev => {
      const dataClone = cloneDeep(prev)
      const index = dataClone.findIndex(item => item.id === id)
      dataClone[index].read = true
      return dataClone
    })
  }

  async function handleUpdateAllReadStatus() {
    try {
      await updateAllReadStatus()
      fetchData()
    } catch (error) {
      alert.error(getErrorMessage(error))
    }
  }

  useEffect(() => {
    if (user.id) {
      fetchData()
    }
  }, [user.id, page])

  return {
    data,
    page,
    total,
    isLoading,
    totalUnread,
    setNotificationPage: setPage,
    updateReadStatus: handleUpdateReadStatus,
    updateAllReadStatus: handleUpdateAllReadStatus,
  }
}
