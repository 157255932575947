import { SelectButton } from '@components/SelectButton'
import { Container } from '@components/Container'
import { SearchManagementLayoutProps } from './interface'

export function SearchManagementLayout({
  children,
  searchMenagementTabOption,
  tab,
  handleChangeTab,
}: SearchManagementLayoutProps) {
  return (
    <Container>
      <div className='flex items-center justify-between w-full py-[20px] border-0]'>
        <h1 className='font-mitr text-[24px] text-secondary'>
          Search Management
        </h1>
      </div>
      <div className='flex mt-[30px]'>
        <SelectButton
          options={searchMenagementTabOption}
          value={tab}
          className='flex-wrap gap-y-[15px]'
          onChange={handleChangeTab}
        />
      </div>
      <div className='py-[30px]'>{children}</div>
    </Container>
  )
}
