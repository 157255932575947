import { withPermissionGuard } from '@libs/permission'
import { PendingInvitationPage } from './PendingInvitationPage'
import { withPendingInvitationPage } from './withPendingInvitationPage'

const ConnectedPendingInvitationPage = withPermissionGuard(
  withPendingInvitationPage(PendingInvitationPage),
  'admin',
  'view'
)

export { ConnectedPendingInvitationPage as PendingInvitationPage }
