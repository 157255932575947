import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ByTitleProps, SearchType } from './interface'
import { ByTitleSearchFormType } from '../ByTitleForm/interface'
import { TabActiveEnum } from '../../interface'

const withByTitle = (Component: React.FC<ByTitleProps>) => {
  function WithByTitle() {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const queryEbookId = searchParams.get('ebookId') || undefined

    const [queryParam, setQueryParam] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      ebookId: queryEbookId,
      keyType: TimeRangeEnum.MONTH,
    })

    const { data = [], isLoading } = useQuery(
      ['ebook-by-title', queryParam],
      () =>
        client?.dashboardClient.ebookByTitle({
          ebookId: Number(queryParam.ebookId),
          startDate: queryParam.startDate,
          endDate: queryParam.endDate,
        }),
      {
        enabled: !!queryParam.ebookId,
      }
    )

    function newDateAt(
      startDate: Date | undefined,
      endDate: Date | undefined,
      timeRange: TimeRangeEnum | undefined
    ) {
      let newStartAt = startDate
        ? DateTime.fromJSDate(startDate).startOf('day')
        : undefined
      let newEndAt = endDate
        ? DateTime.fromJSDate(endDate).endOf('day')
        : undefined
      let keyType = timeRange

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
        newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (newStartAt && newEndAt && !keyType) {
        const diff = newEndAt.diff(newStartAt, ['years', 'months', 'days'])
        const { days = 0, months = 0, years = 0 } = diff.toObject()
        if (years >= 1) {
          keyType = TimeRangeEnum.YEAR
        } else if (months >= 1) {
          keyType = TimeRangeEnum.YEAR
        } else if (days > 1) {
          keyType = TimeRangeEnum.MONTH
        } else {
          keyType = TimeRangeEnum.DAY
        }
      }

      return { newStartAt, newEndAt, keyType }
    }

    async function handleSearch({
      ebookId,
      startDate,
      endDate,
      timeRange,
    }: ByTitleSearchFormType) {
      const { newStartAt, newEndAt, keyType } = newDateAt(
        startDate,
        endDate,
        timeRange
      )

      if (newStartAt && newEndAt && keyType) {
        setQueryParam({
          startDate: newStartAt,
          endDate: newEndAt,
          ebookId,
          keyType,
        })
        navigate({
          search: `?tab=${TabActiveEnum.BY_TITLE}&ebookId=${ebookId}`,
        })
      }
    }

    function handleReset() {
      setQueryParam({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        ebookId: undefined,
        keyType: TimeRangeEnum.MONTH,
      })
      navigate(
        {
          search: `?tab=${TabActiveEnum.BY_TITLE}`,
        },
        {
          replace: true,
        }
      )
    }

    const newProps = {
      queryParam,
      handleSearch,
      handleReset,
      data,
      isLoading,
    }
    return <Component {...newProps} />
  }

  return WithByTitle
}

export default withByTitle
