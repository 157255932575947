import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import cn from 'classnames'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { Toggle } from '@components/Toggle'
import { Checkbox } from '@components/Checkbox'
import StatusButton from '@components/StatusButton'
import { DragIcon, PenIcon, BinIcon, ClockIcon } from '@components/Icons'
import { formatDate } from '@libs/utils'
import { MainBannerPageProps, PlatformEnum, TabEnum } from './interface'

const tabMenus = [
  { value: TabEnum.ALL, label: 'All' },
  { value: TabEnum.ACTIVE, label: 'Active' },
  { value: TabEnum.INACTIVE, label: 'Inactive' },
  { value: TabEnum.EXPIRED, label: 'Expired' },
]

export function MainBannerPage({
  mainBannerList,
  selectedIds,
  platformSelected,
  tabSelected,
  navigate,
  handleDragItem,
  handleSelectItemAll,
  handleSelectItem,
  handleEnable,
  handlePublishMainBannerList,
  handleUnpublishMainBannerList,
  handledeleteMainBannerList,
  handleDelete,
  handlePlatformChange,
  handleTabChange,
}: MainBannerPageProps) {
  const permission = usePermissionAction('homePageManagement')
  return (
    <Container>
      <section className='flex items-center justify-between'>
        <Container.Title showBackBtn={false}>Main Banner</Container.Title>

        <Button
          type='button'
          className={cn('text-[14px] text-white bg-secondary', {
            hidden: !permission.create,
          })}
          onClick={() =>
            navigate({ pathname: '/home-page/main-banner/create' })
          }
        >
          + เพิ่มแบนเนอร์
        </Button>
      </section>

      <section className='flex gap-x-[20px] mb-[20px]'>
        <button
          className={cn(
            'text-[14px] font-light px-[20px] py-[10px] rounded-[8px]',
            {
              'bg-secondary text-white': platformSelected === PlatformEnum.WEB,
              'bg-blue-2 text-secondary-100':
                platformSelected !== PlatformEnum.WEB,
            }
          )}
          type='button'
          onClick={() => handlePlatformChange(PlatformEnum.WEB)}
        >
          Web
        </button>
        <button
          className={cn(
            'text-[14px] font-light px-[20px] py-[10px] rounded-[8px]',
            {
              'bg-secondary text-white': platformSelected === PlatformEnum.IOS,
              'bg-blue-2 text-secondary-100':
                platformSelected !== PlatformEnum.IOS,
            }
          )}
          type='button'
          onClick={() => handlePlatformChange(PlatformEnum.IOS)}
        >
          iOS
        </button>
        <button
          className={cn(
            'text-[14px] font-light px-[20px] py-[10px] rounded-[8px]',
            {
              'bg-secondary text-white':
                platformSelected === PlatformEnum.ANDROID,
              'bg-blue-2 text-secondary-100':
                platformSelected !== PlatformEnum.ANDROID,
            }
          )}
          type='button'
          onClick={() => handlePlatformChange(PlatformEnum.ANDROID)}
        >
          Android
        </button>
      </section>

      <section className='flex text-black-480'>
        {tabMenus.map((menu, index) => (
          <button
            key={index}
            className='relative'
            type='button'
            onClick={() => handleTabChange(menu.value)}
          >
            <div
              className={cn('px-[20px] py-[10px] text-[14px]', {
                'font-bold': tabSelected === menu.value,
                'font-light': tabSelected !== menu.value,
              })}
            >
              {menu.label}
            </div>
            <div
              className={cn('w-full absolute border-b', {
                'border-secondary border-b-[3px] rounded-l-[2px] rounded-r-[2px] bottom-[-1px]':
                  tabSelected === menu.value,
                'border-gray bottom-0': tabSelected !== menu.value,
              })}
            />
          </button>
        ))}

        <div className='border-b border-gray w-full' />
      </section>

      <section className='flex items-center justify-between my-[30px] h-[40px]'>
        <div className='flex gap-x-[10px] text-black-480'>
          <Checkbox
            className={cn({ hidden: !permission.edit && !permission.delete })}
            onChange={handleSelectItemAll}
            checked={
              selectedIds.length === mainBannerList.length &&
              mainBannerList.length !== 0
            }
          />
          <span className='font-light text-secondary'>ทั้งหมด</span>
          <span className='text-secondary-100'>
            ( <b>{mainBannerList.length.toLocaleString() || 0} รายการ</b> )
          </span>
        </div>
        {!!selectedIds.length && (
          <div className='flex gap-x-[10px]'>
            {permission.edit && (
              <>
                <StatusButton
                  status='publish'
                  type='button'
                  onClick={handlePublishMainBannerList}
                />
                <StatusButton
                  status='unpublish'
                  type='button'
                  onClick={handleUnpublishMainBannerList}
                />
              </>
            )}
            {permission.delete && (
              <StatusButton
                status='delete'
                type='button'
                onClick={handledeleteMainBannerList}
              />
            )}
          </div>
        )}
      </section>

      <DragDropContext onDragEnd={handleDragItem}>
        <Droppable droppableId='list' isDropDisabled={!permission.edit}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {mainBannerList.map((item, index) => (
                <Draggable
                  draggableId={`${item?.id.toString()}`}
                  index={index}
                  key={item?.id}
                >
                  {providedDragable => (
                    <div
                      className='flex gap-5 mb-[10px]'
                      ref={providedDragable.innerRef}
                      {...providedDragable.draggableProps}
                      {...providedDragable.dragHandleProps}
                    >
                      <div className='flex items-center justify-between gap-x-[20px]'>
                        <Checkbox
                          className={cn({
                            hidden: !permission.edit && !permission.delete,
                          })}
                          checked={selectedIds.includes(item?.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.stopPropagation()
                            handleSelectItem(item?.id)
                          }}
                        />
                        <div className='font-mitr font-medium text-2xl w-[50px] text-secondary-100'>
                          {item.runningNo.toLocaleString()}
                        </div>

                        <DragIcon
                          className='cursor-grab text-secondary'
                          width='10'
                        />
                      </div>

                      <div className='w-full border-[0.5px] border-gray rounded-lg p-[30px] shadow-51'>
                        <div className='flex gap-x-[30px]'>
                          <div
                            className={cn(
                              'w-[160px] h-[68.57px] aspect-[21/9]',
                              {
                                'opacity-30':
                                  (platformSelected === PlatformEnum.WEB &&
                                    !item.isActiveWeb) ||
                                  (platformSelected === PlatformEnum.IOS &&
                                    !item.isActiveIos) ||
                                  (platformSelected === PlatformEnum.ANDROID &&
                                    !item.isActiveAndroid),
                              }
                            )}
                          >
                            <img
                              className='h-full w-full object-cover rounded-lg'
                              src={item?.coverImgPath || ''}
                              alt='banner'
                            />
                          </div>

                          <div className='flex-1'>
                            <div className='grid grid-cols-7 gap-x-[30px] mb-[14px] justify-end'>
                              <div className='col-span-3 text-secondary'>
                                <p className='text-xs font-light text-secondary-100'>
                                  Description
                                </p>
                                <p className='text-sm font-light line-clamp-1 break-all'>
                                  {item.description}
                                </p>
                              </div>

                              <div className='col-span-3 text-secondary'>
                                <p className='text-xs font-light text-secondary-100'>
                                  Link
                                </p>
                                <p className='text-sm font-light line-clamp-1 break-all'>
                                  {item.rawLink}
                                </p>
                              </div>

                              <Toggle
                                className='ml-auto disabled:cursor-not-allowed'
                                disabled={!permission.edit}
                                checked={
                                  (platformSelected === PlatformEnum.WEB &&
                                    item.isActiveWeb) ||
                                  (platformSelected === PlatformEnum.IOS &&
                                    item.isActiveIos) ||
                                  (platformSelected === PlatformEnum.ANDROID &&
                                    item.isActiveAndroid)
                                }
                                onChange={() => handleEnable(item)}
                              />
                            </div>

                            <div className='flex items-center justify-between'>
                              <div className='flex items-center text-secondary-100 text-[14px] font-light gap-x-[5px]'>
                                <ClockIcon
                                  className='text-secondary'
                                  width='18'
                                  height='18'
                                />
                                {`${formatDate(
                                  item.startPublishedAt
                                )} - ${formatDate(item.endPublishedAt)}`}
                              </div>

                              <div className='flex gap-x-[10px]'>
                                <button
                                  type='button'
                                  className={cn(
                                    'flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                    {
                                      hidden: !permission.edit,
                                    }
                                  )}
                                  onClick={() =>
                                    navigate({
                                      pathname: `/home-page/main-banner/edit/${item?.id}`,
                                    })
                                  }
                                >
                                  <PenIcon width='20' height='20' />
                                </button>
                                <button
                                  type='button'
                                  className={cn(
                                    'flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                    {
                                      hidden: !permission.delete,
                                    }
                                  )}
                                  onClick={() => handleDelete(item?.id)}
                                >
                                  <BinIcon width='20' height='20' />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}
