import { useMemo } from 'react'
import { SelectField } from '@components/forms/SelectField'
import { CoinOptionType } from '@models/coin/CoinOptionType'

function SelectCoinTypeField({
  coins,
  viewMode,
}: {
  coins: CoinOptionType[]
  viewMode: boolean
}) {
  const coinOptions = useMemo(() => {
    const options = coins?.map(c => ({
      label: `เหรียญ${c.label}`,
      value: String(c.value),
    }))
    return options
  }, [coins])

  return (
    <SelectField
      name='benefitCoinId'
      placeholder='เลือกเหรียญ'
      className='w-[140px]'
      options={coinOptions}
      disabled={viewMode}
    />
  )
}

export default SelectCoinTypeField
