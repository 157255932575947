import React from 'react'
import cn from 'classnames'
import { useMatch, Link } from 'react-router-dom'

import { OptionType } from './SelectMenu/interface'

export function SelectList({ option }: { option: OptionType }) {
  const match = useMatch({ path: option.basePath, end: false })
  return (
    <Link
      to={option?.path}
      className='flex text-sm font-light text-black-480 mb-5 cursor-pointer hover:text-[#053af6] hover:font-bold'
    >
      <div className='flex items-center gap-x-[10px]'>
        <span
          className={cn({
            'text-[#053af6] font-bold': match,
          })}
        >
          {option?.label}
        </span>

        {option?.isAlarm && (
          <div className='w-[8px] h-[8px] rounded-full bg-[#f7575f]' />
        )}
      </div>
    </Link>
  )
}
