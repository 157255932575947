import { gql } from 'graphql-request'

export const BOOK_LOGS = gql`
  query BookLogs($bookId: Int!, $limitPerPage: Int, $page: Int) {
    bookLogs(bookId: $bookId, limitPerPage: $limitPerPage, page: $page) {
      total
      page
      data {
        id
        action
        actionBy {
          id
          avatarImgPath
          displayName
        }
        bookReport {
          id
          code
        }
        createdAt
      }
    }
  }
`
