import { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { TextAreaField } from '@components/forms/TextAreaField'
import { BanModalProps } from './interface'

export function BanModal({
  header,
  title,
  topChartId,
  imageUrl,
  publisher,
  writer,
  penName,
  handleBanBook,
  isLoading,
  onClose,
}: BanModalProps) {
  return (
    <div className='w-[500px] pt-[40px] pb-[30px] px-[30px]'>
      <div className='text-center text-[24px] font-mitr text-secondary font-medium pb-[20px]'>
        แบนนิยายใน {header}
      </div>
      <div className='flex gap-x-[20px] mb-[20px]'>
        <div className='rounded-[8px] w-[33px] h-[50px] overflow-hidden mt-[10px]'>
          <img className='object-cover w-full h-full' src={imageUrl} alt='' />
        </div>
        <section className='flex-1'>
          <section className='flex justify-between mt-[15px] mb-[5px]'>
            <div className='font-mitr text-[12px] text-secondary font-medium line-clamp-1'>
              {title}
            </div>
          </section>
          <section className='flex font-light text-[12px] text-black-480 mb-[14px]'>
            {publisher && (
              <p className='pr-[10px] border-r border-gray mr-[10px]'>
                <span className='text-black-970'>สำนักพิมพ์ : </span>
                {publisher}
              </p>
            )}
            {writer ? (
              <p className='pr-[10px] border-gray mr-[10px]'>
                <span className='text-black-970  mobile:w-[108px]'>
                  ผู้เขียน :<span className=''>{writer}</span>
                </span>
              </p>
            ) : (
              <p className='pr-[10px] border-gray mr-[10px]'>
                <span className='text-black-970'>นามปากกา : </span>
                {penName}
              </p>
            )}
          </section>
        </section>
      </div>
      <Formik
        initialValues={{
          description: '',
        }}
        onSubmit={value => handleBanBook(topChartId, value.description)}
      >
        {({ isSubmitting }) => (
          <Form>
            <TextAreaField
              textAreaClassName='h-[120px] placeholder:text-secondary-100'
              name='description'
              label='ส่งข้อความถึงผู้เขียน'
              placeholder='พิมพ์ข้อความ'
              maxLength={200}
            />
            <div className='flex font-mitr justify-end text-[14px] font-medium mt-[30px]'>
              <button
                onClick={onClose}
                type='button'
                className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-black-480'
              >
                ยกเลิก
              </button>
              <button
                type='submit'
                disabled={isLoading}
                className='flex font-mitr w-[102px] items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-primary'
              >
                ส่ง
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
