import { withPermissionGuard } from '@libs/permission'
import { PaymentReportDetailPage } from './PaymentReportDetailPage'
import { withPaymentReportDetailPage } from './withPaymentReportDetailPage'

const ConnectedPaymentReportDetailPage = withPermissionGuard(
  withPaymentReportDetailPage(PaymentReportDetailPage),
  'sale',
  'view'
)

export { ConnectedPaymentReportDetailPage as PaymentReportDetailPage }
