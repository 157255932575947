import { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { SalesReportSearchFormType } from '@models/dashboard/SalesReportSearchFormType'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { getErrorMessage } from '@libs/utils'
import { SaleReportQueryParam, SalesReportPageProps } from './interface'

const initialData = {
  totalBook: 0,
  totalChapter: 0,
  totalUserReading: 0,
  totalDownload: 0,
  totalSpend: 0,
  totalProfit: 0,
  totalSell: 0,
  totalDonate: 0,
  totalOverAllSale: 0,
  saleTarget: 0,
}

export function withSalesReportPage(Component: React.FC<SalesReportPageProps>) {
  function WithSalesReportPage() {
    const queryClient = useQueryClient()
    const client = useClient()
    const alert = useAlert()
    const { user, token } = useAuthentication()
    const [queryParam, setQueryParam] = useState<SaleReportQueryParam>({
      categoryIds: [],
      bookType: [],
      startAt: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
      writer: undefined,
    })
    const queryKey = ['sale-report', 'role', user.role.id, queryParam]

    const { data = initialData } = useQuery(queryKey, () =>
      client?.dashboardClient.saleReport({
        writerId: queryParam.writer?.id,
        bookType: queryParam.bookType,
        startDate: queryParam.startAt,
        endDate: queryParam.endAt,
        categoryIds: queryParam.categoryIds,
        roleId: user.role.id,
      })
    )

    const { data: categorySaleReports = [] } = useQuery(
      ['category-sale-reports', queryParam],
      () =>
        client?.dashboardClient.categorySaleReport({
          writerId: queryParam.writer?.id,
          bookType: queryParam.bookType,
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
          categoryIds: queryParam.categoryIds,
        })
    )

    function resetHandler() {
      setQueryParam({
        categoryIds: [],
        bookType: [],
        startAt: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endAt: DateTime.now().endOf('day'),
        writer: undefined,
      })
    }

    function newDateAt(
      startAt: Date | undefined,
      endAt: Date | undefined,
      timeRange: TimeRangeEnum
    ) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 })
        newEndAt = DateTime.now()
      } else if (timeRange === TimeRangeEnum.WEEK) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.WEEK] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.THREE_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.THREE_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.SIX_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.SIX_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return { newStartAt, newEndAt }
    }

    async function handleSearch({
      categoryIds,
      bookType,
      startAt,
      endAt,
      timeRange,
      writer,
    }: SalesReportSearchFormType) {
      const { newStartAt, newEndAt } = newDateAt(startAt, endAt, timeRange)
      if (newStartAt && newEndAt) {
        setQueryParam({
          categoryIds: categoryIds.map(row => Number(row)),
          bookType,
          startAt: newStartAt,
          endAt: newEndAt,
          writer,
        })
      }
    }

    async function handleExport() {
      try {
        const { startAt, endAt, bookType, writer, categoryIds } = queryParam

        const bookTypeParam = !bookType.length
          ? [BookEnum.MANGA, BookEnum.NOVEL]
          : bookType
        await client?.dashboardClient.exportSalesDashboard({
          startDate: startAt,
          endDate: endAt,
          bookTypes: bookTypeParam,
          categoryIds,
          writerId: writer?.id,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      queryKey,
      writer: queryParam.writer,
      bookType: queryParam.bookType,
      startAt: queryParam.startAt,
      endAt: queryParam.endAt,
      data,
      categorySaleReports,
      handleSearch,
      handleExport,
      resetHandler,
    }

    return <Component {...componentProps} />
  }

  return WithSalesReportPage
}
