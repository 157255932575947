import { gql } from 'graphql-request'

export const UPDATE_ADMIN_SALE_TARGET = gql`
  mutation UpdateAdminSaleTarget(
    $updateAdminSaleTargetInput: UpdateAdminSaleTargetInput!
  ) {
    updateAdminSaleTarget(
      updateAdminSaleTargetInput: $updateAdminSaleTargetInput
    ) {
      saleTarget
    }
  }
`
