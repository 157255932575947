import { Expose, Type } from 'class-transformer'
import { SearchPopupBannerType } from './SearchPopupBannerType'

export class SearchPopupBannerResponse {
  @Expose()
  @Type(() => SearchPopupBannerType)
  data: SearchPopupBannerType[]

  @Expose() page: number

  @Expose() total: number
}
