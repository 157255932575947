import { DateTime } from 'luxon'

import { PromotionBuyCoinConditionEnum } from '@interfaces/PromotionBuyCoinConditionEnum'
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { PromotionAvailableDayEnum } from '@interfaces/promotionDiscount/PromotionAvailableDayEnum'
import { PromotionDiscountTypeEnum } from '@interfaces/promotionDiscount/PromotionDiscountTypeEnum'
import { PromotionPaymentMethodEnum } from '@interfaces/promotionDiscount/PromotionPaymentMethodEnum'
import { PromotionRequestActionEnum } from '@interfaces/PromotionRequestActionEnum'
import { PromotionUserTypeConditionEnum } from '@interfaces/promotionDiscount/PromotionUserTypeConditionEnum'
import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { SettingPromotionStatusEnum } from '@interfaces/SettingPromotionStatusEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { BookEnum } from '@interfaces/BookEnum'

export const selectedChanelText = ({ channel }: { channel: string }) => {
  switch (channel) {
    case PromotionPaymentMethodEnum.DOLFIN_WALLET:
      return (
        <>
          <div>Dolfin</div>
          <div className=' last:hidden'>,</div>
        </>
      )
    case PromotionPaymentMethodEnum.CARD:
      return (
        <>
          <div>บัตรเครดิต/เดบิต</div>
          <div className=' last:hidden'>,</div>
        </>
      )
    case PromotionPaymentMethodEnum.QR_PROMPT_PAY:
      return (
        <>
          <div>สแกน QR พร้อมเพย์</div>
          <div className=' last:hidden'>,</div>
        </>
      )
    case PromotionPaymentMethodEnum.SHOPEE_PAY:
      return (
        <>
          <div>ShopeePay</div>
          <div className=' last:hidden'>,</div>
        </>
      )

    default:
      return <div>-</div>
  }
}

export const coinOptionsText = (text?: string) => {
  switch (text) {
    case PromotionBuyCoinConditionEnum.GREATER_THAN:
      return '>'
    case PromotionBuyCoinConditionEnum.LESS_THAN:
      return '<'
    case PromotionBuyCoinConditionEnum.GREATER_THAN_EQUAL:
      return '>='
    case PromotionBuyCoinConditionEnum.LESS_THAN_EQUAL:
      return '<='
    default:
      return '-'
  }
}

export const discountTypeOptionsText = (text?: string) => {
  switch (text) {
    case PromotionDiscountTypeEnum.BAHT:
      return 'บาท'
    case PromotionDiscountTypeEnum.PERCENTAGE:
      return '%'
    default:
      return '-'
  }
}

export const promotionStatusText = (text?: string) => {
  switch (text) {
    case SettingPromotionStatusEnum.ALL:
      return 'ทั้งหมด'
    case SettingPromotionStatusEnum.PENDING:
      return 'รอการอนุมัติ'
    case SettingPromotionStatusEnum.REJECTED:
      return 'Reject'
    case SettingPromotionStatusEnum.APPROVED:
      return 'อนุมัติแล้ว'
    case SettingPromotionStatusEnum.STARTED:
      return 'ดำเนินการแล้ว'
    case SettingPromotionStatusEnum.CLOSED:
      return 'ปิดโปรโมชั่น'
    case SettingPromotionStatusEnum.DRAFT:
      return 'แบบร่าง'
    case SettingPromotionStatusEnum.GENERATE_CODE:
      return 'สร้างโค้ด'
    case SettingPromotionStatusEnum.CODE_GENERATED:
      return 'สร้างโค้ดแล้ว'
    default:
      return '-'
  }
}

export const forUserTypeOptionsText = (text?: string) => {
  switch (text) {
    case PromotionUserTypeConditionEnum.NEW_USER:
      return 'ผู้ใช้งานใหม่'
    case PromotionUserTypeConditionEnum.ALL_USER:
      return 'ผู้ใช้งานทั้งหมด'
    case PromotionUserTypeConditionEnum.SPECIFY_USER:
      return 'กำหนดผู้ใช้งาน'
    default:
      return '-'
  }
}

export const dayOptionsText = (text?: string) => {
  switch (text) {
    case PromotionAvailableDayEnum.MONDAY:
      return 'วันจันทร์'
    case PromotionAvailableDayEnum.TUESDAY:
      return 'วันอังคาร'
    case PromotionAvailableDayEnum.WEDNESDAY:
      return 'วันพุธ'
    case PromotionAvailableDayEnum.THURSDAY:
      return 'วันพฤหัสบดี'
    case PromotionAvailableDayEnum.FRIDAY:
      return 'วันศุกร์'
    case PromotionAvailableDayEnum.SATURDAY:
      return 'วันเสาร์'
    case PromotionAvailableDayEnum.SUNDAY:
      return 'วันอาทิตย์'
    case PromotionAvailableDayEnum.ALL_DAY:
      return 'ทุกวัน'
    default:
      return '-'
  }
}

export const promotionRequestActionText = (text?: string) => {
  switch (text) {
    case PromotionRequestActionEnum.CREATE:
      return 'สร้างโปรโมชั่น'
    case PromotionRequestActionEnum.UPDATE:
      return 'แก้ไขโปรโมชั่น'
    case PromotionRequestActionEnum.STOP:
      return 'หยุดดำเนินการ'
    case PromotionRequestActionEnum.CONTINUE:
      return 'ดำเนินการ'
    default:
      return '-'
  }
}

export const promotionRequestStatusText = (text?: string) => {
  switch (text) {
    case PromotionRequestStatusEnum.PENDING:
      return 'รอการอนุมัติ'
    case PromotionRequestStatusEnum.APPROVED:
      return 'ดำเนินการแล้ว'
    case PromotionRequestStatusEnum.REJECTED:
      return 'Reject'
    default:
      return '-'
  }
}

export const promotionBenefitTypeText = (text?: string) => {
  switch (text) {
    case BuyCoinCodeTypeEnum.DISCOUNT:
      return 'รับส่วนลด'
    case BuyCoinCodeTypeEnum.COIN:
      return 'รับเพิ่ม'
    default:
      return '-'
  }
}

export const promotionChallengeActionText = (text?: string) => {
  switch (text) {
    case PromotionChallengeActionEnum.LOVE:
      return 'กดหัวใจ'
    case PromotionChallengeActionEnum.COMMENT:
      return 'Comment นิยาย'
    case PromotionChallengeActionEnum.SHARE:
      return 'Share นิยายไปยัง Social Media'
    case PromotionChallengeActionEnum.FINISH_PROFILE:
      return 'กรอกข้อมูล Profile ให้ครบ'
    case PromotionChallengeActionEnum.READ:
      return 'อ่านนิยายเรื่องใดก็ได้'
    case PromotionChallengeActionEnum.UNLOCK_CHAPTER:
      return 'ปลดล็อกนิยายรายตอน'
    case PromotionChallengeActionEnum.DONATE:
      return 'โดเนทตัวละคร'
    case PromotionChallengeActionEnum.SET_PRICE_CHAPTER:
      return 'เพิ่มตอนติดเหรียญ'
    case PromotionChallengeActionEnum.CREATE_BOOK:
      return 'สร้างเรื่องใหม่'
    default:
      return '-'
  }
}

export const promotionChallengeBenefitLimitTypeText = (text?: string) => {
  switch (text) {
    case PromotionChallengeLimitTypeEnum.ONCE:
      return 'ครั้งเดียว'
    case PromotionChallengeLimitTypeEnum.UNLIMITED:
      return 'ไม่จำกัด'
    case PromotionChallengeLimitTypeEnum.PER_DAY:
      return 'ครั้ง/วัน'
    case PromotionChallengeLimitTypeEnum.PER_CAMPAIGN:
      return 'ครั้ง/แคมเปญ'
    default:
      return '-'
  }
}

export const promotionChallengeReadConditionText = (text?: string) => {
  switch (text) {
    case PromotionChallengeReadConditionEnum.CHAPTER:
      return 'ตอน'
    case PromotionChallengeReadConditionEnum.BOOK:
      return 'เรื่อง'
    default:
      return '-'
  }
}

export const promotionChallengeBookTypeText = (text?: string) => {
  switch (text) {
    case BookEnum.NOVEL:
      return 'นิยาย'
    case BookEnum.MANGA:
      return 'การ์ตูน'
    default:
      return '-'
  }
}

export function transformDate(value: string) {
  return DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm', {
    locale: 'th-Th',
  })
}
