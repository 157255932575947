import { gql } from 'graphql-request'

export const GET_PURCHASE_PROPORTION = gql`
  query GetPurchaseProportion($startDate: DateTime, $endDate: DateTime) {
    getPurchaseProportion(startDate: $startDate, endDate: $endDate) {
      paymentMethod
      platform
      total
    }
  }
`
