import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import { FormDetailPromotionCodePageProps } from './interface'

export function withFormDetailPromotionCodePage(
  Component: React.FC<FormDetailPromotionCodePageProps>
) {
  function WithFormDetailPromotionCodePage() {
    const { id } = useParams()
    const navigate = useNavigate()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryKey: QueryKey = ['promotion', 'code', 'form-detail', Number(id)]

    const { data, isLoading } = useQuery(
      queryKey,
      () => client?.promotionCodeClient.getPromotionCodeFormDetail(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: promotionChannelOption = [], isLoading: channelLoading } =
      useQuery('saleChannels', () =>
        client?.promotionCodeClient.getSaleChannels()
      )

    const { data: coinOptions = [], isLoading: coinLoading } = useQuery(
      'coin-options',
      () => client?.promotionCodeClient.getCoinOptions()
    )

    const { mutateAsync: approvePromotionCode, isLoading: approvedLoading } =
      useMutation(
        (promotionRequestId: number) =>
          client!.promotionClient.updatePromotionRequest({
            id: promotionRequestId,
            status: PromotionStatusEnum.APPROVED,
          }),
        {
          onSuccess: () => {
            alert.success(`ดำเนินการสำเร็จ`)
            navigate('/promotion-setting')
          },
          onError: () => {
            alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
          },
        }
      )

    function handleApprove() {
      if (data?.lastRequest?.id) {
        approvePromotionCode(data?.lastRequest?.id)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId: data?.lastRequest?.id,
        promotionId: data?.id,
        promotionName: data?.promotionName,
        startPromotion: data!.startPublishedAt,
        endPromotion: data!.endPublishedAt,
        createdAt: new Date(data!.createdAt!),
        promotionDetail: data?.promotionDetail,
        onSuccess: () => navigate('/promotion-setting'),
      })
    }

    function goBackHandle() {
      navigate(-1)
    }
    if (id && data) {
      const newProps = {
        data,
        isLoading: isLoading || channelLoading || coinLoading,
        promotionChannelOption,
        coinOptions,
        handleApprove,
        handleReject,
        goBackHandle,
      }

      return <Component {...newProps} />
    }
    return null
  }

  return WithFormDetailPromotionCodePage
}
