import { Box } from '@components/Box'
import { NoData } from '@components/NoData'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { OrderByEnum } from '@interfaces/dashboard/HashtagParam'
import { HashtagProps } from './interface'

export function Hashtag({
  data,
  isLoading,
  handleSortChange,
  handleWaypointEnter,
}: HashtagProps) {
  const items = data?.pages.flatMap(row => row?.data) || []
  return (
    <>
      <Box.Title>Hashtag</Box.Title>

      <Table
        className='max-h-[250px] pr-[10px]'
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
        data={items}
        columns={[
          {
            column: 'ค้นหาจาก Hashtag',
            accessor: 'tag',
            cell: ({ value }) => (
              <div className='line-clamp-1 break-word'>{value}</div>
            ),
            cellClassName: 'py-[10px] pr-[10px]',
            sortKey: OrderByEnum.TAG,
            isSortable: true,
          },
          {
            column: 'จำนวนค้นหา',
            accessor: 'totalSearch',
            cell: ({ value }) => (
              <div>{(value as number).toLocaleString()}</div>
            ),
            cellClassName: 'py-[10px] pr-[10px] w-[180px]',
            sortKey: OrderByEnum.TOTAL_SEARCH,
            isSortable: true,
          },
        ]}
        initialSort={{
          key: OrderByEnum.TOTAL_SEARCH,
          order: SortingType.DESC,
        }}
        isLoading={isLoading}
        fixedHeader
        onSort={handleSortChange}
        onWaypointEnter={handleWaypointEnter}
      />
    </>
  )
}
