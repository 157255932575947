import { Table } from '@components/Table'
import { ByTitleTableProps } from './interface'
import { columns } from './column'

export function ByTitleTable({ data, isLoading }: ByTitleTableProps) {
  return (
    <Table
      fixedHeader
      className='max-h-[610px]'
      data={data ? [data] : []}
      columns={columns}
      isLoading={isLoading}
    />
  )
}
