import { NewsAndPromotionTableProps } from './interface'

export function withNewsAndPromotionTable(
  Component: React.FC<NewsAndPromotionTableProps>
) {
  function WithNewsAndPromotionTable(props: NewsAndPromotionTableProps) {
    return <Component {...props} />
  }

  return WithNewsAndPromotionTable
}
