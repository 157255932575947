import { gql } from 'graphql-request'

export const GET_HIGHEST_EARNING_WRITING = gql`
  query GetHighestEarningWriting(
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: HighestEarningWritingOrderBy
  ) {
    getHighestEarningWriting(
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
    ) {
      bookId
      rowNumber
      bookCoverImgPath
      bookTitle
      bookType
      firstPenName
      bookViewCount
      bookLikeCount
      bookChapterCount
      bookTotalSales
    }
  }
`
