import { gql } from 'graphql-request'

export const CREATE_OR_UPDATE_CATEGORIES = gql`
  mutation CreateOrUpdateCategory(
    $createOrUpdateCategoryInput: [CreateOrUpdateCategoryInput!]!
  ) {
    createOrUpdateCategory(
      createOrUpdateCategoryInput: $createOrUpdateCategoryInput
    ) {
      id
      isManga
      isNovel
      name
    }
  }
`
