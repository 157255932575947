import { gql } from 'graphql-request'

export const GET_BOOK_BANNER = gql`
  query GetBookBanners {
    getBookBanners {
      id
      bookId
      runningNo
      isOnWeb
      isOnIos
      isOnAndroid
      linkWeb
      linkIos
      linkAndroid
      isActive
      createdAt
      updatedAt
      book {
        id
        writer
        coverImgPath
        title
      }
    }
  }
`

export const ADMIN_SEARCH_BOOKS = gql`
  query AdminSearchBooks(
    $bookType: BookType
    $categoryIds: [Int!]
    $orderBy: OrderedBy
    $secondaryType: RecommendTypeSearch
    $searchText: String
    $isEbook: Boolean
  ) {
    adminSearchBooks(
      bookType: $bookType
      categoryIds: $categoryIds
      orderBy: $orderBy
      secondaryType: $secondaryType
      searchText: $searchText
      isEbook: $isEbook
    ) {
      data {
        id
        writer
        title
        coverImgPath
        bookType
        penName {
          id
          firstPenName
          user {
            id
            displayName
          }
        }
      }
      total
    }
  }
`

export const UPDATE_BOOK_BANNER = gql`
  mutation UpdateBookBanner($updateBookBannerInput: UpdateBookBannerInput!) {
    updateBookBanner(updateBookBannerInput: $updateBookBannerInput) {
      id
    }
  }
`

export const CREATE_GROUP_BOOK_BANNER = gql`
  mutation Mutation($createBookBannersInput: CreateBookBannersInput!) {
    createBookBanners(createBookBannersInput: $createBookBannersInput) {
      id
      runningNo
    }
  }
`

export const REMOVE_GROUP_BOOK_BANNER = gql`
  mutation RemoveBookBanners($highlightGroupId: Int!, $ids: [Int!]!) {
    removeBookBanners(highlightGroupId: $highlightGroupId, ids: $ids) {
      id
      bookId
    }
  }
`

export const DELETE_BOOK_BANNER = gql`
  mutation RemoveBookBanner($removeBookBannerId: Int!) {
    removeBookBanner(id: $removeBookBannerId) {
      id
    }
  }
`

export const GET_HIGHLIGHT_GROUP_BOOK_BANNER = gql`
  query GetHighlightBookBanner($id: Int!) {
    getHighlightBookBanner(id: $id) {
      id
      bannerName
      runningNo
      bannerType
      status
      isActive
      publishedFrom
      publishedTo
      bookBanner {
        id
        bookId
        runningNo
        isOnWeb
        isOnIos
        isOnAndroid
        linkWeb
        linkIos
        linkAndroid
        isActive
        isEbook
        createdAt
        updatedAt
        book {
          id
          writer
          coverImgPath
          title
          bookType
          penName {
            id
            firstPenName
            user {
              id
              displayName
            }
          }
        }
      }
    }
  }
`
