import { gql } from 'graphql-request'

export const GET_TOP_MONEY_SPENDER = gql`
  query GetTopMoneySpender(
    $orderBy: GetUserTopSpendOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getTopMoneySpender(
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      userId
      username
      userMoneySpend
    }
  }
`
