import cn from 'classnames'
import { Pagination } from '@components/Pagination'
import { Search } from '@components/Search'
import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { UserMissionTableProps } from './interface'

export function UserMissionTable({
  data,
  columns,
  page,
  perpage,
  total,
  isLoading,
  pageChange,
  handleSort,
  handleSearch,
  handleResetSearch,
  exportReport,
}: UserMissionTableProps) {
  return (
    <div>
      <Search
        placeholder='ค้นหาด้วยชื่อ หรือ ID'
        onSearch={text => {
          handleSearch(text)
        }}
        onReset={() => {
          handleResetSearch()
        }}
        onExport={exportReport}
      />

      <Table
        data={data}
        columns={columns}
        className={cn('my-[30px]', { 'min-h-[400px]': isLoading })}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
        onSort={handleSort}
        isLoading={isLoading}
      />
      <Pagination
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
      />
    </div>
  )
}
