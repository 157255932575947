import cn from 'classnames'

import { CheckIcon, ErrorIcon, UploadCodeIcon } from '@components/Icons'
import { Button } from '@components/Button'
import { Loading } from '@components/Loading'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import { UniqeCodeTypeEnum } from '@interfaces/promotionCode/UniqeCodeTypeEnum'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'

import { UploadPromotionCodeFieldProps } from './interface'

function UploadPromotionCodeField({
  values,
  fileName,
  couponCount,
  error,
  isCheckingFile,
  inputRef,
  onChange,
  showUploadDialog,
  onClearSelectedFile,
  generateCoupon,
}: UploadPromotionCodeFieldProps) {
  const disabled =
    values.uniqeCodeType !== UniqeCodeTypeEnum.UPLOAD_CODE ||
    generateCoupon === PromotionCodeJobStatusEnum.PROCESSING
  const isError = !!error
  return (
    <PromotionRadioInputField
      name='uniqeCodeType'
      className='w-full'
      disabled={generateCoupon === PromotionCodeJobStatusEnum.PROCESSING}
      value={UniqeCodeTypeEnum.UPLOAD_CODE}
      checked={values.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE}
    >
      <div
        className={cn(
          'flex  items-center justify-between border-2 rounded-lg ml-[44px] px-5 py-[13px]',
          {
            'border-primary': !disabled,
            'border-gray': disabled,
            'border-red-74': isError,
          }
        )}
      >
        <div className='flex items-center space-x-5'>
          <UploadCodeIcon />
          <div className='text-sm font-light text-secondary !mr-5'>
            อัปโหลดไฟล์คูปอง (.CSV)
          </div>
          <div className='h-[50px] w-[1px] border-l border-r mx-1' />
          <Button
            type='button'
            onClick={showUploadDialog}
            className={cn('bg-primary text-white rounded-lg', {
              'opacity-50': disabled,
            })}
          >
            <span className='text-sm font-sarabun font-bold'>Browse</span>
          </Button>
        </div>
        {fileName && (
          <div className='flex items-center space-x-5'>
            <div className='flex items-center space-x-2'>
              {!isCheckingFile ? (
                isError ? (
                  <ErrorIcon width='18' height='18' />
                ) : (
                  <CheckIcon
                    width='18'
                    height='18'
                    className='text-white bg-primary rounded-full p-1'
                  />
                )
              ) : (
                <Loading className='!my-0' size={18} />
              )}
              <span className='text-sm font-bold'>{fileName}</span>
            </div>
            {!!couponCount && values.codeFile?.url && (
              <span className='text-[14px] text-secondary px-[20px]'>
                {couponCount.toLocaleString()} Code
              </span>
            )}
            {!disabled && (
              <Button type='button' onClick={onClearSelectedFile}>
                <span className='text-sm text-primary underline font-bold font-sarabun'>
                  Clear
                </span>
              </Button>
            )}
          </div>
        )}
        <input
          ref={inputRef}
          className='hidden'
          type='file'
          accept='.csv'
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </PromotionRadioInputField>
  )
}

export default UploadPromotionCodeField
