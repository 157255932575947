import React from 'react'
import {
  PromotionSettingSearchFormProps,
  WithPromotionSettingSearchFormProps,
} from './interface'

export function withPromotionSettingSearchForm(
  Component: React.FC<PromotionSettingSearchFormProps>
) {
  function WithPromotionSettingSearchForm(
    props: WithPromotionSettingSearchFormProps
  ) {
    return <Component {...props} />
  }

  return WithPromotionSettingSearchForm
}
