import { gql } from 'graphql-request'

export const GET_ADMIN_USER = gql`
  query AdminLists(
    $limitPerPage: Int
    $page: Int
    $search: String
    $orderBy: AdminListsOrderBy
  ) {
    adminLists(
      limitPerPage: $limitPerPage
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      data {
        id
        businessUserId
        lastLoginAt
        role {
          id
        }
        user {
          displayName
          email
        }
      }
      total
    }
  }
`
