import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { ArrowLeftIcon } from '@components/Icons'
import { Loading } from '@components/Loading'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'

import { PromotionCodeTransaction } from './components/PromotionCodeTransaction'
import { PromotionCodeInfo } from './components/PromotionCodeInfo'
import { PromotionCodeApproveSection } from './components/PromotionCodeApproveSection'
import { PromotionCodeDetailPageProps } from './interface'

export function PromotionCodeDetailPage({
  data,
  isLoading,
  toggleActiveLoading,
  goBackHandle,
  handleActivePromotion,
  handleDeletePromotion,
  handleEditPromotion,
  handleGoToHistoryPage,
}: PromotionCodeDetailPageProps) {
  const permission = usePermissionAction('promotionSetting')
  const canDelete = [
    PromotionStatusEnum.DRAFT,
    PromotionStatusEnum.GENERATE_CODE,
    PromotionStatusEnum.GENERATE_FAIL,
    PromotionStatusEnum.CODE_GENERATED,
  ]
  const isSeeTransaction = [
    PromotionStatusEnum.APPROVED,
    PromotionStatusEnum.STARTED,
    PromotionStatusEnum.CLOSED,
  ]
  return (
    <Container>
      {!isLoading ? (
        data ? (
          <>
            <div className='flex items-center gap-x-[20px] mb-[28px]'>
              <button
                className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
                type='button'
                onClick={goBackHandle}
              >
                <ArrowLeftIcon width='24' height='24' />
              </button>

              <div className='flex justify-between items-center  flex-1'>
                <div className='text-[24px] text-secondary font-medium font-mitr line-clamp-1 break-word'>
                  {data?.name}
                </div>
                <Button
                  onClick={handleGoToHistoryPage}
                  type='button'
                  className='border border-secondary rounded-[20px] whitespace-nowrap'
                >
                  <span className='font-mitr font-medium text-sm'>
                    ดูประวัติการแก้ไขโปรโมชั่น
                  </span>
                </Button>
              </div>
            </div>
            <PromotionCodeInfo
              promotionStatus={data.status}
              values={data}
              toggleActiveLoading={toggleActiveLoading}
              handleChangeStatus={
                permission.edit &&
                data.isEditable &&
                (data.status === PromotionStatusEnum.STARTED ||
                  data.status === PromotionStatusEnum.CLOSED) &&
                new Date(data.startPublishedAt!) <= new Date() &&
                new Date(data.endPublishedAt!) >= new Date()
                  ? () => {
                      handleActivePromotion(!data.isActive)
                    }
                  : undefined
              }
              // DESC: enabled edit button when lastRequest has no value (meaning no promotion request to be approved or rejected)
              handleEditPromotion={
                permission.edit && data.isEditable && !data.lastRequest
                  ? handleEditPromotion
                  : undefined
              }
              handleDeletePromotion={
                permission.delete && canDelete.includes(data.status)
                  ? handleDeletePromotion
                  : undefined
              }
            >
              {permission.approve && !!data.lastRequest && (
                <PromotionCodeApproveSection
                  requestId={data.lastRequest.id}
                  promotionId={data.id}
                  promotionName={data.name || ''}
                  promotionDetail={data.description}
                  startPromotion={new Date(data.startPublishedAt!)}
                  endPromotion={new Date(data.endPublishedAt!)}
                  createdAt={new Date(data.createdAt)}
                  lastRequest={data.lastRequest}
                />
              )}
            </PromotionCodeInfo>
            <div className='flex py-[40px] border-b-[1px] gap-x-[60px]'>
              <div>
                <div className='text-[14px] text-secondary-100 font-light'>
                  ช่องทาง
                </div>
                <div className='font-bold text-[24px] text-secondary'>
                  {data.promotionCode.channels[0].name}
                </div>
              </div>
              <div>
                <div className='text-[14px] text-secondary-100 font-light'>
                  Code ทั้งหมด
                </div>
                <div className='font-bold text-[24px] text-secondary'>
                  {data.promotionCode.channels[0].totalCount.toLocaleString()}
                </div>
              </div>
              <div>
                <div className='text-[14px] text-secondary-100 font-light'>
                  ใช้แล้ว
                </div>
                <div className='font-bold text-[24px] text-primary'>
                  {data.promotionCode.channels[0].totalUsedCount.toLocaleString()}
                </div>
              </div>
            </div>

            {isSeeTransaction.includes(data.status) &&
              data.promotionCode.couponType && (
                <PromotionCodeTransaction
                  exportedCouponFilePath={
                    data.promotionCode.exportedCouponFilePath
                  }
                  codesFilePath={data.promotionCode.codesFilePath}
                  couponType={data.promotionCode.couponType}
                  promotionType={data.promotionCode.type}
                  coinName={data.promotionCode.coinName}
                  isUsageLimitPerUser={data.isUsageLimitPerUser}
                  id={data.promotionCode.id}
                />
              )}
          </>
        ) : (
          <div>เกิดข้อผิดพลาด กรุณาลองใหม่ในภายหลัง</div>
        )
      ) : (
        <Loading size={32} className='m-auto mt-[100px]' />
      )}
    </Container>
  )
}
