import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { timeRangeValue } from '@interfaces/TimeRangeEnum'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  PromotionChallengeTransactionTableAcceptProps,
  PromotionChallengeTransactionTableProps,
} from './inteface'

export function withPromotionChallengeTransactionTable(
  Component: React.FC<PromotionChallengeTransactionTableProps>
) {
  function Hoc({
    searchParams,
    promotionChallengeId,
    action,
    page,
    pageChange,
    total,
    perpage,
    handleTotalChange,
  }: PromotionChallengeTransactionTableAcceptProps) {
    const [sortParams, setSortParams] = useState<{
      sortKey: string
      sortOrder: SortingType
    }>({
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })
    const queryKey = ['promotion-challenge', searchParams, sortParams, page]
    const client = useClient()

    const { data: promotionChallengeLogs = [], isLoading } = useQuery(
      queryKey,
      () => {
        let startAt = searchParams.startDate
          ? DateTime.fromJSDate(searchParams.startDate).startOf('day').toUTC()
          : DateTime.now()
              .minus({ days: timeRangeValue[KeyTypeEnum.MONTH] })
              .startOf('day')
              .toUTC()
        let endAt = searchParams.endDate
          ? DateTime.fromJSDate(searchParams.endDate).endOf('day').toUTC()
          : DateTime.now().endOf('day').toUTC()

        if (searchParams.selectedDate === KeyTypeEnum.DAY) {
          startAt = DateTime.now().startOf('day').toUTC()
          endAt = DateTime.now().endOf('day').toUTC()
        } else if (searchParams.selectedDate === KeyTypeEnum.MONTH) {
          startAt = DateTime.now()
            .minus({ days: timeRangeValue[KeyTypeEnum.MONTH] })
            .startOf('day')
            .toUTC()
          endAt = DateTime.now().endOf('day').toUTC()
        } else if (searchParams.selectedDate === KeyTypeEnum.YEAR) {
          startAt = DateTime.now()
            .minus({ days: timeRangeValue[KeyTypeEnum.YEAR] })
            .startOf('day')
            .toUTC()
          endAt = DateTime.now().endOf('day').toUTC()
        }

        return client?.promotionChallengeClient
          .promotionChallengeLogs({
            promotionChallengeId: Number(promotionChallengeId),
            searchText: searchParams.searchText,
            startDate: startAt,
            endDate: endAt,
            sortKey: sortParams.sortKey,
            orderBy: sortParams.sortOrder,
            limitPerPage: perpage,
            page,
          })
          .then(res => {
            pageChange(page)
            handleTotalChange(res.total)
            return res.data
          })
      },
      {
        enabled: !!promotionChallengeId && !!Number(promotionChallengeId),
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSortParams(prev => ({
        ...prev,
        sortKey: key,
        sortOrder: order,
      }))
    }

    const newProps = {
      data: promotionChallengeLogs,
      page,
      perpage,
      total,
      pageChange,
      handleSort,
      action,
      isLoading,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
