import { addComma } from '@libs/utils'
import cn from 'classnames'

import {
  ProgressBarProps,
  ProgressBarTwoSideProps,
  ProgressBarOverAllTwoSideProps,
} from './interface'

export function ProgressBar({
  value,
  max,
  className,
  progressBarClassName,
}: ProgressBarProps) {
  const progress = (value / max) * 100
  return (
    <div
      className={cn(
        'w-full h-[10px] rounded-[40px] overflow-hidden bg-gray',
        className
      )}
    >
      <div
        className={cn('h-[10px]', progressBarClassName)}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  )
}

export function ProgressBarOverAll({
  value,
  max,
  className,
  progressBarClassName,
}: ProgressBarProps) {
  const progress = (value / max) * 100

  return (
    <div
      className={cn(
        'w-full h-[56px] flex rounded-[8px] font-bold items-center bg-secondary text-white overflow-hidden',
        className
      )}
    >
      <div
        className={cn(
          'h-full px-[20px] flex items-center gap-x-[10px] rounded-l-[8px]',
          progressBarClassName,
          {
            'bg-secondary': progress === 0,
            'bg-primary': progress > 0,
          }
        )}
        style={{
          width: `${Math.min(100, progress)}%`,
        }}
      >
        <span className='text-[24px]'>{addComma(value)}</span>
        <span className='text-[16px] mt-[3px]'>({progress.toFixed(2)}%)</span>
      </div>
      <div
        className={cn('flex-1 flex px-[20px] justify-end', {
          hidden: value >= max,
        })}
      >
        <span className='text-[24px]'>{addComma(max)}</span>
      </div>
    </div>
  )
}

export function ProgressBarTwoSide({
  firstValue,
  secondValue,
  firstLabel,
  secondLabel,
  className,
  progressBarClassName,
}: ProgressBarTwoSideProps) {
  const sum = firstValue + secondValue
  const firstProgress = (firstValue / sum) * 100 || 0
  const secondProgress = (secondValue / sum) * 100 || 0

  return (
    <div>
      <div className='flex justify-between text-secondary-100 text-[14px] mb-[5px]'>
        <span>{firstLabel}</span>
        <span>{secondLabel}</span>
      </div>
      <div
        className={cn(
          'w-full  h-[30px] flex rounded-[8px] bg-secondary text-white overflow-hidden',
          className
        )}
      >
        <div
          className={cn(
            'flex items-center h-full  font-bold gap-x-[10px]',
            progressBarClassName
          )}
          style={{
            width: `${firstProgress}%`,
          }}
        >
          <span className='text-[16px] ml-[10px]'>{addComma(firstValue)}</span>
          <span className='text-[12px]'>({firstProgress.toFixed(2)}%)</span>
        </div>
        <div
          className={cn(
            'flex items-center justify-end h-full px-[10px] gap-x-[10px] font-bold'
          )}
          style={{
            width: `${secondProgress}%`,
          }}
        >
          <span className='text-[12px]'>({secondProgress.toFixed(2)}%)</span>
          <span className='text-[16px]'>{addComma(secondValue)}</span>
        </div>
      </div>
    </div>
  )
}

export function ProgressBarOverAllTwoSide({
  firstValue,
  secondValue,
  firstLabel,
  secondLabel,
  className,
  progressBarClassName,
}: ProgressBarOverAllTwoSideProps) {
  const sum = firstValue + secondValue
  const firstProgress = (firstValue / sum) * 100 || 0
  const secondProgress = (secondValue / sum) * 100 || 0

  return (
    <div>
      <div className='flex justify-between text-secondary-100 text-[14px] font-light mb-[9px]'>
        <span>{firstLabel}</span>
        <span>{secondLabel}</span>
      </div>
      <div
        className={cn(
          'w-full h-[56px] flex rounded-[8px] font-bold items-center bg-secondary text-white overflow-hidden',
          className
        )}
      >
        <div
          className={cn(
            'h-full flex items-center px-[20px] gap-x-[10px] bg-primary rounded-[8px]',
            progressBarClassName
          )}
          style={{
            width: `${firstProgress}%`,
          }}
        >
          <span className='text-[24px]'>{addComma(firstValue, 0)}</span>
          <span className='text-[16px] mt-[3px]'>
            ({firstProgress.toFixed(2)}%)
          </span>
        </div>
        <div
          className={cn(
            'h-full flex items-center justify-end px-[20px] gap-x-[10px] font-bold',
            {
              '!w-full': !secondProgress,
            }
          )}
          style={{
            width: `${secondProgress}%`,
          }}
        >
          <span className='text-[16px] mt-[3px]'>
            ({secondProgress.toFixed(2)}%)
          </span>
          <span className='text-[24px]'>{addComma(secondValue, 0)}</span>
        </div>
      </div>
    </div>
  )
}
