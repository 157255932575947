/* eslint-disable class-methods-use-this */
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'
import { DonationSearchFormType } from '@interfaces/dashboard/DonationSearchFormType'
import { CharacterDonationByCategory } from '@models/character/CharacterDonationByCategory'
import { CharacterDonationByStoryResponse } from '@models/character/CharacterDonationByStory'
import { CharacterDonationByWriterResponse } from '@models/character/CharacterDonationByWriter'
import { CharacterDonationSearchParams } from '@interfaces/character/CharacterDonationSearchParams'
import { CharacterDonationByCharacterResponse } from '@models/character/CharacterDonationByCharacter'
import { CharacterDonationBySpenderResponse } from '@models/character/CharacterDonationBySpender'
import { TotalCharacterDonation } from '@models/character/TotalCharacterDonation'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'
import { CHARACTER_DONATION_BY_CATEGORY } from './schemas/characterDonationByCategory'
import { CHARACTER_DONATION_BY_STORY } from './schemas/characterDonationByStory'
import { CHARACTER_DONATION_BY_WRITER } from './schemas/characterDonationByWriter'
import { CHARACTER_DONATION_BY_CHARACTER } from './schemas/characterDonationByCharacter'
import { CHARACTER_DONATION_BY_SPENDER } from './schemas/characterDonationBySpender'
import { TOTAL_CHARACTER_DONATION } from './schemas/totalCharacterDonation'

export class CharacterClient {
  constructor(private client: GraphQLClient) {}

  async getCharacterDonationByCategory({
    startDate,
    endDate,
  }: DonationQueryParam): Promise<CharacterDonationByCategory[]> {
    const { characterDonationByCategory } = await this.client.request(
      CHARACTER_DONATION_BY_CATEGORY,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
      }
    )

    return plainToInstance(
      CharacterDonationByCategory,
      characterDonationByCategory as []
    )
  }

  async getCharacterDonationByStory(
    searchParams: CharacterDonationSearchParams
  ): Promise<CharacterDonationByStoryResponse> {
    const { characterDonationByStory } = await this.client.request(
      CHARACTER_DONATION_BY_STORY,
      this.buildSearchParams(searchParams)
    )

    return plainToInstance(
      CharacterDonationByStoryResponse,
      characterDonationByStory
    )
  }

  async getCharacterDonationByWriter(
    searchParams: CharacterDonationSearchParams
  ): Promise<CharacterDonationByWriterResponse> {
    const { characterDonationByWriter } = await this.client.request(
      CHARACTER_DONATION_BY_WRITER,
      this.buildSearchParams(searchParams)
    )

    return plainToInstance(
      CharacterDonationByWriterResponse,
      characterDonationByWriter
    )
  }

  async getCharacterDonationByCharacter(
    searchParams: CharacterDonationSearchParams
  ): Promise<CharacterDonationByCharacterResponse> {
    const { characterDonationByCharacter } = await this.client.request(
      CHARACTER_DONATION_BY_CHARACTER,
      this.buildSearchParams(searchParams)
    )

    return plainToInstance(
      CharacterDonationByCharacterResponse,
      characterDonationByCharacter
    )
  }

  async getCharacterDonationBySpender(
    searchParams: CharacterDonationSearchParams
  ): Promise<CharacterDonationBySpenderResponse> {
    const { characterDonationBySpender } = await this.client.request(
      CHARACTER_DONATION_BY_SPENDER,
      this.buildSearchParams(searchParams)
    )
    return plainToInstance(
      CharacterDonationBySpenderResponse,
      characterDonationBySpender
    )
  }

  async getTotalCharacterDonation({
    startDate,
    endDate,
  }: DonationQueryParam): Promise<TotalCharacterDonation> {
    const { totalCharacterDonation } = await this.client.request(
      TOTAL_CHARACTER_DONATION,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
      }
    )
    return plainToInstance(TotalCharacterDonation, totalCharacterDonation)
  }

  private buildSearchParams({
    startDate,
    endDate,
    key,
    order,
    page,
    limitPerPage,
  }: CharacterDonationSearchParams) {
    return {
      dateRange: {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      },
      orderBy: key || undefined,
      orderDirection: key ? order : undefined,
      page,
      limitPerPage,
    }
  }
}
