import { Expose, Type } from 'class-transformer'

import { ChallengeUnit } from '@interfaces/user-mission/ChallengeEnum'
import { UserMissionEnum } from '@interfaces/user-mission/UserMissionEnum'
import { ChallengeLevelType } from './ChallengeLevelType'

export class UserMissionType {
  @Expose() id: number

  @Expose() title: string

  @Expose() description: string

  @Expose() iconUrl: string

  @Expose() active: boolean

  @Expose() criteria: string

  @Expose() tableHeader: string

  @Expose() challengeUnit: ChallengeUnit

  @Expose() challengeType: UserMissionEnum

  @Expose({ name: 'levels' })
  @Type(() => ChallengeLevelType)
  challengeLevels: ChallengeLevelType[]

  @Expose() missionLink: string
}
