import cn from 'classnames'

import { Upload2Icon, CameraIcon } from '@components/Icons'
import { UploadSquareImageProps } from './interface'

export function UploadSquareImage({
  src,
  title,
  size,
  inputRef,
  isError,
  onChange,
  showUploadDialog,
}: UploadSquareImageProps) {
  return (
    <div className='relative cursor-pointer'>
      {src && (
        <div
          onClick={showUploadDialog}
          className='relative w-[159px] aspect-square'
        >
          <img
            src={src}
            className='w-full h-full rounded-full overflow-hidden border border-gray'
            alt='cover'
          />

          <div className='absolute bottom-2 right-2 bg-white rounded-full w-[30px] h-[30px] border border-gray flex flex-col items-center justify-center text-blue-3'>
            <CameraIcon width='24' height='24' />
          </div>
        </div>
      )}
      {!src && (
        <div
          className={cn(
            'bg-blue-2 w-[159px] aspect-square rounded-full flex flex-col items-center justify-center text-secondary-100',
            {
              'border border-red': isError,
            }
          )}
          onClick={showUploadDialog}
        >
          <Upload2Icon className='w-[24px] h-[24px] text-secondary-100' />
          <div className='font-medium font-mitr text-[14px] underline'>
            {title}
          </div>
          <div className='text-[14px] font-light font-sarabun pt-[10px]'>
            {size} X {size} px
          </div>
        </div>
      )}
      <input
        ref={inputRef}
        className='hidden'
        type='file'
        accept='image/*'
        onChange={onChange}
      />
    </div>
  )
}
