import { withPermissionGuard } from '@libs/permission'
import { SalesLayout } from './SalesLayout'
import { withSalesLayout } from './withSalesLayout'

const ConnectedSalesLayout = withPermissionGuard(
  withSalesLayout(SalesLayout),
  'sale',
  'view'
)

export { ConnectedSalesLayout as SalesLayout }
