import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import {
  NewWriterPopularTableProps,
  WithNewWriterPopularTableProps,
} from './interface'

export function withNewWriterPopularTable(
  Component: React.FC<NewWriterPopularTableProps>
) {
  function WithNewWriterPopularTable({
    searchParams,
  }: WithNewWriterPopularTableProps) {
    const [sort, setSort] = useState({
      sortKey: 'userViewCount',
      sortOrder: 'DESC',
    })

    const { data = [], isLoading } = useQuery(
      ['writer-top-sale', searchParams, sort],
      () =>
        client!.dashboardClient.getHotWriter({
          ...searchParams,
          ...sort,
        }),
      {
        enabled: !!searchParams,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort({ sortKey: key, sortOrder: order })
    }

    const newProps = { data, isLoading, handleSort }

    return <Component {...newProps} />
  }

  return WithNewWriterPopularTable
}
