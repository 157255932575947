import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByWriterType {
  @Expose() writerId: number

  @Expose() userId: number

  @Expose() displayName: string

  @Expose() totalPenName: number

  @Expose() totalBook: number

  @Expose() totalSearch: number
}

@Exclude()
export class SearchResultByWriterResponse {
  @Expose()
  @Type(() => SearchResultByWriterType)
  data: SearchResultByWriterType[]

  @Expose() page: number
}
