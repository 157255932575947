import { withPermissionGuard } from '@libs/permission'
import { PopupBannerLayout } from './PopupBannerLayout'
import { withPopupBannerLayout } from './withPopupBannerLayout'

const ConnectedPopupBannerLayout = withPermissionGuard(
  withPopupBannerLayout(PopupBannerLayout),
  'sale',
  'view'
)

export { ConnectedPopupBannerLayout as PopupBannerLayout }
