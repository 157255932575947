import { Type } from 'class-transformer'
import { UserCoinHistoriesType } from './UserCoinHistoriesType'

export class UserCoinHistoriesResponse {
  @Type(() => UserCoinHistoriesType)
  data: UserCoinHistoriesType[]

  total: number

  page: number
}
