import { WrtierType } from '@models/writer/WrtierType'
import { Expose, Type } from 'class-transformer'
import { PenNameType } from './PenNameType'

export class UserManagementDetailResponse {
  @Expose() id: number

  @Expose() username: string

  @Expose() email: string

  @Expose() displayName: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType[]

  @Expose() writer: WrtierType

  @Expose() penNameCount: number

  @Expose() totalBook: number

  @Expose() avatarImgPath: string

  @Expose() bannerImgPath: string

  @Expose() currentFreeCoin: number

  @Expose() currentPaidCoin: number

  @Expose() isBan: boolean
}
