import { Box } from '@components/Box'
import { useWriterDetail } from '../hooks/useWriterDetail'
import { TextInput } from './TextInput'

export function CoordinatorInformation() {
  const { writer } = useWriterDetail()
  return (
    <div>
      <Box.Title>ข้อมูลฝ่ายผู้ประสานงาน</Box.Title>
      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <TextInput
          label='ชื่อ - นามสกุลผู้ประสานงาน'
          placeholder='กรอกชื่อ - นามสกุลผู้ประสานงาน'
          value={writer?.coordinatorFullName || ''}
        />
        <TextInput
          label='อีเมล'
          placeholder='email@example.com'
          value={writer?.coordinatorEmail || ''}
        />
        <TextInput
          label='เบอร์โทรศัพท์'
          placeholder='(+66) กรอกเบอร์โทรศัพท์'
          value={writer?.coordinatorPhoneNumber || ''}
        />
      </div>
    </div>
  )
}
