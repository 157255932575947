import { ReportReplyFormAcceptProps, ReportReplyFormProps } from './interface'

export function withReportReplyForm(Component: React.FC<ReportReplyFormProps>) {
  function WithReportReplyForm({
    messageToBookOwner,
    messageToReporter,
    ...props
  }: ReportReplyFormAcceptProps) {
    const initialValueMessageToOwner = {
      messageToBookOwner,
    }
    const initialValueMessageToReporter = {
      messageToReporter,
    }
    const newProps = {
      ...props,
      initialValueMessageToOwner,
      initialValueMessageToReporter,
    }
    return <Component {...newProps} />
  }

  return WithReportReplyForm
}
