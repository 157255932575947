/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'
import { Pagination } from '@components/Pagination'
import { Toggle } from '@components/Toggle'
import { Checkbox } from '@components/Checkbox'
import { ColumnType, SortingType } from '@components/Table/interface'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { NewsAndPromotionType } from '@models/newsAndPromotion/NewsAndPromotionType'
import { Table } from '@components/Table'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { ActionCell } from './components/ActionCell/ActionCell'
import { NewsAndPromotionTableProps } from './interface'

export function NewsAndPromotionTable({
  page,
  perpage,
  total,
  data,
  selectedIds,
  pageChange,
  handleSort,
  handleSelectAll,
  handleSelect,
  handleDelete,
  handleEnable,
  isLoading,
}: NewsAndPromotionTableProps) {
  const permission = usePermissionAction('newsPromotion')
  const columns: ColumnType<NewsAndPromotionType>[] = useMemo(() => {
    return [
      {
        column: (
          <Checkbox
            defaultChecked={
              selectedIds.length > 0 && selectedIds.length === data.length
            }
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              handleSelectAll()
            }}
          />
        ),
        accessor: 'id',
        cell: ({ value }) => (
          <Checkbox
            defaultChecked={selectedIds.includes(value)}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              handleSelect(value)
            }}
          />
        ),
        cellClassName: cn('min-w-[50px] w-[50px]', {
          hidden: !permission.delete,
        }),
      },
      {
        column: 'รูป',
        accessor: 'thumbnailImgPath',
        cell: ({ value }) => (
          <img
            className='object-cover w-[66px] h-[50px]'
            src={value}
            alt='cover'
          />
        ),
        cellClassName: 'min-w-[66px] w-[66px] pr-[20px]',
      },
      {
        column: 'วันที่-เวลา',
        accessor: 'updatedAt',
        cell: ({ value }) => (
          <p>
            {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
              locale: 'th-Th',
            })}
          </p>
        ),
        isSortable: true,
      },
      {
        column: 'สร้างโดย',
        accessor: 'user',
        cell: ({ value }) => (
          <p className='max-w-[60px] truncate'>{value.displayName}</p>
        ),
      },
      {
        column: 'หัวข้อ',
        accessor: 'newsType',
        cell: ({ value }) =>
          value === NewsTypeEnum.PROMOTION ? (
            <p className='text-blue-5'>โปรโมชั่น</p>
          ) : (
            <p className='text-green'>ข่าวสาร</p>
          ),
      },
      {
        column: 'ชื่อเรื่อง',
        accessor: 'name',
        cell: ({ value }) => (
          <p className='max-w-[150px] font-mitr truncate'>{value}</p>
        ),
      },
      {
        column: 'รายละเอียด',
        accessor: 'description',
        cell: ({ value }) => (
          <div className='max-w-[200px] line-clamp-1 break-all'>{value}</div>
        ),
      },
      {
        column: 'แสดง',
        accessor: 'isActive',
        cell: ({ value, obj }) => {
          return (
            <Toggle
              disabled={!permission.edit}
              checked={value}
              value={value}
              onChange={() => handleEnable(obj.id, !value)}
            />
          )
        },
        isSortable: true,
        cellClassName: cn({
          hidden: !permission.edit,
        }),
      },
      {
        column: 'Action',
        actionCell: ({ obj }) => (
          <ActionCell slug={obj.slug} id={obj.id} handleDelete={handleDelete} />
        ),
        isAction: true,
        cellClassName: 'flex justify-end py-[10px]',
      },
    ]
  }, [data, selectedIds, permission])

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'ageUploadedAt',
          order: SortingType.DESC,
        }}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
      />
      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
