import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useDebounce } from '@hooks/useDebounce'
import { WriterOptionType } from '@models/user/WriterOptionType'
import { ByUserSearchFormType } from '@models/dashboard/ByUserSearchFormType'
import { useOutside } from '@hooks/useOutside'
import { WithSearchWriterFieldProps, SearchWriterFieldProps } from './interface'

const withSearchWriterField = (Component: React.FC<SearchWriterFieldProps>) => {
  function WithSearchWriterField({
    name,
    setText,
    text,
    resetHandler,
    ...props
  }: WithSearchWriterFieldProps) {
    const client = useClient()
    const searchRef = useRef<HTMLDivElement>(null)
    const [isShowSearchLog, setIsShowSearchLog] = useState<boolean>(false)
    const [textSearch, setTextSearch] = useState('')
    const { setFieldValue, submitForm, values, resetForm } =
      useFormikContext<ByUserSearchFormType>()
    const { debounce } = useDebounce()

    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['search-writer', 'page', textSearch],
        ({ pageParam = 1 }) =>
          client?.userClient.getWriterOption(textSearch, pageParam),
        {
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function showSearchLog() {
      setIsShowSearchLog(true)
    }

    function hideSearchLog() {
      setIsShowSearchLog(false)
    }

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
      setText(event.target.value)
    }

    function handleSelectWriter(writer: WriterOptionType) {
      setFieldValue('writerId', writer.id)
      setFieldValue('userId', writer.user.id)
      setFieldValue('penNameId', undefined)
      setText(writer.user.username)
      hideSearchLog()
      submitForm()
    }

    function removeTextSearch() {
      resetForm()
      setText('')
      if (resetHandler) {
        resetHandler()
      }
    }

    const writers = useMemo(
      () => data?.pages.flatMap(page => page!.data) || [],
      [data]
    )

    useOutside(searchRef, () => {
      const prevText =
        writers.find(writer => writer.id === Number(values.writerId))?.user
          ?.username || ''
      hideSearchLog()
      setText(prevText)
    })

    useEffect(() => {
      debounce(() => {
        setTextSearch(text)
      }, 400)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const componentsProps = {
      ...props,
      text,
      isShowSearchLog,
      writers,
      showSearchLog,
      searchRef,
      handleSelectWriter,
      handleTextChange,
      handleFetchNextPage,
      removeTextSearch,
    }
    return <Component {...componentsProps} />
  }

  return WithSearchWriterField
}

export { withSearchWriterField }
