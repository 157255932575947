import { gql } from 'graphql-request'

export const GET_USER_WRITERS = gql`
  query FindUserWriters(
    $limitPerPage: Int
    $page: Int
    $statusType: WriterStatusAdminEnum
    $searchText: String
    $orderBy: WriterListsOrderBy
  ) {
    findUserWriters(
      limitPerPage: $limitPerPage
      page: $page
      statusType: $statusType
      searchText: $searchText
      orderBy: $orderBy
    ) {
      total
      page
      data {
        id
        user {
          username
          id
          avatarImgPath
        }
        status
        createdAt
      }
    }
  }
`
