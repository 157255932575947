import cn from 'classnames'

import { CheckIcon, ErrorIcon, UploadCodeIcon } from '@components/Icons'
import { Button } from '@components/Button'
import { Loading } from '@components/Loading'

import { UploadFileUserIdFieldProps } from './interface'

function UploadFileUserIdField({
  value,
  fileName,
  isError,
  isCheckingFile,
  inputRef,
  disabled,
  onChange,
  showUploadDialog,
  onClearSelectedFile,
}: UploadFileUserIdFieldProps) {
  return (
    <>
      <div
        className={cn(
          'flex items-center justify-between border-2 rounded-lg px-5 py-4',
          { 'border-red-74': isError }
        )}
      >
        <div className='flex items-center space-x-5'>
          <UploadCodeIcon />
          <div className='text-sm font-light !mr-5'>
            อัปโหลดไฟล์ User ID (.csv)
          </div>
          <div className='h-[50px] w-[1px] border-l border-r mx-1' />
          <Button
            type='button'
            onClick={showUploadDialog}
            className={cn('bg-primary text-white rounded-lg', {
              'opacity-50': disabled,
            })}
          >
            <span className='text-sm'>Browse</span>
          </Button>
        </div>
        {fileName && (
          <div className='flex items-center space-x-5'>
            <div className='flex items-center space-x-2'>
              {!isCheckingFile ? (
                isError ? (
                  <ErrorIcon width='18' height='18' />
                ) : (
                  <CheckIcon
                    width='18'
                    height='18'
                    className='text-white bg-primary rounded-full p-1'
                  />
                )
              ) : (
                <Loading className='!my-0!' size={18} />
              )}
              <span className='text-sm font-bold'>{fileName}</span>
            </div>
            {!disabled && (
              <Button type='button' onClick={onClearSelectedFile}>
                <span className='text-sm text-primary underline'>Clear</span>
              </Button>
            )}
          </div>
        )}
        <input
          ref={inputRef}
          className='hidden'
          type='file'
          accept='.csv'
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  )
}

export default UploadFileUserIdField
