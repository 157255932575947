import { Expose, Type } from 'class-transformer'

import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { PromotionRequestActionEnum } from '@interfaces/PromotionRequestActionEnum'
import { PromotionStatusEnum } from '@interfaces/PromotionStatusEnum'
import { PromotionSaleCorrectionDetailType } from './PromotionSaleCorrectionDetailType'
import { PromotionSaleDetailType } from './PromotionSaleDetailType'

class User {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath?: string
}

export class PromotionSaleRequestType {
  @Expose()
  id: number

  @Expose()
  status: PromotionRequestStatusEnum

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose()
  @Type(() => User)
  createdBy: User

  @Expose()
  @Type(() => User)
  approvedBy: User

  @Expose() rejectedReason?: string

  @Expose()
  action: PromotionRequestActionEnum

  @Expose()
  oldPromotion: {
    status: PromotionStatusEnum
  }

  @Expose()
  @Type(() => PromotionSaleDetailType)
  newPromotion: PromotionSaleDetailType

  @Expose()
  @Type(() => PromotionSaleCorrectionDetailType)
  correctionDetail?: PromotionSaleCorrectionDetailType
}
