import { DateTime } from 'luxon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { DonationSearchFormType } from '@interfaces/dashboard/DonationSearchFormType'
import { useAlert } from '@hooks/useAlert'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { SearchPageProps } from './interface'
import { DonationQueryParam } from '../DonationPage/interface'

const mapInterval = {
  [TimeRangeEnum.DAY]: '1 DAY',
  [TimeRangeEnum.WEEK]: '1 WEEK',
  [TimeRangeEnum.MONTH]: '1 MONTH',
  [TimeRangeEnum.THREE_MONTH]: '3 MONTH',
  [TimeRangeEnum.SIX_MONTH]: '6 MONTH',
  [TimeRangeEnum.YEAR]: '1 YEAR',
}

export function withSearchPage(Component: React.FC<SearchPageProps>) {
  function WithSearchPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const tab = params.get('tab') || 'POPULAR'
    const alert = useAlert()

    const [tabActive, setTabActive] = useState(tab)

    function handleSelectTab(active: string) {
      setTabActive(active)
      navigate({
        pathname: '',
        search: `?tab=${active}`,
      })
    }

    useEffect(() => {
      setTabActive(tab)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    const [searchParams, setSearchParams] = useState<DonationQueryParam>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })

    function newDateAt(
      startAt: Date | undefined,
      endAt: Date | undefined,
      timeRange: TimeRangeEnum | undefined
    ) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : searchParams.startDate
      let newEndAt = endAt
        ? DateTime.fromJSDate(endAt).endOf('day')
        : searchParams.endDate

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 })
        newEndAt = DateTime.now()
      } else if (timeRange === TimeRangeEnum.WEEK) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.WEEK] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.THREE_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.THREE_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.SIX_MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.SIX_MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return { newStartAt, newEndAt }
    }

    function handleSearch({
      interval,
      startDate,
      endDate,
    }: DonationSearchFormType) {
      const { newStartAt, newEndAt } = newDateAt(startDate, endDate, interval)
      setSearchParams({
        startDate: newStartAt,
        endDate: newEndAt,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportSearchDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
      })
    }

    const pageProps = {
      searchParams,
      tabActive,
      handleSearch,
      handleExport,
      handleSelectTab,
      handleReset,
    }

    return <Component {...pageProps} />
  }

  return WithSearchPage
}
