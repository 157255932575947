import { Expose, Type } from 'class-transformer'

import { ActionStatusEnum } from '@interfaces/highlightBanner/ActionStatusEnum'
import { PenNameType } from '@models/penName/PenNameType'
import { BookEnum } from '@interfaces/BookEnum'
import { BookCategoryType } from './BookCategoryType'

export class BookType {
  @Expose() id: number

  @Expose() bannerId?: number

  @Expose() writer: string

  @Expose() title: string

  @Expose() coverImgPath: string

  @Expose() bookType?: BookEnum

  @Type(() => PenNameType)
  penName?: PenNameType

  @Expose() actionStatus?: ActionStatusEnum

  @Type(() => BookCategoryType)
  category: BookCategoryType
}
