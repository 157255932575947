import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { TableDetail } from './components/TableDetail'
import { TotalDetail } from './components/TotalDetail'
import { SalesByUserDetailPageProps } from './interface'

export function SalesByUserDetailPage({
  data,
  initialSort,
  isLoading,
  page,
  perpage,
  total,
  pageChange,
  handleSort,
  setValue,
  handleExport,
}: SalesByUserDetailPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>
        {`@${data?.username}` || '-'}
      </Container.Title>
      <TotalDetail
        currentSales={data?.currentSales}
        currentDonation={data?.currentDonation}
        currentSummary={data?.currentSummary}
        totalSpend={data?.totalIncome}
        setValue={setValue}
        handleExport={handleExport}
      />
      <TableDetail
        data={data?.detail.data || []}
        initialSort={initialSort}
        isLoading={isLoading}
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
        handleSort={handleSort}
      />
    </Container>
  )
}
