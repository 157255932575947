import { useEffect } from 'react'
import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { getErrorMessage } from '@libs/utils'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { EditPromotionCodePageProps } from './interface'

export function withEditPromotionCodePage(
  Component: React.FC<EditPromotionCodePageProps>
) {
  function WithEditPromotionCodePage() {
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmCreateModal = useModal({ modal: 'confirmCreatePromotionCode' })

    const queryKey: QueryKey = ['promotion', 'code', Number(id)]

    const { data, isFetching } = useQuery(
      [...queryKey, 'edit'],
      () => client?.promotionCodeClient.getPromotionCodeForm(Number(id)),
      {
        onError: () => navigate(-1),
      }
    )

    const { data: coinOptions = [] } = useQuery(
      ['coin-options', 'promotion-code'],
      () => client?.promotionCodeClient.getCoinOptions()
    )

    const { mutateAsync: updatePromotion } = useMutation(
      (form: PromotionCodeFormType) =>
        client!.promotionCodeClient.updatePromotionCode(
          form,
          data?.promotionStatus === PromotionStatusEnum.DRAFT
            ? PromotionStatusEnum.DRAFT
            : PromotionStatusEnum.PENDING
        ),
      {
        onSuccess: () => {
          alert.success('แก้ไขโปรโมชั่นสำเร็จ')
          if (data?.promotionStatus === PromotionStatusEnum.DRAFT) {
            navigate(`/promotion-setting/promotion-code/${id}/create-code`, {
              replace: true,
            })
          } else {
            navigate(`/promotion-setting/promotion-code/${id}`, {
              replace: true,
            })
          }
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function updatePromotionCode(values: PromotionCodeFormType) {
      await updatePromotion(values)
      confirmCreateModal.hide()
    }

    function handleLeave() {
      navigate(-1) // DESC: go back
    }

    useEffect(() => {
      if (data) {
        if (!data.isEditable) {
          navigate(-1)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const newProps = {
      initialValues: data,
      updatePromotionCode,
      isLoading: isFetching,
      handleLeave,
      coinOptions,
    }

    return <Component {...newProps} />
  }

  return WithEditPromotionCodePage
}
