import { ChartOptionType } from '@interfaces/ChartOptionType'
import { Expose, Transform, Type } from 'class-transformer'

export class DeviceCategoryType {
  @Expose() name: string

  @Expose({ name: 'total' })
  @Transform(({ value }) => value || 0)
  value: number
}

export class BookCategoriesType {
  @Expose() name: string

  @Expose() category: number

  @Expose({ name: 'total' })
  @Transform(({ value }) => value || 0)
  value: number
}

export class WriterGenderCategoriesType {
  @Expose()
  name: string

  @Expose()
  value: number
}

export class WriterOverViewType {
  @Expose() totalWriter: number

  @Expose() newWriter: number

  @Expose() summaryWriterUsage: number

  @Expose() accumulateWriter: number

  @Expose()
  @Type(() => DeviceCategoryType)
  deviceCategory: DeviceCategoryType[]

  @Expose()
  @Type(() => BookCategoriesType)
  bookCategories: BookCategoriesType[]

  @Expose()
  @Transform(({ obj }) => {
    const res = Object.entries(obj.writerGenderCategories).map(([key, val]) => {
      let name = 'ไม่ระบุเพศ'
      if (key === 'female') name = 'หญิง'
      if (key === 'male') name = 'ชาย'

      return { name, value: val || 0 }
    })

    return res
  })
  @Type(() => WriterGenderCategoriesType)
  writerGenderCategories: WriterGenderCategoriesType[]

  @Expose()
  @Transform(({ value }) => {
    return [
      { value: value?.ageBelowFifteen || 0, name: 'ต่ำกว่า 15' },
      { value: value?.ageBetweenSixteenAndTwenty || 0, name: '16 - 20' },
      { value: value?.ageBetweenTwentyOneAndThirty || 0, name: '21-30' },
      { value: value?.ageBetweenThirtyOneAndForty || 0, name: '31-40' },
      { value: value?.ageMoreThanFortyOne || 0, name: '41 ขึ้นไป' },
      { value: value?.otherAge || 0, name: 'ไม่ระบุ' },
    ]
  })
  writerAgeCategories: ChartOptionType[]
}
