import { SalesEbookPromotionStatusEnum } from '@interfaces/SalesEbookPromotionStatusEnum'
import { Expose } from 'class-transformer'

export class AnalyticsEbookDetailTableType {
  @Expose() category: string

  @Expose() coverImgPath: string

  @Expose() discountedPrice: number

  @Expose() discountCoin: number

  @Expose() discountPercent: number

  @Expose() ebookTitle: string

  @Expose() penName: string

  @Expose() promotionDiscountValue: number

  @Expose() promotionEndedAt: string

  @Expose() promotionStartedAt: string

  @Expose() promotionStatus: SalesEbookPromotionStatusEnum

  @Expose() promotionType: string

  @Expose() rawPrice: number

  @Expose() total: number
}
