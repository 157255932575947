import { ExportJobResponse } from '@models/file/ExportJobResponse'
import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { CANCEL_EXPORT_JOB } from './schemas/cancelExportJob'
import { EXPORT_JOBS } from './schemas/exportJobs'
import { GET_DYNAMIC_LINK } from './schemas/getDynamicLink'
import { UPLOAD_FILE } from './schemas/uploadFile'

export class FileClient {
  constructor(private client: GraphQLClient) {}

  async uploadFile(file: File | Blob): Promise<string> {
    const {
      UploadBookCover: { filePath },
    } = await this.client.request(UPLOAD_FILE, {
      file,
    })

    return filePath
  }

  async getDynamicLink(link: string): Promise<string | null> {
    try {
      const { dynamicLinkValidation } = await this.client.request(
        GET_DYNAMIC_LINK,
        {
          link,
        }
      )

      return dynamicLinkValidation?.link || null
    } catch (error) {
      return null
    }
  }

  async exportJobs({
    page,
    limitPerPage,
  }: {
    page: number
    limitPerPage: number
  }): Promise<ExportJobResponse> {
    const { exportJobs } = await this.client.request(EXPORT_JOBS, {
      limitPerPage,
      page,
    })
    return plainToInstance(ExportJobResponse, exportJobs)
  }

  async cancelExportJob(id: number): Promise<boolean> {
    const res = await this.client.request(CANCEL_EXPORT_JOB, {
      cancelExportInput: {
        id,
      },
    })

    return res
  }
}
