import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'

class UserType {
  id: number

  displayName: number
}

export class NewsAndPromotionType {
  id: number

  bannerImgPath: string

  thumbnailImgPath: string

  name: string

  description: string

  content: string

  isActive: boolean

  newsType: NewsTypeEnum

  updatedAt: string

  user: UserType

  slug: string
}
