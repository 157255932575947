import cx from 'classnames'

import { Upload1Icon, Upload2Icon } from '@components/Icons'
import { UploadImageProps } from './interface'

export function UploadImage({
  className,
  src,
  inputRef,
  textSize,
  textBtn,
  textReUploadBtn,
  ratio,
  onChange,
  showUploadDialog,
}: UploadImageProps) {
  return (
    <section>
      <div className={cx('rounded-[8px] overflow-hidden', className)}>
        <div
          className='relative w-full h-full'
          style={{ paddingTop: `${ratio * 100}%` }}
        >
          {src ? (
            <div className='absolute inset-0'>
              <img src={src} className='w-full h-full' alt='cover' />
            </div>
          ) : (
            <div className='bg-blue-2 absolute inset-0'>
              <div className='absolute flex flex-col items-center -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2'>
                <>
                  <Upload1Icon
                    width='101'
                    height='101'
                    className={cx('text-secondary-100', {
                      ' mb-[40px]': !textSize,
                    })}
                  />

                  {textSize && (
                    <p className='font-sarabun font-light text-[14px] text-blue-3 py-[20px]'>
                      {textSize} px
                    </p>
                  )}

                  <button
                    className='w-fit text-white bg-secondary flex items-center gap-x-[12px] px-[28px] py-[10px] rounded-[20px] whitespace-nowrap'
                    type='button'
                    onClick={showUploadDialog}
                  >
                    <Upload2Icon width='35' height='35' />
                    <div className='font-mitr text-[19px] font-medium'>
                      {textBtn}
                    </div>
                  </button>
                </>
              </div>
            </div>
          )}

          <input
            ref={inputRef}
            className='hidden'
            type='file'
            accept='image/*'
            onChange={onChange}
          />
        </div>
      </div>

      {src && (
        <button
          type='button'
          className='rounded-3xl bg-[#f4f4f4] px-5 py-1 mt-[10px]'
          onClick={showUploadDialog}
        >
          <span className='font-mitr font-medium text-[14px] text-secondary-100'>
            {textReUploadBtn || textBtn}
          </span>
        </button>
      )}
    </section>
  )
}
