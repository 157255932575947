import { gql } from 'graphql-request'

export const CHARACTER_DONATION_BY_CHARACTER = gql`
  query CharacterDonationByCharacter(
    $dateRange: DatePicker
    $orderBy: CharacterDonationByCharacterSort
    $orderDirection: OrderDirection
    $limitPerPage: Int
    $page: Int
  ) {
    characterDonationByCharacter(
      dateRange: $dateRange
      orderBy: $orderBy
      orderDirection: $orderDirection
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        rank
        characterName
        bookId
        bookTitle
        username
        totalDonation
      }
      total
      page
    }
  }
`
