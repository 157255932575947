import { Expose, Transform } from 'class-transformer'

export class TopMoneySpenderType {
  @Expose({ name: 'userId' }) id: string

  @Expose({ name: 'username' }) username: string

  @Expose({ name: 'userMoneySpend' })
  @Transform(({ value }) => (value ? Number(value) : 0))
  moneySpend: number
}
