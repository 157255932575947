import * as Yup from 'yup'

export const validationSchema = Yup.object()
  .shape({
    imgPath: Yup.object().shape({
      url: Yup.string().required(),
    }),
    description: Yup.string().required(),
    rawLink: Yup.string().required(),
  })
  .test((value, context) => {
    if (!value.isOnWeb && !value.isOnIos && !value.isOnAndroid) {
      return context.createError({
        path: 'isOnWeb',
        message: 'required',
      })
    }

    return true
  })
