import cn from 'classnames'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TextInputField } from '@components/forms/TextInputField'
import { Container } from '@components/Container'
import { Box } from '@components/Box'
import { Button } from '@components/Button'
import { RoleManagementProps } from './interface'
import { FeatureRoleList } from '../FeatureRoleList'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

export function RoleManagement({
  title,
  subTitle,
  initialValues,
  onSubmit,
}: RoleManagementProps) {
  return (
    <Container>
      <Container.Title showBackBtn>{title}</Container.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, resetForm }) => (
          <Form>
            <Box className='shadow border border-blue-2 mb-[20px]'>
              <h1 className='text-[18px] font-bold text-secondary mb-[30px]'>
                {subTitle}
              </h1>
              <TextInputField
                name='name'
                label='ชื่อ'
                placeholder='พิมพ์ชื่อ Role'
                className='w-[400px]'
              />
            </Box>
            <Box className='shadow border border-blue-2'>
              <h1 className='text-[18px] font-bold text-secondary mb-[30px]'>
                ตั้งค่า Permission
              </h1>
              <p className='text-[12px] font-bold text-secondary-100'>
                Main Feature
              </p>
              <FeatureRoleList />
            </Box>
            <div className='flex justify-center gap-x-[20px] mt-[30px]'>
              <Button
                type='button'
                className='border border-secondary-100 font-medium text-secondary-100 text-[14px] px-[30px]'
                onClick={() => resetForm()}
              >
                ยกเลิก
              </Button>
              <Button
                type='submit'
                className='text-white bg-primary font-medium px-[30px] text-[14px]'
                disabled={isSubmitting}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
