import { Expose, Type } from 'class-transformer'

export class BookChapterDetailDashboardType {
  @Expose() bookChapterId: number

  @Expose() runningNo: number

  @Expose() chapterName: string

  @Expose() updatedAt: string

  @Expose() price: number

  @Expose() bookChapterTotalDownload: number

  @Expose() bookChapterRevenue: number

  @Expose() bookChapterTotalView: number

  @Expose() bookChapterUniqueView: number

  @Expose() bookChapterTotalComment: number
}

export class BookChapterDetailDashboardResponse {
  @Expose()
  @Type(() => BookChapterDetailDashboardType)
  data: BookChapterDetailDashboardType[]

  @Expose() total: number

  @Expose() page: number
}
