import { withPermissionGuard } from '@libs/permission'
import { SearchManagementLayout } from './SearchManagementLayout'
import { withSearchManagementLayout } from './withSearchManagementLayout'

const ConnectedSearchManagementLayout = withPermissionGuard(
  withSearchManagementLayout(SearchManagementLayout),
  'searchManagement',
  'view'
)

export { ConnectedSearchManagementLayout as SearchManagementLayout }
