import React from 'react'
import cn from 'classnames'
import { Container } from '@features/promotionSetting/components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import {
  PromotionUserEnum,
  PromotionUserTypeValue,
} from '@interfaces/promotionCode/PromotionUserEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import {
  coinOptionsText,
  dayOptionsText,
  selectedChanelText,
} from '@libs/promotion/utils'
import { PromotionCodeContentProps } from './interface'

export function PromotionCodeContent({
  isDisableStatus,
  description,
  userTerm,
  usageDay,
  promotionCode,
  userType,
  usageLimitPerUser,
  usageLimit,
  isUsageLimitPerUser,
  isUsageLimit,
  usersFileName,
  users,
  isExpand,
  couponValue,
}: PromotionCodeContentProps) {
  return (
    <>
      {isExpand ? (
        <>
          <div
            className={cn(
              'grid grid-cols-[130px_1fr] gap-y-[25px] text-[14px]',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100 mt-[2px] '>
              รายละเอียด :
            </span>
            <span className='break-word'>{description || '-'}</span>
            <span className='text-[12px] font-light text-secondary-100'>
              เงื่อนไขการใช้งาน :
            </span>
            <div className='grid grid-col-1 whitespace-pre-wrap break-word'>
              <span>{userTerm || '-'}</span>
            </div>
          </div>
          <Container.Hr />
          <div
            className={cn(
              'grid grid-cols-[130px_395px_75px_13fr] gap-y-[25px] text-[14px] items-center',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100'>
              ช่องทาง :
            </span>
            <div className='flex space-x-1'>
              {promotionCode?.channels
                ? promotionCode?.channels.map((item, index) => (
                    <React.Fragment key={index}>
                      <span>
                        {item.name} &nbsp;({item.totalCount} Code)
                      </span>
                      <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                    </React.Fragment>
                  ))
                : '-'}
            </div>
          </div>
          <Container.Hr />
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-x-[40px]'>
              <Card.TextVertical
                label='สำหรับ :'
                value={PromotionUserTypeValue[userType as PromotionUserEnum]}
                valueColor={
                  isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                }
              />
              {usersFileName && (
                <>
                  <Card.TextVertical
                    label='Upload file :'
                    value={usersFileName || '-'}
                    valueColor={
                      isDisableStatus
                        ? 'secondary-light'
                        : 'secondary-100-light'
                    }
                  />
                  <Card.TextVertical
                    label=''
                    value={`(${users.length} ID)`}
                    valueColor={
                      isDisableStatus
                        ? 'secondary-light'
                        : 'secondary-100-light'
                    }
                  />
                </>
              )}
            </div>
          </div>
          <Container.Hr />
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-x-[40px]'>
              {isUsageLimitPerUser ? (
                <Card.TextVertical
                  label='จำกัดการเข้าร่วมโปรโมชั่น :'
                  value={usageLimitPerUser}
                  lastLabel='ครั้ง'
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
              ) : (
                <Card.TextVertical
                  label='จำกัดการเข้าร่วมโปรโมชั่น :'
                  value='ไม่จำกัด'
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
              )}
              {isUsageLimit ? (
                <Card.TextVertical
                  label='จำกัดจำนวนสิทธิ์ :'
                  value={usageLimit}
                  lastLabel='ครั้ง'
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
              ) : (
                <Card.TextVertical
                  label='จำกัดจำนวนสิทธิ์ :'
                  value='ไม่จำกัด'
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
              )}
              <span className='text-[12px] font-light text-secondary-100'>
                ใช้ได้ในวัน :
              </span>
              <span
                className={cn('flex flex-wrap text-sm text-secondary', {
                  'text-secondary': isDisableStatus,
                  'text-secondary-100': !isDisableStatus,
                })}
              >
                {usageDay?.length
                  ? usageDay.length === 7
                    ? 'ทุกวัน'
                    : usageDay.map((day, index) => (
                        <React.Fragment key={index}>
                          <span>{dayOptionsText(day)}</span>
                          <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                        </React.Fragment>
                      ))
                  : '-'}
              </span>
            </div>
          </div>
          <Container.Hr />
          <div className='flex items-center justify-between mt-[20px]'>
            <div className='flex gap-x-5'>
              <div className='flex items-center gap-x-5 text-secondary-100 text-[14px] font-light'>
                <div className='font-light space-x-[10px]'>
                  <span className='text-secondary-100'>เงื่อนไข : </span>
                  {promotionCode?.type === UserByEnum.BUY_COIN ? (
                    <>
                      <span className='text-secondary-100'>
                        เมื่อผู้ใช้งานซื้อเหรียญ
                      </span>
                      <span
                        className={cn({
                          'text-secondary': isDisableStatus,
                          'text-secondary-100': !isDisableStatus,
                        })}
                      >
                        {coinOptionsText(promotionCode?.buyCoinCondition)}
                      </span>
                      <span
                        className={cn('font-bold', {
                          'text-primary': isDisableStatus,
                          'text-secondary-100': !isDisableStatus,
                        })}
                      >
                        {promotionCode?.buyCoinConditionValue || 0}
                      </span>
                      <span className='!ml-1 text-secondary-100'>บาท</span>
                    </>
                  ) : (
                    <span className='text-secondary-100'>
                      เมื่อผู้ใช้งานรีดีมโค้ด
                    </span>
                  )}
                </div>
              </div>

              {promotionCode?.type === UserByEnum.BUY_COIN ? (
                <>
                  <div className='flex items-center gap-x-[10px] text-secondary-100 text-sm font-light'>
                    <p>ผ่านช่องทาง : </p>
                    <div className='flex space-x-1'>
                      {promotionCode?.paymentChannels
                        ? promotionCode?.paymentChannels.map((item, index) => (
                            <React.Fragment key={index}>
                              {selectedChanelText({ channel: item })}
                            </React.Fragment>
                          ))
                        : '-'}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'flex items-center gap-x-[10px] text-sm font-light',
                      {
                        'text-secondary': isDisableStatus,
                        'text-secondary-100': !isDisableStatus,
                      }
                    )}
                  >
                    <p>รับเพิ่ม : </p>
                    <p
                      className={cn('font-bold', {
                        'text-primary': isDisableStatus,
                        'text-secondary-100': !isDisableStatus,
                      })}
                    >
                      {promotionCode?.benefitValue}&nbsp;
                      <span className='text-secondary-100 font-light'>
                        {promotionCode?.coin?.name}
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                <div
                  className={cn(
                    'flex items-center gap-x-[10px] text-sm font-light',
                    {
                      'text-secondary': isDisableStatus,
                      'text-secondary-100': !isDisableStatus,
                    }
                  )}
                >
                  <p>รับทันที :</p>
                  <p
                    className={cn('font-bold', {
                      'text-primary': isDisableStatus,
                      'text-secondary-100': !isDisableStatus,
                    })}
                  >
                    {promotionCode?.benefitValue}
                    <span className='text-secondary-100 px-[10px] font-light'>
                      {promotionCode?.coin?.name}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          className={cn('grid grid-cols-[130px_1fr] gap-y-[25px] text-[14px]', {
            'text-secondary': isDisableStatus,
            'text-secondary-100': !isDisableStatus,
          })}
        >
          <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
            รายละเอียด :
          </span>
          <span className='break-word'>{description || '-'}</span>
        </div>
      )}
      <Container.Hr />
      <div className='flex items-center'>
        {promotionCode?.channels.map((item, index) => (
          <div key={index} className='flex gap-x-[60px]'>
            <Card.TextHorizontal
              label='ช่องทาง'
              value={item.name}
              valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
            />
            <Card.TextHorizontal
              label='จำนวน Code ทั้งหมด'
              value={item.totalCount}
              valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
            />
            <Card.TextHorizontal
              label='ใช้แล้ว'
              value={item.totalUsedCount}
              valueColor={isDisableStatus ? 'primary' : 'secondary-100'}
            />
            <div className='border-r border-gray h-[54px] mr-[40px]' />
          </div>
        ))}
        {couponValue && (
          <Card.TextHorizontal
            label='Universal Code'
            value={couponValue}
            valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
          />
        )}
      </div>
    </>
  )
}
