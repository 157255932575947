import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import { useClient } from '@hooks/useClient'
import { CoinTableProps, SearchParamsType } from './interface'

const withCoinTable = (Component: React.FC<CoinTableProps>) => {
  function WithCoinTable() {
    const client = useClient()
    const { id } = useParams()
    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      startDate: null,
      endDate: null,
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    const { data = [], isLoading } = useQuery(
      ['user-management', id, 'coin', searchParams, page],
      () =>
        client?.userManagementClient
          .getUserCoinHistories({
            userId: Number(id),
            limitPerPage: perpage,
            page,
            startDate: searchParams.startDate,
            endDate: searchParams.endDate,
            sortKey: searchParams.sortKey,
            orderBy: searchParams.sortOrder,
          })
          .then(res => {
            handleTotalChange(res.total)

            return res.data
          })
    )

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function startDateChange(date: Date | null) {
      pageChange(1)
      setSearchParams(prev => ({
        ...prev,
        startDate: date,
      }))
    }

    function endDateChange(date: Date | null) {
      pageChange(1)
      setSearchParams(prev => ({ ...prev, endDate: date }))
    }

    const newProps = {
      searchParams,
      page,
      perpage,
      total,
      pageChange,
      data,
      isLoading,
      handleSort,
      startDateChange,
      endDateChange,
    }
    return <Component {...newProps} />
  }

  return WithCoinTable
}

export default withCoinTable
