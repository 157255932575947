import { gql } from 'graphql-request'

export const GET_PERFORMANCE_LOG_CITY = gql`
  query GetPerformanceLogCityData(
    $startDate: DateTime
    $endDate: DateTime
    $limit: Int!
  ) {
    getPerformanceLogCityData(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      cityName
      count
    }
  }
`
