import { Waypoint } from 'react-waypoint'

import { chartNumbers } from '@constants/chartNumbers'
import { StoryCard } from '@components/StoryCard'
import { TopRankFilter } from '@components/TopRankFilter'
import { Container } from '@components/Container'
import { NoData } from '@components/NoData'
import { TopRankTemplateProps } from './interface'

export function TopRankTemplate({
  title,
  query,
  books,
  isLoading,
  limit,
  dateOfWeek,
  disabledNext,
  disabledPrev,
  handleCategoryChange,
  handleBookTypeChange,
  nextWeek,
  prevWeek,
  handleBanBook,
  handleFetchNextPage,
}: TopRankTemplateProps) {
  return (
    <Container>
      <TopRankFilter
        dateOfWeek={dateOfWeek}
        title={title}
        category={query.categoryId}
        storyType={query.bookType}
        disabledNext={disabledNext}
        disabledPrev={disabledPrev}
        handleChangeCategory={handleCategoryChange}
        handleChangeStoryType={handleBookTypeChange}
        handleClickNextWeek={nextWeek}
        handleClickPrevWeek={prevWeek}
      />
      <div className=' grid grid-cols-2 gap-y-[30px] mt-[30px] mx-auto'>
        {books?.pages.map((page, i) =>
          page?.data.map((item, index) => (
            <div key={item.id} className='relative' onClick={() => {}}>
              <StoryCard
                className='ml-[100px]'
                header={title}
                topChartId={item.id}
                title={item.book.title}
                imageUrl={item.book.coverImgPath}
                category={item.book.category.name}
                publisher={item.book.penName?.user.displayName}
                writer={item.book.writer}
                penName={item.book.penName?.firstPenName}
                view={item.viewCount}
                like={item.likeCount}
                previousRank={item.previousRank}
                rankChange={item.rankChange}
                isBan={item.isBan}
                banReason={item.banReason}
                handleBanBook={handleBanBook}
                isLoading={isLoading}
              />
              <div className='absolute top-0 left-0'>
                {limit * i + index < 10 ? (
                  chartNumbers[index]
                ) : (
                  <div className='px-[28px] py-[50px]  text-primary text-[40px] font-bold'>
                    {limit * i + index + 1}
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {books?.pages[0]?.data.length === 0 ? (
        <NoData />
      ) : (
        <Waypoint onEnter={handleFetchNextPage} />
      )}
    </Container>
  )
}
