import { Container } from '@components/Container'
import { CoinManagementForm } from '@features/coin/components/CoinManagementForm'
import { CoinCreatePageProps } from './interface'

const initialValues = {
  name: '',
  upload: {
    url: '',
    blob: undefined,
  },
}

export function CoinCreatePage({ onSubmit }: CoinCreatePageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>เพิ่มเหรียญ</Container.Title>
      <CoinManagementForm initialValues={initialValues} onSubmit={onSubmit} />
    </Container>
  )
}
