import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'

import { useAlert } from '@hooks/useAlert'
import { client } from '@client/init'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { WriterPublisherPageProps, SearchType } from './interface'

export function withOverviewPage(
  Component: React.FC<WriterPublisherPageProps>
) {
  function WithOverviewPage() {
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })

    function handleSearch(startDate: DateTime, endDate: DateTime) {
      setSearchParams({
        startDate,
        endDate,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
      })
    }

    const { data } = useQuery(
      ['writer-over-view', searchParams],
      () => client!.dashboardClient.getWriterOverView({ ...searchParams! }),
      {
        enabled: !!searchParams,
      }
    )

    const { data: graphData } = useQuery(
      ['writer-free-vs-earn', searchParams],
      () =>
        client!.dashboardClient.getWriterFreeVsEarnWriter({ ...searchParams! }),
      {
        enabled: !!searchParams,
      }
    )

    async function handleExport() {
      try {
        const { startDate, endDate } = searchParams

        await client?.dashboardClient.exportWriterDashboard({
          startDate,
          endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      data,
      graphData,
      searchParams,
      handleSearch,
      handleReset,
      handleExport,
    }

    return <Component {...componentProps} />
  }

  return WithOverviewPage
}
