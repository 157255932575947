import React, { useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import cloneDeep from 'lodash.clonedeep'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { HighlightBannerPositionEnum } from '@interfaces/mainBanner/HighlightBannerPositionEnum'
import { HighlightBannerPageProps, TabEnum } from './interface'

const reorder = (
  list: HighlightBannerType[],
  startIndex: number,
  endIndex: number
) => {
  const result = cloneDeep(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function withHighlightBannerPage(
  Component: React.FC<HighlightBannerPageProps>
) {
  function WithHighlightBannerPage() {
    const client = useClient()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [tabSelected, setTabSelected] = useState(TabEnum.ALL)
    const confirmRemoveModal = useModal({ modal: 'confirm' })
    const { position } = useParams()
    const queryKey = ['highlight-banner-list', position?.toUpperCase()]

    const { data = [], refetch } = useQuery(
      queryKey,
      () =>
        client!.highlightBannerClient.getAllHighlightBanner(
          position!.toUpperCase() as HighlightBannerPositionEnum
        ),
      { enabled: !!position }
    )

    const highlightBannerData = useMemo(() => {
      return data.filter(row => {
        const all = tabSelected === TabEnum.ALL
        const active = row.isActive && tabSelected === TabEnum.ACTIVE
        const inactive =
          !row.isActive &&
          new Date().valueOf() < new Date(row.publishedTo).valueOf() &&
          tabSelected === TabEnum.INACTIVE
        const expired =
          new Date().valueOf() > new Date(row.publishedTo).valueOf() &&
          tabSelected === TabEnum.EXPIRED

        return all || active || inactive || expired
      })
    }, [data, tabSelected])

    const { mutateAsync: toggleEnableStatus } = useMutation(
      (param: HighlightBannerType) =>
        client!.highlightBannerClient.updateEnableHighlightBanner(
          param.id,
          !param.isActive,
          param.bannerType
        ),
      {
        onMutate: variables => {
          queryClient.setQueryData<HighlightBannerType[] | undefined>(
            queryKey,
            oldData => {
              if (oldData) {
                const tmp = cloneDeep(oldData)
                const index = tmp.findIndex(row => row.id === variables.id)

                if (index !== -1) {
                  tmp[index] = {
                    ...tmp[index],
                    isActive: !variables.isActive,
                  }
                }

                return tmp
              }

              return oldData
            }
          )
        },
        onError: (error, variables) => {
          queryClient.setQueryData<HighlightBannerType[] | undefined>(
            queryKey,
            oldData => {
              if (oldData) {
                const tmp = cloneDeep(oldData)
                const index = tmp.findIndex(row => row.id === variables.id)

                if (index !== -1) {
                  tmp[index] = variables
                }

                return tmp
              }

              return oldData
            }
          )

          alert.error(getErrorMessage(error))
        },
      }
    )

    const { mutateAsync: reorderHighlightBanner } = useMutation(
      ({ id, newIndex }: { id: number; oldIndex: number; newIndex: number }) =>
        client!.highlightBannerClient.updateOrderHighlightBanner(
          newIndex + 1,
          id
        ),
      {
        onMutate: ({ oldIndex, newIndex }) => {
          const items = reorder(highlightBannerData, oldIndex, newIndex)

          queryClient.setQueryData<HighlightBannerType[] | undefined>(
            queryKey,
            oldData => {
              if (oldData) {
                return items.map((row, index) => {
                  const temp = cloneDeep(row)

                  if (index === newIndex) {
                    temp.runningNo = oldData[newIndex].runningNo
                  } else if (
                    oldIndex < newIndex &&
                    index >= oldIndex &&
                    index < newIndex
                  ) {
                    temp.runningNo -= 1
                  } else if (
                    oldIndex > newIndex &&
                    index > newIndex &&
                    index <= oldIndex
                  ) {
                    temp.runningNo += 1
                  }

                  return temp
                })
              }

              return oldData
            }
          )
        },
        onError: (_, { oldIndex, newIndex }) => {
          alert.error('เกิดข้อผิดพลาด')
          const items = reorder(highlightBannerData, newIndex, oldIndex)
          queryClient.setQueryData<HighlightBannerType[] | undefined>(
            queryKey,
            () => items
          )
        },
      }
    )

    const { mutateAsync: deleteBanner } = useMutation(
      (id: number) => client!.highlightBannerClient.deleteHighlightBanner(id),
      {
        onSuccess: async () => {
          await refetch()
          confirmRemoveModal.hide()
          alert.success('ลบข้อมูลสำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutateAsync: publishHighlightBannerList } = useMutation(
      (ids: number[]) =>
        client!.highlightBannerClient.publishHighlightBanner(ids),
      {
        onSuccess: async () => {
          setSelectedIds([])
          await refetch()
        },
        onError: error => {
          const errorMessage = getErrorMessage(error)
          alert.error(errorMessage)
        },
      }
    )

    const { mutateAsync: unPublishHighlightBannerList } = useMutation(
      (ids: number[]) =>
        client!.highlightBannerClient.unPublishHighlightBanner(ids),
      {
        onSuccess: async () => {
          setSelectedIds([])
          await refetch()
        },
        onError: error => {
          const errorMessage = getErrorMessage(error)
          alert.error(errorMessage)
        },
      }
    )

    async function handleDragItem(result: DropResult) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      await reorderHighlightBanner({
        id: highlightBannerData[source.index].id,
        oldIndex: source.index,
        newIndex: destination.index,
      })
    }

    function handleSelectItemAll(): void {
      const itemIds: number[] = highlightBannerData.map(
        (item: HighlightBannerType) => item.id
      )
      const hasAllData = itemIds.every(row => selectedIds.includes(row))

      if (hasAllData) {
        setSelectedIds([])
      } else {
        setSelectedIds(prev => [
          ...prev,
          ...itemIds.filter(row => !prev.includes(row)),
        ])
      }
    }

    function handleSelectItem(id: number) {
      const index = selectedIds.findIndex((val: number) => val === id)
      if (index !== -1) {
        setSelectedIds((prev: number[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds((prev: number[]) => [...prev, id])
      }
    }

    async function handleEnable(value: HighlightBannerType) {
      if (
        new Date().valueOf() > new Date(value.publishedTo).valueOf() &&
        !value.isActive
      ) {
        alert.error(
          'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
        )
        return
      }

      await toggleEnableStatus(value)
    }

    async function handlePublishHighlightBanner() {
      const ids = selectedIds.filter(id => {
        const item = highlightBannerData.find(row => row.id === id)

        return item
          ? new Date().valueOf() <= new Date(item.publishedTo).valueOf()
          : false
      })

      await publishHighlightBannerList(ids)
    }

    async function handleUnpublishHighlightBanner() {
      await unPublishHighlightBannerList(selectedIds)
    }

    async function deleteBannerList() {
      confirmRemoveModal.hide()
      const promises = selectedIds.map(id =>
        client!.highlightBannerClient.deleteHighlightBanner(id)
      )
      await Promise.all(promises)
      setSelectedIds([])
      await refetch()
      alert.success('ลบข้อมูลสำเร็จ')
    }

    function handleDeleteBanner(id: number) {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: async () => {
          await deleteBanner(id)
        },
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function handleDeleteBannerList() {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: deleteBannerList,
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function handleTabChange(value: TabEnum) {
      setTabSelected(value)
      setSelectedIds([])
    }

    const newProps = {
      data: highlightBannerData,
      selectedIds,
      tabSelected,
      navigate,
      handleTabChange,
      handleDragItem,
      handleSelectItemAll,
      handleSelectItem,
      handleEnable,
      handleDeleteBanner,
      handleDeleteBannerList,
      handlePublishHighlightBanner,
      handleUnpublishHighlightBanner,
    }

    return <Component {...newProps} />
  }

  return WithHighlightBannerPage
}
