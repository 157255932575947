import cn from 'classnames'

export function SlugInput({
  className,
  ...props
}: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      type='text'
      className={cn(
        className,
        'p-[10px] rounded-[8px] border outline-none text-[14px] h-[40px] w-full placeholder:text-black-970 placeholder:font-light'
      )}
      {...props}
    />
  )
}
