import { SortingType } from '@components/Table/interface'
import { DateType } from '@features/dashboard/components/SearchDateRange/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { DurationEnum } from '@interfaces/DurationEnum'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export enum SearchPopularBy {
  ALL = 'ALL',
  TITLE = 'TITLE',
  PEN_NAME = 'PEN_NAME',
  WRITER = 'WRITER',
  COVER = 'COVER',
  TAG = 'TAG',
  CHAPTER_NAME = 'CHAPTER_NAME',
  CHARACTER = 'CHARACTER',
  INTRO = 'INTRO',
  CONTENT = 'CONTENT',
}
export enum OrderByEnum {
  SEARCH_COUNT = 'searchText',
}

export interface PopularSearchTextSectionSortParam {
  orderBy: OrderByEnum
  orderDirection: SortingType
  searchBy: SearchPopularBy
}

export interface PopularSearchTextSectionWithPageSortParam
  extends PopularSearchTextSectionSortParam,
    DonationQueryParam {
  page: number
  searchText?: string
}

export interface TopHashtagQueryParam {
  bookType: BookEnum | null
  typeDuration: DurationEnum | null
  categoryId: number | null
  startDate?: DateType | null
  endDate?: DateType | null
  sortKey?: string
  sortOrder?: SortingType
}
