import { Formik, Form } from 'formik'
import cn from 'classnames'

import { Box } from '@components/Box'
import { Avatar } from '@components/Avatar'
import { Button } from '@components/Button'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { BanSearchTermSection } from './components/BanSearchTermSection'
import { RecommendSearchTermSection } from './components/RecommendSearchTermSection'
import { ManageSearchTermPageProps } from './interface'

export function ManageSearchTermPage({
  initialValues,
  userImageUrl,
  userFullName,
  handleSubmit,
}: ManageSearchTermPageProps) {
  const permission = usePermissionAction('searchManagement')
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className='grid gap-y-[20px] mt-[20px]'>
          <Box>
            <Box.Title className='text-[18px]'>จัดการแนะนำคำค้นหา</Box.Title>
            <RecommendSearchTermSection />
          </Box>
          <Box>
            <Box.Title className='text-[18px]'>แบนคำค้นหา</Box.Title>
            <BanSearchTermSection />
          </Box>
          <div className='flex items-center justify-between'>
            <div className='flex gap-x-[10px] items-center'>
              <span className='text-[14px] text-secondary-100 font-bold'>
                ดำเนินการโดย :
              </span>
              {userImageUrl ? (
                <Avatar
                  className='w-[50px] h-[50px]'
                  src={userImageUrl}
                  alt=''
                />
              ) : (
                <div className='w-[50px] h-[50px] rounded-full  bg-gray' />
              )}
              <span className='text-[14px] text-secondary font-bold'>
                {userFullName || '-'}
              </span>
            </div>
            <div
              className={cn('flex gap-x-[20px]', {
                hidden: !permission.edit,
              })}
            >
              <Button
                type='reset'
                className='border border-black-480 text-black-480 text-[14px]'
              >
                ยกเลิก
              </Button>
              <Button
                className='text-white bg-primary text-[14px]'
                type='submit'
                disabled={isSubmitting}
              >
                บันทึก และส่งแบบร่าง
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
