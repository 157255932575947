import React from 'react'
import { ByUserFormProps } from './interface'

export function withByUserForm(Component: React.FC<ByUserFormProps>) {
  function WithByUserForm(props: ByUserFormProps) {
    return <Component {...props} />
  }

  return WithByUserForm
}
