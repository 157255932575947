import React, { useEffect, useRef, useState } from 'react'
import { useField } from 'formik'

import {
  NumberWithCommaFieldProps,
  WithNumberWithCommaFieldProps,
} from './interface'

export function withNumberWithCommaField(
  Component: React.FC<NumberWithCommaFieldProps>
) {
  function WithNumberWithCommaField({
    name,
    disabled = false,
    maxNumber,
    ...props
  }: WithNumberWithCommaFieldProps) {
    const [field, { touched, error }, { setValue }] = useField(name)

    const rightIconRef = useRef<HTMLDivElement>(null)
    const [rightIconWidth, setRightIconWidth] = useState(0)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = Number(event.target.value.replaceAll(',', ''))

      if (Number.isNaN(value)) return

      if (maxNumber && value > maxNumber) {
        setValue(maxNumber)
        return
      }

      setValue(value)
    }

    useEffect(() => {
      if (rightIconRef.current !== null) {
        setRightIconWidth(rightIconRef.current.clientWidth)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const componentProps = {
      ...props,
      ...field,
      touched,
      error,
      disabled,
      rightIconRef,
      rightIconWidth,
      onChange: handleChange,
    }

    return <Component {...componentProps} />
  }

  return WithNumberWithCommaField
}
