import React from 'react'
import cn from 'classnames'
import { useFormikContext } from 'formik'

import { Box } from '@components/Box'
import { RadioInputField } from '@components/forms/RadioInputField'
import { Checkbox } from '@components/Checkbox'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { NumberWithDecimalField } from '@components/forms/NumberWithDecimalField'
import { MultiSelectDay } from '@features/promotionSetting/components/MultiSelectDay'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { CheckboxField } from '@components/forms/CheckboxField'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import SelectCoinTypeField from '@features/promotionSetting/components/PromotionChallengeForm/components/SelectCoinTypeField'
import { MultiSelectField } from '@components/forms/MultiSelectField'
import { UploadPromotionSaleField } from '@features/promotionSetting/components/PromotionSaleForm/components/UploadPromotionSaleField'
import { InputField } from '@components/forms/InputField'
import { SaleConditionFormProps } from './interface'

export function SaleConditionForm({
  activeCoinOptions,
  saleCoinOptions,
  categoryOptions,
  viewMode,
  isEdit,
}: SaleConditionFormProps) {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<PromotionSaleFormType>()
  return (
    <Box className='mt-[20px]'>
      <div className='grid gap-y-[30px]'>
        <h1 className='text-[18px] font-bold text-secondary'>
          เงื่อนไขโปรโมชั่น
        </h1>
        <div className='flex space-x-[40px]'>
          <span className='text-[14px] text-secondary'>ใน :</span>
          <RadioInputField
            name='conditionType'
            label='เรื่องใดก็ได้'
            value={PSConditionTypeEnum.ALL}
            labelClassName='text-[14px] text-secondary ml-[5px]'
            checked={values.conditionType === PSConditionTypeEnum.ALL}
            disabled={viewMode || isEdit}
          />
          <RadioInputField
            name='conditionType'
            label='ระบุ Story ID'
            value={PSConditionTypeEnum.STORY}
            labelClassName='text-[14px] text-secondary ml-[5px]'
            checked={values.conditionType === PSConditionTypeEnum.STORY}
            disabled={viewMode || isEdit}
          />
          <RadioInputField
            name='conditionType'
            label='ระบุ Writer ID'
            value={PSConditionTypeEnum.WRITER}
            labelClassName='text-[14px] text-secondary ml-[5px]'
            checked={values.conditionType === PSConditionTypeEnum.WRITER}
            disabled={viewMode || isEdit}
          />
        </div>
        {values.conditionType === PSConditionTypeEnum.ALL ? (
          <MultiSelectField
            name='categoryIds'
            label='หมวดหมู่'
            placeholder='หมวดหมู่'
            className='w-full'
            options={categoryOptions}
            disabled={viewMode || isEdit}
            showSelectAll
          />
        ) : (
          <>
            <div className='flex items-center border-t border-gray pt-[30px]'>
              <span className='mr-[20px] font-light text-[14px] text-secondary'>
                {values.conditionType === PSConditionTypeEnum.STORY
                  ? 'ID เรื่อง'
                  : 'Writer ID'}
              </span>
              <InputField
                name='termIdInput'
                className='flex-1'
                placeholder={`กรอก ${
                  values.conditionType === PSConditionTypeEnum.STORY
                    ? 'Story'
                    : ' Writer'
                } ID`}
                showErrorMsg={false}
                onChange={() =>
                  setFieldValue('termIdInputError', 'checking...')
                }
                disabled={
                  Boolean(values.conditionFile) ||
                  Boolean(values.conditionFileError) ||
                  viewMode ||
                  isEdit
                }
              />
            </div>
            <div
              className={cn(
                'text-[12px] font-light text-[#f7575f] mt-[-20px] text-right',
                {
                  hidden: !values.termIdInputError,
                }
              )}
            >
              {values.termIdInputError}
            </div>

            <UploadPromotionSaleField
              type={values.conditionType}
              disabled={
                Boolean(values.termIdInput) ||
                Boolean(values.termIdInputError) ||
                viewMode ||
                isEdit
              }
            />
            <div
              className={cn(
                'text-[12px] font-light text-[#f7575f] mt-[-20px] text-right',
                {
                  hidden: !values.conditionFileError,
                }
              )}
            >
              {values.conditionFileError}
            </div>
          </>
        )}

        <div className='flex'>
          <div className='flex items-center gap-x-[10px] mr-[40px]'>
            <span className='text-[14px] text-secondary whitespace-nowrap'>
              จำนวน :
              {errors.actionValue && <span className='text-red-74'> *</span>}
            </span>
            <NumberWithCommaField
              name='actionValue'
              inputClassName='pr-[90px]'
              className={cn('text-secondary-100 text-[14px] w-[180px]')}
              showErrorMsg={false}
              rightIcon='เหรียญขึ้นไป'
              disabled={viewMode}
              maxLength={10}
            />
          </div>
          <div className={cn('flex flex-wrap items-center gap-[20px]')}>
            {errors.coinConditionIds && touched.coinConditionIds && (
              <span className='text-red-74 mr-[10px] text-sm'>*</span>
            )}
            {activeCoinOptions.map(coinOption => (
              <Checkbox
                key={coinOption.value}
                name='coinConditionIds'
                label={`เหรียญ${coinOption.label}`}
                checked={values.coinConditionIds?.includes(
                  Number(coinOption.value)
                )}
                className='min-w-fit'
                onChange={() => {
                  const coinIds = [...values.coinConditionIds]
                  const coinIdsIndex = coinIds.findIndex(
                    t => t === Number(coinOption.value)
                  )
                  if (coinIdsIndex !== -1) {
                    coinIds.splice(coinIdsIndex, 1)
                  } else {
                    coinIds.push(Number(coinOption.value))
                  }
                  setFieldValue('coinConditionIds', coinIds)
                }}
                disabled={viewMode}
              />
            ))}
          </div>
          <div className='flex items-center ml-auto'>
            <span className='text-[12px] text-secondary whitespace-nowrap mr-[20px]'>
              ใช้ได้ในวัน
            </span>
            <MultiSelectDay
              name='usageDay'
              placeholder='เลือกวัน'
              className='w-[135px]'
              disabled={viewMode}
            />
          </div>
        </div>
        <div className='flex space-x-[40px]'>
          <span className='text-[14px] text-secondary'>ใช้เหรียญ :</span>
          <RadioInputField
            name='useCoinConditionType'
            label='ภายในเรื่องเดียวกัน'
            value={PSUseCoinConditionTypeEnum.STORY}
            labelClassName='text-[14px] text-secondary ml-[5px]'
            checked={
              values.useCoinConditionType === PSUseCoinConditionTypeEnum.STORY
            }
            disabled={viewMode}
          />
          <RadioInputField
            name='useCoinConditionType'
            label='ทั้งหมด'
            value={PSUseCoinConditionTypeEnum.ALL}
            labelClassName='text-[14px] text-secondary ml-[5px]'
            checked={
              values.useCoinConditionType === PSUseCoinConditionTypeEnum.ALL
            }
            disabled={viewMode}
          />
        </div>
        <div className={cn('border-b border-gray ')} />
        <div className='flex items-center gap-x-[40px]'>
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex items-center gap-x-[20px]'>
              <span className='text-[14px] text-secondary-100'>
                รับเหรียญ :{' '}
                {(errors.benefitValue || errors.benefitCoinId) && (
                  <span className='text-red-74'>*</span>
                )}
              </span>
              <NumberWithDecimalField
                name='benefitValue'
                className='w-[140px] text-secondary-100 text-[14px]'
                inputClassName='!pr-[63px]'
                rightIcon='เหรียญ'
                showErrorMsg={false}
                disabled={viewMode}
                maxLength={8}
              />
              <SelectCoinTypeField
                coins={saleCoinOptions}
                viewMode={viewMode}
              />
            </div>
          </div>
        </div>
        <div className='flex items-center gap-x-[40px]'>
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex items-center gap-x-[20px]'>
              <span className='text-[14px] text-secondary'>
                เก็บเหรียญได้ :
              </span>
              <RadioInputField
                name='benefitLimitType'
                label='ครั้งเดียว'
                value={PromotionChallengeLimitTypeEnum.ONCE}
                labelClassName={`text-[14px] ml-[5px] ${
                  values.benefitLimitType ===
                  PromotionChallengeLimitTypeEnum.ONCE
                    ? 'text-secondary'
                    : 'text-secondary-100'
                } `}
                checked={
                  values.benefitLimitType ===
                  PromotionChallengeLimitTypeEnum.ONCE
                }
                disabled={viewMode}
              />
            </div>
          </div>
          <div className='flex items-center gap-x-[40px]'>
            <RadioInputField
              name='benefitLimitType'
              label='ไม่จำกัด'
              value={PromotionChallengeLimitTypeEnum.UNLIMITED}
              labelClassName={`text-[14px] ml-[5px] ${
                values.benefitLimitType ===
                PromotionChallengeLimitTypeEnum.UNLIMITED
                  ? 'text-secondary'
                  : 'text-secondary-100'
              } `}
              checked={
                values.benefitLimitType ===
                PromotionChallengeLimitTypeEnum.UNLIMITED
              }
              disabled={viewMode}
            />
          </div>
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex gap-x-[10px]'>
              <RadioInputField
                checkMarkClassName='mt-[10px]'
                name='benefitLimitType'
                label=''
                value={PromotionChallengeLimitTypeEnum.PER_DAY}
                checked={
                  values.benefitLimitType ===
                  PromotionChallengeLimitTypeEnum.PER_DAY
                }
                disabled={viewMode}
              />
              <NumberWithCommaField
                name='benefitLimitValue'
                className='w-[120px] text-secondary-100 text-[14px]'
                rightIcon='ครั้ง/วัน'
                inputClassName='!pr-[68px]'
                disabled={
                  values.benefitLimitType !==
                    PromotionChallengeLimitTypeEnum.PER_DAY || viewMode
                }
                showErrorMsg={false}
                maxLength={10}
              />
            </div>
          </div>
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex gap-x-[10px]'>
              <RadioInputField
                checkMarkClassName='mt-[10px]'
                name='benefitLimitType'
                label=''
                value={PromotionChallengeLimitTypeEnum.PER_CAMPAIGN}
                checked={
                  values.benefitLimitType ===
                  PromotionChallengeLimitTypeEnum.PER_CAMPAIGN
                }
                disabled={viewMode}
              />
              <NumberWithCommaField
                name='benefitLimitValue'
                className='w-[142px] text-secondary-100 text-[14px]'
                rightIcon='ครั้ง/แคมเปญ'
                inputClassName='!pr-[90px]'
                disabled={
                  values.benefitLimitType !==
                    PromotionChallengeLimitTypeEnum.PER_CAMPAIGN || viewMode
                }
                showErrorMsg={false}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        <div className='flex items-center gap-x-[40px]'>
          <div className='flex items-center gap-x-[40px]'>
            <div className='flex items-center gap-x-[20px]'>
              <span className='text-[14px] text-secondary'>
                งบประมาณ/แคมเปญ :{' '}
                {errors.budget && <span className='text-red-74'>*</span>}
              </span>
              <NumberWithCommaField
                name='budget'
                className='w-[214px] text-secondary-100 text-[14px]'
                rightIcon={`เหรียญ${
                  saleCoinOptions.find(
                    coin => coin.value === Number(values.benefitCoinId)
                  )?.label || ''
                }`}
                inputClassName='!pr-[60px]'
                showErrorMsg={false}
                disabled={viewMode}
                maxLength={10}
              />
            </div>
            <div className='flex items-center'>
              <CheckboxField
                type='checkbox'
                name='isLimitBudget'
                label='จำกัดจำนวน'
                checked={values.isLimitBudget}
                className='mr-[24px]'
                disabled={viewMode}
              />
              <NumberWithCommaField
                name='budgetPerDay'
                className='w-[214px] text-secondary-100 text-[14px] mr-[10px]'
                rightIcon={`เหรียญ${
                  saleCoinOptions.find(
                    coin => coin.value === Number(values.benefitCoinId)
                  )?.label || ''
                }`}
                inputClassName='!pr-[60px]'
                disabled={!values.isLimitBudget || viewMode}
                showErrorMsg={false}
                maxLength={10}
              />
              <span className='text-[14px] text-secondary-100'>ต่อวัน</span>
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}
