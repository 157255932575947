import { Expose, Type } from 'class-transformer'

export class BookOptionType {
  @Expose() id: number

  @Expose() coverImgPath: string

  @Expose() title: string
}

export class BookOptionResponse {
  @Expose()
  @Type(() => BookOptionType)
  data: BookOptionType[]

  @Expose() page: number

  @Expose() total: number
}
