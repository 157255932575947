import { ColumnType } from '@components/Table/interface'
import { CharacterDonationByCharacterSortEnum } from '@interfaces/character/CharacterDonationByCharacteSortEnum'
import { abbreviateNumber } from '@libs/utils'
import { CharacterDonationByCharacter } from '@models/character/CharacterDonationByCharacter'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<CharacterDonationByCharacter>[] = [
  {
    column: 'อันดับ',
    accessor: 'rank',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationByCharacterSortEnum.RANK,
  },
  {
    column: 'Character Name',
    accessor: 'characterName',
    cell: ({ value }) => (
      <p className='max-w-[150px] line-clamp-1 break-all'>{value}</p>
    ),
    isSortable: true,
    sortKey: CharacterDonationByCharacterSortEnum.CHARACTER_NAME,
  },
  {
    column: 'Story',
    accessor: 'bookTitle',
    cell: ({ value }) => (
      <p className='max-w-[200px] line-clamp-1 break-all'>{value}</p>
    ),
    isSortable: true,
    sortKey: CharacterDonationByCharacterSortEnum.STORY,
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationByCharacterSortEnum.USERNAME,
  },
  {
    column: 'Donation',
    accessor: 'totalDonation',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationByCharacterSortEnum.DONATION,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell bookId={obj.bookId} />,
    cellClassName: 'flex justify-end',
    isAction: true,
  },
]
