import { withPermissionGuard } from '@libs/permission'
import { SalesByUserDetailPage } from './SalesByUserDetailPage'
import { withSalesByUserDetailPage } from './withSalesByUserDetailPage'

const ConnectedSalesByUserDetailPage = withPermissionGuard(
  withSalesByUserDetailPage(SalesByUserDetailPage),
  'user',
  'view'
)

export { ConnectedSalesByUserDetailPage as SalesByUserDetailPage }
