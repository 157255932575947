import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'

import { Button } from '@components/Button'
import {
  CodeChannelTypeEnum,
  CodeChannelTypeValue,
} from '@interfaces/promotionCode/CodeChannelTypeEnum '
import {
  CoinCoditionEnum,
  CoinCoditionTypeValue,
} from '@interfaces/promotionCode/CoinCoditionEnum'
import {
  DaySelectEnum,
  daySelectTypeValue,
} from '@interfaces/promotionCode/DaySelectEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import {
  PaymentMethodEnum,
  PaymentMethodTypeValue,
} from '@interfaces/promotionCode/PaymentMethodEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import {
  PromotionUserEnum,
  PromotionUserTypeValue,
} from '@interfaces/promotionCode/PromotionUserEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { formatDateDomini } from '@libs/utils'
import { ConfirmToCreateModalProps } from './interface'

export function ConfirmToCreateModal({
  values,
  handleSubmit,
  handleCancel,
  promotionChannelOption,
  coinOptions,
  title,
  isSubmitting,
  setIsSubmitting,
}: ConfirmToCreateModalProps) {
  const now = DateTime.now().toISO()
  const headerClassName = 'text-[18px] font-bold'
  const titleClassName = 'text-[12px] font-light text-secondary-100 mr-[5px]'

  return (
    <div className='flex flex-col w-[700px] text-[14px] font-light text-secondary px-[40px] py-[40px]'>
      <p className='font-mitr font-medium text-[24px] pb-[25px] border-b text-center'>
        คุณยืนยัน{title}
      </p>
      <div className='flex flex-col max-h-[440px] overflow-y-auto'>
        <div className='flex flex-col gap-y-[20px]  py-[20px]'>
          <p className={cn('line-clamp-1 break-word', headerClassName)}>
            {values.promotionName}
          </p>

          <div className='flex justify-between'>
            <div>
              <span className={cn(titleClassName)}>วันที่สร้างโปรโมชั่น :</span>
              <span>{formatDateDomini(now, true)}</span>
            </div>
            <div>
              <span className='text-[14px] mr-[5px] font -light text-secondary-100'>
                ระยะเวลาใช้โค้ด :
              </span>
              <span className='font-bold'>
                {values.publishedType === PromotionPublishedEnum.NOW
                  ? values.startNowAt
                    ? formatDateDomini(values.startNowAt.toISOString(), true)
                    : 'ทันที'
                  : formatDateDomini(
                      values.startPublishedAt.toISOString(),
                      true
                    )}
              </span>
              <span className='text-[14px] px-[10px] font -light text-secondary-100'>
                ถึง
              </span>
              <span className='font-bold'>
                {formatDateDomini(values.endPublishedAt.toISOString(), true)}
              </span>
            </div>
          </div>
          <div className='flex '>
            <div className={cn('min-w-[95px]', titleClassName)}>
              รายละเอียด :
            </div>
            <div className='break-word line-clamp-1'>
              {values.promotionDetail || '-'}
            </div>
          </div>
          <div className='flex'>
            <div className={cn('min-w-[95px]', titleClassName)}>
              เงื่อนไขการใช้งาน :
            </div>
            <div className='whitespace-pre-line break-word'>
              {values.promotionCondition || '-'}
            </div>
          </div>
          <div className='flex'>
            <div className={cn('w-[95px]', titleClassName)}>ช่องทาง :</div>
            <div className='w-[437px] flex flex-wrap  gap-x-[20px]'>
              {values.codeChannelType === CodeChannelTypeEnum.OTHER
                ? values.codeChannelSelect
                  ? promotionChannelOption?.find(
                      row => row.value === values.codeChannelSelect
                    )?.label
                  : values.codePurchase
                : CodeChannelTypeValue[values.codeChannelType]}{' '}
              ({values.codeChannelInput.toLocaleString()} Code)
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-y-[20px] py-[20px] border-t'>
          <p className={cn(headerClassName)}>เงื่อนไขโปรโมชั่น</p>
          <div className='flex'>
            <div className={cn('min-w-[95px]', titleClassName)}>สำหรับ :</div>
            <div className='flex flex-col gap-y-[20px]'>
              <div className='flex gap-x-[40px]'>
                {
                  PromotionUserTypeValue[
                    values.forUserType as PromotionUserEnum
                  ]
                }
                {values.isFirstTopUp && ', เติมเงินครั้งแรก'}
                <div>
                  {values.limitJoinPromotionType === LimitToJoinEnum.LIMIT ? (
                    <div className={titleClassName}>
                      จำกัดการใช้โปรโมชั่น :{' '}
                      <span className='text-[14px] px-[10px] text-secondary'>
                        {values.limitJoinPromotion.toLocaleString()}
                      </span>
                      ครั้ง
                    </div>
                  ) : (
                    <div className={titleClassName}>ไม่จำกัดจำนวน</div>
                  )}
                </div>
              </div>
              <div className='flex items-center'>
                {values.isLimitPromotionCode ? (
                  <div className={cn('whitespace-nowrap', titleClassName)}>
                    จำกัดจำนวนสิทธิ์ :{' '}
                    <span className='text-[14px] px-[10px] text-secondary'>
                      {values.limitPromotionCode.toLocaleString()}
                    </span>
                    สิทธิ์
                  </div>
                ) : (
                  <div className={cn('whitespace-nowrap', titleClassName)}>
                    จำกัดจำนวนสิทธิ์ :
                    <span className='text-[14px] px-[10px] text-secondary'>
                      ไม่จำกัดสิทธิ์
                    </span>
                  </div>
                )}

                <div
                  className={cn('ml-[40px] whitespace-nowrap', titleClassName)}
                >
                  ใช้ได้ในวัน :
                </div>
                <div className='pl-[5px] break-word line-clamp-1'>
                  {values.availableDay.length === 7
                    ? 'ทุกวัน'
                    : values.availableDay.map(item => (
                        <React.Fragment key={item}>
                          <span>
                            {daySelectTypeValue[item as DaySelectEnum]}
                          </span>
                          <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                        </React.Fragment>
                      ))}
                </div>
              </div>
            </div>
          </div>
          <div className='flex'>
            <div className={cn('min-w-[95px] text-secondary-100')}>
              เงื่อนไข :
            </div>
            <div className='flex flex-col justify-center gap-y-[20px]'>
              {values.userByType === UserByEnum.BUY_COIN && (
                <>
                  <div className='text-secondary-100 flex gap-x-[20px] items-center'>
                    <div className='flex whitespace-nowrap'>
                      เมื่อผู้ใช้งานซื้อเหรียญ
                      <span className='text-secondary px-[10px]'>
                        {
                          CoinCoditionTypeValue[
                            values.userBuyCoinCondition as CoinCoditionEnum
                          ]
                        }
                      </span>
                      <span className='text-primary font-bold mr-[4px]'>
                        {values.userBuyCoinInput.toLocaleString()}
                      </span>
                      บาท
                    </div>
                    <div className='flex'>
                      <span className='whitespace-nowrap'>ผ่านช่องทาง :</span>
                      <span className='ml-[10px] text-secondary break-word line-clamp-1'>
                        {values.paymentMethod.length === 4
                          ? 'ทุกช่องทาง'
                          : values.paymentMethod.map(item => (
                              <React.Fragment key={item}>
                                <span>
                                  {
                                    PaymentMethodTypeValue[
                                      item as PaymentMethodEnum
                                    ]
                                  }
                                </span>
                                <span className=' mr-1 last:mr-0 last:hidden'>
                                  ,
                                </span>
                              </React.Fragment>
                            ))}
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-x-[20px]'>
                    <div>
                      รับเพิ่ม :
                      <span className='px-[10px] text-primary font-bold'>
                        {values.moreCoinInput.toLocaleString()}
                      </span>{' '}
                      <span className='text-secondary-100'>
                        {
                          coinOptions?.find(
                            row => row.value === values.moreCoinOption
                          )?.label
                        }
                      </span>
                    </div>
                  </div>
                </>
              )}
              {values.userByType === UserByEnum.REDEEM && (
                <div>
                  <span className='text-secondary-100 mr-[10px]'>
                    เมื่อผู้ใช้งานรีดีมโค้ด
                  </span>
                  รับทันที :
                  <span className='text-primary font-bold px-[10px]'>
                    {values.directCoinInput.toLocaleString()}
                  </span>
                  <span className='text-secondary-100'>
                    {
                      coinOptions?.find(
                        row => row.value === values.directCoinOption
                      )?.label
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-center gap-x-[20px] pt-[20px] border-t'>
        <Button
          onClick={handleCancel}
          className=' text-secondary text-[14px] px-[30px]'
        >
          ยกเลิก
        </Button>
        <Button
          onClick={() => {
            setIsSubmitting(true)
            handleSubmit(values)
          }}
          disabled={isSubmitting}
          className='text-white bg-primary px-[30px] text-[14px]'
        >
          ยืนยัน
        </Button>
      </div>
    </div>
  )
}
