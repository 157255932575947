import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Table } from '@components/Table'
import { ByTitleProps } from './interface'
import { ByTitleForm } from '../ByTitleForm'
import { byTitleColumns } from '../column'

function ByTitle({
  handleReset,
  handleSearch,
  queryParam,
  data,
  isLoading,
}: ByTitleProps) {
  return (
    <>
      <ByTitleForm
        initialValues={{
          ebookId: queryParam.ebookId,
          timeRange: TimeRangeEnum.MONTH,
          startDate: undefined,
          endDate: undefined,
        }}
        handleSubmit={handleSearch}
        onResetForm={handleReset}
      />
      {queryParam.ebookId ? (
        <Table
          className='mt-[30px]'
          data={data}
          columns={byTitleColumns}
          isLoading={isLoading}
        />
      ) : (
        <CardContainer>
          <p className='text-center'>
            เลือกชื่อเรื่อง,ช่วงเวลา ที่ต้องการดูข้อมูล
          </p>
        </CardContainer>
      )}
    </>
  )
}

export default ByTitle
