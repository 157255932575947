import React, { useCallback } from 'react'
import { useField } from 'formik'

import { ImageType } from '@interfaces/ImageType'
import { useAlert } from '@hooks/useAlert'
import { WithUploadCoverFieldProps, UploadCoverFieldProps } from './interface'

export function withUploadCoverField(
  Component: React.FC<UploadCoverFieldProps>
) {
  function WithUploadCoverField({
    name,
    label,
    className,
    description,
    inputRef,
    minHeigth,
    minWidth,
  }: WithUploadCoverFieldProps) {
    const [{ value }, { touched, error }, { setValue }] =
      useField<ImageType>(name)
    const alert = useAlert()

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target

        if (files && files[0].size / (1000 * 1000) < 1) {
          const objectUrl = URL.createObjectURL(files[0])
          const img = new Image()
          img.onload = function onLoadImage() {
            if (
              minWidth &&
              minHeigth &&
              (img.width < minWidth || img.height < minHeigth)
            ) {
              alert.error(
                `ขนาดรูปภาพต้องไม่ต่ำกว่า ${minWidth} X ${minHeigth} px`
              )
              URL.revokeObjectURL(objectUrl)
            } else {
              setValue({
                url: objectUrl,
                blob: files[0],
              })
            }
          }
          img.src = objectUrl
        } else {
          alert.error('ไฟล์ภาพมีขนาดเกินกำหนด (1 MB)')
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const componentProps = {
      className,
      label,
      description,
      src: value?.url,
      touched,
      error,
      inputRef,
      onChange: handleChange,
    }

    return <Component {...componentProps} />
  }

  return WithUploadCoverField
}
