import { Expose, Transform } from 'class-transformer'

export class ReaderTopMoneySpenderType {
  @Expose() userId: string

  @Expose() username: string

  @Expose()
  @Transform(({ value }) => (value ? Number(value) : 0))
  userCoinSpend: number
}
