import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@hooks/useAuthentication'

export function withUnAuthenGuard(Component: React.FC<any>) {
  function WithUnAuthenGuard(props: any) {
    const { isUserLoading, isAuthenticated } = useAuthentication()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isUserLoading && isAuthenticated) {
        navigate('/')
      }
    }, [isUserLoading, isAuthenticated, navigate])

    if (!isAuthenticated && !isUserLoading) {
      return <Component {...props} />
    }

    return <></>
  }

  return WithUnAuthenGuard
}
