import React from 'react'
import { useMutation } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import {
  WithUserMissionLevelFormProps,
  UserMissionLevelFormProps,
} from './interface'

export function withUserMissionLevelForm(
  Component: React.FC<UserMissionLevelFormProps>
) {
  function WithUserMissionLevelForm(props: WithUserMissionLevelFormProps) {
    const { userMissionForm, refetchUserMission } = props
    const client = useClient()
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })

    const { mutateAsync: toggleChallengeLevel } = useMutation(
      ({ id, isActive }: { id: number; isActive: boolean }) =>
        client!.userMissionClient.updateEnableChallengeLevel({ id, isActive }),
      {
        onSuccess: async (_, { isActive }) => {
          confirmModal.hide()
          alert.success(isActive ? 'เปิดเผยแพร่สำเร็จ' : 'ปิดเผยแพร่สำเร็จ')
          await refetchUserMission()
        },
        onError: (error: any) => {
          if (
            error.response.errors?.length &&
            error.response.errors[0].message.includes('cannot disable')
          ) {
            alert.error('ไม่สามารถปิดเผยแพร่ เนื่องจากมี user ถึงระดับนี้แล้ว')
          } else {
            const message = getErrorMessage(error)
            alert.error(message)
          }
          confirmModal.hide()
        },
      }
    )

    async function handleToggleChallengeLevel(index: number) {
      const userMissionLevels = userMissionForm.userMissionLevels.filter(
        row => row.id
      )
      const isActive = !userMissionLevels[index].isActive

      if (
        !isActive &&
        index < userMissionLevels.length - 1 &&
        userMissionLevels[index + 1].isActive === true
      ) {
        alert.error(
          'ไม่สามารถทำการปิดเผยแพร่ได้ เนื่องจากระดับถัดไปเปิดเผยแพร่'
        )

        return
      }

      if (
        isActive &&
        index > 0 &&
        index < userMissionLevels.length &&
        userMissionLevels[index - 1].isActive === false
      ) {
        alert.error(
          'ไม่สามารถทำการเปิดเผยแพร่ได้ เนื่องจากระดับก่อนหน้าปิดเผยแพร่'
        )

        return
      }

      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              {isActive
                ? `ยืนยันการเปิดเผยแพร่ ${userMissionLevels[index].levelName} หรือไม่?`
                : `ยืนยันการปิดเผยแพร่ ${userMissionLevels[index].levelName} หรือไม่?`}
            </p>
          </div>
        ),
        onConfirm: async () => {
          await toggleChallengeLevel({
            id: userMissionLevels[index].id,
            isActive,
          })
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const componentProps = {
      ...props,
      initialValues: userMissionForm,
      handleToggleChallengeLevel,
    }

    return <Component {...componentProps} />
  }
  return WithUserMissionLevelForm
}
