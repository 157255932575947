import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { BanUserImageEnum } from '@interfaces/user/BanUserImageEnum'
import { UserManagementDetailProps } from './interface'

export function withUserManagementDetailPage(
  Component: React.FC<UserManagementDetailProps>
) {
  function WithUserManagementDetailPage() {
    const { id } = useParams()
    const client = useClient()
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const alert = useAlert()
    const confirmModal = useModal({
      modal: 'confirm',
    })
    const { isAuthenticated } = useAuthentication()
    const tab = params.get('tab') || 'penName'
    const [tabActive, setTabActive] = useState(tab)

    function handleSelectTab(active: string) {
      setTabActive(active)
      navigate({
        pathname: '',
        search: `?tab=${active}`,
      })
    }

    const { data, refetch } = useQuery(
      ['user-management', { id }],
      () => client?.userClient.getUserManagement(Number(id)),
      { enabled: isAuthenticated && !!id }
    )

    function banCover() {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ต้องการจะแบนรูปภาพหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              await client!.userManagementClient.createBanImageLog({
                userId: Number(id),
                type: BanUserImageEnum.PROFILE_IMAGE,
                imageUrl: data!.avatarImgPath,
              })
              alert.success('ดำเนินการสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              refetch()
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    function banBanner() {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ต้องการจะแบนรูปภาพหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              await client!.userManagementClient.createBanImageLog({
                userId: Number(id),
                type: BanUserImageEnum.PROFILE_COVER,
                imageUrl: data!.bannerImgPath,
              })
              alert.success('ดำเนินการสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              refetch()
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }
    async function handleUnbanUser() {
      try {
        await client?.userManagementClient.unBanUser(Number(id))
        refetch()
        alert.success('ยกเลิกแบนสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }
    useEffect(() => {
      setTabActive(tab)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])
    const newProps = {
      data,
      tabActive,
      handleSelectTab,
      banCover,
      banBanner,
      handleUnbanUser,
    }
    return <Component {...newProps} />
  }
  return WithUserManagementDetailPage
}
