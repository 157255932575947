import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { StoryDetailReaderType } from '@models/story/StoryDetailReaderType'
import { BookTopChartsResponse } from '@models/story/BookTopChartsResponse'
import { BookTopChartsParamType } from '@interfaces/story/BookTopChartsParamType'
import { BookChapterByBookResponse } from '@models/story/BookChapterByBookResponse'
import { ListUserReadingResponse } from '@models/story/ListUserReadingResponse'
import { BookCharacterType } from '@models/story/BookCharacterType'
import { DateTime } from 'luxon'
import { BookChapterViewerResponse } from '@models/story/BookChapterViewerResponse'
import { BookChapterDetailDashboardResponse } from '@models/story/BookChapterDetailDashboardResponse'
import { BookPromotionDetailDashboardResponse } from '@models/story/BookPromotionDetailDashboardResponse'
import { GET_BOOK_TOP_CHARTS } from './schemas/getBookTopChart'
import { GET_STORY_DETAIL } from './schemas/getStoryDetail'
import { GET_STORY_COVER } from './schemas/getStoryCover'
import { GET_BOOK_CHAPTER_BY_BOOK } from './schemas/getBookChapterByBook'
import { BAN_TOP_CHART_BOOK } from './schemas/banTopChartBooks'
import { GET_BANNED_BOOK_TOP_CHARTS } from './schemas/getBannedBookTopChart'
import { GET_LIST_USER_READING } from './schemas/getListUserReading'
import { GET_CHARACTERS } from './schemas/getCharacters'
import { GET_BOOK_CHAPTER_VIEWER } from './schemas/getBookChapterViewer'
import { GET_BOOK_CHAPTER_DETAIL_DASHBOARD } from './schemas/getBookChapterDetailDashboard'
import { GET_BOOK_PROMOTION_DETAIL_DASHBOARD } from './schemas/getBookPromotionDetailDashboard'

interface BookTopChartsQuery extends BookTopChartsParamType {
  page: number
}
export class StoryClient {
  constructor(private client: GraphQLClient) {}

  async getStoryDetail(id: number): Promise<StoryDetailReaderType> {
    const { book } = await this.client.request(GET_STORY_DETAIL, { id })

    return plainToInstance(StoryDetailReaderType, book)
  }

  async getStoryCover(id: number): Promise<StoryDetailReaderType> {
    const { book } = await this.client.request(GET_STORY_COVER, { id })

    return plainToInstance(StoryDetailReaderType, book)
  }

  async getCharacters(id: number): Promise<BookCharacterType[]> {
    const { getCharacterByBook } = await this.client.request(GET_CHARACTERS, {
      bookId: id,
    })

    return plainToInstance(BookCharacterType, getCharacterByBook as [])
  }

  async getBookChapterByBook({
    page,
    bookId,
    orderKey,
    orderBy,
  }: {
    page: number
    bookId: number
    orderKey: string
    orderBy: string
  }): Promise<BookChapterByBookResponse> {
    const { getBookChapterByBook } = await this.client.request(
      GET_BOOK_CHAPTER_BY_BOOK,
      {
        limitPerPage: 10,
        page,
        bookId,
        orderBy: orderKey ? { [orderKey]: orderBy } : undefined,
      }
    )

    return plainToInstance(BookChapterByBookResponse, getBookChapterByBook)
  }

  async getBookTopCharts({
    limit,
    categoryId,
    bookType,
    topChartType,
    bookTopChartType,
    page,
    ...params
  }: BookTopChartsQuery): Promise<BookTopChartsResponse> {
    const startDate = params.endDate.startOf('day').toUTC()
    const endDate = startDate.plus({ hour: 8, day: 1 })
    const { bookTopCharts } = await this.client.request(GET_BOOK_TOP_CHARTS, {
      limitPerPage: limit,
      page,
      bookType,
      ...(categoryId && { categoryId: Number(categoryId) }),
      startDate,
      endDate,
      topChartsType: topChartType,
      bookTopChartsType: bookTopChartType,
      isAdminView: true,
    })

    return plainToInstance(BookTopChartsResponse, bookTopCharts)
  }

  async getBannedBookTopCharts({
    limitPerPage,
    page,
  }: {
    limitPerPage: number
    page: number
  }): Promise<BookTopChartsResponse> {
    const { findBannedBookTopCharts } = await this.client.request(
      GET_BANNED_BOOK_TOP_CHARTS,
      {
        limitPerPage,
        page,
      }
    )

    return plainToInstance(BookTopChartsResponse, findBannedBookTopCharts)
  }

  async banTopChartBooks({
    topChartId,
    banReason,
  }: {
    topChartId: number
    banReason?: string
  }): Promise<void> {
    await this.client.request(BAN_TOP_CHART_BOOK, {
      banTopChartInput: { topChartId, banReason },
    })
  }

  async getListUserReading({
    page,
    bookId,
    startDate,
    endDate,
    orderKey,
    orderBy,
  }: {
    page: number
    bookId: number
    startDate: DateTime
    endDate: DateTime
    orderKey: string
    orderBy: string
  }): Promise<ListUserReadingResponse> {
    const { getListUserReading } = await this.client.request(
      GET_LIST_USER_READING,
      {
        limitPerPage: 20,
        page,
        bookId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        orderBy: orderKey ? { [orderKey]: orderBy } : undefined,
      }
    )

    return plainToInstance(ListUserReadingResponse, getListUserReading)
  }

  async getBookChapterViewer({
    bookId,
    startDate,
    endDate,
  }: {
    bookId: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<BookChapterViewerResponse> {
    const { getBookChapterViewer } = await this.client.request(
      GET_BOOK_CHAPTER_VIEWER,
      {
        bookId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(BookChapterViewerResponse, getBookChapterViewer)
  }

  async getBookChapterDetailDashboard({
    page,
    bookId,
    startDate,
    endDate,
    key,
    order,
  }: {
    page: number
    bookId: number
    startDate: DateTime
    endDate: DateTime
    key: string
    order: string
  }): Promise<BookChapterDetailDashboardResponse> {
    const { getBookChapterDetailDashboard } = await this.client.request(
      GET_BOOK_CHAPTER_DETAIL_DASHBOARD,
      {
        limitPerPage: 20,
        page,
        bookId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        orderBy: key ? { [key]: order } : undefined,
      }
    )

    return plainToInstance(
      BookChapterDetailDashboardResponse,
      getBookChapterDetailDashboard
    )
  }

  async getBookPromotionDetailDashboard({
    page,
    bookId,
    startDate,
    endDate,
    key,
    order,
  }: {
    page: number
    bookId: number
    startDate: DateTime
    endDate: DateTime
    key: string
    order: string
  }): Promise<BookPromotionDetailDashboardResponse> {
    const { getBookDashboardDetail } = await this.client.request(
      GET_BOOK_PROMOTION_DETAIL_DASHBOARD,
      {
        limitPerPage: 20,
        page,
        bookId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        orderBy: key ? { [key]: order } : undefined,
      }
    )

    return plainToInstance(
      BookPromotionDetailDashboardResponse,
      getBookDashboardDetail
    )
  }
}
