import { gql } from 'graphql-request'

export const GET_ROLES = gql`
  query Roles($orderBy: RolesOrderBy) {
    roles(orderBy: $orderBy) {
      id
      name
    }
  }
`
