import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useClient } from '@hooks/useClient'
import { useDebounce } from '@hooks/useDebounce'
import { NewsAndPromotionFormType } from '@models/newsAndPromotion/NewsAndPromotionFormType'
import { CircleCheckIcon } from '@components/Icons'

export function VerifySlug() {
  const client = useClient()
  const { values, initialValues, setFieldValue } =
    useFormikContext<NewsAndPromotionFormType>()
  const { debounce } = useDebounce()
  const [isPass, setPass] = useState(false)

  useEffect(() => {
    debounce(() => {
      if (values.slug === '') {
        setPass(false)
        setFieldValue('verifySlug', false)
      } else if (initialValues.slug === values.slug) {
        setPass(true)
        setFieldValue('verifySlug', true)
      } else if (initialValues.slug !== values.slug) {
        client?.newsClient
          .verifyNewsSlug(values.slug)
          .then(res => {
            setPass(res)
            setFieldValue('verifySlug', res)
          })
          .catch(() => {
            setFieldValue('verifySlug', false)
            setPass(false)
          })
      }
    }, 400)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.slug, initialValues.slug])

  if (isPass) {
    return (
      <div className='flex items-center gap-x-[5px] text-[14px] text-primary font-light whitespace-nowrap mt-[10px]'>
        <CircleCheckIcon width='24' height='24' />
        สามารถใช้ชื่อนี้ได้
      </div>
    )
  }

  return null
}
