import cn from 'classnames'
import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon } from '@components/Icons'
import { SelectDropdownProps } from './interface'

export function SelectDropdown({
  title,
  placeholder,
  options,
  value,
  className,
  disabled = false,
  optionClassName,
  inputClassName,
  defualtInputColor = 'bg-white',
  handleSelect,
}: SelectDropdownProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className={cn('relative', className)}>
      <div
        className={cn(
          'w-full min-h-[40px] border rounded-[8px] text-[14px] px-[10px] py-[8px] text-secondary font-light cursor-pointer',
          {
            'border-primary bg-white': isShowDropdown,
            [`border-gray-border ${defualtInputColor}`]: !isShowDropdown,
          }
        )}
        onClick={disabled ? () => {} : toggleDropdown}
      >
        <div className='flex items-center justify-between'>
          <div>
            {title && (
              <span className='text-secondary-100 mr-[2px]'>{title}: </span>
            )}
            <span
              className={cn(
                {
                  'font-bold text-secondary': !inputClassName,
                },
                inputClassName
              )}
            >
              {options?.find(row => row.value === value)?.label ? (
                <span>{options?.find(row => row.value === value)?.label}</span>
              ) : (
                <span className='text-secondary-100 mr-[2px]'>
                  {placeholder}
                </span>
              )}
            </span>
          </div>
          <ChevronDownIcon
            width='16'
            height='16'
            className={cn('text-primary')}
          />
        </div>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-full z-10 mt-[10px] right-0 bg-white border border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow'
          >
            {options.map((row, index) => (
              <div
                className='flex items-center hover:bg-primary-100 hover:text-primary p-[10px] gap-x-[10px]'
                key={index}
                onClick={() => {
                  handleSelect(row.value)
                }}
              >
                {row.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
