import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { RolePermissionType } from '@models/rolePermission/RolePermissionType'
import { RoleType } from '@models/rolePermission/RoleType'
import { InviteListType } from '@models/admin/InviteListType'
import { PendingListAdminResponse } from '@models/admin/PendingListAdminResponse'
import { PendingListAdminType } from '@models/admin/PendingListAdminType'
import { AdminUserResponse } from '@models/admin/AdminUserResponse'
import { AdminUserType } from '@models/admin/AdminUserType'
import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'
import { CREATE_ROLE } from './schemas/createRole'
import { CREATE_ADMIN } from './schemas/createAdmin'
import { UPDATE_ROLE } from './schemas/updateRole'
import { GET_ROLE } from './schemas/getRole'
import { GET_ROLES } from './schemas/getRoles'
import { PENDING_LIST_ADMIN } from './schemas/pendingListAdmin'
import {
  UPDATE_ADMIN_ROLE,
  UPDATE_ADMIN_ROLE_USER,
} from './schemas/updateAdminRole'
import { REMOVE_ADMINS } from './schemas/removeAdmins'
import { RESEND_INVITATION } from './schemas/resendInvitation'
import { GET_ADMIN_USER } from './schemas/getAdminUser'
import { REMOVE_ROLE } from './schemas/removeRole'
import { VALIDATE_ADMIN_EMAIL } from './schemas/validateAdminEmail'

export class AdminClient {
  constructor(private client: GraphQLClient) {}

  async getRole(id: number): Promise<RolePermissionType> {
    const { role } = await this.client.request(GET_ROLE, {
      id,
    })
    return role
  }

  async getRoles(key?: string, order?: string): Promise<RoleType[]> {
    const { roles } = await this.client.request(GET_ROLES, {
      orderBy: key ? { [key]: order } : undefined,
    })
    return plainToInstance(RoleType, roles as [])
  }

  async createRole(form: RolePermissionFormType) {
    await this.client.request(CREATE_ROLE, {
      createRoleInput: {
        name: form.name,
        permissions: form.permissions.filter(item => item.status),
      },
    })
  }

  async updateRole(form: RolePermissionFormType, id: number) {
    await this.client.request(UPDATE_ROLE, {
      updateRoleInput: {
        id,
        name: form.name,
        permissions: form.permissions.filter(item => item.status),
      },
    })
  }

  async createAdmin(form: InviteListType[]) {
    await this.client.request(CREATE_ADMIN, {
      createAdminInputs: form.map(item => {
        return { ...item, rolesId: Number(item.rolesId) }
      }),
    })
  }

  async getPendingListAdmin({
    page,
    perpage,
    search,
    orderKey,
    orderBy,
  }: {
    page: number
    perpage: number
    search: string
    orderBy: string
    orderKey: string
  }): Promise<PendingListAdminResponse> {
    const { pendingListAdmin } = await this.client.request(PENDING_LIST_ADMIN, {
      page,
      limitPerPage: perpage,
      search,
      orderBy: orderKey ? { [orderKey]: orderBy } : undefined,
    })
    return plainToInstance(PendingListAdminResponse, pendingListAdmin)
  }

  async updateAdminRole(
    id: number,
    rolesId: number
  ): Promise<PendingListAdminType> {
    const { updateAdminRole } = await this.client.request(UPDATE_ADMIN_ROLE, {
      updateAdminRoleInput: {
        id,
        rolesId,
      },
    })

    return plainToInstance(PendingListAdminType, updateAdminRole)
  }

  async removeAdmins(ids: number[]): Promise<void> {
    await this.client.request(REMOVE_ADMINS, {
      removeAdminsInput: { ids },
    })
  }

  async resendInvitation(id: number): Promise<void> {
    await this.client.request(RESEND_INVITATION, {
      resendInvitationId: id,
    })
  }

  async getAdminUser({
    page,
    perpage,
    search,
    orderKey,
    orderBy,
  }: {
    page: number
    perpage: number
    search: string
    orderBy: string
    orderKey: string
  }): Promise<AdminUserResponse> {
    const { adminLists } = await this.client.request(GET_ADMIN_USER, {
      page,
      limitPerPage: perpage,
      search,
      orderBy: orderKey ? { [orderKey]: orderBy } : undefined,
    })
    return plainToInstance(AdminUserResponse, adminLists)
  }

  async updateAdminRoleUser(
    id: number,
    rolesId: number
  ): Promise<AdminUserType> {
    const { updateAdminRole } = await this.client.request(
      UPDATE_ADMIN_ROLE_USER,
      {
        updateAdminRoleInput: {
          id,
          rolesId,
        },
      }
    )
    return plainToInstance(AdminUserType, updateAdminRole)
  }

  async removeRole(id: number): Promise<void> {
    await this.client.request(REMOVE_ROLE, {
      id,
    })
  }

  async validateAdminEmail(email: string): Promise<void> {
    await this.client.request(VALIDATE_ADMIN_EMAIL, {
      email,
    })
  }
}
