import { Container } from '@components/Container'
import { UserMissionEnum } from '@interfaces/user-mission/UserMissionEnum'
import { UserMissionButton } from './components/UserMissionButton'
import { UserMissionCard } from './components/UserMissionCard'
import { UserMissionPageProps } from './interface'

export function UserMissionPage({
  data,
  type,
  handleTabChange,
  handleToggle,
}: UserMissionPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn={false}>
        User Mission (รางวัล)
      </Container.Title>
      <div className='flex gap-x-[20px] mb-[30px]'>
        <UserMissionButton
          active={type === UserMissionEnum.READER}
          onClick={() => handleTabChange(UserMissionEnum.READER)}
        >
          Reader
        </UserMissionButton>

        <UserMissionButton
          active={type === UserMissionEnum.WRITER}
          onClick={() => handleTabChange(UserMissionEnum.WRITER)}
        >
          Writer
        </UserMissionButton>
      </div>
      <div className='grid gap-[20px]'>
        {data.map(row => (
          <UserMissionCard
            key={row.id}
            id={row.id}
            title={row.title}
            description={row.description}
            iconUrl={row.iconUrl}
            active={row.active}
            criteria={row.criteria}
            challengeUnit={row.challengeUnit}
            challengeLevels={row.challengeLevels}
            handleToggle={handleToggle}
          />
        ))}
      </div>
    </Container>
  )
}
