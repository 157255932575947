import cn from 'classnames'

import { ResidenceEnum } from '@interfaces/ResidenceEnum'
import { TextArea } from '@components/TextArea'
import { WriterEnum } from '@interfaces/WriterEnum'
import { OptionType } from '@interfaces/OptionType'
import { TextInput } from './TextInput'
import { SelectButton } from './SelectButton'
import { residenceOptions } from './Address'
import { useWriterDetail } from '../hooks/useWriterDetail'

export function CertificateAddress({
  countryOptions,
}: {
  countryOptions: OptionType[]
}) {
  const { writer } = useWriterDetail()
  return (
    <div>
      <h2 className='font-mitr text-[24px] ladding-[1.5] font-medium text-secondary'>
        ที่อยู่ตามหนังสือรับรอง
      </h2>
      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <SelectButton
          label='ประเทศ'
          className='font-mitr'
          options={residenceOptions}
          value={writer?.residence}
        />
        {writer?.residence === ResidenceEnum.LOCAL ? (
          <>
            <TextInput
              label='ที่อยู่'
              placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
              value={writer?.certificateAddress}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer?.certificatePostcode}
            />
            <TextInput
              label='จังหวัด'
              placeholder='จังหวัด'
              value={writer?.certificateProvince}
            />
            <TextInput
              label='เขต / อำเภอ'
              placeholder='เขต / อำเภอ'
              value={writer?.certificateDistrict}
            />
            <TextInput
              label='แขวง / ตำบล'
              placeholder='แขวง / ตำบล'
              value={writer?.certificateSubDistrict}
            />
          </>
        ) : (
          <>
            <TextInput
              label='ประเทศ'
              placeholder='เลือกประเทศ'
              value={writer?.certificateCountry || ''}
              options={countryOptions}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer?.certificatePostcode || ''}
            />
            <div
              className={cn({
                'col-span-2': writer?.type === WriterEnum.INDIVIDUAL,
                'col-span-3': writer?.type === WriterEnum.LEGAL,
              })}
            >
              <label className='text-[12px] font-light mb-[6px] text-black-480'>
                ที่อยู่
              </label>
              <TextArea
                className='min-h-[120px]'
                placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
                value={writer?.certificateAddress}
                disabled
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
