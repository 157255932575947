import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from '@components/Button'
import { TextAreaField } from '@components/forms/TextAreaField'
import { Typography } from '@features/user/components/Typography'
import { AgeConfirmRejectModalProps } from './interface'

const validationSchema = Yup.object().shape({
  message: Yup.string().required(),
})

export function AgeConfirmRejectModal({
  avatarImgPath,
  userId,
  fullName,
  username,
  handleSubmit,
  handleCancel,
}: AgeConfirmRejectModalProps) {
  return (
    <div className='flex flex-col gap-y-[20px] w-[500px] px-[30px] py-[40px]'>
      <h1 className='font-mitr font-medium text-[24px] text-secondary text-center'>
        Reject
      </h1>
      <div className='flex gap-x-[40px]'>
        <div className='flex items-center gap-x-[20px]'>
          {avatarImgPath ? (
            <img
              className='w-[38px] h-[38px] rounded-full object-cover'
              src={avatarImgPath}
              alt='User cover'
            />
          ) : (
            <div className='w-[38px] h-[38px] rounded-full bg-gray' />
          )}
          <Typography label='User ID' text={userId} />
        </div>
        <Typography
          label='ชื่อ - นามสกุล'
          text={fullName}
          className='maxw-[110px]'
        />
        <Typography
          label='ชื่อผู้ใช้'
          text={username}
          className='maxw-[110px]'
        />
      </div>
      <Formik
        initialValues={{ message: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <TextAreaField
              textAreaClassName='min-h-[120px]'
              name='message'
              label='ส่งข้อความถึงผู้เขียน'
              placeholder='พิมพ์ข้อความ'
            />
            <div className='flex justify-end items-center gap-x-[20px] font-mitr mt-[20px]'>
              <Button
                className=' text-sm text-secondary !px-0'
                type='button'
                onClick={handleCancel}
              >
                ยกเลิก
              </Button>
              <Button
                disabled={isSubmitting}
                className='w-[100px]  text-sm bg-primary text-white'
                type='submit'
              >
                ส่ง
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
