import { Expose, Transform, Type } from 'class-transformer'

export class UserCommentType {
  @Expose()
  @Transform(({ value }) => value.replace(/<p>|<\/p>/gi, ''))
  content: string

  @Expose() id: number

  @Expose() book: {
    id: number
    title: string
  }

  @Expose() bookChapter: {
    chapterTitle: string
    id: number
  }

  @Expose() createdAt: string
}

export class UserCommentResponse {
  @Expose()
  @Type(() => UserCommentType)
  data: UserCommentType[]

  @Expose() total: number

  @Expose() page: number
}
