import { Loading } from '@components/Loading'
import { Container } from '@components/Container'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionCodeForm } from '@features/promotionSetting/components/PromotionCodeForm'
import { EditPromotionCodePageProps } from './interface'

export function EditPromotionCodePage({
  isLoading,
  initialValues,
  coinOptions,
  updatePromotionCode,
}: EditPromotionCodePageProps) {
  return (
    <Container>
      {!isLoading ? (
        initialValues ? (
          <PromotionCodeForm
            initialValues={initialValues}
            handleSubmit={updatePromotionCode}
            title='แก้ไขโปรโมชั่น Code'
            disableEdit={
              initialValues.promotionStatus !== PromotionStatusEnum.DRAFT
            }
            coinOptions={coinOptions}
          />
        ) : (
          <div className='m-auto'>เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}
