/* eslint-disable max-classes-per-file */
import { Expose, Transform } from 'class-transformer'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { ImageType } from '@interfaces/ImageType'

class AgeVerifyApproverType {
  @Expose() avatarImgPath: string

  @Expose() displayName: string
}

export class AgeConfirmUserlType {
  @Expose() id: string

  @Expose() avatarImgPath: string

  @Expose() fullName: string

  @Expose() username: string

  @Expose() ageVerify: AgeVerifyEnum

  @Expose() email: string

  @Expose() phoneNumber: string

  @Expose() birthDay: string

  @Expose() displayName: string

  @Expose() ageVerifyRejectedReason: string

  @Expose() ageVerifyRejectedAt: string

  @Expose() ageVerifyApprovedAt: string

  @Expose()
  @Transform(
    ({ value }) => ({
      url: value,
      name: typeof value === 'string' ? value.split('/').pop() : '',
    }),
    {
      toClassOnly: true,
    }
  )
  idCardImgPath: ImageType

  @Expose() ageVerifyApprover: AgeVerifyApproverType
}
