import { CrossIcon } from '@components/Icons'
import { ExportJobStatusEnum } from '@interfaces/exportFile/ExportJobStatusEnum'
import { StatusProps } from './interface'

export function Status({
  id,
  status,
  presignedPath,
  onCancelExportJob,
}: StatusProps) {
  if (status === ExportJobStatusEnum.PROCESSING)
    return (
      <div className='grid grid-cols-[1fr_18px] items-center gap-x-[10px]'>
        <span className='text-[12px] text-secondary text-right font-light'>
          Inprogress
        </span>
        <button
          className='bg-secondary-100 rounded-full p-[2px]'
          type='button'
          onClick={() => onCancelExportJob(id)}
        >
          <CrossIcon className='text-white' width='14' height='14' />
        </button>
      </div>
    )

  if (status === ExportJobStatusEnum.CANCELED)
    return (
      <div className='text-[12px] text-secondary-100 text-right font-light'>
        Canceled
      </div>
    )

  if (status === ExportJobStatusEnum.FAILED)
    return (
      <div className='text-[12px] text-secondary-100 text-right font-light'>
        failed
      </div>
    )

  return (
    <div className='text-right'>
      <a
        className='text-[12px] text-white font-bold bg-primary py-[6px] px-[10px] rounded-[8px]'
        target='_blank'
        href={presignedPath}
        rel='noreferrer'
      >
        Download
      </a>
    </div>
  )
}
