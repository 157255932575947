import { gql } from 'graphql-request'

export const POPUP_BANNER_LOGS = gql`
  query PopupBannerLogs(
    $limitPerPage: Int = 10
    $page: Int = 1
    $popupBannerId: Int!
  ) {
    popupBannerLogs(
      limitPerPage: $limitPerPage
      page: $page
      popupBannerId: $popupBannerId
    ) {
      data {
        id
        createdAt
        createdBy {
          avatarImgPath
          displayName
        }
        correctionDetail {
          title {
            old
            new
          }
          link {
            old
            new
          }
          screen {
            old
            new
          }
          platforms {
            old
            new
          }
          publishedType {
            old
            new
          }
          startPublishedAt {
            old
            new
          }
          endPublishedAt {
            old
            new
          }
          userTypes {
            old
            new
          }
        }
      }
      total
      page
    }
  }
`
