import { gql } from 'graphql-request'

export const GET_PROMOTION_CODE_FORM_DETAIL = gql`
  query Promotion($promotionId: Float!) {
    promotion(id: $promotionId) {
      lastRequest {
        newPromotion {
          id
          businessId
          name
          description
          userTerm
          startPublishedAt
          endPublishedAt
          publishedType
          type
          userType
          usageLimitPerUser
          isUsageLimitPerUser
          usageLimit
          isUsageLimit
          usageDay
          status
          users {
            id
          }
          usersFileName
          usersFilePath
          isFirstTopUp
          createdAt
          promotionCode {
            id
            type
            buyCoinConditionValue
            buyCoinCondition
            channels {
              name
              totalCount
            }
            paymentChannels
            benefitValue
            coin {
              id
            }
          }
          lastRequest {
            id
          }
        }
      }
    }
  }
`
