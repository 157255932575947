import cn from 'classnames'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { DateTimeInput } from '@components/DateTimeInput'
import { SelectButton } from '@components/SelectButton'
import { Button } from '@components/Button'
import { DurationEnum } from '@interfaces/DurationEnum'
import { ExportButton } from '@components/ExportButton'
import { SearchDateProps } from './interface'

const dateRangeOptions = [
  { text: 'เมื่อวาน', value: DurationEnum.DAILY },
  { text: '1 เดือน', value: DurationEnum.MONTHLY },
  { text: '1 ปี', value: DurationEnum.YEARLY },
]

export function SearchDate({
  className,
  searchParams,
  handleSearch,
  handleReset,
  handleDateRangeChange,
  handleStartDateChange,
  handleEndDateChange,
  handleExport,
}: SearchDateProps) {
  const permission = usePermissionAction('dashboard')

  return (
    <div className={cn('flex items-center justify-between', className)}>
      <div className='h-[63px] flex items-end gap-x-[10px]'>
        <SelectButton
          label='เลือกช่วงเวลา'
          mode='line'
          className='w-fit overflow-auto'
          options={dateRangeOptions}
          onChange={handleDateRangeChange}
          value={searchParams.selectedDate}
        />
        <DateTimeInput
          className='w-[180px]'
          placeholder='ตั้งแต่วันที่'
          selected={searchParams.startDate}
          maxDate={searchParams.endDate || new Date()}
          onChange={handleStartDateChange}
        />
        <DateTimeInput
          className='w-[180px] '
          placeholder='ถึงวันที่'
          selected={searchParams.endDate}
          maxDate={new Date()}
          minDate={searchParams.startDate}
          onChange={handleEndDateChange}
        />
      </div>

      <div className='h-[63px] flex items-end gap-x-[10px]'>
        <Button
          className='underline text-secondary text-[14px] w-full'
          onClick={handleReset}
        >
          ล้างค่า
        </Button>
        <Button
          type='submit'
          className='bg-secondary text-white text-[14px]'
          onClick={handleSearch}
        >
          ค้นหา
        </Button>
        <ExportButton
          className={cn({
            hidden: !permission.download,
          })}
          handleClick={handleExport}
        />
      </div>
    </div>
  )
}
