import cn from 'classnames'
import { DateTime, Duration } from 'luxon'

import { ReaderDetailByUserType } from '@models/dashboard/ReaderDetailByUserType'
import { UserReaderCoinDetailType } from '@models/dashboard/UserReaderCoinDetailType'
import { SearchType } from './ByUser/interface'

function ByUserTopSection({
  readerDetail,
  readerCoinDetail,
  queryParam,
}: {
  readerDetail: ReaderDetailByUserType
  readerCoinDetail: UserReaderCoinDetailType
  queryParam: SearchType
}) {
  return (
    <>
      <div className='flex items-center justify-between divide-x pb-[20px] border-b-[1px]'>
        <div className='flex items-center gap-x-[20px] pr-[30px]'>
          {readerDetail.user.avatarImgPath ? (
            <img
              className='w-[90px] h-[90px] rounded-full'
              src={readerDetail.user.avatarImgPath}
              alt=''
            />
          ) : (
            <div className='w-[90px] h-[90px] shrink-0 rounded-full bg-gray' />
          )}
          <div className='flex flex-col'>
            <span className='font-bold text-secondary break-word line-clamp-1'>
              {readerDetail.user.username}
            </span>
            <span className='text-[14px] font-light text-secondary-100'>
              ID : {readerDetail.user.id}
            </span>
          </div>
        </div>
        <div className='flex divide-x'>
          <div className='flex flex-col px-[30px] min-w-[170px]'>
            <span className='text-[14px] font-light text-secondary-100'>
              นามปากกาทั้งหมด
            </span>
            <span className='font-bold text-secondary'>
              {readerDetail.user.penNameCount.toLocaleString()}
            </span>
          </div>
          <div className='flex flex-col px-[30px] min-w-[170px]'>
            <span className='text-[14px] font-light text-secondary-100'>
              เวลารวมที่ใช้งาน
            </span>
            <span className='font-bold text-secondary'>
              {Duration.fromMillis(readerDetail.summaryTime).toFormat(
                "hh':' mm'hr.'"
              )}
            </span>
          </div>
          <div className='flex flex-col px-[30px] min-w-[170px]'>
            <span className='text-[14px] font-light text-secondary-100'>
              สมัครสมาชิกเมื่อ
            </span>
            <span className='font-bold text-secondary'>
              {DateTime.fromISO(readerDetail.user.createdAt).toFormat(
                'dd LLL yyyy',
                {
                  locale: 'th-Th',
                }
              )}
            </span>
          </div>
          <div className='flex flex-col px-[30px] min-w-[260px]'>
            <span className='text-[14px] font-light text-secondary-100'>
              ช่วงเวลา
            </span>
            <span className='font-bold text-secondary'>
              {`${queryParam.startDate.toFormat('dd LLL yyyy', {
                locale: 'th-Th',
              })} - ${queryParam.endDate.toFormat('dd LLL yyyy', {
                locale: 'th-Th',
              })}`}
            </span>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex text-[14px] w-full font-light text-secondary-100 py-[30px] mb-[30px] border-b-[1px]'
        )}
      >
        <div className='flex w-full gap-x-[40px] items-end'>
          <div className='flex flex-col gap-y-[12px]'>
            <span>จำนวนเหรียญที่ซื้อไป</span>
            <div>
              <span className='font-bold text-base'>
                {readerCoinDetail.totalBuyPaidCoin.toLocaleString()}
              </span>{' '}
              เหรียญทอง
              <span className='ml-[19px] font-bold text-base'>
                {readerCoinDetail.totalBuyFreeCoin.toLocaleString()}
              </span>{' '}
              เหรียญเงิน
            </div>
          </div>
          <div className='flex-1 flex flex-col gap-y-[12px]'>
            <span>จำนวนเหรียญที่ใช้ไป</span>
            <div>
              <span className='font-bold text-base'>
                {readerCoinDetail.totalUsePaidCoin.toLocaleString()}
              </span>{' '}
              เหรียญทอง
              <span className='ml-[19px] font-bold text-base'>
                {readerCoinDetail.totalUseFreeCoin.toLocaleString()}
              </span>{' '}
              เหรียญเงิน
            </div>
          </div>
          <div className='flex flex-col gap-y-[3px]'>
            <span>จำนวนเหรียญคงเหลือ</span>
            <div>
              <span className='font-bold text-2xl text-[#ffb033]'>
                {readerCoinDetail.currentPaidCoin.toLocaleString()}
              </span>{' '}
              เหรียญทอง
              <span className='ml-[19px] font-bold text-2xl text-secondary'>
                {readerCoinDetail.currentFreeCoin.toLocaleString()}
              </span>{' '}
              เหรียญเงิน
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ByUserTopSection
