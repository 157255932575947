import React, { useState, useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'

import { usePagination } from '@hooks/usePagination'
import { useClient } from '@hooks/useClient'
import { ChallengeUser } from '@models/user-mission/ChallengeUser'
import { ColumnType, SortByType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { UserMissionTableProps, WithUserMissionTableProps } from './interface'

export function withUserMissionTable(
  Component: React.FC<UserMissionTableProps>
) {
  function WithUserMissionTable({
    userMissionType,
  }: WithUserMissionTableProps) {
    const alert = useAlert()
    const client = useClient()
    const queryClient = useQueryClient()
    const { id } = useParams()
    const [searchKeyword, setSearchKeyword] = useState('')
    const [searchParams, setSearchParams] = useState({
      orderBy: '',
      orderKey: '',
    })
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    const { data, isLoading } = useQuery(
      ['challenge-level-user', searchParams, searchKeyword, page, id],
      () =>
        client?.userMissionClient.getChallengeLevelUser({
          ...searchParams,
          challengeId: Number(id),
          searchKeyword,
          page,
          perpage,
        }),
      {
        enabled: !!id,
        onSuccess: response => {
          if (response) {
            handleTotalChange(response.total)
          }
        },
      }
    )

    const columns: ColumnType<ChallengeUser>[] = [
      {
        column: 'User ID',
        accessor: 'userId',
        cell: ({ value }) => <div>{value}</div>,
        isSortable: true,
        cellClassName: 'w-[150px] min-w-[150px]',
        sortKey: 'USER_ID',
      },
      {
        column: 'ผู้ใช้',
        accessor: 'username',
        cell: ({ value }) => (
          <div className='line-clamp-1 break-all pr-[15px]'>{value}</div>
        ),
        cellClassName: 'w-full',
        isSortable: true,
        sortKey: 'NAME',
      },
      {
        column: data?.data[0]?.tableHeader || 'ยอดวิว',
        accessor: 'current',
        cell: ({ value }) => <div>{value}</div>,
        isSortable: true,
        cellClassName: 'w-[200px] min-w-[200px]',
        sortKey: 'CURRENT',
      },
      {
        column: 'Level',
        accessor: 'level',
        cell: ({ value }) => <div>{value}</div>,
        isSortable: true,
        cellClassName: 'w-[200px] min-w-[200px]',
        sortKey: 'LEVEL',
      },
      {
        column: 'Action',
        actionCell: ({ obj }) => (
          <Link to={`/dashboard/reader?tab=byUser&userId=${obj.userId}`}>
            <button
              type='button'
              className='h-[30px] px-[4px] border border-gray rounded-[8px] bg-white'
            >
              <EyeIcon width='18' height='18' className='text-secondary-100' />
            </button>
          </Link>
        ),
        isAction: true,
        cellClassName: 'w-[30px] py-[7px]',
      },
    ]

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSearchParams(prev => ({ ...prev, orderBy: order, orderKey: key }))
    }, [])

    async function handleSearch(text: string) {
      setSearchKeyword(text)
      setSearchParams({ orderBy: '', orderKey: '' })
    }

    async function handleResetSearch() {
      setSearchKeyword('')
      setSearchParams({ orderBy: '', orderKey: '' })
    }

    async function exportReport() {
      try {
        await client?.userMissionClient.exportUserMissionDetail({
          challengeId: Number(id),
          challengeType: userMissionType,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      data: data?.data || [],
      columns,
      page,
      perpage,
      total,
      isLoading,
      handleSort,
      pageChange,
      handleSearch,
      handleResetSearch,
      exportReport,
    }

    return <Component {...componentProps} />
  }

  return WithUserMissionTable
}
