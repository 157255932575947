import { useState } from 'react'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'
import { useAlert } from '@hooks/useAlert'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { ManageSearchTermType } from '@models/searchManagement/ManageSearchTermType'
import { ManageSearchTermPageProps } from './interface'

export function withManageSearchTermPage(
  Component: React.FC<ManageSearchTermPageProps>
) {
  function WithManageSearchTermPage() {
    const client = useClient()
    const alert = useAlert()
    const { isAuthenticated } = useAuthentication()
    const [initialValues, setInitialValues] = useState<ManageSearchTermType>({
      banSearchTermList: [],
      recommendSearchTermList: [],
    })

    const { data, refetch } = useQuery(
      'search-keyword',
      () =>
        client?.searchManagementClient.searchKeyword().then(res => {
          const tt = {}
          setInitialValues(
            plainToInstance(ManageSearchTermType, {
              banKeywords: res.banKeywords,
              fiveWordRecommendKeyWord: res.fiveWordRecommendKeyWord,
            })
          )
          return res.lastUpdatedBy
        }),
      { enabled: isAuthenticated }
    )

    async function handleSubmit(form: ManageSearchTermType) {
      try {
        await client?.searchManagementClient.updateSearchKeyword(form)
        await refetch()
        alert.success('ดำเนินการสำเร็จ')
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ ได้สำเร็จ!')
      }
    }

    const pageProps = {
      initialValues,
      userFullName: data?.displayName,
      userImageUrl: data?.avatarImgPath,
      handleSubmit,
    }

    return <Component {...pageProps} />
  }

  return WithManageSearchTermPage
}
