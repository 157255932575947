import { UIEvent } from 'react'
import { SupporterType } from '@models/supporter/SupporterType'
import { ViewAllSponsorsModalProps } from './interface'
import { Box } from '../../../Box'

function ViewAllSponsorsModal({
  supporters,
  handleFetchNextPage,
}: ViewAllSponsorsModalProps) {
  return (
    <div className='w-[1108px] max-h-[85vh] p-[40px] flex flex-col'>
      <Box.Title className='text-2xl text-center mb-[20px]'>
        ดูผู้สนับสนุนทั้งหมด
      </Box.Title>

      <div
        className='grid grid-cols-10 gap-x-[20px] gap-y-[30px] p-5 overflow-auto'
        onScroll={handleFetchNextPage}
      >
        {supporters.map((item: SupporterType, index: number) => (
          <div className='flex flex-col items-center' key={item.id}>
            <p className='font-medium mb-[10px]'>#{index + 1}</p>
            <img
              className='w-[50px] h-[50px] rounded-full shrink-0'
              src={item.avatarImgPath || '/images/avatar.png'}
              alt='profile'
            />
            <p className='font-bold text-sm text-secondary mt-[10px] max-w-[60px] truncate'>
              {item.displayName}
            </p>
            <span className='font-light text-xs text-secondary-100 mt-[5px] max-w-[60px] truncate'>
              @{item.username}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export { ViewAllSponsorsModal }
