import { Expose, Transform } from 'class-transformer'

export class TrafficCategoryType {
  @Expose()
  @Transform(({ value }) => value || 'N/A')
  name: string

  @Expose({ name: 'total' })
  value: number
}
