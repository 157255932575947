import { Container } from '@components/Container'
import { PromotionChallengeForm } from '@features/promotionSetting/components/PromotionChallengeForm'
import { EditPromotionChallengePageProps } from './interface'

function EditPromotionChallengePage({
  initialValues,
  handleSubmit,
  activeCoinOptions,
  challengeCoinOptions,
}: EditPromotionChallengePageProps) {
  return (
    <Container>
      <PromotionChallengeForm
        title='แก้ไขโปรโมชั่น Challenge'
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        activeCoinOptions={activeCoinOptions}
        challengeCoinOptions={challengeCoinOptions}
      />
    </Container>
  )
}

export default EditPromotionChallengePage
