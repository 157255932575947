import React, { useMemo, useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { PaymentReportByUserSearchType, ReportByUserProps } from './interface'

const PER_PAGE = 15

const withReportByUser = (Component: React.FC<ReportByUserProps>) => {
  function WithReportByUser() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const location = useLocation()
    const [params] = useSearchParams()
    const alert = useAlert()
    const [searchParam, setSearchParam] =
      useState<PaymentReportByUserSearchType>({
        writerId: Number(params.get('writerId')) || undefined,
      })
    const [sortBy, setSortBy] = useState<SortByType>({
      key: params.get('sortKey') || 'transactionId',
      order: (params.get('sortType') as SortingType) || SortingType.DESC,
    })

    function handleSearch(form: PaymentReportByUserSearchType, text: string) {
      setSearchParam(form)
      navigate({
        pathname: location.pathname,
        search: `?tab=BY_USER&writerId=${form.writerId}&searchText=${text}`,
      })
    }
    function handleReset() {
      setSearchParam({
        writerId: undefined,
      })
      navigate({
        pathname: location.pathname,
        search: `?tab=BY_USER`,
      })
    }

    const {
      data: paymentsReports,
      isLoading,
      isFetchingNextPage,
      fetchNextPage,
    } = useInfiniteQuery(
      ['payment-report', 'by-user', searchParam, sortBy],
      ({ pageParam = 1 }) =>
        client?.saleClient.getPaymentsReports({
          writerId: searchParam.writerId,
          page: pageParam,
          sort: sortBy,
          limitPerPage: PER_PAGE,
        }),

      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleSort({ key, order }: SortByType) {
      setSortBy(prev => ({ ...prev, key, order }))
      let search = '?tab=BY_USER'
      if (searchParam.writerId) {
        search += `&writerId=${searchParam.writerId}`
      }
      search += `&sortKey=${key}&sortType=${order}`

      navigate({
        pathname: location.pathname,
        search,
      })
    }

    const data = useMemo(
      () => paymentsReports?.pages?.flatMap(page => page.data) ?? [],
      [paymentsReports]
    )

    async function handleExport() {
      try {
        await client?.saleClient.exportPaymentReports({
          writerId: searchParam.writerId,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      handleSearch,
      handleExport,
      initialValues: searchParam,
      total: paymentsReports?.pages[0].total || 0,
      amount: paymentsReports?.pages[0].totalPaid || 0,
      data,
      sortBy,
      isLoading,
      handleSortChange: handleSort,
      handleWaypointEnter: handleFetchNextPage,
      handleReset,
    }
    return <Component {...newProps} />
  }

  return WithReportByUser
}

export default withReportByUser
