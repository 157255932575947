import { Formik, Form } from 'formik'
import cn from 'classnames'

import { BookEnum } from '@interfaces/BookEnum'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Button } from '@components/Button'
import { MultiSelectField } from '@components/forms/MultiSelectField'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { SearchWriterField } from '@features/dashboard/components/SearchWriterField'
import { ExportButton } from '@components/ExportButton'
import { SalesReportSearchFormProps } from './interface'

export function SalesReportSearchForm({
  initialValues,
  handleSubmit,
  categories,
  handleExport,
  resetHandler,
}: SalesReportSearchFormProps) {
  const permission = usePermissionAction('dashboard')
  const bookType = [
    { value: BookEnum.NOVEL, text: 'นิยาย' },
    { value: BookEnum.MANGA, text: 'การ์ตูน' },
  ]

  const timeRangeOption = [
    { value: TimeRangeEnum.DAY, text: '24 ชั่วโมง' },
    { value: TimeRangeEnum.WEEK, text: '7 วัน' },
    { value: TimeRangeEnum.MONTH, text: '1 เดือน' },
    { value: TimeRangeEnum.THREE_MONTH, text: '3 เดือน' },
    { value: TimeRangeEnum.SIX_MONTH, text: '6 เดือน' },
    { value: TimeRangeEnum.YEAR, text: '1 ปี' },
  ]
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue, resetForm }) => (
          <Form>
            <div className='flex flex-col gap-y-[20px] mb-[30px]'>
              <div className='grid grid-cols-3 gap-x-[20px] '>
                <SearchWriterField
                  name='writer'
                  placeholder='ค้นหาด้วยชื่อนักเขียน, สำนักพิมพ์ หรือ ID'
                  inputClassName='font-[300] bg-blue-2 rounded-[20px] placeholder:text-secondary-100'
                  className='mt-[22px]'
                  resetHandler={() => {
                    resetForm()
                    resetHandler()
                  }}
                />

                <MultiSelectField
                  name='bookType'
                  label='ลักษณะงานเขียน'
                  placeholder='ลักษณะงานเขียน'
                  options={bookType}
                />
                <MultiSelectField
                  name='categoryIds'
                  label='หมวดหมู่'
                  placeholder='หมวดหมู่'
                  options={categories}
                />
              </div>
              <div className='flex justify-between items-end '>
                <div className='flex items-end gap-x-[10px]'>
                  <SelectButtonField
                    name='timeRange'
                    options={timeRangeOption}
                    label='เลือกช่วงเวลา'
                    mode='line'
                    className='w-fit overflow-auto'
                    onChange={() => {
                      if (values.startAt) {
                        setFieldValue('startAt', undefined)
                      }
                      if (values.endAt) {
                        setFieldValue('endAt', undefined)
                      }
                    }}
                  />
                  <DateTimeInputField
                    className='w-[145px]'
                    name='startAt'
                    placeholder='ตั้งแต่วันที่'
                    onChange={value => {
                      setFieldValue('startAt', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    maxDate={values.endAt || new Date()}
                  />
                  <DateTimeInputField
                    className='w-[145px] '
                    name='endAt'
                    placeholder='ถึงวันที่'
                    onChange={value => {
                      setFieldValue('endAt', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    minDate={values.startAt}
                    maxDate={new Date()}
                  />
                </div>
                <div className='flex font-mitr justify-end space-x-[6px]'>
                  <Button
                    type='button'
                    className='text-secondary underline whitespace-pre text-sm'
                    onClick={() => {
                      resetForm()
                      resetHandler()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white '
                    disabled={isSubmitting}
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                  <ExportButton
                    className={cn({
                      hidden: !permission.download,
                    })}
                    handleClick={handleExport}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
