import { gql } from 'graphql-request'

export const GET_ALL_HIGHLIGH_BANNER = gql`
  query GetAllHighlightBanner($position: HighlightBannerPosition) {
    getAllHighlightBanner(position: $position) {
      id
      bannerName
      runningNo
      bannerType
      status
      isActive
      publishedFrom
      publishedTo
      position
      posterBanner {
        id
        runningNo
        imgPath
        description
        isOnWeb
        isOnIos
        isOnAndroid
        linkWeb
        linkIos
        linkAndroid
        isActive
      }
      bookBanner {
        id
        bookId
        runningNo
        isOnWeb
        isOnIos
        isOnAndroid
        linkWeb
        linkIos
        linkAndroid
        isActive
        book {
          id
          coverImgPath
        }
      }
    }
  }
`

export const CREATE_HIGHLIGHT_GROUP_BANNER = gql`
  mutation Mutation($createHighlightGroupInput: CreateHighlightGroupInput!) {
    createHighlightBanner(
      createHighlightGroupInput: $createHighlightGroupInput
    ) {
      id
    }
  }
`

export const UPDATE_HIGHLIGHT_BANNER = gql`
  mutation Mutation($updateHighlightGroupInput: UpdateHighlightGroupInput!) {
    updateHighlightBanner(
      updateHighlightGroupInput: $updateHighlightGroupInput
    ) {
      id
      bannerName
      runningNo
      bannerType
      status
      isActive
    }
  }
`

export const UPDATE_ORDER_HIGHLIGHT_BANNER = gql`
  mutation Mutation(
    $runningNo: Float!
    $updateHighlightGroupRunningNoId: Int!
  ) {
    updateHighlightGroupRunningNo(
      runningNo: $runningNo
      id: $updateHighlightGroupRunningNoId
    ) {
      id
      bannerName
      runningNo
      bannerType
      status
      isActive
      publishedFrom
      publishedTo
      posterBanner {
        id
        runningNo
        imgPath
        description
        isOnWeb
        isOnIos
        isOnAndroid
        linkWeb
        linkIos
        linkAndroid
        isActive
      }
      bookBanner {
        id
        bookId
        runningNo
        isOnWeb
        isOnIos
        isOnAndroid
        linkWeb
        linkIos
        linkAndroid
        isActive
        book {
          id
          coverImgPath
        }
      }
    }
  }
`

export const PUBLISH_HIGHLIGHT_BANNER = gql`
  mutation PublishHighlightGroups($ids: [Int!]!) {
    publishHighlightGroups(ids: $ids) {
      id
      status
    }
  }
`

export const UNPUBLISH_HIGHLIGHT_BANNER = gql`
  mutation UnPublishHighlightGroups($ids: [Int!]!) {
    unPublishHighlightGroups(ids: $ids) {
      id
      status
    }
  }
`

export const DELETE_HIGHLIGHT_BANNER = gql`
  mutation RemoveHighlightBanner($removeHighlightBannerId: Int!) {
    removeHighlightBanner(id: $removeHighlightBannerId) {
      id
    }
  }
`

export const UPDATE_HIGHLIGHT_GROUP_BANNER = gql`
  mutation UpdateHighlightBanner(
    $updateHighlightGroupInput: UpdateHighlightGroupInput!
  ) {
    updateHighlightBanner(
      updateHighlightGroupInput: $updateHighlightGroupInput
    ) {
      id
    }
  }
`
