import { gql } from 'graphql-request'

export const GET_PROMOTION_REQUEST_BY_PROMOTION_ID = gql`
  query PromotionRequestByPromotionId($promotionId: Float!) {
    promotionRequestByPromotionId(promotionId: $promotionId) {
      id
      createdAt
    }
  }
`
