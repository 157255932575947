import { withPermissionGuard } from '@libs/permission'
import { StickerManagementEditPage } from './StickerManagementEditPage'
import { withStickerManagementEditPage } from './withStickerManagementEditPage'

const ConnectedStickerManagementEditPage = withPermissionGuard(
  withStickerManagementEditPage(StickerManagementEditPage),
  'chapterBook',
  'edit'
)

export { ConnectedStickerManagementEditPage as StickerManagementEditPage }
