import { Expose, Type } from 'class-transformer'
import { PromotionSettingType } from './PromotionSettingType'

export class PromotionSettingListType {
  @Expose()
  @Type(() => PromotionSettingType)
  data: PromotionSettingType[]

  @Expose() total: number

  @Expose() page: number
}
