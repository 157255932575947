import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { CategoryParam, OrderByEnum } from '@interfaces/dashboard/CategoryParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { CategoryProps, WithCategoryProps } from './interface'

export function withCategory(Component: React.FC<CategoryProps>) {
  function WithCategory({ searchParam }: WithCategoryProps) {
    const client = useClient()
    const [categoryParam, setCategoryParam] = useState<CategoryParam>({
      orderBy: OrderByEnum.TOTAL_SEARCH,
      orderDirection: SortingType.DESC,
    })

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        ['search-result-by-category', searchParam, categoryParam],
        async ({ pageParam = 1 }) => {
          const res = await client?.dashboardClient.searchResultByCategory({
            ...searchParam,
            ...categoryParam,
            page: pageParam,
          })

          return res
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setCategoryParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      isLoading,
      handleSortChange,
      handleWaypointEnter,
    }

    return <Component {...componentProps} />
  }

  return WithCategory
}
