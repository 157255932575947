import { SelectButtonLine } from '@components/SelectButtonLine'
import { BanTabsProps } from './interface'

export function BanTabs({
  tabs,
  activeBanTab,
  handleActiveTabChange,
}: BanTabsProps) {
  return (
    <SelectButtonLine
      options={tabs}
      value={activeBanTab}
      onChange={handleActiveTabChange}
      className='gap-y-[15px] my-[30px]'
    />
  )
}
