import { useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { DropResult } from 'react-beautiful-dnd'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { CategoryManageType } from '@models/chapterBook/CategoryManageType'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { queryClient } from '@client/init'
import { CategoryEditType, CategoryManagePageProps } from './interface'

function reorder(
  list: CategoryManageType[],
  startIndex: number,
  endIndex: number
) {
  const result = cloneDeep(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export function withCategoryManagePage(
  Component: React.FC<CategoryManagePageProps>
) {
  function WithCategoryManagePage() {
    const client = useClient()
    const [editCategoryList, setEditCategoryList] = useState<
      CategoryManageType[]
    >([])

    const loadingModal = useModal({ modal: 'loading' }, true)
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const { data = [], isLoading } = useQuery('categories', () =>
      client?.categoryClient.allCategories()
    )

    function onEditName(value: CategoryManageType) {
      const index = editCategoryList.findIndex(row => row.id === value.id)
      if (index === -1) {
        setEditCategoryList(prev => prev.concat(value))
      }
    }

    function handleDragItem(
      result: DropResult,
      value: CategoryManageType[],
      setFieldValue: (field: string, value: any) => void
    ) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      const items = reorder(value, source.index, destination.index)
      onEditName(items[destination.index])
      setFieldValue('categories', items)
    }

    function onSubmit({ categories }: { categories: CategoryManageType[] }) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการบันทึกประเภทหนังสือหรือไม่? หลังจากบันทึกแล้ว
              จะไม่สามารถลบประเภทหนังสือได้
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            loadingModal.show()

            const editCategories = categories.reduce((res, cur, index) => {
              if (cur.id < 0) {
                return res.concat({
                  name: cur.name,
                  runningNo: index + 1,
                  isManga: cur.isManga,
                  isNovel: cur.isNovel,
                })
              }

              const editIndex = editCategoryList.findIndex(
                editCate => editCate.id === cur.id
              )

              if (editIndex !== -1) {
                const newData: CategoryEditType = { id: cur.id, name: cur.name }

                if (cur.id !== data[index].id) {
                  newData.runningNo = index + 1
                }

                if (editCategoryList[editIndex].isManga !== cur.isManga) {
                  newData.isManga = cur.isManga
                }

                if (editCategoryList[editIndex].isNovel !== cur.isNovel) {
                  newData.isNovel = cur.isNovel
                }

                return res.concat(newData)
              }

              return res
            }, [] as CategoryEditType[])

            const editCase = editCategories.every(row => row.id)
            const createCase = editCategories.every(row => !row.id)

            const res = await client?.categoryClient.createOrUpdateCategories(
              editCategories
            )
            queryClient.setQueryData('categories', () => res)
            alert.success(
              editCase
                ? 'แก้ไขประเภทหนังสือสำเร็จ'
                : createCase
                ? 'เพิ่มประเภทหนังสือสำเร็จ'
                : 'เพิ่มและแก้ไขประเภทหนังสือสำเร็จ'
            )
          } catch (e) {
            alert.error('เกิดข้อผิดพลาดในการแก้ไขประเภทหนังสือ')
          } finally {
            loadingModal.hide()
            confirmModal.hide()
            setEditCategoryList([])
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const newProps = {
      data,
      handleDragItem,
      onEditName,
      onSubmit,
    }
    if (!isLoading) {
      return <Component {...newProps} />
    }
    return <></>
  }

  return WithCategoryManagePage
}
