import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_TITLE = gql`
  query GetSearchResultByTitle(
    $dateRange: DatePicker
    $interval: SearchPastInterval
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByTitleSort
    $orderDirection: OrderDirection
  ) {
    searchResultByTitle(
      dateRange: $dateRange
      interval: $interval
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        bookId
        title
        writer
        penName
        totalSearch
      }
      page
    }
  }
`
