import { gql } from 'graphql-request'

export const GET_BOOK_ADD_TO_SHELF = gql`
  query GetBookAddToShelf(
    $page: Int
    $userId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: BookOrderBy
    $limitPerPage: Int
  ) {
    getBookAddToShelf(
      page: $page
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      limitPerPage: $limitPerPage
    ) {
      page
      total
      data {
        coverImgPath
        id
        title
        penNameId
        firstPenName
        chapterCount
        viewCount
        likeCount
        chapterReadCount
        bookType
      }
    }
  }
`
