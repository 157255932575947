import { Outlet } from 'react-router-dom'
import { EbookPage } from './pages/EbookPage'

export const ebookRoutes = [
  {
    path: 'ebook',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <EbookPage />,
      },
    ],
  },
]
