import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { StoryPageProps } from './interface'

export function withStoryPage(Component: React.FC<StoryPageProps>) {
  function WithStoryPage() {
    const client = useClient()
    const { id } = useParams()
    const { isAuthenticated } = useAuthentication()
    const { data, isLoading } = useQuery(
      ['story-cover', id],
      () => client?.storyClient.getStoryDetail(Number(id)),
      {
        enabled: !!id && isAuthenticated,
      }
    )

    const newProps = {
      data,
      isLoading,
      bookId: id,
    }

    return <Component {...newProps} />
  }

  return WithStoryPage
}
