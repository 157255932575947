import { ChartOptionType } from '@interfaces/ChartOptionType'
import React, { useState, useEffect } from 'react'

import { PieChartProps, WithPieChartProps } from './interface'

const initialOption = {
  title: {
    text: '',
    left: 'left',
    textStyle: {
      color: '#0c1135',
      fontFamily: 'Sarabun',
      fontWeight: 'bold',
      fontSize: 24,
    },
  },
  tooltip: {
    trigger: 'item',
    formatter: (params: any) => {
      const { marker, seriesName, name, value } = params
      return `<p style='margin-bottom: 10px'>${marker} ${seriesName}</p><p>${name} : <strong>${value}</strong></p>`
    },
  },
  legend: {
    orient: 'vertical',
    top: 'middle',
    left: 0,
    itemWidth: 24,
    itemHeight: 24,
    itemGap: 18,
    height: 250,
    icon: 'rect',
    textStyle: {
      fontFamily: 'Sarabun',
      fontSize: 16,
      fontWeight: 300,
      color: '#858baf',
      padding: [4, 10],
    },
    formatter: (name: string) => name,
    data: [],
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: 95,
      left: 'left',
      center: [100, '50%'],
      label: { show: false },
      animationType: 'scale',
      animationEasing: 'elasticOut',
      data: [],
    },
  ],
}

const colors = [
  '#3f54d9',
  '#79beff',
  '#3fd986',
  '#efdf28',
  '#ffb033',
  '#eeeeee',
  '#f1f9ff',
  '#0d1135',
  '#3c405d',
  '#6d7085',
]

export function withPieChart(Component: React.FC<PieChartProps>) {
  function WithPieChart({
    data,
    title,
    legendStyle = 'left',
    colorMaster,
    ...props
  }: WithPieChartProps) {
    const [option, setOption] = useState(initialOption)

    useEffect(() => {
      setOption({
        ...initialOption,
        title: { ...initialOption.title, text: title || '' },
        legend: {
          ...initialOption.legend,
          left: legendStyle === 'left' ? 250 : 0,
          top: legendStyle === 'left' ? 'middle' : 'bottom',
          formatter: (name: string) => {
            const sumValue = data.reduce(
              (prev: number, cur: ChartOptionType) => prev + cur.value,
              0
            )
            const { value } = data.filter((row: any) => row.name === name)[0]
            if (sumValue) {
              return `${((value / sumValue) * 100).toFixed(0)}%   ${name}`
            }
            return `${(100 / data.length).toFixed(0)}%   ${name}`
          },
          data: data.map((val: any, index: number) => ({
            ...val,
            itemStyle: {
              color:
                !!colorMaster && colorMaster.length > 0
                  ? colorMaster[index]
                  : colors[index],
            },
          })),
        },
        series: [
          {
            ...initialOption.series[0],
            name: title || '',
            center: legendStyle === 'left' ? [100, '50%'] : [100, 150],
            data: data.map((val: any, index: number) => ({
              ...val,
              itemStyle: {
                color:
                  !!colorMaster && colorMaster.length > 0
                    ? colorMaster[index]
                    : colors[index],
              },
            })),
          },
        ],
      })
    }, [data, title, legendStyle, colorMaster])

    const newProps = { ...props, option }

    return <Component {...newProps} />
  }

  return WithPieChart
}
