import * as yup from 'yup'
import { Box } from '@components/Box'
import { Button } from '@components/Button'
import { InputField } from '@components/forms/InputField'
import { Form, Formik } from 'formik'
import { UploadCoinButtonField } from '../UploadCoinButtonField'
import { UploadCoinField } from '../UploadCoinField'
import { CoinManagementFormProps } from './interface'

const schema = yup.object().shape({
  name: yup.string().required(),
  upload: yup.object().shape({
    url: yup.string().required(),
  }),
})

export function CoinManagementForm({
  initialValues,
  onSubmit,
}: CoinManagementFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ resetForm, dirty, isSubmitting }) => (
        <Form className='w-full'>
          <Box>
            <div className='w-full flex flex-row'>
              <div className='mr-[30px] w-[200px]'>
                <UploadCoinField />
              </div>
              <div className='w-full flex flex-col justify-between'>
                <InputField
                  name='name'
                  placeholder='ชื่อ'
                  label='ชื่อเหรียญ'
                  inputClassName='font-[300] bg-white text-black-970 w-full rounded-[20px] '
                  className='w-full'
                />
                <UploadCoinButtonField />
              </div>
            </div>
          </Box>
          <div className='flex gap-x-[20px] font-mitr justify-center items-center text-[14px] font-medium mt-[30px]'>
            <Button
              type='button'
              className='w-[100px] border-[1px] border-secondary-100 text-secondary-100'
              onClick={() => resetForm()}
            >
              ยกเลิก
            </Button>
            <Button
              className='text-white bg-primary w-[100px]'
              type='submit'
              disabled={!dirty || isSubmitting}
            >
              บันทึก
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
