import { gql } from 'graphql-request'

export const CHARACTER_DONATION_BY_STORY = gql`
  query CharacterDonationByStory(
    $dateRange: DatePicker
    $orderBy: CharacterDonationByStorySort
    $orderDirection: OrderDirection
    $limitPerPage: Int
    $page: Int
  ) {
    characterDonationByStory(
      dateRange: $dateRange
      orderBy: $orderBy
      orderDirection: $orderDirection
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        rank
        bookId
        coverImgPath
        title
        bookType
        viewCount
        firstPenName
        chapterCount
        totalDonation
        likeCount
      }
      total
      page
    }
  }
`
