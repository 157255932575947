import { UploadSquareImage } from '@components/UploadSquareImage'
import { CropImageModal } from '@components/CropImageModal'
import { CropSquareImageProps } from './interface'

export function CropSquareImage({
  src,
  title,
  size,
  imgRef,
  uploadUrl,
  visible,
  loading,
  isCircle,
  hideModal,
  handleFileChange,
  cropImage,
  zoom,
  move,
}: CropSquareImageProps) {
  return (
    <>
      <UploadSquareImage
        src={src}
        size={size}
        title={title}
        isError={false}
        onChange={handleFileChange}
      />
      <CropImageModal
        title={title}
        imgRef={imgRef}
        uploadUrl={uploadUrl}
        visible={visible}
        loading={loading}
        isCircle={isCircle}
        hideModal={hideModal}
        cropImage={cropImage}
        zoom={zoom}
        move={move}
      />
    </>
  )
}
