import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'

import { columns } from './column'
import { CoinHistoryTableProps } from './interface'

export function CoinHistoryTable({
  data,
  isLoading,
  page,
  perpage,
  total,
  searchParam,
  handleSort,
  pageChange,
}: CoinHistoryTableProps) {
  return (
    <div>
      <div className='flex items-center mb-[10px]'>
        <span className='text-secondary-100 text-[12px] font-light'>
          {`ข้อมูลทั้งหมด : ${total.toLocaleString()}`}
        </span>
      </div>
      <div className='flex-1 overflow-x-auto pb-[20px]'>
        <Table
          data={data}
          columns={columns}
          onSort={handleSort}
          isLoading={isLoading}
          initialSort={{
            key: searchParam.sortKey,
            order: searchParam.sortOrder,
          }}
          className='w-[1750px]'
          headerClassName='border-x-[1px] border-y-[1px] border-gray text-secondary-100 text-[12px] font-bold'
        />
      </div>
      <div className='mt-[10px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
