import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByChapterType {
  @Expose() bookId: number

  @Expose() title: string

  @Expose() writer: string

  @Expose() penName: string

  @Expose() totalSearch: number

  @Expose() chapterName: string
}

@Exclude()
export class SearchResultByChapterResponse {
  @Expose()
  @Type(() => SearchResultByChapterType)
  data: SearchResultByChapterType[]

  @Expose() page: number
}
