import { Formik, Form } from 'formik'

import { RoleManagement } from '@features/admin/components/RoleManagement'
import { EditRolePageProps } from './interface'

export function EditRolePage({ data, updateRoleHandler }: EditRolePageProps) {
  return (
    <RoleManagement
      title='แก้ไข Role'
      subTitle='ชื่อ Role'
      initialValues={data}
      onSubmit={updateRoleHandler}
    />
  )
}
