import cn from 'classnames'
import { Box } from '@components/Box'
import { Checkbox } from '@components/Checkbox'
import { Button } from '@components/Button'
import { CheckIcon } from '@components/Icons'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'

import { StatusSectionProps } from '../interface'

export function StatusSection({
  status,
  isWaitingReview,
  isNotified,
  bookReportId,
  handleUpdateBookReport,
}: StatusSectionProps) {
  return (
    <Box>
      <Box.Title className='font-bold text-secondary'>สถานะ</Box.Title>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row items-center gap-x-[40px]'>
          <Checkbox
            classNameLabel={cn('font-sarabun font-light', {
              'text-secondary': status !== 'SUCCESS',
              'text-primary': status === 'SUCCESS',
            })}
            label='รอการตรวจสอบ'
            checked={isWaitingReview}
            onChange={() => {}}
          />
          <div className='border-l-[1px] border-gray h-[24px]' />
          <Checkbox
            classNameLabel={cn('font-sarabun font-light', {
              'text-secondary': status !== 'SUCCESS',
              'text-primary': status === 'SUCCESS',
            })}
            label='แจ้งนักเขียนแล้ว'
            checked={isNotified}
            onChange={() => {
              if (status !== 'SUCCESS') {
                if (!isNotified) {
                  handleUpdateBookReport({
                    id: bookReportId,
                    status: ReportStatusEnum.REPORTED,
                  })
                } else {
                  handleUpdateBookReport({
                    id: bookReportId,
                    status: ReportStatusEnum.WAITING,
                  })
                }
              }
            }}
          />
        </div>
        <Button
          disabled={status === 'WAITING'}
          className='text-[14px] px-[30px] bg-primary text-white'
          onClick={() => {
            if (status !== 'SUCCESS') {
              handleUpdateBookReport({
                id: bookReportId,
                status: ReportStatusEnum.SUCCESS,
              })
            }
          }}
        >
          {status === 'SUCCESS' && (
            <CheckIcon className='w-[16px] h-[16px] mr-[10px]' />
          )}
          ดำเนินการสำเร็จ
        </Button>
      </div>
    </Box>
  )
}
