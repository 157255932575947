import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Container } from '@components/Container'
import { SelectButton } from '@components/SelectButton'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { AdminLayoutProps } from './interface'

export function AdminLayout({
  children,
  tab,
  adminTabOption,
  handleChangeAdmin,
}: AdminLayoutProps) {
  const permission = usePermissionAction('admin')
  return (
    <Container>
      <div className='flex items-center justify-between w-full pb-[28px] border-0]'>
        <h1 className='font-mitr text-[24px] text-secondary'>Admin</h1>
        <div
          className={cn(
            'flex font-mitr gap-x-[20px] justify-center items-center px-[10px]',
            {
              hidden: !permission.create,
            }
          )}
        >
          <Link to='/admin/add-admin'>
            <button
              type='button'
              className='flex font-mitr text-[14px] font-medium items-center rounded-[20px] h-[40px] w-[192px] justify-center disabled:opacity-30 text-white bg-secondary'
            >
              + เพิ่ม Admin
            </button>
          </Link>
          <Link to='/admin/add-role'>
            <button
              type='button'
              className='flex text-[14px] font-mitr font-medium items-center rounded-[20px] h-[40px] w-[192px] justify-center disabled:opacity-30 text-white bg-secondary'
            >
              + เพิ่ม Role
            </button>
          </Link>
        </div>
      </div>

      <SelectButton
        options={adminTabOption}
        value={tab}
        className='flex-wrap gap-y-[15px]'
        onChange={handleChangeAdmin}
      />

      {children}
    </Container>
  )
}
