import { withPermissionGuard } from '@libs/permission'
import { AgeConfirmationDetailPage } from './AgeConfirmationDetailPage'
import { withAgeConfirmationDetailPage } from './withAgeConfirmationDetailPage'

const ConnectedAgeConfirmationDetailPage = withPermissionGuard(
  withAgeConfirmationDetailPage(AgeConfirmationDetailPage),
  'user',
  'view'
)

export { ConnectedAgeConfirmationDetailPage as AgeConfirmationDetailPage }
