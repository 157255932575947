import { Expose, Type } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'

export class BookByUserType {
  @Expose() id: number

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() penName: {
    id: number
    firstPenName: string
  }

  @Expose() chapterCount: number

  @Expose({ name: 'totalSales' }) amountSpent: number

  @Expose() viewCount: number

  @Expose() sumCharacterDonate: number

  @Expose() bookType: BookEnum

  @Expose() purchaseCount: number
}

export class BookByUserResponse {
  @Expose()
  @Type(() => BookByUserType)
  data: BookByUserType[]

  @Expose() total: number

  @Expose() page: number
}
