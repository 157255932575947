import { withPermissionGuard } from '@libs/permission'
import { ReportListDetailPage } from './ReportListDetailPage'
import { withReportListDetailPage } from './withReportListDetailPage'

const ConnectedReportListDetailPage = withPermissionGuard(
  withReportListDetailPage(ReportListDetailPage),
  'chapterBook',
  'view'
)

export { ConnectedReportListDetailPage as ReportListDetailPage }
