import cn from 'classnames'
import { useRef } from 'react'
import { useField } from 'formik'

import { CheckIcon, CrossIcon, UploadCodeIcon } from '@components/Icons'
import { UploadFileProps } from './interface'

export function UploadFile({
  className,
  text,
  name,
  fileName,
  errorMsg,
  disabled,
  onChange,
}: UploadFileProps) {
  const [, , { setValue }] = useField(name)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <div
        className={cn(
          'px-[30px] py-[13px] border-2 rounded-[8px] grid grid-cols-[30px_250px_1fr] gap-x-[20px] items-center shadow',
          { 'border-red-74': !!errorMsg, 'border-primary': !errorMsg },
          className
        )}
      >
        <UploadCodeIcon className='text-secondary' width='30' height='30' />
        <div className='text-[14px] font-light'>{text}</div>
        <div className='pl-[20px] border-l border-gray flex justify-between items-center'>
          <button
            type='button'
            className={cn(
              'px-[20px] py-[9.5px] my-[5px] rounded-[8px] bg-primary w-[86px] text-[14px] text-white font-bold',
              {
                'cursor-not-allowed': disabled,
              }
            )}
            onClick={() => inputRef.current?.click()}
            disabled={disabled}
          >
            Browse
          </button>
          <input
            ref={inputRef}
            className='hidden'
            type='file'
            accept='.csv'
            onChange={event => {
              if (event.target.files?.length) {
                const [file] = event.target.files
                setValue(file)
                if (inputRef.current) inputRef.current.value = ''
                onChange()
              }
            }}
          />
          <div
            className={cn('text-[14px] flex items-center', {
              hidden: !fileName,
            })}
          >
            <div
              className={cn(
                'text-white rounded-full w-[18px] h-[18px] relative mr-[10px]',
                {
                  'bg-primary': !errorMsg,
                  'bg-red-74': !!errorMsg,
                }
              )}
            >
              <CheckIcon
                className={cn(
                  'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                  { hidden: !!errorMsg }
                )}
                width='12'
                height='12'
              />
              <CrossIcon
                className={cn(
                  'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                  { hidden: !errorMsg }
                )}
                width='15'
                height='15'
              />
            </div>
            <div className='font-bold text-secondary mr-[30px]'>{fileName}</div>
            <button
              className='underline text-primary font-bold'
              type='button'
              onClick={() => {
                setValue('')
                onChange()
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div
        className={cn('text-red-74 text-[12px] font-light mt-[10px]', {
          hidden: !errorMsg,
        })}
      >
        *{errorMsg}
      </div>
    </>
  )
}
