import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { TopWriterByViewTableProps } from './interface'
import { columns } from './column'

export function TopWriterByViewTable({
  data,
  isLoading,
  handleSort,
}: TopWriterByViewTableProps) {
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        Top writer by view
      </h1>

      <Table
        onSort={handleSort}
        isLoading={isLoading}
        data={data}
        columns={columns}
        initialSort={{ key: 'viewCount', order: SortingType.DESC }}
      />
    </div>
  )
}
