import { Link } from 'react-router-dom'

import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import PromotionSaleInfo from '@features/promotionSetting/components/PromotionSaleInfo'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { Loading } from '@components/Loading'
import { PromotionSaleList } from '@features/promotionSetting/components/PromotionSaleList'
import { PromotionSaleDetailPageProps } from './interface'
import { PromotionSaleApproveSection } from './components/PromotionSaleApproveSection'

export function PromotionSaleDetailPage({
  detail,
  isLoading,
  handleActivePromotion,
  handleDeletePromotion,
  handleEditPromotion,
}: PromotionSaleDetailPageProps) {
  const permission = usePermissionAction('promotionSetting')

  return (
    <Container>
      {!isLoading ? (
        detail ? (
          <>
            <Container.Title showBackBtn>
              <div className='flex items-center justify-between'>
                <div>{detail?.name || '-'}</div>
                <Link
                  to={`/promotion-setting/promotion-sale/${detail.id}/history`}
                >
                  <Button
                    type='button'
                    className='border border-secondary rounded-[20px]'
                  >
                    <span className='font-mitr font-medium text-sm'>
                      ดูประวัติการแก้ไขโปรโมชั่น
                    </span>
                  </Button>
                </Link>
              </div>
            </Container.Title>
            <Card>
              <PromotionSaleInfo
                status={detail.status}
                isShowActionMenu
                detail={detail}
                handleActivePromotion={
                  permission.edit &&
                  detail.isEditable &&
                  (detail.status === PromotionStatusEnum.STARTED ||
                    detail.status === PromotionStatusEnum.CLOSED) &&
                  new Date(detail.startPublishedAt!) <= new Date() &&
                  new Date(detail.endPublishedAt!) >= new Date()
                    ? handleActivePromotion
                    : undefined
                }
                // DESC: enabled edit button when lastRequest has no value (meaning no promotion request to be approved or rejected)
                handleEditPromotion={
                  permission.edit && detail.isEditable && !detail.lastRequest
                    ? handleEditPromotion
                    : undefined
                }
                handleDeletePromotion={
                  permission.delete &&
                  detail.status === PromotionStatusEnum.DRAFT
                    ? handleDeletePromotion
                    : undefined
                }
              />
              {/* DESC: show approval section when lastRequest has value (meaning there is promotion request to be approved or rejected) */}
              {permission.approve &&
                (detail.status === PromotionStatusEnum.PENDING ||
                  !!detail.lastRequest) && (
                  <PromotionSaleApproveSection
                    requestId={detail.lastRequest?.id}
                    promotionId={detail.id}
                    promotionName={detail.name || ''}
                    promotionDetail={detail.description}
                    startPromotion={new Date(detail.startPublishedAt!)}
                    endPromotion={new Date(detail.endPublishedAt!)}
                    createdAt={new Date(detail.createdAt)}
                    actionBy={
                      detail.lastRequest
                        ? {
                            imageUrl:
                              detail.lastRequest.createdBy.avatarImgPath,
                            displayName:
                              detail.lastRequest.createdBy.displayName,
                            actionAt: detail.lastRequest.createdAt,
                            actionType: detail.lastRequest.action,
                          }
                        : undefined
                    }
                  />
                )}
            </Card>
            <div className='flex space-x-[40px]'>
              <Card.TextHorizontal
                label='จำนวนยอดขายรวม'
                value={
                  detail.promotionSale.summary.totalSale.toLocaleString() || 0
                }
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label='จำนวนเรื่องที่ขายได้ในแคมเปญ'
                value={
                  detail.promotionSale.summary.totalBook.toLocaleString() || 0
                }
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label='จำนวนครั้งที่ขายได้ในแคมเปญ'
                value={
                  detail.promotionSale.summary.totalSell.toLocaleString() || 0
                }
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label='จำนวนเหรียญที่แจกไปแล้ว'
                value={
                  detail.promotionSale.summary.totalDeal.toLocaleString() || 0
                }
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label='จำนวนผู้ที่ได้รับรางวัล'
                value={
                  detail.promotionSale.summary.userCount.toLocaleString() || 0
                }
                valueColor='primary'
              />
            </div>
            <hr className='my-[30px] text-gray' />
            <PromotionSaleList promotionSaleId={detail.promotionSale.id} />
          </>
        ) : (
          <div>เกิดข้อผิดพลาด กรุณาลองใหม่ในภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}
