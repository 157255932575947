import { useState } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useTable } from '@hooks/useTable'
import { SortByType, SortingType } from '@components/Table/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { SearchType } from '@features/dashboard/pages/StoryPage/components/WriterSection/interface'
import { columns } from './components/column'
import {
  StoryHotNewSectionProps,
  WithStoryHotNewSectionProps,
} from './interface'

export function withStoryHotNewSection(
  Component: React.FC<StoryHotNewSectionProps>
) {
  function WithStoryHotNewSection({
    searchDateParams,
  }: WithStoryHotNewSectionProps) {
    const client = useClient()
    const [searchParams, setSearchParams] = useState<SearchType>({
      ...searchDateParams,
      bookType: null,
      categoryId: null,
      sortKey: 'rank',
      sortOrder: SortingType.ASC,
      limit: 10,
    })

    const { data, isLoading } = useQuery(
      ['story-dashboard-hot-new', searchParams, searchDateParams],
      () =>
        client?.dashboardClient.bookDashboardHotNew({
          ...searchParams,
          startDate: searchDateParams.startDate,
          endDate: searchDateParams.endDate,
        })
    )

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function bookTypeChange(bookType: BookEnum | null) {
      setSearchParams(prev => ({ ...prev, bookType }))
    }
    function categoryChange(categoryId: number) {
      setSearchParams(prev => ({ ...prev, categoryId: Number(categoryId) }))
    }

    const componentProps = {
      data: data?.data || [],
      handleSort,
      bookTypeChange,
      categoryChange,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithStoryHotNewSection
}
