import { Duration } from 'luxon'
import { OverviewAcceptProps, OverviewProps } from './interface'

export function withOverview(Component: React.FC<OverviewProps>) {
  function Overview({
    totalUser,
    totalPageView,
    avgSessionDuration,
    bounceRate,
    storageSize,
  }: OverviewAcceptProps) {
    const resultAvgSessionDuration = Duration.fromObject({
      second: avgSessionDuration,
    }).toFormat('hh:mm:ss')

    const data = [
      { name: 'จำนวนผู้ใช้', value: `${totalUser.toLocaleString()}` },
      { name: 'จำนวน Page View', value: `${totalPageView.toLocaleString()}` },
      { name: 'เวลาใช้งานเฉลี่ย', value: `${resultAvgSessionDuration}` },
      { name: 'Bounce rate', value: `${bounceRate.toFixed(2)}%` },
      { name: 'จำนวนข้อมูลที่เก็บ', value: storageSize },
    ]
    const componentProps = {
      data,
    }
    return <Component {...componentProps} />
  }

  return Overview
}
