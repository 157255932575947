import { Expose, Type } from 'class-transformer'
import { UserWritersType } from './UserWritersType'

export class UserWritersResponse {
  @Expose()
  @Type(() => UserWritersType)
  data: UserWritersType[]

  @Expose()
  total: number
}
