import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { PromotionCodeDetail } from '@features/promotionSetting/components/PromotioinCodeDetail'
import { CreatePromotionCode } from '@features/promotionSetting/components/PromotionCodeForm/components/CreatePromotionCode'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { UniqeCodeTypeEnum } from '@interfaces/promotionCode/UniqeCodeTypeEnum'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CreateCodePromotionCodePageProps } from './interface'

const validationSchema = Yup.object()
  .shape({
    coupons: Yup.array().when(['codeType', 'uniqeCodeType'], {
      is: (codeType: any, uniqeCodeType: any) => {
        return (
          codeType === CodeTypeEnum.UNIVERSAL ||
          uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE
        )
      },
      then: Yup.array().min(1),
    }),
    universalCodeInput: Yup.string().when('codeType', {
      is: (codeType: CodeTypeEnum) => codeType === CodeTypeEnum.UNIVERSAL,
      then: Yup.string().required('กรุณากรอก code'),
    }),
  })
  .test((values, context) => {
    if (values.universalCodeInputError) {
      return context.createError({
        path: 'universalCodeInput',
        message: values.universalCodeInputError,
      })
    }
    if (
      values.codeFileError &&
      values.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE
    ) {
      return context.createError({
        path: 'codeFile',
        message: values.codeFileError,
      })
    }
    if (
      values.codeType === CodeTypeEnum.UNIQE &&
      values.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE &&
      values.coupons!.length <= 0
    ) {
      return context.createError({
        path: 'codeFile',
        message: 'required',
      })
    }
    if (
      values.codeType === CodeTypeEnum.UNIQE &&
      values.uniqeCodeType === UniqeCodeTypeEnum.GENERATE_CODE &&
      !values.isGenerateCompleted
    ) {
      return context.createError({
        path: 'isGenerateCompleted',
        message: 'not completed',
      })
    }
    return true
  })

export function CreateCodePromotionCodePage({
  data,
  initialValues,
  handleSubmit,
  goBackHandle,
  isCreate,
  setIsCreate,
}: CreateCodePromotionCodePageProps) {
  return (
    <Container>
      <Container.Title
        showBackBtn
        onClickBackBtn={goBackHandle}
        className='line-clamp-1 break-word'
      >
        {data.promotionName}
      </Container.Title>
      <PromotionCodeDetail values={data}>
        {!isCreate && (
          <div className='flex pt-[20px] justify-center'>
            <Button
              onClick={() => setIsCreate(true)}
              className='text-white bg-primary px-[30px] text-[14px]'
            >
              สร้างโค้ด
            </Button>
          </div>
        )}
      </PromotionCodeDetail>
      {isCreate && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <CreatePromotionCode
                promotionCodesId={data.promotionCodeId!}
                generateCouponStatus={data.generateCouponStatus}
                channels={data.channelName || '-'}
                channelsInput={data.codeChannelInput}
              />
              <div className='flex justify-center gap-x-[20px] mt-[30px]'>
                <Button
                  onClick={goBackHandle}
                  className='border border-secondary-100 text-secondary-100 text-[14px] px-[30px]'
                >
                  ยกเลิก
                </Button>
                <Button
                  type='submit'
                  className='text-white bg-primary px-[30px] text-[14px]'
                  disabled={!isValid || isSubmitting}
                >
                  บันทึกและส่ง
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  )
}
