import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useRealtimeNotification } from '@hooks/notification/useRealtimeNotification'
import { NotificationType } from '@models/notification/NotificationType'
import { NotificationPopupProps, WithNotificationPopupProps } from './interface'

export function withNotificationPopup(
  Component: React.FC<NotificationPopupProps>
) {
  function WithNotificationPopup(props: WithNotificationPopupProps) {
    const navigate = useNavigate()
    const {
      data,
      hasNew,
      setHasNew,
      updateReadStatus,
      fetchNextData,
      updateBadge,
    } = useRealtimeNotification()

    async function handleClickNotification(notice: NotificationType) {
      if (!notice.read) {
        await updateReadStatus(notice.id)
      }
      navigate(notice.urlPath)
    }

    function goToAllNoti() {
      navigate('/notification')
    }

    const componentProps = {
      noticeList: data,
      hasNew,
      handleClickNotification,
      setHasNew,
      fetchNextData,
      updateBadge,
      goToAllNoti,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithNotificationPopup
}
