import { gql } from 'graphql-request'

export const GET_USER_PUNISHMENT_HISTORY = gql`
  query GetUserPunishmentHistory(
    $limitPerPage: Int
    $page: Int
    $userId: Int!
    $orderBy: UserPunishmentOrderBy
    $punishmentType: PunishmentEnum!
  ) {
    getUserPunishmentHistory(
      limitPerPage: $limitPerPage
      page: $page
      userId: $userId
      orderBy: $orderBy
      punishmentType: $punishmentType
    ) {
      data {
        id
        createdAt
        message
        operatedUser {
          id
          displayName
        }
        startPunishedAt
        endPunishedAt
        period
        punishmentTimes
      }
      page
      total
    }
  }
`
