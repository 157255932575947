import { gql } from 'graphql-request'

export const COINS = gql`
  query Coins(
    $limitPerPage: Int
    $page: Int
    $orderBy: FindAllCoinOrderBy
    $where: FindAllCoinWhere
  ) {
    coins(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      where: $where
    ) {
      data {
        id
        name
        imgPath
        amountCoin
        amountCoinPerValue
        valueUnitType
        valueUnitCoin {
          id
          name
          imgPath
        }
        isExpireByActivity
        isExpireByPaid
        expireWithinMonthsByActivity
        expireWithinMonthsByPaid
        visible
        editable
        status
      }
      page
      total
    }
  }
`
