import { gql } from 'graphql-request'

export const GET_STICKER = gql`
  query GetSticker($id: Int!) {
    sticker(id: $id) {
      id
      name
      stickerImgPath
    }
  }
`
