import cn from 'classnames'
import { ProgressBar } from '@components/ProgressBar'
import { LogCityChartProps } from './interface'

export function LogCityChart({
  data,
  topThree,
  maxValue,
  className,
}: LogCityChartProps) {
  return (
    <div
      className={cn(
        'grid grid-cols-1 gap-y-[10px] text-[14px] font-light',
        className
      )}
    >
      {data.map((item, i) => (
        <div key={i} className='flex gap-x-[10px] items-center'>
          <div className='w-[160px] text-secondary'>{item.name}</div>
          <div
            className={cn('w-[60px] text-right', {
              'text-primary': topThree.some(
                element => element.name === item.name
              ),
              'text-secondary-100': !topThree.some(
                element => element.name === item.name
              ),
            })}
          >
            <span>{item.value.toLocaleString()}</span>
          </div>
          <ProgressBar
            value={item.value}
            max={maxValue}
            className='flex-1'
            progressBarClassName={cn('rounded-full', {
              'bg-primary': topThree.some(
                element => element.name === item.name
              ),
              'bg-gray-400': !topThree.some(
                element => element.name === item.name
              ),
            })}
          />
        </div>
      ))}
    </div>
  )
}
