import { gql } from 'graphql-request'

export const GET_TOTAL_SALES_BY_USER = gql`
  query GetUserManagementTableCount(
    $searchText: String!
    $isIncludePenName: Boolean
  ) {
    getUserManagementTableCount(
      searchText: $searchText
      isIncludePenName: $isIncludePenName
    ) {
      allCount
      publisherCount
      writerCount
      readerCount
    }
  }
`
