import { ColumnType } from '@components/Table/interface'
import { UserPunishmentType } from '@models/user/UserPunishmentHistoryResponse'
import { DateTime } from 'luxon'

export const columns: ColumnType<UserPunishmentType>[] = [
  {
    column: 'ครั้งที่เตือน',
    accessor: 'punishmentTimes',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    isSortable: true,
    cellClassName: 'pr-[5px]',
  },
  {
    column: 'วันที่ส่งข้อความตักเตือน',
    accessor: 'createdAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
          locale: 'th-Th',
        })}
      </div>
    ),
    isSortable: true,
    cellClassName: 'pr-[5px]',
  },
  {
    column: 'ข้อความที่ส่ง',
    accessor: 'message',
    cell: ({ value }) => <p>{value}</p>,
    cellClassName: 'w-[550px] max-w-[550px] pr-[100px] py-[10px]',
    isSortable: false,
  },
  {
    column: 'ตักเตือนโดย',
    accessor: 'operatedUser.displayName',
    cell: ({ value }) => <p className='line-clamp-1'>{value}</p>,
    cellClassName: 'py-[10px]',
    isSortable: false,
  },
]
