import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { PopularBookSearchLogsGraph } from '@models/dashboard/PopularBookSearchLogsGraph'
import { SaleReportType } from '@models/dashboard/SaleReportType'
import { WriterByUserType } from '@models/dashboard/WriterByUserType'
import { CategorySaleReportType } from '@models/dashboard/CategorySaleReportType'
import { BookByUserResponse } from '@models/dashboard/BookByUserResponse'
import { BookCountByUserType } from '@models/dashboard/BookCountByUserType'
import { PenNameIncomeType } from '@models/dashboard/PenNameIncomeType'
import { TrafficByUserType } from '@models/dashboard/TrafficByUserType'
import { AppPerformanceType } from '@models/dashboard/AppPerformanceType'
import { ReaderTopMoneySpenderType } from '@models/dashboard/ReaderTopMoneySpenderType'
import { ReaderTopTimeSpendType } from '@models/dashboard/ReaderTopTimeSpendType'
import { PerformanceLogCityType } from '@models/dashboard/PerformanceLogCityType'
import { TotalUserGenderType } from '@models/dashboard/TotalUserGenderType'
import { TotalUserAgeRangeType } from '@models/dashboard/TotalUserAgeRangeType'
import { FrequencyToBuyCoinByUserType } from '@models/dashboard/FrequencyToBuyCoinByUserType'
import { ReaderBadgeType } from '@models/dashboard/ReaderBadgeType'
import { SearchResultByTitleResponse } from '@models/dashboard/SearchResultByTitleType'
import { SearchResultByCategoryResponse } from '@models/dashboard/SearchResultByCategoryType'
import { SearchResultByPublisherResponse } from '@models/dashboard/SearchResultByPublisherType'
import { SearchResultByWriterResponse } from '@models/dashboard/SearchResultByWriterType'
import { SearchResultByChapterResponse } from '@models/dashboard/SearchResultByChapterType'
import { SearchResultByCoverResponse } from '@models/dashboard/SearchResultByCoverType'
import { SearchResultByHashtagResponse } from '@models/dashboard/SearchResultByHashtagType'
import { WriterOverViewType } from '@models/dashboard/WriterOverViewType'
import { WriterFreeVsEarnType } from '@models/dashboard/WriterFreeVsEarnType'
import { HotWriterType } from '@models/dashboard/HotWriterType'
import { TopWriterBySaleType } from '@models/dashboard/TopWriterBySaleType'
import { TopWriterByViewType } from '@models/dashboard/TopWriterByViewType'
import { StoryType } from '@models/dashboard/StoryType'
import { StoryIncomeType } from '@models/dashboard/StoryIncomeType'
import { StoryIncomeDataType } from '@models/dashboard/StoryIncomeDataType'
import { StoryTopLoveType } from '@models/dashboard/StoryTopLoveType'
import { StoryTopViewType } from '@models/dashboard/StoryTopViewType'
import { StoryTopAddToShelfType } from '@models/dashboard/StoryTopAddToShelfType'
import { StoryTopCommentType } from '@models/dashboard/StoryTopCommentType'
import { UserCommentResponse } from '@models/dashboard/UserCommentResponse'
import {
  TopHashtagResponseClick,
  TopHashtagResponseUsed,
} from '@models/dashboard/TopHashtag'
import { PopularBookSearchLogResponse } from '@models/dashboard/PopularBookSearchLogs'
import { BookReadingResponse } from '@models/dashboard/BookReadingResponse'
import { ReadTimeUsageResponse } from '@models/dashboard/ReadTimeUsageResponse'
import { ReaderDetailByUserType } from '@models/dashboard/ReaderDetailByUserType'
import { UserReaderCoinDetailType } from '@models/dashboard/UserReaderCoinDetailType'
import { ReadTimeUsageType } from '@models/dashboard/ReadTimeUsageType'
import { ReaderDashboardDetailType } from '@models/dashboard/ReaderDashboardDetailType'
import { ReaderPayingVsNonUserPayingType } from '@models/dashboard/ReaderPayingVsNonUserPayingType'
import { ReaderAverageRevenuePerPayingUserType } from '@models/dashboard/ReaderAverageRevenuePerPayingUserType'
import { PerformanceArgumentType } from '@features/dashboard/pages/AppPerformancePage/components/PerformanceTemplate/interface'
import { BookEnum } from '@interfaces/BookEnum'
import {
  SearchResultByStoryParam,
  SearchStoryParam,
} from '@interfaces/dashboard/StoryParam'
import { GET_TOP_HASHTAG_USED } from '@client/collections/Dashboard/schemas/getTopHashtagUsed'
import { GET_TOP_HASHTAG_CLICK } from '@client/collections/Dashboard/schemas/getTopHashtagClick'
import { GET_POPULAR_BOOK_SEARCH_LOGS } from '@client/collections/Dashboard/schemas/getPopularBookSearchLogs'
import { SearchType as SearchLimitStoryParam } from '@features/dashboard/pages/StoryPage/components/WriterSection/interface'
import { SearchResultByWriterParam } from '@interfaces/dashboard/WriterParam'
import { SearchResultByChapterParam } from '@interfaces/dashboard/ChapterParam'
import { TopHashtagByTopHashtagSortParam } from '@interfaces/dashboard/TopHashtagParam'
import { SearchResultByCoverParam } from '@interfaces/dashboard/CoverParam'
import { SearchResultByCategoryParam } from '@interfaces/dashboard/CategoryParam'
import { SearchResultByPublisherParam } from '@interfaces/dashboard/PublisherParam'
import { SearchResultByHashtagParam } from '@interfaces/dashboard/HashtagParam'
import { PopularSearchTextSectionWithPageSortParam } from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { GET_POPULAR_BOOK_SEARCH_LOGS_GRAPH } from '@client/collections/Dashboard/schemas/getPopularBookSearchLogsGraph'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { SortingType } from '@components/Table/interface'
import { SearchType } from '@features/dashboard/pages/StoryPage/components/OverallPage/interface'
import { BookOptionResponse } from '@models/dashboard/BookOptionResponse'
import { BookByTitleType } from '@models/dashboard/BookByTitleType'
import { EbookOverviewType } from '@models/dashboard/EbookOverviewType'
import { EbookOverviewSaleType } from '@models/dashboard/EbookOverviewSaleType'
import { EbookOverviewSaleTopRankResponse } from '@models/dashboard/EbookOverviewSaleTopRankResponse'
import { SearchEbookResponse } from '@models/dashboard/SearchEbookResponse'
import { EbookOverviewSaleTopRankDetailType } from '@models/dashboard/EbookOverviewSaleTopRankDetailType'
import { BoughtEbookReaderResponse } from '@models/dashboard/BoughtEbookReaderResponse'
import { EbookDashboardByUserResponse } from '@models/dashboard/EbookDashboardByUserResponse'
import { DashboardPerformanceEnum } from '@interfaces/dashboard/DashboardPerformanceEnum'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { GET_BY_USER_REPORT } from './schemas/getByUserReport'
import { GET_WRITING_LIST } from './schemas/getWritingList'
import { GET_WRITING_COUNT } from './schemas/getWritingCount'
import { GET_PENNAME_INCOME } from './schemas/getPenNameIncome'
import { GET_TRAFFIC_BY_USER } from './schemas/getTrafficByUser'
import { GET_SALE_REPORT } from './schemas/getSaleReport'
import { GET_CATEGORY_SALE_REPORT_LIST } from './schemas/getCategorySaleReportList'
import { UPDATE_ADMIN_SALE_TARGET } from './schemas/updateAdminSaleTarget'
import { GET_PERFORMANCE } from './schemas/getPerformance'
import { GET_WRITER_OVER_VIEW } from './schemas/getWriterOverView'
import { GET_TOP_WRITER_BY_VIEW } from './schemas/getTopWriterByView'
import { GET_HOT_WRITER } from './schemas/getHotWriter'
import { GET_TOP_WRITER_BY_SALE } from './schemas/getTopWriterBySale'
import { GET_READER_TOP_COIN_SPENDER } from './schemas/getReaderTopCoinSpender'
import { GET_READER_TOP_TIME_SPENDER } from './schemas/getReaderTopTimeSpend'
import { GET_PERFORMANCE_LOG_CITY } from './schemas/getPerformanceLogCity'
import { GET_TOTAL_USER_GENDER } from './schemas/getTotalUserGender'
import { GET_TOTAL_USER_AGE_RANGE } from './schemas/getTotalUserAgeRange'
import { GET_FREQUENCY_TO_BY_COIN_BY_USER } from './schemas/getFrequencyToBuyCoinByUser'
import { GET_READER_BADGE } from './schemas/getReaderBadge'
import { GET_SEARCH_RESULT_BY_TITLE } from './schemas/getSearchResultByTitle'
import { GET_SEARCH_RESULT_BY_WRITER } from './schemas/getSearchResultByWriter'
import { GET_SEARCH_RESULT_BY_CHAPTER } from './schemas/getSearchResultByChapter'
import { GET_SEARCH_RESULT_BY_COVER } from './schemas/getSearchResultByCover'
import { GET_SEARCH_RESULT_BY_CATEGORY } from './schemas/getSearchResultByCategory'
import { GET_SEARCH_RESULT_BY_PUBLISHER } from './schemas/getSearchResultByPublisher'
import { GET_SEARCH_RESULT_BY_TAG } from './schemas/getSearchResultByTag'
import { GET_STORY } from './schemas/getStory'
import { GET_STORY_INCOME } from './schemas/getStoryIncome'
import { GET_STORY_TOP_VIEW } from './schemas/getStoryTopView'
import { GET_STORY_TOP_LOVE } from './schemas/getStoryTopLove'
import { GET_STORY_TOP_ADD_TO_SHELF } from './schemas/getStoryTopAddToShelf'
import { GET_STORY_TOP_COMMENT } from './schemas/getStoryTopComment'
import { GET_USER_COMMENT } from './schemas/getUserComment'
import { GET_BOOK_ADD_TO_SHELF } from './schemas/getBookAddToShelf'
import { GET_BOOK_READING } from './schemas/getBookReading'
import { GET_READ_TIME_USAGE } from './schemas/getReadTimeUsageByUser'
import { GET_USER_READER_COIN_DETAIL } from './schemas/getUserReaderCoinDetail'
import { GET_READER_DETAIL_BY_USER } from './schemas/getReaderDetailByUser'
import { GET_READER_DASHBOARD_DETAIL } from './schemas/getReaderDashboardDetail'
import { GET_READTIME_USAGE } from './schemas/getReadTimeUsage'
import { GET_AVERAGE_REVENUE_PER_PAYING_USER } from './schemas/getReaderAverageRevenuePerPayingUser'
import { GET_PAYING_VS_NON_USER_PAYING } from './schemas/getReaderPayingVsNonUserPaying'
import { GET_WRITER_FREE_VS_EARN } from './schemas/getWriterFreeVsEarnWriter'
import { GET_BOOK_OPTION } from './schemas/getBookOption'
import { GET_BOOK_BY_TITLE } from './schemas/getBookByTitle'
import { EBOOK_OVERVIEW } from './schemas/ebookDashboardOverview'
import { EBOOK_OVERVIEW_BY_CATEGORY } from './schemas/ebookDashboardOverviewByCategory'
import { EBOOK_OVERVIEW_TOP_RANK } from './schemas/ebookDashboardOverviewSalesTopRank'
import { GET_SEARCH_EBOOK } from './schemas/getEbooksForSearch'
import { EBOOK_BY_TITLE } from './schemas/ebookDashboardByTitle'
import { GET_BOUGHT_EBOOK_READER } from './schemas/getBoughtEbookReader'
import { EBOOK_DASHBOARD_BY_USER } from './schemas/ebookDashboardByUser'
import { BOOK_DASHBOARD_HOT_NEW } from './schemas/bookDashboardHotNew'
import { EXPORT_APP_PERFORMANCE } from './schemas/exportAppPerformance'
import { EXPORT_SALES_DASHBOARD } from './schemas/exportSalesDashboard'
import { EXPORT_READER_DASHBOARD } from './schemas/exportReaderDashboard'
import { EXPORT_READER_DASHBOARD_BY_ID } from './schemas/exportReaderDashboardById'
import { EXPORT_WRITER_DASHBOARD } from './schemas/exportWriterDashboard'
import { EXPORT_WRITER_DASHBOARD_BY_ID } from './schemas/exportWriterDashboardById'
import { EXPORT_EBOOK_DASHBOARD } from './schemas/exportEbookDashboard'
import { EXPORT_EBOOK_DETAIL_DASHBOARD } from './schemas/exportEbookDetailDashboard'
import { EXPORT_EBOOK_BY_USER_DASHBOARD } from './schemas/exportEbookByUserDashboard'
import { EXPORT_DONATION_DASHBOARD } from './schemas/exportDonationDashboard'
import { EXPORT_SEARCH_DASHBOARD } from './schemas/exportSearchDashboard'
import { EXPORT_STORY_DASHBOARD } from './schemas/exportStoryDashboard'
import { EXPORT_STORY_DETAIL_DASHBOARD } from './schemas/exportStoryDetailDashboard'

export class DashboardClient {
  constructor(private client: GraphQLClient) {}

  async saleReport({
    writerId,
    bookType,
    categoryIds,
    startDate,
    endDate,
    roleId,
  }: {
    writerId?: number
    bookType: BookEnum[]
    categoryIds?: number[]
    startDate: DateTime
    endDate: DateTime
    roleId: number
  }): Promise<SaleReportType> {
    const { getSalesReportsAdminDashboard } = await this.client.request(
      GET_SALE_REPORT,
      {
        writerId,
        bookTypes:
          bookType.length === 0 ? [BookEnum.MANGA, BookEnum.NOVEL] : bookType,
        categoryIds: categoryIds?.length ? categoryIds : undefined,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        roleId,
      }
    )

    return plainToInstance(SaleReportType, getSalesReportsAdminDashboard)
  }

  async categorySaleReport({
    writerId,
    bookType,
    categoryIds,
    startDate,
    endDate,
  }: {
    writerId?: number
    bookType: BookEnum[]
    categoryIds?: number[]
    startDate: DateTime
    endDate: DateTime
  }): Promise<CategorySaleReportType[]> {
    const { getProfitPerCategory } = await this.client.request(
      GET_CATEGORY_SALE_REPORT_LIST,
      {
        writerId,
        bookTypes:
          bookType.length === 0 ? [BookEnum.MANGA, BookEnum.NOVEL] : bookType,
        categoryIds: categoryIds?.length || undefined,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(CategorySaleReportType, getProfitPerCategory as [])
  }

  async updateSaleTarget({
    saleTarget,
    userId,
  }: {
    saleTarget: number
    userId: number
  }): Promise<number> {
    const { updateAdminSaleTarget } = await this.client.request(
      UPDATE_ADMIN_SALE_TARGET,
      {
        updateAdminSaleTargetInput: {
          userId,
          saleTarget,
        },
      }
    )

    return updateAdminSaleTarget.saleTarget
  }

  async getByUserReport({
    writerId,
    penNameId,
    startDate,
    endDate,
  }: {
    writerId?: number
    penNameId?: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<WriterByUserType> {
    const { penNameDashboard } = await this.client.request(GET_BY_USER_REPORT, {
      writerId,
      penNameId,
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })

    return plainToInstance(WriterByUserType, penNameDashboard)
  }

  async getBookByUser({
    writerId,
    penNameId,
    bookStatus,
    page,
    sortKey,
    sortOrder,
    startDate,
    endDate,
  }: {
    writerId?: number
    penNameId?: number
    bookStatus: string
    page: number
    sortKey: string
    sortOrder: string
    startDate: DateTime
    endDate: DateTime
  }): Promise<BookByUserResponse> {
    const { getWritingLists } = await this.client.request(GET_WRITING_LIST, {
      writerId,
      penNameId,
      bookStatus,
      page,
      limitPerPage: 20,
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
    })
    return plainToInstance(BookByUserResponse, getWritingLists)
  }

  async getBookCountByUser({
    writerId,
    penNameId,
  }: {
    writerId?: number
    penNameId?: number
  }): Promise<BookCountByUserType> {
    const { getWritingCountLists } = await this.client.request(
      GET_WRITING_COUNT,
      {
        writerId,
        penNameId,
      }
    )
    return plainToInstance(BookCountByUserType, getWritingCountLists)
  }

  async getPenNameIncome({
    writerId,
    penNameId,
    startDate,
    endDate,
  }: {
    writerId?: number
    penNameId?: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<PenNameIncomeType[]> {
    const { getSummaryPenNameIncome } = await this.client.request(
      GET_PENNAME_INCOME,
      {
        writerId,
        penNameId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(PenNameIncomeType, getSummaryPenNameIncome as [])
  }

  async getTrafficByUser({
    writerId,
    penNameId,
    startDate,
    endDate,
  }: {
    writerId?: number
    penNameId?: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<TrafficByUserType[]> {
    const { getDashboardTrafficByUser } = await this.client.request(
      GET_TRAFFIC_BY_USER,
      {
        writerId,
        penNameId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(TrafficByUserType, getDashboardTrafficByUser as [])
  }

  async getWriterOverView({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<WriterOverViewType> {
    const { getWriterPublisherOverview } = await this.client.request(
      GET_WRITER_OVER_VIEW,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(WriterOverViewType, getWriterPublisherOverview)
  }

  async getWriterFreeVsEarnWriter({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<WriterFreeVsEarnType> {
    const { getFreeVSEarnWriter } = await this.client.request(
      GET_WRITER_FREE_VS_EARN,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(WriterFreeVsEarnType, getFreeVSEarnWriter)
  }

  async getTopWriterByView({
    startDate,
    endDate,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    sortKey?: string
    sortOrder?: string
  }): Promise<TopWriterByViewType[]> {
    const { getTopWriterByView } = await this.client.request(
      GET_TOP_WRITER_BY_VIEW,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        limit: 10,
        orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
      }
    )

    return plainToInstance(TopWriterByViewType, getTopWriterByView as [])
  }

  async getHotWriter({
    startDate,
    endDate,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    sortKey?: string
    sortOrder?: string
  }): Promise<HotWriterType[]> {
    const { getHotWriter } = await this.client.request(GET_HOT_WRITER, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      limit: 20,
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
    })

    return plainToInstance(HotWriterType, getHotWriter as [])
  }

  async getTopWriterBySale({
    startDate,
    endDate,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    sortKey?: string
    sortOrder?: string
  }): Promise<TopWriterBySaleType[]> {
    const { getTopWriterBySale } = await this.client.request(
      GET_TOP_WRITER_BY_SALE,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        limit: 10,
        orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
      }
    )

    return plainToInstance(TopWriterBySaleType, getTopWriterBySale as [])
  }

  async getPerformance({
    startDate,
    endDate,
    dashboardType,
    keyType,
  }: PerformanceArgumentType): Promise<AppPerformanceType> {
    const { getPerformanceDashboard } = await this.client.request(
      GET_PERFORMANCE,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        dashboardType,
        keyType,
      }
    )
    return plainToInstance(AppPerformanceType, {
      ...getPerformanceDashboard,
      sessionHistory: {
        ...getPerformanceDashboard.sessionHistory,
        startDay: startDate.day,
        endDay: endDate.day,
        startYear: startDate.year,
        endYear: endDate.year,
      },
      storageSizeHistory: {
        ...getPerformanceDashboard.storageSizeHistory,
        startDay: startDate.day,
        endDay: endDate.day,
        startYear: startDate.year,
        endYear: endDate.year,
      },
    })
  }

  async getReaderTopMoneySpender({
    startDate,
    endDate,
    key,
    order,
  }: {
    startDate: DateTime
    endDate: DateTime
    key?: string
    order?: string
  }): Promise<ReaderTopMoneySpenderType[]> {
    const { getTopCoinSpender } = await this.client.request(
      GET_READER_TOP_COIN_SPENDER,
      {
        limit: 10,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        orderBy: key ? { [key]: order } : undefined,
      }
    )
    return plainToInstance(ReaderTopMoneySpenderType, getTopCoinSpender as [])
  }

  async getReaderTopTimeSpend({
    startDate,
    endDate,
    key,
    order,
  }: {
    startDate: DateTime
    endDate: DateTime
    key?: string
    order?: string
  }): Promise<ReaderTopTimeSpendType[]> {
    const { getTopTimeSpend } = await this.client.request(
      GET_READER_TOP_TIME_SPENDER,
      {
        limit: 10,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        orderBy: key ? { [key]: order } : undefined,
      }
    )
    return plainToInstance(ReaderTopTimeSpendType, getTopTimeSpend as [])
  }

  async getPerformanceLogCity({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<PerformanceLogCityType[]> {
    const { getPerformanceLogCityData } = await this.client.request(
      GET_PERFORMANCE_LOG_CITY,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        limit: 10,
      }
    )
    return plainToInstance(
      PerformanceLogCityType,
      getPerformanceLogCityData as []
    )
  }

  async getTotalUserGender({
    endDate,
    startDate,
    penNameId,
    writerId,
  }: {
    startDate: DateTime
    endDate: DateTime
    penNameId?: number
    writerId?: number
  }): Promise<TotalUserGenderType> {
    const { getTotalReadingGender } = await this.client.request(
      GET_TOTAL_USER_GENDER,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        penNameId,
        writerId,
      }
    )
    return plainToInstance(TotalUserGenderType, getTotalReadingGender, {
      excludeExtraneousValues: true,
    })
  }

  async getTotalUserAgeRange({
    endDate,
    startDate,
    penNameId,
    writerId,
  }: {
    startDate: DateTime
    endDate: DateTime
    penNameId?: number
    writerId?: number
  }): Promise<TotalUserAgeRangeType> {
    const { getTotalUserAgeRange } = await this.client.request(
      GET_TOTAL_USER_AGE_RANGE,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        penNameId,
        writerId,
      }
    )
    return plainToInstance(TotalUserAgeRangeType, getTotalUserAgeRange, {
      excludeExtraneousValues: true,
    })
  }

  async getFrequencyToBuyCoinByUser({
    endDate,
    startDate,
    userId,
    keyType,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    keyType: TimeRangeEnum
  }): Promise<FrequencyToBuyCoinByUserType> {
    const { getFrequencyToBuyCoinByUser } = await this.client.request(
      GET_FREQUENCY_TO_BY_COIN_BY_USER,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        userId,
        keyType,
      }
    )

    const startDay = startDate.day
    const startEndOfMonth =
      startDate.month === endDate.month
        ? undefined
        : startDate.endOf('month').day
    const endDay = endDate.day

    return plainToInstance(FrequencyToBuyCoinByUserType, {
      ...getFrequencyToBuyCoinByUser,
      startDay,
      startEndOfMonth,
      endDay,
    })
  }

  async getReaderBadge({
    userId,
    challengeTypeArgs,
  }: {
    userId: number
    challengeTypeArgs: string
  }): Promise<ReaderBadgeType[]> {
    const { getReaderBadge } = await this.client.request(GET_READER_BADGE, {
      userId,
      challengeTypeArgs,
    })
    return plainToInstance(ReaderBadgeType, getReaderBadge as [])
  }

  async topHashtagUsed({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: TopHashtagByTopHashtagSortParam): Promise<TopHashtagResponseUsed> {
    const { findTagUsed } = await this.client.request(GET_TOP_HASHTAG_USED, {
      dateRange: {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      },
      orderBy: {
        [orderBy]: orderDirection,
      },
      limitPerPage: 10,
      page,
    })

    return plainToInstance(TopHashtagResponseUsed, findTagUsed)
  }

  async popularBookGraph({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
    searchBy,
    searchText,
  }: PopularSearchTextSectionWithPageSortParam): Promise<
    PopularBookSearchLogsGraph[]
  > {
    const { popularBookSearchGraph } = await this.client.request(
      GET_POPULAR_BOOK_SEARCH_LOGS_GRAPH,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        searchBy,
        searchText,
        orderBy: {
          [orderBy]: orderDirection,
        },
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(
      PopularBookSearchLogsGraph,
      popularBookSearchGraph as Record<string, any>[]
    )
  }

  async popularSearchText({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
    searchBy,
    searchText,
  }: PopularSearchTextSectionWithPageSortParam): Promise<PopularBookSearchLogResponse> {
    const { popularBookSearchLogs } = await this.client.request(
      GET_POPULAR_BOOK_SEARCH_LOGS,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        searchBy,
        searchText,
        orderBy: {
          [orderBy]: orderDirection,
        },
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(PopularBookSearchLogResponse, popularBookSearchLogs)
  }

  async topHashtagClick({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: TopHashtagByTopHashtagSortParam): Promise<TopHashtagResponseClick> {
    const { findTagClick } = await this.client.request(GET_TOP_HASHTAG_CLICK, {
      dateRange: {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      },
      orderBy: {
        [orderBy]: orderDirection,
      },
      limitPerPage: 10,
      page,
    })

    return plainToInstance(TopHashtagResponseClick, findTagClick)
  }

  async searchResultByTitle({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByStoryParam): Promise<SearchResultByTitleResponse> {
    const { searchResultByTitle } = await this.client.request(
      GET_SEARCH_RESULT_BY_TITLE,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(SearchResultByTitleResponse, searchResultByTitle)
  }

  async searchResultByWriter({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByWriterParam): Promise<SearchResultByWriterResponse> {
    const { searchResultByWriter } = await this.client.request(
      GET_SEARCH_RESULT_BY_WRITER,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(SearchResultByWriterResponse, searchResultByWriter)
  }

  async searchResultByChapter({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByChapterParam): Promise<SearchResultByChapterResponse> {
    const { searchResultByChapter } = await this.client.request(
      GET_SEARCH_RESULT_BY_CHAPTER,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(SearchResultByChapterResponse, searchResultByChapter)
  }

  async searchResultByCover({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByCoverParam): Promise<SearchResultByCoverResponse> {
    const { searchResultByCover } = await this.client.request(
      GET_SEARCH_RESULT_BY_COVER,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(SearchResultByCoverResponse, searchResultByCover)
  }

  async searchResultByCategory({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByCategoryParam): Promise<SearchResultByCategoryResponse> {
    const { searchResultByCategory } = await this.client.request(
      GET_SEARCH_RESULT_BY_CATEGORY,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(
      SearchResultByCategoryResponse,
      searchResultByCategory
    )
  }

  async searchResultByPublisher({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByPublisherParam): Promise<SearchResultByPublisherResponse> {
    const { searchResultByPublisher } = await this.client.request(
      GET_SEARCH_RESULT_BY_PUBLISHER,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(
      SearchResultByPublisherResponse,
      searchResultByPublisher
    )
  }

  async searchResultByHashtag({
    startDate,
    endDate,
    orderBy,
    orderDirection,
    page,
  }: SearchResultByHashtagParam): Promise<SearchResultByHashtagResponse> {
    const { searchResultByTag } = await this.client.request(
      GET_SEARCH_RESULT_BY_TAG,
      {
        dateRange: {
          startDate: startDate.toUTC(),
          endDate: endDate.toUTC(),
        },
        orderBy,
        orderDirection,
        limitPerPage: 10,
        page,
      }
    )

    return plainToInstance(SearchResultByHashtagResponse, searchResultByTag)
  }

  async getStory({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<StoryType> {
    const { bookDashboard } = await this.client.request(GET_STORY, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })

    return plainToInstance(StoryType, bookDashboard)
  }

  async getStoryIncome(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
    limit: number
  }): Promise<StoryIncomeType> {
    const { bookDashboardIncome } = await this.client.request(
      GET_STORY_INCOME,
      {
        startDate: params.startDate.toUTC(),
        endDate: params.endDate.toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        limit: params.limit || 10,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    const { data, income } = bookDashboardIncome

    return plainToInstance(StoryIncomeType, {
      data: data as StoryIncomeDataType[],
      income: income as number,
    })
  }

  async getStoryTopView(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
  }): Promise<StoryTopViewType[]> {
    const { bookDashboardTopView } = await this.client.request(
      GET_STORY_TOP_VIEW,
      {
        startDate: params.startDate.toUTC(),
        endDate: params.endDate.toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    return plainToInstance(StoryTopViewType, bookDashboardTopView.data as [])
  }

  async getStoryTopLove(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
  }): Promise<StoryTopLoveType[]> {
    const { bookDashboardTopLove } = await this.client.request(
      GET_STORY_TOP_LOVE,
      {
        startDate: params.startDate.toUTC(),
        endDate: params.endDate.toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    return plainToInstance(StoryTopLoveType, bookDashboardTopLove.data as [])
  }

  async getStoryTopAddToShelf(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
  }): Promise<StoryTopAddToShelfType[]> {
    const { bookDashboardTopAddToShelf } = await this.client.request(
      GET_STORY_TOP_ADD_TO_SHELF,
      {
        startDate: params.startDate.toUTC(),
        endDate: params.endDate.toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    return plainToInstance(
      StoryTopAddToShelfType,
      bookDashboardTopAddToShelf.data as []
    )
  }

  async getStoryTopComment(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
  }): Promise<StoryTopCommentType[]> {
    const { bookDashboardTopComment } = await this.client.request(
      GET_STORY_TOP_COMMENT,
      {
        startDate: params.startDate.toUTC(),
        endDate: params.endDate.toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    return plainToInstance(
      StoryTopCommentType,
      bookDashboardTopComment.data as []
    )
  }

  async getUserComment({
    startDate,
    endDate,
    userId,
    page,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    page: number
    sortKey?: string
    sortOrder?: string
  }): Promise<UserCommentResponse> {
    const { getUserComment } = await this.client.request(GET_USER_COMMENT, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      limitPerPage: 20,
      userId,
      page,
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
    })

    return plainToInstance(UserCommentResponse, getUserComment)
  }

  async getBookAddToShelf({
    startDate,
    endDate,
    userId,
    page,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    page: number
    sortKey?: string
    sortOrder?: string
  }): Promise<BookReadingResponse> {
    const { getBookAddToShelf } = await this.client.request(
      GET_BOOK_ADD_TO_SHELF,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        limitPerPage: 20,
        userId,
        page,
        orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
      }
    )

    return plainToInstance(BookReadingResponse, getBookAddToShelf)
  }

  async getBookReading({
    startDate,
    endDate,
    userId,
    page,
    sortKey,
    sortOrder,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    page: number
    sortKey?: string
    sortOrder?: string
  }): Promise<BookReadingResponse> {
    const { getBookReading } = await this.client.request(GET_BOOK_READING, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      limitPerPage: 20,
      userId,
      page,
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
    })

    return plainToInstance(BookReadingResponse, getBookReading)
  }

  async getReadTimeUsageByUser({
    startDate,
    endDate,
    userId,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
  }): Promise<ReadTimeUsageResponse> {
    const { getReadTimeUsageByUser } = await this.client.request(
      GET_READ_TIME_USAGE,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        userId,
      }
    )
    return plainToInstance(ReadTimeUsageResponse, getReadTimeUsageByUser, {
      excludeExtraneousValues: true,
    })
  }

  async getUserReaderCoinDetail({
    startDate,
    endDate,
    userId,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
  }): Promise<UserReaderCoinDetailType> {
    const { getUserReaderCoinDetail } = await this.client.request(
      GET_USER_READER_COIN_DETAIL,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        userId,
      }
    )
    return plainToInstance(UserReaderCoinDetailType, getUserReaderCoinDetail)
  }

  async getReaderDetailByUser({
    userId,
  }: {
    userId: number
  }): Promise<ReaderDetailByUserType> {
    const { getReaderDetailByUser } = await this.client.request(
      GET_READER_DETAIL_BY_USER,
      {
        userId,
      }
    )
    return plainToInstance(ReaderDetailByUserType, getReaderDetailByUser)
  }

  async getReaderDashboardDetail({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<ReaderDashboardDetailType> {
    const { getReaderDashboardDetail } = await this.client.request(
      GET_READER_DASHBOARD_DETAIL,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(ReaderDashboardDetailType, getReaderDashboardDetail)
  }

  async getReadTimeUsage({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<ReadTimeUsageType[]> {
    const { getReadTimeUsage } = await this.client.request(GET_READTIME_USAGE, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
    return plainToInstance(ReadTimeUsageType, getReadTimeUsage as [])
  }

  async getPayingVsNonUserPaying({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<ReaderPayingVsNonUserPayingType[]> {
    const { getPayingVsNonUserPaying } = await this.client.request(
      GET_PAYING_VS_NON_USER_PAYING,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )
    return plainToInstance(
      ReaderPayingVsNonUserPayingType,
      getPayingVsNonUserPaying as []
    )
  }

  async getAverageRevenuePerPayingUser({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<ReaderAverageRevenuePerPayingUserType[]> {
    const { getAverageRevenuePerPayingUser } = await this.client.request(
      GET_AVERAGE_REVENUE_PER_PAYING_USER,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )
    return plainToInstance(
      ReaderAverageRevenuePerPayingUserType,
      getAverageRevenuePerPayingUser as []
    )
  }

  async getBookOption(
    searchText: string,
    page: number
  ): Promise<BookOptionResponse> {
    const { searchBook } = await this.client.request(GET_BOOK_OPTION, {
      searchText,
      page,
      limitPerPage: 20,
    })

    return plainToInstance(BookOptionResponse, searchBook)
  }

  async getBookByTitle({
    bookId,
    startDate,
    endDate,
  }: {
    bookId?: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<BookByTitleType> {
    const { bookDashboardByTitle } = await this.client.request(
      GET_BOOK_BY_TITLE,
      {
        bookId,
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      }
    )

    return plainToInstance(BookByTitleType, bookDashboardByTitle)
  }

  async ebookDashboardOverview({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    const res = await this.client.request(EBOOK_OVERVIEW, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })

    return plainToInstance(EbookOverviewType, res.ebookDashboardOverview)
  }

  async ebookDashboardOverviewSalesByCategory({
    startDate,
    endDate,
    bookType,
  }: {
    startDate: DateTime
    endDate: DateTime
    bookType: BookEnum | null
  }) {
    const res = await this.client.request(EBOOK_OVERVIEW_BY_CATEGORY, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      ebookTypes: bookType ? [bookType] : undefined,
    })

    return plainToInstance(
      EbookOverviewSaleType,
      res.ebookDashboardOverviewSalesByCategory as []
    )
  }

  async ebookDashboardOverviewSalesTopRank({
    startDate,
    endDate,
    categoryIds,
    ebookTypes,
    sortKey,
    sortOrder,
    limitPerPage,
    page,
  }: {
    startDate: DateTime
    endDate: DateTime
    categoryIds: string | null
    ebookTypes: BookEnum | null
    sortKey?: string
    sortOrder?: SortingType
    limitPerPage: number
    page: number
  }): Promise<EbookOverviewSaleTopRankResponse> {
    const res = await this.client.request(EBOOK_OVERVIEW_TOP_RANK, {
      limitPerPage,
      page,
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      categoryIds: categoryIds ? [Number(categoryIds)] : undefined,
      ebookTypes: ebookTypes ? [ebookTypes] : undefined,
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
    })

    return plainToInstance(
      EbookOverviewSaleTopRankResponse,
      res.ebookDashboardOverviewSalesTopRank
    )
  }

  async searchEbook(
    searchText: string,
    page: number
  ): Promise<SearchEbookResponse> {
    const { getEbooksForSearch } = await this.client.request(GET_SEARCH_EBOOK, {
      searchText,
      page,
      limitPerPage: 20,
    })

    return plainToInstance(SearchEbookResponse, getEbooksForSearch)
  }

  async ebookByTitle({
    ebookId,
    startDate,
    endDate,
  }: {
    ebookId: number
    startDate: DateTime
    endDate: DateTime
  }): Promise<EbookOverviewSaleTopRankDetailType[]> {
    const {
      ebookDashboardByTitle: { data },
    } = await this.client.request(EBOOK_BY_TITLE, {
      ebookId,
      startDate,
      endDate,
    })

    return plainToInstance(EbookOverviewSaleTopRankDetailType, data as [])
  }

  async getBoughtEbookReader({
    startDate,
    endDate,
    userId,
    page,
    key,
    order,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    page: number
    key: string
    order: SortingType
  }): Promise<BoughtEbookReaderResponse> {
    const { boughtEbookReader } = await this.client.request(
      GET_BOUGHT_EBOOK_READER,
      {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
        limitPerPage: 20,
        userId,
        page,
        orderBy: key ? { [key]: order } : undefined,
      }
    )

    return plainToInstance(BoughtEbookReaderResponse, boughtEbookReader)
  }

  async ebookByUser({
    page,
    limitPerPage,
    startDate,
    endDate,
    userId,
    key,
    order,
  }: {
    page: number
    limitPerPage: number
    startDate: DateTime
    endDate: DateTime
    userId: number
    key: string
    order: SortingType
  }): Promise<EbookDashboardByUserResponse> {
    const { ebookDashboardByUser } = await this.client.request(
      EBOOK_DASHBOARD_BY_USER,
      {
        limitPerPage,
        page,
        startDate,
        endDate,
        userId,
        orderBy: {
          [key]: order,
        },
      }
    )

    return plainToInstance(EbookDashboardByUserResponse, ebookDashboardByUser)
  }

  async bookDashboardHotNew(params: {
    bookType: BookEnum | null
    categoryId: number | null
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortOrder: SortingType
    limit: number
  }): Promise<StoryIncomeType> {
    const { bookDashboardHotNew } = await this.client.request(
      BOOK_DASHBOARD_HOT_NEW,
      {
        startDate: params.startDate.minus({ days: 13 }).toUTC(),
        endDate: params.startDate.endOf('day').toUTC(),
        bookType: params.bookType,
        categoryId: params.categoryId,
        limit: params.limit || 10,
        orderBy: params.sortKey
          ? { [params.sortKey]: params.sortOrder }
          : undefined,
      }
    )

    return plainToInstance(StoryIncomeType, bookDashboardHotNew)
  }

  async exportAppPerformanceReport({
    dashboardType,
    startDate,
    endDate,
    keyType,
  }: {
    dashboardType: DashboardPerformanceEnum
    startDate: DateTime
    endDate: DateTime
    keyType: KeyTypeEnum
  }): Promise<void> {
    await this.client.request(EXPORT_APP_PERFORMANCE, {
      dashboardType,
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      keyType,
    })
  }

  async exportSalesDashboard({
    startDate,
    endDate,
    bookTypes,
    categoryIds,
    writerId,
  }: {
    startDate: DateTime
    endDate: DateTime
    bookTypes: BookEnum[]
    categoryIds: number[]
    writerId?: number
  }): Promise<void> {
    await this.client.request(EXPORT_SALES_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      bookTypes,
      categoryIds,
      writerId,
    })
  }

  async exportReaderDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<void> {
    await this.client.request(EXPORT_READER_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportReaderDashboardById({
    userId,
    startDate,
    endDate,
    keyType,
  }: {
    userId: number
    startDate: DateTime
    endDate: DateTime
    keyType: TimeRangeEnum
  }): Promise<void> {
    await this.client.request(EXPORT_READER_DASHBOARD_BY_ID, {
      userId,
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      keyType,
    })
  }

  async exportWriterDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<void> {
    await this.client.request(EXPORT_WRITER_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportWriterDashboardById({
    startDate,
    endDate,
    userId,
    writerId,
    penNameId,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
    writerId: number
    penNameId?: number
  }): Promise<void> {
    await this.client.request(EXPORT_WRITER_DASHBOARD_BY_ID, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      userId,
      writerId,
      penNameId,
    })
  }

  async exportEbookDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_EBOOK_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportEbookDetailDashboard({
    startDate,
    endDate,
    ebookId,
  }: {
    startDate: DateTime
    endDate: DateTime
    ebookId: number
  }) {
    await this.client.request(EXPORT_EBOOK_DETAIL_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      ebookId,
    })
  }

  async exportEbookByUserDashboard({
    startDate,
    endDate,
    userId,
  }: {
    startDate: DateTime
    endDate: DateTime
    userId: number
  }) {
    await this.client.request(EXPORT_EBOOK_BY_USER_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      userId,
    })
  }

  async exportDonationDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_DONATION_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportSearchDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_SEARCH_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportStoryDashboard({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_STORY_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }

  async exportStoryDetailDashboard({
    startDate,
    endDate,
    bookId,
  }: {
    startDate: DateTime
    endDate: DateTime
    bookId: number
  }) {
    await this.client.request(EXPORT_STORY_DETAIL_DASHBOARD, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
      bookId,
    })
  }
}
