import cn from 'classnames'

import { usePortalDropdown } from '@hooks/useDropdown'
import { DragIndicatorIcon } from '@components/Icons'
import { ButtonSelectProps } from './interface'

export function ButtonSelect({ className, actions }: ButtonSelectProps) {
  const { isShowDropdown, toggleDropdown, Dropdown } = usePortalDropdown()

  return (
    <div className={cn('relative', className)}>
      <button type='button' className={cn('h-[30px]')} onClick={toggleDropdown}>
        <div className='flex'>
          <DragIndicatorIcon
            width='20'
            height='20'
            className={cn({
              'text-primary': isShowDropdown,
              'text-secondary': !isShowDropdown,
            })}
          />
        </div>
      </button>
      {isShowDropdown && (
        <Dropdown>
          <div className='w-[158px] absolute text-[14px] right-[-20px] font-light py-[10px] text-secondary mt-[6px] bg-white shadow-51 rounded-[8px] border border-gray overflow-y-auto'>
            {actions.map(({ label, isShow, disabled, onClick }, index) =>
              isShow ? (
                <button
                  key={index}
                  className='flex text-left w-full leading-[1.5] font-light hover:bg-blue-8 hover:text-primary py-[5px] px-[15px] disabled:bg-gray disabled:text-black-970'
                  type='button'
                  onClick={onClick}
                  disabled={disabled}
                >
                  {label}
                </button>
              ) : null
            )}
          </div>
        </Dropdown>
      )}
    </div>
  )
}
