import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { PenNameType } from '@models/penName/PenNameType'
import { BookEnum } from '@interfaces/BookEnum'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'

@Exclude()
export class EbookDetailType {
  @Expose() id: number

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Transform(({ value }) => value || 0)
  avgRating: number

  @Expose()
  @Transform(({ value }) => value || 0)
  purchaseCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  viewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalSales: number

  @Expose()
  @Transform(({ value }) => value || 0)
  reviewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  readFreeTrialCount: number

  @Expose() bookType: BookEnum

  @Expose() ebookStatus: EbookStatusEnum
}
