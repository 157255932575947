export enum DaySelectEnum {
  ALL_DAY = 'ALL_DAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const daySelectTypeValue = {
  [DaySelectEnum.ALL_DAY]: 'ทุกวัน',
  [DaySelectEnum.MONDAY]: 'วันจันทร์',
  [DaySelectEnum.TUESDAY]: 'วันอังคาร',
  [DaySelectEnum.WEDNESDAY]: 'วันพุธ',
  [DaySelectEnum.THURSDAY]: 'วันพฤหัสบดี',
  [DaySelectEnum.FRIDAY]: 'วันศุกร์',
  [DaySelectEnum.SATURDAY]: 'วันเสาร์',
  [DaySelectEnum.SUNDAY]: 'วันอาทิตย์',
}
