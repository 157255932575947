import { Exclude, Expose, Type } from 'class-transformer'
import { ChallengeUser } from './ChallengeUser'

@Exclude()
export class ChallengeUserOptionResponse {
  @Expose() page: number

  @Expose() total: number

  @Expose()
  @Type(() => ChallengeUser)
  data: ChallengeUser[]
}
