import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { useAlert } from '@hooks/useAlert'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { SearchFormType, StoryReaderPageProps } from './interface'

export function withStoryReaderPage(Component: React.FC<StoryReaderPageProps>) {
  function WithStoryReaderPage() {
    const client = useClient()
    const { id } = useParams()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const [searchParams, setSearchParams] = useState<SearchFormType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      keyType: KeyTypeEnum.MONTH,
    })
    const alert = useAlert()
    const { data, isLoading } = useQuery(
      ['story-cover', id],
      () => client?.storyClient.getStoryDetail(Number(id)),
      {
        enabled: !!id && isAuthenticated,
      }
    )

    const { data: BookChapterViewerData } = useQuery(
      ['book-chapter-viewer', id, searchParams],
      () =>
        client?.storyClient.getBookChapterViewer({
          bookId: Number(id),
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
        }),
      {
        enabled: !!id && !!searchParams,
      }
    )

    function handleSearch(
      startDate: DateTime,
      endDate: DateTime,
      keyType?: KeyTypeEnum | null
    ) {
      setSearchParams({
        startDate,
        endDate,
        keyType,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        keyType: KeyTypeEnum.MONTH,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportStoryDetailDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          bookId: Number(id),
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    if (!data) return null
    const newProps = {
      data,
      bookId: id,
      searchParams,
      totalViewer: BookChapterViewerData?.uniqueViewerBuyAllChapter || 0,
      uniqueView: BookChapterViewerData?.uniqueView || 0,
      handleSearch,
      handleReset,
      handleExport,
    }

    return <Component {...newProps} />
  }

  return WithStoryReaderPage
}
