import { DateTime } from 'luxon'

import { Box } from '@components/Box'
import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { WriterEnum } from '@interfaces/WriterEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Navigation } from './components/Navigation'
import { UserInformation } from './components/UserInformation'
import { WriterInformation } from './components/WriterInformation'
import { Address } from './components/Address'
import { DeliveryAddress } from './components/DeliveryAddress'
import { WriterSubmitDetailPageProps } from './interface'
import { BankAccountInformation } from './components/BankAccountInformation'
import { AccountingInformation } from './components/AccountingInformation'
import { CoordinatorInformation } from './components/CoordinatorInformation'
import { CertificateAddress } from './components/CertificateAddress'
import { Consent } from './components/Consent'

export function WriterSubmitDetailPage({
  writer,
  handleReject,
  handleApprove,
  countryOptions,
}: WriterSubmitDetailPageProps) {
  const permission = usePermissionAction('user')
  return (
    <Container className='flex flex-col'>
      <Navigation />
      <UserInformation />
      <Box className='flex flex-col gap-[50px] border-blue-2 shadow'>
        <WriterInformation />
        {writer?.type === WriterEnum.LEGAL && (
          <>
            <AccountingInformation />
            <CoordinatorInformation />
          </>
        )}
        {writer?.type === WriterEnum.INDIVIDUAL ? (
          <Address countryOptions={countryOptions} />
        ) : (
          <CertificateAddress countryOptions={countryOptions} />
        )}
        <DeliveryAddress countryOptions={countryOptions} />
        <BankAccountInformation />
        <Consent />
        {[
          WriterStatusEnum.CREATED_REJECTED,
          WriterStatusEnum.EDITED_REJECTED,
        ].includes(writer?.status) && (
          <div className='flex flex-col border-[#eee] border-t-[1px] pt-[30px] mt-[-20px]'>
            <div className='text-[14px] font-light mb-[20px]'>
              <span className='text-secondary-100 '>
                เหตุผลที่ถูก Reject : &nbsp;
              </span>
              <span className='text-secondary'>
                {writer.rejectedReason || '-'}
              </span>
            </div>
            <div className='text-[14px]'>
              <span className='text-secondary-100 font-light'>
                Rejected at : &nbsp;
              </span>
              <span className='font-light text-secondary'>
                {writer.rejectedAt
                  ? DateTime.fromISO(writer.rejectedAt).toFormat(
                      'dd LLL yyyy HH:mm:ss',
                      {
                        locale: 'th-Th',
                      }
                    )
                  : '-'}
              </span>
            </div>
          </div>
        )}
        {writer?.status === WriterStatusEnum.APPROVED && (
          <div className='flex flex-col border-[#eee] border-t-[1px] pt-[30px]'>
            <div className='text-[14px]'>
              <span className='text-secondary-100 font-light'>
                Approved at : &nbsp;
              </span>
              <span className='font-light text-secondary'>
                {writer.approvedAt
                  ? DateTime.fromISO(writer.approvedAt).toFormat(
                      'dd LLL yyyy HH:mm:ss',
                      {
                        locale: 'th-Th',
                      }
                    )
                  : '-'}
              </span>
            </div>
          </div>
        )}
      </Box>
      {(writer?.status === WriterStatusEnum.APPROVED ||
        writer?.status === WriterStatusEnum.EDITED_REJECTED ||
        writer?.status === WriterStatusEnum.CREATED_REJECTED) &&
        writer?.approver && (
          <div className='flex text-[14px] items-center justify-end gap-x-[10px] text-secondary-100 font-light mt-[20px]'>
            {writer.status === WriterStatusEnum.APPROVED
              ? 'Approve by :'
              : 'Reject by :'}
            {writer.approver?.avatarImgPath ? (
              <img
                className='w-[50px] h-[50px] object-cover rounded-full'
                src={writer.approver.avatarImgPath}
                alt={writer.approver.displayName}
              />
            ) : (
              <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
            )}
            <span className='font-bold text-secondary'>
              {writer.approver.displayName}
            </span>
          </div>
        )}
      {[
        WriterStatusEnum.CREATED_PENDING,
        WriterStatusEnum.EDITED_PENDING,
      ].includes(writer?.status) &&
        permission.edit && (
          <div className='flex justify-center gap-x-[20px] font-mitr mt-[30px]'>
            <Button
              className='w-[100px] text-sm mt-[10px] text-secondary-100 border-secondary-100 border-[1px]'
              type='button'
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button
              className='w-[100px] text-sm bg-primary mt-[10px] text-white'
              type='button'
              onClick={handleApprove}
            >
              Approve
            </Button>
          </div>
        )}
    </Container>
  )
}
