import { Expose, Type } from 'class-transformer'

export class ChapterType {
  @Expose()
  name: string

  @Expose()
  chapters: ChaptersType[]

  @Expose()
  characters: CharactersType[]
}

export class PCLReferenceType {
  @Expose()
  bookName: string

  @Expose()
  chapterName: string

  @Expose()
  coinValue: number
}

export class PCLPenNameType {
  @Expose()
  name: string

  @Expose()
  sales: number

  @Expose()
  chapters: PCLReferenceType[]
}

export class SocialPlatformType {
  @Expose()
  name: string

  @Expose()
  socialPlatforms: SocialPlatformsType[]
}

export class SocialPlatformsType {
  @Expose()
  value: number

  @Expose()
  count: number
}

export class CharactersType {
  @Expose()
  value: number

  @Expose()
  count: number
}

export class ChaptersType {
  @Expose()
  value: number

  @Expose()
  count: number
}

export class PromotionChallengeLogType {
  @Expose()
  id: number

  @Expose()
  userId: number

  @Expose()
  username: string

  @Expose()
  penNames: PCLPenNameType[]

  @Expose()
  bookName: string

  @Expose()
  chapterName: string

  @Expose()
  coinSpent: number

  @Expose()
  coinReceived: number

  @Expose()
  @Type(() => ChapterType)
  chapters: ChapterType[]

  @Expose()
  @Type(() => SocialPlatformType)
  socialPlatforms: SocialPlatformType[]

  @Expose()
  createdAt: Date | null

  @Expose()
  expiredAt: Date | null

  @Expose()
  deletedAt: Date | null

  @Expose()
  writerId: number
}
