import cn from 'classnames'

import { useDropdown } from '@hooks/useDropdown'
import { NotificationIcon, NotificationOutlineIcon } from '@components/Icons'
import { NotificationPopupProps } from './interface'

export function NotificationPopup({
  className,
  noticeList,
  hasNew,
  setHasNew,
  handleClickNotification,
  fetchNextData,
  updateBadge,
  goToAllNoti,
}: NotificationPopupProps) {
  const { dropdownRef, toggleDropdown, isShowDropdown } = useDropdown()

  return (
    <div className={cn('relative', className)}>
      <button
        className='flex'
        type='button'
        onClick={() => {
          if (hasNew && !isShowDropdown) updateBadge()
          toggleDropdown()
        }}
        data-test='notification-menu'
      >
        {isShowDropdown ? (
          <NotificationIcon
            className='text-secondary'
            width='24px'
            height='24px'
          />
        ) : (
          <NotificationOutlineIcon
            className='text-secondary'
            width='24px'
            height='24px'
          />
        )}
        {hasNew && (
          <div className='absolute top-[6px] z-1 right-[4px] w-[6px] h-[6px] bg-red rounded-full' />
        )}
      </button>
      <div
        ref={dropdownRef}
        className={cn(
          'fixed top-[60px] right-0 z-10 bg-white shadow w-[310px] text-secondary',
          { hidden: !isShowDropdown }
        )}
      >
        <div className='flex justify-between border-b border-gray-3 p-[10px] text-[14px] font-medium'>
          <p className='font-mitr text-secondary'>การแจ้งเตือน</p>
          <button
            type='button'
            className='text-primary underline cursor-pointer'
            onClick={goToAllNoti}
          >
            ดูทั้งหมด
          </button>
        </div>
        <ul
          ref={dropdownRef}
          className='p-0 m-0 overflow-y-scroll max-h-[580px] list-none'
          onScroll={(e: any) => {
            const scrollHeight = e.target.scrollHeight - 10
            const scrollTop = e.target.scrollTop + e.target.offsetHeight
            if (scrollHeight <= scrollTop) {
              fetchNextData()
            }
          }}
        >
          {noticeList.length !== 0 ? (
            noticeList.map(notice => (
              <li key={notice.id}>
                <div
                  className={cn(
                    'flex space-x-[20px] p-[20px] border-gray border-b-[1px] cursor-pointer text-secondary',
                    {
                      'bg-gray-1': !notice.read,
                    }
                  )}
                  onClick={() => handleClickNotification(notice)}
                >
                  <div className='relative shrink-0 w-[60px] h-[60px] bg-gray rounded-[8px] overflow-hidden' />

                  <div className='overflow-hidden'>
                    <p className='font-mitr font-medium text-[14px] line-clamp-1 break-all'>
                      {notice.title}
                    </p>
                    <p className='text-[12px] font-light line-clamp-5 break-words'>
                      {notice.description}
                    </p>
                    <p className='text-[12px] text-secondary-1 mt-[7px]'>
                      {notice.createdAt}
                    </p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className='font-mitr font-medium text-[14px] py-[20px] text-center'>
              ไม่พบการแจ้งเตือน
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}
