import { CoinDetail } from '../CoinDetail'
import { CoinTable } from '../CoinTable'
import { CoinSectionProps } from './interface'

export function CoinSection({
  currentFreeCoin,
  currentPaidCoin,
}: CoinSectionProps) {
  return (
    <>
      <div className='border-b pb-[30px] my-[30px] border-gray'>
        <CoinDetail isByUser />
      </div>
      <CoinTable />
    </>
  )
}
