import { plainToInstance } from 'class-transformer'
import { useFormikContext } from 'formik'
import React from 'react'

import { LevelType } from '@models/user-mission/LevelType'
import { UserMissionLevelFormType } from '@models/user-mission/UserMissionLevelFormType'
import {
  CreateUserMissionLevelButtonProps,
  WithCreateUserMissionLevelButtonProps,
} from './interface'

export function withCreateUserMissionLevelButton(
  Component: React.FC<CreateUserMissionLevelButtonProps>
) {
  function WithCreateUserMissionLevelButton({
    setEditId,
    setUserMissionForm,
  }: WithCreateUserMissionLevelButtonProps) {
    const { initialValues } = useFormikContext<UserMissionLevelFormType>()

    function addLevelForm() {
      setEditId(0)
      const newLevel = plainToInstance(LevelType, {
        id: 0,
        level: initialValues.userMissionLevels.length + 1,
        levelName: '',
        target: 0,
        iconUrl: '',
        isUsed: false,
        isActive: false,
        useCount: 0,
      })

      setUserMissionForm({
        userMissionLevels: [...initialValues.userMissionLevels, newLevel],
      })
    }

    const componentProps = {
      hideButton: initialValues.userMissionLevels.some(row => row.id === 0),
      addLevelForm,
    }

    return <Component {...componentProps} />
  }
  return WithCreateUserMissionLevelButton
}
