import { gql } from 'graphql-request'

export const GET_BOOK_READING = gql`
  query GetBookReading(
    $orderBy: BookOrderBy
    $limitPerPage: Int
    $page: Int
    $userId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getBookReading(
      orderBy: $orderBy
      limitPerPage: $limitPerPage
      page: $page
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      page
      total
      data {
        coverImgPath
        id
        title
        penNameId
        firstPenName
        chapterCount
        viewCount
        likeCount
        chapterReadCount
        bookType
      }
    }
  }
`
