import React, { useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import {
  ReportDetailActivityModalAcceptProps,
  ReportDetailActivityModalProps,
} from './interface'

export function withReportDetailActivityModal(
  Component: React.FC<ReportDetailActivityModalProps>
) {
  function WithReportDetailActivityModal({
    reportId,
  }: ReportDetailActivityModalAcceptProps) {
    const client = useClient()
    const {
      data: reportActivityLogs,
      isLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
    } = useInfiniteQuery(
      ['report-logs', reportId],
      ({ pageParam = 1 }) =>
        client!.reportClient.bookReportLogs({
          bookReportId: reportId,
          page: pageParam,
          limitPerPage: 10,
        }),
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )
    function handleFetchNextPage() {
      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
    }

    const data = useMemo(
      () => reportActivityLogs?.pages?.flatMap(page => page.data) ?? [],
      [reportActivityLogs]
    )

    const newProps = {
      data,
      isLoading,
      handleFetchNextPage,
    }
    return <Component {...newProps} />
  }
  return WithReportDetailActivityModal
}
