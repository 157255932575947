import { Expose, Transform, Type } from 'class-transformer'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'

class PromotionCodeDetail {
  @Expose() name: string

  @Expose() status: PromotionStatusEnum
}
export class PromotionCodeRequestListType {
  @Expose() id: number

  @Expose() createdAt: string

  @Expose()
  @Type(() => PromotionCodeDetail)
  promotion: PromotionCodeDetail
}
