import { gql } from 'graphql-request'

export const USER_CONSENTS = gql`
  query UserConsents(
    $limitPerPage: Int
    $page: Int
    $userId: Int!
    $orderBy: UserConsentOrderBy
  ) {
    userConsents(
      limitPerPage: $limitPerPage
      page: $page
      userId: $userId
      orderBy: $orderBy
    ) {
      data {
        id
        userId
        consentId
        createdAt
        consent {
          version
          type
        }
      }
      total
      page
    }
  }
`
