import React from 'react'
import ReactECharts from 'echarts-for-react'
import { DateTime } from 'luxon'

import { addComma, clearComma, formatDateDomini } from '@libs/utils'
import { EbookSalesGraphTypeEnum } from '@interfaces/EbookSalesGraphTypeEnum'
import { NoData } from '@components/NoData'
import { SaleAnalyticsChartProps } from './interface'

function SaleAnalyticsChart({
  data,
  graphType,
  style,
  tabId,
}: SaleAnalyticsChartProps) {
  const chartOption = {
    grid: {
      top: 10,
      bottom: 30,
      left: 50,
      right: 30,
    },
    tooltip: {
      trigger: 'axis',
      formatter: (chart: any) => {
        const tooltipDefault = `${formatDateDomini(
          chart[0].name
        )}: <strong>${addComma(chart[0].value)}</strong>`
        const tooltipSales = `<div style="display: flex;">${formatDateDomini(
          chart[0].name
        )}: <div style="padding: 0px 5px 0px 10px;"><strong>${
          chart[0].value
        }</strong></div>`
        if (tabId) {
          return tooltipSales
        }
        return tooltipDefault
      },
    },
    xAxis: {
      type: 'category',
      data: data.map(item => item.xValue.toLocaleString()),
      axisLabel: {
        formatter: (value: string) => {
          const val = clearComma(value)

          switch (graphType) {
            case EbookSalesGraphTypeEnum.HOUR:
              return DateTime.fromISO(value).toFormat('HH:mm')
            case EbookSalesGraphTypeEnum.DAY:
              return formatDateDomini(value)
            case EbookSalesGraphTypeEnum.WEEK:
              return formatDateDomini(value)
            case EbookSalesGraphTypeEnum.MONTH:
              return DateTime.fromISO(value).toFormat('LLL', {
                locale: 'th-TH',
              })
            case EbookSalesGraphTypeEnum.YEAR:
              return DateTime.fromISO(value).toFormat('yyyy', {
                locale: 'th-TH',
              })
            default:
              return value
          }
        },
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      lineStyle: {
        color: 'red',
        type: 'solid',
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
        },
      },
    },
    series: [
      {
        data: data.map(item => item.yValue),
        type: 'line',
        showSymbol: false,
        lineStyle: {
          width: 1,
          color: '#3f54d9',
        },
      },
    ],
    textStyle: {
      color: '#858baf',
    },
  }
  return (
    <div className='mt-[30px]'>
      {data.length === 0 ? (
        <NoData className='mt-[50px]' />
      ) : (
        <ReactECharts option={chartOption} style={style} />
      )}
    </div>
  )
}

export default SaleAnalyticsChart
