import React from 'react'

import { WriterReportProps } from './interface'

const withWriterReport = (Component: React.FC<WriterReportProps>) => {
  function WithWriterReport(props: WriterReportProps) {
    return <Component {...props} />
  }

  return WithWriterReport
}

export { withWriterReport }
