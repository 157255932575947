import { gql } from 'graphql-request'

export const GET_ALL_SALES_BY_USER_DETAIL = gql`
  query GetAllSalesByUserDetail(
    $userId: Int!
    $limitPerPage: Int
    $page: Int
    $orderBy: GetAllSalesByUserInputOrderedList
    $startDate: DateTime
    $endDate: DateTime
    $period: GetAllSalesByUserDetailPeriod!
  ) {
    getAllSalesByUserDetail(
      userId: $userId
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
      period: $period
    ) {
      username
      userId
      currentSales
      currentDonation
      currentSummary
      totalIncome
      detail {
        total
        page
        data {
          date
          transactionId
          sales
          donation
          description
        }
      }
    }
  }
`
