import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { InviteListType } from '@models/admin/InviteListType'
import { InviteEmailFormType } from '@models/admin/InviteEmailFormType'
import { SortByType, SortingType } from '@components/Table/interface'
import { AddAdminPageProps } from './interface'

export function withAddAdminPage(Component: React.FC<AddAdminPageProps>) {
  function WithAddAdminPage() {
    const client = useClient()

    const { isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const navigate = useNavigate()

    const [inviteList, setInviteList] = useState<InviteListType[]>([])
    const [selectedEmail, setSelectedEmail] = useState<string[]>([])

    const { data: roleOption } = useQuery(
      'roles',
      () => client?.adminClient.getRoles(),
      {
        enabled: isAuthenticated,
      }
    )

    function addInviteEmailHandler(value: InviteEmailFormType) {
      setInviteList([
        ...inviteList,
        {
          email: value.email,
          rolesId: roleOption ? roleOption[0].value : '',
        },
      ])
    }

    async function validateAdminEmail(value: InviteEmailFormType) {
      try {
        await client?.adminClient.validateAdminEmail(value.email)
        addInviteEmailHandler(value)
      } catch (error: any) {
        alert.error(getErrorMessage(error))
      }
    }

    const { mutate: createSticker, isLoading } = useMutation(
      () => client!.adminClient.createAdmin(inviteList),
      {
        onSuccess: () => {
          alert.success('เพิ่ม Admin สำเร็จ')
          navigate('/admin/admin-user-management')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )
    async function createAdminHandler() {
      createSticker()
    }

    function handleSelectAll() {
      if (inviteList.length === selectedEmail.length) {
        setSelectedEmail([])
      } else {
        setSelectedEmail(inviteList.map(row => row.email))
      }
    }

    function deleteSelectEmail() {
      const temps = [...inviteList]
      const newData = temps.filter(temp => {
        return (
          selectedEmail.filter(item => {
            return item === temp.email
          }).length === 0
        )
      })
      setInviteList(newData)
      setSelectedEmail([])
    }

    function handlerDelete(value: string) {
      const temps = [...inviteList]
      const newData = temps.filter(temp => temp.email !== value)
      setSelectedEmail(selectedEmail.filter(temp => temp !== value))
      setInviteList(newData)
    }

    function handleSelect(value: string) {
      const index = selectedEmail.findIndex((item: string) => item === value)

      if (index !== -1) {
        setSelectedEmail((prev: string[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedEmail((prev: string[]) => [...prev, value])
      }
    }

    function listOptionChange(value: string, i: number) {
      const temp = [...inviteList]
      temp[i].rolesId = value
      setInviteList(temp)
    }

    function handleSort({ key, order }: SortByType) {
      const temp = [...inviteList]
      if (key === 'email') {
        temp.sort((a, b) => {
          if (a.email < b.email) {
            if (order === SortingType.ASC) return -1
            return 1
          }
          if (a.email > b.email) {
            if (order === SortingType.ASC) return 1
            return -1
          }
          return 0
        })
      } else {
        temp.sort((a, b) => {
          if (a.rolesId < b.rolesId) {
            if (order === SortingType.ASC) return -1
            return 1
          }
          if (a.rolesId > b.rolesId) {
            if (order === SortingType.ASC) return 1
            return -1
          }
          return 0
        })
      }
      setInviteList(temp)
    }

    function clearInviteList() {
      setInviteList([])
    }

    const newProps = {
      navigate,
      inviteList,
      createAdminHandler,
      validateAdminEmail,
      selectedEmail,
      handleSort,
      roleOption: roleOption || [],
      handleSelectAll,
      deleteSelectEmail,
      handlerDelete,
      handleSelect,
      listOptionChange,
      isLoading,
      clearInviteList,
    }
    return <Component {...newProps} />
  }

  return WithAddAdminPage
}
