import { gql } from 'graphql-request'

export const GET_WRITER_LIST = gql`
  query GetUserManagement(
    $userId: Int!
    $writingListUserId: Float!
    $limitPerPage: Int
    $page: Int
    $bookType: [BookType!]
    $categoryIds: [Int!]
    $orderBy: writingListOrderBy
    $searchText: String
    $bookStatus: BookStatus
  ) {
    getUserManagement(userId: $userId) {
      writingList(
        userId: $writingListUserId
        limitPerPage: $limitPerPage
        page: $page
        bookType: $bookType
        categoryIds: $categoryIds
        orderBy: $orderBy
        searchText: $searchText
        bookStatus: $bookStatus
      ) {
        total
        page
        data {
          id
          penName {
            firstPenName
            id
          }
          coverImgPath
          title
          bookType
          chapterCount
          viewCount
          likeCount
          sumCharacterDonate
          amountSpent
        }
      }
      writingCountList(
        userId: $writingListUserId
        bookType: $bookType
        categoryIds: $categoryIds
        searchText: $searchText
      ) {
        publishedCount
        draftCount
      }
    }
  }
`
