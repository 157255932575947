import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { SearchParamsType } from '@features/promotionSetting/pages/PromotionCodeDetailPage/components/PromotionCodeTransaction/interface'
import { SaleChannelsOptionType } from '@models/promotion/SaleChannelOptionType'
import { CoinStatusEnum } from '@interfaces/CoinStatusEnum'
import { SortingEnum } from '@interfaces/SortingEnum'
import { CoinOptionType } from '@models/promotion/CoinOptionType'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { CodeChannelTypeEnum } from '@interfaces/promotionCode/CodeChannelTypeEnum '
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { PromotionCodeUploadType } from '@models/promotion/PromotionCodeUploadType'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { UniqeCodeTypeEnum } from '@interfaces/promotionCode/UniqeCodeTypeEnum'
import { GenerateCouponsType } from '@models/promotion/GenerateCouponsType'
import { PromotionCodeDetailType } from '@models/promotion/PromotionCodeDetailType'
import { PromotionCodeRequestType } from '@models/promotion/PromotionCodeRequestType'
import { PromotionCodeRequestListType } from '@models/promotion/PromotionCodeRequestListType'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { PromotionCodeLogCountType } from '@models/promotion/PromotionCodeLogCountType'
import { PromotionCodeLogResponseType } from '@models/promotion/PromotionCodeLogsType'
import { SALE_CHANNELS } from './schemas/saleChannels'
import { CREATE_SALE_CHANNELS } from './schemas/createSaleChannels'
import { COIN_OPTIONS } from './schemas/coinOptions'
import { CREATE_PROMOTION_CODE } from './schemas/createPromotionCode'
import { GET_PROMOTION_CODE_FORM } from './schemas/getPromotionCodeForm'
import { UPDATE_PROMOTION_CODE } from './schemas/updatePromotionCode'
import { UPLOAD_PROMOTION_CODE } from './schemas/uploadPromotionCode'
import { CHECK_COUPON_CODE } from './schemas/checkCouponCode'
import { GENNERATE_COUPONS } from './schemas/generateCoupons'
import { GET_GENNERATE_COUPONS } from './schemas/getGenerateCoupons'
import { GET_PROMOTION_CODE_DETAIL } from './schemas/getPromotionCodeDetail'
import { GET_PROMOTION_CODE_FORM_DETAIL } from './schemas/getPromotionCodeFormDetail'
import { GET_PROMOTION_CODE_HISTORY_BY_ID } from './schemas/getPromotionCodeHistory'
import { GET_PROMOTION_CODE_HISTORY_LIST } from './schemas/getPromotionCodeHistoryList'
import { PRMOTION_CODE_LOG_COUNT } from './schemas/promotionCodeLogCount'
import { PRMOTION_CODE_LOG } from './schemas/promotionCodeLogs'
import { PRMOTION_CODE_USED_LOG } from './schemas/promotionCodeUsedLogs'
import { PRMOTION_CODE_REMAINING_LOG } from './schemas/promotionCodeRemainingLogs'
import { GET_PROMOTION_CODE_DUP_FORM } from './schemas/getPromotionCodeDupForm'
import { EXPORT_PROMOTION_CODE } from './schemas/exportPromotionCode'

export class PromotionCodeClient {
  constructor(private client: GraphQLClient) {}

  async getSaleChannels(): Promise<SaleChannelsOptionType[]> {
    const { saleChannels } = await this.client.request(SALE_CHANNELS)
    return plainToInstance(
      SaleChannelsOptionType,
      saleChannels.filter(
        (item: any) => item.name !== 'Shopee' && item.name !== 'Lazada'
      ) as []
    )
  }

  async createSaleChannel(name: string): Promise<void> {
    await this.client.request(CREATE_SALE_CHANNELS, {
      createSaleChannelInput: {
        name,
      },
    })
  }

  async getCoinOptions(): Promise<CoinOptionType[]> {
    const { coins } = await this.client.request(COIN_OPTIONS, {
      orderBy: {
        createdAt: SortingEnum.ASC,
      },
      where: {
        status: CoinStatusEnum.ACTIVE,
        visible: true,
      },
    })
    return plainToInstance(CoinOptionType, coins.data as [])
  }

  async getPromotionCodeForm(id: number): Promise<PromotionCodeFormType> {
    const { promotion } = await this.client.request(GET_PROMOTION_CODE_FORM, {
      promotionId: id,
    })
    return plainToInstance(PromotionCodeFormType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionCodeDupForm(id: number): Promise<PromotionCodeFormType> {
    const { promotion } = await this.client.request(
      GET_PROMOTION_CODE_DUP_FORM,
      {
        promotionId: id,
      }
    )
    return plainToInstance(PromotionCodeFormType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionCodeFormDetail(id: number): Promise<PromotionCodeFormType> {
    const {
      promotion: {
        lastRequest: { newPromotion },
      },
    } = await this.client.request(GET_PROMOTION_CODE_FORM_DETAIL, {
      promotionId: id,
    })
    return plainToInstance(PromotionCodeFormType, newPromotion)
  }

  async getPromotionCodeDetail(id: number): Promise<PromotionCodeDetailType> {
    const { promotion } = await this.client.request(GET_PROMOTION_CODE_DETAIL, {
      promotionId: id,
    })
    return plainToInstance(PromotionCodeDetailType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionCodeHistoryList(
    id: number
  ): Promise<PromotionCodeRequestListType[]> {
    const { promotionRequestByPromotionId } = await this.client.request(
      GET_PROMOTION_CODE_HISTORY_LIST,
      {
        promotionId: id,
      }
    )
    return plainToInstance(
      PromotionCodeRequestListType,
      promotionRequestByPromotionId as [],
      {
        excludeExtraneousValues: true,
      }
    )
  }

  async getPromotionCodeHistoryById(
    id: number
  ): Promise<PromotionCodeRequestType> {
    const { promotionRequest } = await this.client.request(
      GET_PROMOTION_CODE_HISTORY_BY_ID,
      {
        promotionRequestId: id,
      }
    )
    return plainToInstance(PromotionCodeRequestType, promotionRequest, {
      excludeExtraneousValues: true,
    })
  }

  async getGenerateCouponStatus(id: number): Promise<GenerateCouponsType> {
    const { promotion } = await this.client.request(GET_GENNERATE_COUPONS, {
      promotionId: id,
    })
    return plainToInstance(GenerateCouponsType, promotion)
  }

  async createPromotionCode(values: PromotionCodeFormType): Promise<number> {
    const createPromotionCodeInput = await this.transformPromotionCodeInput(
      values,
      PromotionStatusEnum.DRAFT
    )
    const {
      createPromotionCode: { id },
    } = await this.client.request(CREATE_PROMOTION_CODE, {
      createPromotionCodeInput,
    })
    return id
  }

  async updatePromotionCode(
    values: PromotionCodeFormType,
    status: PromotionStatusEnum
  ): Promise<string> {
    const updatePromotionCodeInput = await this.transformPromotionCodeInput(
      values,
      status
    )
    const {
      updatePromotionCode: { message },
    } = await this.client.request(UPDATE_PROMOTION_CODE, {
      updatePromotionCodeInput: {
        ...updatePromotionCodeInput,
        id: values.promotionCodeId,
      },
    })
    return message
  }

  async createCodePromotionCode(
    codeValues: CreateCodeFormType,
    promotionValues: PromotionCodeFormType
  ): Promise<string> {
    const updatePromotionCodeInput = await this.transformPromotionCodeInput(
      promotionValues,
      PromotionStatusEnum.PENDING
    )
    const {
      updatePromotionCode: { message },
    } = await this.client.request(UPDATE_PROMOTION_CODE, {
      updatePromotionCodeInput: {
        ...updatePromotionCodeInput,
        id: promotionValues.promotionCodeId,
        couponType: codeValues.codeType,
        codesFilePath:
          codeValues.codeType === CodeTypeEnum.UNIQE &&
          codeValues.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE
            ? codeValues.codeFile?.url
            : undefined,
        codesFileName:
          codeValues.codeType === CodeTypeEnum.UNIQE &&
          codeValues.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE
            ? codeValues.codeFile?.name
            : undefined,
        coupons:
          codeValues.codeType === CodeTypeEnum.UNIVERSAL ||
          codeValues.uniqeCodeType === UniqeCodeTypeEnum.UPLOAD_CODE
            ? codeValues.coupons
            : undefined,
      },
    })
    return message
  }

  async uploadPromotionCode({
    file,
    promotionCodeId,
  }: {
    file: File | Blob
    promotionCodeId: number
  }): Promise<PromotionCodeUploadType> {
    const { uploadPromotionCodes } = await this.client.request(
      UPLOAD_PROMOTION_CODE,
      {
        promotionCodeId,
        file,
      }
    )
    return plainToInstance(PromotionCodeUploadType, uploadPromotionCodes)
  }

  async checkCouponCodeAlreadyExist(couponCode: string): Promise<boolean> {
    const { checkCouponCodeAlreadyExist } = await this.client.request(
      CHECK_COUPON_CODE,
      {
        couponCode,
      }
    )
    return checkCouponCodeAlreadyExist as boolean
  }

  async generateCoupons(promotionCodesId: number): Promise<string> {
    const { generateCoupons } = await this.client.request(GENNERATE_COUPONS, {
      generateCouponsInput: {
        promotionCodesId,
      },
    })
    return generateCoupons as string
  }

  async promotionCodeLogCount(
    promotionCodeId: number,
    searchText: string
  ): Promise<PromotionCodeLogCountType> {
    const { promotionCodeLogCount } = await this.client.request(
      PRMOTION_CODE_LOG_COUNT,
      {
        promotionCodeId,
        searchText,
      }
    )
    return plainToInstance(PromotionCodeLogCountType, promotionCodeLogCount)
  }

  async promotionCodeLogs(
    promotionCodeId: number,
    page: number,
    searchText: string
  ): Promise<PromotionCodeLogResponseType> {
    const { promotionCodeLogs } = await this.client.request(PRMOTION_CODE_LOG, {
      promotionCodeId,
      page,
      searchText,
      limitPerPage: 20,
    })
    return plainToInstance(PromotionCodeLogResponseType, promotionCodeLogs)
  }

  async promotionCodeUsedLogs(
    promotionCodeId: number,
    page: number,
    searchPrams: SearchParamsType
  ): Promise<PromotionCodeLogResponseType> {
    const { promotionCodeUsedLogs } = await this.client.request(
      PRMOTION_CODE_USED_LOG,
      {
        promotionCodeId,
        page,
        limitPerPage: 20,
        searchText: searchPrams.searchText,
        orderBy: searchPrams.sortKey
          ? { [searchPrams.sortKey]: searchPrams.sortOrder }
          : undefined,
      }
    )
    return plainToInstance(PromotionCodeLogResponseType, promotionCodeUsedLogs)
  }

  async promotionCodeRemainingLogs(
    promotionCodeId: number,
    page: number,
    searchText: string
  ): Promise<PromotionCodeLogResponseType> {
    const { promotionCodeRemainingLogs } = await this.client.request(
      PRMOTION_CODE_REMAINING_LOG,
      {
        promotionCodeId,
        page,
        searchText,
        limitPerPage: 20,
      }
    )
    return plainToInstance(
      PromotionCodeLogResponseType,
      promotionCodeRemainingLogs
    )
  }

  async transformPromotionCodeInput(
    values: PromotionCodeFormType,
    status: PromotionStatusEnum
  ) {
    if (values.codePurchase) {
      await this.createSaleChannel(values.codePurchase)
    }
    const promotionCodeInput = {
      name: values.promotionName,
      description: values.promotionDetail,
      userTerm: values.promotionCondition,
      startPublishedAt:
        values.publishedType === PromotionPublishedEnum.PERIOD
          ? values.startPublishedAt
          : values.startNowAt,
      endPublishedAt: values.endPublishedAt,
      publishedType: values.publishedType,
      userType: values.forUserType,
      isFirstTopUp: values.isFirstTopUp,
      usageLimitPerUser:
        values.limitJoinPromotionType === LimitToJoinEnum.LIMIT
          ? values.limitJoinPromotion
          : undefined,
      isUsageLimitPerUser:
        values.limitJoinPromotionType === LimitToJoinEnum.LIMIT,
      usageLimit: values.isLimitPromotionCode
        ? values.limitPromotionCode
        : undefined,
      isUsageLimit: values.isLimitPromotionCode,
      usageDay: values.availableDay,
      status,
      userIds: values.userIds,
      usersFileName:
        values.forUserType === PromotionUserEnum.SPECIFY_USER
          ? values.userIdsFile?.name
          : undefined,
      usersFilePath:
        values.forUserType === PromotionUserEnum.SPECIFY_USER
          ? values.userIdsFile?.url
          : undefined,
      type: values.userByType,
      buyCoinCondition:
        values.userByType === UserByEnum.BUY_COIN
          ? values.userBuyCoinCondition
          : undefined,
      buyCoinConditionValue:
        values.userByType === UserByEnum.BUY_COIN
          ? values.userBuyCoinInput
          : undefined,
      channels: [
        {
          name:
            values.codeChannelType === CodeChannelTypeEnum.SHOPEE
              ? 'Shopee'
              : values.codeChannelType === CodeChannelTypeEnum.LAZADA
              ? 'Lazada'
              : values.codeChannelSelect
              ? values.codeChannelSelect
              : values.codePurchase,
          totalCount: values.codeChannelInput,
          totalUsedCount: values.codeChannelTotalUsedCount,
        },
      ],
      paymentChannels:
        values.userByType === UserByEnum.BUY_COIN ? values.paymentMethod : [],
      benefitType: BuyCoinCodeTypeEnum.COIN,
      benefitValue:
        values.userByType === UserByEnum.BUY_COIN
          ? Number(values.moreCoinInput)
          : Number(values.directCoinInput),
      coinsId:
        values.userByType === UserByEnum.BUY_COIN
          ? values.moreCoinOption
            ? Number(values.moreCoinOption)
            : undefined
          : values.directCoinOption
          ? Number(values.directCoinOption)
          : undefined,
    }
    return promotionCodeInput
  }

  async exportPromotionCode({
    promotionCodeId,
    searchText,
  }: {
    promotionCodeId: number
    searchText: string
  }): Promise<void> {
    await this.client.request(EXPORT_PROMOTION_CODE, {
      promotionCodeId,
      searchText,
    })
  }
}
