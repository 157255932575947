import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'

import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { SettingPromotionStatusEnum } from '@interfaces/SettingPromotionStatusEnum'
import { PromotionStatusEnum } from '@interfaces/PromotionStatusEnum'
import { SettingPromotionOrderEnum } from '@interfaces/SettingPromotionOrderEnum'
import { PromotionSettingListType } from '@models/promotion/PromotionSettingListType'
import { PromotionTypeEnum } from '@interfaces/PromotionTypeEnum'
import { PromotionUserIdsUploadType } from '@models/promotion/PromotionUserIdsUploadType'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionHistoryType } from '@models/promotion/PromotionHistoryType'
import { GET_PROMOTIONS } from './schemas/getPromotions'
import { DELETE_PROMOTION } from './schemas/deletePromotion'
import { TOGGLE_PROMOTION } from './schemas/togglePromotionIsActive'
import { UPDATE_PROMOTION_REQUEST } from './schemas/updatePromotionRequest'
import { UPLOAD_PROMOTION_USERS } from './schemas/uploadPromotionUsers'
import { CHECK_USER_EXIST } from './schemas/checkUserExist'
import { GET_TOTAL_PROMOTIONS } from './schemas/getTotalPromotionList'
import { GET_PROMOTION_REQUEST_BY_PROMOTION_ID } from './schemas/getPromotionRequestByPromotionId'
import { CHECK_BOOK_EXIST } from './schemas/checkBookExist'
import { CHECK_WRITER_EXIST } from './schemas/checkWriterExist'

export class PromotionClient {
  constructor(private client: GraphQLClient) {}

  async getPromotions({
    limitPerPage,
    page,
    searchText,
    startDate,
    endDate,
    type,
    status,
    sort,
    activeTab,
  }: {
    limitPerPage: number
    page: number
    searchText: string
    startDate?: Date
    endDate?: Date
    type: SettingPromotionMenuTypeEnum
    status: SettingPromotionStatusEnum
    sort: SettingPromotionOrderEnum
    activeTab?: PromotionChallengeActionEnum
  }): Promise<PromotionSettingListType> {
    const { promotions } = await this.client.request(GET_PROMOTIONS, {
      limitPerPage,
      page,
      searchText,
      startPublishedAt: startDate
        ? DateTime.fromJSDate(startDate).startOf('day').toUTC()
        : undefined,
      endPublishedAt: endDate
        ? DateTime.fromJSDate(endDate).endOf('day').toUTC()
        : undefined,
      type: type === SettingPromotionMenuTypeEnum.ALL ? null : type,
      status: status === SettingPromotionStatusEnum.ALL ? null : status,
      orderBy: {
        createdAt:
          sort === SettingPromotionOrderEnum.CREATED ? 'DESC' : undefined,
        startPublishedAt:
          sort === SettingPromotionOrderEnum.ASC
            ? 'ASC'
            : sort === SettingPromotionOrderEnum.DESC
            ? 'DESC'
            : undefined,
        endPublishedAt:
          sort === SettingPromotionOrderEnum.ENDED ? 'ASC' : undefined,
      },
      challengeAction: activeTab === 'ALL' ? null : activeTab,
    })
    return plainToInstance(PromotionSettingListType, promotions)
  }

  async getTotalPromotions(): Promise<number> {
    const {
      promotions: { total },
    } = await this.client.request(GET_TOTAL_PROMOTIONS, {
      limitPerPage: 10,
      page: 1,
      searchText: '',
      startPublishedAt: undefined,
      endPublishedAt: undefined,
      type: null,
      status: null,
      orderBy: { createdAt: 'DESC' },
    })
    return total
  }

  async togglePromotionIsActive(
    isActive: boolean,
    id: number
  ): Promise<number> {
    const { togglePromotionIsActive } = await this.client.request(
      TOGGLE_PROMOTION,
      {
        isActive,
        togglePromotionIsActiveId: id,
      }
    )
    return togglePromotionIsActive.id
  }

  async deletePromotion(id: number): Promise<void> {
    await this.client.request(DELETE_PROMOTION, {
      id,
    })
  }

  async updatePromotionRequest(updatePromotionRequestInput: {
    id: number
    status:
      | PromotionStatusEnum.PENDING
      | PromotionStatusEnum.APPROVED
      | PromotionStatusEnum.REJECTED
    rejectedReason?: string
  }): Promise<void> {
    await this.client.request(UPDATE_PROMOTION_REQUEST, {
      updatePromotionRequestInput,
    })
  }

  async uploadPromotionUsers({
    file,
    promotionType,
  }: {
    file: File | Blob
    promotionType: PromotionTypeEnum
  }): Promise<PromotionUserIdsUploadType> {
    const { uploadPromotionUsers } = await this.client.request(
      UPLOAD_PROMOTION_USERS,
      {
        promotionType,
        file,
      }
    )
    return plainToInstance(PromotionUserIdsUploadType, uploadPromotionUsers)
  }

  async checkUserExist(userIds: number[]): Promise<boolean> {
    const { checkUserExist } = await this.client.request(CHECK_USER_EXIST, {
      userIds,
    })
    return checkUserExist as boolean
  }

  async getPromotionHistoryList(
    promotionId: number
  ): Promise<PromotionHistoryType[]> {
    const { promotionRequestByPromotionId } = await this.client.request(
      GET_PROMOTION_REQUEST_BY_PROMOTION_ID,
      {
        promotionId,
      }
    )
    return plainToInstance(
      PromotionHistoryType,
      promotionRequestByPromotionId as []
    )
  }

  async checkBookExist(bookIds: number[]): Promise<boolean> {
    const { checkBookExist } = await this.client.request(CHECK_BOOK_EXIST, {
      bookIds,
    })
    return checkBookExist as boolean
  }

  async checkWriterExist(writerIds: number[]): Promise<boolean> {
    const { checkWriterExist } = await this.client.request(CHECK_WRITER_EXIST, {
      writerIds,
    })
    return checkWriterExist as boolean
  }
}
