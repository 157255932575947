import cn from 'classnames'
import Modal from '@components/Modal/Modal'
import { PaperclipIcon } from '@components/Icons'
import { ImageViewerProps } from './interface'

export function ImageViewer({
  src,
  alt,
  label,
  name,
  className,
  isViewerOpen,
  isPdf,
  handleOpenModal,
  handleCloseModal,
}: ImageViewerProps) {
  return (
    <div>
      {label && (
        <div className='text-[12px] font-light mb-[10px] text-secondary-100'>
          {label}
        </div>
      )}
      {!isPdf && (
        <a href={src} target='_blank' rel='noreferrer'>
          <img
            className={cn(
              'w-full h-full object-cover rounded-[8px]',
              className
            )}
            src={src}
            alt={alt}
          />
        </a>
      )}
      {name && (
        <div className='flex gap-x-[5px] mt-[10px]'>
          <PaperclipIcon width='16' height='16' className='text-primary' />
          {isPdf ? (
            <a
              className='text-[12px] font-light text-primary underline'
              href={src}
              target='_blank'
              rel='noreferrer'
            >
              {name}
            </a>
          ) : (
            <p className='text-[12px] font-light text-primary underline'>
              {name}
            </p>
          )}
        </div>
      )}
      <Modal isOpen={isViewerOpen} onClose={handleCloseModal}>
        <img src={src} className='w-full h-full object-cover' alt={alt} />
      </Modal>
    </div>
  )
}
