import React from 'react'
import { useField } from 'formik'
import { RadioInputFieldProps } from './interface'

export function withRadioInputField(Component: React.FC<RadioInputFieldProps>) {
  function WithRadioInputField({
    name,
    value,
    ...props
  }: RadioInputFieldProps) {
    const [field] = useField(name)
    const componentProps = {
      ...props,
      ...field,
      value,
    }

    return <Component {...componentProps} />
  }

  return WithRadioInputField
}
