import React, { useState } from 'react'

import { ByUserFormProps, WithByUserFormProps } from './interface'

export function withByUserForm(Component: React.FC<ByUserFormProps>) {
  function WithByUserForm({ onResetForm, ...props }: WithByUserFormProps) {
    const [text, setText] = useState('')
    function resetHandler() {
      setText('')
      onResetForm()
    }
    const newProps = {
      text,
      setText,
      resetHandler,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithByUserForm
}
