import React from 'react'

import { MainBannerFormProps } from './interface'

export function withMainBannerForm(Component: React.FC<MainBannerFormProps>) {
  function WithMainBannerForm(props: MainBannerFormProps) {
    return <Component {...props} />
  }

  return WithMainBannerForm
}
