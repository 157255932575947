import { Expose } from 'class-transformer'

export class EbookOverviewType {
  @Expose() alreadyPurchasedCount: number

  @Expose() downloadedCount: number

  @Expose() freeAlreadyPurchasedCount: number

  @Expose() freeDownloadedCount: number

  @Expose() mangaEbookCount: number

  @Expose() mangaEbookPublishedCount: number

  @Expose() mangaEbookPurchasedCount: number

  @Expose() mangaEbookWriterIncome: number

  @Expose() novelEbookCount: number

  @Expose() novelEbookPublishedCount: number

  @Expose() novelEbookPurchasedCount: number

  @Expose() novelEbookWriterIncome: number
}
