import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { BookType } from '@models/book/BookType'
import { ActionStatusEnum } from '@interfaces/highlightBanner/ActionStatusEnum'
import { CreateHighlightBannerPosterPageProps } from './interface'

export function withCreateHighlightBannerBookPage(
  Component: React.FC<CreateHighlightBannerPosterPageProps>
) {
  function WithCreateHighlightBannerBookPage() {
    const { id, position } = useParams()
    const navigate = useNavigate()
    const client = useClient()
    const alert = useAlert()

    const { mutate: createBookBanner } = useMutation(
      (form: { highlightGroupId: number; bookIds: number[] }) =>
        client!.highlightBannerClient.createGroupBookBanner(
          form.highlightGroupId,
          form.bookIds
        ),
      {
        onSuccess: () => {
          alert.success('บันทึกเรียบร้อย')
          navigate(`/home-page/highlight-banner/${position}/book/${id}`)
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(statusBooks: BookType[]) {
      const createItems = statusBooks.filter(
        item => item.actionStatus === ActionStatusEnum.ADD
      )

      createBookBanner({
        highlightGroupId: Number(id),
        bookIds: createItems.map(param => param.id),
      })
    }

    const componentProps = {
      data: [],
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithCreateHighlightBannerBookPage
}
