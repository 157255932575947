import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInputField } from '@components/forms/TextInputField'
import { Button } from '@components/Button'
import { StorySettingFormProps } from './interface'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  maxCharacter: Yup.number().min(0).default(200).required(),
})

export function StorySettingForm({
  initialValues,
  onSubmit,
}: StorySettingFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <div className='flex justify-between px-[30px] py-[30px] border rounded-[8px] mb-[30px]'>
            <div className='w-[740px]'>
              <TextInputField
                className='text-secondary'
                name='name'
                label='ชื่อแบบฟอร์ม'
                placeholder='ชื่อแบบฟอร์ม'
              />
            </div>
            <div className='w-auto'>
              <TextInputField
                className='text-secondary'
                name='maxCharacter'
                label='จำกัดจำนวนตัวอักษร'
                rightIcon={<p className='text-secondary-100'>ตัวอักษร</p>}
                type='number'
              />
            </div>
          </div>
          <div className='flex justify-center gap-x-[20px]'>
            <Button
              type='reset'
              className='border border-secondary-100 text-secondary-100  w-[100px]'
              disabled={isSubmitting || !dirty}
            >
              <span>ยกเลิก</span>
            </Button>
            <Button
              type='submit'
              className='w-[126px] text-white bg-primary w-[126px]'
              disabled={isSubmitting || !dirty}
            >
              <span>บันทึก</span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
