import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import {
  OrderByEnum,
  PublisherParam,
} from '@interfaces/dashboard/PublisherParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { PublisherProps, WithPublisherProps } from './interface'

export function withPublisher(Component: React.FC<PublisherProps>) {
  function WithPublisher({ searchParam }: WithPublisherProps) {
    const client = useClient()
    const [publisherParam, setPublisherParam] = useState<PublisherParam>({
      orderBy: OrderByEnum.TOTAL_SEARCH,
      orderDirection: SortingType.DESC,
    })

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        ['search-result-by-publisher', searchParam, publisherParam],
        async ({ pageParam = 1 }) => {
          const res = await client?.dashboardClient.searchResultByPublisher({
            ...searchParam,
            ...publisherParam,
            page: pageParam,
          })

          return res
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setPublisherParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      isLoading: isLoading || isFetchingNextPage,
      handleSortChange,
      handleWaypointEnter,
    }

    return <Component {...componentProps} />
  }

  return WithPublisher
}
