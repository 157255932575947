import { Formik, Form } from 'formik'

import { Button } from '@components/Button'
import { TextInputField } from '@components/forms/TextInputField'
import { UploadImageField } from '@components/forms/UploadImageField'
import { CheckboxField } from '@components/forms/CheckboxField'
import { validationSchema } from './validationSchema'
import { MainBannerFormProps } from './interface'
import { PublishSection } from '../PublishSection'

export function MainBannerForm({
  initialValues,
  isEdit = false,
  onSubmit,
}: MainBannerFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ resetForm, isValid, dirty, isSubmitting, values }) => {
        return (
          <Form>
            <section className='flex flex-col gap-y-[30px] border border-blue-2 rounded-lg p-[30px] shadow'>
              <UploadImageField
                textSize='2100 X 900'
                name='coverImg'
                textBtn='อัปโหลดรูปภาพแบนเนอร์'
                textReUploadBtn='เปลี่ยนรูปภาพแบนเนอร์'
                className='w-full'
                ratio={9 / 21}
              />
              <TextInputField
                name='description'
                label='Description (alt text)'
                placeholder='Description (alt text)'
              />
              <TextInputField
                type='url'
                name='rawLink'
                label='Link'
                placeholder='Link'
              />
              <hr />
              <div className='flex items-center gap-x-[16px] text-[12px] font-light text-secondary-100'>
                <div>Display :</div>
                <CheckboxField name='web' label='Web' checked={values.web} />
                <CheckboxField name='ios' label='iOS' checked={values.ios} />
                <CheckboxField
                  name='android'
                  label='Android'
                  checked={values.android}
                />
              </div>
              <hr />
              <PublishSection />
            </section>

            <section className='flex justify-center gap-x-5 my-[30px]'>
              <Button
                type='button'
                className='border border-secondary-100 text-[14px] text-secondary-100 min-w-[100px]'
                onClick={() => resetForm()}
              >
                ยกเลิก
              </Button>

              <Button
                type='submit'
                className='border border-secondary bg-secondary text-[14px] text-white min-w-[100px] disabled:border-primary disabled:bg-primary disabled:cursor-not-allowed'
                disabled={isSubmitting || !(isValid && dirty)}
              >
                บันทึก
              </Button>
            </section>
          </Form>
        )
      }}
    </Formik>
  )
}
