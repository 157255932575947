import { Children, memo } from 'react'
import { Waypoint } from 'react-waypoint'
import get from 'lodash.get'
import cn from 'classnames'

import { SortDownIcon } from '@components/Icons'
import { NoData } from '@components/NoData'
import { TableProps, ColumnType, SortingType } from './interface'

function renderCell<A extends ColumnType<D>, D>(item: A, d: D, i: number) {
  if (item.cell && item.accessor) {
    return item.cell({
      obj: d,
      value: get(d, item.accessor, undefined),
      i,
    })
  }
  if (item.isAction && item.actionCell) {
    return item.actionCell({ obj: d })
  }
  return null
}

function Table<T>({
  className,
  data,
  columns,
  sortKey,
  sortOrder,
  headerClassName = '',
  bodyClassName = '',
  fixedHeader = false,
  isLoading = false,
  handleSort,
  onWaypointEnter,
}: TableProps<T>) {
  return (
    <div
      className={cn(className, {
        'overflow-y-auto': fixedHeader,
      })}
    >
      <table className='w-full table-auto'>
        <thead className={cn({ 'sticky top-0 ': fixedHeader })}>
          <tr
            className={cn(
              {
                'border-b border-gray text-blue-3 text-[12px] font-bold':
                  !headerClassName,
              },
              headerClassName
            )}
          >
            {Children.toArray(
              columns.map(item => {
                const columnSortKey = item.sortKey || item.accessor
                const isSorted = sortKey === columnSortKey
                return (
                  <th
                    className={cn(
                      'text-left py-[10px] bg-white',
                      item.cellClassName
                    )}
                  >
                    <div
                      className={cn('flex items-center space-x-[4px]', {
                        'cursor-pointer': item.isSortable,
                      })}
                      onClick={() => {
                        if (item.isSortable && columnSortKey && data.length) {
                          handleSort(columnSortKey)
                        }
                      }}
                    >
                      {item.column}
                      {item.isSortable && (
                        <SortDownIcon
                          className={cn('shrink-0 ', {
                            'text-gray': !isSorted,
                            'text-black-970': isSorted,
                            'rotate-180':
                              isSorted && sortOrder === SortingType.DESC,
                          })}
                          width='18'
                          height='18'
                        />
                      )}
                    </div>
                  </th>
                )
              })
            )}
          </tr>
        </thead>
        <tbody>
          {Children.toArray(
            data.map((d, i) => (
              <tr
                key={`${Object.values(d as any)[0]}-${i}`}
                className={cn(
                  {
                    'border-b border-gray text-[14px] font-light text-secondary':
                      !bodyClassName,
                  },
                  bodyClassName
                )}
              >
                {Children.toArray(
                  columns.map((item, index) => (
                    <td
                      key={`${item.accessor}-${index}`}
                      className={cn(
                        { 'py-[10px]': !item.cellClassName },
                        item.cellClassName
                      )}
                    >
                      {renderCell(item, d, i)}
                    </td>
                  ))
                )}
              </tr>
            ))
          )}
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <div className='w-full my-[20px] flex justify-center'>
                  <div className='h-[30px] w-[30px] rounded-full border-2 border-x-secondary border-t-secondary animate-spin' />
                </div>
              </td>
            </tr>
          )}
          {!isLoading && data.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>
                <NoData />
              </td>
            </tr>
          ) : null}
          {onWaypointEnter && (
            <tr>
              <td>
                <Waypoint onEnter={onWaypointEnter} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const MemoizedTable = memo(Table)
export { MemoizedTable as Table }
