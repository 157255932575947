import React from 'react'
import { Field } from 'formik'

import { FieldPropsType } from '@interfaces/commons/FieldPropsType'
import { SelectFieldAcceptProps, SelectFieldProps } from './interface'

const withSelectField = (Component: React.FC<SelectFieldProps>) => {
  function Hoc({ name, onChange, options, ...props }: SelectFieldAcceptProps) {
    return (
      <Field name={name}>
        {({ field, meta, form }: FieldPropsType<any>) => (
          <Component
            {...props}
            {...field}
            options={options}
            setValue={value => {
              form.setFieldValue(name, value)
              setTimeout(() => {
                form.setFieldTouched(name, true)
              })
              if (onChange) {
                onChange(value)
              }
            }}
            error={meta.error}
            touched={meta.touched}
          />
        )}
      </Field>
    )
  }

  return Hoc
}

export { withSelectField }
