import cn from 'classnames'
import { Container } from '@components/Container'
import { Search } from '@components/Search'
import { Pagination } from '@components/Pagination'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { SalesByUserPageProps } from './interface'
import { SalesByUserTable } from './components/SalesByUserTable'
import { UserStopSapPaymentTable } from './components/UserStopSapPaymentTable'

export function SalesByUserPage({
  activeTab,
  data,
  userStopSapPaymentData,
  page,
  perpage,
  total,
  dataTotal,
  isLoading,
  isUserStopSapPaymentData,
  searchParams,
  refetchAllSalesByUser,
  refetchUserStopSapPayment,
  handleSubmitSearch,
  handleResetSearch,
  handleUpdateActiveTab,
  handleSort,
  pageChange,
}: SalesByUserPageProps) {
  const menusArray = [
    {
      value: UserManageMentSearchEnum.ALL,
      text: 'ทั้งหมด',
      total: dataTotal.allCount,
    },
    {
      value: UserManageMentSearchEnum.PUBLISHER,
      text: 'สำนักพิมพ์',
      total: dataTotal.publisherCount,
    },
    {
      value: UserManageMentSearchEnum.WRITER,
      text: 'นักเขียน',
      total: dataTotal.writerCount,
    },
    {
      value: UserManageMentSearchEnum.READER,
      text: 'นักอ่าน',
      total: dataTotal.readerCount,
    },
    {
      value: UserManageMentSearchEnum.PAYMENT,
      text: 'ปิดการจ่ายเงิน',
      total: 0,
    },
  ]
  return (
    <Container className='flex flex-col gap-y-[30px]'>
      <h1 className='font-mitr text-[24px] font-medium text-secondary'>
        Sales By User
      </h1>
      <Search
        defaultValue={searchParams.searchText}
        placeholder='ค้นหาด้วยชื่อ หรือ ID'
        onSearch={handleSubmitSearch}
        onReset={handleResetSearch}
      />
      <div
        className={cn(
          'text-[14px] text-secondary font-light gap-x-[15px] flex border-b border-gray'
        )}
      >
        {menusArray.map(item => (
          <button
            key={item.value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  item.value === activeTab,
                'text-secondary-100 font-light': item.value !== activeTab,
              }
            )}
            onClick={() => handleUpdateActiveTab(item.value)}
          >
            {item.text}
            {item.value !== UserManageMentSearchEnum.PAYMENT && (
              <span className='font-light pl-[5px]'>
                ({item.total.toLocaleString()})
              </span>
            )}
          </button>
        ))}
      </div>
      {activeTab !== UserManageMentSearchEnum.PAYMENT ? (
        <SalesByUserTable
          data={data}
          isLoading={isLoading}
          activeTab={activeTab}
          searchParams={searchParams}
          handleSort={handleSort}
          refetchAllSalesByUser={refetchAllSalesByUser}
        />
      ) : (
        <UserStopSapPaymentTable
          data={userStopSapPaymentData}
          isLoading={isUserStopSapPaymentData}
          activeTab={activeTab}
          searchParams={searchParams}
          handleSort={handleSort}
          refetchUserStopSapPayment={refetchUserStopSapPayment}
        />
      )}
      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </Container>
  )
}
