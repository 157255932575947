import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import { usePagination } from '@hooks/usePagination'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { queryClient } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { TopicEnum } from '@interfaces/TopicEnum'
import { ReportListTableAcceptProps, ReportListTableProps } from './interface'
import { useBookReportsColumns } from './useBookReportsColumns'

export function withReportListTable(Component: React.FC<ReportListTableProps>) {
  function WithReportListTable({
    textSearch,
    tabId,
    activeTab,
  }: ReportListTableAcceptProps) {
    const client = useClient()
    const alert = useAlert()
    const { isAuthenticated } = useAuthentication()
    const [sortParams, setSortParams] = useState({
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })
    const [selectedId, setSelectedId] = useState<number[]>([])
    const [selectedTopic, setSelectedTopic] = useState(TopicEnum.ALL)
    const { page, perpage, pageChange } = usePagination({})

    const { data: bookReports, isLoading } = useQuery(
      [
        'book-reports',
        tabId,
        selectedTopic,
        page,
        textSearch,
        sortParams,
        activeTab,
      ],
      () =>
        client?.reportClient.bookReports({
          page,
          limitPerPage: perpage,
          search: textSearch,
          where: {
            status:
              tabId !== ReportStatusEnum.ALL && tabId !== ReportStatusEnum.BANED
                ? tabId
                : undefined,
            title: selectedTopic !== TopicEnum.ALL ? selectedTopic : undefined,
          },
          type: activeTab,
          isVisible: tabId === ReportStatusEnum.BANED ? false : undefined,
          ...sortParams,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    function handleSelectAll() {
      if (bookReports?.data) {
        if (bookReports.data.length === selectedId.length) {
          setSelectedId([])
        } else {
          setSelectedId(bookReports.data.map(row => row.id))
        }
      }
    }

    function handleSelect(value: number) {
      const index = selectedId.findIndex(item => item === value)
      if (index !== -1) {
        setSelectedId(prev => {
          const temp = [...prev]
          temp.splice(index, 1)
          return temp
        })
      } else {
        setSelectedId(prev => [...prev, value])
      }
    }

    function handleSelectTopic(topic: TopicEnum) {
      setSelectedTopic(topic)
    }

    function handleSort({ key, order }: SortByType) {
      setSortParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function handlePageChange(newPage: number) {
      setSelectedId([])
      pageChange(newPage)
    }

    const { mutate: bulkUpdateBookReports, isLoading: isUpdating } =
      useMutation(
        (ids: number[]) => client!.reportClient.bulkUpdateBookReports(ids),
        {
          onSuccess: () => {
            alert.success(`อัปเดตสถานะรายงานสำเร็จ`)
            setSelectedId([])
            queryClient.invalidateQueries(['book-reports', tabId])
          },
          onError: () => {
            alert.error(`เกิดข้อผิดพลาด`)
          },
        }
      )

    function handleUpdateStatusToSuccess() {
      const selectData = bookReports?.data.filter(report =>
        selectedId.find(id => id === report.id)
      )

      if (selectData?.length && selectData.length > 0) {
        if (selectData.every(data => data.status === 'SUCCESS')) {
          alert.info('ข้อมูลที่เลือกถูกดำเนินการสำเร็จแล้ว')
        } else {
          bulkUpdateBookReports(selectedId)
        }
      }
    }

    const { columns } = useBookReportsColumns(
      selectedId,
      bookReports?.data.length || 0,
      handleSelectAll,
      handleSelect
    )

    // const { Table } = useTable<ReportType>({
    //   data: bookReports?.data || [],
    //   columns,
    // })

    useEffect(() => {
      handlePageChange(1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch, selectedTopic])

    const componentProps = {
      page,
      perpage,
      total: bookReports?.total || 0,
      selectedId,
      selectedTopic,
      data: bookReports?.data || [],
      handlePageChange,
      handleSort,
      handleSelect,
      handleSelectAll,
      handleSelectTopic,
      handleUpdateStatusToSuccess,
      isLoading,
      columns,
    }

    return <Component {...componentProps} />
  }

  return WithReportListTable
}
