import { Container } from '@components/Container'
import { BannerEnum } from '@interfaces/BannerEnum'
import { HighlightBannerForm } from '@features/home/components/HighlightBannerForm'
import { CreateHighlightBannerPageProps } from './interface'

export function CreateHighlightBannerPage({
  type,
  initialValues,
  onSubmit,
}: CreateHighlightBannerPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between mb-5'>
        <Container.Title showBackBtn>
          สร้างแบนเนอร์แบบ
          {type === BannerEnum.POSTER ? ' Poster' : 'ปกหนังสือ'}
        </Container.Title>
      </section>

      <HighlightBannerForm initialValues={initialValues} onSubmit={onSubmit} />
    </Container>
  )
}
