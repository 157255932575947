import { withPermissionGuard } from '@libs/permission'
import { NewsAndPromotionListPage } from './NewsAndPromotionListPage'
import { withNewsAndPromotionListPage } from './withNewsAndPromotionListPage'

const ConnectedNewsAndPromotionListPage = withPermissionGuard(
  withNewsAndPromotionListPage(NewsAndPromotionListPage),
  'newsPromotion',
  'view'
)

export { ConnectedNewsAndPromotionListPage as NewsAndPromotionListPage }
