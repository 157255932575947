import { gql } from 'graphql-request'

export const FIND_ONE_NEWS = gql`
  query FindOneNews($findOneNewsId: Int!) {
    findOneNews(id: $findOneNewsId) {
      id
      bannerImgPath
      thumbnailImgPath
      name
      slug
      description
      content
      isActive
      isWeb
      isIos
      isAndroid
      startPublishedAt
      endPublishedAt
      newsType
      newsLogs {
        id
        user {
          displayName
          avatarImgPath
        }
        createdAt
      }
      isCreateNoti
    }
  }
`
