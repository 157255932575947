export enum SalesEbookPromotionStatusEnum {
  PROMOTION_NOT_START_YET = 'PROMOTION_NOT_START_YET',
  PROMOTION_PERIOD = 'PROMOTION_PERIOD',
  PROMOTION_ENDED = 'PROMOTION_ENDED',
  PROMOTION_CLOSED = 'PROMOTION_CLOSED',
}

export const ebookSalesStatusValue = {
  [SalesEbookPromotionStatusEnum.PROMOTION_NOT_START_YET]:
    'ยังไม่ถึงช่วงโปรโมชั่น',
  [SalesEbookPromotionStatusEnum.PROMOTION_PERIOD]: 'อยู่ในช่วงโปรโมชั่น',
  [SalesEbookPromotionStatusEnum.PROMOTION_ENDED]: 'หมดอายุ',
  [SalesEbookPromotionStatusEnum.PROMOTION_CLOSED]: 'ปิดโปรโมชั่น',
}
