import cn from 'classnames'
import { Button } from '@components/Button'
import { ExportIcon } from '@components/Icons'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { addComma } from '@libs/utils'
import { ExportButton } from '@components/ExportButton'
import { SelectButton } from './components/SelectButton'
import { TotalDetailProps } from './interface'

export function TotalDetail({
  currentSales,
  currentDonation,
  currentSummary,
  totalSpend,
  setValue,
  handleExport,
}: TotalDetailProps) {
  const permission = usePermissionAction('user')
  return (
    <div>
      <div className='w-full border-b-[1px] border-gray rounded-lg py-[20px] mb-[10px]'>
        <div className='flex justify-between'>
          <div className='text-secondary-100'>
            <div className='text-[14px]'>ยอดขายปัจจุบัน</div>
            <div className='text-[24px] font-bold text-black-480'>
              {addComma(currentSales || 0)}
            </div>
          </div>
          <div className='text-secondary-100'>
            <div className='text-[14px]'>ยอดสนับสนุนปัจจุบัน</div>
            <div className='text-[24px] font-bold text-black-480'>
              {addComma(currentDonation || 0)}
            </div>
          </div>
          <div className=''>
            <div className='text-[14px] text-secondary-100'>ยอดรวมปัจจุบัน</div>
            <div className='text-[24px] text-black-480 font-bold'>
              {addComma(currentSummary || 0)}
            </div>
          </div>
          <div className=''>
            <div className='text-[14px] text-secondary-100'>ยอดจ่ายเงินรวม</div>
            <div className='text-[24px] font-bold text-primary'>
              {addComma(totalSpend || 0)}
            </div>
          </div>
          <div className='grid grid-cols-1 mx-[10px]'>
            <div className='border-l' />
          </div>
          <div>
            <SelectButton
              label='เลือกดู'
              className='w-[167px]'
              setValue={setValue}
            />
          </div>
          <div>
            <ExportButton
              className={cn(
                'border !border-black-480 !rounded-3xl !bg-secondary px-5 py-2 text-sm text-white mt-[20px]',
                {
                  hidden: !permission.download,
                }
              )}
              icon={<ExportIcon width='24' height='24' />}
              handleClick={handleExport}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
