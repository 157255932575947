import { ColumnType } from '@components/Table/interface'
import { PenNameListType } from '@models/user/PenNameListType'
import { ActionCell } from './components/ActionCell'

export const columns: ColumnType<PenNameListType>[] = [
  {
    column: 'Penname ID',
    accessor: 'id',
    cell: ({ value }) => <p className='max-w-[300px] truncate'>{value}</p>,
    cellClassName: 'w-[350px] pr-[20px]',
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    cell: ({ value }) => <p className='max-w-[300px] truncate'>{value}</p>,
    cellClassName: 'w-[350px]',
    isSortable: true,
  },
  {
    column: 'จำนวนงานเขียน',
    accessor: 'totalOverallBook',
    cell: ({ value }) => (
      <p>{value ? (value as number).toLocaleString() : 0}</p>
    ),
    cellClassName: ' pr-[20px]',
    isSortable: true,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell data={obj} />,
    cellClassName: 'flex justify-end  py-[6.5px]',
    isAction: true,
  },
]
