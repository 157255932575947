import { Expose, Type } from 'class-transformer'
import { PermissionType } from '@models/rolePermission/PermissionType'

export class RolePermissionFormType {
  @Expose() name: string

  @Expose()
  @Type(() => PermissionType)
  permissions: PermissionType[]
}
