export enum PaymentMethodEnum {
  CARD = 'CARD',
  QR_PROMPT_PAY = 'QR_PROMPT_PAY',
  SHOPEE_PAY = 'SHOPEE_PAY',
  TRUE_MONEY = 'TRUE_MONEY_WALLET',
  IN_APP_PURCHASE = 'IN_APP_PURCHASE',
  INTERNET_BANKING = 'INTERNET_BANKING',
}

export const PaymentMethodTypeValue = {
  [PaymentMethodEnum.CARD]: 'Debit/ Creadit',
  [PaymentMethodEnum.QR_PROMPT_PAY]: 'Prompt Pay',
  [PaymentMethodEnum.SHOPEE_PAY]: 'Shopee Pay',
  [PaymentMethodEnum.TRUE_MONEY]: 'True Money Wallet',
  [PaymentMethodEnum.IN_APP_PURCHASE]: 'In App',
  [PaymentMethodEnum.INTERNET_BANKING]: 'Internet Banking',
}
