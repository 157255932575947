import React from 'react'
import cn from 'classnames'

import { SearchIcon } from '@components/Icons'
import { SearchFieldProps } from './interface'

export function SearchField({
  placeholder,
  className,
  inputClassName,
  isShowSearchLog,
  showSearchLog,
  hideSearchLog,
  ...props
}: SearchFieldProps) {
  return (
    <div className={cn('w-full', className)}>
      <div className='relative flex items-center w-full'>
        <div className='absolute left-0 ml-[20px]'>
          <SearchIcon className='text-primary' />
        </div>
        <input
          className={cn(
            'flex w-full h-[40px] pl-[50px] border-gray-border focus:outline-none text-[14px] font-light rounded-[8px] p-[10px]  focus:border-primary focus:text-secondary',
            inputClassName
          )}
          onBlur={hideSearchLog}
          onFocus={showSearchLog}
          autoComplete='off'
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  )
}
