import { Expose, Transform } from 'class-transformer'

export class PromotionCodeLogCountType {
  @Expose()
  @Transform(({ value }) => value || 0)
  totalCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  usedCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  remainingCount: number
}
