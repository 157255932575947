import { Expose, Type } from 'class-transformer'

import { PromotionSaleLogType } from './PromotionSaleLogType'

export class PromotionSaleLogResponseType {
  @Expose()
  @Type(() => PromotionSaleLogType)
  data: PromotionSaleLogType[]

  @Expose() total: number

  @Expose() page: number
}
