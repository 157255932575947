import { useEffect, useState } from 'react'
import { QueryKey, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { UniqeCodeTypeEnum } from '@interfaces/promotionCode/UniqeCodeTypeEnum'
import { getErrorMessage } from '@libs/utils'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'
import { validationSchema } from '@features/promotionSetting/components/PromotionCodeForm/validationSchema'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { CreateCodePromotionCodePageProps } from './interface'

const initialValues: CreateCodeFormType = {
  codeType: CodeTypeEnum.UNIVERSAL,
  universalCodeInput: '',
  universalCodeInputError: undefined,
  codeFileError: undefined,
  codeFile: undefined,
  uniqeCodeType: UniqeCodeTypeEnum.UPLOAD_CODE,
  isGenerateCompleted: false,
  coupons: [],
}

export function withCreateCodePromotionCodePage(
  Component: React.FC<CreateCodePromotionCodePageProps>
) {
  function WithCreateCodePromotionCodePage() {
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const [isCreate, setIsCreate] = useState(false)
    const [canCreate, setCanCreate] = useState(false)

    const queryKey: QueryKey = ['promotion', 'code', 'create-code', Number(id)]

    const { data, isLoading } = useQuery(
      queryKey,
      () => client?.promotionCodeClient.getPromotionCodeForm(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    function goBackHandle() {
      navigate(-1)
    }

    async function handleSubmit(values: CreateCodeFormType) {
      if (data) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          await client!.promotionCodeClient.createCodePromotionCode(
            values,
            data
          )
          alert.success('สร้าง Code โปรโมชั่นสำเร็จ')
          navigate(`/promotion-setting/promotion-code/${id}`)
        } catch (error) {
          alert.error(getErrorMessage(error))
        }
      }
    }
    useEffect(() => {
      async function validation(promotionData: PromotionCodeFormType) {
        try {
          await validationSchema.validate(promotionData)
          if (
            promotionData.promotionStatus === PromotionStatusEnum.DRAFT &&
            !Boolean(promotionData.lastRequest)
          ) {
            setCanCreate(true)
          } else {
            navigate(`/promotion-setting`)
          }
        } catch (error) {
          navigate(`/promotion-setting`)
        }
      }
      if (data) {
        validation(data)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    if (!isLoading && !!Number(id) && data && canCreate) {
      const newProps = {
        data,
        initialValues: Boolean(data.generateCouponStatus)
          ? {
              ...initialValues,
              codeType: CodeTypeEnum.UNIQE,
              uniqeCodeType: UniqeCodeTypeEnum.GENERATE_CODE,
              isGenerateCompleted:
                data.generateCouponStatus ===
                PromotionCodeJobStatusEnum.COMPLETED,
            }
          : initialValues,
        handleSubmit,
        goBackHandle,
        isCreate,
        setIsCreate,
      }
      return <Component {...newProps} />
    }
    return null
  }

  return WithCreateCodePromotionCodePage
}
