import { gql } from 'graphql-request'

export const GET_TOP_WRITER_BY_SALE = gql`
  query GetTopWriterBySale(
    $limit: Int
    $endDate: DateTime
    $startDate: DateTime
    $orderBy: GetTopWriterBySaleOrderedList
  ) {
    getTopWriterBySale(
      limit: $limit
      endDate: $endDate
      startDate: $startDate
      orderBy: $orderBy
    ) {
      userId
      username
      userMoneyReceived
      writerId
    }
  }
`
