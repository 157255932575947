import { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { ReportStoryAcceptProps, ReportStoryProps } from './interface'

export function withReportStory(Component: React.FC<ReportStoryProps>) {
  function WithReportStory({ activeTab }: ReportStoryAcceptProps) {
    const inputRef = useRef<HTMLInputElement>(null)
    const [params] = useSearchParams()
    const [textSearch, setTextSearch] = useState<string>('')
    const navigate = useNavigate()
    const client = useClient()

    const { data = 0 } = useQuery(
      'total-ebook-banned',
      () => client!.reportClient.totalEbookBanned(),
      {
        enabled: activeTab === ReportBookEnum.EBOOK,
      }
    )

    function onSearch(text: string) {
      setTextSearch(text)
    }

    function handleReset() {
      setTextSearch('')
    }

    function handleTabChange(value: ReportStatusEnum) {
      params.set('subtabs', value)
      navigate(
        {
          search: `?${params.toString()}`,
        },
        {
          replace: true,
        }
      )
    }

    const newProps = {
      activeTab,
      textSearch,
      inputRef,
      totalEbookBanned: data,
      onSearch,
      handleReset,
      handleTabChange,
    }

    return <Component {...newProps} />
  }

  return WithReportStory
}
