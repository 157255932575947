import { Expose } from 'class-transformer'
import { BannerEnum } from '@interfaces/BannerEnum'

export class HighlightBannerFormType {
  @Expose() id?: number

  @Expose() bannerName?: string

  @Expose() bannerType?: BannerEnum

  @Expose() isActive?: boolean

  @Expose() publishedTo?: string

  @Expose() publishedFrom?: string
}
