import cn from 'classnames'

import { CropImage } from '@components/CropImage'
import { CropImageFieldProps } from './interface'

export function CropImageField({
  className,
  isError,
  ...props
}: CropImageFieldProps) {
  return (
    <CropImage
      className={cn(className, {
        'border border-red': isError,
      })}
      {...props}
    />
  )
}
