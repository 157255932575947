import { UserStopSapPaymentTableProps } from './interface'

export function withUserStopSapPaymentTable(
  Component: React.FC<UserStopSapPaymentTableProps>
) {
  function WithUserStopSapPaymentTable(props: UserStopSapPaymentTableProps) {
    return <Component {...props} />
  }

  return WithUserStopSapPaymentTable
}
