import { withPermissionGuard } from '@libs/permission'
import { CoinPage } from './CoinPage'
import { withCoinPage } from './withCoinPage'

const ConnectedCoinPage = withPermissionGuard(
  withCoinPage(CoinPage),
  'coin',
  'view'
)

export { ConnectedCoinPage as CoinPage }
