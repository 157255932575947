export enum PopupBannerStatusEnum {
  ALL = 'All',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export const popupBannerStatusValue = {
  [PopupBannerStatusEnum.ALL]: 'ทั้งหมด',
  [PopupBannerStatusEnum.ACTIVE]: 'เผยแพร่',
  [PopupBannerStatusEnum.INACTIVE]: 'ไม่เผยแพร่',
  [PopupBannerStatusEnum.EXPIRED]: 'หมดอายุ',
}
