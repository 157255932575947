import { Expose, Transform, Type } from 'class-transformer'

import { Pagination } from '@models/pagination/Pagination'
import { WriterBookSaleType } from './WriterBookSaleType'

export class PaginatedWriterBookSale extends Pagination<WriterBookSaleType> {
  @Expose()
  @Type(() => WriterBookSaleType)
  @Transform(({ value }) => value || [])
  data: WriterBookSaleType[]
}
