import { FunctionComponentType } from '@interfaces/FunctionComponentType'

import cn from 'classnames'

export function Box({ children, className }: FunctionComponentType) {
  return (
    <section
      className={cn(
        'p-[30px] border border-blue-2 rounded-[8px] shadow',
        className
      )}
    >
      {children}
    </section>
  )
}

Box.Title = function BoxTitle({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn('font-medium font-mitr text-2xl text-secondary', className)}
    >
      {children}
    </div>
  )
}

Box.Name = function BoxName({ children, className }: FunctionComponentType) {
  const defaultClassName = 'font-bold text-secondary'
  return <span className={cn(defaultClassName, className)}>{children}</span>
}

Box.SubTitle = function BoxSubTitle({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('font-bold text-base text-secondary', className)}>
      {children}
    </div>
  )
}

Box.Paragraph = function BoxParagraph({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('font-light text-base text-secondary', className)}>
      {children}
    </div>
  )
}

Box.SubParagraph = function BoxSubParagraph({
  children,
  className,
}: FunctionComponentType) {
  return (
    <div className={cn('font-light text-base text-secondary-100', className)}>
      {children}
    </div>
  )
}

Box.Text = function BoxText({ children, className }: FunctionComponentType) {
  const defaultClassName = 'font-light text-secondary'
  return <span className={cn(defaultClassName, className)}>{children}</span>
}

Box.Label = function BoxLabel({ children, className }: FunctionComponentType) {
  return (
    <div className={cn('font-light text-xs text-secondary-100', className)}>
      {children}
    </div>
  )
}

Box.Tag = function BoxTag({ children, className }: FunctionComponentType) {
  const defaultClassName =
    'flex justify-center items-center bg-primary rounded px-[10px] py-[4px]'
  return (
    <div className={cn(defaultClassName, className)}>
      <span className='font-medium text-white text-xs'>{children}</span>
    </div>
  )
}
