import { Expose, Transform } from 'class-transformer'
import { PopupBannerStatusEnum } from '@interfaces/PopupBannerStatusEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'

export class SearchPopupBannerType {
  @Expose() id: number

  @Expose() title: string

  @Expose()
  @Transform(({ value }) => value || [])
  platforms: PlatformEnum[]

  @Expose() viewCount: number

  @Expose() uniqueViewerCount: number

  @Expose() clickLinkCount: number

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose() status: PopupBannerStatusEnum
}
