import { gql } from 'graphql-request'

export const UPDATE_PROMOTION_CODE = gql`
  mutation UpdatePromotionCode(
    $updatePromotionCodeInput: UpdatePromotionCodeInput!
  ) {
    updatePromotionCode(updatePromotionCodeInput: $updatePromotionCodeInput) {
      message
    }
  }
`
