import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { StorageSizeHistoryDataType } from './StorageSizeHistoryDataType'

export class StorageSizeHistoryType {
  @Expose({ name: 'key' }) keyType: KeyTypeEnum

  @Expose()
  @Type(() => StorageSizeHistoryDataType)
  @Transform(({ value, obj }) => {
    if (
      obj.key === KeyTypeEnum.MONTH &&
      obj.startDay > obj.endDay &&
      value.length
    ) {
      const index = value.findIndex(
        (row: StorageSizeHistoryDataType) => row.value === obj.startDay
      )
      const firstArr = value.slice(index)
      const tailArr = value.slice(0, index)
      return firstArr.concat(tailArr)
    }

    return value
  })
  data: StorageSizeHistoryDataType[]
}
