import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@hooks/useAuthentication'

export function withAuthenGuard(Component: React.FC<any>) {
  function WithAuthenGuard(props: any) {
    const { isUserLoading, isAuthenticated } = useAuthentication()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isUserLoading && !isAuthenticated) {
        navigate('/login')
      }
    }, [isUserLoading, isAuthenticated, navigate])

    if (isAuthenticated) {
      return <Component {...props} />
    }

    return <></>
  }

  return WithAuthenGuard
}
