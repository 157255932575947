import { FunctionComponentType } from '@interfaces/FunctionComponentType'

import cn from 'classnames'

export function Box({ children, className }: FunctionComponentType) {
  return (
    <section
      className={cn(
        'border border-blue-2 rounded-[8px] p-[30px] shadow',
        className
      )}
    >
      {children}
    </section>
  )
}

Box.Title = function BoxTitle({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'font-mitr font-medium text-[24px] text-secondary mb-[20px]',
        className
      )}
    >
      {children}
    </div>
  )
}
