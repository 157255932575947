import { useEffect, useState, useRef, useMemo } from 'react'
import cn from 'classnames'

import { useDropdown } from '@hooks/useDropdown'
import { useOutside } from '@hooks/useOutside'
import { SearchInput } from '@components/SearchInput'
import { SelectSearchProps } from './interface'

export function SelectSearch({
  placeholder,
  className,
  disabled,
  value,
  options,
  isShowImage = false,
  onChange,
}: SelectSearchProps) {
  const searchRef = useRef<any>()

  const [textSearch, setTextSearch] = useState('')
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()

  useOutside(searchRef, () => {
    if (isShowDropdown) {
      if (value !== textSearch) {
        setTextSearch(String(value))
      }
      toggleDropdown()
    }
  })

  useEffect(() => {
    setTextSearch(String(value))
  }, [value])

  const optionsFilter = useMemo(() => {
    if (!options?.length) {
      return []
    }

    if (!textSearch) {
      return options
    }

    return options.filter(option => {
      const text = String(option.value)
      return text.toLowerCase().includes(textSearch.toLowerCase())
    })
  }, [options, textSearch])

  return (
    <div
      ref={searchRef}
      className={cn('relative w-full', className)}
      onClick={toggleDropdown}
    >
      <div className='relative w-full flex items-center justify-between'>
        <SearchInput
          className={cn('w-full outline-none font-light text-primary', {
            'bg-gray-light': disabled,
          })}
          placeholder={placeholder}
          value={textSearch}
          onChange={e => setTextSearch((e.target as HTMLInputElement).value)}
          disabled={disabled}
          onReset={() => setTextSearch('')}
        />
      </div>
      {Boolean(options?.length) && isShowDropdown && (
        <div
          ref={dropdownRef}
          className='absolute z-10  left-0 right-0 bg-white border border-blue-2 rounded-[10px] max-h-[400px] w-full overflow-y-auto shadow'
        >
          {optionsFilter.map((option, index) => (
            <div
              className='flex text-[14px] items-center gap-x-[10px] text-black-480 py-[10px] px-[20px] hover:text-primary'
              key={index}
              onClick={() => onChange && onChange(option.value)}
            >
              <img
                className={cn('w-[40px] h-[40px] rounded-full ', {
                  hidden: !isShowImage,
                })}
                alt='penName'
                src={option.imageUrl}
              />
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
