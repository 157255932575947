import { Link } from 'react-router-dom'
import { abbreviateNumber } from '@libs/utils'
import { EyeIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { TopBookType } from '@models/salse/TopBookType'
import { BookEnum } from '@interfaces/BookEnum'

export const columns: ColumnType<TopBookType>[] = [
  {
    column: 'อันดับ',
    accessor: 'numRow',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: 'rowNumber',
    cellClassName: 'w-[60px] max-w-[60px] py-[7px]',
  },
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img src={value} alt='cover' className='w-[30px] h-[40px]' />
      ) : (
        <div className='w-[30px] h-[40px] bg-gray' />
      ),
    cellClassName: ' w-[50px]  max-w-[50px] py-[10px]',
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => (
      <p className='line-clamp-2 max-w-[200px] max-h-[50px] leading-[1.8]'>
        {value}
      </p>
    ),
    isSortable: true,
    sortKey: 'bookTitle',
    cellClassName: 'w-[280px] max-w-[280px] py-[7px]',
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => (
      <p>{value === BookEnum.NOVEL ? 'นิยาย' : 'การ์ตูน'}</p>
    ),
    isSortable: true,
    sortKey: 'bookBookType',
    cellClassName: 'w-[120px] max-w-[120px] py-[7px]',
  },
  {
    column: 'นามปากกา',
    accessor: 'penName',
    cell: ({ value }) => (
      <p className='max-w-[130px] line-clamp-1 leading-[1.8]'>{value}</p>
    ),
    isSortable: true,
    sortKey: 'penNameFirstPenName',
    cellClassName: 'w-[150px] max-w-[150px] p-[7px]',
  },
  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: 'bookChapterCount',
    cellClassName: 'w-[120px] max-w-[120px] p-[7px]',
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: 'bookViewCount',
    cellClassName: 'w-[90px] max-w-[90px] px-[8px] py-[7px] ',
  },
  {
    column: 'Love',
    accessor: 'likeCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: 'bookLikeCount',
    cellClassName: 'w-[90px] max-w-[90px] px-[8px] py-[7px] ',
  },
  {
    column: 'ยอดขาย',
    accessor: 'totalSales',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
    sortKey: 'bookTotalSales',
    cellClassName: 'w-[100px] max-w-[100px] py-[7px]',
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => (
      <Link to={`/story/${value}`}>
        <button
          className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isSortable: false,
    cellClassName: 'w-[45px] max-w-[45px] py-[7px]',
  },
]
