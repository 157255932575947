import React from 'react'
import { Button } from './Button/Button'
import { ButtonProps } from './Button/interface'

interface StatusButtonProps extends ButtonProps {
  status: 'delete' | 'publish' | 'unpublish'
}

function ButtonRender({ status, ...props }: StatusButtonProps) {
  switch (status) {
    case 'delete':
      return (
        <Button
          className='border border-none rounded-3xl px-5 py-1 text-sm font-medium font-mitr text-red bg-[#feeeef] h-[30px]'
          {...props}
        >
          ลบ
        </Button>
      )
    case 'publish':
      return (
        <Button
          className='border border-none rounded-3xl px-5 py-1 text-sm font-medium font-mitr text-green bg-[#ecf9f6] h-[30px]'
          {...props}
        >
          เผยแพร่
        </Button>
      )
    case 'unpublish':
      return (
        <Button
          className='border border-none rounded-3xl px-5 py-1 text-sm font-medium font-mitr text-[#858baf] bg-[#f3f3f7] h-[30px]'
          {...props}
        >
          ไม่เผยแพร่
        </Button>
      )
    default:
      return null
  }
}

function StatusButton(props: StatusButtonProps) {
  return <ButtonRender {...props} />
}

export default StatusButton
