import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import {
  SpenderTypeEnum,
  TopSpenderTableProps,
} from '../TopSpenderTable/interface'
import { TopCoinSpenderProps } from './interface'

export function withTopCoinSpender(Component: React.FC<TopSpenderTableProps>) {
  function WithTopCoinSpender({ searchParam }: TopCoinSpenderProps) {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const [sort, setSort] = useState<SortByType>({
      key: 'userCoinSpend',
      order: SortingType.DESC,
    })

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSort(prev => ({ ...prev, key, order }))
    }, [])

    const { data = [], isLoading } = useQuery(
      ['coin-spender', searchParam, sort],
      () =>
        client?.coinClient.getTopCoinSpender({
          searchParam,
          sort,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const pageProps = {
      data,
      isLoading,
      title: 'Top 20 Coin Spender',
      sort,
      spenderType: SpenderTypeEnum.COIN,
      handleSort,
    }
    return <Component {...pageProps} />
  }

  return WithTopCoinSpender
}
