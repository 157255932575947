import { Form, Formik } from 'formik'

import { Button } from '@components/Button'
import { SelectButton } from '@components/SelectButton'
import { InputField } from '@components/forms/InputField'
import { SearchIcon } from '@components/Icons'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { PromotionSearchFormProps } from './interface'

export function PromotionSearchForm({
  initialValues,
  placeholder,
  dateRangeOptions,
  handleReset,
  handleSubmit,
  handleExport,
}: PromotionSearchFormProps) {
  const permission = usePermissionAction('promotionSetting')
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form>
          <div className='flex flex-col gap-y-[20px] mb-[30px]'>
            <div className='flex items-center justify-between'>
              <InputField
                name='searchText'
                placeholder={placeholder}
                leftIcon={<SearchIcon className='text-primary' />}
                autoTrim={false}
                inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
              />
              <div className='flex-shrink-0 flex gap-x-[10px]'>
                <Button
                  className='underline text-secondary'
                  onClick={handleReset}
                  disabled={isSubmitting}
                >
                  ล้างค่า
                </Button>
                <Button
                  type='submit'
                  className='bg-secondary text-white'
                  disabled={isSubmitting}
                >
                  ค้นหา
                </Button>
              </div>
            </div>
            <div className='flex items-end justify-between'>
              <div className='flex items-end space-x-[10px]'>
                <SelectButton
                  label='เลือกช่วงเวลา'
                  mode='line'
                  className='w-fit bg-blue-2'
                  options={dateRangeOptions}
                  onChange={value => {
                    setFieldValue('selectedDate', value)
                    setFieldValue('startDate', null)
                    setFieldValue('endDate', null)
                  }}
                  value={values.selectedDate}
                />
                <DateTimeInputField
                  name='startDate'
                  className='w-[180px]'
                  placeholder='ตั้งแต่วันที่'
                  maxDate={values.endDate || new Date()}
                  onChange={value => {
                    setFieldValue('startDate', value)
                    if (values.selectedDate) {
                      setFieldValue('selectedDate', null)
                    }
                  }}
                />
                <DateTimeInputField
                  name='endDate'
                  className='w-[180px] '
                  placeholder='ถึงวันที่'
                  maxDate={new Date()}
                  minDate={values.startDate || undefined}
                  onChange={value => {
                    setFieldValue('endDate', value)
                    if (values.selectedDate) {
                      setFieldValue('selectedDate', null)
                    }
                  }}
                />
              </div>
              {permission.download && (
                <ExportButton
                  className='border !border-secondary-100 text-sm !text-secondary-100 rounded-[20px] !bg-white'
                  handleClick={handleExport}
                  label='Export Report'
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
