import { SelectButtonLine } from '@components/SelectButtonLine'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionSettingTabsProps } from './interface'

const tabs = [
  { value: PromotionChallengeActionEnum.ALL, text: 'ทั้งหมด' },
  { value: PromotionChallengeActionEnum.LOVE, text: 'กดหัวใจ' },
  { value: PromotionChallengeActionEnum.COMMENT, text: 'Comment นิยาย' },
  {
    value: PromotionChallengeActionEnum.SHARE,
    text: 'Share นิยายไปยัง Social Media',
  },
  {
    value: PromotionChallengeActionEnum.FINISH_PROFILE,
    text: 'กรอกข้อมูล Profile ให้ครบ',
  },
  { value: PromotionChallengeActionEnum.READ, text: 'อ่านนิยายเรื่องใดก็ได้' },
  {
    value: PromotionChallengeActionEnum.UNLOCK_CHAPTER,
    text: 'ปลดล็อกนิยายรายตอน',
  },
  { value: PromotionChallengeActionEnum.DONATE, text: 'สนับสนุนตัวละคร' },
  {
    value: PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
    text: 'เพิ่มตอนติดเหรียญ',
  },
  { value: PromotionChallengeActionEnum.CREATE_BOOK, text: 'สร้างเรื่องใหม่' },
]

export function PromotionSettingTabs({
  activeChallengeTab,
  setActiveChallengeTab,
}: PromotionSettingTabsProps) {
  return (
    <SelectButtonLine
      options={tabs}
      value={activeChallengeTab}
      onChange={setActiveChallengeTab}
      className='gap-y-[15px] my-[30px]'
    />
  )
}
