import { Box } from '@components/Box'

import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { Device } from '../Device'
import { Overview } from '../Overview'
import { StorageUsage } from '../StorageUsage'
import { TopPageView } from '../TopPageView'
import { Trafic } from '../Trafic'
import { PerformanceTemplateProps } from './interface'
import { SessionHistory } from '../SessionHistory'
import { OverviewActiveUser } from '../OverviewActiveUser'

export function PerformanceTemplate({
  tabId,
  totalUser,
  totalPageView,
  avgSessionDuration,
  bounceRate,
  storageSize,
  sessionHistory,
  trafficCategory,
  deviceCategory,
  topPageView,
  storageSizeHistory,
  Active1DayUsers,
  Active7DayUsers,
  Active28DayUsers,
  searchParams,
  handleSearch,
  handleReset,
  handleExport,
}: PerformanceTemplateProps) {
  return (
    <div>
      <div className='flex items-center gap-x-[20px]'>
        <SearchDateRange
          className='flex-1'
          onSearch={handleSearch}
          onReset={handleReset}
          onExport={handleExport}
        />
      </div>
      <Box className='flex flex-col gap-y-[30px] divide-y divide-gray shadow mt-[30px]'>
        <Overview
          totalUser={totalUser}
          totalPageView={totalPageView}
          avgSessionDuration={avgSessionDuration}
          bounceRate={bounceRate}
          storageSize={storageSize}
        />
        <OverviewActiveUser
          Active1DayUsers={Active1DayUsers}
          Active7DayUsers={Active7DayUsers}
          Active28DayUsers={Active28DayUsers}
        />
        <SessionHistory sessionHistory={sessionHistory} />
        {trafficCategory.length === 0 ? (
          <div className='flex pt-[30px]'>
            <Device deviceCategory={deviceCategory} />
          </div>
        ) : (
          <div className='flex pt-[30px]'>
            <Trafic trafficCategory={trafficCategory} />
            <Device deviceCategory={deviceCategory} />
          </div>
        )}
        <TopPageView topPageView={topPageView} tabId={tabId} />
        <StorageUsage
          storageSizeHistory={storageSizeHistory}
          startDate={searchParams.startDate.toJSDate()}
          endDate={searchParams.endDate.toJSDate()}
          storageSize={storageSize}
        />
      </Box>
    </div>
  )
}
