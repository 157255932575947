import { Box } from '@components/Box'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import { SearchInput } from '@components/SearchInput'
import cn from 'classnames'
import {
  OrderByEnum,
  SearchPopularBy,
} from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { PopularSearchTextByTypeCardProps } from '@features/dashboard/pages/SearchPage/components/PopularSearchTextByTypeCard/interface'

const TitleMapper: Record<SearchPopularBy, string> = {
  [SearchPopularBy.ALL]: 'ทั้งหมด',
  [SearchPopularBy.TITLE]: 'ชื่อเรื่อง',
  [SearchPopularBy.TAG]: 'Tag',
  [SearchPopularBy.CHAPTER_NAME]: 'ชื่อตอน',
  [SearchPopularBy.COVER]: 'ปก',
  [SearchPopularBy.CONTENT]: 'เนื้อหา',
  [SearchPopularBy.CHARACTER]: 'ตัวละคร',
  [SearchPopularBy.INTRO]: 'เรื่องย่อ',
  [SearchPopularBy.PEN_NAME]: 'นามปากกา',
  [SearchPopularBy.WRITER]: 'นักเขียน',
}

export function PopularSearchTextByTypeCard({
  data,
  isLoading,
  handleWaypointEnter,
  handleSortChange,
  type,
  inputRef,
  onSearch,
  onReset,
}: PopularSearchTextByTypeCardProps) {
  const items = data?.pages.flatMap(row => row?.data) || []
  return (
    <Box className='w-full'>
      <h2 className='mb-[20px] font-mitr font-normal text-[24px] text-secondary'>
        <span className='font-medium text-secondary-100'>
          คำค้นหายอดนิยม :{' '}
        </span>
        {TitleMapper[type]}
      </h2>
      <SearchInput
        ref={inputRef}
        className={cn('flex-1 text-primary')}
        placeholder='ค้นหาคำค้นที่ต้องการ'
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onSearch((event.target as HTMLInputElement).value)
          }
        }}
        onReset={onReset}
      />
      <div className='w-full'>
        <div>
          <Table
            className='mb-[30px] max-h-[250px] pr-[10px]'
            isLoading={isLoading}
            data={items}
            columns={[
              {
                column: 'คำค้นหายอดนิยม',
                accessor: 'searchText',
                cell: ({ value }) => (
                  <div className='line-clamp-1 break-word'>{value}</div>
                ),
                cellClassName: 'py-[10px] pr-[10px]',
              },
              {
                column: 'จำนวนการค้นหา (ครั้ง)',
                accessor: 'totalPopularSearch',
                cell: ({ value }) => <div className='text-right'>{value}</div>,
                cellClassName: 'py-[10px] w-[150px] pr-[10px]',
                sortKey: OrderByEnum.SEARCH_COUNT,
                isSortable: true,
              },
            ]}
            initialSort={{
              key: OrderByEnum.SEARCH_COUNT,
              order: SortingType.DESC,
            }}
            onSort={handleSortChange}
            onWaypointEnter={handleWaypointEnter}
            fixedHeader
          />
        </div>
      </div>
    </Box>
  )
}
