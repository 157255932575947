import { Expose } from 'class-transformer'

export class ApproverType {
  @Expose()
  id: number

  @Expose()
  avatarImgPath: string

  @Expose()
  displayName: string
}
