import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { plainToInstance } from 'class-transformer'

import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { UserMissionFormType } from '@models/user-mission/UserMissionFormType'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { UserMissionLevelFormType } from '@models/user-mission/UserMissionLevelFormType'
import { EditUserMissionPageProps } from './interface'

export function withEditUserMissionPage(
  Component: React.FC<EditUserMissionPageProps>
) {
  function WithEditUserMissionPage() {
    const client = useClient()
    const alert = useAlert()
    const { id } = useParams()
    const navigate = useNavigate()
    const [editId, setEditId] = useState(-1)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const confirmModal = useModal({ modal: 'confirm' })
    const [userMissionForm, setUserMissionForm] =
      useState<UserMissionLevelFormType>()

    const { data, isLoading, refetch } = useQuery(
      ['user-mission', id],
      () =>
        client?.userMissionClient.getUserMission(Number(id)).then(res => {
          setUserMissionForm(
            plainToInstance(UserMissionLevelFormType, {
              userMissionLevels: res.challengeLevels,
            })
          )
          return res
        }),
      { enabled: !!id }
    )

    const { mutateAsync: updateUserMission } = useMutation(
      (form: UserMissionFormType) =>
        client!.userMissionClient.updateUserMission(form),
      {
        onSuccess: async () => {
          alert.success('บันทึกข้อมูลเรียบร้อย')
          await refetch()
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function handleUpdateUserMission(form: UserMissionFormType) {
      await updateUserMission(form)
    }

    function onClickBackBtn() {
      if (isEdit || editId !== -1) {
        confirmModal.show({
          content: (
            <div className='w-[340px] mb-[20px] font-light text-center text-secondary '>
              <p className='font-mitr  text-[24px]  pb-[20px] font-medium '>
                ยืนยันการออกจากหน้า
              </p>
              <p className='pb-[5px]'>มีการเปิดการแก้ไขข้อมูลอยู่</p>
              <p>คุณต้องการออกจากหน้านี้หรือไม่</p>
            </div>
          ),
          onConfirm: () => {
            navigate(-1)
            confirmModal.hide()
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } else {
        navigate(-1)
      }
    }

    if (isLoading || !data || !userMissionForm) return <></>

    const componentProps = {
      data,
      userMissionForm,
      handleUpdateUserMission,
      refetchUserMission: refetch,
      editId,
      setEditId,
      isEdit,
      setIsEdit,
      onClickBackBtn,
      setUserMissionForm,
    }

    return <Component {...componentProps} />
  }

  return WithEditUserMissionPage
}
