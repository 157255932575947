import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import {
  WithPromotionSaleTableProps,
  PromotionSaleTableProps,
} from './inteface'
import { SelectedDateEnum } from '../PromotionSearchForm/interface'

export function withPromotionSaleTable(
  Component: React.FC<PromotionSaleTableProps>
) {
  function Hoc({
    searchParams,
    promotionSaleId,
    page,
    total,
    perpage,
    pageChange,
    handleTotalChange,
    handleSort,
  }: WithPromotionSaleTableProps) {
    const queryKey = ['promotion-sale', searchParams, page]
    const client = useClient()

    const { data, isLoading } = useQuery(
      queryKey,
      () => {
        let startAt: DateTime | undefined = searchParams.startDate
          ? DateTime.fromJSDate(searchParams.startDate).startOf('day').toUTC()
          : DateTime.now().minus({ days: 6 }).startOf('day').toUTC()
        let endAt: DateTime | undefined = searchParams.endDate
          ? DateTime.fromJSDate(searchParams.endDate).endOf('day').toUTC()
          : DateTime.now().endOf('day').toUTC()

        if (searchParams.selectedDate === SelectedDateEnum.TO_DAY) {
          startAt = DateTime.now().startOf('day').toUTC()
          endAt = DateTime.now().endOf('day').toUTC()
        } else if (searchParams.selectedDate === SelectedDateEnum.SEVEN_DAY) {
          startAt = DateTime.now().minus({ days: 6 }).startOf('day').toUTC()
          endAt = DateTime.now().endOf('day').toUTC()
        } else if (
          searchParams.selectedDate === SelectedDateEnum.THROUGHOUT_THE_CAMPAIGN
        ) {
          startAt = undefined
          endAt = undefined
        }

        return client?.promotionSaleClient
          .promotionSaleLogs({
            promotionSaleId,
            searchText: searchParams.searchText,
            startDate: startAt,
            endDate: endAt,
            sortKey: searchParams.key,
            orderBy: searchParams.order,
            limitPerPage: perpage,
            page,
          })
          .then(res => {
            pageChange(page)
            handleTotalChange(res.total)
            return res.data
          })
      },
      {
        enabled: !!promotionSaleId,
      }
    )

    const newProps = {
      searchParams,
      data,
      page,
      perpage,
      total,
      isLoading,
      pageChange,
      handleSort,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
