import { Exclude, Expose, Type } from 'class-transformer'
import { ChapterOptionType } from './ChapterOptionType'

@Exclude()
export class ChapterOptionResponse {
  @Expose() page: number

  @Expose({ name: 'data' })
  @Type(() => ChapterOptionType)
  chapters: ChapterOptionType[]
}
