import React from 'react'

import { PopupBannerFormProps } from './interface'

export function withPopupBannerForm(Component: React.FC<PopupBannerFormProps>) {
  function WithPopupBannerForm(props: PopupBannerFormProps) {
    const componentProps = props

    return <Component {...componentProps} />
  }

  return WithPopupBannerForm
}
