import { Expose, Transform } from 'class-transformer'
import { GenderEnum } from '@interfaces/GenderEnum'
import { FirstLoginProviderEnum } from '@interfaces/FirstLoginProviderEnum'

export class WriterUserType {
  @Expose() id: number

  @Expose() avatarImgPath: string

  @Expose() bannerImgPath: string

  @Expose() idNumber: string

  @Expose()
  @Transform(({ value }) => value || '')
  fullName: string

  @Expose() email: string

  @Expose() phoneNumber: string

  @Expose()
  birthDay: string

  @Expose()
  @Transform(({ value }) =>
    typeof value === 'string' ? value.toUpperCase() : GenderEnum.OTHER
  )
  gender: GenderEnum

  @Expose() username: string

  @Expose() displayName: string

  @Expose() firstLoginProvider: FirstLoginProviderEnum
}
