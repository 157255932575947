import { useMemo } from 'react'
import cn from 'classnames'
import cloneDeep from 'lodash.clonedeep'

import { ProgressBar } from '@components/ProgressBar'
import { Loading } from '@components/Loading'
import { IncomeByPennameProps } from './interface'

export function IncomeByPenname({
  data,
  className,
  isLoading,
}: IncomeByPennameProps) {
  const temp = cloneDeep(data)
  const maxValue = useMemo(
    () => Math.max(...temp.map(i => i.PenNameIncome)),
    [temp]
  )
  const topThree = useMemo(
    () => temp.sort((a, b) => b.PenNameIncome - a.PenNameIncome).slice(0, 3),
    [temp]
  )
  return (
    <>
      <div className='flex flex-col gap-5'>
        <h1 className='text-secondary text-2xl font-bold'>รายได้ตามนามปากกา</h1>
        {isLoading && <Loading />}
        {data.length === 0 && !isLoading && (
          <div className='text-[24px] text-secondary-50 flex justify-center font-mitr font-medium my-[20px] '>
            ไม่พบข้อมูล
          </div>
        )}
        <div
          className={cn(
            'grid grid-cols-1 gap-y-[10px] text-[14px] font-light',
            className
          )}
        >
          {data.map((item, i) => (
            <div key={i} className='flex gap-x-[10px] items-center'>
              <div className='w-[160px] line-clamp-1 text-secondary break-words'>
                {item.firstPenName}
              </div>
              <div
                className={cn('w-[60px] text-right line-clamp-1', {
                  'text-primary': topThree.some(
                    element => element.penNameId === item.penNameId
                  ),
                  'text-secondary-100': !topThree.some(
                    element => element.penNameId === item.penNameId
                  ),
                })}
              >
                {item.PenNameIncome.toLocaleString()}
              </div>
              <ProgressBar
                value={item.PenNameIncome}
                max={maxValue}
                className='flex-1'
                progressBarClassName={cn('rounded-full', {
                  'bg-primary': topThree.some(
                    element => element.penNameId === item.penNameId
                  ),
                  'bg-gray-400': !topThree.some(
                    element => element.penNameId === item.penNameId
                  ),
                })}
              />
            </div>
          ))}
        </div>
      </div>
      <hr className='my-[30px]' />
    </>
  )
}
