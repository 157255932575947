import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { ReportListType } from '@models/report/ReportListType'
import { ReportType } from '@models/report/ReportType'
import { UpdateBookReportInputType } from '@features/chapterBook/pages/ReportListDetailPage/interface'
import { ReportLogListType } from '@models/report/ReportLogListType'
import { ReportPublishLogListType } from '@models/report/ReportPublishLogListType'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { TopicEnum } from '@interfaces/TopicEnum'
import { BOOK_REPORT } from './schemas/bookReport'
import { BOOK_REPORTS } from './schemas/bookReports'
import { UPDATE_BOOK_REPORT } from './schemas/updateBookReport'
import { DELETE_BOOK_REPORT } from './schemas/deleteBookReport'
import { BOOK_REPORT_LOGS } from './schemas/bookReportLogs'
import { BOOK_LOGS } from './schemas/bookLogs'
import { BULK_UPDATE_BOOK_REPORT } from './schemas/bulkUpdateBookReports'
import { EBOOK_LOGS } from './schemas/ebookLogs'
import { TOTAL_EBOOK_BANNED } from './schemas/totalEbookBanned'

export class ReportClient {
  constructor(private client: GraphQLClient) {}

  async bookReports({
    page,
    limitPerPage,
    search,
    where = null,
    sortKey,
    sortOrder,
    type,
    isVisible,
  }: {
    page: number
    limitPerPage: number
    where?: { status?: ReportStatusEnum; title?: TopicEnum } | null
    search?: string
    sortKey?: string
    sortOrder?: string
    type: ReportBookEnum
    isVisible?: boolean
  }) {
    const { bookReports } = await this.client.request(BOOK_REPORTS, {
      page,
      limitPerPage,
      search,
      where,
      orderBy: sortKey ? { [sortKey]: sortOrder } : undefined,
      type,
      isVisible,
    })

    return plainToInstance(ReportListType, bookReports)
  }

  async bookReport(bookReportId: number) {
    const { bookReport } = await this.client.request(BOOK_REPORT, {
      bookReportId,
    })

    return plainToInstance(ReportType, bookReport)
  }

  async updateBookReport(updateBookReportInput: UpdateBookReportInputType) {
    await this.client.request(UPDATE_BOOK_REPORT, { updateBookReportInput })
  }

  async bulkUpdateBookReports(ids: number[]) {
    await this.client.request(BULK_UPDATE_BOOK_REPORT, {
      bulkUpdateBookReportsInput: { ids },
    })
  }

  async deleteBookReport(ids: number[]) {
    await this.client.request(DELETE_BOOK_REPORT, { ids })
  }

  async bookReportLogs({
    bookReportId,
    page = 1,
    limitPerPage = 10,
  }: {
    bookReportId: number
    page?: number
    limitPerPage?: number
  }) {
    const { bookReportLogs } = await this.client.request(BOOK_REPORT_LOGS, {
      bookReportId,
      page,
      limitPerPage,
    })

    return plainToInstance(ReportLogListType, bookReportLogs)
  }

  async reportPublishLogs({
    id,
    page = 1,
    limitPerPage = 10,
    type,
  }: {
    id: number
    page?: number
    limitPerPage?: number
    type: ReportBookEnum
  }) {
    if (type === ReportBookEnum.STORY) {
      const { bookLogs } = await this.client.request(BOOK_LOGS, {
        bookId: id,
        page,
        limitPerPage,
      })
      return plainToInstance(ReportPublishLogListType, bookLogs)
    }

    const { ebookLogs } = await this.client.request(EBOOK_LOGS, {
      ebookId: id,
      page,
      limitPerPage,
    })
    return plainToInstance(ReportPublishLogListType, ebookLogs)
  }

  async totalEbookBanned(): Promise<number> {
    const { totalEbookBanned } = await this.client.request(TOTAL_EBOOK_BANNED)

    return totalEbookBanned
  }
}
