import React, { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { formatDate } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { UserMissionLogsType } from '@models/user-mission/UserMissionLogsType'

import { UserMissionHistoryPageProps } from './interface'

export function withUserMissionHistoryPage(
  Component: React.FC<UserMissionHistoryPageProps>
) {
  function Hoc() {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const { id } = useParams()
    const [activeIndex, setActiveIndex] = useState(0)

    const { data = [], isLoading } = useQuery(
      ['user-missions-log', { id }],
      () => client?.userMissionClient.getUserMissionLogs(Number(id)),
      { enabled: isAuthenticated && !!id }
    )

    function handleChangeHistory(index: number) {
      setActiveIndex(index)
    }

    const optionData = useMemo(() => {
      return data
        .sort((a: UserMissionLogsType, b: UserMissionLogsType) => b.id - a.id)
        .map(item => ({
          text: `${formatDate(item.createdAt)} - ${DateTime.fromISO(
            item.createdAt
          ).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`,
        }))
    }, [data])

    if (isLoading || !data) return <></>

    const componentProps = {
      data: data[activeIndex],
      optionData,
      activeIndex,
      handleChangeHistory,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
