import { useNavigate } from 'react-router-dom'
import { SettingCardProps, WithSettingCardProps } from './interface'

export function withSettingCard(Component: React.FC<SettingCardProps>) {
  function WithSettingCard(props: WithSettingCardProps) {
    const navigate = useNavigate()

    function navigateToEditPage(id: number) {
      navigate(`/chapter-book/book-story-setting/edit/${id}`)
    }

    const componentProps = {
      ...props,
      navigateToEditPage,
    }
    return <Component {...componentProps} />
  }

  return WithSettingCard
}
