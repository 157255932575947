import cn from 'classnames'
import { ColumnType } from '@components/Table/interface'
import { ReportType } from '@models/report/ReportType'
import { formatDateTime } from '@libs/utils'
import { Checkbox } from '@components/Checkbox'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { TopicEnum } from '@interfaces/TopicEnum'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { ActionCell } from './component/ActionCell'

export const reportTopicText: Record<string, string> = {
  [TopicEnum.ALL]: 'หัวข้อทั้งหมด',
  [TopicEnum.RUDE_LANGUAGE]: 'ใช้คำหยาบคาย',
  [TopicEnum.PIRACY_CONTENT]: 'ละเมิดลิขสิทธิ์',
  [TopicEnum.IMPOLITIC_CONTENT]: 'มีเนื้อหาไม่เหมาะสม',
  [TopicEnum.PIRACY_IMAGE]: 'ใช้ภาพไม่เหมาะสม',
  [TopicEnum.OTHER]: 'อื่นๆ',
}

export const reportStatusText: Record<string, string> = {
  [ReportStatusEnum.REPORTED]: 'แจ้งนักเขียนแล้ว',
  [ReportStatusEnum.SUCCESS]: 'ดำเนินการสำเร็จ',
  [ReportStatusEnum.WAITING]: 'รอการตรวจสอบ',
}

export function useBookReportsColumns(
  selectedId: number[],
  dataLength: number,
  handleSelectAll: () => void,
  handleSelect: (value: number) => void
) {
  const permission = usePermissionAction('chapterBook')
  const columns: ColumnType<ReportType>[] = [
    // DESC: If want feature checkbox delete report list, uncomment code below (line 32 to line 58)
    {
      column: (
        <Checkbox
          defaultChecked={
            selectedId.length === dataLength && selectedId.length > 0
          }
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            handleSelectAll()
          }}
        />
      ),
      accessor: 'id',
      cell: ({ value }) => (
        <Checkbox
          defaultChecked={selectedId.includes(value)}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            handleSelect(value)
          }}
        />
      ),
      cellClassName: cn('min-w-[50px] w-[50px]', {
        hidden: !permission.delete,
      }),
      isSortable: false,
    },
    {
      column: 'Case ID',
      accessor: 'code',
      sortKey: 'id',
      cell: ({ value }) => <p>{value || '-'}</p>,
      isSortable: true,
    },
    {
      column: 'วันที่-เวลา',
      accessor: 'createdAt',
      cell: ({ value }) => <p>{formatDateTime(value)}</p>,
      isSortable: true,
    },
    {
      column: 'ผู้รายงาน',
      accessor: 'reportBy.displayName',
      cell: ({ value }) => <p className='w-[80px] truncate'>{value}</p>,
      isSortable: false,
    },
    {
      column: 'เรื่อง',
      accessor: 'book.title',
      cell: ({ value }) => <p className='w-[127px] truncate'>{value}</p>,
      isSortable: false,
    },
    {
      column: 'หัวข้อ',
      accessor: 'topic',
      cell: ({ value }) => <p>{reportTopicText[value]}</p>,
      isSortable: false,
    },
    {
      column: 'รายละเอียด',
      accessor: 'detail',
      cell: ({ value }) => <p className='w-[200px] truncate'>{value}</p>,
      isSortable: false,
    },
    {
      column: 'สถานะ',
      accessor: 'status',
      cell: ({ value }) => (
        <p
          className={cn('font-bold', {
            'text-primary': value === ReportStatusEnum.REPORTED,
            'text-green': value === ReportStatusEnum.SUCCESS,
            'text-black-480': value === ReportStatusEnum.WAITING,
          })}
        >
          {reportStatusText[value]}
        </p>
      ),
      isSortable: true,
    },
    {
      column: 'Action',
      accessor: 'id',
      actionCell: ({ obj }) => <ActionCell id={obj.id} />,
      isAction: true,
    },
  ]
  return { columns }
}
