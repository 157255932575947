import { Link } from 'react-router-dom'
import { ColumnType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { TopWriterBySaleType } from '@models/dashboard/TopWriterBySaleType'

export const columns: ColumnType<TopWriterBySaleType>[] = [
  {
    column: 'User ID',
    accessor: 'userId',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: 'usersId',
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p className='max-w-[160px] truncate'>@{value}</p>,
    isSortable: true,
  },
  {
    column: 'Sales',
    accessor: 'userMoneyReceived',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    sortKey: 'moneyReceived',
    isSortable: true,
  },
  {
    column: 'Action',
    cellClassName: 'flex items-center justify-end h-[44px]',
    actionCell: ({ obj }) => (
      <Link
        to={`/dashboard/writer-publisher?tab=byUser&userId=${obj.userId}&writerId=${obj.writerId}`}
      >
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isAction: true,
  },
]
