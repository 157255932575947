import { InfiniteData } from 'react-query'

import { PopupBannerLogResponse } from '@models/popupBanner/PopupBannerLogResponse'
import { LogType } from '@models/popupBanner/LogType'

export enum HistoryType {
  CreatedBy = 'createdBy',
  Title = 'title',
  Published = 'published',
  Link = 'link',
  DisplayAt = 'displayAt',
  Platform = 'platform',
  UserType = 'userType',
}

export interface HistoryPopupBannerProps {
  data: InfiniteData<PopupBannerLogResponse> | undefined
  handleFetchNextPage: () => void
}

export interface HistoryItemProps {
  data: LogType
  type: HistoryType
}
