import { SortingType } from '@components/Table/interface'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { PromotionChallengeDetailType } from '@models/promotion/challenge/PromotionChallengeDetailType'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { PromotionChallengeLogResponseType } from '@models/promotion/challenge/PromotionChallengeLogResponseType'
import { PromotionRequestType } from '@models/promotion/challenge/PromotionRequestType'
import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'
import { CREATE_PROMOTION_CHALLENGE } from './schemas/createPromotionChallenge'
import { GET_PROMOTION_CHALLENGE } from './schemas/getPromotionChallenge'
import { GET_PROMOTION_CHALLENGE_DETAIL } from './schemas/getPromotionChallengeDetail'
import { GET_PROMOTION_CHALLENGE_FORM_DETAIL } from './schemas/getPromotionChallengeFormDetail'
import { GET_PROMOTION_CHALLENGE_HISTORY } from './schemas/getPromotionChallengeHistory'
import { PROMOTION_CHALLENGE_LOGS } from './schemas/promotionChallengeLogs'
import { UPDATE_PROMOTION_CHALLENGE } from './schemas/updatePromotionChallenge'
import { EXPORT_PROMOTION_CHALLENGE } from './schemas/exportPromotionChallenge'

function TransformPromotionChallengeInput(values: PromotionChallengeFormType) {
  const promotionChallengeInput = {
    id: values.id,
    name: values.name,
    description: values.description,
    userTerm: values.userTerm,
    publishedType: values.publishedType,
    startPublishedAt:
      values.publishedType === PromotionPublishedEnum.PERIOD
        ? values.startPublishedAt
        : values.startNowAt,
    endPublishedAt: values.endPublishedAt,
    status: values.status,
    action: values.action,
    actionValue:
      values.action !== PromotionChallengeActionEnum.FINISH_PROFILE
        ? Number(values.actionValue)
        : undefined,
    wordCount:
      values.action === PromotionChallengeActionEnum.COMMENT
        ? Number(values.wordCount)
        : undefined,
    socialMediaChannels:
      values.action === PromotionChallengeActionEnum.SHARE
        ? values.socialMediaChannels
        : undefined,
    readCondition:
      values.action === PromotionChallengeActionEnum.READ
        ? values.readCondition
        : undefined,
    coinConditionIds: [
      PromotionChallengeActionEnum.DONATE,
      PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
      PromotionChallengeActionEnum.CREATE_BOOK,
      PromotionChallengeActionEnum.UNLOCK_CHAPTER,
    ].includes(values.action)
      ? values.coinConditionIds
      : undefined,
    categoryIds:
      values.action === PromotionChallengeActionEnum.CREATE_BOOK
        ? values.categoryIds?.map(c => Number(c))
        : undefined,
    bookTypes:
      values.action === PromotionChallengeActionEnum.CREATE_BOOK
        ? values.bookTypes
        : undefined,
    benefitValue: Number(values.benefitValue),
    benefitCoinId: Number(values.benefitCoinId),
    benefitLimitType: values.benefitLimitType,
    benefitLimitValue: [
      PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
      PromotionChallengeLimitTypeEnum.PER_DAY,
    ].includes(values.benefitLimitType!)
      ? Number(values.benefitLimitValue)
      : undefined,
    budget: Number(values.budget),
    budgetPerDay: values.isLimitBudget
      ? Number(values.budgetPerDay)
      : undefined,
  }
  return promotionChallengeInput
}

export class PromotionChallengeClient {
  constructor(private client: GraphQLClient) {}

  async createPromotionChallenge(
    values: PromotionChallengeFormType
  ): Promise<number> {
    const createPromotionChallengeInput =
      TransformPromotionChallengeInput(values)
    const {
      createPromotionChallenge: { id },
    } = await this.client.request(CREATE_PROMOTION_CHALLENGE, {
      createPromotionChallengeInput,
    })
    return id
  }

  async getPromotionChallengeForm(
    id: number
  ): Promise<PromotionChallengeFormType> {
    const { promotion } = await this.client.request(GET_PROMOTION_CHALLENGE, {
      promotionId: id,
    })
    return plainToInstance(PromotionChallengeFormType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionChallengeDetail(
    id: number
  ): Promise<PromotionChallengeDetailType> {
    const { promotion } = await this.client.request(
      GET_PROMOTION_CHALLENGE_DETAIL,
      {
        promotionId: id,
      }
    )
    return plainToInstance(PromotionChallengeDetailType, promotion, {
      excludeExtraneousValues: true,
    })
  }

  async getPromotionHistory(
    promotionRequestId: number
  ): Promise<PromotionRequestType> {
    const { promotionRequest } = await this.client.request(
      GET_PROMOTION_CHALLENGE_HISTORY,
      {
        promotionRequestId,
      }
    )
    return plainToInstance(PromotionRequestType, promotionRequest)
  }

  async updatePromotionChallenge(
    values: PromotionChallengeFormType
  ): Promise<string> {
    const updatePromotionChallengeInput =
      TransformPromotionChallengeInput(values)
    const {
      updatePromotionChallenge: { message },
    } = await this.client.request(UPDATE_PROMOTION_CHALLENGE, {
      updatePromotionChallengeInput,
    })
    return message
  }

  async promotionChallengeLogs({
    promotionChallengeId,
    page,
    limitPerPage,
    searchText,
    sortKey,
    orderBy,
    startDate,
    endDate,
  }: {
    promotionChallengeId: number
    page: number
    limitPerPage: number
    searchText: string
    sortKey: string
    orderBy?: SortingType
    startDate: DateTime
    endDate: DateTime
  }): Promise<PromotionChallengeLogResponseType> {
    const { promotionChallengeLogs } = await this.client.request(
      PROMOTION_CHALLENGE_LOGS,
      {
        promotionChallengeId,
        page,
        searchText,
        limitPerPage,
        orderBy: sortKey ? { [sortKey]: orderBy } : undefined,
        startDate,
        endDate,
      }
    )
    return plainToInstance(
      PromotionChallengeLogResponseType,
      promotionChallengeLogs
    )
  }

  async getPromotionChallengeFormDetail(
    id: number
  ): Promise<PromotionChallengeFormType> {
    const {
      promotion: {
        lastRequest: { newPromotion },
      },
    } = await this.client.request(GET_PROMOTION_CHALLENGE_FORM_DETAIL, {
      promotionId: id,
    })
    return plainToInstance(PromotionChallengeFormType, newPromotion)
  }

  async exportPromotionChallenge({
    promotionId,
    promotionChallengeId,
    action,
    startDate,
    endDate,
    searchText,
  }: {
    promotionId: number
    promotionChallengeId: number
    action: PromotionChallengeActionEnum
    startDate: DateTime
    endDate: DateTime
    searchText: string
  }): Promise<void> {
    await this.client.request(EXPORT_PROMOTION_CHALLENGE, {
      promotionId,
      promotionChallengeId,
      action,
      startDate,
      endDate,
      searchText,
    })
  }
}
