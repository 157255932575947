import React from 'react'
import cn from 'classnames'

import { Container } from '@features/promotionSetting/components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import {
  dayOptionsText,
  promotionChallengeBenefitLimitTypeText,
} from '@libs/promotion/utils'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import {
  promotionSaleConditionValue,
  PSConditionTypeEnum,
} from '@interfaces/promotionSale/PSConditionTypeEnum'
import { promotionSaleCoinConditionValue } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PromotionSaleContentProps } from './interface'

export function PromotionSaleContent({
  isDisableStatus,
  description,
  userTerm,
  promotionSale,
  isExpand,
  usageDay,
}: PromotionSaleContentProps) {
  return (
    <>
      {isExpand ? (
        <>
          <div
            className={cn(
              'grid grid-cols-[150px_1fr] gap-y-[25px] text-[14px]',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
              รายละเอียด :
            </span>
            <span className='break-word'>{description || '-'}</span>
            <span className='text-[12px] font-light text-secondary-100'>
              เงื่อนไขการใช้งาน :
            </span>
            <div className='grid grid-col-1 whitespace-pre-wrap break-word'>
              <span>{userTerm || '-'}</span>
            </div>
          </div>
          <Container.Hr />
          <div
            className={cn(
              'grid grid-cols-[150px_1fr] gap-y-[25px] text-[14px]',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
              เงื่อนไขการซื้อ :
            </span>
            <div className='grid gap-y-[20px]'>
              <div className='flex space-x-[40px]'>
                <Card.TextVertical
                  label='ใน :'
                  value={
                    promotionSaleConditionValue[promotionSale.conditionType]
                  }
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
                {promotionSale.conditionType === PSConditionTypeEnum.ALL ? (
                  <Card.TextVertical
                    label='หมวดหมู่ :'
                    value={`${promotionSale.categories.map(item => item.name)}`}
                    valueColor={
                      isDisableStatus
                        ? 'secondary-light'
                        : 'secondary-100-light'
                    }
                  />
                ) : (
                  <Card.TextVertical
                    label='Upload file :'
                    value={promotionSale.conditionFileName}
                    lastLabel={`(${promotionSale.termCount.toLocaleString()} ID)`}
                    valueColor={
                      isDisableStatus
                        ? 'secondary-light'
                        : 'secondary-100-light'
                    }
                  />
                )}
              </div>
              <div className='flex space-x-[40px]'>
                <div className='text-[14px] font-light text-secondary-100'>
                  จำนวน :
                  <span
                    className={cn('text-sm  ml-[10px]', {
                      'text-secondary-100': isDisableStatus,
                      'text-secondary': !isDisableStatus,
                    })}
                  >
                    {promotionSale.actionValue.toLocaleString()}
                    <span className='text-secondary-100 mx-[10px]'>
                      เหรียญขึ้นไป
                    </span>
                    <span className='text-secondary-100'>
                      (
                      {`${promotionSale.coinsCondition.map(item => item.name)}`}
                      )
                    </span>
                  </span>
                </div>
                <div className='flex space-x-[8px]'>
                  <span className='text-[14px] font-light text-secondary-100'>
                    ใช้ได้ในวัน :
                  </span>
                  <span
                    className={cn('flex flex-wrap text-sm text-secondary', {
                      'text-secondary': isDisableStatus,
                      'text-secondary-100': !isDisableStatus,
                    })}
                  >
                    {usageDay?.length
                      ? usageDay.length === 7
                        ? 'ทุกวัน'
                        : usageDay.slice(0, 4).map((day, index) => (
                            <React.Fragment key={index}>
                              <span>{dayOptionsText(day)}</span>
                              <span className=' mr-1 last:mr-0 last:hidden'>
                                ,
                              </span>
                            </React.Fragment>
                          ))
                      : '-'}
                  </span>
                </div>
                <Card.TextVertical
                  label='ใช้เหรียญ :'
                  value={
                    promotionSaleCoinConditionValue[
                      promotionSale.useCoinConditionType
                    ]
                  }
                  valueColor={
                    isDisableStatus ? 'secondary-light' : 'secondary-100-light'
                  }
                />
              </div>
            </div>
          </div>
          <Container.Hr />

          <div
            className={cn(
              'grid grid-cols-[150px_1fr] gap-y-[25px] text-[14px] items-center',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[14px] font-light text-secondary-100'>
              รับเหรียญ :
            </span>
            <div className='flex space-x-[20px]'>
              <div
                className={cn({
                  'text-secondary': isDisableStatus,
                  'text-secondary-100': !isDisableStatus,
                })}
              >
                {promotionSale.benefitValue} &nbsp;
                <span className='text-secondary-100'>
                  เหรียญ{promotionSale.benefitCoin.name}
                </span>
              </div>
              <div className='text-[14px] font-light text-secondary-100'>
                เก็บเหรียญได้ :
                <span className='text-secondary px-[5px]'>
                  {promotionSale.benefitLimitType !==
                    PromotionChallengeLimitTypeEnum.ONCE &&
                    promotionSale.benefitLimitValue?.toLocaleString()}
                </span>
                <span
                  className={cn({
                    'text-secondary': isDisableStatus,
                    'text-secondary-100':
                      !isDisableStatus ||
                      [
                        PromotionChallengeLimitTypeEnum.PER_DAY,
                        PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
                      ].includes(promotionSale.benefitLimitType),
                  })}
                >
                  {promotionChallengeBenefitLimitTypeText(
                    promotionSale.benefitLimitType
                  )}
                </span>
              </div>
              <div
                className={cn({
                  'text-secondary': isDisableStatus,
                  'text-secondary-100': !isDisableStatus,
                })}
              >
                <span className='text-[14px] mr-[8px] font-light text-secondary-100'>
                  งบประมาณ/แคมเปญ :
                </span>
                {promotionSale?.budget.toLocaleString() || 0} &nbsp;
                <span className='text-secondary-100'>
                  เหรียญ{promotionSale?.benefitCoin.name}
                </span>
              </div>
              {promotionSale?.budgetPerDay && (
                <div className='text-[14px] font-light text-secondary-100'>
                  จำกัดจำนวน &nbsp;
                  <span
                    className={cn({
                      'text-secondary': isDisableStatus,
                      'text-secondary-100': !isDisableStatus,
                    })}
                  >
                    {promotionSale?.budgetPerDay.toLocaleString() || 0} &nbsp;
                  </span>
                  <span className='text-secondary-100'>
                    เหรียญ{promotionSale?.benefitCoin.name} / ต่อวัน
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          className={cn('grid grid-cols-[130px_1fr] gap-y-[25px] text-[14px]', {
            'text-secondary': isDisableStatus,
            'text-secondary-100': !isDisableStatus,
          })}
        >
          <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
            รายละเอียด :
          </span>
          <span className='break-word'>{description || '-'}</span>
        </div>
      )}
      <Container.Hr />
      <div className='flex gap-x-[80px]'>
        <Card.TextHorizontal
          label='จำนวนยอดขายรวม'
          value={promotionSale.summary.totalSale || 0}
          valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
        />
        <Card.TextHorizontal
          label='จำนวนเหรียญที่แจกไปแล้ว'
          value={promotionSale.summary.totalDeal || 0}
          valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
        />
        <Card.TextHorizontal
          label='จำนวนผู้ที่ได้รับรางวัล'
          value={promotionSale.summary.userCount || 0}
          valueColor={isDisableStatus ? 'primary' : 'secondary-100'}
        />
      </div>
    </>
  )
}
