import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { capitalize } from '@libs/utils'
import { Expose, Transform } from 'class-transformer'

export class PurchaseProportionType {
  @Expose()
  @Transform(({ obj }) => {
    if (!obj.paymentMethod || !obj.platform) return 'N/A'

    const value =
      obj.paymentMethod === PaymentMethodEnum.IN_APP_PURCHASE
        ? obj.platform
        : obj.paymentMethod

    return capitalize(value).replace(/_/g, ' ')
  })
  paymentMethod: string

  @Expose() total: number
}
