import React, { useRef } from 'react'
import { useField } from 'formik'
import { UploadCoinFieldProps } from './interface'

export function withUploadCoinField(Component: React.FC<UploadCoinFieldProps>) {
  function WithUploadCoinField() {
    const [{ value }, , { setValue }] = useField('upload')
    const inputRef = useRef<HTMLInputElement>(null)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        setValue({
          url: URL.createObjectURL(files[0]),
          blob: files[0],
        })
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: value?.url,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadCoinField
}
