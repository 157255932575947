import { SortingType } from '@components/Table/interface'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export enum OrderByEnum {
  CATEGORY_NAME = 'CATEGORY_NAME',
  TOTAL_SEARCH = 'TOTAL_SEARCH',
}

export interface CategoryParam {
  orderBy: OrderByEnum
  orderDirection: SortingType
}

export interface SearchResultByCategoryParam
  extends CategoryParam,
    DonationQueryParam {
  page: number
}
