import { gql } from 'graphql-request'

export const GET_TOTAL_USER_AGE_RANGE = gql`
  query GetTotalUserAgeRange($startDate: DateTime, $endDate: DateTime) {
    getTotalUserAgeRange(startDate: $startDate, endDate: $endDate) {
      ageMoreThanFortyOne
      ageBetweenThirtyOneAndForty
      ageBetweenTwentyOneAndThirty
      ageBetweenSixteenAndTwenty
      ageBelowFifteen
      otherAge
    }
  }
`
