import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SalesLayoutProps, SalesPathType } from './interface'

const salesTabOption = [
  {
    text: 'Sales Report',
    value: 'salesReport',
  },
  {
    text: 'Payment Report',
    value: 'paymentReport',
  },
]
const salesTabPath = {
  salesReport: '/sales/sales-report',
  paymentReport: '/sales/payment-report',
}

export function withSalesLayout(Component: React.FC<SalesLayoutProps>) {
  function WithSalesLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<SalesPathType>('salesReport')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(salesTabPath).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as SalesPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function handleChangeTab(type: SalesPathType) {
      setTab(type)
      navigate(salesTabPath[type])
    }

    const newProps = { salesTabOption, tab, handleChangeTab, ...props }
    return <Component {...newProps} />
  }

  return WithSalesLayout
}
