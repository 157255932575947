import cn from 'classnames'
import { BinIcon, PenIcon } from '@components/Icons'
import { SettingCardProps } from './interface'

export function SettingCard({
  id,
  label,
  name,
  className,
  component,
  componentClassName,
  canDelete,
  canEdit,
  navigateToEditPage,
  onDelete,
}: SettingCardProps) {
  return (
    <div
      className={cn(
        'border border-gray p-[30px] border rounded-[8px]',
        className
      )}
    >
      <div className='text-[18px] font-bold text-secondary mb-[30px]'>
        {name}
        {label && <span className='text-secondary-100'>({label})</span>}
      </div>
      <div className='flex justify-between'>
        <div className={cn('w-[173px]', componentClassName)}>{component}</div>
        <div className='flex gap-x-[10px] w-auto mt-[35px]'>
          {canEdit && (
            <button
              type='button'
              className='flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px]'
              onClick={() => navigateToEditPage(id)}
            >
              <PenIcon width='20' height='20' />
            </button>
          )}
          {canDelete && (
            <button
              type='button'
              className='flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px]'
              onClick={() => onDelete(id)}
            >
              <BinIcon width='20' height='20' />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
