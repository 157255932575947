import { gql } from 'graphql-request'

export const GET_USER_MANAGEMENT = gql`
  query GetUserManagement($userId: Int!) {
    getUserManagement(userId: $userId) {
      id
      username
      email
      penNameCount
      totalBook
      isBan
      writer {
        id
        affiliation
        fullName
        status
      }
      displayName
      penName {
        firstPenName
      }
      avatarImgPath
      bannerImgPath
      currentFreeCoin
      currentPaidCoin
    }
  }
`
