import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { TopMoneySpenderTableProps } from './interface'
import { columns } from './column'

export function TopMoneySpenderTable({
  data,
  handleSort,
  isLoading,
}: TopMoneySpenderTableProps) {
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        Top Paying User
      </h1>
      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'userCoinSpend',
          order: SortingType.DESC,
        }}
      />
    </div>
  )
}
