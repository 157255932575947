import { Outlet } from 'react-router-dom'

import { HighlightBannerPage } from '@features/home/pages/HighlightBannerPage'
import { HighlightBannerPosterPage } from '@features/home/pages/HighlightBannerPage/components/HighlightBannerPosterPage'
import { HighlightBannerBookPage } from '@features/home/pages/HighlightBannerPage/components/HighlightBannerBookPage'
import { CreateHighlightBannerPage } from '@features/home/pages/HighlightBannerPage/components/CreateHighlightBannerPage'
import { CreateHighlightBannerPosterPage } from '@features/home/pages/HighlightBannerPage/components/CreateHighlightBannerPosterPage'
import { CreateHighlightBannerBookPage } from '@features/home/pages/HighlightBannerPage/components/CreateHighlightBannerBookPage'
import { EditHighlightBannerBookPage } from '@features/home/pages/HighlightBannerPage/components/EditHighlightBannerBookPage'
import { EditHighlightBannerPage } from '@features/home/pages/HighlightBannerPage/components/EditHighlightBannerPage'
import { EditHighlightBannerPosterPage } from '@features/home/pages/HighlightBannerPage/components/EditHighlightBannerPosterPage'
import { MainBannerPage } from '@features/home/pages/MainBannerPage'
import { CreateMainBannerPage } from '@features/home/pages/CreateMainBannerPage'
import { EditMainBannerPage } from '@features/home/pages/EditMainBannerPage'
import { BannerEnum } from '@interfaces/BannerEnum'
import { PopupBannerLayout } from '@components/layouts/PopupBannerLayout'
import { BanListPage } from './pages/BanListPage'
import { TopViewPage } from './pages/TopViewPage'
import { TopPurchasePage } from './pages/TopPurchasePage'
import { CreatePopupBannerPage } from './pages/CreatePopupBannerPage'
import { EditPopupBannerPage } from './pages/EditPopupBannerPage'
import { PopupBannerDetailPage } from './pages/PopupBannerDetailPage'
import { PopupBannerManagementPage } from './pages/PopupBannerManagementPage'

export const homeRoutes = [
  {
    path: 'home-page',
    element: <Outlet />,
    children: [
      {
        path: 'main-banner',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <MainBannerPage />,
          },
          {
            path: 'create',
            element: <CreateMainBannerPage />,
          },
          {
            path: 'edit/:id',
            element: <EditMainBannerPage />,
          },
        ],
      },
      {
        path: 'highlight-banner/:position',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <HighlightBannerPage />,
          },
          {
            path: 'poster/create',
            element: <CreateHighlightBannerPage type={BannerEnum.POSTER} />,
          },
          {
            path: 'poster/edit/:id',
            element: <EditHighlightBannerPage type={BannerEnum.POSTER} />,
          },
          {
            path: 'poster/:id',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <HighlightBannerPosterPage />,
              },
              {
                path: 'create',
                element: <CreateHighlightBannerPosterPage />,
              },
              {
                path: 'edit/:id',
                element: <EditHighlightBannerPosterPage />,
              },
            ],
          },
          {
            path: 'book/create',
            element: <CreateHighlightBannerPage type={BannerEnum.BOOK} />,
          },
          {
            path: 'book/edit/:id',
            element: <EditHighlightBannerPage type={BannerEnum.BOOK} />,
          },
          {
            path: 'book/:id',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <HighlightBannerBookPage />,
              },
              {
                path: 'create',
                element: <CreateHighlightBannerBookPage />,
              },
              {
                path: 'edit',
                element: <EditHighlightBannerBookPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'top-view',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <TopViewPage />,
          },
          {
            path: 'ban-list',
            element: <BanListPage title='Top View' />,
          },
        ],
      },
      {
        path: 'top-purchase',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <TopPurchasePage />,
          },
          {
            path: 'ban-list',
            element: <BanListPage title='Top Purchase' />,
          },
        ],
      },
      {
        path: 'pop-up',
        element: <Outlet />,
        children: [
          {
            path: 'pop-up-management',
            element: (
              <PopupBannerLayout>
                <PopupBannerManagementPage />
              </PopupBannerLayout>
            ),
          },
          {
            path: 'pop-up-detail',
            element: (
              <PopupBannerLayout>
                <PopupBannerDetailPage />
              </PopupBannerLayout>
            ),
          },
          {
            path: 'create',
            element: <CreatePopupBannerPage />,
          },
          {
            path: ':id/edit',
            element: <EditPopupBannerPage />,
          },
        ],
      },
    ],
  },
]
