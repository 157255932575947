import { FieldPropsType } from '@interfaces/commons/FieldPropsType'
import { FastField } from 'formik'
import { Tiptap } from '@components/Tiptap'
import { TiptapFieldProps } from './interface'

function TiptapField({ name, editorRef }: TiptapFieldProps) {
  return (
    <FastField name={name}>
      {({ meta, form }: FieldPropsType<any>) => (
        <Tiptap
          editorRef={editorRef}
          initialValue={meta.initialValue}
          onChange={value => {
            form.setFieldValue(name, value)
            setTimeout(() => {
              form.setFieldTouched(name, true)
            })
          }}
        />
      )}
    </FastField>
  )
}

export default TiptapField
