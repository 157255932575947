import { PromotionSettingCard } from '../PromotionSettingCard'
import { PromotionSettingListProps } from './interface'

export function PromotionSettingList({
  queryKey,
  data,
  total,
}: PromotionSettingListProps) {
  return (
    <>
      <div className='text-secondary-100 font-light text-xs flex-shrink-0 mt-[20px] mb-[10px]'>
        โปรโมชั่น :
        <span className='font-bold text-secondary px-[5px]'>{total}</span>
        <span className='text-secondary'>รายการ</span>
      </div>
      {data.map(item => (
        <PromotionSettingCard
          key={item.id}
          id={item.id}
          businessId={item.businessId}
          queryKey={queryKey}
          promotionType={item.type}
          status={item.status}
          title={item.name}
          description={item.description}
          userTerm={item.userTerm}
          enabled={item.isActive}
          isEditable={item.isEditable}
          usageDay={item.usageDay}
          promotionChallenge={item.promotionChallenge}
          promotionCode={item.promotionCode}
          promotionSale={item.promotionSale}
          userType={item.userType}
          publishedType={item.publishedType}
          publishStartDate={item.startPublishedAt}
          publishEndDate={item.endPublishedAt}
          createdAt={item.createdAt}
          usageLimitPerUser={item.usageLimitPerUser}
          usageLimit={item.usageLimit}
          isUsageLimitPerUser={item.isUsageLimitPerUser}
          isUsageLimit={item.isUsageLimit}
          lastRequest={item.lastRequest}
          usersFileName={item.usersFileName}
          couponValue={item.couponValue}
          users={item.users || []}
        />
      ))}
    </>
  )
}
