import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from '@components/Button'
import { InputField } from '@components/forms/InputField'
import { ForgotPasswordPageProps } from './interface'

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
})

export function ForgotPasswordPage({
  submitForgotPassword,
  isLoading,
}: ForgotPasswordPageProps) {
  return (
    <div className='w-[340px] mobile:hidden'>
      <div className='font-mitr text-[24px] text-center text-black-480 font-medium mt-[65px]'>
        ลืมรหัสผ่าน
      </div>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={submitForgotPassword}
        validationSchema={schema}
      >
        {() => (
          <Form className='mobile:w-full'>
            <div className='py-[20px]'>
              <InputField
                type='email'
                name='email'
                placeholder='อีเมล'
                showErrorMsg={false}
              />
            </div>
            <div className='flex justify-end mb-[65px]'>
              <Link
                to='/login'
                className='font-mitr text-[12px] text-black-480 font-medium underline mt-[5px] cursor-pointer'
              >
                เข้าสู่ระบบ
              </Link>
            </div>
            <Button
              type='submit'
              className='font-mitr text-[14px] w-[340px] text-white bg-primary px-[18px] py-[8px] rounded-[20px]'
              disabled={isLoading}
            >
              รีเซ็ทรหัสผ่าน
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
