import { WriterSectionProps } from './interface'
import { DonationTemplate } from '../DonationTemplate'
import { columns } from './components/column'

export function WriterSection({
  data,
  handleSort,
  handleFetchNextPage,
  isLoading,
}: WriterSectionProps) {
  return (
    <DonationTemplate
      title='Top rank donation by writer'
      data={data}
      isLoading={isLoading}
      columns={columns}
      handleSort={handleSort}
      onWaypointEnter={handleFetchNextPage}
    />
  )
}
