import { gql } from 'graphql-request'

export const GET_ALL_USER_MANAGEMENTS = gql`
  query GetAllUserManagements(
    $page: Int
    $limitPerPage: Int
    $searchText: String
    $userType: UserType!
    $orderBy: UserManagementOrderedList
  ) {
    getAllUserManagements(
      page: $page
      limitPerPage: $limitPerPage
      searchText: $searchText
      userType: $userType
      orderBy: $orderBy
    ) {
      data {
        id
        idNumber
        username
        email
        displayName
        idCardImgPath
        isIdCard
        writer {
          id
          idNumber
          fullName
          affiliation
          status
        }
        penName {
          id
          firstPenName
          secondPenName
        }
        isBan
        ageVerify
        writingDisabled
        isPinEnable
      }
      total
    }
  }
`
