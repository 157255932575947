import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { TextAreaField } from '@components/forms/TextAreaField'
import { formatDateTime } from '@libs/utils'
import { RejectPromotionModalProps } from './interface'

const validationSchema = Yup.object().shape({
  rejectMessage: Yup.string().required(),
})

export function RejectPromotionModal({
  promotionName,
  promotionDetail,
  startPromotion,
  endPromotion,
  createdAt,
  initialValues,
  handleSubmit,
  handleCancel,
}: RejectPromotionModalProps) {
  return (
    <div className='!w-[640px] !py-[40px] !px-[30px]'>
      <Container.Title
        className='text-center pb-[30px] border-b'
        showBackBtn={false}
      >
        Reject
      </Container.Title>
      <div className='-mt-2 text-lg font-bold text-secondary line-clamp-1 break-all'>
        {promotionName}
      </div>
      <div className='w-full text-sm mt-5 flex items-center justify-between'>
        <div className='space-x-1'>
          <span className='text-xs text-secondary-100 font-light'>
            Create date :
          </span>
          <span className=' text-secondary'>
            {formatDateTime(createdAt.toISOString(), { separateText: '-' })}
          </span>
        </div>
        <div className='flex items-center space-x-[10px]'>
          <div className='space-x-1'>
            <span className='text-secondary-100 font-light'>ระยะเวลา :</span>
            <span className='text-secondary font-bold'>
              {startPromotion
                ? formatDateTime(startPromotion.toISOString(), {
                    separateText: '-',
                  })
                : 'ทันที'}
            </span>
          </div>
          <span className='text-secondary-100 font-light'>ถึง</span>
          <span className='text-secondary font-bold'>
            {formatDateTime(endPromotion.toISOString(), { separateText: '-' })}
          </span>
        </div>
      </div>
      <div className='flex gap-x-[38px] items-baseline text-sm border-b pb-5 mt-5 '>
        <span className='text-xs text-secondary-100  whitespace-nowrap font-light'>
          รายละเอียด:
        </span>
        <span className='text-secondary break-word'>
          {promotionDetail || '-'}
        </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <TextAreaField
              className='mt-5 h-full'
              textAreaClassName='h-[120px]'
              label='ส่งข้อความถึงดำเนินการ'
              placeholder='พิมพ์ข้อความ'
              name='rejectMessage'
            />
            <div className='mt-4 flex items-center justify-center space-x-1'>
              <Button
                type='button'
                onClick={handleCancel}
                className='py-2 font-mitr text-sm font-medium'
              >
                <span>ยกเลิก</span>
              </Button>
              <Button
                type='submit'
                className='py-2 text-white bg-primary font-mitr text-sm font-medium w-[100px]'
                disabled={!isValid || isSubmitting}
              >
                <span>ส่ง</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
