import { withPermissionGuard } from '@libs/permission'
import { EditMainBannerPage } from './EditMainBannerPage'
import { withEditMainBannerPage } from './withEditMainBannerPage'

const ConnectedEditMainBannerPage = withPermissionGuard(
  withEditMainBannerPage(EditMainBannerPage),
  'homePageManagement',
  'edit'
)

export { ConnectedEditMainBannerPage as EditMainBannerPage }
