import { Button } from '@components/Button'

import { LeaveCreateModalProps } from './interface'

export function LeaveCreateModal({
  handleSubmit,
  handleCancel,
}: LeaveCreateModalProps) {
  return (
    <div className='flex flex-col  py-[40px] px-[30px] w-[400px] items-center text-secondary text-center'>
      <div className='font-mitr font-medium text-[24px]'>
        ออกจากการสร้าง Promotion
      </div>
      <div className='text-[14px] font-light w-[280px] mt-[20px]'>
        หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้ จะหายไป
        คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่
      </div>
      <div className='flex justify-center gap-x-[10px] mt-[30px]'>
        <Button
          onClick={handleCancel}
          className=' text-secondary text-[14px] px-[10px]'
        >
          ออกจากหน้านี้
        </Button>
        <Button
          onClick={handleSubmit}
          className='text-white bg-primary px-[20px] text-[14px]'
        >
          บันทึกแบบร่าง
        </Button>
      </div>
    </div>
  )
}
