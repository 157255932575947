import React from 'react'

import { useCropImage } from '@hooks/useCropImage'
import { CropImageProps, WithCropImageProps } from './interface'

export function withCropImage(Component: React.FC<CropImageProps>) {
  function WithCropImage({
    width,
    height,
    onChange,
    title = 'เพิ่มรูป',
    ...props
  }: WithCropImageProps) {
    const {
      imgRef,
      uploadUrl,
      filename,
      visible,
      loading,
      hideModal,
      handleFileChange,
      cropImage,
      zoom,
      move,
    } = useCropImage({
      aspectRatio: width / height,
      maxSize: 1,
      onChange,
    })

    const pageProps = {
      ...props,
      imgRef,
      uploadUrl,
      filename,
      visible,
      loading,
      title,
      width,
      height,
      hideModal,
      handleFileChange,
      cropImage,
      zoom,
      move,
    }
    return <Component {...pageProps} />
  }

  return WithCropImage
}
