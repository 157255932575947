import { withPermissionGuard } from '@libs/permission'
import { CreateCodePromotionCodePage } from './CreateCodePromotionCodePage'
import { withCreateCodePromotionCodePage } from './withCreateCodePromotionCodePage'

const ConnectedCreateCodePromotionCodePage = withPermissionGuard(
  withCreateCodePromotionCodePage(CreateCodePromotionCodePage),
  'promotionSetting',
  'create'
)

export { ConnectedCreateCodePromotionCodePage as CreateCodePromotionCodePage }
