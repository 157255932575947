import cn from 'classnames'

import { Button } from '@components/Button'
import { CreateUserMissionLevelButtonProps } from './interface'

export function CreateUserMissionLevelButton({
  hideButton,
  addLevelForm,
}: CreateUserMissionLevelButtonProps) {
  return (
    <div className={cn('mt-[20px]', { hidden: hideButton })}>
      <Button
        className='w-[100px] text-white bg-secondary ml-auto'
        type='button'
        onClick={addLevelForm}
      >
        เพิ่ม level
      </Button>
    </div>
  )
}
