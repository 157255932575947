import { DateTime } from 'luxon'

import { Container } from '@components/Container'
import { PopupBannerForm } from '@features/home/components/PopupBannerForm'
import { PopupBannerPublishedTypeEnum } from '@interfaces/PopupBannerPublishedTypeEnum'
import { PopupBannerDisplayEnum } from '@interfaces/PopupBannerDisplayEnum'
import { CreatePopupBannerPageProps } from './interface'

export function CreatePopupBannerPage({
  goBackToPupUpBannerPage,
  onSubmit,
}: CreatePopupBannerPageProps) {
  return (
    <Container>
      <Container.Title onClickBackBtn={goBackToPupUpBannerPage} showBackBtn>
        Create Pop-up Banner
      </Container.Title>
      <PopupBannerForm
        initialValues={{
          id: 0,
          title: '',
          link: '',
          coverImage: {
            url: '',
          },
          displayAt: PopupBannerDisplayEnum.Home,
          display: [],
          userTypes: [],
          publishedType: PopupBannerPublishedTypeEnum.Now,
          startDate: DateTime.now().toJSDate(),
          endDate: DateTime.now().plus({ month: 1 }).toJSDate(),
        }}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
