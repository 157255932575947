import { gql } from 'graphql-request'

export const GET_PEN_NAME_LIST = gql`
  query GetUserManagement(
    $userId: Int!
    $penNameListUserId: Float!
    $limitPerPage: Int
    $page: Int
    $orderBy: penNameListOrderBy
  ) {
    getUserManagement(userId: $userId) {
      penNameList(
        userId: $penNameListUserId
        limitPerPage: $limitPerPage
        page: $page
        orderBy: $orderBy
      ) {
        data {
          totalOverallBook
          firstPenName
          id
        }
        total
        page
      }
    }
  }
`
