import { ColumnType } from '@components/Table/interface'
import { BookPromotionDetailDashboardType } from '@models/story/BookPromotionDetailDashboardResponse'

export const columns: ColumnType<BookPromotionDetailDashboardType>[] = [
  {
    column: 'โปรโมชั่น',
    accessor: 'promotionName',
    cell: ({ value }) => (
      <p className='w-[250px] pr-[10px] line-clamp-1'>{value}</p>
    ),
  },
  {
    column: 'ราคา',
    accessor: 'price',
    cell: ({ value }) => <p>{value || '-'}</p>,
    cellClassName: 'w-[250px] max-w-[250px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'จำนวนครั้งที่ซื้อ',
    accessor: 'purchaseCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[350px] max-w-[350px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'ยอดซื้อ',
    accessor: 'revenue',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
  },
]
