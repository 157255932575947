import ReactECharts from 'echarts-for-react'

import { CLIENT_URL } from '@configs/config'

export type DataSourceType = {
  name: string
  value: number
  color?: string
}

interface BarChartProps {
  title: string
  dataSource: DataSourceType[]
  style?: React.CSSProperties
  disable?: boolean
}

export function BarChart({ title, dataSource, style, disable }: BarChartProps) {
  const option = {
    title: {
      text: title,
      textStyle: {
        color: '#0c1135',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        fontSize: 24,
      },
    },
    grid: {
      top: 50,
      bottom: 10,
      left: 250,
      right: 30,
    },
    tooltip: {
      trigger: 'item',
      formatter: ({ seriesName, data }: any) => {
        return `${seriesName}<br /> ${
          data.name
        }: <strong>${data.value.toLocaleString()}</strong>`
      },
    },
    dataset: {
      source: dataSource,
    },
    xAxis: {
      max: 'dataMax',
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      inverse: true,
      axisLine: {
        show: false,
      },
      minorSplitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        margin: 10,
        width: 235,
        color: (value: string, index: number) => {
          return dataSource[index]?.color || '#3f54d9'
        },
        formatter: (value: string, index: number) => {
          if (value?.length > 30) {
            return `{name|${value.substring(0, 25)}...} {value|${dataSource[
              index
            ]?.value?.toLocaleString()}}`
          }
          return `{name|${value}} {value|${dataSource[
            index
          ]?.value?.toLocaleString()}}`
        },
        rich: {
          name: {
            fontFamily: 'Sarabun',
            fontSize: 14,
            fontWeight: 300,
            color: '#0c1135',
            width: '100%',
            align: 'left',
          },
          value: {
            fontFamily: 'Sarabun',
            fontSize: 14,
            fontWeight: 300,
          },
        },
      },
      animationDuration: 300,
      animationDurationUpdate: 300,
      triggerEvent: true,
    },
    series: [
      {
        name: title,
        seriesLayoutBy: 'row',
        type: 'bar',
        barWidth: '10px',
        itemStyle: {
          color: (params: any) => {
            return params.value.color || '#3f54d9'
          },
          borderRadius: 8,
        },
        encode: {
          x: 'value',
          y: 'name',
        },
      },
    ],
  }

  const onEvents = {
    click: (params: any) => {
      if (params.targetType === 'axisLabel' && !disable) {
        window.open(`${CLIENT_URL}${params.value}`, '_blank')
      }
    },
  }

  return (
    <ReactECharts
      option={option}
      style={style}
      notMerge
      lazyUpdate
      onEvents={onEvents}
    />
  )
}
