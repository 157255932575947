import React from 'react'

import { Button } from '@components/Button'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/Icons'
import { EndChapter } from '@components/EndChapter'
import { HorizontalStyleProps } from './interface'

export function HorizontalStyle({
  chapterPages,
  totalPage,
  page,
  nextPage,
  prevPage,
}: HorizontalStyleProps) {
  return (
    <>
      <div className='relative flex flex-col gap-y-[20px] m-auto '>
        <div className='text-secondary dark:text-white m-auto'>
          <span className='text-[24px] font-[700]'>{page}</span>
          <span className='text-[18px] font-[300]'> / {totalPage}</span>
        </div>
        <div className='max-w-[700px] m-auto'>
          <img src={chapterPages[page - 1].imgPath} alt='' />
        </div>
        <Button
          className='absolute left-0 top-[50%] border h-[40px] border-secondary bg-secondary text-white'
          type='button'
          disabled={page === 1}
          onClick={prevPage}
        >
          <ArrowLeftIcon width='24' height='24' />
        </Button>
        <Button
          className='absolute right-0 top-[50%] border border-secondary bg-secondary text-[14px] text-white h-[40px]'
          type='button'
          disabled={page === totalPage}
          onClick={nextPage}
        >
          {page === totalPage ? (
            'จบตอน'
          ) : (
            <ArrowRightIcon width='24' height='24' />
          )}
        </Button>
      </div>
      {page === totalPage && <EndChapter />}
    </>
  )
}
