import { SortingType } from '@components/Table/interface'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export enum OrderByEnum {
  DISPLAY_NAME = 'DISPLAY_NAME',
  TOTAL_PEN_NAME = 'TOTAL_PEN_NAME',
  TOTAL_BOOK = 'TOTAL_BOOK',
  TOTAL_SEARCH = 'TOTAL_SEARCH',
}

export interface WriterParam {
  orderBy: OrderByEnum
  orderDirection: SortingType
}

export interface SearchResultByWriterParam
  extends WriterParam,
    DonationQueryParam {
  page: number
}
