import { gql } from 'graphql-request'

export const EXPORT_WRITER_DASHBOARD_BY_ID = gql`
  query ExportWriterDashboardById(
    $startDate: DateTime
    $endDate: DateTime
    $userId: Int!
    $writerId: Int!
    $penNameId: Int
  ) {
    exportWriterDashboardById(
      startDate: $startDate
      endDate: $endDate
      userId: $userId
      writerId: $writerId
      penNameId: $penNameId
    )
  }
`
