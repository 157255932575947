import { CoinHistorySelectTabProps } from './interface'

export function withCoinHistorySelectTab(
  Component: React.FC<CoinHistorySelectTabProps>
) {
  function WithCoinHistorySelectTab(props: CoinHistorySelectTabProps) {
    return <Component {...props} />
  }

  return WithCoinHistorySelectTab
}
