import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class PopularBookSearchLog {
  @Expose() totalPopularSearch: string

  @Expose() searchText: string
}

@Exclude()
export class PopularBookSearchLogResponse {
  @Expose()
  @Type(() => PopularBookSearchLog)
  data: PopularBookSearchLog[]

  @Expose() page: number
}
