import { withPermissionGuard } from '@libs/permission'
import { CreatePromotionSalePage } from './CreatePromotionSalePage'
import { withCreatePromotionSalePage } from './withCreatePromotionSalePage'

const ConnectedCreatePromotionSalePage = withPermissionGuard(
  withCreatePromotionSalePage(CreatePromotionSalePage),
  'promotionSetting',
  'create'
)

export { ConnectedCreatePromotionSalePage as CreatePromotionSalePage }
