import React from 'react'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { StoryCardProps, WithStoryCardProps } from './interface'

export function withStoryCard(Component: React.FC<StoryCardProps>) {
  function WithStoryCard({
    header,
    topChartId,
    title,
    imageUrl,
    publisher,
    writer,
    penName,
    handleBanBook,
    isLoading,
    ...props
  }: WithStoryCardProps) {
    const banModal = useModal({ modal: 'ban' })
    const confirmModal = useModal({
      modal: 'confirm',
    })

    function showBanModal() {
      banModal.show({
        header,
        topChartId,
        title,
        imageUrl,
        publisher,
        writer,
        penName,
        handleBanBook,
        isLoading,
        onClose: () => banModal.hide(),
      })
    }

    function showUnBanModal() {
      confirmModal.show({
        content: (
          <div className='w-[450px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันยกเลิกการแบนหนังสือเล่มนี้หรือไม่?
            </p>
          </div>
        ),
        isLoading,
        onConfirm: () => handleBanBook(topChartId),
        onClose: () => banModal.hide(),
      })
    }

    const newProps = {
      header,
      topChartId,
      title,
      imageUrl,
      publisher,
      writer,
      penName,
      isLoading,
      handleBanBook,
      showBanModal,
      showUnBanModal,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithStoryCard
}
