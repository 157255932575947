import cx from 'classnames'

import { ButtonProps } from './interface'

export function Button({
  type,
  className,
  icon,
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cx(
        'flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 disabled:cursor-not-allowed',
        className
      )}
      {...props}
    >
      <div className='flex items-center'>
        {icon && <div className='mr-[10px]'>{icon}</div>}
        {children}
      </div>
    </button>
  )
}
