import { OverAllChapterProps, WithOverAllChapterProps } from './interface'

export function withOverAllChapter(Component: React.FC<OverAllChapterProps>) {
  function WithOverAllChapter({
    hasPrice,
    freePrice,
  }: WithOverAllChapterProps) {
    const sum = hasPrice + freePrice
    const hasPricePercent = (hasPrice / sum) * 100 || 0
    const freePricePercent = (freePrice / sum) * 100 || 0

    const props = {
      hasPrice,
      hasPricePercent,
      freePrice,
      freePricePercent,
    }

    return <Component {...props} />
  }

  return WithOverAllChapter
}
