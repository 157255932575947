import { Expose, Transform, Type } from 'class-transformer'
import { PromotionRequestActionEnum } from '@interfaces/PromotionRequestActionEnum'
import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { PromotionAvailableDayEnum } from '@interfaces/promotionDiscount/PromotionAvailableDayEnum'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import { PromotionUserTypeConditionEnum } from '@interfaces/promotionDiscount/PromotionUserTypeConditionEnum'
import { PromotionTypeEnum } from '@interfaces/PromotionTypeEnum'
import { PromotionPublishedTypeEnum } from '@interfaces/PromotionPublishedTypeEnum'

class User {
  @Expose() id: number

  @Expose() displayName: string

  @Expose()
  @Transform(({ value }) => value || '/images/avatar.png')
  avatarImgPath: string
}

// DESC: class NewPromotionLastRequest is cloned from PromotionDetailType because prevent eslint import/no-cycle
class NewPromotionLastRequest {
  @Expose() id: number

  @Expose() businessId?: string

  @Expose() name?: string

  @Expose()
  description?: string

  @Expose()
  userTerm?: string

  @Expose()
  startPublishedAt?: string // DESC: DateTime string

  @Expose()
  endPublishedAt?: string // DESC: DateTime string

  @Expose()
  publishedType: PromotionPublishedTypeEnum

  @Expose()
  type: PromotionTypeEnum

  @Expose()
  userType: PromotionUserTypeConditionEnum // TODO: If promotion code using different type, refactor this later

  @Expose()
  isUsageLimitPerUser?: boolean

  @Expose()
  usageLimitPerUser?: number

  @Expose()
  isUsageLimit?: boolean

  @Expose()
  usageLimit?: number

  @Expose()
  usageDay?: PromotionAvailableDayEnum[] // TODO: If promotion code using different type, refactor this later

  @Expose()
  status: PromotionStatusEnum // TODO: If promotion code using different type, refactor this later

  @Expose()
  @Type(() => User)
  users: User[]

  @Expose() isActive: boolean

  @Expose() isEditable: boolean

  @Expose() createdAt: string // DESC: DateTime string

  @Expose() updatedAt: string // DESC: DateTime string

  @Expose() deletedAt?: string // DESC: DateTime string
}

export class PromotionLastRequestType {
  @Expose() id: number

  @Expose() action?: PromotionRequestActionEnum

  @Expose()
  @Type(() => User)
  createdBy: User

  @Expose() createdAt: string // DESC: DateTime string

  @Expose() status: PromotionRequestStatusEnum

  @Expose()
  @Type(() => NewPromotionLastRequest)
  newPromotion?: NewPromotionLastRequest
}
