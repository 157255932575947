import cn from 'classnames'
import { Typography } from '@features/sales/components/Typography'
import { ReportSummaryDetailProps } from './interface'

export function ReportSummaryDetail({
  className,
  downloadTotal,
  remainCoin,
  salesSystem,
  donateSystem,
  shareSystem,
  activeUser,
  salesTotal,
  amountTotal,
  donateTotal,
}: ReportSummaryDetailProps) {
  return (
    <>
      <div
        className={cn(
          'flex justify-between text-[14px] font-light text-secondary-100 pb-[20px]',
          className
        )}
      >
        <Typography
          label='ยอดดาวน์โหลด'
          text={
            <span className='font-bold text-[24px]'>
              {downloadTotal.toLocaleString()}
            </span>
          }
        />
        {/* <Typography
          label='จำนวนเหรียญทองคงเหลือในระบบ'
          text={
            <span className='font-bold text-[24px]'>
              {remainCoin.toLocaleString()}
            </span>
          }
        /> */}
        <Typography
          label='ส่วนแบ่งยอดขายเข้าระบบ'
          text={
            <span className='font-bold text-[24px] text-secondary'>
              {salesSystem.toLocaleString()}
            </span>
          }
        />
        <Typography
          label='ส่วนแบ่งยอดสนับสนุนเข้าระบบ'
          text={
            <span className='font-bold text-[24px] text-secondary'>
              {donateSystem.toLocaleString()}
            </span>
          }
        />
        <Typography
          label='ส่วนแบ่งเข้าระบบทั้งหมด'
          text={
            <span className='font-bold text-[24px] text-primary'>
              {shareSystem.toLocaleString()}
            </span>
          }
        />
      </div>
      <div
        className={cn(
          'flex justify-between text-[14px] font-light text-secondary-100 py-[20px] border-y-[1px] border-gray'
        )}
      >
        <Typography
          row
          label='Daily active user'
          text={
            <span className='font-bold  text-secondary'>
              {activeUser.toLocaleString()}
            </span>
          }
        />
        <Typography
          row
          label='ยอดขาย'
          text={
            <span className='font-bold  text-secondary'>
              {salesTotal.toLocaleString()}
            </span>
          }
        />
        <Typography
          row
          label='ยอดซื้อตอนนี้'
          text={
            <span className='font-bold  text-secondary'>
              {amountTotal.toLocaleString()}
            </span>
          }
        />
        <Typography
          row
          label='ยอดสนับสนุน'
          text={
            <span className='font-bold  text-secondary'>
              {donateTotal.toLocaleString()}
            </span>
          }
        />
        <Typography
          row
          label='จำนวนเหรียญทองคงเหลือในระบบ'
          text={
            <span className='font-bold  text-secondary'>
              {remainCoin.toLocaleString()}
            </span>
          }
        />
      </div>
    </>
  )
}
