import { gql } from 'graphql-request'

export const GET_READTIME_USAGE = gql`
  query GetReadTimeUsage($endDate: DateTime, $startDate: DateTime) {
    getReadTimeUsage(endDate: $endDate, startDate: $startDate) {
      hour
      count
    }
  }
`
