import { Link } from 'react-router-dom'

import { Button } from '@components/Button'
import { promotionRequestActionText } from '@libs/promotion/utils'
import { formatDateTime } from '@libs/utils'
import { PromotionSaleApproveSectionProps } from './interface'

function PromotionSaleApproveSection({
  promotionId,
  actionBy,
  handleApprove,
  handleReject,
}: PromotionSaleApproveSectionProps) {
  return (
    <>
      <div className='w-full border-b my-5 h-[1px]' />
      <div className='flex justify-between flex-row-reverse h-[50px]'>
        <div className='flex items-center space-x-5'>
          <Link
            to={`/promotion-setting/promotion-sale/${promotionId}/form-detail`}
          >
            <Button
              type='button'
              className='px-0 text-secondary font-mitr text-sm font-medium'
            >
              <span>ดูรายละเอียด</span>
            </Button>
          </Link>
          <Button
            type='button'
            onClick={handleReject}
            className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
          >
            <span>Reject</span>
          </Button>
          <Button
            type='button'
            onClick={handleApprove}
            className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
          >
            <span>Approve</span>
          </Button>
        </div>
        {actionBy && (
          <div className='flex items-center space-x-5'>
            <div className='flex items-center space-x-[10px]'>
              <span className='text-secondary-100 font-bold text-sm'>
                ดำเนินการโดย :
              </span>
              {actionBy.imageUrl ? (
                <img
                  className='w-[50px] h-[50px] rounded-full'
                  src={actionBy.imageUrl}
                  alt='User cover'
                />
              ) : (
                <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
              )}
              <div className='space-y-1 text-sm'>
                <p className='text-secondary font-bold'>
                  {actionBy.displayName || '-'}
                </p>
                <p className='text-secondary-100 font-normal'>
                  {formatDateTime(actionBy.actionAt)}
                </p>
              </div>
            </div>
            <div className='w-[1px] h-full border-r' />
            <div className='text-sm font-bold text-primary'>
              {promotionRequestActionText(actionBy.actionType)}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PromotionSaleApproveSection
