import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

import { client } from '@client/init'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { useDebounce } from '@hooks/useDebounce'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { SaleConditionFormProps } from './interface'

export function withSaleConditionForm(
  Component: React.FC<SaleConditionFormProps>
) {
  function Hoc(props: SaleConditionFormProps) {
    const { debounce } = useDebounce()
    const { values, dirty, setFieldValue, setValues } =
      useFormikContext<PromotionSaleFormType>()

    useEffect(() => {
      function setFieldData(data: number[], res: boolean) {
        if (res) {
          setFieldValue('termIds', data)
          setFieldValue('termCount', data.length)
          setFieldValue('termIdInputError', undefined)
        } else {
          setFieldValue(
            'termIdInputError',
            'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
          )
        }
      }
      function setError(error: any, isCheckBook: boolean) {
        const e = error.response.errors[0]
        if (e.errorCode === 400102 && isCheckBook) {
          setFieldValue(
            'termIdInputError',
            `*ไม่พบ Book ID ${e.extras?.writerIds?.join(',')} ในระบบ`
          )
        } else if (e.errorCode === 40092) {
          setFieldValue(
            'termIdInputError',
            `*ไม่พบ Writer ID ${e.extras?.writerIds?.join(',')} ในระบบ`
          )
        } else {
          setFieldValue(
            'termIdInputError',
            'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
          )
        }
      }
      debounce(() => {
        if (values.termIdInput) {
          const termId = values.termIdInput.split(',')
          if (termId.length <= 10) {
            const a = termId.map(Number)
            // transform string to number,check input type number or string if number('1,2,3') transform to number if string('a,b,c') transform to null
            const b = a.filter(item => item)
            // filter null
            if (a.length === b.length) {
              // if a.length !== b.length wrong format

              // check duplicate
              if (new Set(a).size === a.length) {
                if (values.conditionType === PSConditionTypeEnum.STORY) {
                  client?.promotionClient
                    .checkBookExist(a)
                    .then(res => setFieldData(a, res))
                    .catch(error => setError(error, true))
                } else {
                  client?.promotionClient
                    .checkWriterExist(a)
                    .then(res => setFieldData(a, res))
                    .catch(error => setError(error, false))
                }
              } else {
                setFieldValue(
                  'termIdInputError',
                  '*มีรายการ ID ซ้ำ ไม่สามารถใช้ได้'
                )
              }
            } else {
              setFieldValue('termIdInputError', '*รูปแบบไม่ถูกต้อง')
            }
          } else {
            setFieldValue('termIdInputError', '*สามารถกรอก ได้สูงสุด 10 ID')
          }
        } else {
          setFieldValue('termIdInputError', undefined)
        }
      }, 600)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.termIdInput])

    useEffect(() => {
      if (dirty) {
        setValues({
          ...values,
          categoryIds: [],
          conditionFile: undefined,
          conditionFileError: undefined,
          termIds: [],
          termIdInput: '',
          termIdInputError: undefined,
          termCount: 0,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.conditionType])

    const newProps = {
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
