import { useState } from 'react'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useSearchHistoryColumns } from '../useSearchHistoryColumns'
import { SearchHistoryProps, WithSearchHistoryProps } from './interface'

export function withSearchHistory(Component: React.FC<SearchHistoryProps>) {
  function WithSearchHistory({
    data,
    selectedId,
    handleSelectAll,
    handleSelect,
    ...props
  }: WithSearchHistoryProps) {
    const client = useClient()
    const alert = useAlert()

    async function blockWordingHandler(keyword: string) {
      try {
        await client?.searchManagementClient.manageSearchKeyword({
          action: 'BAN',
          keywords: keyword,
        })
        alert.success('บล็อคคำค้นหานี้สำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    async function addWordingHandler(keyword: string) {
      try {
        await client?.searchManagementClient.manageSearchKeyword({
          action: 'RECOMMEND',
          keywords: keyword,
        })
        alert.success('เพิ่มคำค้นที่แนะนำสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    async function blockWordingSelcet() {
      try {
        await client?.searchManagementClient.manageSearchKeyword({
          action: 'BAN',
          keywords: data
            .filter(item => selectedId.includes(item.id))
            .map(item => item.searchText),
        })
        alert.success('บล็อคคำค้นหาสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    async function addWordingSelect() {
      try {
        await client?.searchManagementClient.manageSearchKeyword({
          action: 'RECOMMEND',
          keywords: data
            .filter(item => selectedId.includes(item.id))
            .map(item => item.searchText),
        })
        alert.success('เพิ่มคำค้นที่แนะนำสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    const { columns } = useSearchHistoryColumns(
      selectedId,
      data.length,
      handleSelectAll,
      handleSelect,
      blockWordingHandler,
      addWordingHandler
    )

    const newProps = {
      data,
      columns,
      selectedIdLenght: selectedId.length,
      blockWordingSelcet,
      addWordingSelect,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithSearchHistory
}
