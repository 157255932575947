import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TabsProps, WithTabsProps } from './interface'

export function withTabs(Component: React.FC<TabsProps>) {
  function WithTabs({
    initialTab,
    tabs,
    mode,
    isLoading = false,
    queryTabKey,
    handleTabChange,
  }: WithTabsProps) {
    const [params] = useSearchParams()
    const [activeTab, setActiveTab] = useState(initialTab)
    useEffect(() => {
      if (queryTabKey) {
        setActiveTab((params.get(queryTabKey) as string) || tabs[0].key)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabs, queryTabKey])

    function onTabChange(value: string) {
      setActiveTab(value)
      if (handleTabChange) handleTabChange(value)
    }

    const componentProps = {
      tabs,
      mode,
      activeTab,
      isLoading,
      onTabChange,
    }

    return <Component {...componentProps} />
  }

  return WithTabs
}
