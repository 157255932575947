import { DeviceSectionProps, WithDeviceSectionProps } from './interface'

export function withDeviceSection(Component: React.FC<DeviceSectionProps>) {
  function WithDeviceSection({ data }: WithDeviceSectionProps) {
    const temp = [...data.countBuyChapterByPlatform]
    const maxValue = Math.max(
      ...data.countBuyChapterByPlatform.map(i => i.count)
    )
    const topThree = temp.sort((a, b) => b.count - a.count).slice(0, 3)

    const props = { data, topThree, maxValue }

    return <Component {...props} />
  }

  return WithDeviceSection
}
