import { DateTime } from 'luxon'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { SummaryCoinSearchFormType } from '@models/coin/SummaryCoinSearchFormType'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { SummaryCoinPageProps, SummaryCoinQueryParam } from './interface'

export function withSummaryCoinPage(Component: React.FC<SummaryCoinPageProps>) {
  function WithSummaryCoinPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const [searchParam, setSearchParam] = useState<SummaryCoinQueryParam>({
      timeRange: TimeRangeEnum.MONTH,
      startAt: DateTime.now()
        .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
    })

    function transformData({
      timeRange,
      startAt,
      endAt,
    }: SummaryCoinSearchFormType) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return {
        timeRange,
        startAt: newStartAt,
        endAt: newEndAt,
      }
    }

    async function handleSubmit(form: SummaryCoinSearchFormType) {
      const { startAt, endAt, timeRange } = transformData(form)

      if (startAt && endAt) {
        setSearchParam({ timeRange, startAt, endAt })
      }
    }

    async function handleExport() {
      try {
        await client?.coinClient.exportCoinSummary({
          startDate: searchParam.startAt,
          endDate: searchParam.endAt,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const { data: purchaseProportion = [] } = useQuery(
      ['purchase-proportion', searchParam],
      () =>
        client?.saleClient.getPurchaseProportion({
          searchParam,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const newProps = {
      handleSubmit,
      handleExport,
      searchParam,
      purchaseProportion,
    }
    return <Component {...newProps} />
  }

  return WithSummaryCoinPage
}
