import { useCallback, useState } from 'react'
import { Table } from '@components/Table'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import {
  ColumnType,
  SortByType,
  SortingType,
} from '@components/Table/interface'

interface TableProps<T> {
  data: T[]
  columns: ColumnType<T>[]
}

export interface TableComponentProps extends FunctionComponentType {
  onSort?: (sort: SortByType) => void
  onWaypointEnter?: () => void
  fixedHeader?: boolean
  headerClassName?: string
  bodyClassName?: string
  isLoading?: boolean
}

export function useTable<T>({ data, columns }: TableProps<T>) {
  const [sortBy, setSortBy] = useState<SortByType>({
    key: '',
    order: SortingType.ASC,
  })

  function resetSortBy() {
    setSortBy({
      key: '',
      order: SortingType.ASC,
    })
  }

  const TableComponent = useCallback(
    ({
      className,
      onSort,
      onWaypointEnter,
      headerClassName,
      bodyClassName,
      fixedHeader,
      isLoading,
    }: TableComponentProps) => {
      return (
        <Table
          className={className}
          data={data}
          columns={columns}
          headerClassName={headerClassName}
          bodyClassName={bodyClassName}
          initialSort={sortBy}
          fixedHeader={fixedHeader}
          onWaypointEnter={onWaypointEnter}
          isLoading={isLoading}
          onSort={sort => {
            setSortBy(sort)
            if (onSort) {
              onSort(sort)
            }
          }}
        />
      )
    },
    [data, columns, sortBy, setSortBy]
  )

  return { Table: TableComponent, resetSortBy }
}
