import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useFormikContext } from 'formik'
import { useClient } from '@hooks/useClient'
import { ByUserSearchFormType } from '@models/dashboard/ByUserSearchFormType'
import { PenNameFieldProps, WithPenNameFieldProps } from './interface'

export function withPenNameField(Component: React.FC<PenNameFieldProps>) {
  function WithPenNameField({ penNameHandle }: WithPenNameFieldProps) {
    const client = useClient()
    const {
      values: { userId },
    } = useFormikContext<ByUserSearchFormType>()

    const { data: penNameOptions = [] } = useQuery(
      ['pen-name-options', userId],
      () =>
        client?.userClient.getUserPenNames(Number(userId)).then(res => {
          penNameHandle(res.length)
          return res.map(row => ({
            value: String(row.id),
            label: row.firstPenName,
          }))
        }),
      { enabled: !!userId }
    )

    const newProps = {
      penNameOptions,
    }
    return <Component {...newProps} />
  }

  return WithPenNameField
}
