import React from 'react'
import { useQuery } from 'react-query'
import { client } from '@client/init'

import { IncomeByPennameProps, WithIncomeByPennameProps } from './interface'

export function withIncomeByPenname(Component: React.FC<IncomeByPennameProps>) {
  function WithIncomeByPenname({
    queryParam,
    className,
  }: WithIncomeByPennameProps) {
    const { data = [], isLoading } = useQuery(
      ['penNameIncome', queryParam],
      () =>
        client?.dashboardClient.getPenNameIncome({
          writerId: Number(queryParam.writerId),
          penNameId: Number(queryParam.penNameId),
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
        }),
      {
        enabled: !!queryParam.writerId,
      }
    )

    const newProps = {
      data,
      className,
      isLoading,
    }
    return <Component {...newProps} />
  }

  return WithIncomeByPenname
}
