import { client } from '@client/init'
import { useDebounce } from '@hooks/useDebounce'
import { CoinCoditionEnum } from '@interfaces/promotionCode/CoinCoditionEnum'
import { CoinOptionEnum } from '@interfaces/promotionCode/CoinOptionEnum'
import { DiscountOptionEnum } from '@interfaces/promotionCode/DiscountOptionEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  PromotionConditionProps,
  WithPromotionConditionProps,
} from './interface'

const coinConditionOption = [
  {
    label: '>',
    value: CoinCoditionEnum.GREATER_THAN,
  },
  {
    label: '<',
    value: CoinCoditionEnum.LESS_THAN,
  },
  {
    label: '>=',
    value: CoinCoditionEnum.GREATER_THAN_EQUAL,
  },
  {
    label: '<=',
    value: CoinCoditionEnum.LESS_THAN_EQUAL,
  },
]
const discountTypeOptions = [
  {
    label: 'บาท',
    value: DiscountOptionEnum.BAHT,
  },
  {
    label: '%',
    value: DiscountOptionEnum.PERCENTAGE,
  },
]
const forUserTypeOptions = [
  {
    label: 'ผู้ใช้งานใหม่',
    value: PromotionUserEnum.NEW_USER,
  },
  {
    label: 'ผู้ใช้งานทั้งหมด',
    value: PromotionUserEnum.ALL_USER,
  },
  {
    label: 'กำหนดผู้ใช้งาน',
    value: PromotionUserEnum.SPECIFY_USER,
  },
]

const withPromotionCondition = (
  Component: React.FC<PromotionConditionProps>
) => {
  function WithPromotionCondition({
    coinOptions,
    ...props
  }: WithPromotionConditionProps) {
    const { values, dirty, setFieldValue, setFieldError } =
      useFormikContext<PromotionCodeFormType>()
    const { debounce } = useDebounce()

    const goldCoinId = useMemo(
      () => coinOptions.find(item => item.isGoldCoin)?.value,
      [coinOptions]
    )
    useEffect(() => {
      debounce(() => {
        if (values.userIdInput) {
          const userIds = values.userIdInput.split(',')
          if (userIds.length <= 10) {
            const a = userIds.map(item => Number(item))
            // transform string to number,check input type number or string if number('1,2,3') tranform to numbebr if string('a,b,c') tranform to null
            const b = a.filter(item => item)
            // filter null
            if (a.length === b.length) {
              // if a.length !== b.length wrong format
              client?.promotionClient
                .checkUserExist(a)
                .then(res => {
                  if (res) {
                    setFieldValue('userIds', a)
                    setFieldValue('userIdInputError', undefined)
                  }
                })
                .catch(error => {
                  const e = error.response.errors[0]
                  if (e.errorCode === 4001) {
                    setFieldValue(
                      'userIdInputError',
                      '*มีรายการ User ID ซ้ำ ไม่สามารถใช้ได้'
                    )
                  } else if (e.errorCode === 4002) {
                    setFieldValue(
                      'userIdInputError',
                      `*ไม่พบ User ID #${e.extras.userIds.join(',')}# ในระบบ`
                    )
                    // add # to split userIds
                  } else {
                    setFieldValue(
                      'userIdInputError',
                      'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
                    )
                  }
                })
            } else {
              setFieldValue('userIdInputError', '*รูปแบบไม่ถูกต้อง')
            }
          } else {
            setFieldValue(
              'userIdInputError',
              '*สามารถกรอก user ID ได้สูงสุด 10 ID'
            )
          }
        } else {
          setFieldValue('userIdInputError', undefined)
        }
      }, 600)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.userIdInput])

    useEffect(() => {
      if (dirty) {
        if (
          values.codeChannelInput &&
          values.limitJoinPromotion &&
          values.limitJoinPromotionType === LimitToJoinEnum.LIMIT
        ) {
          const limitPromotionCode =
            values.codeChannelInput * values.limitJoinPromotion
          setFieldValue('limitPromotionCode', limitPromotionCode)
          // auto cal limitPromotionCode
        }

        if (values.limitJoinPromotionType === LimitToJoinEnum.UNLIMIT) {
          setFieldValue('limitPromotionCode', values.codeChannelInput)
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      values.codeChannelInput,
      values.limitJoinPromotion,
      values.limitJoinPromotionType,
    ])

    useEffect(() => {
      if (dirty) {
        const maxLimitPromotionCode =
          values.codeChannelInput * values.limitJoinPromotion
        if (
          values.limitPromotionCode > maxLimitPromotionCode &&
          values.limitJoinPromotionType === LimitToJoinEnum.LIMIT
        ) {
          setFieldValue('limitPromotionCode', maxLimitPromotionCode)
        }

        if (
          values.limitJoinPromotionType === LimitToJoinEnum.UNLIMIT &&
          values.limitPromotionCode > values.codeChannelInput
        ) {
          setFieldValue('limitPromotionCode', values.codeChannelInput)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.limitPromotionCode])

    useEffect(() => {
      if (values.isFirstTopUp) {
        setFieldValue('limitJoinPromotion', 1)
        setFieldValue('limitJoinPromotionType', LimitToJoinEnum.LIMIT)
        // if isFirstTopup  fix limitJoinPromotion 1
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.isFirstTopUp])

    useEffect(() => {
      if (values.moreCoinOption === goldCoinId) {
        setFieldValue('moreCoinInput', Math.floor(Number(values.moreCoinInput)))
      }
      if (values.directCoinOption === goldCoinId) {
        setFieldValue(
          'directCoinInput',
          Math.floor(Number(values.directCoinInput))
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.directCoinOption, values.moreCoinOption, goldCoinId])

    const newProps = {
      coinConditionOption,
      forUserTypeOptions,
      discountTypeOptions,
      coinOptions,
      goldCoinId,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithPromotionCondition
}

export default withPromotionCondition
