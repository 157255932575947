import cn from 'classnames'
import { ByTitle } from './components/ByTitle'
import { ByUser } from './components/ByUser'
import { Overview } from './components/Overview'

import { EbookPageProps, TabActiveEnum } from './interface'

export function EbookPage({ tabActive, handleSelectTab }: EbookPageProps) {
  const menusArray = [
    {
      value: 'overview',
      text: 'Overview',
    },
    {
      value: 'byTitle',
      text: 'By Title',
    },
    {
      value: 'byUser',
      text: 'By User',
    },
  ]
  return (
    <>
      <div className='text-[14px] text-secondary font-light gap-x-[15px] mb-5 flex border-b border-gray'>
        {menusArray.map(item => (
          <button
            key={item.value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  item.value === tabActive,
                'text-secondary-100 font-light': item.value !== tabActive,
              }
            )}
            onClick={() => handleSelectTab(item.value)}
          >
            {item.text}
          </button>
        ))}
      </div>
      {tabActive === TabActiveEnum.OVERVIEW && <Overview />}
      {tabActive === TabActiveEnum.BY_TITLE && <ByTitle />}
      {tabActive === TabActiveEnum.BY_USER && <ByUser />}
    </>
  )
}
