import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Button } from '@components/Button'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/Icons'
import { ReadingPageFooterProps } from './interface'

export function ReadingPageFooter({
  bookId,
  prevId,
  nextId,
}: ReadingPageFooterProps) {
  return (
    <div className='flex justify-between py-[50px] px-[60px] text-white'>
      <Link to={`/preview/${bookId}/chapter/${prevId}`}>
        <Button
          type='button'
          className='border border-gray text-secondary-100 h-[40px]'
          disabled={!bookId || !prevId}
        >
          <div className='flex items-center text-sm gap-x-[5px]'>
            <ArrowLeftIcon width='24' height='24' />
            ตอนก่อนหน้า
          </div>
        </Button>
      </Link>
      <Link to={`/preview/${bookId}/chapter/${nextId}`}>
        <Button
          type='button'
          className='bg-primary h-[40px]'
          disabled={!bookId || !nextId}
        >
          <div className='flex items-center text-sm gap-x-[5px]'>
            ตอนต่อไป
            <ArrowRightIcon width='24' height='24' />
          </div>
        </Button>
      </Link>
    </div>
  )
}
