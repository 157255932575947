import React from 'react'
import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { BannerEnum } from '@interfaces/BannerEnum'
import { DAY, getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { HighlightBannerFormType } from '@models/highlightBanner/HighlightBannerFormType'
import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'
import { HighlightBannerPositionEnum } from '@interfaces/mainBanner/HighlightBannerPositionEnum'
import {
  CreateHighlightBannerPageProps,
  WithCreateHighlightBannerPageProps,
} from './interface'

export function withCreateHighlightBannerPage(
  Component: React.FC<CreateHighlightBannerPageProps>
) {
  function WithCreateHighlightBannerPage({
    type,
  }: WithCreateHighlightBannerPageProps) {
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()
    const { position } = useParams()

    const initialValues = plainToInstance(HighlightBannerFormType, {
      bannerName: '',
      bannerType: '',
    })

    const { mutate } = useMutation(
      (form: HighlightBannerFormType) =>
        client!.highlightBannerClient.createHighlightGroupBanner(
          form,
          position!.toUpperCase() as HighlightBannerPositionEnum
        ),
      {
        onSuccess: (response: HighlightBannerType) => {
          const link =
            type === BannerEnum.POSTER
              ? `/home-page/highlight-banner/${position}/poster/${response?.id}`
              : `/home-page/highlight-banner/${position}/book/${response?.id}`

          navigate(link)
        },
        onError: (error: any) => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: HighlightBannerFormType) {
      const nextDate = new Date(new Date(Date.now() + DAY * 30))

      mutate({
        ...form,
        bannerType: type,
        publishedFrom: DateTime.fromJSDate(new Date()).toUTC().toString(),
        publishedTo: DateTime.fromJSDate(nextDate).toUTC().toString(),
      })
    }

    const componentProps = {
      type,
      initialValues,
      onSubmit,
    }
    return <Component {...componentProps} />
  }

  return WithCreateHighlightBannerPage
}
