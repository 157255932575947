import React from 'react'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useStory } from '@hooks/story/useStory'
import { PayRankProps, WithPayRankProps } from './interface'

function withPayRank(Component: React.FC<PayRankProps>) {
  function WithPayRank({ className }: WithPayRankProps) {
    const viewAllSponsorsModal = useModal({ modal: 'viewAllSponsors' })
    const { data } = useStory()

    const handleViewAll = () => {
      if (data?.id) {
        viewAllSponsorsModal.show({ bookId: data.id })
      }
    }

    const newProps = {
      className,
      supporters: data?.supporters || [],
      handleViewAll,
    }

    if (!data?.supporters?.length) return null

    return <Component {...newProps} />
  }
  return WithPayRank
}

export { withPayRank }
