import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import PromotionChallengeInfo from '@features/promotionSetting/components/PromotionChallengeInfo'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PromotionChallengeTransactionList } from '@features/promotionSetting/components/PromotionChallengeTransactionList'
import { Loading } from '@components/Loading'
import { PromotionChallengeDetailPageProps } from './interface'
import { PromotionChallengeApproveSection } from './components/PromotionChallengeApproveSection'

function PromotionChallengeDetailPage({
  detail,
  isLoading,
  handleActivePromotion,
  handleGoToHistoryPage,
  handleDeletePromotion,
  handleEditPromotion,
}: PromotionChallengeDetailPageProps) {
  const permission = usePermissionAction('promotionSetting')
  return (
    <Container>
      {!isLoading ? (
        detail ? (
          <>
            <Container.Title showBackBtn>
              <div className='flex items-center justify-between'>
                <div>{detail?.name || '-'}</div>
                <Button
                  onClick={handleGoToHistoryPage}
                  type='button'
                  className='border border-secondary rounded-[20px]'
                >
                  <span className='font-mitr font-medium text-sm'>
                    ดูประวัติการแก้ไขโปรโมชั่น
                  </span>
                </Button>
              </div>
            </Container.Title>
            <Card>
              <PromotionChallengeInfo
                status={detail.status}
                isShowActionMenu
                detail={detail}
                handleActivePromotion={
                  permission.edit &&
                  detail.isEditable &&
                  (detail.status === PromotionStatusEnum.STARTED ||
                    detail.status === PromotionStatusEnum.CLOSED) &&
                  new Date(detail.startPublishedAt!) <= new Date() &&
                  new Date(detail.endPublishedAt!) >= new Date()
                    ? () => {
                        handleActivePromotion(!detail.isActive)
                      }
                    : undefined
                }
                // DESC: enabled edit button when lastRequest has no value (meaning no promotion request to be approved or rejected)
                handleEditPromotion={
                  permission.edit && detail.isEditable && !detail.lastRequest
                    ? handleEditPromotion
                    : undefined
                }
                handleDeletePromotion={
                  permission.delete &&
                  detail.status === PromotionStatusEnum.DRAFT
                    ? handleDeletePromotion
                    : undefined
                }
              />
              {/* DESC: show approval section when lastRequest has value (meaning there is promotion request to be approved or rejected) */}
              {permission.approve &&
                (detail.status === PromotionStatusEnum.PENDING ||
                  !!detail.lastRequest) && (
                  <PromotionChallengeApproveSection
                    requestId={detail.lastRequest?.id}
                    promotionId={detail.id}
                    promotionName={detail.name || ''}
                    promotionDetail={detail.description}
                    startPromotion={new Date(detail.startPublishedAt!)}
                    endPromotion={new Date(detail.endPublishedAt!)}
                    createdAt={new Date(detail.createdAt)}
                    actionBy={
                      detail.lastRequest
                        ? {
                            imageUrl:
                              detail.lastRequest.createdBy.avatarImgPath,
                            displayName:
                              detail.lastRequest.createdBy.displayName,
                            actionAt: detail.lastRequest.createdAt,
                            actionType: detail.lastRequest.action,
                          }
                        : undefined
                    }
                  />
                )}
            </Card>
            <div className='flex gap-x-[80px]'>
              <Card.TextHorizontal
                label='เก็บ Challenge ไปแล้วทั้งหมด (ครั้ง)'
                value={detail.promotionChallenge?.summary.progressCount || 0}
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label='User ทั้งหมด'
                value={detail.promotionChallenge?.summary.userCount || 0}
                valueColor='secondary'
              />
              <Card.TextHorizontal
                label={`มูลค่าเหรียญ${
                  detail.promotionChallenge?.summary.coin.name || ''
                }`}
                value={detail.promotionChallenge?.summary.coinValue || 0}
                valueColor='primary'
              />
            </div>
            <PromotionChallengeTransactionList
              action={detail.promotionChallenge?.action}
              promotionChallengeId={detail.promotionChallenge?.id}
            />
          </>
        ) : (
          <div>เกิดข้อผิดพลาด กรุณาลองใหม่ในภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}

export default PromotionChallengeDetailPage
