import { gql } from 'graphql-request'

export const EXPORT_PROMOTION_CHALLENGE = gql`
  query ExportPromotionChallenge(
    $promotionId: Int!
    $promotionChallengeId: Int!
    $action: PromotionChallengeAction!
    $startDate: DateTime!
    $endDate: DateTime!
    $searchText: String
  ) {
    exportPromotionChallenge(
      promotionId: $promotionId
      promotionChallengeId: $promotionChallengeId
      action: $action
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
    )
  }
`
