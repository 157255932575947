import { gql } from 'graphql-request'

export const EXPORT_USER_MISSION = gql`
  query ExportUserMissionDetail(
    $challengeId: Int!
    $challengeType: ChallengeType!
  ) {
    exportUserMissionDetail(
      challengeId: $challengeId
      challengeType: $challengeType
    )
  }
`
