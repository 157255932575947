import { gql } from 'graphql-request'

export const GET_BOOK_PROMOTION_DETAIL_DASHBOARD = gql`
  query GetBookDashboardDetail(
    $bookId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: GetBookDashboardDetailOrderBy
    $limitPerPage: Int
    $page: Int
  ) {
    getBookDashboardDetail(
      bookId: $bookId
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        price
        promotionName
        purchaseCount
        revenue
      }
      page
      total
    }
  }
`
