import { Expose, Transform } from 'class-transformer'

import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'

export class HighlightGroupBannerFormType {
  @Expose()
  @Transform(({ value }) => new Date(value))
  publishedFrom: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  publishedTo: Date

  @Expose()
  @Transform(({ obj }) => {
    const currentDate = new Date().valueOf()
    const publishedFrom = new Date(obj.publishedFrom).valueOf()

    return currentDate >= publishedFrom
      ? PublishedEnum.IMMEDIATELY
      : PublishedEnum.CUSTOM
  })
  publishedType: PublishedEnum
}
