import { Expose, Type } from 'class-transformer'

import { PopupBannerPublishedTypeEnum } from '@interfaces/PopupBannerPublishedTypeEnum'
import { PopupBannerDisplayEnum } from '@interfaces/PopupBannerDisplayEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { UserPlatformTypeEnum } from '@interfaces/UserPlatformTypeEnum'
import { LogType } from './LogType'

export class PopupBannerType {
  @Expose() id: number

  @Expose() imgPath: string

  @Expose() title: string

  @Expose() link: string

  @Expose() screen: PopupBannerDisplayEnum

  @Expose() platforms: PlatformEnum[]

  @Expose() publishedType: PopupBannerPublishedTypeEnum

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose()
  @Type(() => LogType)
  logs?: LogType

  @Expose() userTypes: UserPlatformTypeEnum[]
}
