import { Expose } from 'class-transformer'
import { ChartDataType } from './ChartDataType'

export class AnalyticsEbookDetailGraphDataType extends ChartDataType {
  @Expose({ name: 'value', toClassOnly: true })
  yValue: number

  @Expose({ name: 'date' })
  xValue: string
}
