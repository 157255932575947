import React, { useState } from 'react'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'

import {
  ConfirmToCreateModalProps,
  WithConfirmToCreateModalProps,
} from './interface'

export function withConfirmToCreateModal(
  Component: React.FC<ConfirmToCreateModalProps>
) {
  function ConfirmToCreateModal({ ...props }: WithConfirmToCreateModalProps) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const confirmCreateModal = useModal({ modal: 'confirmCreatePromotionCode' })

    function handleCancel() {
      confirmCreateModal.hide()
    }

    const componentProps = {
      handleCancel,
      isSubmitting,
      setIsSubmitting,
      ...props,
    }
    return <Component {...componentProps} />
  }

  return ConfirmToCreateModal
}
