import { Expose } from 'class-transformer'

export class WriterBookSaleDetailType {
  @Expose() id: number

  @Expose() name: string

  @Expose() price: number

  @Expose() purchasedCount: number

  @Expose() sales: string

  @Expose() writerIncome: string
}
