import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { UserMissionType } from '@models/user-mission/UserMissionType'
import { UserMissionDetailPageProps } from './interface'

export function withUserMissionDetailPage(
  Component: React.FC<UserMissionDetailPageProps>
) {
  function WithUserMissionDetailPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const { id } = useParams()

    const { data: dataUserMission, isLoading: isLoadingDataUserMission } =
      useQuery(
        ['user-mission', id],
        () => client?.userMissionClient.getUserMission(Number(id)),
        { enabled: !!id }
      )

    const {
      data: dataLevelUserCount = [],
      isLoading: isLoadingDataLevelUserCount,
    } = useQuery(
      ['challenge-level-user-count', id],
      () => client?.userMissionClient.getchallengeLevelUserCount(Number(id)),
      { enabled: !!id }
    )

    const { mutate: updateEnable } = useMutation(
      (form: { id: number; active: boolean }) =>
        client!.userMissionClient.updateEnableUserMission(form),
      {
        onSuccess: res => {
          queryClient.setQueryData<UserMissionType | undefined>(
            ['user-mission', id],
            oldData => {
              if (oldData && res) {
                return {
                  ...oldData,
                  active: res.active,
                }
              }

              return oldData
            }
          )
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleToggle() {
      const isEnable = dataUserMission?.active

      updateEnable({ id: Number(id), active: !isEnable })
    }

    if (
      isLoadingDataUserMission ||
      isLoadingDataLevelUserCount ||
      !dataUserMission
    )
      return <></>

    const componentProps = {
      id: Number(id),
      dataUserMission,
      dataLevelUserCount,
      handleToggle,
      navigate,
    }
    return <Component {...componentProps} />
  }

  return WithUserMissionDetailPage
}
