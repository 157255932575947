import { Container } from '@features/promotionSetting/components/Container'
import {
  PromotionIcon,
  PatchCheckIcon,
  PromotionEmptyIcon,
  SalePromotionIcon,
} from '@components/Icons'
import { PromotionSettingSearchForm } from '@features/promotionSetting/components/PromotionSettingSearchForm'
import { SelectDropdown } from '@components/SelectDropdown/SelectDropdown'
import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { PromotionSettingTabs } from '@features/promotionSetting/components/PromotionSettingTabs'
import { PromotionSettingList } from '@features/promotionSetting/components/PromotionSettingList'
import { SelectButton } from '@components/SelectButton'
import { Loading } from '@components/Loading'
import { Pagination } from '@components/Pagination'
import { PromotionSettingPageProps } from './interface'

export function PromotionSettingPage({
  haveData,
  menus,
  promotionShow,
  promotionOrder,
  selectedPromotionShow,
  selectedPromotionOrder,
  activePromotionTab,
  searchParams,
  initialValues,
  page,
  perpage,
  handlePageChange,
  navigate,
  onActiveTabChange,
  handleSubmitSearch,
  handleResetSearch,
  handleSelectPromotionShow,
  handleSelectPromotionOrder,
  activeChallengeTab,
  setActiveChallengeTab,
  queryKey,
  data,
  total,
  isLoading,
}: PromotionSettingPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between font-mitr'>
        <Container.Title value='Promotion Setting' />
        <div className='flex gap-x-5'>
          <Container.MenuButton
            value='สร้างโปรโมชั่น Code'
            icon={<PromotionIcon />}
            onClick={() => navigate('/promotion-setting/promotion-code/create')}
          />
          <Container.MenuButton
            value='สร้างโปรโมชั่น Challenge'
            icon={<PatchCheckIcon />}
            onClick={() =>
              navigate('/promotion-setting/promotion-challenge/create')
            }
          />
          <Container.MenuButton
            value='สร้างโปรโมชั่นการขาย'
            icon={<SalePromotionIcon />}
            onClick={() => navigate('/promotion-setting/promotion-sale/create')}
          />
        </div>
      </section>
      {haveData ? (
        <>
          <PromotionSettingSearchForm
            initialValues={initialValues}
            handleSubmit={handleSubmitSearch}
            handleResetSearch={handleResetSearch}
          />
          <Container.Hr />
          <div className='flex justify-between'>
            <div className='overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
              <SelectButton
                options={menus}
                value={activePromotionTab}
                onChange={onActiveTabChange}
                className='gap-y-[15px]'
              />
            </div>
            <section className='flex gap-x-5'>
              <SelectDropdown
                value={selectedPromotionShow}
                options={promotionShow}
                handleSelect={handleSelectPromotionShow}
                className='w-full min-w-[180px]'
                inputClassName='font-light text-secondary-100'
              />
              <SelectDropdown
                value={selectedPromotionOrder}
                options={promotionOrder}
                handleSelect={handleSelectPromotionOrder}
                placeholder='เรียงตาม'
                className='w-full min-w-[180px] max-w-[200px]'
                inputClassName='font-light text-secondary-100'
              />
            </section>
          </div>
          {activePromotionTab === SettingPromotionMenuTypeEnum.CHALLENGE && (
            <PromotionSettingTabs
              activeChallengeTab={activeChallengeTab}
              setActiveChallengeTab={activeTab => {
                setActiveChallengeTab(activeTab)
                navigate({
                  search: `?tab=${activePromotionTab}&challengeTab=${activeTab}`,
                })
              }}
            />
          )}
          {isLoading ? (
            <Loading size={32} className='m-auto mt-[100px]' />
          ) : total > 0 ? (
            <>
              <PromotionSettingList
                queryKey={queryKey}
                data={data}
                total={total}
              />
              <section className='my-[30px]'>
                <Pagination
                  page={page}
                  perpage={perpage}
                  total={total}
                  pageChange={handlePageChange}
                />
              </section>
            </>
          ) : (
            <div className='flex mt-[80px]'>
              <div className='flex flex-col items-center justify-center w-full text-secondary-100'>
                <PromotionEmptyIcon
                  className='text-secondary-100 mb-[20px]'
                  width='68'
                  height='68'
                />
                <p className='text-[24px] px-[93px] mb-[8px] font-light font-mitr'>
                  ไม่มีรายการโปรโมชั่น
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='flex h-screen'>
          <div className='flex flex-col items-center justify-center w-full text-secondary-100'>
            <PromotionEmptyIcon
              className='text-secondary-100 mb-[20px]'
              width='68'
              height='68'
            />
            <p className='text-[24px] px-[93px] mb-[8px] font-light font-mitr'>
              ไม่มีรายการโปรโมชั่น
            </p>
            <p className='text-[14px] font-light'>
              คุณสามารถสร้าง Promotion ได้ 3 แบบจากเมนูด้านบนได้แก่
            </p>
            <p className='text-[14px] font-light'>
              สร้างโปรโมชั่น Code, สร้างโปรโมชั่นส่วนลด, สร้างโปรโมชั่น
              Challenge
            </p>
          </div>
        </div>
      )}
    </Container>
  )
}
