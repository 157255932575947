import { BookEnum } from '@interfaces/BookEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeReadConditionEnum } from '@interfaces/promotionChllenge/PromotionChallengeReadConditionEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { SocialMediaChannelsEnum } from '@interfaces/promotionChllenge/SocialMediaChannelsEnum'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { PromotionLastRequestType } from '../PromotionLastRequestType'
import { CoinConditionType } from './CoinConditionType'

class PromotionChallengeFormType {
  @Expose()
  id?: number

  @Expose()
  categories: {
    id: number
    name: string
  }[]

  @Expose()
  action: PromotionChallengeActionEnum

  @Expose()
  actionValue?: number

  @Expose()
  wordCount?: number

  @Expose()
  socialMediaChannels: SocialMediaChannelsEnum[]

  @Expose()
  readCondition?: PromotionChallengeReadConditionEnum

  @Expose()
  @Type(() => CoinConditionType)
  coinsCondition?: CoinConditionType[]

  @Expose()
  bookTypes: BookEnum[]

  @Expose()
  benefitValue: number

  @Expose()
  @Transform(({ value }) => String(value?.name) || undefined, {
    toClassOnly: true,
  })
  benefitCoin?: number

  @Expose()
  benefitLimitType?: PromotionChallengeLimitTypeEnum

  @Expose()
  benefitLimitValue?: number

  @Expose()
  budget: number

  @Expose()
  budgetPerDay?: number

  @Expose()
  summary: {
    userCount: number
    progressCount: number
    coinValue: number
    coin: {
      name: string
    }
  }

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  createdAt?: string // DESC: DateTime string
}
export class PromotionChallengeDetailType {
  @Expose() id: number

  @Expose() businessId: string

  @Expose()
  name: string

  @Expose()
  description: string

  @Expose()
  userTerm: string

  @Expose()
  startPublishedAt: string

  @Expose()
  endPublishedAt: string

  @Expose()
  publishedType: PromotionPublishedEnum

  @Expose()
  status: PromotionStatusEnum

  @Expose()
  createdAt: string // DateTime string

  @Expose() updatedAt: string // DateTime string

  @Expose() deletedAt: string // DateTime string

  @Expose() isActive: boolean

  @Expose() isEditable: boolean

  @Expose()
  @Type(() => PromotionChallengeFormType)
  promotionChallenge: PromotionChallengeFormType

  @Expose()
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}
