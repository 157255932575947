import { CategorySectionProps, WithCategorySectionProps } from './interface'

export function withCategorySection(Component: React.FC<CategorySectionProps>) {
  function WithCategorySection({ data }: WithCategorySectionProps) {
    const temp = [...data.countByCategory]
    const maxValue = Math.max(...data.countByCategory.map(i => i.count))
    const topThree = temp.sort((a, b) => b.count - a.count).slice(0, 3)

    const props = { data, topThree, maxValue }

    return <Component {...props} />
  }

  return WithCategorySection
}
