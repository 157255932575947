import React from 'react'
import { PopupFormAcceptProps, PopupFormProps } from './interface'

export function withPopupForm(Component: React.FC<PopupFormProps>) {
  function WithPopupForm({
    onResetForm,
    onExport,
    ...props
  }: PopupFormAcceptProps) {
    function resetHandler() {
      onResetForm()
    }
    const newProps = {
      ...props,
      resetHandler,
      handleExport: onExport,
    }
    return <Component {...newProps} />
  }

  return WithPopupForm
}
