import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { EbookDetailType } from '@models/ebook/EbookDetailType'
import { BoughtEbookDetailResponse } from '@models/ebook/BoughtEbookDetailResponse'
import { SortingType } from '@components/Table/interface'
import { EbookSalesReportEnum } from '@interfaces/EbookSalesReportEnum'
import { DateTime } from 'luxon'
import { AnalyticsEbookDetailType } from '@models/ebook/AnalyticsEbookDetailType'
import { GET_EBOOK_DETAIL } from './schemas/getEbookDetail'
import { GET_BOUGHT_EBOOK_DETAIL } from './schemas/getBoughtEbookDetail'
import { GET_ANALYTICS_EBOOK_DETAIL } from './schemas/getAnalyticsEbookDetail'

export class EbookClient {
  constructor(private client: GraphQLClient) {}

  async getEbookDetail(ebookId: number): Promise<EbookDetailType> {
    const { ebookDetail } = await this.client.request(GET_EBOOK_DETAIL, {
      ebookId,
    })

    return plainToInstance(EbookDetailType, ebookDetail)
  }

  async getBoughtEbookDetail({
    page,
    ebookId,
    startDate,
    endDate,
    key,
    order,
  }: {
    page: number
    ebookId: number
    startDate: DateTime
    endDate: DateTime
    key: string
    order: SortingType
  }): Promise<BoughtEbookDetailResponse> {
    const { boughtEbookDetail } = await this.client.request(
      GET_BOUGHT_EBOOK_DETAIL,
      {
        limitPerPage: 10,
        page,
        ebookId,
        startDate,
        endDate,
        orderBy: key ? { [key]: order } : undefined,
      }
    )

    return plainToInstance(BoughtEbookDetailResponse, boughtEbookDetail)
  }

  async getAnalyticsEbookDetail({
    reportType,
    ebookId,
    startDate,
    endDate,
    sortKey,
    sortValue,
  }: {
    reportType: EbookSalesReportEnum
    ebookId: number
    startDate: DateTime
    endDate: DateTime
    sortKey: string
    sortValue: SortingType
  }): Promise<AnalyticsEbookDetailType> {
    const { analyticsEbookDetail } = await this.client.request(
      GET_ANALYTICS_EBOOK_DETAIL,
      {
        reportType,
        ebookId,
        startDate,
        endDate,
        orderBy: sortKey ? { [sortKey]: sortValue } : undefined,
      }
    )

    return plainToInstance(AnalyticsEbookDetailType, analyticsEbookDetail)
  }
}
