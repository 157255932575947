import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { CategoryCoverPageProps } from './interface'

const withCategoryCoverPage = (Component: React.FC<CategoryCoverPageProps>) => {
  function WithCategoryCoverPage() {
    const navigate = useNavigate()
    const { id } = useParams()
    const alert = useAlert()
    const [selectedCoverIds, setSelectedCoverIds] = useState<number[]>([])
    const client = useClient()
    const confirmModal = useModal({
      modal: 'confirm',
    })

    function goToCreateCoverPage() {
      navigate(`/chapter-book/cover/${id}/create`)
    }

    function goToEditCoverPage(coverId: number) {
      navigate(`/chapter-book/cover/${id}/edit/${coverId}`)
    }

    function handleSelectCover(selectedId: number) {
      if (selectedCoverIds.includes(selectedId)) {
        setSelectedCoverIds(
          selectedCoverIds.filter(coverId => coverId !== selectedId)
        )
      } else {
        setSelectedCoverIds([...selectedCoverIds, selectedId])
      }
    }

    const {
      data: category,
      isLoading,
      refetch,
    } = useQuery(
      ['category_cover', id],
      () => client?.categoryClient.category(Number(id)),
      { enabled: !!id }
    )

    const { mutate: removeManyCategoryCover } = useMutation(
      (ids: number[]) => client!.categoryClient.removeManyCategoryCover(ids),
      {
        onSuccess: () => {
          alert.success('ลบปกหนังสือสำเร็จ')
          setSelectedCoverIds([])
          confirmModal.hide()
          refetch()
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
          confirmModal.hide()
        },
      }
    )

    const handleRemoveCategoryCover = (removeId?: number) => {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบปกหนังสือนี้หรือไม่?
            </p>
          </div>
        ),
        onConfirm: () =>
          removeManyCategoryCover(removeId ? [removeId] : selectedCoverIds),
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    if (!isLoading && category) {
      const newProps = {
        category,
        goToCreateCoverPage,
        selectedCoverIds,
        handleSelectCover,
        goToEditCoverPage,
        handleRemoveCategoryCover,
      }
      return <Component {...newProps} />
    }
    return <></>
  }

  return WithCategoryCoverPage
}

export default withCategoryCoverPage
