import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { CodeTypeEnum } from '@interfaces/promotionCode/CodeTypeEnum'
import { CoinCoditionEnum } from '@interfaces/promotionCode/CoinCoditionEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { DiscountOptionEnum } from '@interfaces/promotionCode/DiscountOptionEnum'
import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { PromotionLastRequestType } from './PromotionLastRequestType'

class ChannelsType {
  @Expose() name: string

  @Expose() totalCount: number

  @Expose() totalUsedCount: number
}

export class PromotionCodeDetailCodeType {
  @Expose() id: number

  @Expose() type: UserByEnum

  @Expose()
  @Transform(({ value }) => value || 0)
  buyCoinConditionValue: number

  @Expose() buyCoinCondition: CoinCoditionEnum

  @Expose() paymentChannels: PaymentMethodEnum[]

  @Expose()
  @Transform(({ value }) => value || 0)
  benefitValue: number

  @Expose()
  @Transform(({ obj }) => obj.coin?.name || '')
  coinName: string

  @Expose()
  @Type(() => ChannelsType)
  channels: ChannelsType[]

  @Expose() couponType: CodeTypeEnum

  @Expose() codesFilePath: string

  @Expose() codesFileName: string

  @Expose() exportedCouponFilePath?: string
}

export class PromotionCodeDetailType {
  @Expose() id: number

  @Expose() businessId: string

  @Expose() name: string

  @Expose() description: string

  @Expose() userTerm: string

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose() publishedType: PromotionPublishedEnum

  @Expose() userType: PromotionUserEnum

  @Expose()
  @Transform(({ value }) => value || 0)
  usageLimitPerUser: number

  @Expose() isUsageLimitPerUser: boolean

  @Expose()
  @Transform(({ value }) => value || 0)
  usageLimit: number

  @Expose() isUsageLimit: boolean

  @Expose() usageDay: DaySelectEnum[]

  @Expose()
  @Transform(({ obj, value }) => {
    if (value === 'DRAFT' && obj.generateCouponStatus === 'PROCESSING') {
      return PromotionStatusEnum.GENERATE_CODE
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'COMPLETED') {
      return PromotionStatusEnum.CODE_GENERATED
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'FAILED') {
      return PromotionStatusEnum.GENERATE_FAIL
    }
    return value
  })
  status: PromotionStatusEnum

  @Expose()
  @Transform(({ obj }) => {
    if (Array.isArray(obj?.users)) {
      return obj.users.length
    }
    return 0
  })
  userLength: number

  @Expose() isActive: boolean

  @Expose() isEditable: boolean

  @Expose() usersFileName: string

  @Expose() usersFilePath: string

  @Expose() createdAt: string

  @Expose() generateCouponStatus: PromotionCodeJobStatusEnum

  @Expose() isFirstTopUp: boolean

  @Expose()
  @Type(() => PromotionCodeDetailCodeType)
  promotionCode: PromotionCodeDetailCodeType

  @Expose()
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}
