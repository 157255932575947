import { PennameSectionProps } from './interface'

export function withPennameSection(Component: React.FC<PennameSectionProps>) {
  function WithPennameSection({ writerId, userId }: PennameSectionProps) {
    const newProps = {
      writerId,
      userId,
    }
    return <Component {...newProps} />
  }
  return WithPennameSection
}
