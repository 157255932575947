import { OverAllStoryProps, WithOverAllStoryProps } from './interface'

export function withOverAllStory(Component: React.FC<OverAllStoryProps>) {
  function WithOverAllStory({ isEnded, notEnded }: WithOverAllStoryProps) {
    const sum = isEnded + notEnded
    const isEndedPercent = (isEnded / sum) * 100 || 0
    const notEndedPercent = (notEnded / sum) * 100 || 0

    const props = {
      isEnded,
      isEndedPercent,
      notEnded,
      notEndedPercent,
    }

    return <Component {...props} />
  }

  return WithOverAllStory
}
