import { Expose, Transform, Type } from 'class-transformer'

export class CountBookByEnding {
  @Expose() isEnded: number

  @Expose() notEnded: number
}

export class CountBookChapterByPrice {
  @Expose() hasPrice: number

  @Expose() freePrice: number
}

export class CountByCategory {
  @Expose() name: string

  @Expose() count: number
}

export class CountBuyChapterByPlatform {
  @Expose()
  @Transform(({ value }) => {
    return value === 'web'
      ? 'ซื้อผ่านเว็บ'
      : value === 'android'
      ? 'ซื้อผ่าน Android'
      : 'ซื้อผ่าน iOS'
  })
  platform: string

  @Expose() count: number
}

export class StoryType {
  @Expose() countBook: number

  @Expose() countBookChapter: number

  @Expose() countBookChapterPublish: number

  @Expose()
  @Type(() => CountBookByEnding)
  countBookByEnding: CountBookByEnding

  @Expose()
  @Type(() => CountBookChapterByPrice)
  countBookChapterByPrice: CountBookChapterByPrice

  @Expose()
  @Type(() => CountBuyChapterByPlatform)
  countBuyChapterByPlatform: CountBuyChapterByPlatform[]

  @Expose()
  @Type(() => CountByCategory)
  countByCategory: CountByCategory[]
}
