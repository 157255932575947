import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { useAuthentication } from '@hooks/useAuthentication'
import { ConsentProps } from './interface'
import { useWriterDetail } from '../../hooks/useWriterDetail'

export function withConsent(Component: React.FC<ConsentProps>) {
  function WithConsent() {
    const { user } = useAuthentication()
    const { writer } = useWriterDetail()
    const client = useClient()
    const termsOfServiceModal = useModal({ modal: 'writerTermsOfService' })
    const { data: consentHistory } = useQuery(
      ['consent-history', writer?.writerId],
      () => client?.userWriterClient.getConsentHistory(writer!.writerId!),
      { enabled: writer?.writerId !== undefined }
    )

    function openTermsOfServiceModal(
      consentId: number,
      content: string,
      title: string,
      version: string,
      submittedDate: string,
      writersConsentId: number
    ) {
      if (writer) {
        termsOfServiceModal.show({
          content,
          consentId,
          userId: writer.user.id,
          adminId: user.id,
          title,
          submittedDate,
          version,
          writersConsentId,
        })
      }
    }

    const componentProps = {
      consentHistory,
      writer,
      isPending:
        writer?.status === WriterStatusEnum.EDITED_PENDING ||
        writer?.status === WriterStatusEnum.CREATED_PENDING,
      openTermsOfServiceModal,
    }

    if (!writer) return <></>

    return <Component {...componentProps} />
  }

  return WithConsent
}
