import { TopRankTemplate } from '@components/TopRankTemplate'
import { BookTopChartProvider } from '@hooks/book/useBookTopChart'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'

export function TopPurchasePage() {
  return (
    <BookTopChartProvider
      bookTopChartType={BookTopChartEnum.PURCHASE}
      limit={20}
    >
      <TopRankTemplate title='Top Purchase' />
    </BookTopChartProvider>
  )
}
