import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { CodeChannelTypeEnum } from '@interfaces/promotionCode/CodeChannelTypeEnum '
import { DiscountOptionEnum } from '@interfaces/promotionCode/DiscountOptionEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { CoinCoditionEnum } from '@interfaces/promotionCode/CoinCoditionEnum'
import * as Yup from 'yup'

export const validationSchema = Yup.object()
  .shape({
    promotionName: Yup.string().required('จำเป็น'),
    promotionDetail: Yup.string().required('จำเป็น'),
    promotionCondition: Yup.string().required('จำเป็น'),
    codeChannelInput: Yup.number()
      .moreThan(0, 'มากกว่า 0')
      .max(20000, 'สร้างได้สูงสุดไม่เกิน 20,000 / ครั้ง'),
    limitJoinPromotion: Yup.number().when('limitJoinPromotionType', {
      is: (value: any) => value === LimitToJoinEnum.LIMIT,
      then: Yup.number().moreThan(0, ' '),
    }),
    limitPromotionCode: Yup.number().when('isLimitPromotionCode', {
      is: (value: any) => !!value,
      then: Yup.number().moreThan(0, ' '),
    }),
    availableDay: Yup.array().min(1, ' '),
    userBuyCoinInput: Yup.number()
      .when(['userByType', 'userBuyCoinCondition'], {
        is: (userByType: any, userBuyCoinCondition: any) =>
          userByType === UserByEnum.BUY_COIN &&
          userBuyCoinCondition === CoinCoditionEnum.GREATER_THAN,
        then: Yup.number().min(0, ' '),
      })
      .when(['userByType', 'userBuyCoinCondition'], {
        is: (userByType: any, userBuyCoinCondition: any) =>
          userByType === UserByEnum.BUY_COIN &&
          userBuyCoinCondition === CoinCoditionEnum.LESS_THAN,
        then: Yup.number().moreThan(1, ' '),
      })
      .when(['userByType', 'userBuyCoinCondition'], {
        is: (userByType: any, userBuyCoinCondition: any) =>
          userByType === UserByEnum.BUY_COIN &&
          userBuyCoinCondition === CoinCoditionEnum.GREATER_THAN_EQUAL,
        then: Yup.number().moreThan(0, ' '),
      })
      .when(['userByType', 'userBuyCoinCondition'], {
        is: (userByType: any, userBuyCoinCondition: any) =>
          userByType === UserByEnum.BUY_COIN &&
          userBuyCoinCondition === CoinCoditionEnum.LESS_THAN_EQUAL,
        then: Yup.number().moreThan(0, ' '),
      }),
    paymentMethod: Yup.array().when('userByType', {
      is: (value: any) => value === UserByEnum.BUY_COIN,
      then: Yup.array().min(1, ' '),
    }),
    moreCoinInput: Yup.number().when('userByType', {
      is: (userByType: any) => {
        return userByType === UserByEnum.BUY_COIN
      },
      then: Yup.number().moreThan(0, ' '),
    }),
    moreCoinOption: Yup.string().when('userByType', {
      is: (userByType: any) => {
        return userByType === UserByEnum.BUY_COIN
      },
      then: Yup.string().required(),
    }),
    directCoinInput: Yup.number().when(['userByType'], {
      is: (userByType: any) => {
        return userByType === UserByEnum.REDEEM
      },
      then: Yup.number().moreThan(0, ' '),
    }),
    directCoinOption: Yup.string().when(['userByType'], {
      is: (userByType: any) => {
        return userByType === UserByEnum.REDEEM
      },
      then: Yup.string().required(' '),
    }),
  })
  .test((values, context) => {
    if (
      values.codeChannelType === CodeChannelTypeEnum.OTHER &&
      !values.codePurchase &&
      !values.codeChannelSelect
    ) {
      return context.createError({
        message: 'required',
        path: 'codeChannelSelect',
      })
    }
    if (
      values.forUserType === PromotionUserEnum.SPECIFY_USER &&
      !values.userIdInput &&
      !values.userIdsFile &&
      !values.userIdsFileError
    ) {
      return context.createError({
        message: 'จำเป็น',
        path: 'userIdInput',
      })
    }
    if (values.userIdInputError) {
      return context.createError({
        message: values.userIdInputError,
        path: 'userIdInput',
      })
    }
    if (values.userIdsFileError) {
      return context.createError({
        path: 'userIdsFile',
        message: values.userIdsFileError,
      })
    }

    return true
  })
