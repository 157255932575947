import { useRef } from 'react'
import { useFormikContext } from 'formik'
import { useMutation } from 'react-query'

import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import {
  FileType,
  UploadPromotionSaleFieldAcceptProps,
  UploadPromotionSaleFieldProps,
} from './interface'

const withUploadPromotionSaleField = (
  Component: React.FC<UploadPromotionSaleFieldProps>
) => {
  function WithUploadPromotionSaleField({
    type,
    ...props
  }: UploadPromotionSaleFieldAcceptProps) {
    const { values, setValues, errors } =
      useFormikContext<PromotionSaleFormType>()
    const inputRef = useRef<HTMLInputElement>(null)
    const client = useClient()
    const fileName = useRef(values.conditionFile?.name)

    const { mutateAsync: uploadPromotionSale, isLoading: isCheckingFile } =
      useMutation(
        (uploadInput: { file: File | Blob; type: PSConditionTypeEnum }) =>
          client!.promotionSaleClient.uploadPromotionSaleTerm(uploadInput),
        {
          onSuccess: res => {
            if (res.count) {
              setValues({
                ...values,
                conditionFile: {
                  name: res.fileName,
                  url: res.filePath,
                },
                termIds: res.ids,
                termCount: res.count,
                conditionFileError: undefined,
              })
            } else {
              setValues({
                ...values,
                conditionFile: undefined,
                termIds: [],
                termCount: 0,
                conditionFileError: 'ข้อมูลไม่ถูกต้อง',
              })
            }
          },
          onError: (e: any) => {
            setValues({
              ...values,
              conditionFile: undefined,
              termIds: [],
              termCount: 0,
              conditionFileError: getErrorMessage(e),
            })
          },
        }
      )

    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target
      if (files) {
        try {
          fileName.current = files[0].name
          await uploadPromotionSale({
            file: files[0],
            type,
          })
        } finally {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '' // DECS: clear input value for case that select same file
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    function onClearSelectedFile() {
      fileName.current = undefined
      setValues({
        ...values,
        conditionFile: undefined,
        termIds: [],
        termCount: 0,
        conditionFileError: undefined,
      })
    }

    const newProps = {
      ...props,
      inputRef,
      type,
      fileCount: values.termCount,
      fileName: fileName.current,
      isCheckingFile,
      isError: !!errors.conditionFile,
      onChange: handleChange,
      showUploadDialog,
      onClearSelectedFile,
    }
    return <Component {...newProps} />
  }
  return WithUploadPromotionSaleField
}

export default withUploadPromotionSaleField
