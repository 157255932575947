import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { DAY, getErrorMessage } from '@libs/utils'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { CreatePromotionSalePageProps } from './interface'

export function withCreatePromotionSalePage(
  Component: React.FC<CreatePromotionSalePageProps>
) {
  function WithCreatePromotionSalePage() {
    const [initialValues, setInitialValues] = useState<
      PromotionSaleFormType | undefined
    >(undefined)
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmModal = useModal({ modal: 'confirm' })

    const { data: saleCoinOptions = [], isLoading } = useQuery(
      'sale-coin-options',
      () =>
        client?.coinClient.getPromotionChallengeCoinOptions().then(res => {
          if (res[0].value) {
            setInitialValues({
              name: '',
              description: '',
              userTerm: '',
              startPublishedAt: new Date(),
              endPublishedAt: new Date(Date.now() + DAY * 30),
              publishedType: PromotionPublishedEnum.NOW,
              status: PromotionStatusEnum.PENDING,
              conditionType: PSConditionTypeEnum.ALL,
              termIds: [],
              termIdInput: '',
              categoryIds: [],
              actionValue: 0,
              coinConditionIds: [],
              usageDay: [],
              useCoinConditionType: PSUseCoinConditionTypeEnum.STORY,
              benefitValue: 0,
              benefitCoinId: String(res[0].value),
              benefitLimitType: PromotionChallengeLimitTypeEnum.ONCE,
              benefitLimitValue: 1,
              budget: 0,
              budgetPerDay: 0,
              isLimitBudget: false,
            })
          }

          return res
        })
    )

    const { mutateAsync: createPromotion } = useMutation(
      (values: PromotionSaleFormType) =>
        client!.promotionSaleClient.createPromotionSale(values),
      {
        onSuccess: id => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          confirmModal.hide()
          navigate(`/promotion-setting`) // TODO: redirect to approve page after submit form success
        },
        onError: error => {
          alert.error(getErrorMessage(error))
          confirmModal.hide()
        },
      }
    )
    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionSaleFormType) =>
        client!.promotionSaleClient.createPromotionSale({
          ...values,
          status: PromotionStatusEnum.DRAFT,
        }),
      {
        onSuccess: id => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function createPromotionCode(values: PromotionSaleFormType) {
      await createPromotion(values)
      confirmModal.hide()
    }

    function handleLeave(values: PromotionSaleFormType) {
      confirmModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่นการขาย
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(values)
          confirmModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmModal.hide()
          navigate(-1)
        },
      })
    }

    const newProps = {
      initialValues,
      saleCoinOptions,
      isLoading,
      createPromotionCode,
      handleLeave,
    }

    return <Component {...newProps} />
  }

  return WithCreatePromotionSalePage
}
