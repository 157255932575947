import { DateTime, Duration } from 'luxon'
import Tooltip from '@reach/tooltip'

import {
  CardValue,
  TextLabel,
  TextValue,
} from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import {
  AddIcon,
  HeartIcon,
  ChatIcon,
  Information2Icon,
} from '@components/Icons'
import { OverviewProps } from './interface'

export function Overview({
  user,
  penNameCount,
  summaryActionTime,
  createdAt,
  totalRead,
  firstBookCreatedDate,
  summaryDonate,
  summaryBookPurchase,
  totalBookshelf,
  summaryBookLike,
  summaryBookComment,
  summarySellCount,
  penNameTitle,
  penNameId,
  totalPaidWriterIncome,
  totalPayableWriterIncome,
}: OverviewProps) {
  return (
    <>
      <section className='flex items-center justify-between divide-x'>
        <div className='flex items-center justify-center gap-x-[30px] pr-[30px] '>
          {user.avatarImgPath ? (
            <img
              className='w-[70px] h-[70px] rounded-full object-cover'
              src={user.avatarImgPath}
              alt='user cover'
            />
          ) : (
            <div className='w-[70px] h-[70px] rounded-full' />
          )}
          <div>
            <p className='font-bold text-[#393c4e] break-word line-clamp-1'>
              @{user.username}
            </p>
            <p className='text-[#858baf] text-sm font-light'>ID : {user.id}</p>
          </div>
        </div>
        <div className='flex divide-x'>
          <div className='flex items-center justify-center px-[30px] w-[170px]'>
            {penNameId ? (
              <CardValue>
                <TextLabel>นามปากกา</TextLabel>
                <TextValue className='text-secondary text-base line-clamp-1 break-words'>
                  {penNameTitle}
                </TextValue>
              </CardValue>
            ) : (
              <CardValue>
                <TextLabel>นามปากกาทั้งหมด</TextLabel>
                <TextValue className='text-secondary text-base'>
                  {penNameCount.toLocaleString()}
                </TextValue>
              </CardValue>
            )}
          </div>
          <div className='flex items-center  justify-center px-[30px]'>
            <CardValue>
              <TextLabel>เวลารวมที่ใช้งาน</TextLabel>
              <TextValue className='text-secondary text-base'>
                {Duration.fromMillis(summaryActionTime).toFormat(
                  "hh':' mm'hr.'"
                )}
              </TextValue>
            </CardValue>
          </div>
          <div className='flex items-center  justify-center px-[30px]'>
            <CardValue>
              <TextLabel>สมัครสมาชิกเมื่อ</TextLabel>
              <TextValue className='text-secondary text-base'>
                {DateTime.fromISO(createdAt).toFormat('dd LLL yyyy', {
                  locale: 'th-Th',
                })}
              </TextValue>
            </CardValue>
          </div>
          <div className='flex items-center justify-center px-[30px]'>
            <CardValue>
              <TextLabel>เขียนเรื่องแรกเมื่อ</TextLabel>
              <TextValue className='text-secondary text-base'>
                {firstBookCreatedDate
                  ? DateTime.fromISO(firstBookCreatedDate).toFormat(
                      'dd LLL yyyy',
                      {
                        locale: 'th-Th',
                      }
                    )
                  : '-'}
              </TextValue>
            </CardValue>
          </div>
        </div>
      </section>

      <hr className='my-[30px]' />

      <section className='grid grid-cols-3 divide-x'>
        <div className='flex justify-center gap-x-[30px]'>
          <CardValue>
            <TextLabel>จำนวนโหลด</TextLabel>
            <TextValue className='text-secondary text-2xl'>
              {summarySellCount.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>ยอดการอ่านทั้งหมด</TextLabel>
            <TextValue className='text-secondary text-2xl'>
              {totalRead.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>
        <div className='flex justify-center gap-x-[30px]'>
          <CardValue>
            <TextLabel>ยอดสนับสนุน(เหรียญ)</TextLabel>
            <TextValue className='text-secondary text-2xl'>
              {summaryDonate.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>ยอดขายทั้งหมด(เหรียญ)</TextLabel>
            <TextValue className='text-secondary text-2xl'>
              {summaryBookPurchase.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>
        <div className='flex justify-center gap-x-[30px]'>
          <CardValue>
            <TextLabel>ยอดขายที่เบิกแล้ว(บาท)</TextLabel>
            <TextValue className='text-primary text-2xl'>
              {totalPaidWriterIncome.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>
              รายได้ค้างในระบบ(บาท)&nbsp;
              <Tooltip
                className='bg-primary-1 border border-blue rounded-[8px] p-[10px]'
                label={
                  <div>
                    รายได้ค้างในระบบ(บาท) =
                    ยอดรวมปัจจุบัน(ยอดรวม)-ยอดจ่ายเงินรวม-ยอดรวมปัจจุบัน(ปัจจุบัน)
                  </div>
                }
                style={{ zIndex: 100 }}
              >
                <button type='button'>
                  <Information2Icon
                    width='12'
                    height='12'
                    className='text-blue-50'
                  />
                </button>
              </Tooltip>
            </TextLabel>
            <TextValue className='text-secondary-100 text-2xl'>
              {totalPayableWriterIncome.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>
      </section>

      <hr className='my-[30px]' />

      <section className='flex justify-start gap-x-[100px] ml-[50px]'>
        <CardValue>
          <TextLabel>เพิ่มเข้าชั้นทั้งหมด</TextLabel>
          <TextValue className='text-secondary text-2xl'>
            <AddIcon className='text-primary' width='24' height='24' />
            {totalBookshelf.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel>การกดหัวใจทั้งหมด</TextLabel>
          <TextValue className='text-secondary text-2xl'>
            <HeartIcon className='text-primary' width='24' height='24' />
            {summaryBookLike.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel>คอมเม้นต์ทั้งหมด</TextLabel>
          <TextValue className='text-secondary text-2xl'>
            <ChatIcon className='text-primary' width='24' height='24' />{' '}
            {summaryBookComment.toLocaleString()}
          </TextValue>
        </CardValue>
      </section>

      <hr className='my-[30px]' />
    </>
  )
}
