import { useRef, useState, useEffect, useMemo } from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { ExportJobResponse } from '@models/file/ExportJobResponse'
import { ExportJobStatusEnum } from '@interfaces/exportFile/ExportJobStatusEnum'
import { ExportListProps } from './interface'

const PER_PAGE = 10

export function withExportList(Component: React.FC<ExportListProps>) {
  function WithExportList() {
    const client = useClient()
    const queryClient = useQueryClient()
    const alert = useAlert()
    const [isOpen, setOpen] = useState(false)
    const intervalRef = useRef<any>(null)

    const { mutateAsync } = useMutation((id: number) =>
      client!.fileClient.cancelExportJob(id)
    )

    const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
      'export-jobs',
      async ({ pageParam = 1 }) => {
        const res = await client!.fileClient.exportJobs({
          page: pageParam,
          limitPerPage: PER_PAGE,
        })

        return res
      },
      {
        getNextPageParam: (lastPage: ExportJobResponse) => {
          if (lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    const exportJobs = useMemo(() => {
      return data?.pages.flatMap(row => row.data) || []
    }, [data])

    async function handleFetchNextPage() {
      if (!isFetchingNextPage) await fetchNextPage()
    }

    function onToggle() {
      setOpen(prev => !prev)
    }

    async function onCancelExportJob(id: number) {
      const res = await mutateAsync(id)
      if (res) queryClient.refetchQueries('export-jobs')
      else alert.error('เกิดข้อผิดพลาดไม่สามารถยกเลิกได้')
    }

    useEffect(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }

      if (
        exportJobs.some(row => row.status === ExportJobStatusEnum.PROCESSING)
      ) {
        intervalRef.current = setInterval(() => {
          queryClient.refetchQueries('export-jobs')
        }, 5000)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportJobs])

    const componentProps = {
      exportJobs,
      isOpen,
      handleFetchNextPage,
      onCancelExportJob,
      onToggle,
    }

    return <Component {...componentProps} />
  }

  return WithExportList
}
