import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Button } from '@components/Button'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { ExportButton } from '@components/ExportButton'
import { SearchWriterField } from '@components/forms/SearchWriterField'
import { SearchActionEnum } from '@components/forms/SearchWriterField/interface'
import { ByUserFormProps } from './interface'

const validationSchema = Yup.object()
  .shape({
    startDate: Yup.string().when('timeRange', {
      is: (value: TimeRangeEnum) => value === undefined,
      then: Yup.string().required(),
    }),
    endDate: Yup.string().when('timeRange', {
      is: (value: TimeRangeEnum) => value === undefined,
      then: Yup.string().required(),
    }),
  })
  .test((value, context) => {
    if (!value.timeRange && (!value.startDate || !value.endDate)) {
      return context.createError({
        path: 'timeRange',
        message: 'required',
      })
    }

    return true
  })

export function ByUserForm({
  initialValues,
  disableExport,
  handleSubmit,
  onResetForm,
  exportFile,
}: ByUserFormProps) {
  const timeRangeOption = [
    { value: TimeRangeEnum.DAY, text: 'เมื่อวาน' },
    { value: TimeRangeEnum.MONTH, text: '1 เดือน' },
    { value: TimeRangeEnum.YEAR, text: '1 ปี' },
  ]
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, isValid, setFieldValue, resetForm }) => (
          <Form>
            <div className='flex flex-col gap-y-[30px] mb-[30px]'>
              <div className='grid grid-cols-1'>
                <SearchWriterField
                  name='userId'
                  placeholder='ค้นหาด้วยชื่อนักเขียน, สำนักพิมพ์ หรือ ID'
                  inputClassName='font-[300] bg-blue-2 rounded-[20px] placeholder:text-secondary-100'
                  type={SearchActionEnum.USER}
                  resetHandler={() => {
                    resetForm({
                      values: {
                        userId: undefined,
                        timeRange: TimeRangeEnum.MONTH,
                        startDate: undefined,
                        endDate: undefined,
                      },
                    })
                    onResetForm()
                  }}
                />
              </div>
              <div className='flex justify-between items-end '>
                <div className='flex items-end gap-x-[10px]'>
                  <SelectButtonField
                    name='timeRange'
                    options={timeRangeOption}
                    label='เลือกช่วงเวลา'
                    mode='line'
                    className='w-fit overflow-auto'
                    onChange={() => {
                      if (values.startDate) {
                        setFieldValue('startDate', undefined)
                      }
                      if (values.endDate) {
                        setFieldValue('endDate', undefined)
                      }
                    }}
                  />
                  <DateTimeInputField
                    className='w-[180px]'
                    name='startDate'
                    placeholder='ตั้งแต่วันที่'
                    onChange={value => {
                      setFieldValue('startDate', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    maxDate={values.endDate || new Date()}
                  />
                  <DateTimeInputField
                    className='w-[180px] '
                    name='endDate'
                    placeholder='ถึงวันที่'
                    onChange={value => {
                      setFieldValue('endDate', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    minDate={values.startDate}
                    maxDate={new Date()}
                  />
                </div>
                <div className='flex font-mitr justify-end gap-x-[20px]'>
                  <Button
                    type='reset'
                    className='text-secondary underline whitespace-pre'
                    onClick={() => {
                      resetForm({
                        values: {
                          userId: undefined,
                          timeRange: TimeRangeEnum.MONTH,
                          startDate: undefined,
                          endDate: undefined,
                        },
                      })
                      onResetForm()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white'
                    disabled={isSubmitting || !isValid}
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                  <ExportButton
                    handleClick={exportFile}
                    disabled={disableExport}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
