import { Expose, Type } from 'class-transformer'

import { EbookSalesGraphTypeEnum } from '@interfaces/EbookSalesGraphTypeEnum'
import { AnalyticsEbookDetailGraphDataType } from './AnalyticsEbookDetailGraphDataType'
import { AnalyticsEbookDetailTableType } from './AnalyticsEbookDetailTableType'

export class AnalyticsEbookDetailType {
  @Expose()
  @Type(() => AnalyticsEbookDetailTableType)
  tableData: AnalyticsEbookDetailTableType[]

  @Expose()
  @Type(() => AnalyticsEbookDetailGraphDataType)
  graphData: AnalyticsEbookDetailGraphDataType[]

  @Expose()
  graphType: EbookSalesGraphTypeEnum
}
