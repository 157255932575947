import { withPermissionGuard } from '@libs/permission'
import { WriterSubmitPage } from './WriterSubmitPage'
import { withWriterSubmitPage } from './withWriterSubmitPage'

const ConnectedWriterSubmitPage = withPermissionGuard(
  withWriterSubmitPage(WriterSubmitPage),
  'user',
  'view'
)
export { ConnectedWriterSubmitPage as WriterSubmitPage }
