import { Container } from '@components/Container'
import { StickerManagementForm } from '@features/chapterBook/components/StickerManagementForm'
import { StickerManagementAddPageProps } from './interface'

export function StickerManagementAddPage({
  initialValues,
  onSubmit,
}: StickerManagementAddPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>เพิ่มสติ๊กเกอร์</Container.Title>

      <StickerManagementForm
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
