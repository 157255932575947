import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { PopupBannerFormType } from '@models/popupBanner/PopupBannerFormType'
import { CreatePopupBannerPageProps } from './interface'

export function withCreatePopupBannerPage(
  Component: React.FC<CreatePopupBannerPageProps>
) {
  function WithCreatePopupBannerPage() {
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()

    function goBackToPupUpBannerPage() {
      navigate('/home-page/pop-up/pop-up-management')
    }

    async function onSubmit(value: PopupBannerFormType) {
      try {
        await client?.popupBannerClient.createPopupBanner(value)
        alert.success('เพิ่ม pop up สำเร็จ')
        goBackToPupUpBannerPage()
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    const componentProps = {
      goBackToPupUpBannerPage,
      onSubmit,
    }

    return <Component {...componentProps} />
  }

  return WithCreatePopupBannerPage
}
