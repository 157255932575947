import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { ActiveUserHistoryDataType } from '@models/dashboard/ActiveUserHistoryDataType'
import { timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import {
  PerformanceArgumentType,
  PerformanceTemplateAcceptProps,
  PerformanceTemplateProps,
} from './interface'

const initialData = {
  totalUser: 0,
  totalPageView: 0,
  avgSessionDuration: 0,
  bounceRate: 0,
  storageSize: 0,
  sessionHistory: {
    keyType: KeyTypeEnum.MONTH,
    data: [
      {
        value: 0,
        totalSession: 0,
      },
    ],
  },
  trafficCategory: [
    {
      name: '',
      value: 0,
    },
  ],
  deviceCategory: [
    {
      name: '',
      value: 0,
    },
  ],
  topPageView: [
    {
      name: '',
      value: 0,
    },
  ],
  storageSizeHistory: {
    keyType: KeyTypeEnum.MONTH,
    data: [
      {
        value: 0,
        size: 0,
      },
    ],
  },
  activeUserHistory: {
    keyType: KeyTypeEnum.MONTH,
    data: [
      {
        value: 0,

        active1DayUsers: 0,

        active7DayUsers: 0,

        active28DayUsers: 0,
      },
    ],
  },
}

export function withPerformanceTemplate(
  Component: React.FC<PerformanceTemplateProps>
) {
  function WithPerformanceTemplate({ tabId }: PerformanceTemplateAcceptProps) {
    const queryClient = useQueryClient()
    const client = useClient()
    const alert = useAlert()
    const [searchParams, setSearchParams] = useState<PerformanceArgumentType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[KeyTypeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      dashboardType: tabId,
      keyType: KeyTypeEnum.MONTH,
    })
    const queryKey = ['performance', searchParams]

    const { data = initialData } = useQuery(
      queryKey,
      () => client!.dashboardClient.getPerformance(searchParams!),
      {
        enabled: !!searchParams,
      }
    )

    let storageSize
    if (data.storageSize < 1000) {
      storageSize = `${data.storageSize.toFixed(2)}GB`
    } else {
      const result = data.storageSize / 1000
      storageSize = `${result.toFixed(2)}TB`
    }

    const sumActive1DayUsers = data.activeUserHistory.data
      .filter(
        (cur: ActiveUserHistoryDataType) => cur.active1DayUsers !== undefined
      )
      .reduce(
        (prev: number, cur: ActiveUserHistoryDataType) =>
          prev + cur.active1DayUsers,
        0
      )
    const sumActive7DayUsers = data.activeUserHistory.data
      .filter(
        (cur: ActiveUserHistoryDataType) => cur.active7DayUsers !== undefined
      )
      .reduce(
        (prev: number, cur: ActiveUserHistoryDataType) =>
          prev + cur.active7DayUsers,
        0
      )
    const sumActive28DayUsers = data.activeUserHistory.data
      .filter(
        (cur: ActiveUserHistoryDataType) => cur.active28DayUsers !== undefined
      )
      .reduce(
        (prev: number, cur: ActiveUserHistoryDataType) =>
          prev + cur.active28DayUsers,
        0
      )

    function handleSearch(
      startDate: DateTime,
      endDate: DateTime,
      keyType: KeyTypeEnum
    ) {
      setSearchParams({
        startDate,
        endDate,
        dashboardType: tabId,
        keyType,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[KeyTypeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        dashboardType: tabId,
        keyType: KeyTypeEnum.MONTH,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportAppPerformanceReport({
          dashboardType: tabId,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          keyType: searchParams.keyType,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const componentProps = {
      tabId,
      totalUser: data.totalUser,
      totalPageView: data.totalPageView,
      avgSessionDuration: data.avgSessionDuration,
      bounceRate: data.bounceRate,
      storageSize,
      sessionHistory: data.sessionHistory,
      trafficCategory: data.trafficCategory,
      deviceCategory: data.deviceCategory,
      topPageView: data.topPageView,
      storageSizeHistory: data.storageSizeHistory,
      Active1DayUsers: sumActive1DayUsers,
      Active7DayUsers: sumActive7DayUsers,
      Active28DayUsers: sumActive28DayUsers,
      searchParams,
      handleSearch,
      handleReset,
      handleExport,
    }

    return <Component {...componentProps} />
  }

  return WithPerformanceTemplate
}
