import { withPermissionGuard } from '@libs/permission'
import { HighlightBannerPosterPage } from './HighlightBannerPosterPage'
import { withHighlightBannerPosterPage } from './withHighlightBannerPosterPage'

const Connected = withPermissionGuard(
  withHighlightBannerPosterPage(HighlightBannerPosterPage),
  'homePageManagement',
  'view'
)

export { Connected as HighlightBannerPosterPage }
