import { useMutation } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { ForgotPasswordPageProps } from './interface'

export function withForgotPasswordPage(
  Component: React.FC<ForgotPasswordPageProps>
) {
  function WithForgotPasswordPage() {
    const client = useClient()
    const alert = useAlert()

    const { mutate: forgotPassword, isLoading } = useMutation(
      (email: string) => client!.userClient.forgotAdminPassword(email),
      {
        onSuccess: () => {
          alert.success(`โปรดตรวจสอบอีเมลเพื่อรีเซ็ตรหัสผ่าน`)
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
        },
      }
    )

    function submitForgotPassword(form: { email: string }) {
      forgotPassword(form.email)
    }

    const newProps = {
      submitForgotPassword,
      isLoading,
    }
    return <Component {...newProps} />
  }

  return WithForgotPasswordPage
}
