import { withPermissionGuard } from '@libs/permission'
import { MainBannerPage } from './MainBannerPage'
import { withMainBannerPage } from './withMainBannerPage'

const ConnectedMainBannerPage = withPermissionGuard(
  withMainBannerPage(MainBannerPage),
  'homePageManagement',
  'view'
)

export { ConnectedMainBannerPage as MainBannerPage }
