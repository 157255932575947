import { Expose } from 'class-transformer'

export class BookStorySettingFormType {
  @Expose()
  id?: number

  @Expose()
  name: string

  @Expose()
  maxCharacter: number

  isDeleted?: boolean
}
