import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { Expose, Type } from 'class-transformer'

import { PromotionSubCorrectionDetail } from './PromotionSubCorrectionDetail'

class PublishPeriodCorrectionDetail {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  endPublishedAt?: PromotionSubCorrectionDetail<string>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  startPublishedAt?: PromotionSubCorrectionDetail<string>
}

class SaleActionType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  actionValue?: PromotionSubCorrectionDetail<number>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  coinsCondition?: PromotionSubCorrectionDetail<
    {
      id: number
      name: string
    }[]
  >

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  useCoinConditionType?: PromotionSubCorrectionDetail<PSUseCoinConditionTypeEnum>
}

class SaleBenefitType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitCoin?: PromotionSubCorrectionDetail<string>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitValue?: PromotionSubCorrectionDetail<number>
}

class SaleBenefitLimitType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitLimitType?: PromotionSubCorrectionDetail<PromotionChallengeLimitTypeEnum>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitLimitValue?: PromotionSubCorrectionDetail<number>
}

class SaleBudgetType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  budget?: PromotionSubCorrectionDetail<number>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  budgetPerDay?: PromotionSubCorrectionDetail<number>
}

export class PromotionSaleCorrectionDetailType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  name?: PromotionSubCorrectionDetail<string>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  description?: PromotionSubCorrectionDetail<string>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  userTerm?: PromotionSubCorrectionDetail<string>

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  usageDay?: PromotionSubCorrectionDetail<DaySelectEnum[]>

  @Expose()
  @Type(() => PublishPeriodCorrectionDetail)
  publishPeriod?: PublishPeriodCorrectionDetail

  @Expose()
  @Type(() => SaleActionType)
  saleAction?: SaleActionType

  @Expose()
  @Type(() => SaleBenefitType)
  saleBenefit?: SaleBenefitType

  @Expose()
  @Type(() => SaleBenefitLimitType)
  saleBenefitLimit?: SaleBenefitLimitType

  @Expose()
  @Type(() => SaleBudgetType)
  saleBudget?: SaleBudgetType
}
