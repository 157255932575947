import { addComma } from '@libs/utils'
import {
  ChallengeUnit,
  ChallengeUnitValue,
} from '@interfaces/user-mission/ChallengeEnum'

interface InformationLevelProps {
  id: number
  level: number
  levelName: string
  criteria: string
  target: number
  toTarget: number
  challengeUnit: ChallengeUnit
}

export function InformationLevel({
  id,
  level,
  levelName,
  criteria,
  target,
  toTarget,
  challengeUnit,
}: InformationLevelProps) {
  return (
    <tr>
      <td className='py-[10px] text-secondary'>
        <div>
          <span className='text-[12px] text-secondary-100 mr-[10px]'>
            Level {level} :
          </span>
          {levelName}
        </div>
      </td>
      <td>
        <div className='text-secondary'>
          <span className='text-[12px] text-secondary-100 mr-[10px] ml-[10px]'>
            {criteria} :
          </span>
          {addComma(target, 0)}
          {toTarget ? <span> - {addComma(toTarget - 1, 0)}</span> : ''}
        </div>
      </td>
      <td>
        <span className='text-[12px] text-secondary-100 ml-[10px]'>
          {ChallengeUnitValue[challengeUnit]}
          {!toTarget && 'ขึ้นไป'}
        </span>
      </td>
    </tr>
  )
}
