import { Exclude, Expose, Transform } from 'class-transformer'

import { PopupBannerPublishedTypeEnum } from '@interfaces/PopupBannerPublishedTypeEnum'
import { PopupBannerDisplayEnum } from '@interfaces/PopupBannerDisplayEnum'
import { ImageType } from '@models/ImageType'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { DateTime } from 'luxon'
import { UserPlatformTypeEnum } from '@interfaces/UserPlatformTypeEnum'

@Exclude()
export class PopupBannerFormType {
  @Expose() id: number

  @Expose() title: string

  @Expose() link: string

  @Expose({ name: 'imgPath' })
  @Transform(({ value }) => ({
    url: value || '',
  }))
  coverImage: ImageType

  @Expose({ name: 'screen' }) displayAt: PopupBannerDisplayEnum

  @Expose({ name: 'platforms' }) display: PlatformEnum[]

  @Expose() publishedType: PopupBannerPublishedTypeEnum

  @Expose({ name: 'startPublishedAt' })
  @Transform(({ value }) =>
    value ? DateTime.fromISO(value).toJSDate() : value
  )
  startDate: Date

  @Expose({ name: 'endPublishedAt' })
  @Transform(({ value }) =>
    value ? DateTime.fromISO(value).toJSDate() : value
  )
  endDate: Date

  @Expose()
  userTypes: UserPlatformTypeEnum[]
}
