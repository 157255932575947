import { gql } from 'graphql-request'

export const EXPORT_PAYMENT_REPORTS = gql`
  query ExportPaymentReports(
    $paymentCycleMonth: Int
    $paymentCycleYear: Int
    $writerId: Int
  ) {
    exportPaymentReports(
      paymentCycleMonth: $paymentCycleMonth
      paymentCycleYear: $paymentCycleYear
      writerId: $writerId
    )
  }
`
