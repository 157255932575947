import { gql } from 'graphql-request'

export const UPDATE_PROMOTION_CHALLENGE = gql`
  mutation UpdatePromotionChallenge(
    $updatePromotionChallengeInput: UpdatePromotionChallengeInput!
  ) {
    updatePromotionChallenge(
      updatePromotionChallengeInput: $updatePromotionChallengeInput
    ) {
      message
    }
  }
`
