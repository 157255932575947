import React from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { HighlightBannerFormType } from '@models/highlightBanner/HighlightBannerFormType'
import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'
import { BannerEnum } from '@interfaces/BannerEnum'
import {
  EditHighlightBannerPageProps,
  WithEditHighlightBannerPageProps,
} from './interface'

export function withEditHighlightBannerPage(
  Component: React.FC<EditHighlightBannerPageProps>
) {
  function WithEditHighlightBannerPage({
    type,
  }: WithEditHighlightBannerPageProps) {
    const { id, position } = useParams()
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()

    const {
      data: initialValues,
      isLoading,
      refetch,
    } = useQuery(
      ['highlight-banner', id],
      () => client?.highlightBannerClient.getHighlightBanner(Number(id), type),
      { enabled: !!id }
    )

    const { mutate } = useMutation(
      (form: HighlightBannerFormType) =>
        client!.highlightBannerClient.updateHighlightGroupBanner(form),
      {
        onSuccess: (response: HighlightBannerType) => {
          if (type === BannerEnum.POSTER) {
            navigate(
              `/home-page/highlight-banner/${position}/poster/${response?.id}`
            )
          }
          if (type === BannerEnum.BOOK) {
            navigate(
              `/home-page/highlight-banner/${position}/book/${response?.id}`
            )
          }
          refetch()
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    async function onSubmit(form: HighlightBannerFormType) {
      mutate({
        bannerName: form.bannerName,
        id: Number(id),
      })
    }

    if (isLoading) return <></>

    const componentProps = {
      type,
      initialValues: plainToInstance(HighlightBannerFormType, initialValues),
      onSubmit,
    }
    return <Component {...componentProps} />
  }

  return WithEditHighlightBannerPage
}
