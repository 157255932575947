import React, { useState } from 'react'
import { useField } from 'formik'

import { WithPasswordFieldProps, PasswordFieldProps } from './interface'

const withPasswordField = (Component: React.FC<PasswordFieldProps>) => {
  function WithPasswordField({
    name,
    onChange,
    ...props
  }: WithPasswordFieldProps) {
    const [showPassword, setShowPassword] = useState(false)
    const [field, meta] = useField(name)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }

    function handleChangeTypePassword() {
      setShowPassword(!showPassword)
    }

    const componentsProps = {
      ...props,
      ...field,
      value: field.value || '',
      onChange: handleChange,
      error: meta.error,
      touched: meta.touched,
      showPassword,
      handleChangeTypePassword,
    }

    return <Component {...componentsProps} />
  }

  WithPasswordField.defaultProps = {
    type: 'password',
    showErrorMsg: true,
  }

  return WithPasswordField
}

export { withPasswordField }
