import { TableDetailAcceptProps, TableDetailProps } from './interface'

export function withTableDetail(Component: React.FC<TableDetailProps>) {
  function WithTableDetail({
    data,
    page,
    perpage,
    total,
    pageChange,
    handleSort,
    ...props
  }: TableDetailAcceptProps) {
    const componentProps = {
      ...props,
      data,
      page,
      perpage,
      total,
      pageChange,
      handleSort,
    }
    return <Component {...componentProps} />
  }
  return WithTableDetail
}
