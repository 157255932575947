import React from 'react'
import {
  PromotionChallengeSearchFormAcceptProps,
  PromotionChallengeSearchFormProps,
} from './interface'

export function withPromotionChallengeSearchForm(
  Component: React.FC<PromotionChallengeSearchFormProps>
) {
  function Hoc({
    initialValues,
    handleSubmit,
    handleExport,
  }: PromotionChallengeSearchFormAcceptProps) {
    const newProps = {
      initialValues,
      handleSubmit,
      handleExport,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
