import { ColumnType } from '@components/Table/interface'
import { abbreviateNumber } from '@libs/utils'
import { CharacterDonationByWriter } from '@models/character/CharacterDonationByWriter'
import { CharacterDonationByWriterSortEnum } from '@interfaces/character/CharacterDonationByWriterSortEnum'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<CharacterDonationByWriter>[] = [
  {
    column: 'User ID',
    accessor: 'userId',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationByWriterSortEnum.USER_ID,
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>@{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationByWriterSortEnum.USERNAME,
  },
  {
    column: 'งานเขียนทั้งหมด',
    accessor: 'totalBook',
    cell: ({ value }) => <p>{(value as number).toLocaleString()}</p>,
    isSortable: true,
    sortKey: CharacterDonationByWriterSortEnum.TOTAL_WRITING,
  },
  {
    column: 'Donation',
    accessor: 'totalDonation',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationByWriterSortEnum.DONATION,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => (
      <ActionCell userId={obj.userId} writerId={obj.writerId} />
    ),
    cellClassName: 'flex justify-end py-[10px]',
    isAction: true,
  },
]
