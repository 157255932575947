import { Expose, Transform } from 'class-transformer'

import { ImageType } from '@interfaces/ImageType'

export class HighlightPosterBannerFormType {
  @Expose() id?: number

  @Expose() highlightGroupId?: number

  @Expose({ name: 'imgPath' })
  @Transform(({ obj }) => ({ url: obj?.imgPath }))
  imgPath?: ImageType

  @Expose() description?: string

  @Expose() isOnWeb?: boolean

  @Expose() isOnIos?: boolean

  @Expose() isOnAndroid?: boolean

  @Expose() rawLink?: string

  @Expose() linkWeb?: string

  @Expose() linkIos?: string

  @Expose() linkAndroid?: string

  @Expose() isActive?: boolean

  @Expose() runningNo?: number

  @Expose() publishedTo?: string

  @Expose() publishedFrom?: string
}
