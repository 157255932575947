import { Expose, Type } from 'class-transformer'

import { BookType } from './BookType'

export class AdminSearchBookResponse {
  @Expose()
  @Type(() => BookType)
  data: BookType[]

  @Expose() total: number
}
