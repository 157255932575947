import { StoryIncomeDataType } from '@models/dashboard/StoryIncomeDataType'
import { ColumnType } from '@components/Table/interface'
import { abbreviateNumber, addComma } from '@libs/utils'
import { BookEnum } from '@interfaces/BookEnum'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<StoryIncomeDataType>[] = [
  {
    column: 'อันดับ',
    accessor: 'rank',
    cell: ({ value }) => <p>{addComma(value, 0)}</p>,
    isSortable: true,
  },
  {
    column: 'ปก',
    accessor: 'bookCoverImgPath',
    cell: ({ value }) =>
      value ? (
        <img
          className='min-w-[29px] max-w-[29px] min-h-[41px] max-h-[41px] object-cover'
          alt='cover'
          src={value}
        />
      ) : (
        <div className='min-w-[29px] max-w-[29px] min-h-[41px] max-h-[41px] object-cover bg-gray' />
      ),
    isSortable: false,
  },
  {
    column: 'เรื่อง',
    accessor: 'bookTitle',
    cell: ({ value }) => <p className='w-[115px] line-clamp-1'>{value}</p>,
    isSortable: true,
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => (
      <p>{value === BookEnum.NOVEL ? 'นิยายรายตอน' : 'การ์ตูน'}</p>
    ),
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    cell: ({ value }) => (
      <p className='max-w-[120px] line-clamp-1 brack-all'>{value}</p>
    ),
    isSortable: true,
  },
  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
  },
  {
    column: 'View',
    accessor: 'countView',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
  },
  {
    column: 'Love',
    accessor: 'countLike',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
  },
  {
    column: 'ยอดขาย',
    accessor: 'countIncome',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
  },
  {
    column: 'Action',
    accessor: 'bookId',
    cell: ({ value }) => <ActionCell id={value} />,
    cellClassName: 'flex justify-end',
    isAction: true,
  },
]
