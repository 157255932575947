import { gql } from 'graphql-request'

export const GET_SALE_REPORT = gql`
  query GetSalesReportsAdminDashboard(
    $writerId: Int
    $bookTypes: [BookType!]
    $categoryIds: [Int!]
    $startDate: DateTime
    $endDate: DateTime
    $roleId: Int
  ) {
    getSalesReportsAdminDashboard(
      writerId: $writerId
      bookTypes: $bookTypes
      categoryIds: $categoryIds
      startDate: $startDate
      endDate: $endDate
      roleId: $roleId
    ) {
      totalBooksSold
      totalBookChaptersSold
      totalUsersReading
      totalSpend
      totalProfit
      totalBookAndBookChapterSales
      totalDonation
      totalOverAllSales
      salesTarget
      totalUsersDownload
    }
  }
`
