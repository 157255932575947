import React, { useState } from 'react'
import { ByTitleFormAcceptProps, ByTitleFormProps } from './interface'

export function withByTitleForm(Component: React.FC<ByTitleFormProps>) {
  function WithByTitleForm({ onResetForm, ...props }: ByTitleFormAcceptProps) {
    const [text, setText] = useState('')
    function resetHandler() {
      setText('')
      onResetForm()
    }
    const newProps = {
      text,
      setText,
      resetHandler,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithByTitleForm
}
