import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import cn from 'classnames'

import { CalendarIcon } from '@components/Icons'
import { DateTimeInputProps } from './interface'

export function DateTimeInput({
  className,
  placeholder,
  inputClassName,
  showTimeSelect,
  ...props
}: DateTimeInputProps) {
  return (
    <label className={cn('w-full flex items-center', className)}>
      <DatePicker
        dateFormat={showTimeSelect ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy'}
        placeholderText={placeholder}
        showTimeSelect={showTimeSelect}
        className={cn(
          'font-light text-[14px] min-h-[40px] placeholder:text-secondary-100 focus:outline-none  items-center px-[10px] border rounded-[8px] w-full text-secondary',
          inputClassName
        )}
        autoComplete='off'
        {...props}
      />
      <CalendarIcon
        width='16'
        height='16'
        className='text-primary ml-[-22px] z-[1]'
      />
    </label>
  )
}
