import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { StoryContextType } from './interface'

interface StoryProviderType {
  children: React.ReactNode
  bookId: number
}

export const StoryContext = createContext<StoryContextType>({
  data: undefined,
  charactersData: [],
  isLoading: false,
  bookId: 0,
})

export function StoryProvider({ children, bookId }: StoryProviderType) {
  const client = useClient()
  const { isAuthenticated } = useAuthentication()
  const { data, isLoading } = useQuery(
    ['story-detail', bookId],
    () => client?.storyClient.getStoryDetail(bookId),
    {
      enabled: !!bookId && isAuthenticated,
    }
  )

  const { data: charactersData = [], isLoading: isCharactersLoading } =
    useQuery(
      ['characters', bookId],
      () => client?.storyClient.getCharacters(bookId),
      {
        enabled: !!bookId && isAuthenticated,
      }
    )

  const value = {
    data,
    charactersData,
    isLoading: isLoading || isCharactersLoading,
    bookId,
  }

  return <StoryContext.Provider value={value}>{children}</StoryContext.Provider>
}

export function useStory() {
  return useContext(StoryContext)
}
