import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { UserType } from '@models/profile/UserType'
import { UserWriterResponse } from '@models/user/UserWriterResponse'
import { WritingSearchType } from '@interfaces/user/WritingSearchType'
import { AcceptAdminFormType } from '@models/authentication/AcceptAdminFormType'
import { UserManagementDetailResponse } from '@models/user/UserManagementDetailResponse'
import { SearchParamType } from '@features/user/pages/UserManagementDetailPage/interface'
import { UserPenNameListResponse } from '@models/user/UserPenNameListResponse'
import { UserPenNameResponse } from '@models/user/UserPenNameResponse'
import {
  WriterOptionResponse,
  WriterOptionType,
} from '@models/user/WriterOptionType'
import { UserOptionResponse } from '@models/user/UserOptionResponse'
import { GET_PROFILE } from './schemas/getProfile'
import { GET_PEN_NAME_LIST } from './schemas/getPenNameList'
import { GET_USER_MANAGEMENT } from './schemas/getUserManagement'
import { ACCEPT_ADMIN } from './schemas/acceptAdmin'
import { GET_WRITER_LIST } from './schemas/getWriterList'
import { GET_USER_PEN_NAME } from './schemas/getUserPenNames'
import { FORGOT_ADMIN_PASSWORD } from './schemas/forgotAdminPassword'
import { GET_WRITER_OPTIONS } from './schemas/getWriterOptions'
import { UPDATE_LAST_LOGIN } from './schemas/updateLastLogin'
import { SEARCH_USER } from './schemas/searchUser'
import { GET_WRITER_OPTION } from './schemas/getWriterOption'

export class UserClient {
  constructor(private client: GraphQLClient) {}

  async getProfile(): Promise<UserType> {
    const { getProfile } = await this.client.request(GET_PROFILE)
    return plainToInstance(UserType, getProfile)
  }

  async acceptAdmin(form: AcceptAdminFormType, token: string) {
    await this.client.request(ACCEPT_ADMIN, {
      acceptAdminInput: {
        token,
        displayName: form.displayName,
        password: form.password,
      },
    })
  }

  async forgotAdminPassword(email: string) {
    await this.client.request(FORGOT_ADMIN_PASSWORD, {
      forgotPasswordAdminInput: {
        email,
      },
    })
  }

  async updateLastLogin() {
    await this.client.request(UPDATE_LAST_LOGIN)
  }

  async getUserManagement(
    userId: number
  ): Promise<UserManagementDetailResponse> {
    const { getUserManagement } = await this.client.request(
      GET_USER_MANAGEMENT,
      {
        userId,
      }
    )

    return plainToInstance(UserManagementDetailResponse, getUserManagement)
  }

  async getWriterList(
    userId: number,
    limitPerPage: number,
    page: number,
    searchParam: WritingSearchType,
    sortTable: {
      sortKey: string
      sortOrder: string
    }
  ): Promise<UserWriterResponse> {
    const { getUserManagement } = await this.client.request(GET_WRITER_LIST, {
      userId,
      writingListUserId: userId,
      limitPerPage,
      page,
      searchText: searchParam.text,
      categoryIds: searchParam.categories.length
        ? searchParam.categories.map(id => Number(id))
        : null,
      bookStatus: searchParam.bookStatus,
      bookType: searchParam.bookType.length ? searchParam.bookType : null,
      ...(sortTable.sortKey && {
        orderBy: {
          [sortTable.sortKey]: sortTable.sortOrder.toLocaleUpperCase(),
        },
      }),
    })

    return plainToInstance(
      UserWriterResponse,
      {
        ...getUserManagement.writingList,
        ...getUserManagement.writingCountList,
      } || {}
    )
  }

  async getPenNameList(
    userId: number,
    page: number,
    limitPerPage: number,
    searchParam: SearchParamType
  ): Promise<UserPenNameListResponse> {
    const { getUserManagement } = await this.client.request(GET_PEN_NAME_LIST, {
      userId,
      penNameListUserId: userId,
      page,
      limitPerPage,
      ...(searchParam.sortKey && {
        orderBy: {
          [searchParam.sortKey]: searchParam.sortOrder.toLocaleUpperCase(),
        },
      }),
    })

    return plainToInstance(
      UserPenNameListResponse,
      getUserManagement.penNameList || {}
    )
  }

  async getUserPenNames(id: number): Promise<UserPenNameResponse[]> {
    const { getUserPenNames } = await this.client.request(GET_USER_PEN_NAME, {
      userId: id,
    })

    return plainToInstance(UserPenNameResponse, getUserPenNames as [])
  }

  async getWriterOptions(searchText: string): Promise<WriterOptionType[]> {
    const { searchWriter } = await this.client.request(GET_WRITER_OPTIONS, {
      searchText,
    })

    return plainToInstance(WriterOptionType, searchWriter as [])
  }

  async getWriterOption(
    searchText: string,
    page: number
  ): Promise<WriterOptionResponse> {
    const { searchWriterPaginate } = await this.client.request(
      GET_WRITER_OPTION,
      {
        searchText,
        page,
        limitPerPage: 20,
      }
    )

    return plainToInstance(WriterOptionResponse, searchWriterPaginate)
  }

  async searchUser(
    searchText: string,
    page: number
  ): Promise<UserOptionResponse> {
    const { searchUser } = await this.client.request(SEARCH_USER, {
      searchText,
      page,
      limitPerPage: 20,
    })

    return plainToInstance(UserOptionResponse, searchUser)
  }
}
