import { PromotionChallengeSearchForm } from '../PromotionChallengeSearchForm'
import { PromotionChallengeTransactionTable } from '../PromotionChallengeTransactionTable'
import { PromotionChallengeTransactionListProps } from './interface'

function PromotionChallengeTransactionList({
  promotionChallengeId,
  searchParams,
  action,
  handleSubmit,
  handleExport,
  ...props
}: PromotionChallengeTransactionListProps) {
  return (
    <div className='w-full mt-[30px]'>
      <PromotionChallengeSearchForm
        initialValues={searchParams}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <PromotionChallengeTransactionTable
        searchParams={searchParams}
        promotionChallengeId={promotionChallengeId}
        action={action}
        {...props}
      />
    </div>
  )
}

export default PromotionChallengeTransactionList
