import { Expose, Type } from 'class-transformer'

import { UserRemainCoinType } from './UserRemainCoinType'

export class UserRemainCoinResponse {
  @Expose()
  @Type(() => UserRemainCoinType)
  data: UserRemainCoinType[]

  @Expose() total: number

  @Expose() page: number
}
