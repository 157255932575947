import { PurchaseProportion } from '@features/sales/pages/SalesReportPage/components/PurchaseProportion'
import { SalesReportSearchForm } from '@features/sales/pages/SalesReportPage/components/SalesReportSearchForm'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { CoinDetail } from './components/CoinDetail'
import { TopCoinSpender } from './components/TopCoinSpender'
import { TopMoneySpender } from './components/TopMoneySpender'
import { SummaryCoinPageProps } from './interface'

export function SummaryCoinPage({
  handleSubmit,
  handleExport,
  searchParam,
  purchaseProportion,
}: SummaryCoinPageProps) {
  return (
    <>
      <SalesReportSearchForm
        initialValues={{
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <CoinDetail searchParam={searchParam} />
      <PurchaseProportion data={purchaseProportion} />
      <TopMoneySpender searchParam={searchParam} />
      <TopCoinSpender searchParam={searchParam} />
    </>
  )
}
