import { ChartOptionType } from '@interfaces/ChartOptionType'
import { Expose, Transform } from 'class-transformer'

export class TotalUserGenderType {
  @Expose()
  @Transform(({ obj }) => {
    return [
      { value: obj?.femaleReader || 0, name: 'หญิง' },
      { value: obj?.maleReader || 0, name: 'ชาย' },
      { value: obj?.otherReader || 0, name: 'ไม่ระบุเพศ' },
    ]
  })
  readerGender: ChartOptionType[]
}
