import { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { usePagination } from '@hooks/usePagination'
import {
  ColumnType,
  SortByType,
  SortingType,
} from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { useClient } from '@hooks/useClient'
import { UserRemainCoinType } from '@models/coin/UserRemainCoinType'
import { CoinBalancePageProps } from './interface'

const PER_PAGE = 20

export function withCoinBalancePage(Component: React.FC<CoinBalancePageProps>) {
  function WithCoinBalancePage() {
    const client = useClient()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: PER_PAGE })
    const [searchParam, setSearchParam] = useState('')
    const [sort, setSort] = useState<SortByType>({
      key: 'userId',
      order: SortingType.ASC,
    })

    const { data: activeCoins } = useQuery('active-coins', () =>
      client?.coinClient.getActiveCoins()
    )

    const columns = useMemo<ColumnType<UserRemainCoinType>[]>(() => {
      const goldCoin = activeCoins?.find(row => row.isGoldCoin)
      const silverCoin = activeCoins?.find(row => row.isSilverCoin)
      const otherCoin = activeCoins?.find(row => row.isOtherCoin)

      const cols: ColumnType<UserRemainCoinType>[] = [
        {
          column: 'User ID',
          accessor: 'userId',
          cell: ({ value }) => <p>{value || '-'}</p>,
          isSortable: true,
          sortKey: 'userId',
          cellClassName: 'w-[80px] py-[7px]',
        },
        {
          column: 'Username',
          accessor: 'username',
          cell: ({ value }) => <p>@{value}</p>,
          isSortable: true,
          sortKey: 'username',
          cellClassName: 'py-[7px]',
        },
      ]

      if (goldCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{goldCoin.name}</div>,
          accessor: 'paidCoin',
          cell: ({ value }) => (
            <p className='font-bold text-gold text-right'>
              {value.toLocaleString()}
            </p>
          ),
          isSortable: true,
          sortKey: 'paidCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      if (silverCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{silverCoin.name}</div>,
          accessor: 'secondaryCoin',
          cell: ({ value }) => (
            <p className='font-bold text-secondary-100 text-right'>
              {value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          ),
          isSortable: true,
          sortKey: 'secondaryCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      if (otherCoin) {
        cols.push({
          column: <div className='ml-auto'>มูลค่าเหรียญ{otherCoin.name}</div>,
          accessor: 'activityCoin',
          cell: ({ value }) => (
            <p className='font-bold text-secondary text-right'>
              {value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          ),
          isSortable: true,
          sortKey: 'activityCoin',
          cellClassName: 'w-[200px] py-[7px]',
        })
      }

      cols.push({
        column: <div className='ml-auto'>Action</div>,
        accessor: 'userId',
        cell: ({ value }) => (
          <Link
            to={{
              pathname: `/user/user-management/${value}`,
              search: `?tab=coin`,
            }}
          >
            <button
              className='border border-gray bg-white h-[30px] px-[4px] rounded-[8px]'
              type='button'
            >
              <EyeIcon className='text-black-970' width='18' height='18' />
            </button>
          </Link>
        ),
        isSortable: false,
        cellClassName: 'w-[100px] py-[7px] text-right',
      })

      return cols
    }, [activeCoins])

    const { data: remainCoins, isLoading } = useQuery(
      ['remain-coins', searchParam, page, sort, activeCoins],
      () =>
        client!.coinClient
          .userRemainCoins({
            searchParam,
            perpage,
            page,
            sortValue: sort.order,
            sortKey: sort.key,
            paidCoinId: activeCoins!.find(row => row.isGoldCoin)!.id,
            secondaryCoinId: activeCoins!.find(row => row.isSilverCoin)!.id,
            activityCoinId: activeCoins!.find(row => row.isOtherCoin)?.id,
          })
          .then(res => {
            handleTotalChange(res.total)
            return res
          }),
      {
        enabled: !!activeCoins?.length,
      }
    )

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }

    function handleSubmitSearch(searchText: string) {
      setSearchParam(searchText)
      pageChange(1)
    }

    function handleReset() {
      setSearchParam('')
      pageChange(1)
    }

    const newProps = {
      sort,
      remainCoins,
      columns,
      isLoading,
      page,
      perpage,
      total,
      pageChange,
      handleSubmitSearch,
      handleReset,
      handleSort,
    }
    return <Component {...newProps} />
  }

  return WithCoinBalancePage
}
