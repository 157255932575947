import React, { useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { DropResult } from 'react-beautiful-dnd'
import { useQuery } from 'react-query'

import { MainMenuType } from '@models/mainMenu/mainMenuType'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { MenuEditType, MenuManagePageProps } from './interface'

function reorder(list: MainMenuType[], startIndex: number, endIndex: number) {
  const result = cloneDeep(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const withMenuManagePage = (Component: React.FC<MenuManagePageProps>) => {
  function WithMenuManagePage() {
    const { isAuthenticated } = useAuthentication()
    const [editMenuList, setEditMenuList] = useState<MainMenuType[]>([])
    const client = useClient()
    const loadingModal = useModal({ modal: 'loading' }, true)
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const {
      data = [],
      isLoading,
      refetch,
    } = useQuery('menus', () => client?.mainMenuClient.mainMenus(), {
      enabled: isAuthenticated,
    })

    function addEditItem(value: MainMenuType) {
      const index = editMenuList.findIndex(row => row.id === value.id)

      if (index === -1) {
        setEditMenuList(prev => prev.concat(value))
      }
    }

    function handleDragItem(
      result: DropResult,
      value: MainMenuType[],
      setFieldValue: (field: string, value: any) => void
    ) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      const items = reorder(value, source.index, destination.index)
      addEditItem(items[destination.index])
      setFieldValue('menus', items)
    }

    async function updateMainMenus(menus: MenuEditType[]) {
      try {
        await client?.mainMenuClient.updateMainMenus(menus)
      } catch {
        alert.error('เกิดข้อผิดพลาดในการแก้ไขเมนู')
      }
    }

    function onSubmit({ menus }: { menus: MainMenuType[] }) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการบันทึกเมนูหรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          loadingModal.show()
          const updateDatas = editMenuList.map(editMenu => {
            const newData: MenuEditType = { id: editMenu.id }
            const index = menus.findIndex(menu => menu.id === editMenu.id)

            if (
              index !== -1 &&
              (menus[index].id === data[index].id ||
                menus[index].name !== editMenu.name)
            ) {
              newData.name = menus[index].name
            }

            if (index !== -1 && menus[index].id !== data[index].id) {
              newData.runningNo = index + 1
            }

            return newData
          })
          try {
            await updateMainMenus(updateDatas)
            alert.success('แก้ไขเมนูสำเร็จ')
          } finally {
            loadingModal.hide()
            confirmModal.hide()
            setEditMenuList([])
            refetch()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const newProps = {
      data,
      handleDragItem,
      addEditItem,
      onSubmit,
    }
    return <Component {...newProps} />
  }

  return WithMenuManagePage
}

export default withMenuManagePage
