import { useMemo } from 'react'
import { DateTime } from 'luxon'

import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { UserWritersType } from '@models/writer/UserWritersType'
import { ColumnType, SortingType } from '@components/Table/interface'
import { StatusCell } from './components/StatusCell'
import { ActionCell } from './components/ActionCell'
import { UserCell } from './components/UserCell'
import { WriterTableProps } from './interface'

export function WriterTable({ data, handleSort, isLoading }: WriterTableProps) {
  const columns: ColumnType<UserWritersType>[] = useMemo(
    () => [
      {
        column: 'Date & Time',
        accessor: 'createdAt',
        cell: ({ value }) => (
          <p>
            {DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
              locale: 'th-Th',
            })}
          </p>
        ),
        cellClassName: 'max-w-[250px] w-[250px] pr-[10px]',
        isSortable: true,
      },
      {
        column: 'User ID',
        accessor: 'userId',
        sortKey: 'id',
        cell: ({ value }) => <p>{value}</p>,
        cellClassName: 'pr-[10px]',
        isSortable: true,
      },
      {
        column: 'Username',
        accessor: 'username',
        cell: ({ obj }) => (
          <UserCell coverImgPath={obj.avatarImgPath} username={obj.username} />
        ),
        cellClassName: 'pr-[10px]',
        isSortable: true,
      },
      {
        column: 'Status',
        accessor: 'status',
        cell: ({ value }) => <StatusCell value={value} />,
        isSortable: true,
        cellClassName: 'pr-[10px]',
      },
      {
        column: 'Action',
        accessor: 'id',
        cell: ({ value }) => <ActionCell id={value} />,
        isSortable: false,
        cellClassName: 'flex justify-end  py-[6.5px]',
      },
    ],
    []
  )

  return (
    <Table
      data={data}
      columns={columns}
      onSort={handleSort}
      isLoading={isLoading}
      initialSort={{
        key: 'createdAt',
        order: SortingType.DESC,
      }}
      bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
    />
  )
}
