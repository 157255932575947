import React from 'react'
import { BookChapterViewerProps } from './interface'

export function withBookChapterViewer(
  Component: React.FC<BookChapterViewerProps>
) {
  function WithBookChapterViewer({ ...props }: BookChapterViewerProps) {
    return <Component {...props} />
  }

  return WithBookChapterViewer
}
