import { PieChart } from '@components/chart/PieChart'
import { WriterGenderCategoriesType } from '@models/dashboard/WriterOverViewType'

export function GenderChart({ data }: { data: WriterGenderCategoriesType[] }) {
  return (
    <div className='w-full'>
      <PieChart
        title='เพศ'
        data={data}
        legendStyle='bottom'
        style={{ height: `calc(270px + ${data.length * 40}px)`, width: '100%' }}
      />
    </div>
  )
}
