import { Expose, Transform, Type } from 'class-transformer'

import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { PromotionRequestActionEnum } from '@interfaces/PromotionRequestActionEnum'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionCodeDetailType } from './PromotionCodeDetailType'
import { PromotionCodeCorrectionDetailType } from './PromotionCodeCorrectionDetailType'

class User {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath?: string
}

class OldPromotionType {
  @Expose()
  @Transform(({ obj, value }) => {
    if (value === 'DRAFT' && obj.generateCouponStatus === 'PROCESSING') {
      return PromotionStatusEnum.GENERATE_CODE
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'COMPLETED') {
      return PromotionStatusEnum.CODE_GENERATED
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'FAILED') {
      return PromotionStatusEnum.GENERATE_FAIL
    }
    return value
  })
  status: PromotionStatusEnum
}

export class PromotionCodeRequestType {
  @Expose()
  id: number

  @Expose()
  status: PromotionRequestStatusEnum

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose()
  @Type(() => User)
  createdBy: User

  @Expose()
  @Type(() => User)
  approvedBy: User

  @Expose() rejectedReason?: string

  @Expose()
  action: PromotionRequestActionEnum

  @Expose()
  @Type(() => OldPromotionType)
  oldPromotion: OldPromotionType

  @Expose()
  @Type(() => PromotionCodeDetailType)
  newPromotion: PromotionCodeDetailType

  @Expose()
  @Type(() => PromotionCodeCorrectionDetailType)
  correctionDetail?: PromotionCodeCorrectionDetailType
}
