import { gql } from 'graphql-request'

export const GET_FREQUENCY_TO_BY_COIN_BY_USER = gql`
  query GetFrequencyToBuyCoinByUser(
    $userId: Int!
    $endDate: DateTime
    $startDate: DateTime
    $keyType: KeyType!
  ) {
    getFrequencyToBuyCoinByUser(
      userId: $userId
      endDate: $endDate
      startDate: $startDate
      keyType: $keyType
    ) {
      keyType
      data {
        value
        totalBuyCoin
      }
      averageBuyCoin
    }
  }
`
