import { UserManagementTableProps } from './interface'

export function withUserManagementTable(
  Component: React.FC<UserManagementTableProps>
) {
  function WithUserManagementTable(props: UserManagementTableProps) {
    return <Component {...props} />
  }

  return WithUserManagementTable
}
