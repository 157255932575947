import React from 'react'
import { ButtonProps, ButtonType } from './interface'

export function withButton(Component: React.FC<ButtonProps>) {
  function WithButton(props: ButtonProps) {
    return <Component {...props} />
  }

  WithButton.defaultProps = {
    type: 'button' as ButtonType,
    className: '',
    icon: null,
  }

  return WithButton
}
