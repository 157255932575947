import React, { useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { DateTime } from 'luxon'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { DAY, getErrorMessage } from '@libs/utils'
import { HighlightPosterBannerType } from '@models/highlightBanner/HighlightPosterBannerType'
import { HighlightPosterBannerFormType } from '@models/highlightBanner/HighlightPosterBannerFormType'
import { HighlightBannerFormType } from '@models/highlightBanner/HighlightBannerFormType'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { BannerEnum } from '@interfaces/BannerEnum'
import { DisplayTypeEnum } from '@interfaces/DisplayTypeEnum'
import { queryClient } from '@client/init'
import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'
import { HighlightBannerPosterPageProps } from './interface'

export function withHighlightBannerPosterPage(
  Component: React.FC<HighlightBannerPosterPageProps>
) {
  function WithHighlightBannerPosterPage() {
    const client = useClient()
    const alert = useAlert()
    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [publishedType, setPublishedType] = useState<PublishedEnum>(
      PublishedEnum.CUSTOM
    )
    const confirmRemoveModal = useModal({ modal: 'confirm' })
    const queryKey = 'highlight-banner-poster-list'

    const { data, refetch } = useQuery(
      [queryKey, id],
      () =>
        client!.highlightBannerClient.getHighlightGroupPosterBanner(Number(id)),
      {
        onSuccess: res => {
          setPublishedType(res.publishedType)
        },
        enabled: !!id,
      }
    )

    const { mutate: updateGroupPosterBanner } = useMutation(
      (form: HighlightBannerFormType) =>
        client!.highlightBannerClient.updateHighlightGroupBanner(form),
      {
        onSuccess: () => {
          refetch()
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: updatePosterBanner } = useMutation(
      (form: HighlightPosterBannerFormType) =>
        client!.highlightBannerClient.enablePosterBanner(form),
      {
        onSuccess: () => {
          refetch()
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: reorderPosterBanner } = useMutation(
      ({
        posterId,
        newIndex,
      }: {
        posterId: number
        oldIndex: number
        newIndex: number
      }) =>
        client!.highlightBannerClient.updateOrderPosterBanner(
          posterId,
          newIndex + 1
        ),
      {
        onSuccess: () => refetch(),
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
        },
      }
    )

    const { mutate: handleDeleteBanner } = useMutation(
      (posterId: number) =>
        client!.highlightBannerClient.deletePosterBanner(posterId),
      {
        onSuccess: () => {
          refetch()
          confirmRemoveModal.hide()
          alert.success('ลบข้อมูลสำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: handleDeleteBanners } = useMutation(
      (form: { highlightGroupId: number; ids: number[] }) =>
        client!.highlightBannerClient.deletePosterBanners(
          form.highlightGroupId,
          form.ids
        ),
      {
        onSuccess: () => {
          refetch()
          confirmRemoveModal.hide()
          alert.success('ลบข้อมูลสำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: displayPosterBanner } = useMutation(
      (form: HighlightPosterBannerFormType) =>
        client!.highlightBannerClient.updatePosterBanner(form),
      {
        onSuccess: (resp: void, variables: HighlightPosterBannerFormType) => {
          queryClient.setQueryData<HighlightBannerType | undefined>(
            [queryKey, id],
            (oldData?: HighlightBannerType) => {
              if (oldData) {
                const index = oldData.posterBanner?.findIndex(
                  b => b.id === variables.id
                )
                // eslint-disable-next-line no-param-reassign
                oldData.posterBanner[index] = {
                  ...oldData.posterBanner[index],
                  isOnAndroid: !!variables.isOnAndroid,
                  isOnIos: !!variables.isOnIos,
                  isOnWeb: !!variables.isOnWeb,
                }
              }
              return oldData
            }
          )
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    function handleSelectItemAll(): void {
      if (data?.posterBanner) {
        const itemIds: number[] = data.posterBanner.map(
          (item: HighlightPosterBannerType) => item.id
        )
        const selectedItemIds = itemIds?.filter(selectId =>
          selectedIds.includes(selectId)
        )
        if (itemIds?.length === selectedItemIds?.length) {
          setSelectedIds(prev =>
            prev.filter(
              (selectId: number) => !selectedItemIds?.includes(selectId)
            )
          )
        } else if (selectedItemIds?.length) {
          setSelectedIds(prev => [
            ...prev,
            ...itemIds.filter(selectId => !selectedItemIds?.includes(selectId)),
          ])
        } else {
          setSelectedIds(prev => [...prev, ...itemIds])
        }
      }
    }

    function handleDragItem(result: DropResult) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      reorderPosterBanner({
        posterId: data?.posterBanner[source.index].id || 0,
        oldIndex: source.index,
        newIndex: destination.index,
      })
    }

    function handleSelectItem(selectId: number) {
      const index = selectedIds.findIndex((val: number) => val === selectId)
      if (index !== -1) {
        setSelectedIds((prev: number[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds((prev: number[]) => [...prev, selectId])
      }
    }

    function handleEnable(value: HighlightPosterBannerType) {
      const form: HighlightPosterBannerFormType = {
        id: value.id,
        isActive: !value.isActive,
      }

      updatePosterBanner(form)
    }

    async function handlePublishBanner() {
      await Promise.all(
        selectedIds.map(posterId =>
          client!.highlightBannerClient.enablePosterBanner({
            id: posterId,
            isActive: true,
          })
        )
      )
      refetch()
    }

    async function handleUnPublishBanner() {
      await Promise.all(
        selectedIds.map(posterId =>
          client!.highlightBannerClient.enablePosterBanner({
            id: posterId,
            isActive: false,
          })
        )
      )
      refetch()
    }

    function handleDeleteBannerList() {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: () =>
          handleDeleteBanners({
            highlightGroupId: Number(id),
            ids: selectedIds,
          }),
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function startDateChange(date: Date) {
      const dateTime = DateTime.fromJSDate(date).toUTC().toString()
      const now = new Date().valueOf()
      const start = DateTime.fromJSDate(date).toUTC().valueOf()
      const end = DateTime.fromJSDate(new Date(data?.publishedTo as string))
        .toUTC()
        .valueOf()

      if (data?.publishedTo && dateTime >= data.publishedTo) {
        updateGroupPosterBanner({
          id: Number(id),
          bannerType: BannerEnum.POSTER,
          publishedFrom: DateTime.fromJSDate(date).toUTC().toString(),
          publishedTo: DateTime.fromJSDate(date).toUTC().toString(),
          isActive: start <= now && end >= now,
        })
      } else {
        updateGroupPosterBanner({
          id: Number(id),
          bannerType: BannerEnum.POSTER,
          publishedFrom: DateTime.fromJSDate(date).toUTC().toString(),
          isActive: start <= now && end >= now,
        })
      }
    }

    function endDateChange(date: Date) {
      const now = new Date().valueOf()
      const start = DateTime.fromJSDate(new Date(data?.publishedFrom as string))
        .toUTC()
        .valueOf()
      const end = DateTime.fromJSDate(date).toUTC().valueOf()

      updateGroupPosterBanner({
        id: Number(id),
        bannerType: BannerEnum.POSTER,
        publishedTo: DateTime.fromJSDate(date).toUTC().toString(),
        isActive: start <= now && end >= now,
      })
    }

    function publishChange(value: PublishedEnum) {
      if (value === PublishedEnum.IMMEDIATELY) {
        const nextDate = new Date(new Date(Date.now() + DAY * 30))
        updateGroupPosterBanner({
          id: Number(id),
          isActive: true,
          bannerType: BannerEnum.POSTER,
          publishedFrom: DateTime.fromJSDate(new Date()).toUTC().toString(),
          publishedTo: DateTime.fromJSDate(nextDate).toUTC().toString(),
        })
      }
      setPublishedType(value)
    }

    function displayChangeActive(
      value: HighlightPosterBannerType,
      type: DisplayTypeEnum
    ) {
      displayPosterBanner({
        highlightGroupId: Number(id),
        id: value.id,
        isActive: value.isActive,
        linkWeb: value.linkWeb,
        linkIos: value.linkIos,
        linkAndroid: value.linkAndroid,
        isOnWeb: type === DisplayTypeEnum.WEB ? !value.isOnWeb : value.isOnWeb,
        isOnIos: type === DisplayTypeEnum.IOS ? !value.isOnIos : value.isOnIos,
        isOnAndroid:
          type === DisplayTypeEnum.ANDROID
            ? !value.isOnAndroid
            : value.isOnAndroid,
      })
    }

    if (!data) return null

    const newProps = {
      id: Number(id),
      data,
      selectedIds,
      publishedType,
      navigate,
      handleDragItem,
      handleSelectItemAll,
      handleSelectItem,
      handleEnable,
      handleDeleteBanner,
      handleDeleteBannerList,
      handlePublishBanner,
      handleUnPublishBanner,
      startDateChange,
      endDateChange,
      publishChange,
      displayChangeActive,
    }

    return <Component {...newProps} />
  }

  return WithHighlightBannerPosterPage
}
