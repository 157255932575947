import { StorySettingFormProps } from './interface'

export function withStorySettingForm(
  Component: React.FC<StorySettingFormProps>
) {
  function WithStorySettingForm(props: StorySettingFormProps) {
    const componentProps = {
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithStorySettingForm
}
