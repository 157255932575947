import { Link } from 'react-router-dom'

import { ColumnType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { abbreviateNumber } from '@libs/utils'
import { BookByUserType } from '@models/dashboard/BookByUserResponse'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'

export const columns: ColumnType<BookByUserType>[] = [
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img
          className='w-[29px] h-[41px] object-cover shrink-0'
          src={value}
          alt='cover'
        />
      ) : (
        <div className='w-[29px] h-[41px] object-cover shrink-0' />
      ),
    isSortable: false,
    cellClassName: 'pr-[5px]',
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => (
      <p className='w-[190px] line-clamp-2 pr-[5px]'>{value}</p>
    ),
    isSortable: true,
    sortKey: 'title',
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => <p>{bookTypeValue[value as BookEnum]}</p>,
    cellClassName: 'pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'penName.firstPenName',
    cell: ({ value }) => <p className='w-[140px] line-clamp-1'>{value}</p>,
    sortKey: 'firstPenName',
    cellClassName: 'pr-[5px]',
    isSortable: true,
  },
  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'pr-[5px]',
    isSortable: true,
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{abbreviateNumber(value || 0)}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Download',
    accessor: 'purchaseCount',
    cell: ({ value }) => <p>{abbreviateNumber(value || 0)}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: false,
  },
  {
    column: 'ยอดขาย',
    accessor: 'amountSpent',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[90px] max-w-[90px] pr-[5px]',
    isSortable: true,
    sortKey: 'totalSales',
  },
  {
    column: 'ยอดโดเนท',
    accessor: 'sumCharacterDonate',
    cell: ({ value }) => <p>{value ? value.toLocaleString() : '-'}</p>,
    cellClassName: 'w-[90px] max-w-[90px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => (
      <div className='flex gap-x-[10px]'>
        <Link to={`/story/${value}`}>
          <button
            className='border border-gray h-[30px] px-[4px] rounded-[8px]'
            type='button'
          >
            <EyeIcon className='text-black-970' width='18' height='18' />
          </button>
        </Link>
      </div>
    ),
    isSortable: false,
  },
]
