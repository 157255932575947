import { Expose, Transform } from 'class-transformer'

export class SaleChannelsOptionType {
  @Expose({ name: 'name' }) label: string

  @Expose()
  @Transform(({ obj }) => {
    return obj?.name
  })
  value: string
}
