import { Type, Expose, Transform } from 'class-transformer'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'

class UserType {
  id: number

  @Transform(({ value }) => value || '/images/icon-app.png')
  avatarImgPath: string

  displayName: string
}

export class CoinType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  imgPath: string

  @Transform(({ value }) => value)
  amountCoin: number

  @Transform(({ value }) => value)
  amountCoinPerValue: number

  @Expose({ name: 'valueUnitCoin' })
  @Transform(({ value }) => value?.name)
  valueUnitCoinName: string

  @Transform(({ value }) => value ?? 0)
  expireWithinMonthsByActivity: number

  @Transform(({ value }) => value ?? 0)
  expireWithinMonthsByPaid: number

  @Expose()
  visible: boolean
}

export class CoinHistoryType {
  id: number

  @Type(() => CoinType)
  newCoins: CoinType[]

  status: CoinRequestStatusEnum

  @Type(() => UserType)
  createdBy: UserType

  @Type(() => UserType)
  approvedBy: UserType

  createdAt: string
}
