import { useNotification } from '@hooks/notification/useNotification'
import { NotificationType } from '@models/notification/NotificationType'
import { usePagination } from '@hooks/usePagination'
import { useNavigate } from 'react-router-dom'
import { NotificationPageProps } from './interface'

export function withNotificationPage(
  Component: React.FC<NotificationPageProps>
) {
  function WithNotificationPage() {
    const { page, perpage, pageChange } = usePagination({})
    const navigate = useNavigate()

    const {
      data,
      total: totalNotification,
      isLoading,
      totalUnread,
      setNotificationPage,
      updateReadStatus,
      updateAllReadStatus,
    } = useNotification()

    function handleClickNotification(notice: NotificationType) {
      if (!notice.read) {
        updateReadStatus(notice.id)
      }
      navigate(notice.urlPath)
    }

    function handlePaginationChange(newPage: number) {
      setNotificationPage(newPage)
      pageChange(newPage)
    }

    const componentProps = {
      noticeList: data,
      page,
      perpage,
      total: totalNotification,
      totalUnread,
      isLoading,
      handleClickNotification,
      updateAllReadStatus,
      handlePaginationChange,
    }

    return <Component {...componentProps} />
  }
  return WithNotificationPage
}
