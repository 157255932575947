/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react'
import { SlugInputProps } from './interface'

export function withSlugInput(
  Component: React.FC<React.HTMLProps<HTMLInputElement>>
) {
  function WithSlugInput({ onChange, setTouched, ...props }: SlugInputProps) {
    function slugify(text: string) {
      return text
        .toLowerCase()
        .replace(/\s+/gi, '-')
        .replace(/-+/g, '-')
        .toLowerCase()
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = slugify(event.target.value)
      event.target.value = value
      if (onChange) {
        onChange(event)
      }
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      const { value } = event.target
      if (value?.slice(-1) === '-' && onChange) {
        event.target.value = value.slice(0, -1)
        onChange(event)
      }
      setTouched(true)
    }

    const componentProps = {
      ...props,
      onChange: handleChange,
      onBlur: handleBlur,
    }
    return <Component {...componentProps} />
  }

  return WithSlugInput
}
