import { Expose, Type } from 'class-transformer'
import { AdminUserType } from './AdminUserType'

export class AdminUserResponse {
  @Expose()
  @Type(() => AdminUserType)
  data: AdminUserType[]

  @Expose()
  total: number
}
