import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import Footnote from './Footnote'

const FootnoteExtension = Node.create({
  group: 'inline',
  content: 'inline*',
  inline: true,
  atom: true,
  name: 'footnote',
  parseHTML() {
    return [
      {
        tag: 'footnote',
      },
    ]
  },

  addAttributes() {
    return {
      hrefId: {
        default: '',
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['footnote', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Footnote)
  },
})

export default FootnoteExtension
