import { useMemo } from 'react'
import cn from 'classnames'

import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { CommentSectionProps } from './interface'
import { columns } from './Columns'

export function CommentSection({
  data,
  isLoading,
  handleFetchNextPage,
  handleSort,
}: CommentSectionProps) {
  const userComment = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <div className='mb-[30px]'>
      <div className='flex'>
        <div className='flex-1 font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          รายการคอมเมนต์
        </div>

        <div className='text-[14px] text-secondary-100 font-light'>
          <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
            {data?.pages[0].total ? data?.pages[0].total.toLocaleString() : '-'}
          </span>
          คอมเมนต์
        </div>
      </div>
      <Table
        data={userComment}
        columns={columns}
        onSort={handleSort}
        fixedHeader
        className='mb-[30px] max-h-[400px] pr-[20px]'
        onWaypointEnter={handleFetchNextPage}
        isLoading={isLoading}
        initialSort={{ key: 'createdAt', order: SortingType.DESC }}
      />
    </div>
  )
}
