import { useMemo } from 'react'

import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { columns } from '../StorySectionColumn'
import { ReadSectionProps } from './interface'

export function ReadSection({
  data,
  isLoading,
  handleFetchNextPage,
  handleSort,
}: ReadSectionProps) {
  const bookData = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <div className='mb-[30px]'>
      <div className='flex'>
        <div className='flex-1 font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          เรื่องที่อ่าน
        </div>
        <div className='text-[14px] text-secondary-100 font-light'>
          อ่านทั้งหมด:
          <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
            {data?.pages[0].total ? data?.pages[0].total.toLocaleString() : '-'}
          </span>
          เรื่อง
        </div>
      </div>

      <Table
        data={bookData}
        columns={columns}
        onSort={handleSort}
        fixedHeader
        className='mb-[30px] max-h-[400px] pr-[20px]'
        onWaypointEnter={handleFetchNextPage}
        isLoading={isLoading}
        initialSort={{ key: 'chapterReadCount', order: SortingType.DESC }}
      />
    </div>
  )
}
