import { Type } from 'class-transformer'
import { CoinRequestType } from './CoinRequestType'

export class CoinRequestResponse {
  @Type(() => CoinRequestType)
  data: CoinRequestType[]

  total: number

  page: number
}
