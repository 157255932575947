import { useMemo } from 'react'
import { Pagination } from '@components/Pagination'
import { ColumnType } from '@components/Table/interface'
import { UserManagementList } from '@models/user/UserManagementList'
import { PenNameType } from '@models/user/PenNameType'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { Table } from '@components/Table'
import { AgeConfirmStatus } from '@features/user/components/AgeConfirmStatus'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { BanIcon } from '@components/Icons'
import { ActionCell } from './components/ActionCell'
import { PennameCell } from './components/PennameCell'
import { UserManagementTableProps } from './interface'

export function UserManagementTable({
  page,
  perpage,
  total,
  data,
  queryKey,
  searchParams,
  isLoading,
  pageChange,
  handleSort,
}: UserManagementTableProps) {
  const columns: ColumnType<UserManagementList>[] = useMemo(
    () => [
      {
        column: 'User ID',
        accessor: 'id',
        cell: ({ value }) => <p>{value || '-'}</p>,
        isSortable: true,
        sortKey: 'userId',
        cellClassName: 'max-w-[80px] w-[80px] pr-[20px]',
      },
      {
        column: 'Status',
        accessor: 'isBan',
        cell: ({ value }) => (
          <div className='flex items-center '>
            {value ? (
              <BanIcon width='18' height='18' className='text-red' />
            ) : (
              <span className='ml-[6px]'>-</span>
            )}
          </div>
        ),
        isSortable: true,
        cellClassName: 'pr-[20px]',
      },
      {
        column: 'Username',
        accessor: 'username',
        cell: ({ value }) => (
          <p className='line-clamp-1 break-word'>@{value || '-'}</p>
        ),
        isSortable: true,
        cellClassName: 'pr-[20px]',
      },
      {
        column: 'Email',
        accessor: 'email',
        cell: ({ value }) => (
          <p className='line-clamp-1 break-word'>{value || '-'}</p>
        ),
        isSortable: true,
        cellClassName: 'pr-[20px]',
      },
      {
        column: 'Writer ID',
        accessor: 'writer.id',
        cell: ({ value, obj }) => {
          return obj.writer?.status === WriterStatusEnum.APPROVED ? (
            <p className='line-clamp-1 break-word'>{value}</p>
          ) : (
            <p>-</p>
          )
        },
        isSortable: true,
        cellClassName: 'max-w-[110px] w-[110px] pr-[20px]',
        sortKey: 'writerId',
      },
      {
        column: 'Publisher',
        accessor: 'writer.affiliation',
        cell: ({ value, obj }) => {
          return value === AffiliationEnum.PUBLISHER &&
            obj.writer?.status === WriterStatusEnum.APPROVED ? (
            <p className='line-clamp-1 break-word'>{obj.displayName}</p>
          ) : (
            <p>-</p>
          )
        },
        isSortable: true,
        cellClassName: 'max-w-[110px] w-[110px] pr-[20px]',
        sortKey: 'fullNamePublisher',
      },
      {
        column: 'Writer',
        accessor: 'writer.affiliation',
        cell: ({ value, obj }) => {
          return value === AffiliationEnum.INDIVIDUAL &&
            obj.writer?.status === WriterStatusEnum.APPROVED ? (
            <p className='line-clamp-1 break-word'>{obj.displayName}</p>
          ) : obj.penName.length > 0 ? (
            <p className='line-clamp-1 break-word'>{obj.displayName}</p>
          ) : (
            <p>-</p>
          )
        },
        isSortable: true,
        cellClassName: 'max-w-[110px] w-[110px] pr-[20px]',
        sortKey: 'fullNameWriter',
      },
      {
        column: 'Penname',
        accessor: 'penName',
        cell: ({ value }) => (
          <PennameCell
            penname={value.map((item: PenNameType) => `${item.firstPenName}`)}
          />
        ),
        cellClassName: 'max-w-[180px] w-[180px] pr-[20px]',
      },
      {
        column: 'ID card',
        accessor: 'ageVerify',
        cell: ({ value }) => (
          <div className='flex items-center '>
            {value ? (
              <AgeConfirmStatus status={value} />
            ) : (
              <span className='ml-[6px]'>-</span>
            )}
          </div>
        ),
        isSortable: true,
        cellClassName: 'max-w-[110px] w-[110px] pr-[20px]',
      },
      {
        column: 'Action',
        actionCell: ({ obj }) => (
          <ActionCell
            id={obj.id}
            canResetPin={obj.isPinEnable}
            isBan={obj.isBan}
            queryKey={queryKey}
            writingDisabled={obj.writingDisabled}
          />
        ),
        isAction: true,
        cellClassName: 'flex max-w-[80px] w-[80px] justify-end',
      },
    ],
    [queryKey]
  )

  return (
    <div>
      <div className='flex-1 overflow-x-auto pb-[20px]'>
        <Table
          data={data}
          columns={columns}
          initialSort={{
            key: searchParams.sortKey,
            order: searchParams.sortOrder,
          }}
          isLoading={isLoading}
          onSort={handleSort}
          bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
        />
      </div>
      <div className='mt-[10px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
