import cn from 'classnames'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CrossIcon } from '@components/Icons'
import { ManageSearchTermTemplateProps } from './interface'

export function ManageSearchTermTemplate({
  data,
  inputRef,
  isBan,
  label,
  handleKeyDown,
  handleDelete,
}: ManageSearchTermTemplateProps) {
  const permission = usePermissionAction('searchManagement')
  return (
    <>
      <div className='text-secondary-100 text-[12px] font-light mb-[5px]'>
        {label || 'คำค้นหา'}
      </div>
      <div className='flex flex-wrap gap-[10px] border border-gray p-[10px] rounded-[8px] text-[14px] text-white max-h-[260px] overflow-y-auto'>
        {data.map(
          (row, index) =>
            !row.isDelete && (
              <div
                className={cn(
                  'flex items-center gap-x-[5px] px-[10px] py-[6px] rounded-[8px] h-[30px]',
                  {
                    'bg-secondary': !isBan,
                    'bg-red-74': isBan,
                  }
                )}
                key={row.id || row.mockId}
              >
                <span>{row.name}</span>
                <button
                  type='button'
                  onClick={() => handleDelete(index)}
                  className={cn({ hidden: !permission.edit })}
                >
                  <CrossIcon width='18' height='18' />
                </button>
              </div>
            )
        )}
        <input
          disabled={!permission.edit}
          ref={inputRef}
          className='outline-none text-black-480 h-[30px] disabled:bg-white'
          type='text'
          onKeyDown={handleKeyDown}
        />
      </div>
    </>
  )
}
