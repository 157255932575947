import { Expose, Transform, Type } from 'class-transformer'

import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { SettingPromotionStatusEnum } from '@interfaces/SettingPromotionStatusEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { PromotionPublishedTypeEnum } from '@interfaces/PromotionPublishedTypeEnum'
import { UserSearchType } from '@models/user/UserSearchType'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { PromotionLastRequestType } from './PromotionLastRequestType'
import { PromotionCodeType } from './PromotionCodeType'
import { PromotionChallengeType } from './PromotionChallengeType'
import { PromotionSaleType } from './PromotionSaleType'

export class PromotionSettingType {
  @Expose() id: number

  @Expose() businessId: string

  @Expose() type: SettingPromotionMenuTypeEnum

  @Expose()
  @Transform(({ obj, value }) => {
    if (value === 'DRAFT' && obj.generateCouponStatus === 'PROCESSING') {
      return SettingPromotionStatusEnum.GENERATE_CODE
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'COMPLETED') {
      return SettingPromotionStatusEnum.CODE_GENERATED
    }
    if (value === 'DRAFT' && obj.generateCouponStatus === 'FAILED') {
      return SettingPromotionStatusEnum.GENERATE_FAIL
    }
    return value
  })
  status: SettingPromotionStatusEnum

  @Expose() name: string

  @Expose() description: string

  @Expose() isActive: boolean

  @Expose() isEditable: boolean

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose() userType: PromotionUserEnum

  @Expose() usageLimitPerUser: number

  @Expose() usageLimit: number

  @Expose() usageDay: DaySelectEnum[]

  @Expose() createdAt: string

  @Expose() publishedType: PromotionPublishedTypeEnum

  @Expose() userTerm: string

  @Expose() isUsageLimitPerUser: boolean

  @Expose() isUsageLimit: boolean

  @Expose() usersFileName: string

  @Expose() users: UserSearchType[]

  @Expose() generateCouponStatus?: PromotionCodeJobStatusEnum

  @Expose() couponValue?: string

  @Expose()
  @Type(() => PromotionChallengeType)
  promotionChallenge?: PromotionChallengeType

  @Expose()
  @Type(() => PromotionCodeType)
  promotionCode?: PromotionCodeType

  @Expose()
  @Type(() => PromotionSaleType)
  promotionSale?: PromotionSaleType

  @Expose()
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType
}
