import { withPermissionGuard } from '@libs/permission'
import { HighlightBannerBookPage } from './HighlightBannerBookPage'
import { withHighlightBannerBookPage } from './withHighlightBannerBookPage'

const Connected = withPermissionGuard(
  withHighlightBannerBookPage(HighlightBannerBookPage),
  'homePageManagement',
  'view'
)

export { Connected as HighlightBannerBookPage }
