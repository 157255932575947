import { Outlet } from 'react-router-dom'
import { NewsAndPromotionCreatePage } from './pages/NewsAndPromotionCreatePage'
import { NewsAndPromotionDetailPage } from './pages/NewsAndPromotionDetailPage'
import { NewsAndPromotionListPage } from './pages/NewsAndPromotionListPage'

export const newsAndPromotionRoutes = [
  {
    path: 'news-promotion',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <NewsAndPromotionListPage />,
      },
      {
        path: 'create',
        element: <NewsAndPromotionCreatePage />,
      },
      {
        path: ':id',
        element: <NewsAndPromotionDetailPage />,
      },
    ],
  },
]
