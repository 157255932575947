import { useStory } from '@hooks/story/useStory'
import React from 'react'

import { BookLinkProps } from './interface'

export function withBookLink(Component: React.FC<BookLinkProps>) {
  function Hoc() {
    const { data, isLoading } = useStory()

    const isShowNaiinBook = !!(
      data?.naiinCoverImgPath &&
      data?.naiinTitle &&
      data?.naiinCover &&
      data?.naiinLink
    )

    if (
      isLoading ||
      !data ||
      (!isShowNaiinBook && data.bookLinks.length === 0)
    ) {
      return null
    }

    const componentProps = {
      naiinCoverImgPath: data.naiinCoverImgPath,
      naiinTitle: data.naiinTitle,
      naiinCover: data.naiinCover,
      naiinLink: data.naiinLink,
      bookLinks: data.bookLinks,
      isShowNaiinBook,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
