import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import {
  SearchManagementLayoutProps,
  SearchManagementTabPathType,
} from './interface'

const searchMenagementTabOption = [
  {
    text: 'ประวัติการค้นหา',
    value: 'history',
  },
  {
    text: 'จัดการคำค้นหา',
    value: 'manageSearchTerm',
  },
]
const searchMenagementTabPath = {
  history: '/search-management/history',
  manageSearchTerm: '/search-management/manage-search-term',
}

export function withSearchManagementLayout(
  Component: React.FC<SearchManagementLayoutProps>
) {
  function WithSearchManagementLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<SearchManagementTabPathType>('history')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(searchMenagementTabPath).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as SearchManagementTabPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleChangeTab(type: SearchManagementTabPathType) {
      setTab(type)
      navigate(searchMenagementTabPath[type])
    }

    const newProps = {
      searchMenagementTabOption,
      tab,
      handleChangeTab,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithSearchManagementLayout
}
