import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'

import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { getErrorMessage } from '@libs/utils'
import { BookStorySettingType } from '@models/bookStorySetting/BookStorySettingType'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { BookStorySettingPageProps } from './interface'

export function withBookStorySettingPage(
  Component: React.FC<BookStorySettingPageProps>
) {
  function WithBookStorySettingPage() {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const navigate = useNavigate()
    const alert = useAlert()
    const confirmModal = useModal({
      modal: 'confirm',
    })
    const confirmRemoveModal = useModal({ modal: 'confirm' })

    const {
      data: bookStorySettings = [],
      isLoading,
      isFetching,
      refetch,
    } = useQuery(
      'book-story-settings',
      () => client?.bookStorySettingClient.getBookStorySettings(),
      {
        enabled: isAuthenticated,
      }
    )

    function handleSubmit(values: {
      bookStorySettings: BookStorySettingType[]
    }) {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ยืนยันการบันทึกแก้ไขแบบฟอร์มทั้งหมดหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              const updateData = values.bookStorySettings.filter(item => {
                return bookStorySettings.find(
                  oldItem =>
                    oldItem.id === item.id &&
                    (oldItem.maxCharacter !== item.maxCharacter ||
                      item.isDeleted)
                )
              })
              await client?.bookStorySettingClient.updateAndRemoveBookStorySetting(
                updateData
              )
              alert.success('บันทึกการแก้ไขแบบฟอร์มสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              refetch()
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    function showConfirmRemoveModal(onRemove: () => void) {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: () => {
          onRemove()
          confirmRemoveModal.hide()
        },
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    if (isLoading || isFetching) {
      return <></>
    }

    const newProps = {
      bookStorySettings,
      navigate,
      handleSubmit,
      showConfirmRemoveModal,
    }
    return <Component {...newProps} />
  }

  return WithBookStorySettingPage
}
