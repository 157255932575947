import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { PopupBannerLogResponse } from '@models/popupBanner/PopupBannerLogResponse'
import { HistoryPopupBannerProps } from './interface'

const PER_PAGE = 15

export function withHistoryPopupBanner(
  Component: React.FC<HistoryPopupBannerProps>
) {
  function WithHistoryPopupBanner() {
    const { id } = useParams()
    const client = useClient()

    const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
      ['histories', id],
      async ({ pageParam = 1 }) =>
        client!.popupBannerClient
          .getPopupBannerLogs({
            limitPerPage: PER_PAGE,
            page: pageParam,
            popupBannerId: Number(id),
          })
          .then(res => {
            return res
          }),
      {
        getNextPageParam: (lastPage: PopupBannerLogResponse) => {
          if (lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const componentProps = {
      data,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithHistoryPopupBanner
}
