import React from 'react'

import { OverAllStatisProps } from './interface'

export function withOverAllStatis(Component: React.FC<OverAllStatisProps>) {
  function Hoc(props: OverAllStatisProps) {
    return <Component {...props} />
  }

  return Hoc
}
