import { gql } from 'graphql-request'

export const MAIN_MENUS = gql`
  query MainMenus {
    mainMenus {
      id
      name
      runningNo
    }
  }
`
