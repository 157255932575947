import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useClient } from '@hooks/useClient'
import { StickerManageType } from '@models/sticker/StickerManageType'
import { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import { StickerManagementEditPageProps } from './interface'

export function withStickerManagementEditPage(
  Component: React.FC<StickerManagementEditPageProps>
) {
  function WithStickerManagementEditPage() {
    const navigate = useNavigate()
    const client = useClient()
    const { id } = useParams()
    const { data, isLoading, isFetching } = useQuery(['sticker', id], () =>
      client?.stickerClient.getSticker(Number(id))
    )

    const { mutate: updateSticker } = useMutation(
      (form: StickerManagementFormType) =>
        client!.stickerClient.updateSticker(form),
      {
        onSuccess: () => {
          navigate({
            pathname: '/chapter-book/sticker-management',
          })
        },
      }
    )

    async function onSubmit(form: StickerManagementFormType) {
      updateSticker(form)
    }

    if (isFetching) return null

    const newProps = {
      initialValues: plainToInstance(StickerManagementFormType, data),
      isLoading,
      onSubmit,
    }
    return <Component {...newProps} />
  }

  return WithStickerManagementEditPage
}
