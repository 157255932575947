import { withPermissionGuard } from '@libs/permission'
import withCoverListPage from './withCoverListPage'
import CoverListPage from './CoverListPage'

const ConnectedCoverListPage = withPermissionGuard(
  withCoverListPage(CoverListPage),
  'chapterBook',
  'view'
)

export { ConnectedCoverListPage as CoverListPage }
