import { Box } from '@components/Box'
import { ResidenceEnum } from '@interfaces/ResidenceEnum'
import { TextArea } from '@components/TextArea'
import { Checkbox } from '@components/Checkbox'
import { OptionType } from '@interfaces/OptionType'
import { TextInput } from './TextInput'
import { SelectButton } from './SelectButton'
import { useWriterDetail } from '../hooks/useWriterDetail'

const residenceOptions = [
  { text: 'ประเทศไทย', value: ResidenceEnum.LOCAL },
  { text: 'ต่างประเทศ', value: ResidenceEnum.ABOARD },
]

export function DeliveryAddress({
  countryOptions,
}: {
  countryOptions: OptionType[]
}) {
  const { writer } = useWriterDetail()

  return (
    <div>
      <Box.Title>ที่อยู่สำหรับจัดส่งเอกสาร</Box.Title>
      <Checkbox
        label='ใช้ที่อยู่ตามบัตรประชาชน'
        defaultChecked={writer?.isSameMainAddress || false}
      />
      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <SelectButton
          label='ประเทศ'
          options={residenceOptions}
          value={writer?.sendResidence}
          className='font-mitr'
        />
        {writer?.sendResidence === ResidenceEnum.LOCAL ? (
          <>
            <TextInput
              label='ที่อยู่'
              placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
              value={writer.sendAddress}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer.sendPostcode}
            />
            <TextInput
              label='จังหวัด'
              placeholder='จังหวัด'
              value={writer.sendProvince}
            />
            <TextInput
              label='เขต / อำเภอ'
              placeholder='เขต / อำเภอ'
              value={writer.sendDistrict}
            />
            <TextInput
              label='แขวง / ตำบล'
              placeholder='แขวง / ตำบล'
              value={writer?.sendSubDistrict}
            />
          </>
        ) : (
          <>
            <TextInput
              label='ประเทศ'
              placeholder='เลือกประเทศ'
              value={writer?.sendCountry || ''}
              options={countryOptions}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer?.sendPostcode || ''}
            />
            <div className='col-span-3'>
              <label className='text-[12px] font-light mb-[6px] text-black-480'>
                ที่อยู่
              </label>
              <TextArea
                className='min-h-[120px]'
                placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
                value={writer?.sendAddress}
                disabled
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
