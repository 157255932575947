import cn from 'classnames'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function TextInputUnit({ children, className }: FunctionComponentType) {
  return (
    <p className={cn('text-secondary-100 text-sm font-light', className)}>
      {children}
    </p>
  )
}
