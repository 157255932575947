import { Outlet } from 'react-router-dom'
import { MenuManagePage } from './pages/MenuManagePage'

export const menuManagementRoutes = [
  {
    path: 'menu-management',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <MenuManagePage />,
      },
    ],
  },
]
