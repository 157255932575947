import { BookEnum } from '@interfaces/BookEnum'

export class CharacterDonationByStory {
  rank: number

  bookId: number

  coverImgPath: number

  title: string

  bookType: BookEnum

  viewCount: number

  firstPenName: string

  chapterCount: number

  totalDonation: number

  likeCount: number
}

export class CharacterDonationByStoryResponse {
  data: CharacterDonationByStory[]

  total: number

  page: number
}
