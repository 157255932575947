import { withPermissionGuard } from '@libs/permission'
import { NewsAndPromotionCreatePage } from './NewsAndPromotionCreatePage'
import { withNewsAndPromotionCreatePage } from './withNewsAndPromotionCreatePage'

const ConnectedNewsAndPromotionCreatePage = withPermissionGuard(
  withNewsAndPromotionCreatePage(NewsAndPromotionCreatePage),
  'newsPromotion',
  'create'
)

export { ConnectedNewsAndPromotionCreatePage as NewsAndPromotionCreatePage }
