import React from 'react'
import { useFormikContext } from 'formik'
import { useMutation } from 'react-query'
import cloneDeep from 'lodash.clonedeep'

import { UserMissionLevelFormType } from '@models/user-mission/UserMissionLevelFormType'
import { LevelType } from '@models/user-mission/LevelType'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { UserMissionLevelProps, WithUserMissionLevelProps } from './interface'

export function withUserMissionLevel(
  Component: React.FC<UserMissionLevelProps>
) {
  function WithUserMissionLevel({
    index,
    userMissionId,
    editId,
    setEditId,
    refetchUserMission,
    ...props
  }: WithUserMissionLevelProps) {
    const client = useClient()
    const alert = useAlert()
    const { values, errors, initialValues, setFieldValue, resetForm } =
      useFormikContext<UserMissionLevelFormType>()

    const { mutate: createUserMissionLevelLevel, isLoading: isCreateLoading } =
      useMutation(
        (form: LevelType) =>
          client!.userMissionClient.createChallengeLevel(form, userMissionId),
        {
          onSuccess: async () => {
            await refetchUserMission()
            alert.success('บันทึกข้อมูลเรียบร้อย')
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )

    const { mutate: updateUserMissionLevelLevel, isLoading: isUpdateLoading } =
      useMutation(
        (form: LevelType) =>
          client!.userMissionClient.updateChallengeLevel(form),
        {
          onSuccess: async () => {
            await refetchUserMission()
            setEditId(-1)
            alert.success('บันทึกข้อมูลเรียบร้อย')
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )

    function updateImage(blob: Blob | null) {
      if (blob) {
        setFieldValue(`userMissionLevels.${index}.upload`, {
          url: URL.createObjectURL(blob),
          blob,
        })
      }
    }

    async function handleUpdateUserMissionLevel() {
      if (values.userMissionLevels[index].id) {
        await updateUserMissionLevelLevel(values.userMissionLevels[index])
      } else {
        await createUserMissionLevelLevel(values.userMissionLevels[index])
      }
    }

    function handleCancel() {
      setEditId(-1)
      if (values.userMissionLevels[index].id) {
        resetForm()
      } else {
        const temp = cloneDeep(initialValues)
        temp.userMissionLevels.splice(temp.userMissionLevels.length - 1, 1)
        resetForm({
          values: {
            userMissionLevels: temp.userMissionLevels,
          },
        })
      }
    }

    function handleEditIdChange(value: number) {
      if (editId === 0) {
        const temp = cloneDeep(initialValues)
        temp.userMissionLevels.splice(temp.userMissionLevels.length - 1, 1)
        resetForm({
          values: {
            userMissionLevels: temp.userMissionLevels,
          },
        })
      } else {
        resetForm()
      }

      setEditId(value)
    }

    const componentProps = {
      ...props,
      index,
      userMissionLevel: initialValues.userMissionLevels[index],
      imageUrl: values.userMissionLevels[index].upload.url,
      isActive: values.userMissionLevels[index].isActive,
      isUsed: values.userMissionLevels[index].isUsed,
      dirty:
        initialValues.userMissionLevels[index].levelName !==
          values.userMissionLevels[index].levelName ||
        initialValues.userMissionLevels[index].target !==
          values.userMissionLevels[index].target ||
        initialValues.userMissionLevels[index].upload.url !==
          values.userMissionLevels[index].upload.url,
      isValid: !(errors.userMissionLevels && errors.userMissionLevels[index]),
      isSubmitting: isCreateLoading || isUpdateLoading,
      updateImage,
      handleUpdateUserMissionLevel,
      handleCancel,
      handleEditIdChange,
    }

    return <Component {...componentProps} />
  }

  return WithUserMissionLevel
}
