import { Button } from '@components/Button'
import { BookLinkProps } from './interface'
import { Box } from '../Box'

export function BookLink({
  naiinCoverImgPath,
  naiinTitle,
  naiinCover,
  naiinLink,
  bookLinks,
  isShowNaiinBook,
}: BookLinkProps) {
  return (
    <Box className='mt-5'>
      {isShowNaiinBook && (
        <>
          <Box.Title className='font-mitr'>
            หนังสือที่ขายกับร้านนายอินทร์
          </Box.Title>

          <div className='flex mobile:flex-col gap-x-[37px] mt-5'>
            <div className='w-[144px] rounded-lg overflow-hidden shrink-0'>
              <div className='relative w-full h-full pt-[140.625%]'>
                {naiinCoverImgPath && (
                  <img
                    className='absolute inset-0'
                    src={naiinCoverImgPath}
                    alt='ebook'
                  />
                )}
              </div>
            </div>

            <div className='flex-1 mobile:mt-5'>
              <Box.Label>ชื่อเรื่อง</Box.Label>
              <Box.Paragraph className='mt-1'>{naiinTitle}</Box.Paragraph>

              <Box.Label className='mt-5'>คำโปรย</Box.Label>
              <Box.Paragraph className='mt-1 line-clamp-2 break-word'>
                {naiinCover}
              </Box.Paragraph>

              <Button
                type='button'
                className='bg-primary rounded-full font-medium text-sm text-white mt-[30px] h-10 w-[178px]'
                onClick={() => {
                  if (naiinLink) {
                    window.open(naiinLink, '_blank')
                  }
                }}
              >
                ซื้อเลย
              </Button>
            </div>
          </div>
        </>
      )}

      {/* {!!bookLinks.length && (
        <div className='flex flex-col'>
          <Box.Title>ลิงก์อื่นๆ</Box.Title>

          {bookLinks.map(bookLink => (
            <div
              className='flex mobile:flex-col items-center mobile:items-start mobile:gap-y-[10px] mt-5'
              key={bookLink.id}
            >
              <Box.Paragraph className='basis-[25%] truncate'>
                {bookLink.detail}
              </Box.Paragraph>
              <Box.Paragraph className='underline text-primary basis-[75%] truncate'>
                <a
                  className='cursor-pointer line-clamp-1 break-all'
                  href={bookLink.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  {bookLink.link}
                </a>
              </Box.Paragraph>
            </div>
          ))}
        </div>
      )} */}
    </Box>
  )
}
