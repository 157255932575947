import { gql } from 'graphql-request'

export const GET_BOOK_CHAPTER_VIEWER = gql`
  query GetBookChapterViewer(
    $bookId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getBookChapterViewer(
      bookId: $bookId
      startDate: $startDate
      endDate: $endDate
    ) {
      uniqueViewerBuyAllChapter
      uniqueView
    }
  }
`
