import React, { useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { QueryKey, useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { PromotionSettingSearchFormType } from '@models/promotion/PromotionSettingSearchFormType'
import { SettingPromotionOrderEnum } from '@interfaces/SettingPromotionOrderEnum'
import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { SettingPromotionStatusEnum } from '@interfaces/SettingPromotionStatusEnum'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { usePagination } from '@hooks/usePagination'
import { PromotionSettingPageProps } from './interface'

const menus = [
  {
    text: 'ทั้งหมด',
    value: SettingPromotionMenuTypeEnum.ALL,
  },
  {
    text: 'โปรโมชั่น Code',
    value: SettingPromotionMenuTypeEnum.CODE,
  },
  {
    text: 'User Challenge',
    value: SettingPromotionMenuTypeEnum.CHALLENGE,
  },
  {
    text: 'โปรโมชั่นการขาย',
    value: SettingPromotionMenuTypeEnum.SALE,
  },
]

const promotionShow = [
  {
    label: 'ทั้งหมด',
    value: SettingPromotionStatusEnum.ALL,
  },
  {
    label: 'ดำเนินการแล้ว',
    value: SettingPromotionStatusEnum.STARTED,
  },
  {
    label: 'ปิดโปรโมชั่น',
    value: SettingPromotionStatusEnum.CLOSED,
  },
  {
    label: 'อนุมัติแล้ว',
    value: SettingPromotionStatusEnum.APPROVED,
  },
  {
    label: 'รออนุมัติ',
    value: SettingPromotionStatusEnum.PENDING,
  },

  {
    label: 'แบบร่าง',
    value: SettingPromotionStatusEnum.DRAFT,
  },
  {
    label: 'Reject',
    value: SettingPromotionStatusEnum.REJECTED,
  },
]

const promotionOrder = [
  {
    label: 'วันที่สร้าง',
    value: SettingPromotionOrderEnum.CREATED,
  },
  {
    label: 'วันที่เผยแพร่ เก่า-ใหม่',
    value: SettingPromotionOrderEnum.ASC,
  },
  {
    label: 'วันที่เผยแพร่ ใหม่-เก่า',
    value: SettingPromotionOrderEnum.DESC,
  },
  {
    label: 'วันที่จบ',
    value: SettingPromotionOrderEnum.ENDED,
  },
]

export function withPromotionSettingPage(
  Component: React.FC<PromotionSettingPageProps>
) {
  function Hoc() {
    const client = useClient()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const text = params.get('text') || ''
    const startDate = params.get('startDate') || ''
    const endDate = params.get('endDate') || ''

    const [selectedPromotionShow, setSelectedPromotionShow] =
      useState<SettingPromotionStatusEnum>(
        (params.get('show') as SettingPromotionStatusEnum) ||
          SettingPromotionStatusEnum.ALL
      )
    const [selectedPromotionOrder, setSelectedPromotionOrder] =
      useState<SettingPromotionOrderEnum>(
        (params.get('order') as SettingPromotionOrderEnum) ||
          SettingPromotionOrderEnum.CREATED
      )
    const [searchParams, setSearchParams] =
      useState<PromotionSettingSearchFormType>({
        text,
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
      })

    const [activePromotionTab, setActivePromotionTab] =
      useState<SettingPromotionMenuTypeEnum>(
        (params.get('tab') as SettingPromotionMenuTypeEnum) ||
          SettingPromotionMenuTypeEnum.ALL
      )

    const [activeChallengeTab, setActiveChallengeTab] =
      useState<PromotionChallengeActionEnum>(
        (params.get('challengeTab') as PromotionChallengeActionEnum) ||
          PromotionChallengeActionEnum.ALL
      )

    const { page, perpage, pageChange } = usePagination({
      page: Number(params.get('page')),
    })

    const queryKey: QueryKey = [
      'get-promotions',
      activeChallengeTab,
      searchParams,
      activePromotionTab,
      selectedPromotionShow,
      selectedPromotionOrder,
      page,
      perpage,
    ]

    const { data: isHavePromotioin, isLoading: checkLoading } = useQuery(
      'check-have-promotions',
      () => client?.promotionClient.getTotalPromotions()
    )

    const { data: promotionList, isLoading } = useQuery(queryKey, () =>
      client?.promotionClient.getPromotions({
        limitPerPage: perpage,
        page,
        searchText: searchParams.text,
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        type: activePromotionTab,
        status: selectedPromotionShow,
        sort: selectedPromotionOrder,
        activeTab:
          activePromotionTab === SettingPromotionMenuTypeEnum.CHALLENGE
            ? activeChallengeTab
            : undefined,
      })
    )

    function handleSubmitSearch(value: PromotionSettingSearchFormType) {
      pageChange(1)
      setSearchParams(prev => {
        const newSearchParams = {
          ...prev,
          text: value.text,
          startDate: value.startDate,
          endDate: value.endDate,
        }

        navigate({
          search: `?${createSearchParams({
            ...newSearchParams,
            startDate: newSearchParams.startDate?.toISOString() || '',
            endDate: newSearchParams.endDate?.toISOString() || '',
            tab: activePromotionTab,
            challengeTab: activeChallengeTab,
            show: selectedPromotionShow,
            order: selectedPromotionOrder,
          })}`,
        })
        return newSearchParams
      })
    }

    function handleResetSearch() {
      pageChange(1)
      setSearchParams({
        text: '',
        startDate: undefined,
        endDate: undefined,
      })
      setSelectedPromotionShow(SettingPromotionStatusEnum.ALL)
      setSelectedPromotionOrder(SettingPromotionOrderEnum.CREATED)

      const query = new URLSearchParams()
      query.set('tab', activePromotionTab)
      query.set('challengeTab', activeChallengeTab)

      navigate({
        search: `?${query.toString()}`,
      })
    }

    function onActiveTabChange(value: string) {
      pageChange(1)
      setActivePromotionTab(value as SettingPromotionMenuTypeEnum)

      const query = new URLSearchParams()
      query.set('tab', value)
      query.set('challengeTab', activeChallengeTab)
      query.set('show', selectedPromotionShow)
      query.set('order', selectedPromotionOrder)

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      if (searchParams.startDate && searchParams.endDate) {
        query.set('startDate', searchParams.startDate.toISOString())
        query.set('endDate', searchParams.endDate.toISOString())
      }

      navigate({
        search: `?${query.toString()}`,
      })
    }

    function handleSelectPromotionShow(status: SettingPromotionStatusEnum) {
      pageChange(1)
      setSelectedPromotionShow(status)

      const query = new URLSearchParams()
      query.set('tab', activePromotionTab)
      query.set('challengeTab', activeChallengeTab)
      query.set('show', status)
      query.set('order', selectedPromotionOrder)

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      if (searchParams.startDate && searchParams.endDate) {
        query.set('startDate', searchParams.startDate.toISOString())
        query.set('endDate', searchParams.endDate.toISOString())
      }

      navigate({
        search: `?${query.toString()}`,
      })
    }

    function handleSelectPromotionOrder(sort: SettingPromotionOrderEnum) {
      pageChange(1)
      setSelectedPromotionOrder(sort)

      const query = new URLSearchParams()
      query.set('tab', activePromotionTab)
      query.set('challengeTab', activeChallengeTab)
      query.set('show', selectedPromotionShow)
      query.set('order', sort)

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      if (searchParams.startDate && searchParams.endDate) {
        query.set('startDate', searchParams.startDate.toISOString())
        query.set('endDate', searchParams.endDate.toISOString())
      }

      navigate({
        search: `?${query.toString()}`,
      })
    }

    function handlePageChange(value: number) {
      pageChange(value)

      const query = new URLSearchParams()
      query.set('tab', activePromotionTab)
      query.set('challengeTab', activeChallengeTab)
      query.set('show', selectedPromotionShow)
      query.set('order', selectedPromotionOrder)
      query.set('page', String(value))

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      if (searchParams.startDate && searchParams.endDate) {
        query.set('startDate', searchParams.startDate.toISOString())
        query.set('endDate', searchParams.endDate.toISOString())
      }

      navigate({
        search: `?${query.toString()}`,
      })
    }

    const componentProps = {
      haveData: Boolean(isHavePromotioin),
      menus,
      promotionShow,
      promotionOrder,
      selectedPromotionShow,
      selectedPromotionOrder,
      activePromotionTab,
      searchParams,
      initialValues: searchParams,
      page,
      perpage,
      activeChallengeTab,
      queryKey,
      data: promotionList?.data || [],
      total: promotionList?.total || 0,
      isLoading: isLoading || checkLoading,
      handlePageChange,
      navigate,
      onActiveTabChange,
      handleSubmitSearch,
      handleResetSearch,
      handleSelectPromotionShow,
      handleSelectPromotionOrder,
      setActiveChallengeTab,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
