import { QueryClient } from 'react-query'
import { GraphQLClient } from 'graphql-request'

import { Client } from '@client/Client'
import { API_URL } from '@configs/config'
import { RequestInit } from 'graphql-request/dist/types.dom'
import jwtDecode from 'jwt-decode'
import { getAuth } from '@firebase/auth'

async function middleware(request: RequestInit) {
  const token = localStorage.getItem('token')
  let { headers } = request

  if (token) {
    const jwt: { exp: number } = jwtDecode(token)
    const currentDate = new Date()
    const expireDate = new Date((jwt.exp - 60) * 1000)

    if (expireDate <= currentDate) {
      if (getAuth().currentUser) {
        const newIdToken = await getAuth().currentUser?.getIdToken()
        if (newIdToken) {
          headers = {
            ...headers,
            authorization: `Bearer ${newIdToken}`,
          }
          localStorage.setItem('token', newIdToken)
        }
      }
    } else {
      headers = {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    }
  }

  return {
    ...request,
    headers,
  }
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})
const gqlApiInstance = new GraphQLClient(API_URL, {
  requestMiddleware: middleware,
})
const client = new Client(gqlApiInstance)

export { client, queryClient }
