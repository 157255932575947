import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { CoinDetail } from '../SummaryCoinPage/components/CoinDetail'
import { CoinHistorySearchForm } from './components/CoinHistorySearchForm'
import { CoinHistorySelectTab } from './components/CoinHistorySelectTab'
import { CoinHistoryPageProps } from './interface'

export function CoinHistoryPage({
  searchParam,
  activeTab,
  page,
  perpage,
  total,
  pageChange,
  handleTotalChange,
  handleSubmit,
  onActiveTabChange,
  handleSort,
  exportFile,
}: CoinHistoryPageProps) {
  return (
    <>
      <CoinHistorySearchForm
        initialValues={{
          searchText: '',
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSubmit}
        exportFile={exportFile}
      />
      <CoinDetail searchParam={searchParam} />
      <CoinHistorySelectTab
        searchParam={searchParam}
        activeTab={activeTab}
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
        handleTotalChange={handleTotalChange}
        onActiveTabChange={onActiveTabChange}
        handleSort={handleSort}
      />
    </>
  )
}
