import { SalesReportSearchFormProps } from './interface'

export function withSalesReportSearchForm(
  Component: React.FC<SalesReportSearchFormProps>
) {
  function WithSalesReportSearchForm(props: SalesReportSearchFormProps) {
    return <Component {...props} />
  }

  return WithSalesReportSearchForm
}
