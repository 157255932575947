import { useState } from 'react'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { AgeConfirmResponse } from '@models/ageConfirmation/AgeConfirmResponse'
import { SortingType } from '@components/Table/interface'

interface AgeConfirmProps {
  page: number
  statusType: SearchUserStatusEnum
  searchText: string
  sortKey: string
  sortOrder: SortingType
  handleTotalChange: (newTotal: number) => void
}

export function useAgeConfirmation({
  page,
  statusType,
  searchText,
  sortKey,
  sortOrder,
  handleTotalChange,
}: AgeConfirmProps) {
  const client = useClient()
  const { isUserLoading, isAuthenticated } = useAuthentication()
  const [total, setTotal] = useState({
    all: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  })

  async function allTotalHandler(value: number) {
    const pendingTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.PENDING,
      searchText
    )
    const approvedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.APPROVED,
      searchText
    )
    const rejectedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.REJECTED,
      searchText
    )
    setTotal({
      all: value,
      pending: pendingTotal || 0,
      approved: approvedTotal || 0,
      rejected: rejectedTotal || 0,
    })
  }

  async function pendigTotalHandler(value: number) {
    const allTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      undefined,
      searchText
    )
    const approvedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.APPROVED,
      searchText
    )
    const rejectedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.REJECTED,
      searchText
    )
    setTotal({
      all: allTotal || 0,
      pending: value,
      approved: approvedTotal || 0,
      rejected: rejectedTotal || 0,
    })
  }

  async function approvedTotalHandler(value: number) {
    const allTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      undefined,
      searchText
    )
    const pendingTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.PENDING,
      searchText
    )
    const rejectedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.REJECTED,
      searchText
    )
    setTotal({
      all: allTotal || 0,
      pending: pendingTotal || 0,
      approved: value,
      rejected: rejectedTotal || 0,
    })
  }

  async function rejectedTotalHandler(value: number) {
    const allTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      undefined,
      searchText
    )
    const pendingTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.PENDING,
      searchText
    )
    const approvedTotal = await client?.ageConfirmClient.getTotalAgeVerifyUsers(
      SearchUserStatusEnum.APPROVED,
      searchText
    )
    setTotal({
      all: allTotal || 0,
      pending: pendingTotal || 0,
      approved: approvedTotal || 0,
      rejected: value,
    })
  }

  const queryParams = [
    'age_confirm',
    page,
    statusType,
    searchText,
    sortKey,
    sortOrder,
  ]
  const { data, isLoading } = useQuery(
    queryParams,
    () =>
      client?.ageConfirmClient
        .getAgeVerifyUsers({ page, statusType, searchText, sortKey, sortOrder })
        .then((res: AgeConfirmResponse) => {
          if (statusType === SearchUserStatusEnum.ALL) {
            allTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.PENDING) {
            pendigTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.APPROVED) {
            approvedTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.REJECTED) {
            rejectedTotalHandler(res.total)
          }
          handleTotalChange(res.total)
          return res.data
        }),
    { enabled: !isUserLoading && isAuthenticated }
  )

  return {
    data,
    total,
    isLoading: isLoading || isUserLoading,
  }
}
