import { gql } from 'graphql-request'

export const POPUP_BANNERS = gql`
  query PopupBanners(
    $statuses: [PopupBannerStatus!]
    $screens: [PopupBannerScreenEnum!]
    $page: Int
    $limitPerPage: Int
  ) {
    popupBanners(
      statuses: $statuses
      screens: $screens
      page: $page
      limitPerPage: $limitPerPage
    ) {
      total
      page
      data {
        id
        imgPath
        isActive
        startPublishedAt
        endPublishedAt
        link
        title
        runningNo
      }
    }
  }
`
