import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { DashboardLayoutProps, DashboardTabPathType } from './interface'

const dashboardTabOption = SHOW_EBOOK_FEATURE
  ? [
      {
        text: 'Sales Report',
        value: 'salesReport',
      },
      {
        text: 'App performance',
        value: 'appPerformance',
      },
      {
        text: 'Reader',
        value: 'reader',
      },
      {
        text: 'Writer, Publisher',
        value: 'writerPublisher',
      },
      {
        text: 'Story',
        value: 'story',
      },
      {
        text: 'Ebook',
        value: 'ebook',
      },
      {
        text: 'Donation',
        value: 'donation',
      },
      {
        text: 'Search',
        value: 'search',
      },
    ]
  : [
      {
        text: 'Sales Report',
        value: 'salesReport',
      },
      {
        text: 'App performance',
        value: 'appPerformance',
      },
      {
        text: 'Reader',
        value: 'reader',
      },
      {
        text: 'Writer, Publisher',
        value: 'writerPublisher',
      },
      {
        text: 'Story',
        value: 'story',
      },
      {
        text: 'Donation',
        value: 'donation',
      },
      {
        text: 'Search',
        value: 'search',
      },
    ]

const dashboardTabPath = {
  salesReport: '/dashboard/sales-report',
  appPerformance: '/dashboard/app-performance',
  reader: '/dashboard/reader',
  writerPublisher: '/dashboard/writer-publisher',
  story: '/dashboard/story',
  ebook: '/dashboard/ebook',
  donation: '/dashboard/donation',
  search: '/dashboard/search',
}

export function withDashboardLayout(Component: React.FC<DashboardLayoutProps>) {
  function WithDashboardLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<DashboardTabPathType>('salesReport')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(dashboardTabPath).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as DashboardTabPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function handleChangeTab(type: DashboardTabPathType) {
      setTab(type)
      navigate(dashboardTabPath[type])
    }

    const newProps = {
      dashboardTabOption,
      tab,
      handleChangeTab,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithDashboardLayout
}
