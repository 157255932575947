import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { Overview } from './components/Overview'
import { WritingCetegoryChart } from './components/WritingCetegoryChart'
import { DeviceChart } from './components/DeviceChart'
import { AgeRangeChart } from './components/AgeRangeChart'
import { GenderChart } from './components/GenderChart'
import { NewWriterPopularTable } from './components/NewWriterPopularTable'
import { TopWriterBySalesTable } from './components/TopWriterBySalesTable'
import { TopWriterByViewTable } from './components/TopWriterByViewTable'
import { WriterPublisherPageProps } from './interface'

export function OverviewPage({
  data,
  graphData,
  searchParams,
  handleExport,
  handleReset,
  handleSearch,
}: WriterPublisherPageProps) {
  return (
    <>
      <section className='flex items-center gap-x-[20px]'>
        <SearchDateRange
          className='flex-1'
          onSearch={handleSearch}
          onReset={handleReset}
          onExport={handleExport}
        />
      </section>
      {data && graphData && searchParams && (
        <CardContainer>
          <Overview
            newWriter={data.newWriter}
            totalWriter={data.totalWriter}
            summaryWriterUsage={data.summaryWriterUsage}
            accumulateWriter={data.accumulateWriter}
            graphData={graphData}
          />

          <hr className='my-[30px]' />

          <WritingCetegoryChart data={data.bookCategories} />

          <section className='w-full flex items-start justify-between'>
            <DeviceChart data={data.deviceCategory} />
            <AgeRangeChart data={data.writerAgeCategories} />
            <GenderChart data={data.writerGenderCategories} />
          </section>

          <hr className='my-[30px]' />

          <section className='grid grid-cols-2 gap-x-[80px]'>
            <TopWriterBySalesTable searchParams={searchParams} />
            <TopWriterByViewTable searchParams={searchParams} />
          </section>

          <section className='mt-[40px]'>
            <NewWriterPopularTable searchParams={searchParams} />
          </section>
        </CardContainer>
      )}
    </>
  )
}
