import { NoData } from '@components/NoData'
import { Tabs, Tab, TabBody, TabHeader } from '@components/Tabs'
import { EbookSalesReportEnum } from '@interfaces/EbookSalesReportEnum'
import { Table } from '@components/Table'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { EbookOverviewTabsProps } from './interface'
import { SaleAnalyticsChart } from '../SaleAnalyticsChart'
import {
  ebookReviewColumns,
  ebookWishColumns,
  promotionColumns,
  readTrialColumns,
  salesColumns,
  viewColumns,
} from './columns'

export function EbookOverviewTabs({
  data,
  isLoading,
  sortParams,
  isNoData,
  handleTabChange,
  handleSortChange,
}: EbookOverviewTabsProps) {
  return (
    <div className='mt-[30px]'>
      <CardContainer>
        <Tabs
          initialActiveTab={sortParams.reportType}
          onActiveTabChange={handleTabChange}
          mode='line'
        >
          <Tab tabId={EbookSalesReportEnum.PURCHASE_COUNT}>
            <TabHeader>
              <span>จำนวนที่ขาย</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.PURCHASE_COUNT}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด :{' '}
                      <span className='text-secondary'>จำนวนที่ขาย</span>
                    </div>
                    <Table
                      columns={salesColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      isLoading={isLoading}
                      initialSort={{
                        key: sortParams.sortKey,
                        order: sortParams.sortValue,
                      }}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
          <Tab tabId={EbookSalesReportEnum.PROMOTION}>
            <TabHeader>
              <span>โปรโมชั่น</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.PROMOTION}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด :{' '}
                      <span className='text-secondary'>โปรโมชั่น</span>
                    </div>
                    <Table
                      columns={promotionColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      isLoading={isLoading}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                      className='min-h-[100px] pr-[10px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
          <Tab tabId={EbookSalesReportEnum.VIEW}>
            <TabHeader>
              <span>ยอดวิว</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.VIEW}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด :{' '}
                      <span className='text-secondary'>ยอดวิว</span>
                    </div>

                    <Table
                      columns={viewColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      initialSort={{
                        key: sortParams.sortKey,
                        order: sortParams.sortValue,
                      }}
                      isLoading={isLoading}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                      className='min-h-[100px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
          <Tab tabId={EbookSalesReportEnum.WISH_EBOOK}>
            <TabHeader>
              <span>อีบุ๊กที่อยากได้</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.WISH_EBOOK}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด :{' '}
                      <span className='text-secondary'>อีบุ๊กที่อยากได้</span>
                    </div>

                    <Table
                      columns={ebookWishColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      initialSort={{
                        key: sortParams.sortKey,
                        order: sortParams.sortValue,
                      }}
                      isLoading={isLoading}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                      className='min-h-[100px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
          <Tab tabId={EbookSalesReportEnum.READ_TRIAL_CHAPTERS}>
            <TabHeader>
              <span>ทดลองอ่าน</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.READ_TRIAL_CHAPTERS}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด :{' '}
                      <span className='text-secondary'>ทดลองอ่าน</span>
                    </div>

                    <Table
                      columns={readTrialColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      initialSort={{
                        key: sortParams.sortKey,
                        order: sortParams.sortValue,
                      }}
                      isLoading={isLoading}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                      className='min-h-[100px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
          <Tab tabId={EbookSalesReportEnum.REVIEW_EBOOK}>
            <TabHeader>
              <span>รีวิว</span>
            </TabHeader>
            <TabBody>
              {isNoData ? (
                <NoData />
              ) : (
                <>
                  <SaleAnalyticsChart
                    data={data.graphData}
                    graphType={data.graphType}
                    tabId={EbookSalesReportEnum.REVIEW_EBOOK}
                    style={{
                      height: 300,
                      width: 1000,
                    }}
                  />
                  <div className='mt-[40px]'>
                    <div className=' text-[14px] font-bold text-secondary-100 mb-[20px]'>
                      รายละเอียด : <span className='text-secondary'>รีวิว</span>
                    </div>

                    <Table
                      columns={ebookReviewColumns}
                      data={data.tableData}
                      onSort={handleSortChange}
                      initialSort={{
                        key: sortParams.sortKey,
                        order: sortParams.sortValue,
                      }}
                      isLoading={isLoading}
                      bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
                      className='min-h-[100px]'
                    />
                  </div>
                </>
              )}
            </TabBody>
          </Tab>
        </Tabs>
      </CardContainer>
    </div>
  )
}
