import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { PromotionSaleForm } from '@features/promotionSetting/components/PromotionSaleForm'
import { DuplicatePromotionSalePageProps } from './interface'

export function DuplicatePromotionSalePage({
  initialValues,
  saleCoinOptions,
  createPromotionSale,
  handleLeave,
  isLoading,
}: DuplicatePromotionSalePageProps) {
  return (
    <Container>
      {!isLoading ? (
        initialValues ? (
          <PromotionSaleForm
            initialValues={initialValues}
            handleSubmit={createPromotionSale}
            goBackHandle={handleLeave}
            title='สร้างโปรโมชั่นการขาย'
            saleCoinOptions={saleCoinOptions}
          />
        ) : (
          <div className='m-auto'>เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง</div>
        )
      ) : (
        <Loading size={36} className='m-auto' />
      )}
    </Container>
  )
}
