import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { SaleBookTableRowAcceptProps, SaleBookTableRowProps } from './interface'

const PER_PAGE = 10

function withSaleBookTableRow(Component: React.FC<SaleBookTableRowProps>) {
  function WithSaleBookTableRow({
    data,
    productType,
    userId,
    startDate,
    endDate,
  }: SaleBookTableRowAcceptProps) {
    const client = useClient()
    const [isShowSubRow, setIsShowSubRow] = useState<boolean>(false)

    const {
      fetchNextPage,
      data: saleDetail,
      isFetchingNextPage,
      hasNextPage,
      isLoading,
    } = useInfiniteQuery(
      ['sale-book-detail', data.id, startDate, endDate, productType],
      async ({ pageParam = 1 }) => {
        const res =
          await client!.userWriterClient.getWriterSalesDetailByProductType({
            page: pageParam,
            limitPerPage: PER_PAGE,
            startDate,
            endDate,
            bookIdOrEbookId: data.id,
            userId,
            productType,
          })

        return res
      },
      {
        getNextPageParam: (lastPage: any) => {
          if (lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
        enabled: isShowSubRow,
      }
    )

    function handleFetchNextPage(event: any) {
      const scrollHeight = event.target.scrollHeight - 10
      const scrollTop = event.target.scrollTop + event.target.offsetHeight
      if (scrollHeight <= scrollTop && !isFetchingNextPage && hasNextPage) {
        fetchNextPage()
      }
    }

    const handleToggleShowSubRow = () => {
      setIsShowSubRow(!isShowSubRow)
    }

    const newProps = {
      data,
      saleDetail,
      isShowSubRow,
      isFetchingNextPage,
      isLoading,
      handleToggleShowSubRow,
      handleFetchNextPage,
    }
    return <Component {...newProps} />
  }

  return WithSaleBookTableRow
}
export default withSaleBookTableRow
