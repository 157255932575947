import { Box } from '@components/Box'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterEnum } from '@interfaces/WriterEnum'

import { CircleCheckIcon, WrongRedIcon } from '@components/Icons'
import { TextInput } from './TextInput'
import { ImageViewer } from './ImageViewer'
import { SelectButton } from './SelectButton'
import { useWriterDetail } from '../hooks/useWriterDetail'
import { TextInputVerifyNumber } from './TextInputVerifyNumber'

const writerTypeOptions = [
  { text: 'บุคคล', value: WriterEnum.INDIVIDUAL },
  { text: 'นิติบุคคล', value: WriterEnum.LEGAL },
]

const affiliationOptions = [
  { text: 'สำนักพิมพ์', value: AffiliationEnum.PUBLISHER },
  { text: 'นักเขียน', value: AffiliationEnum.INDIVIDUAL },
]

export function WriterInformation() {
  const { writer, isNumberHasBan, handleValidateNumber } = useWriterDetail()
  return (
    <div>
      <Box.Title>ข้อมูลนักเขียน</Box.Title>
      <div className='grid grid-cols-3 mobile:grid-cols-1 gap-[20px]'>
        <SelectButton
          className='border-none font-mitr'
          label='ประเภท'
          options={writerTypeOptions}
          value={writer?.type}
        />
        <SelectButton
          className='border-none font-mitr'
          label='สังกัด'
          options={affiliationOptions}
          value={writer?.affiliation}
        />
        {writer?.type === WriterEnum.INDIVIDUAL ? (
          <>
            <div className='col-start-1 col-end-2'>
              <TextInput
                label='ชื่อ - นามสกุล'
                placeholder='กรอกชื่อ - นามสกุล'
                value={writer?.fullName}
              />
            </div>
            <div>
              <TextInputVerifyNumber
                label='รหัสประจำตัวประชาชน'
                placeholder='กรอกไม่เว้นวรรค และไม่ใส่ขีด (-)'
                value={writer?.idNumber}
                isVerifyNumber
                isNumberHasBan={!!isNumberHasBan}
                handleValidateNumber={handleValidateNumber}
              />
              {isNumberHasBan === true ? (
                <div className='flex items-center justify-end mt-[5px] text-[12px] text-red-74'>
                  <WrongRedIcon
                    className='pr-[5px]'
                    width='24px'
                    height='24px'
                  />{' '}
                  รหัสประจำตัวประชาชนนี้ถูกแบนถาวร
                </div>
              ) : isNumberHasBan === false ? (
                <div className='flex items-center justify-end mt-[5px] text-[12px] text-green-986'>
                  <CircleCheckIcon
                    className='pr-[5px]'
                    width='24px'
                    height='24px'
                  />{' '}
                  รหัสประจำตัวประชาชนนี้สามารถใช้งานได้
                </div>
              ) : null}
            </div>
            <TextInput
              label='อีเมล'
              placeholder='email@example.com'
              value={writer?.email}
            />
            <TextInput
              label='เบอร์โทรศัพท์'
              placeholder='(+66) กรอกเบอร์โทรศัพท์'
              value={writer?.phoneNumber}
            />
          </>
        ) : (
          <>
            <div className='col-start-1 col-end-2'>
              <TextInput
                label='ชื่อบริษัท'
                placeholder='กรอกชื่อบริษัท'
                value={writer?.companyName || ''}
              />
            </div>
            <div>
              <TextInputVerifyNumber
                label='เลขประจำตัวผู้เสียภาษี'
                placeholder='กรอกไม่เว้นวรรค และไม่ใส่ขีด (-)'
                value={writer?.taxId || ''}
                isVerifyNumber
                isNumberHasBan={!!isNumberHasBan}
                handleValidateNumber={handleValidateNumber}
              />
              {isNumberHasBan === true ? (
                <div className='flex items-center justify-end mt-[5px] text-[12px] text-red-74'>
                  <WrongRedIcon
                    className='pr-[5px]'
                    width='24px'
                    height='24px'
                  />{' '}
                  เลขประจำตัวผู้เสียภาษีนี้ถูกแบนถาวร
                </div>
              ) : isNumberHasBan === false ? (
                <div className='flex items-center justify-end mt-[5px] text-[12px] text-green-986'>
                  <CircleCheckIcon
                    className='pr-[5px]'
                    width='24px'
                    height='24px'
                  />{' '}
                  เลขประจำตัวผู้เสียภาษีนี้สามารถใช้งานได้
                </div>
              ) : null}
            </div>
            <div className='col-start-1 col-end-2'>
              <ImageViewer
                label='หนังสือรับรอง (ไฟล์ pdf, ไฟล์ภาพ jpg หรือ png)'
                className='w-[140px] h-[200px]'
                src={writer?.companyCertificateImgPath || ''}
                name={writer?.companyCertificateImgPath}
              />
            </div>
            <ImageViewer
              label='ภพ.20 (ไฟล์ pdf, ไฟล์ภาพ jpg หรือ png)'
              className='w-[280px] h-[200px]'
              src={writer?.vatRegistrationCertificateImgPath || ''}
              name={writer?.vatRegistrationCertificateImgPath}
            />
          </>
        )}
      </div>
    </div>
  )
}
