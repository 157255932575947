import { gql } from 'graphql-request'

export const CHARACTER_DONATION_BY_CATEGORY = gql`
  query CharacterDonationByCategory($dateRange: DatePicker) {
    characterDonationByCategory(dateRange: $dateRange) {
      categoryId
      categoryName
      totalDonation
    }
  }
`
