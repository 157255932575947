import { SortByType } from '@components/Table/interface'
import { TopSpenderType } from '@models/coin/TopSpenderType'

export interface TopSpenderTableProps {
  data: TopSpenderType[]
  isLoading: boolean
  title: string
  height?: string
  spenderType: SpenderTypeEnum
  sort: SortByType
  handleSort: (sort: SortByType) => void
}

export enum SpenderTypeEnum {
  MONEY = 'MONEY',
  COIN = 'COIN',
}
