import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'

import { SortingType } from '@components/Table/interface'
import { BanUserImageEnum } from '@interfaces/user/BanUserImageEnum'
import { SystemCoinType } from '@models/coin/SystemCoinType'
import { UserCoinHistoriesResponse } from '@models/coin/UserCoinHistoriesResponse'
import { UserCoinLogResponse } from '@models/coin/UserCoinLogResponse'
import { FilterUserManagement } from '@models/filter/FilterUserManagement'
import { BanUserResponse } from '@models/user/BanUserResponse'
import { UserManagementResponse } from '@models/user/UserManagementResponse'
import { UserManagementTotalResponse } from '@models/user/UserManagementTotalResponse'
import { PunishmentEnum } from '@interfaces/user/PunishmentEnum'
import { PunishmentPeriodEnum } from '@interfaces/user/PunishmentPeriodEnum'
import { UserPunishmentHistoryResponse } from '@models/user/UserPunishmentHistoryResponse'
import { UserPunishmentTotalResponse } from '@models/user/UserPunishmentTotalResponse'
import { BAN_USER } from './schemas/banUser'
import { CREATE_BAN_IMAGE_LOG } from './schemas/createBanImageLog'
import { GET_ALL_USER_MANAGEMENTS } from './schemas/getAllUserManagements'
import { GET_TOTAL_USER_MANAGEMENTS } from './schemas/getTotalUserManagement'
import { GET_USER_COIN_HISTORIES } from './schemas/getUserCoinHistories'
import { GET_USER_COIN_LOGS } from './schemas/getUserCoinLogs'
import { GET_USER_MANAGE_COIN } from './schemas/getUserManagementCoins'
import { RESET_USER_PASSWORD } from './schemas/resetUserPassword'
import { TOGGLE_WRITING_DISABLED } from './schemas/toggleWritingDisabled'
import { CREATE_USER_PUNISHMENT } from './schemas/createUserPunishment'
import { GET_USER_PUNISHMENT_HISTORY } from './schemas/getUserPunishmentHistory'
import { UN_BAN_USER } from './schemas/unBanUser'
import { GET_TOTAL_USER_PUNISHMENT } from './schemas/getUserPunishmentTableCount'
import { RESET_PIN_CODE } from './schemas/resetPinCode'
import { EXPORT_USER_MANAGEMENT } from './schemas/exportUserManagement'

export class UserManagementClient {
  constructor(private client: GraphQLClient) {}

  async getAllUserManagements(
    query: FilterUserManagement
  ): Promise<UserManagementResponse> {
    const key =
      query.sortKey === 'fullNamePublisher' ||
      query.sortKey === 'fullNameWriter'
        ? 'displayName'
        : query.sortKey

    const { getAllUserManagements } = await this.client.request(
      GET_ALL_USER_MANAGEMENTS,
      {
        limitPerPage: query.limitPerPage,
        page: query.page,
        userType: query.userType,
        searchText: query.searchText,
        orderBy: { [key]: query.sortOrder },
      }
    )
    return plainToInstance(UserManagementResponse, getAllUserManagements)
  }

  async getTotalUserManagement(
    searchText: string
  ): Promise<UserManagementTotalResponse> {
    const { getUserManagementTableCount } = await this.client.request(
      GET_TOTAL_USER_MANAGEMENTS,
      {
        searchText,
        isIncludePenName: true,
      }
    )
    return plainToInstance(
      UserManagementTotalResponse,
      getUserManagementTableCount
    )
  }

  async getUserCoinLogs({
    id,
    page,
    perpage,
    sortKey,
    orderBy,
    startDate,
    endDate,
  }: {
    id: number
    page: number
    perpage: number
    sortKey: string
    orderBy?: SortingType
    startDate: Date | null
    endDate: Date | null
  }): Promise<UserCoinLogResponse> {
    const { userCoinLogs } = await this.client.request(GET_USER_COIN_LOGS, {
      limitPerPage: perpage,
      page,
      orderBy: { [sortKey]: orderBy },
      userId: id,
      startDate: startDate ? DateTime.fromJSDate(startDate).toUTC() : undefined,
      endDate: endDate ? DateTime.fromJSDate(endDate).toUTC() : undefined,
    })

    return plainToInstance(UserCoinLogResponse, userCoinLogs)
  }

  async getUserCoinHistories({
    userId,
    limitPerPage,
    page,
    sortKey,
    orderBy,
    startDate,
    endDate,
  }: {
    userId: number
    limitPerPage: number
    page: number
    sortKey: string
    orderBy: SortingType
    startDate: Date | null
    endDate: Date | null
  }): Promise<UserCoinHistoriesResponse> {
    const { userCoinHistories } = await this.client.request(
      GET_USER_COIN_HISTORIES,
      {
        userId,
        limitPerPage,
        page,
        startDate,
        endDate,
        orderBy: {
          [sortKey]: orderBy,
        },
      }
    )
    return plainToInstance(UserCoinHistoriesResponse, userCoinHistories)
  }

  async resetUserPassword(userId: number): Promise<void> {
    await this.client.request(RESET_USER_PASSWORD, {
      resetPasswordInput: {
        userId,
      },
    })
  }

  async banUser(userId: number): Promise<BanUserResponse> {
    const { banUser } = await this.client.request(BAN_USER, { userId })
    return banUser
  }

  async toggleWritingDisabled(userId: number): Promise<void> {
    await this.client.request(TOGGLE_WRITING_DISABLED, {
      userId,
    })
  }

  async getUserManagementCoins({
    userId,
  }: {
    userId: number
  }): Promise<SystemCoinType[]> {
    const { userManagementCoins } = await this.client.request(
      GET_USER_MANAGE_COIN,
      {
        userId,
      }
    )
    return plainToInstance(SystemCoinType, userManagementCoins as [])
  }

  async createBanImageLog({
    userId,
    type,
    imageUrl,
    characterId,
    bookId,
    bookChapterId,
  }: {
    userId: number
    type: BanUserImageEnum
    imageUrl: string
    characterId?: number
    bookId?: number
    bookChapterId?: number
  }): Promise<void> {
    await this.client.request(CREATE_BAN_IMAGE_LOG, {
      createBanImageLogInput: {
        userId,
        type,
        imageUrl,
        characterId,
        bookId,
        bookChapterId,
      },
    })
  }

  async createUserPunishment({
    userId,
    operatedId,
    message,
    period,
    punishmentType,
  }: {
    userId: number
    operatedId: number
    message: string
    period?: PunishmentPeriodEnum
    punishmentType: PunishmentEnum
  }): Promise<void> {
    await this.client.request(CREATE_USER_PUNISHMENT, {
      createUserPunishmentInput: {
        userId,
        operatedId,
        message,
        period,
        punishmentType,
      },
    })
  }

  async getUserPunishmentHistory({
    userId,
    limitPerPage,
    page,
    punishmentType,
    key,
    order,
  }: {
    userId: number
    limitPerPage: number
    page: number
    punishmentType: PunishmentEnum
    key: string
    order: string
  }): Promise<UserPunishmentHistoryResponse> {
    const { getUserPunishmentHistory } = await this.client.request(
      GET_USER_PUNISHMENT_HISTORY,
      {
        limitPerPage,
        page,
        userId,
        orderBy: key ? { [key]: order } : undefined,
        punishmentType,
      }
    )
    return plainToInstance(
      UserPunishmentHistoryResponse,
      getUserPunishmentHistory
    )
  }

  async unBanUser(userId: number): Promise<void> {
    await this.client.request(UN_BAN_USER, {
      userId,
    })
  }

  async getUserPunishmentTotalCount(
    userId: number
  ): Promise<UserPunishmentTotalResponse> {
    const { getUserPunishmentTableCount } = await this.client.request(
      GET_TOTAL_USER_PUNISHMENT,
      {
        userId,
      }
    )
    return plainToInstance(
      UserPunishmentTotalResponse,
      getUserPunishmentTableCount
    )
  }

  async resetPinCode(userId: number): Promise<void> {
    await this.client.request(RESET_PIN_CODE, {
      userId,
    })
  }

  async exportUserManagement(): Promise<void> {
    await this.client.request(EXPORT_USER_MANAGEMENT)
  }
}
