import { useRef } from 'react'
import cn from 'classnames'

import { useDraggable } from '@hooks/draggableScroll/useDraggable'
import { OptionType } from '@interfaces/OptionType'
import { SelectButtonLineProps } from './interface'

export function SelectButtonLine({
  className,
  options,
  value,

  buttonClassName,
  onChange,
}: SelectButtonLineProps) {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref)

  return (
    <div className='relative text-[14px] text-secondary-100 font-light mobile:overflow-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
      <div
        className={cn(
          'flex overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar',
          className
        )}
        {...events}
        ref={ref}
      >
        {options.map((option: OptionType) => (
          <div
            className={cn(
              'flex px-[20px] py-[9px] items-center justify-center whitespace-pre cursor-pointer  h-[40px] w-max',
              {
                ' border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  value === option.value,
                ' text-secondary-100': value !== option.value,
              },
              buttonClassName
            )}
            key={`${option.value}`}
            onClick={() => onChange && onChange(option.value)}
          >
            {option.icon && <div className='mr-[10px]'>{option.icon}</div>}
            {option.text}
            {typeof option.total === 'number' && (
              <span className='px-[2px]'> ({option.total}) </span>
            )}
          </div>
        ))}
      </div>
      <div className={cn('absolute w-full h-[1px] bottom-[1px] bg-gray z-0')} />
    </div>
  )
}
