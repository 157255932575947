import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import { Button } from '@components/Button'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { promotionRequestActionText } from '@libs/promotion/utils'
import { PromotionSaleHistoryUserInfoProp } from './interface'

export function PromotionSaleHistoryUserInfo({
  data,
  approvedLoading,
  handleReject,
  handleApprove,
}: PromotionSaleHistoryUserInfoProp) {
  const permission = usePermissionAction('promotionSetting')
  return (
    <div className='flex justify-between items-center pt-[20px]'>
      <div className='flex items-center space-x-[10px]'>
        <span className='text-secondary-100 font-bold text-sm'>
          ดำเนินการโดย :
        </span>
        {data.createdBy.avatarImgPath ? (
          <img
            className='w-[50px] h-[50px] rounded-full'
            src={data.createdBy.avatarImgPath}
            alt='User cover'
          />
        ) : (
          <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
        )}
        <div className='space-y-1 text-sm'>
          <p className='text-secondary font-bold'>
            {data.createdBy.displayName}
          </p>
          <p className='text-secondary-100 font-normal'>
            {DateTime.fromISO(data.createdAt)
              .setLocale('th')
              .toFormat('dd  LLL yyyy HH:mm')}
          </p>
        </div>
      </div>
      <div className='text-sm font-bold text-primary'>
        {promotionRequestActionText(data.action)}
      </div>
      {data.status !== PromotionRequestStatusEnum.PENDING && (
        <div className='flex items-center space-x-[10px]'>
          <span className='text-secondary-100 font-bold text-sm'>
            {data.status === PromotionRequestStatusEnum.APPROVED &&
              'อนุมัติโดย :'}
            {data.status === PromotionRequestStatusEnum.REJECTED && 'Reject :'}
          </span>
          {data.approvedBy.avatarImgPath ? (
            <img
              className='w-[50px] h-[50px] rounded-full'
              src={data.approvedBy.avatarImgPath}
              alt='User cover'
            />
          ) : (
            <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
          )}
          <div className='space-y-1 text-sm'>
            <p className='text-secondary font-bold'>
              {data.approvedBy.displayName}
            </p>
            <p className='text-secondary-100 font-normal'>
              {DateTime.fromISO(data.updatedAt)
                .setLocale('th')
                .toFormat('dd  LLL yyyy HH:mm')}
            </p>
          </div>
        </div>
      )}
      {data.status === PromotionRequestStatusEnum.PENDING &&
        (permission.approve ? (
          <div className='flex items-center space-x-5'>
            <Link
              to={`/promotion-setting/promotion-sale/${data.id}/form-detail`}
            >
              <Button
                type='button'
                className='px-0 text-secondary font-mitr text-sm font-medium'
              >
                <span>ดูรายละเอียด</span>
              </Button>
            </Link>
            <Button
              type='button'
              onClick={handleReject}
              className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
            >
              <span>Reject</span>
            </Button>
            <Button
              type='button'
              disabled={approvedLoading}
              onClick={handleApprove}
              className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
            >
              <span>Approve</span>
            </Button>
          </div>
        ) : (
          <div className='flex items-center font-light text-primary'>
            (รอการอนุมัติ)
          </div>
        ))}
    </div>
  )
}
