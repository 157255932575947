import React, { useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import {
  OrderByEnum,
  PopularSearchTextSectionSortParam,
  SearchPopularBy,
} from '@interfaces/dashboard/PopularSearchTextSectionParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import {
  WithPopularSearchTextByTypeCardProps,
  PopularSearchTextByTypeCardProps,
} from './interface'

export function withPopularTextSearchSection(
  Component: React.FC<PopularSearchTextByTypeCardProps>
) {
  function WithPopularTextSearchSection({
    searchParam,
    type,
  }: WithPopularSearchTextByTypeCardProps) {
    const client = useClient()
    const [searchText, setSearchText] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)
    const [popularSearchTextParam, setPopularSearchTextParam] =
      useState<PopularSearchTextSectionSortParam>({
        orderBy: OrderByEnum.SEARCH_COUNT,
        orderDirection: SortingType.DESC,
        searchBy: SearchPopularBy.ALL,
      })

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        [
          `popular-search-text-section`,
          type,
          searchParam,
          searchText,
          popularSearchTextParam,
        ],
        async ({ pageParam = 1 }) => {
          const params = {
            orderBy: popularSearchTextParam.orderBy,
            orderDirection: popularSearchTextParam.orderDirection,
            searchBy: type,
            searchText,
            page: pageParam,
            ...searchParam,
          }

          return client?.dashboardClient.popularSearchText(params)
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setPopularSearchTextParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
        searchBy: type,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const handleOnSearch = (text: string) => {
      setSearchText(text)
    }
    const handleOnReset = () => {
      setSearchText('')
    }

    const componentProps = {
      data,
      isLoading,
      handleSortChange,
      handleWaypointEnter,
      type,
      inputRef,
      onSearch: handleOnSearch,
      onReset: handleOnReset,
    }

    return <Component {...componentProps} />
  }

  return WithPopularTextSearchSection
}
