import { Expose, Transform } from 'class-transformer'

export class SystemCoinType {
  @Expose() coinId: number

  @Expose() coinName: string

  @Expose() coinImgPath: string

  @Expose() received: number

  @Expose() used: number

  @Expose() remain: number

  @Expose() expired: number

  @Expose() sell: number

  @Expose()
  @Transform(
    ({ obj }) => !obj.isExpireByActivity && !obj.isExpireByPaid && !obj.editable
  )
  isGoldCoin: boolean

  @Expose()
  @Transform(
    ({ obj }) => obj.isExpireByActivity && obj.isExpireByPaid && !obj.editable
  )
  isSilverCoin: boolean

  @Expose()
  @Transform(
    ({ obj }) => obj.isExpireByActivity && obj.isExpireByPaid && obj.editable
  )
  isOtherCoin: boolean

  @Expose()
  @Transform(({ obj }) => `เหรียญ${obj.coinName}ที่ขายไปแล้ว`)
  sellText: string

  @Expose()
  @Transform(({ obj }) => `เหรียญ${obj.coinName}ที่หมดอายุไปแล้ว`)
  expiredText: string

  @Expose()
  @Transform(({ obj }) => `เหรียญ${obj.coinName}คงเหลือทั้งหมด`)
  remainText: string

  @Expose()
  @Transform(({ obj }) => `เหรียญ${obj.coinName}ที่ใช้ไปทั้งหมด`)
  usedText: string

  @Expose()
  @Transform(({ obj }) => `เหรียญ${obj.coinName}ที่แจกไปแล้ว`)
  receivedText: string
}
