import { gql } from 'graphql-request'

export const COIN_REQUESTS = gql`
  query CoinRequests(
    $limitPerPage: Int
    $page: Int
    $orderBy: FindAllCoinRequestOrderBy
  ) {
    coinRequests(limitPerPage: $limitPerPage, page: $page, orderBy: $orderBy) {
      data {
        id
        newCoins {
          id
          name
          imgPath
          amountCoin
          amountCoinPerValue
          valueUnitType
          valueUnitCoin {
            id
            name
            imgPath
          }
          isExpireByActivity
          isExpireByPaid
          expireWithinMonthsByActivity
          expireWithinMonthsByPaid
          visible
          status
        }
        status
        createdBy {
          id
          avatarImgPath
          displayName
        }
        approvedBy {
          id
          avatarImgPath
          displayName
        }
        createdAt
      }
      total
      page
    }
  }
`
