import { withPermissionGuard } from '@libs/permission'
import { CreateMainBannerPage } from './CreateMainBannerPage'
import { withCreateMainBannerPage } from './withCreateMainBannerPage'

const ConnectedCreateMainBannerPage = withPermissionGuard(
  withCreateMainBannerPage(CreateMainBannerPage),
  'homePageManagement',
  'create'
)

export { ConnectedCreateMainBannerPage as CreateMainBannerPage }
