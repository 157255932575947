import { Expose, Type } from 'class-transformer'

import { LogType } from './LogType'

export class PopupBannerLogResponse {
  @Expose()
  @Type(() => LogType)
  data: LogType[]

  @Expose() total: number

  @Expose() page: number
}
