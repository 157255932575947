import { gql } from 'graphql-request'

export const EBOOK_OVERVIEW_BY_CATEGORY = gql`
  query EbookDashboardOverviewSalesByCategory(
    $startDate: DateTime
    $endDate: DateTime
    $ebookTypes: [BookType!]
  ) {
    ebookDashboardOverviewSalesByCategory(
      startDate: $startDate
      endDate: $endDate
      ebookTypes: $ebookTypes
    ) {
      id
      name
      totalSales
    }
  }
`
