import { useMemo } from 'react'

import { SearchInput } from '@components/SearchInput'
import { ReportStatusEnum } from '@interfaces/ReportStatusEnum'
import { TabModeEnum } from '@components/Tabs/interface'
import { Tabs } from '@components/TabsV2'
import { Typography } from '@features/sales/components/Typography'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { ReportListTable } from '../ReportListTable'
import { ReportStoryProps } from './interface'

export function ReportStory({
  activeTab,
  textSearch,
  inputRef,
  totalEbookBanned,
  onSearch,
  handleReset,
  handleTabChange,
}: ReportStoryProps) {
  const tabs = useMemo(() => {
    const arr = [
      {
        key: ReportStatusEnum.ALL,
        title: 'ทั้งหมด',
        component: (
          <ReportListTable
            key={`${activeTab}_${ReportStatusEnum.ALL}`}
            textSearch={textSearch}
            tabId={ReportStatusEnum.ALL}
            activeTab={activeTab}
          />
        ),
      },
      {
        key: ReportStatusEnum.WAITING,
        title: 'รอการตรวจสอบ',
        component: (
          <ReportListTable
            key={`${activeTab}_${ReportStatusEnum.WAITING}`}
            textSearch={textSearch}
            tabId={ReportStatusEnum.WAITING}
            activeTab={activeTab}
          />
        ),
      },
      {
        key: ReportStatusEnum.REPORTED,
        title: 'แจ้งนักเขียนแล้ว',
        component: (
          <ReportListTable
            key={`${activeTab}_${ReportStatusEnum.REPORTED}`}
            textSearch={textSearch}
            tabId={ReportStatusEnum.REPORTED}
            activeTab={activeTab}
          />
        ),
      },
      {
        key: ReportStatusEnum.SUCCESS,
        title: 'ดำเนินการสำเร็จ',
        component: (
          <ReportListTable
            key={`${activeTab}_${ReportStatusEnum.SUCCESS}`}
            textSearch={textSearch}
            tabId={ReportStatusEnum.SUCCESS}
            activeTab={activeTab}
          />
        ),
      },
    ]

    if (activeTab === ReportBookEnum.STORY) return arr

    return [
      ...arr,
      {
        key: ReportStatusEnum.BANED,
        title: 'อีบุ๊กที่ถูกแบน',
        component: (
          <ReportListTable
            key={`${activeTab}_${ReportStatusEnum.BANED}`}
            textSearch={textSearch}
            tabId={ReportStatusEnum.BANED}
            activeTab={activeTab}
          />
        ),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, textSearch])

  return (
    <>
      <SearchInput
        ref={inputRef}
        className='flex-1 text-primary mt-[20px] mb-[30px]'
        placeholder='ค้นหาผู้รายงาน,งานเขียน, สำนักพิมพ์, นักเขียน, นามปากกา หรืออื่น ๆ'
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onSearch((event.target as HTMLInputElement).value)
          }
        }}
        onReset={handleReset}
      />
      {activeTab === ReportBookEnum.EBOOK && (
        <Typography
          className='text-[14px] text-secondary-100 mb-[30px]'
          label='จำนวนอีบุ๊กที่ถูกแบน'
          text={
            <div className='text-[24px] font-bold text-secondary'>
              {totalEbookBanned.toLocaleString()}
            </div>
          }
        />
      )}
      <Tabs
        tabs={tabs}
        initialTab={ReportStatusEnum.ALL}
        mode={TabModeEnum.LINE}
        handleTabChange={handleTabChange}
        queryTabKey='subtabs'
      />
    </>
  )
}
