import type { FileType } from '@components/UploadFileUserIdField/interface'
import { CodeChannelTypeEnum } from '@interfaces/promotionCode/CodeChannelTypeEnum '
import { CoinCoditionEnum } from '@interfaces/promotionCode/CoinCoditionEnum'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { UpdateTypeEnum } from '@interfaces/promotionCode/UpdateTypeEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { PromotionLastRequestType } from './PromotionLastRequestType'

export class PromotionCodeFormType {
  @Expose() id?: number

  @Expose()
  @Transform(({ obj }) => obj.promotionCode?.id)
  promotionCodeId?: number

  @Expose({ name: 'name' }) promotionName: string

  @Expose({ name: 'description' }) promotionDetail?: string

  @Expose({ name: 'userTerm' }) promotionCondition?: string

  @Expose() publishedType: PromotionPublishedEnum

  @Expose()
  @Transform(({ obj }) => {
    const { name } = obj.promotionCode.channels[0]
    if (name === 'Shopee') return CodeChannelTypeEnum.SHOPEE
    if (name === 'Lazada') return CodeChannelTypeEnum.LAZADA
    return CodeChannelTypeEnum.OTHER
  })
  codeChannelType: CodeChannelTypeEnum

  @Expose()
  @Transform(({ obj }) => {
    const { name } = obj.promotionCode.channels[0]
    if (name !== 'Shopee' && name !== 'Lazada') return name
    return ''
  })
  codeChannelSelect: string

  @Expose()
  @Transform(({ value }) => value || '')
  codePurchase?: string

  @Expose()
  @Transform(({ obj }) => {
    const { totalCount } = obj.promotionCode.channels[0]
    return totalCount || 0
  })
  codeChannelInput: number

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : new Date()))
  startPublishedAt: Date

  @Expose()
  @Transform(({ obj }) =>
    obj.publishedType === PromotionPublishedEnum.NOW
      ? obj.startPublishedAt
        ? new Date(obj.startPublishedAt)
        : undefined
      : undefined
  )
  startNowAt?: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  endPublishedAt: Date

  @Expose({ name: 'userType' }) forUserType: PromotionUserEnum

  @Expose() isFirstTopUp: boolean

  @Expose({ name: 'isUsageLimitPerUser' })
  @Transform(({ value }) =>
    value ? LimitToJoinEnum.LIMIT : LimitToJoinEnum.UNLIMIT
  )
  limitJoinPromotionType: LimitToJoinEnum

  @Expose({ name: 'usageLimitPerUser' })
  @Transform(({ value }) => value || 1)
  limitJoinPromotion: number

  @Expose({ name: 'isUsageLimit' }) isLimitPromotionCode: boolean

  @Expose({ name: 'usageLimit' })
  @Transform(({ value }) => value || 0)
  limitPromotionCode: number

  @Expose({ name: 'usageDay' }) availableDay: DaySelectEnum[]

  @Expose()
  @Transform(({ obj }) => obj.promotionCode.paymentChannels)
  paymentMethod: PaymentMethodEnum[]

  @Expose()
  @Transform(({ obj }) => obj.promotionCode.type)
  userByType: UserByEnum

  @Expose()
  @Transform(
    ({ obj }) => obj?.users?.map((user: { id: number }) => user.id) || []
  )
  userIds: number[]

  @Expose()
  @Transform(({ obj }) =>
    obj?.usersFileName
      ? undefined
      : obj?.users?.map((user: { id: number }) => user.id).toString() || ''
  )
  userIdInput: string

  @Expose() userIdInputError?: string

  @Expose()
  @Transform(({ obj }) =>
    obj?.usersFileName && obj?.usersFilePath
      ? { url: obj.usersFilePath, name: obj.usersFileName }
      : undefined
  )
  userIdsFile?: FileType

  @Expose() userIdsFileError?: string

  @Expose()
  @Transform(
    ({ obj }) =>
      obj.promotionCode.buyCoinCondition || CoinCoditionEnum.GREATER_THAN
  )
  userBuyCoinCondition: CoinCoditionEnum

  @Expose()
  @Transform(({ obj }) => obj.promotionCode.buyCoinConditionValue || 0)
  userBuyCoinInput: number

  @Expose()
  @Transform(({ obj }) => {
    if (
      obj.promotionCode.type === UserByEnum.BUY_COIN &&
      obj.promotionCode.coin
    ) {
      return String(obj.promotionCode.coin.id)
    }
    return ''
  })
  moreCoinOption: string

  @Expose()
  @Transform(({ obj }) => {
    if (obj.promotionCode.type === UserByEnum.BUY_COIN) {
      return obj.promotionCode.benefitValue
    }
    return 0
  })
  moreCoinInput: number

  @Expose()
  @Transform(({ obj }) => {
    if (
      obj.promotionCode.type === UserByEnum.REDEEM &&
      obj.promotionCode.coin
    ) {
      return String(obj.promotionCode.coin.id)
    }
    return ''
  })
  directCoinOption: string

  @Expose()
  @Transform(({ obj }) => {
    if (obj.promotionCode.type === UserByEnum.REDEEM) {
      return obj.promotionCode.benefitValue
    }
    return 0
  })
  directCoinInput: number

  @Expose()
  @Transform(({ obj }) => obj.promotionCode.coin?.name || '')
  coinName?: string

  @Expose()
  @Transform(({ obj }) => {
    const { name } = obj.promotionCode.channels[0]
    return name
  })
  channelName?: string

  @Expose({ name: 'status' }) promotionStatus?: PromotionStatusEnum

  @Expose() businessId?: string

  @Expose() createdAt?: string

  @Expose() updatedAt?: string

  @Expose() deletedAt?: string

  @Expose() isActive?: boolean

  @Expose() isEditable?: boolean

  @Expose() generateCouponStatus?: PromotionCodeJobStatusEnum

  @Expose()
  @Type(() => PromotionLastRequestType)
  lastRequest?: PromotionLastRequestType

  @Expose() updatedBy?: {
    url: string
    displayName: string
    updatedAt: Date
    updatedType: UpdateTypeEnum
  }

  @Expose()
  @Transform(({ obj }) => {
    const { totalUsedCount } = obj.promotionCode.channels[0]
    return totalUsedCount || 0
  })
  codeChannelTotalUsedCount: number
}
