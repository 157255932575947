import { useModal } from '@hooks/contexts/ModalContext/ModalContext'

export function PennameCell({ penname }: { penname: string[] }) {
  const modal = useModal({ modal: 'pennameModal' })
  function showPennameDialog() {
    modal.show({
      penname,
    })
  }
  return (
    <>
      {penname.length > 0 ? (
        <div className='grid grid-cols-[80px_70px] items-center gap-x-[12px]'>
          <div className='truncate'>{penname[0]}</div>
          {penname.length > 1 && (
            <div className='flex'>
              <button
                className='bg-white border font-light border-blue-2 rounded-[8px] px-[6px] py-[3px] text-secondary-100'
                type='button'
                onClick={event => {
                  event.stopPropagation()
                  showPennameDialog()
                }}
              >
                +{penname.length - 1}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>-</p>
      )}
    </>
  )
}
