import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { SalesByUserTableProps } from './interface'
import { salesByUserColumns } from './column'

export function SalesByUserTable({
  data,
  isLoading,
  activeTab,
  searchParams,
  handleSort,
  refetchAllSalesByUser,
}: SalesByUserTableProps) {
  return (
    <div>
      <Table
        data={data}
        columns={salesByUserColumns(activeTab, refetchAllSalesByUser)}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: searchParams.sortKeyAllSaleByUser,
          order: searchParams.sortOrderAllSaleByUser,
        }}
      />
    </div>
  )
}
