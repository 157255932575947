import { gql } from 'graphql-request'

export const POPUP_BANNER = gql`
  query PopupBanner($id: Int!) {
    popupBanner(id: $id) {
      id
      imgPath
      title
      link
      screen
      platforms
      publishedType
      startPublishedAt
      endPublishedAt
      userTypes
    }
  }
`
