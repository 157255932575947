import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PopupBannerLayoutProps, PopupBannerPathType } from './interface'

const popupBannerTabOption = [
  {
    text: 'จัดการ Pop-up',
    value: 'popupBannerManagement',
  },
  {
    text: 'รายละเอียด Pop up',
    value: 'popupBannerDetail',
  },
]
const popupBannerPathType = {
  popupBannerManagement: '/home-page/pop-up/pop-up-management',
  popupBannerDetail: '/home-page/pop-up/pop-up-detail',
}

export function withPopupBannerLayout(
  Component: React.FC<PopupBannerLayoutProps>
) {
  function WithPopupBannerLayout(props: FunctionComponentType) {
    const [tab, setTab] = useState<PopupBannerPathType>('popupBannerManagement')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const match = Object.entries(popupBannerPathType).find(
        ([, value]) => value === location.pathname
      )
      if (match) {
        setTab(match[0] as PopupBannerPathType)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function handleChangeTab(type: PopupBannerPathType) {
      setTab(type)
      navigate(popupBannerPathType[type])
    }

    const newProps = {
      ...props,
      popupBannerTabOption,
      tab,
      handleChangeTab,
      navigate,
    }
    return <Component {...newProps} />
  }

  return WithPopupBannerLayout
}
