export enum PromotionCodeJobStatusEnum {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export const PromotionCodeJobTypeValue = {
  [PromotionCodeJobStatusEnum.PROCESSING]: 'สร้างโค้ด',
  [PromotionCodeJobStatusEnum.COMPLETED]: 'สร้างโค้ดแล้ว',
  [PromotionCodeJobStatusEnum.FAILED]: 'สร้างโค้ดไม่สำเร็จ',
}
