import React from 'react'
import cn from 'classnames'

import { Container } from '@features/promotionSetting/components/Container'
import { Card } from '@features/promotionSetting/components/Card'
import {
  promotionChallengeActionText,
  promotionChallengeBenefitLimitTypeText,
  promotionChallengeBookTypeText,
  promotionChallengeReadConditionText,
} from '@libs/promotion/utils'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { capitalizeFirstLetter } from '@libs/utils'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionChallengeContentProps } from './interface'

export function PromotionChallengeContent({
  isDisableStatus,
  description,
  userTerm,
  promotionChallenge,
  isExpand,
}: PromotionChallengeContentProps) {
  return (
    <>
      {isExpand ? (
        <>
          <div
            className={cn(
              'grid grid-cols-[150px_1fr] gap-y-[25px] text-[14px]',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
              รายละเอียด :
            </span>
            <span className='break-word'>{description || '-'}</span>
            <span className='text-[12px] font-light text-secondary-100'>
              เงื่อนไขการใช้งาน :
            </span>
            <div className='grid grid-col-1 whitespace-pre-wrap break-word'>
              <span>{userTerm || '-'}</span>
            </div>
          </div>
          <Container.Hr />
          <div
            className={cn(
              'grid grid-cols-[150px_200px_200px_6fr] gap-y-[25px] text-[14px] items-center',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[12px] font-light text-secondary-100'>
              Action :
            </span>
            <span>
              {promotionChallengeActionText(promotionChallenge?.action)}
            </span>
            {promotionChallenge?.action !==
              PromotionChallengeActionEnum.FINISH_PROFILE && (
              <div className='text-[14px] font-light text-secondary-100'>
                จำนวน :
                <span
                  className={cn('px-[10px] text-secondary', {
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  })}
                >
                  {promotionChallenge?.actionValue}
                </span>
                {promotionChallenge?.readCondition
                  ? promotionChallengeReadConditionText(
                      promotionChallenge?.readCondition
                    )
                  : promotionChallenge?.coinsCondition
                  ? 'เหรียญขึ้นไป'
                  : 'ครั้ง'}
              </div>
            )}
            {promotionChallenge?.action ===
              PromotionChallengeActionEnum.COMMENT && (
              <div
                className={cn('text-[14px] font-light', {
                  'text-secondary': isDisableStatus,
                  'text-secondary-100': !isDisableStatus,
                })}
              >
                จำนวนตัวอักษร : &nbsp;
                {promotionChallenge?.wordCount.toLocaleString() || '-'}
                &nbsp;
                <span className='text-secondary-100'>ตัวอักษร</span>
              </div>
            )}
            {(promotionChallenge?.action ===
              PromotionChallengeActionEnum.UNLOCK_CHAPTER ||
              promotionChallenge?.action ===
                PromotionChallengeActionEnum.DONATE ||
              promotionChallenge?.action ===
                PromotionChallengeActionEnum.SET_PRICE_CHAPTER ||
              promotionChallenge?.action ===
                PromotionChallengeActionEnum.CREATE_BOOK) && (
              <div className='font-light space-x-[10px]'>
                <span className='text-sm text-secondary'>
                  {promotionChallenge.coinsCondition
                    ?.map(coin => `เหรียญ${coin.name}`)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          {promotionChallenge?.action ===
            PromotionChallengeActionEnum.CREATE_BOOK && (
            <>
              <Container.Hr />
              <div
                className={cn(
                  'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center',
                  {
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  }
                )}
              >
                <span className='text-[14px] font-light text-secondary-100'>
                  ประเภทงานเขียน :
                </span>
                <div
                  className={cn({
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  })}
                >
                  {promotionChallenge?.bookTypes
                    ?.map(bookType => promotionChallengeBookTypeText(bookType))
                    .join(', ')}
                </div>
                <div className='text-[14px] font-light text-secondary-100'>
                  หมวดหมู่ : &nbsp;
                  <span
                    className={cn({
                      'text-secondary': isDisableStatus,
                      'text-secondary-100': !isDisableStatus,
                    })}
                  >
                    {promotionChallenge?.categories
                      ?.map(category => category.name)
                      .join(', ')}
                  </span>
                </div>
              </div>
            </>
          )}
          {promotionChallenge?.action ===
            PromotionChallengeActionEnum.SHARE && (
            <>
              <Container.Hr />
              <div
                className={cn(
                  'grid grid-cols-[150px_200px_1fr_6fr] gap-y-[25px] text-[14px] items-center',
                  {
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  }
                )}
              >
                <span className='text-xs text-secondary-100'>แชร์ไปยัง : </span>
                <span
                  className={cn('text-sm', {
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  })}
                >
                  {promotionChallenge?.socialMediaChannels
                    ?.map(social => capitalizeFirstLetter(social.toLowerCase()))
                    .join(', ')}
                </span>
              </div>
            </>
          )}
          <Container.Hr />
          <div
            className={cn(
              'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[14px] font-light text-secondary-100'>
              รับเหรียญ :
            </span>
            <div
              className={cn({
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              })}
            >
              {promotionChallenge?.benefitValue} &nbsp;
              <span className='text-secondary-100'>
                เหรียญ{promotionChallenge?.summary.coin.name}
              </span>
            </div>
            <div className='text-[14px] font-light text-secondary-100'>
              เก็บเหรียญได้ :
              <span className='text-secondary px-[5px]'>
                {promotionChallenge?.benefitLimitValue?.toLocaleString()}
              </span>
              <span
                className={cn({
                  'text-secondary': isDisableStatus,
                  'text-secondary-100':
                    !isDisableStatus ||
                    [
                      PromotionChallengeLimitTypeEnum.PER_DAY,
                      PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
                    ].includes(
                      promotionChallenge?.benefitLimitType ||
                        PromotionChallengeLimitTypeEnum.ONCE
                    ),
                })}
              >
                {promotionChallengeBenefitLimitTypeText(
                  promotionChallenge?.benefitLimitType
                )}
              </span>
            </div>
          </div>
          <Container.Hr />
          <div
            className={cn(
              'grid grid-cols-[150px_200px_1fr] gap-y-[25px] text-[14px] items-center',
              {
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              }
            )}
          >
            <span className='text-[14px] font-light text-secondary-100'>
              งบประมาณ/แคมเปญ :
            </span>
            <div
              className={cn({
                'text-secondary': isDisableStatus,
                'text-secondary-100': !isDisableStatus,
              })}
            >
              {promotionChallenge?.budget.toLocaleString() || 0} &nbsp;
              <span className='text-secondary-100'>
                เหรียญ{promotionChallenge?.summary.coin.name}
              </span>
            </div>
            {promotionChallenge?.budgetPerDay && (
              <div className='text-[14px] font-light text-secondary-100'>
                จำกัดจำนวน &nbsp;
                <span
                  className={cn({
                    'text-secondary': isDisableStatus,
                    'text-secondary-100': !isDisableStatus,
                  })}
                >
                  {promotionChallenge?.budgetPerDay.toLocaleString()} &nbsp;
                </span>
                <span className='text-secondary-100'>
                  เหรียญ{promotionChallenge?.summary.coin.name} / ต่อวัน
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className={cn('grid grid-cols-[130px_1fr] gap-y-[25px] text-[14px]', {
            'text-secondary': isDisableStatus,
            'text-secondary-100': !isDisableStatus,
          })}
        >
          <span className='text-[12px] font-light text-secondary-100 mt-[2px]'>
            รายละเอียด :
          </span>
          <span className='break-word'>{description || '-'}</span>
        </div>
      )}
      <Container.Hr />
      <div className='flex gap-x-[80px]'>
        <Card.TextHorizontal
          label='เก็บ Challenge ไปแล้วทั้งหมด (ครั้ง)'
          value={promotionChallenge?.summary.progressCount || 0}
          valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
        />
        <Card.TextHorizontal
          label='User ทั้งหมด'
          value={promotionChallenge?.summary.userCount || 0}
          valueColor={isDisableStatus ? 'secondary' : 'secondary-100'}
        />
        <Card.TextHorizontal
          label={`มูลค่าเหรียญ${promotionChallenge?.summary.coin.name || ''}`}
          value={promotionChallenge?.summary.coinValue || 0}
          valueColor={isDisableStatus ? 'primary' : 'secondary-100'}
        />
      </div>
    </>
  )
}
