import { gql } from 'graphql-request'

export const UPLOAD_PROMOTION_SALE = gql`
  mutation UploadPromotionSaleTerm(
    $type: PSConditionTypeEnum!
    $file: Upload!
  ) {
    uploadPromotionSaleTerm(type: $type, file: $file) {
      count
      fileName
      filePath
      ids
    }
  }
`
