import { Expose, Transform } from 'class-transformer'

export class ReaderDashboardDetailType {
  @Expose()
  @Transform(({ value }) => value || 0)
  totalReader: number

  @Expose()
  @Transform(({ value }) => value || 0)
  averageTimeUsage: number
}
