import { Link, useParams } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export function ActionCell({ chapterId }: { chapterId: number }) {
  const { id } = useParams()
  return (
    <Link target='_blank' to={`/preview/${id}/chapter/${chapterId}`}>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </Link>
  )
}
