import { VerticalStackBarChart } from '@components/chart/VerticalStackBarChart'
import {
  CardValue,
  TextLabel,
  TextValue,
} from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { Duration } from 'luxon'
import { OverviewProps } from './interface'

export function Overview({
  totalWriter,
  summaryWriterUsage,
  newWriter,
  accumulateWriter,
  graphData,
  setGraphData,
}: OverviewProps) {
  return (
    <section className='flex space-x-[60px]'>
      <div className='flex flex-col space-y-[40px]'>
        <CardValue>
          <TextLabel>จำนวนนักเขียน</TextLabel>
          <TextValue className='text-secondary text-[24px]'>
            {totalWriter.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel className='whitespace-nowrap'>นักเขียนใหม่</TextLabel>
          <TextValue className='text-secondary text-[24px]'>
            {newWriter.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel className='whitespace-nowrap'>ยอดนักเขียนสะสม</TextLabel>
          <TextValue className='text-secondary text-[24px]'>
            {accumulateWriter.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel>เวลาที่ใช้งานเฉลี่ย</TextLabel>
          <div className='text-secondary text-[24px] font-bold'>
            {Duration.fromMillis(summaryWriterUsage).toFormat('h:mm:ss')}
            <span className='text-secondary-100 text-sm'> hr</span>
          </div>
        </CardValue>
      </div>
      <div className='border-r border-gray' />
      <div className='flex flex-col space-y-[40px]'>
        <CardValue>
          <TextLabel>Free Writer</TextLabel>
          <TextValue className='text-secondary text-[24px]'>
            {graphData.totalFreeWriter.toLocaleString()}
          </TextValue>
        </CardValue>
        <CardValue>
          <TextLabel>Earn Writer</TextLabel>
          <TextValue className='text-secondary text-[24px]'>
            {graphData.totalEarnWriter.toLocaleString()}
          </TextValue>
        </CardValue>
      </div>
      <div className='flex w-full'>
        <div className='flex flex-col w-full'>
          <VerticalStackBarChart
            title='Free Writer VS Earn Writer'
            headerTitleClassName='text-[18px]'
            grid={{ left: 40, right: 5, top: 65 }}
            style={{ width: '100%', height: '350px' }}
            dataSource={setGraphData(graphData)}
          />
        </div>
      </div>
    </section>
  )
}
