/* eslint-disable no-unsafe-optional-chaining */
import cn from 'classnames'
import { Waypoint } from 'react-waypoint'
import { Avatar } from '@components/Avatar'
import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { useAuthentication } from '@hooks/useAuthentication'
import { CoinManagementHistoryPageProps } from './interface'

export function CoinManagementHistoryPage({
  activeIndex,
  optionData,
  currentCoinHistory,
  handleChangeHistory,
  handleFetchNextPage,
  handleApprove,
  handleReject,
}: CoinManagementHistoryPageProps) {
  const { user } = useAuthentication()
  const permission = usePermissionAction('coin')
  const canApprove =
    permission.approve && currentCoinHistory?.createdBy?.id !== user.id

  return (
    <Container className='overflow-y-hidden h-full'>
      <Container.Title showBackBtn>
        ดูประวัติการเปลี่ยนมูลค่าเหรียญ
      </Container.Title>
      <div className='flex h-full'>
        <div className='overflow-y-auto overflow-x-hidden min-w-[170px] w-[170px]'>
          <div className='flex flex-col gap-y-[20px] text-[14px] text-secondary-100 font-bold'>
            {optionData.map((item, index) => (
              <button
                key={index}
                type='button'
                className={cn('flex items-center justify-left', {
                  'text-secondary font-bold': index === activeIndex,
                })}
                onClick={() => handleChangeHistory(index)}
              >
                {item.text}
                {item.status === CoinRequestStatusEnum.PENDING && (
                  <div className='w-[8px] h-[8px] rounded-full bg-red ml-[4px]' />
                )}
              </button>
            ))}
            <Waypoint onEnter={handleFetchNextPage} />
          </div>
        </div>
        <div className='border-l mr-[50px] ml-[20px]' />
        <div className='flex flex-col gap-y-[20px] w-full'>
          <div className='grid grid-cols-[40px_fit-content(50px)_fit-content(100px)_fit-content(100px)_fit-content(100px)_fit-content(80px)_1fr_1fr] gap-y-[20px] gap-x-[10px] overflow-y-auto overflow-x-hidden max-h-[calc(100%-180px)] text-[14px] text-secondary-100 font-light items-center'>
            {currentCoinHistory?.newCoins?.map((item, index) => (
              <>
                <div className='w-[50px] h-[40px] flex items-start'>
                  <img
                    className='min-w-[40px] h-[40px]'
                    src={item.imgPath}
                    alt={item.name}
                  />
                </div>
                <div className='font-bold text-secondary ml-[10px]'>
                  {item.amountCoin.toLocaleString()}
                </div>
                <div className='line-clamp-1'>เหรียญ{item.name}</div>
                <div className='flex items-center gap-x-[10px]'>
                  <span>=</span>
                  <span className='font-bold text-secondary'>
                    {item.amountCoinPerValue.toLocaleString()}
                  </span>
                </div>
                <div>
                  <span className='line-clamp-1'>
                    {index <= 1 && !item.valueUnitCoinName
                      ? 'บาท'
                      : `เหรียญ${item.valueUnitCoinName}`}
                  </span>
                </div>
                <div>
                  {index > 1 && (
                    <span className='text-secondary'>
                      ({item.visible ? 'เผยแพร่' : 'ไม่เผยแพร่'})
                    </span>
                  )}
                </div>
                {item.name === 'ทอง' ? (
                  <div className='col-span-2' />
                ) : (
                  <>
                    <div className='mx-[20px]'>
                      อายุเหรียญจากการซื้อ&nbsp;
                      <span className='text-secondary font-bold'>
                        {item.expireWithinMonthsByPaid}
                      </span>
                      &nbsp;เดือน
                    </div>
                    <div>
                      อายุเหรียญจากกิจกรรม&nbsp;
                      <span className='text-secondary font-bold'>
                        {item.expireWithinMonthsByActivity}
                      </span>
                      &nbsp;เดือน
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <div className='border-t border-gray' />
          <div className='flex justify-between mt-[10px]'>
            <div className='flex items-center text-[14px] font-bold'>
              <div className='text-secondary-100'>ดำเนินการโดย :</div>
              <Avatar
                className='w-[50px] h-[50px] mx-[10px]'
                src={currentCoinHistory?.createdBy?.avatarImgPath}
                alt={currentCoinHistory?.createdBy?.displayName}
              />
              <div className='text-secondary'>
                {currentCoinHistory?.createdBy?.displayName}
              </div>
            </div>

            {currentCoinHistory?.status === CoinRequestStatusEnum.PENDING ? (
              canApprove ? (
                <div className='flex'>
                  <Button
                    type='button'
                    className='px-[30px] border border-secondary-100 text-secondary-100'
                    onClick={() => handleReject(currentCoinHistory.id)}
                  >
                    Reject
                  </Button>
                  <Button
                    type='submit'
                    className='px-[55px] bg-primary text-white ml-[20px]'
                    onClick={() => handleApprove(currentCoinHistory.id)}
                  >
                    Approve
                  </Button>
                </div>
              ) : (
                <div className='text-[16px] font-light text-primary mt-[16px]'>
                  (รอการอนุมัติ)
                </div>
              )
            ) : (
              <div className='flex items-center text-[14px] font-bold'>
                <div className='text-secondary-100'>
                  {currentCoinHistory?.status === CoinRequestStatusEnum.APPROVED
                    ? 'Approver : '
                    : 'Rejecter : '}
                </div>
                <Avatar
                  className='w-[50px] h-[50px] mx-[10px]'
                  src={currentCoinHistory?.approvedBy?.avatarImgPath}
                  alt={currentCoinHistory?.approvedBy?.displayName}
                />
                <div className='text-secondary'>
                  {currentCoinHistory?.approvedBy?.displayName}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
