export class CharacterDonationBySpender {
  userId: number

  username: string

  coinSpend: number

  profilePageSlug: string
}

export class CharacterDonationBySpenderResponse {
  data: CharacterDonationBySpender[]

  total: number

  page: number
}
