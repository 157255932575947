import { Formik, Form } from 'formik'
import cn from 'classnames'
import * as Yup from 'yup'

import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Button } from '@components/Button'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { ReaderByUserSearchFormProps } from './interface'
import { SearchUserField } from '../SearchUserField'

export function ReaderByUserSearchForm({
  initialValues,
  text,
  disableExport,
  handleSubmit,
  handleReset,
  handleExport,
  setText,
}: ReaderByUserSearchFormProps) {
  const permission = usePermissionAction('dashboard')

  const timeRangeOption = [
    { value: TimeRangeEnum.DAY, text: 'เมื่อวาน' },
    { value: TimeRangeEnum.MONTH, text: '1 เดือน' },
    { value: TimeRangeEnum.YEAR, text: '1 ปี' },
  ]
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className='flex flex-col gap-y-[30px] mb-[30px]'>
              <div className='grid grid-cols-1'>
                <SearchUserField
                  name='userId'
                  placeholder='ค้นหาด้วยชื่อหรือ ID'
                  text={text}
                  setText={setText}
                  inputClassName='font-[300] bg-blue-2 rounded-[20px] placeholder:text-secondary-100'
                  className='mt-[22px]'
                  resetHandler={() => {
                    setText('')
                    handleReset()
                  }}
                />
              </div>
              <div className='flex justify-between items-end '>
                <div className='flex items-end gap-x-[10px]'>
                  <SelectButtonField
                    name='timeRange'
                    options={timeRangeOption}
                    label='เลือกช่วงเวลา'
                    mode='line'
                    className='w-fit overflow-auto'
                    onChange={() => {
                      if (values.startDate) {
                        setFieldValue('startDate', undefined)
                      }
                      if (values.endDate) {
                        setFieldValue('endDate', undefined)
                      }
                    }}
                  />
                  <DateTimeInputField
                    className='w-[180px]'
                    name='startDate'
                    placeholder='ตั้งแต่วันที่'
                    onChange={value => {
                      setFieldValue('startDate', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    maxDate={values.endDate || new Date()}
                  />
                  <DateTimeInputField
                    className='w-[180px] '
                    name='endDate'
                    placeholder='ถึงวันที่'
                    onChange={value => {
                      setFieldValue('endDate', value)
                      if (values.timeRange) {
                        setFieldValue('timeRange', undefined)
                      }
                    }}
                    minDate={values.startDate}
                    maxDate={new Date()}
                  />
                </div>
                <div className='flex font-mitr justify-end gap-x-[20px]'>
                  <Button
                    type='reset'
                    className='text-secondary underline whitespace-pre'
                    onClick={() => {
                      setText('')
                      handleReset()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white '
                    disabled={isSubmitting}
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                  <ExportButton
                    className={cn({
                      hidden: !permission.download,
                    })}
                    disabled={disableExport}
                    handleClick={handleExport}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
