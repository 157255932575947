import { gql } from 'graphql-request'

export const GET_STORY_COVER = gql`
  query GetBook($id: Int!) {
    book(id: $id) {
      id
      coverImgPath
      title
      writer
      cover
      writingType
      chapterType
      isTranslated
      likeCount
      viewCount
      shelfAddedCount
      commentCount
      isEpub
      isPdf
      intro
      status
      bookType
      chapterCount
      amountSpent
      sumCharacterDonate
      chapterCount
      penName {
        id
        firstPenName
        user {
          id
          displayName
          avatarImgPath
          writer {
            affiliation
          }
        }
      }
      category {
        id
        name
      }
  }
`
