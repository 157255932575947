import { gql } from 'graphql-request'

export const GET_STICKER_LIST = gql`
  query GetStickerList(
    $limitPerPage: Int
    $page: Int
    $orderBy: StickerListsOrderBy
  ) {
    stickers(limitPerPage: $limitPerPage, page: $page, orderBy: $orderBy) {
      data {
        id
        name
        stickerImgPath
      }
      total
      page
    }
  }
`
