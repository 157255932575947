import { Outlet } from 'react-router-dom'
import { PreviewReadingPage } from './pages/PreviewReadingPage'
import { PreviewStoryPage } from './pages/PreviewStoryPage'

export const previewRoutes = [
  {
    path: 'preview',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <PreviewStoryPage />,
      },
      {
        path: ':id/chapter/:chapterId',
        element: <PreviewReadingPage />,
      },
    ],
  },
]
