import React, { useState } from 'react'

import {
  ReaderByUserSearchFormProps,
  WithReaderByUserSearchFormProps,
} from './interface'

export function withReaderByUserSearchForm(
  Component: React.FC<ReaderByUserSearchFormProps>
) {
  function WithReaderByUserSearchForm(props: WithReaderByUserSearchFormProps) {
    const [text, setText] = useState('')

    const newProps = {
      text,
      setText,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithReaderByUserSearchForm
}
