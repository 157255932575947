import { useState } from 'react'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'

import { useClient } from '@hooks/useClient'
import { CoinHistoryTypeEnum } from '@interfaces/coin/CoinHistoryTypeEnum'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { CoinHistorySearchFormType } from '@models/coin/CoinHistorySearchFormType'
import { useAlert } from '@hooks/useAlert'
import { useAuthentication } from '@hooks/useAuthentication'
import { SortByType, SortingType } from '@components/Table/interface'
import { getErrorMessage } from '@libs/utils'
import { usePagination } from '@hooks/usePagination'
import { CoinHistoryPageProps, CoinHistoryQueryParam } from './interface'

export function withCoinHistoryPage(Component: React.FC<CoinHistoryPageProps>) {
  function WithCoinHistoryPage() {
    const client = useClient()
    const queryClient = useQueryClient()
    const [activeTab, setActiveTab] = useState(CoinHistoryTypeEnum.ALL)
    const alert = useAlert()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })
    const [searchParam, setSearchParam] = useState<CoinHistoryQueryParam>({
      searchText: '',
      timeRange: TimeRangeEnum.MONTH,
      startAt: DateTime.now()
        .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })

    async function handleSubmit({
      searchText,
      timeRange,
      startAt,
      endAt,
    }: CoinHistorySearchFormType) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
        newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      if (newStartAt && newEndAt) {
        const obj = {
          searchText,
          timeRange,
          startAt: newStartAt,
          endAt: newEndAt,
        }

        setSearchParam(prev => ({
          ...prev,
          ...obj,
        }))
        pageChange(1)
      }
    }

    function onActiveTabChange(tabId: string) {
      setActiveTab(tabId as CoinHistoryTypeEnum)
      pageChange(1)
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParam(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    async function exportFile() {
      try {
        await client?.coinClient.exportCoinHistory({
          searchParam,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      searchParam,
      activeTab,
      page,
      perpage,
      total,
      pageChange,
      handleTotalChange,
      onActiveTabChange,
      handleSubmit,
      handleSort,
      exportFile,
    }
    return <Component {...newProps} />
  }

  return WithCoinHistoryPage
}
