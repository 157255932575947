import React from 'react'

import { CoinSectionProps } from './interface'

export function withCoinSection(Component: React.FC<CoinSectionProps>) {
  function WithCoinSection(props: CoinSectionProps) {
    return <Component {...props} />
  }
  return WithCoinSection
}
