import { withPermissionGuard } from '@libs/permission'
import { BookStorySettingEditPage } from './BookStorySettingEditPage'
import { withBookStorySettingEditPage } from './withBookStorySettingEditPage'

const ConnectedBookStorySettingEditPage = withPermissionGuard(
  withBookStorySettingEditPage(BookStorySettingEditPage),
  'chapterBook',
  'edit'
)
export { ConnectedBookStorySettingEditPage as BookStorySettingEditPage }
