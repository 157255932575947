import { withPermissionGuard } from '@libs/permission'
import { UploadFilePage } from './UploadFilePage'
import { withUploadFilePage } from './withUploadFilePage'

const ConnectedUploadFilePage = withPermissionGuard(
  withUploadFilePage(UploadFilePage),
  'coin',
  'view'
)

export { ConnectedUploadFilePage as UploadFilePage }
