import { gql } from 'graphql-request'

export const GET_PROMOTION_SALE_DETAIL = gql`
  query PromotionSaleDatail($promotionId: Float!) {
    promotion(id: $promotionId) {
      id
      businessId
      name
      description
      userTerm
      startPublishedAt
      endPublishedAt
      publishedType
      status
      isActive
      isEditable
      createdAt
      updatedAt
      deletedAt
      usageDay
      promotionSale {
        id
        actionValue
        benefitLimitType
        benefitLimitValue
        benefitValue
        budget
        budgetPerDay
        categories {
          id
          name
        }
        coinsCondition {
          id
          name
        }
        conditionFileName
        conditionType
        termCount
        useCoinConditionType
        summary {
          totalBook
          totalDeal
          totalSale
          totalSell
          userCount
          coin {
            name
          }
        }
      }
      lastRequest {
        id
        createdBy {
          id
          displayName
          avatarImgPath
        }
        createdAt
        action
      }
    }
  }
`
