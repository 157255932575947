import { Expose, Type } from 'class-transformer'
import { SearchHistoryType } from './SearchHistoryType'

export class SearchLogResponse {
  @Expose()
  @Type(() => SearchHistoryType)
  data: SearchHistoryType[]

  @Expose() total: number
}
