import { gql } from 'graphql-request'

export const GET_WRITER_OPTIONS = gql`
  query SearchWriter($searchText: String!) {
    searchWriter(searchText: $searchText) {
      id
      user {
        id
        avatarImgPath
        username
        penNameCount
      }
    }
  }
`
