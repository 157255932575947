import { Expose } from 'class-transformer'

import { ExportJobStatusEnum } from '@interfaces/exportFile/ExportJobStatusEnum'

export class ExportJobType {
  @Expose() id: number

  @Expose() name: string

  @Expose() status: ExportJobStatusEnum

  @Expose() presignedPath: string
}
