export enum PSConditionTypeEnum {
  ALL = 'ALL',
  STORY = 'STORY',
  WRITER = 'WRITER',
}

export const promotionSaleConditionValue = {
  [PSConditionTypeEnum.ALL]: 'เรื่องใดก็ได้',
  [PSConditionTypeEnum.STORY]: 'ระบุ Story ID',
  [PSConditionTypeEnum.WRITER]: 'ระบุ Writer ID',
}
