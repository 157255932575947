import { DateTime } from 'luxon'
import { ColumnType } from '@components/Table/interface'
import { AgeConfirmStatus } from '@features/user/components/AgeConfirmStatus'
import { AgeConfirmType } from '@models/ageConfirmation/AgeConfirmType'
import { ActionCell } from './ActionCell'
import { UserCell } from './UserCell'

export const columns: ColumnType<AgeConfirmType>[] = [
  {
    column: 'Date & Time',
    accessor: 'ageUploadedAt',
    cell: ({ value }) => (
      <p>
        {value
          ? DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
              locale: 'th-Th',
            })
          : '-'}
      </p>
    ),
    cellClassName: 'max-w-[250px] w-[250px] pr-[10px]',
    isSortable: true,
  },
  {
    column: 'User ID',
    accessor: 'id',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    cellClassName: 'pr-[10px]',
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ obj }) => (
      <UserCell coverImgPath={obj.avatarImgPath} username={obj.username} />
    ),
    isSortable: true,
    cellClassName: 'pr-[10px]',
  },
  {
    column: 'Status',
    accessor: 'ageVerify',
    cell: ({ value }) => <AgeConfirmStatus status={value} />,
    isSortable: true,
    cellClassName: 'pr-[10px]',
  },
  {
    column: 'Action',
    accessor: 'id',
    cell: ({ value }) => <ActionCell id={value} />,
    isSortable: false,
    cellClassName: 'flex justify-end  py-[6.5px]',
  },
]
