import React, { useState } from 'react'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { DateTime } from 'luxon'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { EbookPageProps, SearchFormType } from './interface'

export function withEbookPage(Component: React.FC<EbookPageProps>) {
  function WithEbookPage() {
    const client = useClient()
    const { id } = useParams()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const { isAuthenticated } = useAuthentication()
    const [searchParams, setSearchParams] = useState<SearchFormType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      keyType: KeyTypeEnum.MONTH,
    })

    const { data, isLoading } = useQuery(
      ['ebook-cover', id],
      () => client?.ebookClient.getEbookDetail(Number(id)),
      {
        enabled: !!id && isAuthenticated,
      }
    )

    function handleSearch(
      startDate: DateTime,
      endDate: DateTime,
      keyType?: KeyTypeEnum | null
    ) {
      setSearchParams({
        startDate,
        endDate,
        keyType,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        keyType: KeyTypeEnum.MONTH,
      })
    }

    async function handleExport() {
      try {
        await client?.dashboardClient.exportEbookDetailDashboard({
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          ebookId: Number(id),
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    if (!id) return null

    const newProps = {
      ebookId: id,
      data,
      isLoading,
      searchParams,
      handleSearch,
      handleReset,
      handleExport,
    }

    return <Component {...newProps} />
  }

  return WithEbookPage
}
