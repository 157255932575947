import { Expose, Type, Transform } from 'class-transformer'
import { BookTopChartsType } from '@models/story/BookTopChartsType'

export class ListUserReadingType {
  @Expose() id: number

  @Expose() username: string

  @Expose()
  @Transform(value => value || 0)
  commentCount: number

  @Expose()
  @Transform(value => value || 0)
  totalSpend: number
}

export class ListUserReadingResponse {
  @Expose()
  @Type(() => BookTopChartsType)
  data: BookTopChartsType[]

  @Expose() total: number

  @Expose() page: number
}
