import { gql } from 'graphql-request'

export const TOTAL_CHARACTER_DONATION = gql`
  query TotalCharacterDonation($dateRange: DatePicker) {
    totalCharacterDonation(dateRange: $dateRange) {
      totalIncome
      writerIncome
      platformIncome
    }
  }
`
