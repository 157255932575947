import { Expose, Type, Transform } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'

export class BookReadingType {
  @Expose() coverImgPath: string

  @Expose() id: number

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() penNameId: number

  @Expose() firstPenName: string

  @Expose()
  @Transform(({ value }) => value || 0)
  chapterCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  viewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  likeCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  chapterReadCount: number
}

export class BookReadingResponse {
  @Expose()
  @Type(() => BookReadingType)
  data: BookReadingType[]

  @Expose() total: number

  @Expose() page: number
}
