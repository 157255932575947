import { withPermissionGuard } from '@libs/permission'
import CategoryCoverPage from './CategoryCoverPage'
import withCategoryCoverPage from './withCategoryCoverPage'

const ConnectedCategoryCoverPage = withPermissionGuard(
  withCategoryCoverPage(CategoryCoverPage),
  'chapterBook',
  'view'
)

export { ConnectedCategoryCoverPage as CategoryCoverPage }
