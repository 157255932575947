import { client } from '@client/init'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { ByUserProps, SearchType, ByUserSearchFormType } from './interface'

const withByUser = (Component: React.FC<ByUserProps>) => {
  function WithByUser() {
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const queryUserId = searchParams.get('userId') || undefined

    const [queryParam, setQueryParam] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
      userId: queryUserId,
      keyType: TimeRangeEnum.MONTH,
    })

    function newDateAt(
      startDate: Date | undefined,
      endDate: Date | undefined,
      timeRange: TimeRangeEnum | undefined
    ) {
      let newStartAt = startDate
        ? DateTime.fromJSDate(startDate).startOf('day')
        : undefined
      let newEndAt = endDate
        ? DateTime.fromJSDate(endDate).endOf('day')
        : undefined
      let keyType = timeRange

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
        newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (newStartAt && newEndAt && !keyType) {
        const diff = newEndAt.diff(newStartAt, ['years', 'months', 'days'])
        const { days = 0, months = 0, years = 0 } = diff.toObject()
        if (years >= 1) {
          keyType = TimeRangeEnum.YEAR
        } else if (months >= 1) {
          keyType = TimeRangeEnum.YEAR
        } else if (days > 1) {
          keyType = TimeRangeEnum.MONTH
        } else {
          keyType = TimeRangeEnum.DAY
        }
      }

      return { newStartAt, newEndAt, keyType }
    }

    async function handleSearch({
      userId,
      startDate,
      endDate,
      timeRange,
    }: ByUserSearchFormType) {
      const { newStartAt, newEndAt, keyType } = newDateAt(
        startDate,
        endDate,
        timeRange
      )

      if (newStartAt && newEndAt && keyType) {
        setQueryParam({
          startDate: newStartAt,
          endDate: newEndAt,
          userId,
          keyType,
        })
        navigate({
          search: `?tab=byUser&userId=${userId}`,
        })
      }
    }

    function handleReset() {
      setQueryParam({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
        userId: undefined,
        keyType: TimeRangeEnum.MONTH,
      })
      navigate({
        search: `?tab=byUser`,
      })
    }

    const { data: readerDetail, isLoading } = useQuery(
      ['reader-detail-by-user', queryParam.userId],
      () =>
        client?.dashboardClient.getReaderDetailByUser({
          userId: Number(queryParam.userId),
        }),
      {
        enabled: !!queryParam.userId,
      }
    )
    const { data: readerCoinDetail, isLoading: coinLoading } = useQuery(
      ['reader-coin-detail', queryParam],
      () =>
        client?.dashboardClient.getUserReaderCoinDetail({
          startDate: queryParam.startDate,
          endDate: queryParam.endDate,
          userId: Number(queryParam.userId),
        }),
      {
        enabled: !!queryParam.userId,
      }
    )

    async function handleExport() {
      try {
        const { startDate, endDate, userId, keyType } = queryParam

        if (userId) {
          await client?.dashboardClient.exportReaderDashboardById({
            startDate,
            endDate,
            userId: Number(userId),
            keyType,
          })
          await queryClient.refetchQueries('export-jobs')
        }
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      queryParam,
      readerDetail,
      readerCoinDetail,
      handleSearch,
      handleReset,
      handleExport,
      isLoading: isLoading || coinLoading,
    }
    return <Component {...newProps} />
  }

  return WithByUser
}

export default withByUser
