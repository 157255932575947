import React from 'react'
import { UploadCoverProps } from '@interfaces/UploadCover'

export function withUploadCover(Component: React.FC<UploadCoverProps>) {
  function WithUploadCover(props: UploadCoverProps) {
    return <Component {...props} />
  }

  return WithUploadCover
}
