import cn from 'classnames'
import { useMatch, Link } from 'react-router-dom'

import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon, ChevronUpIcon } from '@components/Icons'
import { SelectMenuProps } from './interface'
import { SelectList } from '../SelectList'

export function SelectMenu({
  label,
  option,
  isAlarm,
  isToggle,
  basePath,
}: SelectMenuProps) {
  const match = useMatch({ path: basePath, end: false })
  const { isShowDropdown, toggleDropdown } = useDropdown()

  return (
    <div className='relative select-none'>
      <button
        type='button'
        className={cn('flex w-full')}
        onClick={() => isToggle && toggleDropdown()}
      >
        <Link
          to={label?.path || {}}
          className={cn(
            'flex items-center justify-between w-full font-bold py-5',
            {
              'text-secondary-100': !isShowDropdown,
              'text-secondary': isShowDropdown,
              'text-primary': match && !option?.length,
            }
          )}
        >
          <div className='flex items-center gap-x-[10px]'>
            {label?.label}

            {isAlarm && (
              <div className='w-[8px] h-[8px] rounded-full bg-[#f7575f]' />
            )}
          </div>

          {!!option?.length &&
            (isShowDropdown ? (
              <ChevronUpIcon width='16' height='16' />
            ) : (
              <ChevronDownIcon width='16' height='16' />
            ))}
        </Link>
      </button>
      {isShowDropdown && (
        <div className='w-full mb-5 pl-5'>
          {option?.length &&
            option?.map((item, index) => (
              <SelectList key={index} option={item} />
            ))}
        </div>
      )}
    </div>
  )
}
