import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { queryClient } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionChallengeFormType } from '@models/promotion/challenge/PromotionChallengeFormType'
import { PreviewPromotionChallenge } from '@features/promotionSetting/components/PromotionChallengeForm/components/PreviewPromotionChallenge'
import { Loading } from '@components/Loading'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { EditPromotionChallengePageProps } from './interface'

export function withEditPromotionChallengePage(
  Component: React.FC<EditPromotionChallengePageProps>
) {
  function Hoc() {
    const navigate = useNavigate()
    const { id } = useParams()
    const client = useClient()
    const alert = useAlert()
    const confirmInfoModal = useModal({ modal: 'confirm' })
    const queryKey: QueryKey = ['promotion', 'challenge', Number(id)]

    const { data, isLoading, isFetching } = useQuery(
      [...queryKey, 'edit'],
      () =>
        client?.promotionChallengeClient.getPromotionChallengeForm(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { mutateAsync: updatePromotionChallenge } = useMutation(
      (form: PromotionChallengeFormType) =>
        client!.promotionChallengeClient.updatePromotionChallenge({
          ...form,
          status: PromotionStatusEnum.PENDING,
        }),
      {
        onSuccess: () => {
          alert.success('แก้ไขโปรโมชั่นส่วนลดสำเร็จ')
          queryClient.invalidateQueries(queryKey)
          navigate(`/promotion-setting/promotion-challenge/${id}`)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    const { data: activeCoinOptions = [] } = useQuery('activeCoinOptions', () =>
      client?.coinClient.getVisibleActiveCoinOptions()
    )

    const { data: challengeCoinOptions = [] } = useQuery(
      'challengeCoinOptions',
      () => client?.coinClient.getPromotionChallengeCoinOptions()
    )

    function handleSubmit(form: PromotionChallengeFormType) {
      confirmInfoModal.show({
        content: (
          <PreviewPromotionChallenge
            form={form}
            activeCoinOptions={activeCoinOptions}
            challengeCoinOptions={challengeCoinOptions}
          />
        ),
        onConfirm: async () => {
          await updatePromotionChallenge(form)
          confirmInfoModal.hide()
        },
        onClose: () => {
          confirmInfoModal.hide()
        },
      })
    }

    if (!data || isLoading || isFetching) {
      return (
        <div className='flex w-full justify-center'>
          <Loading />
        </div>
      )
    }

    const newProps = {
      initialValues: data,
      handleSubmit,
      activeCoinOptions,
      challengeCoinOptions,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
