import cn from 'classnames'

export function TextArea({
  className,
  ...props
}: React.HTMLProps<HTMLTextAreaElement>) {
  return (
    <textarea
      className={cn(
        'p-[10px] border border-gray rounded-[8px] w-full outline-none resize-none  disabled:bg-[#eeeeee] disabled:text-[#979797] placeholder:text-secondary-100 focus:border-primary focus:text-primary',
        className
      )}
      {...props}
    />
  )
}
