import { gql } from 'graphql-request'

export const EDIT_PROMOTION_SALE = gql`
  mutation UpdatePromotionSale(
    $updatePromotionSaleInput: UpdatePromotionSaleInput!
  ) {
    updatePromotionSale(updatePromotionSaleInput: $updatePromotionSaleInput) {
      message
    }
  }
`
