import { useRef, useState } from 'react'
import cn from 'classnames'
import { SearchInput } from '@components/SearchInput'
import { Button } from '@components/Button'
import { ExportButton } from './ExportButton'
import { CrossIcon, SearchIcon } from './Icons'

interface SearchProps {
  className?: string
  placeholder?: string
  defaultValue?: string
  onSearch: (text: string) => void
  onReset: () => void
  onExport?: () => Promise<void>
}

export function Search({
  className,
  placeholder,
  defaultValue,
  onSearch,
  onReset,
  onExport,
}: SearchProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [text, setText] = useState(defaultValue || '')
  function handleReset() {
    if (onReset) {
      onReset()
    }
    if (text) {
      setText('')
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value)
  }
  return (
    <div className='flex text-[14px] gap-x-[30px]'>
      <div className={cn('relative flex-1 text-primary ', className)}>
        <div className='absolute left-[15px] top-[10px] w-[15px]'>
          <SearchIcon width='20' height='20' />
        </div>
        <input
          ref={inputRef}
          value={text}
          className={cn(
            'w-full h-[40px] rounded-[20px] outline-none py-1.5 text-[14px] text-black-480 focus:border-primary focus:text-primary border border-blue-2 placeholder:text-secondary-100 font-light bg-blue-2 pl-[40px] pr-[35px]'
          )}
          placeholder={placeholder || 'ค้นหา'}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              onSearch((event.target as HTMLInputElement).value)
            }
          }}
          onChange={handleChange}
        />
        <div
          className={cn('absolute right-[15px] top-[8px]', {
            hidden: text === '',
          })}
          onClick={handleReset}
        >
          <CrossIcon className='text-black-480' width='18px' />
        </div>
      </div>
      <div className='flex gap-x-[20px]'>
        <Button
          className='underline text-secondary  font-medium !px-0'
          onClick={handleReset}
        >
          ล้างค่า
        </Button>
        <Button
          className='bg-secondary text-white font-medium'
          onClick={() => {
            if (inputRef.current) {
              onSearch(inputRef.current.value)
            }
          }}
        >
          ค้นหา
        </Button>
        {onExport && (
          <ExportButton
            className='border !border-secondary-100 !text-secondary-100 !bg-white'
            label='Export Report'
            handleClick={onExport}
          />
        )}
      </div>
    </div>
  )
}
