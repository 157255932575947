import { Expose, Transform } from 'class-transformer'

export class CoinOptionType {
  @Expose({ name: 'id' })
  @Transform(({ obj }) => {
    return obj.id ? String(obj.id) : ''
  })
  value: string

  @Expose({ name: 'name' })
  label: string

  @Expose()
  @Transform(
    ({ obj }) =>
      !obj.expireWithinMonthsByActivity && !obj.expireWithinMonthsByPaid
  )
  isGoldCoin: boolean
}
