import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { useParams } from 'react-router-dom'
import { CoinDetailProps } from '@components/CoinDetail/interface'

export function withCoinDetail(Component: React.FC<CoinDetailProps>) {
  function WithCoinDetail() {
    const client = useClient()
    const { id } = useParams()
    const isByUser = true
    const { data: systemCoins = [], isLoading } = useQuery(
      ['user-management', id, 'coins'],
      () =>
        client?.userManagementClient.getUserManagementCoins({
          userId: Number(id),
        })
    )

    const newProps = {
      systemCoins,
      showAllCoin: false,
      isByUser,
    }
    return <Component {...newProps} />
  }

  return WithCoinDetail
}
