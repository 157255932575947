export interface EbookPageProps {
  tabActive: string
  handleSelectTab: (active: string) => void
}

export enum TabActiveEnum {
  OVERVIEW = 'overview',
  BY_TITLE = 'byTitle',
  BY_USER = 'byUser',
}
