import { gql } from 'graphql-request'

export const GET_BOUGHT_EBOOK_DETAIL = gql`
  query BoughtEbookDetail(
    $ebookId: Int!
    $limitPerPage: Int
    $page: Int
    $orderBy: BoughtEbookOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    boughtEbookDetail(
      ebookId: $ebookId
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        id
        username
        createdAt
        price
        star
        boughtSeriesCount
      }
      page
      total
    }
  }
`
