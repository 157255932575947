import React, { useState } from 'react'
import cn from 'classnames'

import { CrossIcon, SearchIcon } from '@components/Icons'
import { SearchInputProps } from '@components/interfaces/SearchInput'

export const SearchInput = React.forwardRef(
  (
    {
      className,
      inputClassName,
      defaultValue = '',
      value,
      onKeyDown,
      onReset,
      onChange,
      ...props
    }: SearchInputProps,
    ref: any
  ) => {
    const [text, setText] = useState(defaultValue)
    function handleReset() {
      if (onReset) {
        onReset()
      }
      if (text) {
        setText('')
      }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setText(event.target.value)
      if (onChange) {
        onChange(event)
      }
    }

    const val = value ?? text

    return (
      <div className={cn('relative ', className)}>
        <div className='absolute left-[15px] top-[10px] w-[15px]'>
          <SearchIcon width='20' height='20' />
        </div>
        <input
          ref={ref}
          value={val}
          className={cn(
            'w-full h-[40px] rounded-[20px] outline-none py-1.5 text-[14px] text-black-480 focus:border-primary focus:text-primary border border-blue-2 placeholder:text-secondary-100 font-light bg-blue-2 pl-[40px] pr-[35px]',
            inputClassName
          )}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          {...props}
        />

        <div
          className={cn('absolute right-[15px] top-[8px]', {
            hidden: val === '',
          })}
          onClick={handleReset}
        >
          <CrossIcon className='text-black-480' width='18px' />
        </div>
      </div>
    )
  }
)
