import { Container } from '@components/Container'
import { StorySettingForm } from '@features/chapterBook/components/StorySettingForm'
import { BookStorySettingAddPageProps } from './interface'

export function BookStorySettingEditPage({
  initialValues,
  handleSubmit,
}: BookStorySettingAddPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>แก้ไขแบบฟอร์ม</Container.Title>
      <StorySettingForm initialValues={initialValues} onSubmit={handleSubmit} />
    </Container>
  )
}
