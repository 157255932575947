import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export enum TabModeEnum {
  LINE = 'line',
  BOX = 'box',
}

export interface TabContextType {
  menus: Tab
  handleAddMenu: (tab: Tab) => void
  activeTab: string
  handleUpdateActiveTab: (key: string) => void
}

export type Tab = Record<string, { children: JSX.Element; disabled?: boolean }>

export interface TabsProviderPropsType extends FunctionComponentType {
  initialActiveTab: string
  onActiveTabChange?: (key: any) => void
  mode?: string
}
