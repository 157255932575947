import { withPermissionGuard } from '@libs/permission'
import { EditRolePage } from './EditRolePage'
import { withEditRolePage } from './withEditRolePage'

const ConnectedEditRolePage = withPermissionGuard(
  withEditRolePage(EditRolePage),
  'admin',
  'view'
)

export { ConnectedEditRolePage as EditRolePage }
