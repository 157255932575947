import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { OrderByEnum, WriterParam } from '@interfaces/dashboard/WriterParam'
import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { WithWriterProps, WriterProps } from './interface'

export function withWriter(Component: React.FC<WriterProps>) {
  function WithWriter({ searchParam }: WithWriterProps) {
    const client = useClient()
    const [writerParam, setWriterParam] = useState<WriterParam>({
      orderBy: OrderByEnum.TOTAL_SEARCH,
      orderDirection: SortingType.DESC,
    })

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        ['search-result-by-writer', searchParam, writerParam],
        async ({ pageParam = 1 }) => {
          const res = await client?.dashboardClient.searchResultByWriter({
            ...searchParam,
            ...writerParam,
            page: pageParam,
          })

          return res
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setWriterParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      isLoading: isFetchingNextPage || isLoading,
      handleSortChange,
      handleWaypointEnter,
    }

    return <Component {...componentProps} />
  }

  return WithWriter
}
