import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { UserWritersResponse } from '@models/writer/UserWritersResponse'
import { UserWritersParam } from '@models/writer/UserWritersParam'
import { WriterType } from '@models/writer/WriterType'
import { ConsentType } from '@models/consent/ConsentType'
import { WriterRequestType } from '@models/writer/WriterRequestType'
import { ConsentHistoryType } from '@models/consent/ConsentHistoryType'
import { ProductType } from '@models/salse/ProductType'
import { WriterSalesType } from '@models/writer/WriterSalesType'
import { PaginatedWriterBookSaleDetail } from '@models/writer/PaginatedWriterBookSaleDetail'
import { SortingType } from '@components/Table/interface'
import { UserConsentRespons } from '@models/consent/UserConsentResponse'
import { GET_USER_WRITERS } from './schemas/getUserWriters'
import { GET_WRITER } from './schemas/getWriter'
import { GET_TOTAL_STATUS } from './schemas/getTotalStatus'
import { VERIFY_WRITER } from './schemas/verifyWriter'
import { GET_CONSENTS } from './schemas/getConsents'
import { FIND_WRITER_REQUEST_BY_ID } from './schemas/findWriterRequestById'
import { GET_CONSENT_HISTORIES } from './schemas/getConsentHistories'
import { GET_LATEST_CONSENT } from './schemas/getLastestConsent'
import { GET_WRITER_SALES_BY_PRODUCT_TYPE } from './schemas/getWriterSalesByProductType'
import { GET_WRITER_SALES_DETAIL_BY_PRODUCT_TYPE } from './schemas/getWriterSalesDetailByProductType'
import { CHECK_NUMBER_HAS_BAN } from './schemas/checkNumberHasBan'
import { USER_CONSENTS } from './schemas/userConsents'

interface QuerySaleArgs {
  startDate: DateTime
  endDate: DateTime
  limitPerPage: number
  page: number
  productType: ProductType
  userId: number
}

interface QuerySaleDetailArgs extends QuerySaleArgs {
  bookIdOrEbookId: number
}

export class UserWriterClient {
  constructor(private client: GraphQLClient) {}

  async findUserWriters({
    searchText = '',
    statusType = SearchUserStatusEnum.ALL,
    page = 1,
    sortKey,
    sortOrder,
  }: UserWritersParam): Promise<UserWritersResponse> {
    const { findUserWriters } = await this.client.request(GET_USER_WRITERS, {
      page,
      searchText,
      limitPerPage: 20,
      statusType: statusType === SearchUserStatusEnum.ALL ? null : statusType,
      orderBy: sortKey ? { [sortKey]: sortOrder } : {},
    })

    return plainToInstance(UserWritersResponse, findUserWriters)
  }

  async getTotalUserWriters({
    searchText = '',
    statusType = null,
  }: {
    statusType?: SearchUserStatusEnum | null
    searchText?: string
  }): Promise<number> {
    const {
      findUserWriters: { total },
    } = await this.client.request(GET_TOTAL_STATUS, {
      statusType: statusType === SearchUserStatusEnum.ALL ? null : statusType,
      searchText,
    })

    return total
  }

  async getWriterById(id: number): Promise<WriterType> {
    const { writer } = await this.client.request(GET_WRITER, {
      id,
    })

    return plainToInstance(WriterType, writer)
  }

  async findWriterRequestById(id: number): Promise<WriterRequestType> {
    const { findWriterRequestById } = await this.client.request(
      FIND_WRITER_REQUEST_BY_ID,
      {
        findWriterRequestByIdId: id,
      }
    )

    return plainToInstance(WriterRequestType, findWriterRequestById)
  }

  async verifyWriter(form: {
    id: number
    status: WriterStatusEnum
    rejectedReason?: string
  }): Promise<void> {
    await this.client.request(VERIFY_WRITER, {
      verifyWriter: form,
    })
  }

  async getLatestConsent(): Promise<ConsentType> {
    const { latestConsents } = await this.client.request(GET_LATEST_CONSENT)

    return plainToInstance(ConsentType, latestConsents)
  }

  async getConsents(): Promise<ConsentType[]> {
    const { consents } = await this.client.request(GET_CONSENTS)

    return plainToInstance(ConsentType, consents as [])
  }

  async getConsentHistory(writerId: number): Promise<ConsentHistoryType> {
    const { writerHistory } = await this.client.request(GET_CONSENT_HISTORIES, {
      writerId,
    })

    return plainToInstance(ConsentHistoryType, writerHistory)
  }

  async getWriterSalesByProductType(
    getWriterSalesByProductTypeInput: QuerySaleArgs
  ): Promise<WriterSalesType> {
    const response = await this.client.request(
      GET_WRITER_SALES_BY_PRODUCT_TYPE,
      {
        getWriterSalesByProductTypeInput,
      }
    )

    return plainToInstance(
      WriterSalesType,
      response.getWriterSalesByProductType
    )
  }

  async getWriterSalesDetailByProductType(
    getWriterSalesDetailByProductTypeInput: QuerySaleDetailArgs
  ): Promise<PaginatedWriterBookSaleDetail> {
    const response = await this.client.request(
      GET_WRITER_SALES_DETAIL_BY_PRODUCT_TYPE,
      {
        getWriterSalesDetailByProductTypeInput,
      }
    )

    return plainToInstance(
      PaginatedWriterBookSaleDetail,
      response.getWriterSalesDetailByProductType.salesDetail
    )
  }

  async verifyNumberHasBan(verifyId: string): Promise<boolean> {
    const { checkNumberHasBan } = await this.client.request(
      CHECK_NUMBER_HAS_BAN,
      {
        number: verifyId,
      }
    )
    return checkNumberHasBan
  }

  async getUserConsents({
    userId,
    page,
    limitPerPage,
    key,
    order,
  }: {
    userId: number
    page: number
    limitPerPage: number
    key: string
    order: SortingType
  }): Promise<UserConsentRespons> {
    const { userConsents } = await this.client.request(USER_CONSENTS, {
      limitPerPage,
      page,
      userId,
      orderBy: {
        [key]: order,
      },
    })

    return plainToInstance(UserConsentRespons, userConsents)
  }
}
