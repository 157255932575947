import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import { StickerManagementAddPageProps } from './interface'

export function withStickerManagementAddPage(
  Component: React.FC<StickerManagementAddPageProps>
) {
  function WithStickerManagementAddPage() {
    const client = useClient()
    const navigate = useNavigate()
    const initialValues = {
      name: '',
      upload: {
        url: '',
        blob: undefined,
      },
    }

    const { mutate: createSticker } = useMutation(
      (form: StickerManagementFormType) =>
        client!.stickerClient.createSticker(form),
      {
        onSuccess: () => {
          navigate({
            pathname: '/chapter-book/sticker-management',
          })
        },
      }
    )

    async function onSubmit(form: StickerManagementFormType) {
      createSticker(form)
    }

    const newProps = {
      initialValues,
      onSubmit,
    }
    return <Component {...newProps} />
  }

  return WithStickerManagementAddPage
}
