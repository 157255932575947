import { withPermissionGuard } from '@libs/permission'
import { UserManagementDetailPage } from './UserManagementDetailPage'
import { withUserManagementDetailPage } from './withUserManagementDetailPage'

const ConnectedUserManagementDetailPage = withPermissionGuard(
  withUserManagementDetailPage(UserManagementDetailPage),
  'user',
  'view'
)

export { ConnectedUserManagementDetailPage as UserManagementDetailPage }
