import { withPermissionGuard } from '@libs/permission'
import { PromotionSettingPage } from './PromotionSettingPage'
import { withPromotionSettingPage } from './withPromotionSettingPage'

const Connected = withPermissionGuard(
  withPromotionSettingPage(PromotionSettingPage),
  'promotionSetting',
  'view'
)

export { Connected as PromotionSettingPage }
