import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { Expose } from 'class-transformer'

export class WriterType {
  @Expose() id: number

  @Expose() idNumber: string

  @Expose() fullName: string

  @Expose() affiliation: AffiliationEnum

  @Expose() status: WriterStatusEnum
}
