import { Outlet } from 'react-router-dom'

import { WriterSubmitPage } from './pages/WriterSubmitPage'
import { UserManagementPage } from './pages/UserManagementPage'
import { UserManagementDetailPage } from './pages/UserManagementDetailPage'
import { WriterSubmitDetailPage } from './pages/WriterSubmitDetailPage'
import { SalesByUserPage } from './pages/SalesByUserPage'
import { SalesByUserDetailPage } from './pages/SalesByUserDetailPage'
import { AgeConfirmationPage } from './pages/AgeConfirmationPage'
import { AgeConfirmationDetailPage } from './pages/AgeConfirmationDetailPage'
import { WriterDetailProvider } from './pages/WriterSubmitDetailPage/hooks/useWriterDetail'

export const userRoutes = [
  {
    path: 'user',
    element: <Outlet />,
    children: [
      {
        path: 'writer-submit',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <WriterSubmitPage />,
          },
          {
            path: ':id',
            element: (
              <WriterDetailProvider>
                <WriterSubmitDetailPage />
              </WriterDetailProvider>
            ),
          },
        ],
      },
      {
        path: 'user-management',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <UserManagementPage />,
          },
          {
            path: ':id',
            element: <UserManagementDetailPage />,
          },
        ],
      },
      {
        path: 'age-confirmation',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <AgeConfirmationPage />,
          },
          {
            path: ':id',
            element: <AgeConfirmationDetailPage />,
          },
        ],
      },
      {
        path: 'sales-by-user',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <SalesByUserPage />,
          },
          {
            path: ':id',
            element: <SalesByUserDetailPage />,
          },
        ],
      },
    ],
  },
]
