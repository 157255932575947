import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAuthentication } from '@hooks/useAuthentication'
import { usePagination } from '@hooks/usePagination'
import { PunishmentEnum } from '@interfaces/user/PunishmentEnum'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BanSectionAcceptProps, BanSectionProps } from './interface'

export function withBanSection(Component: React.FC<BanSectionProps>) {
  function WithBanSection(props: BanSectionAcceptProps) {
    const [sort, setSort] = useState<SortByType>({
      key: 'punishmentTimes',
      order: SortingType.DESC,
    })
    const [activeBanTab, setActiveBanTab] = useState<PunishmentEnum>(
      PunishmentEnum.ADMONISH
    )
    const { id } = useParams()
    const { isAuthenticated } = useAuthentication()
    const [params] = useSearchParams()
    const banTab = params.get('banTab') || PunishmentEnum.ADMONISH
    const navigate = useNavigate()
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({})

    const {
      data: userPunishmentTotal = {
        totalAdmonish: 0,
        totalBan: 0,
      },
      isSuccess: isUserPunishmentTotal,
    } = useQuery(
      [
        'user-punishment-history-total',
        page,
        perpage,
        pageChange,
        activeBanTab,
        sort,
        id,
      ],
      () =>
        client!.userManagementClient.getUserPunishmentTotalCount(Number(id)),
      { enabled: isAuthenticated && !!id && activeBanTab === banTab }
    )

    const { data, isLoading } = useQuery(
      [
        'user-punishment-history-list',
        page,
        perpage,
        pageChange,
        activeBanTab,
        sort,
        id,
      ],
      () =>
        client?.userManagementClient
          .getUserPunishmentHistory({
            userId: Number(id),
            limitPerPage: perpage,
            page,
            punishmentType: activeBanTab,
            ...sort,
          })
          .then(res => {
            handleTotalChange(res.total)
            return res
          }),
      { enabled: isUserPunishmentTotal }
    )

    const tabs = [
      {
        value: PunishmentEnum.ADMONISH,
        text: 'ตักเตือน',
        total: userPunishmentTotal.totalAdmonish,
      },
      {
        value: PunishmentEnum.BAN,
        text: 'แบน',
        total: userPunishmentTotal.totalBan,
      },
    ]

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, key, order }))
    }

    function handleActiveTabChange(activeTab: PunishmentEnum) {
      setActiveBanTab(activeTab)
      navigate({
        search: `?tab=ban&banTab=${activeTab}`,
      })
      setSort(prev => ({
        ...prev,
        key: 'punishmentTimes',
        order: SortingType.DESC,
      }))
      pageChange(1)
    }

    useEffect(() => {
      setActiveBanTab(banTab as PunishmentEnum)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [banTab])

    if (!data || isLoading) return null

    const newProps = {
      ...props,
      data: data.data,
      page: data.page,
      total: data.total,
      tabs,
      activeBanTab,
      perpage,
      isLoading,
      sort,
      pageChange,
      handleActiveTabChange,
      navigate,
      handleSort,
    }

    return <Component {...newProps} />
  }
  return WithBanSection
}
