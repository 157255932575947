import { Expose } from 'class-transformer'

export class PromotionUserIdsUploadType {
  @Expose()
  userIds: number[]

  @Expose() fileName: string

  @Expose() filePath: string
}
