import { Expose, Transform } from 'class-transformer'

export class PaymentReportType {
  @Expose() paid: number

  @Expose() paidAfterDeductWithholdingTax: number

  @Expose() paymentCycleMonth: number

  @Expose() paymentCycleYear: number

  @Expose() productType: string

  @Expose() transactionId: string

  @Expose() userId: number

  @Expose() writerDisplayName: string

  @Expose() writerEmail: string

  @Expose() writerFullName: string

  @Expose() writerId: number

  @Expose({ name: 'userAvatarImgPath' }) avatarImg: string
}
