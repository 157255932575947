import cn from 'classnames'

import { CheckboxProps } from '@components/Checkbox/interface'
import { CheckIcon } from '@components/Icons'

function Checkbox({
  label,
  disabled,
  readOnly,
  className,
  classNameLabel,
  type = 'checkbox',
  ...props
}: CheckboxProps) {
  const checked = props.checked ?? props.defaultChecked

  return (
    <label className={cn('flex items-center', className)}>
      <div
        className={cn(
          'relative h-6 w-6 border border-gray rounded-[8px] bg-white',
          {
            'bg-primary border-primary': checked,
            '!bg-white !border-gray': disabled,
          }
        )}
      >
        <input
          className={cn('w-full h-full opacity-0', {
            'cursor-pointer': !disabled,
          })}
          type={type}
          disabled={disabled || readOnly}
          {...props}
        />
        {checked && (
          <CheckIcon
            width='16'
            height='16'
            className={cn(
              'text-white absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none',
              {
                'text-[#dddddd]': disabled,
              }
            )}
          />
        )}
      </div>

      {label && (
        <div
          className={cn(
            'text-[14px] ml-[10px] font-light text-secondary',
            classNameLabel
          )}
        >
          {label}
        </div>
      )}
    </label>
  )
}

export { Checkbox }
