import cn from 'classnames'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'
import { ActionCellProps } from './interface'

export function ActionCell({ id, className = '' }: ActionCellProps) {
  return (
    <div className={cn('flex gap-x-[15px] py-[10px]', className)}>
      <Link to={`/user/user-management/${id}?tab=coin`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-secondary-100' width='18' height='18' />
        </button>
      </Link>
    </div>
  )
}
