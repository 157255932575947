import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { BookSupporterType } from '@models/book/BookSupporterType'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookLinkType } from '@models/book/BookLinkType'
import { BookRatingType } from '@models/book/BookRatingType'
import { BookTagsType } from '@models/book/BookTagsType'
import { PenNameType } from '@models/penName/PenNameType'
import { BookEnum } from '@interfaces/BookEnum'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { ChapterTypeEnum } from '@interfaces/ChapterTypeEnum'
import { StoryFieldType } from './StoryFieldType'

@Exclude()
export class StoryDetailReaderType {
  @Expose() id: number

  @Expose() writer: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Transform(({ value }) =>
    value === WritingEnum.ORIGINAL
      ? 'ออริจินอล'
      : value === WritingEnum.FAN_FICTION
      ? 'แฟนฟิค'
      : ''
  )
  writingType: string

  @Expose() isTranslated: boolean

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() likeCount: number

  @Expose() viewCount: number

  @Expose() shelfAddedCount: number

  @Expose() lastCommentDate: string

  @Expose() commentCount: number

  @Expose() commentable: boolean

  @Expose() guestCommentable: boolean

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean

  @Expose() isEnded: boolean

  @Expose() isEbook: boolean

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose() intro: string

  @Expose() naiinCoverImgPath: string

  @Expose() naiinTitle: string

  @Expose() naiinCover: string

  @Expose() naiinLink: string

  @Expose() updatedAt: string

  @Expose() publishedAt: string

  @Expose() createdAt: string

  @Expose() isUserHasBlockedComment: boolean

  @Expose({ name: 'bookLink' })
  @Type(() => BookLinkType)
  bookLinks: BookLinkType[]

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  status: BookStatusEnum

  @Expose()
  @Transform(({ value }) => value || 0)
  amountSpent: number

  @Expose()
  @Transform(({ value }) => value || 0)
  sumCharacterDonate: number

  @Expose()
  @Transform(({ value }) => value || 0)
  chapterCount: number

  @Expose() bookType: BookEnum

  @Expose() chapterType: ChapterTypeEnum

  @Expose()
  @Type(() => BookSupporterType)
  supporters: BookSupporterType[]

  @Expose()
  @Transform(({ value }) => value ?? false)
  isLiked: boolean

  @Expose()
  @Type(() => StoryFieldType)
  bookStory: StoryFieldType[]
}
