import { useState } from 'react'

import { useUserWriter } from '@hooks/userWriter/useUserWriter'
import { usePagination } from '@hooks/usePagination'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { SortByType, SortingType } from '@components/Table/interface'
import { WriterSubmitPageProps, SearchParamsType } from './interface'

export function withWriterSubmitPage(
  Component: React.FC<WriterSubmitPageProps>
) {
  function WithWriterSubmitPage() {
    const [activeTab, setActiveTab] = useState<SearchUserStatusEnum>(
      SearchUserStatusEnum.ALL
    )

    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })

    const [searchParams, setSearchParams] = useState<SearchParamsType>({
      text: '',
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })

    const {
      data,
      total: userTotal,
      isLoading,
    } = useUserWriter({
      page,
      statusType: activeTab,
      searchText: searchParams.text,
      sortKey: searchParams.sortKey,
      sortOrder: searchParams.sortOrder,
      handleTotalChange,
    })

    function handleUpdateActiveTab(value: SearchUserStatusEnum) {
      setActiveTab(value)
      pageChange(1)
    }

    function handleSubmitSearch(text: string) {
      setSearchParams(prev => ({ ...prev, text }))
      pageChange(1)
    }

    function handleResetSearch() {
      setSearchParams(prev => ({ ...prev, text: '' }))
      pageChange(1)
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const componentProps = {
      activeTab,
      data: data || [],
      userTotal,
      page,
      perpage,
      total,
      isLoading,
      pageChange,
      handleSort,
      handleSubmitSearch,
      handleResetSearch,
      handleUpdateActiveTab,
    }

    return <Component {...componentProps} />
  }

  return WithWriterSubmitPage
}
