import { Expose } from 'class-transformer'
import { PromotionBuyCoinConditionEnum } from '@interfaces/PromotionBuyCoinConditionEnum'
import { PromotionPaymentMethodEnum } from '@interfaces/promotionDiscount/PromotionPaymentMethodEnum'
import { DiscountTypeEnum } from '@interfaces/DiscountTypeEnum'
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'

interface PromotionChannel {
  name: string
  totalCount: number
  totalUsedCount: number
}

interface CoinType {
  id: number
  name: string
}
export class PromotionCodeType {
  @Expose() id: number

  @Expose() type: UserByEnum

  @Expose() buyCoinConditionValue: number

  @Expose() buyCoinCondition: PromotionBuyCoinConditionEnum

  @Expose() channels: PromotionChannel[]

  @Expose() paymentChannels: PromotionPaymentMethodEnum[]

  @Expose() benefitValue: number

  @Expose() coin?: CoinType
}
