import { NavigationReadingPageProps } from './interface'
import { SelectChapter } from '../SelectChapter'

export function NavigationReadingPage({
  bookId,
  chapterName,
  chapterTitle,
}: NavigationReadingPageProps) {
  return (
    <div className='flex bg-white text-secondary justify-between px-[20px] h-[80px] items-center text-[14px] sticky top-0 mb-[20px] z-30 shadow-navbar'>
      <div className='flex items-center gap-x-[20px]'>
        <SelectChapter bookId={bookId} />
        <div className='font-light truncate max-w-[300px]'>
          {chapterTitle} {chapterName}
        </div>
      </div>
    </div>
  )
}
