import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Tab, TabBody, TabHeader, Tabs } from '@components/Tabs'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { NewsAndPromotionTable } from './components/NewsAndPromotionTable'
import { NewsAndPromotionListPageProps } from './interface'

export function NewsAndPromotionListPage({
  selectedIds,
  data,
  page,
  perpage,
  total,
  activeTab,
  handlePageChange,
  handleSort,
  handleSelectAll,
  handleSelect,
  handleDelete,
  handleDeleteAll,
  handleEnable,
  handleChangeTab,
  isLoading,
}: NewsAndPromotionListPageProps) {
  const navigate = useNavigate()
  const permission = usePermissionAction('newsPromotion')

  return (
    <Container>
      <section className='flex items-center justify-between'>
        <Container.Title showBackBtn={false}>News & Promotion</Container.Title>
        <div className='flex gap-x-[10px]'>
          <Button
            type='button'
            className={cn(
              'border border-none rounded-3xl px-5 py-1 text-sm font-medium font-mitr text-red bg-[#feeeef] h-[40px]',
              {
                hidden: !permission.delete,
              }
            )}
            onClick={() => handleDeleteAll(selectedIds)}
            disabled={selectedIds.length === 0}
          >
            ลบ
          </Button>
          <Button
            type='button'
            className={cn('text-[14px] text-white bg-secondary h-[40px]', {
              hidden: !permission.create,
            })}
            onClick={() => navigate({ pathname: '/news-promotion/create' })}
          >
            + เพิ่มข่าวสารและโปรโมชั่น
          </Button>
        </div>
      </section>
      <section>
        <Tabs
          initialActiveTab={activeTab}
          mode='line'
          onActiveTabChange={handleChangeTab}
        >
          <Tab tabId='all'>
            <TabHeader>
              <span>ทั้งหมด</span>
            </TabHeader>
            <TabBody>
              <NewsAndPromotionTable
                selectedIds={selectedIds}
                data={data}
                page={page}
                perpage={perpage}
                total={total}
                handleSort={handleSort}
                pageChange={handlePageChange}
                handleSelectAll={handleSelectAll}
                handleSelect={handleSelect}
                handleDelete={handleDelete}
                handleEnable={handleEnable}
                isLoading={isLoading}
              />
            </TabBody>
          </Tab>
          <Tab tabId={NewsTypeEnum.PROMOTION}>
            <TabHeader>
              <span>โปรโมชั่น</span>
            </TabHeader>
            <TabBody>
              <NewsAndPromotionTable
                selectedIds={selectedIds}
                data={data}
                page={page}
                perpage={perpage}
                total={total}
                handleSort={handleSort}
                pageChange={handlePageChange}
                handleSelectAll={handleSelectAll}
                handleSelect={handleSelect}
                handleDelete={handleDelete}
                handleEnable={handleEnable}
                isLoading={isLoading}
              />
            </TabBody>
          </Tab>
          <Tab tabId={NewsTypeEnum.NEWS}>
            <TabHeader>
              <span>ข่าวสาร</span>
            </TabHeader>
            <TabBody>
              <NewsAndPromotionTable
                selectedIds={selectedIds}
                data={data}
                page={page}
                perpage={perpage}
                total={total}
                handleSort={handleSort}
                pageChange={handlePageChange}
                handleSelectAll={handleSelectAll}
                handleSelect={handleSelect}
                handleDelete={handleDelete}
                handleEnable={handleEnable}
                isLoading={isLoading}
              />
            </TabBody>
          </Tab>
        </Tabs>
      </section>
    </Container>
  )
}
