import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { SearchDateRange } from '@features/dashboard/components/SearchDateRange'
import { Cover } from './components/Cover'
import { EbookPageProps } from './interface'
import { EbookOverviewTabs } from './components/EbookOverviewTabs'
import { BoughtEbookTable } from './components/BoughtEbookTable'

export function EbookPage({
  ebookId,
  data,
  isLoading,
  searchParams,
  handleSearch,
  handleReset,
  handleExport,
}: EbookPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>รายละเอียดอีบุ๊ก</Container.Title>
      {isLoading && <Loading />}
      {data && !isLoading && ebookId && (
        <>
          <Cover data={data} />
          <div className='flex items-center gap-x-[20px] mt-[20px]'>
            <SearchDateRange
              className='flex-1'
              onSearch={handleSearch}
              onReset={handleReset}
              onExport={handleExport}
            />
          </div>
          <EbookOverviewTabs ebookId={ebookId} searchParams={searchParams} />
          <BoughtEbookTable ebookId={ebookId} searchParams={searchParams} />
        </>
      )}
    </Container>
  )
}
