import { gql } from 'graphql-request'

export const GET_PAYING_VS_NON_USER_PAYING = gql`
  query GetPayingVsNonUserPaying($startDate: DateTime, $endDate: DateTime) {
    getPayingVsNonUserPaying(startDate: $startDate, endDate: $endDate) {
      day
      month
      year
      payingUserCount
      totalUser
    }
  }
`
