/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Element } from 'react-scroll'
import { VerticalStyleProps, WithVerticalStyleProps } from './interface'

export function withVerticalStyle(Component: React.FC<VerticalStyleProps>) {
  function WithVerticalStyle({
    chapterPages,
    chapterId,
    isReview = false,
  }: WithVerticalStyleProps) {
    function createUserReadingHandler(chapterPageId: number) {}

    const newProps = {
      Element,
      chapterPages,
      createUserReadingHandler,
    }
    return <Component {...newProps} />
  }
  return WithVerticalStyle
}
