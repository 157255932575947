import { DateTime } from 'luxon'
import React from 'react'

export const DAY = 86400000

export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('th-TH', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export function formatDateTime(
  date: string,
  options?: { showSecond?: boolean; separateText?: string }
) {
  const dateTimeString = new Date(date).toLocaleTimeString('th-TH', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: options?.showSecond ? '2-digit' : undefined,
  })
  if (options?.separateText) {
    const dateTimeStringWithSeparator = dateTimeString.split(' ')
    dateTimeStringWithSeparator.splice(3, 0, options.separateText)
    return dateTimeStringWithSeparator.join().replaceAll(',', ' ')
  }
  return dateTimeString
}

export function formatYmd(date: Date): string {
  const result = date.toISOString().slice(0, 10)

  return String(result)
}

export function abbreviateNumber(value: number, digit = 1): string {
  const defaultSymbols = ['', 'K', 'M', 'G', 'T', 'P', 'E']

  if (value <= 0) return value.toString()

  const tier = Math.floor(Math.log10(value) / 3)

  if (tier === 0) return value.toString()

  const scale = 10 ** (tier * 3)
  const scaled = value / scale

  const [number, decimal] = String(scaled).split('.')

  const res = `${number}${decimal ? `.${decimal.slice(0, digit)}` : ''}`

  return res + defaultSymbols[tier]
}

export function getErrorMessage(error: any): string {
  if (error.response.errors.length) {
    return error.response.errors[0].message
  }

  return 'Something wrong.'
}

export function diffNow(dateTime: string): string {
  const dateNow = new Date(dateTime)
  const dt = DateTime.fromJSDate(dateNow)
  let diffStr = ''
  if (dt) {
    const { minutes } = dt.diffNow('minutes')
    const { hours } = dt.diffNow('hours')
    const { days } = dt.diffNow('days')
    const { months } = dt.diffNow('months')
    const { years } = dt.diffNow('years')

    diffStr =
      Math.abs(Math.round(minutes)) < 1
        ? `เมื่อสักครู่`
        : Math.abs(Math.round(minutes)) < 60
        ? `${Math.abs(minutes).toFixed(0)} นาที`
        : Math.abs(Math.round(hours)) < 24
        ? `${Math.abs(hours).toFixed(0)} ชั่วโมง`
        : Math.abs(Math.round(months)) < 1
        ? `${Math.abs(days).toFixed(0)} วัน`
        : Math.abs(Math.round(months)) < 12
        ? `${Math.abs(months).toFixed(0)} เดือน`
        : `${Math.abs(years).toFixed(0)} ปี`
  }
  return diffStr
}

export function addComma(value: number, digit = 2) {
  const integer = value.toLocaleString().split('.')[0]
  const decimal = value.toFixed(digit).split('.')[1]

  return digit ? `${integer}.${decimal}` : integer
}

export function clearComma(value: string) {
  return Number(value.replaceAll(',', ''))
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export function formatDateDomini(
  date: string,
  isShowTime = false,
  isFullYear = true
) {
  const d = DateTime.fromISO(date)
  const t = d.setLocale('th')
  const yearFormat = isFullYear ? 'yyyy' : 'yy'
  let text = `${d.toFormat('dd')} ${t.toFormat('LLL')} ${d.toFormat(
    yearFormat
  )}`

  if (isShowTime) {
    text += ` ${d.toFormat('HH:mm')}`
  }

  return text
}

export function preventKeyBoardInput(
  inputEvent: React.KeyboardEvent<HTMLInputElement>,
  preventKey: string[]
) {
  if (preventKey.includes(inputEvent.key)) {
    inputEvent.preventDefault()
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function checkCurrentDate(value: Date, currentDate: Date) {
  return (
    value.getDate() === currentDate.getDate() &&
    value.getMonth() === currentDate.getMonth() &&
    value.getFullYear() === currentDate.getFullYear()
  )
}

export function showCommaFromString(value?: string) {
  if (!value) return '-'

  const [integer, decimal] = value.split('.')

  return `${Number(integer).toLocaleString()}.${decimal}`
}

export function diffDate(value1: Date, value2: Date) {
  if (value1.getFullYear() > value2.getFullYear()) {
    return 1
  }

  if (value1.getFullYear() < value2.getFullYear()) {
    return -1
  }

  if (value1.getMonth() > value2.getMonth()) {
    return 1
  }

  if (value1.getMonth() < value2.getMonth()) {
    return -1
  }

  if (value1.getDate() > value2.getDate()) {
    return 1
  }

  if (value1.getDate() < value2.getDate()) {
    return -1
  }

  return 0
}
