import { CodeTransactionTableProps } from './interface'

export function withCodeTransactionTable(
  Component: React.FC<CodeTransactionTableProps>
) {
  function WithCodeTransactionTable(props: CodeTransactionTableProps) {
    return <Component {...props} />
  }

  return WithCodeTransactionTable
}
