export enum PromotionUserEnum {
  NEW_USER = 'NEW_USER',
  ALL_USER = 'ALL_USER',
  SPECIFY_USER = 'SPECIFY_USER',
}

export const PromotionUserTypeValue = {
  [PromotionUserEnum.NEW_USER]: 'ผู้ใช้งานใหม่',
  [PromotionUserEnum.ALL_USER]: 'ผู้ใช้งานทั้งหมด',
  [PromotionUserEnum.SPECIFY_USER]: 'กำหนดผู้ใช้งาน',
}
