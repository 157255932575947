import { Outlet } from 'react-router-dom'

import { SalesLayout } from '@components/layouts/SalesLayout'
import { SalesReportPage } from './pages/SalesReportPage'
import { PaymentReportPage } from './pages/PaymentReportPage'
import { PaymentReportDetailPage } from './pages/PaymentReportDetailPage'

export const salesRoutes = [
  {
    path: 'sales',
    element: <Outlet />,
    children: [
      {
        path: 'sales-report',
        element: (
          <SalesLayout>
            <SalesReportPage />
          </SalesLayout>
        ),
      },
      {
        path: 'payment-report',
        element: (
          <SalesLayout>
            <PaymentReportPage />
          </SalesLayout>
        ),
      },
      {
        path: 'payment-report/:id',
        element: <PaymentReportDetailPage />,
      },
    ],
  },
]
