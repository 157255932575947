import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export function ActionCell({ userId }: { userId: number }) {
  return (
    <Link to={`/dashboard/reader?tab=byUser&userId=${userId}`}>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </Link>
  )
}
