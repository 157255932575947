import { Expose, Type } from 'class-transformer'
import { PopupBannerListType } from './PopupBannerListType'

export class PopupBannerListResponse {
  @Expose()
  @Type(() => PopupBannerListType)
  data: PopupBannerListType[]

  @Expose() page: number

  @Expose() total: number
}
