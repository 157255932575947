import { Expose, Transform } from 'class-transformer'

export class CategorySaleReportType {
  @Expose() id: number

  @Expose({ name: 'totalSales' })
  @Transform(({ value }) => value || 0)
  value: number

  @Expose() name: string
}
