import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class ReaderPayingVsNonUserPayingType {
  @Expose() year: number

  @Expose()
  @Transform(
    ({ value, obj }) => `${DateTime.now()
      .set({ month: value })
      .toFormat('LLL', { locale: 'th-TH' })
      .toString()} ${obj.year}
  `
  )
  month: string

  @Expose() day: string

  @Expose() payingUserCount: number

  @Expose() totalUser: number
}
