import React, { useRef } from 'react'
import { useFormikContext } from 'formik'
import { CoinFormType } from '@models/coin/CoinFormType'
import { UploadCoinButtonFieldProps } from './interface'

export function withUploadCoinButtonField(
  Component: React.FC<UploadCoinButtonFieldProps>
) {
  function WithUploadCoinButtonField() {
    const inputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } = useFormikContext<CoinFormType>()

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        setFieldValue('upload', {
          url: URL.createObjectURL(files[0]),
          blob: files[0],
        })
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: values?.upload?.url,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadCoinButtonField
}
