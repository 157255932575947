import { addComma } from '@libs/utils'
import { OverAllStatisProps } from './interface'

export function OverAllStatis({ data }: OverAllStatisProps) {
  return (
    <>
      <div className='grid grid-cols-[140px_1fr_120px]'>
        <div className='flex flex-col font-sarabun'>
          <div className='text-[14px] font-light text-secondary-100'>
            จำนวนเรื่องทั้งหมด
          </div>
          <div className='text-[24px] font-bold text-secondary'>
            {addComma(data.countBook, 0)}
          </div>
        </div>
        <div className='flex flex-col font-sarabun'>
          <div className='text-[14px] font-light text-secondary-100'>
            จำนวนตอนทั้งหมด
          </div>
          <div className='text-[24px] font-bold text-secondary'>
            {addComma(data.countBookChapter, 0)}
          </div>
        </div>
        <div className='flex flex-col font-sarabun'>
          <div className='text-[14px] font-light text-secondary-100'>
            จำนวนตอนที่เผยแพร่
          </div>
          <div className='text-[24px] font-bold text-primary'>
            {addComma(data.countBookChapterPublish, 0)}
          </div>
        </div>
      </div>

      <hr className='border border-gray h-[1px] w-full' />
    </>
  )
}
