import { gql } from 'graphql-request'

export const SEARCH_USER = gql`
  query SearchUser($searchText: String, $page: Int, $limitPerPage: Int) {
    searchUser(
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
    ) {
      page
      total
      data {
        avatarImgPath
        username
        id
      }
    }
  }
`
