import { Transform, Expose } from 'class-transformer'
import type { ImageType } from '@interfaces/ImageType'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { DAY } from '@libs/utils'
import { NewsLogsType } from './NewsLogsType'

export class NewsAndPromotionFormType {
  id?: number

  @Transform(({ value }) => ({ url: value }))
  bannerImgPath: ImageType

  @Transform(({ value }) => ({ url: value }))
  thumbnailImgPath: ImageType

  name: string

  slug: string

  description: string

  content: string

  isWeb: boolean

  isIos: boolean

  isAndroid: boolean

  @Transform(({ value }) => (value ? new Date(value) : value))
  startPublishedAt: Date

  @Transform(({ value }) =>
    value ? new Date(value) : new Date(Date.now() + DAY * 30)
  )
  endPublishedAt: Date

  @Transform(({ value }) => value.toUpperCase())
  newsType: NewsTypeEnum

  @Transform(({ value }) =>
    value.sort(
      (a: NewsLogsType, b: NewsLogsType) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  )
  newsLogs: NewsLogsType[]

  @Expose()
  @Transform(({ obj }) => {
    return obj.startPublishedAt && obj.endPublishedAt
      ? PublishedEnum.CUSTOM
      : PublishedEnum.IMMEDIATELY
  })
  publishedType: PublishedEnum

  @Expose()
  verifySlug: boolean

  @Expose()
  isCreateNoti: boolean
}
