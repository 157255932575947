import ReactECharts from 'echarts-for-react'

import { PieChartProps } from './interface'

export function PieChart({ option, showLoading, ...props }: PieChartProps) {
  return (
    <ReactECharts
      option={option}
      notMerge
      lazyUpdate
      showLoading={showLoading}
      opts={{ renderer: 'svg' }}
      style={{ height: '300px', width: '100%' }}
      {...props}
    />
  )
}
