import { withPermissionGuard } from '@libs/permission'
import { EditHighlightBannerBookPage } from './EditHighlightBannerBookPage'
import { withEditHighlightBannerBookPage } from './withEditHighlightBannerBookPage'

const Connected = withPermissionGuard(
  withEditHighlightBannerBookPage(EditHighlightBannerBookPage),
  'homePageManagement',
  'edit'
)

export { Connected as EditHighlightBannerBookPage }
