import React from 'react'

import { ToggleProps } from './interface'

export function withToggle(Component: React.FC<ToggleProps>) {
  function WithToggle(props: ToggleProps) {
    return <Component {...props} />
  }

  return WithToggle
}
