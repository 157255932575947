import cn from 'classnames'
import { DateTime } from 'luxon'

import { formatDate } from '@libs/utils'
import { Avatar } from '@components/Avatar'
import { Container } from '@components/Container'
import { InformationLevel } from '@features/user-mission/components/InformationLevel'
import { ChallengeStatus } from '@interfaces/user-mission/ChallengeEnum'
import { UserMissionHistoryPageProps } from './interface'
import { EditSectionLog } from './components/EditSectionLog/EditSectionLog'
import { CreateSectionLog } from './components/CreateSectionLog/CreateSectionLog'

export function UserMissionHistoryPage({
  data,
  optionData,
  activeIndex,
  handleChangeHistory,
}: UserMissionHistoryPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>ดูประวัติการแก้ไข Mission</Container.Title>

      <div className='grid grid-cols-[185px_10px_1fr] h-full'>
        <div className='overflow-y-auto overflow-x-hidden min-w-[170px] w-[170px]'>
          <div className='flex flex-col gap-y-[20px] text-[14px] text-secondary-100 font-bold pb-[10px]'>
            {optionData.map((item, index) => (
              <button
                key={index}
                type='button'
                className={cn('flex items-center justify-left', {
                  'text-secondary font-bold': index === activeIndex,
                })}
                onClick={() => handleChangeHistory(index)}
              >
                {item.text}
              </button>
            ))}
          </div>
        </div>

        <div className='border-l border-gray h-full m-auto' />

        <div className='flex flex-col gap-y-[30px] ml-[45px]'>
          <div className='shadow border border-blue-2 rounded-lg p-[30px]'>
            <div className='grid grid-cols-[72px_1fr] gap-x-[20px] items-center'>
              <div className='border border-blue-2 w-[72px] aspect-square rounded-full overflow-hidden'>
                <img
                  className='w-full h-full'
                  src={data?.challenge?.iconUrl}
                  alt={data?.levelName}
                />
              </div>
              <div>
                <div className='flex gap-x-[20px] text-[18px] font-bold'>
                  <p className='text-primary'>Reader</p>
                  <div className='border-r border-r-gray' />
                  <p className='text-secondary'>{data?.challenge?.title}</p>
                </div>
              </div>
            </div>

            <div className='border-t border-gray my-[30px]' />

            <p className='text-[18px] text-secondary font-bold mb-[14px]'>
              เงื่อนไข
            </p>

            <table className='text-[14px] text-black-480 font-light'>
              <tbody>
                {data?.challengeLevelLogs.map((challenge, i) => (
                  <InformationLevel
                    key={challenge.id}
                    id={challenge.id}
                    level={challenge.level}
                    levelName={challenge.levelName}
                    criteria={data.challenge.criteria}
                    target={challenge.target}
                    toTarget={
                      (data?.challengeLevelLogs || []).length - 1 === i
                        ? 0
                        : (data?.challengeLevelLogs || [])[i + 1].target
                    }
                    challengeUnit={data.challenge.challengeUnit}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <div className='grid grid-cols-[91px_1fr_100px] gap-x-[10px] items-center text-sm text-secondary-100'>
            <p className='text-secondary-100 font-bold'>ดำเนินการโดย :</p>
            <div className='flex items-center gap-x-[10px]'>
              <Avatar
                className='w-[50px] h-[50px]'
                src={data?.user?.avatarImgPath}
                alt={data?.user?.displayName}
              />
              <div className='flex flex-col gap-y-[4px]'>
                <p className='font-bold text-secondary'>
                  {data?.user?.displayName}
                </p>
                <p>
                  {formatDate(data?.createdAt)} -{' '}
                  {DateTime.fromISO(data?.createdAt).toLocaleString(
                    DateTime.TIME_24_WITH_SECONDS
                  )}
                </p>
              </div>
            </div>
            <div className='text-primary'>
              {data?.type === ChallengeStatus.CREATE
                ? '(สร้างข้อมูล)'
                : '(แก้ไขข้อมูล)'}
            </div>
          </div>

          <div className='border-t border-gray' />

          <div>
            <p className='text-secondary-100 font-light text-[14px]'>
              รายละเอียดการแก้ไข :
            </p>

            {data?.type === ChallengeStatus.CREATE && (
              <CreateSectionLog
                level={data?.level}
                levelName={data?.levelName}
                target={data?.target}
                criteria={data?.challenge?.criteria}
                challengeUnit={data?.challenge?.challengeUnit}
              />
            )}

            {data?.type === ChallengeStatus.UPDATE && (
              <EditSectionLog
                level={data?.level}
                levelName={data?.levelName}
                target={data?.target}
                newLevel={data?.level}
                newLevelName={data?.newLevelName}
                newTarget={data?.newTarget}
                criteria={data?.challenge?.criteria}
                challengeUnit={data?.challenge?.challengeUnit}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
