import { PieChart } from '@components/chart/PieChart'
import { DeviceProps } from './PerformanceTemplate/interface'

export function Device({ deviceCategory }: DeviceProps) {
  return (
    <div className='w-full'>
      <PieChart
        title='Device'
        data={deviceCategory}
        legendStyle='left'
        style={{ width: '100%' }}
      />
    </div>
  )
}
