import { Container } from '@components/Container'
import { HighlightBannerBookForm } from '@features/home/components/HighlightBannerBookForm'
import { CreateHighlightBannerPosterPageProps } from './interface'

export function CreateHighlightBannerBookPage({
  data,
  onSubmit,
}: CreateHighlightBannerPosterPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between mb-5'>
        <Container.Title showBackBtn>เพิ่มนิยายสำหรับแบนเนอร์</Container.Title>
      </section>

      <HighlightBannerBookForm data={data} onSubmit={onSubmit} />
    </Container>
  )
}
