import { InformationLevel } from '@features/user-mission/components/InformationLevel'
import { UserMissionValueEnum } from '@interfaces/user-mission/UserMissionEnum'
import { InformationProps } from './interface'

export function Information({
  dataUserMission,
  dataLevelUserCount,
}: InformationProps) {
  const {
    iconUrl,
    challengeType,
    title,
    description,
    challengeLevels,
    criteria,
    challengeUnit,
  } = dataUserMission

  return (
    <div>
      <div className='p-[30px] border border-blue-2 shadow rounded-[8px] mb-[30px]'>
        <div className='grid grid-cols-[72px_1fr] gap-x-[20px] pb-[30px] border-b border-b-gray mb-[30px] items-center'>
          <div className='border border-blue-2 rounded-full w-[72px] aspect-square overflow-hidden'>
            <img
              className='w-full h-full'
              src={iconUrl}
              alt='logo-user-mission'
            />
          </div>
          <div>
            <div className='flex text-[18px] text-black-480 font-bold'>
              <div className='pr-[20px] mr-[20px] border-r border-r-gray text-primary'>
                {UserMissionValueEnum[challengeType]}
              </div>
              <div className='text-secondary'>{title}</div>
            </div>
          </div>
        </div>
        <div>
          <div className='text-[18px] text-secondary font-bold mb-[20px]'>
            เงื่อนไข
          </div>

          <table className='text-[14px] text-black-480 font-light'>
            <tbody>
              {challengeLevels.map((challenge, i) => (
                <InformationLevel
                  key={challenge.id}
                  id={challenge.id}
                  level={challenge.level}
                  levelName={challenge.levelName}
                  criteria={criteria}
                  target={challenge.target}
                  toTarget={
                    challengeLevels.length - 1 === i
                      ? 0
                      : challengeLevels[i + 1].target
                  }
                  challengeUnit={challengeUnit}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='border-b border-b-gray pb-[30px] mb-[30px]'>
        <div className='flex items-center gap-[50px] flex-wrap'>
          {dataLevelUserCount.map(row => (
            <div key={row.id}>
              <div className='text-[14px] font-light text-secondary-100'>
                User ใน Lv. {row.level}
              </div>
              <div className='text-[24px] text-secondary font-bold'>
                {row.userCount}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
