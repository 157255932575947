import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import Tooltip from '@reach/tooltip'
import { usePortalDropdown } from '@hooks/useDropdown'
import { StickerManageType } from '@models/sticker/StickerManageType'
import { TiptapStickerButtonProps } from './interface'

export function TiptapStickerButton({
  stickerList,
  icon,
  editor,
  handleFetchNextPage,
  tooltipLabel,
  isTransparentBackground = false,
}: TiptapStickerButtonProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  useEffect(() => {
    function handleKeydown(event: KeyboardEvent) {
      if (event.key === 'Enter' && isShowDropdown && buttonRef?.current) {
        toggleDropdown(buttonRef.current)
      }
    }
    window.addEventListener('keydown', handleKeydown)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDropdown])

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            ref={buttonRef}
            type='button'
            className={cn(
              'rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
              {
                'bg-transparent': isTransparentBackground,
                'bg-gray-2': !isTransparentBackground,
              }
            )}
            onClick={toggleDropdown}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          ref={buttonRef}
          type='button'
          className={cn(
            'rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
            {
              'bg-transparent': isTransparentBackground,
              'bg-gray-2': !isTransparentBackground,
            }
          )}
          onClick={toggleDropdown}
        >
          {icon}
        </button>
      )}
      <Dropdown zIndex={100}>
        <div
          className={cn(
            'p-[10px] mt-[8px] bg-white border-1 border-gray rounded-[10px] max-h-[400px] shadow',
            { hidden: !isShowDropdown }
          )}
          onClick={event => event.stopPropagation()}
        >
          <div
            className='w-[300px] h-[200px] gap-[5px] grid grid-cols-5 overflow-y-auto'
            onScroll={(e: any) => {
              const scrollHeight = e.target.scrollHeight - 10
              const scrollTop = e.target.scrollTop + e.target.offsetHeight
              if (scrollHeight <= scrollTop) {
                handleFetchNextPage()
              }
            }}
          >
            {stickerList?.pages.map(page =>
              page.data.map((sticker: StickerManageType) => (
                <div
                  className='cursor-pointer select-none w-[50px] h-[50px]'
                  key={sticker.id}
                  onClick={(e: any) => {
                    editor
                      .chain()
                      .focus()
                      .setImage({ src: sticker.imageUrl, alt: 'sticker' })
                      .run()
                    toggleDropdown(e)
                  }}
                >
                  <img
                    src={sticker.imageUrl}
                    alt='sticker'
                    className='w-full h-full'
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </Dropdown>
    </div>
  )
}
