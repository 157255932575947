import { ChartOptionType } from '@interfaces/ChartOptionType'
import { Expose, Transform } from 'class-transformer'

export class TotalUserAgeRangeType {
  @Expose()
  @Transform(({ obj }) => {
    return [
      { value: obj?.ageBelowFifteen || 0, name: 'ต่ำกว่า 15' },
      { value: obj?.ageBetweenSixteenAndTwenty || 0, name: '16 - 20' },
      { value: obj?.ageBetweenTwentyOneAndThirty || 0, name: '21-30' },
      { value: obj?.ageBetweenThirtyOneAndForty || 0, name: '31-40' },
      { value: obj?.ageMoreThanFortyOne || 0, name: '41 ขึ้นไป' },
      { value: obj?.otherAge || 0, name: 'ไม่ระบุ' },
    ]
  })
  readerAge: ChartOptionType[]
}
