import { withPermissionGuard } from '@libs/permission'
import { PromotionCodeHistoryPage } from './PromotionCodeHistoryPage'
import { withPromotionCodeHistoryPage } from './withPromotionCodeHistoryPage'

const ConnectedPromotionCodeHistoryPage = withPermissionGuard(
  withPromotionCodeHistoryPage(PromotionCodeHistoryPage),
  'promotionSetting',
  'view'
)

export { ConnectedPromotionCodeHistoryPage as PromotionCodeHistoryPage }
