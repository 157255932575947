import { TiptapViewer } from '@components/TiptapViewer'
import { Box } from '../Box'
import { IntroductionProps } from './interface'

export function Introduction({ intro }: IntroductionProps) {
  return (
    <>
      <Box className='mt-5'>
        <Box.Title className='font-mitr'>แนะนำเรื่อง</Box.Title>
        <Box.Paragraph className='mt-5'>
          <TiptapViewer value={intro} />
        </Box.Paragraph>
      </Box>
    </>
  )
}
