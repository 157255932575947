import { Formik, Form } from 'formik'
import cn from 'classnames'

import { GenderEnum } from '@interfaces/GenderEnum'
import { LogTimeEnum } from '@interfaces/LogTimeEnum'
import { AgeRangeEnum } from '@interfaces/AgeRangeEnum'
import { Button } from '@components/Button'
import { SelectField } from '@components/forms/SelectField'
import { InputField } from '@components/forms/InputField'
import { SearchIcon } from '@components/Icons'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { SearchFormProps } from './interface'

const genderOption = [
  { value: GenderEnum.ALL, label: 'ทั้งหมด' },
  { value: GenderEnum.MALE, label: 'ชาย' },
  { value: GenderEnum.FEMALE, label: 'หญิง' },
  { value: GenderEnum.OTHER, label: 'ไม่ระบุเพศ' },
]

const logTimeOption = [
  { value: LogTimeEnum.A_DAY_AGO, label: '1 วัน' },
  { value: LogTimeEnum.A_MONTH_AGO, label: '1 เดือน' },
  { value: LogTimeEnum.A_YEAR_AGO, label: '1 ปี' },
]

const ageRangeOption = [
  { value: AgeRangeEnum.ALL, label: 'ทั้งหมด' },
  { value: AgeRangeEnum.AGE_BELOW_15, label: 'ต่ำกว่า 15' },
  { value: AgeRangeEnum.AGE_16_TO_20, label: '16-20' },
  { value: AgeRangeEnum.AGE_21_TO_30, label: '21-30' },
  { value: AgeRangeEnum.AGE_31_TO_40, label: '31-40' },
  { value: AgeRangeEnum.AGE_41_TO_50, label: '41-50' },
  { value: AgeRangeEnum.AGE_ABOVE_50, label: 'มากกว่า 50' },
]
export function SearchForm({
  initialValues,
  handleSubmit,
  isLoading,
  handleExport,
}: SearchFormProps) {
  const permission = usePermissionAction('searchManagement')
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, resetForm, submitForm }) => (
        <Form>
          <div className='flex flex-col gap-y-[20px] mb-[30px]'>
            <InputField
              name='searchText'
              placeholder='ค้นหาคำค้นหา, ผู้ค้นหา หรืออื่นๆ'
              leftIcon={<SearchIcon className='text-primary' />}
              inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
            />
            <div className='flex gap-x-[70px]'>
              <div className='flex-1 grid grid-cols-3 gap-x-[20px]'>
                <SelectField
                  name='display'
                  label='แสดง'
                  options={logTimeOption}
                />
                <SelectField name='gender' label='เพศ' options={genderOption} />
                <SelectField
                  name='ageRange'
                  label='ช่วงอายุ'
                  options={ageRangeOption}
                />
              </div>
              <div className='flex font-mitr items-end justify-end ml-[25px] gap-x-[20px] divide-x divide-gray'>
                <div className='flex'>
                  <Button
                    type='button'
                    className='text-secondary underline'
                    onClick={() => {
                      resetForm()
                      submitForm()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white '
                    disabled={isLoading}
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                </div>
                <div
                  className={cn('pl-[20px]', {
                    hidden: !permission.download,
                  })}
                >
                  <ExportButton handleClick={handleExport} />
                </div>
              </div>
            </div>
            <hr className='border-t-[1px] mt-[10px] border-gray' />
          </div>
        </Form>
      )}
    </Formik>
  )
}
