export enum PromotionStatusEnum {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  STARTED = 'STARTED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  GENERATE_CODE = 'GENERATE_CODE',
  CODE_GENERATED = 'CODE_GENERATED',
  GENERATE_FAIL = 'GENERATE_FAIL',
}

export const PromotionStatusTypeValue = {
  [PromotionStatusEnum.DRAFT]: 'แบบร่าง',
  [PromotionStatusEnum.PENDING]: 'รอการอนุมัติ',
  [PromotionStatusEnum.APPROVED]: 'อนุมัติแล้ว',
  [PromotionStatusEnum.STARTED]: 'ดำเนินการแล้ว',
  [PromotionStatusEnum.REJECTED]: 'Rejected',
  [PromotionStatusEnum.CLOSED]: 'ปิดโปรโมชั่น',
  [PromotionStatusEnum.GENERATE_CODE]: 'สร้างโค้ด',
  [PromotionStatusEnum.CODE_GENERATED]: 'สร้างโค้ดแล้ว',
  [PromotionStatusEnum.GENERATE_FAIL]: 'สร้างโค้ดไม่สำเร็จ',
}
