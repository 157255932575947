import { Expose, Type } from 'class-transformer'
import { EbookDashboardByUserType } from './EbookDashboardByUserType'

export class EbookDashboardByUserResponse {
  @Expose()
  @Type(() => EbookDashboardByUserType)
  data: EbookDashboardByUserType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
