import { gql } from 'graphql-request'

export const UPDATE_CATEGORY_COVER = gql`
  mutation UpdateCategoryCover(
    $updateCategoryCoverInput: UpdateCategoryCoverInput!
  ) {
    updateCategoryCover(updateCategoryCoverInput: $updateCategoryCoverInput) {
      id
    }
  }
`
