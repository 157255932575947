import { Link } from 'react-router-dom'
import { ColumnType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { ReaderTopMoneySpenderType } from '@models/dashboard/ReaderTopMoneySpenderType'

export const columns: ColumnType<ReaderTopMoneySpenderType>[] = [
  {
    column: 'User ID',
    accessor: 'userId',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: 'usersId',
    cellClassName: 'py-[10px] w-[80px] pr-[10px]',
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p className='line-clamp-1 break-word'>@{value}</p>,
    isSortable: true,
    sortKey: 'userUsername',
    cellClassName: 'py-[10px] pr-[10px]',
  },
  {
    column: 'Coin',
    accessor: 'userCoinSpend',
    cell: ({ value }) => <p>{Number(value).toLocaleString()}</p>,
    isSortable: true,
    cellClassName: 'py-[10px] w-[120px] pr-[10px]',
  },
  {
    column: 'Action',
    cellClassName: 'w-[48px] text-right',
    actionCell: ({ obj }) => (
      <Link to={`/dashboard/reader?tab=byUser&userId=${obj.userId}`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isAction: true,
  },
]
