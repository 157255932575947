import { PieChart } from '@components/chart/PieChart'
import { TrafficByUserType } from '@models/dashboard/TrafficByUserType'

export function TraficChart({
  readerTraffic,
}: {
  readerTraffic: TrafficByUserType[]
}) {
  const calHeight = `${readerTraffic.length * 42 + 264}px`
  return (
    <div className='w-full'>
      <PieChart
        title='Trafic'
        data={readerTraffic}
        legendStyle='bottom'
        style={{
          height: `${readerTraffic.length * 42 + 264}px`,
          width: '100%',
        }}
      />
    </div>
  )
}
