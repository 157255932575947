import { useNavigate } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export function ActionCell({ bookId }: { bookId: number }) {
  const navigate = useNavigate()
  return (
    <div className='flex gap-x-[10px] py-[10px]'>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
        onClick={() => {
          navigate({ pathname: `/preview/${bookId}` })
        }}
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </div>
  )
}
