import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useDebounce } from '@hooks/useDebounce'
import { UserOptionType } from '@models/user/UserOptionResponse'
import { useOutside } from '@hooks/useOutside'
import { WithSearchUserFieldProps, SearchUserFieldProps } from './interface'

const withSearchUserField = (Component: React.FC<SearchUserFieldProps>) => {
  function WithSearchUserField({
    name,
    text,
    setText,
    resetHandler,
    ...props
  }: WithSearchUserFieldProps) {
    const client = useClient()
    const searchRef = useRef<HTMLDivElement>(null)
    const [isShowSearchLog, setIsShowSearchLog] = useState<boolean>(false)
    const [textSearch, setTextSearch] = useState('')
    const { submitForm } = useFormikContext()
    const [field, , { setValue }] = useField(name)
    const { debounce } = useDebounce()

    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['search-user', textSearch],
        ({ pageParam = 1 }) =>
          client?.userClient.searchUser(textSearch, pageParam),
        {
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function showSearchLog() {
      setIsShowSearchLog(true)
    }

    function hideSearchLog() {
      setIsShowSearchLog(false)
    }

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
      setText(event.target.value)
    }

    function handleSelectWriter(user: UserOptionType) {
      setValue(user.id)
      setText(user.username)
      hideSearchLog()
      submitForm()
    }

    function removeTextSearch() {
      if (resetHandler) {
        resetHandler()
      }
      setValue(undefined)
      setText('')
    }

    const userOption = useMemo(
      () => data?.pages.flatMap(page => page!.data) || [],
      [data]
    )

    useOutside(searchRef, () => {
      const prevText =
        userOption.find(user => user.id === field.value)?.username || ''
      hideSearchLog()
      setText(prevText)
    })

    useEffect(() => {
      debounce(() => {
        setTextSearch(text)
      }, 400)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const componentsProps = {
      ...props,
      text,
      isShowSearchLog,
      value: field.value || '',
      userOption,
      showSearchLog,
      handleSelectWriter,
      handleTextChange,
      searchRef,
      handleFetchNextPage,
      removeTextSearch,
    }
    return <Component {...componentsProps} />
  }

  return WithSearchUserField
}

export { withSearchUserField }
