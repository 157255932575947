import { gql } from 'graphql-request'

export const GET_PAYMENT_REPORTS = gql`
  query GetPaymentsReports(
    $limitPerPage: Int
    $page: Int
    $year: Float
    $monthNo: Float
    $writerId: Float
    $orderBy: GetPaymentsReportsOrderedList
  ) {
    getPaymentsReports(
      limitPerPage: $limitPerPage
      page: $page
      year: $year
      monthNo: $monthNo
      writerId: $writerId
      orderBy: $orderBy
    ) {
      totalPaid
      total
      page
      data {
        paid
        paidAfterDeductWithholdingTax
        paymentCycleMonth
        paymentCycleYear
        productType
        transactionId
        userId
        writerDisplayName
        writerEmail
        writerFullName
        writerId
        userAvatarImgPath
      }
    }
  }
`
