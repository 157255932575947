import { gql } from 'graphql-request'

export const PRMOTION_CODE_LOG_COUNT = gql`
  query PromotionCodeLogCount($promotionCodeId: Int!, $searchText: String) {
    promotionCodeLogCount(
      promotionCodeId: $promotionCodeId
      searchText: $searchText
    ) {
      totalCount
      usedCount
      remainingCount
    }
  }
`
