import { SelectButtonLine } from '@components/SelectButtonLine'
import { PaymentReportEnum } from '@interfaces/PaymentReportTabEnum'
import { ReportByUser } from './components/ReportByUser'
import { ReportOverview } from './components/ReportOverview'
import { PaymentReportPageProps } from './interface'

const tabs = [
  { value: PaymentReportEnum.OVERVIEW, text: 'Report Overview' },
  { value: PaymentReportEnum.BY_USER, text: 'By User' },
]

export function PaymentReportPage({
  handleChangeTab,
  activeTab,
}: PaymentReportPageProps) {
  return (
    <>
      <SelectButtonLine
        options={tabs}
        value={activeTab}
        onChange={handleChangeTab}
        className='gap-y-[15px] mt-[20px] mb-[30px]'
      />
      {activeTab === PaymentReportEnum.OVERVIEW ? (
        <ReportOverview />
      ) : (
        <ReportByUser />
      )}
    </>
  )
}
