import { Formik, Form } from 'formik'

import { Button } from '@components/Button'
import { TextInputField } from '@components/forms/TextInputField'
import { UploadImageField } from '@components/forms/UploadImageField'
import { CheckboxField } from '@components/forms/CheckboxField'
import { InputField } from '@components/forms/InputField'
import { validationSchema } from './validationSchema'
import { HighlightBannerPosterFormProps } from './interface'

export function HighlightBannerPosterForm({
  initialValues,
  onSubmit,
}: HighlightBannerPosterFormProps) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ resetForm, dirty, isValid, isSubmitting, values }) => {
        return (
          <Form>
            <section className='flex flex-col gap-y-[30px] border border-blue-2 rounded-lg p-[30px] shadow'>
              <UploadImageField
                textSize='2100 X 900'
                name='imgPath'
                textBtn='อัปโหลดรูปภาพแบนเนอร์'
                className='w-full'
                ratio={9 / 21}
              />

              <TextInputField
                name='description'
                label='Description'
                placeholder='Description'
              />

              <TextInputField
                type='url'
                name='rawLink'
                label='Link'
                placeholder='Link'
              />

              <div className='flex items-center gap-x-[16px] text-[12px] font-light text-secondary-100'>
                <div>Display :</div>
                <CheckboxField
                  name='isOnWeb'
                  label='Web'
                  checked={values.isOnWeb}
                />
                <CheckboxField
                  name='isOnIos'
                  label='iOS'
                  checked={values.isOnIos}
                />
                <CheckboxField
                  name='isOnAndroid'
                  label='Android'
                  checked={values.isOnAndroid}
                />
              </div>
            </section>

            <section className='flex justify-center gap-x-5 my-[30px]'>
              <Button
                type='button'
                className='border border-secondary-100 text-[14px] text-secondary-100 min-w-[100px]'
                onClick={() => resetForm()}
              >
                ยกเลิก
              </Button>

              <Button
                type='submit'
                className='border border-primary bg-primary text-[14px] text-white min-w-[100px] disabled:border-primary/90 disabled:bg-primary/90 disabled:cursor-not-allowed'
                disabled={!(dirty && isValid && !isSubmitting)}
              >
                บันทึก
              </Button>
            </section>
          </Form>
        )
      }}
    </Formik>
  )
}
