import { Expose, Type } from 'class-transformer'

export class EbookOptionType {
  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() id: number
}

export class SearchEbookResponse {
  @Expose() page: number

  @Expose() total: number

  @Expose()
  @Type(() => EbookOptionType)
  data: EbookOptionType[]
}
