import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import { useAlert } from '@hooks/useAlert'
import { PromotionSaleHistoryPageProps } from './interface'

export function withPromotionSaleHistoryPage(
  Component: React.FC<PromotionSaleHistoryPageProps>
) {
  function WithPromotionSaleHistoryPage() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { id } = useParams()
    const [requestId, setRequestId] = useState<number | undefined>(undefined)
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })

    const { data: historyList, isLoading: listLoading } = useQuery(
      ['promotion', Number(id), 'history-list'],
      () => client?.promotionClient.getPromotionHistoryList(Number(id)),
      {
        onSuccess: res => {
          if (res && res.length >= 1) {
            setRequestId(res[0].id)
          }
        },
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: historyData, isLoading: detailLoading } = useQuery(
      ['promotion', requestId, 'history-detail'],
      () => client?.promotionSaleClient.getPromotionSaleHistoryById(requestId!),
      {
        enabled: !!requestId,
      }
    )

    function selectIdHandle(selectId: number) {
      setRequestId(selectId)
    }

    const { mutateAsync: approvePromotionCode, isLoading: approvedLoading } =
      useMutation(
        (promotionRequestId: number) =>
          client!.promotionClient.updatePromotionRequest({
            id: promotionRequestId,
            status: PromotionStatusEnum.APPROVED,
          }),
        {
          onSuccess: () => {
            alert.success(`ดำเนินการสำเร็จ`)
            queryClient.invalidateQueries([
              'promotion',
              requestId,
              'history-detail',
            ])
          },
          onError: () => {
            alert.error('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
          },
        }
      )

    function handleApprove() {
      if (requestId) {
        approvePromotionCode(requestId)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId,
        promotionId: id,
        promotionName: historyData?.newPromotion.name,
        startPromotion: new Date(historyData!.newPromotion.startPublishedAt),
        endPromotion: new Date(historyData!.newPromotion.endPublishedAt),
        createdAt: new Date(historyData!.createdAt),
        promotionDetail: historyData?.newPromotion.description,
        onSuccess: () =>
          queryClient.invalidateQueries([
            'promotion',
            requestId,
            'history-detail',
          ]),
      })
    }

    function goBackHandle() {
      navigate(-1)
    }

    const newProps = {
      promotionId: id,
      historyData,
      historyList,
      listLoading,
      detailLoading,
      approvedLoading,
      requestId,
      selectIdHandle,
      handleApprove,
      handleReject,
      goBackHandle,
    }

    return <Component {...newProps} />
  }

  return WithPromotionSaleHistoryPage
}
