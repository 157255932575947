import { Outlet } from 'react-router-dom'
import { StoryPage } from '@features/story/pages/StoryPage'

export const storyRoutes = [
  {
    path: 'story',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <StoryPage />,
      },
    ],
  },
]
