import { Exclude, Expose, Type } from 'class-transformer'

import { PromotionRequestActionEnum } from '@interfaces/PromotionRequestActionEnum'
import { PromotionRequestStatusEnum } from '@interfaces/PromotionRequestStatusEnum'
import { PromotionStatusEnum } from '@interfaces/PromotionStatusEnum'
import { PromotionChallengeDetailType } from './PromotionChallengeDetailType'
import { PromotionChallengeCollectionDetailType } from './PromotionChallengeCollectionDetailType'

class User {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath?: string
}

export class PromotionRequestType {
  @Expose()
  id: number

  @Expose()
  status: PromotionRequestStatusEnum

  @Expose()
  @Type(() => User)
  createdBy: User

  @Expose()
  @Type(() => User)
  approvedBy?: User

  @Expose() rejectedReason?: string

  @Expose()
  oldPromotion: {
    status: PromotionStatusEnum
  }

  @Expose()
  @Type(() => PromotionChallengeDetailType)
  newPromotion: PromotionChallengeDetailType

  @Expose()
  action?: PromotionRequestActionEnum

  @Expose()
  @Type(() => PromotionChallengeCollectionDetailType)
  correctionDetail?: PromotionChallengeCollectionDetailType

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  createdAt: string // DESC: DateTime string

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  updatedAt: string // DESC: DateTime string

  @Expose({ toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  deletedAt?: string // DESC: DateTime string
}
