import { useNavigate } from 'react-router-dom'

import {
  ArrowLeftIcon,
  ClipboardCheckIcon,
  ClipboardPendingIcon,
  ClipboardCancelIcon,
} from '@components/Icons'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { useWriterDetail } from '../hooks/useWriterDetail'

export function Navigation() {
  const { writer } = useWriterDetail()
  const navigate = useNavigate()

  return (
    <div className='flex items-center gap-x-[20px]'>
      <button
        className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
        type='button'
        onClick={() => navigate(-1)}
      >
        <ArrowLeftIcon width='24' height='24' />
      </button>
      <h1 className='font-mitr font-medium text-[24px] text-secondary line-clamp-1 break-word'>
        @{writer?.user?.username}
      </h1>

      {[
        WriterStatusEnum.CREATED_PENDING,
        WriterStatusEnum.EDITED_PENDING,
      ].includes(writer?.status || WriterStatusEnum.CREATED_PENDING) && (
        <div className='flex items-center justify-center bg-primary/10 w-[30px] h-[30px] rounded-[8px]'>
          <ClipboardPendingIcon
            className='text-primary'
            width='18'
            height='18'
          />
        </div>
      )}

      {writer?.status === WriterStatusEnum.APPROVED && (
        <div className='flex items-center justify-center bg-green/10 w-[30px] h-[30px] rounded-[8px]'>
          <ClipboardCheckIcon className='text-green' width='18' height='18' />
        </div>
      )}

      {[
        WriterStatusEnum.CREATED_REJECTED,
        WriterStatusEnum.EDITED_REJECTED,
      ].includes(writer?.status || WriterStatusEnum.CREATED_PENDING) && (
        <div className='flex items-center justify-center bg-red/10 w-[30px] h-[30px] rounded-[8px]'>
          <ClipboardCancelIcon className='text-red' width='18' height='18' />
        </div>
      )}
    </div>
  )
}
