import React from 'react'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { CoverListPageProps } from './interface'

const withCoverListPage = (Component: React.FC<CoverListPageProps>) => {
  function WithCoverListPage() {
    const client = useClient()

    const { data: categories, isLoading } = useQuery('categories_cover', () =>
      client?.categoryClient.allCategories()
    )

    if (!isLoading) {
      const newProps = {
        categories,
      }
      return <Component {...newProps} />
    }
    return <></>
  }

  return WithCoverListPage
}

export default withCoverListPage
