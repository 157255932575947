import { gql } from 'graphql-request'

export const CATEGORY_COVER = gql`
  query CategoryCover($categoryCoverId: Int!) {
    categoryCover(id: $categoryCoverId) {
      id
      imgPath
      coverName
    }
  }
`
