import { gql } from 'graphql-request'

export const BOOK_DASHBOARD_HOT_NEW = gql`
  query BookDashboardHotNew(
    $startDate: DateTime
    $endDate: DateTime
    $bookType: BookType
    $categoryId: Float
    $limit: Int = 10
    $orderBy: BookDetailTop100OrderBy
  ) {
    bookDashboardHotNew(
      startDate: $startDate
      endDate: $endDate
      bookType: $bookType
      categoryId: $categoryId
      limit: $limit
      orderBy: $orderBy
    ) {
      income
      data {
        rank
        bookId
        bookCoverImgPath
        bookTitle
        bookType
        firstPenName
        secondPenName
        chapterCount
        countLike
        countView
        countIncome
      }
    }
  }
`
