import { WriterListType } from '@models/writer/WriterListType'
import { Expose, Type } from 'class-transformer'

export class UserWriterResponse {
  @Expose() total: number

  @Expose() page: number

  @Expose()
  @Type(() => WriterListType)
  data: WriterListType[]

  @Expose() draftCount: number

  @Expose() publishedCount: number
}
