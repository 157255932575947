import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import { useAlert } from '@hooks/useAlert'
import { PromotionCodeHistoryPageProps } from './interface'

export function withPromotionCodeHistoryPage(
  Component: React.FC<PromotionCodeHistoryPageProps>
) {
  function WithPromotionCodeHistoryPage() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [requestId, setRequestId] = useState<number | undefined>(undefined)
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryClient = useQueryClient()

    const { data: historyList = [], isLoading: listLoading } = useQuery(
      ['promotion-code', 'history', Number(id), 'list'],
      () => client?.promotionCodeClient.getPromotionCodeHistoryList(Number(id)),
      {
        onSuccess: res => {
          if (res.length >= 1) {
            setRequestId(res[0].id)
          }
        },
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: historyData, isLoading: detailLoading } = useQuery(
      ['promotion-code', 'history', Number(requestId), 'detail'],
      () =>
        client?.promotionCodeClient.getPromotionCodeHistoryById(
          Number(requestId)
        ),
      {
        enabled: !!requestId && !!Number(requestId),
      }
    )

    const { data: coinOptions = [] } = useQuery('coin-options', () =>
      client?.promotionCodeClient.getCoinOptions()
    )

    function selectIdHandle(selectId: number) {
      setRequestId(selectId)
    }

    const { mutateAsync: approvePromotionCode, isLoading: approvedLoading } =
      useMutation(
        (promotionRequestId: number) =>
          client!.promotionClient.updatePromotionRequest({
            id: promotionRequestId,
            status: PromotionStatusEnum.APPROVED,
          }),
        {
          onSuccess: () => {
            alert.success(`ดำเนินการสำเร็จ`)
            queryClient.invalidateQueries([
              'promotion-code',
              'history',
              Number(requestId),
              'detail',
            ])
          },
          onError: () => {
            alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
          },
        }
      )

    function handleApprove() {
      if (requestId) {
        approvePromotionCode(requestId)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId,
        promotionId: id,
        promotionName: historyData?.newPromotion.name,
        startPromotion: new Date(historyData!.newPromotion.startPublishedAt),
        endPromotion: new Date(historyData!.newPromotion.endPublishedAt),
        createdAt: new Date(historyData!.createdAt),
        promotionDetail: historyData?.newPromotion.description,
        onSuccess: () => {
          queryClient.invalidateQueries([
            'promotion-code',
            'history',
            Number(requestId),
            'detail',
          ])
        },
      })
    }

    function goBackHandle() {
      navigate(-1)
    }

    function handleGoToFormDetail() {
      navigate(`/promotion-setting/promotion-code/${id}/form-detail`)
    }

    const newProps = {
      historyData,
      historyList,
      coinOptions,
      listLoading,
      detailLoading,
      approvedLoading,
      selectIdHandle,
      requestId,
      goBackHandle,
      handleApprove,
      handleReject,
      handleGoToFormDetail,
    }

    return <Component {...newProps} />
  }

  return WithPromotionCodeHistoryPage
}
