import { Expose, Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { BannerEnum } from '@interfaces/BannerEnum'
import { BannerStatusEnum } from '@interfaces/BannerStatusEnum'
import { HighlightBannerPositionEnum } from '@interfaces/mainBanner/HighlightBannerPositionEnum'
import { HighlightPosterBannerType } from './HighlightPosterBannerType'
import { HighlightBookBannerType } from './HighlightBookBannerType'

export class HighlightBannerType {
  @Expose() id: number

  @Expose() bannerName: string

  @Expose() runningNo: number

  @Expose() bannerType: BannerEnum

  @Expose() status: BannerStatusEnum

  @Expose() position: HighlightBannerPositionEnum

  @Expose()
  @Transform(({ value, obj }) =>
    new Date().valueOf() > new Date(obj.publishedTo).valueOf() ? false : value
  )
  isActive: boolean

  @Expose()
  @Type(() => HighlightPosterBannerType)
  @Transform(({ obj }) =>
    obj.bannerType === BannerEnum.POSTER ? obj.posterBanner : null
  )
  posterBanner: HighlightPosterBannerType[]

  @Expose()
  @Type(() => HighlightBookBannerType)
  @Transform(({ obj }) =>
    obj.bannerType === BannerEnum.BOOK ? obj.bookBanner : null
  )
  bookBanner: HighlightBookBannerType[]

  @Expose() publishedFrom: string

  @Expose() publishedTo: string

  @Expose()
  @Transform(({ obj }) => {
    const date = DateTime.now()
    const start = DateTime.fromISO(obj.publishedFrom)
    const end = DateTime.fromISO(obj.publishedTo)
    return start <= date && end >= date
      ? PublishedEnum.IMMEDIATELY
      : PublishedEnum.CUSTOM
  })
  publishedType: PublishedEnum
}
