import { Link } from 'react-router-dom'
import { EyeIcon } from '@components/Icons'

export function ActionCell({ id }: { id: number }) {
  return (
    <div className='flex gap-x-[10px] py-[10px]'>
      <Link to={`/story-detail/${id}`}>
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    </div>
  )
}
