import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class TopHashtag {
  @Expose() tagName: string
}

export class TopHashtagClick extends TopHashtag {
  @Expose({ name: 'totalTagClick' }) totalUserClick?: number
}
export class TopHashtagUsed extends TopHashtag {
  @Expose({ name: 'totalTagUsed' }) usedCount?: number
}

@Exclude()
export class TopHashtagResponseClick {
  @Expose()
  @Type(() => TopHashtagClick)
  data: TopHashtagClick[]

  @Expose() page: number
}

@Exclude()
export class TopHashtagResponseUsed {
  @Expose()
  @Type(() => TopHashtagUsed)
  data: TopHashtagUsed[]

  @Expose() page: number
}
