import React from 'react'
import { useQuery } from 'react-query'
import { client } from '@client/init'
import { ByTitleTableAcceptProps, ByTitleTableProps } from './interface'

export function withByTitleTable(Component: React.FC<ByTitleTableProps>) {
  function WithByTitleTable({ queryParam }: ByTitleTableAcceptProps) {
    const { data, isLoading } = useQuery(
      ['book-by-title', queryParam],
      () =>
        client?.dashboardClient.getBookByTitle({
          bookId: Number(queryParam.bookId),
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
        }),
      {
        enabled: !!queryParam.bookId,
      }
    )

    const componentProps = {
      data,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithByTitleTable
}
