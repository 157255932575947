import cn from 'classnames'
import { Waypoint } from 'react-waypoint'

import { CrossIcon, SearchIcon } from '@components/Icons'
import { SearchWriterFieldProps } from './interface'

export function SearchWriterField({
  text,
  writers,
  placeholder,
  className,
  inputClassName,
  isShowSearchLog,
  searchRef,
  showSearchLog,
  handleTextChange,
  handleSelectWriter,
  handleFetchNextPage,
  removeTextSearch,
}: SearchWriterFieldProps) {
  return (
    <div ref={searchRef} className={cn('w-full', className)}>
      <div className='relative flex items-center w-full'>
        <div className='absolute left-0 ml-[20px]'>
          <SearchIcon className='text-primary' />
        </div>
        <input
          className={cn(
            'flex w-full h-[40px] pl-[50px] pr-[40px] border-gray-border focus:outline-none text-[14px] font-light rounded-[8px] p-[10px]  focus:border-primary focus:text-secondary',
            inputClassName
          )}
          autoComplete='off'
          value={text}
          placeholder={placeholder}
          onFocus={showSearchLog}
          onChange={handleTextChange}
          onKeyDown={(keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
            if (keyEvent.key === 'Enter') {
              keyEvent.preventDefault()
            }
          }}
        />
        <button
          className={cn('absolute right-[15px] text-secondary', {
            hidden: !text,
          })}
          type='button'
          onClick={removeTextSearch}
        >
          <CrossIcon />
        </button>
        {isShowSearchLog && (
          <div className='absolute w-full z-10 mt-[45px] top-0 bg-white border-1 border-gray rounded-[10px] max-h-[350px] overflow-y-auto shadow'>
            {writers.map(row => (
              <div
                key={row.id}
                className='flex justify-between items-center px-[15px] py-[10px] cursor-pointer text-black-480 hover:text-primary hover:bg-blue-8'
                onClick={() => handleSelectWriter(row)}
              >
                <div className='flex items-center gap-x-[10px]'>
                  <img
                    className='w-[40px] h-[40px] rounded-full'
                    src={row.user.avatarImgPath}
                    alt=''
                  />
                  <span className='text-[14px] font-light'>
                    {row.user.username}
                  </span>
                </div>
                <div className='text-[14px] text-secondary-100 font-light'>
                  ID : {row.id}
                </div>
              </div>
            ))}
            <Waypoint onEnter={handleFetchNextPage} />
          </div>
        )}
      </div>
    </div>
  )
}
