import { Expose, Type } from 'class-transformer'

import { UserType } from '@models/profile/UserType'
import { PunishmentPeriodEnum } from '@interfaces/user/PunishmentPeriodEnum'

export class UserPunishmentType {
  @Expose() id: number

  @Expose() createdAt: string

  @Expose() message: string

  @Expose() operatedUser: UserType

  @Expose() period: PunishmentPeriodEnum

  @Expose() punishmentTimes: number

  @Expose() endPunishedAt: string

  @Expose() startPunishedAt: string
}

export class UserPunishmentHistoryResponse {
  @Expose()
  @Type(() => UserPunishmentType)
  data: UserPunishmentType[]

  @Expose() total: number

  @Expose() page: number
}
