import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { columns } from './column'
import { NewWriterPopularTableProps } from './interface'

export function NewWriterPopularTable({
  data,
  isLoading,
  handleSort,
}: NewWriterPopularTableProps) {
  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        นักเขียนใหม่มาแรง
      </h1>

      <Table
        data={data}
        columns={columns}
        initialSort={{ key: 'userViewCount', order: SortingType.DESC }}
        isLoading={isLoading}
        onSort={handleSort}
      />
    </div>
  )
}
