import { useFormikContext } from 'formik'
import React, { useRef } from 'react'

import { useAlert } from '@hooks/useAlert'
import { ManageSearchTermType } from '@models/searchManagement/ManageSearchTermType'
import { ManageSearchTermTemplateProps } from '../ManageSearchTermTemplate/interface'

export function withRecommendSearchTermSection(
  Component: React.FC<ManageSearchTermTemplateProps>
) {
  function WithRecommendSearchTermSection() {
    const alert = useAlert()
    const inputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } = useFormikContext<ManageSearchTermType>()

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.code === 'Enter') {
        event.preventDefault()
        const text = (event.target as HTMLInputElement).value.trim()
        const valueLength = values.recommendSearchTermList.filter(
          val => !val.isDelete
        ).length

        if (valueLength > 4) {
          if (inputRef.current) inputRef.current.value = ''
          alert.error('ไม่สามารถเพิ่มคำค้นหาแนะนำได้เกิน 5 คำ')
          return
        }

        if (text) {
          const isDuplicate = values.banSearchTermList
            .concat(values.recommendSearchTermList)
            .some(item => !item.isDelete && item.name === text)

          if (!isDuplicate) {
            setFieldValue('recommendSearchTermList', [
              ...values.recommendSearchTermList,
              {
                id: undefined,
                name: (event.target as HTMLInputElement).value,
                mockId: new Date().valueOf(),
                isDelete: false,
              },
            ])
          } else {
            alert.error('ไม่สามารถใส่ข้อความซ้ำได้')
          }
        }

        if (inputRef.current) inputRef.current.value = ''
      }
    }

    function handleDelete(index: number) {
      values.recommendSearchTermList[index].isDelete = true
      setFieldValue('recommendSearchTermList', values.recommendSearchTermList)
    }

    const componentProps = {
      data: values.recommendSearchTermList,
      inputRef,
      isBan: false,
      label: 'คำค้นหาแนะนำ',
      handleKeyDown,
      handleDelete,
    }

    return <Component {...componentProps} />
  }

  return WithRecommendSearchTermSection
}
