import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByPublisherType {
  @Expose() userId: number

  @Expose() writerId: number

  @Expose() publisher: string

  @Expose() totalSearch: number
}

@Exclude()
export class SearchResultByPublisherResponse {
  @Expose()
  @Type(() => SearchResultByPublisherType)
  data: SearchResultByPublisherType[]

  @Expose() page: number
}
