import { OptionType } from '@interfaces/OptionType'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { SelectButtonAcceptProps, SelectButtonProps } from './interface'

const options = [
  { value: 'total', label: 'ยอดรวม' },
  { value: 'current', label: 'ปัจจุบัน' },
]

export function withSelectButton(Component: React.FC<SelectButtonProps>) {
  function WithSelectButton(props: SelectButtonAcceptProps) {
    const [year, setYear] = useState(new Date().getFullYear())
    const [selectedLabel, setSelectedLabel] = useState('ยอดรวม')
    const datetimeCurrent = DateTime.local().minus({ month: 1 }).toMillis()
    const now = DateTime.local()
    const months = useMemo(() => {
      const datetime = DateTime.fromObject({
        year,
        month: 1,
        day: 1,
        hour: now.hour,
        minute: now.minute,
        second: now.second,
      })
      return Array.from({ length: 12 }, (_, i) => {
        const transformDatetime = datetime.plus({ month: i })
        const label = transformDatetime.toJSDate().toLocaleString('th-TH', {
          month: 'long',
        })
        return {
          value: transformDatetime.toJSDate().toString(),
          label,
        } as OptionType
      })
    }, [year, now.hour, now.minute, now.second])
    function increaseYear() {
      setYear(year + 1)
    }

    function decreaseYear() {
      setYear(year - 1)
    }

    function handleSelectedChange(value: string) {
      setSelectedLabel(value)
    }
    const newProps = {
      months,
      year,
      selectedLabel,
      options,
      datetimeCurrent,
      increaseYear,
      decreaseYear,
      handleSelectedChange,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithSelectButton
}
