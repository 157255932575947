import { Expose, Type } from 'class-transformer'
import { UserCoinLogType } from './UserCoinLogType'

export class UserCoinLogResponse {
  @Expose()
  @Type(() => UserCoinLogType)
  data: UserCoinLogType[]

  @Expose() total: number
}
