import { WithLogCityChartProps, LogCityChartProps } from './interface'

export function withLogCityChart(Component: React.FC<LogCityChartProps>) {
  function WithLogCityChart({ className, data }: WithLogCityChartProps) {
    const temp = [...data]
    const maxValue = Math.max(...data.map(i => i.value))
    const sortData = temp.sort((a, b) => b.value - a.value)

    const newProps = {
      className,
      data: sortData,
      topThree: sortData.slice(0, 3),
      maxValue,
    }
    return <Component {...newProps} />
  }

  return WithLogCityChart
}
