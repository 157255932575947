import { Expose, Transform } from 'class-transformer'

import { ImageType } from '@interfaces/ImageType'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'

export class MainBannerFormType {
  @Expose({ name: 'coverImgPath' })
  @Transform(({ obj }) => ({ url: obj?.coverImgPath }))
  coverImg: ImageType

  @Expose() description: string

  @Expose()
  @Transform(({ value }) => value || '')
  rawLink: string

  @Expose()
  @Transform(({ value }) => value || '')
  linkWeb: string

  @Expose()
  @Transform(({ value }) => value || '')
  linkIos: string

  @Expose()
  @Transform(({ value }) => value || '')
  linkAndroid: string

  @Expose()
  @Transform(({ value }) => new Date(value))
  startPublishedAt: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  endPublishedAt: Date

  @Expose() web: boolean

  @Expose() ios: boolean

  @Expose() android: boolean

  @Expose()
  @Transform(({ obj }) => {
    const currentDate = new Date().valueOf()
    const startPublished = new Date(obj.startPublishedAt).valueOf()

    return currentDate >= startPublished
      ? PublishedEnum.IMMEDIATELY
      : PublishedEnum.CUSTOM
  })
  publishedType: PublishedEnum
}
