import { PunishmentEnum } from '@interfaces/user/PunishmentEnum'
import { BanTable } from './components/BanTable'
import { BanTabs } from './components/BanTabs'
import { WarningTable } from './components/WarningTable'
import { BanSectionProps } from './interface'

export function BanSection({
  data,
  tabs,
  activeBanTab,
  page,
  perpage,
  total,
  isLoading,
  sort,
  pageChange,
  handleActiveTabChange,
  handleSort,
}: BanSectionProps) {
  return (
    <div className='mt-[30px]'>
      <BanTabs
        tabs={tabs}
        activeBanTab={activeBanTab}
        handleActiveTabChange={handleActiveTabChange}
      />
      {activeBanTab === PunishmentEnum.ADMONISH && (
        <WarningTable
          data={data}
          isLoading={isLoading}
          page={page}
          perpage={perpage}
          total={total}
          sort={sort}
          pageChange={pageChange}
          handleSort={handleSort}
        />
      )}
      {activeBanTab === PunishmentEnum.BAN && (
        <BanTable
          data={data}
          isLoading={isLoading}
          page={page}
          perpage={perpage}
          total={total}
          sort={sort}
          pageChange={pageChange}
          handleSort={handleSort}
        />
      )}
    </div>
  )
}
