import React from 'react'
import { useField } from 'formik'

import {
  MultiSelectFieldPropsType,
  WithMultiSelectFieldPropsType,
} from './interface'

export function withMultiSelectField(
  Component: React.FC<MultiSelectFieldPropsType>
) {
  function WithMultiSelectField({
    name,
    options,
    ...props
  }: WithMultiSelectFieldPropsType) {
    const [field, meta, { setValue }] = useField(name)

    function handleRemove(value: string) {
      const index = field.value.findIndex((row: string) => row === value)

      if (index !== -1) {
        const temp = [...field.value]
        temp.splice(index, 1)

        setValue(temp)
      }
    }

    function handleSelectAll() {
      if (field.value.length === options.length) {
        setValue([])
      } else {
        setValue(options.map(item => item.value))
      }
    }

    const pageProps = {
      ...props,
      ...field,
      options,
      handleSelectAll,
      error: meta.error,
      touched: meta.touched,
      handleRemove,
    }
    return <Component {...pageProps} />
  }
  WithMultiSelectField.defaultProps = {
    isInputLabel: false,
  }

  return WithMultiSelectField
}
