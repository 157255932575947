import { useState } from 'react'
import cn from 'classnames'
import { Button } from '@components/Button'
import { Loading } from '@components/Loading'
import { ExportButtonProps } from './interface'

export function ExportButton({
  className = '',
  disabled = false,
  label = 'Export',
  handleClick,
  icon,
}: ExportButtonProps) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Button
      type='button'
      className={cn('bg-primary text-white', className, {
        '!px-[15px]': isLoading,
      })}
      disabled={isLoading || disabled}
      onClick={async () => {
        if (handleClick) {
          try {
            setIsLoading(true)
            await handleClick()
            setIsLoading(false)
          } catch {
            setIsLoading(false)
          }
        }
      }}
    >
      <div className='flex items-center justify-center space-x-[10px]'>
        {isLoading ? <Loading size={24} /> : icon && <div>{icon}</div>}
        <div className='text-[14px]'>{label}</div>
      </div>
    </Button>
  )
}
