import React, { useRef } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { UploadCoverField } from '@components/forms/UploadCoverField'
import { Container } from '@components/Container'
import { TextInputField } from '@components/forms/TextInputField'
import { Button } from '@components/Button'
import { CoverManageProps } from './interface'

const coverManageSchema = Yup.object().shape({
  coverName: Yup.string().required('จำเป็น'),
  coverImage: Yup.object().shape({
    url: Yup.string().required(),
  }),
})

function CoverManage({
  initialValues,
  coverId,
  onSubmitCategoryCover,
}: CoverManageProps) {
  const imageRef = useRef<HTMLInputElement>(null)

  function showUploadDialog() {
    const event = imageRef.current
    if (event) {
      event.click()
    }
  }

  return (
    <Container>
      <Container.Title showBackBtn>
        {coverId ? 'Edit' : 'Create'} Cover
      </Container.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={coverManageSchema}
        onSubmit={onSubmitCategoryCover}
      >
        {({ values, resetForm }) => (
          <Form>
            <div className='flex p-[30px] border rounded-[8px] gap-x-[30px]'>
              <UploadCoverField
                name='coverImage'
                label='อัปโหลดหน้าปก'
                className='min-w-[262px] w-[262px] min-h-[376px] h-[376px]'
                description='780 X 1098 px'
                inputRef={imageRef}
                minHeigth={1098}
                minWidth={780}
              />
              <div className='flex flex-col justify-between w-full'>
                <TextInputField
                  name='coverName'
                  label='Name'
                  placeholder='Name'
                  className='w-full'
                />
                {values.coverImage?.url && (
                  <Button
                    className='px-[20px] text-sm h-[30px] text-secondary-100 bg-blue-2 w-fit'
                    type='button'
                    onClick={showUploadDialog}
                  >
                    อัปโหลดใหม่
                  </Button>
                )}
              </div>
            </div>
            <div className='flex justify-center mt-[30px] gap-x-[20px]'>
              <Button
                className='px-[30px] text-sm border border-secondary-100 text-secondary-100'
                type='button'
                onClick={() => resetForm()}
              >
                ยกเลิก
              </Button>
              <Button
                className='bg-primary text-sm text-white px-[32px] mobile:w-full'
                type='submit'
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default CoverManage
