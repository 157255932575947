import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'

import { WithSearchFieldProps, SearchFieldProps } from './interface'

const withSearchField = (Component: React.FC<SearchFieldProps>) => {
  function WithSearchField({ name, onChange, ...props }: WithSearchFieldProps) {
    const [isShowSearchLog, setIsShowSearchLog] = useState<boolean>(false)
    const [field, , { setValue }] = useField(name)

    function showSearchLog() {
      setIsShowSearchLog(true)
    }

    function hideSearchLog() {
      setTimeout(() => {
        setIsShowSearchLog(false)
      }, 100)
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }

    const componentsProps = {
      ...props,
      name,
      isShowSearchLog,
      showSearchLog,
      hideSearchLog,
      value: field.value || '',
      onChange: handleChange,
    }
    return <Component {...componentsProps} />
  }

  return WithSearchField
}

export { withSearchField }
