import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class StickerManageType {
  @Expose() id: number

  @Expose() name: string

  @Expose({ name: 'stickerImgPath' }) imageUrl: string
}
