import { Outlet } from 'react-router-dom'
import { DashboardLayout } from '@components/layouts/DashboardLayout'
import { SalesReportPage } from './pages/SalesReportPage'
import { DonationPage } from './pages/DonationPage'
import { WriterPublisherPage } from './pages/WriterPublisherPage'
import { AppPerformancePage } from './pages/AppPerformancePage'
import { ReaderPage } from './pages/ReaderPage'
import { SearchPage } from './pages/SearchPage'
import { StoryPage } from './pages/StoryPage'
import { EbookPage } from './pages/EbookPage'

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ),
    children: [
      {
        path: 'sales-report',
        element: <SalesReportPage />,
      },
      {
        path: 'app-performance',
        element: <AppPerformancePage />,
      },
      {
        path: 'reader',
        element: <ReaderPage />,
      },
      {
        path: 'writer-publisher',
        element: <WriterPublisherPage />,
      },
      {
        path: 'story',
        element: <StoryPage />,
      },
      {
        path: 'ebook',
        element: <EbookPage />,
      },
      {
        path: 'donation',
        element: <DonationPage />,
      },
      {
        path: 'search',
        element: <SearchPage />,
      },
    ],
  },
]
