import React from 'react'
import { actionOptions } from '@features/promotionSetting/pages/CreatePromotionChallengePage/promotionChallengeOptions'
import {
  PromotionChallengeFormProp,
  WithPromotionChallengeFormProp,
} from './interface'

export function withPromotionChallengeForm(
  Component: React.FC<PromotionChallengeFormProp>
) {
  function Hoc(props: WithPromotionChallengeFormProp) {
    const componentProps = {
      ...props,
      actionOptions,
    }
    return <Component {...componentProps} />
  }

  return Hoc
}
