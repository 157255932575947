import { gql } from 'graphql-request'

export const CREATE_USER_PUNISHMENT = gql`
  mutation CreateUserPunishment(
    $createUserPunishmentInput: CreateUserPunishmentInput!
  ) {
    createUserPunishment(
      createUserPunishmentInput: $createUserPunishmentInput
    ) {
      message
    }
  }
`
