import { gql } from 'graphql-request'

export const COIN_HISTORIES = gql`
  query CoinHistories(
    $coinHistoryType: CoinHistoryTypeEnum!
    $limitPerPage: Int
    $page: Int
    $orderBy: GetCoinHistoryOrderBy
    $startDate: DateTime
    $endDate: DateTime
    $searchText: String
  ) {
    coinHistories(
      coinHistoryType: $coinHistoryType
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
    ) {
      data {
        id
        activity
        type
        platform
        createdAt
        paidCoin
        freeCoin
        user {
          id
          username
        }
        transaction {
          transactionId
          currency
          amount
        }
        reference {
          id
          name
          coupon
          coinName
          promotionName
          transactionType
          transactionDetail
        }
      }
      page
      total
    }
  }
`
