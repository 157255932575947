import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'

import { SortByType, SortingType } from '@components/Table/interface'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { TopRankTableProps } from '../TopRankTable/interface'
import { columns } from './columns'
import { TopBookTableProps } from './interface'

export function withTopBookTable(Component: React.FC<TopRankTableProps>) {
  function WithTopBookTable({ searchParam }: TopBookTableProps) {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const [sort, setSort] = useState<SortByType>({
      key: '',
      order: '' as SortingType,
    })

    const handleSort = useCallback(({ key, order }: SortByType) => {
      setSort({ key, order })
    }, [])

    const { data = [], isLoading } = useQuery(
      ['highest-earning-writing', searchParam, sort],
      () =>
        client?.saleClient.getHighestEarningWriting({
          searchParam,
          sort,
        }),
      {
        enabled: isAuthenticated,
      }
    )

    const pageProps = {
      title: 'Top rank งานเขียนทำรายได้สูงสุด',
      className: 'max-h-[655px] pr-[20px]',
      data,
      columns,
      handleSort,
      isLoading,
    }
    return <Component {...pageProps} />
  }

  return WithTopBookTable
}
