import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useAuthentication } from '@hooks/useAuthentication'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { AgeConfirmationDetailPageProps } from './interface'

export function withAgeConfirmationDetailPage(
  Component: React.FC<AgeConfirmationDetailPageProps>
) {
  function WithAgeConfirmationDetailPage() {
    const client = useClient()
    const alert = useAlert()
    const { isAuthenticated } = useAuthentication()
    const navigate = useNavigate()
    const { id } = useParams()
    const rejectModal = useModal({ modal: 'ageConfirmReject' })
    const confirmModal = useModal({ modal: 'confirm' })

    const { data: userData, isLoading } = useQuery(
      ['age-confirm-user-detail', id],
      () => client?.ageConfirmClient.getUserById(Number(id)),
      { enabled: !!id && isAuthenticated }
    )

    function handleReject() {
      rejectModal.show({
        userId: userData?.id,
        avatarImgPath: userData?.avatarImgPath,
        fullName: userData?.fullName,
        username: userData?.username,
      })
    }
    function handleApprove() {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการอนุมัติหรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.ageConfirmClient.verifyAge({
              userId: Number(id),
              ageVerify: AgeVerifyEnum.APPROVED,
            })
            confirmModal.hide()
            alert.success('ดำเนินการสำเร็จ')
            navigate('/user/age-confirmation')
          } catch (_) {
            alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ ได้สำเร็จ!')
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    if (!userData || isLoading) return null

    const newProps = {
      data: userData,
      handleReject,
      handleApprove,
    }
    return <Component {...newProps} />
  }
  return WithAgeConfirmationDetailPage
}
