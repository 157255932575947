import { withPermissionGuard } from '@libs/permission'
import { EditPromotionSalePage } from './EditPromotionSalePage'
import { withEditPromotionSalePage } from './withEditPromotionSalePage'

const ConnectedEditPromotionSalePage = withPermissionGuard(
  withEditPromotionSalePage(EditPromotionSalePage),
  'promotionSetting',
  'edit'
)

export { ConnectedEditPromotionSalePage as EditPromotionSalePage }
