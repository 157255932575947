import { gql } from 'graphql-request'

export const BOOK_REPORT_LOGS = gql`
  query BookReportLogs($bookReportId: Int!, $page: Int, $limitPerPage: Int) {
    bookReportLogs(
      id: $bookReportId
      page: $page
      limitPerPage: $limitPerPage
    ) {
      total
      page
      data {
        id
        other
        action
        actionBy {
          id
          avatarImgPath
          displayName
        }
        createdAt
      }
    }
  }
`
