import { useClient } from '@hooks/useClient'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { CategorySectionProps, WithCategorySectionProps } from './interface'

export function withCategorySection(Component: React.FC<CategorySectionProps>) {
  function WithCategorySection({ searchParams }: WithCategorySectionProps) {
    const client = useClient()

    const { data = [] } = useQuery(
      ['character-donation-by-category', searchParams],
      () =>
        client?.characterClient?.getCharacterDonationByCategory(searchParams)
    )

    const maxValue = useMemo(() => {
      return Math.max(...data.map(item => item.totalDonation))
    }, [data])

    const topThree = useMemo(() => {
      return [...data]
        .sort((a, b) => b.totalDonation - a.totalDonation)
        .map(item => item.categoryId)
        .slice(0, 3)
    }, [data])

    const componentProps = {
      data,
      maxValue,
      topThree,
    }

    return <Component {...componentProps} />
  }

  return WithCategorySection
}
