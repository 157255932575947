import { Expose } from 'class-transformer'

export class UserRemainCoinType {
  @Expose() userId: number

  @Expose() username: string

  @Expose() paidCoin: number

  @Expose() secondaryCoin: number

  @Expose() activityCoin: number
}
