import { gql } from 'graphql-request'

export const GET_BY_USER_REPORT = gql`
  query PenNameDashboard(
    $writerId: Int!
    $penNameId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    penNameDashboard(
      writerId: $writerId
      penNameId: $penNameId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      createdAt
      ageBelowFifteen
      ageBetweenSixteenAndTwenty
      ageBetweenTwentyOneAndThirty
      ageBetweenThirtyOneAndForty
      ageMoreThanFortyOne
      otherAge
      maleReader
      femaleReader
      otherReader
      summaryAddBookShelfCount
      summaryTotalRead
      summaryBookLike
      summaryBookComment
      summaryBookPurchase
      summaryDonate
      summaryActionTime
      summarySellCount
      firstBookCreatedDate
      firstPenName
      totalPaidWriterIncome
      totalPayableWriterIncome
      user {
        id
        createdAt
        avatarImgPath
        username
        penNameCount
      }
    }
  }
`
