import cn from 'classnames'

import { ToggleProps } from './interface'

export function Toggle({ className, checked, ...props }: ToggleProps) {
  return (
    <label className={cn(className)}>
      <div
        className={cn(
          'w-[44px] h-[24px] flex items-center p-[2px] rounded-[40px] cursor-pointer',
          {
            'bg-primary': checked,
            'bg-gray': !checked,
            'opacity-60 cursor-not-allowed': props.disabled && !checked,
            'opacity-30 cursor-not-allowed': props.disabled && checked,
          }
        )}
      >
        <div
          className={cn(
            'rounded-full h-[20px] w-[20px] bg-white transition-transform duration-200 ease-linear',
            {
              'translate-x-0': !checked,
              'translate-x-[20px]': checked,
            }
          )}
        />
      </div>
      <input type='checkbox' className='hidden' checked={checked} {...props} />
    </label>
  )
}
