import { useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useMutation } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { PromotionTypeEnum } from '@interfaces/PromotionTypeEnum'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'

import {
  FileType,
  UploadPromotionCodeFieldAcceptProps,
  UploadPromotionCodeFieldProps,
} from './interface'

const withUploadPromotionCodeField = (
  Component: React.FC<UploadPromotionCodeFieldProps>
) => {
  function WithUploadPromotionCodeField({
    name,
    fieldValueName,
    fieldErrorName,
    channelsInput,
    promotionCodeId,
    ...props
  }: UploadPromotionCodeFieldAcceptProps) {
    const { error: alertError } = useAlert()
    const { values, setFieldValue } = useFormikContext<CreateCodeFormType>()
    const [, { error }] = useField(name)
    const inputRef = useRef<HTMLInputElement>(null)
    const client = useClient()
    const [fileName, setFileName] = useState(values.codeFile?.name)
    const [couponCount, setCouponCount] = useState(0)

    const { mutateAsync: uploadPromotionCode, isLoading: isCheckingFile } =
      useMutation(
        (file: File | Blob) =>
          client!.promotionCodeClient.uploadPromotionCode({
            file,
            promotionCodeId,
          }),
        {
          onSuccess: data => {
            setCouponCount(data.couponCount)
            setFieldValue(fieldValueName, data.coupons)
            setFieldValue(name, {
              name: data.fileName,
              url: data.filePath,
            })
          },
          onError: (errors: any) => {
            const e = errors.response.errors[0]
            if (e.errorCode === 4003) {
              setFieldValue(fieldErrorName, '*รูปแบบ Code ไม่ถูกต้อง')
            } else if (e.errorCode === 4004) {
              setFieldValue(
                fieldErrorName,
                '**มีรายการ Code ซ้ำ ไม่สามารถใช้ได้'
              )
            } else if (e.errorCode === 4005) {
              setFieldValue(fieldErrorName, '*code ใน file ซ้ำกับในระบบ')
            } else if (e.errorCode === 4006) {
              setFieldValue(fieldErrorName, '*จำนวน Code น้อยกว่าที่กำหนด')
            } else if (e.errorCode === 4007) {
              setFieldValue(fieldErrorName, '*จำนวน Code มากกว่าที่กำหนด')
            } else if (e.errorCode === 4008) {
              setFieldValue(fieldErrorName, '*รูปแบบไฟล์ไม่ถูกต้อง')
            } else {
              setFieldValue(fieldErrorName, '*เกิดข้อผิดพลาด ไม่สามารถใช้ได้')
            }
          },
        }
      )
    function onClearSelectedFile() {
      setFileName(undefined)
      setCouponCount(0)
      setFieldValue(name, undefined)
      setFieldValue(fieldValueName, [])
      setFieldValue(fieldErrorName, undefined)
    }

    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      onClearSelectedFile()
      const { files } = event.target
      if (files) {
        try {
          setFileName(files[0].name)
          await uploadPromotionCode(files[0])
        } finally {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '' // DECS: clear input value for case that select same file
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const newProps = {
      inputRef,
      values,
      fileName,
      couponCount,
      isCheckingFile,
      error,
      onChange: handleChange,
      showUploadDialog,
      onClearSelectedFile,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithUploadPromotionCodeField
}

export default withUploadPromotionCodeField
