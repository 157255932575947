import { useMemo } from 'react'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { NoData } from '@components/NoData'
import { ReaderListProps } from './interface'
import { columns } from './column'

export function ReaderList({
  data,
  isLoading,
  uniqueView,
  handleSort,
  handleFetchNextPage,
}: ReaderListProps) {
  const userReading = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <CardContainer>
      <div>
        <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
          รายการผู้อ่าน{' '}
          <span className='text-[14px] font-mitr font-light'>
            ({uniqueView.toLocaleString()} unique users)
          </span>
        </h1>

        <Table
          onSort={handleSort}
          onWaypointEnter={handleFetchNextPage}
          className='mb-[30px] max-h-[400px] pr-[10px]'
          fixedHeader
          isLoading={isLoading}
          data={userReading}
          columns={columns}
          initialSort={{ key: 'userTotalSpend', order: SortingType.DESC }}
        />
      </div>
    </CardContainer>
  )
}
