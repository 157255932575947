export enum CharacterDonationByStorySortEnum {
  TITLE = 'TITLE',
  BOOK_TYPE = 'BOOK_TYPE',
  VIEW = 'VIEW',
  PEN_NAME = 'PEN_NAME',
  CHAPTERS = 'CHAPTERS',
  DONATION = 'DONATION',
  LOVE = 'LOVE',
  RANK = 'RANK',
}
