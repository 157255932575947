import { Expose, Transform, Type } from 'class-transformer'
import { WriterType } from '@models/user/WriterType'
import { ReportBookEnum } from '@interfaces/ReportBookType'

class UserType {
  @Expose() id: number

  @Expose() fullName: string

  @Expose() displayName: string

  @Expose() phoneNumber: string

  @Expose() email: string

  @Expose() avatarImgPath?: string

  @Expose()
  @Type(() => WriterType)
  writer: WriterType
}

class PenNameType {
  @Expose() id: number

  @Expose() firstPenName: string

  @Expose() secondPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}

class BookType {
  @Expose() id: number

  @Expose() title: string

  @Expose() coverImgPath: string

  @Expose() writer: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() isTranslated: boolean
}

export class ReportType {
  @Expose() id: number

  @Expose() code?: string

  @Expose() isBanned: boolean

  @Expose() isVisible: boolean

  @Expose() hasBookLog: boolean

  @Expose() hasBookReportLog: boolean

  @Expose() status: string

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose()
  @Type(() => UserType)
  updatedBy?: UserType

  @Expose({ name: 'title' }) topic: string

  @Expose()
  @Transform(({ obj }) => obj.book || obj.ebook)
  @Type(() => BookType)
  book: BookType

  @Expose() messageToReporter?: string

  @Expose() messageToBookOwner?: string

  @Expose() detail: string

  @Expose()
  @Type(() => UserType)
  reportBy: UserType

  @Expose()
  @Transform(({ obj }) =>
    obj.book ? ReportBookEnum.STORY : ReportBookEnum.EBOOK
  )
  type: ReportBookEnum
}
