import React from 'react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'

function FootnoteOrderedList({ node }: any) {
  return (
    <NodeViewWrapper id='footnote'>
      <p contentEditable={false}>เชิงอรรถ</p>
      <NodeViewContent />
    </NodeViewWrapper>
  )
}

export default FootnoteOrderedList
