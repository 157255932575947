import { useFormikContext } from 'formik'
import React, { useRef } from 'react'

import { useAlert } from '@hooks/useAlert'
import { ManageSearchTermType } from '@models/searchManagement/ManageSearchTermType'
import { ManageSearchTermTemplateProps } from '../ManageSearchTermTemplate/interface'

export function withBanSearchTermSection(
  Component: React.FC<ManageSearchTermTemplateProps>
) {
  function WithBanSearchTermSection() {
    const alert = useAlert()
    const inputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } = useFormikContext<ManageSearchTermType>()

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.code === 'Enter') {
        event.preventDefault()
        const text = (event.target as HTMLInputElement).value.trim()

        if (text) {
          const isDuplicate = values.banSearchTermList
            .concat(values.recommendSearchTermList)
            .some(item => !item.isDelete && item.name === text)
          if (!isDuplicate) {
            setFieldValue('banSearchTermList', [
              ...values.banSearchTermList,
              {
                id: undefined,
                name: (event.target as HTMLInputElement).value,
                mockId: new Date().valueOf(),
                isDelete: false,
              },
            ])
          } else alert.error('ไม่สามารถใส่ข้อความซ้ำได้')
        }

        if (inputRef.current) inputRef.current.value = ''
      }
    }

    function handleDelete(index: number) {
      values.banSearchTermList[index].isDelete = true
      setFieldValue('banSearchTermList', values.banSearchTermList)
    }

    const componentProps = {
      data: values.banSearchTermList,
      inputRef,
      isBan: true,
      label: 'คำค้นหา',
      handleKeyDown,
      handleDelete,
    }

    return <Component {...componentProps} />
  }

  return WithBanSearchTermSection
}
