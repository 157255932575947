import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { ByTitleSearchFormType } from '@models/dashboard/ByTitleSearchFormType'
import { ByTitlePageProps, SearchQueryParam } from './interface'

export function withByTitlePage(Component: React.FC<ByTitlePageProps>) {
  function WithByTitlePage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const queryBookId = searchParams.get('bookId') || undefined

    const [queryParam, setQueryParam] = useState<SearchQueryParam>({
      bookId: queryBookId,
      startAt: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
    })

    function onResetForm() {
      setSearchParams({ tab: 'byTitle' })
      setQueryParam({
        bookId: undefined,
        startAt: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endAt: DateTime.now().endOf('day'),
      })
    }

    function newDateAt(
      startAt: Date | undefined,
      endAt: Date | undefined,
      timeRange: TimeRangeEnum
    ) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
        newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return { newStartAt, newEndAt }
    }

    async function handleSearch({
      bookId,
      timeRange,
      startAt,
      endAt,
    }: ByTitleSearchFormType) {
      const { newStartAt, newEndAt } = newDateAt(startAt, endAt, timeRange)

      if (newStartAt && newEndAt) {
        setQueryParam({
          bookId,
          startAt: newStartAt,
          endAt: newEndAt,
        })

        const newSearchParam: any = {
          tab: 'byTitle',
        }

        if (bookId) newSearchParam.bookId = bookId

        setSearchParams(newSearchParam)
      }
    }

    const newProps = {
      queryParam,
      handleSearch,
      onResetForm,
    }
    return <Component {...newProps} />
  }

  return WithByTitlePage
}
