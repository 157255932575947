import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import { PromotionSaleForm } from '@features/promotionSetting/components/PromotionSaleForm'
import { FormDetailPromotionSalePageProps } from './interface'

function FormDetailPromotionDiscountPage({
  promotionId,
  initialValues,
  saleCoinOptions,
  handleApprove,
  handleReject,
}: FormDetailPromotionSalePageProps) {
  const permission = usePermissionAction('promotionSetting')
  const navigate = useNavigate()
  useEffect(() => {
    if (initialValues?.id && !initialValues.lastRequest) {
      navigate(`/promotion-setting/promotion-challenge/${initialValues.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues?.id, initialValues?.lastRequest])

  return (
    <Container>
      <PromotionSaleForm
        initialValues={initialValues}
        handleSubmit={async () => {}}
        goBackHandle={() => navigate(-1)}
        title='ดูรายละเอียด Challenge'
        viewMode
        saleCoinOptions={saleCoinOptions}
      />
      {permission.approve &&
        (initialValues?.status === PromotionStatusEnum.PENDING ||
          !!initialValues?.lastRequest) && (
          <div className='flex items-center justify-between mt-[30px]'>
            <Button
              type='button'
              className='border border-secondary rounded-[20px]'
              onClick={() =>
                navigate(
                  `/promotion-setting/promotion-sale/${promotionId}/history`
                )
              }
            >
              <span className='font-mitr font-medium text-sm'>
                ดูประวัติการแก้ไขโปรโมชั่น
              </span>
            </Button>
            <div className='flex items-center space-x-5'>
              <Button
                type='button'
                onClick={handleReject}
                className='border px-[28px] border-secondary-100 text-secondary-100 font-mitr text-sm font-medium rounded-[20px]'
              >
                <span>Reject</span>
              </Button>
              <Button
                type='button'
                onClick={handleApprove}
                className='border px-[55px] border-primary text-white bg-primary font-mitr text-sm font-medium rounded-[20px]'
              >
                <span>Approve</span>
              </Button>
            </div>
          </div>
        )}
    </Container>
  )
}

export default FormDetailPromotionDiscountPage
