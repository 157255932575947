import { Expose, Transform, Type } from 'class-transformer'

import { ChallengeUnit } from '@interfaces/user-mission/ChallengeEnum'
import { ChallengeLevelType } from '@models/user-mission/ChallengeLevelType'
import {
  challengeCriteriaValue,
  ChallengeCriteriaEnum,
} from '@interfaces/user-mission/ChallengeCriteriaEnum'

export class ReaderBadgeType {
  @Expose() id: number

  @Expose() title: string

  @Expose() description: string

  @Expose() iconUrl: string

  @Expose()
  @Transform(({ value }) =>
    value ? challengeCriteriaValue[value as ChallengeCriteriaEnum] : ''
  )
  criteria: string

  @Expose() unit: ChallengeUnit

  @Expose() currentLevel: number

  @Expose() currentLevelName: string

  @Expose() completed: number

  @Expose() nextLevel: number | null

  @Expose() nextLevelName: string

  @Expose() nextRemaining: number

  @Expose() nextTarget: number

  @Expose() maxLevel: number

  @Expose() previousTarget: number

  @Expose()
  @Type(() => ChallengeLevelType)
  challengeLevels: ChallengeLevelType[]
}
