import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { PasswordField } from '@components/forms/PasswordField'
import { FieldDescription } from '@components/FieldDescription'
import { ResetPasswordPageProps } from './interface'

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'รหัสผ่านต้องมากกว่าหรือเท่ากับ 8 ตัวอักษร')
    .required('จำเป็น')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_.])[A-Za-z\d@$!%*?&_.]{8,}$/,
      'รูปแบบไม่ถูกต้อง'
    ),
  confirmPassword: Yup.string()
    .required('จำเป็น')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_.])[A-Za-z\d@$!%*?&_.]{8,}$/,
      'รูปแบบไม่ถูกต้อง'
    )
    .oneOf([Yup.ref('password'), null], 'กรุณากรอกรหัสผ่านให้ตรงกัน'),
})

export function ResetPasswordPage({
  submitResetPassword,
}: ResetPasswordPageProps) {
  return (
    <div className='w-[340px] mobile:hidden'>
      <div className='font-mitr text-[24px] text-center text-black-480 font-medium mt-[40px]'>
        ตั้งรหัสผ่านใหม่
      </div>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={submitResetPassword}
        validationSchema={schema}
      >
        {() => (
          <Form className='mobile:w-full'>
            <div className='py-[20px]'>
              <div>
                <PasswordField
                  name='password'
                  placeholder='รหัสผ่าน'
                  showErrorMsg
                />
                <FieldDescription
                  text={`ความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์เล็ก (a-z)
            อย่างน้อย 1 ตัว, ตัวอักษรพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว, มีตัวเลข
            และอักขระพิเศษ (@$!%*?&_.)`}
                />
              </div>
            </div>
            <div className='mb-[24px]'>
              <div>
                <PasswordField
                  name='confirmPassword'
                  placeholder='ยืนยันรหัสผ่าน'
                  showErrorMsg
                />
                <FieldDescription
                  text={`ความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์เล็ก (a-z)
            อย่างน้อย 1 ตัว, ตัวอักษรพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว, มีตัวเลข
            และอักขระพิเศษ (@$!%*?&_.)`}
                />
              </div>
            </div>
            <button
              type='submit'
              className='font-mitr text-[14px] w-[340px] text-white bg-primary px-[18px] py-[8px] rounded-[20px] mt-[30px]'
            >
              ยืนยัน
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
