import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'
import { client } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { ReadTimeUsageType } from '@models/dashboard/ReadTimeUsageType'
import { ReaderAverageRevenuePerPayingUserType } from '@models/dashboard/ReaderAverageRevenuePerPayingUserType'
import { ReaderPayingVsNonUserPayingType } from '@models/dashboard/ReaderPayingVsNonUserPayingType'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { OverviewProps, SearchType } from './interface'

const timeUsage: ReadTimeUsageType[] = [
  { name: '6:00', value: 0, color: '' },
  { name: '7:00', value: 0, color: '' },
  { name: '8:00', value: 0, color: '' },
  { name: '9:00', value: 0, color: '' },
  { name: '10:00', value: 0, color: '' },
  { name: '11:00', value: 0, color: '' },
  { name: '12:00', value: 0, color: '' },
  { name: '13:00', value: 0, color: '' },
  { name: '14:00', value: 0, color: '' },
  { name: '15:00', value: 0, color: '' },
  { name: '16:00', value: 0, color: '' },
  { name: '17:00', value: 0, color: '' },
  { name: '18:00', value: 0, color: '' },
  { name: '19:00', value: 0, color: '' },
  { name: '20:00', value: 0, color: '' },
  { name: '21:00', value: 0, color: '' },
  { name: '22:00', value: 0, color: '' },
  { name: '23:00', value: 0, color: '' },
  { name: '0:00', value: 0, color: '' },
  { name: '1:00', value: 0, color: '' },
  { name: '2:00', value: 0, color: '' },
  { name: '3:00', value: 0, color: '' },
  { name: '4:00', value: 0, color: '' },
  { name: '5:00', value: 0, color: '' },
]

const withOverview = (Component: React.FC<OverviewProps>) => {
  function WithOverview() {
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useState<SearchType>({
      startDate: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endDate: DateTime.now().endOf('day'),
    })

    function handleSearch(startDate: DateTime, endDate: DateTime) {
      setSearchParams({
        startDate,
        endDate,
      })
    }

    function handleReset() {
      setSearchParams({
        startDate: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endDate: DateTime.now().endOf('day'),
      })
    }

    async function handleExport() {
      try {
        const { startDate, endDate } = searchParams

        await client?.dashboardClient.exportReaderDashboard({
          startDate,
          endDate,
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    function setGraphPayingData(data: ReaderPayingVsNonUserPayingType[]) {
      const temp = [...data]
      const topThree = temp
        .sort(
          (
            a: ReaderPayingVsNonUserPayingType,
            b: ReaderPayingVsNonUserPayingType
          ) => b.payingUserCount - a.payingUserCount
        )
        .slice(0, 3)

      const newData = data.map((item: ReaderPayingVsNonUserPayingType) => {
        const name = item.day ? 'day' : 'month'
        return {
          name: item[name],
          value: item.payingUserCount,
          color: topThree.some(
            (element: ReaderPayingVsNonUserPayingType) =>
              element[name] === item[name]
          )
            ? '#3f54d9'
            : '#cecfd7',
        }
      })
      return newData
    }

    function setGraphPayingVsNonUserPayingData(
      data: ReaderPayingVsNonUserPayingType[]
    ) {
      return [
        {
          title: 'Paying User',
          color: '#3f54d9',
          data: data.map(item => ({
            name: item.day ? item.day : item.month,
            value: item.payingUserCount,
          })),
        },
        {
          title: 'Non-Paying User',
          data: data.map(item => ({
            name: item.day ? item.day : item.month,
            value: item.totalUser,
          })),
        },
      ]
    }

    const { data: performanceLogCity = [] } = useQuery(
      ['performance-log-city', searchParams],
      () => client?.dashboardClient.getPerformanceLogCity({ ...searchParams! }),
      { enabled: !!searchParams }
    )
    const { data: totalUserGender } = useQuery(
      ['total-user-gender', searchParams],
      () => client?.dashboardClient.getTotalUserGender({ ...searchParams! }),
      { enabled: !!searchParams }
    )
    const { data: totalUserAgeRange } = useQuery(
      ['total-user-age-range', searchParams],
      () => client?.dashboardClient.getTotalUserAgeRange({ ...searchParams! }),
      { enabled: !!searchParams }
    )

    const { data: readerDashboardDetail } = useQuery(
      ['reader-dashboard-detail', searchParams],
      () =>
        client?.dashboardClient.getReaderDashboardDetail({ ...searchParams! }),
      { enabled: !!searchParams }
    )

    const { data: averageRevenuePerPayingUserData = [] } = useQuery(
      ['reader-dashboard-average-revenue-per-paying-user', searchParams],
      () =>
        client?.dashboardClient
          .getAverageRevenuePerPayingUser({ ...searchParams! })
          .then(res => {
            const temp = [...res]
            const topThree = temp
              .sort(
                (
                  a: ReaderAverageRevenuePerPayingUserType,
                  b: ReaderAverageRevenuePerPayingUserType
                ) => b.value - a.value
              )
              .slice(0, 3)

            const data = res.map(
              (item: ReaderAverageRevenuePerPayingUserType) => {
                return {
                  ...item,
                  color: topThree.some(
                    (element: ReaderAverageRevenuePerPayingUserType) =>
                      element.name === item.name
                  )
                    ? '#3f54d9'
                    : '#cecfd7',
                }
              }
            )
            return data
          }),
      { enabled: !!searchParams }
    )

    const { data: payingVsNonUserPayingData = [] } = useQuery(
      ['reader-dashboard-paying-vs-non-user-paying', searchParams],
      () =>
        client?.dashboardClient.getPayingVsNonUserPaying({ ...searchParams! }),
      { enabled: !!searchParams }
    )

    const { data: readTimeUsage = [] } = useQuery(
      ['read-time-usage', searchParams],
      () =>
        client?.dashboardClient
          .getReadTimeUsage({
            startDate: searchParams!.startDate,
            endDate: searchParams!.endDate,
          })
          .then(res => {
            const temp = [...res]
            const topThree = temp
              .sort(
                (a: ReadTimeUsageType, b: ReadTimeUsageType) =>
                  b.value - a.value
              )
              .slice(0, 3)

            const data = res.map((item: ReadTimeUsageType) => {
              return {
                ...item,
                color: topThree.some(
                  (element: ReadTimeUsageType) => element.name === item.name
                )
                  ? '#3f54d9'
                  : '#cecfd7',
              }
            })
            return timeUsage.map(
              row => data.find(item => item.name === row.name) || row
            )
          }),
      {
        enabled: !!searchParams,
      }
    )

    const newProps = {
      searchParams,
      performanceLogCity,
      readerDashboardDetail,
      averageRevenuePerPayingUserData,
      payingVsNonUserPayingData,
      readerGender: totalUserGender?.readerGender || [],
      readerAge: totalUserAgeRange?.readerAge || [],
      readTimeUsage,
      handleSearch,
      handleReset,
      handleExport,
      setGraphPayingVsNonUserPayingData,
      setGraphPayingData,
    }
    return <Component {...newProps} />
  }

  return WithOverview
}

export default withOverview
