import React from 'react'
import { useField } from 'formik'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import {
  MultiSelectCategoryProps,
  WithMultiSelectCategoryProps,
} from './interface'

export function withMultiSelectCategory(
  Component: React.FC<MultiSelectCategoryProps>
) {
  function WithMultiSelectCategory({
    name,
    bookTypes,
    ...props
  }: WithMultiSelectCategoryProps) {
    const [field, meta, { setValue }] = useField(name)
    const client = useClient()

    const { data: categories = [] } = useQuery(
      ['category-options', bookTypes?.join(' ')],
      () =>
        client?.categoryClient.getCategoryOptions(bookTypes).then(res =>
          res.map(row => ({
            label: row.text,
            value: String(row.value),
          }))
        )
    )

    function handleSelectAll() {
      if (field.value.length === categories.length) {
        setValue([])
      } else {
        setValue(categories.map(item => item.value))
      }
    }

    const pageProps = {
      options: categories,
      ...props,
      ...field,
      error: meta.error,
      touched: meta.touched,
      bookTypes,
      handleSelectAll,
    }
    return <Component {...pageProps} />
  }

  return WithMultiSelectCategory
}
