import cn from 'classnames'

import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { Button } from '@components/Button'
import { SearchHistoryProps } from './interface'

export function SearchHistory({
  data,
  columns,
  selectedIdLenght,
  total,
  isLoading,
  blockWordingSelcet,
  addWordingSelect,
  handleSort,
}: SearchHistoryProps) {
  const permission = usePermissionAction('searchManagement')
  return (
    <div className='mt-[20px]'>
      <div className='flex justify-between mb-[30px]'>
        <div className='text-[16px] text-secondary-100 font-light'>
          จำนวนคำค้นหาที่พบ:&nbsp;
          <span className='font-bold text-secondary'>
            {total.toLocaleString()}
          </span>
          &nbsp;รายการ
        </div>
        <div
          className={cn('flex gap-x-[20px]', {
            hidden: !permission.edit,
          })}
        >
          <Button
            className='text-red text-[14px] bg-red/10  border-none border h-[30px]'
            disabled={selectedIdLenght === 0}
            onClick={blockWordingSelcet}
          >
            บล็อคคำค้นหานี้
          </Button>
          <Button
            className='text-primary text-[14px] bg-primary/10  border-none border h-[30px]'
            disabled={selectedIdLenght === 0}
            onClick={addWordingSelect}
          >
            เพิ่มในคำค้นหาที่แนะนำ
          </Button>
        </div>
      </div>
      <div className='flex-1 overflow-x-auto pb-[20px]'>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          initialSort={{ key: 'DATE_TIME', order: SortingType.DESC }}
          onSort={handleSort}
          bodyClassName='border-gray text-[14px] hover:bg-blue-4/10 font-light text-secondary border-b-[1px]'
        />
      </div>
    </div>
  )
}
