import React from 'react'
import { useField } from 'formik'

import { WithInputFieldProps, InputFieldProps } from './interface'

const withInputField = (Component: React.FC<InputFieldProps>) => {
  function WithInputField({
    name,
    onChange,
    autoEmptyValue = true,
    autoTrim = true,
    ...props
  }: WithInputFieldProps) {
    const [field, meta, { setValue }] = useField(name)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
      if (autoTrim) {
        const { value } = e.target
        setValue(value.trim())
      }
      field.onBlur(e)
    }

    const componentsProps = {
      ...props,
      ...field,
      value: autoEmptyValue ? field.value || '' : field.value,
      onChange: handleChange,
      error: meta.error,
      touched: meta.touched,
      onBlur: handleBlur,
    }

    return <Component {...componentsProps} />
  }

  WithInputField.defaultProps = {
    type: 'text',
  }

  return WithInputField
}

export { withInputField }
