import { withPermissionGuard } from '@libs/permission'
import { EditUserMissionPage } from './EditUserMissionPage'
import { withEditUserMissionPage } from './withEditUserMissionPage'

const ConnectedEditUserMissionPage = withPermissionGuard(
  withEditUserMissionPage(EditUserMissionPage),
  'userMission',
  'edit'
)

export { ConnectedEditUserMissionPage as EditUserMissionPage }
