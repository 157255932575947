import { PieChart } from '@components/chart/PieChart'
import { ChartOptionType } from '@interfaces/ChartOptionType'

export function ReadersByAge({ readerAge }: { readerAge: ChartOptionType[] }) {
  return (
    <div className='w-full'>
      <PieChart
        title='ผู้อ่านตามช่วงอายุ'
        data={readerAge}
        legendStyle='bottom'
        style={{ height: '517px', width: '100%' }}
      />
    </div>
  )
}
