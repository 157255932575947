import { Type, Expose, Transform } from 'class-transformer'
import { CoinHistoryType } from './CoinHistoryType'

export class CoinHistoryResponse {
  @Type(() => CoinHistoryType)
  data: CoinHistoryType[]

  total: number

  page: number
}
