import { gql } from 'graphql-request'

export const CREATE_PROMOTION_CHALLENGE = gql`
  mutation CreatePromotionChallenge(
    $createPromotionChallengeInput: CreatePromotionChallengeInput!
  ) {
    createPromotionChallenge(
      createPromotionChallengeInput: $createPromotionChallengeInput
    ) {
      id
    }
  }
`
