import { gql } from 'graphql-request'

export const GET_SEARCH_LOG = gql`
  query GetSearchLogForAdmin(
    $startDate: DateTime
    $endDate: DateTime
    $gender: GenderEnum
    $ageRange: AgeRange
    $orderBy: SearchLogOrderBy
    $orderDirection: OrderDirection
    $searchText: String
    $page: Int
    $limitPerPage: Int
  ) {
    getSearchLogsForAdmin(
      startDate: $startDate
      endDate: $endDate
      gender: $gender
      ageRange: $ageRange
      orderBy: $orderBy
      orderDirection: $orderDirection
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        user {
          id
          displayName
          gender
          age
        }
        searchText
        createdAt
      }
      total
    }
  }
`
