import { gql } from 'graphql-request'

export const GET_TRAFFIC_BY_USER = gql`
  query GetDashboardTrafficByUser(
    $penNameId: Int
    $writerId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getDashboardTrafficByUser(
      penNameId: $penNameId
      writerId: $writerId
      startDate: $startDate
      endDate: $endDate
    ) {
      sourceName
      count
    }
  }
`
