import React, { useState } from 'react'
import { DateTime } from 'luxon'

import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { timeRangeValue } from '@interfaces/TimeRangeEnum'
import {
  SearchDateRangeProps,
  SearchType,
  WithSearchDateRangeProps,
} from './interface'

export function withSearchDateRange(Component: React.FC<SearchDateRangeProps>) {
  function WithSearchDateRange({
    defaultSelectDateRange,
    onSearch,
    onReset,
    onExport,
    ...props
  }: WithSearchDateRangeProps) {
    const [searchParams, setSearchParams] = useState<SearchType>({
      selectedDate: defaultSelectDateRange || KeyTypeEnum.MONTH,
      startDate: null,
      endDate: null,
    })

    function handleDateRangeChange(value: KeyTypeEnum) {
      setSearchParams({
        selectedDate: value,
        startDate: null,
        endDate: null,
      })
    }

    function handleStartDateChange(date: Date) {
      setSearchParams(prev => ({
        ...prev,
        selectedDate: null,
        startDate: date,
      }))
    }

    function handleEndDateChange(date: Date) {
      setSearchParams(prev => ({
        ...prev,
        selectedDate: null,
        endDate: date,
      }))
    }

    function formatDate() {
      let start = DateTime.now()
      let end = DateTime.now()
      let type = searchParams.selectedDate || KeyTypeEnum.MONTH
      if (searchParams.selectedDate === KeyTypeEnum.DAY) {
        start = start.minus({ days: 1 }).startOf('day')
        end = end.minus({ days: 1 }).endOf('day')
      } else if (searchParams.selectedDate === KeyTypeEnum.MONTH) {
        start = start
          .minus({ days: timeRangeValue[KeyTypeEnum.MONTH] })
          .startOf('day')
        end = end.endOf('day')
      } else if (searchParams.selectedDate === KeyTypeEnum.YEAR) {
        start = start
          .minus({ days: timeRangeValue[KeyTypeEnum.YEAR] })
          .startOf('day')
        end = end.endOf('day')

        if (start.year !== end.year) type = KeyTypeEnum.ACROSS_YEAR
      } else {
        if (!searchParams.startDate || !searchParams.endDate) {
          return undefined
        }
        start = DateTime.fromJSDate(searchParams.startDate as Date).startOf(
          'day'
        )
        end = DateTime.fromJSDate(searchParams.endDate as Date).endOf('day')
        const diff = end.diff(start, ['years', 'months', 'days'])
        const { days = 0, months = 0, years = 0 } = diff.toObject()
        if (start.year < end.year) {
          type = KeyTypeEnum.ACROSS_YEAR
        } else if (years >= 1) {
          type = KeyTypeEnum.YEAR
        } else if (months >= 1) {
          type = KeyTypeEnum.YEAR
        } else if (days > 1) {
          type = KeyTypeEnum.MONTH
        } else {
          type = KeyTypeEnum.DAY
        }
      }
      return { start, end, type }
    }

    function handleSearch() {
      const selectDate = formatDate()
      if (selectDate) {
        onSearch(selectDate.start, selectDate.end, selectDate.type)
      }
    }

    function handleReset() {
      setSearchParams({
        selectedDate: defaultSelectDateRange || KeyTypeEnum.MONTH,
        startDate: null,
        endDate: null,
      })
      onReset()
    }

    const componentProps = {
      ...props,
      searchParams,
      handleSearch,
      handleReset,
      handleExport: onExport,
      handleDateRangeChange,
      handleStartDateChange,
      handleEndDateChange,
    }

    return <Component {...componentProps} />
  }

  return WithSearchDateRange
}
