import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { client } from '@client/init'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAlert } from '@hooks/useAlert'
import { OptionType } from '@interfaces/OptionType'
import { getErrorMessage } from '@libs/utils'
import { PaymentReportSearchType, ReportOverviewProps } from './interface'

const PER_PAGE = 15

const withReportOverview = (Component: React.FC<ReportOverviewProps>) => {
  function WithReportOverview() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const location = useLocation()
    const [params] = useSearchParams()
    const alert = useAlert()
    const [searchParam, setSearchParam] = useState<PaymentReportSearchType>({
      month:
        params.get('month') || `${DateTime.now().minus({ months: 1 }).month}`,
      year: params.get('year') || `${DateTime.now().minus({ months: 1 }).year}`,
    })
    const [sortBy, setSortBy] = useState<SortByType>({
      key: params.get('sortKey') || 'transactionId',
      order: (params.get('sortType') as SortingType) || SortingType.DESC,
    })

    const options = useMemo(() => {
      const currentYear = DateTime.now().year
      const datetime = DateTime.fromObject({
        month: 1,
      })
      const months = Array.from({ length: 12 }, (_, i) => {
        const transformDatetime = datetime.plus({ month: i })
        const label = transformDatetime
          .toJSDate()
          .toLocaleString('th-TH', { month: 'long' })
        return { label, value: `${i + 1}` } as OptionType
      })
      const years = Array.from({ length: 11 }, (_, i) => {
        const y = Number(currentYear) - i
        return {
          label: `${y}`,
          value: `${y}`,
        } as OptionType
      })
      return { months, years }
    }, [])

    function handleSearch(form: PaymentReportSearchType) {
      setSearchParam(form)
      navigate({
        pathname: location.pathname,
        search: `?tab=OVERVIEW&month=${form.month}&year=${form.year}`,
      })
    }

    const {
      data: paymentsReports,
      isLoading,
      isFetchingNextPage,
      fetchNextPage,
    } = useInfiniteQuery(
      ['payment-report', 'overview', searchParam, sortBy],
      ({ pageParam = 1 }) =>
        client?.saleClient.getPaymentsReports({
          monthNo: Number(searchParam.month),
          year: Number(searchParam.year),
          sort: sortBy,
          page: pageParam,
          limitPerPage: PER_PAGE,
        }),

      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.page * PER_PAGE < lastPage.total)
            return lastPage.page + 1

          return undefined
        },
        staleTime: Infinity,
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleSort({ key, order }: SortByType) {
      setSortBy(prev => ({ ...prev, key, order }))
      const search = `?tab=OVERVIEW&month=${searchParam.month}&year=${searchParam.year}&sortKey=${key}&sortType=${order}`

      navigate({
        pathname: location.pathname,
        search,
      })
    }

    function handleReset() {
      setSearchParam({
        month: `${DateTime.now().minus({ months: 1 }).month}`,
        year: `${DateTime.now().minus({ months: 1 }).year}`,
      })
      navigate({
        pathname: location.pathname,
        search: '?tab=OVERVIEW',
      })
    }

    const data = useMemo(
      () => paymentsReports?.pages?.flatMap(page => page.data) ?? [],
      [paymentsReports]
    )

    async function handleExport() {
      try {
        await client?.saleClient.exportPaymentReports({
          paymentCycleMonth: Number(searchParam.month),
          paymentCycleYear: Number(searchParam.year),
        })
        await queryClient.refetchQueries('export-jobs')
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      handleSearch,
      monthOptions: options.months,
      yearOptions: options.years,
      initialValues: searchParam,
      total: paymentsReports?.pages[0].total || 0,
      amount: paymentsReports?.pages[0].totalPaid || 0,
      monthYear: searchParam,
      data,
      sortBy,
      isLoading,
      handleSortChange: handleSort,
      handleWaypointEnter: handleFetchNextPage,
      handleReset,
      handleExport,
    }
    return <Component {...newProps} />
  }

  return WithReportOverview
}

export default withReportOverview
