import { gql } from 'graphql-request'

export const BOOK_REPORT = gql`
  query GetBookReport($bookReportId: Int!) {
    bookReport(id: $bookReportId) {
      id
      title
      detail
      status
      messageToReporter
      messageToBookOwner
      isBanned
      isVisible
      reportBy {
        id
        displayName
        email
        phoneNumber
      }
      book {
        id
        title
        coverImgPath
        writer
        penName {
          id
          firstPenName
          secondPenName
          user {
            id
            fullName
            writer {
              affiliation
            }
          }
        }
        isTranslated
      }
      ebook {
        id
        title
        coverImgPath
        writer
        penName {
          id
          firstPenName
          secondPenName
          user {
            id
            fullName
            writer {
              affiliation
            }
          }
        }
        isTranslated
      }
      updatedBy {
        id
        displayName
        avatarImgPath
      }
      createdAt
      updatedAt
      hasBookLog
      hasBookReportLog
    }
  }
`
