import { Button } from '@components/Button'
import { PromotionSettingCloseModalProps } from './interface'

export function PromotionSettingCloseModal({
  title,
  onConfirm,
  onClose,
  isClose,
}: PromotionSettingCloseModalProps) {
  return (
    <div className='w-[400px] text-center px-[30px] py-[40px]'>
      <p className='font-mitr text-[24px] text-secondary font-medium mb-[20px]'>
        {isClose ? 'หยุดดำเนินการโปรโมชั่น' : 'เปิดดำเนินการโปรโมชั่น'}
      </p>
      <div className='flex flex-col text-[14px]'>
        <span>
          {isClose
            ? 'คุณกำลังจะหยุดดำเนินการโปรโมชั่น'
            : 'คุณกำลังจะเปิดดำเนินการโปรโมชั่น'}
        </span>
        <span className='font-bold'>“{title}”</span>
        <span>ต้องการจะดำเนินการต่อหรือไม่</span>
      </div>
      <div className='flex justify-center items-center text-[14px] font-medium mt-[30px]'>
        <Button type='button' onClick={onClose}>
          ยกเลิก
        </Button>
        <Button
          className='text-white bg-primary'
          type='button'
          onClick={onConfirm}
        >
          {isClose ? 'หยุดดำเนินการ' : 'เปิดดำเนินการ'}
        </Button>
      </div>
    </div>
  )
}
