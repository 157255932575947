import { withPermissionGuard } from '@libs/permission'
import { StickerManagementAddPage } from './StickerManagementAddPage'
import { withStickerManagementAddPage } from './withStickerManagementAddPage'

const ConnectedStickerManagementAddPage = withPermissionGuard(
  withStickerManagementAddPage(StickerManagementAddPage),
  'chapterBook',
  'create'
)

export { ConnectedStickerManagementAddPage as StickerManagementAddPage }
