import { Container } from '@components/Container'
import { ArrowLeftIcon } from '@components/Icons'
import { Loading } from '@components/Loading'
import { PromotionCodeViewForm } from './components/PromotionCodeViewForm'
import { FormDetailPromotionCodePageProps } from './interface'

export function FormDetailPromotionCodePage({
  data,
  isLoading,
  promotionChannelOption,
  coinOptions,
  handleApprove,
  handleReject,
  goBackHandle,
}: FormDetailPromotionCodePageProps) {
  return (
    <Container>
      {isLoading ? (
        <Loading size={36} className='m-auto' />
      ) : data ? (
        <>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={goBackHandle}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>
            <div className='text-[24px] text-secondary line-clamp-1 break-all font-medium font-mitr'>
              {data.promotionName}
            </div>
          </div>

          <PromotionCodeViewForm
            data={data}
            promotionChannelOption={promotionChannelOption}
            coinOptions={coinOptions}
            handleApprove={handleApprove}
            handleReject={handleReject}
          />
        </>
      ) : (
        <div className='m-auto'>เกิดข้อผิดพลาดบางอย่าง</div>
      )}
    </Container>
  )
}
