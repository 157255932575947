import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByCoverType {
  @Expose() bookId: number

  @Expose() title: string

  @Expose() writer: string

  @Expose() penName: string

  @Expose() totalSearch: number

  @Expose() cover: string
}

@Exclude()
export class SearchResultByCoverResponse {
  @Expose()
  @Type(() => SearchResultByCoverType)
  data: SearchResultByCoverType[]

  @Expose() page: number
}
