import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { DuplicatePromotionCodePageProps } from './interface'

export function withDuplicatePromotionCodePage(
  Component: React.FC<DuplicatePromotionCodePageProps>
) {
  function WithDuplicatePromotionCodePage() {
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmCreateModal = useModal({ modal: 'confirmCreatePromotionCode' })
    const confirmLeaveModal = useModal({ modal: 'confirm' })
    const queryKey: QueryKey = ['promotion', 'code', 'duplicate', Number(id)]

    const { data, isLoading } = useQuery(
      queryKey,
      () => client?.promotionCodeClient.getPromotionCodeDupForm(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const { data: coinOptions = [] } = useQuery(
      ['coin-options', 'promotion-code'],
      () => client?.promotionCodeClient.getCoinOptions()
    )

    const { mutateAsync: createPromotion } = useMutation(
      (form: PromotionCodeFormType) =>
        client!.promotionCodeClient.createPromotionCode(form),
      {
        onSuccess: promotionId => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          confirmCreateModal.hide()
          navigate(
            `/promotion-setting/promotion-code/${promotionId}/create-code`
          ) // TODO: redirect to approve page after submit form success
        },
        onError: error => {
          alert.error(getErrorMessage(error))
          confirmCreateModal.hide()
        },
      }
    )
    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionCodeFormType) =>
        client!.promotionCodeClient.createPromotionCode(values),
      {
        onSuccess: () => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function createPromotionCode(values: PromotionCodeFormType) {
      await createPromotion(values)
      confirmCreateModal.hide()
    }

    function handleLeave(values: PromotionCodeFormType) {
      confirmLeaveModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่น Code
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(values)
          confirmLeaveModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmLeaveModal.hide()
          navigate(-1)
        },
      })
    }

    const newProps = {
      initialValues: {
        ...data!,
        startNowAt: undefined,
        startPublishedAt: new Date(),
        endPublishedAt: DateTime.now().plus({ month: 1 }).toJSDate(),
      },
      createPromotionCode,
      handleLeave,
      isLoading,
      coinOptions,
    }

    return <Component {...newProps} />
  }

  return WithDuplicatePromotionCodePage
}
