import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import { StickerManageType } from '@models/sticker/StickerManageType'
import { SearchStickerParam } from '@features/chapterBook/pages/StickerManagementPage/interface'
import { SortingType } from '@components/Table/interface'
import { CREATE_STICKER } from './schemas/createSticker'
import { GET_STICKER_LIST } from './schemas/getStickerList'
import { GET_STICKER } from './schemas/getSticker'
import { UPDATE_STICKER } from './schemas/updateSticker'
import { REMOVE_STICKER } from './schemas/removeSticker'
import { REMOVE_STICKER_LIST } from './schemas/removeStickerList'
import { GetStickerListResponse } from './interface'
import { FileClient } from '../File/FileClient'

export class StickerClient {
  constructor(private client: GraphQLClient, private fileClient: FileClient) {}

  async getStickerList(
    param: SearchStickerParam
  ): Promise<GetStickerListResponse> {
    const { stickers } = await this.client.request(GET_STICKER_LIST, {
      limitPerPage: param.perpage,
      page: param.page,
      orderBy: {
        createdAt: SortingType.DESC,
      },
    })

    return {
      data: plainToInstance(StickerManageType, stickers.data as []),
      total: stickers.total,
      page: stickers.page,
    }
  }

  async getSticker(id: number): Promise<StickerManageType> {
    const { sticker } = await this.client.request(GET_STICKER, {
      id,
    })

    return plainToInstance(StickerManageType, sticker)
  }

  async createSticker(form: StickerManagementFormType) {
    let stickerImgPath = ''
    if (form.upload.blob) {
      stickerImgPath = await this.fileClient.uploadFile(form.upload.blob)
    }

    await this.client.request(CREATE_STICKER, {
      createStickerInput: {
        stickerImgPath,
        name: form.name,
      },
    })
  }

  async updateSticker(form: StickerManagementFormType): Promise<void> {
    let stickerImgPath = form.upload.url
    if (form.upload.blob) {
      stickerImgPath = await this.fileClient.uploadFile(form.upload.blob)
    }

    await this.client.request(UPDATE_STICKER, {
      updateStickerInput: {
        id: form.id,
        stickerImgPath,
        name: form.name,
      },
    })
  }

  async removeSticker(id: number): Promise<void> {
    await this.client.request(REMOVE_STICKER, {
      id,
    })
  }

  async removeStickerList(ids: number[]): Promise<void> {
    await this.client.request(REMOVE_STICKER_LIST, {
      ids,
    })
  }
}
