import { Duration } from 'luxon'

import { VerticalBarChart } from '@components/chart/VerticalBarChart'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { BarChartByUserProps } from './interface'

function BarChartByUser({
  readTimeUsage,
  frequencyToBuyCoin,
  timeOfUse,
}: BarChartByUserProps) {
  return (
    <div>
      <VerticalBarChart
        title='ความถี่ในการซื้อเหรียญ'
        headerTitleClassName='text-[24px]'
        style={{ width: '100%', height: '350px' }}
        dataSource={frequencyToBuyCoin.data}
        grid={{ left: 50, right: 0, top: 10 }}
        subTitle={
          <div>
            ความถี่การซื้อโดยเฉลี่ย:
            <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
              {frequencyToBuyCoin.averageBuyCoin
                ? frequencyToBuyCoin.averageBuyCoin.toLocaleString()
                : '-'}
            </span>
            {frequencyToBuyCoin.keyType === TimeRangeEnum.DAY
              ? ' ครั้ง/วัน'
              : frequencyToBuyCoin.keyType === TimeRangeEnum.MONTH
              ? 'ครั้ง/เดือน'
              : 'ครั้ง/ปี'}
          </div>
        }
      />
      <VerticalBarChart
        title='ช่วงเวลาเข้าใช้งาน'
        headerTitleClassName='text-[24px]'
        style={{ width: '100%', height: '350px' }}
        labelFormatter={(value: number, index: number) => {
          return `${Duration.fromObject({ minutes: value })?.toFormat(
            'm:ss'
          )} mins`
        }}
        grid={{ left: 70, right: 0, top: 10 }}
        dataSource={readTimeUsage.timeList}
        subTitle={
          <div className='flex items-center gap-x-[30px]'>
            <div>
              เวลาที่ใช้โดยเฉลี่ย:
              <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
                {readTimeUsage.averageTimeUsage
                  ? Duration.fromObject({
                      milliseconds: readTimeUsage.averageTimeUsage,
                    })?.toFormat('h:m')
                  : '-'}
              </span>
              ชม.
            </div>
            <div>
              ช่วงเวลาที่เข้าใช้งาน:
              <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
                {timeOfUse.length ? timeOfUse.join(', ') : '-'}
              </span>
              ชม.
            </div>
            <div>
              เวลาที่ใช้มากที่สุด:
              <span className='ml-[10px] mr-[5px] text-secondary font-medium text-base font-mitr'>
                {readTimeUsage.greatestTimeUsage
                  ? Duration.fromObject({
                      milliseconds: readTimeUsage.greatestTimeUsage,
                    })?.toFormat('h:m')
                  : '-'}
              </span>
              ชม.
            </div>
          </div>
        }
      />
    </div>
  )
}

export default BarChartByUser
