import cn from 'classnames'
import {
  CardContainer,
  CardValue,
  TextLabel,
  TextTitle,
  TextValue,
} from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'
import { EbookDetailType } from '@models/ebook/EbookDetailType'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'

export function Cover({ data }: { data: EbookDetailType }) {
  return (
    <CardContainer className='grid grid-cols-[304px_1fr] w-full gap-x-[30px]'>
      <div className='w-[304px] h-[435px]'>
        <img
          className='w-full h-full rounded-lg object-cover'
          src={data?.coverImgPath}
          alt='cover'
        />
      </div>

      <div className='w-full'>
        <div className='flex items-start'>
          <TextTitle className='break-word line-clamp-2'>
            {data?.title}
          </TextTitle>

          <div
            className={cn(
              'rounded text-center px-[10px] py-[4px] mt-[6px] font-medium ml-[10px] text-green bg-[#48bea21a] text-xs',
              {
                'min-w-[76px]': data?.ebookStatus === EbookStatusEnum.DRAFT,
                'min-w-[62px]': data?.ebookStatus === EbookStatusEnum.PUBLISHED,
              }
            )}
          >
            {data?.ebookStatus === EbookStatusEnum.DRAFT
              ? 'ไม่เผยแพร่'
              : 'เผยแพร่'}
          </div>
        </div>

        <div className='font-light text-[14px] text-primary underline flex gap-x-[20px] mt-[10px]'>
          <span>{bookTypeValue[data?.bookType as BookEnum]}</span>
          <span>{data?.category.name}</span>
        </div>

        <hr className='my-[30px]' />

        <TextTitle>ข้อมูลนักเขียน</TextTitle>

        <div className='flex items-center gap-x-5 mt-5'>
          <img
            className='w-10 h-10 object-cover rounded-full'
            src={data?.penName?.user?.avatarImgPath || '/images/avatar.png'}
            alt='writer_profile'
          />

          <div className='grid grid-cols-2 gap-x-[50px] w-full font-light text-[16px] leading-8'>
            <div className='text-secondary-100 break-word line-clamp-1 '>
              {!!data?.penName.user.writer &&
              data.penName.user.writer.affiliation === AffiliationEnum.PUBLISHER
                ? 'สำนักพิมพ์ : '
                : 'นักเขียน : '}
              <span className='font-bold text-secondary '>
                {data?.penName.user.displayName}
              </span>
            </div>

            <div className='text-secondary-100 break-word line-clamp-1'>
              นามปากกา :{' '}
              <span className='font-bold text-secondary'>
                {data?.penName.firstPenName}
              </span>
            </div>
          </div>
        </div>

        <hr className='my-[30px]' />

        <div className='flex space-x-[60px]'>
          <CardValue>
            <TextLabel>ยอดดาวน์โหลด</TextLabel>
            <TextValue className='text-primary text-[24px]'>
              {data?.purchaseCount.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>ยอดขาย</TextLabel>
            <TextValue className='text-primary text-[24px]'>
              {data?.totalSales.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>เรตติ้ง</TextLabel>
            <TextValue className='text-primary text-[24px]'>{`${data?.avgRating}(${data?.reviewCount})`}</TextValue>
          </CardValue>
        </div>

        <hr className='mt-[30px] mb-[25px]' />

        <div className='flex space-x-[60px]'>
          <CardValue>
            <TextLabel>ยอดวิว</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.viewCount}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>ทดลองอ่าน</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.readFreeTrialCount.toLocaleString()}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>รีวิว</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.reviewCount.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>
      </div>
    </CardContainer>
  )
}
