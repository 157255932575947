/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

export function RedirectPage({ pathname }: { pathname: string }) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate({ pathname })
  }, [])

  return null
}
