import React from 'react'
import { useField } from 'formik'

import { OptionType } from '@interfaces/OptionType'
import { DaySelectEnum } from '@interfaces/promotionCode/DaySelectEnum'
import { MultiSelectDayProps, WithMultiSelectDayProps } from './interface'

const dayOptions: OptionType[] = [
  {
    label: 'วันจันทร์',
    value: DaySelectEnum.MONDAY,
  },
  {
    label: 'วันอังคาร',
    value: DaySelectEnum.TUESDAY,
  },
  {
    label: 'วันพุธ',
    value: DaySelectEnum.WEDNESDAY,
  },
  {
    label: 'วันพฤหัสบดี',
    value: DaySelectEnum.THURSDAY,
  },
  {
    label: 'วันศุกร์',
    value: DaySelectEnum.FRIDAY,
  },
  {
    label: 'วันเสาร์',
    value: DaySelectEnum.SATURDAY,
  },
  {
    label: 'วันอาทิตย์',
    value: DaySelectEnum.SUNDAY,
  },
]
export function withMultiSelectDay(Component: React.FC<MultiSelectDayProps>) {
  function WithMultiSelectDay({ name, ...props }: WithMultiSelectDayProps) {
    const [field, meta, { setValue }] = useField(name)

    function handleSelectAll() {
      if (field.value.length === 7) {
        setValue([])
      } else {
        setValue(dayOptions.map(item => item.value))
      }
    }

    const pageProps = {
      options: dayOptions,
      ...props,
      ...field,
      error: meta.error,
      touched: meta.touched,
      handleSelectAll,
    }
    return <Component {...pageProps} />
  }

  return WithMultiSelectDay
}
