import { useMemo, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { client } from '@client/init'
import { formatDate, getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinManagementHistoryPageProps } from './interface'

export function withCoinManagementHistoryPage(
  Component: React.FC<CoinManagementHistoryPageProps>
) {
  function WithCoinManagementHistoryPage() {
    const alert = useAlert()
    const confirmModal = useModal({
      modal: 'confirm',
    })
    const [activeIndex, setActiveIndex] = useState(0)

    function handleChangeHistory(index: number) {
      setActiveIndex(index)
    }

    const {
      fetchNextPage,
      data: coinHistory,
      isFetchingNextPage,
      refetch,
    } = useInfiniteQuery(
      ['coin-history'],
      async ({ pageParam = 1 }) => {
        const res = await client?.coinClient.getCoinHistories({
          limitPerPage: 20,
          page: pageParam,
        })

        return res
      },
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleApprove(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              คุณแน่ใจหรือไม่ว่า อนุมัติคำขอ
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.coinClient.updateCoinRequestStatus({
              id,
              status: CoinRequestStatusEnum.APPROVED,
            })
            alert.success('อนุมัติคำขอสำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            refetch()
            confirmModal.hide()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    function handleReject(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              คุณแน่ใจหรือไม่ว่า ต้องการปฏิเสธคำขอ หากกดปฏิเสธแล้ว
              การปรับแต่งค่าทั้งหมดจะคืนกลับสู่ค่าเดิมก่อนหน้านี้
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.coinClient.updateCoinRequestStatus({
              id,
              status: CoinRequestStatusEnum.REJECTED,
            })
            alert.success('ปฏิเสธคำขอสำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            refetch()
            confirmModal.hide()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const optionData = useMemo(() => {
      return (
        coinHistory?.pages.flatMap(page =>
          page.data.map(item => ({
            text: `${formatDate(item.createdAt)} - ${DateTime.fromISO(
              item.createdAt
            ).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`,
            status: item.status,
          }))
        ) ?? []
      )
    }, [coinHistory])

    const coinHistoryData = useMemo(() => {
      return coinHistory?.pages.flatMap(page => page.data) ?? []
    }, [coinHistory])

    const newProps = {
      activeIndex,
      optionData,
      currentCoinHistory: coinHistoryData[activeIndex],
      handleChangeHistory,
      handleFetchNextPage,
      handleApprove,
      handleReject,
    }

    return <Component {...newProps} />
  }

  return WithCoinManagementHistoryPage
}
