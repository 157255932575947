import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PenIcon } from '@components/Icons'
import { CategoryType } from '@models/category/CategoryType'
import { CategoryCoverType } from '@models/category/CategoryCoverType'
import PlusCoverCard from './PlusCoverCard'

function CategorySection({ category }: { category: CategoryType }) {
  const navigate = useNavigate()

  function goToCategoryCoverPage() {
    navigate(`/chapter-book/cover/${category.id}`)
  }

  return (
    <div className='border-b pb-[20px] mb-[25px]'>
      <div className='mb-[14px] flex'>
        <div className='flex-1'>
          <span className='font-bold'>{category.name}</span>
          <span className='font-light ml-[20px] text-secondary-100'>{`(${category.categoryCovers.length.toLocaleString()} รายการ)`}</span>
        </div>
        <div
          className='border p-[4px] rounded-[8px] cursor-pointer'
          onClick={goToCategoryCoverPage}
        >
          <PenIcon width='20' height='20' className='text-secondary-100' />
        </div>
      </div>
      {!category.categoryCovers.length ? (
        <PlusCoverCard
          value={0}
          goToCategoryCoverPage={goToCategoryCoverPage}
        />
      ) : (
        <div className='flex gap-x-[10px]'>
          {category.categoryCovers
            .slice(0, 12)
            .map((cover: CategoryCoverType) => (
              <div
                key={cover.id}
                className='w-[66px] h-[92px] rounded-[8px] flex items-center justify-center'
              >
                <img
                  src={cover.imgPath}
                  alt='Cover'
                  className='w-full h-full rounded-[8px]'
                />
              </div>
            ))}
          {category.categoryCovers.length > 12 && (
            <PlusCoverCard
              value={category.categoryCovers.slice(12).length}
              goToCategoryCoverPage={goToCategoryCoverPage}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default CategorySection
