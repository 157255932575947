import { DateTime } from 'luxon'
import { Waypoint } from 'react-waypoint'

import { ArrowRightIcon } from '@components/Icons'
import {
  HistoryItemProps,
  HistoryPopupBannerProps,
  HistoryType,
} from './interface'

function HistoryItem({ type, data }: HistoryItemProps) {
  function transFormDate(value: string) {
    return DateTime.fromISO(value).toFormat('dd LLL yyyy - HH:mm:ss', {
      locale: 'th-Th',
    })
  }

  if (type === HistoryType.CreatedBy) {
    return (
      <>
        <div className='col-span-3 font-normal'>
          {transFormDate(data.createdAt)}
        </div>
        <div className='col-span-4' />
        <div className='col-span-1 flex items-center space-x-[10px]'>
          <img
            className='w-[50px] h-[50px] rounded-full'
            src={
              data.createdBy.avatarImgPath
                ? data.createdBy.avatarImgPath
                : '/images/avatar.png'
            }
            alt={data.createdBy.displayName}
          />

          <div className='font-bold'>{data.createdBy.displayName}</div>
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (type === HistoryType.Title && data.correctionDetail.title) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Title :
          </span>
          {data.correctionDetail.title.old}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Title :
          </span>
          {data.correctionDetail.title.new}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (type === HistoryType.Link && data.correctionDetail.link) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Link :
          </span>
          {data.correctionDetail.link.old}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Link :
          </span>
          {data.correctionDetail.link.new}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (type === HistoryType.UserType && data.correctionDetail.userTypes) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            สำหรับ :
          </span>
          {(data.correctionDetail.userTypes.old as string[]).join(', ')}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            สำหรับ :
          </span>
          {(data.correctionDetail.userTypes.new as string[]).join(', ')}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (type === HistoryType.DisplayAt && data.correctionDetail.screen) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Display at :
          </span>
          {data.correctionDetail.screen.old}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Display at :
          </span>
          {data.correctionDetail.screen.new}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (type === HistoryType.Platform && data.correctionDetail.platforms) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Display :
          </span>
          {(data.correctionDetail.platforms.old as string[]).join(', ')}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            Display :
          </span>
          {(data.correctionDetail.platforms.new as string[]).join(', ')}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  if (
    type === HistoryType.Published &&
    data.correctionDetail.startPublishedAt &&
    data.correctionDetail.endPublishedAt
  ) {
    return (
      <>
        <div className='col-span-3'>
          <span className='text-secondary-100 leading-[1.5] mr-[10px]'>
            เผยแพร่ :
          </span>
          {transFormDate(data.correctionDetail.startPublishedAt.old as string)}
          <span className='text-secondary-100 leading-[1.5] mx-[10px]'>
            ถึง
          </span>
          {transFormDate(data.correctionDetail.endPublishedAt.old as string)}
        </div>
        <div className='col-span-1' />
        <div className='col-span-1'>
          <ArrowRightIcon className='text-primary' width='18' height='18' />
        </div>
        <div className='col-span-3'>
          {transFormDate(data.correctionDetail.startPublishedAt.new as string)}
          <span className='text-secondary-100 leading-[1.5] mx-[10px]'>
            ถึง
          </span>
          {transFormDate(data.correctionDetail.endPublishedAt.new as string)}
        </div>
        <div className='col-span-8 mb-[10px]' />
      </>
    )
  }

  return null
}

export function HistoryPopupBanner({
  data,
  handleFetchNextPage,
}: HistoryPopupBannerProps) {
  if (data?.pages[0].data.length === 0) return null

  return (
    <div className='mt-[40px]'>
      <div className='text-[24px] text-black-480 font-medium font-mitr mb-[20px]'>
        ประวัติการดำเนินการ
      </div>
      <div className='grid grid-cols-8 text-secondary text-[14px] font-light items-center'>
        <div className='text-secondary-100 font-normal'>เวลา</div>
        <div className='col-span-6' />
        <div className='text-secondary-100 font-normal'>โดย</div>
        <hr className='col-span-8 mt-[10px] mb-[20px]' />
        {data?.pages
          .flatMap(row => row.data)
          .map(row => (
            <>
              <HistoryItem data={row} type={HistoryType.CreatedBy} />
              <HistoryItem data={row} type={HistoryType.Title} />
              <HistoryItem data={row} type={HistoryType.Link} />
              <HistoryItem data={row} type={HistoryType.DisplayAt} />
              <HistoryItem data={row} type={HistoryType.Platform} />
              <HistoryItem data={row} type={HistoryType.Published} />
              <HistoryItem data={row} type={HistoryType.UserType} />
              <hr className='col-span-8 mt-[10px] mb-[20px]' />
            </>
          ))}
        <Waypoint onEnter={handleFetchNextPage} />
      </div>
    </div>
  )
}
