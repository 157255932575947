import { Container } from '@components/Container'
import { SelectButtonLine } from '@components/SelectButtonLine'
import { CoinLayoutProps } from './interface'

export function CoinLayout({
  children,
  tab,
  coinTabOption,
  handleTabChange,
}: CoinLayoutProps) {
  return (
    <Container>
      <div className='font-mitr text-[24px] text-secondary'>Coin</div>

      <SelectButtonLine
        options={coinTabOption}
        value={tab}
        className='mt-[20px] mb-[30px]'
        onChange={handleTabChange}
      />

      {children}
    </Container>
  )
}
