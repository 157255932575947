import cn from 'classnames'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinValue } from './components/CoinValue'
import { CoinPageProps } from './interface'

export function CoinPage({
  coins,
  coinRequest,
  hasCoinRequests,
  navigate,
  handleSubmit,
  handleApprove,
  handleReject,
}: CoinPageProps) {
  const permission = usePermissionAction('coin')
  return (
    <>
      <div className='flex items-center justify-between w-full pb-[30px] border-0'>
        <div className='flex flex-1 text-[14px] font-mitr gap-x-[10px] justify-between items-center px-[10px]'>
          <button
            type='button'
            className={cn(
              'flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[60px] justify-center disabled:opacity-30 text-white bg-secondary',
              {
                hidden: !permission.create,
              }
            )}
            onClick={() =>
              navigate({
                pathname: '/coin/coin-setting/create-coin',
              })
            }
            disabled={coinRequest?.status === CoinRequestStatusEnum.PENDING}
          >
            <span>+ เพิ่มเหรียญ</span>
          </button>
          {hasCoinRequests && (
            <button
              type='button'
              className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 border border-secondary text-secondary'
              onClick={() =>
                navigate({
                  pathname: '/coin/coin-setting/history-coin',
                })
              }
            >
              <span>ดูประวัติการเปลี่ยนมูลค่าเหรียญ</span>
            </button>
          )}
        </div>
      </div>
      {/* TODO: รอ API */}
      {/* <CoinInformation coinInformation={data.coinInformation} /> */}
      <CoinValue
        coins={coins}
        coinRequest={coinRequest}
        onSubmit={value => {
          if (coinRequest?.status === CoinRequestStatusEnum.PENDING) {
            handleApprove(coinRequest.id)
          } else {
            handleSubmit(value)
          }
        }}
        onReject={() => {
          if (coinRequest) {
            handleReject(coinRequest?.id)
          }
        }}
      />
    </>
  )
}
