import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { queryClient } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { ReportBookEnum } from '@interfaces/ReportBookType'
import { ReportProps, UpdateBookReportInputType } from './interface'

export function withReportListDetailPage(Component: React.FC<ReportProps>) {
  function WithReportListDetailPage() {
    const { id } = useParams()
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const reportDetailActivityModal = useModal({
      modal: 'reportDetailActivity',
    })
    const publishActivityModal = useModal({ modal: 'publishActivity' })
    const confirmChangePublish = useModal({ modal: 'confirm' }, true)

    const { data, isLoading } = useQuery(
      ['book-report-detail', id],
      () => client?.reportClient.bookReport(Number(id)),
      {
        enabled: isAuthenticated && Boolean(Number(id)),
      }
    )

    const { mutate: updateBookReport, isLoading: isUpdating } = useMutation(
      (inputData: UpdateBookReportInputType) =>
        client!.reportClient.updateBookReport(inputData),
      {
        onSuccess: () => {
          alert.success(`อัปเดตรายละเอียดสำเร็จ`)
          queryClient.invalidateQueries(['book-report-detail', id])
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด`)
        },
      }
    )

    function handleUpdateBookReport(input: UpdateBookReportInputType) {
      updateBookReport(input)
    }

    function handleOpenReportDetailActivityModal(reportId: number) {
      reportDetailActivityModal.show({ reportId })
    }

    function handleOpenPublishActivityModal(
      bookOrReportId: number,
      type: ReportBookEnum
    ) {
      publishActivityModal.show({ id: bookOrReportId, type })
    }

    function handleOpenConfirmChangePublishModal(input: {
      id: number
      isVisible: boolean
    }) {
      confirmChangePublish.show({
        content: (
          <ConfirmModal.Title>
            ยืนยันการ
            {input.isVisible ? 'เปิด' : 'ปิด'}
            เผยแพร่เนื้อหา
          </ConfirmModal.Title>
        ),
        onConfirm: () => {
          handleUpdateBookReport(input)
          confirmChangePublish.hide()
        },
        onClose: () => confirmChangePublish.hide(),
      })
    }

    const newProps = {
      report: data,
      isLoading,
      handleUpdateBookReport,
      handleOpenPublishActivityModal,
      handleOpenConfirmChangePublishModal,
      handleOpenReportDetailActivityModal,
    }

    return <Component {...newProps} />
  }

  return WithReportListDetailPage
}
