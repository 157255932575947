import { gql } from 'graphql-request'

export const GET_SEARCH_KEYWORD = gql`
  query SearchKeyword {
    searchKeyword {
      banKeywords {
        id
        keyword
      }
      fiveWordRecommendKeyWord {
        id
        keyword
      }
      lastUpdatedBy {
        displayName
        avatarImgPath
      }
    }
  }
`
