import React from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { WithReaderBadgeProps, ReaderBadgeProps } from './interface'

const withReaderBadge = (Component: React.FC<ReaderBadgeProps>) => {
  function WithReaderBadge({ queryParam, challenge }: WithReaderBadgeProps) {
    const { data: readerBadge = [] } = useQuery(
      ['reader-badge', challenge],
      () =>
        client?.dashboardClient.getReaderBadge({
          userId: Number(queryParam.userId),
          challengeTypeArgs: challenge,
        }),
      {
        enabled: !!queryParam.userId,
      }
    )

    const newProps = { readerBadge }
    return <Component {...newProps} />
  }

  return WithReaderBadge
}

export default withReaderBadge
