import { gql } from 'graphql-request'

export const GET_PROMOTIONS = gql`
  query Promotions(
    $limitPerPage: Int
    $page: Int
    $searchText: String
    $startPublishedAt: DateTime
    $endPublishedAt: DateTime
    $type: PromotionType
    $status: PromotionStatus
    $orderBy: PromotionOrderBy
    $challengeAction: PromotionChallengeAction
  ) {
    promotions(
      limitPerPage: $limitPerPage
      page: $page
      searchText: $searchText
      startPublishedAt: $startPublishedAt
      endPublishedAt: $endPublishedAt
      type: $type
      status: $status
      orderBy: $orderBy
      challengeAction: $challengeAction
    ) {
      data {
        id
        businessId
        type
        status
        name
        description
        isActive
        isEditable
        startPublishedAt
        endPublishedAt
        userType
        isFirstTopUp
        usageLimitPerUser
        usageLimit
        usageDay
        promotionChallenge {
          id
          action
          actionValue
          wordCount
          benefitLimitType
          benefitLimitValue
          benefitValue
          budget
          budgetPerDay
          bookTypes
          socialMediaChannels
          readCondition
          summary {
            userCount
            progressCount
            coinValue
            coin {
              id
              name
            }
          }
          coinsCondition {
            id
            name
          }
          categories {
            id
            name
          }
        }
        promotionCode {
          id
          type
          buyCoinConditionValue
          buyCoinCondition
          channels {
            name
            totalCount
            totalUsedCount
          }
          paymentChannels
          benefitValue
          coin {
            id
            name
          }
        }
        promotionSale {
          actionValue
          benefitCoin {
            name
          }
          benefitCoinId
          benefitLimitType
          benefitLimitValue
          benefitValue
          budget
          budgetPerDay
          categories {
            id
            name
          }
          coinsCondition {
            id
            name
          }
          conditionFileName
          conditionType
          id
          termCount
          useCoinConditionType
          summary {
            totalBook
            totalDeal
            totalSale
            totalSell
            userCount
          }
        }
        createdAt
        publishedType
        userTerm
        isUsageLimitPerUser
        isUsageLimit
        usersFileName
        users {
          id
          displayName
          avatarImgPath
        }
        lastRequest {
          id
          createdBy {
            id
            displayName
            avatarImgPath
          }
          createdAt
          action
          status
        }
        generateCouponStatus
        couponValue
      }
      total
      page
    }
  }
`
