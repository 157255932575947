import { Waypoint } from 'react-waypoint'
import { StoryCard } from '@components/StoryCard'
import { Container } from '@components/Container'
import { BanListPageProps } from './interface'

export function BanListPage({
  title,
  books,
  isLoading,
  handleBanBook,
  handleFetchNextPage,
}: BanListPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>ดูรายการแบนหนังสือ</Container.Title>
      <div className=' grid grid-cols-2 gap-y-[30px] gap-x-[15px] mt-[30px] mx-auto'>
        {books?.pages.map((page, i) =>
          page?.data.map(item => (
            <div key={item.id} onClick={() => {}}>
              <StoryCard
                className='w-[500px]'
                header={title}
                topChartId={item.id}
                title={item.book.title}
                imageUrl={item.book.coverImgPath}
                category={item.book.category.name}
                publisher={item.book.penName?.user.displayName}
                writer={item.book.writer}
                penName={item.book.penName?.firstPenName}
                view={item.viewCount}
                like={item.likeCount}
                previousRank={item.previousRank}
                rankChange={item.rankChange}
                isBan={item.isBan}
                banReason={item.banReason}
                isLoading={isLoading}
                handleBanBook={handleBanBook}
                isBanCard
              />
            </div>
          ))
        )}
      </div>
      {books?.pages[0]?.data.length === 0 && (
        <p className='text-center  mt-[30px]'>ไม่มีรายการแบนหนังสือ</p>
      )}
      <Waypoint onEnter={handleFetchNextPage} />
    </Container>
  )
}
