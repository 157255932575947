import cn from 'classnames'

import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function Card({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'bg-white border border-[#f4f4f7] p-[30px] rounded-lg shadow mb-[30px]',
        className
      )}
    >
      {children}
    </div>
  )
}

Card.TextVertical = function TextVertical({
  label,
  lastLabel,
  value,
  valueColor = 'secondary-bold',
}: {
  label: string
  lastLabel?: string
  value: string | number
  valueColor?:
    | 'primary'
    | 'secondary-bold'
    | 'secondary-100-bold'
    | 'secondary-light'
    | 'secondary-100-light'
    | 'gold'
}) {
  return (
    <p className='text-[14px] font-light text-secondary-100'>
      {label}
      <span
        className={cn('text-sm text-secondary ml-[10px]', {
          'text-primary font-bold': valueColor === 'primary',
          'text-secondary font-bold': valueColor === 'secondary-bold',
          'text-secondary font-light': valueColor === 'secondary-light',
          'text-secondary-100 font-bold': valueColor === 'secondary-100-bold',
          'text-secondary-100 font-light': valueColor === 'secondary-100-light',
          'text-gold': valueColor === 'gold',
        })}
      >
        {typeof value === 'number' ? value.toLocaleString() : value}
      </span>
      {lastLabel && (
        <span className='text-sm text-secondary-100 ml-[10px]'>
          {lastLabel}
        </span>
      )}
    </p>
  )
}

Card.TextHorizontal = function TextHorizontal({
  label,
  lastLabel,
  value,
  valueColor = 'secondary',
}: {
  label: string
  lastLabel?: string
  value: string | number
  valueColor?: 'primary' | 'secondary' | 'secondary-100' | 'gold'
}) {
  return (
    <div className='flex flex-col gap-y-1'>
      <p className='text-sm font-light text-secondary-100'>{label}</p>
      <p
        className={cn('text-2xl font-bold text-secondary', {
          'text-primary': valueColor === 'primary',
          'text-secondary': valueColor === 'secondary',
          'text-secondary-100': valueColor === 'secondary-100',
          'text-gold': valueColor === 'gold',
        })}
      >
        {typeof value === 'number' ? value.toLocaleString() : value}
      </p>
    </div>
  )
}
