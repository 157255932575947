import { useQueryClient } from 'react-query'
import cloneDeep from 'lodash.clonedeep'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { getErrorMessage } from '@libs/utils'
import { UserManagementResponse } from '@models/user/UserManagementResponse'
import { PunishmentEnum } from '@interfaces/user/PunishmentEnum'
import { useAuthentication } from '@hooks/useAuthentication'
import { ActionCellProps, WithActionCellProps } from './interface'
import { InitialBanUserModalType } from '../BanUserModal/interface'

export function withActionCell(Component: React.FC<ActionCellProps>) {
  function WithActionCell({
    queryKey,
    writingDisabled,
    ...props
  }: WithActionCellProps) {
    const client = useClient()
    const queryClient = useQueryClient()
    const alert = useAlert()
    const { user } = useAuthentication()
    const banUserModal = useModal({ modal: 'banUser' })
    const confirmModal = useModal({ modal: 'confirm' })

    async function handleResetUserPassword(userId: number) {
      try {
        await client?.userManagementClient.resetUserPassword(userId)
        alert.success('ส่งคำขอรีเซ็ตรหัสผ่านไปยังอีเมลผู้ใช้งานสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    async function handleToggleWritingDisabled(userId: number) {
      try {
        await client?.userManagementClient.toggleWritingDisabled(userId)
        queryClient.setQueryData<UserManagementResponse | undefined>(
          queryKey,
          (oldData?: UserManagementResponse) => {
            if (oldData) {
              const dataClone = cloneDeep(oldData)
              const index = dataClone.data.findIndex(item => item.id === userId)
              dataClone.data[index].writingDisabled =
                !dataClone.data[index].writingDisabled
              return dataClone
            }
            return oldData
          }
        )
        if (writingDisabled) {
          alert.success('เปิดการสร้างงานเขียนสำเร็จ')
        } else {
          alert.success('ปิดการสร้างงานเขียนสำเร็จ')
        }
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    async function handleBanUser(userId: number) {
      banUserModal.show({
        id: userId,
        onConfirm: async (val: InitialBanUserModalType) => {
          try {
            await client!.userManagementClient.createUserPunishment({
              userId,
              operatedId: user.id,
              message: val.message,
              period: val.period,
              punishmentType: PunishmentEnum.BAN,
            })
            alert.success('ดำเนินการสำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            await queryClient.invalidateQueries(queryKey)
            banUserModal.hide()
          }
        },
        onHistory: async () => {
          window.open(
            `/user/user-management/${userId}?tab=ban&banTab=${PunishmentEnum.BAN}`,
            '_blank'
          )
        },
        onClose: () => {
          banUserModal.hide()
        },
      })
    }

    async function handleWarningUser(userId: number) {
      banUserModal.show({
        id: userId,
        isWarning: true,
        onConfirm: async (val: InitialBanUserModalType) => {
          try {
            await client!.userManagementClient.createUserPunishment({
              userId,
              operatedId: user.id,
              message: val.message,
              punishmentType: PunishmentEnum.ADMONISH,
            })
            alert.success('ดำเนินการสำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            await queryClient.invalidateQueries(queryKey)
            banUserModal.hide()
          }
        },
        onHistory: async () => {
          window.open(`/user/user-management/${userId}?tab=ban`, '_blank')
        },
        onClose: () => {
          banUserModal.hide()
        },
      })
    }

    async function handleUnbanUser(userId: number) {
      try {
        await client?.userManagementClient.unBanUser(userId)
        queryClient.setQueryData<UserManagementResponse | undefined>(
          queryKey,
          (oldData?: UserManagementResponse) => {
            if (oldData) {
              const dataClone = cloneDeep(oldData)
              const index = dataClone.data.findIndex(item => item.id === userId)
              dataClone.data[index].isBan = !dataClone.data[index].isBan
              return dataClone
            }
            return oldData
          }
        )
        alert.success('ยกเลิกแบนสำเร็จ')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    function resetPinCode(userId: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการรีเซ็ต PIN code หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.userManagementClient.resetPinCode(userId)
            alert.success('รีเซ็ต PIN code สำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    const componentProps = {
      ...props,
      writingDisabled,
      handleBanUser,
      handleWarningUser,
      handleResetUserPassword,
      handleToggleWritingDisabled,
      handleUnbanUser,
      resetPinCode,
    }

    return <Component {...componentProps} />
  }

  return WithActionCell
}
