import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import React from 'react'
import { useQuery } from 'react-query'
import { PromotionCodeFormProps, WithPromotionCodeFormProps } from './interface'

export function withPromotionCodeForm(
  Component: React.FC<PromotionCodeFormProps>
) {
  function WithPromotionCodeForm({
    handleSubmit,
    title,
    coinOptions,
    ...props
  }: WithPromotionCodeFormProps) {
    const confirmCreateModal = useModal({ modal: 'confirmCreatePromotionCode' })

    const { data: promotionChannelOption = [] } = useQuery('saleChannels', () =>
      client?.promotionCodeClient.getSaleChannels()
    )

    function confirmCreate(values: PromotionCodeFormType) {
      confirmCreateModal.show({
        values,
        handleSubmit,
        promotionChannelOption,
        coinOptions,
        title,
      })
    }
    const newProps = {
      confirmCreate,
      promotionChannelOption,
      coinOptions,
      title,
      ...props,
    }

    return <Component {...newProps} />
  }

  return WithPromotionCodeForm
}
