import cn from 'classnames'
import { Table } from '@components/Table'
import { Pagination } from '@components/Pagination'
import { SortingType } from '@components/Table/interface'
import { columns } from './column'
import { WritingTableProps } from './interface'

export function WritingTable({
  data,
  page,
  perpage,
  total,
  isLoading,
  pageChange,
  handleSort,
}: WritingTableProps) {
  return (
    <>
      <Table
        data={data}
        columns={columns}
        className={cn({ 'min-h-[400px]': isLoading })}
        bodyClassName='border-b border-gray text-[14px] font-light text-secondary hover:bg-blue-4/10'
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'bookId',
          order: SortingType.DESC,
        }}
      />
      <div className='mt-[30px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </>
  )
}
