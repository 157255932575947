export class CharacterDonationByCharacter {
  rank: number

  characterId: number

  characterName: string

  characterImgPath: string

  characterRole: string

  coinsToNextLevel: number

  characterNextRank: string

  bookId: number

  bookTitle: string

  username: string

  totalDonation: number
}

export class CharacterDonationByCharacterResponse {
  data: CharacterDonationByCharacter[]

  total: number

  page: number
}
