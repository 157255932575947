import cn from 'classnames'

import { Box } from '@components/Box'
import { WriterEnum } from '@interfaces/WriterEnum'
import { ResidenceEnum } from '@interfaces/ResidenceEnum'
import { TextArea } from '@components/TextArea'
import { OptionType } from '@interfaces/OptionType'
import { TextInput } from './TextInput'
import { ImageViewer } from './ImageViewer'
import { SelectButton } from './SelectButton'
import { useWriterDetail } from '../hooks/useWriterDetail'

export const residenceOptions = [
  { text: 'ประเทศไทย', value: ResidenceEnum.LOCAL },
  { text: 'ต่างประเทศ', value: ResidenceEnum.ABOARD },
]

export function Address({ countryOptions }: { countryOptions: OptionType[] }) {
  const { writer } = useWriterDetail()
  return (
    <div>
      <Box.Title>ที่อยู่ตามบัตรประชาชน</Box.Title>
      <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
        <SelectButton
          label='ประเทศ'
          options={residenceOptions}
          value={writer?.residence}
          className='font-mitr'
        />
        {writer?.residence === ResidenceEnum.LOCAL ? (
          <>
            <TextInput
              label='ที่อยู่'
              placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
              value={writer?.address}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer?.postcode}
            />
            <TextInput
              label='จังหวัด'
              placeholder='จังหวัด'
              value={writer?.province}
            />
            <TextInput
              label='เขต / อำเภอ'
              placeholder='เขต / อำเภอ'
              value={writer?.district}
            />
            <TextInput
              label='แขวง / ตำบล'
              placeholder='แขวง / ตำบล'
              value={writer?.subDistrict}
            />
            {writer?.type === WriterEnum.INDIVIDUAL && (
              <ImageViewer
                className='w-[200px] h-[126.7px]'
                label='รูปภาพบัตรประชาชน (ไฟล์ภาพ jpg หรือ png)'
                src={writer?.idCardImgPath}
                name={writer?.idCardImgPath}
              />
            )}
          </>
        ) : (
          <>
            <TextInput
              label='ประเทศ'
              placeholder='เลือกประเทศ'
              value={writer?.country || ''}
              options={countryOptions}
            />
            <TextInput
              label='รหัสไปรษณีย์'
              placeholder='รหัสไปรษณีย์'
              value={writer?.postcode || ''}
            />
            <div
              className={cn({
                'col-span-2': writer?.type === WriterEnum.INDIVIDUAL,
                'col-span-3': writer?.type === WriterEnum.LEGAL,
              })}
            >
              <label className='text-[12px] font-light mb-[6px] text-black-480'>
                ที่อยู่
              </label>
              <TextArea
                className='min-h-[120px]'
                placeholder='กรอกรายละเอียดที่อยู่ เช่น บ้านเลขที่, ตึก, ซอย'
                value={writer?.address}
                disabled
              />
            </div>
            {writer?.type === WriterEnum.INDIVIDUAL && (
              <ImageViewer
                className='w-[200px] h-[126.7px]'
                label='รูปภาพบัตรประชาชน (ไฟล์ภาพ jpg หรือ png)'
                src={writer?.idCardImgPath}
                name={writer?.idCardImgPath}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
