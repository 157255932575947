import { MoonIcon } from '@components/Icons'

export function LoadingModal() {
  return (
    <div className='w-[353px] h-[178px] bg-loading flex items-center justify-center text-white'>
      <MoonIcon
        className='mr-[10px] animate-spin-slow'
        width='40'
        height='40'
      />
      <span className='text-[24px] font-sarabun font-medium'>Loading...</span>
    </div>
  )
}
