import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { PreviewReadingPageProps } from './interface'

export function withPreviewReadingPage(
  Component: React.FC<PreviewReadingPageProps>
) {
  function WithPreviewReadingPage() {
    const client = useClient()
    const { id: bookId, chapterId } = useParams()
    const { isAuthenticated } = useAuthentication()

    const { data } = useQuery(
      ['story', bookId, 'chapter', chapterId],
      () => client?.chapterClient.bookChapterReader(Number(chapterId)),
      { enabled: isAuthenticated && !!chapterId }
    )

    const newProps = {
      data,
      bookId: Number(bookId),
      chapterId: Number(chapterId),
    }
    return <Component {...newProps} />
  }
  return WithPreviewReadingPage
}
