import React from 'react'
import cn from 'classnames'
import { Container } from '@components/Container'
import { Box } from '@components/Box'
import { Button } from '@components/Button'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { AgeConfirmStatus } from '@features/user/components/AgeConfirmStatus'
import { ImageViewer } from '@features/user/pages/WriterSubmitDetailPage/components/ImageViewer'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { DateTime } from 'luxon'
import { AgeConfirmationDetailPageProps } from './interface'
import { UserInformation } from './components/UserInformation'

export function AgeConfirmationDetailPage({
  data,
  handleReject,
  handleApprove,
}: AgeConfirmationDetailPageProps) {
  const permission = usePermissionAction('user')
  return (
    <Container>
      <Container.Title showBackBtn>
        <div className='flex items-center gap-x-[20px] '>
          <span className=' line-clamp-1 break-word'>@{data.username}</span>
          <AgeConfirmStatus status={data.ageVerify} />
        </div>
      </Container.Title>
      <UserInformation
        avatarImgPath={data.avatarImgPath}
        userId={data.id}
        fullName={data.fullName}
        username={data.username}
        email={data.email}
        phoneNumber={data.phoneNumber}
        birthDay={data.birthDay}
      />
      <Box className='shadow border border-blue-2 mt-[30px]'>
        <div className='font-mitr font-medium text-[24px] text-secondary mb-[20px]'>
          รูปภาพบัตรประชาชน
        </div>

        {data.ageVerify === AgeVerifyEnum.PENDING ? (
          <ImageViewer
            className='w-[470px]'
            src={data.idCardImgPath.url}
            name={data.idCardImgPath.name}
          />
        ) : (
          <>
            <div className='flex flex-col w-[470px] h-[300px] mb-[20px] border-custom items-center justify-center'>
              <img
                src='/images/id-card.png'
                alt=''
                className='w-[103px] h-[58px]'
              />

              {data.ageVerify === AgeVerifyEnum.APPROVED ? (
                <span className='mt-[5px] font-mitr font-medium text-[18px] text-primary'>
                  ยืนยันแล้ว
                </span>
              ) : (
                <span className='mt-[5px] font-mitr font-medium text-[18px] text-red'>
                  Rejected
                </span>
              )}
            </div>
            {data.ageVerify === AgeVerifyEnum.REJECTED && (
              <>
                <div className='text-[14px] font-light mb-[20px]'>
                  <span className='text-secondary-100 '>
                    เหตุผลที่ถูก Reject : &nbsp;
                  </span>
                  <span className='text-secondary'>
                    {data.ageVerifyRejectedReason || '-'}
                  </span>
                </div>
                <div className='text-[14px]'>
                  <span className='text-secondary-100 font-light'>
                    Rejected at : &nbsp;
                  </span>
                  <span className='font-light text-secondary'>
                    {data.ageVerifyRejectedAt
                      ? DateTime.fromISO(data.ageVerifyRejectedAt).toFormat(
                          'dd LLL yyyy HH:mm:ss',
                          {
                            locale: 'th-Th',
                          }
                        )
                      : '-'}
                  </span>
                </div>
              </>
            )}
            {data.ageVerify === AgeVerifyEnum.APPROVED && (
              <div className='text-[14px]'>
                <span className='text-secondary-100 font-light'>
                  Approved at : &nbsp;
                </span>
                <span className='font-light text-secondary'>
                  {data.ageVerifyApprovedAt
                    ? DateTime.fromISO(data.ageVerifyApprovedAt).toFormat(
                        'dd LLL yyyy HH:mm:ss',
                        {
                          locale: 'th-Th',
                        }
                      )
                    : '-'}
                </span>
              </div>
            )}
          </>
        )}
      </Box>
      <div className='mt-[30px]'>
        {data.ageVerify === AgeVerifyEnum.PENDING ? (
          <div
            className={cn('flex justify-center gap-x-[20px] font-mitr', {
              hidden: !permission.edit,
            })}
          >
            <Button
              className='w-[100px] text-sm text-secondary-100 border-secondary-100 border-[1px]'
              type='button'
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button
              className='w-[100px] text-sm bg-primary text-white'
              type='button'
              onClick={handleApprove}
            >
              Approve
            </Button>
          </div>
        ) : (
          <div className='flex items-center text-[14px] justify-end gap-x-[10px] text-secondary-100 font-light'>
            {data.ageVerify === AgeVerifyEnum.APPROVED
              ? 'Approve by :'
              : 'Rejected by :'}
            {data.ageVerifyApprover?.avatarImgPath ? (
              <img
                className='w-[50px] h-[50px] object-cover rounded-full'
                src={data.ageVerifyApprover.avatarImgPath}
                alt='avatarImg'
              />
            ) : (
              <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
            )}
            <span className='font-bold text-secondary'>
              {data.ageVerifyApprover?.displayName || '-'}
            </span>
          </div>
        )}
      </div>
    </Container>
  )
}
