import { ColumnType } from '@components/Table/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { abbreviateNumber } from '@libs/utils'
import { CharacterDonationByStory } from '@models/character/CharacterDonationByStory'
import { CharacterDonationByStorySortEnum } from '@interfaces/character/CharacterDonationByStorySortEnum'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<CharacterDonationByStory>[] = [
  {
    column: 'อันดับ',
    accessor: 'rank',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.RANK,
  },
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img className='w-[29px] h-[41px]' alt='cover' src={value} />
      ) : (
        <div className='w-[29px] h-[41px] bg-gray' />
      ),
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => (
      <p className='max-w-[200px] line-clamp-1 brack-all'>{value}</p>
    ),
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.TITLE,
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => (
      <p>{value === BookEnum.NOVEL ? 'นิยายรายตอน' : 'การ์ตูน'}</p>
    ),
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.BOOK_TYPE,
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    cell: ({ value }) => (
      <p className='max-w-[120px] line-clamp-1 brack-all'>{value}</p>
    ),
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.PEN_NAME,
  },

  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => <p>{(value as number).toLocaleString()}</p>,
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.CHAPTERS,
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.VIEW,
  },
  {
    column: 'Love',
    accessor: 'likeCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.LOVE,
  },
  {
    column: 'ยอดสนับสนุน',
    accessor: 'totalDonation',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationByStorySortEnum.DONATION,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell bookId={obj.bookId} />,
    cellClassName: 'flex justify-end',
    isAction: true,
  },
]
