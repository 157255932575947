import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { PromotionCodeForm } from '@features/promotionSetting/components/PromotionCodeForm'
import { DuplicatePromotionCodePageProps } from './interface'

export function DuplicatePromotionCodePage({
  initialValues,
  createPromotionCode,
  handleLeave,
  isLoading,
  coinOptions,
}: DuplicatePromotionCodePageProps) {
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : initialValues ? (
        <PromotionCodeForm
          initialValues={initialValues}
          handleSubmit={createPromotionCode}
          goBackHandle={handleLeave}
          title='สร้างโปรโมชั่น Code'
          coinOptions={coinOptions}
        />
      ) : (
        'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
      )}
    </Container>
  )
}
