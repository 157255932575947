import React from 'react'
import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import {
  PromotionCodeApproveSectionAcceptProps,
  PromotionCodeApproveSectionProps,
} from './interface'

export function withPromotionCodeApproveSection(
  Component: React.FC<PromotionCodeApproveSectionProps>
) {
  function Hoc({
    requestId,
    promotionId,
    promotionName,
    startPromotion,
    endPromotion,
    createdAt,
    promotionDetail,
    ...props
  }: PromotionCodeApproveSectionAcceptProps) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })
    const queryKey: QueryKey = ['promotion-code-detail', promotionId]

    function handleGoToFormDetail() {
      navigate(`/promotion-setting/promotion-code/${promotionId}/form-detail`)
    }

    const { mutateAsync: approvePromotionCode, isLoading } = useMutation(
      (promotionRequestId: number) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status: PromotionStatusEnum.APPROVED,
        }),
      {
        onSuccess: () => {
          alert.success(`ดำเนินการสำเร็จ`)
          queryClient.invalidateQueries(queryKey)
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
        },
      }
    )

    function handleApprove() {
      if (requestId) {
        approvePromotionCode(requestId)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId,
        promotionId,
        promotionName,
        startPromotion,
        endPromotion,
        createdAt,
        promotionDetail,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
        },
      })
    }
    const newProps = {
      ...props,
      handleApprove,
      handleReject,
      handleGoToFormDetail,
      approvedLoading: isLoading,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
