import { Expose, Transform } from 'class-transformer'
import { CoinStatusEnum } from '@interfaces/CoinStatusEnum'
import { CoinUnitEnum } from '@interfaces/CoinUnitEnum'
import type { ImageType } from '@interfaces/ImageType'

export class CoinType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  @Transform(({ value }) => ({
    url: value,
  }))
  imgPath: ImageType

  @Transform(({ value }) => value ?? 1)
  amountCoin: number

  @Transform(({ value }) => value ?? 1)
  amountCoinPerValue: number

  @Expose()
  valueUnitType: CoinUnitEnum

  @Expose({ name: 'valueUnitCoin' })
  @Transform(({ value }) => value?.id ?? null)
  valueUnitCoinId: number | null

  @Transform(({ value }) => value ?? 0)
  expireWithinMonthsByActivity: number

  @Expose()
  @Transform(({ value }) => value ?? false)
  isExpireByActivity: boolean

  @Transform(({ value }) => value ?? 0)
  expireWithinMonthsByPaid: number

  @Expose()
  @Transform(({ value }) => value ?? false)
  isExpireByPaid: boolean

  @Expose()
  visible: boolean

  @Expose()
  editable: boolean

  @Expose()
  status: CoinStatusEnum
}
