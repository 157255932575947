import { gql } from 'graphql-request'

export const GET_TOP_HASHTAG_CLICK = gql`
  query FindTagClick(
    $dateRange: DatePicker
    $limitPerPage: Int
    $page: Int
    $orderBy: UserTagClickOrderBy
  ) {
    findTagClick(
      dateRange: $dateRange
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
    ) {
      data {
        tagName
        totalTagClick
      }
      page
    }
  }
`
