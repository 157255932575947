import { PlusCircleIcon, MinusCircleIcon } from '@components/Icons'
import { BookEnum } from '@interfaces/BookEnum'
import { BookItemType } from './interface'

export function BookItem({
  isRemove = false,
  id,
  bookTitle,
  writer,
  penName,
  publisher,
  coverImgPath,
  bookType,
  actionHandler,
}: BookItemType) {
  return (
    <div className='flex items-center gap-x-[10px] px-[15px] py-[10px] border-b'>
      {coverImgPath ? (
        <img
          className='min-w-[33px] max-w-[33px] h-[50px] rounded-[4px] object-cover'
          src={coverImgPath}
          alt='Book cover'
        />
      ) : (
        <div className='min-w-[33px] max-w-[33px] h-[50px] bg-gray rounded-[4px]' />
      )}

      <div className='flex flex-col gap-y-[5px] w-full'>
        <p className='font-mitr font-medium text-secondary text-xs line-clamp-1 break-word'>
          {bookTitle}
        </p>

        <div className='grid grid-cols-2'>
          {bookType === BookEnum.MANGA && publisher && (
            <>
              <p className='text-secondary-100 text-xs font-light truncate'>
                สำนักพิมพ์ : {publisher}
              </p>

              {penName && (
                <p className='text-secondary-100 text-xs font-light truncate break-word'>
                  นามปากกา : {penName}
                </p>
              )}
            </>
          )}

          {((bookType === BookEnum.MANGA && !publisher) ||
            bookType === BookEnum.NOVEL) && (
            <>
              {writer && (
                <p className='text-secondary-100 text-xs font-light truncate min-w-full mr-2'>
                  ผู้เขียน : {writer}
                </p>
              )}

              {penName && (
                <p className='text-secondary-100 text-xs font-light truncate'>
                  นามปากกา : {penName}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <button type='button' onClick={() => actionHandler(id)}>
        {isRemove ? (
          <MinusCircleIcon className='text-red' width='18' height='18' />
        ) : (
          <PlusCircleIcon className='text-primary' width='18' height='18' />
        )}
      </button>
    </div>
  )
}
