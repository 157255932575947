import cx from 'classnames'

import { AvatarProps } from './interfaces/Avatar'

export function Avatar({ src, className, alt }: AvatarProps) {
  return (
    <div className={cx('rounded-full overflow-hidden bg-gray', className)}>
      <img className='object-cover w-full h-full' src={src} alt={alt} />
    </div>
  )
}
