import { gql } from 'graphql-request'

export const GET_TOP_COIN_SPENDER = gql`
  query GetTopCoinSpender(
    $orderBy: GetUserTopCoinSpendOrderBy
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getTopCoinSpender(
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
    ) {
      userCoinSpend
      userId
      username
    }
  }
`
