import { ColumnType } from '@components/Table/interface'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { UserManageMentSearchEnum } from '@interfaces/UserManageMentSearchEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import {
  UserStopSapPaymentResponse,
  UserStopSapPaymentType,
} from '@models/salesByUser/UserStopSapPaymentResponse'
import { QueryObserverResult } from 'react-query'
import { ActionCell } from '../ActionCell'

export function UserStopSapPaymentColumns(
  activeTab: UserManageMentSearchEnum,
  refetchUserStopSapPayment: () => Promise<
    QueryObserverResult<UserStopSapPaymentResponse | undefined, unknown>
  >
) {
  const columns: ColumnType<UserStopSapPaymentType>[] = [
    {
      column: 'User ID',
      accessor: 'id',
      cell: ({ value }) => <p>{value}</p>,
      isSortable: true,
      cellClassName: 'max-w-[220px] w-[220px] pr-[20px]',
      sortKey: 'userId',
    },
    {
      column: 'Username',
      accessor: 'username',
      cell: ({ value }) => (
        <p className='line-clamp-1 break-word font-bold'>@{value}</p>
      ),
      isSortable: true,
      cellClassName: 'py-[8px] max-w-[250px] w-[250px] pr-[10px]',
    },
    {
      column: 'Writer ID',
      accessor: 'writer.id',
      cell: ({ value, obj }) => {
        return obj.writer?.status === WriterStatusEnum.APPROVED ? (
          <p className='line-clamp-1 break-word'>{value}</p>
        ) : (
          <p>-</p>
        )
      },
      isSortable: true,
      cellClassName: 'py-[8px] max-w-[250px] w-[250px] pr-[10px]',
      sortKey: 'writerId',
    },
    {
      column: 'Writer Name',
      accessor: 'writer.affiliation',
      cell: ({ value, obj }) => {
        return value === AffiliationEnum.INDIVIDUAL ? (
          <p className='line-clamp-1 break-word'>{obj.displayName}</p>
        ) : (
          <p>-</p>
        )
      },
      isSortable: true,
      cellClassName: 'py-[8px] max-w-[200px] w-[200px] pr-[10px]',
      sortKey: 'writerFullName',
    },
    {
      column: 'Action',
      actionCell: ({ obj }) => (
        <ActionCell
          id={obj.id}
          activeTab={activeTab}
          refetchUserStopSapPayment={refetchUserStopSapPayment}
        />
      ),
      isAction: true,
      cellClassName: 'flex py-[8px] w-full pr-[10px] justify-end',
    },
  ]
  return columns
}
