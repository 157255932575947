import { Expose, Type } from 'class-transformer'
import { UserManagementList } from './UserManagementList'

export class UserManagementResponse {
  @Expose()
  @Type(() => UserManagementList)
  data: UserManagementList[]

  @Expose() total: number
}
