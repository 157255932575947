import { AdminLayout } from '@components/layouts/AdminLayout'
import { Outlet } from 'react-router-dom'
import { AdminUserManagement } from './pages/AdminUserManagement'
import { AddAdminPage } from './pages/AddAdminPage'
import { AddNewRolePage } from './pages/AddNewRolePage'
import { EditRolePage } from './pages/EditRolePage'
import { PendingInvitationPage } from './pages/PendingInvitationPage'
import { RolePermissionPage } from './pages/RolePermissionPage'

export const adminRoutes = [
  {
    path: 'admin',
    element: <Outlet />,
    children: [
      {
        path: 'admin-user-management',
        element: (
          <AdminLayout>
            <AdminUserManagement />
          </AdminLayout>
        ),
      },
      {
        path: 'add-admin',
        element: <AddAdminPage />,
      },
      {
        path: 'add-role',
        element: <AddNewRolePage />,
      },
      {
        path: 'role/:id',
        element: <EditRolePage />,
      },
      {
        path: 'role-permission-setting',
        element: (
          <AdminLayout>
            <RolePermissionPage />
          </AdminLayout>
        ),
      },
      {
        path: 'pending-invitation',
        element: (
          <AdminLayout>
            <PendingInvitationPage />
          </AdminLayout>
        ),
      },
    ],
  },
]
