import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_COVER = gql`
  query GetSearchResultByCover(
    $dateRange: DatePicker
    $interval: SearchPastInterval
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByCoverSort
    $orderDirection: OrderDirection
  ) {
    searchResultByCover(
      dateRange: $dateRange
      interval: $interval
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        bookId
        title
        writer
        penName
        totalSearch
        cover
      }
      page
    }
  }
`
