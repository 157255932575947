import { Formik, Form } from 'formik'

import { Button } from '@components/Button'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { InputField } from '@components/forms/InputField'
import { SelectField } from '@components/forms/SelectField'
import { SearchIcon } from '@components/Icons'
import { ExportButton } from '@components/ExportButton'
import {
  PopupBannerStatusEnum,
  popupBannerStatusValue,
} from '@interfaces/PopupBannerStatusEnum'
import { PopupFormProps } from './interface'

export function PopupForm({
  initialValues,
  handleSubmit,
  handleExport,
  resetHandler,
}: PopupFormProps) {
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, dirty, resetForm }) => (
          <Form>
            <div className='flex flex-col gap-y-[20px] mb-[30px]'>
              <div className='grid grid-cols-1'>
                <InputField
                  name='searchText'
                  placeholder='ค้นหาด้วยชื่อแคมเปญ หรือ ID'
                  leftIcon={<SearchIcon className='text-primary' />}
                  inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
                />
              </div>
              <div className='flex justify-between items-end '>
                <div className='flex space-x-[10px]'>
                  <SelectField
                    className='w-[110px]'
                    name='status'
                    options={[
                      {
                        label:
                          popupBannerStatusValue[PopupBannerStatusEnum.ALL],
                        value: PopupBannerStatusEnum.ALL,
                      },
                      {
                        label:
                          popupBannerStatusValue[PopupBannerStatusEnum.ACTIVE],
                        value: PopupBannerStatusEnum.ACTIVE,
                      },
                      {
                        label:
                          popupBannerStatusValue[
                            PopupBannerStatusEnum.INACTIVE
                          ],
                        value: PopupBannerStatusEnum.INACTIVE,
                      },
                    ]}
                  />
                  <DateTimeInputField
                    className='w-[180px]'
                    name='startAt'
                    placeholder='ตั้งแต่วันที่'
                    maxDate={values.endAt || new Date()}
                  />
                  <DateTimeInputField
                    className='w-[180px] '
                    name='endAt'
                    placeholder='ถึงวันที่'
                    minDate={values.startAt}
                    maxDate={new Date()}
                  />
                </div>
                <div className='flex font-mitr justify-end ml-[25px] gap-x-[20px]'>
                  <Button
                    type='reset'
                    className='text-secondary underline'
                    onClick={() => {
                      resetForm()
                      resetHandler()
                    }}
                  >
                    <span className='text-[14px]'>ล้างค่า</span>
                  </Button>
                  <Button
                    type='submit'
                    className='bg-secondary text-white '
                    disabled={
                      isSubmitting ||
                      !dirty ||
                      (!!values.startAt && !values.endAt) ||
                      (!values.startAt && !!values.endAt)
                    }
                  >
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                  <ExportButton handleClick={handleExport} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
