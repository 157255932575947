import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { SortByType, SortingType } from '@components/Table/interface'
import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { BoughtEbookTableAcceptProps, BoughtEbookTableProps } from './interface'

export function withBoughtEbookTable(
  Component: React.FC<BoughtEbookTableProps>
) {
  function WithBoughtEbookTable({
    ebookId,
    searchParams,
  }: BoughtEbookTableAcceptProps) {
    const client = useClient()
    const { isAuthenticated } = useAuthentication()
    const [sortParams, setSortParams] = useState<SortByType>({
      key: 'price',
      order: SortingType.DESC,
    })

    const { fetchNextPage, data, isLoading, isFetchingNextPage } =
      useInfiniteQuery(
        ['bought-ebook-detail', ebookId, sortParams],
        ({ pageParam = 1 }) =>
          client!.ebookClient.getBoughtEbookDetail({
            ebookId: Number(ebookId),
            startDate: searchParams.startDate,
            endDate: searchParams.endDate,
            page: pageParam,
            ...sortParams,
          }),
        {
          enabled: !!ebookId && isAuthenticated,
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) {
              return lastPage.page + 1
            }

            return undefined
          },
        }
      )

    function handleSort({ key, order }: SortByType) {
      setSortParams(prev => ({ ...prev, key, order }))
    }

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const newProps = {
      data,
      isLoading,
      sortParams,
      handleSort,
      handleFetchNextPage,
    }

    return <Component {...newProps} />
  }

  return WithBoughtEbookTable
}
