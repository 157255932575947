/* eslint-disable max-classes-per-file */
import { Expose } from 'class-transformer'

class UserType {
  avatarImgPath: string
}

export class UserPenNameResponse {
  @Expose() id: number

  @Expose() firstPenName: string

  @Expose() user: UserType
}
