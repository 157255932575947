import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinType } from '@models/coin/CoinType'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { CoinPageProps } from './interface'

export function withCoinPage(Component: React.FC<CoinPageProps>) {
  function WithCoinPage() {
    const navigate = useNavigate()
    const client = useClient()
    const confirmModal = useModal({ modal: 'confirm' })
    const alert = useAlert()

    const { data, refetch, isFetching } = useQuery('coin', () =>
      client?.coinClient.getCoins()
    )

    const {
      data: coinRequest,
      isFetching: isCoinRequestFetching,
      refetch: refetchCoinRequest,
    } = useQuery('coin-request', () =>
      client?.coinClient.getCoinRequests({
        limitPerPage: 10,
        page: 1,
      })
    )

    async function createCoinRequest(value: CoinType[]) {
      try {
        await client?.coinClient.createCoinRequest(value)
        await refetchCoinRequest()
        confirmModal.hide()
        alert.success('บันทึก และส่งแบบร่างสำเร็จ')
      } catch (error: any) {
        if (
          error.response.errors.length &&
          error.response.errors[0].errorCode === 4009
        ) {
          alert.error(
            `${error.response.errors[0].extras.coinName} ไม่สามารถปิดเผยแพร่ได้ เนื่องจากยังมีเหรียญคงเหลือในระบบ`
          )
        } else {
          alert.error(getErrorMessage(error))
        }
      }
    }

    async function handleSubmit(value: CoinType[]) {
      await createCoinRequest(value)
    }

    function handleApprove(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              คุณแน่ใจหรือไม่ว่า อนุมัติคำขอ
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.coinClient.updateCoinRequestStatus({
              id,
              status: CoinRequestStatusEnum.APPROVED,
            })
            alert.success('อนุมัติคำขอสำเร็จ')
            refetch()
            refetchCoinRequest()
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
          }
        },
        onClose: () => confirmModal.hide(),
      })
    }

    function handleReject(id: number) {
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              คุณแน่ใจหรือไม่ว่า ต้องการปฏิเสธคำขอ หากกดปฏิเสธแล้ว
              การปรับแต่งค่าทั้งหมดจะคืนกลับสู่ค่าเดิมก่อนหน้านี้
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.coinClient.updateCoinRequestStatus({
              id,
              status: CoinRequestStatusEnum.REJECTED,
            })
            alert.success('ปฏิเสธคำขอสำเร็จ')
            refetch()
            refetchCoinRequest()
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
          }
        },
        onClose: () => confirmModal.hide(),
      })
    }

    if (isFetching || isCoinRequestFetching) {
      return null
    }

    const newProps = {
      coins: data?.data ?? [],
      hasCoinRequests: coinRequest ? coinRequest?.data?.length > 0 : false,
      coinRequest:
        coinRequest?.data?.find(
          item => item.status === CoinRequestStatusEnum.PENDING
        ) || null,
      navigate,
      handleSubmit,
      handleApprove,
      handleReject,
    }
    return <Component {...newProps} />
  }

  return WithCoinPage
}
