import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useInfiniteQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useDebounce } from '@hooks/useDebounce'
import { ByTitleSearchFormType } from '@models/dashboard/ByTitleSearchFormType'
import { useOutside } from '@hooks/useOutside'
import { BookOptionType } from '@models/dashboard/BookOptionResponse'
import { SearchBookFieldAcceptProps, SearchBookFieldProps } from './interface'

export function withSearchBookField(Component: React.FC<SearchBookFieldProps>) {
  function WithSearchBookField({
    name,
    setText,
    text,
    resetHandler,
    ...props
  }: SearchBookFieldAcceptProps) {
    const client = useClient()
    const searchRef = useRef<HTMLDivElement>(null)
    const [isShowSearchLog, setIsShowSearchLog] = useState<boolean>(false)
    const [textSearch, setTextSearch] = useState('')
    const { setFieldValue, submitForm } =
      useFormikContext<ByTitleSearchFormType>()
    const [field, , { setValue }] = useField<number | undefined>(name)
    const { debounce } = useDebounce()

    const { data, isLoading, isFetchingNextPage, fetchNextPage } =
      useInfiniteQuery(
        ['search-book', 'page', textSearch],
        ({ pageParam = 1 }) =>
          client?.dashboardClient.getBookOption(textSearch, pageParam),
        {
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function showSearchLog() {
      setIsShowSearchLog(true)
    }

    function hideSearchLog() {
      setIsShowSearchLog(false)
    }

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
      setText(event.target.value)
    }

    function handleSelectBook(book: BookOptionType) {
      setValue(book.id)
      setText(book.title)
      hideSearchLog()
      submitForm()
    }

    function removeTextSearch() {
      if (resetHandler) {
        resetHandler()
      }
      setValue(undefined)
      setText('')
    }

    const books = useMemo(
      () => data?.pages.flatMap(page => page!.data) || [],
      [data]
    )

    useOutside(searchRef, () => {
      const prevText = books.find(book => book.id === field.value)?.title || ''
      hideSearchLog()
      setText(prevText)
    })

    useEffect(() => {
      debounce(() => {
        setTextSearch(text)
      }, 400)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const componentsProps = {
      ...props,
      text,
      isShowSearchLog,
      books,
      searchRef,
      handleSelectBook,
      handleTextChange,
      handleFetchNextPage,
      showSearchLog,
      removeTextSearch,
    }
    return <Component {...componentsProps} />
  }

  return WithSearchBookField
}
