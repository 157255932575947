import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  coins: Yup.array().of(
    Yup.object().shape({
      amountCoin: Yup.number()
        .typeError('ใส่มูลค่าขั้นต่ำ 1')
        .min(1, 'ใส่มูลค่าขั้นต่ำ 1')
        .required(),
      amountCoinPerValue: Yup.number()
        .typeError('ใส่มูลค่าขั้นต่ำ 1')
        .min(1, 'ใส่มูลค่าขั้นต่ำ 1')
        .required(),
      expireWithinMonthsByPaid: Yup.number().when('isExpireByPaid', {
        is: true,
        then: schema => schema.min(1, 'ใส่ขั้นต่ำ 1 เดือน').required(),
      }),
      expireWithinMonthsByActivity: Yup.number().when('isExpireByActivity', {
        is: true,
        then: schema => schema.min(1, 'ใส่ขั้นต่ำ 1 เดือน').required(),
      }),
    })
  ),
})
