import cn from 'classnames'
import { PromotionRadioInputFieldProps } from './interface'

export function PromotionRadioInputField({
  label,
  className,
  checked,
  children,
  ...props
}: PromotionRadioInputFieldProps) {
  return (
    <div>
      <label className={cn('cursor-pointer radio-custom ', className)}>
        <span
          className={cn('flex-1 text-[14px]', {
            'text-secondary': checked,
            'text-secondary-100': !checked,
          })}
        >
          {label}
        </span>
        <input type='radio' {...props} />
        <span
          className={cn('check-mark  after:!bg-primary', {
            '!border-primary !bg-primary check-dot': checked,
            'after:!bg-white': !checked,
            '!top-[30px]': children,
          })}
        />
      </label>
      {children}
    </div>
  )
}
