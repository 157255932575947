import cn from 'classnames'

export function NoData({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'text-[24px] text-secondary-50 flex justify-center font-mitr font-medium mt-[20px] mb-[180px]',
        className
      )}
    >
      ไม่พบข้อมูล
    </div>
  )
}
