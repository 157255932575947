import cn from 'classnames'

export function Typography({
  label,
  text,
  row = false,
  className,
}: {
  label: string
  text: React.ReactNode
  row?: boolean
  className?: string
}) {
  return (
    <div
      className={cn('flex gap-y-[4px]', className, {
        'flex-col': !row,
      })}
    >
      <span className='text-secondary-100 whitespace-nowrap'>{label}</span>
      {row && <span className='mx-[3px]'>:</span>}
      {text}
    </div>
  )
}
