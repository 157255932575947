import cn from 'classnames'

import {
  ChallengeStatus,
  ChallengeUnitValue,
} from '@interfaces/user-mission/ChallengeEnum'
import { CropSquareImage } from '@components/CropSquareImage'
import { InputField } from '@components/forms/InputField'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { Button } from '@components/Button'
import { EditIcon } from '@components/Icons'
import { Toggle } from '@components/Toggle'
import { addComma } from '@libs/utils'
import { UserMissionLevelProps } from './interface'

export function UserMissionLevel({
  status,
  index,
  imageUrl,
  criteria,
  unit,
  userMissionLevel,
  dirty,
  isValid,
  isSubmitting,
  showForm,
  isActive,
  isUsed,
  updateImage,
  handleToggleChallengeLevel,
  handleUpdateUserMissionLevel,
  handleCancel,
  handleEditIdChange,
}: UserMissionLevelProps) {
  if (showForm) {
    return (
      <div
        className={cn('flex items-center gap-x-[20px] py-[20px]', {
          'border-b border-gray': status === ChallengeStatus.UPDATE,
        })}
      >
        <div
          className={cn(
            'text-[24px] text-secondary-100 font-medium font-mitr',
            {
              'text-primary': status === ChallengeStatus.CREATE,
            }
          )}
        >
          {userMissionLevel.level}.
        </div>

        <CropSquareImage
          src={imageUrl}
          title='เพิ่มรูป Level'
          size={200}
          aspectRatio={1}
          onChange={updateImage}
          isCircle
        />

        <InputField
          name={`userMissionLevels.${index}.levelName`}
          label='ชื่อ Level'
          showErrorMsg={false}
        />

        <NumberWithCommaField
          name={`userMissionLevels.${index}.target`}
          label={criteria}
          showErrorMsg={false}
          disabled={userMissionLevel.level === 1 || isUsed}
          placeholder='0'
          maxLength={10}
          rightIcon={
            <div className='text-[14px] text-secondary-100 font-light'>
              {ChallengeUnitValue[unit]}ขึ้นไป
            </div>
          }
        />

        <div className='flex text-center justify-end gap-x-[20px] mt-5 min-w-[180px]'>
          <Button
            type='button'
            className='text-black-480 text-sm font-medium underline w-[40px]'
            onClick={handleCancel}
          >
            ยกเลิก
          </Button>

          <Button
            type='button'
            className='text-white text-sm font-medium bg-secondary w-[76px] h-[40px]'
            disabled={!dirty || !isValid || isSubmitting}
            onClick={handleUpdateUserMissionLevel}
          >
            บันทึก
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center gap-x-[20px] py-[20px] border-b border-gray'>
      <div className='text-[24px] text-secondary-100 font-medium font-mitr'>
        {userMissionLevel.level}.
      </div>

      <div
        className={cn(
          'w-[159px] aspect-square rounded-full shrink-0 overflow-hidden border border-gray',
          {
            'bg-blue-2': !userMissionLevel.upload.url,
          }
        )}
      >
        {userMissionLevel.upload.url ? (
          <img
            src={userMissionLevel.upload.url}
            alt='iconChallengeUrl'
            className='w-full h-full'
          />
        ) : (
          <div className='w-full h-full relative'>
            <img
              src='/images/crown.webp'
              className='grayscale absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'
              width='60'
              height='60'
              alt='Crown'
            />
          </div>
        )}
      </div>

      <div className='flex flex-col gap-y-[4px] w-full'>
        <span className='text-xs font-light text-secondary-100'>
          ชื่อ Level
        </span>
        <span className='text-sm font-light text-secondary'>
          {userMissionLevel.levelName}
        </span>
      </div>

      <div className='flex flex-col gap-y-[4px] w-full'>
        <p className='text-xs font-light text-secondary-100'>{criteria}</p>
        <p className='text-sm font-light text-secondary'>
          {addComma(userMissionLevel.target, 0)}
          <span className='text-secondary-100 ml-[10px]'>
            {ChallengeUnitValue[unit]}ขึ้นไป
          </span>
        </p>
      </div>

      <div className='flex items-center justify-end gap-x-[20px] min-w-[180px]'>
        <button
          type='button'
          className='flex items-center justify-center w-[30px] h-[30px] border border-blue-2 rounded-lg text-secondary-100'
          onClick={() => handleEditIdChange(userMissionLevel.id)}
        >
          <EditIcon width='20' height='20' />
        </button>

        <Toggle
          checked={isActive}
          onChange={() => handleToggleChallengeLevel(index)}
        />
      </div>
    </div>
  )
}
