import { Expose, Transform } from 'class-transformer'

export class ChallengeUser {
  @Expose() id: number

  @Expose({ name: 'user' })
  @Transform(({ obj }) => obj.user.id)
  userId: number

  @Expose()
  @Transform(({ obj }) => obj.user.displayName)
  username: string

  @Expose()
  @Transform(({ obj }) => obj.user.avatarImgPath)
  avatarImgPath: string

  @Expose() tableHeader: string

  @Expose() current: number

  @Expose({ name: 'challengeLevel' })
  @Transform(({ value }) => value.level)
  level: number
}
