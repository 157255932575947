import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PaymentReportEnum } from '@interfaces/PaymentReportTabEnum'
import { PaymentReportPageProps } from './interface'

export function withPaymentReportPage(
  Component: React.FC<PaymentReportPageProps>
) {
  function WithPaymentReportPage() {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const tab = params.get('tab') || PaymentReportEnum.OVERVIEW
    const [activeTab, setActiveTab] = useState<PaymentReportEnum>(
      PaymentReportEnum.OVERVIEW
    )

    useEffect(() => {
      setActiveTab(tab as PaymentReportEnum)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    function handleChangeTab(value: string) {
      setActiveTab(value as PaymentReportEnum)
      navigate({
        pathname: '',
        search: `?tab=${value}`,
      })
    }

    const componentProps = {
      handleChangeTab,
      activeTab,
    }

    return <Component {...componentProps} />
  }

  return WithPaymentReportPage
}
