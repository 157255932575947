import cn from 'classnames'
import { BinIcon, PenIcon } from '@components/Icons'
import { RoleType } from '@models/rolePermission/RoleType'
import { PermissionActionType } from '@interfaces/permission/PermissionActionType'
import { ColumnType } from '@components/Table/interface'

export function roleColumns(
  handleDelete: (id: string) => void,
  handleUpdate: (id: string) => void,
  permission: PermissionActionType
) {
  const columns: ColumnType<RoleType>[] = [
    {
      column: '',
      accessor: 'value',
      cell: () => <div />,
      isSortable: false,
    },
    {
      column: 'Role',
      accessor: 'label',
      cell: ({ value }) => <p>{value}</p>,
      isSortable: true,
      sortKey: 'name',
      cellClassName: 'max-w-[300px] w-[300px] pr-[20px]',
    },
    {
      column: 'Action',
      accessor: 'value',
      cell: ({ value }) => (
        <div className='flex gap-x-[10px] '>
          <button
            type='button'
            onClick={() => handleUpdate(value)}
            className={cn(
              'flex items-center justify-center bg-white text-black-970 border border-gray rounded-lg w-[30px] h-[30px]',
              {
                hidden: !permission.edit,
              }
            )}
          >
            <PenIcon width='20' height='20' className='text-secondary-100' />
          </button>
          <button
            type='button'
            onClick={() => handleDelete(value)}
            className={cn(
              'flex items-center justify-center bg-white text-black-970 border border-gray rounded-lg w-[30px] h-[30px]',
              {
                hidden: !permission.delete,
              }
            )}
          >
            <BinIcon width='20' height='20' className='text-secondary-100' />
          </button>
        </div>
      ),
      isSortable: false,
      cellClassName: cn('max-w-[300px] w-[300px] m-auto py-[14.5px] ', {
        hidden: !permission.edit && !permission.delete,
      }),
    },
    {
      column: '',
      accessor: 'value',
      cell: () => <div />,
      isSortable: false,
    },
  ]
  return { columns }
}
