import React, { useState } from 'react'
import cn from 'classnames'
import { usePortalDropdown } from '@hooks/useDropdown'
import Tooltip from '@reach/tooltip'
import { Editor } from '@tiptap/react'
import TiptapDropdownInput from './TiptapDropdownInput'

interface TiptapLinkInputProps {
  icon: React.ReactNode
  editor: Editor
  isActive?: boolean
  tooltipLabel?: string
}

export default function TiptapLinkInput({
  icon,
  editor,
  isActive,
  tooltipLabel,
}: TiptapLinkInputProps) {
  const [url, setUrl] = useState('')
  const { Dropdown, isShowDropdown, toggleDropdown } = usePortalDropdown()

  return (
    <div className='relative'>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[8px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            type='button'
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
              {
                'text-primary': isActive,
              }
            )}
            onClick={e => {
              toggleDropdown(e)
              setUrl(editor.getAttributes('link').href || '')
            }}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          type='button'
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px] cursor-pointer',
            {
              'text-primary': isActive,
            }
          )}
          onClick={e => {
            toggleDropdown(e)
            setUrl(editor.getAttributes('link').href || '')
          }}
        >
          {icon}
        </button>
      )}
      <Dropdown>
        <TiptapDropdownInput
          className={cn({ hidden: !isShowDropdown })}
          title='Link'
          onComfirm={() => {
            if (url) {
              editor.commands.setLink({
                href: url,
                target: '_blank',
              })
            } else {
              editor.commands.unsetLink()
            }
          }}
          value={url}
          onChange={setUrl}
          toggleDropdown={toggleDropdown}
        />
      </Dropdown>
    </div>
  )
}
