import { gql } from 'graphql-request'

export const CREATE_CHALLENGE_LEVEL = gql`
  mutation CreateChallengeLevel(
    $createChallengeLevelInput: CreateChallengeLevelInput!
  ) {
    createChallengeLevel(
      createChallengeLevelInput: $createChallengeLevelInput
    ) {
      id
      level
      levelName
      iconUrl
      target
      isActive
      isUsed
      userCount
    }
  }
`
