import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { UserStopSapPaymentColumns } from './column'
import { UserStopSapPaymentTableProps } from './interface'

export function UserStopSapPaymentTable({
  data,
  isLoading,
  activeTab,
  searchParams,
  handleSort,
  refetchUserStopSapPayment,
}: UserStopSapPaymentTableProps) {
  return (
    <Table
      data={data}
      columns={UserStopSapPaymentColumns(activeTab, refetchUserStopSapPayment)}
      onSort={handleSort}
      isLoading={isLoading}
      initialSort={{
        key: searchParams.sortKeyUserStopSapPayment,
        order: searchParams.sortOrderUserStopSapPayment,
      }}
    />
  )
}
