import { Pagination } from '@components/Pagination'
import { SearchForm } from './components/SearchForm'
import { SearchHistory } from './components/SearchHistory'
import { SearchHistoryPageProps } from './interface'

export function SearchHistoryPage({
  data,
  page,
  perpage,
  total,
  searchParams,
  isLoading,
  selectedId,
  handlePageChange,
  handleSort,
  handleSubmit,
  handleExport,
  handleSelectAll,
  handleSelect,
}: SearchHistoryPageProps) {
  return (
    <>
      <SearchForm
        isLoading={isLoading}
        initialValues={searchParams}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <SearchHistory
        data={data}
        total={total}
        isLoading={isLoading}
        selectedId={selectedId}
        handleSort={handleSort}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
      />
      <div className='mt-[30px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={handlePageChange}
        />
      </div>
    </>
  )
}
