import React from 'react'
import { useField } from 'formik'

import { ImageType } from '@interfaces/ImageType'
import { WithUploadImageFieldProps, UploadImageFieldProps } from './interface'

export function withUploadImageField(
  Component: React.FC<UploadImageFieldProps>
) {
  function WithUploadImageField({ name, ...props }: WithUploadImageFieldProps) {
    const [{ value }, , { setValue }] = useField<ImageType>(name)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files?.length) {
        setValue({
          url: URL.createObjectURL(files[0]),
          blob: files[0],
        })
      }
    }

    const componentProps = {
      src: value?.url,
      onChange: handleChange,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithUploadImageField
}
