import { gql } from 'graphql-request'

export const GET_SEARCH_EBOOK = gql`
  query GetEbooksForSearch(
    $searchText: String
    $page: Int
    $limitPerPage: Int
  ) {
    getEbooksForSearch(
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
    ) {
      page
      total
      data {
        id
        title
        coverImgPath
      }
    }
  }
`
