import { ReportPublishLogActionEnum } from '@interfaces/ReportPublishLogActionEnum'
import { Expose, Type } from 'class-transformer'
import { ReportType } from './ReportType'

class UserType {
  @Expose() id: number

  @Expose() fullName: string

  @Expose() displayName: string

  @Expose() phoneNumber: string

  @Expose() email: string

  @Expose() avatarImgPath?: string
}

export class ReportPublishLogType {
  @Expose() id: number

  @Expose() action: ReportPublishLogActionEnum

  @Expose()
  @Type(() => UserType)
  actionBy: UserType

  @Expose()
  @Type(() => ReportType)
  bookReport: ReportType

  @Expose()
  createdAt: string
}
