import cn from 'classnames'

import { InputFieldProps } from './interface'

function InputField({
  label,
  placeholder,
  className,
  inputClassName,
  disabled,
  pattern,
  error,
  touched,
  labelClassName,
  rightIcon,
  leftIcon,
  leftIconClassName,
  rightIconClassName,
  showErrorMsg,
  ...props
}: InputFieldProps) {
  return (
    <div className={cn('w-full', className)}>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 mb-[4px] font-light',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <div className='relative flex items-center w-full'>
        {leftIcon && (
          <div className={cn('absolute left-0 ml-[20px]', leftIconClassName)}>
            {leftIcon}
          </div>
        )}
        <input
          className={cn(
            'flex w-full h-[40px] leading-10 border-[1px] focus:outline-none text-[14px] text-secondary font-light rounded-[8px] p-[10px] disabled:bg-[#eeeeee] disabled:text-[#979797] placeholder:text-secondary-100 focus:border-primary focus:text-primary',
            {
              'border-[#eeeeee]': !(touched && error),
              'border-[#f7575f]': touched && error,
              'pr-[30px]': rightIcon,
              'pl-[50px]': leftIcon,
            },
            inputClassName
          )}
          autoComplete='off'
          placeholder={placeholder ?? label}
          disabled={disabled}
          pattern={pattern}
          {...props}
        />
        {rightIcon && (
          <div className={cn('absolute right-0 mr-[20px]', rightIconClassName)}>
            {rightIcon}
          </div>
        )}
      </div>
      {showErrorMsg && touched && error && (
        <div className='flex items-center mt-[10px]'>
          <div className='text-[12px] text-[#f7575f]'>{error}</div>
        </div>
      )}
    </div>
  )
}

InputField.defaultProps = {
  showErrorMsg: true,
}

export default InputField
