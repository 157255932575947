import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PaymentReportByUserSearchType } from '../ReportByUser/interface'

import { ByUserFormProps, WithByUserFormProps } from './interface'

export function withByUserForm(Component: React.FC<ByUserFormProps>) {
  function WithByUserForm({
    onResetForm,
    handleSearch,
    ...props
  }: WithByUserFormProps) {
    const [params] = useSearchParams()
    const [text, setText] = useState(params.get('searchText') || '')
    function resetHandler() {
      setText('')
      onResetForm()
    }

    function handleSubmit(val: PaymentReportByUserSearchType) {
      handleSearch(val, text)
    }

    const newProps = {
      text,
      setText,
      resetHandler,
      handleSubmit,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithByUserForm
}
