import { Expose, Transform } from 'class-transformer'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'

export class HighlightPosterBannerType {
  @Expose() id: number

  @Expose() runningNo: number

  @Expose() imgPath: string

  @Expose() description: string

  @Expose() isOnWeb: boolean

  @Expose() isOnIos: boolean

  @Expose() isOnAndroid: boolean

  @Expose() rawLink: string

  @Expose() linkWeb: string

  @Expose() linkIos: string

  @Expose() linkAndroid: string

  @Expose() isActive: boolean

  @Expose() isPublishNow: boolean

  @Expose() isPublishTime: boolean

  @Expose()
  @Transform(({ value }) => new Date(value))
  publishedFrom: Date

  @Expose()
  @Transform(({ value }) => new Date(value))
  publishedTo: Date

  @Expose()
  @Transform(({ obj }) => {
    const currentDate = new Date().valueOf()
    const publishedFrom = new Date(obj.publishedFrom).valueOf()

    return currentDate >= publishedFrom
      ? PublishedEnum.IMMEDIATELY
      : PublishedEnum.CUSTOM
  })
  publishedType: PublishedEnum
}
