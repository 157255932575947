import cn from 'classnames'

import { EyeIcon, EyeOffIcon } from '@components/Icons'
import { PasswordFieldProps } from './interface'

function PasswordField({
  label,
  placeholder,
  className,
  inputClassName,
  disabled,
  pattern,
  error,
  touched,
  labelClassName,
  showErrorMsg,
  showPassword,
  handleChangeTypePassword,
  ...props
}: PasswordFieldProps) {
  return (
    <div className={cn('w-full', className)}>
      {label && (
        <div
          className={cn(
            'text-[12px] text-secondary-100 mb-[4px] font-light',
            labelClassName
          )}
        >
          {label}
        </div>
      )}
      <div className='relative flex items-center w-full'>
        <input
          className={cn(
            'flex w-full h-[40px] leading-10 border-[1px] focus:outline-none text-[14px] text-secondary font-light rounded-[8px] p-[10px] disabled:bg-[#eeeeee] disabled:text-[#979797] focus:border-primary focus:text-primary',
            {
              'border-[#eeeeee]': !(touched && error),
              'border-[#f7575f]': touched && error,
            },
            inputClassName
          )}
          autoComplete='off'
          placeholder={placeholder ?? label}
          disabled={disabled}
          pattern={pattern}
          {...props}
          type={!showPassword ? 'password' : 'text'}
        />
        <div
          className='absolute right-0 inset-y-0 mr-[10px] flex items-center text-primary cursor-pointer'
          onClick={handleChangeTypePassword}
        >
          {showPassword ? (
            <EyeOffIcon width='16' height='16' />
          ) : (
            <EyeIcon width='16' height='16' />
          )}
        </div>
      </div>
      {showErrorMsg && touched && error && (
        <div className='flex items-center mt-[10px]'>
          <div className='text-[12px] text-[#f7575f]'>{error}</div>
        </div>
      )}
    </div>
  )
}

export default PasswordField
