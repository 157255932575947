import { useEffect } from 'react'
import { useTab } from '@components/Tabs/Tabs'

interface TabHeaderProps {
  children: JSX.Element
  tabId?: string
  disabled?: boolean
}

export function TabHeader({ tabId, children, disabled }: TabHeaderProps) {
  const { handleAddMenu } = useTab()

  useEffect(
    () => handleAddMenu({ [tabId!]: { children, disabled } }),
    [disabled, tabId, children, handleAddMenu]
  )

  return null
}
