import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'

import { ChapterBookPreviewType } from '@models/chapterBook/ChapterBookPreviewType'
import { ChapterOptionResponse } from '@models/chapterBook/ChapterOptionResponse'
import { BookChapterType } from '@models/story/BookChapterType'
import { BOOK_CHAPTER_READER, GET_CHAPTER_LIST } from './schemas/bookChapter'
import { BOOK_CHAPTER_OPTIONS } from './schemas/bookChapterOption'

export class ChapterClient {
  constructor(private client: GraphQLClient) {}

  async bookChapterReader(id: number): Promise<ChapterBookPreviewType> {
    const { bookChapter } = await this.client.request(BOOK_CHAPTER_READER, {
      id,
    })

    return plainToInstance(ChapterBookPreviewType, bookChapter)
  }

  async bookChapterOptions(
    bookId: number,
    page: number,
    limitPerPage: number
  ): Promise<ChapterOptionResponse> {
    const { getBookChapters } = await this.client.request(
      BOOK_CHAPTER_OPTIONS,
      {
        bookId,
        page,
        limitPerPage,
      }
    )

    return plainToInstance(ChapterOptionResponse, getBookChapters)
  }

  async getChapters(bookId: number, page: number): Promise<BookChapterType[]> {
    const { getBookChapters } = await this.client.request(GET_CHAPTER_LIST, {
      bookId,
      limitPerPage: 50,
      page,
    })

    return plainToInstance(BookChapterType, getBookChapters.data as [])
  }
}
