import { gql } from 'graphql-request'

export const EXPORT_READER_DASHBOARD_BY_ID = gql`
  query ExportReaderDashboardById(
    $userId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $keyType: KeyType
  ) {
    exportReaderDashboardById(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      keyType: $keyType
    )
  }
`
