import { useMutation, useQueryClient } from 'react-query'
import React, { useState } from 'react'

import { useAuthentication } from '@hooks/useAuthentication'
import { useClient } from '@hooks/useClient'
import { SaleTargetSettingFormType } from '@models/dashboard/SaleTargetSettingFormType'
import { SaleReportType } from '@models/dashboard/SaleReportType'
import { useAlert } from '@hooks/useAlert'

import { OverAllReportProps, WithOverAllReportProps } from './interface'

const withOverAllReport = (Component: React.FC<OverAllReportProps>) => {
  function WithOverAllReport({ queryKey, ...props }: WithOverAllReportProps) {
    const client = useClient()
    const alert = useAlert()
    const { user } = useAuthentication()
    const queryClient = useQueryClient()
    const [isOpen, setOpen] = useState(false)

    function openSaleTargetSettingModal() {
      setOpen(true)
    }

    function closeSaleTargetSettingModal() {
      setOpen(false)
    }

    const { mutate: updateSaleTarget } = useMutation(
      ({ saleTarget }: SaleTargetSettingFormType) =>
        client!.dashboardClient.updateSaleTarget({
          userId: user.id,
          saleTarget,
        }),
      {
        onSuccess: res => {
          alert.success('อัปเดตเรียบร้อย')
          queryClient.setQueryData<SaleReportType | undefined>(
            queryKey,
            oldData => {
              if (oldData && res) {
                return {
                  ...oldData,
                  saleTarget: res,
                }
              }

              return oldData
            }
          )
          closeSaleTargetSettingModal()
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด')
        },
      }
    )

    async function handleSaleTargetChange(form: SaleTargetSettingFormType) {
      await updateSaleTarget(form)
    }

    const componentProps = {
      ...props,
      isOpen,
      openSaleTargetSettingModal,
      closeSaleTargetSettingModal,
      handleSaleTargetChange,
    }

    return <Component {...componentProps} />
  }

  return WithOverAllReport
}

export { withOverAllReport }
