import { gql } from 'graphql-request'

export const UPLOAD_PROMOTION_USERS = gql`
  mutation UploadPromotionUsers(
    $promotionType: PromotionType!
    $file: Upload!
  ) {
    uploadPromotionUsers(promotionType: $promotionType, file: $file) {
      userIds
      fileName
      filePath
    }
  }
`
