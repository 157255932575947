import React from 'react'
import { TextInputVerifyNumberProps } from './interface'

export function withTextInputVerifyNumber(
  Component: React.FC<TextInputVerifyNumberProps>
) {
  function WithTextInputVerifyNumber(props: TextInputVerifyNumberProps) {
    return <Component {...props} />
  }

  return WithTextInputVerifyNumber
}
