import cn from 'classnames'
import ReactECharts from 'echarts-for-react'

export type DataSourceType = {
  name: string
  value: number
  color?: string
}

interface VerticalBarChartProps {
  title: string
  subTitle?: React.ReactNode
  dataSource: DataSourceType[]
  style?: React.CSSProperties
  labelFormatter?: (value: number, index: number) => string
  grid?: { left?: number; right?: number; top?: number; bottom?: number }
  headerTitleClassName?: string
}

export function VerticalBarChart({
  title,
  subTitle,
  dataSource,
  style,
  labelFormatter,
  grid = { left: 30, right: 0, top: 10 },
  headerTitleClassName,
}: VerticalBarChartProps) {
  const option = {
    grid,
    dataset: {
      source: dataSource,
    },
    tooltip: {
      trigger: 'item',
      formatter: ({ seriesName, data }: any) => {
        return `${seriesName}<br /> ${
          data.name
        }: <strong>${data.value.toLocaleString()}</strong>`
      },
    },
    xAxis: {
      type: 'category',
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    yAxis: {
      axisLabel: {
        show: true,
        formatter: labelFormatter,
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    animationDuration: 300,
    animationDurationUpdate: 300,
    series: [
      {
        name: title,
        type: 'bar',
        itemStyle: {
          color: (params: any) => {
            return params.value.color || '#cecfd7'
          },
        },
        encode: {
          y: 'value',
          x: 'name',
        },
      },
    ],
    textStyle: {
      color: '#858baf',
    },
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <span
          className={cn(
            'font-mitr font-medium text-secondary mb-[20px]',
            headerTitleClassName
          )}
        >
          {title}
        </span>
        {!!subTitle && (
          <div className='text-[14px] text-secondary-100 font-light'>
            {subTitle}
          </div>
        )}
      </div>
      <ReactECharts option={option} style={style} notMerge lazyUpdate />
    </>
  )
}
