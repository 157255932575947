import cn from 'classnames'
import { Container } from '@components/Container'
import { TabSelect } from '@features/user/components/TabSelect'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { ExportButton } from '@components/ExportButton'
import { UserManagementTable } from './components/UserManagementTable'
import { SearchSection } from './components/SearchSection'
import { UserManagementPageProps } from './interface'

export function UserManagementPage({
  data,
  page,
  perpage,
  total,
  statusOptions,
  queryKey,
  searchParams,
  isLoading,
  pageChange,
  handleSubmitSearch,
  handleChangeStatus,
  handleReset,
  handleSort,
  exportEmail,
}: UserManagementPageProps) {
  const permission = usePermissionAction('userMission')
  return (
    <Container className='flex flex-col gap-y-[30px]'>
      <div className='flex font-mitr text-[24px] font-medium text-secondary justify-between'>
        <span>User Management</span>
        <ExportButton
          className={cn({
            hidden: !permission.download,
          })}
          handleClick={exportEmail}
        />
      </div>
      <TabSelect
        options={statusOptions}
        handleUpdateActiveTab={handleChangeStatus}
        activeTab={searchParams.userType}
      />
      <SearchSection
        defaultValue={searchParams.searchText}
        onSearch={handleSubmitSearch}
        handleReset={handleReset}
        placeholder='ค้นหาชื่อ, นามปากกา, อีเมล, ID'
      />
      <UserManagementTable
        data={data}
        page={page}
        perpage={perpage}
        total={total}
        queryKey={queryKey}
        searchParams={searchParams}
        isLoading={isLoading}
        pageChange={pageChange}
        handleSort={handleSort}
      />
    </Container>
  )
}
