import { gql } from 'graphql-request'

export const UPLOAD_PROMOTION_CODE = gql`
  mutation UploadPromotionCodes($promotionCodeId: Int!, $file: Upload!) {
    uploadPromotionCodes(promotionCodeId: $promotionCodeId, file: $file) {
      fileName
      filePath
      couponCount
      coupons
    }
  }
`
