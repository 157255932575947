import { gql } from 'graphql-request'

export const GET_PROMOTION_CODE_HISTORY_BY_ID = gql`
  query PromotionRequest($promotionRequestId: Float!) {
    promotionRequest(id: $promotionRequestId) {
      id
      status
      createdBy {
        id
        avatarImgPath
        displayName
      }
      approvedBy {
        id
        avatarImgPath
        displayName
      }
      rejectedReason
      action
      correctionDetail {
        name {
          old
          new
        }
        description {
          old
          new
        }
        userTerm {
          old
          new
        }
        channels {
          old
          new
        }
        usageDay {
          new
          old
        }
        usageLimit {
          new
          old
        }
        usageLimitPerUser {
          new
          old
        }
        publishPeriod {
          endPublishedAt {
            old
            new
          }
          startPublishedAt {
            old
            new
          }
        }
        userType {
          new
          old
        }
        isFirstTopUp {
          new
          old
        }
        codeCondition {
          type {
            new
            old
          }
          buyCoinCondition {
            new
            old
          }
          buyCoinConditionValue {
            new
            old
          }
          paymentChannels {
            new
            old
          }
        }
        codeBenefit {
          benefitValue {
            new
            old
          }
          coinsId {
            new
            old
          }
        }
      }
      createdAt
      updatedAt
      oldPromotion {
        generateCouponStatus
        status
      }
      newPromotion {
        id
        businessId
        name
        description
        userTerm
        startPublishedAt
        endPublishedAt
        publishedType
        type
        userType
        usageLimitPerUser
        isUsageLimitPerUser
        usageLimit
        isUsageLimit
        usageDay
        status
        users {
          id
        }
        usersFileName
        usersFilePath
        isFirstTopUp
        createdAt
        generateCouponStatus
        promotionCode {
          id
          type
          buyCoinConditionValue
          buyCoinCondition
          channels {
            name
            totalCount
          }
          paymentChannels
          benefitType
          benefitValue
          coin {
            id
            name
          }
          codesFileName
          codesFilePath
          couponType
        }
        lastRequest {
          id
        }
      }
    }
  }
`
