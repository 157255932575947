import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import {
  OrderByEnum,
  TopHashtagSortParam,
} from '@interfaces/dashboard/TopHashtagParam'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import {
  TopHashtagProps,
  TopHashtagType,
  WithTopHashtagProps,
} from './interface'

export function withTopHashtag(Component: React.FC<TopHashtagProps>) {
  function WithTopHashtag({ type, searchParam }: WithTopHashtagProps) {
    const client = useClient()
    const [topHashtagParam, setTopHashtagParam] = useState<TopHashtagSortParam>(
      {
        orderBy:
          type === TopHashtagType.USE
            ? OrderByEnum.USED_COUNT
            : OrderByEnum.TAG_COUNT,
        orderDirection: SortingType.DESC,
      }
    )

    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery(
        [`top-hashtag-${type}`, searchParam, topHashtagParam],
        async ({ pageParam = 1 }) => {
          const params = {
            orderBy: topHashtagParam.orderBy,
            orderDirection: topHashtagParam.orderDirection,
            page: pageParam,
            ...searchParam,
          }

          return type === TopHashtagType.USE
            ? client?.dashboardClient.topHashtagUsed(params)
            : client?.dashboardClient.topHashtagClick(params)
        },
        {
          getNextPageParam: (lastPage: any) => {
            if (lastPage.data.length) return lastPage.page + 1

            return undefined
          },
          staleTime: Infinity,
        }
      )

    function handleSortChange(sort: SortByType) {
      setTopHashtagParam({
        orderBy: sort.key as OrderByEnum,
        orderDirection: sort.order,
      })
    }

    function handleWaypointEnter() {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const componentProps = {
      data,
      isLoading,
      handleSortChange,
      handleWaypointEnter,
      type,
    }

    return <Component {...componentProps} />
  }

  return WithTopHashtag
}
