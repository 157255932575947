import { withPermissionGuard } from '@libs/permission'
import { UserManagementPage } from './UserManagementPage'
import { withUserManagementPage } from './withUserManagementPage'

const ConnectedUserManagementPage = withPermissionGuard(
  withUserManagementPage(UserManagementPage),
  'user',
  'view'
)
export { ConnectedUserManagementPage as UserManagementPage }
