import React from 'react'
import { PromotionSearchFormProps } from './interface'

export function withPromotionSearchForm(
  Component: React.FC<PromotionSearchFormProps>
) {
  function Hoc(props: PromotionSearchFormProps) {
    return <Component {...props} />
  }
  return Hoc
}
