import { gql } from 'graphql-request'

export const CREATE_PROMOTION_SALE = gql`
  mutation CreatePromotionSale(
    $createPromotionSaleInput: CreatePromotionSaleInput!
  ) {
    createPromotionSale(createPromotionSaleInput: $createPromotionSaleInput) {
      id
    }
  }
`
