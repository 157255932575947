import { gql } from 'graphql-request'

export const BOOK_CHAPTER_OPTIONS = gql`
  query GetBookChapters($page: Int, $limitPerPage: Int, $bookId: Int) {
    getBookChapters(page: $page, limitPerPage: $limitPerPage, bookId: $bookId) {
      page
      data {
        id
        chapterName
        chapterTitle
        status
      }
    }
  }
`
