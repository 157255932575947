import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_WRITER = gql`
  query GetSearchResultByWriter(
    $dateRange: DatePicker
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByWriterSort
    $orderDirection: OrderDirection
  ) {
    searchResultByWriter(
      dateRange: $dateRange
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        writerId
        userId
        displayName
        totalPenName
        totalBook
        totalSearch
      }
      page
    }
  }
`
