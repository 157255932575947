import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { DateTime } from 'luxon'

import { Box } from '@components/Box'
import { CheckboxField } from '@components/forms/CheckboxField'
import { CropImageField } from '@components/forms/CropImageField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { InputField } from '@components/forms/InputField'
import { RadioInputField } from '@components/forms/RadioInputField'
import { Button } from '@components/Button'
import { RadioInput } from '@components/RadioInput'
import { PopupBannerPublishedTypeEnum } from '@interfaces/PopupBannerPublishedTypeEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { PopupBannerDisplayEnum } from '@interfaces/PopupBannerDisplayEnum'
import { UserPlatformTypeEnum } from '@interfaces/UserPlatformTypeEnum'
import { checkCurrentDate } from '@libs/utils'
import { PopupBannerFormProps } from './interface'

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  display: yup.array().min(1).required(),
  userTypes: yup.array().min(1).required(),
  coverImage: yup.object().shape({
    url: yup.string().required(),
  }),
})

export function PopupBannerForm({
  initialValues,
  onSubmit,
}: PopupBannerFormProps) {
  const currentDate = new Date()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting, dirty, isValid, setValues, resetForm }) => (
        <Form>
          <Box className='grid grid-cols-[390px_1fr] gap-x-[30px] mb-[30px]'>
            <CropImageField
              className='w-full'
              name='coverImage'
              textSize='990 X 1320'
              textBtn='อัปโหลดรูปภาพ Pop up'
              width={390}
              height={520}
              title='เพิ่มภาพ pop-up'
            />
            <div>
              <div className='text-[14px] font-light mb-[26px]'>
                <span className='text-[12px] text-secondary-100'>
                  ID:&emsp;
                </span>
                {values.id || '-'}
              </div>
              <InputField
                className='mb-[30px]'
                name='title'
                label='Title'
                showErrorMsg={false}
              />
              <InputField className='mb-[30px]' name='link' label='Link' />
              <hr className='my-[30px] text-gray' />
              <div className='grid grid-cols-[70px_1fr] items-center gap-y-[20px]'>
                <div className='text-[12px] text-secondary-100 font-light'>
                  สำหรับ :&emsp;
                </div>
                <div className='flex space-x-[30px]'>
                  <CheckboxField
                    name='userTypes'
                    label='นักอ่าน'
                    value={UserPlatformTypeEnum.Reader}
                    checked={values.userTypes.includes(
                      UserPlatformTypeEnum.Reader
                    )}
                  />
                  <CheckboxField
                    name='userTypes'
                    label='นักเขียน'
                    value={UserPlatformTypeEnum.Writer}
                    checked={values.userTypes.includes(
                      UserPlatformTypeEnum.Writer
                    )}
                  />
                </div>
                <hr className='col-span-2 my-[10px]' />
                <div className='text-[12px] text-secondary-100 font-light'>
                  Display at :&emsp;
                </div>
                <div className='flex space-x-[30px]'>
                  <RadioInputField
                    name='displayAt'
                    label='หน้า home'
                    value={PopupBannerDisplayEnum.Home}
                    checked={values.displayAt === PopupBannerDisplayEnum.Home}
                  />
                  <RadioInputField
                    name='displayAt'
                    label='หน้าเหรียญ'
                    value={PopupBannerDisplayEnum.Coin}
                    checked={values.displayAt === PopupBannerDisplayEnum.Coin}
                  />
                </div>
                <hr className='col-span-2 my-[10px]' />
                <div className='text-[12px] text-secondary-100 font-light'>
                  Display :&emsp;
                </div>
                <div className='flex space-x-[30px]'>
                  <CheckboxField
                    name='display'
                    label='Web'
                    value={PlatformEnum.WEB}
                    checked={values.display.includes(PlatformEnum.WEB)}
                  />
                  <CheckboxField
                    name='display'
                    label='iOS'
                    value={PlatformEnum.IOS}
                    checked={values.display.includes(PlatformEnum.IOS)}
                  />
                  <CheckboxField
                    name='display'
                    label='Android'
                    value={PlatformEnum.ANDROID}
                    checked={values.display.includes(PlatformEnum.ANDROID)}
                  />
                </div>
                <hr className='col-span-2 my-[10px]' />
                <div className='text-[12px] text-secondary-100 font-light'>
                  เผยแพร่ :&emsp;
                </div>
                <div className='flex space-x-[30px]'>
                  <RadioInput
                    name='publishedType'
                    text='ทันที'
                    checked={
                      values.publishedType === PopupBannerPublishedTypeEnum.Now
                    }
                    onChange={() => {
                      setValues({
                        ...values,
                        publishedType: PopupBannerPublishedTypeEnum.Now,
                        startDate: DateTime.now().toJSDate(),
                        endDate: DateTime.now().plus({ month: 1 }).toJSDate(),
                      })
                    }}
                  />
                  <RadioInputField
                    name='publishedType'
                    label='ตั้งเวลาเผยแพร่'
                    value={PopupBannerPublishedTypeEnum.Period}
                    checked={
                      values.publishedType ===
                      PopupBannerPublishedTypeEnum.Period
                    }
                  />
                </div>
                <div />
                <div className='flex items-center space-x-[20px]'>
                  <DateTimeInputField
                    name='startDate'
                    disabled={
                      values.publishedType === PopupBannerPublishedTypeEnum.Now
                    }
                    showTimeSelect
                    minDate={currentDate}
                    maxDate={values.endDate}
                    minTime={
                      checkCurrentDate(values.startDate, currentDate)
                        ? currentDate
                        : undefined
                    }
                    maxTime={
                      checkCurrentDate(values.startDate, values.endDate)
                        ? values.endDate
                        : checkCurrentDate(values.startDate, currentDate)
                        ? new Date(new Date().setHours(23, 59, 59, 999))
                        : undefined
                    }
                  />
                  <span className='text-[14px] text-black-480 font-light'>
                    ถึง
                  </span>
                  <DateTimeInputField
                    name='endDate'
                    showTimeSelect
                    minDate={currentDate}
                    minTime={
                      checkCurrentDate(currentDate, values.endDate)
                        ? currentDate
                        : undefined
                    }
                    maxTime={
                      checkCurrentDate(currentDate, values.endDate)
                        ? new Date(new Date().setHours(23, 59, 59, 999))
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </Box>
          <div className='flex justify-center space-x-[20px]'>
            <Button
              className='text-secondary-100 border border-secondary-100 text-[14px] font-medium w-[100px]'
              onClick={() => resetForm()}
            >
              ยกเลิก
            </Button>
            <Button
              className='bg-primary text-white text-[14px] font-medium w-[100px]'
              type='submit'
              disabled={isSubmitting || !dirty || !isValid}
            >
              บันทึก
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
