import { Expose, Transform, Type } from 'class-transformer'
import { WriterEnum } from '@interfaces/WriterEnum'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { ResidenceEnum } from '@interfaces/ResidenceEnum'
import { CertificationTaxEnum } from '@interfaces/CertificationTaxEnum'
import { BankAccountType } from '@models/writer/BankAccountType'
import type { ImageType } from '@interfaces/ImageType'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { ApproverType } from './ApproverType'
import { WriterUserType } from './WriterUserType'
import { WritersConsentType } from './WritersConsentType'

export class WriterType {
  @Expose()
  id: number

  @Expose()
  type: WriterEnum

  @Expose()
  affiliation: AffiliationEnum

  @Expose()
  fullName: string

  @Expose()
  idNumber: string

  @Expose()
  email: string

  @Expose()
  phoneNumber: string

  @Expose()
  residence: ResidenceEnum

  @Expose()
  country: string

  @Expose()
  address: string

  @Expose()
  postcode: string

  @Expose()
  province: string

  @Expose()
  district: string

  @Expose()
  subDistrict: string

  @Expose()
  @Transform(
    ({ value }) => ({
      url: value,
      name: typeof value === 'string' ? value.split('/').pop() : '',
    }),
    {
      toClassOnly: true,
    }
  )
  idCardImgPath: ImageType

  @Expose()
  isSameMainAddress: boolean

  @Expose()
  sendResidence: ResidenceEnum

  @Expose()
  sendCountry: string

  @Expose()
  sendAddress: string

  @Expose()
  sendPostcode: string

  @Expose()
  sendProvince: string

  @Expose()
  sendDistrict: string

  @Expose()
  sendSubDistrict: string

  @Expose()
  certificationTax: CertificationTaxEnum

  @Expose()
  companyName: string

  @Expose()
  taxId: string

  @Expose()
  @Transform(
    ({ value }) => ({
      url: value,
      name: typeof value === 'string' ? value.split('/').pop() : '',
    }),
    {
      toClassOnly: true,
    }
  )
  companyCertificateImgPath: ImageType

  @Expose()
  @Transform(
    ({ value }) => ({
      url: value,
      name: typeof value === 'string' ? value.split('/').pop() : '',
    }),
    {
      toClassOnly: true,
    }
  )
  vatRegistrationCertificateImgPath: ImageType

  @Expose()
  coordinatorFullName: string

  @Expose()
  coordinatorEmail: string

  @Expose()
  coordinatorPhoneNumber: string

  @Expose()
  accountingFullName: string

  @Expose()
  accountingEmail: string

  @Expose()
  accountingPhoneNumber: string

  @Expose()
  certificateCountry: string

  @Expose()
  certificateAddress: string

  @Expose()
  certificatePostcode: string

  @Expose()
  certificateProvince: string

  @Expose()
  certificateDistrict: string

  @Expose()
  certificateSubDistrict: string

  @Expose()
  rejectedReason: string

  @Expose()
  approvedAt: string

  @Expose()
  rejectedAt: string

  @Type(() => WritersConsentType)
  writersConsents: WritersConsentType[]

  @Expose({ name: 'bankAccounts' })
  @Transform(
    ({ value }) => {
      if (value?.length > 0) {
        return {
          id: value[0].id,
          bankName: value[0].bankName,
          bankBranch: value[0].bankBranch,
          bankProvince: value[0].bankProvince,
          accountNumber: value[0].accountNumber,
          accountName: value[0].accountName,
          accountType: value[0].accountType,
          bankCoverImgPath: {
            name: value[0].bankCoverImgPath?.split('/').pop(),
            url: value[0].bankCoverImgPath,
          },
        }
      }

      return {}
    },
    { toClassOnly: true }
  )
  bankAccount: BankAccountType

  @Expose()
  status: WriterStatusEnum

  @Expose()
  @Type(() => WriterUserType)
  user: WriterUserType

  @Expose()
  @Type(() => ApproverType)
  approver: ApproverType
}
