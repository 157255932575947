import React from 'react'
import cn from 'classnames'

import { ChevronDownIcon, CrossIcon } from '@components/Icons'
import { useMutiSelectDropDown } from '@hooks/useDropdown'
import { Checkbox } from '@components/Checkbox'
import { PaymentMethodProps } from './interface'

export function PaymentMethod({
  className,
  optionClassName,
  options,
  label,
  value,
  placeholder,
  handleSelectAll,
  error,
  touched,
  disabled = false,
  ...props
}: PaymentMethodProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } =
    useMutiSelectDropDown()

  return (
    <div className={cn('text-secondary-100', className)}>
      {label && <div className='text-[12px] font-light mb-[4px]'>{label}</div>}

      <div
        className={cn(
          'flex gap-x-[10px] min-h-[40px] px-[10px] items-center py-[8px] border border-gray-border rounded-[8px] cursor-pointer',
          {
            'pointer-events-none': disabled,
            'border-[#f7575f]': error && touched,
          }
        )}
        onClick={toggleDropdown}
      >
        <div className='flex-1 flex text-secondary line-clamp-1  gap-[6px] text-[14px]'>
          {(value as string[]).length ? (
            (value as string[]).length === options.length ? (
              <span>ทุกช่องทาง</span>
            ) : (
              options.map(
                option =>
                  (value as string[])?.find(row => row === option.value) && (
                    <React.Fragment key={option.value}>
                      <span>{option.label}</span>
                      <span className=' mr-1 last:mr-0 last:hidden'>,</span>
                    </React.Fragment>
                  )
              )
            )
          ) : (
            <div className='text-[14px] font-light text-secondary-100'>
              {placeholder}
            </div>
          )}
        </div>
        <ChevronDownIcon className={cn('w-[16px] h-[16px] text-primary')} />
      </div>
      <div className='relative'>
        <div
          ref={dropdownRef}
          className={cn(
            'absolute shadow border border-gray w-full top-[5px] z-10 bg-white rounded py-[10px] max-h-[400px] overflow-y-auto',
            {
              hidden: !isShowDropdown,
            }
          )}
        >
          <div className='flex items-center text-[14px] px-[14px] py-[6px] font-light '>
            <Checkbox
              onClick={handleSelectAll}
              defaultChecked={(value as string[])?.length === options.length}
              label='ทุกช่องทาง'
            />
          </div>
          <hr className='text-gray pb-[8px] mt-[8px]' />
          <span className='text-[14px] px-[14px]'>Web</span>
          {options.map(option => (
            <div
              key={option.value}
              className='flex items-center text-[14px] px-[14px] py-[6px] font-light '
            >
              <Checkbox
                {...props}
                value={option.value}
                defaultChecked={
                  (value as string[])?.includes(option.value) ||
                  (value as string[])?.length === options.length
                }
                label={option.label}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
