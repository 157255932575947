import { gql } from 'graphql-request'

export const COIN_OPTIONS = gql`
  query Coins(
    $limitPerPage: Int
    $page: Int
    $orderBy: FindAllCoinOrderBy
    $where: FindAllCoinWhere
    $type: CoinType
  ) {
    coins(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      where: $where
      type: $type
    ) {
      data {
        id
        name
        imgPath
        expireWithinMonthsByActivity
        expireWithinMonthsByPaid
      }
    }
  }
`
