export enum SettingPromotionStatusEnum {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  STARTED = 'STARTED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  GENERATE_CODE = 'GENERATE_CODE',
  CODE_GENERATED = 'CODE_GENERATED',
  GENERATE_FAIL = 'GENERATE_FAIL',
}

export const SettingPromotionStatusTypeValue = {
  [SettingPromotionStatusEnum.ALL]: '-',
  [SettingPromotionStatusEnum.DRAFT]: 'แบบร่าง',
  [SettingPromotionStatusEnum.PENDING]: 'รอการอนุมัติ',
  [SettingPromotionStatusEnum.APPROVED]: 'อนุมัติแล้ว',
  [SettingPromotionStatusEnum.STARTED]: 'ดำเนินการแล้ว',
  [SettingPromotionStatusEnum.REJECTED]: 'Rejected',
  [SettingPromotionStatusEnum.CLOSED]: 'ปิดโปรโมชั่น',
  [SettingPromotionStatusEnum.GENERATE_CODE]: 'สร้างโค้ด',
  [SettingPromotionStatusEnum.CODE_GENERATED]: 'สร้างโค้ดแล้ว',
  [SettingPromotionStatusEnum.GENERATE_FAIL]: 'สร้างโค้ดไม่สำเร็จ',
}
