import { useState } from 'react'
import { useQuery } from 'react-query'

import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { BookEnum } from '@interfaces/BookEnum'
import { WriterSectionProps, WriterSectionState, SearchType } from './interface'

export function withWriterSection(Component: React.FC<WriterSectionProps>) {
  function WithWriterSection({ searchDateParams }: WriterSectionState) {
    const client = useClient()

    const [initialActiveTab, setInitialActiveTab] = useState<string>('10')
    const [searchParams, setSearchParams] = useState<SearchType>({
      ...searchDateParams,
      bookType: null,
      categoryId: null,
      sortKey: 'rank',
      sortOrder: SortingType.ASC,
      limit: 10,
    })

    const { data, isLoading } = useQuery(
      ['story-dashboard-income', searchParams, searchDateParams],
      () =>
        client?.dashboardClient.getStoryIncome({
          ...searchParams,
          startDate: searchDateParams.startDate,
          endDate: searchDateParams.endDate,
        })
    )

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    function handleChangeTab(value: number) {
      setInitialActiveTab(`${value}`)
      setSearchParams({ ...searchParams, limit: value })
    }

    function bookTypeChange(bookType: BookEnum | null) {
      setSearchParams(prev => ({ ...prev, bookType }))
    }

    function categoryChange(categoryId: number) {
      setSearchParams(prev => ({ ...prev, categoryId: Number(categoryId) }))
    }

    const componentProps = {
      data: data?.data || [],
      income: data?.income || 0,
      initialActiveTab,
      handleSort,
      handleChangeTab,
      bookTypeChange,
      categoryChange,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithWriterSection
}
