import { Box } from '@components/Box'
import { ReaderBadgeProps } from './interface'
import { UserMissionCard } from '../UserMissionCard'

function ReaderBadge({ readerBadge }: ReaderBadgeProps) {
  return (
    <div className='grid grid-cols-3 gap-x-[20px]'>
      {readerBadge.length === 0 && (
        <Box className='shadow border-blue-2 flex flex-col items-center gap-y-[10px] py-[20px] mt-[10px] mb-[30px]'>
          <div className='font-medium text-base font-mitr'>
            No Rank &amp; Badge
          </div>
        </Box>
      )}
      {readerBadge.map((row, index) => (
        <UserMissionCard key={index} data={row} />
      ))}
    </div>
  )
}

export default ReaderBadge
