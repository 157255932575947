import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'

import { client } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { ByUserSearchFormType } from '@models/dashboard/ByUserSearchFormType'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { getErrorMessage } from '@libs/utils'
import { ByUserPageProps, SearchQueryParam } from './interface'

export function withByUserPage(Component: React.FC<ByUserPageProps>) {
  function WithByUserPage() {
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useSearchParams()
    const queryWriterId = searchParams.get('writerId') || undefined
    const queryPenNameId = searchParams.get('penNameId') || undefined
    const queryUserId = searchParams.get('userId') || undefined

    const [penNameCount, setPenNameCount] = useState(0)
    const [queryParam, setQueryParam] = useState<SearchQueryParam>({
      startAt: DateTime.now()
        .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
      userId: queryUserId,
      writerId: queryWriterId,
      penNameId: queryPenNameId,
    })

    const { data: writerData, isLoading } = useQuery(
      ['write-by-user', queryParam],
      () =>
        client?.dashboardClient.getByUserReport({
          writerId: Number(queryParam.writerId),
          penNameId: Number(queryParam.penNameId),
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
        }),
      {
        enabled: !!queryParam.writerId,
      }
    )

    const { data: trafficByUser = [], isLoading: trafficLoading } = useQuery(
      ['traffic-by-user', queryParam],
      () =>
        client?.dashboardClient.getTrafficByUser({
          writerId: Number(queryParam.writerId),
          penNameId: Number(queryParam.penNameId),
          startDate: queryParam.startAt,
          endDate: queryParam.endAt,
        }),
      {
        enabled: !!queryParam.writerId,
      }
    )

    function penNameHandle(value: number) {
      setPenNameCount(value)
    }

    function onResetForm() {
      setSearchParams({ tab: 'byUser' })
      setQueryParam({
        startAt: DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day'),
        endAt: DateTime.now().endOf('day'),
        userId: undefined,
        writerId: undefined,
        penNameId: undefined,
      })
    }

    function newDateAt(
      startAt: Date | undefined,
      endAt: Date | undefined,
      timeRange: TimeRangeEnum
    ) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().minus({ day: 1 }).startOf('day')
        newEndAt = DateTime.now().minus({ day: 1 }).endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ days: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      return { newStartAt, newEndAt }
    }

    async function handleSearch({
      userId,
      writerId,
      penNameId,
      timeRange,
      startAt,
      endAt,
    }: ByUserSearchFormType) {
      const { newStartAt, newEndAt } = newDateAt(startAt, endAt, timeRange)

      if (newStartAt && newEndAt) {
        setQueryParam({
          startAt: newStartAt,
          endAt: newEndAt,
          penNameId,
          writerId,
          userId,
        })

        const newSearchParam: any = {
          tab: 'byUser',
        }

        if (writerId) newSearchParam.writerId = writerId
        if (userId) newSearchParam.userId = userId
        if (penNameId) newSearchParam.penNameId = penNameId

        setSearchParams(newSearchParam)
      }
    }

    async function handleExport() {
      try {
        const { startAt, endAt, writerId, userId, penNameId } = queryParam

        if (startAt && endAt && writerId && userId) {
          await client?.dashboardClient.exportWriterDashboardById({
            startDate: startAt,
            endDate: endAt,
            userId: Number(userId),
            writerId: Number(writerId),
            penNameId: penNameId ? Number(penNameId) : undefined,
          })
          await queryClient.refetchQueries('export-jobs')
        }
      } catch (e) {
        alert.error(getErrorMessage(e))
      }
    }

    const newProps = {
      queryParam,
      handleSearch,
      handleExport,
      writerData,
      trafficByUser,
      penNameCount,
      penNameHandle,
      onResetForm,
      isLoading: isLoading || trafficLoading,
    }
    return <Component {...newProps} />
  }

  return WithByUserPage
}
