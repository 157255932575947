import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class ReaderAverageRevenuePerPayingUserType {
  @Expose() year: number

  @Expose()
  @Transform(({ obj }) =>
    obj.day
      ? obj.day
      : `${DateTime.now()
          .set({ month: obj.month })
          .toFormat('LLL', { locale: 'th-TH' })
          .toString()} ${obj.year}
  `
  )
  name: string

  @Expose({ name: 'averageRevenuePerPayingUser' }) value: number
}
