import { gql } from 'graphql-request'

export const FORGOT_ADMIN_PASSWORD = gql`
  mutation ForgotAdminPassword(
    $forgotPasswordAdminInput: ForgotPasswordAdminInput!
  ) {
    forgotAdminPassword(forgotPasswordAdminInput: $forgotPasswordAdminInput) {
      message
    }
  }
`
