import {
  WithReportCategoryDetailProps,
  ReportCategoryDetailProps,
} from './interface'

export function withReportCategoryDetail(
  Component: React.FC<ReportCategoryDetailProps>
) {
  function WithReportCategoryDetail({
    className,
    data,
  }: WithReportCategoryDetailProps) {
    const temp = data.filter(row => row.value > 0)
    const maxValue = Math.max(...data.map(i => i.value))
    const topThree = temp.sort((a, b) => b.value - a.value).slice(0, 3)
    const newProps = {
      className,
      data,
      topThree,
      maxValue,
    }
    return <Component {...newProps} />
  }

  return WithReportCategoryDetail
}
