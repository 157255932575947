import { useMemo } from 'react'
import cn from 'classnames'

import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { NoData } from '@components/NoData'
import { AllWriterTableProps } from './interface'
import { columns } from './column'

export function AllWriterTable({
  book,
  bookCount,
  handleSort,
  tab,
  setTab,
  handleFetchNextPage,
  isLoading,
}: AllWriterTableProps) {
  const menusArray = [
    {
      value: BookStatusEnum.PUBLISHED,
      text: 'งานเขียนที่เผยแพร่',
      total: bookCount?.publishedCount || 0,
    },
    {
      value: BookStatusEnum.DRAFT,
      text: 'งานเขียนที่ไม่เผยแพร่',
      total: bookCount?.draftCount || 0,
    },
  ]

  const data = useMemo(
    () => book?.pages.flatMap(page => page.data) ?? [],
    [book]
  )

  return (
    <div>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        งานเขียนทั้งหมด
      </h1>
      <div className='relative select-none mb-[30px]'>
        <div
          className={cn(
            'text-[14px] text-secondary font-light gap-x-[15px] flex'
          )}
        >
          {menusArray.map(item => (
            <button
              key={item.value}
              type='button'
              className={cn(
                'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
                {
                  'border-b-[3px] border-black-480 text-black-480 font-bold z-[1]':
                    item.value === tab,
                  'text-black-480 font-light': item.value !== tab,
                }
              )}
              onClick={() => setTab(item.value)}
            >
              {item.text}
              <span className='font-light'>
                ({item.total.toLocaleString()})
              </span>
            </button>
          ))}
        </div>
        <div
          className={cn('absolute w-full h-[1px] bottom-[1px] bg-gray z-0')}
        />
      </div>
      <Table
        fixedHeader
        className='max-h-[610px] pr-[20px]'
        data={data}
        columns={columns}
        onSort={handleSort}
        onWaypointEnter={handleFetchNextPage}
        isLoading={isLoading}
        initialSort={{ key: 'title', order: SortingType.DESC }}
      />
    </div>
  )
}
