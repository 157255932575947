import { Expose } from 'class-transformer'

export class WriterBookSaleType {
  @Expose() id: number

  @Expose() title: string

  @Expose() firstPenName: string

  @Expose() secondPenName: string

  @Expose() purchasedCount: number

  @Expose() sales: string

  @Expose() writerIncome: string
}
