import { FormikHelpers } from 'formik'

import { useAuthentication } from '@hooks/useAuthentication'
import { useAlert } from '@hooks/useAlert'
import { LoginFormType } from '@models/authentication/LoginFormType'
import { LoginPageProps } from './interface'

export function withLoginPage(Component: React.FC<LoginPageProps>) {
  function WithLoginPage() {
    const { signIn } = useAuthentication()
    const alert = useAlert()

    async function handleLogin({
      email,
      password,
    }: LoginFormType): Promise<void> {
      try {
        await signIn({ email, password })
      } catch (error: any) {
        const errorMsg = error.message
        if (
          errorMsg.includes('auth/user-not-found') ||
          errorMsg.includes('auth/wrong-password')
        ) {
          alert.error('อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง')
        } else {
          alert.error(errorMsg)
        }
      }
    }

    const newProps = {
      handleLogin,
    }

    return <Component {...newProps} />
  }

  return WithLoginPage
}
