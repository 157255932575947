import { withPermissionGuard } from '@libs/permission'
import { BookStorySettingPage } from './BookStorySettingPage'
import { withBookStorySettingPage } from './withBookStorySettingPage'

const ConnectedBookStorySettingPage = withPermissionGuard(
  withBookStorySettingPage(BookStorySettingPage),
  'chapterBook',
  'view'
)

export { ConnectedBookStorySettingPage as BookStorySettingPage }
