import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { SelectField } from '@components/forms/SelectField'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { MultiSelectField } from '@components/forms/MultiSelectField'
import { Button } from '@components/Button'
import { PlusCircleIcon, MinusCircleIcon } from '@components/Icons'
import { Checkbox } from '@components/forms/Checkbox'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { BookItem } from './components/BookItem/BookItem'
import { SearchField } from './components/SearchField'
import { HighlightBookBannerFormProps } from './interface'

function Skeleton() {
  return (
    <div className='border-b border-gray px-4 py-[10px] w-full mx-auto'>
      <div className='animate-pulse flex space-x-4 items-center'>
        <div className='rounded bg-gray h-[50px] w-[33px]' />
        <div className='flex-1 space-y-6 py-1'>
          <div className='space-y-3'>
            <div className='h-2 bg-gray rounded w-[100px]' />
            <div className='grid grid-cols-6 gap-10'>
              <div className='h-2 bg-gray rounded col-span-2' />
              <div className='h-2 bg-gray rounded col-span-2' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function HighlightBannerBookForm({
  bookList,
  isLoadingBookList,
  selectedBooks,
  statusBooks,
  categoryOptions,
  searchParams,
  bookOption,
  typeOption,
  orderOption,
  totalBook,
  resetForm,
  searchFormSubmit,
  addItem,
  addAllItem,
  removeItem,
  removeAllItem,
  onSubmit,
  handleFetchNextPage,
}: HighlightBookBannerFormProps) {
  const navigate = useNavigate()

  return (
    <div>
      <Formik initialValues={searchParams} onSubmit={searchFormSubmit}>
        {({}) => (
          <Form>
            <section className='flex flex-col gap-5'>
              <div>
                <SearchField
                  name='searchText'
                  placeholder='ค้นหางานเขียน, สำนักพิมพ์, นักเขียน, นามปากกา หรืออื่น ๆ'
                  inputClassName='font-[300] bg-gray-50  rounded-[20px]'
                />
              </div>
              <div className='grid grid-cols-2 gap-x-5'>
                <SelectField
                  name='bookType'
                  label='ลักษณะงานเขียน'
                  labelClassName='text-secondary-100'
                  options={bookOption}
                />

                <MultiSelectField
                  name='categoryIds'
                  label='หมวดหมู่'
                  placeholder='หมวดหมู่'
                  options={categoryOptions}
                />
              </div>
              <div className='flex space-x-[20px] items-end'>
                <SelectField
                  className='w-[400px]'
                  labelClassName='text-secondary-100'
                  name='orderBy'
                  label='เรียงตาม'
                  options={orderOption}
                />

                <SelectButtonField
                  name='secondaryType'
                  options={typeOption}
                  label='ประเภท'
                  mode='line'
                  className='w-fit overflow-auto'
                />

                <div className='flex items-center w-full'>
                  {SHOW_EBOOK_FEATURE && (
                    <Checkbox name='isEbook' label='Ebook Series' />
                  )}
                  <Button
                    type='reset'
                    className='text-secondary underline ml-auto whitespace-nowrap'
                    onClick={resetForm}
                  >
                    ล้างค่า
                  </Button>
                  <Button type='submit' className='bg-primary text-white '>
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                </div>
              </div>
            </section>

            <hr className='my-[30px]' />

            <section>
              <div className='grid grid-cols-2 gap-5 font-[300]'>
                <div>
                  <div className='flex justify-start mb-[24px]'>
                    <p className='text-secondary font-[500] mr-[30px]'>
                      งานเขียนที่พบ
                    </p>
                    <p className='text-secondary-100'>
                      จำนวนงานเขียน :{' '}
                      <span className='text-secondary font-[700]'>
                        {totalBook.toLocaleString()}
                      </span>{' '}
                      รายการ
                    </p>
                  </div>
                  <div
                    className='h-[450px] border border-gray-border rounded-[8px] overflow-y-auto relative'
                    onScroll={(e: any) => {
                      const scrollHeight = e.target.scrollHeight - 10
                      const scrollTop =
                        e.target.scrollTop + e.target.offsetHeight
                      if (scrollHeight <= scrollTop) {
                        handleFetchNextPage()
                      }
                    }}
                  >
                    <div className='flex items-center justify-end border-b px-[10px] py-[8px] sticky top-0 bg-white'>
                      <button
                        type='button'
                        className='flex gap-x-[10px]'
                        onClick={addAllItem}
                      >
                        <span className='text-secondary text-xs font-light'>
                          เพิ่มทั้งหมด
                        </span>
                        <PlusCircleIcon
                          className='text-primary'
                          width='18'
                          height='18'
                        />
                      </button>
                    </div>
                    {isLoadingBookList ? (
                      [...Array(7)].map((_, index) => <Skeleton key={index} />)
                    ) : bookList.length ? (
                      bookList.map(item => (
                        <BookItem
                          key={item?.id}
                          id={item?.id}
                          bookTitle={item?.title}
                          writer={item?.writer}
                          coverImgPath={item?.coverImgPath}
                          penName={item?.penName?.firstPenName}
                          publisher={item?.penName?.user?.displayName}
                          bookType={item?.bookType}
                          actionHandler={() => addItem(item)}
                        />
                      ))
                    ) : (
                      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[14px] text-secondary-100'>
                        ไม่พบงานเขียน
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div className='flex justify-end gap-x-[5px] mb-[24px] text-secondary font-[500]'>
                    <span>งานเขียนที่เพิ่มเข้ามา</span>
                    <span className='text-secondary-100'>
                      {' '}
                      ( {selectedBooks.length || 0} เรื่อง)
                    </span>
                  </div>
                  <div className='h-[450px] border border-gray-border rounded-[8px] overflow-y-auto relative'>
                    <div className='flex items-center justify-end border-b px-[10px] py-[8px] sticky top-0 bg-white'>
                      <button
                        type='button'
                        className='flex gap-x-[10px]'
                        onClick={removeAllItem}
                      >
                        <span className='text-secondary text-xs font-light'>
                          ลบทั้งหมด
                        </span>
                        <MinusCircleIcon
                          className='text-red'
                          width='18'
                          height='18'
                        />
                      </button>
                    </div>
                    {selectedBooks.length ? (
                      selectedBooks.map(item => (
                        <BookItem
                          key={item?.id}
                          id={item?.id}
                          bookTitle={item?.title}
                          writer={item?.writer}
                          coverImgPath={item?.coverImgPath}
                          penName={item?.penName?.firstPenName}
                          publisher={item?.penName?.user?.displayName}
                          bookType={item?.bookType}
                          actionHandler={() => removeItem(item)}
                          isRemove
                        />
                      ))
                    ) : (
                      <div className='w-full h-full flex items-center justify-center text-[14px] text-secondary-100'>
                        เพิ่มงานเขียนที่ต้องการ
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className='flex items-center justify-center gap-x-5 my-[30px]'>
              <Button
                type='button'
                className='border border-secondary-100 text-[14px] text-secondary-100 min-w-[100px]'
                onClick={() => navigate(-1)}
              >
                ยกเลิก
              </Button>
              <Button
                type='button'
                className='border border-primary bg-primary text-[14px] text-white min-w-[100px] disabled:border-primary/90 disabled:bg-primary/90 disabled:cursor-not-allowed'
                onClick={() => onSubmit(statusBooks)}
              >
                บันทึก
              </Button>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  )
}
