import { Container } from '@components/Container'
import { StorySettingForm } from '@features/chapterBook/components/StorySettingForm'
import { BookStorySettingAddPageProps } from './interface'

export function BookStorySettingAddPage({
  handleSubmit,
}: BookStorySettingAddPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>เพิ่มแบบฟอร์ม</Container.Title>
      <StorySettingForm
        initialValues={{
          name: '',
          maxCharacter: 200,
        }}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}
