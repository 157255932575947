import { Expose, Transform } from 'class-transformer'

export class SaleReportType {
  @Expose({ name: 'totalBooksSold' })
  @Transform(({ value }) => value || 0)
  totalBook: number

  @Expose({ name: 'totalBookChaptersSold' })
  @Transform(({ value }) => value || 0)
  totalChapter: number

  @Expose({ name: 'totalUsersReading' })
  @Transform(({ value }) => value || 0)
  totalUserReading: number

  @Expose({ name: 'totalUsersDownload' })
  @Transform(({ value }) => value || 0)
  totalDownload: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalSpend: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalProfit: number

  @Expose({ name: 'totalBookAndBookChapterSales' })
  @Transform(({ value }) => value || 0)
  totalSell: number

  @Expose({ name: 'totalDonation' })
  @Transform(({ value }) => value || 0)
  totalDonate: number

  @Expose({ name: 'totalOverAllSales' })
  @Transform(({ value }) => value || 0)
  totalOverAllSale: number

  @Expose({ name: 'salesTarget' })
  @Transform(({ value }) => value || 500000)
  saleTarget: number
}
