import cx from 'classnames'

import { addComma } from '@libs/utils'
import { ProgressBar } from '@components/ProgressBar'
import { DeviceSectionProps } from './interface'

export function DeviceSection({
  data,
  maxValue,
  topThree,
}: DeviceSectionProps) {
  return (
    <div>
      <div className='font-sarabun text-[24px] font-sarabun font-bold text-secondary'>
        สัดส่วนการซื้อตามอุปกรณ์
      </div>
      <div className='pt-[15px] text-[14px]'>
        {data.countBuyChapterByPlatform.map((item, index) => (
          <div
            key={index}
            className='grid grid-cols-[200px_80px_1fr] font-sarabun items-center py-[5px]'
          >
            <div className='text-secondary'>{item.platform}</div>
            <div className='text-secondary-100 text-right'>
              {addComma(item.count, 0)}
            </div>
            <ProgressBar
              className='bg-transparent ml-[20px]'
              progressBarClassName={cx('rounded-[40px]', {
                'bg-secondary-50': !topThree.some(
                  element => element.platform === item.platform
                ),
                'bg-primary': topThree.some(
                  element => element.platform === item.platform
                ),
              })}
              value={item.count}
              max={maxValue}
            />
          </div>
        ))}
      </div>
      <hr className='border border-gray h-[1px] mt-[30px] w-full' />
    </div>
  )
}
