import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { CodeChannelTypeEnum } from '@interfaces/promotionCode/CodeChannelTypeEnum '
import { CoinCoditionEnum } from '@interfaces/promotionCode/CoinCoditionEnum'
import { LimitToJoinEnum } from '@interfaces/promotionCode/LimitToJoinEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionCode/PromotionPublishedEnum'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { PromotionUserEnum } from '@interfaces/promotionCode/PromotionUserEnum'
import { UserByEnum } from '@interfaces/promotionCode/UserByEnum'
import { DAY, getErrorMessage } from '@libs/utils'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { CreatePromotionCodePageProps } from './interface'

export function withCreatePromotionCodePage(
  Component: React.FC<CreatePromotionCodePageProps>
) {
  function WithCreatePromotionCodePage() {
    const [initialValues, setInitialValues] = useState<
      PromotionCodeFormType | undefined
    >(undefined)
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmCreateModal = useModal({ modal: 'confirmCreatePromotionCode' })
    const confirmLeaveModal = useModal({ modal: 'confirm' })
    const { data: coinOptions = [], isLoading } = useQuery(
      ['coin-options', 'promotion-code'],
      () =>
        client?.promotionCodeClient.getCoinOptions().then(res => {
          const silverCoin = res.find(row => row.isGoldCoin === false)
          if (silverCoin) {
            setInitialValues({
              promotionName: '',
              promotionDetail: '',
              promotionCondition: '',
              publishedType: PromotionPublishedEnum.NOW,
              codeChannelType: CodeChannelTypeEnum.SHOPEE,
              codeChannelSelect: '',
              codePurchase: '',
              codeChannelInput: 0,
              codeChannelTotalUsedCount: 0,
              startPublishedAt: new Date(),
              endPublishedAt: new Date(Date.now() + DAY * 30),
              forUserType: PromotionUserEnum.NEW_USER,
              isFirstTopUp: false,
              userIds: [],
              userIdInput: '',
              userIdInputError: undefined,
              userIdsFile: undefined,
              userIdsFileError: undefined,
              limitJoinPromotionType: LimitToJoinEnum.LIMIT,
              limitJoinPromotion: 1,
              isLimitPromotionCode: false,
              limitPromotionCode: 0,
              availableDay: [],
              paymentMethod: [],
              userByType: UserByEnum.BUY_COIN,
              userBuyCoinCondition: CoinCoditionEnum.GREATER_THAN,
              userBuyCoinInput: 0,
              moreCoinOption: silverCoin.value,
              moreCoinInput: 0,
              directCoinOption: silverCoin.value,
              directCoinInput: 0,
              promotionStatus: PromotionStatusEnum.DRAFT,
            })
          }
          return res
        })
    )

    const { mutateAsync: createPromotion } = useMutation(
      (form: PromotionCodeFormType) =>
        client!.promotionCodeClient.createPromotionCode(form),
      {
        onSuccess: id => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          confirmCreateModal.hide()
          navigate(`/promotion-setting/promotion-code/${id}/create-code`, {
            replace: true,
          })
        },
        onError: error => {
          alert.error(getErrorMessage(error))
          confirmCreateModal.hide()
        },
      }
    )
    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionCodeFormType) =>
        client!.promotionCodeClient.createPromotionCode(values),
      {
        onSuccess: id => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function createPromotionCode(values: PromotionCodeFormType) {
      await createPromotion(values)
      confirmCreateModal.hide()
    }

    function handleLeave(values: PromotionCodeFormType) {
      confirmLeaveModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่น Code
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(values)
          confirmLeaveModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmLeaveModal.hide()
          navigate(-1)
        },
      })
    }

    const newProps = {
      initialValues,
      coinOptions,
      isLoading,
      createPromotionCode,
      handleLeave,
    }

    return <Component {...newProps} />
  }

  return WithCreatePromotionCodePage
}
