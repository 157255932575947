import { DateTime } from 'luxon'
import cn from 'classnames'

import { AndroidIcon, AppleIcon, WebIcon } from '@components/Icons'
import { ColumnType } from '@components/Table/interface'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import {
  PopupBannerStatusEnum,
  popupBannerStatusValue,
} from '@interfaces/PopupBannerStatusEnum'
import { SearchPopupBannerType } from '@models/popupBanner/SearchPopupBannerType'

export const columns: ColumnType<SearchPopupBannerType>[] = [
  {
    column: 'ID',
    accessor: 'id',
    cell: ({ value }) => <p>{value || '-'}</p>,
    isSortable: true,
    cellClassName: 'py-[10px] pr-[5px] min-w-[50px] max-w-[50px]',
  },
  {
    column: 'Title',
    accessor: 'title',
    cell: ({ value }) => <p className='line-clamp-1'>{value}</p>,
    cellClassName: 'w-full py-[10px] pr-[5px]',
  },
  {
    column: 'Platform',
    accessor: 'platforms',
    cell: ({ value }) => (
      <div className='grid grid-cols-[25px_25px_1fr]'>
        <div>
          {(value as PlatformEnum[]).includes(PlatformEnum.WEB) && (
            <WebIcon width='18' height='18' />
          )}
        </div>
        <div>
          {(value as PlatformEnum[]).includes(PlatformEnum.ANDROID) && (
            <AndroidIcon width='18' height='18' />
          )}
        </div>
        <div>
          {(value as PlatformEnum[]).includes(PlatformEnum.IOS) && (
            <AppleIcon width='18' height='18' />
          )}
        </div>
      </div>
    ),
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
  },
  {
    column: 'ViewCount',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'UniqueViewers',
    accessor: 'uniqueViewerCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Click link',
    accessor: 'clickLinkCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'PublishDate',
    accessor: 'startPublishedAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy', {
          locale: 'th-Th',
        })}
      </div>
    ),
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'ExpiredDate',
    accessor: 'endPublishedAt',
    cell: ({ value }) => (
      <div>
        {DateTime.fromISO(value).toFormat('dd LLL yyyy', {
          locale: 'th-Th',
        })}
      </div>
    ),
    cellClassName: 'min-w-[100px] max-w-[100px] py-[10px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Status',
    accessor: 'status',
    cell: ({ value }) => (
      <div
        className={cn('font-bold text-[14px]', {
          'text-green-986': value === PopupBannerStatusEnum.ACTIVE,
          'text-secondary': value === PopupBannerStatusEnum.INACTIVE,
          'text-secondary-100': value === PopupBannerStatusEnum.EXPIRED,
        })}
      >
        {popupBannerStatusValue[value as PopupBannerStatusEnum]}
      </div>
    ),
    cellClassName: 'flex justify-end py-[10px] min-w-[80px] max-w-[80px]',
    isSortable: true,
  },
]
