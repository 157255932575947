import { gql } from 'graphql-request'

export const CREATE_PROMOTION_CODE = gql`
  mutation CreatePromotionCode(
    $createPromotionCodeInput: CreatePromotionCodeInput!
  ) {
    createPromotionCode(createPromotionCodeInput: $createPromotionCodeInput) {
      id
    }
  }
`
