import { useState } from 'react'
import { useQuery } from 'react-query'

import { client } from '@client/init'
import { CoinDetailProps } from '@components/CoinDetail/interface'
import { CoinDetailAcceptProps } from './interface'

export function withCoinDetail(Component: React.FC<CoinDetailProps>) {
  function WithCoinDetail({ isByUser, searchParam }: CoinDetailAcceptProps) {
    const [showAllCoin, setShowAllCoin] = useState(false)

    const { data } = useQuery(
      ['system-coin', searchParam.startAt, searchParam.endAt],
      () =>
        client!.coinClient.getSystemCoins({
          startDate: searchParam.startAt,
          endDate: searchParam.endAt,
        })
    )

    function toggleShowAllCoin() {
      setShowAllCoin(prev => !prev)
    }

    const newProps = {
      systemCoins: data,
      showAllCoin,
      isByUser,
      toggleShowAllCoin,
    }
    return <Component {...newProps} />
  }

  return WithCoinDetail
}
