import { useEffect, useState } from 'react'
import {
  collection,
  documentId,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore'

export type MenuNotice = {
  ageConfirmation: boolean
  coin: boolean
  promotionSetting: boolean
  reportManagement: boolean
  writerSubmit: boolean
}

const docId = 'admin'

export function useAdminMenuBadge() {
  const db = getFirestore()
  const adminMenuBadgeCollection = collection(db, 'admin-menu-badge')
  const [menuNotice, setMenuNotice] = useState<MenuNotice>({
    ageConfirmation: false,
    coin: false,
    promotionSetting: false,
    reportManagement: false,
    writerSubmit: false,
  })

  useEffect(() => {
    const q = query(adminMenuBadgeCollection, where(documentId(), '==', docId))
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let notice: any
      querySnapshot.docChanges().forEach(change => {
        notice = change.doc.data()
      })
      setMenuNotice(notice as MenuNotice)
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { menuNotice }
}
