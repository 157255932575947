import React from 'react'

import {
  PaginationType,
  PaginationProps,
} from '@components/Pagination/interface'

export function withPagination(Component: React.FC<PaginationProps>) {
  function WithPagination({
    page,
    perpage,
    total,
    pageChange,
  }: PaginationType) {
    const totalPage = Math.ceil(total / perpage)

    const pageProps = {
      page,
      total,
      perpage,
      pageChange,
      totalPage,
    }
    return <Component {...pageProps} />
  }
  return WithPagination
}
