import { withPermissionGuard } from '@libs/permission'
import { UserMissionDetailPage } from './UserMissionDetailPage'
import { withUserMissionDetailPage } from './withUserMissionDetailPage'

const ConnectedUserMissionDetailPage = withPermissionGuard(
  withUserMissionDetailPage(UserMissionDetailPage),
  'userMission',
  'view'
)

export { ConnectedUserMissionDetailPage as UserMissionDetailPage }
