import React from 'react'
import { ArrowRightIcon } from '@components/Icons'
import {
  promotionChallengeActionText,
  promotionChallengeBenefitLimitTypeText,
  promotionChallengeReadConditionText,
} from '@libs/promotion/utils'
import { capitalizeFirstLetter, formatDateTime } from '@libs/utils'
import { CorrectionDetailProps } from './interface'

function CorrectionDetail({ data }: CorrectionDetailProps) {
  return (
    <div className='py-[30px] flex flex-col space-y-5 border-b text-sm font-light'>
      <div className='text-secondary-100'>รายละเอียดการแก้ไข :</div>
      {data?.name && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              ชื่อโปรโมชั่น :
            </span>
            <span className='text-secondary'>{data.name?.old}</span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3'>
            <div className='col-span-3 flex items-center space-x-[10px]'>
              <span className='text-secondary-100 flex-shrink-0'>
                ชื่อโปรโมชั่น :
              </span>
              <span className='text-secondary'>{data.name?.new}</span>
            </div>
          </div>
        </div>
      )}
      {data.description && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              รายละเอียด :
            </span>
            <span className='text-secondary'>{data.description.old}</span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              รายละเอียด :
            </span>
            <span className='text-secondary'>{data.description.new}</span>
          </div>
        </div>
      )}
      {data.userTerm && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              เงื่อนไขการใช้งาน :
            </span>
            <span className='text-secondary break-word'>
              {data.userTerm.old}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>
              เงื่อนไขการใช้งาน :
            </span>
            <span className='text-secondary break-word'>
              {data.userTerm.new}
            </span>
          </div>
        </div>
      )}
      {data.publishPeriod && (
        <div className='grid grid-cols-11 items-center'>
          <div className='col-span-5 flex items-center space-x-[10px]'>
            <span className='text-secondary-100 flex-shrink-0'>เผยแพร่ :</span>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.startPublishedAt!.old!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
            <span className='text-secondary-100'>ถึง</span>
            <span className='text-secondary'>
              {formatDateTime(data.publishPeriod.endPublishedAt!.old!, {
                separateText: '-',
                showSecond: true,
              })}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-5'>
            <div className='ml-[20px] flex items-center space-x-[10px]'>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.startPublishedAt!.new!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
              <span className='text-secondary-100'>ถึง</span>
              <span className='text-secondary'>
                {formatDateTime(data.publishPeriod.endPublishedAt!.new!, {
                  separateText: '-',
                  showSecond: true,
                })}
              </span>
            </div>
          </div>
        </div>
      )}
      {(!!data.challengeAction?.action ||
        !!data.challengeAction?.actionValue) && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>Action :</span>
            <span className='text-secondary'>
              {promotionChallengeActionText(data.challengeAction?.action?.old)}
            </span>
            <span className='text-secondary-100'>จำนวน :</span>
            <span className='text-secondary'>
              {data.challengeAction.actionValue.old?.toLocaleString()}{' '}
              {data.challengeAction.readCondition &&
                data.challengeAction.readCondition.old}{' '}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>Action :</span>
            <span className='text-secondary'>
              {promotionChallengeActionText(data.challengeAction?.action?.new)}
            </span>
            <span className='text-secondary-100'>จำนวน :</span>
            <span className='text-secondary'>
              {data.challengeAction.actionValue?.new?.toLocaleString()}
              {data.challengeAction.readCondition &&
                data.challengeAction.readCondition.new}{' '}
            </span>
          </div>
        </div>
      )}
      {data.challengeAction?.coinsCondition && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>เหรียญ :</span>
            <span className='text-secondary'>
              {data.challengeAction.coinsCondition &&
              data.challengeAction.coinsCondition.old
                ? data.challengeAction.coinsCondition.old
                    ?.map((c: any) => `เหรียญ${c.name}`)
                    ?.join(', ')
                : '-'}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>เหรียญ :</span>
            <span className='text-secondary'>
              {data.challengeAction.coinsCondition &&
              data.challengeAction.coinsCondition.new
                ? data.challengeAction.coinsCondition.new
                    ?.map((c: any) => `เหรียญ${c.name}`)
                    ?.join(', ')
                : '-'}
            </span>
          </div>
        </div>
      )}
      {data.challengeAction?.wordCount && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำนวนตัวอักษร :</span>
            <span className='text-secondary'>
              {data.challengeAction.wordCount.old?.toLocaleString()}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำนวน :</span>
            <span className='text-secondary'>
              {data.challengeAction.wordCount.new?.toLocaleString()}
            </span>
          </div>
        </div>
      )}
      {(data.challengeAction?.bookTypes ||
        data.challengeAction?.categories) && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>ประเภทงานเขียน :</span>
            <span className='text-secondary'>
              {data.challengeAction.bookTypes.old
                ?.map((b: any) => b.name)
                ?.join(', ')}
            </span>
            <span className='text-secondary-100'>หมวดหมู่ :</span>
            <span className='text-secondary'>
              {data.challengeAction.categories?.old
                ?.map(c => c.name)
                .join(', ')}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>ประเภทงานเขียน :</span>
            <span className='text-secondary'>
              {data.challengeAction.bookTypes.new
                ?.map((b: any) => b.name)
                ?.join(', ')}
            </span>
            <span className='text-secondary-100'>หมวดหมู่ :</span>
            <span className='text-secondary'>
              {data.challengeAction.categories?.new
                ?.map(c => c.name)
                .join(', ')}
            </span>
          </div>
        </div>
      )}
      {data.challengeAction?.socialMediaChannels && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>แชร์ไปยัง :</span>
            <span className='text-secondary'>
              {data.challengeAction.socialMediaChannels.new
                ?.map((social: string) =>
                  capitalizeFirstLetter(social.toLowerCase())
                )
                ?.join(', ')}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>แชร์ไปยัง :</span>
            <span className='text-secondary'>
              {data.challengeAction.socialMediaChannels.new
                ?.map((social: string) =>
                  capitalizeFirstLetter(social.toLowerCase())
                )
                ?.join(', ')}
            </span>
          </div>
        </div>
      )}
      {data.challengeBenefit && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>รับเหรียญ :</span>
            <span className='text-secondary'>
              {data.challengeBenefit.benefitValue?.old?.toLocaleString()}
              <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit.benefitCoin?.old}`}</span>
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>รับเหรียญ :</span>
            <span className='text-secondary'>
              {data.challengeBenefit.benefitValue?.new?.toLocaleString()}
              <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit.benefitCoin?.new}`}</span>
            </span>
          </div>
        </div>
      )}
      {data.challengeBenefitLimit && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>เก็บเหรียญได้ :</span>
            <span className='text-secondary'>
              {data.challengeBenefitLimit.benefitLimitValue?.old?.toLocaleString()}
              {promotionChallengeBenefitLimitTypeText(
                data.challengeBenefitLimit.benefitLimitType?.old?.toUpperCase()
              )}
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>เก็บเหรียญได้ :</span>
            <span className='text-secondary'>
              {data.challengeBenefitLimit.benefitLimitValue?.new?.toLocaleString()}{' '}
              {promotionChallengeBenefitLimitTypeText(
                data.challengeBenefitLimit.benefitLimitType?.new?.toUpperCase()
              )}
            </span>
          </div>
        </div>
      )}
      {data.challengeBudget?.budget && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>งบประมาณ/แคมเปญ :</span>
            <span className='text-secondary'>
              {data.challengeBudget.budget?.old?.toLocaleString()}
              <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit?.benefitCoin?.old}`}</span>
            </span>
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>งบประมาณ/แคมเปญ :</span>
            <span className='text-secondary'>
              {data.challengeBudget.budget?.new?.toLocaleString()}
              <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit?.benefitCoin?.new}`}</span>
            </span>
          </div>
        </div>
      )}
      {data.challengeBudget?.budgetPerDay && (
        <div className='grid grid-cols-7 items-center'>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดจำนวน :</span>
            {data.challengeBudget.budgetPerDay?.old ? (
              <span className='text-secondary'>
                {data.challengeBudget.budgetPerDay?.old?.toLocaleString()}
                <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit?.benefitCoin?.old} ต่อวัน`}</span>
              </span>
            ) : (
              <span className='text-secondary'>-</span>
            )}
          </div>
          <div className='col-span-1 justify-self-center'>
            <ArrowRightIcon className='text-primary' width='18' height='18' />
          </div>
          <div className='col-span-3 flex items-center space-x-[10px]'>
            <span className='text-secondary-100'>จำกัดจำนวน :</span>
            {data.challengeBudget.budgetPerDay?.new ? (
              <span className='text-secondary'>
                {data.challengeBudget.budgetPerDay?.new?.toLocaleString()}
                <span className='text-secondary-100 ml-[5px]'>{`เหรียญ${data.challengeBenefit?.benefitCoin?.new} ต่อวัน`}</span>
              </span>
            ) : (
              <span className='text-secondary'>-</span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CorrectionDetail
