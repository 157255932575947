import { Expose } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'

export class StoryTopAddToShelfType {
  @Expose() rank: number

  @Expose() bookId: number

  @Expose() bookCoverImgPath: string

  @Expose() bookTitle: string

  @Expose() bookType: BookEnum

  @Expose() firstPenName: string

  @Expose() secondPenName: string

  @Expose() chapterCount: number

  @Expose() countLike: number

  @Expose() countView: number

  @Expose() countShelf: number
}
