import { useState, useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'
import { SortByType, SortingType } from '@components/Table/interface'
import { useClient } from '@hooks/useClient'
import { CharacterSectionProps, WithCharacterSectionProps } from './interface'

export function withCharacterSection(
  Component: React.FC<CharacterSectionProps>
) {
  function WithCharacterSection({ searchParams }: WithCharacterSectionProps) {
    const client = useClient()
    const [sortBy, setSortBy] = useState<SortByType>({
      key: '',
      order: SortingType.ASC,
    })

    const {
      data: donationByCharacter,
      isFetchingNextPage,
      fetchNextPage,
      isLoading,
    } = useInfiniteQuery(
      ['character-donation-by-character', searchParams, sortBy],
      ({ pageParam = 1 }) =>
        client!.characterClient.getCharacterDonationByCharacter({
          ...searchParams,
          ...sortBy,
          page: pageParam,
          limitPerPage: 10,
        }),
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    function handleSort({ key, order }: SortByType) {
      setSortBy(prev => ({ ...prev, key, order }))
    }

    const data = useMemo(
      () => donationByCharacter?.pages?.flatMap(page => page.data) ?? [],
      [donationByCharacter]
    )

    const componentProps = {
      data,
      handleSort,
      handleFetchNextPage,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithCharacterSection
}
