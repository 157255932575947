import { Expose, Transform } from 'class-transformer'

export class HighestCharacterDonateResponse {
  @Expose({ name: 'bookId' }) id: string

  @Expose({ name: 'bookTitle' }) title: string

  @Expose() characterName: string

  @Expose() username: string

  @Expose({ name: 'rowNumber' }) numRow: string

  @Expose({ name: 'characterTotalDonate' })
  @Transform(({ value }) => (value ? Number(value) : 0))
  donateCount: number
}
