import { gql } from 'graphql-request'

export const PRMOTION_CODE_USED_LOG = gql`
  query PromotionCodeUsedLogs(
    $promotionCodeId: Int!
    $limitPerPage: Int
    $page: Int
    $orderBy: CodeLogUsedOrderBy
    $searchText: String
  ) {
    promotionCodeUsedLogs(
      promotionCodeId: $promotionCodeId
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      searchText: $searchText
    ) {
      page
      total
      data {
        id
        userId
        userType
        type
        channel
        coupon
        totalPrice
        discountedPrice
        summaryPrice
        discountedPercentage
        paidCoinReceived
        feeCoinReceived
        promotionName
        currentUsed
        maximumUsed
        createdAt
      }
    }
  }
`
