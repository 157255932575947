import { withPermissionGuard } from '@libs/permission'
import { EditHighlightBannerPage } from './EditHighlightBannerPage'
import { withEditHighlightBannerPage } from './withEditHighlightBannerPage'

const Connected = withPermissionGuard(
  withEditHighlightBannerPage(EditHighlightBannerPage),
  'homePageManagement',
  'edit'
)

export { Connected as EditHighlightBannerPage }
