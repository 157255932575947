import React from 'react'
import cn from 'classnames'

interface TiptapDropdownInputProps {
  onComfirm: () => void
  onChange: (value: string) => void
  toggleDropdown: (e: any) => void
  className: string
  value: string
  title: string
}

function TiptapDropdownInput({
  onComfirm,
  onChange,
  toggleDropdown,
  className,
  value,
  title,
}: TiptapDropdownInputProps) {
  return (
    <div
      className={cn(
        'p-[10px] bg-white border-1 border-gray rounded-[10px] max-h-[400px] shadow-[0_3px_12px_0_rgba(51,51,51,0.05)]',
        className
      )}
      onClick={event => event.stopPropagation()}
    >
      <div className='px-[5px] pb-[5px] flex'>
        <div className='flex-1  text-secondary text-sm '>{title}</div>
        <button
          className='bg-gray-2 rounded-[6px] text-secondary text-sm font-light flex items-center justify-center px-[5px] cursor-pointer'
          type='button'
          onClick={e => {
            onComfirm()
            toggleDropdown(e)
          }}
        >
          Confirm
        </button>
      </div>
      <input
        type='text'
        className='w-[250px] border-[1px] border-gray-3 focus:outline-none text-[14px] text-gray-7 rounded-[8px] p-[5px] flex disabled:bg-gray-3 disabled:text-gray-10"'
        onChange={event => onChange(event.target.value)}
        value={value || ''}
      />
    </div>
  )
}

export default TiptapDropdownInput
