import { DateTime } from 'luxon'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { Container } from '@components/Container'
import { ArrowLeftIcon } from '@components/Icons'
import { Loading } from '@components/Loading'
import PromotionSaleInfo from '@features/promotionSetting/components/PromotionSaleInfo'
import { Card } from '@features/promotionSetting/components/Card'
import { PromotionSaleHistoryPageProps } from './interface'
import { PromotionSaleHistoryUserInfo } from './components/PromotionSaleHistoryUserInfo'
import CorrectionDetail from './components/CorrectionDetail'

export function PromotionSaleHistoryPage({
  promotionId,
  historyList,
  historyData,
  listLoading,
  detailLoading,
  approvedLoading,
  requestId,
  selectIdHandle,
  handleReject,
  handleApprove,
  goBackHandle,
}: PromotionSaleHistoryPageProps) {
  return (
    <Container>
      {listLoading ? (
        <Loading size={36} className='m-auto' />
      ) : historyList && historyList.length >= 1 ? (
        <>
          <div className='flex items-center gap-x-[20px] mb-[28px]'>
            <button
              className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
              type='button'
              onClick={goBackHandle}
            >
              <ArrowLeftIcon width='24' height='24' />
            </button>

            <div className='text-[24px] text-secondary font-medium font-mitr'>
              ดูประวัติการเปลี่ยนโปรโมชั่น
            </div>
          </div>
          <div className='flex divide-x'>
            <div className='w-[180px] flex flex-col gap-y-[20px] max-h-[70vh] overflow-y-auto'>
              {historyList?.map(item => (
                <button
                  key={item.id}
                  type='button'
                  className={cn('flex text-sm font-bold', {
                    'text-secondary': item.id === requestId,
                    'text-secondary-100': item.id !== requestId,
                  })}
                  onClick={() => selectIdHandle(item.id)}
                >
                  {DateTime.fromISO(item.createdAt)
                    .setLocale('th')
                    .toFormat('dd  LLL yyyy - HH:mm:ss')}
                </button>
              ))}
            </div>
            <div className='flex-1 pl-[50px]'>
              {detailLoading ? (
                <Loading size={36} className='m-auto' />
              ) : historyData ? (
                <>
                  <Card>
                    <PromotionSaleInfo
                      status={historyData.oldPromotion.status}
                      detail={historyData.newPromotion}
                    />
                  </Card>
                  <PromotionSaleHistoryUserInfo
                    data={historyData}
                    handleReject={handleReject}
                    handleApprove={handleApprove}
                    approvedLoading={approvedLoading}
                  />
                  {historyData.correctionDetail && (
                    <CorrectionDetail
                      data={historyData.correctionDetail}
                      benefitCoin={
                        historyData.newPromotion.promotionSale.benefitCoin
                      }
                    />
                  )}
                  {historyData.rejectedReason && (
                    <div className='pt-[30px]'>
                      <div className='text-xs text-secondary-100 font-light'>
                        ส่งข้อความถึงผู้ดำเนินการ
                      </div>
                      <div className='text-sm font-light whitespace-pre-line'>
                        {historyData.rejectedReason}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <span className='text-center'>
                  เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่ในภายหลัง
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        'No data'
      )}
    </Container>
  )
}
