import { Link } from 'react-router-dom'

import { Container } from '@components/Container'
import { UserMissionForm } from '@features/user-mission/components/UserMissionForm'
import { UserMissionLevelForm } from '@features/user-mission/components/UserMissionLevelForm'
import { EditUserMissionPageProps } from './interface'

export function EditUserMissionPage({
  data,
  userMissionForm,
  handleUpdateUserMission,
  refetchUserMission,
  editId,
  isEdit,
  setEditId,
  setIsEdit,
  onClickBackBtn,
  setUserMissionForm,
}: EditUserMissionPageProps) {
  return (
    <Container>
      <div className='flex justify-between'>
        <Container.Title showBackBtn onClickBackBtn={onClickBackBtn}>
          รายละเอียด Mission{editId}
        </Container.Title>
        <Link to={`/user-mission/history/${data.id}`}>
          <button
            type='button'
            className='w-[212px] h-[40px] rounded-[20px] border border-secondary text-sm font-medium text-secondary font-mitr'
          >
            ดูประวัติการแก้ไข Mission
          </button>
        </Link>
      </div>

      <UserMissionForm
        data={data}
        onSubmit={handleUpdateUserMission}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <UserMissionLevelForm
        userMissionId={data.id}
        userMissionForm={userMissionForm}
        criteria={data.criteria}
        unit={data.challengeUnit}
        refetchUserMission={refetchUserMission}
        editId={editId}
        setEditId={setEditId}
        setUserMissionForm={setUserMissionForm}
      />
    </Container>
  )
}
