import React from 'react'

import { HighlightBannerPosterFormProps } from './interface'

export function withHighlightBannerPosterForm(
  Component: React.FC<HighlightBannerPosterFormProps>
) {
  function WithHighlightBannerPosterForm({
    ...props
  }: HighlightBannerPosterFormProps) {
    return <Component {...props} />
  }

  return WithHighlightBannerPosterForm
}
