import React from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import {
  PromotionApproveSectionAcceptProps,
  PromotionApproveSectionProps,
} from './interface'

export function withPromotionApproveSection(
  Component: React.FC<PromotionApproveSectionProps>
) {
  function WithPromotionApproveSection({
    queryKey,
    requestId,
    promotionId,
    promotionName,
    startPromotion,
    endPromotion,
    createdAt,
    promotionDetail,
    promotionType,
    ...props
  }: PromotionApproveSectionAcceptProps) {
    const queryClient = useQueryClient()
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })

    const { mutateAsync: approvePromotion, isLoading } = useMutation(
      (promotionRequestId: number) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status: PromotionStatusEnum.APPROVED,
        }),
      {
        onSuccess: () => {
          alert.success(`ดำเนินการสำเร็จ`)
          if (queryKey) {
            queryClient.invalidateQueries(queryKey)
          }
        },
        onError: () => {
          alert.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง`)
        },
      }
    )

    function handleApprove() {
      if (requestId) {
        approvePromotion(requestId)
      }
    }

    function handleReject() {
      rejectModal.show({
        requestId,
        promotionId,
        promotionName,
        startPromotion,
        endPromotion,
        createdAt,
        promotionDetail,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
        },
      })
    }
    const newProps = {
      ...props,
      handleApprove,
      handleReject,
    }
    return <Component {...newProps} />
  }
  return WithPromotionApproveSection
}
