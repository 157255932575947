import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { client } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { PromotionSaleFormType } from '@models/promotion/sale/PromotionSaleFormType'
import { PromotionStatusEnum } from '@interfaces/promotionChllenge/PromotionStatusEnum'
import { DuplicatePromotionSalePageProps } from './interface'

export function withDuplicatePromotionSalePage(
  Component: React.FC<DuplicatePromotionSalePageProps>
) {
  function WithDuplicatePromotionSalePage() {
    const { id } = useParams()
    const alert = useAlert()
    const navigate = useNavigate()
    const confirmModal = useModal({ modal: 'confirm' })
    const queryKey: QueryKey = ['promotion', 'sale', Number(id)]

    const { data, isFetching, isLoading } = useQuery(
      [...queryKey, 'duplicate'],
      () => client?.promotionSaleClient.getPromotionSaleForm(Number(id)),
      {
        onError: () => navigate(-1),
      }
    )

    const { data: saleCoinOptions = [] } = useQuery('sale-coin-options', () =>
      client?.coinClient.getPromotionChallengeCoinOptions()
    )

    const { mutateAsync: createPromotion } = useMutation(
      (values: PromotionSaleFormType) =>
        client!.promotionSaleClient.createPromotionSale(values),
      {
        onSuccess: () => {
          alert.success('สร้างโปรโมชั่นสำเร็จ')
          confirmModal.hide()
          navigate(-1)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
          confirmModal.hide()
        },
      }
    )
    const { mutateAsync: createPromotionDraft } = useMutation(
      (values: PromotionSaleFormType) =>
        client!.promotionSaleClient.createPromotionSale({
          ...values,
          status: PromotionStatusEnum.DRAFT,
        }),
      {
        onSuccess: () => {
          alert.success('บันทึกแบบร่างโปรโมชั่นสำเร็จ')
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function createPromotionSale(values: PromotionSaleFormType) {
      await createPromotion(values)
      confirmModal.hide()
    }

    function handleLeave(values: PromotionSaleFormType) {
      confirmModal.show({
        confirmButtonText: 'บันทึกแบบร่าง',
        closeButtonText: 'ออกจากหน้านี้',
        content: (
          <div className='w-[320px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ออกจากการสร้างโปรโมชั่นการขาย
            </p>
            <div className='mt-5 text-sm font-light text-secondary text-center'>
              <p>หากคุณออกจากหน้านี้ ข้อมูลที่กรอกไว้จะหายไป</p>
              <p>คุณต้องการที่จะบันทึกแบบร่างไว้หรือไม่</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          await createPromotionDraft(values)
          confirmModal.hide()
          navigate(-1)
        },
        onClose: () => {
          confirmModal.hide()
          navigate(-1)
        },
      })
    }

    const newProps = {
      initialValues: data
        ? {
            ...data!,
            id: undefined,
            status: PromotionStatusEnum.PENDING,
            startNowAt: undefined,
            startPublishedAt: new Date(),
            endPublishedAt: DateTime.now().plus({ month: 1 }).toJSDate(),
          }
        : undefined,
      createPromotionSale,
      handleLeave,
      isLoading: isFetching || isLoading,
      saleCoinOptions,
    }

    return <Component {...newProps} />
  }

  return WithDuplicatePromotionSalePage
}
