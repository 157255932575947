import { Field } from 'formik'
import React from 'react'
import { FieldPropsType } from '@interfaces/commons/FieldPropsType'
import {
  SelectButtonFieldAcceptProps,
  SelectButtonFieldProps,
} from './interface'

const withSelectButtonField = (Component: React.FC<SelectButtonFieldProps>) => {
  function Hoc({ name, onChange, ...props }: SelectButtonFieldAcceptProps) {
    return (
      <Field name={name}>
        {({ field, meta, form }: FieldPropsType<any>) => (
          <Component
            {...props}
            value={field.value}
            onChange={value => {
              form.setFieldValue(name, value)
              setTimeout(() => {
                form.setFieldTouched(name, true)
              })
              if (onChange) {
                onChange(value)
              }
            }}
          />
        )}
      </Field>
    )
  }

  return Hoc
}

export { withSelectButtonField }
