import { gql } from 'graphql-request'

export const GET_MAIN_BANNER_LIST = gql`
  query GetMainBannerList($platform: PlatformEnum!) {
    mainBanners(platform: $platform) {
      id
      web
      android
      ios
      isActiveWeb
      isActiveIos
      isActiveAndroid
      coverImgPath
      rawLink
      linkWeb
      linkIos
      linkAndroid
      description
      startPublishedAt
      endPublishedAt
      runningNo
    }
  }
`
