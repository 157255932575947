import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { useMemo } from 'react'
import { BoughtEbookTableProps } from './interface'
import { buyerColumns } from '../EbookOverviewTabs/columns'

export function BoughtEbookTable({
  data,
  isLoading,
  sortParams,
  handleSort,
  handleFetchNextPage,
}: BoughtEbookTableProps) {
  const boughtEbookDetail = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <CardContainer>
      <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
        รายการผู้ซื้อ
      </h1>

      <Table
        columns={buyerColumns}
        data={boughtEbookDetail}
        onSort={handleSort}
        onWaypointEnter={handleFetchNextPage}
        isLoading={isLoading}
        initialSort={{ key: sortParams.key, order: sortParams.order }}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
        className='min-h-[100px] pr-[10px]'
      />
    </CardContainer>
  )
}
