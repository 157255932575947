import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function withAuthenticationLayout(
  Component: React.FC<FunctionComponentType>
) {
  function WithAuthenticationLayout(props: FunctionComponentType) {
    return <Component {...props} />
  }

  return WithAuthenticationLayout
}
