import { Expose, Transform, Type } from 'class-transformer'

export class SalesByUserType {
  @Expose() id: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalIncome: number

  @Expose() username: string

  @Expose()
  @Transform(({ value }) => value || 0)
  totalReceivedDonation: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalReceivedSales: number

  @Expose()
  @Transform(({ value }) => value || 0)
  currentFreeCoin: number

  @Expose()
  @Transform(({ value }) => value || 0)
  currentPaidCoin: number

  @Expose() isStopSapPayment: boolean
}

export class SalesByUserResponse {
  @Expose()
  @Type(() => SalesByUserType)
  data: SalesByUserType[]
}
