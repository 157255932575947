import { useNavigate } from 'react-router-dom'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'
import { AddNewRolePageProps } from './interface'

export function withAddNewRolePage(Component: React.FC<AddNewRolePageProps>) {
  function WithAddNewRolePage() {
    const client = useClient()
    const navigate = useNavigate()
    const alert = useAlert()

    async function createRoleHandler(value: RolePermissionFormType) {
      try {
        await client?.adminClient.createRole(value)
        alert.success('เพิ่ม Role สำเร็จ')
        navigate('/admin/role-permission-setting')
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถเพิ่ม Role ได้สำเร็จ!')
      }
    }

    const newProps = {
      createRoleHandler,
    }
    return <Component {...newProps} />
  }

  return WithAddNewRolePage
}
