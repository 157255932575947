import React, { useRef } from 'react'

import { WithUploadImageProps, UploadImageProps } from './interface'

export function withUploadImage(Component: React.FC<UploadImageProps>) {
  function WithUploadImage({ onChange, ...props }: WithUploadImageProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.value = ''
        event.click()
      }
    }

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        onChange(event)
        if (inputRef.current) inputRef.current.value = ''
      }
    }

    const componentProps = {
      ...props,
      inputRef,
      onChange: handleFileChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadImage
}
