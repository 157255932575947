import { Expose, Type } from 'class-transformer'
import { CoinType } from './CoinType'

export class CoinResponse {
  @Type(() => CoinType)
  data: CoinType[]

  total: number

  page: number
}
