import cn from 'classnames'

import { Upload2Icon } from '@components/Icons'
import { UploadStickerFieldProps } from './interface'

export function UploadThumbnailField({
  src,
  inputRef,
  isError,
  onChange,
  showUploadDialog,
  className,
}: UploadStickerFieldProps) {
  return (
    <div className={cn('relative cursor-pointer flex ', className)}>
      {src && (
        <div className='flex flex-col'>
          <div
            onClick={showUploadDialog}
            className='w-[154px]  aspect-square rounded-[8px]'
          >
            <img
              src={src}
              className='w-full aspect-square rounded-[8px] my-auto'
              alt='cover'
            />
          </div>
          <button
            type='button'
            className='rounded-[15px] bg-[#f4f4f4] py-1 mt-[10px]'
            onClick={showUploadDialog}
          >
            <span className='font-mitr font-medium text-[14px] text-secondary-100'>
              อัปโหลดใหม่
            </span>
          </button>
        </div>
      )}

      {!src && (
        <div
          className={cn(
            'bg-blue-2 w-[154px] h-full rounded-[8px] flex flex-col items-center justify-center',
            {
              'border border-red': isError,
            }
          )}
          onClick={showUploadDialog}
        >
          <Upload2Icon className='text-secondary-100 w-[24px] h-[24px]' />
          <div className='text-secondary-100 font-medium font-mitr text-[14px] underline text-center'>
            Thumbnail <br />
            picture
          </div>
          <div className='text-secondary-100 text-[14px] font-light font-sarabun pt-[10px]'>
            200 X 200 px
          </div>
        </div>
      )}

      <input
        ref={inputRef}
        className='hidden'
        type='file'
        accept='image/*'
        onChange={onChange}
      />
    </div>
  )
}
