import React from 'react'
import { useField } from 'formik'

import {
  NumberWithDecimalFieldProps,
  WithNumberWithDecimalFieldProps,
} from './interface'

export function withNumberWithDecimalField(
  Component: React.FC<NumberWithDecimalFieldProps>
) {
  function WithNumberWithDecimalField({
    name,
    disabled = false,
    isNoDecimal = false,
    ...props
  }: WithNumberWithDecimalFieldProps) {
    const [field, { touched, error }, { setValue }] = useField(name)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { value } = event.target
      const regex = isNoDecimal
        ? /^[0-9]{1,}$/
        : /^([0-9]{1,})(([.]{1})?(|[0-9]{1,2}))?$/

      if (value === '') {
        setValue(0)
      }

      if (regex.test(value)) {
        const result = Number(value) < 1 ? value : value.replace(/^0+/, '')
        setValue(result)
      }
    }

    function onBlurHandle() {
      if (Number.isNaN(field.value)) {
        setValue(0)
      } else setValue(Number(field.value))
    }

    const componentProps = {
      ...props,
      ...field,
      touched,
      error,
      disabled,
      onBlurHandle,
      onChange: handleChange,
    }

    return <Component {...componentProps} />
  }

  return WithNumberWithDecimalField
}
