import { useStory } from '@hooks/story/useStory'
import React from 'react'
import { CharacterProps } from './interface'

export function withCharacter(Component: React.FC<CharacterProps>) {
  function WithCharacter() {
    const { data, charactersData } = useStory()

    if (!data) return null

    const componentPage = {
      bookId: data.id,
      bookName: data.title,
      characters: charactersData,
    }

    return <Component {...componentPage} />
  }
  return WithCharacter
}
