import { SortingType } from '@components/Table/interface'
import { DonationQueryParam } from '@features/dashboard/pages/DonationPage/interface'

export enum OrderByEnum {
  PUBLISHER = 'PUBLISHER',
  TOTAL_SEARCH = 'TOTAL_SEARCH',
}

export interface PublisherParam {
  orderBy: OrderByEnum
  orderDirection: SortingType
}

export interface SearchResultByPublisherParam
  extends PublisherParam,
    DonationQueryParam {
  page: number
}
