import { useFormikContext } from 'formik'

import { Box } from '@components/Box'
import { InputField } from '@components/forms/InputField'
import { PromotionCodeFormType } from '@models/promotion/PromotionCodeFormType'
import { TextAreaField } from '@components/forms/TextAreaField'
import { PromotionDetailProps } from './interface'

export function PromotionDetail({
  values,
  viewMode = false,
}: PromotionDetailProps) {
  const maxConditionLength = 5000
  return (
    <Box>
      <div className='grid gap-y-[30px]'>
        <h1 className='text-[18px] font-bold text-secondary'>
          รายละเอียดโปรโมชั่น
        </h1>
        <div className='flex flex-col gap-y-[30px]'>
          <InputField
            name='promotionName'
            label='ชื่อโปรโมชั่น'
            placeholder='ชื่อโปรโมชั่น'
            disabled={viewMode}
            maxLength={100}
          />
          <InputField
            name='promotionDetail'
            label='รายละเอียด'
            placeholder='รายละเอียด'
            disabled={viewMode}
            maxLength={200}
          />
          <div className='flex flex-col w-full text-secondary gap-y-[10px]'>
            <TextAreaField
              textAreaClassName='h-[150px] placeholder:text-secondary-100'
              name='promotionCondition'
              label='เงื่อนไขการใช้งาน'
              placeholder='เงื่อนไขการใช้งาน'
              maxLength={maxConditionLength}
              disabled={viewMode}
            />
            <p className='text-xs font-light text-secondary-100'>
              จำนวนตัวอักษรทั้งหมด:{' '}
              <span className='font-bold'>
                {values.promotionCondition?.length.toLocaleString()}
              </span>
              <span>/{maxConditionLength.toLocaleString()}</span>
            </p>
          </div>
        </div>
      </div>
    </Box>
  )
}
