/* eslint-disable max-classes-per-file */
import { Expose, Type } from 'class-transformer'

class RoleType {
  @Expose() id: number
}

class UserType {
  @Expose() email: string

  @Expose() displayName: string
}
export class AdminUserType {
  @Expose() id: number

  @Expose() businessUserId: string

  @Expose()
  @Type(() => UserType)
  user: UserType

  @Expose()
  @Type(() => RoleType)
  role: RoleType

  @Expose() lastLoginAt: string
}
