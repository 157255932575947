import { Container } from '@components/Container'
import { StickerManagementForm } from '@features/chapterBook/components/StickerManagementForm'
import { StickerManagementEditPageProps } from './interface'

export function StickerManagementEditPage({
  initialValues,
  isLoading,
  onSubmit,
}: StickerManagementEditPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>แก้ไขสติ๊กเกอร์</Container.Title>

      {!isLoading && (
        <StickerManagementForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  )
}
