import React from 'react'

import { useStory } from '@hooks/story/useStory'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { client, queryClient } from '@client/init'
import { BanUserImageEnum } from '@interfaces/user/BanUserImageEnum'
import { CharacterCardProps, WithCharacterCardProps } from './interface'

export function withCharacterCard(Component: React.FC<CharacterCardProps>) {
  function WithCharacterCard({
    id,
    bookId,
    imageUrl,
    ...props
  }: WithCharacterCardProps) {
    const alert = useAlert()
    const { data } = useStory()
    const confirmModal = useModal({
      modal: 'confirm',
    })

    function banCover() {
      try {
        confirmModal.show({
          content: (
            <div className='w-[400px] mb-[20px] '>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ต้องการจะแบนรูปภาพหรือไม่?
              </p>
            </div>
          ),
          onConfirm: async () => {
            try {
              await client!.userManagementClient.createBanImageLog({
                userId: data!.penName.user.id,
                type: BanUserImageEnum.CHARACTER,
                imageUrl,
                characterId: Number(id),
              })
              alert.success('ดำเนินการสำเร็จ')
            } catch (error) {
              alert.error(getErrorMessage(error))
            } finally {
              await queryClient.invalidateQueries(['characters', bookId])
              confirmModal.hide()
            }
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    const newProps = {
      banCover,
      id,
      bookId,
      imageUrl,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithCharacterCard
}
