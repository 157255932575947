import { gql } from 'graphql-request'

export const EXPORT_COIN_HISTORY = gql`
  query ExportCoinHistory(
    $startDate: DateTime!
    $endDate: DateTime!
    $searchText: String
    $orderBy: GetCoinHistoryOrderBy
  ) {
    exportCoinHistory(
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
      orderBy: $orderBy
    )
  }
`
