import { ProgressBarOverAllTwoSide } from '@components/ProgressBar'
import { OverAllChapterProps } from './interface'

export function OverAllChapter({
  freePrice,
  hasPrice,
  freePricePercent,
  hasPricePercent,
}: OverAllChapterProps) {
  const hasPriceLabel = `ตอนที่ติดเหรียญ (${hasPricePercent.toFixed(2)}%)`
  const freePriceLabel = `ตอนฟรี (${freePricePercent.toFixed(2)}%)`

  return (
    <ProgressBarOverAllTwoSide
      firstValue={hasPrice}
      secondValue={freePrice}
      firstLabel={hasPriceLabel}
      secondLabel={freePriceLabel}
    />
  )
}
