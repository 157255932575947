import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import { readConditionOptions } from '@features/promotionSetting/pages/CreatePromotionChallengePage/promotionChallengeOptions'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import {
  PreviewPromotionChallengeAcceptProps,
  PreviewPromotionChallengeProps,
} from './interface'

const withPreviewPromotionChallenge = (
  Component: React.FC<PreviewPromotionChallengeProps>
) => {
  function WithPreviewPromotionChallenge({
    form,
    ...props
  }: PreviewPromotionChallengeAcceptProps) {
    const client = useClient()

    const { data: categories = [] } = useQuery(['category-options'], () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          label: row.text,
          value: String(row.value),
        }))
      )
    )

    const actionCountUnit = useMemo(() => {
      let unit = { rightIcon: '', label: 'จำนวน :' }
      if (
        [
          PromotionChallengeActionEnum.LOVE,
          PromotionChallengeActionEnum.COMMENT,
          PromotionChallengeActionEnum.SHARE,
        ].includes(form.action)
      ) {
        unit.rightIcon = 'ครั้ง'
      } else if (form.action === PromotionChallengeActionEnum.READ) {
        unit.rightIcon =
          readConditionOptions.find(read => form.readCondition === read.value)
            ?.label || ''
      } else if (
        [
          PromotionChallengeActionEnum.UNLOCK_CHAPTER,
          PromotionChallengeActionEnum.DONATE,
        ].includes(form.action)
      ) {
        unit = {
          rightIcon: 'เหรียญขึ้นไป',
          label: 'จำนวน :',
        }
      } else if (
        [PromotionChallengeActionEnum.SET_PRICE_CHAPTER].includes(form.action)
      ) {
        unit = {
          rightIcon: 'เหรียญขึ้นไป',
          label: 'ขายตอนใหม่ได้ :',
        }
      } else if (
        [PromotionChallengeActionEnum.CREATE_BOOK].includes(form.action)
      ) {
        unit = {
          rightIcon: 'เหรียญขึ้นไป',
          label: 'ขายตอนในเรื่องใหม่ได้ :',
        }
      }
      return unit
    }, [form])

    const newProps = { categories, form, actionCountUnit, ...props }
    return <Component {...newProps} />
  }

  return WithPreviewPromotionChallenge
}

export default withPreviewPromotionChallenge
