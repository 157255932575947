import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { GetStickerListResponse } from '@client/collections/Sticker/interface'
import { useClient } from '@hooks/useClient'
import {
  TiptapStickerButtonProps,
  WithTiptapStickerButtonProps,
} from './interface'

const PER_PAGE = 20

export function withTiptapStickerButton(
  Component: React.FC<TiptapStickerButtonProps>
) {
  function WithTiptapStickerButton(props: WithTiptapStickerButtonProps) {
    const client = useClient()

    const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
      'sticker-list',
      async ({ pageParam = 1 }) => {
        const res = await client!.stickerClient.getStickerList({
          page: pageParam,
          perpage: PER_PAGE,
        })

        return res
      },
      {
        getNextPageParam: (lastPage: GetStickerListResponse) => {
          if (lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const componentProps = {
      ...props,
      stickerList: data,
      handleFetchNextPage,
    }

    return <Component {...componentProps} />
  }

  return WithTiptapStickerButton
}
