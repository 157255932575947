import { gql } from 'graphql-request'

export const GET_PENNAME_INCOME = gql`
  query GetSummaryPenNameIncome(
    $penNameId: Int
    $writerId: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getSummaryPenNameIncome(
      penNameId: $penNameId
      writerId: $writerId
      startDate: $startDate
      endDate: $endDate
    ) {
      penNameId
      penNameFirstPenName
      penNameTotalIncome
      writerId
    }
  }
`
