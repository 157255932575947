import { DropResult } from 'react-beautiful-dnd'
import { NavigateFunction } from 'react-router-dom'

import { MainBannerType } from '@models/mainBanner/MainBannerType'

export interface MainBannerPageProps {
  mainBannerList: MainBannerType[]
  selectedIds: number[]
  platformSelected: PlatformEnum
  tabSelected: TabEnum
  navigate: NavigateFunction
  handleDragItem: (result: DropResult) => void
  handleSelectItemAll: () => void
  handleSelectItem: (id: number) => void
  handleEnable: (value: MainBannerType) => void
  handlePublishMainBannerList: () => void
  handleUnpublishMainBannerList: () => void
  handledeleteMainBannerList: () => void
  handleDelete: (id: number) => void
  handlePlatformChange: (value: PlatformEnum) => void
  handleTabChange: (value: TabEnum) => void
}

export enum PlatformEnum {
  WEB = 'WEB',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export enum TabEnum {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  EXPIRED = 'Expired',
}
