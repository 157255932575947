import { useMemo } from 'react'
import { Table } from '@components/Table'
import { CardContainer } from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { NoData } from '@components/NoData'
import { PromotionListProps } from './interface'
import { columns } from './column'

export function PromotionList({
  data,
  isLoading,
  sort,
  handleSort,
  handleFetchNextPage,
}: PromotionListProps) {
  const bookPromotionDetail = useMemo(
    () =>
      data?.pages
        .map(page => page.data)
        .reduce((prev, curr) => prev.concat(curr), []) || [],
    [data]
  )
  return (
    <>
      <CardContainer>
        <h1 className='font-mitr text-2xl font-medium text-secondary mb-5'>
          โปรโมชั่น
        </h1>

        <Table
          onSort={handleSort}
          onWaypointEnter={handleFetchNextPage}
          className='mb-[30px] max-h-[400px]'
          fixedHeader
          isLoading={isLoading}
          data={bookPromotionDetail}
          columns={columns}
          initialSort={{ key: sort.key, order: sort.order }}
        />
      </CardContainer>
    </>
  )
}
