import * as Yup from 'yup'
import { FieldArray, Form, Formik } from 'formik'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { Container } from '@components/Container'
import { DragIcon } from '@components/Icons'
import { InputField } from '@components/forms/InputField'
import { MenuManagePageProps } from './interface'

const validationSchema = Yup.object().shape({
  menus: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
    })
  ),
})

function MenuManagePage({
  data,
  onSubmit,
  handleDragItem,
  addEditItem,
}: MenuManagePageProps) {
  return (
    <Container>
      <Formik
        initialValues={{
          menus: data,
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, resetForm }) => (
          <Form>
            <FieldArray name='menus'>
              {() => (
                <>
                  <div className='flex items-center justify-between w-full py-[20px] border-0'>
                    <h1 className='font-mitr text-[24px] text-secondary'>
                      Menu Management
                    </h1>
                    <div className='flex font-mitr gap-x-[10px] justify-center items-center px-[10px]'>
                      <button
                        type='button'
                        className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-black-480'
                        disabled={!dirty}
                        onClick={() => resetForm()}
                      >
                        <span>ยกเลิก</span>
                      </button>
                      <button
                        type='submit'
                        className='flex font-mitr w-[126px] items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-primary'
                        disabled={!dirty}
                      >
                        <span>บันทึก</span>
                      </button>
                    </div>
                  </div>
                  <div className='border-[1px] border-[#eee] mt-[20px]' />
                  <div className='flex flex-col w-full '>
                    <div className='flex items-center justify-between gap-y-[20px] w-full py-[20px] border-0'>
                      <div className='flex items-center'>
                        <label className='text-secondary ml-[10px]'>
                          ทั้งหมด
                          <span className='text-blue-3 font-bold ml-[10px]'>
                            ({values.menus.length || 0} รายการ)
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='border-[1px] border-[#eee]' />
                    <DragDropContext
                      onDragEnd={result => {
                        handleDragItem(result, values.menus, setFieldValue)
                      }}
                    >
                      <Droppable droppableId='list'>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className='flex flex-col gap-y-[20px] mt-[20px]'
                          >
                            {values.menus.map((item, index) => (
                              <Draggable
                                draggableId={`${item.id}`}
                                index={index}
                                key={item.id}
                              >
                                {providedDragable => (
                                  <div
                                    className='flex gap-5'
                                    ref={providedDragable.innerRef}
                                    {...providedDragable.draggableProps}
                                    {...providedDragable.dragHandleProps}
                                  >
                                    <div className='flex items-center justify-between gap-x-[30px] text-secondary'>
                                      <span className='text-blue-3 font-mitr font-medium text-2xl'>
                                        {index + 1}
                                      </span>
                                      <DragIcon
                                        width='10'
                                        className='cursor-grab'
                                      />
                                    </div>
                                    <div className='flex items-center justify-between gap-y-[20px] w-full border-0'>
                                      <div className='w-full pr-[30px]'>
                                        <InputField
                                          name={`menus[${index}].name`}
                                          type='text'
                                          className='disabled:bg-white'
                                          value={item.name}
                                          onClick={() => addEditItem(item)}
                                          placeholder='ชื่อหมวดหมู่'
                                          showErrorMsg={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div className='flex justify-center gap-x-[20px] mt-[30px]'>
                      <button
                        type='button'
                        className='flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-blue-3 border border-blue-3 w-[100px]'
                        disabled={!dirty}
                        onClick={() => resetForm()}
                      >
                        <span>ยกเลิก</span>
                      </button>
                      <button
                        type='submit'
                        className='flex font-mitr w-[100px] items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30 text-white bg-primary'
                        disabled={!dirty}
                      >
                        <span>บันทึก</span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default MenuManagePage
