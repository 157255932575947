import { SortByType } from '@components/Table/interface'
import { HighestCharacterDonateResponse } from '@models/salse/HighestCharacterDonateReponse'
import { PaymentReportResponse } from '@models/salse/PaymentReportResponse'
import { PurchaseProportionType } from '@models/salse/PurchaseProportionType'
import { SalesReportOverviewType } from '@models/salse/SalesReportOverviewType'
import { TopBookType } from '@models/salse/TopBookType'
import { TopMoneySpenderType } from '@models/salse/TopMoneySpenderType'
import { TopWriterType } from '@models/salse/TopWriterType'
import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'
import { EXPORT_SALES_REPORTS } from './schemas/exportSalesReports'
import { GET_HIGHEST_CHARACTER_DONATE } from './schemas/getHighestCharacterDonate'
import { GET_HIGHEST_EARNING_WRITER } from './schemas/getHighestEarningWriter'
import { GET_HIGHEST_EARNING_WRITING } from './schemas/getHighestEarningWriting'
import { GET_PAYMENT_REPORTS } from './schemas/getPaymentsReports'
import { GET_PURCHASE_PROPORTION } from './schemas/getPurchaseProportion'
import { GET_SALES_REPORTS_OVERVIEW } from './schemas/getSalesReportsOverview'
import { GET_TOP_MONEY_SPENDER } from './schemas/getTopMoneySpender'
import { EXPORT_PAYMENT_REPORTS } from './schemas/exportPaymentReports'

export class SaleClient {
  constructor(private client: GraphQLClient) {}

  async getTopMoneySpender({
    searchParam,
    sort,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
    sort: SortByType
  }): Promise<TopMoneySpenderType[]> {
    const { getTopMoneySpender } = await this.client.request(
      GET_TOP_MONEY_SPENDER,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        orderBy: sort.key ? { [sort.key]: sort.order } : undefined,
      }
    )

    return plainToInstance(TopMoneySpenderType, getTopMoneySpender as [])
  }

  async getHighestEarningWriting({
    searchParam,
    sort,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
    sort: SortByType
  }): Promise<TopBookType[]> {
    const { getHighestEarningWriting } = await this.client.request(
      GET_HIGHEST_EARNING_WRITING,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        orderBy: sort.key ? { [sort.key]: sort.order } : undefined,
      }
    )

    return plainToInstance(TopBookType, getHighestEarningWriting as [])
  }

  async getHighestEarningWriter({
    searchParam,
    sort,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
    sort: SortByType
  }): Promise<TopWriterType[]> {
    const { getHighestEarningWriter } = await this.client.request(
      GET_HIGHEST_EARNING_WRITER,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        orderBy: sort.key ? { [sort.key]: sort.order } : undefined,
      }
    )

    return plainToInstance(TopWriterType, getHighestEarningWriter as [])
  }

  async getHighestCharacterDonate({
    searchParam,
    sort,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
    sort: SortByType
  }): Promise<HighestCharacterDonateResponse[]> {
    const { getHighestCharacterDonate } = await this.client.request(
      GET_HIGHEST_CHARACTER_DONATE,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        orderBy: sort.key ? { [sort.key]: sort.order } : undefined,
      }
    )

    return plainToInstance(
      HighestCharacterDonateResponse,
      getHighestCharacterDonate as []
    )
  }

  async getPurchaseProportion({
    searchParam,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
  }): Promise<PurchaseProportionType[]> {
    const { getPurchaseProportion } = await this.client.request(
      GET_PURCHASE_PROPORTION,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
      }
    )

    return plainToInstance(PurchaseProportionType, getPurchaseProportion as [])
  }

  async getSalesReportsOverview({
    searchParam,
  }: {
    searchParam: { startAt: DateTime; endAt: DateTime }
  }): Promise<SalesReportOverviewType> {
    const { getSalesReportsOverview } = await this.client.request(
      GET_SALES_REPORTS_OVERVIEW,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
      }
    )

    return plainToInstance(SalesReportOverviewType, getSalesReportsOverview)
  }

  async getPaymentsReports({
    writerId,
    monthNo,
    year,
    sort,
    page,
    limitPerPage,
  }: {
    writerId?: number
    monthNo?: number
    year?: number
    page: number
    sort?: SortByType
    limitPerPage: number
  }): Promise<PaymentReportResponse> {
    const { getPaymentsReports } = await this.client.request(
      GET_PAYMENT_REPORTS,
      {
        limitPerPage,
        page,
        year,
        monthNo,
        writerId,
        orderBy: sort?.key ? { [sort.key]: sort.order } : undefined,
      }
    )

    return plainToInstance(PaymentReportResponse, getPaymentsReports)
  }

  async exportSalesReports({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_SALES_REPORTS, { startDate, endDate })
  }

  async exportPaymentReports({
    paymentCycleMonth,
    paymentCycleYear,
    writerId,
  }: {
    paymentCycleMonth?: number
    paymentCycleYear?: number
    writerId?: number
  }) {
    await this.client.request(EXPORT_PAYMENT_REPORTS, {
      paymentCycleMonth,
      paymentCycleYear,
      writerId,
    })
  }
}
