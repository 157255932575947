import { Typography } from '../Typography'
import { OverviewProps } from './interface'

export function Overview({ data }: OverviewProps) {
  return (
    <div className='flex justify-between'>
      {data.map((item, index) => (
        <Typography key={index} label={item.name}>
          {item.value}
        </Typography>
      ))}
    </div>
  )
}
