import { withPermissionGuard } from '@libs/permission'
import { RolePermissionPage } from './RolePermissionPage'
import { withRolePermissionPage } from './withRolePermissionPage'

const ConnectedRolePermissionPage = withPermissionGuard(
  withRolePermissionPage(RolePermissionPage),
  'admin',
  'view'
)

export { ConnectedRolePermissionPage as RolePermissionPage }
