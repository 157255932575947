import { Link } from 'react-router-dom'
import { ColumnType } from '@components/Table/interface'
import { EyeIcon } from '@components/Icons'
import { HotWriterType } from '@models/dashboard/HotWriterType'

export const columns: ColumnType<HotWriterType>[] = [
  {
    column: 'User ID',
    accessor: 'userUserId',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
  },
  {
    column: 'Publisher',
    accessor: 'writerAffiliation',
    cell: ({ value }) => <p className='max-w-[150px] truncate'>{value}</p>,
    isSortable: true,
    sortKey: 'affiliation',
  },
  {
    column: 'Writer',
    accessor: 'userUsername',
    cell: ({ value }) => <p className='max-w-[150px] truncate'>{value}</p>,
    isSortable: true,
  },
  {
    column: 'Most viewed story',
    accessor: 'bookTitle',
    cell: ({ value }) => <p className='max-w-[400px] truncate'>{value}</p>,
    isSortable: true,
  },
  {
    column: 'View',
    accessor: 'userViewCount',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    isSortable: true,
  },
  {
    column: 'Action',
    cellClassName: 'flex items-center justify-end h-[44px]',
    actionCell: ({ obj }) => (
      <Link
        to={`/dashboard/writer-publisher?tab=byUser&userId=${obj.userUserId}&writerId=${obj.writerId}`}
      >
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </Link>
    ),
    isAction: true,
  },
]
