import { PromotionSaleTable } from '../PromotionSaleTable'
import { PromotionSearchForm } from '../PromotionSearchForm'
import { SelectedDateEnum } from '../PromotionSearchForm/interface'
import { PromotionSaleListProps } from './interface'

const dateRangeOptions = [
  { text: 'วันนี้', value: SelectedDateEnum.TO_DAY },
  { text: '7 วัน', value: SelectedDateEnum.SEVEN_DAY },
  { text: 'ตลอดแคมเปญ', value: SelectedDateEnum.THROUGHOUT_THE_CAMPAIGN },
]

export function PromotionSaleList({
  promotionSaleId,
  searchParams,
  handleSubmit,
  handleExport,
  handleReset,
  ...props
}: PromotionSaleListProps) {
  return (
    <div className='w-full mt-[30px]'>
      <PromotionSearchForm
        initialValues={searchParams}
        placeholder='ค้นหาด้วยชื่อ, User ID, Story ID, นามปากกา'
        dateRangeOptions={dateRangeOptions}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <PromotionSaleTable
        searchParams={searchParams}
        promotionSaleId={promotionSaleId}
        {...props}
      />
    </div>
  )
}
