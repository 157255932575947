import { useState } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { useAuthentication } from '@hooks/useAuthentication'
import { UserWritersParam } from '@models/writer/UserWritersParam'
import { UserWritersResponse } from '@models/writer/UserWritersResponse'
import { SearchUserStatusEnum } from '@interfaces/SearchUserStatusEnum'
import { SortingType } from '@components/Table/interface'

interface UserWritersProps {
  statusType: SearchUserStatusEnum
  searchText: string
  sortKey: string
  sortOrder: SortingType
  page: number
  handleTotalChange: (newTotal: number) => void
}

export function useUserWriter({
  statusType,
  searchText,
  sortKey,
  sortOrder,
  page,
  handleTotalChange,
}: UserWritersProps) {
  const client = useClient()
  const { isUserLoading, isAuthenticated } = useAuthentication()
  const [total, setTotal] = useState({
    all: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  })

  async function allTotalHandler(value: number) {
    const pendingTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.PENDING,
    })
    const approvedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.APPROVED,
    })
    const rejectedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.REJECTED,
    })
    setTotal({
      all: value,
      pending: pendingTotal || 0,
      approved: approvedTotal || 0,
      rejected: rejectedTotal || 0,
    })
  }

  async function pendigTotalHandler(value: number) {
    const allTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: null,
    })
    const approvedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.APPROVED,
    })
    const rejectedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.REJECTED,
    })
    setTotal({
      all: allTotal || 0,
      pending: value,
      approved: approvedTotal || 0,
      rejected: rejectedTotal || 0,
    })
  }

  async function approvedTotalHandler(value: number) {
    const allTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: null,
    })
    const pendingTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.PENDING,
    })
    const rejectedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.REJECTED,
    })
    setTotal({
      all: allTotal || 0,
      pending: pendingTotal || 0,
      approved: value,
      rejected: rejectedTotal || 0,
    })
  }

  async function rejectedTotalHandler(value: number) {
    const allTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: null,
    })
    const pendingTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.PENDING,
    })
    const approvedTotal = await client?.userWriterClient.getTotalUserWriters({
      searchText,
      statusType: SearchUserStatusEnum.APPROVED,
    })
    setTotal({
      all: allTotal || 0,
      pending: pendingTotal || 0,
      approved: approvedTotal || 0,
      rejected: value,
    })
  }
  const queryParams = [
    'user_writer',
    statusType,
    searchText,
    sortKey,
    sortOrder,
    page,
  ]
  const { data, isLoading } = useQuery(
    queryParams,
    () =>
      client?.userWriterClient
        .findUserWriters({ statusType, searchText, sortKey, sortOrder, page })
        .then((res: UserWritersResponse) => {
          if (statusType === SearchUserStatusEnum.ALL) {
            allTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.PENDING) {
            pendigTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.APPROVED) {
            approvedTotalHandler(res.total)
          }
          if (statusType === SearchUserStatusEnum.REJECTED) {
            rejectedTotalHandler(res.total)
          }
          handleTotalChange(res.total)

          return res.data
        }),
    { enabled: !isUserLoading && isAuthenticated }
  )

  return {
    data,
    total,
    isLoading: isLoading || isUserLoading,
  }
}
