import React, { useRef } from 'react'
import { useField } from 'formik'

import { ImageType } from '@interfaces/ImageType'
import { useAlert } from '@hooks/useAlert'
import {
  UploadStickerFieldAcceptProps,
  UploadStickerFieldProps,
} from './interface'

export function withUploadThumbnailField(
  Component: React.FC<UploadStickerFieldProps>
) {
  function WithUploadThumbnailField({
    fieldName,
    ...props
  }: UploadStickerFieldAcceptProps) {
    const { error: alertError } = useAlert()
    const [{ value }, { error, touched }, { setValue }] =
      useField<ImageType>(fieldName)
    const inputRef = useRef<HTMLInputElement>(null)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        const img = new Image()
        const url = URL.createObjectURL(files[0])
        img.src = url
        img.onload = () => {
          if (img.width === img.height) {
            setValue({
              url,
              blob: files[0],
            })
          } else {
            alertError('รูปภาพต้องมีขนาด 1:1')
          }
          URL.revokeObjectURL(url)
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: value?.url,
      isError: !!error && touched,
      onChange: handleChange,
      showUploadDialog,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithUploadThumbnailField
}
