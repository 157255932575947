import { Expose, Type } from 'class-transformer'
import { AgeConfirmType } from './AgeConfirmType'

export class AgeConfirmResponse {
  @Expose()
  @Type(() => AgeConfirmType)
  data: AgeConfirmType[]

  @Expose()
  total: number
}
