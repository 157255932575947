import { Button } from '@components/Button'
import { Pagination } from '@components/Pagination'
import { SelectDropdown } from '@components/SelectDropdown/SelectDropdown'
import { Table } from '@components/Table'
import { SortingType } from '@components/Table/interface'
import { TopicEnum } from '@interfaces/TopicEnum'
import { ReportListTableProps } from './interface'
import { reportTopicText } from './useBookReportsColumns'

const reportTypeOptions = [
  {
    label: reportTopicText[TopicEnum.ALL],
    value: TopicEnum.ALL,
  },
  {
    label: reportTopicText[TopicEnum.IMPOLITIC_CONTENT],
    value: TopicEnum.IMPOLITIC_CONTENT,
  },
  {
    label: reportTopicText[TopicEnum.PIRACY_CONTENT],
    value: TopicEnum.PIRACY_CONTENT,
  },
  {
    label: reportTopicText[TopicEnum.PIRACY_IMAGE],
    value: TopicEnum.PIRACY_IMAGE,
  },
  {
    label: reportTopicText[TopicEnum.RUDE_LANGUAGE],
    value: TopicEnum.RUDE_LANGUAGE,
  },
  {
    label: reportTopicText[TopicEnum.OTHER],
    value: TopicEnum.OTHER,
  },
]

export function ReportListTable({
  page,
  perpage,
  total,
  handlePageChange,
  handleSort,
  selectedId,
  selectedTopic,
  handleSelectTopic,
  handleUpdateStatusToSuccess,
  columns,
  data,
  isLoading,
}: ReportListTableProps) {
  return (
    <div>
      <div className='flex items-center justify-between border-b pb-5 mb-[10px]'>
        <div className='w-full max-w-[50%] flex items-center space-x-10'>
          <div className='font-light text-xs flex-shrink-0'>
            ผู้รายงาน : <span className='font-bold'>{total}</span> รายการ
          </div>
          <SelectDropdown
            value={selectedTopic}
            options={reportTypeOptions}
            handleSelect={handleSelectTopic}
            className='w-full max-w-[158px]'
            inputClassName='font-light text-secondary-100'
          />
        </div>
        {selectedId.length > 0 && (
          <Button
            className='text-white bg-primary'
            type='button'
            onClick={handleUpdateStatusToSuccess}
          >
            <span className='text-sm'>ดำเนินการสำเร็จ</span>
          </Button>
        )}
      </div>

      <Table
        columns={columns}
        data={data}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'createdAt',
          order: SortingType.DESC,
        }}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary  border-b-[1px]'
      />
      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={handlePageChange}
        />
      </div>
    </div>
  )
}
