import React, { useRef } from 'react'
import { UploadSquareImageProps, WithUploadSquareImageProps } from './interface'

export function withUploadSquareImage(
  Component: React.FC<UploadSquareImageProps>
) {
  function WithUploadSquareImage({
    title,
    size,
    src,
    isError,
    onChange,
  }: WithUploadSquareImageProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      onChange(event)
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }

    const componentProps = {
      inputRef,
      title,
      src,
      size,
      isError,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadSquareImage
}
