import { Expose, Type } from 'class-transformer'

import { CategoryType } from '@models/category/CategoryType'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PSUseCoinConditionTypeEnum } from '@interfaces/promotionSale/PSUseCoinConditionTypeEnum'
import { PSConditionTypeEnum } from '@interfaces/promotionSale/PSConditionTypeEnum'

class CoinType {
  @Expose() id: number

  @Expose() name: string
}

class BenefitCoinType {
  @Expose() name: string
}

class SummaryType {
  @Expose() totalBook: number

  @Expose() totalDeal: number

  @Expose() totalSale: number

  @Expose() totalSell: number

  @Expose() userCount: number
}

export class PromotionSaleType {
  @Expose() actionValue: number

  @Expose()
  @Type(() => SummaryType)
  benefitCoin: BenefitCoinType

  @Expose() benefitCoinId: number

  @Expose() benefitLimitType: PromotionChallengeLimitTypeEnum

  @Expose() benefitLimitValue: number

  @Expose() benefitValue: number

  @Expose() budget: number

  @Expose() budgetPerDay: number

  @Expose()
  @Type(() => CategoryType)
  categories: CategoryType[]

  @Expose()
  @Type(() => CoinType)
  coinsCondition: CoinType[]

  @Expose() conditionFileName: string

  @Expose() conditionType: PSConditionTypeEnum

  @Expose() id: number

  @Expose() termCount: number

  @Expose() useCoinConditionType: PSUseCoinConditionTypeEnum

  @Expose()
  @Type(() => SummaryType)
  summary: SummaryType
}
