import { ColumnType } from '@components/Table/interface'
import { formatDateTime } from '@libs/utils'
import { ReportLogType } from '@models/report/ReportLogType'
import { ReportPublishLogType } from '@models/report/ReportPublishLogType'
import ReportActivityAction from '../ReportActivityAction'

export const columns: ColumnType<ReportPublishLogType>[] = [
  {
    column: 'เวลา',
    accessor: 'createdAt',
    cell: ({ value }) => <p>{formatDateTime(value)}</p>,
    isSortable: false,
  },
  {
    column: 'ดำเนินการ',
    accessor: 'action',
    cell: ({ value }) => <ReportActivityAction action={value} />,
  },
  {
    column: '',
    accessor: 'bookReport',
    cell: ({ value }) => value?.code || '-',
  },
  {
    column: 'โดย',
    accessor: 'actionBy',
    cell: ({ value }) => (
      <div className='flex items-center gap-x-2'>
        {value?.avatarImgPath ? (
          <img
            className='w-[50px] h-[50px] rounded-full'
            src={value.avatarImgPath}
            alt='User avatar'
          />
        ) : (
          <div className='bg-gray w-[50px] h-[50px] object-cover rounded-full' />
        )}
        <div className='text-[14px] text-secondary font-bold'>
          {value?.displayName || 'ไม่ทราบชื่อ'}
        </div>
      </div>
    ),
    isSortable: false,
  },
]
