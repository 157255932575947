import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { client } from '@client/init'
import { SortByType } from '@components/Table/interface'
import { ChapterProps, WithChapterProps } from './interface'

export function withChapters(Component: React.FC<ChapterProps>) {
  function Hoc({ bookId }: WithChapterProps) {
    const [sort, setSort] = useState({
      orderKey: 'runningNo',
      orderBy: 'ASC',
    })

    function handleSort({ key, order }: SortByType) {
      setSort(prev => ({ ...prev, orderKey: key, orderBy: order }))
    }

    const {
      fetchNextPage,
      data: chapterData,
      isLoading,
      isFetchingNextPage,
    } = useInfiniteQuery(
      ['book-chapter-by-book', bookId, sort],
      ({ pageParam = 1 }) =>
        client?.storyClient.getBookChapterByBook({
          bookId: Number(bookId),
          page: pageParam,
          ...sort,
        }),
      {
        enabled: !!bookId,
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const newProps = {
      chapterData,
      bookId,
      handleSort,
      handleFetchNextPage,
      isLoading,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
