import { DropResult } from 'react-beautiful-dnd'
import { NavigateFunction } from 'react-router-dom'

import { HighlightBannerType } from '@models/highlightBanner/HighlightBannerType'

export interface HighlightBannerPageProps {
  data: HighlightBannerType[]
  selectedIds: number[]
  tabSelected: TabEnum
  navigate: NavigateFunction
  handleDragItem: (result: DropResult) => Promise<void>
  handleSelectItemAll: () => void
  handleSelectItem: (id: number) => void
  handleEnable: (value: HighlightBannerType) => Promise<void>
  handleDeleteBanner: (id: number) => void
  handleDeleteBannerList: () => void
  handlePublishHighlightBanner: () => Promise<void>
  handleUnpublishHighlightBanner: () => Promise<void>
  handleTabChange: (value: TabEnum) => void
}

export enum TabEnum {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  EXPIRED = 'Expired',
}
