import React, { useEffect } from 'react'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import { useAlert } from '@hooks/useAlert'
import IframeExtension from './components/IframeExtension'
import { TiptapAcceptProps, TiptapProps } from './interface'
import FootnoteExtension from './components/Footnote/FootnoteExtension'
import SpoilerExtension from './components/Spoiler/SpoilerExtension'
import FootnoteOrderedListExtension from './components/FootnoteOrderedList/FootnoteOrderedListExtension'
import { CustomOrderedListExtension } from './components/CustomOrderedList'
import { CustomListItemExtension } from './components/CustomListItem'

function withTiptap(Component: React.FC<TiptapProps>) {
  function Hoc({
    onChange,
    initialValue,
    editorRef,
    ...props
  }: TiptapAcceptProps) {
    const alert = useAlert()
    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Subscript,
        Superscript,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['paragraph'],
        }),
        Highlight.configure({ multicolor: true }),
        FootnoteExtension,
        FootnoteOrderedListExtension,
        SpoilerExtension,
        Link.configure({
          openOnClick: true,
        }),
        Image.configure({ inline: true }),
        IframeExtension,
        CustomOrderedListExtension,
        CustomListItemExtension,
      ],
      content: initialValue,
      onUpdate(event) {
        if (onChange) {
          onChange(event.editor.getHTML())
        }
      },
      editorProps: {
        transformPastedText(text) {
          return text.replaceAll(/\u00A0/g, ' ')
        },
        transformPastedHTML(html) {
          // eslint-disable-next-line no-useless-escape
          const transformHtml = html.replaceAll(/<img([\w\W]+?)[\/]*>/g, '')
          return transformHtml.replaceAll(/\u00A0/g, ' ')
        },
        handleDOMEvents: {
          drop: (view, event) => {
            event.preventDefault()
            return true
          },
        },
        handlePaste: (view, event, slice) => {
          const type = ['text', 'heading', 'paragraph']
          return !type.includes(slice.content.firstChild?.type.name || '')
        },
      },
    })

    const isMac =
      typeof window !== 'undefined'
        ? navigator?.platform.indexOf('Mac') > -1
        : false
    const isWin =
      typeof window !== 'undefined'
        ? navigator?.platform.indexOf('Win') > -1
        : false

    function pasteAlert() {
      if (isMac) {
        alert.warning(
          `Press ⌘+V to paste. Your browser doesn't suppport pasting with the toolbar button or context menu option`
        )
      } else if (isWin) {
        alert.warning(
          `Press Ctrl+V to paste. Your browser doesn't suppport pasting with the toolbar button or context menu option`
        )
      } else
        alert.warning(
          `Your browser doesn't suppport pasting with the toolbar button or context menu option`
        )
    }

    useEffect(() => {
      editor?.commands.setContent(initialValue || '<p></p>')
      if (onChange && editor) {
        onChange(editor.getHTML())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, editor])

    useEffect(() => {
      return () => {
        if (editor) {
          editor.destroy()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!editor) return null

    const newProps = {
      editorRef,
      editor,
      onChange,
      pasteAlert,
      isMac,
      isWin,
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withTiptap }
