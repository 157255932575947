// import { Tab, TabBody, TabHeader, Tabs } from '@components/Tabs'
import { TabModeEnum } from '@components/Tabs/interface'
import { Tabs } from '@components/TabsV2'
import { CoinHistoryTypeEnum } from '@interfaces/coin/CoinHistoryTypeEnum'
import { CoinHistoryTable } from '../CoinHistoryTable'
import { CoinHistorySelectTabProps } from './interface'

export function CoinHistorySelectTab({
  searchParam,
  activeTab,
  page,
  perpage,
  total,
  pageChange,
  handleTotalChange,
  onActiveTabChange,
  handleSort,
}: CoinHistorySelectTabProps) {
  return (
    <div className='mt-[45px]'>
      <Tabs
        tabs={[
          {
            key: CoinHistoryTypeEnum.ALL,
            title: 'ประวัติทั้งหมด',
            component: (
              <CoinHistoryTable
                key={CoinHistoryTypeEnum.ALL}
                searchParam={searchParam}
                activeTab={activeTab}
                page={page}
                perpage={perpage}
                total={total}
                pageChange={pageChange}
                handleTotalChange={handleTotalChange}
                handleSort={handleSort}
              />
            ),
          },
          {
            key: CoinHistoryTypeEnum.PURCHASE,
            title: 'ประวัติการจ่ายคอยน์',
            component: (
              <CoinHistoryTable
                key={CoinHistoryTypeEnum.PURCHASE}
                searchParam={searchParam}
                activeTab={activeTab}
                page={page}
                perpage={perpage}
                total={total}
                pageChange={pageChange}
                handleTotalChange={handleTotalChange}
                handleSort={handleSort}
              />
            ),
          },
          {
            key: CoinHistoryTypeEnum.REFILL,
            title: 'ประวัติการเติมคอยน์',
            component: (
              <CoinHistoryTable
                key={CoinHistoryTypeEnum.REFILL}
                searchParam={searchParam}
                activeTab={activeTab}
                page={page}
                perpage={perpage}
                total={total}
                pageChange={pageChange}
                handleTotalChange={handleTotalChange}
                handleSort={handleSort}
              />
            ),
          },
        ]}
        mode={TabModeEnum.BOX}
        initialTab={CoinHistoryTypeEnum.ALL}
        handleTabChange={onActiveTabChange}
      />
    </div>
  )
}
