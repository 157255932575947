import { gql } from 'graphql-request'

export const GET_PROMOTION_SALE_HISTORY_BY_ID = gql`
  query PromotionRequest($promotionRequestId: Float!) {
    promotionRequest(id: $promotionRequestId) {
      id
      status
      rejectedReason
      action
      createdAt
      updatedAt
      createdBy {
        id
        avatarImgPath
        displayName
      }
      approvedBy {
        id
        avatarImgPath
        displayName
      }
      correctionDetail {
        name {
          old
          new
        }
        description {
          old
          new
        }
        userTerm {
          old
          new
        }
        usageDay {
          old
          new
        }
        publishPeriod {
          startPublishedAt {
            old
            new
          }
          endPublishedAt {
            old
            new
          }
        }
        saleAction {
          actionValue {
            old
            new
          }
          coinsCondition {
            old
            new
          }
          useCoinConditionType {
            old
            new
          }
        }
        saleBenefit {
          benefitCoin {
            old
            new
          }
          benefitValue {
            old
            new
          }
        }
        saleBenefitLimit {
          benefitLimitType {
            old
            new
          }
          benefitLimitValue {
            old
            new
          }
        }
        saleBudget {
          budget {
            old
            new
          }
          budgetPerDay {
            old
            new
          }
        }
      }
      oldPromotion {
        status
      }
      newPromotion {
        id
        businessId
        name
        description
        userTerm
        startPublishedAt
        endPublishedAt
        publishedType
        status
        createdAt
        updatedAt
        usageDay
        promotionSale {
          id
          actionValue
          benefitLimitType
          benefitLimitValue
          benefitValue
          budget
          budgetPerDay
          categories {
            id
            name
          }
          coinsCondition {
            id
            name
          }
          conditionFileName
          conditionType
          termCount
          useCoinConditionType
          summary {
            coin {
              name
            }
          }
        }
      }
    }
  }
`
