import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { SessionHistoryDataType } from './SessionHistoryDataType'

export class SessionHistoryType {
  @Expose({ name: 'key' }) keyType: KeyTypeEnum

  @Expose()
  @Type(() => SessionHistoryDataType)
  @Transform(({ value, obj }) => {
    if (obj.key !== KeyTypeEnum.MONTH) return value

    if (obj.startDay > obj.endDay) {
      const index = value.findIndex(
        (row: SessionHistoryDataType) => row.value === obj.startDay
      )
      const firstArr = value.slice(index)
      const tailArr = value.slice(0, index)
      return firstArr.concat(tailArr)
    }

    return value
  })
  data: SessionHistoryDataType[]
}
