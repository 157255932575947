import { BookReadingType } from '@models/dashboard/BookReadingResponse'
import { ColumnType } from '@components/Table/interface'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'
import { abbreviateNumber } from '@libs/utils'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<BookReadingType>[] = [
  {
    column: 'ปก',
    accessor: 'coverImgPath',
    cell: ({ value }) =>
      value ? (
        <img className='w-[29px] h-[41px]' alt='cover' src={value} />
      ) : (
        <div className='w-[29px] h-[41px] bg-gray' />
      ),
    cellClassName: 'w-[60px] min-w-[60px] pr-[5px]',
    isSortable: false,
  },
  {
    column: 'เรื่อง',
    accessor: 'title',
    cell: ({ value }) => <p className='w-[130px] line-clamp-2'>{value}</p>,
    isSortable: true,
  },
  {
    column: 'ประเภท',
    accessor: 'bookType',
    cell: ({ value }) => <p>{bookTypeValue[value as BookEnum]}</p>,
    isSortable: true,
  },
  {
    column: 'Penname',
    accessor: 'firstPenName',
    sortKey: 'firstPenName',
    cell: ({ value }) => <p className='w-[130px] line-clamp-1'>{value}</p>,
    isSortable: true,
  },

  {
    column: 'จำนวนตอน',
    accessor: 'chapterCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[100px] max-w-[100px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'view',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'love',
    accessor: 'likeCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[80px] max-w-[80px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'อ่านไปแล้ว(ตอน)',
    accessor: 'chapterReadCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[120px] max-w-[120px] pr-[5px]',
    isSortable: true,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell bookId={obj.id} />,
    cellClassName: 'flex justify-end',
    isAction: true,
  },
]
