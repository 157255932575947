import { useState } from 'react'

export function usePagination(value: { perpage?: number; page?: number }) {
  const [page, setPage] = useState(value.page || 1)
  const [perpage, setPerpage] = useState(value.perpage || 10)
  const [total, setTotal] = useState(0)

  function pageChange(newPage: number) {
    setPage(newPage)
  }

  function perpageChange(newPerpage: number) {
    setPerpage(newPerpage)
  }

  function handleTotalChange(newTotal: number) {
    setTotal(newTotal)
  }

  return {
    page,
    perpage,
    total,
    pageChange,
    perpageChange,
    handleTotalChange,
  }
}
