import cn from 'classnames'
import { Link } from 'react-router-dom'

import { BanCoverIcon, ChatboxIcon, EyeIcon } from '@components/Icons'
import { BookChapterType } from '@models/story/BookChapterType'
import { SortingEnum } from '@interfaces/SortingEnum'
import { Box } from '../../../Box'
import { ChapterGroupProps } from './interface'
import SelectCoin from '../SelectCoin/SelectCoin'
import SelectStatus from '../SelectStatus/SelectStatus'

const coinOptions = [
  { label: 'ฟรี', value: 0 },
  { label: 'ตั้งราคา', value: undefined },
]

export function ChapterGroup({
  chapters,
  isManga,
  bookId,
  sorter,
  banCover,
}: ChapterGroupProps) {
  return (
    <div className='space-y-[10px]'>
      {chapters
        .sort((a, b) =>
          sorter === SortingEnum.ASC ? a.order - b.order : b.order - a.order
        )
        .map((item: BookChapterType) => (
          <div className='h-[110px] bg-white rounded-[8px] p-[20px] items-center shadow'>
            <div className='flex'>
              <div className='flex items-center shrink-0'>
                <Box.SubTitle className='min-w-[50px] text-secondary-100'>
                  {item.order.toLocaleString()}
                </Box.SubTitle>

                {isManga && (
                  <>
                    {item.chapterCoverImgPath ? (
                      <img
                        className='rounded-[8px] shrink-0 w-[70px] h-[70px] mr-[10px]'
                        src={item.chapterCoverImgPath}
                        alt='Chapter cover'
                      />
                    ) : (
                      <div className='rounded-[8px] shrink-0 w-[70px] h-[70px] mr-[10px] bg-gray' />
                    )}

                    <button
                      className='font-light text-[14px] text-primary border border-gray  bg-white rounded-lg p-[5px] mr-[20px]'
                      type='button'
                      onClick={() =>
                        banCover(item.id, item.chapterCoverImgPath)
                      }
                    >
                      <BanCoverIcon width='18' height='18' />
                    </button>
                  </>
                )}
              </div>
              <div className='w-full mr-[5px] border-l pl-[20px]'>
                <div className='text-secondary font-medium text-base font-mitr mb-[21px] line-clamp-1 break-all'>
                  {item.chapterTitle} {item.chapterName}
                </div>
                <div
                  className={cn('flex items-center space-x-[10px] font-light')}
                >
                  <div className='flex items-center space-x-[10px] w-[129px]'>
                    <EyeIcon width='24' height='24' className='text-primary' />
                    <span className='text-secondary-100 text-base'>
                      {item.viewCount.toLocaleString()}
                    </span>
                  </div>
                  <div className='flex items-center space-x-[5px] w-[129px]'>
                    <ChatboxIcon
                      width='24'
                      height='24'
                      className='text-primary'
                    />
                    <span className='text-secondary-100 text-base'>
                      {item.commentCount.toLocaleString()}
                    </span>
                  </div>
                  <div className='flex items-center space-x-[5px] w-[129px]'>
                    <img
                      className='w-[17px] h-[18px] shrink-0'
                      src='/images/gold-coin.webp'
                      width='100%'
                      height='100%'
                      alt='coin'
                    />

                    <span className='text-secondary-100 text-base'>
                      {item.totalSales.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
              <div className='grid gap-y-[10px] justify-end col-span-2 max-w-[270px]'>
                <SelectStatus
                  id={item.id}
                  value={item.status}
                  publishedAt={item.publishedAt}
                  showPublishedAt={item.showPublishedAt}
                  handleSelect={() => {}}
                  disabled
                />
                <div className='flex space-x-[10px]'>
                  <SelectCoin
                    options={coinOptions}
                    value={item.price}
                    handleSelect={() => {}}
                    disabled
                  />

                  <Link to={`/preview/${bookId}/chapter/${item.id}`}>
                    <button
                      className='flex bg-white items-center justify-center disabled:opacity-30 border border-gray-3 rounded-lg min-w-[30px] h-[30px] text-secondary-100'
                      type='button'
                    >
                      <EyeIcon width='20' height='20' />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
