import { gql } from 'graphql-request'

export const EXPORT_EBOOK_BY_USER_DASHBOARD = gql`
  query ExportEbookByUserDashboard(
    $startDate: DateTime!
    $endDate: DateTime!
    $userId: Int!
  ) {
    exportEbookByUserDashboard(
      startDate: $startDate
      endDate: $endDate
      userId: $userId
    )
  }
`
