import cn from 'classnames'

import { Button } from '@components/Button'
import { Container } from '@components/Container'
import { EbookIcon } from '@components/Icons'
import { SelectButton } from '@components/SelectButton'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PopupBannerLayoutProps } from './interface'

export function PopupBannerLayout({
  children,
  tab,
  popupBannerTabOption,
  handleChangeTab,
  navigate,
}: PopupBannerLayoutProps) {
  const permission = usePermissionAction('homePageManagement')
  return (
    <Container>
      <div className='flex items-center justify-between mb-[30px]'>
        <h1 className='font-mitr text-[24px] text-secondary'>Pop-up Banner</h1>
        <Button
          type='button'
          className={cn('text-[14px] text-white bg-secondary', {
            hidden: !permission.create,
          })}
          icon={<EbookIcon width='24' height='24' />}
          onClick={() => navigate({ pathname: '/home-page/pop-up/create' })}
        >
          เพิ่ม Pop-up Banner
        </Button>
      </div>
      <SelectButton
        options={popupBannerTabOption}
        value={tab}
        className='flex-wrap gap-y-[15px] mb-[20px]'
        onChange={handleChangeTab}
      />
      <div>{children}</div>
    </Container>
  )
}
