import { Expose, Type } from 'class-transformer'
import { CategoryCoverType } from './CategoryCoverType'

export class CategoryType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  isNovel: boolean

  @Expose()
  isManga: boolean

  @Expose()
  @Type(() => CategoryCoverType)
  categoryCovers: CategoryCoverType[]
}
