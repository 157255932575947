import { ColumnType } from '@components/Table/interface'
import { CharacterDonationBySpender } from '@models/character/CharacterDonationBySpender'
import { abbreviateNumber } from '@libs/utils'
import { CharacterDonationBySpenderSortEnum } from '@interfaces/character/CharacterDonationBySpenderSortEnum'
import { ActionCell } from './ActionCell'

export const columns: ColumnType<CharacterDonationBySpender>[] = [
  {
    column: 'User ID',
    accessor: 'userId',
    cell: ({ value }) => <p>{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationBySpenderSortEnum.USER_ID,
  },
  {
    column: 'Username',
    accessor: 'username',
    cell: ({ value }) => <p>@{value}</p>,
    isSortable: true,
    sortKey: CharacterDonationBySpenderSortEnum.USERNAME,
  },
  {
    column: 'Coin Spend',
    accessor: 'coinSpend',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    isSortable: true,
    sortKey: CharacterDonationBySpenderSortEnum.COINS_SPEND,
  },
  {
    column: 'Action',
    actionCell: ({ obj }) => <ActionCell userId={obj.userId} />,
    cellClassName: 'flex justify-end py-[10px]',
    isAction: true,
  },
]
