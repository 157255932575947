import { Expose, Type } from 'class-transformer'
import { ReportType } from './ReportType'

export class ReportListType {
  @Expose() total: number

  @Expose() page: number

  @Expose()
  @Type(() => ReportType)
  data: ReportType[]
}
