import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { capitalizeFirstLetter } from '@libs/utils'
import {
  ChapterType,
  PCLPenNameType,
  SocialPlatformType,
} from '@models/promotion/challenge/PromotionChallengeLogType'

export const actionColums = {
  [PromotionChallengeActionEnum.LOVE]: [
    {
      column: 'เรื่องที่กดหัวใจ',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                style={{
                  height:
                    c.chapters?.length > 1 ? c.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'เรื่อง,ตอน/ครั้ง',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                key={c.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.chapters?.map(chapter => (
                  <div
                    key={chapter.value}
                    className='flex pb-[10px] last:pb-0 gap-x-[5px] '
                  >
                    <div className='max-w-[120px] truncate'>
                      {chapter.value}
                    </div>
                    <div>/ {chapter.count}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
  ],
  [PromotionChallengeActionEnum.COMMENT]: [
    {
      column: 'เรื่องที่ Comment',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                style={{
                  height:
                    c.chapters?.length > 1 ? c.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'เรื่อง,ตอน/ครั้ง',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                key={c.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.chapters?.map(chapter => (
                  <div
                    key={chapter.value}
                    className='flex pb-[10px] last:pb-0 gap-x-[5px] '
                  >
                    <div className='max-w-[120px] truncate'>
                      {chapter.value}
                    </div>
                    <div>/ {chapter.count}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
  ],
  [PromotionChallengeActionEnum.SHARE]: [
    {
      column: 'แชร์เรื่อง',
      accessor: 'socialPlatforms',
      cell: ({ value }: { value: SocialPlatformType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(s => (
              <div
                style={{
                  height:
                    s.socialPlatforms?.length > 1
                      ? s.socialPlatforms.length * 31.5
                      : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {s.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'แชร์ไปที่/ครั้ง',
      accessor: 'socialPlatforms',
      cell: ({ value }: { value: SocialPlatformType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(s => (
              <div
                key={s.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {s.socialPlatforms?.map(platform => (
                  <div
                    key={platform.value}
                    className='truncate pb-[10px] last:pb-0 '
                  >
                    {`${capitalizeFirstLetter(String(platform.value))} / ${
                      platform.count
                    }`}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
  ],
  [PromotionChallengeActionEnum.DONATE]: [
    {
      column: 'เรื่องที่โดเนทตัวละคร',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                style={{
                  height:
                    c.characters?.length > 1
                      ? c.characters.length * 31.5
                      : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'ตัวละคร/มูลค่าเหรียญ',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                key={c.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.characters?.map(character => (
                  <div
                    key={character.value}
                    className='flex pb-[10px] last:pb-0 gap-x-[5px] '
                  >
                    <div className='max-w-[120px] truncate'>
                      {character.value}
                    </div>
                    <div>/ {character.count}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
  ],
  [PromotionChallengeActionEnum.FINISH_PROFILE]: [{}],
  [PromotionChallengeActionEnum.READ]: [
    {
      column: 'เรื่องที่อ่าน',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                style={{
                  height:
                    c.chapters?.length > 1 ? c.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'เรื่อง,ตอน/ครั้ง',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                key={c.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.chapters?.map(chapter => (
                  <div
                    key={chapter.value}
                    className='flex pb-[10px] last:pb-0 gap-x-[5px] '
                  >
                    <div className='max-w-[120px] truncate'>
                      {chapter.value}
                    </div>
                    <div>/ {chapter.count}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
  ],
  [PromotionChallengeActionEnum.UNLOCK_CHAPTER]: [
    {
      column: 'เรื่องที่ใช้เหรียญ',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                style={{
                  height:
                    c.chapters?.length > 1 ? c.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'ตอน/มูลค่าเหรียญ',
      accessor: 'chapters',
      cell: ({ value }: { value: ChapterType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(c => (
              <div
                key={c.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {c.chapters?.map(chapter => (
                  <div
                    key={chapter.value}
                    className='flex pb-[10px] last:pb-0 gap-x-[5px] '
                  >
                    <div className='max-w-[120px] truncate'>
                      {chapter.value}
                    </div>
                    <div>/ {chapter.count}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r',
    },
  ],
  [PromotionChallengeActionEnum.ALL]: [{}],
  [PromotionChallengeActionEnum.SET_PRICE_CHAPTER]: [
    {
      column: 'นามปากกา',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                style={{
                  height:
                    p.chapters?.length > 1 ? p.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'จำนวนเหรียญที่ขายได้',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                style={{
                  height:
                    p.chapters?.length > 1 ? p.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.sales}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-r max-w-[150px]',
    },
    {
      column: 'จากเรื่อง/ตอน/จำนวนเหรียญ',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                key={p.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.chapters?.map(chapter => (
                  <div key={chapter.bookName} className='flex gap-x-[5px] '>
                    <div className='max-w-[120px] truncate text-secondary-100'>
                      {chapter.bookName}
                    </div>
                    <div className='max-w-[100px] truncate text-secondary-100'>
                      / {chapter.chapterName}
                    </div>
                    <div>
                      <span className='text-secondary-100'>/ </span>
                      {chapter.coinValue} เหรียญ
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-r',
    },
  ],
  [PromotionChallengeActionEnum.CREATE_BOOK]: [
    {
      column: 'นามปากกา',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                style={{
                  height:
                    p.chapters?.length > 1 ? p.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.name}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-l border-r max-w-[150px]',
    },
    {
      column: 'จำนวนเหรียญที่ขายได้',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                style={{
                  height:
                    p.chapters?.length > 1 ? p.chapters.length * 31.5 : '100%',
                }}
                className='w-full truncate border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.sales}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-r max-w-[150px]',
    },
    {
      column: 'จากเรื่อง/ตอน/จำนวนเหรียญ',
      accessor: 'penNames',
      cell: ({ value }: { value: PCLPenNameType[] }) => {
        return (
          <div className='py-[10px]'>
            {value?.map(p => (
              <div
                key={p.name}
                className='w-full border-b last:border-none px-[10px] mb-[10px] pb-[10px] last:mb-0 last:pb-0'
              >
                {p.chapters?.map(chapter => (
                  <div key={chapter.bookName} className='flex gap-x-[5px] '>
                    <div className='max-w-[120px] truncate text-secondary-100'>
                      {chapter.bookName}
                    </div>
                    <div className='max-w-[100px] truncate text-secondary-100'>
                      / {chapter.chapterName}
                    </div>
                    <div>
                      <span className='text-secondary-100'>/ </span>
                      {chapter.coinValue} เหรียญ
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )
      },
      cellClassName: '!py-0 border-r',
    },
  ],
}
