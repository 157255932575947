import { gql } from 'graphql-request'

export const GET_WRITER_OVER_VIEW = gql`
  query DeviceCategory($startDate: DateTime, $endDate: DateTime) {
    getWriterPublisherOverview(startDate: $startDate, endDate: $endDate) {
      totalWriter
      newWriter
      summaryWriterUsage
      accumulateWriter
      deviceCategory {
        name
        total
      }
      bookCategories {
        name
        category
        total
      }
      writerAgeCategories {
        ageMoreThanFortyOne
        ageBetweenThirtyOneAndForty
        ageBetweenTwentyOneAndThirty
        ageBetweenSixteenAndTwenty
        ageBelowFifteen
        otherAge
      }
      writerGenderCategories {
        female
        male
        other
      }
    }
  }
`
