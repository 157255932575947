export enum TimeRangeEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  THREE_MONTH = 'THREE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
  YEAR = 'YEAR',
}

export const timeRangeValue = {
  [TimeRangeEnum.WEEK]: 6,
  [TimeRangeEnum.MONTH]: 29,
  [TimeRangeEnum.THREE_MONTH]: 89,
  [TimeRangeEnum.SIX_MONTH]: 179,
  [TimeRangeEnum.YEAR]: 364,
}
