import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function withPreviewStoryPage(
  Component: React.FC<FunctionComponentType>
) {
  function WithPreviewStoryPage() {
    return <Component />
  }

  return WithPreviewStoryPage
}
