import cn from 'classnames'
import { ByTitlePage } from './components/ByTitlePage'
import { OverallPage } from './components/OverallPage'
import { StoryPageProps } from './interface'

export function StoryPage({ tabActive, handleSelectTab }: StoryPageProps) {
  const menusArray = [
    {
      value: 'overall',
      text: 'Overall',
    },
    {
      value: 'byTitle',
      text: 'By Title',
    },
  ]
  return (
    <>
      <div
        className={cn(
          'text-[14px] text-secondary font-light gap-x-[15px] mb-5 flex border-b border-gray'
        )}
      >
        {menusArray.map(item => (
          <button
            key={item.value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  item.value === tabActive,
                'text-secondary-100 font-light': item.value !== tabActive,
              }
            )}
            onClick={() => handleSelectTab(item.value)}
          >
            {item.text}
          </button>
        ))}
      </div>
      {tabActive === 'overall' && <OverallPage />}
      {tabActive === 'byTitle' && <ByTitlePage />}
    </>
  )
}
