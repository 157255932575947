import { withPermissionGuard } from '@libs/permission'
import { CoinCreatePage } from './CoinCreatePage'
import { withCoinCreatePage } from './withCoinCreatePage'

const ConnectedCoinCreatePage = withPermissionGuard(
  withCoinCreatePage(CoinCreatePage),
  'coin',
  'create'
)

export { ConnectedCoinCreatePage as CoinCreatePage }
