import { PieChart } from '@components/chart/PieChart'
import { ChartOptionType } from '@interfaces/ChartOptionType'

export function ReadersByGender({
  readerGender,
}: {
  readerGender: ChartOptionType[]
}) {
  return (
    <div className='w-full'>
      <PieChart
        title='ผู้อ่านตามเพศ'
        data={readerGender}
        legendStyle='bottom'
        style={{ height: '390px', width: '100%' }}
      />
    </div>
  )
}
