import { withPermissionGuard } from '@libs/permission'
import { TopViewPage } from './TopViewPage'
import { withTopViewPage } from './withTopViewPage'

const ConnectedTopViewPage = withPermissionGuard(
  withTopViewPage(TopViewPage),
  'homePageManagement',
  'view'
)

export { ConnectedTopViewPage as TopViewPage }
