import { Button } from '@components/Button'
import { BookSupporterType } from '@models/book/BookSupporterType'
import { PayRankProps } from './interface'
import { Box } from '../Box'
import { UserRank } from './components/UserRank'

function PayRank({ supporters, handleViewAll }: PayRankProps) {
  return (
    <Box className='mt-5'>
      <Box.Title className='text-2xl'>10 อันดับสายเปย์</Box.Title>

      <div className='grid grid-cols-10 my-5'>
        {supporters.map((item: BookSupporterType, index: number) => (
          <div className='relative flex flex-col items-center' key={item.id}>
            <UserRank
              avatarImgPath={item.avatarImgPath || '/images/avatar.png'}
              rank={index + 1}
            />
            <p className='font-bold text-sm text-secondary mt-[10px] max-w-[60px] truncate'>
              {item.displayName}
            </p>
            <span className='font-light text-xs text-secondary-100 mt-[5px] max-w-[60px] truncate'>
              @{item.username}
            </span>
          </div>
        ))}
      </div>

      <div className='flex justify-center mt-[30px]'>
        <Button
          type='button'
          className='border border-secondary'
          onClick={handleViewAll}
        >
          <span className='font-mitr text-secondary text-sm font-medium'>
            ดูผู้สนับสนุนทั้งหมด
          </span>
        </Button>
      </div>
    </Box>
  )
}

export { PayRank }
