import { NoData } from '@components/NoData'
import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'
import { columns } from './column'
import { TableDetailProps } from './interface'

export function TableDetail({
  data,
  initialSort,
  isLoading,
  page,
  perpage,
  total,
  pageChange,
  handleSort,
}: TableDetailProps) {
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        onSort={handleSort}
        initialSort={initialSort}
      />
      <div className='mt-[21px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
