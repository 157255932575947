import { EyeIcon } from '@components/Icons'
import { useNavigate } from 'react-router-dom'

export function ActionCell({ id }: { id: number }) {
  const navigate = useNavigate()
  return (
    <div className='flex gap-x-[10px]'>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
        onClick={() => {
          navigate({ pathname: `/chapter-book/report-list/${id}` })
        }}
      >
        <EyeIcon className='text-secondary-100' width='18' height='18' />
      </button>
    </div>
  )
}
