import React from 'react'
import { useQuery } from 'react-query'
import { BookEnum } from '@interfaces/BookEnum'
import { BookIcon, CartoonIcon } from '@components/Icons'
import { useClient } from '@hooks/useClient'
import { WithTopRankFilterProps, TopRankFilterProps } from './interface'

const storyTypeOptions = [
  {
    text: 'นิยายรายตอน',
    value: BookEnum.NOVEL,
    icon: <BookIcon />,
  },
  { text: 'การ์ตูน', value: BookEnum.MANGA, icon: <CartoonIcon /> },
]

export default function withTopRankFilter(
  Component: React.FC<TopRankFilterProps>
) {
  function Hoc({ storyType, ...props }: WithTopRankFilterProps) {
    const client = useClient()
    const { data: categories = [] } = useQuery(
      ['category-options', storyType],
      () =>
        client?.categoryClient.getCategoryOptions([storyType]).then(res =>
          res.map(row => ({
            ...row,
            value: String(row.value),
          }))
        )
    )
    const newProps = {
      storyTypeOptions,
      categoryOptions: [{ text: 'ทุกหมวดหมู่', value: '' }, ...categories],
      storyType,
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
