import { Expose } from 'class-transformer'
import { number } from 'yup/lib/locale'

export class ChartDataType {
  @Expose()
  xValue: string | number

  @Expose()
  yValue: number
}
