import { withPermissionGuard } from '@libs/permission'
import { AddNewRolePage } from './AddNewRolePage'
import { withAddNewRolePage } from './withAddNewRolePage'

const ConnectedAddNewRolePage = withPermissionGuard(
  withAddNewRolePage(AddNewRolePage),
  'admin',
  'create'
)

export { ConnectedAddNewRolePage as AddNewRolePage }
