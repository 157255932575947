import { withPermissionGuard } from '@libs/permission'
import { PromotionCodeTransaction } from './PromotionCodeTransaction'
import { withPromotionCodeTransaction } from './withPromotionCodeTransaction'

const Connected = withPermissionGuard(
  withPromotionCodeTransaction(PromotionCodeTransaction),
  'promotionSetting',
  'view'
)

export { Connected as PromotionCodeTransaction }
