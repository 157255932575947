import React from 'react'

import { Container } from '@components/Container'
import { MainBannerForm } from '@features/home/components/MainBannerForm'
import { CreateMainBannerPageProps } from './interface'

export function CreateMainBannerPage({
  initialValues,
  onSubmit,
}: CreateMainBannerPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between'>
        <Container.Title showBackBtn>Create Banner</Container.Title>
      </section>

      <MainBannerForm initialValues={initialValues} onSubmit={onSubmit} />
    </Container>
  )
}
