import { gql } from 'graphql-request'

export const GET_SUPPORTERS = gql`
  query GetSupporters($bookId: Int!, $limitPerPage: Int, $page: Int) {
    getSupporters(bookId: $bookId, limitPerPage: $limitPerPage, page: $page) {
      data {
        id
        avatarImgPath
        displayName
        username
        profilePageSlug
      }
      page
    }
  }
`
