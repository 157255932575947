import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useClient } from '@hooks/useClient'
import { NewsAndPromotionFormType } from '@models/newsAndPromotion/NewsAndPromotionFormType'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { NewsAndPromotionDetailPageProps } from './interface'

export function withNewsAndPromotionDetailPage(
  Component: React.FC<NewsAndPromotionDetailPageProps>
) {
  function WithNewsAndPromotionDetailPage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const params = useParams()
    const id = Number(params.id)
    const newsLogsModal = useModal({ modal: 'adminRevisionHistoryLogs' })

    const { data, isFetching } = useQuery(['news-and-promotion', { id }], () =>
      client?.newsClient.findOneNews(id)
    )

    async function handleSubmit(form: NewsAndPromotionFormType) {
      try {
        await client?.newsClient.updateNewsAndPromotion(form)
        alert.success('บันทึกข้อมูลสำเร็จ')
        navigate('/news-promotion')
      } catch (error) {
        alert.error(getErrorMessage(error))
      }
    }

    function handleOpenNewsLogs() {
      newsLogsModal.show({
        title: 'ประวัติการแก้ไข',
        datas: data?.newsLogs,
      })
    }

    const updatedBy = useMemo(() => {
      return data?.newsLogs?.[0]?.user
    }, [data])

    if (isFetching || !data) {
      return null
    }

    const newProps = {
      initialValues: data,
      updatedBy,
      handleSubmit,
      handleOpenNewsLogs,
    }

    return <Component {...newProps} />
  }
  return WithNewsAndPromotionDetailPage
}
