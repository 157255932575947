import { gql } from 'graphql-request'

export const CATEGORY = gql`
  query Category($categoryId: Int!) {
    category(id: $categoryId) {
      id
      name
      categoryCovers {
        id
        imgPath
        coverName
      }
    }
  }
`
