import { SalesByUserTableProps } from './interface'

export function withSalesByUserTable(
  Component: React.FC<SalesByUserTableProps>
) {
  function WithSalesByUserTable(props: SalesByUserTableProps) {
    return <Component {...props} />
  }

  return WithSalesByUserTable
}
