import { createContext, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { WriterRequestType } from '@models/writer/WriterRequestType'
import { useClient } from '@hooks/useClient'
import { WriterEnum } from '@interfaces/WriterEnum'
import { useAlert } from '@hooks/useAlert'

interface WriterDetailContextType {
  writer?: WriterRequestType
  isNumberHasBan?: boolean
  handleValidateNumber: () => void
}

const WriterDetailContext = createContext<WriterDetailContextType>({
  writer: undefined,
  isNumberHasBan: undefined,
  handleValidateNumber: () => {},
})

export function WriterDetailProvider({ children }: FunctionComponentType) {
  const client = useClient()
  const alert = useAlert()
  const { id } = useParams()
  const [isNumberHasBan, setIsNumberHasBan] = useState<boolean | undefined>()
  const { data: writer } = useQuery(['writer_request', id], () =>
    client?.userWriterClient.findWriterRequestById(Number(id))
  )

  async function handleValidateNumber() {
    if (writer) {
      try {
        await client?.userWriterClient
          .verifyNumberHasBan(
            writer.type === WriterEnum.INDIVIDUAL
              ? writer.idNumber
              : writer.taxId
          )
          .then(res => setIsNumberHasBan(res))
      } catch (_) {
        alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ')
      }
    }
  }

  const value = {
    writer,
    isNumberHasBan,
    handleValidateNumber,
  }

  return (
    <WriterDetailContext.Provider value={value}>
      {children}
    </WriterDetailContext.Provider>
  )
}

export function useWriterDetail() {
  return useContext(WriterDetailContext)
}
