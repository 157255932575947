import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { SelectDropdown } from '@components/SelectDropdown/SelectDropdown'
import { Box } from '@components/Box'
import { useTable } from '@hooks/useTable'
import { InviteListType } from '@models/admin/InviteListType'
import { ColumnType } from '@components/Table/interface'
import { Checkbox } from '@components/Checkbox'
import { BinIcon } from '@components/Icons'
import { InviteEmailForm } from './components/InviteEmailForm'
import { AddAdminPageProps } from './interface'

export function AddAdminPage({
  navigate,
  inviteList,
  createAdminHandler,
  validateAdminEmail,
  selectedEmail,
  handleSort,
  roleOption,
  handleSelectAll,
  deleteSelectEmail,
  handlerDelete,
  handleSelect,
  listOptionChange,
  isLoading,
  clearInviteList,
}: AddAdminPageProps) {
  const columns: ColumnType<InviteListType>[] = [
    {
      column: (
        <Checkbox
          defaultChecked={
            selectedEmail.length === inviteList.length &&
            selectedEmail.length > 0
          }
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            handleSelectAll()
          }}
        />
      ),
      accessor: 'email',
      cell: ({ value }) => (
        <Checkbox
          defaultChecked={selectedEmail.includes(value)}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            handleSelect(value)
          }}
        />
      ),
      isSortable: false,
      cellClassName: 'max-w-[150px] w-[150px] pr-[10px]',
    },
    {
      column: 'อีเมล',
      accessor: 'email',
      cell: ({ value }) => (
        <p className='max-w-[500px] truncate font-mitr'>{value}</p>
      ),
      isSortable: true,
      cellClassName: 'pr-[20px]',
    },
    {
      column: 'Role',
      accessor: 'rolesId',
      cell: ({ value, i }) => (
        <SelectDropdown
          options={roleOption}
          defualtInputColor='bg-blue-2 border-none'
          value={value}
          className='w-[232px]'
          inputClassName='font-light'
          handleSelect={item => listOptionChange(item, i!)}
        />
      ),
      isSortable: true,
      cellClassName: 'max-w-[500px] w-[500px] py-[9.5px] pr-[30px]',
    },
    {
      column: 'Action',
      accessor: 'email',
      cell: ({ value }) => (
        <button
          type='button'
          onClick={() => handlerDelete(value)}
          className='flex items-center ml-auto justify-center text-secondary border bg-white border-gray rounded-lg w-[30px] h-[30px]'
        >
          <BinIcon width='20' height='20' className='text-secondary-100' />
        </button>
      ),
      isSortable: false,
      cellClassName: 'max-w-[40px] w-[40px]  ',
    },
  ]
  const { Table, resetSortBy } = useTable({ data: inviteList, columns })
  return (
    <Container>
      <Container.Title showBackBtn>เพิ่ม Admin</Container.Title>
      <InviteEmailForm
        onSubmit={value => {
          validateAdminEmail(value)
          resetSortBy()
        }}
        inviteList={inviteList}
      />
      <Box className='mt-[20px] shadow-none !border-gray'>
        <div className='flex justify-between mb-[30px]'>
          <div className='text-[18px] font-bold text-secondary'>
            รายการส่งคำเชิญ
          </div>
          <button
            type='button'
            className='rounded-[20px] w-[104px] py-[5px] text-sm font-medium font-mitr text-red bg-[#feeeef] disabled:opacity-30'
            disabled={selectedEmail.length === 0}
            onClick={deleteSelectEmail}
          >
            ลบคำเชิญ
          </button>
        </div>
        <Table
          onSort={handleSort}
          bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary border-b-[1px]'
        />
      </Box>

      <div className='flex justify-center gap-x-[20px] mt-[30px]'>
        <Button
          onClick={clearInviteList}
          className='border border-secondary-100 text-secondary-100 text-[14px] w-[100px]'
        >
          ยกเลิก
        </Button>
        <Button
          onClick={createAdminHandler}
          className='text-white bg-primary w-[100px] text-[14px]'
          disabled={inviteList.length === 0 || isLoading}
        >
          บันทึก
        </Button>
      </div>
    </Container>
  )
}
