import { Box } from '@components/Box'
import { SearchSection } from '@features/dashboard/components/SearchSection'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { CharacterSection } from './components/CharacterSection'
import { SpenderSection } from './components/SpenderSection'
import { StorySection } from './components/StorySection'
import { WriterSection } from './components/WriterSection'
import { DonationPageProps } from './interface'
import { CategorySection } from './components/CategorySection'
import { TotalDonationSection } from './components/TotalDonationSection'

export function DonationPage({
  searchParams,
  handleSearch,
  handleExport,
  handleReset,
}: DonationPageProps) {
  return (
    <>
      <SearchSection
        initialValues={{
          startDate: undefined,
          endDate: undefined,
          interval: TimeRangeEnum.MONTH,
        }}
        handleSubmit={handleSearch}
        handleExport={handleExport}
        handleReset={handleReset}
      />
      <Box>
        <TotalDonationSection searchParams={searchParams} />
        <hr className='border border-gray h-[1px] my-[30px] w-full' />
        <CategorySection searchParams={searchParams} />
        <StorySection searchParams={searchParams} />
        <WriterSection searchParams={searchParams} />
        <CharacterSection searchParams={searchParams} />
        <SpenderSection searchParams={searchParams} />
      </Box>
    </>
  )
}
