import React, { useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { InfiniteData, useInfiniteQuery, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'

import { client, queryClient } from '@client/init'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import { PopupBannerListType } from '@models/popupBanner/PopupBannerListType'
import { PopupBannerListResponse } from '@models/popupBanner/PopupBannerListResponse'
import {
  PopupActionEnum,
  PopupBannerManagementPageProps,
  PopupScreenEnum,
  PopupStatusEnum,
} from './interface'

export function withPopupBannerManagementPage(
  Component: React.FC<PopupBannerManagementPageProps>
) {
  function WithPopupBannerManagementPage() {
    const alert = useAlert()
    const navigate = useNavigate()
    const limitPerPage = 5
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [statusTabs, setStatusTabs] = useState(PopupStatusEnum.ALL)
    const [screenTabs, setScreenTab] = useState(PopupScreenEnum.ALL)

    const confirmRemoveModal = useModal({ modal: 'confirm' })
    const queryKey = ['pop-up-banner', statusTabs, screenTabs]

    const { fetchNextPage, data, isFetchingNextPage, refetch, isLoading } =
      useInfiniteQuery(
        queryKey,
        async ({ pageParam = 1 }) => {
          const res = await client?.popupBannerClient.popupBanners({
            limitPerPage,
            page: pageParam,
            statuses: statusTabs,
            screens: screenTabs,
          })

          return res
        },
        {
          getNextPageParam: lastPage => {
            if (lastPage && lastPage.data.length) return lastPage.page + 1

            return undefined
          },
        }
      )

    async function handleFetchNextPage() {
      if (!isFetchingNextPage) await fetchNextPage()
    }

    const popupData = useMemo(() => {
      return data?.pages.flatMap(page => page.data) ?? []
    }, [data])

    const { mutateAsync: handlePopupBanner, isLoading: changeStatusLoading } =
      useMutation(
        ({
          popupBanner,
          isActive,
        }: {
          popupBanner: PopupBannerListType
          isActive?: boolean
          page: number
        }) =>
          client!.popupBannerClient.handlePopupBanner({
            id: popupBanner.id,
            isActive,
          }),
        {
          onMutate: ({ popupBanner, isActive, page }) => {
            queryClient.setQueryData<
              InfiniteData<PopupBannerListResponse> | undefined
            >(queryKey, oldData => {
              if (oldData) {
                const temp = cloneDeep(oldData)
                const index = temp.pages[page].data.findIndex(
                  row => row.id === popupBanner.id
                )

                if (index !== -1) {
                  const oldTemp = temp.pages[page].data[index]
                  temp.pages[page].data[index] = {
                    ...oldTemp,
                    isActive: !oldTemp.isActive,
                  }
                }
                return temp
              }

              return oldData
            })
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )

    const { mutateAsync: handlePopupBannerOrder, isLoading: reOrderLoading } =
      useMutation(
        ({
          id,
          runningNo,
        }: {
          id: number
          runningNo: number
          oldIndex: number
          newIndex: number
        }) =>
          client!.popupBannerClient.handlePopupBanner({
            id,
            runningNo,
          }),
        {
          onMutate: ({ oldIndex, newIndex }) => {
            queryClient.setQueryData<
              InfiniteData<PopupBannerListResponse> | undefined
            >(queryKey, oldData => {
              if (oldData) {
                const result = cloneDeep(oldData)
                const oldPage = Math.floor(oldIndex / limitPerPage)
                const newPage = Math.floor(newIndex / limitPerPage)
                const oldIndexInPage = oldIndex - oldPage * limitPerPage
                const newIndexInPage = newIndex - newPage * limitPerPage
                const [removed] = result.pages[oldPage].data.splice(
                  oldIndexInPage,
                  1
                )
                result.pages[newPage].data.splice(newIndexInPage, 0, removed)

                return result
              }

              return oldData
            })
          },
          onSuccess: () => {
            refetch()
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )

    const { mutateAsync: removePopupBanner, isLoading: removeLoading } =
      useMutation(
        (id: number) => client!.popupBannerClient.removePopupBanner(id),
        {
          onSuccess: () => {
            refetch()
            confirmRemoveModal.hide()
            alert.success('ลบข้อมูลสำเร็จ')
          },
          onError: error => {
            const message = getErrorMessage(error)
            alert.error(message)
          },
        }
      )

    const { mutateAsync: bulkUpdatePopupBanner, isLoading: updateLoading } =
      useMutation(
        ({ ids, action }: { ids: number[]; action: PopupActionEnum }) =>
          client!.popupBannerClient.bulkUpdatePopupBanner({ ids, action }),
        {
          onSuccess: () => {
            refetch()
            setSelectedIds([])
            confirmRemoveModal.hide()
          },
          onError: (error: any) => {
            const errorMessage =
              error.response.errors.length &&
              error.response.errors[0].message ===
                'Some of popup banner is not allowed to be unpublished.'
                ? 'ไม่สามารถเปลี่ยนสถานะได้ เนื่องจาก pop up หมดอายุ'
                : getErrorMessage(error)
            alert.error(errorMessage)
          },
        }
      )

    async function handleDragItem(result: DropResult) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      await handlePopupBannerOrder({
        id: popupData[source.index].id,
        runningNo: popupData[destination.index].runningNo,
        oldIndex: source.index,
        newIndex: destination.index,
      })
    }

    function handleSelectItemAll(): void {
      const itemIds: number[] = popupData.map(item => item.id)
      const hasAllData = itemIds.every(row => selectedIds.includes(row))

      if (hasAllData) {
        setSelectedIds([])
      } else {
        setSelectedIds(prev => [
          ...prev,
          ...itemIds.filter(row => !prev.includes(row)),
        ])
      }
    }

    function handleSelectItem(id: number) {
      const index = selectedIds.findIndex((val: number) => val === id)
      if (index !== -1) {
        setSelectedIds((prev: number[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds((prev: number[]) => [...prev, id])
      }
    }

    function handleDeletePopupBanner(id: number) {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: async () => {
          await removePopupBanner(id)
        },
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function handleDeletePopupBannerList() {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: async () => {
          await bulkUpdatePopupBanner({
            ids: selectedIds,
            action: PopupActionEnum.REMOVE,
          })
        },
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    async function handlePopupStatus(
      popupBanner: PopupBannerListType,
      index: number
    ) {
      if (
        new Date(popupBanner.startPublishedAt) <= new Date() &&
        new Date() <= new Date(popupBanner.endPublishedAt)
      ) {
        await handlePopupBanner({
          popupBanner,
          isActive: !popupBanner.isActive,
          page: Math.floor(index / limitPerPage),
        })
      } else {
        alert.error(
          'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
        )
      }
    }

    async function handlePublishPopup() {
      const canPublish = selectedIds.every(id => {
        const popup = popupData.find(row => row.id === id)
        return (
          popup &&
          new Date(popup.startPublishedAt) <= new Date() &&
          new Date() <= new Date(popup.endPublishedAt)
        )
      })

      if (canPublish) {
        await bulkUpdatePopupBanner({
          ids: selectedIds,
          action: PopupActionEnum.PUBLISH,
        })
      } else {
        alert.error(
          'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
        )
      }
    }

    async function handleUnpublishedPopup() {
      await bulkUpdatePopupBanner({
        ids: selectedIds,
        action: PopupActionEnum.UNPUBLISH,
      })
    }

    function handleScreenTabChange(value: PopupScreenEnum) {
      setScreenTab(value)
      setSelectedIds([])
    }

    function handleStatusTabChange(value: PopupStatusEnum) {
      setStatusTabs(value)
      setSelectedIds([])
    }

    const componentProps = {
      popupData,
      selectedIds,
      statusTabs,
      screenTabs,
      navigate,
      isLoading,
      actionLoading:
        changeStatusLoading || reOrderLoading || removeLoading || updateLoading,
      total: data?.pages[0].total || 0,
      handleDragItem,
      handleSelectItem,
      handleSelectItemAll,
      handleFetchNextPage,
      handleDeletePopupBanner,
      handlePopupStatus,
      handleScreenTabChange,
      handleStatusTabChange,
      handlePublishPopup,
      handleUnpublishedPopup,
      handleDeletePopupBannerList,
    }
    return <Component {...componentProps} />
  }

  return WithPopupBannerManagementPage
}
