import { Expose, Transform } from 'class-transformer'
import { ImageType } from '@interfaces/ImageType'

export class CoverFormType {
  @Expose()
  @Transform(({ obj }) => ({ url: obj.imgPath }), { toClassOnly: true })
  coverImage: ImageType

  @Expose() coverName: string

  @Expose() categoryId: number

  @Expose() id?: number
}
