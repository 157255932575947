import cn from 'classnames'
import { TextInput } from '@components/TextInput'
import { TextInputFieldProps } from './interface'

export function TextInputField({
  className,
  label,
  classNameInput,
  classNameLabel,
  touched,
  error,
  rightIcon,
  leftIcon,
  ...props
}: TextInputFieldProps) {
  return (
    <div className={className}>
      {label && (
        <div
          className={cn(
            'text-[12px] font-light mb-[6px] text-secondary-100',
            classNameLabel
          )}
        >
          {label}
        </div>
      )}

      <div className='relative'>
        {leftIcon && (
          <div className='absolute top-1/2 left-0 transform -translate-y-1/2 ml-[20px]'>
            {leftIcon}
          </div>
        )}
        <TextInput
          className={cn(classNameInput, {
            'border-gray': !(touched && error),
            'border-[#f7575f]': touched && error,
            'pr-[80px]': rightIcon,
            'pl-[50px]': leftIcon,
          })}
          {...props}
        />
        {rightIcon && (
          <div className='absolute right-0 top-1/2 transform -translate-y-1/2 mr-[20px] text-[14px] text-black-970'>
            {rightIcon}
          </div>
        )}
      </div>
    </div>
  )
}
