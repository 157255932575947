import { gql } from 'graphql-request'

export const EBOOK_OVERVIEW_TOP_RANK = gql`
  query EbookDashboardOverviewSalesTopRank(
    $limitPerPage: Int
    $page: Int
    $categoryIds: [Int!]
    $startDate: DateTime
    $endDate: DateTime
    $ebookTypes: [BookType!]
    $orderBy: EbookDashboardOverviewSalesTopRankOrderBy
  ) {
    ebookDashboardOverviewSalesTopRank(
      limitPerPage: $limitPerPage
      page: $page
      categoryIds: $categoryIds
      startDate: $startDate
      endDate: $endDate
      ebookTypes: $ebookTypes
      orderBy: $orderBy
    ) {
      data {
        coverImgPath
        coverImgPath
        downloadedCount
        ebookType
        id
        penName
        readTrialChapterCount
        reviewCount
        rowNumber
        title
        totalSales
        viewCount
        wishlistCount
      }
      total
      page
      summaryTotalSales
    }
  }
`
