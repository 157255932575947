import cn from 'classnames'

import {
  CardContainer,
  CardValue,
  TextLabel,
  TextTitle,
  TextValue,
} from '@features/dashboard/pages/WriterPublisherPage/components/Container'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { Box } from '@features/preview/pages/PreviewStoryPage/components/Box'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum, bookTypeValue } from '@interfaces/BookEnum'
import { StoryDetailReaderType } from '@models/story/StoryDetailReaderType'

export function Cover({ data }: { data: StoryDetailReaderType }) {
  return (
    <CardContainer className='grid grid-cols-[304px_1fr] w-full gap-x-[30px]'>
      <div className='w-[304px] h-[435px]'>
        <img
          className='w-full h-full rounded-lg object-cover'
          src={data?.coverImgPath}
          alt='cover'
        />
      </div>

      <div className='w-full'>
        <div className='flex items-start'>
          <TextTitle className='break-word line-clamp-2'>
            {data?.title}
          </TextTitle>

          <div
            className={cn(
              'rounded text-center px-[10px] py-[4px] mt-[6px] font-medium ml-[10px] text-green bg-[#48bea21a] text-xs',
              {
                'min-w-[76px]': data?.status === BookStatusEnum.DRAFT,
                'min-w-[62px]': data?.status === BookStatusEnum.PUBLISHED,
              }
            )}
          >
            {data?.status === BookStatusEnum.DRAFT ? 'ไม่เผยแพร่' : 'เผยแพร่'}
          </div>

          {data?.isEnded && (
            <Box.Tag className='ml-[10px] min-w-[56px] mt-[6px] font-medium'>
              จบแล้ว
            </Box.Tag>
          )}
        </div>

        <div className='font-light text-[14px] text-primary underline flex gap-x-[20px] mt-[10px]'>
          <span>{bookTypeValue[data?.bookType as BookEnum]}</span>
          <span>{data?.category.name}</span>
        </div>

        <hr className='my-[30px]' />

        <TextTitle>ข้อมูลนักเขียน</TextTitle>

        <div className='flex items-center gap-x-5 mt-5'>
          <img
            className='w-10 h-10 object-cover rounded-full'
            src={data?.penName?.user?.avatarImgPath || '/images/avatar.png'}
            alt='writer_profile'
          />

          <div className='grid grid-cols-2 gap-x-[50px] w-full font-light text-[16px] leading-8'>
            <div className='text-secondary-100 break-word line-clamp-1 '>
              {!!data?.penName.user.writer &&
              data.penName.user.writer.affiliation === AffiliationEnum.PUBLISHER
                ? 'สำนักพิมพ์ : '
                : 'นักเขียน : '}
              <span className='font-bold text-secondary '>
                {data?.penName.user.displayName}
              </span>
            </div>

            <div className='text-secondary-100 break-word line-clamp-1'>
              นามปากกา :{' '}
              <span className='font-bold text-secondary'>
                {data?.penName.firstPenName}
              </span>
            </div>
          </div>
        </div>

        <hr className='my-[30px]' />

        <div className='flex gap-x-[60px]'>
          <CardValue>
            <TextLabel>ยอดขาย</TextLabel>
            <TextValue className='text-primary text-[24px]'>
              {data?.amountSpent.toLocaleString()}
            </TextValue>
          </CardValue>
          <CardValue>
            <TextLabel>ยอดสนับสนุนตัวละคร</TextLabel>
            <TextValue className='text-primary text-[24px]'>
              {data?.sumCharacterDonate.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>

        <hr className='my-[30px]' />

        <div className='flex items-center justify-between'>
          <CardValue>
            <TextLabel>ตอน</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.chapterCount}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>วิว</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.viewCount.toLocaleString()}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>คอมเมนต์</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.commentCount.toLocaleString()}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>เพิ่มเข้าชั้น</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.shelfAddedCount.toLocaleString()}
            </TextValue>
          </CardValue>

          <CardValue>
            <TextLabel>กด Love</TextLabel>
            <TextValue className='text-secondary text-base'>
              {data?.likeCount.toLocaleString()}
            </TextValue>
          </CardValue>
        </div>
      </div>
    </CardContainer>
  )
}
