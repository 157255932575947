import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class SearchResultByTitleType {
  @Expose() bookId: number

  @Expose() title: string

  @Expose() writer: string

  @Expose() penName: string

  @Expose() totalSearch: number
}

@Exclude()
export class SearchResultByTitleResponse {
  @Expose()
  @Type(() => SearchResultByTitleType)
  data: SearchResultByTitleType[]

  @Expose() page: number
}
