import { gql } from 'graphql-request'

export const BULK_UPDATE_BOOK_REPORT = gql`
  mutation BulkUpdateReportBooks(
    $bulkUpdateBookReportsInput: BulkUpdateBookReportInput!
  ) {
    bulkUpdateReportBooks(
      bulkUpdateBookReportsInput: $bulkUpdateBookReportsInput
    ) {
      message
    }
  }
`
