import { InfiniteData, useInfiniteQuery, useMutation } from 'react-query'
import cloneDeep from 'lodash.clonedeep'
import { client, queryClient } from '@client/init'
import { useAlert } from '@hooks/useAlert'
import { BookTopChartsResponse } from '@models/story/BookTopChartsResponse'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { BanListPageProps } from './interface'

export function withBanListPage(Component: React.FC<BanListPageProps>) {
  function WithBanListPage(props: BanListPageProps) {
    const alert = useAlert()
    const limitPerPage = 10
    const confirmModal = useModal({
      modal: 'confirm',
    })

    const {
      fetchNextPage,
      data: bannedBookTopChart,
      isLoading,
      isFetchingNextPage,
      isFetching,
    } = useInfiniteQuery(
      ['banned-book-top-chart'],
      async ({ pageParam = 1 }) => {
        const res = await client?.storyClient.getBannedBookTopCharts({
          limitPerPage,
          page: pageParam,
        })

        return res
      },
      {
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length) {
            return lastPage.page + 1
          }

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage && !isFetching) fetchNextPage()
    }

    const { mutate: unbanHandler, isLoading: unBanLoading } = useMutation(
      async ({
        topChartId,
        banReason,
      }: {
        topChartId: number
        banReason?: string
      }) => {
        await client?.storyClient.banTopChartBooks({
          topChartId,
          banReason,
        })
        queryClient.setQueryData<
          InfiniteData<BookTopChartsResponse | undefined> | undefined
        >(['banned-book-top-chart'], oldData => {
          if (oldData) {
            const tmp = cloneDeep(oldData)
            return {
              ...tmp,
              pages: tmp.pages.map(page => {
                if (page) {
                  return {
                    ...page,
                    data: page?.data.filter(row => row.id !== topChartId),
                  }
                }
                return page
              }),
            }
          }
          return oldData
        })
      },
      {
        onSuccess: () => {
          alert.success('ดำเนินการสำเร็จ')
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด ไม่สามารถดำเนินการ ได้สำเร็จ!')
        },
        onSettled: () => {
          confirmModal.hide()
        },
      }
    )

    async function handleBanBook(topChartId: number, banReason?: string) {
      unbanHandler({ topChartId, banReason })
    }
    const newProps = {
      ...props,
      books: bannedBookTopChart,
      isLoading: unBanLoading,
      handleBanBook,
      handleFetchNextPage,
    }
    return <Component {...newProps} />
  }

  return WithBanListPage
}
