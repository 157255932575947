import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
} from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import {
  AccordionContextType,
  AccordionProps,
  WithAccordionProps,
} from './interface'

const AccordionContext = createContext<AccordionContextType>({
  active: false,
  chapters: undefined,
  bookId: 0,
  page: 0,
  isLoading: false,
})

export function withAccordion(Component: React.FC<AccordionProps>) {
  function HOC({
    initialActive = false,
    bookId,
    page,
    ...props
  }: WithAccordionProps) {
    const client = useClient()
    const [active, setActive] = useState(initialActive)
    const [rotate, setRotate] = useState(
      'transform duration-300 ease rotate-180'
    )
    const contentSpace = useRef<HTMLDivElement>(null)
    const { data: chapters, isLoading } = useQuery(
      ['book', bookId, 'chapters', 'tab', page],
      () => client!.chapterClient.getChapters(bookId, page),
      {
        enabled: active,
      }
    )

    useEffect(() => {
      if (initialActive) {
        setRotate('transform duration-300 ease rotate-180')
      }
    }, [initialActive])

    const toggleAccordion = (event: React.MouseEvent<HTMLElement>) => {
      if (event.target instanceof HTMLInputElement) return

      setActive(active === false)

      setRotate(
        active
          ? 'transform duration-300 ease rotate-180'
          : 'transform duration-300 ease'
      )
    }

    const newProps = {
      ...props,
      active,
      rotate,
      contentSpace,
      toggleAccordion,
    }

    return (
      <AccordionContext.Provider
        value={{
          active,
          chapters,
          bookId,
          page,
          isLoading,
        }}
      >
        <Component {...newProps} />
      </AccordionContext.Provider>
    )
  }

  return HOC
}

export function useAccordion() {
  return useContext(AccordionContext)
}
