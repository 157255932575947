import { gql } from 'graphql-request'

export const GET_BOOK_TOP_CHARTS = gql`
  query GetBookTopCharts(
    $limitPerPage: Int
    $page: Int
    $bookType: BookType!
    $categoryId: Int
    $startDate: DateTime!
    $endDate: DateTime!
    $topChartsType: TopChartsType!
    $bookTopChartsType: BookTopChartsType!
    $isOnlyBan: Boolean
    $isAdminView: Boolean
  ) {
    bookTopCharts(
      limitPerPage: $limitPerPage
      page: $page
      bookType: $bookType
      categoryId: $categoryId
      startDate: $startDate
      endDate: $endDate
      topChartsType: $topChartsType
      bookTopChartsType: $bookTopChartsType
      isOnlyBan: $isOnlyBan
      isAdminView: $isAdminView
    ) {
      page
      total
      data {
        id
        currentRank
        previousRank
        rankChange
        viewCount
        likeCount
        isBan
        banReason
        book {
          title
          id
          coverImgPath
          penName {
            user {
              id
            }
            firstPenName
            id
          }
          category {
            id
            name
          }
          bookType
        }
      }
    }
  }
`
