import cn from 'classnames'
import { Waypoint } from 'react-waypoint'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import StatusButton from '@components/StatusButton'
import { Checkbox } from '@components/Checkbox'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { BinIcon, ClockIcon, DragIcon, PenIcon } from '@components/Icons'
import { Toggle } from '@components/Toggle'
import { formatDate } from '@libs/utils'
import { SelectButton } from '@components/SelectButton'
import { Loading } from '@components/Loading'
import {
  PopupBannerManagementPageProps,
  PopupScreenEnum,
  PopupStatusEnum,
} from './interface'

export function PopupBannerManagementPage({
  popupData,
  selectedIds,
  statusTabs,
  screenTabs,
  total,
  navigate,
  isLoading,
  actionLoading,
  handleDragItem,
  handleSelectItem,
  handleSelectItemAll,
  handleFetchNextPage,
  handleDeletePopupBanner,
  handlePopupStatus,
  handleScreenTabChange,
  handleStatusTabChange,
  handlePublishPopup,
  handleUnpublishedPopup,
  handleDeletePopupBannerList,
}: PopupBannerManagementPageProps) {
  const permission = usePermissionAction('homePageManagement')
  const statusMenus = [
    { value: PopupStatusEnum.ALL, text: 'All' },
    { value: PopupStatusEnum.ACTIVE, text: 'Active' },
    { value: PopupStatusEnum.INACTIVE, text: 'Inactive' },
    { value: PopupStatusEnum.EXPIRED, text: 'Expired' },
  ]
  const screenMenus = [
    { value: PopupScreenEnum.ALL, text: 'All' },
    { value: PopupScreenEnum.HOME, text: 'Home' },
    { value: PopupScreenEnum.COIN, text: 'Buy Coin' },
  ]
  return (
    <>
      <section className='flex text-black-480'>
        {statusMenus.map((menu, index) => (
          <button
            key={index}
            className='relative'
            type='button'
            disabled={actionLoading}
            onClick={() => handleStatusTabChange(menu.value)}
          >
            <div
              className={cn('px-[20px] py-[10px] text-[14px]', {
                'font-bold': statusTabs === menu.value,
                'font-light': statusTabs !== menu.value,
              })}
            >
              {menu.text}
            </div>
            <div
              className={cn('w-full absolute border-b', {
                'border-secondary border-b-[3px] rounded-l-[2px] rounded-r-[2px] bottom-[-1px]':
                  statusTabs === menu.value,
                'border-gray bottom-0': statusTabs !== menu.value,
              })}
            />
          </button>
        ))}

        <div className='border-b border-gray w-full' />
      </section>
      <SelectButton
        options={screenMenus}
        value={screenTabs}
        className='flex-wrap gap-y-[15px] my-[30px]'
        onChange={handleScreenTabChange}
      />

      <section className='flex items-center justify-between my-[30px] h-[40px]'>
        <div className='flex gap-x-[10px] text-black-480'>
          <Checkbox
            className={cn({ hidden: !permission.edit && !permission.delete })}
            onChange={handleSelectItemAll}
            readOnly={actionLoading}
            checked={
              selectedIds.length === popupData.length && popupData.length !== 0
            }
          />
          <span className='font-light text-secondary'>ทั้งหมด</span>
          <span className='text-secondary-100'>
            <b>
              ({selectedIds.length.toLocaleString()} {' จาก '}
              {total.toLocaleString() || 0} รายการ
            </b>
            )
          </span>
        </div>
        {!!selectedIds.length && (
          <div className='flex gap-x-[10px]'>
            {permission.edit && (
              <>
                <StatusButton
                  status='publish'
                  type='button'
                  disabled={actionLoading}
                  onClick={handlePublishPopup}
                />
                <StatusButton
                  status='unpublish'
                  type='button'
                  disabled={actionLoading}
                  onClick={handleUnpublishedPopup}
                />
              </>
            )}
            {permission.delete && (
              <StatusButton
                status='delete'
                type='button'
                disabled={actionLoading}
                onClick={handleDeletePopupBannerList}
              />
            )}
          </div>
        )}
      </section>
      <hr className='my-5' />

      <DragDropContext onDragEnd={handleDragItem}>
        <Droppable
          droppableId='list'
          isDropDisabled={!permission.edit || isLoading || actionLoading}
        >
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {popupData.map((item, index) => (
                <Draggable
                  draggableId={`${item?.id.toString()}`}
                  index={index}
                  key={item?.id}
                >
                  {providedDragable => (
                    <div
                      className='flex gap-5 mb-[10px]'
                      ref={providedDragable.innerRef}
                      {...providedDragable.draggableProps}
                      {...providedDragable.dragHandleProps}
                    >
                      <div className='flex items-center justify-between gap-x-[20px]'>
                        <Checkbox
                          className={cn({
                            hidden: !permission.edit && !permission.delete,
                          })}
                          readOnly={actionLoading}
                          checked={selectedIds.includes(item?.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.stopPropagation()
                            handleSelectItem(item?.id)
                          }}
                        />
                        <div className='font-mitr font-medium text-2xl w-[50px] text-secondary-100'>
                          {item.runningNo.toLocaleString()}
                        </div>

                        <DragIcon
                          className='cursor-grab text-secondary'
                          width='10'
                        />
                      </div>

                      <div className='w-full border-[0.5px] border-gray rounded-lg p-[30px] shadow-51'>
                        <div className='flex gap-x-[30px]'>
                          <div
                            className={cn('w-[62px] h-[82px] aspect-[62/82]', {
                              'opacity-30': !item.isActive,
                            })}
                          >
                            <img
                              className='h-full w-full object-cover rounded-lg'
                              src={item?.imgPath || ''}
                              alt='banner'
                            />
                          </div>

                          <div className='flex-1'>
                            <div className='grid font-light grid-cols-8 gap-x-[20px] text-black-480 mb-[14px] justify-end'>
                              <div>
                                <p className='text-xs'>ID</p>
                                <p className='text-sm line-clamp-1 break-all'>
                                  {item.id}
                                </p>
                              </div>
                              <div className='col-span-3'>
                                <p className='text-xs'>Description</p>
                                <p className='text-sm line-clamp-1 break-all'>
                                  {item.title}
                                </p>
                              </div>

                              <div className='col-span-3'>
                                <p className='text-xs'>Link</p>
                                <p className='text-sm line-clamp-1 break-all'>
                                  {item.link}
                                </p>
                              </div>

                              <Toggle
                                className='ml-auto disabled:cursor-not-allowed'
                                disabled={!permission.edit}
                                checked={item.isActive}
                                onChange={() => handlePopupStatus(item, index)}
                              />
                            </div>

                            <div className='flex items-center justify-between'>
                              <div className='flex items-center text-secondary-100 text-[14px] font-light gap-x-[5px]'>
                                <ClockIcon
                                  className='text-secondary'
                                  width='18'
                                  height='18'
                                />
                                {`${formatDate(
                                  item.startPublishedAt
                                )} - ${formatDate(item.endPublishedAt)}`}
                              </div>

                              <div className='flex gap-x-[10px]'>
                                <button
                                  type='button'
                                  className={cn(
                                    'flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                    {
                                      hidden: !permission.edit,
                                    }
                                  )}
                                  onClick={() =>
                                    navigate({
                                      pathname: `/home-page/pop-up/${item?.id}/edit`,
                                    })
                                  }
                                >
                                  <PenIcon width='20' height='20' />
                                </button>
                                <button
                                  type='button'
                                  disabled={actionLoading}
                                  className={cn(
                                    'flex items-center justify-center text-secondary-100 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                                    {
                                      hidden: !permission.delete,
                                    }
                                  )}
                                  onClick={() =>
                                    handleDeletePopupBanner(item.id)
                                  }
                                >
                                  <BinIcon width='20' height='20' />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {isLoading && <Loading className='mx-auto mt-[50px]' />}
              <Waypoint onEnter={handleFetchNextPage} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
