import { gql } from 'graphql-request'

export const GET_BOUGHT_EBOOK_READER = gql`
  query BoughtEbookReader(
    $page: Int
    $userId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: BoughtEbookReaderOrderBy
    $limitPerPage: Int
  ) {
    boughtEbookReader(
      page: $page
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        bookType
        buyPrice
        coverImgPath
        createdAt
        firstPenName
        title
      }
      page
      total
    }
  }
`
