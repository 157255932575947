import cx from 'classnames'

import { RadioInputProps } from './interface'

export function RadioInput({
  checkMarkClassName,
  checkColor = 'bg-primary',
  className,
  text,
  checked,
  inputClassName,
  labelClassName,
  ...props
}: RadioInputProps) {
  return (
    <label className={cx('cursor-pointer radio-custom', className)}>
      <span className={cx('text-[14px]', labelClassName)}>{text}</span>
      <input
        type='radio'
        checked={checked}
        {...props}
        className={inputClassName}
      />
      <span
        className={cx(
          'check-mark',
          { [`${checkColor}`]: checked, 'bg-white': !checked },
          checkMarkClassName
        )}
      />
    </label>
  )
}
