import { gql } from 'graphql-request'

export const GET_SEARCH_RESULT_BY_CATEGORY = gql`
  query GetSearchResultByCategory(
    $dateRange: DatePicker
    $limitPerPage: Int
    $page: Int
    $orderBy: SearchByCategorySort
    $orderDirection: OrderDirection
  ) {
    searchResultByCategory(
      dateRange: $dateRange
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        category
        totalSearch
      }
      page
    }
  }
`
