import { gql } from 'graphql-request'

export const GET_TOTAL_AGE_VERIFY_USERS = gql`
  query FindAgeVerifyUsers(
    $limitPerPage: Int
    $page: Int
    $statusType: UserAgeVerifyStatusAdminEnum
    $searchText: String
    $orderBy: UserListsOrderBy
  ) {
    findAgeVerifyUsers(
      limitPerPage: $limitPerPage
      page: $page
      statusType: $statusType
      searchText: $searchText
      orderBy: $orderBy
    ) {
      total
    }
  }
`
