import { UploadImage } from '@components/UploadImage'
import { CropImageModal } from '@components/CropImageModal'
import { CropImageProps } from './interface'

export function CropImage({
  className,
  src,
  title,
  textBtn,
  textSize,
  imgRef,
  uploadUrl,
  width,
  height,
  visible,
  loading,
  hideModal,
  handleFileChange,
  cropImage,
  zoom,
  move,
}: CropImageProps) {
  return (
    <>
      <UploadImage
        className={className}
        src={src}
        textBtn={textBtn}
        textSize={textSize}
        ratio={height / width}
        onChange={handleFileChange}
      />
      <CropImageModal
        title={title}
        imgRef={imgRef}
        uploadUrl={uploadUrl}
        visible={visible}
        loading={loading}
        isCircle={false}
        hideModal={hideModal}
        cropImage={cropImage}
        zoom={zoom}
        move={move}
      />
    </>
  )
}
