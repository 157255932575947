import { SearchSection } from '@features/user/pages/UserManagementPage/components/SearchSection'
import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { Pagination } from '@components/Pagination'
import { NoData } from '@components/NoData'
import { CoinBalancePageProps } from './interface'

export function CoinBalancePage({
  sort,
  page,
  perpage,
  total,
  remainCoins,
  columns,
  isLoading,
  pageChange,
  handleSubmitSearch,
  handleReset,
  handleSort,
}: CoinBalancePageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>
        รายการเหรียญที่เหลือของผู้ใช้งาน
      </Container.Title>
      <SearchSection
        onSearch={handleSubmitSearch}
        handleReset={handleReset}
        placeholder='ค้นหาด้วยชื่อ หรือ ID'
      />
      <Table
        data={remainCoins?.data || []}
        bodyClassName='border-gray text-[14px] font-light hover:bg-blue-4/10 text-secondary border-b-[1px]'
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        className='py-[30px]'
        fixedHeader
        initialSort={{
          key: sort.key,
          order: sort.order,
        }}
      />
      <Pagination
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
      />
    </Container>
  )
}
