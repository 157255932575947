import { Expose, Transform } from 'class-transformer'

export class UserManagementTotalResponse {
  @Expose()
  @Transform(({ value }) => value || 0)
  allCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  publisherCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  writerCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  readerCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  banCount: number
}
