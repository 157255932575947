import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'

import { PlatformEnum } from '@features/home/pages/MainBannerPage/interface'
import { MainBannerType } from '@models/mainBanner/MainBannerType'
import { MainBannerFormType } from '@models/mainBanner/MainBannerFormType'
import { GET_MAIN_BANNER } from './schemas/getMainBanner'
import { GET_MAIN_BANNER_LIST } from './schemas/getMainBannerList'
import { CREATE_MAIN_BANNER } from './schemas/createMainBanner'
import { UPDATE_MAIN_BANNER } from './schemas/updateMainBanner'
import {
  REMOVE_MAIN_BANNER,
  REMOVE_MAIN_BANNERS,
} from './schemas/removeMainBanner'
import { PUBLISH_MAIN_BANNER_LIST } from './schemas/publishMainBannerList'
import { UNPUBLISH_MAIN_BANNER_LIST } from './schemas/unpublishMainBannerList'
import { FileClient } from '../File/FileClient'

export class MainBannerClient {
  constructor(private client: GraphQLClient, private fileClient: FileClient) {}

  async getMainBannerList(platform: PlatformEnum): Promise<MainBannerType[]> {
    const { mainBanners } = await this.client.request(GET_MAIN_BANNER_LIST, {
      platform,
    })

    return plainToInstance(MainBannerType, mainBanners as [])
  }

  async getMainBanner(id: number): Promise<MainBannerType> {
    const { mainBanner } = await this.client.request(GET_MAIN_BANNER, { id })

    return plainToInstance(MainBannerType, {
      ...mainBanner,
    })
  }

  async createMainBanner(form: MainBannerFormType): Promise<void> {
    const coverImgPath = await this.fileClient.uploadFile(form.coverImg.blob!)
    const dynamicLink = await this.fileClient.getDynamicLink(form.rawLink)

    const currentDate = new Date().valueOf()
    const enable =
      form.startPublishedAt.valueOf() <= currentDate &&
      form.endPublishedAt.valueOf() >= currentDate

    await this.client.request(CREATE_MAIN_BANNER, {
      createMainBannerInput: {
        coverImgPath,
        description: form.description,
        rawLink: form.rawLink,
        linkWeb: form.web ? form.rawLink : null,
        linkIos: form.ios ? dynamicLink || form.rawLink : null,
        linkAndroid: form.android ? dynamicLink || form.rawLink : null,
        startPublishedAt: DateTime.fromJSDate(form.startPublishedAt).toUTC(),
        endPublishedAt: DateTime.fromJSDate(form.endPublishedAt).toUTC(),
        web: form.web,
        ios: form.ios,
        android: form.android,
        isActiveWeb: form.web && enable,
        isActiveIos: form.ios && enable,
        isActiveAndroid: form.android && enable,
      },
    })
  }

  async updateMainBanner(id: number, form: MainBannerFormType): Promise<void> {
    let coverImgPath = form.coverImg.url
    const currentDate = new Date().valueOf()
    const enable =
      form.startPublishedAt.valueOf() <= currentDate &&
      form.endPublishedAt.valueOf() >= currentDate

    if (form.coverImg.blob) {
      coverImgPath = await this.fileClient.uploadFile(form.coverImg.blob)
    }

    const dynamicLink = await this.fileClient.getDynamicLink(form.rawLink)

    await this.client.request(UPDATE_MAIN_BANNER, {
      updateMainBannerInput: {
        id,
        coverImgPath,
        description: form.description,
        rawLink: form.rawLink,
        linkWeb: form.web ? form.rawLink : null,
        linkIos: form.ios ? dynamicLink || form.rawLink : null,
        linkAndroid: form.android ? dynamicLink || form.rawLink : null,
        startPublishedAt: DateTime.fromJSDate(form.startPublishedAt).toUTC(),
        endPublishedAt: DateTime.fromJSDate(form.endPublishedAt).toUTC(),
        web: form.web,
        ios: form.ios,
        android: form.android,
        isActiveWeb: form.web && enable,
        isActiveIos: form.ios && enable,
        isActiveAndroid: form.android && enable,
      },
    })
  }

  async reorderMainBanner(
    id: number,
    order: number
  ): Promise<MainBannerType[]> {
    const { updateMainBanner } = await this.client.request(UPDATE_MAIN_BANNER, {
      updateMainBannerInput: {
        id,
        runningNo: order,
      },
    })

    return plainToInstance(MainBannerType, updateMainBanner as [])
  }

  async publishMainBannerList(ids: number[], displayType: PlatformEnum) {
    const { publishMainBanners } = await this.client.request(
      PUBLISH_MAIN_BANNER_LIST,
      {
        ids,
        displayType,
      }
    )

    return plainToInstance(MainBannerType, publishMainBanners as [])
  }

  async unpublishMainBannerList(ids: number[], displayType: PlatformEnum) {
    const { unPublishMainBanners } = await this.client.request(
      UNPUBLISH_MAIN_BANNER_LIST,
      {
        ids,
        displayType,
      }
    )

    return plainToInstance(MainBannerType, unPublishMainBanners as [])
  }

  async removeMainBanner(id: number): Promise<void> {
    await this.client.request(REMOVE_MAIN_BANNER, {
      id,
    })
  }

  async removeMainBanners(ids: number[]): Promise<void> {
    await this.client.request(REMOVE_MAIN_BANNERS, {
      ids,
    })
  }
}
