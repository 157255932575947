import React from 'react'
import { useMutation } from 'react-query'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { PromotionStatusEnum } from '@interfaces/promotionDiscount/PromotionStatusEnum'
import {
  InitialValuesRejectPromotionType,
  RejectPromotionModalAcceptProps,
  RejectPromotionModalProps,
} from './interface'

const initialValues: InitialValuesRejectPromotionType = {
  rejectMessage: '',
}
export function withRejectPromotionModal(
  Component: React.FC<RejectPromotionModalProps>
) {
  function WithRejectPromotionModal({
    requestId,
    promotionId,
    onSuccess,
    ...props
  }: RejectPromotionModalAcceptProps) {
    const client = useClient()
    const alert = useAlert()
    const rejectModal = useModal({ modal: 'rejectPromotion' })

    const { mutateAsync: rejectPromotion } = useMutation(
      ({
        promotionRequestId,
        rejectedReason,
      }: {
        promotionRequestId: number
        rejectedReason?: string
      }) =>
        client!.promotionClient.updatePromotionRequest({
          id: promotionRequestId,
          status: PromotionStatusEnum.REJECTED,
          rejectedReason,
        }),
      {
        onSuccess: () => {
          alert.success('ดำเนินการสำเร็จ')
          if (onSuccess) {
            onSuccess()
          }
          rejectModal.hide()
        },
        onError: () => {
          alert.error('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
        },
      }
    )

    async function handleSubmit(form: InitialValuesRejectPromotionType) {
      if (requestId) {
        await rejectPromotion({
          promotionRequestId: requestId,
          rejectedReason: form.rejectMessage,
        })
      }
    }

    function handleCancel() {
      rejectModal.hide()
    }

    const newProps = {
      ...props,
      initialValues,
      handleSubmit,
      handleCancel,
    }
    return <Component {...newProps} />
  }
  return WithRejectPromotionModal
}
