import { ReportLogActionEnum } from '@interfaces/ReportLogActionEnum'

function ReportActivityAction({ action }: { action: ReportLogActionEnum }) {
  const textAction = (actionEnum: ReportLogActionEnum) => {
    switch (actionEnum) {
      case ReportLogActionEnum.INFORMED_WRITER:
        return 'แจ้งนักเขียนแล้ว'
      case ReportLogActionEnum.SUCCESS:
        return 'ดำเนินการสำเร็จ'
      case ReportLogActionEnum.SENT_MSG_WRITER:
        return 'ส่งข้อความถึงเจ้าของงานเขียน'
      case ReportLogActionEnum.SENT_MSG_REPORTER:
        return 'ส่งข้อความถึงผู้ Report'
      case ReportLogActionEnum.PUBLISHED:
        return 'เปิด - เผยแพร่เนื้อหา'
      case ReportLogActionEnum.UNPUBLISHED:
        return 'ปิด - เผยแพร่เนื้อหา'
      default:
        return 'รอการตรวจสอบ'
    }
  }
  return <div>{textAction(action)}</div>
}

export default ReportActivityAction
