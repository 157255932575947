import { Typography } from '../Typography'
import { OverviewActiveUserProps } from './interface'

export function OverviewActiveUser({ data }: OverviewActiveUserProps) {
  return (
    <div className='grid grid-cols-[210px_245px_1fr] pt-[30px]'>
      {data.map((item, index) => (
        <Typography key={index} label={item.name}>
          {item.value}
        </Typography>
      ))}
    </div>
  )
}
