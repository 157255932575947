import { gql } from 'graphql-request'

export const GET_PROMOTION_CODE_HISTORY_LIST = gql`
  query PromotionRequestByPromotionId($promotionId: Float!) {
    promotionRequestByPromotionId(promotionId: $promotionId) {
      id
      createdAt
      promotion {
        name
        status
        generateCouponStatus
      }
    }
  }
`
