/* eslint-disable max-classes-per-file */
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { Exclude, Expose, Type, Transform } from 'class-transformer'
import { ChapterPreviewType } from './ChapterPreviewType'

class WriterType {
  @Expose() affiliation: AffiliationEnum

  @Expose() status: WriterStatusEnum
}

class UserType {
  @Expose() displayName: string

  @Expose() avatarImgPath: string

  @Expose() fullName: string

  @Expose()
  @Type(() => WriterType)
  writer: WriterType
}

class PenNameType {
  @Expose() firstPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}

class BookPreviewType {
  @Expose() id: number

  @Expose() title: string

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  orientation: OrientationEnum

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  bookType: BookEnum

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType
}

@Exclude()
export class ChapterBookPreviewType {
  @Expose() id: number

  @Expose() prevId: number | null

  @Expose() nextId: number | null

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() writerMessage: string

  @Expose()
  @Type(() => ChapterPreviewType)
  chapterPages: ChapterPreviewType[]

  @Expose()
  @Type(() => BookPreviewType)
  book: BookPreviewType
}
