import { gql } from 'graphql-request'

export const GET_TOTAL_PROMOTIONS = gql`
  query Promotions(
    $limitPerPage: Int
    $page: Int
    $searchText: String
    $startPublishedAt: DateTime
    $endPublishedAt: DateTime
    $type: PromotionType
    $status: PromotionStatus
    $orderBy: PromotionOrderBy
  ) {
    promotions(
      limitPerPage: $limitPerPage
      page: $page
      searchText: $searchText
      startPublishedAt: $startPublishedAt
      endPublishedAt: $endPublishedAt
      type: $type
      status: $status
      orderBy: $orderBy
    ) {
      total
    }
  }
`
