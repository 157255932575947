import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { useQuery } from 'react-query'
import { useClient } from '@hooks/useClient'
import {
  CoinValueAcceptProps,
  CoinValueProps,
  InitialValuesCoinType,
} from './interface'

export function withCoinValue(Component: React.FC<CoinValueProps>) {
  function WithCoinValue({
    coins,
    coinRequest,
    onSubmit,
    onReject,
  }: CoinValueAcceptProps) {
    const client = useClient()

    const { data: coinOptions = [], isLoading } = useQuery('coin-options', () =>
      client?.coinClient.getCoinOptions()
    )

    function handleSubmit(values: InitialValuesCoinType) {
      onSubmit(values.coins)
    }

    if (isLoading) {
      return null
    }

    const initialValues: InitialValuesCoinType = { coins: [] }
    if (coinRequest?.status === CoinRequestStatusEnum.PENDING) {
      initialValues.coins = coinRequest.newCoins
    } else {
      initialValues.coins = coins.map((item, index) => {
        if (index <= 1 || item.valueUnitCoinId) {
          return item
        }

        return { ...item, valueUnitCoinId: coinOptions[0]?.value }
      })
    }

    const newProps = {
      initialValues,
      coinRequest,
      coinOptions: coinOptions.map(item => ({
        ...item,
        label: `เหรียญ${item.label}`,
      })),
      handleSubmit,
      handleReject: onReject,
    }

    return <Component {...newProps} />
  }

  return WithCoinValue
}
