import { Expose, Type } from 'class-transformer'
import { StoryIncomeDataType } from './StoryIncomeDataType'

export class StoryIncomeType {
  @Expose()
  @Type(() => StoryIncomeDataType)
  data: StoryIncomeDataType[]

  @Expose() income: number
}
