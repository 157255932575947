import { BookEnum } from '@interfaces/BookEnum'
import { SelectButton } from '../SelectButton/SelectButton'
import { StoryTableTemplateProps } from './interface'

const bookType = [
  { value: BookEnum.NOVEL, label: 'นิยาย' },
  { value: BookEnum.MANGA, label: 'การ์ตูน' },
]

export function StoryTableTemplate({
  title,
  children,
  searchParams,
  categoryOptions,
  handleBookTypeChange,
  handleCategoryChange,
}: StoryTableTemplateProps) {
  return (
    <div>
      {title && (
        <div className='font-mitr text-[24px] font-medium text-secondary mb-[20px]'>
          {title}
        </div>
      )}
      <div className='w-full flex justify-between gap-x-[20px] mb-[20px]'>
        <SelectButton
          label='ลักษณะงานเขียน'
          title='ลักษณะงานเขียน'
          options={bookType}
          value={searchParams.bookType}
          className='w-[472px]'
          handleSelect={handleBookTypeChange}
        />
        <SelectButton
          label='หมวดหมู่'
          title='หมวดหมู่'
          options={categoryOptions}
          value={searchParams.category}
          className='w-[477px]'
          handleSelect={handleCategoryChange}
        />
      </div>
      {children}
    </div>
  )
}
