import * as Yup from 'yup'

import { Button } from '@components/Button'
import { Form, Formik } from 'formik'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { InputField } from '@components/forms/InputField'
import { SearchIcon } from '@components/Icons'
import { PromotionSettingSearchFormProps } from './interface'

const validationSchema = Yup.object().test((values, context) => {
  if (Boolean(values.startDate) && Boolean(!values.endDate)) {
    return context.createError({
      message: 'required',
      path: 'endDate',
    })
  }

  if (Boolean(!values.startDate) && Boolean(values.endDate)) {
    return context.createError({
      message: 'required',
      path: 'startDate',
    })
  }

  return true
})

export function PromotionSettingSearchForm({
  initialValues,
  handleSubmit,
  handleResetSearch,
}: PromotionSettingSearchFormProps) {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, resetForm }) => (
          <Form>
            <div className='flex flex-col mt-[40px] mb-[30px]'>
              <div className='flex justify-between items-end '>
                <div className='flex items-end gap-x-[10px]'>
                  <InputField
                    name='text'
                    className='w-[509px]'
                    placeholder='ค้นหาชื่อโปรโมชั่น, Code, PromotionID'
                    autoTrim={false}
                    leftIcon={<SearchIcon className='text-primary' />}
                    inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
                  />
                  <DateTimeInputField
                    className='w-[170px]'
                    name='startDate'
                    placeholder='ตั้งแต่วันที่'
                    maxDate={values.endDate}
                  />
                  <DateTimeInputField
                    className='w-[170px] '
                    name='endDate'
                    placeholder='ถึงวันที่'
                    minDate={values.startDate}
                  />
                </div>
                <div className='flex font-mitr justify-end gap-x-[20px]'>
                  <Button
                    type='button'
                    className='text-secondary underline whitespace-pre px-0'
                    onClick={() => {
                      resetForm({
                        values: {
                          text: '',
                          startDate: undefined,
                          endDate: undefined,
                        },
                      })
                      handleResetSearch()
                    }}
                  >
                    ล้างค่า
                  </Button>
                  <Button type='submit' className='bg-secondary text-white '>
                    <span className='text-[14px]'>ค้นหา</span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
