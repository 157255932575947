import { gql } from 'graphql-request'

export const GET_USER_COIN = gql`
  query GetUserCoin {
    getUserCoin {
      id
      type
      editable
    }
  }
`
