import { DateTime } from 'luxon'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { addComma } from '@libs/utils'
import { Table } from '@components/Table'
import { EyeIcon } from '@components/Icons'
import { PaymentReportType } from '@models/salse/PaymentReportType'
import { Typography } from '@features/sales/components/Typography'
import { SortByType, SortingType } from '@components/Table/interface'
import { NoData } from '@components/NoData'
import { PaymentReportSearchType } from './ReportOverview/interface'

function ActionCell({ data }: { data: PaymentReportType }) {
  const navigate = useNavigate()
  const params = {
    avatarImg: data.avatarImg,
    userId: String(data.userId),
    fullName: data.writerFullName,
    username: data.writerDisplayName,
    email: data.writerEmail,
    amount: String(data.paid),
    productType: data.productType,
    withholdingTax: String(data.paidAfterDeductWithholdingTax),
    month: String(data.paymentCycleMonth),
    year: String(data.paymentCycleYear),
  }
  return (
    <div className='flex gap-x-[10px] py-[10px]'>
      <button
        className='border border-gray h-[30px] px-[4px] rounded-[8px]'
        type='button'
        onClick={() =>
          navigate({
            pathname: `/sales/payment-report/${data.transactionId}`,
            search: `?${createSearchParams(params)}`,
          })
        }
      >
        <EyeIcon className='text-black-970' width='18' height='18' />
      </button>
    </div>
  )
}

interface PaymentReportTableProps {
  total: number
  amount: number
  data: PaymentReportType[]
  handleSortChange: (sort: SortByType) => void
  handleWaypointEnter: () => void
  isLoading: boolean
  monthYear?: PaymentReportSearchType
  sortBy: SortByType
}

function PaymentReportTable({
  data,
  total,
  amount,
  handleSortChange,
  handleWaypointEnter,
  monthYear,
  isLoading,
  sortBy,
}: PaymentReportTableProps) {
  return (
    <div className='p-[30px] border border-blue-2 rounded-[8px] shadow'>
      <div className='flex justify-between items-center pb-[30px] mb-[30px] border-b border-b-gray'>
        <div className='flex gap-x-[40px]'>
          <Typography
            className='text-[14px] text-secondary-100'
            label='จำนวนรายการที่จ่าย'
            text={
              <div className='text-[24px] font-bold'>
                {total.toLocaleString()}
              </div>
            }
          />
          <Typography
            className='text-[14px] text-secondary-100'
            label='ยอดเงินที่จ่ายทั้งหมด'
            text={
              <div className='text-[24px] text-primary font-bold'>
                {addComma(amount)}
              </div>
            }
          />
        </div>
        {!!monthYear && (
          <div className='text-secondary font-light'>{`ข้อมูล ณ เดือน ${DateTime.now()
            .set({
              month: Number(monthYear.month),
              year: Number(monthYear.year),
            })
            .toFormat('LLLL yyyy', { locale: 'th' })}`}</div>
        )}
      </div>
      <div className='text-[24px] text-secondary font-medium font-mitr'>
        รายงานการจ่ายเงิน
      </div>

      <Table
        className='mb-[30px] max-h-[570px] min-h-[200px] scrollbar-none'
        data={data}
        initialSort={{
          key: sortBy.key,
          order: sortBy.order,
        }}
        onSort={handleSortChange}
        onWaypointEnter={handleWaypointEnter}
        fixedHeader
        columns={[
          {
            column: 'Transaction ID',
            accessor: 'transactionId',
            cell: ({ value }) => <div>{value}</div>,
            isSortable: true,
            sortKey: 'transactionId',
          },
          {
            column: 'Month/Year',
            accessor: 'paymentCycleMonth',
            cell: ({ obj }) => (
              <div>
                {obj.paymentCycleMonth}/{obj.paymentCycleYear}
              </div>
            ),
            isSortable: true,
            sortKey: 'paymentCycle',
          },
          {
            column: 'Writer ID',
            accessor: 'writerId',
            cell: ({ value }) => <div>{value}</div>,
            isSortable: true,
            sortKey: 'writerId',
          },
          {
            column: 'Display Name',
            accessor: 'writerDisplayName',
            cell: ({ value }) => (
              <div className='line-clamp-1 break-word'>{value}</div>
            ),
            cellClassName: 'max-w-[160px] w-[160px] pr-[15px]',
            isSortable: true,
            sortKey: 'writerDisplayName',
          },
          {
            column: 'ชื่อที่ลงทะเบียนไว้',
            accessor: 'writerFullName',
            cell: ({ value }) => (
              <div className='line-clamp-1 break-word'>{value}</div>
            ),
            cellClassName: 'max-w-[160px] w-[160px] pr-[15px]',
            isSortable: true,
            sortKey: 'writerFullName',
          },
          {
            column: 'จำนวนเงินที่ได้รับ',
            accessor: 'paid',
            cell: ({ value }) => (
              <div>
                {value?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || 0}
              </div>
            ),
            cellClassName: 'max-w-[120px] w-[120px]',
            isSortable: true,
            sortKey: 'paid',
          },
          {
            column: 'จำนวนเงินที่โอน (หลังหัก ณ ที่จ่าย)',
            accessor: 'paidAfterDeductWithholdingTax',
            cell: ({ value }) => (
              <div>
                {value?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || 0}
              </div>
            ),
            isSortable: true,
            cellClassName: 'max-w-[120px] w-[120px]',
            sortKey: 'paidAfterDeductWithholdingTax',
          },
          {
            column: 'Action',
            actionCell: ({ obj }) => <ActionCell data={obj} />,
            cellClassName: 'flex justify-end',
            isAction: true,
          },
        ]}
      />
    </div>
  )
}

export default PaymentReportTable
