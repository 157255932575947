import React, { useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useAlert } from '@hooks/useAlert'
import { useClient } from '@hooks/useClient'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { MainBannerType } from '@models/mainBanner/MainBannerType'
import { getErrorMessage, formatYmd } from '@libs/utils'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { MainBannerPageProps, PlatformEnum, TabEnum } from './interface'

const reorder = (
  list: MainBannerType[],
  oldIndex: number,
  newIndex: number
) => {
  const result = cloneDeep(list)
  const [removed] = result.splice(oldIndex, 1)
  result.splice(newIndex, 0, removed)

  return result
}

export function withMainBannerPage(Component: React.FC<MainBannerPageProps>) {
  function WithMainBannerPage() {
    const client = useClient()
    const alert = useAlert()
    const queryClient = useQueryClient()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [selectedIdsPublish, setSelectedIdsPublish] = useState<
      MainBannerType[]
    >([])
    const [platformSelected, setPlatformSelected] = useState(PlatformEnum.WEB)
    const [tabSelected, setTabSelected] = useState(TabEnum.ALL)
    const navigate = useNavigate()
    const confirmRemoveModal = useModal({ modal: 'confirm' })
    const queryKey = ['main-banner-list', platformSelected]

    const { data = [], refetch } = useQuery(queryKey, () =>
      client!.mainBannerClient.getMainBannerList(platformSelected)
    )

    const mainBannerList = useMemo(() => {
      return data.filter(row => {
        const webAll =
          platformSelected === PlatformEnum.WEB &&
          row.web &&
          tabSelected === TabEnum.ALL
        const webActive =
          platformSelected === PlatformEnum.WEB &&
          row.web &&
          row.isActiveWeb &&
          tabSelected === TabEnum.ACTIVE
        const webInactive =
          platformSelected === PlatformEnum.WEB &&
          row.web &&
          !row.isActiveWeb &&
          new Date().valueOf() < new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.INACTIVE
        const webExpired =
          platformSelected === PlatformEnum.WEB &&
          row.web &&
          new Date().valueOf() > new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.EXPIRED

        const iosAll =
          platformSelected === PlatformEnum.IOS &&
          row.ios &&
          tabSelected === TabEnum.ALL
        const iosActive =
          platformSelected === PlatformEnum.IOS &&
          row.ios &&
          row.isActiveIos &&
          tabSelected === TabEnum.ACTIVE
        const iosInactive =
          platformSelected === PlatformEnum.IOS &&
          row.ios &&
          !row.isActiveIos &&
          new Date().valueOf() < new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.INACTIVE
        const iosExpired =
          platformSelected === PlatformEnum.IOS &&
          row.ios &&
          new Date().valueOf() > new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.EXPIRED

        const androidAll =
          platformSelected === PlatformEnum.ANDROID &&
          row.android &&
          tabSelected === TabEnum.ALL
        const androidActive =
          platformSelected === PlatformEnum.ANDROID &&
          row.android &&
          row.isActiveAndroid &&
          tabSelected === TabEnum.ACTIVE
        const androidInactive =
          platformSelected === PlatformEnum.ANDROID &&
          row.android &&
          !row.isActiveAndroid &&
          new Date().valueOf() < new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.INACTIVE
        const androidExpired =
          platformSelected === PlatformEnum.ANDROID &&
          row.android &&
          new Date().valueOf() > new Date(row.endPublishedAt).valueOf() &&
          tabSelected === TabEnum.EXPIRED

        return (
          webAll ||
          webActive ||
          webInactive ||
          webExpired ||
          iosAll ||
          iosActive ||
          iosInactive ||
          iosExpired ||
          androidAll ||
          androidActive ||
          androidInactive ||
          androidExpired
        )
      })
    }, [data, platformSelected, tabSelected])

    const canEnable = useMemo(() => {
      return (
        data.filter(row => {
          return (
            (platformSelected === PlatformEnum.WEB &&
              row.web &&
              row.isActiveWeb) ||
            (platformSelected === PlatformEnum.IOS &&
              row.ios &&
              row.isActiveIos) ||
            (platformSelected === PlatformEnum.ANDROID &&
              row.android &&
              row.isActiveAndroid)
          )
        }).length < 10
      )
    }, [data, platformSelected])

    const { mutate: deleteMainBanner } = useMutation(
      (id: number) => client!.mainBannerClient.removeMainBanner(id),
      {
        onSuccess: () => {
          refetch()
          confirmRemoveModal.hide()
          alert.success('ลบข้อมูลสำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: deleteMainBanners } = useMutation(
      (ids: number[]) => client!.mainBannerClient.removeMainBanners(ids),
      {
        onSuccess: () => {
          refetch()
          confirmRemoveModal.hide()
          setSelectedIds([])
          alert.success('ลบข้อมูลสำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    const { mutate: reorderMainBanner } = useMutation(
      ({
        id,
        newRunningNo,
      }: {
        id: number
        oldIndex: number
        newIndex: number
        newRunningNo: number
      }) => client!.mainBannerClient.reorderMainBanner(id, newRunningNo),
      {
        onMutate: ({ oldIndex, newIndex }) => {
          const items = reorder(data, oldIndex, newIndex)

          queryClient.setQueryData<MainBannerType[] | undefined>(
            queryKey,
            oldData => {
              if (oldData) {
                return items.map((row, index) => {
                  const temp = cloneDeep(row)

                  if (index === newIndex) {
                    temp.runningNo = oldData[newIndex].runningNo
                  } else if (
                    oldIndex < newIndex &&
                    index >= oldIndex &&
                    index < newIndex
                  ) {
                    temp.runningNo -= 1
                  } else if (
                    oldIndex > newIndex &&
                    index > newIndex &&
                    index <= oldIndex
                  ) {
                    temp.runningNo += 1
                  }

                  return temp
                })
              }

              return oldData
            }
          )
        },
        onError: (_, { oldIndex, newIndex }) => {
          alert.error('เกิดข้อผิดพลาด')
          const items = reorder(data, newIndex, oldIndex)
          queryClient.setQueryData<MainBannerType[] | undefined>(
            queryKey,
            () => items
          )
        },
      }
    )

    const { mutate: publishMainBannerList } = useMutation(
      (param: { ids: number[]; platform: PlatformEnum }) =>
        client!.mainBannerClient.publishMainBannerList(
          param.ids,
          param.platform
        ),
      {
        onSuccess: response => {
          queryClient.setQueryData<MainBannerType[] | undefined>(
            queryKey,
            () => response
          )
          setSelectedIds([])
        },
        onError: error => {
          const errorMessage = getErrorMessage(error)
          alert.error(errorMessage)
        },
      }
    )

    const { mutate: unpublishMainBannerList } = useMutation(
      (param: { ids: number[]; platform: PlatformEnum }) =>
        client!.mainBannerClient.unpublishMainBannerList(
          param.ids,
          param.platform
        ),
      {
        onSuccess: response => {
          queryClient.setQueryData<MainBannerType[] | undefined>(
            queryKey,
            () => response
          )
          setSelectedIds([])
        },
        onError: error => {
          const errorMessage = getErrorMessage(error)
          alert.error(errorMessage)
        },
      }
    )

    function handleDragItem(result: DropResult) {
      const { destination, source } = result

      if (!destination) return
      if (destination.index === source.index) return

      const oldIndex = data.findIndex(
        row => row.id === mainBannerList[source.index].id
      )
      const newIndex = data.findIndex(
        row => row.id === mainBannerList[destination.index].id
      )

      reorderMainBanner({
        id: mainBannerList[source.index].id,
        newRunningNo: mainBannerList[destination.index].runningNo,
        oldIndex,
        newIndex,
      })
    }

    function handleSelectItemAll(): void {
      const itemIds: number[] = mainBannerList.map(
        (item: MainBannerType) => item.id
      )
      const hasAllData = itemIds.every(row => selectedIds.includes(row))

      if (hasAllData) {
        setSelectedIds([])
        setSelectedIdsPublish([])
      } else {
        setSelectedIds(prev => [
          ...prev,
          ...itemIds.filter(row => !prev.includes(row)),
        ])

        setSelectedIdsPublish(prev => [
          ...prev,
          ...mainBannerList.filter(row => !prev.includes(row)),
        ])
      }
    }

    function handleSelectItem(id: number) {
      const index = selectedIds.findIndex((val: number) => val === id)
      if (index !== -1) {
        setSelectedIds((prev: number[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
        setSelectedIdsPublish((prev: MainBannerType[]) => {
          const temp = [...prev]
          temp.splice(index, 1)

          return temp
        })
      } else {
        setSelectedIds((prev: number[]) => [...prev, id])
        setSelectedIdsPublish((prev: MainBannerType[]) => [
          ...prev,
          mainBannerList.find(p => p.id === id)!,
        ])
      }
    }

    function handleEnable(value: MainBannerType) {
      if (platformSelected === PlatformEnum.WEB) {
        if (
          new Date().valueOf() > new Date(value.endPublishedAt).valueOf() &&
          !value.isActiveWeb
        ) {
          alert.error(
            'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
          )
        } else if (value.isActiveWeb) {
          unpublishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (!value.isActiveWeb) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (canEnable) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        }
      } else if (platformSelected === PlatformEnum.IOS) {
        if (
          new Date().valueOf() > new Date(value.endPublishedAt).valueOf() &&
          !value.isActiveIos
        ) {
          alert.error(
            'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
          )
        } else if (value.isActiveIos) {
          unpublishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (!value.isActiveIos) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (canEnable) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        }
      } else if (platformSelected === PlatformEnum.ANDROID) {
        if (
          new Date().valueOf() > new Date(value.endPublishedAt).valueOf() &&
          !value.isActiveAndroid
        ) {
          alert.error(
            'ไม่สามารถเผยแพร่ได้ เนื่องจากวันที่ไม่อยู่ในช่วงวันที่กำหนด'
          )
        } else if (value.isActiveAndroid) {
          unpublishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (!value.isActiveAndroid) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        } else if (canEnable) {
          publishMainBannerList({
            ids: [value.id],
            platform: platformSelected,
          })
        }
      }
    }

    function handleDelete(id: number) {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: () => deleteMainBanner(id),
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function handlePublishMainBannerList() {
      const ids = selectedIdsPublish.filter(
        item => new Date().valueOf() <= new Date(item.endPublishedAt).valueOf()
      )

      publishMainBannerList({
        ids: ids.map(prev => prev.id),
        platform: platformSelected,
      })
    }

    function handleUnpublishMainBannerList() {
      unpublishMainBannerList({ ids: selectedIds, platform: platformSelected })
    }

    function handledeleteMainBannerList() {
      confirmRemoveModal.show({
        content: <ConfirmModal.Title>ยืนยันการลบข้อมูล</ConfirmModal.Title>,
        onConfirm: () => deleteMainBanners(selectedIds),
        onClose: () => confirmRemoveModal.hide(),
      })
    }

    function handlePlatformChange(value: PlatformEnum) {
      setPlatformSelected(value)
      setSelectedIds([])
    }

    function handleTabChange(value: TabEnum) {
      setTabSelected(value)
      setSelectedIds([])
    }

    const componentProps = {
      mainBannerList,
      selectedIds,
      platformSelected,
      tabSelected,
      navigate,
      handleDragItem,
      handleSelectItemAll,
      handleSelectItem,
      handleEnable,
      handleDelete,
      handlePublishMainBannerList,
      handleUnpublishMainBannerList,
      handledeleteMainBannerList,
      handlePlatformChange,
      handleTabChange,
    }

    return <Component {...componentProps} />
  }

  return WithMainBannerPage
}
