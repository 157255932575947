import React from 'react'
import { Waypoint } from 'react-waypoint'

import { EndChapter } from '@components/EndChapter'
import { VerticalStyleProps } from './interface'

export function VerticalStyle({
  Element,
  chapterPages,
  createUserReadingHandler,
}: VerticalStyleProps) {
  return (
    <>
      <div className='flex flex-col gap-y-[20px] m-auto max-w-[700px]'>
        {chapterPages.map(chapterPage => (
          <Element name={`${chapterPage.id}`} key={chapterPage.id}>
            <Waypoint
              onEnter={() => createUserReadingHandler(chapterPage.id)}
            />
            <img src={chapterPage.imgPath} alt='' />
          </Element>
        ))}
      </div>
      <EndChapter />
    </>
  )
}
