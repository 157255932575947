import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useStory } from '@hooks/story/useStory'
import { BookEnum } from '@interfaces/BookEnum'
import { SortingEnum } from '@interfaces/SortingEnum'
import { ChapterGroupType, ManageChaptersProps } from './interface'

export function withManageChapters(Component: React.FC<ManageChaptersProps>) {
  function Hoc() {
    const { data } = useStory()
    const [sorter, setSorter] = useState<SortingEnum>(SortingEnum.ASC)
    const { id: bookId } = useParams()

    const chapterGroups: ChapterGroupType[] = useMemo(() => {
      if (data?.chapterCount) {
        const length = Math.ceil(data.chapterCount / 50)

        return Array.from({ length }).map((_, index: number, arr) => {
          const tail =
            index + 1 === arr.length
              ? index * 50 + (data.chapterCount - index * 50)
              : (index + 1) * 50
          return { label: ` ${index * 50 + 1} - ${tail}`, order: index }
        })
      }

      return []
    }, [data])

    function handleSortData() {
      setSorter(prev =>
        prev === SortingEnum.ASC ? SortingEnum.DESC : SortingEnum.ASC
      )
    }

    if (!data) return null

    const componentProps = {
      bookId: Number(bookId),
      chapterGroups,
      total: data?.chapterCount || 0,
      sorter,
      isManga: data?.bookType === BookEnum.MANGA,
      handleSortData,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
