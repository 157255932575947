import { gql } from 'graphql-request'

export const GET_ALL_NEWS = gql`
  query AllNews(
    $newsType: NewsType
    $limitPerPage: Int
    $page: Int
    $orderBy: NewsOrderBy
  ) {
    allNews(
      newsType: $newsType
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        bannerImgPath
        thumbnailImgPath
        name
        description
        content
        isActive
        newsType
        updatedAt
        user {
          id
          displayName
        }
        slug
      }
      total
      page
    }
  }
`
