export enum LogTimeEnum {
  A_DAY_AGO = 'A_DAY_AGO',
  A_MONTH_AGO = 'A_MONTH_AGO',
  A_YEAR_AGO = 'A_YEAR_AGO',
}

export const logTimeValue = {
  [LogTimeEnum.A_MONTH_AGO]: 29,
  [LogTimeEnum.A_YEAR_AGO]: 364,
}
