import { addComma } from '@libs/utils'
import { ArrowRightIcon } from '@components/Icons'
import { ChallengeUnitValue } from '@interfaces/user-mission/ChallengeEnum'
import { EditSectionLogProps } from './interface'

export function EditSectionLog({
  level,
  levelName,
  target,
  newLevel,
  newLevelName,
  newTarget,
  criteria,
  challengeUnit,
}: EditSectionLogProps) {
  return (
    <div className='grid grid-cols-[1fr_50px_1fr] my-[20px]'>
      <div className='flex items-center gap-x-[10px] font-light text-[14px] text-secondary'>
        <span className='text-[12px] text-secondary-100'>Level {level} :</span>
        <span>{levelName}</span>
        <span className='text-[12px] text-secondary-100'>{criteria}</span>
        {target && <span>{addComma(target, 0)}</span>}
        <span className='text-[12px] text-secondary-100'>
          {ChallengeUnitValue[challengeUnit]}
        </span>
      </div>

      <div className='flex items-center justify-center'>
        <ArrowRightIcon width='18' height='18' className='text-primary' />
      </div>

      <div className='flex items-center justify-end gap-x-[10px] font-light text-[14px] text-secondary'>
        <span className='text-[12px] text-secondary-100'>
          Level {newLevel} :
        </span>
        <span>{newLevelName}</span>
        <span className='text-[12px] text-secondary-100'>{criteria}</span>
        {newTarget && <span>{addComma(newTarget, 0)}</span>}
        <span className='text-[12px] text-secondary-100'>
          {ChallengeUnitValue[challengeUnit]}
        </span>
      </div>
    </div>
  )
}
