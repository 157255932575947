export enum PromotionChallengeActionEnum {
  ALL = 'ALL',
  LOVE = 'LOVE',
  COMMENT = 'COMMENT',
  SHARE = 'SHARE',
  FINISH_PROFILE = 'FINISH_PROFILE',
  READ = 'READ',
  UNLOCK_CHAPTER = 'UNLOCK_CHAPTER',
  DONATE = 'DONATE',
  SET_PRICE_CHAPTER = 'SET_PRICE_CHAPTER',
  CREATE_BOOK = 'CREATE_BOOK',
}
