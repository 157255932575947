import { gql } from 'graphql-request'

export const GET_READER_TOP_COIN_SPENDER = gql`
  query GetTopCoinSpender(
    $limit: Int
    $endDate: DateTime
    $startDate: DateTime
    $orderBy: GetUserTopCoinSpendOrderBy
  ) {
    getTopCoinSpender(
      limit: $limit
      endDate: $endDate
      startDate: $startDate
      orderBy: $orderBy
    ) {
      userId
      username
      userCoinSpend
    }
  }
`
