import { client as appClient } from '@client/init'
import { SortingType } from '@components/Table/interface'
import { PublishedEnum } from '@interfaces/mainBanner/PublishedEnum'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { NewsAndPromotionFormType } from '@models/newsAndPromotion/NewsAndPromotionFormType'
import { NewsAndPromotionResponse } from '@models/newsAndPromotion/NewsAndPromotionResponse'
import { NewsAndPromotionType } from '@models/newsAndPromotion/NewsAndPromotionType'
import { plainToInstance } from 'class-transformer'
import { GraphQLClient } from 'graphql-request'
import { DateTime } from 'luxon'
import { CREATE_NEWS } from './schemas/createNews'
import { FIND_ONE_NEWS } from './schemas/findOneNews'
import { GET_ALL_NEWS } from './schemas/getAllNews'
import { REMOVE_NEWS } from './schemas/removeNews'
import { UPDATE_NEWS } from './schemas/updateNews'
import { VERIFY_NEWS_SLUG } from './schemas/verifyNewsSlug'

export class NewsClient {
  constructor(private client: GraphQLClient) {}

  async createNewsAndPromotion(form: NewsAndPromotionFormType) {
    let bannerImgPath = ''
    let thumbnailImgPath = ''
    if (form.bannerImgPath.blob) {
      bannerImgPath = await appClient.fileClient.uploadFile(
        form.bannerImgPath.blob
      )
    }
    if (form.thumbnailImgPath.blob) {
      thumbnailImgPath = await appClient.fileClient.uploadFile(
        form.thumbnailImgPath.blob
      )
    }
    await this.client.request(CREATE_NEWS, {
      createNewsInput: {
        bannerImgPath,
        thumbnailImgPath,
        name: form.name,
        slug: form.slug,
        description: form.description,
        content: form.content,
        isWeb: form.isWeb,
        isIos: form.isIos,
        isAndroid: form.isAndroid,
        startPublishedAt:
          form.publishedType === PublishedEnum.IMMEDIATELY
            ? null
            : DateTime.fromJSDate(form.startPublishedAt).toUTC(),
        endPublishedAt:
          form.publishedType === PublishedEnum.CUSTOM
            ? DateTime.fromJSDate(form.endPublishedAt).toUTC()
            : null,
        newsType: form.newsType,
        publishType:
          form.publishedType === PublishedEnum.IMMEDIATELY ? 'NOW' : 'SCHEDULE',
      },
    })
  }

  async getAllNewsAndPromotion({
    newsType,
    limitPerPage,
    page,
    sortKey,
    sortOrder,
  }: {
    newsType: NewsTypeEnum | string
    limitPerPage: number
    page: number
    sortKey: string
    sortOrder: SortingType
  }): Promise<NewsAndPromotionResponse> {
    const { allNews } = await this.client.request(GET_ALL_NEWS, {
      newsType: newsType === 'all' ? undefined : newsType,
      limitPerPage,
      page,
      orderBy: sortKey
        ? {
            [sortKey]: sortOrder,
          }
        : undefined,
    })
    return plainToInstance(NewsAndPromotionResponse, allNews)
  }

  async updateNewsAndPromotionActive(
    id: number,
    isActive: boolean
  ): Promise<NewsAndPromotionType> {
    const { updateNews } = await this.client.request(UPDATE_NEWS, {
      updateNewsInput: {
        id,
        isActive,
      },
    })

    return plainToInstance(NewsAndPromotionType, updateNews)
  }

  async removeNewsAndPromotion(id: number) {
    await this.client.request(REMOVE_NEWS, {
      removeNewsId: id,
    })
  }

  async findOneNews(id: number): Promise<NewsAndPromotionFormType> {
    const { findOneNews } = await this.client.request(FIND_ONE_NEWS, {
      findOneNewsId: id,
    })

    return plainToInstance(NewsAndPromotionFormType, {
      ...findOneNews,
      verifySlug: true,
    })
  }

  async updateNewsAndPromotion(form: NewsAndPromotionFormType) {
    let bannerImgPath = form.bannerImgPath.url
    let thumbnailImgPath = form.thumbnailImgPath.url
    if (form.bannerImgPath.blob) {
      bannerImgPath = await appClient.fileClient.uploadFile(
        form.bannerImgPath.blob
      )
    }
    if (form.thumbnailImgPath.blob) {
      thumbnailImgPath = await appClient.fileClient.uploadFile(
        form.thumbnailImgPath.blob
      )
    }
    await this.client.request(UPDATE_NEWS, {
      updateNewsInput: {
        id: form.id,
        bannerImgPath,
        thumbnailImgPath,
        name: form.name,
        slug: form.slug,
        description: form.description,
        content: form.content,
        isWeb: form.isWeb,
        isIos: form.isIos,
        isAndroid: form.isAndroid,
        startPublishedAt:
          form.publishedType === PublishedEnum.IMMEDIATELY
            ? null
            : DateTime.fromJSDate(form.startPublishedAt).toUTC(),
        endPublishedAt:
          form.publishedType === PublishedEnum.CUSTOM
            ? DateTime.fromJSDate(form.endPublishedAt).toUTC()
            : null,
        newsType: form.newsType,
        publishType:
          form.publishedType === PublishedEnum.IMMEDIATELY ? 'NOW' : 'SCHEDULE',
      },
    })
  }

  async verifyNewsSlug(slug: string): Promise<boolean> {
    const { verifyNewsSlug } = await this.client.request(VERIFY_NEWS_SLUG, {
      slug,
    })

    return verifyNewsSlug
  }
}
