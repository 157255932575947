import React from 'react'
import { useField } from 'formik'

import { OptionType } from '@interfaces/OptionType'
import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { PaymentMethodProps, WithPaymentMethodProps } from './interface'

const options: OptionType[] = [
  {
    label: 'Debit/ Creadit',
    value: PaymentMethodEnum.CARD,
  },
  {
    label: 'Prompt Pay',
    value: PaymentMethodEnum.QR_PROMPT_PAY,
  },
  {
    label: 'Shopee Pay',
    value: PaymentMethodEnum.SHOPEE_PAY,
  },
  {
    label: 'True Money Wallet',
    value: PaymentMethodEnum.TRUE_MONEY,
  },
  {
    label: 'Internet Banking',
    value: PaymentMethodEnum.INTERNET_BANKING,
  },
]
export function withPaymentMethod(Component: React.FC<PaymentMethodProps>) {
  function WithPaymentMethod({ name, ...props }: WithPaymentMethodProps) {
    const [field, meta, { setValue }] = useField(name)

    function handleSelectAll() {
      if (field.value.length === options.length) {
        setValue([])
      } else {
        setValue(options.map(item => item.value))
      }
    }

    const pageProps = {
      options,
      ...props,
      ...field,
      error: meta.error,
      touched: meta.touched,
      handleSelectAll,
    }
    return <Component {...pageProps} />
  }

  return WithPaymentMethod
}
