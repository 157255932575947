import React from 'react'
import { useAuthentication } from '@hooks/useAuthentication'
import { RedirectPage } from '@components/RedirectPage'
import {
  PermissionNameType,
  ActionType,
} from '@interfaces/permission/PermissionNameType'

export function PermissionGuard({ children, feature, action, ...props }: any) {
  const { permission } = useAuthentication()

  if (permission[feature as PermissionNameType][action as ActionType]) {
    return React.cloneElement(children, props)
  }

  return null
}

export function withPermissionGuard(
  Component: React.FC<any>,
  feature: PermissionNameType,
  action: ActionType
) {
  function WithPermissionGuard(props: any) {
    const { permission } = useAuthentication()

    if (permission[feature][action]) {
      return <Component {...props} />
    }

    return <RedirectPage pathname='/' />
  }

  return WithPermissionGuard
}
