import React from 'react'
import { useField } from 'formik'

import { TextInputFieldProps } from './interface'

export function withTextInputField(Component: React.FC<TextInputFieldProps>) {
  function WithTextInputField({ name, ...props }: TextInputFieldProps) {
    const [field, { touched, error }] = useField(name)

    const componentProps = {
      touched,
      error,
      ...props,
      ...field,
    }

    return <Component {...componentProps} />
  }

  return WithTextInputField
}
