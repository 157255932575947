import {
  actionOptions,
  benefitLimitType,
  bookTypeOptions,
} from '@features/promotionSetting/pages/CreatePromotionChallengePage/promotionChallengeOptions'
import { PromotionChallengeActionEnum } from '@interfaces/promotionChllenge/PromotionChallengeActionEnum'
import { PromotionChallengeLimitTypeEnum } from '@interfaces/promotionChllenge/PromotionChallengeLimitTypeEnum'
import { PromotionPublishedEnum } from '@interfaces/promotionChllenge/PromotionPublishedEnum'
import { formatDateDomini, formatDateTime } from '@libs/utils'
import { useMemo } from 'react'
import { PreviewPromotionChallengeProps } from './interface'

function PreviewPromotionChallenge({
  form,
  activeCoinOptions,
  challengeCoinOptions,
  categories,
  actionCountUnit,
}: PreviewPromotionChallengeProps) {
  const selectedCatgories = useMemo(() => {
    return form.categoryIds?.map(
      c =>
        categories?.find(category => Number(category.value) === Number(c))
          ?.label
    )
  }, [categories, form.categoryIds])

  return (
    <div className='w-[612px] mb-[20px] '>
      <div className=' pb-[25px] border-b font-mitr text-center text-[24px] text-secondary font-medium '>
        คุณยืนยันสร้างกิจกรรม Challenge
      </div>
      <div className='h-full max-h-[460px] overflow-y-auto'>
        <div className='py-[20px] flex flex-col space-y-5 border-b'>
          <div className=' text-[18px] text-secondary font-bold '>
            {form.name}
          </div>
          <div className='grid grid-cols-4'>
            <div className='col-span-1 text-xs font-light text-secondary-100'>
              รายละเอียด :{' '}
            </div>
            <div className='col-span-3 text-sm font-light text-secondary'>
              {form.description}
            </div>
          </div>
          <div className='grid grid-cols-4'>
            <div className='col-span-1 text-xs font-light text-secondary-100'>
              เงื่อนไขการใช้งาน :{' '}
            </div>
            <div className='col-span-3 text-sm font-light text-secondary whitespace-pre-wrap break-word'>
              {form.userTerm}
            </div>
          </div>
          <div className='grid grid-cols-4'>
            <div className='col-span-1 text-xs font-light text-secondary-100'>
              เผยแพร่ :{' '}
            </div>
            <div className='col-span-3 flex flex-wrap gap-x-[10px] gap-y-[10px] text-sm font-light text-secondary'>
              <div className='flex items-end text-sm space-x-[10px]'>
                <div className='flex items-end space-x-1'>
                  <div className='text-secondary'>
                    {form.publishedType === PromotionPublishedEnum.NOW
                      ? form.startNowAt
                        ? formatDateDomini(form.startNowAt.toISOString(), true)
                        : 'ทันที'
                      : formatDateDomini(
                          form.startPublishedAt.toISOString(),
                          true
                        )}
                  </div>
                </div>
                <div className='text-secondary-100'>ถึง</div>
                <div className='text-secondary'>
                  {formatDateTime(form.endPublishedAt.toISOString())}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='py-[20px] border-b'>
          <div className='text-lg font-bold text-secondary mb-5'>
            เงื่อนไขโปรโมชั่น
          </div>
          <div className='flex items-baseline flex-wrap gap-x-[40px] gap-y-[20px] border-b pb-[20px]'>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>Action : </span>
              <span className='text-sm text-secondary'>
                {actionOptions.find(a => a.value === form.action)?.label}
              </span>
            </div>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>
                {actionCountUnit.label}
              </span>
              <span className='text-sm text-secondary'>
                {form.actionValue?.toLocaleString()}
                <span className='text-secondary-100 ml-[4px] text-xs'>
                  {actionCountUnit.rightIcon}
                </span>
              </span>
            </div>
            {[
              PromotionChallengeActionEnum.UNLOCK_CHAPTER,
              PromotionChallengeActionEnum.DONATE,
              PromotionChallengeActionEnum.SET_PRICE_CHAPTER,
              PromotionChallengeActionEnum.CREATE_BOOK,
            ].includes(form.action) && (
              <div className='font-light space-x-[10px]'>
                <span className='text-sm text-secondary'>
                  {form.coinConditionIds
                    ?.map(
                      coin =>
                        `เหรียญ${
                          activeCoinOptions.find(c => c.value === coin)?.label
                        }`
                    )
                    .join(', ')}
                </span>
              </div>
            )}
            {form.action === PromotionChallengeActionEnum.CREATE_BOOK && (
              <div className='flex items-baseline flex-wrap gap-x-[40px]'>
                <div className='font-light space-x-[10px]'>
                  <span className='text-xs text-secondary-100'>
                    ประเภทงานเขียน :
                  </span>
                  <span className='text-sm text-secondary'>
                    {form.bookTypes
                      ?.map(
                        bookType =>
                          bookTypeOptions.find(b => b.value === bookType)?.label
                      )
                      .join(', ')}
                  </span>
                </div>
                <div className='font-light space-x-[10px]'>
                  <span className='text-xs text-secondary-100'>หมวดหมู่ :</span>
                  <span className='text-sm text-secondary'>
                    {selectedCatgories?.join(', ')}
                  </span>
                </div>
              </div>
            )}
            {form.action === PromotionChallengeActionEnum.SHARE && (
              <div className='font-light space-x-[10px]'>
                <span className='text-xs text-secondary-100'>แชร์ไปยัง : </span>
                <span className='text-sm text-secondary'>
                  {form.socialMediaChannels
                    ?.map(social => social.toLowerCase())
                    .join(', ')}
                </span>
              </div>
            )}
            {form.action === PromotionChallengeActionEnum.COMMENT && (
              <div className='font-light space-x-[10px]'>
                <span className='text-xs text-secondary-100'>
                  จำนวนตัวอักษร :{' '}
                </span>
                <span className='text-sm text-secondary'>
                  {form.wordCount?.toLocaleString()}
                  <span className='text-secondary-100 ml-[4px] text-xs'>
                    ตัวอักษร
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className='flex items-baseline flex-wrap gap-x-[26px] gap-y-[20px] border-b py-[20px]'>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>รับเหรียญ : </span>
              <span className='text-sm text-secondary'>
                {form.benefitValue.toLocaleString()}
                <span className='text-secondary-100 ml-[4px] text-xs'>
                  {`เหรียญ${
                    challengeCoinOptions.find(
                      coin => coin.value === Number(form.benefitCoinId)
                    )?.label
                  }`}
                </span>
              </span>
            </div>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>
                เก็บเหรียญได้ :{' '}
              </span>
              {form.benefitLimitType && (
                <span className='text-sm text-secondary'>
                  {[
                    PromotionChallengeLimitTypeEnum.PER_DAY,
                    PromotionChallengeLimitTypeEnum.PER_CAMPAIGN,
                  ].includes(form.benefitLimitType) && (
                    <span>{form.benefitLimitValue?.toLocaleString()} </span>
                  )}
                  {benefitLimitType[form.benefitLimitType]}
                </span>
              )}
            </div>
          </div>
          <div className='flex items-baseline flex-wrap gap-x-[26px] gap-y-[20px] pt-[20px]'>
            <div className='font-light space-x-[10px]'>
              <span className='text-xs text-secondary-100'>
                งบประมาณ/แคมเปญ :{' '}
              </span>
              <span className='text-sm text-secondary'>
                {form.budget.toLocaleString()}
                <span className='text-secondary-100 ml-[4px] text-xs'>
                  {`เหรียญ${
                    challengeCoinOptions.find(
                      coin => coin.value === Number(form.benefitCoinId)
                    )?.label
                  }`}
                </span>
              </span>
            </div>
            {form.isLimitBudget && (
              <div className='font-light space-x-[10px]'>
                <span className='text-xs text-secondary-100'>
                  จำกัดจำนวน :{' '}
                </span>
                <span className='text-sm text-secondary'>
                  {form.budgetPerDay?.toLocaleString()}
                  <span className='text-secondary-100 ml-[4px] text-xs'>
                    {`เหรียญ${
                      challengeCoinOptions.find(
                        coin => coin.value === Number(form.benefitCoinId)
                      )?.label
                    }/วัน`}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewPromotionChallenge
