import { useEffect, useRef, useState, useCallback } from 'react'
import { PortalProps, usePortal } from './usePortal'

export const useDropdown = (initialDefaultOpen = false) => {
  const dropdownRef = useRef(null)
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen)

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown)
  }

  useEffect(() => {
    const pageClickEvent = () => {
      if (dropdownRef.current !== null) {
        setIsShowDropdown(!isShowDropdown)
      }
    }
    if (isShowDropdown) {
      window.addEventListener('click', pageClickEvent)
    }
    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [isShowDropdown])

  return { dropdownRef, isShowDropdown, toggleDropdown }
}

export const useMutiSelectDropDown = (initialDefaultOpen = false) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen)

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown)
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageClickEvent = (e: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsShowDropdown(false)
      }
    }
    if (isShowDropdown) {
      window.addEventListener('click', pageClickEvent)
    }
    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [isShowDropdown])

  return { dropdownRef, isShowDropdown, toggleDropdown }
}

export const usePortalDropdown = (initialDefaultOpen = false) => {
  const { Portal, portalRef, targetRef } = usePortal()
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleDropdown = (e: any) => {
    if (!targetRef.current && e.currentTarget && e.currentTarget !== document) {
      targetRef.current = e.currentTarget
    }

    setIsShowDropdown(!isShowDropdown)
  }

  const updateDropdownPosition = () => {
    const portalPosition = portalRef.current.style.position
    const { clientWidth: portalWidth, clientHeight: portalHeight } =
      portalRef.current

    const clickedEl = targetRef.current
    const { top, left, right } = clickedEl.getBoundingClientRect()
    let l = left
    let t = top + clickedEl.clientHeight
    t = portalPosition === 'absolute' ? t + window.scrollY : t

    const outRight = window.innerWidth < left + portalWidth
    const outBottom = window.innerHeight < top + portalHeight

    if (outRight) {
      l =
        window.innerWidth - (right - left + clickedEl.offsetWidth) - portalWidth
    }

    if (outBottom) {
      t = window.scrollY + (top - portalHeight)
    }

    portalRef.current.style.top = `${t}px`
    portalRef.current.style.left = `${l}px`
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pageClickEvent = (e: MouseEvent) => {
      if (portalRef.current !== null) {
        setIsShowDropdown(!isShowDropdown)
      }
    }
    if (isShowDropdown) {
      updateDropdownPosition()
      window.addEventListener('click', pageClickEvent)
    }
    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDropdown])

  const Dropdown = useCallback(
    (props: PortalProps) => {
      return <Portal {...props} />
    },
    [Portal]
  )

  return {
    Dropdown,
    isShowDropdown,
    toggleDropdown,
  }
}
