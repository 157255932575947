import { Expose, Transform, Type } from 'class-transformer'
import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { PromotionAvailableDayEnum } from '@interfaces/promotionDiscount/PromotionAvailableDayEnum'

class PromotionSubCorrectionDetail {
  @Expose()
  old: string

  @Expose()
  new: string
}

class PUsageDayCorrectionDetail {
  @Expose()
  @Transform(({ value }) => value?.map((v: string) => v.toUpperCase()))
  old: PromotionAvailableDayEnum[]

  @Expose()
  @Transform(({ value }) => value?.map((v: string) => v.toUpperCase()))
  new: PromotionAvailableDayEnum[]
}

class PaymentChannelsCorrectionDetail {
  @Expose()
  @Transform(({ value }) => value?.map((v: string) => v.toUpperCase()))
  old: PaymentMethodEnum[]

  @Expose()
  @Transform(({ value }) => value?.map((v: string) => v.toUpperCase()))
  new: PaymentMethodEnum[]
}

class PublishPeriodCorrectionDetail {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  endPublishedAt?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  startPublishedAt?: PromotionSubCorrectionDetail
}

class CodeConditionCorrectionDetail {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  type?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  buyCoinCondition?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  buyCoinConditionValue?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PaymentChannelsCorrectionDetail)
  paymentChannels?: PaymentChannelsCorrectionDetail
}

class CodeBenefitCorrectionDetail {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  benefitValue?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  coinsId?: PromotionSubCorrectionDetail
}

export class PromotionCodeCorrectionDetailType {
  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  name?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  description?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  userTerm?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  channels?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PUsageDayCorrectionDetail)
  usageDay?: PUsageDayCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  usageLimit?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  usageLimitPerUser?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PublishPeriodCorrectionDetail)
  publishPeriod?: PublishPeriodCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  userType?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => PromotionSubCorrectionDetail)
  isFirstTopUp?: PromotionSubCorrectionDetail

  @Expose()
  @Type(() => CodeConditionCorrectionDetail)
  codeCondition?: CodeConditionCorrectionDetail

  @Expose()
  @Type(() => CodeBenefitCorrectionDetail)
  codeBenefit?: CodeBenefitCorrectionDetail
}
