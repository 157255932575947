import React from 'react'
import cn from 'classnames'
import Tooltip from '@reach/tooltip'

interface TiptapButtonProps {
  icon: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  isActive?: boolean
  tooltipLabel?: string
}

export default function TiptapButton({
  icon,
  onClick,
  isActive,
  tooltipLabel,
}: TiptapButtonProps) {
  return (
    <>
      {tooltipLabel ? (
        <Tooltip
          className='bg-gray-1 rounded-[8px] px-[5px] my-[5px] text-[12px]'
          label={<span>{tooltipLabel}</span>}
        >
          <button
            type='button'
            className={cn(
              'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px]',
              {
                'text-primary': isActive,
              }
            )}
            onClick={onClick}
          >
            {icon}
          </button>
        </Tooltip>
      ) : (
        <button
          type='button'
          className={cn(
            'bg-gray-2 rounded-[6px] text-secondary text-sm font-light h-[30px] flex items-center justify-center px-[5px]',
            {
              'text-primary': isActive,
            }
          )}
          onClick={onClick}
        >
          {icon}
        </button>
      )}
    </>
  )
}
