import { Expose, Transform } from 'class-transformer'

export class UserPunishmentTotalResponse {
  @Expose()
  @Transform(({ value }) => value || 0)
  totalAdmonish: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalBan: number
}
