import { useState } from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import { SortByType, SortingType } from '@components/Table/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { SearchStoryParam } from '@interfaces/dashboard/StoryParam'
import { DurationEnum } from '@interfaces/DurationEnum'
import { BookShelfSectionProps, WithBookShelfSectionProps } from './interface'

export function withTopBookShelfSection(
  Component: React.FC<BookShelfSectionProps>
) {
  function WithTopBookShelfSection({
    searchDateParams,
  }: WithBookShelfSectionProps) {
    const client = useClient()
    const [searchParams, setSearchParams] = useState<SearchStoryParam>({
      ...searchDateParams,
      bookType: null,
      categoryId: null,
      sortKey: 'rank',
      sortOrder: SortingType.ASC,
    })

    function handleSort({ key, order }: SortByType) {
      setSearchParams(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const { data, isLoading } = useQuery(
      ['story-dashboard-book-shelf', searchParams, searchDateParams],
      () =>
        client?.dashboardClient.getStoryTopAddToShelf({
          ...searchParams,
          startDate: searchDateParams.startDate,
          endDate: searchDateParams.endDate,
        })
    )

    function bookTypeChange(bookType: BookEnum | null) {
      setSearchParams(prev => ({ ...prev, bookType }))
    }
    function categoryChange(categoryId: number) {
      setSearchParams(prev => ({ ...prev, categoryId: Number(categoryId) }))
    }

    const componentProps = {
      data: data || [],
      handleSort,
      bookTypeChange,
      categoryChange,
      isLoading,
    }

    return <Component {...componentProps} />
  }

  return WithTopBookShelfSection
}
