import { Container } from '@components/Container'
import { PromotionChallengeForm } from '@features/promotionSetting/components/PromotionChallengeForm'

import { CreatePromotionChallengePageProps } from './interface'

export function CreatePromotionChallengePage({
  initialValues,
  activeCoinOptions,
  challengeCoinOptions,
  handleSubmit,
  handleSaveDraft,
}: CreatePromotionChallengePageProps) {
  return (
    <Container>
      <PromotionChallengeForm
        title='สร้างโปรโมชั่น Challenge'
        initialValues={initialValues}
        activeCoinOptions={activeCoinOptions}
        challengeCoinOptions={challengeCoinOptions}
        handleSubmit={handleSubmit}
        handleSaveDraft={handleSaveDraft}
      />
    </Container>
  )
}
