import { useEffect, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { useAlert } from '@hooks/useAlert'
import { client } from '@client/init'
import { getErrorMessage } from '@libs/utils'
import { PromotionCodeJobStatusEnum } from '@interfaces/promotionCode/PromotionCodeJobStatusEnum'
import { CreateCodeFormType } from '@models/promotion/CreateCodeFormType'
import { GenerateCodeFieldProps, WithGenerateCodeFieldProps } from './interface'

const withGenerateCodeField = (Component: React.FC<GenerateCodeFieldProps>) => {
  function WithGenerateCodeField({
    promotionCodesId,
    generateCoupon,
    setGenerateCoupon,
    ...props
  }: WithGenerateCodeFieldProps) {
    const alert = useAlert()
    const { id } = useParams()
    const intervelRef = useRef<any>(null)
    const { values, setFieldValue } = useFormikContext<CreateCodeFormType>()

    const { mutateAsync: generateCoupons, isLoading } = useMutation(
      () => client!.promotionCodeClient.generateCoupons(promotionCodesId!),
      {
        onSuccess: () => {
          setGenerateCoupon(PromotionCodeJobStatusEnum.PROCESSING)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    async function handleGenerate() {
      generateCoupons()
    }

    function clearIntervalRef() {
      if (intervelRef.current !== null) {
        clearInterval(intervelRef.current)
      }
    }
    useEffect(() => {
      async function getGenerateCouponsStatus() {
        try {
          const generateStatus =
            await client!.promotionCodeClient.getGenerateCouponStatus(
              Number(id)
            )
          setGenerateCoupon(generateStatus.generateCouponStatus)
          if (
            generateStatus.generateCouponStatus ===
            PromotionCodeJobStatusEnum.COMPLETED
          ) {
            setFieldValue('isGenerateCompleted', true)
          }
        } catch (error) {
          alert.error(getErrorMessage(error))
        }
      }

      if (generateCoupon === PromotionCodeJobStatusEnum.PROCESSING) {
        intervelRef.current = setInterval(() => {
          getGenerateCouponsStatus()
        }, 10000)
      } else {
        clearIntervalRef()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateCoupon])

    useEffect(() => {
      return () => {
        clearIntervalRef()
      }
    }, [])

    const newProps = {
      handleGenerate,
      generateCoupon,
      isLoading,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithGenerateCodeField
}

export default withGenerateCodeField
