import { gql } from 'graphql-request'

export const EXPORT_POPUP_BANNERS = gql`
  query ExportPopupBanners(
    $startDate: DateTime
    $endDate: DateTime
    $searchText: String
    $orderBy: PopUpBannerOrderBy
    $status: PopupBannerStatus
  ) {
    exportPopupBanners(
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
      orderBy: $orderBy
      status: $status
    )
  }
`
