import cn from 'classnames'
import { CrownIcon } from '@components/Icons'
import { UserRankProps } from './interface'

function rankMapper(rank: number) {
  switch (rank) {
    case 1:
      return (
        <div className='absolute flex justify-center'>
          <CrownIcon className='w-[22px] h-[22px] text-gold' />
          <span className='absolute text-[14px] text-white font-bold font-sarabun'>
            {rank}
          </span>
        </div>
      )
    case 2:
      return (
        <div className='absolute flex justify-center'>
          <CrownIcon className='w-[22px] h-[22px] text-secondary-100' />
          <span className='absolute text-[14px] text-white font-bold font-sarabun'>
            {rank}
          </span>
        </div>
      )
    case 3:
      return (
        <div className='absolute flex justify-center'>
          <CrownIcon className='w-[22px] h-[22px] text-copper' />
          <span className='absolute text-[14px] text-white font-bold font-sarabun'>
            {rank}
          </span>
        </div>
      )
    default:
      return (
        <div className='flex justify-center w-[22px] h-[22px] bg-blue-2 rounded-full absolute'>
          <span className='text-[14px] text-blue-3 font-bold font-sarabun'>
            {rank}
          </span>
        </div>
      )
  }
}

export function UserRank({ avatarImgPath, rank }: UserRankProps) {
  return (
    <>
      {rankMapper(rank)}
      <img
        className={cn('w-[50px] h-[50px] rounded-full object-cover mt-[18px]', {
          'border-[3px] border-gold': rank === 1,
          'border-[3px] border-secondary-100': rank === 2,
          'border-[3px] border-copper': rank === 3,
        })}
        src={avatarImgPath}
        alt='profile'
      />
    </>
  )
}
