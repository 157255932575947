import cn from 'classnames'

import { Loading } from '@components/Loading'
import { ChevronDownIcon } from '@components/Icons'
import { showCommaFromString } from '@libs/utils'
import { SaleBookTableRowProps } from './interface'
import { Table } from '../Table'

function SaleBookTableRow({
  data,
  saleDetail,
  isShowSubRow,
  isFetchingNextPage,
  isLoading,
  handleToggleShowSubRow,
  handleFetchNextPage,
}: SaleBookTableRowProps) {
  return (
    <>
      <Table.Row
        className={cn({ 'border-b border-b-gray-3': isShowSubRow })}
        onClick={handleToggleShowSubRow}
      >
        <Table.Column className='py-[12px]'>
          <div>{data.title}</div>
          <div className='text-secondary-100 text-xs mt-[4px]'>
            นามปากกา : {data.firstPenName}
          </div>
        </Table.Column>
        <Table.Column className='py-[12px] flex items-center justify-end'>
          -
        </Table.Column>
        <Table.Column className='py-[12px] flex items-center justify-end'>
          {data.purchasedCount.toLocaleString()}
        </Table.Column>
        <Table.Column className='py-[12px] flex items-center justify-end'>
          {showCommaFromString(data.sales)}
        </Table.Column>
        <Table.Column className='py-[12px] flex items-center justify-end'>
          {showCommaFromString(data.writerIncome)}
        </Table.Column>
        <Table.Column className='py-[12px] flex items-center justify-center'>
          <ChevronDownIcon
            width='24'
            height='24'
            className={cn('text-primary transition-all', {
              'rotate-180': isShowSubRow,
            })}
          />
        </Table.Column>
      </Table.Row>
      {isShowSubRow && (
        <Table className='w-full max-h-[300px]' onScroll={handleFetchNextPage}>
          {saleDetail?.pages
            .flatMap(row => row.data)
            .map((row, index) => (
              <Table.Row
                className='text-sm border-b border-b-gray text-secondary font-light hover:bg-blue-7'
                key={`${row.id}-${index}`}
              >
                <Table.Column className='py-[12px] pl-[40px]'>
                  {row.name}
                </Table.Column>
                <Table.Column className='flex items-center justify-end py-[12px]'>
                  {row.price.toLocaleString()}
                </Table.Column>
                <Table.Column className='flex items-center justify-end py-[12px]'>
                  {row.purchasedCount.toLocaleString()}
                </Table.Column>
                <Table.Column className='flex items-center justify-end py-[12px]'>
                  {showCommaFromString(row.sales)}
                </Table.Column>
                <Table.Column className='flex items-center justify-end py-[12px] '>
                  {showCommaFromString(row.writerIncome)}
                </Table.Column>
                <Table.Column className='py-[12px]' />
              </Table.Row>
            ))}
          {isFetchingNextPage || isLoading ? (
            <div className='col-span-6 flex justify-center'>
              <Loading />
            </div>
          ) : null}
        </Table>
      )}
    </>
  )
}
export default SaleBookTableRow
