import { QueryKey, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { client, queryClient } from '@client/init'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAlert } from '@hooks/useAlert'
import { PromotionStatusEnum } from '@interfaces/promotionCode/PromotionStatusEnum'
import { getErrorMessage } from '@libs/utils'
import { PromotionCodeDetailPageProps } from './interface'

export function withPromotionCodeDetailPage(
  Component: React.FC<PromotionCodeDetailPageProps>
) {
  function WithPromotionCodeDetailPage() {
    const navigate = useNavigate()

    const { id } = useParams()
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const queryKey: QueryKey = ['promotion-code-detail', Number(id)]

    const { data, isLoading } = useQuery(
      queryKey,
      () => client?.promotionCodeClient.getPromotionCodeDetail(Number(id)),
      {
        onError: () => navigate('/promotion-setting'),
      }
    )

    const {
      mutateAsync: togglePromotionIsActive,
      isLoading: toggleActiveLoading,
    } = useMutation(
      ({ isActive, promotionId }: { isActive: boolean; promotionId: number }) =>
        client!.promotionClient.togglePromotionIsActive(isActive, promotionId),
      {
        onSuccess: () => {
          alert.success('ดำเนินการสำเร็จ')
          queryClient.invalidateQueries(queryKey)
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleActivePromotion(isActive: boolean) {
      if (id && Number(id)) {
        togglePromotionIsActive({ isActive, promotionId: Number(id) })
      }
    }

    const { mutateAsync: deletePromotion, isLoading: deleteLoading } =
      useMutation(
        (promotionId: number) =>
          client!.promotionClient.deletePromotion(promotionId),
        {
          onSuccess: () => {
            alert.success('ลบโปรโมชั่นสำเร็จ')
            navigate(`/promotion-setting`)
          },
          onError: error => {
            alert.error(getErrorMessage(error))
          },
        }
      )

    function handleDeletePromotion() {
      if (id && Number(id)) {
        confirmModal.show({
          content: (
            <div className='w-[280px]'>
              <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                ลบโปรโมชั่น
              </p>
              <div className='flex flex-col text-center  mt-[20px] mb-[30px] text-[14px] font-light text-secondary'>
                คุณกำลังจะลบโปรโมชั่น
                <span className='font-bold'>&ldquo;{data?.name} &rdquo;</span>
                ต้องการที่จะดำเนินการต่อหรือไม่
              </div>
            </div>
          ),
          onConfirm: async () => {
            await deletePromotion(Number(id))
            confirmModal.hide()
          },
          onClose: () => {
            confirmModal.hide()
          },
          confirmButtonText: 'ยืนยัน',
        })
      }
    }

    function handleEditPromotion() {
      const createCodeState = [
        PromotionStatusEnum.GENERATE_CODE,
        PromotionStatusEnum.GENERATE_FAIL,
        PromotionStatusEnum.CODE_GENERATED,
      ]

      if (createCodeState.includes(data!.status)) {
        navigate(`/promotion-setting/promotion-code/${id}/create-code`)
      } else {
        navigate(`/promotion-setting/promotion-code/${id}/edit`)
      }
    }

    function goBackHandle() {
      navigate(-1)
    }

    function handleGoToHistoryPage() {
      navigate(`/promotion-setting/promotion-code/${id}/history`)
    }

    const newProps = {
      data,
      isLoading,
      toggleActiveLoading,
      goBackHandle,
      handleActivePromotion,
      handleDeletePromotion,
      handleEditPromotion,
      handleGoToHistoryPage,
    }

    return <Component {...newProps} />
  }

  return WithPromotionCodeDetailPage
}
