import cn from 'classnames'
import { OverviewPage } from '@features/dashboard/pages/WriterPublisherPage/components/OverviewPage'
import { ByUserPage } from '@features/dashboard/pages/WriterPublisherPage/components/ByUserPage'
import { WriterPublisherPageProps } from './interface'

export function WriterPublisherPage({
  tabActive,
  handleSelectTab,
}: WriterPublisherPageProps) {
  const menusArray = [
    {
      value: 'overview',
      text: 'Overview',
    },
    {
      value: 'byUser',
      text: 'By User',
    },
  ]
  return (
    <>
      <div
        className={cn(
          'text-[14px] text-secondary font-light gap-x-[15px] mb-5 flex border-b border-gray'
        )}
      >
        {menusArray.map(item => (
          <button
            key={item.value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  item.value === tabActive,
                'text-secondary-100 font-light': item.value !== tabActive,
              }
            )}
            onClick={() => handleSelectTab(item.value)}
          >
            {item.text}
          </button>
        ))}
      </div>
      {tabActive === 'byUser' && <ByUserPage />}
      {tabActive === 'overview' && <OverviewPage />}
    </>
  )
}
