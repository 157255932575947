import { Formik, Form } from 'formik'
import cn from 'classnames'

import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { Button } from '@components/Button'
import { SelectButtonField } from '@components/forms/SelectButtonField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { InputField } from '@components/forms/InputField'
import { SearchIcon } from '@components/Icons'
import { ExportButton } from '@components/ExportButton'
import { CoinHistorySearchFormProps } from './interface'

export function CoinHistorySearchForm({
  initialValues,
  handleSubmit,
  exportFile,
}: CoinHistorySearchFormProps) {
  const permission = usePermissionAction('sale')
  const timeRangeOption = [
    { value: TimeRangeEnum.DAY, text: 'รายวัน' },
    { value: TimeRangeEnum.MONTH, text: 'รายเดือน' },
    { value: TimeRangeEnum.YEAR, text: 'รายปี' },
  ]
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, resetForm, submitForm }) => (
          <Form>
            <div className='flex w-full mb-[20px]'>
              <InputField
                name='searchText'
                placeholder='ค้นหาด้วยชื่อ, ID, Transaction ID, ชื่อหนังสือ, ชื่อตอน'
                leftIcon={<SearchIcon className='text-primary' />}
                inputClassName='rounded-[20px] bg-blue-2 placeholder:text-blue-3 placeholder:h-[40px] '
              />
            </div>
            <div className='flex justify-between items-end mb-[30px]'>
              <div className='flex items-end gap-x-[10px]'>
                <SelectButtonField
                  name='timeRange'
                  options={timeRangeOption}
                  label='เลือกช่วงเวลา'
                  mode='line'
                  className='w-fit overflow-auto'
                />
                <DateTimeInputField
                  className='w-[180px]'
                  name='startAt'
                  placeholder='ตั้งแต่วันที่'
                  showTimeSelect
                  onChange={value => {
                    setFieldValue('startAt', value)
                    if (values.timeRange) {
                      setFieldValue('timeRange', undefined)
                    }
                  }}
                  maxDate={values.endAt || new Date()}
                />
                <DateTimeInputField
                  className='w-[180px]'
                  name='endAt'
                  placeholder='ถึงวันที่'
                  showTimeSelect
                  onChange={value => {
                    setFieldValue('endAt', value)
                    if (values.timeRange) {
                      setFieldValue('timeRange', undefined)
                    }
                  }}
                  minDate={values.startAt}
                  maxDate={new Date()}
                />
              </div>
              <div className='flex font-mitr justify-end ml-[25px] gap-x-[20px]'>
                <Button
                  type='button'
                  className='text-secondary underline'
                  onClick={() => {
                    resetForm()
                    submitForm()
                  }}
                >
                  ล้างค่า
                </Button>
                <Button
                  type='submit'
                  className='bg-secondary text-white '
                  disabled={isSubmitting}
                >
                  <span className='text-[14px]'>ค้นหา</span>
                </Button>
                <ExportButton
                  className={cn({
                    hidden: !permission.download,
                  })}
                  handleClick={exportFile}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
