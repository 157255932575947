import { DateTime } from 'luxon'
import { useWriterDetail } from '../hooks/useWriterDetail'

import { Typography } from './Typography'

export function UserInformation() {
  const { writer } = useWriterDetail()

  return (
    <div className='flex items-center gap-x-[40px] py-[30px]'>
      <div className='flex items-center gap-x-[20px]'>
        {writer?.user?.avatarImgPath ? (
          <img
            className='w-[38px] h-[38px] rounded-full object-cover'
            src={writer.user.avatarImgPath}
            alt='User cover'
          />
        ) : (
          <div className='w-[38px] h-[38px] rounded-full bg-gray' />
        )}
        <Typography label='User ID' text={String(writer?.user.id)} />
      </div>
      <Typography
        label='ชื่อ - นามสกุล'
        text={writer?.user?.fullName || ''}
        className='max-w-[250px]'
      />
      <Typography
        label='ชื่อผู้ใช้'
        text={writer?.user?.username || ''}
        className='max-w-[250px]'
      />
      <Typography
        label='อีเมล'
        text={writer?.user?.email || ''}
        className='max-w-[250px]'
      />
      <Typography
        label='เบอร์โทรศัพท์'
        text={writer?.user?.phoneNumber || ''}
      />
      <Typography
        label='วันเกิด'
        text={
          writer?.user?.birthDay
            ? DateTime.fromISO(writer.user.birthDay).toFormat('yyyy/LL/dd', {
                locale: 'th-Th',
              })
            : ''
        }
      />
    </div>
  )
}
