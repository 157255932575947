import ReactECharts from 'echarts-for-react'
import { DateTime } from 'luxon'

import { Box } from '@components/Box'
import { KeyTypeEnum } from '@interfaces/dashboard/KeyTypeEnum'
import { clearComma } from '@libs/utils'
import { SessionHistoryProps } from './interface'

export function SessionHistory({ data, keyType }: SessionHistoryProps) {
  const option = {
    grid: {
      top: 10,
      bottom: 30,
      left: 50,
      right: 30,
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        return `${params[0].seriesName}<br />${
          params[0].axisValue
        }: <strong>${params[0].value.toLocaleString()}</strong>`
      },
    },
    xAxis: {
      type: 'category',
      data: data?.map(item => item.value),
      axisLabel: {
        formatter: (value: string) => {
          const val = clearComma(value)

          switch (keyType) {
            case KeyTypeEnum.DAY:
              return DateTime.now()
                .set({ hour: val, minute: 0 })
                .toFormat('HH:mm', { locale: 'th-TH' })
            case KeyTypeEnum.MONTH:
              return value
            case KeyTypeEnum.YEAR:
              return DateTime.now()
                .set({ month: val })
                .toFormat('LLL', { locale: 'th-TH' })
            default:
              return value
          }
        },
      },
      axisLine: {
        lineStyle: {
          color: '#eeeeee',
          type: 'solid',
        },
      },
    },
    yAxis: {
      type: 'value',
      lineStyle: {
        color: 'red',
        type: 'solid',
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
        },
      },
    },
    series: [
      {
        data: data.map(item => item.totalSession),
        type: 'line',
        name: 'สถิติใช้งาน',
        showSymbol: false,
        lineStyle: {
          width: 1,
          color: '#3f54d9',
        },
      },
    ],
    textStyle: {
      color: '#858baf',
    },
  }

  return (
    <div className='w-full pt-[30px]'>
      <div className='flex items-center'>
        <Box.Title className='font-medium text-secondary'>
          สถิติใช้งาน
        </Box.Title>{' '}
      </div>
      <ReactECharts
        option={option}
        style={{ width: '100%' }}
        notMerge
        lazyUpdate
      />
    </div>
  )
}
