import { Expose } from 'class-transformer'

export class PromotionSaleLogType {
  @Expose() id: number

  @Expose() userId: number

  @Expose() username: string

  @Expose() bookIds: number[]

  @Expose() bookNames: string[]

  @Expose() penNames: string[]

  @Expose() coinSpent: number

  @Expose() coinReceived: number

  @Expose() createdAt: string
}
