import { gql } from 'graphql-request'

export const CREATE_APPLE_FINANCIAL_REPORT = gql`
  mutation CreateAppleFinancialReport(
    $createAppleFinancialReportInput: CreateAppleFinancialReportInput!
  ) {
    createAppleFinancialReport(
      createAppleFinancialReportInput: $createAppleFinancialReportInput
    )
  }
`
