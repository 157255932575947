import { ColumnType } from '@components/Table/interface'
import { abbreviateNumber } from '@libs/utils'
import { BookChapterByBookType } from '@models/story/BookChapterByBookResponse'
import { ActionCell } from '../ActionCell'

export const columns: ColumnType<BookChapterByBookType>[] = [
  {
    column: 'ตอนที่',
    accessor: 'chapterTitle',
    cell: ({ value }) => <p className='break-word  line-clamp-1'>{value}</p>,
    isSortable: true,
    cellClassName: 'pr-[10px] w-[80px]',
    sortKey: 'runningNo',
  },
  {
    column: 'ชื่อตอน',
    accessor: 'chapterName',
    cell: ({ value }) => <p className='line-clamp-1 break-word'>{value}</p>,
    cellClassName: 'w-[130px] py-[10px]  pr-[10px]',
    isSortable: true,
  },
  {
    column: 'View',
    accessor: 'viewCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[130px]  pr-[10px]',
    isSortable: true,
  },
  {
    column: 'Download',
    accessor: 'purchasedCount',
    cell: ({ value }) => <p>{abbreviateNumber(value)}</p>,
    cellClassName: 'w-[130px]  pr-[10px]',
    isSortable: true,
  },
  {
    column: 'ยอดขาย',
    accessor: 'totalSales',
    cell: ({ value }) => <p>{value.toLocaleString()}</p>,
    cellClassName: 'w-[130px]  pr-[10px]',
    isSortable: true,
  },
  {
    column: 'Action',
    accessor: 'id',
    cellClassName: 'w-[58px] ',
    cell: ({ value }) => <ActionCell chapterId={value} />,
    isSortable: false,
  },
]
