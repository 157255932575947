import { ModalContext } from '@hooks/contexts/ModalContext/ModalContext'
import { useContext } from 'react'
import { PennameModalProps } from '../interface'

export function PennameModal({ penname }: PennameModalProps) {
  const { hideModal } = useContext(ModalContext)
  return (
    <div className='text-center px-[30px] py-[40px] w-[400px]'>
      <div className='font-mitr text-[24px] text-secondary font-medium mb-[20px]'>
        นามปากกาทั้งหมด
      </div>
      <div className='flex flex-wrap justify-center gap-[10px] font-light text-[12px] text-black-480 overflow-hidden'>
        {penname.map((item, index) => (
          <div
            key={`${item}-${index}`}
            className='px-[10px] py-[2px] bg-gray-50 rounded-[4px] truncate'
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
