import {
  ClipboardCheckIcon,
  ClipboardPendingIcon,
  ClipboardCancelIcon,
} from '@components/Icons'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { AgeConfirmStatusProps } from './interface'

export function AgeConfirmStatus({ status }: AgeConfirmStatusProps) {
  return (
    <>
      {status === AgeVerifyEnum.PENDING && (
        <div className='bg-primary/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
          <ClipboardPendingIcon
            className='text-primary'
            width='16'
            height='16'
          />
        </div>
      )}
      {status === AgeVerifyEnum.APPROVED && (
        <div className=' bg-green/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
          <ClipboardCheckIcon className='text-green' width='16' height='16' />
        </div>
      )}
      {status === AgeVerifyEnum.REJECTED && (
        <div className=' bg-red/10 w-[30px] h-[30px] p-[6px] rounded-[8px]'>
          <ClipboardCancelIcon className='text-red' width='16' height='16' />
        </div>
      )}
    </>
  )
}
