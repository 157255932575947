/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'
import {
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  onSnapshot,
  DocumentData,
  startAfter,
  getDocs,
  documentId,
  getFirestore,
  collection,
} from '@firebase/firestore'

import { useAuthentication } from '@hooks/useAuthentication'
import { NotificationType } from '@models/notification/NotificationType'

const LIMIT = 20

export function useRealtimeNotification() {
  const db = getFirestore()

  const badgeCollection = collection(db, 'badge')

  const { user } = useAuthentication()
  const [data, setData] = useState<NotificationType[]>([])
  const [hasNew, setHasNew] = useState<boolean>(false)
  const [lastVisible, setLastVisible] = useState<DocumentData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const initialized = useRef(false)

  useEffect(() => {
    setIsLoading(true)
    const q = query(
      collection(db, 'admin-notification', `${user.id}`, 'notifications'),
      orderBy('createdAt', 'desc'),
      limit(LIMIT)
    )
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const queryData: any[] = []
      querySnapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const docData = change.doc.data()
          queryData.push({
            ...docData,
            id: change.doc.id,
            createdAt: DateTime.fromJSDate(docData.createdAt.toDate()).toFormat(
              'dd LLL yy | HH:mm',
              {
                locale: 'th-TH',
              }
            ),
          })
        }
      })
      const newData = plainToInstance(NotificationType, queryData, {
        excludeExtraneousValues: true,
      })

      setData(prev => [...newData, ...prev])
      if (!initialized.current) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
        initialized.current = true
      }
      setIsLoading(false)
    })

    return unsubscribe
  }, [user.id])

  useEffect(() => {
    const q = query(badgeCollection, where(documentId(), '==', String(user.id)))
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let hasNewNotice = false
      querySnapshot.docChanges().forEach(change => {
        hasNewNotice = change.doc.data().new
      })
      setHasNew(hasNewNotice)
    })

    return unsubscribe
  }, [user.id])

  async function fetchNextData() {
    if (!lastVisible || isLoading) return

    setIsLoading(true)
    const q = query(
      collection(db, 'admin-notification', `${user.id}`, 'notifications'),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(LIMIT)
    )
    const querySnapshot = await getDocs(q)
    const queryData = querySnapshot.docs.map(item => {
      const docData = item.data()
      return {
        ...docData,
        id: item.id,
        createdAt: DateTime.fromJSDate(docData.createdAt.toDate()).toFormat(
          'dd LLL yy | HH:mm',
          {
            locale: 'th-TH',
          }
        ),
      }
    })
    const nextData = plainToInstance(NotificationType, queryData as [], {
      excludeExtraneousValues: true,
    })
    setData(prev => [...prev, ...nextData])
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
    setIsLoading(false)
  }

  async function updateReadStatus(id: string) {
    await updateDoc(
      doc(
        collection(db, 'admin-notification', `${user.id}`, 'notifications'),
        id
      ),
      {
        read: true,
      }
    )
    setData(prev => {
      const dataClone = cloneDeep(prev)
      const index = dataClone.findIndex(item => item.id === id)
      dataClone[index].read = true
      return dataClone
    })
  }

  async function updateBadge() {
    await updateDoc(doc(badgeCollection, String(user.id)), {
      new: false,
    })
  }

  return {
    data,
    hasNew,
    setHasNew,
    updateReadStatus,
    fetchNextData,
    updateBadge,
  }
}
