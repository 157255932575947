import React from 'react'

export function withTextInput(
  Component: React.FC<React.HTMLProps<HTMLInputElement>>
) {
  function WithTextInput(props: React.HTMLProps<HTMLInputElement>) {
    return <Component {...props} />
  }

  return WithTextInput
}
