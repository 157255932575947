import React, { useEffect } from 'react'
import { ModalProps } from './interface'

const withModal = (Component: React.FC<ModalProps>) => {
  function HOC({ isOpen, ...props }: ModalProps) {
    useEffect(() => {
      // DESC: disable body scroll when modal is open
      if (isOpen) {
        document.body.style.overflowY = 'hidden'
      }
      return () => {
        document.body.style.overflowY = 'initial'
      }
    }, [isOpen])

    if (!isOpen) {
      return null
    }
    return <Component isOpen={isOpen} {...props} />
  }
  return HOC
}
export { withModal }
