import { useState, useCallback } from 'react'
import {
  TableProps,
  WithTableProps,
  SortByType,
  SortingType,
} from './interface'

export function withTable<T>(Component: React.FC<TableProps<T>>) {
  function WithTable({
    onSort,
    initialSort = {
      key: '',
      order: SortingType.ASC,
    },
    ...props
  }: WithTableProps<T>) {
    const [sortBy, setSortBy] = useState<SortByType>(initialSort)

    const handleSort = useCallback(
      (key: string) => {
        const order =
          sortBy.key === key && sortBy.order === SortingType.ASC
            ? SortingType.DESC
            : SortingType.ASC
        setSortBy({ key, order })

        if (onSort) {
          onSort({ key, order })
        }
      },
      [sortBy, onSort]
    )

    const componentProps = {
      ...props,
      sortKey: sortBy.key,
      sortOrder: sortBy.order,
      handleSort,
    }
    return <Component {...componentProps} />
  }

  return WithTable
}
