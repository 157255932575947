import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'
import { useRef } from 'react'
import { PureEditorContent } from '@tiptap/react'

import { UploadImageField } from '@components/forms/UploadImageField'
import { TextInputField } from '@components/forms/TextInputField'
import { Button } from '@components/Button'
import { CheckboxField } from '@components/forms/CheckboxField'
import { RadioInputField } from '@components/forms/RadioInputField'
import { Avatar } from '@components/Avatar'
import TiptapField from '@components/forms/TiptapField/TiptapField'
import { SlugInputField } from '@components/forms/SlugInputField'
import { NewsTypeEnum } from '@interfaces/NewsTypeEnum'
import { UploadThumbnailField } from '@features/chapterBook/components/UploadThumbnailField'
import { NewsAndPromotionProps } from './interface'
import { VerifySlug } from './components/VerifySlug'
import { NewAndPromotionPublishing } from '../NewAndPromotionPublishing'

const newsTypeOptions = [
  {
    label: 'News',
    value: NewsTypeEnum.NEWS,
  },
  {
    label: 'Promotion',
    value: NewsTypeEnum.PROMOTION,
  },
]

const validationSchema = Yup.object()
  .shape({
    bannerImgPath: Yup.object().shape({
      url: Yup.string().required(),
    }),
    newsType: Yup.string().required(),
    thumbnailImgPath: Yup.object().shape({
      url: Yup.string().required(),
    }),
    isWeb: Yup.boolean(),
    isIos: Yup.boolean(),
    isAndroid: Yup.boolean(),
    name: Yup.string().trim().required(),
    slug: Yup.string().required(),
  })
  .test((value, context) => {
    if (!value.isWeb && !value.isIos && !value.isAndroid) {
      return context.createError({
        path: 'isWeb',
        message: 'At least one platform must be selected',
      })
    }
    if (value.slug && !value.verifySlug) {
      return context.createError({
        path: 'slug',
        message: 'Slug is duplicate with other news',
      })
    }
    return true
  })

export function NewsAndPromotionForm({
  initialValues,
  updatedBy,
  isEdit,
  handleSubmit,
  handleOpenNewsLogs,
}: NewsAndPromotionProps) {
  const editorRef = useRef<PureEditorContent>(null)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting, resetForm }) => {
        return (
          <Form>
            <section className='flex flex-col gap-y-[30px] border border-blue-2 rounded-lg p-[30px] shadow'>
              <UploadImageField
                name='bannerImgPath'
                textBtn='อัปโหลดรูปภาพแบนเนอร์'
                textReUploadBtn='เปลี่ยนรูปภาพแบนเนอร์'
                className='w-full aspect-[21/9]'
                textSize='2100 X 900'
                ratio={9 / 21}
              />
              <hr />

              <div className='flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap'>
                <div className='text-[12px]'> Type :</div>
                {newsTypeOptions.map((items, index) => (
                  <RadioInputField
                    key={index}
                    name='newsType'
                    labelClassName='text-secondary'
                    label={items.label}
                    value={items.value}
                    checked={values.newsType === items.value}
                  />
                ))}
              </div>

              <hr />

              <div>
                <div className='flex gap-x-[16px] items-center font-light text-secondary-100 whitespace-nowrap'>
                  <div className='text-[12px]'> Display :</div>
                  <CheckboxField
                    name='isWeb'
                    label='Web'
                    checked={values.isWeb}
                  />
                  <CheckboxField
                    name='isIos'
                    label='iOS'
                    checked={values.isIos}
                  />
                  <CheckboxField
                    name='isAndroid'
                    label='Android'
                    checked={values.isAndroid}
                  />
                </div>
                {errors.isWeb && (
                  <p className='text-red text-[12px] mt-[10px]'>
                    โปรดเลือกการแสดงผลอย่างน้อย 1 ตัวเลือก
                  </p>
                )}
              </div>
              <hr />
              <NewAndPromotionPublishing />
              <hr />
              <div className='flex items-start gap-x-10 pb-[30px] border-b border-gray'>
                <UploadThumbnailField
                  className='h-[154px]'
                  fieldName='thumbnailImgPath'
                />
                <div className='grid flex-1 gap-y-[30px]'>
                  <TextInputField
                    name='name'
                    classNameLabel='text-secondary-100'
                    label='Title'
                    placeholder='Title'
                  />
                  <TextInputField
                    name='description'
                    classNameLabel='text-secondary-100'
                    label='Description'
                    placeholder='Description'
                  />
                  <div>
                    <SlugInputField
                      name='slug'
                      classNameLabel='text-secondary-100'
                      label='Slug'
                      placeholder='Slug'
                      disabled={isEdit}
                    />
                    <VerifySlug />
                  </div>
                </div>
              </div>

              <div className='font-light text-secondary-100 whitespace-nowrap'>
                <div className='text-[12px]'> Detail :</div>
                <TiptapField name='content' editorRef={editorRef} />
              </div>
            </section>

            <section
              className={cn('flex justify-center my-[30px]', {
                'justify-between': updatedBy,
              })}
            >
              {updatedBy && handleOpenNewsLogs && (
                <button
                  className='flex'
                  type='button'
                  onClick={handleOpenNewsLogs}
                >
                  <div className='text-[14px] font-bold text-secondary-100 mt-[16px]'>
                    ดำเนินการโดย:
                  </div>
                  <Avatar
                    className='w-[50px] h-[50px] mx-[10px]'
                    src={updatedBy.avatarImgPath}
                    alt={updatedBy.displayName}
                  />
                  <div className='text-[14px] font-bold text-secondary mt-[16px]'>
                    {updatedBy.displayName}
                  </div>
                </button>
              )}
              <div className='flex gap-x-5'>
                <Button
                  type='button'
                  className='border border-secondary-100 text-[14px] text-secondary-100 min-w-[100px]'
                  onClick={() => {
                    if (editorRef.current?.props.editor) {
                      editorRef.current.props.editor.commands.setContent(
                        initialValues.content
                      )
                    }
                    resetForm()
                  }}
                >
                  ยกเลิก
                </Button>

                <Button
                  type='submit'
                  disabled={isSubmitting}
                  className='border border-primary bg-primary text-[14px] text-white min-w-[100px] disabled:border-primary/90 disabled:bg-primary/90 disabled:cursor-not-allowed'
                >
                  บันทึก
                </Button>
              </div>
            </section>
          </Form>
        )
      }}
    </Formik>
  )
}
