import cn from 'classnames'

import { SearchTab } from '@features/dashboard/pages/SearchPage/components/SearchTab/SearchTab'
import { PopularTab } from '@features/dashboard/pages/SearchPage/components/PopularTab/PopularTab'
import { SearchSection } from '@features/dashboard/components/SearchSection'
import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { SearchPageProps } from './interface'

enum SearchPageTab {
  POPULAR = 'POPULAR',
  SEARCH = 'SEARCH',
}

const SearchPageTabTitle = {
  [SearchPageTab.POPULAR]: 'แนวโน้มความนิยม',
  [SearchPageTab.SEARCH]: 'ผลการเสิร์ช',
}

export function SearchPage({
  searchParams,
  tabActive,
  handleSearch,
  handleExport,
  handleSelectTab,
  handleReset,
}: SearchPageProps) {
  return (
    <>
      <div
        className={cn(
          'text-[14px] text-secondary font-light gap-x-[15px] mb-5 flex border-b border-gray'
        )}
      >
        {Object.entries(SearchPageTabTitle).map(([key, value]) => (
          <button
            key={value}
            type='button'
            className={cn(
              'flex px-[20px] py-[9px] min-w-fit h-[40px] items-center justify-center cursor-pointer',
              {
                'border-b-[3px] border-secondary text-secondary font-bold z-[1]':
                  key === tabActive,
                'text-secondary-100 font-light': key !== tabActive,
              }
            )}
            onClick={() => handleSelectTab(key)}
          >
            {value}
          </button>
        ))}
      </div>
      <SearchSection
        initialValues={{
          startDate: undefined,
          endDate: undefined,
          interval: TimeRangeEnum.MONTH,
        }}
        handleSubmit={handleSearch}
        handleExport={handleExport}
        handleReset={handleReset}
      />

      {SearchPageTab.POPULAR === tabActive ? (
        <PopularTab searchParam={searchParams} />
      ) : (
        <SearchTab searchParam={searchParams} />
      )}
    </>
  )
}
