import { ChevronDownIcon } from '@components/Icons'
import { useDropdown } from '@hooks/useDropdown'
import cn from 'classnames'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { InitialValuesCoinType } from '../CoinValue/interface'
import { TextInputCustomFieldProps } from './interface'

export function TextInputCustomField({
  className,
  options,
  label,
  touched,
  error,
  coinExchangeOptionIndex,
  showErrorMsg,
  ...props
}: TextInputCustomFieldProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const { values, setFieldValue } = useFormikContext<InitialValuesCoinType>()
  function handleSelect(value: number | null) {
    setFieldValue(`coins[${coinExchangeOptionIndex}].valueUnitCoinId`, value)
  }
  return (
    <div className={className}>
      <div className='relative'>
        <input
          type='text'
          className={cn(
            className,
            'p-[10px] pr-[65px] rounded-[8px] border border-gray outline-none text-[14px] h-[40px] w-full placeholder:text-black-970 placeholder:font-light',
            {
              '!border-red': touched && error,
              'pr-[90px]':
                values.coins[coinExchangeOptionIndex].valueUnitCoinId,
            }
          )}
          {...props}
        />
        <div
          className={cn(
            'absolute right-0 top-1/2 transform -translate-y-1/2 mr-[5px] text-[14px] text-black-970 cursor-pointer',
            { 'pointer-events-none': props.disabled }
          )}
          onClick={toggleDropdown}
        >
          <div className='absolute right-0 top-1/2 transform -translate-y-1/2 mr-[20px] text-[14px] text-secondary-100 whitespace-nowrap'>
            {
              options?.find(
                row =>
                  row.value ===
                  values.coins[coinExchangeOptionIndex].valueUnitCoinId
              )?.label
            }
          </div>
          <ChevronDownIcon className='text-primary' width='16' height='16' />
        </div>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-full z-10 right-0 bg-white  border-1 border-gray rounded-[10px] max-h-[400px] overflow-y-auto shadow-[0_3px_12px_0_rgba(51,51,51,0.05)]'
          >
            <div className='my-[10px]'>
              {options.map((menu, index) => (
                <div
                  className='text-[14px] font-light py-[10px] px-[20px] hover:text-primary hover:bg-blue-8'
                  key={index}
                  onClick={() => handleSelect(menu.value)}
                >
                  {menu.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {showErrorMsg && touched && error && (
        <div className='mt-[10px] text-[12px] text-[#f7575f]'>{error}</div>
      )}
    </div>
  )
}
