import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import { Table } from '@components/Table'
import { NoData } from '@components/NoData'
import { EyeIcon } from '@components/Icons'
import { ColumnType, SortingType } from '@components/Table/interface'
import { PromotionCodeLogType } from '@models/promotion/PromotionCodeLogsType'
import { BuyCoinCodeTypeEnum } from '@interfaces/promotionCode/BuyCoinCodeTypeEnum'
import { CodeTransactionTableProps } from './interface'

export function CodeTransactionTable({
  data,
  handleSort,
  isLoading,
  activeTab,
  promotionType,
  coinName,
  isUsageLimitPerUser,
}: CodeTransactionTableProps) {
  const columns: ColumnType<PromotionCodeLogType>[] = useMemo(() => {
    return activeTab === 'remaining'
      ? [
          {
            column: 'Code',
            accessor: 'coupon',
            cell: ({ value }) => <p>{value}</p>,
            cellClassName: 'py-[10px]  pr-[10px]',
          },
        ]
      : [
          {
            column: 'Code',
            accessor: 'coupon',
            cell: ({ value }) => <p>{value}</p>,
            cellClassName: 'py-[10px] w-[100px] pr-[10px]',
          },
          {
            column: 'Promotion Name',
            accessor: 'promotionName',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            cellClassName: 'py-[10px] pr-[10px]',
          },
          {
            column: 'Channel',
            accessor: 'channel',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            cellClassName: 'py-[8px] w-[80px] pr-[10px]',
          },
          {
            column: 'Used Date',
            accessor: 'createdAt',
            cell: ({ value }) =>
              value ? (
                <p className='line-clamp-1 overflow-wrap-anywhere '>
                  {DateTime.fromISO(value).toFormat('dd LLL yy | HH:mm น.', {
                    locale: 'th-Th',
                  })}
                </p>
              ) : (
                ''
              ),
            isSortable: activeTab === 'used',
            cellClassName: 'py-[8px] w-[160px] pr-[10px]',
          },
          {
            column: `จำนวนที่ได้รับ(เหรียญ${coinName})`,
            accessor: 'feeCoinReceived',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            isSortable: activeTab === 'used',
            cellClassName: 'py-[8px] w-[140px] pr-[20px]',
          },
          {
            column: 'Current Used',
            accessor: 'currentUsed',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            cellClassName: 'py-[8px] w-[60px] pr-[20px]',
          },
          {
            column: 'Maximum Used/campaign',
            accessor: 'maximumUsed',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>
                {!isUsageLimitPerUser ? 'N/A' : value}
              </p>
            ),
            cellClassName: 'py-[8px] w-[110px] pr-[15px]',
          },
          {
            column: 'User ID',
            accessor: 'userId',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            cellClassName: 'py-[8px] w-[60px] pr-[10px]',
          },
          {
            column: 'User Type',
            accessor: 'userType',
            cell: ({ value }) => (
              <p className='line-clamp-1 overflow-wrap-anywhere '>{value}</p>
            ),
            cellClassName: 'py-[8px] w-[100px] pr-[10px]',
            isSortable: activeTab === 'used',
          },
          {
            column: 'Action',
            accessor: 'userId',
            cell: ({ value }) =>
              value ? (
                <div className='flex gap-x-[10px]'>
                  <Link to={`/user/user-management/${value}?tab=coin`}>
                    <button
                      className='border border-gray h-[30px] px-[4px] rounded-[8px]'
                      type='button'
                    >
                      <EyeIcon
                        className='text-secondary-100'
                        width='18'
                        height='18'
                      />
                    </button>
                  </Link>
                </div>
              ) : (
                ''
              ),
          },
        ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, promotionType, coinName])
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        onSort={handleSort}
        isLoading={isLoading}
        initialSort={{
          key: 'createdAt',
          order: SortingType.DESC,
        }}
      />
    </div>
  )
}
