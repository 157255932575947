import cn from 'classnames'
import { Form, Formik, FieldArray } from 'formik'
import { Avatar } from '@components/Avatar'
import { Button } from '@components/Button'
import { CheckboxField } from '@components/forms/CheckboxField'
import { BinIcon, PenIcon } from '@components/Icons'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinFormType } from '@models/coin/CoinFormType'
import { ToggleField } from '@components/forms/ToggleField'
import { useAuthentication } from '@hooks/useAuthentication'
import { NumberWithCommaField } from '@components/forms/NumberWithCommaField'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { TextInputCustomField } from '../TextInputCustomField'
import { CoinValueProps } from './interface'
import { validationSchema } from './validationSchema'
import { TextInputUnit } from './TextInputUnit'

function CoinImage({
  className,
  src,
  alt,
}: {
  className: string
  src?: string
  alt?: string
}) {
  if (!src) {
    return null
  }

  return <img src={src} className={className} alt={alt} />
}

export function CoinValue({
  initialValues,
  coinRequest,
  coinOptions,
  handleSubmit,
  handleReject,
}: CoinValueProps) {
  const { user } = useAuthentication()
  const permission = usePermissionAction('coin')
  const editCoinModal = useModal({ modal: 'editCoin' })
  const confirmModal = useModal({ modal: 'confirm' })
  const canEdit = permission.edit && !coinRequest
  const isCoinRequestPending =
    coinRequest?.status === CoinRequestStatusEnum.PENDING
  const canApprove =
    permission.approve &&
    isCoinRequestPending &&
    coinRequest?.createdBy?.id !== user.id

  function coinPair(coinId: number | null) {
    const coin = coinOptions.find(item => item.value === coinId)

    return (
      <CoinImage
        className='w-[40px] h-[40px] mt-[20px] mr-[10px] object-contain'
        src={coin?.imgPath}
        alt={coin?.label}
      />
    )
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray
              name='coins'
              render={arrayHelpers =>
                values.coins?.map((item, index) => (
                  <div
                    key={item.id}
                    className='w-full border-[0.5px] border-gray rounded-lg px-[30px] py-[30px] shadow-lg mb-[20px]'
                  >
                    <div className='flex justify-between w-full text-[18px] font-bold text-secondary mb-[20px]'>
                      <p>
                        มูลค่าเหรียญ{item.name}
                        {isCoinRequestPending && (
                          <span className='font-sarabun font-light text-base text-primary ml-[20px]'>
                            (รอการอนุมัติ)
                          </span>
                        )}
                      </p>
                      {index >= 2 && (
                        <div className='flex items-center gap-x-[10px]'>
                          <ToggleField
                            name={`coins[${index}].visible`}
                            disabled={!canEdit}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const checked = !!event.target?.checked
                              setFieldValue(
                                `coins[${index}].isExpireByActivity`,
                                checked
                              )
                              setFieldValue(
                                `coins[${index}].isExpireByPaid`,
                                checked
                              )
                            }}
                          />
                          {canEdit && item.editable && (
                            <>
                              {' '}
                              <button
                                type='button'
                                className={cn(
                                  'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px]',
                                  { hidden: !permission.edit }
                                )}
                                onClick={() => {
                                  editCoinModal.show({
                                    initialValues: {
                                      name: item.name,
                                      upload: item.imgPath,
                                    },
                                    onSubmit: (value: CoinFormType) => {
                                      arrayHelpers.replace(index, {
                                        ...item,
                                        name: value.name,
                                        imgPath: value.upload,
                                      })
                                      editCoinModal.hide()
                                    },
                                  })
                                }}
                                disabled={!canEdit}
                              >
                                <PenIcon
                                  width='20'
                                  height='20'
                                  className='text-secondary-100'
                                />
                              </button>
                              <button
                                type='button'
                                className={cn(
                                  'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px]',
                                  { hidden: !permission.delete }
                                )}
                                onClick={() =>
                                  confirmModal.show({
                                    content: (
                                      <ConfirmModal.Title>
                                        ยืนยันการลบข้อมูลเหรียญ
                                      </ConfirmModal.Title>
                                    ),
                                    onConfirm: () => {
                                      arrayHelpers.remove(index)
                                      confirmModal.hide()
                                    },
                                    onClose: () => {
                                      confirmModal.hide()
                                    },
                                  })
                                }
                                disabled={!canEdit}
                              >
                                <BinIcon
                                  width='20'
                                  height='20'
                                  className='text-secondary-100'
                                />
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className='flex mb-[30px]'>
                      <CoinImage
                        className='w-[60px] h-[60px] object-contain shrink-0'
                        src={values.coins[index].imgPath.url}
                        alt={values.coins[index].name}
                      />
                      <div className='w-[173px] mr-[30px] ml-[10px] shrink-0'>
                        <NumberWithCommaField
                          inputClassName='text-secondary'
                          name={`coins[${index}].amountCoin`}
                          label={`มูลค่าเหรียญ${item.name}`}
                          rightIcon={
                            <TextInputUnit>เหรียญ{item.name}</TextInputUnit>
                          }
                          disabled={!canEdit}
                          maxLength={10}
                        />
                      </div>
                      <div className='text-[24px] font-light text-secondary-100 mt-[25px] mr-[30px]'>
                        =
                      </div>
                      {index === 0 ? (
                        <div className='w-[173px]'>
                          <NumberWithCommaField
                            className='text-secondary'
                            name={`coins[${index}].amountCoinPerValue`}
                            label='ราคา (บาท)'
                            rightIcon={<TextInputUnit>บาท</TextInputUnit>}
                            disabled={!canEdit}
                            maxLength={10}
                          />
                        </div>
                      ) : (
                        <>
                          {coinPair(values.coins[index].valueUnitCoinId)}
                          <div className='w-[173px] pt-[20px]'>
                            <TextInputCustomField
                              className='text-secondary'
                              name={`coins[${index}].amountCoinPerValue`}
                              options={
                                index === 1
                                  ? [
                                      {
                                        label: 'บาท',
                                        value: null,
                                        imgPath: '',
                                      },
                                      ...coinOptions.slice(0, 1),
                                    ]
                                  : coinOptions.slice(0, 2)
                              }
                              coinExchangeOptionIndex={index}
                              type='number'
                              disabled={!canEdit}
                              showErrorMsg
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {index >= 1 && (
                      <>
                        <div className='border-[1px] border-[#eee] mt-[20px] mb-[30px]' />
                        <div className='flex justify-between'>
                          <div>
                            <div className='text-[18px] font-bold text-secondary mb-[30px]'>
                              อายุเหรียญ{item.name}ที่ได้จากการซื้อ
                            </div>
                            <div className='flex'>
                              <div className='mt-[30px]'>
                                <CheckboxField
                                  name={`coins[${index}].isExpireByPaid`}
                                  label='จำกัดวันหมดอายุ'
                                  checked={values.coins[index].isExpireByPaid}
                                  disabled={!canEdit || item.visible}
                                />
                              </div>
                              <div className='grid grid-cols-1 mx-[40px]'>
                                <div className='border-l' />
                              </div>
                              <div className='w-[173px]'>
                                <NumberWithCommaField
                                  className='text-secondary'
                                  name={`coins[${index}].expireWithinMonthsByPaid`}
                                  label='อายุเหรียญ  *1 เดือน = 30 วัน'
                                  rightIcon={
                                    <TextInputUnit>เดือน</TextInputUnit>
                                  }
                                  disabled={
                                    !canEdit ||
                                    !values.coins[index].isExpireByPaid
                                  }
                                  maxLength={10}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='grid grid-cols-1 mx-[70px]'>
                            <div className='border-l' />
                          </div>
                          <div>
                            <div className='text-[18px] font-bold text-secondary mb-[30px]'>
                              อายุเหรียญ{item.name}ที่ได้จากกิจกรรม
                            </div>
                            <div className='flex'>
                              <div className='mt-[30px]'>
                                <CheckboxField
                                  name={`coins[${index}].isExpireByActivity`}
                                  label='จำกัดวันหมดอายุ'
                                  checked={
                                    values.coins[index].isExpireByActivity
                                  }
                                  disabled={!canEdit || item.visible}
                                />
                              </div>
                              <div className='grid grid-cols-1 mx-[40px]'>
                                <div className='border-l' />
                              </div>
                              <div className='w-[173px]'>
                                <NumberWithCommaField
                                  className='text-secondary'
                                  name={`coins[${index}].expireWithinMonthsByActivity`}
                                  label='อายุเหรียญ'
                                  rightIcon={
                                    <TextInputUnit>เดือน</TextInputUnit>
                                  }
                                  disabled={
                                    !canEdit ||
                                    !values.coins[index].isExpireByActivity
                                  }
                                  maxLength={10}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))
              }
            />

            <div
              className={cn('flex justify-end', {
                'justify-between': coinRequest?.createdBy,
              })}
            >
              {coinRequest?.createdBy && (
                <div className='flex'>
                  <div className='text-[14px] font-bold text-secondary-100 mt-[16px]'>
                    ดำเนินการโดย:
                  </div>
                  <Avatar
                    className='w-[50px] h-[50px] mx-[10px]'
                    src={coinRequest.createdBy.avatarImgPath}
                    alt={coinRequest.createdBy.displayName}
                  />
                  <div className='text-[14px] font-bold text-secondary mt-[16px]'>
                    {coinRequest.createdBy.displayName}
                  </div>
                </div>
              )}
              {canApprove ? (
                <div className='flex'>
                  <Button
                    type='button'
                    className='px-[30px] border border-secondary-100 text-secondary-100'
                    onClick={handleReject}
                  >
                    Reject
                  </Button>
                  <Button
                    type='submit'
                    className='px-[20px] bg-primary text-white ml-[20px]'
                  >
                    Approve
                  </Button>
                </div>
              ) : isCoinRequestPending ? (
                <p className='font-sarabun text-primary'>รอการอนุมัติ</p>
              ) : permission.edit ? (
                <div className='flex'>
                  <Button
                    type='button'
                    className='px-[30px] border border-secondary-100 text-secondary-100'
                    onClick={() => {
                      confirmModal.show({
                        content: (
                          <div className='w-[400px] mb-[20px] '>
                            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
                              คุณแน่ใจหรือไม่ว่า ยกเลิกการแก้ไข หากกดยกเลิกแล้ว
                              การปรับแต่งค่าทั้งหมดจะคืนกลับสู่ค่าเดิมก่อนหน้านี้
                            </p>
                          </div>
                        ),
                        onConfirm: async () => {
                          resetForm()
                          confirmModal.hide()
                        },
                        onClose: () => confirmModal.hide(),
                      })
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type='submit'
                    className='px-[20px] bg-primary text-white ml-[20px]'
                  >
                    บันทึก และส่งแบบร่าง
                  </Button>
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
