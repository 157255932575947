import React from 'react'

import { useCropImage } from '@hooks/useCropImage'
import { CropSquareImageProps, WithCropSquareImageProps } from './interface'

export function withCropSquareImage(Component: React.FC<CropSquareImageProps>) {
  function WithCropSquareImage({
    aspectRatio,
    onChange,
    title = 'เพิ่มรูป',
    size = 200,
    isCircle = false,
    ...props
  }: WithCropSquareImageProps) {
    const {
      imgRef,
      uploadUrl,
      filename,
      visible,
      loading,
      hideModal,
      handleFileChange,
      cropImage,
      zoom,
      move,
    } = useCropImage({
      aspectRatio,
      maxSize: 1,
      onChange,
    })

    const pageProps = {
      ...props,
      imgRef,
      uploadUrl,
      filename,
      visible,
      loading,
      title,
      size,
      isCircle,
      hideModal,
      handleFileChange,
      cropImage,
      zoom,
      move,
    }
    return <Component {...pageProps} />
  }
  WithCropSquareImage.defaultProps = {
    circle: false,
  }
  return WithCropSquareImage
}
