/* eslint-disable max-classes-per-file */
import { BookEnum } from '@interfaces/BookEnum'
import { Expose } from 'class-transformer'

class PenNameType {
  @Expose() id: number

  @Expose() firstPenName: string
}

export class WriterListType {
  @Expose() id: number

  @Expose() penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() chapterCount: number

  @Expose() viewCount: number

  @Expose() likeCount: number

  @Expose() sumCharacterDonate: number

  @Expose() amountSpent: number
}
