import { gql } from 'graphql-request'

export const PRMOTION_CODE_REMAINING_LOG = gql`
  query PromotionCodeRemainingLogs(
    $promotionCodeId: Int!
    $page: Int
    $limitPerPage: Int
    $searchText: String
  ) {
    promotionCodeRemainingLogs(
      promotionCodeId: $promotionCodeId
      page: $page
      limitPerPage: $limitPerPage
      searchText: $searchText
    ) {
      total
      page
      data {
        id
        userId
        userType
        type
        channel
        coupon
        totalPrice
        discountedPrice
        summaryPrice
        discountedPercentage
        paidCoinReceived
        feeCoinReceived
        promotionName
        currentUsed
        maximumUsed
        createdAt
      }
    }
  }
`
