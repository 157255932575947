import { withPermissionGuard } from '@libs/permission'
import { SalesByUserPage } from './SalesByUserPage'
import { withSalesByUserPage } from './withSalesByUserPage'

const ConnectedSalesByUserPage = withPermissionGuard(
  withSalesByUserPage(SalesByUserPage),
  'user',
  'view'
)
export { ConnectedSalesByUserPage as SalesByUserPage }
