import React from 'react'
import { useClient } from '@hooks/useClient'
import { useQuery } from 'react-query'
import {
  SalesReportSearchFormProps,
  WithSalesReportSearchFormProps,
} from './interface'

export function withSalesReportSearchForm(
  Component: React.FC<SalesReportSearchFormProps>
) {
  function WithSalesReportSearchForm(props: WithSalesReportSearchFormProps) {
    const client = useClient()
    const { data: categories = [] } = useQuery('category-options', () =>
      client?.categoryClient.getCategoryOptions().then(res =>
        res.map(row => ({
          ...row,
          value: String(row.value),
        }))
      )
    )

    const newProps = {
      categories,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithSalesReportSearchForm
}
