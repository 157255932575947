import { PermissionGuardType } from '@models/rolePermission/PermissionGuardType'
import { RolePermissionFormType } from '@models/rolePermission/RolePermissionFormType'

export const defaultPermissionForm: RolePermissionFormType = {
  name: '',
  permissions: [
    {
      name: 'dashboard',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'sale',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'homePageManagement',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'menuManagement',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'chapterBook',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'promotionSetting',
      status: false,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'newsPromotion',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'user',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'userMission',
      status: false,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'coin',
      status: false,
      actions: [
        'active',
        'view',
        'create',
        'edit',
        'delete',
        'download',
        'approve',
      ],
    },
    {
      name: 'searchManagement',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
    {
      name: 'admin',
      status: false,
      actions: ['active', 'view', 'create', 'edit', 'delete', 'download'],
    },
  ],
}

export const defaultPermissionGuard: PermissionGuardType = {
  dashboard: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  sale: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  homePageManagement: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  menuManagement: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  chapterBook: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  promotionSetting: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  newsPromotion: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  user: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  userMission: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  coin: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  searchManagement: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
  admin: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    download: false,
    approve: false,
  },
}
