import { gql } from 'graphql-request'

export const FIND_WRITER_REQUEST_BY_ID = gql`
  query FindWriterRequestById($findWriterRequestByIdId: Int!) {
    findWriterRequestById(id: $findWriterRequestByIdId) {
      id
      bankAccount {
        bankName
        bankBranch
        bankBranchCode
        bankProvince
        accountNumber
        accountName
        accountType
        bankCoverImgPath
      }
      type
      affiliation
      fullName
      idNumber
      email
      phoneNumber
      residence
      country
      address
      postcode
      province
      district
      subDistrict
      idCardImgPath
      isSameMainAddress
      sendResidence
      sendCountry
      sendAddress
      sendPostcode
      sendProvince
      sendDistrict
      sendSubDistrict
      certificationTax
      companyName
      taxId
      companyCertificateImgPath
      vatRegistrationCertificateImgPath
      coordinatorFullName
      coordinatorEmail
      coordinatorPhoneNumber
      accountingFullName
      accountingEmail
      accountingPhoneNumber
      certificateCountry
      certificateAddress
      certificatePostcode
      certificateProvince
      certificateDistrict
      certificateSubDistrict
      writersConsents {
        id
        createdAt
        consent {
          id
          name
          content
        }
      }
      status
      rejectedReason
      approvedAt
      rejectedAt
      approver {
        id
        displayName
        avatarImgPath
      }
      user {
        id
        avatarImgPath
        idNumber
        fullName
        email
        phoneNumber
        birthDay
        gender
        username
        displayName
        idCardImgPath
        firstLoginProvider
      }
      writer {
        id
      }
    }
  }
`
