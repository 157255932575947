import React from 'react'
import cn from 'classnames'
import {
  ChevronUpIcon,
  SalePromotionIcon,
  EyeIcon,
  PatchCheckIcon,
  PromotionIcon,
  ThreeDotIcon,
} from '@components/Icons'
import {
  SettingPromotionStatusEnum,
  SettingPromotionStatusTypeValue,
} from '@interfaces/SettingPromotionStatusEnum'
import { SettingPromotionMenuTypeEnum } from '@interfaces/SettingPromotionMenuTypeEnum'
import { Toggle } from '@components/Toggle'
import { formatDateDomini } from '@libs/utils'
import { PromotionPublishedTypeEnum } from '@interfaces/PromotionPublishedTypeEnum'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { PromotionApproveSection } from '@features/promotionSetting/pages/PromotionSettingPage/components/PromotionApproveSection'
import { Card } from '../Card'
import { Container } from '../Container'
import { PromotionSettingCardProps } from './interface'
import { PromotionCodeContent } from './components/PromotionCodeContent'
import { PromotionChallengeContent } from './components/PromotionChallengeContent'
import { PromotionSaleContent } from './components/PromotionSaleContent'

export function PromotionSettingCard({
  queryKey,
  id,
  businessId,
  promotionType,
  status,
  title,
  description,
  userTerm,
  enabled,
  isEditable,
  usageDay,
  promotionChallenge,
  promotionCode,
  promotionSale,
  userType,
  publishedType,
  publishStartDate,
  publishEndDate,
  createdAt,
  usageLimitPerUser,
  usageLimit,
  isUsageLimitPerUser,
  isUsageLimit,
  usersFileName,
  users,
  lastRequest,
  dropdownRef,
  isShowDropdown,
  isExpand,
  isDisableStatus,
  couponValue,
  handleChange,
  handleActivePromotion,
  toggleDropdown,
  handleEdit,
  handleDuplicate,
  handleDeletePromotion,
  viewPromotion,
}: PromotionSettingCardProps) {
  const permission = usePermissionAction('promotionSetting')
  const isShowToggle =
    permission.edit &&
    isEditable &&
    (status === SettingPromotionStatusEnum.STARTED ||
      status === SettingPromotionStatusEnum.CLOSED) &&
    new Date(publishStartDate!) <= new Date() &&
    new Date(publishEndDate!) >= new Date()

  return (
    <>
      <Card>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-5'>
            {promotionType === SettingPromotionMenuTypeEnum.CODE && (
              <div className='flex items-center gap-x-[10px] font-mitr text-primary whitespace-nowrap'>
                <PromotionIcon />
                <h3 className='font-medium text-sm'>โปรโมชั่น Code</h3>
              </div>
            )}
            {promotionType === SettingPromotionMenuTypeEnum.CHALLENGE && (
              <div className='flex items-center gap-x-[10px] font-mitr text-primary whitespace-nowrap'>
                <PatchCheckIcon />
                <h3 className='font-medium text-sm'>User Challenge</h3>
              </div>
            )}
            {promotionType === SettingPromotionMenuTypeEnum.SALE && (
              <div className='flex items-center gap-x-[10px] font-mitr text-primary whitespace-nowrap'>
                <SalePromotionIcon />
                <h3 className='font-medium text-sm'>โปรโมชั่นการขาย</h3>
              </div>
            )}
            <div className='border-r border-gray h-[25px]' />
            <div
              className={cn(
                'flex justify-center items-center rounded whitespace-nowrap px-[8px] py-[4px] text-[12px] font-bold',
                {
                  'bg-blue-2 text-secondary':
                    status === SettingPromotionStatusEnum.PENDING,
                  'bg-red-74 text-white':
                    status === SettingPromotionStatusEnum.REJECTED,
                  'bg-secondary text-white':
                    status === SettingPromotionStatusEnum.APPROVED,
                  'bg-green-986 text-white':
                    status === SettingPromotionStatusEnum.STARTED,
                  'bg-secondary-100 text-white':
                    status === SettingPromotionStatusEnum.CLOSED,
                  'border border-gray-393 text-secondary':
                    status === SettingPromotionStatusEnum.DRAFT,
                  'border border-green-986 text-green-986':
                    status === SettingPromotionStatusEnum.GENERATE_CODE ||
                    status === SettingPromotionStatusEnum.CODE_GENERATED,
                  'border border-red-74 text-red-74':
                    status === SettingPromotionStatusEnum.GENERATE_FAIL,
                }
              )}
            >
              {SettingPromotionStatusTypeValue[status]}
            </div>
            <span className='text-lg font-bold text-secondary line-clamp-1 break-word pr-[10px]'>
              {title}
            </span>
          </div>

          <div className='flex items-center  gap-x-5 text-gray-500'>
            {isShowToggle && (
              <Toggle
                disabled={!permission.edit || !isEditable}
                checked={enabled}
                onChange={() => {
                  handleActivePromotion(enabled, id)
                }}
              />
            )}
            <button
              type='button'
              className='flex items-center justify-center border border-[#f4f4f7] rounded-lg w-[30px] h-[30px]'
              onClick={() => viewPromotion(id)}
            >
              <EyeIcon width='18' height='18' className='text-secondary-100' />
            </button>
            <div className='relative'>
              <button
                type='button'
                className='flex items-center justify-center w-[30px] h-[30px]'
                onClick={toggleDropdown}
              >
                <ThreeDotIcon
                  width='18'
                  height='18'
                  className='text-secondary'
                />
              </button>
              {isShowDropdown && (
                <div
                  ref={dropdownRef}
                  className='absolute w-[70px] z-10 text-[14px] font-light py-[10px] text-black-480 top-0 left-[35px] bg-white shadow border border-gray rounded-[8px] overflow-y-auto'
                >
                  <div
                    className={cn(
                      'flex items-center hover:bg-[#f2f9ff] hover:text-primary cursor-pointer py-[5px] px-[15px]',
                      {
                        hidden: !isEditable,
                      }
                    )}
                    onClick={() => handleEdit(id)}
                  >
                    แก้ไข
                  </div>
                  {status !== SettingPromotionStatusEnum.DRAFT && (
                    <div
                      className='flex items-center hover:bg-[#f2f9ff] hover:text-primary cursor-pointer py-[5px] px-[15px]'
                      onClick={() => handleDuplicate(id)}
                    >
                      ทำซ้ำ
                    </div>
                  )}
                  {status === SettingPromotionStatusEnum.DRAFT && (
                    <div
                      className='flex items-center hover:bg-[#f2f9ff] hover:text-primary cursor-pointer py-[5px] px-[15px]'
                      onClick={() => handleDeletePromotion(id)}
                    >
                      ลบ
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Container.Hr />
        <div className='flex items-center justify-between'>
          <div className='flex gap-x-[40px]'>
            <Card.TextVertical
              label='PromotionID :'
              value={businessId}
              valueColor={
                isDisableStatus ? 'secondary-bold' : 'secondary-100-bold'
              }
            />
            <Card.TextVertical
              label='Created date :'
              value={formatDateDomini(createdAt, true)}
              valueColor={
                isDisableStatus ? 'secondary-bold' : 'secondary-100-bold'
              }
            />
            <div className='flex gap-x-[10px]'>
              {publishedType === PromotionPublishedTypeEnum.NOW &&
              !Boolean(publishStartDate) ? (
                <Card.TextVertical
                  label={
                    promotionType === SettingPromotionMenuTypeEnum.SALE
                      ? 'ระยะเวลาแคมเปญ :'
                      : 'ระยะเวลาใช้โค้ด :'
                  }
                  value='ทันที'
                  valueColor={
                    isDisableStatus ? 'secondary-bold' : 'secondary-100-bold'
                  }
                />
              ) : (
                <Card.TextVertical
                  label={
                    promotionType === SettingPromotionMenuTypeEnum.SALE
                      ? 'ระยะเวลาแคมเปญ :'
                      : 'ระยะเวลาใช้โค้ด :'
                  }
                  value={formatDateDomini(publishStartDate, true)}
                  valueColor={
                    isDisableStatus ? 'secondary-bold' : 'secondary-100-bold'
                  }
                />
              )}

              <Card.TextVertical
                label='ถึง'
                value={formatDateDomini(publishEndDate, true)}
                valueColor={
                  isDisableStatus ? 'secondary-bold' : 'secondary-100-bold'
                }
              />
            </div>
          </div>
          <div>
            <button
              type='button'
              className={cn('flex w-full')}
              onClick={() => handleChange(isExpand)}
            >
              <ChevronUpIcon
                className={cn('text-primary', {
                  'transform duration-300 ease': isExpand,
                  'transform duration-300 ease rotate-180': !isExpand,
                })}
                width='24'
                height='24'
              />
            </button>
          </div>
        </div>
        <Container.Hr />
        {promotionType === SettingPromotionMenuTypeEnum.CODE &&
          promotionCode && (
            <PromotionCodeContent
              isDisableStatus={isDisableStatus}
              description={description}
              userTerm={userTerm}
              usageDay={usageDay}
              promotionCode={promotionCode}
              userType={userType}
              usageLimitPerUser={usageLimitPerUser}
              usageLimit={usageLimit}
              isUsageLimitPerUser={isUsageLimitPerUser}
              isUsageLimit={isUsageLimit}
              usersFileName={usersFileName}
              users={users}
              isExpand={isExpand}
              couponValue={couponValue}
            />
          )}
        {promotionType === SettingPromotionMenuTypeEnum.CHALLENGE &&
          promotionChallenge && (
            <PromotionChallengeContent
              isDisableStatus={isDisableStatus}
              description={description}
              userTerm={userTerm}
              promotionChallenge={promotionChallenge}
              isExpand={isExpand}
            />
          )}
        {promotionType === SettingPromotionMenuTypeEnum.SALE &&
          promotionSale && (
            <PromotionSaleContent
              isDisableStatus={isDisableStatus}
              description={description}
              userTerm={userTerm}
              promotionSale={promotionSale}
              usageDay={usageDay}
              isExpand={isExpand}
            />
          )}

        <div>
          {(status === SettingPromotionStatusEnum.PENDING || !!lastRequest) && (
            <PromotionApproveSection
              queryKey={queryKey}
              requestId={lastRequest?.id}
              promotionId={id}
              promotionName={title || ''}
              promotionDetail={description}
              promotionRequestStatus={lastRequest?.status}
              promotionType={promotionType}
              startPromotion={new Date(publishStartDate!)}
              endPromotion={new Date(publishEndDate!)}
              createdAt={new Date(createdAt)}
              actionBy={
                lastRequest
                  ? {
                      imageUrl: lastRequest.createdBy.avatarImgPath,
                      displayName: lastRequest.createdBy.displayName,
                      actionAt: lastRequest.createdAt,
                      actionType: lastRequest.action,
                    }
                  : undefined
              }
              formDetailUrl={
                promotionType === SettingPromotionMenuTypeEnum.CODE
                  ? `/promotion-setting/promotion-code/${id}/history`
                  : promotionType === SettingPromotionMenuTypeEnum.CHALLENGE
                  ? `/promotion-setting/promotion-challenge/${id}/history`
                  : `/promotion-setting/promotion-sale/${id}/history`
              }
            />
          )}
        </div>
      </Card>
    </>
  )
}
